import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction || 'column'};
`;

export const Card = styled.div`
  display: flex;
  padding: 16px 12px;
  justify-content: ${({ justify }) => justify || 'start'};
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-radius: 3px;
  width: 50%;
  max-width: 355px;
  cursor: pointer;
  flex-direction: ${({ direction }) => direction || 'column'};
  padding-bottom: ${({ noBottomPadding }) => noBottomPadding && '0px'};
  transition: 0.3s;
  & > img {
    height: 73px;
    width: 100px;
  }
  :hover {
    box-shadow: 0px 2px 10px -4px #000000;
  }
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 15px;
  width: 100%;
  & > div:not(last-child) {
    margin-right: 10px;
  }
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  & > :first-child {
    color: ${({ theme }) => theme.orbit.colorTextSecondary};
    margin-right: 5px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & > p {
    margin-right: 10px;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  & > label:not(:last-child) {
    margin-right: 20px;
  }
`;

export const SummaryTableWrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 50%;
  }
  & + p {
    margin-top: 40px;
  }
`;

export const CardSectionWrapper = styled.div`
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.orbit.borderColorCard}`};
  & > p:nth-child(2) {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.orbit.paletteProductNormal};
    & > span {
      margin-left: 5px;
    }
  }
  &:not(:first-child) {
    border-left: none;
  }
`;

export const MoreInfoWrapper = styled.div`
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.orbit.borderColorCard}`};
  border-top: none;
  margin-bottom: 25px;
`;
