import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import useCurrentUser from 'components/services/auth/useCurrentUser';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { KIWI_ADMIN } from 'consts/roles';
import { statusesBadgeTypes, tierTypes } from 'consts/smartPass';

const TableWrapper = styled.div`
  & table {
    border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};

    & thead {
      border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
    }
  }
`;

const List = ({ companyName, smartPassesList }) => {
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const companyDetails = useCompanyDetails(user.companyName);

  const hasAdminPrivileges =
    user.role === KIWI_ADMIN || user.email === companyDetails.data?.accountManager;

  return (
    <Card
      title={polyglot.t('smart_pass.title')}
      actions={
        !hasAdminPrivileges ? (
          <Link to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass/new`}>
            <Button size="small">{polyglot.t('smart_pass.add_new_smart_pass_button')}</Button>
          </Link>
        ) : undefined
      }
    >
      <CardSection>
        <TableWrapper>
          <Table striped={false}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{polyglot.t('smart_pass.table.smart_pass_name')}</TableCell>
                <TableCell align="left">
                  <Text type="secondary" weight="bold">
                    {polyglot.t('smart_pass.table.smart_pass_airport')}
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text type="secondary" weight="bold" align="right">
                    {polyglot.t('common.type')}
                  </Text>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {smartPassesList.map(smartPass => (
                <TableRow key={smartPass.id}>
                  <TableCell align="left">
                    {smartPass.name ||
                      polyglot.t('smart_pass.form.header.smart_pass_name_untitled')}
                  </TableCell>
                  <TableCell align="left">
                    <Text type="secondary">{smartPass.IATA_code}</Text>
                  </TableCell>
                  <TableCell align="right">
                    <Text type="secondary" align="right">
                      {tierTypes[smartPass.tier]}
                    </Text>
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" justify="end" align="center">
                      <Badge type={statusesBadgeTypes[smartPass.status]}>
                        {polyglot.t(`smart_pass.table.badges.${smartPass.status}`)}
                      </Badge>
                      <Link
                        to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass/view?smartPassId=${smartPass.id}`}
                      >
                        <Button size="small" type="secondary">
                          {polyglot.t('common.view')}
                        </Button>
                      </Link>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </CardSection>
    </Card>
  );
};

export default List;
