import styled from 'styled-components';

export const SuffixIconWrapper = styled.div`
  padding-right: 15px;
`;

export const InputFieldWrapper = styled.div`
  position: relative;
  width: 220px;
`;

export const InputDropdownWrapper = styled.div`
  display: none;
  transition: all 250ms ease-in-out;
  height: 0;
  width: ${props => props.width}
  box-sizing: border-box;
  ${({ isExpanded }) =>
    isExpanded &&
    `
    display: block;
    z-index: 20;
    position: absolute;
    padding: 20px;
    background-color: white;
    height:auto;
    box-shadow: -5px 10px 10px 10px rgba(23, 27, 30, 0.1);
    border-radius: 3px;
  `}
`;
