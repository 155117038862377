import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import NewWindowIcon from '@kiwicom/orbit-components/lib/icons/NewWindow';

import { usePolyglot } from 'components/services/i18n';
import questonMarkImage from 'images/questionMark.png';
import { ROUTE_CONFIG } from 'consts/routes';
import useStaticHelp from './useStaticHelp';
import ResourceGroup from './ContextualResources/ResourceGroup';
import { useWindowSize } from 'hooks';
import { ContextualResourcesState } from './ContextualResourcesProvider';

const Container = styled.div`
  margin-left: 18px;
  margin-top: 4px;
  width: 74px;
`;

const QuestionMarkImage = styled.img`
  cursor: pointer;
`;

const PopoverWrapper = styled.div`
  position: fixed;
  width: 250px;
  top: 67px;
  right: 17px;
  box-shadow: 0 4px 12px 0 rgba(37, 42, 49, 0.3);
  & > a > div {
    flex-direction: row-reverse;
    border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark} !important;

    & > div > svg {
      color: ${({ theme }) => theme.orbit.paletteInkLighter};
    }
  }
`;

const ContextualResourcesWrapper = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.orbit.paletteProductLight};
`;

const ResourcesMenu = () => {
  const polyglot = usePolyglot();
  const [staticHelp] = useStaticHelp();
  const { width } = useWindowSize();
  const { title, forceToPopover } = useContext(ContextualResourcesState);

  return (
    <Container>
      <Popover
        noPadding
        placement="auto-start"
        fixed
        content={
          <PopoverWrapper>
            <Link target="_blank" to={ROUTE_CONFIG.DOCS.path}>
              <ListChoice
                dataTest="user-menu-resources-button"
                title={polyglot.t('common.resources')}
                icon={<NewWindowIcon />}
              />
            </Link>
            <Link target="_blank" to={ROUTE_CONFIG.DOCS_FAQ.path}>
              <ListChoice
                dataTest="user-menu-faq-button"
                title={polyglot.t('common.FAQ')}
                icon={<NewWindowIcon />}
              />
            </Link>
            {(!staticHelp.shown || width <= 1500 || forceToPopover) && title && (
              <ContextualResourcesWrapper>
                <ResourceGroup inPopover />
              </ContextualResourcesWrapper>
            )}
          </PopoverWrapper>
        }
      >
        <QuestionMarkImage src={questonMarkImage} alt="Resources" />
      </Popover>
    </Container>
  );
};

export default ResourcesMenu;
