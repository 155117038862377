import React, { useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Authorization } from 'common/Authorization';
import User from 'shapes/User';
import Polyglot from 'shapes/Polyglot';
import { ROUTE_CONFIG } from 'consts/routes';

import {
  ItemName,
  ItemContainer,
  SubrouteIcon,
  ArrowRight,
  SubrouteContainer,
  Subroute,
  SubrouteIconContainer,
  ItemIcon,
} from './Navigation.styled';

const NavigationItem = ({ item, pathname, polyglot, navigationOpen, user, deselect }) => {
  const [open, setOpen] = useState(false);

  const getLink = useCallback(() => {
    if (item.resource === 'model.company') {
      return `${ROUTE_CONFIG.COMPANIES.path}/${user.companyName}`;
    }

    return item.path;
  }, [item, user]);

  const toggleSubroutes = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const canAccessRoutes = useMemo(() => {
    return item.routes && item.routes.some(route => !(route.action && route.resource));
  }, [item]);

  return (
    <Authorization resource={item.resource} action={item.action}>
      <Link to={getLink()}>
        <ItemContainer
          subroutes={open && navigationOpen}
          numberOfSubRoutes={item.routes && item.routes.length}
          selected={pathname.includes(getLink()) && !deselect}
          open={navigationOpen}
        >
          <ItemIcon>
            <item.Icon size="small" />
          </ItemIcon>
          {!item.routes || !canAccessRoutes ? (
            <ItemName> {polyglot.t(item.name)}</ItemName>
          ) : (
            <div>
              <ItemName>
                {polyglot.t(item.name)}

                <SubrouteIcon onClick={toggleSubroutes}>
                  <ArrowRight size="small" />
                </SubrouteIcon>
              </ItemName>
              <SubrouteContainer>
                {item.routes.map((route, i) => (
                  <Subroute key={i} to={route.path} selected={pathname.includes(route.path)}>
                    <SubrouteIconContainer>
                      <route.Icon size="small" />
                    </SubrouteIconContainer>
                    <span>{polyglot.t(route.name)}</span>
                  </Subroute>
                ))}
              </SubrouteContainer>
            </div>
          )}
        </ItemContainer>
      </Link>
    </Authorization>
  );
};

NavigationItem.propTypes = {
  pathname: PropTypes.string.isRequired,
  user: PropTypes.shape(User).isRequired,
  polyglot: PropTypes.shape(Polyglot).isRequired,
  navigationOpen: PropTypes.bool.isRequired,
};

export default NavigationItem;
