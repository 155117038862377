import React from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { useToggle } from 'utils/hooks';
import SolutionsModal from 'components/scenes/MySolutions/components/SolutionsModal';
import CreateSolutionProvider from 'components/scenes/MySolutions/services/CreateSolution';
import { usePolyglot } from 'components/services/i18n';

import IllustratedCard from './IllustratedCard';

const TextWrapper = styled.div`
  max-width: 400px;

  margin-bottom: 30px;
`;

const CreateSolution = ({ solutions, hasSolutions }) => {
  const polyglot = usePolyglot();
  const modal = useToggle();

  if (solutions.loading) {
    return (
      <Card loading>
        <CardSection />
      </Card>
    );
  }

  const handleCreateSolution = () => {
    modal.setOff();
  };

  return (
    <>
      <CreateSolutionProvider>
        {modal.isOn && <SolutionsModal onClose={modal.setOff} onSuccess={handleCreateSolution} />}
        <IllustratedCard
          title={polyglot.t('getting_started.create_solution.title')}
          illustrationName="Nomad"
          type={hasSolutions ? 'secondary' : 'primary'}
          dataTest="card-solutions"
        >
          <TextWrapper>
            <Text type={hasSolutions ? 'secondary' : 'primary'}>
              {polyglot.t('getting_started.create_solution.description')}
            </Text>
          </TextWrapper>

          <Stack direction="row" spacing="medium" align="center">
            <Button type="primary" size="small" onClick={modal.setOn} disabled={hasSolutions}>
              {polyglot.t('getting_started.create_solution.button')}
            </Button>

            {hasSolutions && (
              <Text type="info" size="small">
                {polyglot.t('getting_started.create_solution.done_text')}
              </Text>
            )}
          </Stack>
        </IllustratedCard>
      </CreateSolutionProvider>
    </>
  );
};

export default CreateSolution;
