import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form/dist/index.ie11';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import Card from 'components/scenes/Booking/common/Card';
import { INITIAL_PASSENGER } from 'components/scenes/Booking/scenes/DepositBooking/consts';
import { usePolyglot } from 'components/services/i18n';
import { getSessionStorageItem } from 'utils/sessionStorage';
import { BOOKING_FLOW_SESSION_ID } from 'consts';
import { getLast20CharsBookingToken } from 'utils/prebooking';
import { useDependencyEffect } from 'hooks';
import useBookingFlow from 'components/services/booking/useBookingFlow';

import PassengerForm from './components/PassengerForm';
import BaggageForm from './components/BaggageForm';
import ActionButtons from './components/ActionButtons';

const CardTabsWrapper = styled.div`
  margin-bottom: 0 !important;
  padding-left: 1px;
  width: 878px;
  overflow: hidden;
  height: 44px;
`;
const CardTabs = styled.div`
  height: 74px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hide;
`;

const CardTab = styled.div`
  display: inline-block;
  height: 44px;
  line-height: 44px;
`;

const getPassengerErrorIndexes = errors => {
  const errorIndexes = [];
  if (errors.passengers?.length) {
    for (const [i, p] of errors.passengers.entries()) {
      if (p) {
        errorIndexes.push(i);
      }
    }
  }
  return errorIndexes;
};

const PassengersAndBags = ({
  apiKey,
  bookingToken,
  currency,
  loading,
  ancillariesReady,
  tripData,
  paxCategories,
}) => {
  const { watch, control, trigger, errors } = useFormContext();
  const { baggage } = tripData;
  const passengers = watch('passengers');
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'passengers',
  });
  const [selectedPassenger, setSelectedPassenger] = useState('');
  const mutationRef = useRef();
  const polyglot = usePolyglot();
  const { updatePrebookingSession } = useBookingFlow();

  const handleAddPassenger = async () => {
    await trigger();
    if (errors.passengers?.length) {
      return;
    }
    ancillariesReady.current = false;
    append({ ...INITIAL_PASSENGER.adult, baggage: {} });
  };

  const handleRemovePassenger = index => {
    ancillariesReady.current = false;
    remove(index);
  };

  const clearInput = index => {
    insert(index, { ...INITIAL_PASSENGER.adult, baggage: {} });
    remove(index + 1);

    mutationRef.current = 'clear';
  };

  useEffect(() => {
    if (mutationRef.current === 'clear') {
      mutationRef.current = null;
      return;
    }
    setSelectedPassenger(fields.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fields)]);

  useDependencyEffect(() => {
    updatePrebookingSession(
      apiKey,
      passengers,
      currency,
      getSessionStorageItem(
        `${BOOKING_FLOW_SESSION_ID}_${getLast20CharsBookingToken(bookingToken)}`,
      ),
    );
  }, [passengers.length, paxCategories]);

  const errorIndexes = getPassengerErrorIndexes(errors);

  return (
    <>
      <CardTabsWrapper data-test="tabs-wrapper">
        <CardTabs>
          {passengers.length < 9 && (
            <CardTab>
              <Button type="primarySubtle" onClick={handleAddPassenger}>
                {polyglot.t('booking.deposit_booking.passengers_and_bags.add_passenger')}
              </Button>
            </CardTab>
          )}
          {fields.map((passenger, index) => {
            let buttonType = 'white';
            if (errorIndexes.includes(index)) {
              buttonType = selectedPassenger === index ? 'critical' : 'criticalSubtle';
            } else {
              buttonType = selectedPassenger === index ? 'white' : 'secondary';
            }
            return (
              <CardTab key={passenger.id}>
                <Button
                  dataTest={`passenger-tab-${index + 1}`}
                  type={buttonType}
                  onClick={() => {
                    setSelectedPassenger(index);
                  }}
                >
                  {passengers[index]?.firstName || index + 1 + '.'}{' '}
                  {passengers[index]?.lastName ||
                    polyglot.t('booking.deposit_booking.booking_summary.price_overview.passenger')}
                </Button>
              </CardTab>
            );
          })}
        </CardTabs>
      </CardTabsWrapper>
      <Card showTitle={false} dataTest="passengers-bags-wrapper">
        {fields.map((passenger, index) => {
          return (
            <div
              key={passenger.id}
              style={{ display: index === selectedPassenger ? 'block' : 'none' }}
            >
              <PassengerForm
                key={passenger.id}
                index={index}
                remove={remove}
                paxCategories={paxCategories}
              />

              <Separator spaceAfter="medium" />

              {loading ? (
                <Loading type="pageLoader" />
              ) : (
                <BaggageForm index={index} baggage={baggage} />
              )}

              <Separator spaceAfter="medium" />

              <ActionButtons
                onAddPassenger={passengers.length < 9 ? handleAddPassenger : null}
                onClearPassenger={() => clearInput(index)}
                onDeletePassenger={() => handleRemovePassenger(index)}
                passengers={passengers}
                index={index}
                paxCategories={paxCategories}
              />
            </div>
          );
        })}
      </Card>
    </>
  );
};

export default React.memo(PassengersAndBags);
