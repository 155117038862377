import React from 'react';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { Space } from 'common';
import { usePolyglot } from 'components/services/i18n';

const Title = ({ children }) => {
  const { isDesktop } = useMediaQuery();

  const level = isDesktop ? 1 : 2;

  return (
    <Heading element={`h${level}`} type={`title${level}`} spaceAfter="large">
      {children}
    </Heading>
  );
};

/**
 * Displays a header and breadcrumbs. All values are calculated from route config
 * and current path.
 */

const PageTitle = React.memo(({ label }) => {
  const polyglot = usePolyglot();

  return (
    <Space before="m">
      <Space before="s">
        <Title>{polyglot.t(label)}</Title>
      </Space>
    </Space>
  );
});

export default PageTitle;
