import React, { useReducer } from 'react';

export const InfoMessagesState = React.createContext({
  state: {},
  addInfoMessage: message => message,
  closeInfoMessage: id => id,
});

const infoMessagesReducer = (state, action) => {
  const { type, message } = action;
  switch (type) {
    case 'ADD_INFO_MESSAGE':
      return {
        ...state,
        [message.id]: message,
      };
    case 'CLOSE_INFO_MESSAGE':
      return {
        ...state,
        [message.id]: {
          ...state[message.id],
          isClosed: true,
        },
      };
    default:
      return state;
  }
};

const InfoMessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(infoMessagesReducer, {});

  const addInfoMessage = message => {
    const { id, type, content } = message;
    if (!state[id]) {
      dispatch({
        type: 'ADD_INFO_MESSAGE',
        message: {
          id,
          type,
          content,
          isClosed: false,
        },
      });
    }
  };

  const closeInfoMessage = id =>
    dispatch({
      type: 'CLOSE_INFO_MESSAGE',
      message: { id },
    });

  const value = { state, addInfoMessage, closeInfoMessage };

  return <InfoMessagesState.Provider value={value}>{children}</InfoMessagesState.Provider>;
};

export default InfoMessagesProvider;
