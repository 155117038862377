import React from 'react';
import * as R from 'ramda';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Collapse from '@kiwicom/orbit-components/lib/Collapse';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import ChevronUpIcon from '@kiwicom/orbit-components/lib/icons/ChevronUp';
import Text from '@kiwicom/orbit-components/lib/Text';

import { Flex, Space } from 'common';
import { usePolyglot } from 'components/services/i18n';
import { useSearch } from 'components/services/search';
import { useToggle } from 'utils/hooks';
import { flightCardAdapters, convertUTCDateToLocalDate } from 'utils/search';

import { NightsWrapper, CollapseWrapper } from './FlightCard.styled';
import RouteGroups from './RouteGroups';
import FlightCardSummary from './FlightCardSummary';
import VirtualInterliningTag from './VirtualInterliningTag';

/**
 * Last element should not be wrapped. Also calculating overnight stay.
 * @param {number} index
 * @param {array} routeGroups
 * @param {number} length
 * @param {Rect.Component} flightLeg
 */
function wrapFlightLeg(index, routeGroups, flightLeg, polyglot) {
  if (index === routeGroups.length - 1) {
    return flightLeg;
  }
  const currentGroup = routeGroups[index];
  const nextGroup = routeGroups[index + 1];
  const lastFlight = currentGroup[currentGroup.length - 1];
  const lastArrival = new Date(lastFlight.local_arrival);
  const firstDeparture = new Date(nextGroup[0].local_departure);
  const nightCount = differenceInCalendarDays(
    convertUTCDateToLocalDate(firstDeparture),
    convertUTCDateToLocalDate(lastArrival),
  );
  return (
    <React.Fragment key={lastFlight.id}>
      <Space after="l">{flightLeg}</Space>
      <Space after="l">
        <NightsWrapper>
          <Text size="small" type="secondary">
            {polyglot.t('searchAndBooking.nights_staying_in', {
              nightCount,
              cityTo: lastFlight.cityTo,
            })}
          </Text>
        </NightsWrapper>
      </Space>
    </React.Fragment>
  );
}

const FlightCard = ({ searchResult }) => {
  const polyglot = usePolyglot();
  const isCollapsed = useToggle();
  const { tabData } = useSearch();
  const { groupByRoutes, getFlightLegProps, stopAirportsFromGroup, getPrice } =
    flightCardAdapters[tabData.tripType];
  const { route, virtual_interlining } = searchResult;
  const routeGroups = groupByRoutes(route);
  const price = getPrice(searchResult);
  const stopAirportFilter = routeGroups.filter(g =>
    R.equals(stopAirportsFromGroup(g), tabData.selectedStopAirports),
  );
  if (tabData.selectedStopAirports && stopAirportFilter.length === 0) {
    return null;
  }
  return (
    <Card>
      <CardSection>
        <FlightCardSummary
          routeGroups={routeGroups}
          searchResult={searchResult}
          getFlightLegProps={getFlightLegProps}
          wrapFlightLeg={wrapFlightLeg}
          price={price}
        />
      </CardSection>
      <CardSection>
        <CollapseWrapper>
          <Collapse
            dataTest="extend-search-modal"
            label={virtual_interlining && <VirtualInterliningTag />}
            actions={
              <Button size="small" type="white" onClick={isCollapsed.toggle}>
                {isCollapsed.isOn ? (
                  <Flex cross="center">
                    <ChevronUpIcon />
                    {polyglot.t('searchAndBooking.close_details')}
                  </Flex>
                ) : (
                  <Flex cross="center">
                    <ChevronDownIcon />
                    {polyglot.t('searchAndBooking.show_details')}
                  </Flex>
                )}
              </Button>
            }
            expanded={isCollapsed.isOn}
          >
            <RouteGroups
              routeGroups={routeGroups}
              tripType={tabData.tripType}
              getFlightLegProps={getFlightLegProps}
            />
          </Collapse>
        </CollapseWrapper>
      </CardSection>
    </Card>
  );
};

export default FlightCard;
