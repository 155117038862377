import * as R from 'ramda';

const getOptionItems = (defs, indices) => {
  return indices.reduce((acc, optionIndex) => {
    if (acc[optionIndex]) {
      acc[optionIndex].amount += 1;
    } else {
      acc[optionIndex] = {
        amount: 1,
        category: defs[optionIndex].category,
        restrictions: defs[optionIndex].restrictions,
        conditions: defs[optionIndex].conditions,
        isHold: defs[optionIndex].isHold,
      };
    }
    return acc;
  }, {});
};

const getOptions = ({
  currentCombination, // current index
  passengerCategory, // adult || child || infant || teen
  pickerType, // holdBag || handBag
  passengerId,
  baggage: { combinations, definitions },
}) => {
  const bagDefinitions = definitions[pickerType];
  const combinationsCopy = R.clone([combinations])[0];

  let passengersCombination = combinationsCopy[pickerType].filter(i =>
    R.includes(passengerCategory, i.conditions.passengerGroups),
  );

  if (passengerId) {
    passengersCombination = passengersCombination.filter(combination =>
      combination.conditions.eligiblePassengerIds?.includes(passengerId),
    );
  }

  const options = passengersCombination.map(c => ({
    index: c.index,
    pickerType,
    price: c.price,
    items: getOptionItems(bagDefinitions, c.indices),
  }));

  const currentComb = options.find(c => c.index === currentCombination);

  if (currentComb) {
    const currentCombinationPrice = currentComb ? currentComb.price.amount : 0;
    const expensiveOptions = options
      .filter(o => o.price.amount > currentCombinationPrice)
      .map(option => {
        const priceAmount = option.price.amount;
        const copyOption = R.clone([option])[0];
        copyOption.price.amount = priceAmount - currentCombinationPrice;
        return copyOption;
      });

    return [currentComb, ...expensiveOptions];
  }

  return options;
};

export default getOptions;
