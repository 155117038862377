export const locationTypes = {
  COUNTRY: 'country',
  CITY: 'city',
  AIRPORT: 'airport',
  STATION: 'station',
  BUS_STATION: 'bus_station',
  REGION: 'region',
  CONTINENT: 'continent',
};

export const SEARCH_LOCATION_TYPES = [
  locationTypes.COUNTRY,
  locationTypes.CITY,
  locationTypes.AIRPORT,
  locationTypes.STATION,
  locationTypes.BUS_STATION,
];

export const tripTypesValues = {
  RETURN: 'Return',
  ONEWAY: 'OneWay',
  MULTICITY: 'MultiCity',
  NOMAD: 'Nomad',
};

export const tripTypesLabels = {
  [tripTypesValues.RETURN]: 'common.return',
  [tripTypesValues.ONEWAY]: 'common.route_one_way',
  [tripTypesValues.MULTICITY]: 'searchAndBooking.tripTypes.multicity',
  [tripTypesValues.NOMAD]: 'searchAndBooking.tripTypes.nomad',
};

export const tripTypes = [
  {
    label: tripTypesLabels[tripTypesValues.RETURN],
    value: tripTypesValues.RETURN,
  },
  {
    label: tripTypesLabels[tripTypesValues.ONEWAY],
    value: tripTypesValues.ONEWAY,
  },
  {
    label: tripTypesLabels[tripTypesValues.MULTICITY],
    value: tripTypesValues.MULTICITY,
  },
  {
    label: tripTypesLabels[tripTypesValues.NOMAD],
    value: tripTypesValues.NOMAD,
  },
];

export const passengerTypes = {
  ADULTS: 'adults',
  CHILDREN: 'children',
  INFANTS: 'infants',
};

export const MAX_PASSENGERS_SUM = 9;

export const cabinClassTypesValues = {
  ANY: undefined,
  ECONOMY: 'M',
  PREMIUM_ECONOMY: 'W',
  BUSINESS: 'C',
  FIRST_CLASS: 'F',
};

export const cabinClassTypesLabels = {
  [cabinClassTypesValues.ANY]: 'searchAndBooking.cabinClassTypes.any',
  [cabinClassTypesValues.ECONOMY]: 'searchAndBooking.cabinClassTypes.economy',
  [cabinClassTypesValues.PREMIUM_ECONOMY]: 'searchAndBooking.cabinClassTypes.premium_economy',
  [cabinClassTypesValues.BUSINESS]: 'searchAndBooking.cabinClassTypes.business',
  [cabinClassTypesValues.FIRST_CLASS]: 'searchAndBooking.cabinClassTypes.first_class',
};

export const fareCategoryStrings = {
  M: 'Economy',
  W: 'Premium Economy',
  C: 'Business',
  F: 'First Class',
};

export const classTypeSmartpoint = {
  ECON: cabinClassTypesValues.ECONOMY,
  PREME: cabinClassTypesValues.PREMIUM_ECONOMY,
  BUSNS: cabinClassTypesValues.BUSINESS,
  FIRST: cabinClassTypesValues.FIRST_CLASS,
  F: cabinClassTypesValues.FIRST_CLASS,
  C: cabinClassTypesValues.BUSINESS,
};

export const cabinClassTypes = [
  {
    label: cabinClassTypesLabels[cabinClassTypesValues.ANY],
    value: cabinClassTypesValues.ANY,
  },
  {
    label: cabinClassTypesLabels[cabinClassTypesValues.ECONOMY],
    value: cabinClassTypesValues.ECONOMY,
  },
  {
    label: cabinClassTypesLabels[cabinClassTypesValues.PREMIUM_ECONOMY],
    value: cabinClassTypesValues.PREMIUM_ECONOMY,
  },
  {
    label: cabinClassTypesLabels[cabinClassTypesValues.BUSINESS],
    value: cabinClassTypesValues.BUSINESS,
  },
  {
    label: cabinClassTypesLabels[cabinClassTypesValues.FIRST_CLASS],
    value: cabinClassTypesValues.FIRST_CLASS,
  },
];

export const vehicleTypesValues = {
  FLIGHT: 'aircraft',
  BUS: 'bus',
  TRAIN: 'train',
};

export const vehicleTypes = [
  {
    label: 'searchAndBooking.vehicleTypes.flight',
    value: vehicleTypesValues.FLIGHT,
  },
  {
    label: 'searchAndBooking.vehicleTypes.bus',
    value: vehicleTypesValues.BUS,
  },
  {
    label: 'searchAndBooking.vehicleTypes.train',
    value: vehicleTypesValues.TRAIN,
  },
];

export const GROUND_TRANSPORT = [vehicleTypesValues.BUS, vehicleTypesValues.TRAIN];

export const sortTypes = {
  BEST: 'quality',
  PRICE: 'price',
  DURATION: 'duration',
};

export const PRODUCT_TYPE_TO_TRIP_TYPE = {
  'Meta Search': tripTypesValues.RETURN,
  'flights-api-basic-credits': tripTypesValues.RETURN,
  'search-and-book-zooz': tripTypesValues.RETURN,
  'flights-api-multicity': tripTypesValues.MULTICITY,
  'search-and-book-multicity': tripTypesValues.MULTICITY,
  'search-and-book-multicity-zooz': tripTypesValues.MULTICITY,
  'search-and-book-nomad': tripTypesValues.NOMAD,
  'flights-api-nomad': tripTypesValues.NOMAD,
  'search-and-book-nomad-zooz': tripTypesValues.NOMAD,
  'smartpoint-plugin-zooz': tripTypesValues.RETURN,
  'smartpoint-plugin-deposit': tripTypesValues.RETURN,
};

export const NOMAD_PRODUCT_TYPES = [
  'search-and-book-nomad',
  'flights-api-nomad',
  'search-and-book-nomad-zooz',
];
export const MULTICITY_PRODUCT_TYPES = [
  'search-and-book-multicity',
  'flights-api-multicity',
  'search-and-book-multicity-zooz',
];

export const ZOOZ_PRODUCT_TYPES = [
  'smartpoint-plugin-zooz',
  'search-and-book-zooz',
  'search-and-book-multicity-zooz',
  'search-and-book-nomad-zooz',
];

export const BOOKABLE_PRODUCT_TYPES = [
  'flights-api-basic-credits',
  'search-and-book-multicity',
  'search-and-book-nomad',
  'search-and-book-zooz',
  'search-and-book-multicity-zooz',
  'search-and-book-nomad-zooz',
  'smartpoint-plugin-zooz',
  'smartpoint-plugin-deposit',
];

export const MIN_CUSTOM_STOPOVERS = 1;
export const MAX_CUSTOM_STOPOVERS = 5;

export const stopoversRadioName = 'stopovers';
export const stopoversRadioValues = {
  ANY: undefined,
  DIRECT: 0,
};

export const availableSearchCurrencies = [
  'AED',
  'AMD',
  'AUD',
  'BHD',
  'BRL',
  // 'BYN',
  'BGN',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JOD',
  'JPY',
  'KGS',
  'KRW',
  'KWD',
  'KZT',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'OMR',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RSD',
  // 'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  'UZS',
  'YER',
  'ZAR',
];

export const selectableAvailableSearchCurrencies = availableSearchCurrencies.map(value => ({
  label: value,
  value: value,
}));

export const SMARTPOINT_APP_INIT_PERIOD = 300000; // 5 minutes

export const searchPreselectParams = {
  CURRENCY: 'searchAndBooking/PRESELECTED_CURRENCY',
  TRANSPORT_TYPE: 'searchAndBooking/TRANSPORT_TYPE',
  AFFIL_ID: 'searchAndBooking/AFFIL_ID',
};
