import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Select from '@kiwicom/orbit-components/lib/Select';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';

import Polyglot from 'shapes/Polyglot';
import { businessTypeOptions } from 'consts/register';
import { ErrorMessageComponent } from 'common';

const PersonalDetails = ({ polyglot, values, touched, handleChange, handleBlur, errors }) => {
  return (
    <Stack spaceAfter="large" spacing="large">
      <Heading type="title2" element="h2">
        {polyglot.t('register.personal_details')}
      </Heading>
      <InputField
        id="register-account-name"
        label={polyglot.t('register.account_name')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="accountName"
        value={values.accountName}
        error={touched.accountName && errors.accountName && polyglot.t(errors.accountName)}
      />
      <InputField
        id="register-website"
        label={polyglot.t('register.website_optional')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="website"
        value={values.website}
        error={touched.website && errors.website && polyglot.t(errors.website)}
      />
      <Select
        id="register-business-type-select"
        name="businessType"
        onChange={handleChange}
        value={values.businessType}
        label={polyglot.t('register.business_type')}
        placeholder={polyglot.t('register.business_type.placeholder')}
        options={businessTypeOptions.map(o => ({
          label: polyglot.t(o.label),
          value: o.value,
        }))}
        error={touched.businessType && errors.businessType && polyglot.t(errors.businessType)}
      />

      {values.businessType === 'Other' && (
        <InputField
          id="register-business-type-other-input"
          name="businessTypeOther"
          onChange={handleChange}
          placeholder={polyglot.t('register.business_type.other.placeholder')}
          value={values.businessTypeOther}
          error={
            touched.businessTypeOther &&
            errors.businessTypeOther &&
            polyglot.t(errors.businessTypeOther)
          }
        />
      )}
      <Stack spacing="XXXSmall">
        <Checkbox
          name="businessPresenceDisclaimer"
          checked={values.businessPresenceDisclaimer}
          label={polyglot.t('register.business_presence_disclaimer')}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <ErrorMessage
          name="businessPresenceDisclaimer"
          render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
        />
      </Stack>
    </Stack>
  );
};

PersonalDetails.schema = Yup.object().shape({
  accountName: Yup.string()
    .required('validation.cant_be_empty')
    .max(255, 'validation.length_exceeded'),
  website: Yup.string().url('validation.must_be_uri').max(255, 'validation.length_exceeded'),
  businessType: Yup.string().required('validation.cant_be_empty'),
  businessTypeOther: Yup.string().when(['businessType'], {
    is: businessType => businessType === 'Other',
    then: Yup.string().required('validation.cant_be_empty'),
  }),
  businessPresenceDisclaimer: Yup.bool().oneOf([true], 'validation.checked'),
});

PersonalDetails.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PersonalDetails;
