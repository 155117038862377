import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import saveAs from 'file-saver';

import AttachmentIcon from '@kiwicom/orbit-components/lib/icons/Attachment';
import AlertCircle from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import { TextLink } from '@kiwicom/orbit-components';

import Polyglot from 'shapes/Polyglot';
import { proxy } from 'utils/api';
import { truncate } from 'utils/string';
import { STATUS } from 'redux/modules/apigee/requests';

import { AttachmentThumbnail } from '../index.styled';

function Attachment({
  content,
  filename,
  serviceDesk,
  thumbnail,
  polyglot,
  requestId,
  withThumbnail = true,
  align = 'center',
}) {
  const [status, setStatus] = useState(STATUS.CLEAR);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  function getPath(url, target = 'attachment') {
    const urlSplit = url.split('/');
    const targetIndex =
      urlSplit.length > 1 ? urlSplit.findIndex(string => string === target) : null;
    const attachmentId = targetIndex !== null && urlSplit[targetIndex + 1];
    const fileName = targetIndex !== null && urlSplit[targetIndex + 2];
    return urlSplit.length > 1
      ? `jira/${serviceDesk}/${target}/${requestId}/${attachmentId}/${fileName}`
      : null;
  }

  function download() {
    setStatus(STATUS.LOADING);
    proxy.v1
      .get(getPath(content), {
        responseType: 'blob',
      })
      .then(response => saveAs(response.data, `${filename}`))
      .then(() => setStatus(STATUS.VALID))
      .catch(() => {
        setStatus(STATUS.ERROR);
      });
  }

  useEffect(() => {
    if (thumbnail) {
      setStatus(STATUS.LOADING);
      proxy.v1
        .get(getPath(thumbnail, 'thumbnail'), { responseType: 'blob' })
        .then(response => {
          setStatus(STATUS.VALID);
          setThumbnailUrl(window.URL.createObjectURL(response.data));
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
          setThumbnailUrl(null);
        });
    } else {
      setStatus(STATUS.VALID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const thumbnailComponent = thumbnail ? (
    <img src={thumbnailUrl} alt="Download" />
  ) : (
    <AttachmentIcon size="large" color="tertiary" />
  );

  return (
    <Stack grow={false} shrink={false} basis="25%" direction="column" align={align}>
      {withThumbnail && (
        <AttachmentThumbnail onClick={download}>
          {status === STATUS.LOADING && (
            <Loading type="pageLoader" text={polyglot.t('support.downloading')} />
          )}
          {status === STATUS.ERROR && (
            <Stack align="center" direction="column">
              <AlertCircle size="large" color="critical" />
              <Text type="critical">
                {polyglot.t('support.requestDetailsModal.attachment_error')}
              </Text>
            </Stack>
          )}
          {status === STATUS.VALID && thumbnailComponent}
        </AttachmentThumbnail>
      )}
      <TextLink type="info" size="small" onClick={download}>
        {truncate(filename, 20)}
      </TextLink>
    </Stack>
  );
}

Attachment.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  content: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  filename: PropTypes.string.isRequired,
  serviceDesk: PropTypes.string.isRequired,
};

export default Attachment;
