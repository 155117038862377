import React, { useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import Select from '@kiwicom/orbit-components/lib/Select';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import InputFile from '@kiwicom/orbit-components/lib/InputFile';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import UploadIcon from '@kiwicom/orbit-components/lib/icons/Upload';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { BillingCyclesState } from 'components/services/billingCycles';
import { useCurrency } from 'components/services/currencies';
import { DayPickerButton, ErrorMessageComponent } from 'common';
import { formatInterval } from 'utils/dates';
import { useToggle } from 'utils/hooks';
import * as datesConstants from 'consts/dates';

const InputDateContainer = styled.div`
  flex: 1;
  & > .DayPickerInput {
    display: block !important;
  }
`;

const BillingCycleSchema = Yup.object().shape({
  amount: Yup.number().required('validation.cant_be_empty'),
  periodStart: Yup.string().required('validation.cant_be_empty'),
  periodEnd: Yup.string().required('validation.cant_be_empty'),
  currency: Yup.string().required('validation.cant_be_empty'),
});

const BillingCyclesUploadModal = ({ onClose, onSuccess }) => {
  const polyglot = usePolyglot();
  const [pdfToUpload, setPdfToUpload] = useState(null);
  const [csvToUpload, setCsvToUpload] = useState(null);
  const { companyName } = useRouteMatch().params;
  const {
    state: { uploading },
    uploadBillingCycle,
  } = useContext(BillingCyclesState);
  const errorUploadAlert = useToggle();
  const { currencies } = useCurrency();

  const pdfName = pdfToUpload && pdfToUpload[0] && pdfToUpload[0].name;
  const csvName = csvToUpload && csvToUpload[0] && csvToUpload[0].name;
  const uploadFiles = async values => {
    const data = { companyName, ...values };
    try {
      await uploadBillingCycle(data, pdfToUpload, csvToUpload);
      onSuccess();
    } catch (error) {
      errorUploadAlert.setOn();
    }
  };
  return (
    <Modal closable onClose={onClose}>
      <ModalHeader title={polyglot.t('company.upload_billing_cycle')} />
      <Formik
        initialValues={{
          amount: '',
          periodStart: '',
          periodEnd: '',
          currency: 'EUR',
        }}
        validationSchema={BillingCycleSchema}
        onSubmit={values => {
          uploadFiles(values);
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, touched, errors }) => (
          <>
            <ModalSection>
              {errorUploadAlert.isOn && (
                <Stack spaceAfter="large">
                  <Alert type="critical" closable icon onClose={errorUploadAlert.setOff}>
                    {polyglot.t('company.error_billing_cycle_upload')}
                  </Alert>
                </Stack>
              )}
              <Stack direction="row" spaceAfter="large">
                <InputField
                  name="amount"
                  type="number"
                  label={polyglot.t('company.billing_cycle.amount')}
                  value={values.amount}
                  error={touched.amount && errors.amount && polyglot.t(errors.amount)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Select
                  name="currency"
                  label={polyglot.t('common.currency')}
                  value={values.currency}
                  error={touched.currency && errors.currency && polyglot.t(errors.currency)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={currencies.map(currency => ({
                    label: `${currency.code.toUpperCase()} (${currency.name})`,
                    value: currency.code.toUpperCase(),
                  }))}
                />
              </Stack>
              <Stack direction="row" spaceAfter="large">
                <InputDateContainer>
                  <DayPickerButton
                    name="periodStart"
                    label={polyglot.t('company.period_start')}
                    onSelect={value =>
                      setFieldValue('periodStart', formatInterval(value, datesConstants.DAY))
                    }
                    selectedDays={values.periodStart}
                    value={values.periodStart}
                    hideOnDayClick={true}
                  />
                  <ErrorMessage
                    name="periodStart"
                    render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
                  />
                </InputDateContainer>
                <InputDateContainer>
                  <DayPickerButton
                    name="periodEnd"
                    label={polyglot.t('company.period_end')}
                    onSelect={value =>
                      setFieldValue('periodEnd', formatInterval(value, datesConstants.DAY))
                    }
                    selectedDays={values.periodEnd}
                    value={values.periodEnd}
                    hideOnDayClick={true}
                  />
                  <ErrorMessage
                    name="periodEnd"
                    render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
                  />
                </InputDateContainer>
              </Stack>
              <Stack direction="row" spaceAfter="normal">
                <InputFile
                  fileName={pdfName}
                  buttonLabel={polyglot.t('company.choose_pdf')}
                  onRemoveFile={() => setPdfToUpload(null)}
                  allowedFileTypes={['.pdf']}
                  onChange={event => setPdfToUpload(event.target.files)}
                />
              </Stack>
              <Stack direction="row">
                <InputFile
                  fileName={csvName}
                  buttonLabel={polyglot.t('company.choose_csv')}
                  onRemoveFile={() => setCsvToUpload(null)}
                  allowedFileTypes={['.csv']}
                  onChange={event => setCsvToUpload(event.target.files)}
                />
              </Stack>
            </ModalSection>
            <ModalFooter>
              <Stack direction="row" justify="end">
                <Button width="138px" type="secondary" onClick={onClose} disabled={uploading}>
                  {polyglot.t('common.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={!R.isEmpty(errors)}
                  loading={uploading}
                  iconLeft={<UploadIcon />}
                >
                  {polyglot.t('company.upload')}
                </Button>
              </Stack>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default BillingCyclesUploadModal;
