import styled from 'styled-components';

const TableHead = styled.thead`
  tr {
    vertical-align: bottom;
    font-weight: 700;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.orbit.borderColorTableCell};

    td {
      padding-bottom: 5px;
    }
  }
`;

export default TableHead;
