import { useContext } from 'react';
import { MenuState } from 'components/services/menu/MenuProvider';

const useHideNavbarAndHeader = () => {
  const menuCtx = useContext(MenuState);

  const hideNavbarAndHeader = () => {
    menuCtx.hideNavigation();
    menuCtx.hideHeader();

    return () => {
      menuCtx.showNavigation();
      menuCtx.showHeader();
    };
  };

  return hideNavbarAndHeader;
};

export default useHideNavbarAndHeader;
