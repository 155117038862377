import { createGlobalStyle } from 'styled-components';

export const ReportMetricsGlobalStyles = createGlobalStyle`
  .metrics-autocomplete {
    min-width: initial;
  }

  .metrics-autocomplete-container {
    flex-wrap: wrap;
    align-items: unset;
    padding: 2px 0;
    margin-top: 0;
    overflow-x: hidden;
  }

  .metrics-text-input-wrapper {
    height: 32px;
    flex: 1;
  }

  .metrics-chips {
    margin: 2px 0;
  }

  .metrics-autocomplete-wrapper {
    height: unset;
  }

  .metrics-icon-classname {
    color: #f9971e;
  }
`;
