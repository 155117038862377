import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import MessagesIcon from '@kiwicom/orbit-components/lib/icons/Messages';

import { ROUTE_CONFIG } from 'consts/routes';
import { resourcesCodenames } from 'consts/kentico';

const CustomerSupport = ({ polyglot }) => (
  <Card>
    <CardSection
      icon={<MessagesIcon />}
      title={polyglot.t('common.customer_support')}
      description={polyglot.t('add_details.customer_support_subtitle')}
    />
    <CardSection>
      <Button
        external
        href={`${ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE.toPath}/${resourcesCodenames.CUSTOMER_SUPPORT_GUIDE}`}
        type="secondary"
        size="small"
      >
        {polyglot.t('add_details.learn_more')}
      </Button>
    </CardSection>
  </Card>
);

export default CustomerSupport;
