import styled, { createGlobalStyle } from 'styled-components';
import { RawCalendar } from 'common';

export const CompareCalendarGlobals = createGlobalStyle`
  .compare-calender-month {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  .compare-calendar-wrapper {
    display: flex;
    justify-content:space-between;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  & > p {
    margin-right: 15px;
  }
`;

export const CompareWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Calendar = styled(RawCalendar)`
  width: 920px;
  max-width: 100%;
  height: 350px;
`;

export const ActionsMenu = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;

  button:not(:last-of-type) {
    margin-right: 8px;
  }
`;
