import React, { useReducer } from 'react';

import api from 'utils/api';
import companySettingsMapper from 'mappers/nextGen/companies/{company_name}/settings/_';

export const CompanySettingsState = React.createContext({
  state: {},
  loadCompanySettings: companyName => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_COMPANY_SETTINGS':
      return {
        ...state,
        [action.companyName]: {
          loading: true,
          error: null,
          data: null,
        },
      };
    case 'GET_COMPANY_SETTINGS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          loading: false,
          error: null,
          data: action.data,
        },
      };
    case 'GET_COMPANY_SETTINGS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    case 'UPDATE_COMPANY_SETTINGS':
      return {
        [action.companyName]: {
          ...state[action.companyName],
          updating: true,
          updateError: null,
        },
      };
    case 'UPDATE_COMPANY_SETTINGS_SUCCESS':
      return {
        [action.companyName]: {
          ...state[action.companyName],
          updating: false,
          data: action.data,
        },
      };
    case 'UPDATE_COMPANY_SETTINGS_FAIL':
      return {
        [action.companyName]: {
          ...state[action.companyName],
          updating: false,
          updateError: action.error,
        },
      };
    default:
      return state;
  }
};

const initialState = {};

const CompanySettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadCompanySettings = async companyName => {
    dispatch({ type: 'GET_COMPANY_SETTINGS', companyName });

    try {
      const response = await api.v1.get(`companies/${companyName}/settings`);
      const data = companySettingsMapper.v1.get.from(response);

      dispatch({ type: 'GET_COMPANY_SETTINGS_SUCCESS', companyName, data });
    } catch (error) {
      dispatch({ type: 'GET_COMPANY_SETTINGS_FAIL', companyName, error });
    }
  };

  const enableIPWhitelisting = async (companyName, ips) => {
    dispatch({ type: 'UPDATE_COMPANY_SETTINGS', companyName });

    try {
      const response = await api.v1.put(`companies/${companyName}/settings`, {
        ip_whitelist_enabled: true,
        ...(ips ? { ip_whitelist: ips } : {}),
      });
      const data = companySettingsMapper.v1.put.from(response);

      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', companyName, data });

      return Promise.resolve();
    } catch (error) {
      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_FAIL', companyName, error });

      return Promise.reject(error);
    }
  };

  const disableIPWhitelisting = async companyName => {
    dispatch({ type: 'UPDATE_COMPANY_SETTINGS', companyName });

    try {
      const response = await api.v1.put(`companies/${companyName}/settings`, {
        ip_whitelist_enabled: false,
      });
      const data = companySettingsMapper.v1.put.from(response);

      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', companyName, data });
    } catch (error) {
      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_FAIL', companyName, error });
    }
  };

  const addIPAddressesToWhitelist = async (companyName, ips) => {
    dispatch({ type: 'UPDATE_COMPANY_SETTINGS', companyName });

    try {
      const response = await api.v1.put(`companies/${companyName}/settings`, {
        ip_whitelist: [...state[companyName].data.ipWhitelist, ...ips],
      });
      const data = companySettingsMapper.v1.put.from(response);

      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', companyName, data });
    } catch (error) {
      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_FAIL', companyName, error });

      return Promise.reject();
    }
  };

  const removeIPAddressFromWhitelisting = async (companyName, ip) => {
    dispatch({ type: 'UPDATE_COMPANY_SETTINGS', companyName });

    try {
      const response = await api.v1.put(`companies/${companyName}/settings`, {
        ip_whitelist: state[companyName].data.ipWhitelist.filter(i => i !== ip),
      });
      const data = companySettingsMapper.v1.put.from(response);

      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', companyName, data });
    } catch (error) {
      dispatch({ type: 'UPDATE_COMPANY_SETTINGS_FAIL', companyName, error });
    }
  };

  const value = {
    state,
    loadCompanySettings,
    addIPAddressesToWhitelist,
    enableIPWhitelisting,
    disableIPWhitelisting,
    removeIPAddressFromWhitelisting,
  };

  return <CompanySettingsState.Provider value={value}>{children}</CompanySettingsState.Provider>;
};

export default CompanySettingsProvider;
