import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Drawer from '@kiwicom/orbit-components/lib/Drawer';
import Box from '@kiwicom/orbit-components/lib/Box';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';

import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import useBooking from 'components/services/booking/useBooking';
import useSeating from 'components/services/bookingToolSeating/useSeating';

import { DrawerWrapper, StyledText, StyledTextRoute } from './index.styled';
import CloseModal from './components/CloseModal';
import SeatingDrawerContent from './components/SeatingDrawerContent';

const SeatingDrawer = ({ isDrawerOpen, closeDrawer }) => {
  const isCloseModalOpen = useToggle();
  const polyglot = usePolyglot();
  const {
    state: { tripType },
  } = useLocation();
  const {
    data: { flights },
  } = useBooking();
  const { seatings, currentSeatings } = useSeating();
  const [isFadeOutAnimation, setFadeOutAnimation] = useState(false);

  return (
    <DrawerWrapper isFadeOutAnimation={isFadeOutAnimation}>
      <Drawer
        suppressed
        noPadding
        actions={
          <ButtonLink
            type="secondary"
            onClick={
              JSON.stringify(seatings) !== JSON.stringify(currentSeatings)
                ? () => isCloseModalOpen.setOn()
                : () => {
                    setFadeOutAnimation(true);
                    setTimeout(() => {
                      closeDrawer();
                    }, 250);
                  }
            }
          >
            <CloseIcon />
          </ButtonLink>
        }
        shown={isDrawerOpen}
        width="calc(100% - 100px)"
        title={
          <Box display="flex" direction="row">
            <StyledText>{polyglot.t('booking_tool.seating.drawer.header')}</StyledText>
            <StyledText>|</StyledText>
            <StyledTextRoute>
              {flights?.[0][0].departure.place.city}
              {tripType.value === 'Return' ? <FlightReturnIcon /> : <FlightDirectIcon />}
              {flights?.[0][flights[0].length - 1].arrival.place.city}
            </StyledTextRoute>
          </Box>
        }
      >
        {isCloseModalOpen.isOn && (
          <CloseModal
            closeModal={isCloseModalOpen.setOff}
            closeDrawer={() => {
              setFadeOutAnimation(true);
              setTimeout(() => {
                closeDrawer();
              }, 250);
            }}
          />
        )}
        <SeatingDrawerContent
          selectedRouteId={isDrawerOpen}
          closeDrawer={() => {
            setFadeOutAnimation(true);
            setTimeout(() => {
              closeDrawer();
            }, 250);
          }}
          openCloseModal={isCloseModalOpen.setOn}
        />
      </Drawer>
    </DrawerWrapper>
  );
};

export default SeatingDrawer;
