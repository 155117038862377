import { retry } from 'utils/functions';

const imports = {
  au: () => retry(() => import(/* webpackChunkName: 'locale-au' */ 'date-fns/locale/en-AU')),
  ca: () => retry(() => import(/* webpackChunkName: 'locale-ca' */ 'date-fns/locale/en-CA')),
  gb: () => retry(() => import(/* webpackChunkName: 'locale-gb' */ 'date-fns/locale/en-GB')),
  us: () => retry(() => import(/* webpackChunkName: 'locale-us' */ 'date-fns/locale/en-US')),
  es: () => retry(() => import(/* webpackChunkName: 'locale-es' */ 'date-fns/locale/es')),
};

export const locales = {
  en: imports.gb,
  au: imports.au,
  ca: imports.ca,
  hk: imports.us,
  in: imports.us,
  ie: imports.gb,
  my: imports.us,
  nz: imports.au,
  sg: imports.us,
  za: imports.us,
  ph: imports.us,
  us: imports.us,
  es: imports.es,
  ag: imports.es,
  cl: imports.es,
  co: imports.es,
  ec: imports.es,
  mx: imports.es,
  pe: imports.es,
};

export const supportedLocales = Object.keys(locales);
