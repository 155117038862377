import Settings from '@kiwicom/orbit-components/lib/icons/Settings';
import Profit from '@kiwicom/orbit-components/lib/icons/Profit';
import Money from '@kiwicom/orbit-components/lib/icons/Money';
import Partners from '@kiwicom/orbit-components/lib/icons/Partners';
import Document from '@kiwicom/orbit-components/lib/icons/Document';
import FamilyHalf from '@kiwicom/orbit-components/lib/icons/FamilyHalf';

const HELP_REQUEST_CATEGORIES = {
  TECHNICAL: 'technical',
  PRODUCT_CONTENT: 'productcontent',
  FINANCE: 'finance',
  BUSINESS: 'business',
  REPORTING: 'reporting',
  TEQUILA: 'tequila',
};

export const HELP_REQUEST_TYPES = {
  [HELP_REQUEST_CATEGORIES.TECHNICAL]: [
    'Search API',
    'Book API',
    'Deeplink & Banners',
    'Widgets',
    'White-labels',
    'Other (Technical)',
  ],
  [HELP_REQUEST_CATEGORIES.PRODUCT_CONTENT]: [
    'Missing Content',
    'Other (Product Content)',
    'Bookability Issue',
    'Content Pricing',
  ],
  [HELP_REQUEST_CATEGORIES.FINANCE]: [
    'Other (Finance)',
    'Billing / Invoicing',
    'Payment',
    'Deposit',
    'Proforma invoice request',
  ],
  [HELP_REQUEST_CATEGORIES.BUSINESS]: [
    'Commercial',
    'Product',
    'Legal',
    'Marketing',
    'Other (Business)',
    'Smart Pass',
  ],
  [HELP_REQUEST_CATEGORIES.REPORTING]: [
    'Missing Data',
    'Building Report',
    'Technical Issue (Reporting)',
    'New Report Request',
    'Other (Reporting)',
  ],
  [HELP_REQUEST_CATEGORIES.TEQUILA]: [
    'Tequila Portal - Technical Issue',
    'Tequila Portal - Request a new feature',
    'Tequila Portal - Question',
  ],
};

export const HELP_ITEMS = [
  {
    name: HELP_REQUEST_CATEGORIES.TECHNICAL,
    icon: <Settings />,
  },
  {
    name: HELP_REQUEST_CATEGORIES.PRODUCT_CONTENT,
    icon: <Profit />,
  },
  {
    name: HELP_REQUEST_CATEGORIES.FINANCE,
    icon: <Money />,
  },
  {
    name: HELP_REQUEST_CATEGORIES.BUSINESS,
    icon: <Partners />,
  },
  {
    name: HELP_REQUEST_CATEGORIES.REPORTING,
    icon: <Document />,
  },
  {
    name: HELP_REQUEST_CATEGORIES.TEQUILA,
    icon: <FamilyHalf />,
  },
];

export const STATUS_OPTIONS = [
  {
    label: 'support.status.waiting_for_support',
    value: 'WAITING_SUPPORT',
  },
  {
    label: 'support.status.pending',
    value: 'PENDING',
  },
  {
    label: 'support.status.in_progress',
    value: 'IN_PROGRESS',
  },
  {
    label: 'support.status.waiting_for_customer',
    value: 'WAITING_CUSTOMER',
  },
  {
    label: 'support.status.closed',
    value: 'CLOSED',
  },
];

export const STATUS = {
  WAITING_FOR_SUPPORT: 'Waiting for Support',
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  WAITING_FOR_CUSTOMER: 'Waiting for Customer',
  CLOSED: 'Closed',
};
