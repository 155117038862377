import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import useCurrentSolutionDetails from 'components/services/solutions/useCurrentSolutionDetails';
import { SolutionsState } from 'components/services/solutions';
import { AuthState } from 'components/services/auth/AuthProvider';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import useToasts from 'components/services/toasts/useToasts';

import QuotaInput from './QuotaInput';

const SolutionsSchema = Yup.object().shape({
  webhookStaging: Yup.string().url('validation.must_be_uri').max(255, 'validation.length_exceeded'),
  webhookProduction: Yup.string()
    .url('validation.must_be_uri')
    .max(255, 'validation.length_exceeded'),
});

const timeUnitOptions = ['minute', 'hour', 'day'];

const SolutionsSchemaWithQuota = Yup.object().shape({
  webhookStaging: Yup.string().url('validation.must_be_uri').max(255, 'validation.length_exceeded'),
  webhookProduction: Yup.string()
    .url('validation.must_be_uri')
    .max(255, 'validation.length_exceeded'),
  quota: Yup.number()
    .min(1, 'solutions.quota.quota_cant_be_smaller_than_zero')
    .max(1e50, 'solutions.quota.input_too_large')
    .integer('solutions.quota.no_decimals')
    .required('solutions.quota.quota_required'),
  quotaInterval: Yup.number()
    .min(1, 'solutions.quota.interval_cant_be_smaller_than_zero')
    .max(1e50, 'solutions.quota.input_too_large')
    .integer('solutions.quota.no_decimals')
    .required('solutions.quota.interval_required'),
  quotaTimeUnit: Yup.mixed().oneOf(timeUnitOptions),
});

const SolutionDetailsForm = ({ onClose, onSuccess, product, canEditQuota }) => {
  const {
    editSolution,
    singleAppsState: { editing },
  } = useContext(SolutionsState);
  const { checkGrantProperties } = useContext(AuthState);

  const polyglot = usePolyglot();
  const solution = useCurrentSolutionDetails();
  const companyName = useCurrentCompanyName();
  const { addErrorToast } = useToasts();
  const canWebhookProduction = checkGrantProperties(
    'model.application',
    'update',
    'webhook_production',
    companyName,
  );
  const canWebhookStaging = checkGrantProperties(
    'model.application',
    'update',
    'webhook_staging',
    companyName,
  );
  const quotaConfig = solution.data.quotaConfig || product.quotaConfig;

  const handleSubmitDetails = async values => {
    try {
      await editSolution(solution.data, values, canEditQuota);
      onSuccess();
    } catch (error) {
      addErrorToast(error.message);
    }
  };

  return (
    <>
      <Modal onClose={onClose}>
        <ModalHeader title={polyglot.t('solutions.edit_details')} />
        <Formik
          initialValues={{
            webhookStaging: solution.data.webhookStaging || '',
            webhookProduction: solution.data.webhookProduction || '',
            ...quotaConfig,
          }}
          validationSchema={canEditQuota ? SolutionsSchemaWithQuota : SolutionsSchema}
          onSubmit={values => {
            handleSubmitDetails(values, product);
          }}
        >
          {({ handleChange, handleSubmit, touched, errors, values }) => (
            <>
              <ModalSection>
                <Stack spacing="medium">
                  <Stack direction="row" spacing="XSmall">
                    <InputField
                      type="text"
                      onChange={handleChange}
                      label={polyglot.t('solution.webhook_endpoint_production')}
                      value={values.webhookProduction}
                      disabled={!canWebhookProduction}
                      name="webhookProduction"
                      error={
                        touched.webhookProduction &&
                        errors.webhookProduction &&
                        polyglot.t(errors.webhookProduction)
                      }
                    />

                    <InputField
                      type="text"
                      onChange={handleChange}
                      label={polyglot.t('solution.webhook_endpoint_staging')}
                      value={values.webhookStaging}
                      disabled={!canWebhookStaging}
                      name="webhookStaging"
                      error={
                        touched.webhookStaging &&
                        errors.webhookStaging &&
                        polyglot.t(errors.webhookStaging)
                      }
                    />
                  </Stack>

                  {canEditQuota && (
                    <QuotaInput
                      quota={values.quota}
                      interval={values.quotaInterval}
                      timeUnit={values.quotaTimeUnit}
                      onChange={handleChange}
                      timeUnitOptions={timeUnitOptions}
                      polyglot={polyglot}
                      touched={touched}
                      errors={errors}
                    />
                  )}
                </Stack>
              </ModalSection>
              <ModalFooter>
                <Button type="secondary" width="128px" onClick={onClose}>
                  {polyglot.t('common.cancel')}
                </Button>
                <Button type="primary" width="128px" onClick={handleSubmit} loading={editing}>
                  {polyglot.t('common.confirm')}
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SolutionDetailsForm;
