import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { useCurrency } from 'components/services/currencies';
import { usePolyglot } from 'components/services/i18n';
import { getSingleSegmentPriceSum } from 'utils/seating';
import { precisionRound } from 'utils/number';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

const SeatingPrice = ({ routeId }) => {
  const { getFormattedPriceString } = useCurrency();
  const { seatings } = useShoppingBasket();
  const polyglot = usePolyglot();
  const segment = seatings[routeId];

  if (!segment) {
    return (
      <Text type="secondary">
        {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.group.button.select.active')}
      </Text>
    );
  } else if (segment && segment.option && !segment.price) {
    return (
      <Text>
        {polyglot.t('tequila.ancillary_seating.mmb.drawer.no_seatmap.quick_option.included')}
      </Text>
    );
  } else if (segment && segment.option && segment.price) {
    return <Text>{getFormattedPriceString(precisionRound(segment.price.amount, 2))}</Text>;
  }

  return <Text>{getFormattedPriceString(getSingleSegmentPriceSum(segment))}</Text>;
};

export default SeatingPrice;
