import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';

import { SERVICE_DESK } from 'consts/support';
import CreateIssueModal from 'components/scenes/CustomerSupport/scenes/NewRequest/components/CreateIssueModal';
import useHelp from 'components/services/help/useHelp';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  border-radius: 3px;
  padding: 16px 12px;
  margin: 5px;
  width: 252px;
  margin-bottom: 12px;
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow 150ms ease-in-out;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(37, 42, 49, 0.12), 0px 1px 4px 0px rgba(37, 42, 49, 0.16);
  }
`;

const RequestCategory = ({ requestCategory, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Text weight="bold" spaceAfter="smallest">
        {requestCategory.name}
      </Text>
      <Text>{requestCategory.description}</Text>
    </Container>
  );
};

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CreateRequestModal = ({ bid, open, onClose }) => {
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const { getRequestTypes, requestTypes, getIssues } = useHelp();

  useEffect(() => {
    getRequestTypes(SERVICE_DESK.CSSD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClose();
    setSelectedRequestType(null);
    getIssues({ project: SERVICE_DESK.CSSD.toUpperCase(), limit: 20, bid });
  };

  if (selectedRequestType) {
    return <CreateIssueModal requestType={selectedRequestType} bid={+bid} onClose={handleClose} />;
  }

  if (!requestTypes?.[SERVICE_DESK.CSSD] || !open) {
    return null;
  }

  return (
    <Modal size="large" onClose={handleClose}>
      <ModalHeader title="Customer support" />
      <ModalSection>
        <CategoriesContainer>
          {requestTypes?.[SERVICE_DESK.CSSD]?.data?.map(reqType => (
            <RequestCategory
              key={reqType.id}
              requestCategory={reqType}
              onClick={() => setSelectedRequestType(reqType)}
            />
          ))}
        </CategoriesContainer>
      </ModalSection>
    </Modal>
  );
};

export default CreateRequestModal;
