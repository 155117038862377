import React, { useCallback, useState } from 'react';

import Popover from '@kiwicom/orbit-components/lib/Popover';
import NewSeatIcon from '@kiwicom/orbit-components/lib/Seat';

import useSeating from 'components/services/bookingToolSeating/useSeating';
import { OPTION_ORBIT_MAP_NEW, SEAT_FORMATED_TYPES } from 'consts/seating';
import { useCurrency } from 'components/services/currencies';
import { precisionRound } from 'utils/number';

import SeatPopover from './SeatPopover/SeatPopover';
import SeatPopoverActions from './SeatPopover/SeatPopoverActions';

const Seat = ({ seat, activeSegmentCode }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const { currentSeatings, removeSeatingSeatMap, addSeatingSeatMap } = useSeating();
  const { getFormattedPriceString } = useCurrency();

  const isSeatOccupied = currentSeatings[activeSegmentCode]?.[seat.name];
  const passengerInitialsOnThisSeat =
    isSeatOccupied &&
    currentSeatings[activeSegmentCode][seat.name].passenger.firstName[0].toUpperCase() +
      currentSeatings[activeSegmentCode][seat.name].passenger.lastName[0].toUpperCase();

  const handleSelectSeat = useCallback(
    (seatName, passenger) => {
      addSeatingSeatMap(
        activeSegmentCode,
        {
          ...seat,
          ...passenger,
        },
        seatName,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSegmentCode],
  );

  const handleDeselectSeat = useCallback(
    seatName => {
      removeSeatingSeatMap(activeSegmentCode, seatName);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeSegmentCode],
  );

  const handleSeatClick = () => {
    if (isSeatOccupied) {
      handleDeselectSeat(seat.name); // deselect seat
      return;
    }
    setPopoverActive(true);
  };

  const handleClosePopover = useCallback(() => setPopoverActive(false), []);

  if (seat.type === SEAT_FORMATED_TYPES.UNAVAILABLE) {
    return <NewSeatIcon type="unavailable" price="-" label />;
  }

  return (
    <Popover
      placement="bottom-end"
      content={
        <SeatPopover
          activeSegmentCode={activeSegmentCode}
          seat={seat}
          onSelectSeat={handleSelectSeat}
          onPopoverClose={handleClosePopover}
          isEmergencyExit={seat.isEmergencyExit}
        />
      }
      actions={
        <SeatPopoverActions
          seatName={seat.name}
          onClose={handleClosePopover}
          onSelectSeat={handleSelectSeat}
        />
      }
      opened={popoverActive}
      onClose={handleClosePopover}
      onOpen={handleSeatClick}
      noPadding
      renderInPortal={false}
    >
      <NewSeatIcon
        type={OPTION_ORBIT_MAP_NEW[seat.type]}
        price={getFormattedPriceString(precisionRound(seat.price.amount, 0))}
        label={passengerInitialsOnThisSeat || (seat.isEmergencyExit && '*')}
        selected={isSeatOccupied}
      />
    </Popover>
  );
};

export default Seat;
