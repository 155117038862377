import React from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Text from '@kiwicom/orbit-components/lib/Text';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import { propsComparison } from 'utils/propsComparison';
import { ErrorMessageComponent } from 'common';
import { IconSuffix } from 'common';
import {
  keys,
  illustrationValues,
  displayingConditionValues,
  generalFormKeys,
} from 'consts/smartPass';

import FileInput from '../FileInput';
import { StackDiv, InputFieldWidthLimiter, LabelWithTextInput } from '../index.styled';
import {
  IllustrationsWrapper,
  IllustrationsRadioWrapper,
  ColorsWidthLimiter,
} from './General.styled';

import Illustration1 from './illustrations/Illustration1';
import Illustration2 from './illustrations/Illustration2';
import Illustration3 from './illustrations/Illustration3';
import Illustration4 from './illustrations/Illustration4';
import Illustration5 from './illustrations/Illustration5';

const {
  IATA_CODE,
  AIRPORT_LOGO,
  SMART_PASS_NAME,
  SMART_PASS_LOGO,
  SMART_PASS_PAGE,
  ILLUSTRATION,
  ILLUSTRATION_COLOR_1,
  ILLUSTRATION_COLOR_2,
  DISPLAYING_CONDITION,
  DISPLAYING_CONDITION_HOURS,
  REPORTING_EMAIL_1,
  REPORTING_EMAIL_2,
  REPORTING_EMAIL_3,
  REPORTING_EMAIL_4,
  AIRPORT_SIGNAGE_1,
  AIRPORT_SIGNAGE_2,
  AIRPORT_SIGNAGE_3,
  TERMINAL_MAPS_1,
  TERMINAL_MAPS_2,
  TERMINAL_MAPS_3,
} = keys;

const General = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  disabled,
  companyName,
  errors,
  touched,
}) => {
  const polyglot = usePolyglot();

  const handleColorChange = e => {
    setFieldValue(e.target.name, e.target.value.toUpperCase());
  };

  const parseColor = color => {
    if (!color) {
      return null;
    }

    return color.startsWith('#') ? color : `#${color}`;
  };

  return (
    <Card
      spaceAfter="largest"
      header={
        <Heading as="h2" type="title3">
          {polyglot.t('smart_pass.form.general.header_title')}
        </Heading>
      }
    >
      <CardSection>
        <Stack direction="row" spaceAfter="largest" align="end">
          <InputField
            label={polyglot.t('smart_pass.form.general.iata_code')}
            value={values[IATA_CODE]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={IATA_CODE}
            disabled={disabled}
            error={touched[IATA_CODE] && errors[IATA_CODE] && polyglot.t(errors[IATA_CODE])}
          />
          <FileInput
            name={AIRPORT_LOGO}
            setFieldValue={setFieldValue}
            value={values[AIRPORT_LOGO]}
            label={polyglot.t('smart_pass.form.general.airport_logo')}
            help={polyglot.t('smart_pass.form.general.attachment_help')}
            disabled={disabled}
            maxImageWidth={224}
            maxImageHeight={64}
            companyName={companyName}
            error={
              touched[AIRPORT_LOGO] && errors[AIRPORT_LOGO] && polyglot.t(errors[AIRPORT_LOGO])
            }
          />
        </Stack>
        <Separator spaceAfter="large" />
        <Stack direction="row" spaceAfter="largest" align="end">
          <InputField
            value={values[SMART_PASS_NAME]}
            name={SMART_PASS_NAME}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.general.smart_pass_name')}
            disabled={disabled}
            error={
              touched[SMART_PASS_NAME] &&
              errors[SMART_PASS_NAME] &&
              polyglot.t(errors[SMART_PASS_NAME])
            }
            suffix={
              <Tooltip content={polyglot.t('smart_pass.form.general.smart_pass_name_tooltip')}>
                <IconSuffix>
                  <InformationCircleIcon />
                </IconSuffix>
              </Tooltip>
            }
          />
          <FileInput
            name={SMART_PASS_LOGO}
            setFieldValue={setFieldValue}
            value={values[SMART_PASS_LOGO]}
            label={polyglot.t('smart_pass.form.general.smart_pass_logo')}
            help={polyglot.t('smart_pass.form.general.attachment_help')}
            disabled={disabled}
            maxImageWidth={224}
            maxImageHeight={64}
            companyName={companyName}
          />
        </Stack>
        <Stack direction="row" spaceAfter="large">
          <InputField
            value={values[SMART_PASS_PAGE]}
            name={SMART_PASS_PAGE}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.general.smart_pass_page')}
            placeholder={polyglot.t('smart_pass.form.general.smart_pass_page_placeholder')}
            disabled={disabled}
          />
          <StackDiv />
        </Stack>
        <Separator spaceAfter="large" />
        <Text spaceAfter="large">{polyglot.t('smart_pass.form.general.illustrations')}</Text>
        <IllustrationsWrapper>
          <IllustrationsRadioWrapper>
            <Illustration1
              color1={parseColor(values[ILLUSTRATION_COLOR_1]) || '#FFF'}
              color2={parseColor(values[ILLUSTRATION_COLOR_2]) || '#000'}
            />
            <Radio
              name={ILLUSTRATION}
              checked={values[ILLUSTRATION] === illustrationValues.FIRST}
              value={illustrationValues.FIRST}
              onChange={handleChange}
              label={polyglot.t('smart_pass.form.general.illustration_number', { number: 1 })}
              disabled={disabled}
              hasError={touched[ILLUSTRATION] && errors[ILLUSTRATION]}
            />
          </IllustrationsRadioWrapper>
          <IllustrationsRadioWrapper>
            <Illustration2
              color1={parseColor(values[ILLUSTRATION_COLOR_1]) || '#FFF'}
              color2={parseColor(values[ILLUSTRATION_COLOR_2]) || '#000'}
            />
            <Radio
              name={ILLUSTRATION}
              checked={values[ILLUSTRATION] === illustrationValues.SECOND}
              value={illustrationValues.SECOND}
              onChange={handleChange}
              label={polyglot.t('smart_pass.form.general.illustration_number', { number: 2 })}
              disabled={disabled}
              hasError={touched[ILLUSTRATION] && errors[ILLUSTRATION]}
            />
          </IllustrationsRadioWrapper>
          <IllustrationsRadioWrapper>
            <Illustration3
              color1={parseColor(values[ILLUSTRATION_COLOR_1]) || '#FFF'}
              color2={parseColor(values[ILLUSTRATION_COLOR_2]) || '#000'}
            />
            <Radio
              name={ILLUSTRATION}
              checked={values[ILLUSTRATION] === illustrationValues.THIRD}
              value={illustrationValues.THIRD}
              onChange={handleChange}
              label={polyglot.t('smart_pass.form.general.illustration_number', { number: 3 })}
              disabled={disabled}
              hasError={touched[ILLUSTRATION] && errors[ILLUSTRATION]}
            />
          </IllustrationsRadioWrapper>
          <IllustrationsRadioWrapper>
            <Illustration4
              color1={parseColor(values[ILLUSTRATION_COLOR_1]) || '#FFF'}
              color2={parseColor(values[ILLUSTRATION_COLOR_2]) || '#000'}
            />
            <Radio
              name={ILLUSTRATION}
              checked={values[ILLUSTRATION] === illustrationValues.FOURTH}
              value={illustrationValues.FOURTH}
              onChange={handleChange}
              label={polyglot.t('smart_pass.form.general.illustration_number', { number: 4 })}
              disabled={disabled}
              hasError={touched[ILLUSTRATION] && errors[ILLUSTRATION]}
            />
          </IllustrationsRadioWrapper>
          <IllustrationsRadioWrapper>
            <Illustration5
              color1={parseColor(values[ILLUSTRATION_COLOR_1]) || '#FFF'}
              color2={parseColor(values[ILLUSTRATION_COLOR_2]) || '#000'}
            />
            <Radio
              name={ILLUSTRATION}
              checked={values[ILLUSTRATION] === illustrationValues.FIFTH}
              value={illustrationValues.FIFTH}
              onChange={handleChange}
              label={polyglot.t('smart_pass.form.general.illustration_number', { number: 5 })}
              disabled={disabled}
              hasError={touched[ILLUSTRATION] && errors[ILLUSTRATION]}
            />
          </IllustrationsRadioWrapper>
        </IllustrationsWrapper>
        <Stack direction="row" spaceAfter="large" align="center">
          <Text>{polyglot.t('smart_pass.form.general.illustration_colors')}</Text>
          <ColorsWidthLimiter>
            <InputField
              name={ILLUSTRATION_COLOR_1}
              value={values[ILLUSTRATION_COLOR_1]}
              onChange={handleColorChange}
              onBlur={handleBlur}
              inlineLabel
              size="small"
              placeholder={polyglot.t('smart_pass.form.general.illustration_colors_placeholder')}
              label={polyglot.t('smart_pass.form.general.illustration_colors_1_label')}
              disabled={disabled}
            />
          </ColorsWidthLimiter>
          <ColorsWidthLimiter>
            <InputField
              name={ILLUSTRATION_COLOR_2}
              value={values[ILLUSTRATION_COLOR_2]}
              onChange={handleColorChange}
              onBlur={handleBlur}
              inlineLabel
              size="small"
              placeholder={polyglot.t('smart_pass.form.general.illustration_colors_placeholder')}
              label={polyglot.t('smart_pass.form.general.illustration_colors_2_label')}
              disabled={disabled}
            />
          </ColorsWidthLimiter>
        </Stack>
        <Separator spaceAfter="large" />
        <Text spaceAfter="small">{polyglot.t('smart_pass.form.general.condition_text')}</Text>
        <Stack spaceAfter="large">
          <Radio
            label={polyglot.t('smart_pass.form.general.condition_text_radio_1')}
            name={DISPLAYING_CONDITION}
            checked={values[DISPLAYING_CONDITION] === displayingConditionValues.NO_CONDITION}
            value={displayingConditionValues.NO_CONDITION}
            onChange={handleChange}
            disabled={disabled}
            hasError={touched[DISPLAYING_CONDITION] && errors[DISPLAYING_CONDITION]}
          />
          <Radio
            name={DISPLAYING_CONDITION}
            checked={values[DISPLAYING_CONDITION] === displayingConditionValues.CONDITION}
            value={displayingConditionValues.CONDITION}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            hasError={touched[DISPLAYING_CONDITION] && errors[DISPLAYING_CONDITION]}
            label={
              <LabelWithTextInput>
                <Text>{polyglot.t('smart_pass.form.general.condition_text_radio_2_1')}</Text>
                <InputFieldWidthLimiter>
                  <InputField
                    disabled={
                      disabled ||
                      values[DISPLAYING_CONDITION] !== displayingConditionValues.CONDITION
                    }
                    name={DISPLAYING_CONDITION_HOURS}
                    value={values[DISPLAYING_CONDITION_HOURS]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    placeholder={polyglot.t('smart_pass.form.add_hours')}
                  />
                </InputFieldWidthLimiter>
                <Text>{polyglot.t('smart_pass.form.general.condition_text_radio_2_2')}</Text>
              </LabelWithTextInput>
            }
          />
          {touched[DISPLAYING_CONDITION_HOURS] && errors[DISPLAYING_CONDITION_HOURS] && (
            <ErrorMessageComponent>
              {polyglot.t(errors[DISPLAYING_CONDITION_HOURS])}
            </ErrorMessageComponent>
          )}
        </Stack>
        <Separator spaceAfter="medium" />
        <Text spaceAfter="smallest">{polyglot.t('smart_pass.form.general.reporting')}</Text>
        <Text spaceAfter="medium" type="secondary">
          {polyglot.t('smart_pass.form.general.reporting_descr')}
        </Text>
        <Stack direction="row" spaceAfter="large">
          <InputField
            value={values[REPORTING_EMAIL_1]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={REPORTING_EMAIL_1}
            inlineLabel
            label={polyglot.t('smart_pass.form.general.reporting_email')}
            placeholder={polyglot.t('smart_pass.form.general.reporting_email_placeholder')}
            disabled={disabled}
            error={
              touched[REPORTING_EMAIL_1] &&
              errors[REPORTING_EMAIL_1] &&
              polyglot.t(errors[REPORTING_EMAIL_1])
            }
          />
          <InputField
            value={values[REPORTING_EMAIL_2]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={REPORTING_EMAIL_2}
            inlineLabel
            label={polyglot.t('smart_pass.form.general.reporting_email')}
            placeholder={polyglot.t('smart_pass.form.general.reporting_email_placeholder')}
            disabled={disabled}
            error={
              touched[REPORTING_EMAIL_2] &&
              errors[REPORTING_EMAIL_2] &&
              polyglot.t(errors[REPORTING_EMAIL_2])
            }
          />
        </Stack>
        <Stack direction="row" spaceAfter="large">
          <InputField
            value={values[REPORTING_EMAIL_3]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={REPORTING_EMAIL_3}
            inlineLabel
            label={polyglot.t('smart_pass.form.general.reporting_email')}
            placeholder={polyglot.t('smart_pass.form.general.reporting_email_placeholder')}
            disabled={disabled}
            error={
              touched[REPORTING_EMAIL_3] &&
              errors[REPORTING_EMAIL_3] &&
              polyglot.t(errors[REPORTING_EMAIL_3])
            }
          />
          <InputField
            value={values[REPORTING_EMAIL_4]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={REPORTING_EMAIL_4}
            inlineLabel
            label={polyglot.t('smart_pass.form.general.reporting_email')}
            placeholder={polyglot.t('smart_pass.form.general.reporting_email_placeholder')}
            disabled={disabled}
            error={
              touched[REPORTING_EMAIL_4] &&
              errors[REPORTING_EMAIL_4] &&
              polyglot.t(errors[REPORTING_EMAIL_4])
            }
          />
        </Stack>
        <Separator spaceAfter="large" />
        <Text spaceAfter="small">
          {polyglot.t('smart_pass.form.general.airport_signage_terminal_maps')}
        </Text>
        <Text type="secondary" spaceAfter="large">
          {polyglot.t('smart_pass.form.general.airport_signage_terminal_maps_descr')}
        </Text>
        <Stack direction="row" spaceAfter="medium">
          <StackDiv>
            <Text spaceAfter="smallest">
              {polyglot.t('smart_pass.form.general.airport_signage')}
            </Text>
            <FileInput
              name={AIRPORT_SIGNAGE_1}
              setFieldValue={setFieldValue}
              value={values[AIRPORT_SIGNAGE_1]}
              spaceAfter="medium"
              disabled={disabled}
              maxImageWidth={152}
              companyName={companyName}
            />
            <FileInput
              name={AIRPORT_SIGNAGE_2}
              setFieldValue={setFieldValue}
              value={values[AIRPORT_SIGNAGE_2]}
              spaceAfter="medium"
              disabled={disabled}
              maxImageWidth={152}
              companyName={companyName}
            />
            <FileInput
              name={AIRPORT_SIGNAGE_3}
              setFieldValue={setFieldValue}
              value={values[AIRPORT_SIGNAGE_3]}
              spaceAfter="smallest"
              disabled={disabled}
              maxImageWidth={152}
              companyName={companyName}
            />
            <Text size="small" type="secondary">
              {polyglot.t('smart_pass.form.general.airport_signage_help')}
            </Text>
          </StackDiv>
          <StackDiv>
            <Text spaceAfter="smallest">{polyglot.t('smart_pass.form.general.terminal_maps')}</Text>
            <FileInput
              name={TERMINAL_MAPS_1}
              setFieldValue={setFieldValue}
              value={values[TERMINAL_MAPS_1]}
              spaceAfter="medium"
              disabled={disabled}
              exactImageWidth={610}
              companyName={companyName}
            />
            <FileInput
              name={TERMINAL_MAPS_2}
              setFieldValue={setFieldValue}
              value={values[TERMINAL_MAPS_2]}
              spaceAfter="medium"
              disabled={disabled}
              exactImageWidth={610}
              companyName={companyName}
            />
            <FileInput
              name={TERMINAL_MAPS_3}
              setFieldValue={setFieldValue}
              value={values[TERMINAL_MAPS_3]}
              spaceAfter="smallest"
              disabled={disabled}
              exactImageWidth={610}
              companyName={companyName}
            />
            <Text size="small" type="secondary">
              {polyglot.t('smart_pass.form.general.terminal_maps_help')}
            </Text>
          </StackDiv>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default React.memo(General, propsComparison(generalFormKeys));
