import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import useMMB from 'components/services/mmb/useMMB';
import { generateButtonType, groupSeatsSeatingTable } from 'utils/seating';
import { usePolyglot } from 'components/services/i18n';

import SeatingSegment from './components/SeatingSegment';
import SeatingDrawer from '../SeatingDrawer';

const SeatingSummaryTable = () => {
  const { bid } = useParams();
  const {
    bookingsState,
    ancillariesOffers: { data },
  } = useMMB();
  const polyglot = usePolyglot();
  const [routeId, setRouteId] = useState(null);
  const {
    bookingData: {
      displayStatus,
      ancillaries: { seating },
      segments,
    },
    passengers,
  } = bookingsState[bid];

  const bookingItems = useMemo(() => {
    let groupedBookingItems = {};

    seating.forEach(seatingItem => {
      seatingItem.items.forEach(item => {
        groupedBookingItems = {
          ...groupedBookingItems,
          [item.segmentCode]: {
            ...(groupedBookingItems?.[item.segmentCode] || {}),
            [item.option]: {
              ...(groupedBookingItems?.[item.segmentCode]?.[item.option] || {}),
              [seatingItem.source]: {
                seats: [
                  ...(groupedBookingItems?.[item.segmentCode]?.[item.option]?.[seatingItem.source]
                    ?.seats || []),
                  ...item.seats,
                ],
                status: seatingItem.status,
              },
            },
          },
        };
      });
    });

    return groupedBookingItems;
  }, [seating]);

  return (
    <>
      {routeId && <SeatingDrawer closeDrawer={() => setRouteId(null)} isDrawerOpen={routeId} />}
      <Stack direction="column">
        {Object.values(segments).map(segment => (
          <>
            <Text weight="bold" spaceAfter="medium">
              {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.to_place.title', {
                location: segment[segment.length - 1].arrival.place.city,
              })}
            </Text>
            {segment.map(({ segmentCode, departure, arrival, carrier }) => (
              <SeatingSegment
                key={segmentCode}
                id={segmentCode}
                cityFrom={departure.place.city}
                cityTo={arrival.place.city}
                airline={carrier.code}
                isDisabledToClick={displayStatus !== 'confirmed'}
                disabled={
                  !data.find(offer => offer.segmentCode === segmentCode) &&
                  !bookingItems[segmentCode]
                }
                buttonType={
                  !!data.find(offer => offer.segmentCode === segmentCode) &&
                  generateButtonType(
                    bookingItems[segmentCode],
                    passengers.length,
                    segmentCode,
                    data,
                  )
                }
                groupedSeats={groupSeatsSeatingTable(bookingItems[segmentCode])}
                onOpenDrawer={setRouteId}
              />
            ))}
          </>
        ))}
      </Stack>
    </>
  );
};

export default SeatingSummaryTable;
