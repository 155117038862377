import React, { useState } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Table, {
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { usePolyglot } from 'components/services/i18n';

import Pagination from './Pagination';
import { useAuditLogs } from '../services/AuditLogsProvider';
import DetailsModal from './DetailsModal';

const Container = styled.div`
  padding: 24px;
`;

const ErrorContainer = styled.div`
  padding: 32px;
  text-align: center;
  & p {
    text-align: center;
  }
`;

const TryAgain = styled.div`
  color: ${({ theme }) => theme.orbit.colorTextLinkPrimary};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.orbit.colorTextLinkPrimaryHover};
    text-decoration: none;
  }
`;

const loadingTextMessage = loadCount => {
  switch (loadCount) {
    case 1:
      return 'audit_logs.table.loading_1';
    case 2:
      return 'audit_logs.table.loading_2';
    case 3:
      return 'audit_logs.table.loading_3';
    case 4:
      return 'audit_logs.table.loading_4';
    case 5:
      return 'audit_logs.table.loading_5';
    default:
      return 'audit_logs.table.loading_1';
  }
};

const TableComponent = () => {
  const polyglot = usePolyglot();
  const { data, loading, error, loadInitialLogs, loadCount } = useAuditLogs();
  const [openLog, setOpenLog] = useState(null);

  if (error) {
    return (
      <ErrorContainer>
        <Illustration name="Error" />
        <Text weight="bold">{polyglot.t('audit_logs.table.failed_to_load')}</Text>
        <TryAgain onClick={() => loadInitialLogs()}>
          {polyglot.t('audit_logs.table.try_again')}
        </TryAgain>
      </ErrorContainer>
    );
  }

  if (loading) {
    return <Loading type="boxLoader" text={polyglot.t(loadingTextMessage(loadCount))} />;
  }

  return (
    <>
      {openLog && <DetailsModal log={openLog} onClose={() => setOpenLog(null)} />}
      <Container>
        <Table compact striped={false}>
          <TableHead>
            <TableRow>
              <TableCell>{polyglot.t('audit_logs.table.category')}</TableCell>
              <TableCell>{polyglot.t('audit_logs.table.action')}</TableCell>
              <TableCell>{polyglot.t('audit_logs.table.user')}</TableCell>
              <TableCell>{polyglot.t('audit_logs.table.datetime')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((log, i) => (
              <TableRow key={i}>
                <TableCell>{log.resource}</TableCell>
                <TableCell>{log.action}</TableCell>
                <TableCell>{log.initiator}</TableCell>
                <TableCell>{format(new Date(log.created_at + 'Z'), 'dd/MM/yyyy, HH:mm')}</TableCell>
                <TableCell>
                  {(log.payload || log.new_state || log.previous_state) && (
                    <Button type="secondary" size="small" onClick={() => setOpenLog(log)}>
                      {polyglot.t('audit_logs.table.view_notes')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination />
      </Container>
    </>
  );
};

export default TableComponent;
