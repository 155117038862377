import styled from 'styled-components';
import { Space, Flex } from 'common';

export const MaxWidthDiv = styled.div`
  max-width: 630px;
`;

export const PriceSpace = styled(Space)`
  min-width: 80px;
`;

export const NightsWrapper = styled(Space)`
  margin-left: 168px;
`;

export const RouteGroupWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FlightCardWrapper = styled(Flex)`
  max-width: 750px;
`;

export const CollapseWrapper = styled.div`
  & > div:first-child > div:first-child > div:first-child > div:last-child > button {
    display: none;
  }
`;
