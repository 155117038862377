export const extractDuplicates = paxCategories => {
  const paxCategoryArray = paxCategories.split(',');
  return paxCategoryArray.reduce((acc, x) => {
    acc[x] = (acc[x] || 0) + 1;
    return acc;
  }, {});
};

// returns true for disabled option
export const disableOptions = (currentOption, currentPax, adults, infants) => {
  switch (currentPax) {
    case 'child':
      if (infants === adults && currentOption === 'infant') {
        return true;
      }
      return false;
    case 'adult':
      if (infants + 1 === adults && currentOption === 'infant') {
        return true;
      }
      if (infants === adults && (currentOption === 'child' || currentOption === 'infant')) {
        return true;
      }
      return false;
    default:
      return false;
  }
};

export const canRemovePassenger = (currentPassengerCategory, index, adults, infants) => {
  if (index === 0) {
    return false;
  }
  if (currentPassengerCategory === 'adult' && adults === infants) {
    return false;
  }
  return true;
};
