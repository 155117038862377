import countriesJSON from 'data/countries.json';
import { nationalities } from 'consts/nationalities';

export const countriesWithICO = ['cz', 'sk'];

export const countriesWithIBAN = [
  'al',
  'dz',
  'ad',
  'ao',
  'at',
  'az',
  'bh',
  'by',
  'be',
  'bj',
  'ba',
  'br',
  'vg',
  'bg',
  'bf',
  'bi',
  'cm',
  'cv',
  'fr',
  'cg',
  'cr',
  'hr',
  'cy',
  'cz',
  'dk',
  'do',
  'eg',
  'ee',
  'fo',
  'fi',
  'ga',
  'ge',
  'de',
  'gi',
  'gr',
  'gl',
  'gt',
  'gg',
  'hu',
  'is',
  'ir',
  'iq',
  'ie',
  'im',
  'il',
  'it',
  'ci',
  'je',
  'jo',
  'kz',
  'xk',
  'kw',
  'lv',
  'lb',
  'li',
  'lt',
  'lu',
  'mk',
  'mg',
  'ml',
  'mt',
  'mr',
  'mu',
  'md',
  'mc',
  'me',
  'mz',
  'nl',
  'no',
  'pk',
  'ps',
  'pl',
  'pt',
  'qa',
  'ro',
  'lc',
  'sm',
  'st',
  'sa',
  'sn',
  'rs',
  'sc',
  'sk',
  'si',
  'es',
  'se',
  'ch',
  'tl',
  'tn',
  'tr',
  'ua',
  'ae',
  'gb',
];

export const nonSanctionedCountries = nationalities
  .filter(n => !n.is_sanction)
  .map(n => ({ value: n.code.toLowerCase(), label: n.name }));

export const redFlagCountries = nationalities
  .filter(n => n.is_red_flag)
  .map(n => n.code.toLowerCase());

const countries = countriesJSON
  .map(country => {
    if (country.id === 'gb') {
      country.id = 'uk';
      country.value = 'uk';
    }
    return country;
  })
  .concat({ label: 'Great Britain', value: 'gb', id: 'gb' });

export const countriesWithDefault = countries
  .concat({ label: 'Default', value: 'default' })
  .sort((country1, country2) => {
    if (country1.label < country2.label) {
      return -1;
    }
    if (country1.label > country2.label) {
      return 1;
    }
    return 0;
  });

const sortedCountries = countries.sort((country1, country2) => {
  if (country1.label < country2.label) {
    return -1;
  }
  if (country1.label > country2.label) {
    return 1;
  }
  return 0;
});

export default sortedCountries;

export const ZIP_REGEX = '^[a-zA-Z0-9][a-zA-Z0-9 -]+$';
