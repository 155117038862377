import { useCallback, useEffect } from 'react';

const useOnRefreshPage = () => {
  const onRefreshPage = useCallback(event => {
    event.preventDefault();
    event.returnValue = '';
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onRefreshPage);

    return () => {
      window.removeEventListener('beforeunload', onRefreshPage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useOnRefreshPage;
