import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTE_CONFIG } from 'consts/routes';

import Booking from './Booking';
import DepositBookingSuccess from './scenes/DepositBooking/scenes/DepositBookingSuccess';

const BookingRoutes = () => (
  <Switch>
    <Route path={ROUTE_CONFIG.KIWI_BOOKING_SUCCESS.path} component={DepositBookingSuccess} />
    <Route path={ROUTE_CONFIG.KIWI_BOOKING.path} component={Booking} />
  </Switch>
);

export default BookingRoutes;
