import React from 'react';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';

const DeleteModal = ({ loading, onClose, onDelete, title, description, size }) => {
  const polyglot = usePolyglot();

  return (
    <Modal hasCloseButton onClose={onClose} size={size}>
      <ModalHeader title={title} description={description} />
      <ModalFooter>
        <Stack justify="end">
          <Button onClick={onClose} disabled={loading} type="secondary">
            {polyglot.t('common.cancel')}
          </Button>
          <Button onClick={onDelete} loading={loading} type="critical">
            {polyglot.t('common.delete')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
