import React from 'react';

import useBooking from 'components/services/booking/useBooking';

import NoSeatMap from './components/NoSeatMap';
import SeatMap from './components/SeatMap';

const Seatings = ({ routeId }) => {
  const {
    seatingOfferData: { offers },
  } = useBooking();
  const offer = offers.find(offerIt => offerIt.segmentCode === routeId);

  if (!offer?.seatmap) {
    return <NoSeatMap routeId={routeId} />;
  }

  return <SeatMap routeId={routeId} />;
};

export default Seatings;
