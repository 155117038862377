import { useRef } from 'react';
import { useCurrentUser } from 'components/services/auth';
import { useLocalStorage } from 'components/services/localStorage';

const ONBOARDING_RELEASE_DATE = new Date('2021-04-05');

export const getInitialOnboardingValue = user => ({
  done: user?.createdAt ? new Date(user?.createdAt) <= ONBOARDING_RELEASE_DATE : false,
  shownInTools: false,
  acknowledgedDocumentation: false,
  acknowledgedStartUsingSolution: false,
  solution: false,
  bank_account: false,
});

const useOnboardingState = () => {
  const user = useCurrentUser();
  const initialValue = useRef(getInitialOnboardingValue(user));

  return useLocalStorage(`${user?.email}_onboarding`, initialValue.current);
};

export default useOnboardingState;
