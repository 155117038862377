import React from 'react';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';
import { Tooltip } from '@kiwicom/orbit-components/lib';

import { MulticityTripWrapper, TripNumber } from '../index.styled';

const TripIcon = ({ roundTrip, fullTrip }) => {
  if (fullTrip) {
    return (
      <MulticityTripWrapper>
        <FlightDirectIcon />
        <Tooltip content={fullTrip.join('>')} preferredPosition="top">
          <TripNumber>{fullTrip.length - 2}</TripNumber>
        </Tooltip>
        <FlightDirectIcon />
      </MulticityTripWrapper>
    );
  }
  if (roundTrip) {
    return <FlightReturnIcon />;
  }
  return <FlightDirectIcon />;
};

const Trip = ({ departure, arrival, fullTrip, roundTrip }) => (
  <>
    {`${departure.place} (${departure.code}) `}
    <TripIcon roundTrip={roundTrip} fullTrip={fullTrip} />
    {`${arrival.place} (${arrival.code}) `}
  </>
);

export default Trip;
