const v1_get_from = data => {
  return {
    bid: data.bid,
    email: data.email,
    name: data.name,
    pnr: Array.isArray(data.pnr) ? data.pnr.join(', ') : data.pnr,
    surname: data.surname,
    login: data.login,
    password: data.password,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
