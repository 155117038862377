import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

export const SubrouteIconContainer = styled.span`
  margin-right: 4px;
`;

export const Subroute = styled(Link)`
  display: block;
  height: 30px;
  line-height: 30px;
  width: max-content;
  transition: color 150ms ease-in-out;

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.orbit.paletteProductNormal};
    `}
`;

export const SubrouteIcon = styled.span`
  cursor: pointer;
`;

export const SubrouteContainer = styled.div`
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 150ms ease-in-out;
`;

export const ArrowRight = styled(ChevronForwardIcon)`
  margin-left: 5px;
  transition: transform 150ms ease-in-out;
`;

export const ItemWithSubroutes = styled.div``;

export const HeaderBorder = styled.div`
  position: fixed;
  z-index: 100;

  &::before {
    content: '';
    background: white;
    height: 60px;
    width: 1px;
    position: absolute;
    left: ${({ navigationOpen, theme }) =>
      navigationOpen ? theme.sizes.navigationExpandedWidth : theme.sizes.navigationWidth}px;
    z-index: 101;
    top: -1px;
    transition: left 150ms ease-in-out;
  }

  &::after {
    content: '';
    background: ${({ theme }) => theme.colors.navigationBorder};
    height: 44px;
    width: 1px;
    position: absolute;
    left: ${({ navigationOpen, theme }) =>
      navigationOpen ? theme.sizes.navigationExpandedWidth : theme.sizes.navigationWidth}px;
    z-index: 102;
    top: 8px;
    transition: left 150ms ease-in-out;
  }
`;

export const NavigationItemsBorder = styled.div`
  position: relative;
  width: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: ${({ theme }) => `1px solid  ${theme.colors.navigationBorder}`};
  p {
    padding-left: 35px;
    padding-bottom: 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 60px;
  min-height: 60px;
  padding-left: 20px;
  background: white;
  z-index: 4;
  box-shadow: ${({ theme }) => `inset 0px -1px 0px 0px  ${theme.colors.navigationBorder}`};
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  width: calc(100% - 20px);
  ::after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }
`;

export const ItemLink = styled(Link)`
  color: ${({ theme }) => theme.orbit.paletteInkNormal};
`;
export const ItemIcon = styled.span`
  color: ${({ theme }) => theme.orbit.paletteInkNormal};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 34px 3px 20px;
  padding-left: 11px;
  width: 205.2px;
  height: 34px;
  border-radius: 2px;
  transition: all 150ms ease-in-out;
  color: transparent;
  white-space: nowrap;
  overflow: hidden;

  :hover {
    background-color: ${({ theme }) => theme.colors.navigationSecondary};
  }

  ${({ subroutes, numberOfSubRoutes }) =>
    subroutes &&
    css`
      margin-bottom: ${numberOfSubRoutes * 30}px;

      & ${SubrouteContainer} {
        display: block;
        visibility: visible;
        opacity: 1;
      }

      & div ${ArrowRight} {
        transform: rotate(90deg);
      }
    `}
  span {
    margin-right: 10px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors.navigationSecondary};
      width: 205.2px;
    `}
  ${({ open }) =>
    !open &&
    css`
      margin-left: 10px;
      text-align: center;
      width: 30px;
      a {
        margin-right: 0px;
      }
    `}
`;

export const ItemName = styled.div`
  padding-left: 10px;
`;

export const NavigationControlContainer = styled.div`
  display: flex;
  border-top: ${({ theme }) => `1px solid  ${theme.colors.navigationBorder}`};
  width: 100%;
  z-index: 100;
  margin-top: auto;
  &::after {
    content: '';
    background: ${({ theme }) => theme.colors.navigationBorder};
    height: 50px;
    width: 1px;
    position: absolute;
    left: ${({ navigationOpen, theme }) =>
      navigationOpen ? theme.sizes.navigationExpandedWidth : theme.sizes.navigationWidth}px;
    z-index: 102;
    transition: left 150ms ease-in-out;
  }
`;
export const SecondaryNavigationControlContainer = styled.div`
  display: flex;
  width: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;
  justify-content: center;
  padding-top: 10px;
  & button {
    font-size: 14px;
    background: ${({ theme, selected }) => selected && theme.colors.navigationSecondary};
    :hover {
      background: ${({ theme }) => theme.colors.navigationSecondary};
    }
    :focus {
      box-shadow: none;
    }
    & div {
      justify-content: space-between;
      & div {
        span {
          text-transform: none;
        }
      }
    }
  }
`;

export const NavigationControlText = styled.span`
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 400;
`;

export const NavigationContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ theme }) => theme.sizes.navigationWidth}px;
  border-right: ${({ theme }) => `1px solid ${theme.colors.navigationBorder}`};
  background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  z-index: 21;
  transition: width 150ms ease-in-out;
  font-size: 14px;
  overflow: hidden;

  & + div {
    transition: all 150ms ease-in-out;
  }

  ${({ open }) =>
    open &&
    css`
      width: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;

      ${ItemContainer} {
        color: ${({ theme }) => theme.orbit.colorTextPrimary};

        & a {
          color: ${({ theme }) => theme.orbit.colorTextPrimary};
        }
      }

      @media all and (min-width: 1280px) {
        & + div {
          margin-left: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;
        }
      }
    `}
`;

export const SecondaryNavigationContainer = styled.div`
  position: fixed;
  top: 60px;
  bottom: 0;
  left: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 0;
  background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  z-index: 21;
  transition: width 300ms ease-in-out;
  font-size: 14px;
  overflow: hidden;
  ${({ open }) =>
    open &&
    css`
      width: ${({ theme }) => theme.sizes.secondaryNavigationExpandedWidth}px;
      border-left: ${({ theme }) => `1px solid  ${theme.colors.navigationBorder}`};
    `}
`;

export const Backdrop = styled.div`
  position: fixed;
  bottom: 0;
  top: 60px;
  width: 100%;
  right: 0;
  z-index: 20;
  background: rgba(0, 0, 0, 0);
  animation: fade 400ms;
  animation-fill-mode: forwards;

  @keyframes fade {
    from {
      left: ${({ theme }) => theme.sizes.navigationExpandedWidth}px;
    }
    to {
      right: 0;
      background: rgba(0, 0, 0, 0.54);
    }
  }
`;

export const SecondaryNavigation = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 25%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  > p {
    padding: 10px 0px 10px 35px;
  }
  span {
    width: 100%;
    padding: 10px 0px 10px 35px;
    cursor: pointer;
  }
`;

export const SecondaryNavigationBlock = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
  min-width: 220px;
  padding: 10px 0px;
  border-bottom: ${({ theme }) => `1px solid ${theme.orbit.backgroundButtonSecondary}`};
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  label {
    width: 14px;
    border-radius: 3px;
    transition: all 150ms;

    div {
      width: 14px;
      height: 14px;
      background: rgba(0, 0, 0, 0.1);

      svg {
        stroke: #fff;
        stroke-width: 2px;
      }
    }

    input {
      &:checked + div {
        border: none;
        background: #00a991;
      }
    }
  }
`;
