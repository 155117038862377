import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ItemContainer } from './Item.styled';

class Item extends PureComponent {
  handleClick = () => {
    const { onClick, value, readOnly } = this.props;
    if (!readOnly) {
      onClick(value);
    }
  };

  handleMouseOver = () => {
    const { onHover, index, readOnly } = this.props;
    if (!readOnly) {
      onHover(index);
    }
  };

  render() {
    const { children, className, isHovered, readOnly } = this.props;
    return (
      <ItemContainer
        className={className}
        hover={isHovered && !readOnly}
        readOnly={readOnly}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
      >
        {children}
      </ItemContainer>
    );
  }
}

Item.propTypes = {
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  index: PropTypes.number,
  value: PropTypes.string,
  children: PropTypes.any.isRequired,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  isHovered: PropTypes.bool,
};

Item.defaultProps = {
  readOnly: false,
};

export default Item;
