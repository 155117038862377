import { useMemo } from 'react';
import * as R from 'ramda';

import useCompanySolutions from './useCompanySolutions';
import useApiProducts from 'components/services/products/useApiProducts';

const useSolutionOptions = (companyName, enables, enablesTypes, disablesTypes, allEnabled) => {
  const solutions = useCompanySolutions(companyName);
  const products = useApiProducts();
  const solutionOptions = useMemo(() => {
    if (!solutions.data || !products.data || solutions.loading || products.loading) {
      return [];
    }

    return solutions.data.reduce((acc, solution) => {
      const { productType, name, affilId, consumerKey, status } = solution;
      const product = products.data && R.find(R.propEq('value', productType))(products.data);

      if (!product) {
        return acc;
      }

      if (enables && !R.isEmpty(enables)) {
        if (R.isEmpty(R.intersection(enables, product.enables))) {
          return acc;
        }
      }

      if (enablesTypes && !R.isEmpty(enablesTypes)) {
        if (!R.includes(productType, enablesTypes)) {
          return acc;
        }
      }

      if (disablesTypes && !R.isEmpty(disablesTypes)) {
        if (R.includes(productType, disablesTypes)) {
          return acc;
        }
      }

      return acc.concat({
        disabled: allEnabled ? false : status === 'revoked',
        label: name,
        value: JSON.stringify({
          name,
          affilId,
          consumerKey,
          dashboardMetrics: product.dashboardMetrics,
          productType,
          category: product.category,
        }),
      });
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutions, products, enables, enablesTypes, disablesTypes]);

  return { solutions, solutionOptions, products };
};

export default useSolutionOptions;
