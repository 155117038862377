import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { ErrorMessageComponent } from 'common';
import Polyglot from 'shapes/Polyglot';

import RegisterTermsOfService from './RegisterTermsOfService';
import RegisterPrivacyPolicy from './RegisterPrivacyPolicy';

const TermsAndPrivacyCheck = ({ polyglot, values, handleChange, handleBlur, errors }) => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const toggleTermsModal = e => {
    e.preventDefault();
    setIsTermsModalOpen(!isTermsModalOpen);
  };

  const togglePolicyModal = e => {
    e.preventDefault();
    setIsPolicyModalOpen(!isPolicyModalOpen);
  };

  return (
    <>
      <RegisterTermsOfService visible={isTermsModalOpen} onClose={toggleTermsModal} />
      <RegisterPrivacyPolicy visible={isPolicyModalOpen} onClose={togglePolicyModal} />

      <Stack spaceAfter="large">
        <Checkbox
          id="register-agree-tos-and-privacy"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.agreedOnTosAndPolicy}
          name="agreedOnTosAndPolicy"
          info={
            <span style={{ lineHeight: 1.7 }}>
              {polyglot.t('register.tos_pre')}
              <TextLink onClick={toggleTermsModal}>{polyglot.t('register.tos')}</TextLink>
              {polyglot.t('register.tos_post')}
              <TextLink onClick={togglePolicyModal}>{polyglot.t('register.policy')}</TextLink>
              {polyglot.t('register.policy_post')}
            </span>
          }
        />
        <ErrorMessage
          name="agreedOnTosAndPolicy"
          render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
        />
        <Checkbox
          id="register-marketing-consent"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.marketingConsent}
          name="marketingConsent"
          info={polyglot.t('register.marketing_consent')}
        />
      </Stack>
    </>
  );
};

TermsAndPrivacyCheck.schema = Yup.object().shape({
  agreedOnTosAndPolicy: Yup.bool().oneOf([true], 'validation.checked'),
});

TermsAndPrivacyCheck.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default TermsAndPrivacyCheck;
