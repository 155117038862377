import React from 'react';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';

import isValid from 'date-fns/isValid';
import isFuture from 'date-fns/isFuture';
import isWithinInterval from 'date-fns/isWithinInterval';
import isBefore from 'date-fns/isBefore';
import subYears from 'date-fns/subYears';
import addDays from 'date-fns/addDays';
import isPast from 'date-fns/isPast';

import { PageContainer } from 'common';
import useApiProducts from 'components/services/products/useApiProducts';
import useBooking from 'components/services/booking/useBooking';
import { SaveBookingProvider } from 'components/services/booking';
import { INITIAL_PASSENGER } from 'components/scenes/Booking/scenes/DepositBooking/consts';
import IFrameBooking from './scenes/IFrameBooking';
import DepositBooking from './scenes/DepositBooking';

const dateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{1,2}$');
const getMonth = date => {
  return Number(date.split('-')[1]);
};

const ContactDetailsSchema = Yup.object().shape({
  countryId: Yup.string().required('booking.required'),
  phoneSuffix: Yup.string()
    .required('booking.required')
    .min(5, 'booking.invalid_number')
    .max(12, 'booking.invalid_number'),
});

const PassengerSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'booking.too_short').required('booking.required'),
  lastName: Yup.string().min(2, 'booking.too_short').required('booking.required'),
  nationality: Yup.string().required('booking.required'),
  gender: Yup.string().required('booking.required'),
  passportId: Yup.string().when('$documentNeed', (type, schema) => {
    if (type === 2) {
      return schema
        .required('booking.required')
        .min(5, 'booking.too_short')
        .max(20, 'booking.too_short');
    }
    return;
  }),
  dateOfBirth: Yup.string()
    .test('validDate', 'booking.invalid_date', (value, context) => {
      if (dateRegex.exec(value) === null) {
        return false;
      }
      // This is fix for Chrome because if you create new Date from value in Chrome it has days overflow and hence returns valid date, e.g. 30.02. becomes 01.03
      if (getMonth(value) !== Number(new Date(value).getMonth()) + 1) {
        return false;
      }
      return true;
    })
    .test('dateInFuture', 'booking.invalid_date', value => {
      return isValid(new Date(value)) && isPast(new Date(value));
    })
    .test('dateTooOld', 'booking.invalid_date', value => {
      return isBefore(new Date('1899-12-31'), new Date(value));
    })
    .when('category', {
      is: 'adult',
      then: Yup.string().test('validAdultAge', 'booking.adult', function (value) {
        return isBefore(
          new Date(value),
          subYears(new Date(), this.options.context.ageCategoryThresholds?.adult),
        );
      }),
    })
    .when('category', {
      is: 'child',
      then: Yup.string().test('validChildAge', 'booking.child', function (value) {
        return isWithinInterval(new Date(value), {
          start: subYears(new Date(), this.options.context.ageCategoryThresholds?.adult),
          end: subYears(new Date(), this.options.context.ageCategoryThresholds?.child),
        });
      }),
    })
    .when('category', {
      is: 'infant',
      then: Yup.string().test('validInfantAge', 'booking.infant', function (value) {
        return isWithinInterval(new Date(value), {
          start: subYears(new Date(), this.options.context.ageCategoryThresholds?.child),
          end: new Date(),
        });
      }),
    })
    .required('booking.required'),
  expiryDate: Yup.string().when(['$documentNeed', 'noExpiry'], {
    is: (documentNeed, noExpiry) =>
      documentNeed === 2 && (noExpiry === false || noExpiry === undefined),
    then: Yup.string()
      .test('validDate', 'booking.invalid_date', value => {
        if (dateRegex.exec(value) === null) {
          return false;
        }
        if (getMonth(value) !== Number(new Date(value).getMonth()) + 1) {
          return false;
        }
        return isValid(new Date(value));
      })
      .test('dateInPast', 'booking.expired', value => {
        return isValid(new Date(value)) && isFuture(new Date(value));
      })
      .test('expiredOnLastFlight', 'booking.expires', function (value) {
        return !isBefore(addDays(new Date(value), 1), new Date(this.options.context.departureDate));
      }),
  }),
  noExpiry: Yup.bool(),
  baggage: Yup.object().shape({
    handBag: Yup.number().required('booking.select_one_option'),
    holdBag: Yup.number().required('booking.select_one_option'),
  }),
});

const DepositBookingSchema = Yup.object().shape({
  contactDetails: ContactDetailsSchema,
  passengers: Yup.array().of(PassengerSchema),
  paidGuarantee: Yup.string().required('booking.select_one_option'),
});

const getInitialPassengers = passengers => {
  const passengerGroups = passengers.split('-');
  const mappedPassengers = [];

  for (let i = 0; i < Number(passengerGroups[0]); i++) {
    mappedPassengers.push(INITIAL_PASSENGER.adult);
  }
  for (let i = 0; i < Number(passengerGroups[1]); i++) {
    mappedPassengers.push(INITIAL_PASSENGER.child);
  }
  for (let i = 0; i < Number(passengerGroups[2]); i++) {
    mappedPassengers.push(INITIAL_PASSENGER.infant);
  }

  return mappedPassengers;
};

const getDepartureDate = location => {
  const routeGroups = location?.state?.routeGroups;
  const lastRouteGroup = routeGroups?.[routeGroups?.length - 1];
  const lastFlight = lastRouteGroup?.[lastRouteGroup?.length - 1];
  return lastFlight?.local_departure;
};

const Booking = ({ location }) => {
  const products = useApiProducts();
  const { data } = useBooking();
  const form = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(DepositBookingSchema),
    context: {
      departureDate: getDepartureDate(location),
      documentNeed: data?.documentNeed,
      ageCategoryThresholds: data?.ageCategoryThresholds,
    },
    defaultValues: {
      passengers: !location.state ? [] : getInitialPassengers(location.state.passengers),
      paidGuarantee: '',
    },
  });

  if (!location.state) {
    return (
      <Redirect
        to={{
          pathname: `/search`,
        }}
      />
    );
  }
  const { productType, forceOld } = location?.state;

  const isDeposit = products.data?.find(p => p.value === productType).isDeposit || false;

  if (isDeposit && !forceOld) {
    return (
      <SaveBookingProvider>
        <PageContainer>
          <FormProvider {...form}>
            <DepositBooking location={location} />
          </FormProvider>
        </PageContainer>
      </SaveBookingProvider>
    );
  }

  return <IFrameBooking location={location} />;
};

export default Booking;
