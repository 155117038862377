import React from 'react';
import { useState } from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import { addMonths, subMonths, startOfMonth, isSameMonth } from 'date-fns';

import DatepickerHeader from './components/DatepickerHeader';
import Calendar from './components/Calendar';

const Datepicker = ({ handleChange, startMonth, startDate, endDate, pages, disabledDates }) => {
  const [firstDate, setFirstDate] = useState(startDate || startMonth || new Date());
  const [secondDate, setSecondDate] = useState(addMonths(startMonth || startDate || new Date(), 1));
  const [isSelecting, setIsSelecting] = useState(false);

  return (
    <Stack shrink justify="around" direction="row">
      {pages === 1 ? (
        <div>
          <DatepickerHeader
            rightDisabled={
              startOfMonth(addMonths(firstDate, 1)).valueOf() === startOfMonth(secondDate).valueOf()
            }
            leftDisabled={disabledDates.min && isSameMonth(disabledDates.min, firstDate)}
            date={firstDate}
            setDate={setFirstDate}
          />
          <Calendar
            handleChange={handleChange}
            date={firstDate}
            startDate={startDate}
            endDate={endDate}
            isSelecting={isSelecting}
            setIsSelecting={setIsSelecting}
            disabledDates={disabledDates}
          />
        </div>
      ) : (
        <>
          <div>
            <DatepickerHeader
              date={firstDate}
              rightDisabled={
                startOfMonth(addMonths(firstDate, 1)).valueOf() ===
                startOfMonth(secondDate).valueOf()
              }
              leftDisabled={disabledDates.min && isSameMonth(disabledDates.min, firstDate)}
              setDate={setFirstDate}
            />
            <Calendar
              handleChange={handleChange}
              date={firstDate}
              startDate={startDate}
              endDate={endDate}
              isSelecting={isSelecting}
              setIsSelecting={setIsSelecting}
              disabledDates={disabledDates}
            />
          </div>
          <div>
            <DatepickerHeader
              leftDisabled={
                startOfMonth(subMonths(secondDate, 1)).valueOf() ===
                startOfMonth(firstDate).valueOf()
              }
              rightDisabled={disabledDates.max && isSameMonth(disabledDates.max, secondDate)}
              date={secondDate}
              setDate={setSecondDate}
            />
            <Calendar
              handleChange={handleChange}
              date={secondDate}
              startDate={startDate}
              endDate={endDate}
              isSelecting={isSelecting}
              setIsSelecting={setIsSelecting}
              disabledDates={disabledDates}
            />
          </div>
        </>
      )}
    </Stack>
  );
};

export default Datepicker;
