import React, { useState, useContext } from 'react';
import { Formik } from 'formik';

import PropTypes from 'prop-types';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { PageTitle } from 'common';
import { History, Match } from 'shapes/ReactRouter';

import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';
import { AuthState } from 'components/services/auth/AuthProvider';
import { executeRecaptcha } from 'utils/window';

import { ResetPasswordContainer, ActionsContainer, IconContainer } from './index.styled';
import { PasswordValidationSchema } from '../Landing';

const ResetPassword = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const polyglot = usePolyglot();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { resetLoading, resetPassword } = useContext(AuthState);

  const getErrorMsg = err => {
    if (err.response.data.error_code === 'INVALID_ARGUMENT_PASSWORD_TOKEN') {
      setErrMsg(polyglot.t('landing.invalid_token'));
    } else if (err.response.status === 401) {
      setErrMsg(polyglot.t('reset_password.common_password_error'));
    } else if (err.response.status === 403) {
      setErrMsg(polyglot.t('common.recaptcha_failed'));
    } else {
      setErrMsg(polyglot.t('common.exception_message'));
    }
  };

  //added manual loading flag because recaptcha needs to execute before calling resetPassword
  const submitPasswordReset = async (password1, password2) => {
    if (password1 !== password2) {
      setLoading(false);
      return;
    }
    setErrMsg(null);
    await executeRecaptcha(
      gtoken => resetPassword(password1, token, gtoken),
      () => {
        setLoading(false);
        history.push(ROUTE_CONFIG.LOGIN.path);
      },
      err => {
        setLoading(false);
        getErrorMsg(err);
      },
    );
  };

  return (
    <div>
      <PageTitle label="common.reset_password" />
      {errMsg && (
        <Alert type="critical" spaceAfter="medium">
          {errMsg}
        </Alert>
      )}
      <Formik
        initialValues={{
          password1: '',
          password2: '',
        }}
        validateOnMount={true}
        validationSchema={PasswordValidationSchema}
        onSubmit={values => {
          setLoading(true);
          submitPasswordReset(values.password1, values.password2);
        }}
      >
        {({ handleChange, handleSubmit, touched, errors }) => (
          <ResetPasswordContainer
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <Stack direction="column" spacing="XXLarge" justify="between" spaceAfter="large">
              <InputField
                type="password"
                id="password1"
                name="password1"
                label={polyglot.t('reset_password.enter_new_password')}
                placeholder={polyglot.t('reset_password.placeholder')}
                help={polyglot.t('validation.instruction')}
                error={touched.password1 && polyglot.t(errors.password1)}
                onChange={handleChange}
                suffix={
                  <IconContainer>
                    <Tooltip
                      content={polyglot.t('landing.tooltip_password_instruction')}
                      size="medium"
                      placement="top-end"
                    >
                      <InformationCircle color="secondary" size="medium" />
                    </Tooltip>
                  </IconContainer>
                }
              />
              <InputField
                type="password"
                id="password2"
                name="password2"
                label={polyglot.t('reset_password.confirm_new_password')}
                placeholder={polyglot.t('reset_password.placeholder')}
                error={touched.password2 && polyglot.t(errors.password2)}
                onChange={handleChange}
              />
            </Stack>
            <div id="tooltips" />
            <ActionsContainer>
              <Button onClick={handleSubmit} loading={resetLoading || loading} width="160px">
                {polyglot.t('reset_password.set_new_password')}
              </Button>
            </ActionsContainer>
          </ResetPasswordContainer>
        )}
      </Formik>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.shape(History).isRequired,
  match: PropTypes.shape(Match).isRequired,
};

export default ResetPassword;
