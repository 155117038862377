import { QUICK_OPTION_TYPES } from 'consts/seating';

const direct_post_to = (currency, seatings) => {
  return {
    currency,
    items: {
      seating: Object.keys(seatings)
        .filter(segmentCode => seatings[segmentCode].option !== QUICK_OPTION_TYPES.RANDOM)
        .map(segmentCode => ({
          option: seatings[segmentCode].option,
          price: {
            amount: seatings[segmentCode].price.amount.toString(),
            base: seatings[segmentCode].price.base.toString(),
            currency: seatings[segmentCode].price.currency,
            merchant: seatings[segmentCode].price.merchant.toString(),
            service: seatings[segmentCode].price.service.toString(),
            service_flat: seatings[segmentCode].price.serviceFlat.toString(),
          },
          segment_code: segmentCode,
        })),
    },
  };
};

export default {
  direct: {
    post: {
      to: direct_post_to,
    },
  },
};
