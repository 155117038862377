import { css } from 'styled-components';

const swaggerOverrides = css`
  .swagger-ui {
    & .info {
      & code {
        color: #0176d2 !important;
        background: #dbf7ff !important;
        border-radius: 100px !important;
        padding: 0 5px !important;
      }
      & p {
        line-height: 20px !important;
      }
    }
    & select {
      border: 1px solid #bac7d5 !important;
      background: white !important;
      height: 44px !important;
      border-radius: 3px !important;
      min-width: 220px !important;

      & option {
        color: #46515e !important;
        font-size: 16px !important;
        font-weight: 400 !important;
      }
    }
    & .scheme-container {
      box-shadow: none !important;
      margin: -30px 0 0 !important;

      & .schemes > label {
        color: #7f91a8 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        font-family: 'Circular Pro', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI',
          'Helvetica Neue', 'Lucida Grande', sans-serif !important;
      }
    }

    & .opblock {
      padding: 20px !important;
    }
    & .opblock-tag {
      font-size: 16px !important;
      font-family: 'Circular Pro', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI',
        'Helvetica Neue', 'Lucida Grande', sans-serif !important;
      color: #171b1e !important;
      font-weight: 500 !important;
      border-bottom: none !important;
      margin: 0 16px !important;
      padding-right: 0 !important;
    }

    & .opblock-tag-section {
      box-shadow: 0 2px 4px 0 rgba(23, 27, 30, 0.1) !important;
      margin-bottom: 24px !important;

      &.is-open .opblock-tag {
        border-bottom: 1px solid #e8edf1 !important;
      }

      & > div {
        padding: 20px 16px !important;
      }
    }

    & .opblock .opblock-summary-method {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      min-width: 64px !important;
      height: 32px !important;
      font-size: 12px !important;
      font-family: 'Circular Pro', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI',
        'Helvetica Neue', 'Lucida Grande', sans-serif !important;
      font-weight: 700 !important;
    }

    & .wrapper {
      padding: 0 !important;
    }
    & .expand-methods svg,
    & .expand-operation svg {
      width: 14px !important;
    }
    & tr[data-param-name='apikey'] .parameter__in {
      color: red !important;
    }
  }
`;

export default swaggerOverrides;
