import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';

import { ClickOutside } from '..';

import { AutocompleteMultiWrapper, ListChoiceContainer } from './AutocompleteMulti.styled';

const AutocompleteMulti = ({
  options,
  size,
  onSelect,
  label,
  prefix,
  onChange,
  selectedOptions,
  onRemove,
  className,
  dataTest,
  placeholder,
  disabled,
  error,
  help,
  inlineLabel,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const inputRef = useRef(null);

  const handleOptionClick = useCallback(
    option => {
      onSelect(option);
      inputRef.current.value = '';
      setMenuOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSelect, inputRef.current],
  );

  const menuContent =
    inputRef.current && inputRef.current.value === '' && selectedOptions.length > 0
      ? selectedOptions.map(option => (
          <ListChoice
            key={option.value}
            title={option.label}
            icon={<CheckIcon />}
            onClick={() => onRemove(option)}
          />
        ))
      : options.map(option => (
          <ListChoice
            key={option.value}
            title={option.label}
            onClick={!option.disabled ? () => handleOptionClick(option) : () => {}}
          />
        ));

  return (
    <AutocompleteMultiWrapper className={className} disabled={disabled}>
      <ClickOutside onClick={() => setMenuOpen(false)}>
        <InputField
          size={size}
          label={label}
          inlineLabel={inlineLabel}
          prefix={prefix}
          disabled={disabled}
          error={error}
          onFocus={() => setMenuOpen(true)}
          onChange={onChange}
          ref={inputRef}
          placeholder={placeholder}
          autoComplete="off"
          dataTest={dataTest}
          help={help}
          tags={
            selectedOptions.length > 0 && (
              <div>
                {selectedOptions.map(option => (
                  <Tag selected key={option.value} onRemove={() => onRemove(option)}>
                    {option.label}
                  </Tag>
                ))}
              </div>
            )
          }
        />
        {menuOpen && (
          <ListChoiceContainer label={label} inlineLabel={inlineLabel}>
            {menuContent}
          </ListChoiceContainer>
        )}
      </ClickOutside>
    </AutocompleteMultiWrapper>
  );
};

AutocompleteMulti.defaultProps = {
  size: 'normal',
  onSelect: () => {},
};

AutocompleteMulti.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  onSelect: PropTypes.func,
  dataTest: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};

export default AutocompleteMulti;
