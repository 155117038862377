import React from 'react';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import Details from './components/Details';
import Billing from './components/Billing';
import Deposit from './components/Deposit';
import SpecialAppsLinks from './components/SpecialAppsLinks';

import { Authorization } from 'common/Authorization';
import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';

const Container = styled.div`
  max-width: 714px;
  margin-top: 24px;
  margin-left: 24px;
`;

const CompanyDetails = () => {
  const companyDetails = useCurrentCompanyDetails();

  return (
    <Authorization resource="model.company" action={['read', 'read_assigned', 'read_own']}>
      <Container>
        <Stack spacing="large">
          <Details companyDetails={companyDetails} />

          <Authorization
            resource="model.bank_account"
            action={['read', 'read_assigned', 'read_own']}
          >
            <Billing companyDetails={companyDetails} />
          </Authorization>

          <Deposit />
          <SpecialAppsLinks />
        </Stack>
      </Container>
    </Authorization>
  );
};

export default CompanyDetails;
