import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  font-size: inherit;
  background: initial;
  color: ${({ theme }) => theme.orbit.colorTextPrimary};
  margin: 0 4px;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({ theme }) => theme.orbit.colorTextSecondary};
  }
`;
