import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import WalletIcon from '@kiwicom/orbit-components/lib/icons/Wallet';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import Button from '@kiwicom/orbit-components/lib/Button';

import { Card, CardActionHeader, CardData } from 'components/common/Card';
import useCurrentCompanyBankAccounts from 'components/services/bankAccounts/useCurrentCompanyBankAccounts';
import { usePolyglot } from 'components/services/i18n';
import { Authorization } from 'common/Authorization';
import { BankAccountModal } from 'common';
import { useToggle } from 'utils/hooks';
import { countriesWithIBAN, nonSanctionedCountries } from 'consts/countries';

const Billing = ({ companyDetails }) => {
  const polyglot = usePolyglot();
  const { data: bankAccounts, loading } = useCurrentCompanyBankAccounts();
  const updatedBilling = useToggle();
  const addBankAccountModal = useToggle();

  const openAddBankAccountModal = () => {
    addBankAccountModal.setOn();
  };

  const getAction = () => {
    const button =
      bankAccounts.length === 0 ? (
        <Button size="small" type="secondary" onClick={openAddBankAccountModal}>
          {polyglot.t('company.billing.add_bank_account.button_text')}
        </Button>
      ) : (
        <Link to={`/companies/${companyDetails.data.name}/billing`}>
          <Button
            type="secondary"
            title="Update billing details"
            dataTest="button-edit-billing"
            size="small"
            iconLeft={<EditIcon />}
          />
        </Link>
      );

    return (
      <Authorization
        resource="model.bank_account"
        action={['update', 'update_assigned', 'update_own']}
      >
        {button}
      </Authorization>
    );
  };

  const closeAddBankAccountModal = isUpdatedSuccessfully => {
    addBankAccountModal.setOff();
    if (isUpdatedSuccessfully) {
      updatedBilling.setOn();
    }
  };

  return (
    <>
      {addBankAccountModal.isOn && <BankAccountModal onClose={closeAddBankAccountModal} />}
      <Card loading={loading} loadingText="Loading billing info...">
        {bankAccounts && companyDetails.data && (
          <>
            <CardActionHeader title="Billing" icon={<WalletIcon />} action={getAction()} />
            {bankAccounts.map((account, i) => {
              const beneficiaryName = account.beneficiaryName;
              const accountNumber = account.accountNumber;

              return (
                <CardData
                  key={i}
                  showAlert={i === 0 && updatedBilling.isOn}
                  alert={
                    <Alert type="success" closable icon onClose={() => updatedBilling.setOff()}>
                      {polyglot.t('company.update_details_success')}
                    </Alert>
                  }
                  leftColumn={[
                    { label: 'Type', value: polyglot.t(`company.account_${i + 1}`) },
                    {
                      label: 'Country',
                      value: (
                        nonSanctionedCountries.find(
                          c => c.value.toLowerCase() === account.country?.toLowerCase?.(),
                        ) || {
                          label: '-',
                        }
                      ).label,
                    },
                    {
                      label: polyglot.t('company.beneficiary_name'),
                      value: beneficiaryName,
                    },
                  ]}
                  rightColumn={[
                    { label: 'SWIFT / BIC', value: account.swift || '-' },
                    {
                      ...(countriesWithIBAN.includes(account.country)
                        ? {
                            label: 'IBAN',
                            value: account.iban || '-',
                          }
                        : { label: 'Account Number', value: accountNumber || '-' }),
                    },
                  ]}
                />
              );
            })}
          </>
        )}
      </Card>
    </>
  );
};

export default Billing;
