import { useContext, useEffect } from 'react';

import { AuthState } from 'components/services/auth/AuthProvider';
import { SpecialAppsContext } from './';

const useSpecialApps = companyName => {
  const { specialAppsState, loadSpecialApps } = useContext(SpecialAppsContext);
  const { checkAccess } = useContext(AuthState);

  useEffect(() => {
    checkAccess('model.company_special_app', ['read', 'read_own'], companyName).then(res => {
      if (res && !specialAppsState[companyName]) {
        loadSpecialApps(companyName);
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  return (
    specialAppsState[companyName] || {
      loading: false,
      error: null,
      updateError: null,
      apps: null,
    }
  );
};

export default useSpecialApps;
