import { useLocalStorage } from 'components/services/localStorage';

const useImpersonate = (uuid = null) => {
  const [impersonatingUser, setImpersonatingUser] = useLocalStorage('impersonating_user', {
    uuid: uuid,
  });

  return [impersonatingUser, setImpersonatingUser];
};

export default useImpersonate;
