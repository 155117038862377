import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@kiwicom/orbit-components/lib/Button';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Attachment from '@kiwicom/orbit-components/lib/icons/Attachment';

import { proxy } from 'utils/api';
import { truncate } from 'utils/string';
import { TemporaryAttachment } from 'shapes/Support';

import { StyledFileInput, MultipleFileInput } from '../index.styled';

function AttachmentInput({
  title,
  serviceDesk,
  uploadButtonText,
  temporaryAttachments,
  setFieldValue,
  spaceAfter,
  dataTest,
  error,
}) {
  const [loading, setLoading] = useState(false);

  function addAttachment(event) {
    const { files } = event.target;
    if (!files) return;

    const formData = new FormData();
    formData.append('file', files[0], files[0].name);
    setLoading(true);
    event.target.value = null;

    proxy.v1
      .post(`jira/${serviceDesk}/attachment`, formData)
      .then(res => {
        setFieldValue('temporaryAttachments', [
          ...temporaryAttachments,
          res.data.temporaryAttachments[0],
        ]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }

  function removeAttachment(id) {
    setFieldValue(
      'temporaryAttachments',
      temporaryAttachments.filter(att => att.temporaryAttachmentId !== id),
    );
  }

  function generateTags(attachments) {
    if (attachments.length === 0) {
      return null;
    }
    return attachments.map(att => (
      <Tag
        key={att.temporaryAttachmentId}
        onRemove={() => removeAttachment(att.temporaryAttachmentId)}
      >
        {truncate(att.fileName, 15)}
      </Tag>
    ));
  }

  const tags = generateTags(temporaryAttachments);
  const suffix = (
    <StyledFileInput>
      <Button size="small" loading={loading} type="secondary" iconLeft={<Attachment />}>
        {uploadButtonText}
      </Button>
      <input disabled={loading} onChange={addAttachment} type="file" />
    </StyledFileInput>
  );

  return (
    <MultipleFileInput>
      <InputField
        label={title}
        readOnly={true}
        placeholder={temporaryAttachments.length ? null : 'None'}
        tags={tags}
        suffix={suffix}
        spaceAfter={spaceAfter}
        dataTest={dataTest}
        error={error}
      />
    </MultipleFileInput>
  );
}

AttachmentInput.propTypes = {
  title: PropTypes.string.isRequired,
  uploadButtonText: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  temporaryAttachments: PropTypes.arrayOf(PropTypes.shape(TemporaryAttachment)).isRequired,
  serviceDesk: PropTypes.string.isRequired,
  spaceAfter: PropTypes.string,
};

export default AttachmentInput;
