import React from 'react';
import Select from '@kiwicom/orbit-components/lib/Select';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import {
  QuotaInputContainer,
  QuotaIntervalContainer,
  QuotaTimeUnitContainer,
  QuotaText,
} from './QuotaInput.styled';

const QuotaInput = ({
  quota,
  interval,
  timeUnit,
  onChange,
  timeUnitOptions,
  polyglot,
  touched,
  errors,
}) => {
  return (
    <Stack direction="row" spacing="none">
      <QuotaInputContainer>
        <InputField
          label="Quota"
          type="number"
          name="quota"
          value={quota}
          onChange={onChange}
          error={touched.quota && errors.quota && polyglot.t(errors.quota)}
        />
      </QuotaInputContainer>
      <QuotaText>
        <Text size="large">{polyglot.t('solutions.quota.requests_every')}</Text>
      </QuotaText>
      <QuotaIntervalContainer>
        <InputField
          label="Interval"
          type="number"
          name="quotaInterval"
          value={interval}
          onChange={onChange}
          error={!!(touched.quotaInterval && errors.quotaInterval)}
        />
        <div>
          {touched.quotaInterval && errors.quotaInterval && polyglot.t(errors.quotaInterval)}
        </div>
      </QuotaIntervalContainer>
      <QuotaTimeUnitContainer>
        <Select
          label="Time unit"
          name="quotaTimeUnit"
          value={timeUnit}
          onChange={onChange}
          options={timeUnitOptions.map(option => ({
            label: option,
            value: option,
          }))}
        />
      </QuotaTimeUnitContainer>
    </Stack>
  );
};

export default QuotaInput;
