import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import styled from 'styled-components';

import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Text from '@kiwicom/orbit-components/lib/Text';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Box from '@kiwicom/orbit-components/lib/Box';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import TicketIcon from '@kiwicom/orbit-components/lib/icons/Ticket';
import AlertButton from '@kiwicom/orbit-components/lib/Alert/AlertButton';

import { usePolyglot } from 'components/services/i18n';
import MultipleInputFiles from 'common/MultipleInputFiles';
import { STATUS } from 'consts/help';
import useHelp from 'components/services/help/useHelp';

import Attachment from './Attachment';
import Message from './Message';
import MessagesToggler from './MessagesToggler';

const InfoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.orbit.spaceMedium};
`;

const BadgeWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.orbit.spaceMedium};
`;

const AttachmentsContainer = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.orbit.spaceXSmall};
`;

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.orbit.spaceSmall};
`;

const setBadgeType = status => {
  switch (status) {
    case STATUS.WAITING_FOR_SUPPORT:
    case STATUS.WAITING_FOR_CUSTOMER:
    case STATUS.PENDING:
      return 'warning';
    case STATUS.CLOSED:
      return 'neutral';
    case STATUS.IN_PROGRESS:
      return 'info';
    default:
      return 'neutral';
  }
};

const ModalContent = ({ onClose, issue }) => {
  const polyglot = usePolyglot();
  const { errors, control, handleSubmit, getValues, watch } = useFormContext();
  const { createIssueComment, createAttachment, getSingleAttachment, comment, attachment } =
    useHelp();

  const handleOnCreateIssue = useCallback(async () => {
    const { reply, attachments } = getValues();
    await createIssueComment(issue.issueKey, reply);

    if (attachments.length === 1) {
      createAttachment(issue.issueKey, attachments[0]);
    } else if (attachments.length > 1) {
      await Promise.allSettled(attachments.map(file => createAttachment(issue.issueKey, file)));
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalHeader title={issue.summary} />
      <ModalSection>
        <Separator spaceAfter="medium" />
        <InfoWrapper>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                Issue Key
              </Text>
            </Box>
            <Text spaceAfter="medium">{issue.issueKey}</Text>
          </Inline>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                {polyglot.t('support.reply_issue_modal.info.request_type')}
              </Text>
            </Box>
            <Text spaceAfter="medium">{issue.requestType}</Text>
          </Inline>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                {polyglot.t('support.reply_issue_modal.info.status')}
              </Text>
            </Box>
            <BadgeWrapper>
              <Badge type={setBadgeType(issue.status)}>{issue.status}</Badge>
            </BadgeWrapper>
          </Inline>
          <Separator spaceAfter="medium" />
          <Alert
            icon={<TicketIcon />}
            inlineActions={
              <AlertButton href={issue.mmbCaseLink} type="info">
                {polyglot.t('customer_support.custom_fields.mmb_case_link_button')}
              </AlertButton>
            }
            title={polyglot.t('customer_support.custom_fields.mmb_case_link')}
            type="info"
            spaceAfter="medium"
          />
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                Bid
              </Text>
            </Box>
            <Text spaceAfter="medium">{issue.bid}</Text>
          </Inline>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                Sub-Category
              </Text>
            </Box>
            <Text spaceAfter="medium">{issue.subcategory}</Text>
          </Inline>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                SLA Class
              </Text>
            </Box>
            <Text spaceAfter="medium">{issue.slaClass}</Text>
          </Inline>
          <Inline spacing="XSmall">
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                Tequila Company URL
              </Text>
            </Box>
            <TextLink spaceAfter="medium" href={issue.tequilaCompanyUrl} title="NESTO">
              {issue.tequilaCompanyUrl}
            </TextLink>
          </Inline>
          <AttachmentsContainer>
            <Box width="100px">
              <Text type="secondary" spaceAfter="medium">
                {polyglot.t('support.reply_issue_modal.info.attachments')}
              </Text>
            </Box>
            <AttachmentsWrapper>
              {issue?.attachments?.map(att => (
                <Attachment
                  key={att.id}
                  fileName={att.fileName}
                  onClick={() => {
                    getSingleAttachment(issue.issueKey, att.id, att.fileName);
                  }}
                />
              ))}
            </AttachmentsWrapper>
          </AttachmentsContainer>
        </InfoWrapper>
        <Separator spaceAfter="medium" />
        <Message
          customer={issue.reporter}
          message={issue.description}
          date={issue?.createdAt ? format(parseISO(issue?.createdAt), 'dd.MM.yyyy, HH:mm') : '/'}
          spaceAfter="spaceLarge"
        />
        {issue?.comments?.length > 3 ? (
          <MessagesToggler numberOfRemainingMessages={(issue.comments.length - 1).toString()}>
            {issue.comments.slice(0, -1).map(comm => {
              return (
                <Message
                  key={comm.id}
                  customer={comm.author}
                  message={comm.body.replace(/![^!]*!/g, '')}
                  date={format(parseISO(comm.createdAt), 'dd.MM.yyyy, HH:mm')}
                  spaceAfter="spaceLarge"
                />
              );
            })}
          </MessagesToggler>
        ) : (
          issue?.comments?.slice(0, -1).map(comm => {
            return (
              <Message
                key={comm.id}
                customer={comm.author}
                message={comm.body.replace(/![^!]*!/g, '')}
                date={format(parseISO(comm.createdAt), 'dd.MM.yyyy, HH:mm')}
                spaceAfter="spaceLarge"
              />
            );
          })
        )}
        {!!issue.comments.length && (
          <Message
            customer={issue.comments[issue.comments.length - 1].author}
            message={issue.comments[issue.comments.length - 1].body}
            date={format(
              parseISO(issue.comments[issue.comments.length - 1].createdAt),
              'dd.MM.yyyy, HH:mm',
            )}
            spaceAfter="spaceLarge"
          />
        )}
        <Separator spaceAfter="medium" />
        <Controller
          control={control}
          name="reply"
          render={({ value, onChange }) => (
            <Textarea
              label={polyglot.t('support.reply_issue_modal.textaread.label')}
              required
              placeholder={polyglot.t('support.reply_issue_modal.textarea.placeholder')}
              spaceAfter="large"
              value={value}
              onChange={onChange}
              error={polyglot.t(errors?.['reply']?.message?.toString())}
            />
          )}
        />
        <Controller
          control={control}
          name="attachments"
          render={({ onChange }) => (
            <MultipleInputFiles
              label={polyglot.t('support.reply_issue_modal.file.label')}
              placeholder={polyglot.t('support.reply_issue_modal.file.placeholder')}
              error={polyglot.t(errors?.['attachments']?.message?.toString())}
              onChange={onChange}
              value={watch('attachments')}
            />
          )}
        />
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" justify="end">
          <Button
            type="secondary"
            onClick={onClose}
            loading={comment.loading || attachment?.loading}
          >
            {polyglot.t('support.reply_issue_modal.button.cancel')}
          </Button>
          <Button
            onClick={handleSubmit(handleOnCreateIssue)}
            loading={comment.loading || attachment?.loading}
          >
            {polyglot.t('support.reply_issue_modal.button.submit')}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  );
};

export default ModalContent;
