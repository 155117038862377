import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import * as sanitizeHtml from 'sanitize-html';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';

import Polyglot from 'shapes/Polyglot';
import { STATUS } from 'redux/modules/apigee/requests';
import { proxy } from 'utils/api';
import { SANITIZE_CONFIG } from 'consts/sanitize';

const EDIT_REQUEST_SCHEMA = Yup.object().shape({
  summary: Yup.string().max(500, 'validation.length_exceeded').required('validation.cant_be_empty'),
  description: Yup.string()
    .max(2500, 'validation.length_exceeded')
    .required('validation.cant_be_empty'),
});

function EditSupportRequest({
  issueId,
  serviceDesk,
  stopEditing,
  cancelEditing,
  polyglot,
  summary,
  description,
}) {
  const [updateRequestStatus, setUpdateRequestStatus] = useState(STATUS.CLEAR);

  const updateRequest = values => {
    setUpdateRequestStatus(STATUS.LOADING);
    values.description = sanitizeHtml(values.description, SANITIZE_CONFIG);
    proxy.v1
      .put(`jira/${serviceDesk}/request/${issueId}`, JSON.stringify({ fields: values }))
      .then(stopEditing)
      .catch(error => setUpdateRequestStatus(STATUS.ERROR));
  };

  const isLoading = updateRequestStatus === STATUS.LOADING;

  return (
    <Formik
      initialValues={{ summary, description }}
      validationSchema={EDIT_REQUEST_SCHEMA}
      onSubmit={updateRequest}
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <Form>
          {updateRequestStatus === STATUS.ERROR && (
            <Alert type="critical" spaceAfter="large">
              {polyglot.t('support.requestDetailsModal.updating_support_request_error')}
            </Alert>
          )}
          <InputField
            name="summary"
            label={polyglot.t('common.summary')}
            onChange={handleChange}
            value={values.summary}
            error={touched.summary && errors.summary && polyglot.t(errors.summary)}
            spaceAfter="large"
          />
          <Textarea
            name="description"
            label={polyglot.t('common.description')}
            rows={10}
            resize="none"
            onChange={handleChange}
            value={values.description}
            error={touched.description && errors.description && polyglot.t(errors.description)}
            spaceAfter="large"
          />
          <Stack direction="row" justify="end">
            <Stack grow={false} shrink basis="20%">
              <Button type="secondary" onClick={cancelEditing} block disabled={isLoading}>
                {polyglot.t('common.cancel')}
              </Button>
            </Stack>
            <Stack grow={false} shrink basis="25%">
              <Button onClick={handleSubmit} block loading={isLoading}>
                {polyglot.t('common.save')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

EditSupportRequest.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  issueId: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stopEditing: PropTypes.func.isRequired,
  cancelEditing: PropTypes.func.isRequired,
  serviceDesk: PropTypes.string.isRequired,
};

export default EditSupportRequest;
