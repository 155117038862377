import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import LogoutIcon from '@kiwicom/orbit-components/lib/icons/Logout';
import PassengerRemoveIcon from '@kiwicom/orbit-components/lib/icons/PassengerRemove';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import CheckCirleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import AlertIcon from '@kiwicom/orbit-components/lib/icons/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import debounce from 'debounce';

import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';
import { useLocalStorage } from 'components/services/localStorage';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useCurrentUserSettings from 'components/services/auth/useCurrentUserSettings';
import useToasts from 'components/services/toasts/useToasts';
import { ROLES_I18N } from 'consts/roles';
import avatarImage from 'images/avatar.png';

const Container = styled.div`
  margin: 0 18px;
  width: 204px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const UserInfoWrapper = styled.div`
  margin-left: 10px;
`;

const UserName = styled.div`
  color: rgb(0, 0, 0);
  font-size: 14px;
`;

const UserRole = styled.div`
  color: rgb(0, 169, 145);
  font-size: 12px;
`;

const ContextualResourcesWrapper = styled.div`
  margin: 10px 10px 0px 10px !important;
`;

const TitleWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
  & h1 {
    color: #005c4e !important;
  }
`;

const CheckboxWrapper = styled.div`
  & span {
    color: #4f5e71 !important;
  }
`;

// We will need this in the future for KYC thing, not ready for release yet
/* const InfoWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`; */

const UserMenu = () => {
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const userSettings = useCurrentUserSettings();
  const { logout, stopImpersonating, updateUserSettings } = useContext(AuthState);
  const history = useHistory();
  const [impersonatingUser] = useLocalStorage('impersonating_user', {
    uuid: null,
  });
  const [consentToggle, setConsentToggle] = useState(userSettings?.marketingConsent);

  const { addSuccessToast } = useToasts();

  const handleConsentToggle = async () => {
    setConsentToggle(!consentToggle);
    try {
      await handleConsentToggleDebounce(!consentToggle);
      addSuccessToast(
        <Stack direction="row" justify="center" align="start">
          <CheckCirleIcon size="small" color="#ffffff" />
          <Text type="white" align="left">
            {!consentToggle
              ? polyglot.t('contextual_menu.marketing_consent_update_success')
              : polyglot.t('contextual_menu.marketing_consent_update_success_2')}
          </Text>
        </Stack>,
      );
    } catch (err) {
      addSuccessToast(
        <Stack direction="row" justify="center" align="start">
          <AlertIcon size="small" color="#ffffff" />
          <Text type="white" align="left">
            {polyglot.t('contextual_menu.marketing_consent_update_failure')}
          </Text>
        </Stack>,
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleConsentToggleDebounce = useCallback(debounce(updateUserSettings, 500), []);

  return (
    <Container>
      <Popover
        noPadding
        fixed
        width="280px"
        content={
          <>
            <ContextualResourcesWrapper>
              {/* We will need this in the future for KYC thing, not ready for release yet*/}
              {/* <Stack direction="row" align="center">
                <AccountCircleIcon />
                <Text>{polyglot.t('common.account_type')}</Text>
              </Stack>
              <TitleWrapper>
                <Text as="h1" uppercase weight="bold">
                  {polyglot.t('register.type_person')}
                </Text>
              </TitleWrapper>
              <Text type="secondary">
                {polyglot.t('contextual_menu.personal_account_info_title')}
              </Text>
              <InfoWrapper>
                <List>
                  <ListItem>{polyglot.t('contextual_menu.personal_account_info_1')}</ListItem>
                  <ListItem>{polyglot.t('contextual_menu.personal_account_info_2')}</ListItem>
                  <ListItem>{polyglot.t('contextual_menu.personal_account_info_3')}</ListItem>
                </List>
              </InfoWrapper>
              <Button size="small" fullWidth>
                {polyglot.t('contextual_menu.personal_account_submit')}
              </Button>
              <LinkWrapper>
                <TextLink href="#">{polyglot.t('add_details.learn_more')}</TextLink>
              </LinkWrapper> */}
              <TitleWrapper>
                <Text as="h1" uppercase weight="bold">
                  {polyglot.t('contextual_menu.personal_deals_title')}
                </Text>
              </TitleWrapper>
              <CheckboxWrapper>
                <Checkbox
                  label={polyglot.t('register.marketing_consent')}
                  checked={!!consentToggle}
                  onChange={handleConsentToggle}
                />
              </CheckboxWrapper>
            </ContextualResourcesWrapper>
            <ListChoice
              dataTest="user-menu-sign-out-button"
              onClick={() => {
                logout();
              }}
              title={polyglot.t('common.sign_out')}
              icon={<LogoutIcon />}
            />
            {impersonatingUser.uuid && (
              <ListChoice
                dataTest="user-menu-stop-impersonating-button"
                onClick={() => {
                  stopImpersonating();
                  history.go(0);
                }}
                title={<Text type="critical">Stop impersonation</Text>}
                icon={<PassengerRemoveIcon />}
              />
            )}
          </>
        }
      >
        <UserWrapper role="button" data-test="header-user-menu-button">
          <img src={avatarImage} alt="User avatar" />
          <UserInfoWrapper>
            <UserName size="small">{`${user.firstName} ${user.lastName}`}</UserName>
            {impersonatingUser?.uuid ? (
              <UserRole size="small">Impersonation</UserRole>
            ) : (
              <UserRole size="small">{polyglot.t(ROLES_I18N[user.role])}</UserRole>
            )}
          </UserInfoWrapper>
        </UserWrapper>
      </Popover>
    </Container>
  );
};

export default UserMenu;
