import styled from 'styled-components';

export const IllustrationsWrapper = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const ColorsWidthLimiter = styled.div`
  max-width: 160px;
`;

export const IllustrationsRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 225px;

  & > img {
    width: 225px;
    height: auto;
  }
`;
