import styled from 'styled-components';
import { StyledTableRow as OrbitTableRow } from '@kiwicom/orbit-components/lib/Table/TableRow/index';

export const NoBookingsWrapper = styled.div`
  text-align: center;
  padding: 50px;
  & button {
    margin: 25px auto;
  }
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`;

export const ClickWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  > svg {
    margin: 0 5px;
  }
`;

export const MulticityTripWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const TripNumber = styled.div`
  background: ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-radius: 50%;
  width: 23px;
  height: 23px;
  text-align: center;
`;

export const WrappedTableRow = styled(OrbitTableRow)`
  ${({ disrupted, theme }) => disrupted && `color:${theme.orbit.colorTextCritical}!important`};
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 16px;
`;

export const BadgeWrapper = styled.div`
  & > div {
    & > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const CloseIconWrapper = styled.div`
  & > svg {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
