import P from 'parsimmon';

import availabilityParser from './parsers/availability';
import fareDisplayParser from './parsers/fareDisplay';
import fareShopParser from './parsers/fareShop';

import formatAdapter from './formatAdapter';

export default command => {
  const result = P.alt(availabilityParser, fareDisplayParser, fareShopParser).parse(command);
  if (!result.status) {
    return null;
  }

  return formatAdapter(result.value);
};
