import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import { IconSuffix } from 'common';
import {
  keys,
  voucherTypeValues,
  yesNoValues,
  voucherCodeTypeValues,
  designTypeValues,
  customDesignSizeValues,
  dynamicStaticValues,
} from 'consts/smartPass';

import { LabelsRowWrapper, FileWithPreview, HalfWidthWrapper } from '../index.styled';
import BagDeposit from './BagDeposit';
import LoungeAccess from './LoungeAccess';
import FileInput from '../FileInput';

const {
  VOUCHER_TYPE,
  VOUCHER_LARGE,
  VOUCHER_SMALL,
  VOUCHER_CODE_PLACED,
  VOUCHER_CODE_TYPE,
  VOUCHER_EAN_CODE,
  VOUCHER_DESIGN_TYPE,
  VOUCHER_CUSTOM_DESIGN_SIZE,
  VOUCHER_CODE_GENERATION,
  VOUCHER_STATIC_KEYWORD,
} = keys;

const { SMALL, LARGE } = customDesignSizeValues;

const BothDesign = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  companyName,
  touched,
  errors,
}) => {
  const polyglot = usePolyglot();
  const handleAddToList = (push, newValueName) => async event => {
    if (event.keyCode === 13) {
      await push(values[newValueName]);
      setFieldValue(newValueName, '');
    }
  };

  return (
    <>
      {values[VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM && (
        <Stack direction="column" spaceAfter="large">
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.vouchers.voucher_design_size')}
          </Text>
          <LabelsRowWrapper>
            <Radio
              label={polyglot.t('smart_pass.form.vouchers.voucher_design_small')}
              name={VOUCHER_CUSTOM_DESIGN_SIZE}
              value={SMALL}
              checked={values[VOUCHER_CUSTOM_DESIGN_SIZE] === SMALL}
              onChange={handleChange}
              hasError={touched[VOUCHER_CUSTOM_DESIGN_SIZE] && errors[VOUCHER_CUSTOM_DESIGN_SIZE]}
            />
            <Radio
              label={polyglot.t('smart_pass.form.vouchers.voucher_design_large')}
              name={VOUCHER_CUSTOM_DESIGN_SIZE}
              value={LARGE}
              checked={values[VOUCHER_CUSTOM_DESIGN_SIZE] === LARGE}
              onChange={handleChange}
              hasError={touched[VOUCHER_CUSTOM_DESIGN_SIZE] && errors[VOUCHER_CUSTOM_DESIGN_SIZE]}
            />
          </LabelsRowWrapper>
          {values[VOUCHER_CUSTOM_DESIGN_SIZE] === LARGE && (
            <FileWithPreview>
              <FileInput
                name={VOUCHER_LARGE}
                setFieldValue={setFieldValue}
                value={values[VOUCHER_LARGE]}
                spaceAfter="large"
                exactImageWidth={812}
                exactImageHeight={296}
                companyName={companyName}
                label={polyglot.t('smart_pass.form.vouchers.voucher_design_large')}
                help={polyglot.t('smart_pass.form.vouchers.voucher_design_large_help')}
                error={
                  touched[VOUCHER_LARGE] &&
                  errors[VOUCHER_LARGE] &&
                  polyglot.t(errors[VOUCHER_LARGE])
                }
              />
            </FileWithPreview>
          )}

          {values[VOUCHER_CUSTOM_DESIGN_SIZE] === SMALL && (
            <FileWithPreview>
              <FileInput
                name={VOUCHER_SMALL}
                setFieldValue={setFieldValue}
                value={values[VOUCHER_SMALL]}
                spaceAfter="large"
                exactImageWidth={398}
                exactImageHeight={296}
                companyName={companyName}
                label={polyglot.t('smart_pass.form.vouchers.voucher_design_small')}
                help={polyglot.t('smart_pass.form.vouchers.voucher_design_small_help')}
                error={
                  touched[VOUCHER_SMALL] &&
                  errors[VOUCHER_SMALL] &&
                  polyglot.t(errors[VOUCHER_SMALL])
                }
              />
            </FileWithPreview>
          )}
        </Stack>
      )}
      {values[VOUCHER_TYPE] === voucherTypeValues.BAG_DEPOSIT && (
        <BagDeposit
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          handleAddToList={handleAddToList}
          errors={errors}
          touched={touched}
        />
      )}
      {values[VOUCHER_TYPE] === voucherTypeValues.LOUNGE_ACCESS && (
        <LoungeAccess
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleAddToList={handleAddToList}
          errors={errors}
          touched={touched}
        />
      )}
      <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.voucher_code')}</Text>
      <LabelsRowWrapper>
        <Radio
          label={polyglot.t('common.yes')}
          name={VOUCHER_CODE_PLACED}
          value={yesNoValues.YES}
          checked={values[VOUCHER_CODE_PLACED] === yesNoValues.YES}
          onChange={handleChange}
          hasError={touched[VOUCHER_CODE_PLACED] && errors[VOUCHER_CODE_PLACED]}
        />
        <Radio
          label={polyglot.t('common.no')}
          name={VOUCHER_CODE_PLACED}
          value={yesNoValues.NO}
          checked={values[VOUCHER_CODE_PLACED] === yesNoValues.NO}
          onChange={handleChange}
          hasError={touched[VOUCHER_CODE_PLACED] && errors[VOUCHER_CODE_PLACED]}
        />
      </LabelsRowWrapper>

      {values[VOUCHER_CODE_PLACED] === yesNoValues.YES && (
        <>
          <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.code_type')}</Text>
          <Stack spaceAfter="large">
            <Radio
              label={polyglot.t('smart_pass.form.vouchers.code_type.qr_code')}
              name={VOUCHER_CODE_TYPE}
              checked={values[VOUCHER_CODE_TYPE] === voucherCodeTypeValues.QR_CODE}
              value={voucherCodeTypeValues.QR_CODE}
              onChange={handleChange}
              hasError={touched[VOUCHER_CODE_TYPE] && errors[VOUCHER_CODE_TYPE]}
            />
            <Radio
              label={polyglot.t('smart_pass.form.vouchers.code_type.ean_code')}
              name={VOUCHER_CODE_TYPE}
              checked={values[VOUCHER_CODE_TYPE] === voucherCodeTypeValues.EAN_13_CODE}
              value={voucherCodeTypeValues.EAN_13_CODE}
              onChange={e => {
                handleChange(e);
                setFieldValue(VOUCHER_CODE_GENERATION, '');
              }}
              hasError={touched[VOUCHER_CODE_TYPE] && errors[VOUCHER_CODE_TYPE]}
            />
            <Radio
              label={polyglot.t('smart_pass.form.vouchers.code_type.pdf417')}
              name={VOUCHER_CODE_TYPE}
              checked={values[VOUCHER_CODE_TYPE] === voucherCodeTypeValues.PDF417}
              value={voucherCodeTypeValues.PDF417}
              onChange={handleChange}
              hasError={touched[VOUCHER_CODE_TYPE] && errors[VOUCHER_CODE_TYPE]}
            />
          </Stack>
          {[voucherCodeTypeValues.QR_CODE, voucherCodeTypeValues.PDF417].includes(
            values[VOUCHER_CODE_TYPE],
          ) && (
            <>
              <Text spaceAfter="small">{polyglot.t('smart_pass.form.code_generated')}</Text>
              <Stack spaceAfter="large">
                <Radio
                  name={VOUCHER_CODE_GENERATION}
                  checked={values[VOUCHER_CODE_GENERATION] === dynamicStaticValues.DYNAMIC}
                  value={dynamicStaticValues.DYNAMIC}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_CODE_GENERATION] && errors[VOUCHER_CODE_GENERATION]}
                  label={
                    <Stack direction="row" align="center" spacing="XXSmall">
                      <Text>{polyglot.t('smart_pass.form.dynamic')}</Text>
                      <Tooltip
                        size="small"
                        content={
                          <>
                            <Text spaceAfter="normal">
                              {polyglot.t('smart_pass.form.vouchers.dynamic_tooltip_1')}
                            </Text>
                            <Text>{polyglot.t('smart_pass.form.vouchers.dynamic_tooltip_2')}</Text>
                            <Text>{polyglot.t('smart_pass.form.vouchers.dynamic_tooltip_3')}</Text>
                            <Text>{polyglot.t('smart_pass.form.vouchers.dynamic_tooltip_4')}</Text>
                          </>
                        }
                      >
                        <InformationCircleIcon />
                      </Tooltip>
                    </Stack>
                  }
                />
                <Radio
                  label={polyglot.t('smart_pass.form.static')}
                  name={VOUCHER_CODE_GENERATION}
                  checked={values[VOUCHER_CODE_GENERATION] === dynamicStaticValues.STATIC}
                  value={dynamicStaticValues.STATIC}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_CODE_GENERATION] && errors[VOUCHER_CODE_GENERATION]}
                />
              </Stack>
            </>
          )}
          {values[VOUCHER_CODE_TYPE] === voucherCodeTypeValues.EAN_13_CODE && (
            <>
              <Text spaceAfter="small">
                {polyglot.t('smart_pass.form.vouchers.ean_13_enter_code')}
              </Text>
              <HalfWidthWrapper>
                <InputField
                  placeholder={polyglot.t('smart_pass.form.vouchers.ean_code_placeholder')}
                  suffix={
                    <Tooltip content={polyglot.t('smart_pass.form.vouchers.ean_code_tooltip')}>
                      <IconSuffix>
                        <InformationCircleIcon />
                      </IconSuffix>
                    </Tooltip>
                  }
                  spaceAfter="large"
                  name={VOUCHER_EAN_CODE}
                  value={values[VOUCHER_EAN_CODE]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[VOUCHER_EAN_CODE] &&
                    errors[VOUCHER_EAN_CODE] &&
                    polyglot.t(errors[VOUCHER_EAN_CODE])
                  }
                />
              </HalfWidthWrapper>
            </>
          )}
          {values[VOUCHER_CODE_GENERATION] === dynamicStaticValues.STATIC && (
            <>
              <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.static_text')}</Text>
              <HalfWidthWrapper>
                <InputField
                  placeholder={polyglot.t('smart_pass.form.vouchers.static_placeholder')}
                  name={VOUCHER_STATIC_KEYWORD}
                  value={values[VOUCHER_STATIC_KEYWORD]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  spaceAfter="large"
                  error={
                    touched[VOUCHER_STATIC_KEYWORD] &&
                    errors[VOUCHER_STATIC_KEYWORD] &&
                    polyglot.t(errors[VOUCHER_STATIC_KEYWORD])
                  }
                />
              </HalfWidthWrapper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BothDesign;
