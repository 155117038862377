import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useCompanyNames from 'components/services/companies/useCompanyNames';
import { usePolyglot } from 'components/services/i18n';
import { Autocomplete } from 'common';

const Wrapper = styled.div`
  max-width: 100%;
`;

const SelectCompany = ({
  companiesFromProps,
  onSelect,
  value,
  label,
  size,
  fullWidth,
  disabled = false,
}) => {
  const { data: companyNames } = useCompanyNames();
  const polyglot = usePolyglot();

  const companyOptions = React.useMemo(
    () =>
      (companiesFromProps || companyNames || []).map(name => ({
        label: name,
        value: name,
      })),
    [companyNames, companiesFromProps],
  );

  return (
    <Wrapper>
      <Autocomplete
        options={companyOptions}
        onSelect={c => {
          onSelect(c.value);
        }}
        value={value}
        label={polyglot.t('partner_payments.select_company')}
        noResultsText={polyglot.t('common.select_app_company.no_results_text')}
        size={size}
        fullWidth={fullWidth}
        loading={!companyNames}
        disabled={disabled}
      />
    </Wrapper>
  );
};

SelectCompany.defaultProps = {
  size: 'normal',
  onSelect: () => {},
  value: null,
};

SelectCompany.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  onSelect: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(SelectCompany);
