import React from 'react';
import { Switch, Route } from 'react-router-dom';

import WidgetBuilder from './scenes/WidgetBuilder';
import List from './scenes/List';
import { WidgetsContextProvider } from 'components/services/widgets';

const Widgets = () => {
  return (
    <WidgetsContextProvider>
      <Switch>
        <Route exact path="/widgets/:companyName/edit/:widgetId" component={WidgetBuilder} />
        <Route exact path="/widgets/:companyName/new" component={WidgetBuilder} />
        <Route exact path="/widgets" component={List} />
      </Switch>
    </WidgetsContextProvider>
  );
};

export default React.memo(Widgets);
