import styled from 'styled-components';

export const Container = styled.div`
  width: 880px;
  margin-top: 24px;
  margin-left: 24px;
  padding-bottom: 24px;
`;

export const ProductInfo = styled.div`
  span {
    font-size: 20px;
    font-weight: 700;

    &:first-of-type {
      color: ${({ theme }) => theme.orbit.colorTextSecondary};
    }

    &:last-of-type {
      margin-left: 2px;
    }
  }
`;
