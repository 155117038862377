import React from 'react';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import BackButtonStyle from './index.styled';

const BackButton = ({ route }) => {
  return (
    <BackButtonStyle to={route} icon="true" backButton>
      <ChevronBackwardIcon />
    </BackButtonStyle>
  );
};

export default BackButton;
