const v1_post_to = (issue, serviceDesk) => {
  return {
    description: issue.description,
    project: serviceDesk,
    request_type_id: issue.requestType,
    summary: issue.subject,
    ...(issue.bid && { bid: +issue.bid }),
    ...(issue.subcategory && {
      subcategory: {
        field: issue.subcategory.field,
        value: issue.subcategory.value,
      },
    }),
  };
};

export default {
  v1: {
    post: {
      to: v1_post_to,
    },
  },
};
