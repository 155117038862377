import React, { useState } from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';

import { ContentBoxWrapper, RouteListingBoxWrapper } from '../../index.styled';
import RouteGroups from '../RouteGroups';
import Seatings from '../Seatings';
import SummarySection from '../SummarySection';

const SeatingDrawerContent = ({ selectedRouteId, closeDrawer, openCloseModal }) => {
  const [routeId, setRouteId] = useState(selectedRouteId);

  return (
    <Stack direction="row" align="start">
      <RouteListingBoxWrapper>
        <RouteGroups routeId={routeId} onSelect={setRouteId} />
        <SummarySection closeDrawer={closeDrawer} openCloseModal={openCloseModal} />
      </RouteListingBoxWrapper>
      <ContentBoxWrapper>
        <Seatings routeId={routeId} />
      </ContentBoxWrapper>
    </Stack>
  );
};

export default SeatingDrawerContent;
