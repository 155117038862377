import { v1 as uuid } from 'uuid';
import expand from 'utils/expand';
import { MESSAGE_TYPES } from 'consts/messages';

const getActionTypes = expand('messages');

const { ADD_MESSAGE } = getActionTypes('ADD_MESSAGE');
const { CLEAR_MESSAGE } = getActionTypes('CLEAR_MESSAGE');
const { CLEAR_ERROR_MESSAGES } = getActionTypes('CLEAR_ERROR_MESSAGES');
const { CLEAR_ALL_MESSAGES } = getActionTypes('CLEAR_ALL_MESSAGES');

const initialState = {
  items: [],
};

/**
 * This module holds data for various messages displayed in the app.
 * They are primary used for success, info and submiting error messages.
 * Loading error messages are handled locally.
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        items: [...state.items, action.message],
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        items: state.items.filter(message => message.id !== action.id),
      };
    case CLEAR_ERROR_MESSAGES:
      return {
        ...state,
        items: state.items.filter(message => message.type !== MESSAGE_TYPES.ERROR),
      };
    case CLEAR_ALL_MESSAGES:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
}

export const addMessage = type => message => ({
  type: ADD_MESSAGE,
  message: { text: message, type, id: uuid() },
});

export const clearMessage = id => ({ type: CLEAR_MESSAGE, id });

export const clearErrorMessages = () => ({
  type: CLEAR_ERROR_MESSAGES,
});

export const clearAllMessages = () => ({
  type: CLEAR_ALL_MESSAGES,
});
