import React, { useState } from 'react';
import * as dfs from 'date-fns';

import Button from '@kiwicom/orbit-components/lib/Button';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Text from '@kiwicom/orbit-components/lib/Text';

import * as dateUtils from 'utils/dates';
import * as dateConstants from 'consts/dates';
import usePolyglot from 'components/services/i18n/usePolyglot';

import DateRangeSelect from './DateRangeSelect';

import {
  TitleWrapper,
  Calendar,
  ActionsMenu,
  CompareCalendarGlobals,
  FlexWrapper,
  CompareWrapper,
} from './Calendar.styled';

const CompareCalendar = ({
  initialRangeInterval,
  onHide,
  onCancel,
  canCompare,
  onApply,
  days = [7, 30, 60, 90, 365, 'Custom'],
  ...props
}) => {
  const [rangeInterval, setRangeInterval] = useState(initialRangeInterval);
  const [rangeIntervalCompare, setRangeIntervalCompare] = useState(dateConstants.CUSTOM);
  const [date, setDate] = useState(
    (props.dates?.length && props.dates.map(d => new Date(d))) || [dateUtils.getYesterday()],
  );
  const [datesToCompare, setDatesToCompare] = useState(
    (props.datesToCompare?.length && props.datesToCompare.map(d => new Date(d))) || [
      dateUtils.getYesterday(),
    ],
  );
  const polyglot = usePolyglot();

  const handleChangeRange = (dates, dateRangeInterval) => {
    setRangeInterval(dateRangeInterval);
    setDate(dates);
  };

  const handleChangeCompare = key => {
    setRangeIntervalCompare(key);

    switch (key) {
      case dateConstants.PREV_PERIOD:
        return handleCompareToPrevPeriod();
      case dateConstants.PREV_YEAR:
        return handleCompareToPrevYear();
      default:
        return null;
    }
  };

  const handleCompareToPrevPeriod = () => {
    setDatesToCompare(dateUtils.getPreviousPeriod(date));
  };

  const handleCompareToPrevYear = () => {
    setDatesToCompare(dateUtils.getPreviousYear(date));
  };

  const handleSelectDates = dates => {
    setDate(dates);
    setRangeInterval(dateConstants.CUSTOM);
  };

  const handleSelectDatesToCompare = dates => {
    setDatesToCompare(dates);
    setRangeIntervalCompare(dateConstants.CUSTOM);
  };

  const handleApply = () => {
    onApply(date, datesToCompare, rangeInterval, canCompare);
  };

  return (
    <Modal closable onClose={onHide} size="normal">
      <CompareCalendarGlobals />
      <ModalSection>
        <>
          {!canCompare && (
            <>
              <TitleWrapper>
                <div>
                  <DateRangeSelect days={days} value={rangeInterval} onChange={handleChangeRange} />
                  <FlexWrapper>
                    <Text size="large">
                      {polyglot.t('common.from')}: {dateUtils.getFirstDate(date)}
                    </Text>
                    <Text size="large">
                      {polyglot.t('common.to')}: {dateUtils.getLastDate(date)}
                    </Text>
                  </FlexWrapper>
                </div>
              </TitleWrapper>
              <Separator spaceAfter="large" />
              <Calendar
                visibleMonths={2}
                firstMonth={dfs.subYears(new Date(), 4)}
                lastMonth={new Date()}
                selectedDates={date}
                selectDates={handleSelectDates}
                rangeSelect
                disabledDates={[new Date()]}
                classes={{
                  month: 'compare-calender-month',
                  calendarWrapper: 'compare-calendar-wrapper',
                }}
                future={false}
              />
            </>
          )}
          {canCompare && (
            <>
              <TitleWrapper>
                <FlexWrapper direction="column">
                  <DateRangeSelect
                    value={rangeIntervalCompare}
                    onChange={handleChangeCompare}
                    isComparing
                  />
                  <FlexWrapper>
                    <Text size="large">
                      {polyglot.t('common.from')}: {dateUtils.getFirstDate(date)}
                    </Text>
                    <Text size="large">
                      {polyglot.t('common.to')}: {dateUtils.getLastDate(date)}
                    </Text>
                  </FlexWrapper>
                </FlexWrapper>
                <CompareWrapper>
                  <Heading type="title3" spaceAfter="small">
                    {polyglot.t('common.comparing_to')}
                  </Heading>
                  <Text size="large" align="center">
                    {polyglot.t('common.from')}: {dateUtils.getFirstDate(datesToCompare)}
                  </Text>
                  <Text size="large" align="center">
                    {polyglot.t('common.to')}: {dateUtils.getLastDate(datesToCompare)}
                  </Text>
                </CompareWrapper>
              </TitleWrapper>
              <Calendar
                visibleMonths={2}
                firstMonth={dfs.subYears(new Date(), 4)}
                lastMonth={new Date()}
                selectedDates={datesToCompare}
                rangeSelect
                selectDates={handleSelectDatesToCompare}
                classes={{
                  month: 'compare-calender-month',
                  calendarWrapper: 'compare-calendar-wrapper',
                }}
                disabledDates={[new Date()]}
                future={false}
              />
            </>
          )}
          <ActionsMenu>
            <Button width="128px" type="secondary" onClick={onHide}>
              {polyglot.t('common.cancel')}
            </Button>
            <Button width="128px" onClick={handleApply} disabled={!date.length}>
              {polyglot.t('common.apply')}
            </Button>
          </ActionsMenu>
        </>
      </ModalSection>
    </Modal>
  );
};

export default CompareCalendar;
