import React, { useContext } from 'react';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { newSmartPassInitialValues } from 'consts/smartPass';

const DeactivateModal = ({ setFieldValue, setFieldTouched }) => {
  const { deactivateModal, changeDeactivateModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();

  const handleDeactivateClick = () => {
    deactivateModal.keys.forEach(key => {
      setFieldValue(key, newSmartPassInitialValues[key]);
      // formik validation fix:
      setTimeout(() => setFieldTouched(key, true));
    });
    changeDeactivateModal(null);
  };

  const handleOnClose = () => {
    changeDeactivateModal(null);
  };

  return (
    <Modal size="small" closable onClose={handleOnClose}>
      <ModalHeader
        title={polyglot.t('smart_pass.form.deactivate_title', { name: deactivateModal.name })}
        description={polyglot.t('smart_pass.form.deactivate_title_descr')}
      />
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button onClick={handleOnClose} type="criticalSubtle">
            {polyglot.t('common.cancel')}
          </Button>
          <Button type="critical" onClick={handleDeactivateClick}>
            {polyglot.t('smart_pass.form.deactivate')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DeactivateModal;
