import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import Box from '@kiwicom/orbit-components/lib/Box';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Inline from '@kiwicom/orbit-components/lib/Inline';

import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';
import { useCurrency } from 'components/services/currencies';
import { precisionRound } from 'utils/number';

import SeatPopoverAlert from './SeatPopoverAlert';
import SeatPopoverPassenger from './SeatPopoverPassenger';
import { Wrapper } from './index.styled';

const SeatPopover = ({
  onSelectSeat,
  onPopoverClose,
  seat,
  isEmergencyExit,
  activeSegmentCode,
}) => {
  const polyglot = usePolyglot();
  const { watch } = useFormContext();
  const { passengers } = watch();
  const isSinglePassenger = passengers.length === 1;
  const { getFormattedPriceString } = useCurrency();
  const { currentSeatings } = useSeating();

  const createOnPassengerClick = useCallback(
    paxIndex => () => {
      onSelectSeat(seat.name, {
        passenger: {
          id: paxIndex,
          firstName: passengers[paxIndex].firstName,
          lastName: passengers[paxIndex].lastName,
        },
      });
      onPopoverClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Wrapper>
      <Stack spacing="small">
        <Box padding={{ right: 'medium' }} textAlign="center">
          <Heading as="h1" type="title2" spaceAfter="smallest" align="center">
            {isSinglePassenger
              ? polyglot.t('booking.additional_services.seating.popover.assign_seat_to_yourself', {
                  seatName: seat.name,
                })
              : polyglot.t('booking.additional_services.seating.popover.assign_seat_to', {
                  seatName: seat.name,
                })}
          </Heading>
          <Inline align="center" spacing="XXSmall" justify="center">
            <Text type="secondary" spaceAfter="medium">
              {polyglot.t('booking.additional_services.seating.popover.price', {
                price: getFormattedPriceString(precisionRound(seat.price.amount, 2)),
              })}
            </Text>
            <Text type="secondary" spaceAfter="medium">
              {polyglot.t(`booking.additional_services.seating.popover.type.${seat.type}`)}
            </Text>
          </Inline>
        </Box>
        {isEmergencyExit && <SeatPopoverAlert />}
        {!isSinglePassenger && (
          <Stack spacing="none">
            {passengers.map((passenger, i) => (
              <SeatPopoverPassenger
                key={`${passenger.firstName}-${passenger.lastName}-${i}`}
                underlined={passengers.length === i + 1}
                passenger={passenger}
                selectedSeatName={
                  Object.values(currentSeatings[activeSegmentCode] || {}).find(
                    it => it.passenger.id === i,
                  )?.name
                }
                onPassengerClick={createOnPassengerClick(i)}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};

export default SeatPopover;
