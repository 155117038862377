const v1_put_from = (solution, apiProducts) => {
  return {
    name: solution.name,
    productType: apiProducts?.find(product => solution.api_product_id === product.id)?.value,
    affilId: solution.affiliate_id,
    consumerKey: solution.api_key,
    status: solution.status,
    approvalDate: new Date(solution?.created_at),
    intendedUse: solution.intended_use,
    uuid: solution.uuid,
    webhookProduction: solution.webhook_production,
    webhookStaging: solution.webhook_staging,
    csEmail: solution.cs_email,
    quotaConfig: {
      quota: solution.quota_config.quota,
      quotaInterval: solution.quota_config.quota_interval,
      quotaTimeUnit: solution.quota_config.quota_time_unit,
    },
  };
};

const v1_put_to = (solutionDetails, canEditQuota) => {
  return {
    ...(solutionDetails.webhookProduction
      ? { webhook_production: solutionDetails.webhookProduction }
      : {}),
    ...(solutionDetails.webhookStaging ? { webhook_staging: solutionDetails.webhookStaging } : {}),
    ...(canEditQuota
      ? {
          quota_config: {
            quota: solutionDetails.quota,
            quota_interval: Number(solutionDetails.quotaInterval),
            quota_time_unit: solutionDetails.quotaTimeUnit,
          },
        }
      : {}),
    intended_use: solutionDetails.intended_use,
  };
};

export default {
  v1: {
    put: {
      to: v1_put_to,
      from: v1_put_from,
    },
  },
};
