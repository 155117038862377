import React from 'react';

import {
  BaggageRecheck,
  Lounge,
  Shopping,
  Restaurant,
  Accommodation,
} from '@kiwicom/orbit-components/lib/icons';

import {
  voucherTypeValues,
  voucherCodeTypeValues,
  fastTrackVoucherCodeTypeValues,
  voucherDiscountTypeValues,
  voucherBagDepositServiceLocationValues,
  loungeLocationValues,
  designTypeValues,
  keys,
  customDesignSizeValues,
  fastTrackVoucherFullfilmentValues,
  yesNoValues,
  terminalsValues,
  previewTypeKeys,
} from 'consts/smartPass';
import { euroSign } from 'consts/symbols';

import { ReactComponent as QRCodeSvg } from '../../../../../images/QR_Code.svg';
import { ReactComponent as EANCodeSvg } from '../../../../../images/EAN_13_Code.svg';
import { ReactComponent as StandardPDFSvg } from '../../../../../images/StandardPDF.svg';
import { ReactComponent as CustomPDFSvg } from '../../../../../images/CustomPDF.svg';
import { ReactComponent as FastTrackPDFSvg } from '../../../../../images/FastTrackPDF.svg';

const StandardScanCodeValues = {
  [voucherCodeTypeValues.QR_CODE]: <QRCodeSvg style={{ marginTop: 8, marginLeft: 8 }} />,
  [voucherCodeTypeValues.EAN_13_CODE]: <EANCodeSvg style={{ marginTop: 20, marginLeft: 26 }} />,
  [voucherCodeTypeValues.PDF417]: <StandardPDFSvg style={{ marginTop: 20, marginLeft: 26 }} />,
};

const baggageLocationTranslations = {
  [voucherBagDepositServiceLocationValues.ARRIVAL_AREA]:
    'smart_pass.form.vouchers_preview_arrival_area',
  [voucherBagDepositServiceLocationValues.DEPARTURE_AREA]:
    'smart_pass.form.vouchers_preview_departure_area',
};

const loungeLocationTranslations = {
  [loungeLocationValues.AIRSIDE]: 'smart_pass.form.vouchers_preview_airside',
  [loungeLocationValues.LANDSIDE]: 'smart_pass.form.vouchers_preview_landside',
};

export const toStandardPreviewInfo = (voucherInfo, smartPassInfo) => {
  const voucherValuesByType = {
    [voucherTypeValues.SHOP]: {
      icon: <Shopping />,
      pluralName: 'smart_pass.form.vouchers_preview_plural_shop',
    },
    [voucherTypeValues.RESTAURANT]: {
      icon: <Restaurant />,
      pluralName: 'smart_pass.form.vouchers_preview_plural_restaurant',
    },
    [voucherTypeValues.HOTEL]: {
      icon: <Accommodation />,
      pluralName: 'smart_pass.form.vouchers_preview_plural_hotel',
    },
    [voucherTypeValues.BAG_DEPOSIT]: {
      icon: <BaggageRecheck />,
      location: baggageLocationTranslations[voucherInfo[keys.VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]],
      terminalsList:
        voucherInfo[keys.VOUCHER_BAG_DEPOSIT_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS
          ? voucherInfo[keys.VOUCHER_BAG_DEPOSIT_TERMINALS_LIST]
          : [],
    },
    [voucherTypeValues.LOUNGE_ACCESS]: {
      icon: <Lounge />,
      location: loungeLocationTranslations[voucherInfo[keys.VOUCHER_LOUNGE_LOCATION]],
      terminalsList:
        voucherInfo[keys.VOUCHER_LOUNGE_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS
          ? voucherInfo[keys.VOUCHER_LOUNGE_TERMINALS_LIST]
          : [],
    },
  };

  const getCurrencySimbol = () => {
    const voucherCurrency = voucherInfo[keys.VOUCHER_DISCOUNT_CURRENCY];
    if (voucherCurrency === 'EUR') return euroSign;
    else return voucherCurrency;
  };

  const currencySymbol = getCurrencySimbol();
  const currencyAmmount = voucherInfo[keys.VOUCHER_DISCOUNT_AMOUNT];

  const discountTextValues = {
    [voucherDiscountTypeValues.AMOUNT]: `
  ${currencySymbol}${currencyAmmount}`,
    [voucherDiscountTypeValues.PERCENTAGE]: `
    ${voucherInfo[keys.VOUCHER_DISCOUNT_PERCENTAGE]}%`,
  };

  const firstName = voucherInfo.voucher_place_name_1;

  const placeNames = [
    voucherInfo.voucher_place_name_1,
    voucherInfo.voucher_place_name_2,
    voucherInfo.voucher_place_name_3,
    voucherInfo.voucher_place_name_4,
  ].filter(place => place);

  const voucherType = voucherInfo[keys.VOUCHER_TYPE];
  const voucherDiscountType = voucherInfo[keys.VOUCHER_DISCOUNT_TYPE];
  const voucher = voucherValuesByType[voucherType];
  const isCodePlaced = voucherInfo[keys.VOUCHER_CODE_PLACED] === yesNoValues.YES;
  const scanCodeSvg = isCodePlaced
    ? StandardScanCodeValues[voucherInfo[keys.VOUCHER_CODE_TYPE]]
    : null;
  const pluralName = voucher.pluralName;
  const isBaggageDeposit = voucherType === voucherTypeValues.BAG_DEPOSIT;
  const isLoungeAccess = voucherType === voucherTypeValues.LOUNGE_ACCESS;
  const isStandardDesign = designTypeValues.STANDARD === voucherInfo[keys.VOUCHER_DESIGN_TYPE];

  const discountText = discountTextValues[voucherDiscountType];

  return {
    discountText,
    location: voucher.location,
    terminalsList: voucher.terminalsList || [],
    firstName,
    pluralName,
    placeNames,
    url: voucherInfo[keys.VOUCHER_URL],
    scanCodeSvg,
    icon: voucher.icon,
    smartpassLogoUrl: smartPassInfo[keys.SMART_PASS_LOGO]?.url,
    airportLogoUrl: smartPassInfo[keys.AIRPORT_LOGO]?.url,
    isBaggageDeposit,
    isLoungeAccess,
    isStandardDesign,
  };
};

const customScanCodeValues = {
  [voucherCodeTypeValues.QR_CODE]: (
    <QRCodeSvg style={{ position: 'absolute', top: 20, right: 20 }} />
  ),
  [voucherCodeTypeValues.EAN_13_CODE]: (
    <EANCodeSvg style={{ position: 'absolute', top: 20, right: 14 }} />
  ),
  [voucherCodeTypeValues.PDF417]: (
    <CustomPDFSvg style={{ position: 'absolute', top: 20, right: 20 }} />
  ),
};

const toCustomPreviewInfo = voucherInfo => {
  const voucherScanCodeType = voucherInfo[keys.VOUCHER_CODE_TYPE];
  const scanCodeSvg = customScanCodeValues[voucherScanCodeType];

  const designSize = voucherInfo[keys.VOUCHER_CUSTOM_DESIGN_SIZE];
  let backgroundImageUrl;

  if (designSize === customDesignSizeValues.LARGE) {
    backgroundImageUrl = voucherInfo[keys.VOUCHER_LARGE].url;
  } else if (designSize === customDesignSizeValues.SMALL) {
    backgroundImageUrl = voucherInfo[keys.VOUCHER_SMALL].url;
  }

  return {
    backgroundImageUrl,
    scanCodeSvg,
  };
};

const fastTrackScanCodeValues = {
  [fastTrackVoucherCodeTypeValues.QR_CODE]: (
    <QRCodeSvg style={{ position: 'absolute', width: 120, height: 120, top: 24, left: 24 }} />
  ),
  [fastTrackVoucherCodeTypeValues.PDF417]: (
    <FastTrackPDFSvg style={{ position: 'absolute', bottom: 24, left: 24 }} />
  ),
};

const toFastTrackPreviewInfo = voucherInfo => {
  const backgroundImageUrl = voucherInfo[keys.FAST_TRACK_CUSTOM_VOUCHER_DESIGN]?.url;
  const scanCodeSvg = fastTrackScanCodeValues[voucherInfo[keys.FAST_TRACK_VOUCHER_CODE_TYPE]];

  const isCodePlaced =
    voucherInfo[keys.FAST_TRACK_VOUCHER_FULFILMENT] === fastTrackVoucherFullfilmentValues.AUTOMATED;

  return {
    backgroundImageUrl,
    scanCodeSvg: isCodePlaced ? scanCodeSvg : null,
  };
};

export const inferVoucherType = voucherInfo => {
  const isCustom = voucherInfo[keys.VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM;
  const isStandard = voucherInfo[keys.VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD;
  const isCustomLarge =
    isCustom && voucherInfo[keys.VOUCHER_CUSTOM_DESIGN_SIZE] === customDesignSizeValues.LARGE;
  const isCustomSmall =
    isCustom && voucherInfo[keys.VOUCHER_CUSTOM_DESIGN_SIZE] === customDesignSizeValues.SMALL;
  const isFastTrack = voucherInfo.isFastTrack;

  return {
    isStandard,
    isCustomLarge,
    isCustomSmall,
    isFastTrack,
  };
};

export const map = {
  toStandardPreviewInfo,
  toCustomPreviewInfo,
  toFastTrackPreviewInfo,
};

export const getPreviewType = voucherInfo => {
  if (voucherInfo[keys.VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD)
    return previewTypeKeys.STANDARD;
  if (
    voucherInfo[keys.VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM &&
    voucherInfo[keys.VOUCHER_CUSTOM_DESIGN_SIZE] === customDesignSizeValues.LARGE
  )
    return previewTypeKeys.CUSTOM_LARGE;
  if (
    voucherInfo[keys.VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM &&
    voucherInfo[keys.VOUCHER_CUSTOM_DESIGN_SIZE] === customDesignSizeValues.SMALL
  )
    return previewTypeKeys.CUSTOM_SMALL;
};
