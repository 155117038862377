import { ANCILLARIES } from 'consts/mmb';

const v1_post_to = (currency, sessionId, ancillary = ANCILLARIES.SEATING) => {
  return {
    ancillaries: [ancillary],
    currency,
    ...(sessionId ? { session_id: sessionId } : {}),
  };
};

const v1_post_from = data => {
  if (data) {
    return data.offers.map(offer => ({
      isFinal: offer.is_final,
      loyaltyOption: offer.loyalty_option,
      priceSource: offer.price_source,
      quickOptions: offer.quick_options.map(quickOption => ({
        option: quickOption.option,
        price: {
          amount: Number(quickOption.price.amount),
          base: Number(quickOption.price.base),
          currency: quickOption.price.currency,
          merchant: Number(quickOption.price.merchant),
          service: Number(quickOption.price.service),
          serviceFlat: Number(quickOption.price.service_flat),
        },
      })),
      segmentCode: offer.segment_code,
    }));
  }

  return null;
};

export default {
  v1: {
    post: {
      to: v1_post_to,
      from: v1_post_from,
    },
  },
};
