import styled from 'styled-components';

import { Space } from 'common';

export const NomadContainer = styled.div`
  width: 880px;
`;

export const NomadSearchbarRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > ${Space}, & > ${Space} > div {
    width: 436px;
  }
`;

export const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReturnContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > div {
    width: 436px;
  }
`;
