import { useContext, useEffect } from 'react';

import { ResourcesState } from './';

const useRegisterTOS = () => {
  const {
    state: { registerTOS },
    fetchRegisterTOS,
  } = useContext(ResourcesState);
  useEffect(() => {
    if (!registerTOS.data) {
      fetchRegisterTOS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return registerTOS;
};

export default useRegisterTOS;
