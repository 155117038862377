import { SEAT_MAP_SAVE_BOOKING_OPTION_NAME } from 'consts/seating';
import { isProduction } from 'utils/env';
import { formatPhonePrefix } from 'utils/functions';

const mapBags = (bags, category, chosenBags) => {
  return bags
    .map(bag => {
      if (bag.index in chosenBags)
        return {
          combination: {
            category,
            conditions: {
              passenger_groups: bag.conditions.passengerGroups,
            },
            price: {
              currency: bag.price.currency,
              amount: bag.price.amount,
              base: bag.price.base,
              service: bag.price.service,
              service_flat: bag.price.serviceFlat,
              merchant: bag.price.merchant,
            },
            indices: bag.indices,
          },
          passengers: chosenBags[bag.index],
        };
      else return null;
    })
    .filter(item => item !== null);
};

const v2_post_to = (params, seatings, baggage, paidGuarantee) => {
  const chosenHoldBaggage = {};
  const chosenHandBaggage = {};
  const seating = [];

  let addedContactDetails = false;
  const passengers = params.passengers.map((item, index) => {
    if (item.baggage.holdBag >= 0) {
      // Passengers field in baggage need to be array an of passengers that have selected that particular baggage
      if (!(item.baggage.holdBag in chosenHoldBaggage)) {
        chosenHoldBaggage[item.baggage.holdBag] = [];
        chosenHoldBaggage[item.baggage.holdBag].push(index);
      } else {
        chosenHoldBaggage[item.baggage.holdBag].push(index);
      }
    }

    if (item.baggage.handBag >= 0) {
      if (!(item.baggage.handBag in chosenHandBaggage)) {
        chosenHandBaggage[item.baggage.handBag] = [];
        chosenHandBaggage[item.baggage.handBag].push(index);
      } else {
        chosenHandBaggage[item.baggage.handBag].push(index);
      }
    }

    const shouldAdd = !addedContactDetails && item.category === 'adult';

    if (shouldAdd) {
      addedContactDetails = true;
    }

    return {
      birthday: item.dateOfBirth,
      category: item.category,
      cardno: item.passportId || '',
      expiration: item.noExpiry ? null : item.expiryDate,
      ...(shouldAdd
        ? {
            email: params.depositEmail,
            phone: `${formatPhonePrefix(params.contactDetails.countryId)}${
              params.contactDetails.phoneSuffix
            }`,
          }
        : {}),
      name: item.firstName,
      surname: item.lastName,
      nationality: item.nationality,
      title: item.gender === 'male' ? 'mr' : 'ms',
    };
  });

  if (seatings) {
    Object.keys(seatings).forEach(routeId => {
      if (seatings[routeId].price) {
        seating.push({
          segment_code: routeId,
          ...seatings[routeId],
          price: {
            currency: seatings[routeId].price.currency,
            amount: seatings[routeId].price.amount.toString(),
            base: seatings[routeId].price.base.toString(),
            service: seatings[routeId].price.service.toString(),
            merchant: seatings[routeId].price.merchant.toString(),
            service_flat: seatings[routeId].price.serviceFlat.toString(),
          },
        });
      } else if (!seatings[routeId].option && Object.keys(seatings[routeId]).length) {
        seating.push({
          segment_code: routeId,
          option: SEAT_MAP_SAVE_BOOKING_OPTION_NAME,
          seats: Object.keys(seatings[routeId]).map(seat => ({
            seat: seatings[routeId][seat].name,
            passenger_idx: seatings[routeId][seat].passenger.id,
            price: {
              amount: seatings[routeId][seat].price.amount.toString(),
              base: seatings[routeId][seat].price.base.toString(),
              currency: seatings[routeId][seat].price.currency,
              merchant: seatings[routeId][seat].price.merchant.toString(),
              service: seatings[routeId][seat].price.service.toString(),
              service_flat: seatings[routeId][seat].price.serviceFlat.toString(),
            },
          })),
        });
      }
    });
  }

  const handBaggage = mapBags(baggage.handBag, 'hand_bag', chosenHandBaggage);
  const holdBaggage = mapBags(baggage.holdBag, 'hold_bag', chosenHoldBaggage);

  let paidGuaranteePriceBreakdown;
  if (paidGuarantee) {
    const { amountInSelectedCurrency, ...restOfPrice } = paidGuarantee.price;
    paidGuaranteePriceBreakdown = restOfPrice;
  }

  return {
    lang: 'en',
    currency: 'EUR',
    booking_token: params.bookingToken,
    session_id: params.sessionId,
    passengers,
    baggage: [...handBaggage, ...holdBaggage],
    ...(paidGuarantee || seatings
      ? {
          additional_services: {
            ...(paidGuarantee ? { guarantee: { price: paidGuaranteePriceBreakdown } } : {}),
            ...(seatings ? { seating } : {}),
          },
        }
      : {}),
    ...(isProduction() && {
      utm: {
        utm_source: 'tequila',
        utm_medium: 'booking_tool',
        utm_campaign: 'deposit',
      },
    }),
  };
};

export default {
  v2: {
    post: {
      to: v2_post_to,
      //   from: v2_get_from,
    },
  },
};
