import React from 'react';
import styled from 'styled-components';

import { usePolyglot } from 'components/services/i18n';
import countries from 'consts/countries';
import { capitalize } from 'utils/string';
import getOptions from 'components/scenes/ManageMyBookings/scenes/BookingDetails/services/getBaggageOptions';
import { getTextFromCategory } from 'components/scenes/ManageMyBookings/scenes/BookingDetails/services/utils';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';
import GenderWomanIcon from '@kiwicom/orbit-components/lib/icons/GenderWoman';
import GenderManIcon from '@kiwicom/orbit-components/lib/icons/GenderMan';
import CakeIcon from '@kiwicom/orbit-components/lib/icons/Cake';
import PassportIcon from '@kiwicom/orbit-components/lib/icons/Passport';

const CardContainer = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
`;

const PassengerDetails = styled.div`
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
`;

const PassengerDetailsRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const BagDetails = styled.div`
  flex: 1;
  padding-left: 16px;
`;

const PassengerCard = ({ passenger }) => {
  const country = countries.find(n => n.value === passenger.nationality)?.label;

  return (
    <CardContainer>
      <PassengerDetails>
        <Stack spacing="small">
          <PassengerDetailsRow>
            <IconWrapper>
              <CountryFlag size="small" code={passenger.nationality} />
            </IconWrapper>
            <Text size="small">{country}</Text>
          </PassengerDetailsRow>

          <PassengerDetailsRow>
            <IconWrapper>
              {passenger.gender === 'male' ? (
                <GenderManIcon size="small" color="secondary" />
              ) : (
                <GenderWomanIcon size="small" color="secondary" />
              )}
            </IconWrapper>
            <Text size="small">{capitalize(passenger.gender)}</Text>
          </PassengerDetailsRow>

          <PassengerDetailsRow>
            <IconWrapper>
              <CakeIcon size="small" color="secondary" />
            </IconWrapper>
            <Text size="small">{`${passenger.dateOfBirth} • ${capitalize(
              passenger.category,
            )}`}</Text>
          </PassengerDetailsRow>

          <PassengerDetailsRow>
            <IconWrapper>
              <PassportIcon size="small" color="secondary" />
            </IconWrapper>
            <Text size="small">
              {passenger.passportId} {!!passenger.expiryDate && `• ${passenger.expiryDate}`}
            </Text>
          </PassengerDetailsRow>
        </Stack>
      </PassengerDetails>

      <BagDetails>
        <Stack spacing="XSmall">
          {passenger?.bags.map((bag, i) => {
            if (bag.amount === undefined) {
              return null;
            }
            return (
              <div key={i}>
                <Text>{`${bag.amount}x ${bag.label}`}</Text>
                {(bag.dimensions || bag.weight) && (
                  <Text type="secondary" size="small">
                    {[bag.dimensions, bag.weight].join(' ')}
                  </Text>
                )}
              </div>
            );
          })}
        </Stack>
      </BagDetails>
    </CardContainer>
  );
};

const Passengers = ({ passengers, baggage }) => {
  const polyglot = usePolyglot();
  return (
    <Stack>
      {passengers?.map((pax, i) => {
        //if pax details weren't filled, don't show summary for that pax
        if (!pax.nationality || !pax.passportId) {
          return null;
        }
        // In form we only have index of selected bag, we need that option dimensions, amount and weight
        const selectedHoldBag = getOptions({
          baggage,
          passengerCategory: pax.category,
          pickerType: 'holdBag',
          currentCombination: pax.baggage?.holdBag,
        }).find(bag => {
          return bag.index === pax.baggage?.holdBag;
        });

        const selectedHandBag = getOptions({
          baggage,
          passengerCategory: pax.category,
          pickerType: 'handBag',
          currentCombination: pax.baggage?.handBag,
        }).find(bag => {
          return bag.index === pax.baggage?.handBag;
        });

        const bags = [];

        if (selectedHandBag) {
          Object.values(selectedHandBag?.items).forEach(bag => {
            const { restrictions } = bag;
            //if previous bag is the same, increase amount
            if (bags.length > 1 && bags[bags.length - 1].sum === restrictions.dimensionsSum) {
              bags[bags.length].amount += 1;
            } else {
              bags.push({
                amount: bag.amount,
                label: polyglot.t(getTextFromCategory(bag.category)),
                dimensions: `${restrictions?.width} x 
                ${restrictions?.height} x 
                ${restrictions?.length} cm`,
                weight: `${restrictions?.weight} kg`,
                sum: restrictions.dimensionsSum,
              });
            }
          });
        }

        if (selectedHoldBag) {
          Object.values(selectedHoldBag?.items).forEach(bag => {
            const { restrictions } = bag;
            //if previous bag is the same, increase amount
            if (bags.length > 1 && bags[bags.length - 1].sum === restrictions.dimensionsSum) {
              bags[bags.length - 1].amount += 1;
            } else {
              bags.push({
                amount: bag.amount,
                label: polyglot.t(getTextFromCategory(bag.category)),
                dimensions: `${restrictions?.width} x 
                ${restrictions?.height} x 
                ${restrictions?.length} cm`,
                weight: `${restrictions?.weight} kg`,
                sum: restrictions.dimensionsSum,
              });
            }
          });
        }

        return <PassengerCard key={i} passenger={{ ...pax, bags }} />;
      })}
    </Stack>
  );
};

export default Passengers;
