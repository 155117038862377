import React from 'react';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { formatTripDuration, calculateNightsLayover } from 'utils/dates';
import { ScrollableVertical } from 'common';

import CarriersStack from '../CarriersStack';
import { Wrapper, CenteredTextWrapper, DataWrapper, Line, FlexWrapper } from './index.styled';

const CityPart = ({ city, code, currentSegment, trip, multicity, align, fullWidth, last }) => {
  const polyglot = usePolyglot();
  if (multicity) {
    const numOfNights = calculateNightsLayover(
      trip[currentSegment].arrival.time.local,
      trip[currentSegment + 1].departure.time.local,
    );

    return (
      <Wrapper fullWidth={fullWidth}>
        {!!numOfNights && (
          <Badge type="info">
            {polyglot.t('common.trip_summary.nights', { number_of_nights: numOfNights })}
          </Badge>
        )}
        <Text type="secondary" size="small" align={align || 'center'} spaceAfter="small">
          {city}
        </Text>
        <Text type="primary" size="small" align={align || 'center'} weight="bold">
          {code}
        </Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper fullWidth={fullWidth} last={last}>
      <Text type="secondary" size="small" align={align || 'center'} spaceAfter="small">
        {city}
      </Text>
      <Text type="primary" size="small" align={align || 'center'} weight="bold">
        {code}
      </Text>
    </Wrapper>
  );
};

const TripSummaryDetails = ({ title, from, to, carriers, duration, type, fullTrip, dataTest }) => {
  const polyglot = usePolyglot();
  const stops = carriers.length - 1;
  const lowerCasedType = type.toLowerCase();
  const isReturnOrOneway = lowerCasedType !== 'multicity' && lowerCasedType !== 'nomad';

  if (isReturnOrOneway) {
    return (
      <Wrapper fullWidth={type !== 'one_way'} data-test={dataTest}>
        <Text size="small" type="secondary" weight="bold" spaceAfter="normal">
          {title}
        </Text>

        <CenteredTextWrapper>
          {!!stops && (
            <Text type="primary" size="small" weight="bold" dataTest="number-of-stops">
              {polyglot.t('common.trip_summary.stops', {
                number_of_stops: stops,
                stops: stops === 1 ? 'stop' : 'stops',
              })}
            </Text>
          )}
        </CenteredTextWrapper>
        <DataWrapper>
          <Line />
          <CityPart align="left" city={from.place.city} code={from.station.code} />
          <CarriersStack carriers={carriers} />
          <CityPart align="right" city={to.place.city} code={to.station.code} />
        </DataWrapper>
        <CenteredTextWrapper>
          <Text type="primary" size="small" weight="bold" spaceAfter="large">
            {formatTripDuration(duration / 1000)}
          </Text>
        </CenteredTextWrapper>
      </Wrapper>
    );
  }

  const flattenedTrip = fullTrip.reduce((acc, t) => {
    t.forEach(tr => acc.push(tr));
    return acc;
  }, []);

  const typeTitle = lowerCasedType === 'multicity' ? 'MULTICITY ' : 'NOMAD';

  return (
    <Wrapper fullWidth data-test={dataTest}>
      <Text size="small" type="secondary" weight="bold" spaceAfter="normal">
        {`${typeTitle} - ${flattenedTrip.length - 1} stops`}
      </Text>
      <ScrollableVertical>
        <FlexWrapper fullWidth={flattenedTrip.length < 5}>
          <CityPart
            fullWidth
            city={flattenedTrip[0].departure.place.city}
            code={flattenedTrip[0].departure.station.code}
          />
          <Line />
          {flattenedTrip.slice(0, flattenedTrip.length - 1).map((ft, i) => (
            <DataWrapper
              multicity
              shouldExpand={flattenedTrip.length < 4}
              autoWidth={ft.arrival.station.code.length < 4}
              key={i}
            >
              <CarriersStack
                carriers={[ft.operatingCarrier.code]}
                tooltips={[
                  {
                    code: ft.operatingCarrier.public_code,
                    number: ft.operatingCarrier.segment_code,
                  },
                ]}
              />
              <CityPart
                city={ft.arrival.place.city}
                code={ft.arrival.station.code}
                trip={flattenedTrip}
                multicity
                fullWidth
                currentSegment={i}
              />
            </DataWrapper>
          ))}
          <DataWrapper
            multicity
            autoWidth={flattenedTrip[flattenedTrip.length - 1].arrival.station.code.length < 4}
          >
            <CarriersStack
              carriers={[flattenedTrip[flattenedTrip.length - 1].operatingCarrier.code]}
              tooltips={[
                {
                  code: flattenedTrip[flattenedTrip.length - 1].operatingCarrier.public_code,
                  number: flattenedTrip[flattenedTrip.length - 1].operatingCarrier.segment_code,
                },
              ]}
            />
          </DataWrapper>
          <CityPart
            last
            fullWidth
            city={flattenedTrip[flattenedTrip.length - 1].arrival.place.city}
            code={flattenedTrip[flattenedTrip.length - 1].arrival.station.code}
          />
        </FlexWrapper>
      </ScrollableVertical>
    </Wrapper>
  );
};

export default TripSummaryDetails;
