import isPast from 'date-fns/isPast';
import isFuture from 'date-fns/isFuture';

import {
  BIDVIEWER_APP_ID,
  SMART_PASS_APP_ID,
  IP_WHITELIST,
  DEMAND_DASHBOARD_ID,
  DESTINATION_DEMAND_DASHBOARD_ID,
  DATA_MARKETPLACE_ID,
  REPORT_SCHEDULES_ID,
} from 'consts/specialApps';

const v1_get_from = specialApp => {
  return {
    ...(specialApp.special_app_id === REPORT_SCHEDULES_ID
      ? {
          config: {
            name: 'scheduled-reports',
            displayName: 'Report schedules',
          },
        }
      : {}),
    ...(specialApp.special_app_id === DEMAND_DASHBOARD_ID
      ? {
          name: 'demand-dashboard',
          displayName: 'Demand dashboard',
          config: {
            airports: specialApp.config.airports,
          },
        }
      : {}),
    ...(specialApp.special_app_id === DESTINATION_DEMAND_DASHBOARD_ID
      ? {
          name: 'destination-demand-dashboard',
          displayName: 'Destination demand dashboard',
          config: {
            cities: specialApp.config.destination_cities || [],
            countries: specialApp.config.destination_countries || [],
          },
        }
      : {}),
    ...([SMART_PASS_APP_ID, BIDVIEWER_APP_ID].includes(specialApp.special_app_id)
      ? {
          name: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'bidviewer' : 'smartpass',
          displayName: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'BID Viewer' : 'Smart Pass',
          config:
            specialApp.special_app_id === BIDVIEWER_APP_ID
              ? { includePassword: specialApp.config.include_password }
              : specialApp.config,
        }
      : {}),
    ...(specialApp.special_app_id === SMART_PASS_APP_ID
      ? {
          name: 'smartpass',
          displayName: 'Smart Pass',
        }
      : {}),
    ...(specialApp.special_app_id === IP_WHITELIST
      ? {
          name: 'ip-whitelisting',
          displayName: 'IP allowlist',
        }
      : {}),
    ...(specialApp.special_app_id === DATA_MARKETPLACE_ID
      ? { name: 'data-marketplace', displayName: 'Data marketplace' }
      : {}),
    id: specialApp.id,
    definitionId: specialApp.special_app_id,
    validFrom: specialApp.valid_from,
    validTo: specialApp.valid_to || '',
    deleted:
      (specialApp.valid_to && isPast(new Date(specialApp.valid_to))) ||
      (specialApp.valid_from && isFuture(new Date(specialApp.valid_from))),
  };
};

const v1_post_to = (companyName, appParams, appDefinition) => {
  return {
    config: {
      ...(appDefinition.id === 2
        ? {
            airports: appParams.airports.replace(/\s/g, '').split(','),
            license_level: appParams.licenseLevel,
            project_id: appParams.projectId,
          }
        : {}),
      ...(appDefinition.id === DEMAND_DASHBOARD_ID
        ? {
            airports: appParams.airports.replace(/\s/g, '').split(','),
          }
        : {}),
      ...(appDefinition.id === DESTINATION_DEMAND_DASHBOARD_ID
        ? {
            destination_cities: appParams.cities.replace(/\s/g, '').split(','),
            destination_countries: appParams.countries.replace(/\s/g, '').split(','),
          }
        : {}),
    },
    company_name: companyName,
    special_app_id: appDefinition.id,
    valid_from: appParams.validFrom || new Date().toISOString(),
    valid_to: appParams.validTo || null,
  };
};

const v1_post_from = specialApp => {
  return {
    ...(specialApp.special_app_id === DEMAND_DASHBOARD_ID
      ? {
          name: 'demand-dashboard',
          displayName: 'Demand dashboard',
          config: {
            airports: specialApp.config.airports,
          },
        }
      : {}),
    ...(specialApp.special_app_id === DESTINATION_DEMAND_DASHBOARD_ID
      ? {
          name: 'destination-demand-dashboard',
          displayName: 'Destination demand dashboard',
          config: {
            cities: specialApp.config.destination_cities,
            countries: specialApp.config.destination_countries,
          },
        }
      : {}),
    ...([SMART_PASS_APP_ID, BIDVIEWER_APP_ID].includes(specialApp.special_app_id)
      ? {
          name: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'bidviewer' : 'smartpass',
          displayName: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'BID Viewer' : 'Smart Pass',
        }
      : {}),
    ...(specialApp.special_app_id === IP_WHITELIST
      ? {
          name: 'ip-whitelisting',
          displayName: 'IP allowlist',
        }
      : {}),
    ...(specialApp.special_app_id === DATA_MARKETPLACE_ID
      ? { name: 'data-marketplace', displayName: 'Data marketplace' }
      : {}),
    ...(specialApp.special_app_id === REPORT_SCHEDULES_ID
      ? {
          name: 'scheduled-reports',
          displayName: 'Report schedules',
        }
      : {}),
    id: specialApp.id,
    definitionId: specialApp.special_app_id,
    validFrom: specialApp.valid_from,
    validTo: specialApp.valid_to || '',
    deleted:
      (specialApp.valid_to && isPast(new Date(specialApp.valid_to))) ||
      (specialApp.valid_from && isFuture(new Date(specialApp.valid_from))),
  };
};

const v1_put_from = specialApp => {
  return {
    ...(specialApp.special_app_id === DEMAND_DASHBOARD_ID
      ? {
          name: 'demand-dashboard',
          displayName: 'Demand dashboard',
          config: {
            airports: specialApp.config.airports,
          },
        }
      : {}),
    ...(specialApp.special_app_id === DESTINATION_DEMAND_DASHBOARD_ID
      ? {
          name: 'destination-demand-dashboard',
          displayName: 'Destination demand dashboard',
          config: {
            cities: specialApp.config.destination_cities,
            countries: specialApp.config.destination_countries,
          },
        }
      : {}),
    ...([SMART_PASS_APP_ID, BIDVIEWER_APP_ID].includes(specialApp.special_app_id)
      ? {
          name: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'bidviewer' : 'smartpass',
          displayName: specialApp.special_app_id === BIDVIEWER_APP_ID ? 'BID Viewer' : 'Smart Pass',
        }
      : {}),
    ...(specialApp.special_app_id === BIDVIEWER_APP_ID
      ? {
          config: { includePassword: specialApp.config.include_password },
        }
      : {}),
    ...(specialApp.special_app_id === IP_WHITELIST
      ? {
          name: 'ip-whitelisting',
          displayName: 'IP allowlist',
        }
      : {}),
    ...(specialApp.special_app_id === DATA_MARKETPLACE_ID
      ? { name: 'data-marketplace', displayName: 'Data marketplace' }
      : {}),
    ...(specialApp.special_app_id === REPORT_SCHEDULES_ID
      ? {
          name: 'scheduled-reports',
          displayName: 'Report schedules',
        }
      : {}),
    id: specialApp.id,
    definitionId: specialApp.special_app_id,
    validFrom: specialApp.valid_from,
    validTo: specialApp.valid_to ? specialApp.valid_to : '',
    deleted:
      (specialApp.valid_to && isPast(new Date(specialApp.valid_to))) ||
      (specialApp.valid_from && isFuture(new Date(specialApp.valid_from))),
  };
};

const v1_put_to = (appParams, app) => {
  return {
    ...(app.definitionId === DEMAND_DASHBOARD_ID
      ? {
          config: {
            airports: appParams.airports
              .replace(/\s/g, '')
              .split(',')
              .filter(airport => airport),
          },
        }
      : {}),
    ...(app.definitionId === DESTINATION_DEMAND_DASHBOARD_ID
      ? {
          config: {
            destination_cities: appParams.cities
              .replace(/\s/g, '')
              .split(',')
              .filter(city => city),
            destination_countries: appParams.countries
              .replace(/\s/g, '')
              .split(',')
              .filter(city => city),
          },
        }
      : {}),
    ...(app.definitionId === BIDVIEWER_APP_ID
      ? {
          config: {
            include_password: appParams.includePassword,
          },
        }
      : {}),
    valid_from: appParams.validFrom || new Date().toISOString(),
    valid_to: appParams.validTo || null,
  };
};

const v1_delete_to = () => {
  return {
    valid_to: new Date().toISOString(),
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      to: v1_post_to,
      from: v1_post_from,
    },
    put: {
      to: {
        delete: v1_delete_to,
        update: v1_put_to,
      },
      from: {
        update: v1_put_from,
      },
    },
  },
};
