import { useContext, useEffect } from 'react';
import { CompaniesState } from './';

const useCompanyNames = () => {
  const { state, loadCompanyNames } = useContext(CompaniesState);

  useEffect(() => {
    if (!state.companyNames) {
      loadCompanyNames();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: state.loadingNames ?? true,
    data: state.companyNames,
    error: state.errorNames,
  };
};

export default useCompanyNames;
