import React, { useReducer } from 'react';

import companyDepositMapper from 'mappers/nextGen/companies/{company_name}/deposit/_';
import api from 'utils/api';

export const DepositState = React.createContext({
  state: {},
  loadDeposit: async companyName => [companyName],
  setNewDeposit: async (deposit, companyName) => [deposit, companyName],
  cashOut: async (value, companyName) => [value, companyName],
});

const depositReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DEPOSIT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_DEPOSIT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.data,
        },
      };
    case 'LOAD_DEPOSIT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
          data: null,
        },
      };
    case 'SET_DEPOSIT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          setting: true,
          errorSet: null,
          data: null,
        },
      };
    case 'SET_DEPOSIT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          setting: false,
        },
      };
    case 'SET_DEPOSIT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          setting: false,
          errorSet: action.error,
        },
      };
    case 'CASH_OUT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          cashOuting: true,
          errorCashOuting: false,
        },
      };
    case 'CASH_OUT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          cashOuting: false,
          cashOutSuccess: true,
        },
      };
    case 'CASH_OUT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          cashOuting: false,
          cashOutSuccess: false,
          errorCashOuting: action.error,
        },
      };
    default:
      return state;
  }
};

const DepositProvider = ({ children }) => {
  const [state, dispatch] = useReducer(depositReducer, {});

  const loadDeposit = async companyName => {
    dispatch({ type: 'LOAD_DEPOSIT', companyName });
    try {
      const res = await api.v1.get(`companies/${companyName}/deposit`);
      const data = companyDepositMapper.v1.get.from(res.data);
      dispatch({
        type: 'LOAD_DEPOSIT_SUCCESS',
        companyName,
        data,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_DEPOSIT_FAIL', companyName, error });
    }
  };

  const setNewDeposit = async (deposit, companyName) => {
    dispatch({ type: 'SET_DEPOSIT', companyName });
    try {
      await api.v1.post(
        `companies/${companyName}/deposit`,
        companyDepositMapper.v1.post.to(deposit, companyName),
      );

      dispatch({ type: 'SET_DEPOSIT_SUCCESS', companyName });
    } catch (error) {
      dispatch({ type: 'SET_DEPOSIT_FAIL', companyName, error });
      return Promise.reject(error);
    }
  };

  const cashOut = async (value, companyName) => {
    dispatch({ type: 'CASH_OUT', companyName });
    try {
      await api.v1.post('booking/backoffice/remove-credits', {
        amount: value,
        company_name: companyName,
      });

      dispatch({ type: 'CASH_OUT_SUCCESS', companyName });
    } catch (error) {
      dispatch({ type: 'CASH_OUT_FAIL', companyName, error });
      return Promise.reject(error);
    }
  };

  const value = {
    state,
    loadDeposit,
    setNewDeposit,
    cashOut,
  };

  return <DepositState.Provider value={value}>{children}</DepositState.Provider>;
};

export default DepositProvider;
