import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from 'redux/modules';

const middleware = [thunk];

const mainReducer = (state, action) => {
  return rootReducer(state, action);
};

const store = createStore(mainReducer, composeWithDevTools(applyMiddleware(...middleware)));

const initialData = store.getState();

/**
 * Creates a mock store used for testing. Returns a new instance each time.
 * Is populated with initial data from real store or provided seed data.
 */
export const createMockStore = seedData =>
  createStore(mainReducer, { ...initialData, ...seedData }, applyMiddleware(...middleware));

export default store;
