import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import subMonths from 'date-fns/subMonths';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import useMMB from 'components/services/mmb/useMMB';
import useBookings from 'components/services/mmb/useBookings';
import { usePolyglot } from 'components/services/i18n';

import BookingsTable from './BookingsTable';
import Filters from '../../components/Filters';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  & > div {
    margin-right: 15px;
  }
`;

const Bookings = () => {
  const [bid, setBid] = useState();
  const [selectedDates, setSelectedDates] = useState([subMonths(new Date(), 1)]);
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    loadingToken,
    loadingBookings,
    loadingMoreBookings,
    hasMoreBookings,
    loadingBid,
    loadMMBBookings,
    loadMoreBookings,
    searchByBid,
  } = useMMB();
  const { data: bookings } = useBookings(selectedDates);

  const history = useHistory();
  const polyglot = usePolyglot();

  const handleFilterReset = async () => {
    setSelectedDates([]);

    await loadMMBBookings();
  };

  const handleFilterApply = async () => {
    await loadMMBBookings(selectedDates);
  };

  const handleLoadMore = async () => {
    await loadMoreBookings(selectedDates);
  };

  const handleBidSearch = () => {
    if (!bid) return;
    searchByBid(bid)
      .then(() => history.push(`/manage-my-bookings/${bid}`))
      .catch(err => setErrorMsg(err));
  };

  return (
    <>
      <Card spaceAfter="large">
        {errorMsg && (
          <Alert
            type="critical"
            spaceAfter="medium"
            dataTest="bid-search-error-alert"
            closable
            onClose={() => setErrorMsg(null)}
          >
            {polyglot.t('mmb.bid_not_found')}
          </Alert>
        )}
        <CardSection>
          <Wrapper>
            <InputField
              dataTest="bid-search"
              placeholder="Search by BID number"
              size="normal"
              type="number"
              prefix={<ButtonLink iconLeft={<SearchIcon />} />}
              onChange={e => setBid(e.target.value)}
            />
            <Button
              width="111px"
              onClick={handleBidSearch}
              loading={loadingBid}
              dataTest="bid-search-btn"
            >
              {polyglot.t('searchAndBooking.search')}
            </Button>
          </Wrapper>
        </CardSection>
        <Filters
          selectedDates={selectedDates}
          handleDateFilter={setSelectedDates}
          handleApply={handleFilterApply}
          handleReset={handleFilterReset}
          disabled={loadingToken}
          loading={loadingBookings || loadingMoreBookings}
        />
      </Card>
      <BookingsTable
        bookings={bookings}
        filters={!!selectedDates.length}
        selectedDates={selectedDates}
        resetFilters={handleFilterReset}
        loading={loadingToken || loadingBookings}
        loadingMore={loadingMoreBookings}
        loadMore={handleLoadMore}
        nextPage={hasMoreBookings}
      />
    </>
  );
};

export default Bookings;
