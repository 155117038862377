import { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import InputGroup from '@kiwicom/orbit-components/lib/InputGroup';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';

import countries from 'data/countries.json';
import { formatPhonePrefix } from 'utils/functions';
import { usePolyglot } from 'components/services/i18n';

import { InputGroupWrapper } from './index.styled';

const ContactDetails = () => {
  const { errors, control } = useFormContext();
  const polyglot = usePolyglot();

  const mappedCountries = useMemo(
    () =>
      countries
        .sort((a, b) => {
          if (a.EN < b.EN) {
            return -1;
          }
          if (a.EN > b.EN) {
            return 1;
          }
          return 0;
        })
        .map(country => ({
          label: `${country.EN} (+${country.callingCode})`,
          value: country.value,
        })),
    [],
  );

  return (
    <Card title="Contact details">
      <CardSection>
        <InputGroupWrapper>
          <InputGroup
            flex={['0 0 130px', '1 1 100%']}
            label={polyglot.t('common.phone')}
            error={polyglot.t(errors?.contactDetails?.phoneSuffix?.message?.toString())}
          >
            <Controller
              name="contactDetails.countryId"
              defaultValue={mappedCountries[0].value}
              control={control}
              render={({ onChange, value }) => (
                <Select
                  name="contactDetails.countryId"
                  onChange={onChange}
                  prefix={<CountryFlag code={value} />}
                  value={value}
                  customValueText={formatPhonePrefix(value)}
                  options={mappedCountries}
                />
              )}
            />
            <Controller
              name="contactDetails.phoneSuffix"
              control={control}
              render={({ onChange, value, onBlur }) => (
                <InputField
                  inputMode="tel"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  onKeyDown={e => {
                    if (
                      !(
                        /^[0-9]$/.test(e.key) ||
                        e.key === 'Backspace' ||
                        (e.metaKey && e.key.toLocaleLowerCase() === 'a')
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </InputGroup>
        </InputGroupWrapper>
      </CardSection>
    </Card>
  );
};

export default ContactDetails;
