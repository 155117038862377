import PropTypes from 'prop-types';

export const TemporaryAttachment = {
  fileName: PropTypes.string.isRequired,
  temporaryAttachmentId: PropTypes.string.isRequired,
};

export const RequestTypeField = {
  fieldId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  jiraSchema: PropTypes.shape({ type: PropTypes.string.isRequired }),
  validValues: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  ).isRequired,
};

export const RequestType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  requestTypeFields: PropTypes.arrayOf(PropTypes.shape(RequestTypeField)).isRequired,
};

export const RequestTypeCategory = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  requestTypes: PropTypes.arrayOf(PropTypes.shape(RequestType)).isRequired,
};

export const SupportRequest = {
  issueId: PropTypes.string.isRequired,
  issueKey: PropTypes.string.isRequired,
  requestTypeId: PropTypes.string.isRequired,
  serviceDeskId: PropTypes.string.isRequired,
  currentStatus: PropTypes.shape({ status: PropTypes.string.isRequired }).isRequired,
  createdDate: PropTypes.shape({ epochMillis: PropTypes.number.isRequired }).isRequired,
  reporter: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }),
  requestFieldValues: PropTypes.arrayOf(
    PropTypes.shape({
      fieldId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ),
};

export const RequestComment = {
  id: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  public: PropTypes.bool.isRequired,
  created: PropTypes.shape({ epochMillis: PropTypes.number.isRequired }).isRequired,
};
