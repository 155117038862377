const v1_post_to = logName => {
  return {
    files: [logName],
  };
};

export default {
  v1: {
    post: {
      to: v1_post_to,
    },
  },
};
