import styled from 'styled-components';

export const StackWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
