import React from 'react';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { PageContainer } from 'common';

import Documentation from './components/Documentation';
import CreateSolution from './components/CreateSolution';
import AddBankAccount from './components/AddBankAccount';
import StartUsingSolution from './components/StartUsingSolution';
import FinishBottomBar from './components/FinishBottomBar';
import CompletedSteps from './components/CompletedSteps';
import { useCurrentUser } from 'components/services/auth';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useCompanyBankAccounts from 'components/services/bankAccounts/useCompanyBankAccounts';
import useOnboardingState from 'components/services/localStorage/useOnboardingState';

const Container = styled(PageContainer)`
  & > :first-child > :first-child {
    max-width: 715px;
  }
  padding-bottom: 74px;
`;

const GettingStarted = () => {
  const user = useCurrentUser();
  const solutions = useCompanySolutions(user.companyName);
  const bankAccounts = useCompanyBankAccounts(user.companyName);
  const [onboardingState, setOnboardingState] = useOnboardingState();

  const hasSolutions = solutions.data?.length > 0;
  const hasBankAccounts = bankAccounts.data?.length > 0;

  const acknowledgedDocumentation = onboardingState.acknowledgedDocumentation;
  const acknowledgedStartUsingSolution = onboardingState.acknowledgedStartUsingSolution;

  const handleAcknowledgeDocumentation = () => {
    setOnboardingState({
      ...onboardingState,
      acknowledgedDocumentation: true,
    });
  };

  const handleAcknowledgeStartUsingSolution = () => {
    setOnboardingState({
      ...onboardingState,
      acknowledgedStartUsingSolution: true,
    });
  };

  return (
    <Container>
      <Stack direction="row">
        <Stack spacing="medium">
          <Documentation
            done={acknowledgedDocumentation}
            onAcknowledge={handleAcknowledgeDocumentation}
          />

          <CreateSolution solutions={solutions} hasSolutions={hasSolutions} />

          <AddBankAccount bankAccounts={bankAccounts} hasBankAccounts={hasBankAccounts} />

          <StartUsingSolution
            done={acknowledgedStartUsingSolution}
            onAcknowledge={handleAcknowledgeStartUsingSolution}
            solutions={solutions}
          />
        </Stack>
        <CompletedSteps hasSolutions={hasSolutions} hasBankAccounts={hasBankAccounts} />
      </Stack>

      {!onboardingState.done && (
        <FinishBottomBar
          disabled={
            !hasSolutions ||
            !hasBankAccounts ||
            !acknowledgedDocumentation ||
            !acknowledgedStartUsingSolution
          }
        />
      )}
    </Container>
  );
};

export default GettingStarted;
