export const QUICK_OPTION_TYPES = {
  RANDOM: 'random',
  WINDOW: 'window',
  AISLE: 'aisle',
  EXTRA_LEG_ROOM: 'extra_leg_room',
  SIT_TOGETHER: 'sit_together',
};

export const SEAT_TYPES = {
  WINDOW: 'window',
  AISLE: 'aisle',
  EXTRA_LEG_ROOM: 'extra_leg_room',
  EMERGENCY_EXIT: 'emergency_exit',
};

export const SEAT_FORMATED_TYPES = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  EXTRA_LEG_ROOM: 'extraLegroom',
};

export const SEATMAP_ROW_TYPES = {
  TITLE: 'title',
  SEATS: 'seats',
  EXIT: 'exit',
  SEATING_CLASS: 'seatingClass',
};

export const OPTION_ORBIT_MAP_NEW = {
  available: 'default',
  unavailable: 'unavailable',
  selected: 'default',
  extraLegroom: 'legroom',
};

export const SEATING_STATUS = {
  LOADING: 'loading',
  COMPLETE: 'complete',
};

export const SEATING_CLASS_TYPES = {
  default: 'Economy',
};

export const SEAT_MAP_SAVE_BOOKING_OPTION_NAME = 'particular_seat';
