import React, { useMemo, useState } from 'react';

import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import { usePolyglot } from 'components/services/i18n';
import { useAuditLogs } from '../services/AuditLogsProvider';
import useCompanyUsers from 'components/services/companyUsers/useCompanyUsers';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';

import DateFilter from './DateFilter';

import {
  Container,
  FakeButton,
  PopoverContent,
  PlaceholderValue,
  NoResults,
} from './Filters.styled';
import { useToggle } from 'utils/hooks';

const logLevelOptions = [
  {
    label: 'All',
    value: undefined,
  },
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'Warning',
    value: 'warning',
  },
  {
    label: 'Error',
    value: 'error',
  },
];

const FakeInputButton = ({ label, placeholder, value }) => {
  return (
    <FakeButton>
      <div>
        <label>{label}</label>
        <div>{value || <PlaceholderValue>{placeholder}</PlaceholderValue>}</div>
      </div>

      <ChevronDownIcon size="small" color="secondary" />
    </FakeButton>
  );
};

const Filters = () => {
  const polyglot = usePolyglot();
  const companyName = useCurrentCompanyName();
  const users = useCompanyUsers(companyName, 1, 1500); // Load all company users for filter purposes; TO-DO: handle in some better way
  const { initiator, setInitiator, logLevel, setLogLevel } = useAuditLogs();
  const [user, setUser] = useState(undefined);
  const [level, setLevel] = useState(undefined);
  const usersPopover = useToggle();
  const logLevelPopover = useToggle();
  const [userQuery, setUserQuery] = useState('');

  const usersOptions = useMemo(() => {
    const options = (users.data || [])
      .filter(
        u =>
          `${u.firstName} ${u.lastName}`.toLowerCase().includes(userQuery.toLowerCase()) ||
          u.email.toLowerCase().includes(userQuery.toLowerCase()),
      )
      .map(u => ({
        label: `${u.firstName} ${u.lastName}`,
        value: u.email,
      }));

    if (options.length !== 0) {
      options.unshift({
        label: 'All',
        value: undefined,
      });
    }

    return options;
  }, [userQuery, users.data]);

  return (
    <Container>
      <Popover
        opened={usersPopover.isOn}
        onOpen={usersPopover.setOn}
        onClose={usersPopover.setOff}
        noPadding
        width="340px"
        content={
          <PopoverContent
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <InputField
              spaceAfter="small"
              value={userQuery}
              size="small"
              onChange={e => setUserQuery(e.target.value)}
              prefix={
                <span>
                  <SearchIcon />
                </span>
              }
              placeholder={polyglot.t('audit_logs.filter_by_user.input_placeholder')}
            />
            {usersOptions.length === 0 && (
              <NoResults>{polyglot.t('audit_logs.filter_by_user.no_results')}</NoResults>
            )}

            {usersOptions.map((option, i) => {
              return (
                <ListChoice
                  key={option.label + i}
                  title={option.label}
                  onClick={() => {
                    setInitiator(option.value);
                    setUser(option);
                    usersPopover.setOff();
                  }}
                  selected={option.value === initiator}
                  selectable={option.value === initiator}
                />
              );
            })}
          </PopoverContent>
        }
      >
        <FakeInputButton
          label={polyglot.t('audit_logs.filter_by_user')}
          value={user?.value && user.label}
          placeholder={polyglot.t('audit_logs.filter_by_user_placeholder')}
        />
      </Popover>

      <DateFilter label={polyglot.t('audit_logs.filter_by_date')} />

      <Popover
        opened={logLevelPopover.isOn}
        onOpen={logLevelPopover.setOn}
        onClose={logLevelPopover.setOff}
        noPadding
        width="340px"
        content={
          <div>
            {logLevelOptions.map((option, i) => {
              return (
                <ListChoice
                  key={option.label + i}
                  title={option.label}
                  onClick={() => {
                    setLogLevel(option.value);
                    setLevel(option);
                  }}
                  selected={option.value === logLevel}
                  selectable={option.value === logLevel}
                />
              );
            })}
          </div>
        }
      >
        <FakeInputButton
          label={polyglot.t('audit_logs.filter_by_log_level')}
          value={level?.value && level.label}
          placeholder={polyglot.t('audit_logs.filter_by_log_level_placeholder')}
        />
      </Popover>
    </Container>
  );
};

export default Filters;
