import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  z-index: 2;
  white-space: nowrap;

  & > div {
    margin: 0 10px;
  }
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      & > div:first-child {
        margin-bottom: 40px;
      }
    `}
  ${({ last }) => last && 'margin-left:10px'};
`;

export const CenteredTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16px;
`;

export const DataWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ multicity }) =>
    multicity &&
    css`
      justify-content: space-evenly;
      align-self: center;
      height: 105px;
      min-width: ${({ autoWidth }) => (autoWidth ? 'auto' : '195px')};
      min-width: ${({ shouldExpand }) => shouldExpand && '30%'};

      & > div {
        margin: 0 20px;
      }
    `}

  & > * {
    z-index: 2;
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 50%;
  width: 94%;
  left: 3%;
  height: 2px;
  background: ${({ theme }) => theme.orbit.borderColorCard};
  z-index: 1;
`;

export const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  min-width: 50%;
`;
