import React, { useState, useEffect } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { useToggle } from 'utils/hooks';
import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import { useCurrentUser } from 'components/services/auth';
import useCompanyWidgetSolutions from 'components/services/widgets/useCompanyWidgetSolutions';
import { usePolyglot } from 'components/services/i18n';
import useWidgetsContext from 'components/services/widgets';
import { Pagination } from 'common';
import { Authorization } from 'common/Authorization';

import CreateWidgetSolutionModal from '../common/CreateWidgetSolutionModal';
import WidgetPreviewDrawer from './components/WidgetPreviewDrawer';

import TableCardSection from './components/TableCardSection';
import SelectCompanyName from './components/SelectCompanyName';
import ClosableAlert from './components/ClosableAlert';

import { Container, ActionsFlexContainer } from './index.styled';

const List = () => {
  const polyglot = usePolyglot();
  const [widgetPreview, setWidgetPreview] = useState({ shown: false, config: {} });
  const showCreateWidgetSolutionModal = useToggle(false);
  const [successCreatingWidgetSolutionAlert, setSuccessCreatingWidgetSolutionAlert] =
    useState(null);
  const user = useCurrentUser();
  const [selectedCompany, setSelectedCompany] = useState(user?.companyName);
  const {
    fetchCompanyWidgets,
    fetchMoreWidgets,
    goToCreateNew,
    clearWidget,
    state: { updatingWidget, deletingWidget, page, pagination },
  } = useWidgetsContext();

  const { companyHasWidgetSolutions } = useCompanyWidgetSolutions(selectedCompany);

  useEffect(() => {
    fetchCompanyWidgets(selectedCompany, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    clearWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showWidgetPreview = config => {
    setWidgetPreview({ shown: true, config });
  };

  const closeWidgetPreview = () => {
    setWidgetPreview({ shown: false, config: {} });
  };

  const handlePagination = (rowsPerPage, nextPage) => {
    fetchMoreWidgets(selectedCompany, nextPage, rowsPerPage);
  };

  return (
    <Container>
      {successCreatingWidgetSolutionAlert}
      {updatingWidget.success && (
        <ClosableAlert type="success" spaceAfter="large">
          {polyglot.t('widgets.save_success')}
        </ClosableAlert>
      )}
      {deletingWidget.success && (
        <ClosableAlert type="success" spaceAfter="large">
          {polyglot.t('widgets.delete_success')}
        </ClosableAlert>
      )}
      {showCreateWidgetSolutionModal.isOn && (
        <CreateWidgetSolutionModal
          setSuccessCreatingWidgetSolutionAlert={setSuccessCreatingWidgetSolutionAlert}
          closeMe={() => showCreateWidgetSolutionModal.setOff()}
        />
      )}
      <Card
        title={polyglot.t('widgets.list.title')}
        actions={
          companyHasWidgetSolutions && (
            <Authorization
              resource="model.widget"
              action={['create', 'create_own', 'create_assigned']}
              companyName={selectedCompany}
            >
              <ActionsFlexContainer>
                <Button
                  type="primarySubtle"
                  size="small"
                  onClick={() => goToCreateNew(selectedCompany)}
                >
                  {polyglot.t('widgets.create_new_widget')}
                </Button>
              </ActionsFlexContainer>
            </Authorization>
          )
        }
      >
        {(user.role === KIWI_ADMIN || user.role === ACCOUNT_MANAGER) && (
          <CardSection>
            <SelectCompanyName onSelect={setSelectedCompany} value={selectedCompany} />
          </CardSection>
        )}

        <CardSection>
          <TableCardSection
            showWidgetPreview={showWidgetPreview}
            selectedCompany={selectedCompany}
            openCreateWidgetSolutionModal={() => showCreateWidgetSolutionModal.setOn()}
          />
          {!!pagination.total && (
            <Pagination
              rows={pagination.total}
              rowsPerPage={pagination.pageSize}
              page={page}
              rowsPerPageItems={[10, 20, 50, 100]}
              onPagination={handlePagination}
            />
          )}
        </CardSection>
      </Card>
      <WidgetPreviewDrawer
        drawerShown={widgetPreview.shown}
        widgetConfigValues={widgetPreview.config}
        closeDrawer={closeWidgetPreview}
      />
    </Container>
  );
};

export default List;
