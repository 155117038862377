import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';

const DepositBookingSuccess = () => {
  const polyglot = usePolyglot();
  const history = useHistory();
  const location = useLocation();
  const { addBookingIframe } = useBookingDetailsIframes();

  const bid = location.state?.bookingId;

  if (!location.state?.fromBooking) {
    return (
      <Redirect
        to={{
          pathname: `/search`,
        }}
      />
    );
  }

  return (
    <Card>
      <CardSection>
        <Stack direction="column" align="center" dataTest="deposit-booking-success">
          <Illustration size="large" name="OnlineCheckIn" />
          <Heading type="title3">{polyglot.t('booking.deposit_booking_success.did_it')}</Heading>
          <Text type="secondary">
            {polyglot.t('booking.deposit_booking_success.successful_booking')} <br />
            {polyglot.t('booking.deposit_booking_success.link_below')}{' '}
          </Text>
          <Stack shrink direction="row" justify="center">
            <Button
              dataTest="check-booking-details"
              type="primary"
              onClick={() => {
                addBookingIframe(bid);
                history.replace(`${ROUTE_CONFIG.MANAGE_MY_BOOKINGS.path}/${bid}`);
              }}
            >
              {polyglot.t('booking.deposit_booking_success.check_details')}
            </Button>
            <ButtonLink
              dataTest="back-to-search"
              type="secondary"
              onClick={() => {
                history.replace(ROUTE_CONFIG.KIWI_SEARCH);
              }}
            >
              <u>{polyglot.t('booking.deposit_booking_success.back_to_search')}</u>
            </ButtonLink>
          </Stack>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default DepositBookingSuccess;
