import { useContext, useEffect } from 'react';
import { PartnerPaymentsState } from './';

const usePartnerPayment = id => {
  const { state, loadPartnerPayment } = useContext(PartnerPaymentsState);
  useEffect(() => {
    loadPartnerPayment(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    ...(state.partnerPayment || {
      data: [],
      loading: false,
      error: null,
    }),
  };
};

export default usePartnerPayment;
