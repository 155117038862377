//user roles used in user management section - for filtering by role and for selecting role in ChangeRoleDialog
export const ROLES = {
  KIWI_ADMIN: 'ACL-admin',
  KIWI_ACCOUNT_MANAGER: 'ACL-account-manager',
  KIWI_FINANCIAL_MANAGER: 'ACL-financial-manager',
  PARTNER_ADMIN: 'ACL-partner-admin',
  PARTNER_DEVELOPER: 'ACL-partner-developer',
  PARTNER_BOOKING_AGENT: 'ACL-partner-booking-agent',
};

export const ROLES_I18N = {
  [ROLES.KIWI_ADMIN]: 'roles.admin',
  [ROLES.KIWI_ACCOUNT_MANAGER]: 'roles.account_manager',
  [ROLES.KIWI_FINANCIAL_MANAGER]: 'roles.financial_manager',
  [ROLES.PARTNER_ADMIN]: 'roles.partner_admin',
  [ROLES.PARTNER_DEVELOPER]: 'roles.partner_developer',
  [ROLES.PARTNER_BOOKING_AGENT]: 'roles.partner_booking_agent',
};

export const ACCOUNT_MANAGER = 'ACL-account-manager';

export const KIWI_ADMIN = 'ACL-admin';

export const FINANCIAL_MANAGER = 'ACL-financial-manager';

export const GETTING_STARTED_ROLES = [
  ROLES.KIWI_ADMIN,
  ROLES.KIWI_ACCOUNT_MANAGER,
  ROLES.PARTNER_ADMIN,
];
