import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ClickOutside extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.isTouch = false;
  }

  getContainer = ref => {
    this.container = ref;
  };

  componentDidMount() {
    document.addEventListener('touchend', this.handle, true);
    document.addEventListener('click', this.handle, true);
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.handle, true);
    document.removeEventListener('click', this.handle, true);
  }

  handle = e => {
    const { onClick } = this.props;

    if (e.type === 'touchend') {
      this.isTouch = true;
    }
    if (e.type === 'click' && this.isTouch) {
      return;
    }
    if (!this.container.contains(e.target)) {
      onClick(e);
    }
  };

  render() {
    const { children, onClick, ...props } = this.props;
    return (
      <div {...props} ref={this.getContainer}>
        {children}
      </div>
    );
  }
}

export default ClickOutside;
