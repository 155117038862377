import React, { useContext, useState } from 'react';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { BankAccountsState } from 'components/services/bankAccounts';
import { usePolyglot } from 'components/services/i18n';

const ChangeMainAccountModal = ({ onClose, account }) => {
  const [errorAlertMessage, setErrorAlertMessage] = useState(false);
  const {
    setPrimary,
    loadCompanyBankAccounts,
    state: { settingPrimary },
  } = useContext(BankAccountsState);
  const polyglot = usePolyglot();

  const handleSubmit = () => {
    setPrimary(account)
      .then(() => {
        onClose(true);
        loadCompanyBankAccounts(account.companyName);
      })
      .catch(err => {
        setErrorAlertMessage(err.message);
      });
  };

  return (
    <Modal closable onClose={() => onClose(false)} size="small">
      <ModalHeader
        title={polyglot.t('company.billing.change_main_bank_account.modal.header')}
        description={polyglot.t(
          'company.billing.change_main_bank_account.modal.header_description',
          {
            type: account.iban ? 'IBAN:' : 'Number:',
            value: account.iban || account.accountNumber,
          },
        )}
      />
      {errorAlertMessage && (
        <ModalSection>
          <Alert
            spaceAfter="large"
            type="critical"
            closable
            icon
            onClose={() => setErrorAlertMessage(false)}
          >
            {errorAlertMessage}
          </Alert>
        </ModalSection>
      )}
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button
            dataTest="change-main-bank-account-modal-cancel-button"
            type="secondary"
            onClick={() => onClose(false)}
          >
            {polyglot.t('common.cancel')}
          </Button>
          <Button
            dataTest="change-main-bank-account-modal-confirm-button"
            type="primary"
            onClick={handleSubmit}
            loading={settingPrimary}
          >
            {polyglot.t('company.billing.change_main_bank_account_button_text')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeMainAccountModal;
