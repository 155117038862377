import React from 'react';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';

const RetryModal = ({ loading, onClose, onRetry }) => {
  const polyglot = usePolyglot();
  return (
    <Modal hasCloseButton onClose={onClose}>
      <ModalHeader
        title={polyglot.t('billing.retry_modal.billing.title')}
        description={polyglot.t('billing.retry_modal.billing.description')}
      />
      <ModalFooter>
        <Stack justify="end">
          <Button onClick={onClose} disabled={loading} type="secondary">
            {polyglot.t('common.cancel')}
          </Button>
          <Button onClick={onRetry} loading={loading} type="primary">
            {polyglot.t('common.retry')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default RetryModal;
