import React, { useReducer } from 'react';
import { v1 as uuid } from 'uuid';
import axios from 'axios';

import { proxy } from 'utils/api';
import { logError } from 'utils/sentry';
import { getBookingToolCookie } from 'utils/search';

const initialState = {
  carriers: {
    data: null,
    loading: false,
    error: null,
  },
  locations: {},
  tabs: { selectedTab: uuid(), data: [] },
  loadCarriers: _ => null,
  loadLocations: id => id,
};
export const BookingToolState = React.createContext(initialState);

const carriersReducer = (state, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case 'LOAD_CARRIERS':
      return {
        data: null,
        loading: true,
        error: null,
      };
    case 'LOAD_CARRIERS_SUCCESS':
      return {
        data: payload,
        loading: false,
        error: null,
      };
    case 'LOAD_CARRIERS_FAIL':
      return {
        data: null,
        loading: false,
        error: error,
      };
    default:
      return state;
  }
};

const locationsReducer = (state, action) => {
  const { id, type, payload, error } = action;
  switch (type) {
    case 'LOAD_LOCATIONS':
      return {
        ...state,
        [id]: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case 'LOAD_LOCATIONS_SUCCESS':
      return {
        ...state,
        [id]: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    case 'LOAD_LOCATIONS_FAIL':
      return {
        ...state,
        [id]: {
          data: null,
          loading: false,
          error,
        },
      };
    default:
      return state;
  }
};

const tabsReducer = (state, action) => {
  const { type } = action;
  // TODO: create methods for handling tabs (add/remove)
  switch (type) {
    default:
      return state;
  }
};

const formatCarriers = data => {
  let carriesMap = {};
  data.forEach(({ id, name }) => {
    carriesMap[id] = name;
  });
  return carriesMap;
};

const getTabsInitialState = () => {
  const cookie = getBookingToolCookie();
  if (cookie) {
    return { selectedTab: Object.keys(cookie)[0], data: cookie };
  }
  return initialState.tabs;
};

const BookingToolProvider = ({ children }) => {
  const [carriers, carriersDispatch] = useReducer(carriersReducer, {});
  const [locations, locationsDispatch] = useReducer(locationsReducer, {});
  const [tabs] = useReducer(tabsReducer, getTabsInitialState());

  const loadCarriers = () => {
    carriersDispatch({ type: 'LOAD_CARRIERS' });
    proxy
      .direct('airlines')
      .then(res => {
        carriersDispatch({
          type: 'LOAD_CARRIERS_SUCCESS',
          payload: formatCarriers(res.data),
        });
      })
      .catch(error => {
        logError(error);
        carriersDispatch({ type: 'LOAD_CARRIERS_FAIL', error });
      });
  };

  const loadLocations = id => {
    locationsDispatch({ type: 'LOAD_LOCATIONS', id });

    const params = {
      type: 'id',
      id,
      limit: 100,
      locale: 'en-US',
      active_only: true,
    };
    axios
      .get('https://api.skypicker.com/locations', { params })
      .then(res => {
        locationsDispatch({
          type: 'LOAD_LOCATIONS_SUCCESS',
          id,
          payload: res.data?.locations?.[0],
        });
      })
      .catch(error => locationsDispatch({ type: 'LOAD_LOCATIONS_FAIL', error }));
  };

  const value = {
    carriers,
    locations,
    loadCarriers,
    loadLocations,
    tabs,
  };

  return <BookingToolState.Provider value={value}>{children}</BookingToolState.Provider>;
};

export default BookingToolProvider;
