import { useEffect, useContext } from 'react';

import { MMBState } from 'components/services/mmb';

const useBookingDisruptions = (bookings = []) => {
  const { state, getBookingDisruptions } = useContext(MMBState);
  const bids = bookings
    ?.slice(-40)
    .filter(b => b.status === 'processing' || b.status === 'confirmed')
    .map(b => b.bid);

  useEffect(() => {
    if (bids?.length) {
      getBookingDisruptions(bids);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings]);

  return (
    { data: state?.disruptions, loading: state.loadingDisruptions, error: null } || {
      data: null,
      loading: false,
      error: null,
    }
  );
};

export default useBookingDisruptions;
