import * as R from 'ramda';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';

/**
 * Evaluates whether the passed value is truthy or falsy
 * @param {string} message i18n message
 */
export const cantBeEmpty = (message = 'validation.cant_be_empty') => ({
  rule: value => (R.is(String, value) ? Boolean(value.trim()) : Boolean(value)),
  message,
});

/**
 * Evaluates whether the passed array is empty or not
 * @param {string} message i18n message
 */
export const cantBeEmptyArray = (message = 'validation.cant_be_empty') => ({
  rule: R.compose(R.not, R.isEmpty),
  message,
});

/**
 * Validates whether the passed value has a minimal length
 * @param {number} minLength The min length that the value must have
 * @param {string} message i18n message
 */
export const cantBeShorterThan = (minLength, message = 'validation.too_short') => ({
  rule: value => String(value || '').length >= minLength,
  message,
});

/**
 * Validates whether the passed value is longer than the predefined length
 * @param {number} maxLength The max length that the value can have
 * @param {string} message i18n message
 */
export const cantBeLongerThan = (maxLength, message = 'validation.length_exceeded') => ({
  rule: value => String(value || '').length <= maxLength,
  message,
});

/**
 * Validates whether the passed value is a valid email
 * Passes on empty fields
 * This is a simple validatation with a lot of false positives
 * @param {number} message i18n message
 */
export const mustBeEmailOrEmpty = (message = 'validation.invalid_email') => ({
  rule: value =>
    !value ||
    R.test(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      value,
    ),
  message,
});

/**
 * Validates whether all characters in a string are numbers, or the string is empty
 * @param {string} message i18n message
 */
export const mustBeNumbersOrEmpty = (message = 'validation.must_be_numbers') => ({
  rule: value => /^(\s*|-?\d+)$/g.test(value || ''),
  message,
});

/**
 * Validates whether the input is a number or a floating point number
 * @param {string} message i18n message
 */
export const mustBeNumbersOrDecimalOrEmpty = (
  message = 'validation.must_be_numbers_or_decimal_or_empty',
) => ({
  rule: value => /^(-?\d+(\.\d?)?)?$/g.test(value || ''),
  message,
});

/**
 * Validates whether the passed value is a valid uri or empty
 * @param {string} message i18n message
 */
export const mustBeUri = (message = 'validation.must_be_uri') => ({
  rule: value =>
    /^$|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g.test(
      value || '',
    ),
  message,
});

/**
 * Validates whether tha passed value contains only latin characters
 * @param {string} message i18n message
 */
export const latinCharsOnly = (message = 'validation.latin_chars') => ({
  // eslint-disable-next-line no-control-regex
  rule: value => !/[^\u0000-\u00ff]/.test(value),
  message,
});

/**
 * Validates that the start date for the given date range comes before the end date.
 * Same date also passes.
 * Value should be passed as an object of the following shape:
 * {dateFrom: Date|string|number, dateTo: Date|string|number}
 *
 * @param {string} message i18n message
 */
export const mustBeValidDateRange = (message = 'validation.invalid_date_range') => ({
  rule: value => {
    if (!value) {
      return true;
    }

    const { dateFrom, dateTo } = value;

    // passes on empty values
    if (!dateFrom && !dateTo) {
      return true;
    }

    // fails if only one value is provided
    if (!dateFrom !== !dateTo) {
      return false;
    }

    return (
      isBefore(new Date(dateFrom), new Date(dateTo)) ||
      isEqual(new Date(dateFrom), new Date(dateTo))
    );
  },
  message,
});

export const mustContainOnlyNumbersOrLetters = (
  message = 'validation.only_numbers_or_letters',
) => ({
  rule: value => /^[a-z0-9]*$/g.test(value),
  message,
});

export const mustHaveOneLowerCaseAndUpperCaseLetter = (
  message = 'validation.one_upper_and_lower',
) => ({
  rule: (value = '') => value.toLowerCase() !== value && value.toUpperCase() !== value,
  message,
});

export const mustContainNumbers = (message = 'validation.must_contain_numbers') => ({
  rule: (value = '') => /[0-9]/.test(value),
  message,
});

/**
\u0020-\u007F - Basic Latin
\u00A0-\u00FF - Latin-1 Supplement
 */

export const cantContainCyrillic = value => /^[\s\w§“”\u0020-\u007F\u00C0-\u00FF]+$/gim.test(value);

export const cantContainCyrillicObsolete = (message = 'validation.no_cyrillic') => ({
  rule: cantContainCyrillic,
  message,
});
