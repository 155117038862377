import React from 'react';
import styled from 'styled-components';
import { Text, Stack, Inline, Popover, ListChoice } from '@kiwicom/orbit-components';
import { Link } from 'react-router-dom';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import MenuKebabIcon from '@kiwicom/orbit-components/lib/icons/MenuKebab';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import QuestionCircleIcon from '@kiwicom/orbit-components/lib/icons/QuestionCircle';

import { useCurrentUser } from 'components/services/auth';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import { ClickOutside } from 'common';
import { useToggle } from 'utils/hooks';
import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';

export const ClickWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  padding-top: 4px;
`;

const ContextualMenu = ({ onOpen, onClose, isOpened }) => {
  const polyglot = usePolyglot();
  return (
    <ClickOutside onClick={onClose}>
      <Popover
        placement="left-start"
        noPadding
        opened={isOpened}
        content={
          <>
            <Link to={`${ROUTE_CONFIG.DOCS.path}/faq/requesting_cash_out`} target="_blank">
              <ListChoice
                title={polyglot.t(
                  'tequila.mmb.credit_activity.header.contextual_menu.link.cash_out',
                )}
                icon={<MoneyIcon size="small" />}
              />
            </Link>
            <Link to={`${ROUTE_CONFIG.DOCS.path}/faq/topping_up_deposit`} target="_blank">
              <ListChoice
                title={polyglot.t('tequila.mmb.credit_activity.header.contextual_menu.link.topup')}
                icon={<QuestionCircleIcon size="small" />}
              />
            </Link>
          </>
        }
      >
        <ClickWrapper onClick={onOpen}>
          <MenuKebabIcon size="small" color="secondary" />
        </ClickWrapper>
      </Popover>
    </ClickOutside>
  );
};

const CreditBalanceHeader = () => {
  const user = useCurrentUser();
  const companyDeposit = useCompanyDeposit(user.companyName);

  const popoverOpened = useToggle();

  return (
    <Card spaceAfter="largest" loading={companyDeposit.loading}>
      <CardSection>
        <Stack direction="row" justify="between" align="center">
          <Text size="large">Your credit balance</Text>
          <Inline spacing="medium">
            <Text size="large">{companyDeposit.data?.balance} €</Text>
            <ContextualMenu
              onOpen={popoverOpened.setOn}
              onClose={popoverOpened.setOff}
              isOpened={popoverOpened.isOn}
            />
          </Inline>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default CreditBalanceHeader;
