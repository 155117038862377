import styled from 'styled-components';

/**
 * Custom table component which is slightly complicated to use.
 * Every roll is now going to be a TableBody with a table row inside.
 * The reason for this is because of the alignment of subtables within the table.
 */
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${({ theme }) => theme.orbit.paletteInkLight};
`;

export default Table;
