import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as reportAliasesActions from 'redux/modules/reportAliases';
import * as hocUtils from 'utils/hoc';
import { Match } from 'shapes/ReactRouter';
import ReportAlias from 'shapes/ReportAlias';

/**
 * HOC which will try loading a report alias with the ID given through
 * router props. It will pass some status variables and the reportAlias itself to the wrapped component.
 */
const withReportAlias = WrappedComponent => {
  class WithReportAlias extends PureComponent {
    componentDidMount() {
      const {
        match: { params },
        loadReportAlias,
      } = this.props;
      if (params.aliasId) {
        loadReportAlias(params.aliasId);
      }
    }

    render() {
      const {
        reportAlias,
        isReportAliasLoading,
        reportAliasError,
        history,
        match,
        match: { params },
      } = this.props;

      return (
        <WrappedComponent
          data-test="hoc-report-alias"
          reportAlias={params.aliasId ? reportAlias : {}}
          isReportAliasLoading={isReportAliasLoading}
          reportAliasError={reportAliasError}
          history={history}
          match={match}
        />
      );
    }
  }

  WithReportAlias.propTypes = {
    match: PropTypes.shape(Match).isRequired,
    loadReportAlias: PropTypes.func.isRequired,
    reportAlias: PropTypes.shape(ReportAlias),
    isReportAliasLoading: PropTypes.bool.isRequired,
    reportAliasError: PropTypes.object,
  };

  // useful for debugging
  WithReportAlias.displayName = `WithReportAlias(${hocUtils.getDisplayName(WrappedComponent)})`;

  // Required for correct tests. Don't use Class.WrappedComponent since it's too magical
  WithReportAlias.Unwrap = WrappedComponent.WrappedComponent || WrappedComponent;

  const mapStateToProps = state => ({
    reportAlias: state.reportAliases.reportAlias,
    isReportAliasLoading: state.reportAliases.loading,
    reportAliasError: state.reportAliases.error,
  });

  const mapDispatchToProps = {
    loadReportAlias: reportAliasesActions.loadById,
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(WithReportAlias));
};

export default withReportAlias;
