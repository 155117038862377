import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { isProduction, isStaging } from 'utils/env';

const SENTRY_CONFIG = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_RELEASE_NAME,
  environment: process.env.REACT_APP_KAPLA_ENV,
  ignoreErrors: ['Non-Error promise rejection captured'], //when we intentionally return a Promise.reject
};

// Immediately configure Sentry if env is production
if (isProduction() || isStaging()) {
  Sentry.init(SENTRY_CONFIG);
}

export const logError = (error, errorInfo) => {
  if (isProduction() || isStaging()) {
    // Send error log to sentry when in production
    Sentry.configureScope(scope => {
      scope.setExtra('errorInfo', errorInfo);
    });
    Sentry.captureException(error);
  } else {
    // print the error when in any other environment
    // eslint-disable-next-line
    console.error(error, error.stack);
  }
};
