import { useMemo } from 'react';

import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import useCompanyPCCApps from 'components/services/smartpoint/useCompanyPCCApps';

import { SAVE_BUTTON_STATUS } from '../consts';

const useCompanyPCCs = () => {
  const companyName = useCurrentCompanyName();
  const solutions = useCompanySolutions(companyName);
  const companyPCCApps = useCompanyPCCApps();

  const companyPCCs = useMemo(() => {
    const isSolutionsValid = !solutions.loading && !solutions.error && solutions.data;
    if (!isSolutionsValid) {
      return { data: [], loading: solutions.loading, error: solutions.error };
    }

    const isCompanyPCCAppsValid =
      !companyPCCApps.loading && !companyPCCApps.error && companyPCCApps.data;
    if (!isCompanyPCCAppsValid) {
      return { data: [], loading: companyPCCApps.loading, error: companyPCCApps.error };
    }

    // Construct a format where apps are grouped by PCC Code
    const companyPCCsResult = companyPCCApps.data.reduce((mappedPCCs, pccApp) => {
      const { id, code, application, application_code } = pccApp;
      const affilId = application_code || application.affiliate_id;
      const { productType } = solutions.data.find(app => app.affilId === affilId);

      const pccConfig = mappedPCCs.find(pcc => pcc.code === code);
      const pccAppValue = { id, affilId, productType };

      if (!pccConfig) {
        mappedPCCs.push({
          code,
          applications: [pccAppValue],
          saveButtonStatus: SAVE_BUTTON_STATUS.HIDDEN,
        });
      } else {
        pccConfig.applications.push(pccAppValue);
      }
      return mappedPCCs;
    }, []);

    return { data: companyPCCsResult, loading: false, error: null };
  }, [solutions, companyPCCApps]);

  return companyPCCs;
};

export default useCompanyPCCs;
