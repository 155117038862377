import styled from 'styled-components';

import { Flex, Space } from 'common';

export const AirlinerContainer = styled(Space)`
  display: flex;
  flex: 0 0 68px;
  min-width: 0;
  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const LegSummaryContainer = styled.div`
  min-width: 100px;
`;

export const TimeContainer = styled(Flex)`
  width: fit-content;
  min-width: 100px;
`;

export const DurationContainer = styled(Flex)`
  max-width: 350px;
`;
