import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import AlertButton from '@kiwicom/orbit-components/lib/Alert/AlertButton';
import { Text } from '@kiwicom/orbit-components';

import Polyglot from 'shapes/Polyglot';
import copyToClipboard from 'utils/copyToClipboard';

const Spacing = styled.div`
  height: 48px;
`;

const AccessDenied = ({ polyglot, error, tryAgain }) => {
  const [copyText, setCopyText] = useState(polyglot.t('register.access_denied.copy_support_email'));

  return (
    <Stack direction="column" align="center" spacing="comfy">
      <Alert icon title={error?.title} type="critical">
        <Text spaceAfter="medium">{error?.msg}</Text>
        <Stack direction="row" justify="start">
          <AlertButton
            size="small"
            type="criticalSubtle"
            onClick={() => {
              copyToClipboard('tequila.support@kiwi.com');
              setCopyText(polyglot.t('common.copied'));
              setTimeout(
                () => setCopyText(polyglot.t('register.access_denied.copy_support_email')),
                2000,
              );
            }}
          >
            {copyText}
          </AlertButton>
        </Stack>
      </Alert>
      <Spacing />
      <Illustration name="NoResults" />
    </Stack>
  );
};

AccessDenied.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

export default AccessDenied;
