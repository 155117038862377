import React, { useEffect } from 'react';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { usePolyglot } from 'components/services/i18n';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

import SeatingContent from './components/SeatingContent';

const Seating = () => {
  const polyglot = usePolyglot();
  const { resetData } = useShoppingBasket();

  useEffect(() => {
    return () => {
      resetData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={polyglot.t('tequila.ancillary_seating.mmb.card.title')}>
      <CardSection>
        <SeatingContent />
      </CardSection>
    </Card>
  );
};

export default Seating;
