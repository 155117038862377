export const ISEARCH = 'isearch';
export const IMMB = 'immb';
export const LOCATIONS_API = 'locations_api';
export const MULTICITY_API = 'multicity_api';
export const NOMAD_API = 'nomad_api';
export const BOOKING_API = 'booking_api';
export const SEARCH_API = 'search_api';
export const WIDGETS = 'widget_builder';
export const DEEPLINK_GENERATOR = 'deeplink_generator';

export const SMARTPOINT_PRODUCTS = ['smartpoint-plugin-zooz', 'smartpoint-plugin-deposit'];
