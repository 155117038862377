import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import StopoverArrow from '@kiwicom/orbit-components/lib/StopoverArrow';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { Flex, Space } from 'common';
import useCarriers from 'components/services/bookingTool/useCarriers';
import { useSearch } from 'components/services/search';
import { defaultDateFormat, localTimeFormat, convertUTCDateToLocalDate } from 'utils/search';

import { AirlinerContainer, TimeContainer, DurationContainer } from './FlightLeg.styled';

const FlightLeg = ({
  carriers,
  stopAirports,
  cityFrom,
  cityTo,
  flyFrom,
  flyTo,
  stops,
  duration,
  departure,
  arrival,
}) => {
  const [checked, changeChecked] = useState(false);
  const { data: carriersMap } = useCarriers();
  const { tabData, selectStopAirport, unselectStopAirport } = useSearch();

  useEffect(() => {
    return () => {
      if (checked) {
        unselectStopAirport();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxOnChange = () => {
    if (checked) {
      changeChecked(false);
      unselectStopAirport();
    } else {
      changeChecked(true);
      selectStopAirport(stopAirports);
    }
  };

  return (
    <Stack align="center">
      <Space right="l">
        <Checkbox
          checked={checked}
          onChange={handleCheckboxOnChange}
          disabled={!checked && tabData.selectedStopAirports !== null}
        />
      </Space>
      <Space right="l">
        <CarrierLogo carriers={carriers.map(id => ({ code: id, name: carriersMap[id] }))} />
      </Space>
      <AirlinerContainer right="l">
        <Text type="secondary" size="small" dataTest="flight-leg-carriers">
          {carriers.map(id => carriersMap[id]).join(', ')}
        </Text>
      </AirlinerContainer>
      <Space right="l">
        <TimeContainer column>
          <Text dataTest="flight-leg-time-of-travel">
            {localTimeFormat(departure)} — {localTimeFormat(arrival)}
          </Text>
          <Text size="small" dataTest="flight-leg-date-of-travel">
            {defaultDateFormat(convertUTCDateToLocalDate(new Date(departure)))}
          </Text>
        </TimeContainer>
      </Space>
      <DurationContainer column>
        <Text>{duration}</Text>
        <Flex cross="center">
          <Text size="small" type="secondary">
            {cityFrom} ({flyFrom})
          </Text>
          <Space left="s" right="s">
            <StopoverArrow stops={`${stops}`} />
          </Space>
          <Text size="small" type="secondary">
            {cityTo} ({flyTo})
          </Text>
        </Flex>
      </DurationContainer>
    </Stack>
  );
};

FlightLeg.propTypes = {
  carriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  cityFrom: PropTypes.string.isRequired,
  cityTo: PropTypes.string.isRequired,
  flyFrom: PropTypes.string.isRequired,
  flyTo: PropTypes.string.isRequired,
  stops: PropTypes.number.isRequired,
  duration: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
};

export default FlightLeg;
