import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import useCompanyNames from 'components/services/companies/useCompanyNames';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';
import { usePolyglot } from 'components/services/i18n';

import { Autocomplete } from 'common';

import { SelectCompanyContainer } from './SelectSolutionsCompany.styled';

const SelectSolutionsCompany = ({ size, onSelect }) => {
  const { data: companyNames, loading } = useCompanyNames();
  const { selectedCompany } = useSelectedCompany();
  const polyglot = usePolyglot();

  const handleSelect = option => {
    onSelect(option.label);
  };

  const companyOptions = useMemo(() => {
    return (companyNames || []).map(name => ({
      value: name,
      label: name,
    }));
  }, [companyNames]);

  return (
    <Loading type="searchLoader" text={polyglot.t('common.loading_companies')} loading={loading}>
      <SelectCompanyContainer>
        <Autocomplete
          options={companyOptions}
          onSelect={handleSelect}
          value={selectedCompany}
          label={polyglot.t('common.load_solutions_for')}
          noResultsText={polyglot.t('common.select_app_company.no_results_text')}
          size={size}
        />
      </SelectCompanyContainer>
    </Loading>
  );
};

SelectSolutionsCompany.defaultProps = {
  size: 'normal',
  onSelect: () => {},
};

SelectSolutionsCompany.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  onSelect: PropTypes.func,
};

export default React.memo(SelectSolutionsCompany);
