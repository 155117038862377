const v2_post_to = reportSchedule => {
  return {
    ...(reportSchedule.emailRecipients?.length && { emails: reportSchedule.emailRecipients }),
    file_format: reportSchedule.formatFile,
    frequency: reportSchedule.frequency,
    is_active: true,
    ...(reportSchedule.sftpHostName && { sftp_hostname: reportSchedule.sftpHostName }),
    ...(reportSchedule.sftpPassword && { sftp_password: reportSchedule.sftpPassword }),
    ...(reportSchedule.sftpFilePath && { sftp_path: reportSchedule.sftpFilePath }),
    ...(typeof reportSchedule.sftpPort === 'number' && { sftp_port: reportSchedule.sftpPort }),
    ...(reportSchedule.sftpUserName && { sftp_username: reportSchedule.sftpUserName }),
  };
};

const v2_get_to = companyName => ({
  params: {
    company_name: companyName,
  },
});

const v2_get_from = reportSchedules =>
  reportSchedules.map(reportSchedule => ({
    scheduleId: reportSchedule.id,
    frequency: reportSchedule.frequency,
    emails: reportSchedule.emails,
    isOn: reportSchedule.is_active,
    fileFormat: reportSchedule.file_format,
    sftpHostName: reportSchedule.sftp_hostname,
    sftpFilePath: reportSchedule.sftp_path,
    sftpPort: reportSchedule.sftp_port,
    sftpUserName: reportSchedule.sftp_username,
  }));

export default {
  v2: {
    post: {
      to: v2_post_to,
    },
    get: {
      to: v2_get_to,
      from: v2_get_from,
    },
  },
};
