import React from 'react';
import { format, lastDayOfMonth, add } from 'date-fns';
import { enGB } from 'date-fns/locale';

import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import Button from '@kiwicom/orbit-components/lib/Button';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import Stack from '@kiwicom/orbit-components/lib/Stack';

const DatepickerHeader = ({ date, setDate, rightDisabled, leftDisabled }) => {
  return (
    <Stack direction="row" align="center" justify="between" spaceAfter="large">
      <Button
        onClick={() => {
          const previousMonth = new Date(date.getTime());
          previousMonth.setDate(0);
          setDate(previousMonth);
        }}
        disabled={leftDisabled}
        iconLeft={<ChevronBackwardIcon />}
        type="secondary"
        size="small"
      ></Button>

      <Tag>
        {format(date, 'LLLL', { locale: enGB })} {date.getFullYear()}
      </Tag>
      <Button
        onClick={() => setDate(add(new Date(lastDayOfMonth(date)), { days: 1 }))}
        disabled={rightDisabled}
        iconLeft={<ChevronForwardIcon />}
        type="secondary"
        size="small"
      ></Button>
    </Stack>
  );
};

export default DatepickerHeader;
