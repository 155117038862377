import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const TabLinksWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  width: 100%;
  margin: -24px -24px 0 -24px;
  align-items: center;
  padding: 0px 24px;
  background-color: ${({ theme }) => theme.orbit.backgroundBody};
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
`;

export const TabIconContainer = styled.div`
  margin: 2px 0 0 8px;
  color: #5f738c;

  & > svg {
    border: 1px solid white;
    border-radius: 50%;
    :hover {
      color: #000;
    }
  }
`;

export const TabLink = styled(({ active, backButton, ...props }) => <Link {...props} />)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  font-size: 12px;
  border-radius: 3px;
  transition: all 150ms ease-in-out;
  font-weight: 700 !important;
  background-color: ${({ theme }) => theme.orbit.backgroundButtonLinkPrimary};
  cursor: pointer;
  margin: 0 2px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.navigationSecondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.navigationSecondary};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.navigationSecondary};
      color: ${({ theme }) => theme.colors.tabNavigation};

      &:hover {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
        color: ${({ theme }) => theme.colors.tabNavigation};
      }
    `}
`;
