import { useContext, useEffect } from 'react';
import { CompanyUsersState } from '.';

const useCompanyUsers = (companyName, page = 1, perPage = 20, sort) => {
  const companyUsersCtx = useContext(CompanyUsersState);

  useEffect(() => {
    companyUsersCtx.loadCompanyUsers(companyName, page, perPage, sort);
    // eslint-disable-next-line
  }, [companyName]);
  return (
    companyUsersCtx?.[companyName] || {
      data: null,
      pagination: {
        page,
        perPage,
        pagesCount: 0,
        totalCount: 0,
      },
      loading: false,
      error: null,
      creatingUser: false,
      createError: null,
      updatingUser: false,
      updateError: null,
      removingUser: false,
      removeError: null,
    }
  );
};

export default useCompanyUsers;
