import expand from 'utils/expand';

const getActionTypes = expand('confirmDialog');

const { SHOW_CONFIRM_DIALOG } = getActionTypes('SHOW_CONFIRM_DIALOG');
const { HIDE_CONFIRM_DIALOG } = getActionTypes('HIDE_CONFIRM_DIALOG');

const initialState = {
  title: '',
  content: '',
  visible: false,
  handleConfirm: null,
  handleCancel: null,
  confirmLabel: '',
  cancelLabel: '',
};

export const showConfirmDialog = confirmDialog => ({
  type: SHOW_CONFIRM_DIALOG,
  confirmDialog,
});

export const hideConfirmDialog = () => ({
  type: HIDE_CONFIRM_DIALOG,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        ...action.confirmDialog,
        visible: true,
      };
    case HIDE_CONFIRM_DIALOG:
      return {
        ...state,
        title: '',
        content: '',
        visible: false,
        handleConfirm: null,
        handleCancel: null,
        confirmLabel: '',
        cancelLabel: '',
      };
    default:
      return state;
  }
};

export default reducer;
