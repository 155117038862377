import React, { useMemo } from 'react';
import Polyglot from 'node-polyglot';
// we'll have to switch to fetching these sooner or later
import translations from 'data/translations';

const locale = 'en';
const phrases = translations[locale];

const defaultPolyglot = new Polyglot({ locale: '', phrases: {} });

export const I18nState = React.createContext({
  locale,
  phrases,
  polyglot: defaultPolyglot,
});

const I18nProvider = ({ children }) => {
  const polyglot = useMemo(() => {
    return new Polyglot({ locale, phrases });
  }, []);

  const value = {
    polyglot,
    locale,
    phrases,
  };

  return <I18nState.Provider value={value}>{children}</I18nState.Provider>;
};

export default I18nProvider;
