import React, { useReducer } from 'react';

import { lowlight as low } from 'lowlight';
import { rehype } from 'rehype';
import plaintextLang from 'highlight.js/lib/languages/plaintext';
import bashLang from 'highlight.js/lib/languages/bash';
import jsonLang from 'highlight.js/lib/languages/json';
import httpLang from 'highlight.js/lib/languages/http';
import 'highlight.js/styles/github.css';

import { proxy } from 'utils/api';
import { isProduction } from 'utils/env';
import { ROUTE_CONFIG } from 'consts/routes';
import {
  CONTENT_TYPES,
  resourcesCodenames,
  CODE_SNIPPET_ONELINER_CLASS,
  CODE_SNIPPET_MULTILINE_CLASS,
} from 'consts/kentico';

import kenticoClient from './kentico';

const { TERMS_CONDITIONS_CODENAME, PRIVACY_POLICY_CODENAME } = resourcesCodenames;

low.registerLanguage('plaintext', plaintextLang);
low.registerLanguage('bash', bashLang);
low.registerLanguage('json', jsonLang);
low.registerLanguage('http', httpLang);

const initialState = {
  resources: {},
  allResources: { loading: false, error: null, data: null },
  taxonomies: {},
  codeSnippets: {},
  termsAndConditions: { loading: false, error: null, data: null },
  bookingTokens: {},
  registerPrivacyPolicy: { loading: false, error: null, data: null },
  registerTOS: { loading: false, error: null, data: null },
};

export const ResourcesState = React.createContext({
  state: {},
  fetchAllResources: x => x,
  fetchTaxonomy: x => x,
  fetchBookingToken: x => x,
  fetchRegisterPrivacyPolicy: x => x,
  fetchRegisterTOS: x => x,
});

const resourcesReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_ALL_RESOURCES':
      return {
        ...state,
        allResources: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'LOAD_ALL_RESOURCES_SUCCESS':
      return {
        ...state,
        allResources: {
          loading: false,
          data: action.allResources,
          error: null,
        },
      };
    case 'LOAD_ALL_RESOURCES_FAIL':
      return {
        ...state,
        allResources: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'LOAD_RESOURCE':
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.codename]: {
            ...state[action.codename],
            loading: true,
            error: null,
          },
        },
      };
    case 'LOAD_RESOURCE_SUCCESS':
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.codename]: {
            ...state[action.codename],
            loading: false,
            data: action.content,
          },
        },
      };
    case 'LOAD_RESOURCE_FAIL':
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.codename]: {
            ...state[action.codename],
            loading: false,
            error: action.error,
          },
        },
      };
    case 'FETCH_TAXONOMY':
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [action.codename]: {
            ...state.taxonomies[action.codename],
            data: null,
            loading: true,
            error: null,
          },
        },
      };
    case 'FETCH_TAXONOMY_SUCCESS':
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [action.codename]: {
            ...state.taxonomies[action.codename],
            loading: false,
            data: action.taxonomy,
          },
        },
      };
    case 'FETCH_TAXONOMY_FAIL':
      return {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [action.codename]: {
            ...state.taxonomies[action.codename],
            loading: false,
            error: action.error,
          },
        },
      };
    case 'LOAD_TERMS_AND_CONDITIONS':
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          loading: true,
        },
      };
    case 'LOAD_TERMS_AND_CONDITIONS_SUCCESS':
      return {
        ...state,
        termsAndConditions: {
          loading: false,
          error: null,
          data: action.termsAndConditions,
        },
      };
    case 'LOAD_TERMS_AND_CONDITIONS_FAIL':
      return {
        ...state,
        termsAndConditions: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    case 'FETCH_BOOKING_TOKEN':
      return {
        ...state,
        bookingTokens: {
          [action.apiKey]: {
            loading: true,
            data: null,
            error: null,
          },
        },
      };
    case 'FETCH_BOOKING_TOKEN_SUCCESS':
      return {
        ...state,
        bookingTokens: {
          [action.apiKey]: {
            loading: false,
            data: action.bookingToken,
            error: null,
          },
        },
      };
    case 'FETCH_BOOKING_TOKEN_FAIL':
      return {
        ...state,
        bookingTokens: {
          [action.apiKey]: {
            loading: false,
            data: null,
            error: action.error,
          },
        },
      };
    case 'LOAD_REGISTER_PRIVACY_POLICY':
      return {
        ...state,
        registerPrivacyPolicy: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'LOAD_REGISTER_PRIVACY_POLICY_SUCCESS':
      return {
        ...state,
        registerPrivacyPolicy: {
          loading: false,
          data: action.registerPrivacyPolicy,
          error: null,
        },
      };
    case 'LOAD_REGISTER_PRIVACY_POLICY_FAIL':
      return {
        ...state,
        registerPrivacyPolicy: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'LOAD_REGISTER_TOS':
      return {
        ...state,
        registerTOS: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'LOAD_REGISTER_TOS_SUCCESS':
      return {
        ...state,
        registerTOS: {
          loading: false,
          data: action.registerTOS,
          error: null,
        },
      };
    case 'LOAD_REGISTER_TOS_FAIL':
      return {
        ...state,
        registerTOS: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'SAVE_CODE_SNIPPET':
      return {
        ...state,
        codeSnippets: {
          ...state.codeSnippets,
          [action.codename]: action.text,
        },
      };
    default:
      return state;
  }
};

const ResourcesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(resourcesReducer, initialState);

  const fetchAllResources = async () => {
    dispatch({ type: 'LOAD_ALL_RESOURCES' });
    try {
      const response = await kenticoClient
        .items()
        .queryConfig({
          usePreviewMode: !isProduction(),
          linkResolver: (linkItem, context) => {
            const { codename, type } = linkItem;
            switch (type) {
              case CONTENT_TYPES.SWAGGER_DOCUMENTATION.single:
                return `${ROUTE_CONFIG.DOCS_TEQUILA_API.toPath}/${codename}`;
              case CONTENT_TYPES.ARTICLE.single:
                return `${ROUTE_CONFIG.DOCS_USER_GUIDES.toPath}/${codename}`;
              case CONTENT_TYPES.NEWS_API_ARTICLE.single:
                return `${ROUTE_CONFIG.DOCS_NEWS_API_SINGLE.toPath}/${codename}`;
              case CONTENT_TYPES.NEWS_ARTICLE.single:
                return `${ROUTE_CONFIG.DOCS_NEWS_SINGLE.toPath}/${codename}`;
              case CONTENT_TYPES.QUICK_GUIDES.single:
                return `${ROUTE_CONFIG.DOCS_FAQ_SINGLE.toPath}/${codename}`;
              default:
                return codename;
            }
          },
          richTextResolver: item => {
            if (item.code && item.code.name === 'Code') {
              const {
                code,
                elements,
                system: { codename },
              } = item;
              dispatch({ type: 'SAVE_CODE_SNIPPET', codename, text: code.value });

              const language = elements.language.value[0].codename;
              const tree = low.highlight(language, code.value);
              const highlighted = rehype()
                .stringify({ type: 'root', children: tree.children })
                .toString();
              const newlineIndex = code.value.indexOf('\n');
              const textAfterNewline = code.value.slice(newlineIndex + 1, code.value.length);

              const generateCodeElement = className =>
                `<pre class="${className}" id="${codename}"><code>${highlighted}</code></pre>`;

              // decide if this codeblock is a one liner, or a code block
              if (newlineIndex !== -1 && textAfterNewline) {
                return generateCodeElement(CODE_SNIPPET_MULTILINE_CLASS);
              }

              return generateCodeElement(CODE_SNIPPET_ONELINER_CLASS);
            }
          },
        })
        .getPromise();

      dispatch({
        type: 'LOAD_ALL_RESOURCES_SUCCESS',
        allResources: response.items,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_ALL_RESOURCES_FAIL', error });
    }
  };

  const fetchTaxonomy = codename => {
    dispatch({ type: 'FETCH_TAXONOMY', codename });

    return (
      kenticoClient
        .taxonomy(codename)
        .queryConfig({
          usePreviewMode: !isProduction(),
        })
        .getPromise()
        // taxonomyData also contains a debug object that contains detailed information about the response
        .then(taxonomyData => {
          dispatch({ type: 'FETCH_TAXONOMY_SUCCESS', codename, taxonomy: taxonomyData.taxonomy });
        })
        .catch(error => dispatch({ type: 'FETCH_TAXONOMY_FAIL', codename, error }))
    );
  };

  const fetchBookingToken = async apikey => {
    try {
      const { data } = await proxy.direct.get('booking/bookabletoken', {
        headers: {
          apikey,
        },
      });
      const bookingToken = data.booking_token;

      return dispatch({ type: 'FETCH_BOOKING_KEY_SUCCESS', bookingToken });
    } catch (error) {
      return Promise.reject(dispatch({ type: 'FETCH_BOOKING_TOKEN_FAIL', error }));
    }
  };

  const fetchRegisterPrivacyPolicy = () => {
    dispatch({ type: 'LOAD_REGISTER_PRIVACY_POLICY' });

    return kenticoClient
      .item(PRIVACY_POLICY_CODENAME)
      .queryConfig({
        usePreviewMode: !isProduction(),
      })
      .getPromise()
      .then(response =>
        dispatch({
          type: 'LOAD_REGISTER_PRIVACY_POLICY_SUCCESS',
          registerPrivacyPolicy: response.item,
        }),
      )
      .catch(error => dispatch({ type: 'LOAD_REGISTER_PRIVACY_POLICY_FAIL', error }));
  };

  const fetchRegisterTOS = () => {
    dispatch({ type: 'LOAD_REGISTER_TOS' });

    return kenticoClient
      .item(TERMS_CONDITIONS_CODENAME)
      .queryConfig({
        usePreviewMode: !isProduction(),
      })
      .getPromise()
      .then(response => dispatch({ type: 'LOAD_REGISTER_TOS_SUCCESS', registerTOS: response.item }))
      .catch(error => dispatch({ type: 'LOAD_REGISTER_TOS_FAIL', error }));
  };

  const value = {
    state,
    fetchAllResources,
    fetchTaxonomy,
    fetchBookingToken,
    fetchRegisterPrivacyPolicy,
    fetchRegisterTOS,
  };

  return <ResourcesState.Provider value={value}>{children}</ResourcesState.Provider>;
};

export default ResourcesProvider;
