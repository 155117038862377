import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Select from '@kiwicom/orbit-components/lib/Select';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { usePolyglot } from 'components/services/i18n';
import { DayPickerButton, SelectTime, ErrorMessageComponent } from 'common';

const TextFilterContainer = styled.div`
  width: 244px;
  & span {
    line-height: 20px;
  }
`;

const SelectContainer = styled.div`
  width: 111px;
  max-width: 111px;
`;

const SelectTimeContainer = styled.div`
  width: 150px;
  max-width: 150px;
  & span {
    line-height: 20px;
  }
`;

const DateInputContainer = styled.div`
  width: 160px;
  max-width: 160px;
`;

const Filters = ({
  loading,
  perPage,
  perPageOptions,
  onPerPageChange,
  startDate,
  onSelectStartDate,
  endDate,
  onSelectEndDate,
  startTime,
  onSelectStartTime,
  endTime,
  onSelectEndTime,
  text,
  onTextChange,
  showInvalidDateRangeError,
}) => {
  const polyglot = usePolyglot();

  return (
    <Stack>
      <SelectContainer>
        <Select
          label={polyglot.t('logs.per_page_label')}
          options={perPageOptions.map(o => ({ value: o, label: o }))}
          size="small"
          value={perPage}
          onChange={onPerPageChange}
          disabled={loading}
        />
      </SelectContainer>

      <Stack direction="row" align="start" spacing="XXSmall">
        <DateInputContainer>
          <DayPickerButton
            size="small"
            label={polyglot.t('logs.start_date')}
            onSelect={onSelectStartDate}
            disabled={loading}
            value={startDate && format(startDate, 'dd. MM. yyyy.')}
            hideOnDayClick={true}
            disabledDays={[
              {
                after: endDate,
              },
            ]}
          />
        </DateInputContainer>
        <SelectTimeContainer>
          <SelectTime
            onChange={onSelectStartTime}
            disabled={!startDate || loading}
            label={polyglot.t('logs.select_time')}
            time={startTime}
          />
        </SelectTimeContainer>
        <DateInputContainer>
          <DayPickerButton
            size="small"
            label={polyglot.t('logs.end_date')}
            onSelect={onSelectEndDate}
            disabled={loading}
            value={endDate && format(endDate, 'dd. MM. yyyy.')}
            hideOnDayClick={true}
            disabledDays={[
              {
                before: startDate,
              },
            ]}
          />
        </DateInputContainer>
        <SelectTimeContainer>
          <SelectTime
            onChange={onSelectEndTime}
            disabled={!endDate || loading}
            label={polyglot.t('logs.select_time')}
            time={endTime}
          />
        </SelectTimeContainer>
        <TextFilterContainer>
          <InputField
            size="small"
            onChange={e => {
              e.persist();
              onTextChange(e);
            }}
            disabled={loading}
            label={polyglot.t('logs.text')}
          />
        </TextFilterContainer>
      </Stack>
      {showInvalidDateRangeError && (
        <ErrorMessageComponent>{polyglot.t('logs.invalid_date_range')}</ErrorMessageComponent>
      )}
    </Stack>
  );
};

export default Filters;
