import expand from 'utils/expand';
import api from 'utils/api';
import reportAliasMapper from 'mappers/nextGen/reports/aliases/_';
import { FIRST_PAGE } from 'consts/pagination';
import { getPolyglot } from 'redux/selectors/i18n';
import { REPORT_ALIAS_TYPES } from 'consts/reports';

const getActionTypes = expand('reportAliases');

const { LOAD_REPORT_ALIASES, LOAD_REPORT_ALIASES_SUCCESS, LOAD_REPORT_ALIASES_FAIL } =
  getActionTypes('LOAD_REPORT_ALIASES');
const { LOAD_REPORT_ALIAS, LOAD_REPORT_ALIAS_SUCCESS, LOAD_REPORT_ALIAS_FAIL } =
  getActionTypes('LOAD_REPORT_ALIAS');
const { CREATE_REPORT_ALIAS, CREATE_REPORT_ALIAS_SUCCESS, CREATE_REPORT_ALIAS_FAIL } =
  getActionTypes('CREATE_REPORT_ALIAS');
const { UPDATE_REPORT_ALIAS, UPDATE_REPORT_ALIAS_SUCCESS, UPDATE_REPORT_ALIAS_FAIL } =
  getActionTypes('UPDATE_REPORT_ALIAS');
const { DELETE_REPORT_ALIAS, DELETE_REPORT_ALIAS_SUCCESS, DELETE_REPORT_ALIAS_FAIL } =
  getActionTypes('DELETE_REPORT_ALIAS');

const initialState = {
  items: [],
  reportAlias: {},
  loading: false,
  submitting: false,
  error: null,
  submitError: null,
  oldInterval: undefined,
  pagination: {
    page: FIRST_PAGE.page,
    perPage: FIRST_PAGE.perPage,
    totalCount: 0,
    pagesCount: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_REPORT_ALIASES:
      return {
        ...state,
        items: initialState.items,
        pagination: initialState.pagination,
        loading: true,
        error: null,
      };
    case LOAD_REPORT_ALIASES_SUCCESS:
      return {
        ...state,
        items: action.items,
        pagination: action.pagination,
        loading: false,
        error: null,
      };
    case LOAD_REPORT_ALIASES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOAD_REPORT_ALIAS:
      return {
        ...state,
        reportAlias: initialState.reportAlias,
        loading: true,
        error: null,
      };
    case LOAD_REPORT_ALIAS_SUCCESS:
      return {
        ...state,
        reportAlias: action.reportAlias,
        loading: false,
        error: null,
      };
    case LOAD_REPORT_ALIAS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_REPORT_ALIAS:
    case UPDATE_REPORT_ALIAS:
    case DELETE_REPORT_ALIAS:
      return {
        ...state,
        submitting: true,
        submitError: null,
      };
    case CREATE_REPORT_ALIAS_SUCCESS:
    case UPDATE_REPORT_ALIAS_SUCCESS:
      return {
        ...state,
        reportAlias: action.reportAlias,
        submitting: false,
        submitError: null,
        oldInterval: (state.reportAlias && state.reportAlias.interval) || undefined,
      };
    case DELETE_REPORT_ALIAS_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitError: null,
      };
    case CREATE_REPORT_ALIAS_FAIL:
    case UPDATE_REPORT_ALIAS_FAIL:
    case DELETE_REPORT_ALIAS_FAIL:
      return {
        ...state,
        submitting: false,
        submitError: action.error,
      };
    default:
      return state;
  }
}

export const load =
  (page = FIRST_PAGE.page, perPage = FIRST_PAGE.perPage) =>
  (dispatch, getState) => {
    const polyglot = getPolyglot(getState());
    const query = {
      page,
      per_page: perPage,
      type: `${REPORT_ALIAS_TYPES.draft},${REPORT_ALIAS_TYPES.default}`,
    };

    dispatch({ type: LOAD_REPORT_ALIASES });

    return api.v1
      .get('reports/aliases', { params: query })
      .then(({ data: response }) => {
        const pagination = {
          page,
          pagesCount: response.pagination.pages,
          perPage: response.pagination.page_size,
          totalCount: response.pagination.total,
        };
        return dispatch({
          type: LOAD_REPORT_ALIASES_SUCCESS,
          items: response.data.map(reportAlias =>
            reportAliasMapper.v1.get.from(reportAlias, polyglot),
          ),
          pagination,
        });
      })
      .catch(error => {
        return dispatch({ type: LOAD_REPORT_ALIASES_FAIL, error });
      });
  };

export const loadById = reportAliasId => (dispatch, getState) => {
  const polyglot = getPolyglot(getState());

  dispatch({ type: LOAD_REPORT_ALIAS });

  return api.v1
    .get(`reports/aliases/${reportAliasId}`)
    .then(({ data: response }) => {
      return dispatch({
        type: LOAD_REPORT_ALIAS_SUCCESS,
        reportAlias: reportAliasMapper.v1.get.from(response, polyglot),
      });
    })
    .catch(error =>
      dispatch({
        type: LOAD_REPORT_ALIAS_FAIL,
        error,
      }),
    );
};

export const create = reportAlias => (dispatch, getState) => {
  dispatch({ type: CREATE_REPORT_ALIAS });
  const polyglot = getPolyglot(getState());

  return api.v1
    .post('reports/aliases', reportAliasMapper.v1.post.to(reportAlias))
    .then(response =>
      dispatch({
        type: CREATE_REPORT_ALIAS_SUCCESS,
        reportAlias: reportAliasMapper.v1.post.from(response.data, polyglot),
      }),
    )
    .catch(error => {
      let message;
      // Conflict
      if (error.status === 409) {
        message = 'reports.already_exists';
      } else {
        message = 'common.submit_error_message';
      }
      return dispatch({ type: CREATE_REPORT_ALIAS_FAIL, error: { ...error, message } });
    });
};

export const edit = reportAlias => (dispatch, getState) => {
  dispatch({ type: UPDATE_REPORT_ALIAS });

  return api.v1
    .put(`reports/aliases/${reportAlias.id}`, reportAliasMapper.v1.put.to(reportAlias))
    .then(response => {
      const polyglot = getPolyglot(getState());

      return dispatch({
        type: UPDATE_REPORT_ALIAS_SUCCESS,
        reportAlias: reportAliasMapper.v1.put.from(response.data, polyglot),
      });
    });
};

export const deleteAlias = id => dispatch => {
  dispatch({ type: DELETE_REPORT_ALIAS });

  return api.v1
    .delete(`reports/aliases/${id}`)
    .then(() => dispatch({ type: DELETE_REPORT_ALIAS_SUCCESS }))
    .catch(error => dispatch({ type: DELETE_REPORT_ALIAS_FAIL, error }));
};
