import { isProduction } from 'utils/env';
import { TEQUILA_BRANDS } from 'consts/brand';

export const getBrandFromProduct = product => {
  const isSmartpoint = product.value.includes('smartpoint');
  const isDeposit = product.isDeposit;

  const root = isProduction() ? TEQUILA_BRANDS.production : TEQUILA_BRANDS.staging;

  if (isSmartpoint) {
    if (isDeposit) {
      return root.SMARTPOINT_DEPOSIT;
    } else {
      return root.SMARTPOINT_ZOOZ;
    }
  } else {
    if (isDeposit) {
      return root.DEPOSIT;
    } else {
      return root.ZOOZ;
    }
  }
};
