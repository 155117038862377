import expand from 'utils/expand';
// if this gets too big we'll switch to loading i18n from API
import translations from 'data/translations';

const getActionTypes = expand('i18n');

export const { SET_LOCALE } = getActionTypes('SET_LOCALE');

const initalState = {
  locale: 'en',
  phrases: translations['en'],
};

export default function reducer(state = initalState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
        phrases: action.phrases,
      };
    default:
      return {
        ...state,
      };
  }
}

/**
 * Updates polyglot with correct phrases for locale
 * @param {string} locale
 */
export const setLocale = locale => {
  const phrases = translations[locale];
  return { type: SET_LOCALE, locale, phrases };
};
