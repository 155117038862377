const v1_get_from = user => {
  return {
    companyName: user.company_name,
    dateOfBirth: user.date_of_birth,
    email: user.email,
    firstName: user.first_name,
    jiraOrganizationId: user.jira_organization_id,
    lastName: user.last_name,
    nationality: user.nationality,
    role: user.role,
    status: user.status,
    username: user.username,
    uuid: user.uuid,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
