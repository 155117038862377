import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import VisibilityIcon from '@kiwicom/orbit-components/lib/icons/Visibility';
import VisibilityOffIcon from '@kiwicom/orbit-components/lib/icons/VisibilityOff';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import copyToClipboard from 'utils/copyToClipboard';
import { AuthState } from 'components/services/auth/AuthProvider';
import { Card, CardData, CardActionHeader } from 'components/common/Card';
import { usePolyglot } from 'components/services/i18n';
import { Space } from 'common';
import { useToggle } from 'utils/hooks';

import SolutionDetailsForm from './SolutionDetailsForm';

const Pointer = styled.div`
  cursor: pointer;
`;

const InputWrapper = styled.div`
  margin-top: 10px;
  input {
    cursor: pointer;
    width: 275px;
  }
`;

const SuffixWrapper = styled.div`
  padding-right: 12px;
`;

const SolutionDetails = ({ solution, companyDetails, product, canEditQuota }) => {
  const appDetailsFormOpen = useToggle();
  const consumerKeyVisible = useToggle();
  const successCopyMessageVisible = useToggle();
  const successUpdateAlert = useToggle();
  const polyglot = usePolyglot();
  const { checkGrantProperties } = useContext(AuthState);
  const customerSupportEnabled = companyDetails.customerSupportEnabled;

  const canEdit =
    checkGrantProperties(
      'model.application',
      'update',
      'webhook_production',
      companyDetails.name,
    ) ||
    checkGrantProperties('model.application', 'update', 'webhook_staging', companyDetails.name);

  const canCsAndWebhook = solution.productCategory !== 'AFFILIATE';

  const getCardData = () => {
    const leftColumn = [];
    const rightColumn = [];

    leftColumn.push({
      label: polyglot.t('solution.api_key'),
      value: (
        <InputWrapper>
          <Stack direction="column" spacing="XXXSmall">
            <Pointer onDoubleClick={copyConsumerKey}>
              <Tooltip preferredPosition="top" content={polyglot.t(getCopyMessage())}>
                <InputField
                  value={solution.consumerKey}
                  onBlur={successCopyMessageVisible.setOff}
                  readOnly
                  type={consumerKeyVisible.isOn ? 'text' : 'password'}
                  suffix={
                    <SuffixWrapper>
                      <Pointer
                        onClick={
                          consumerKeyVisible.isOn
                            ? consumerKeyVisible.setOff
                            : consumerKeyVisible.setOn
                        }
                      >
                        <Button type="secondary" size="small">
                          {consumerKeyVisible.isOn ? (
                            <VisibilityOffIcon size="small" />
                          ) : (
                            <VisibilityIcon size="small" />
                          )}
                        </Button>
                      </Pointer>
                    </SuffixWrapper>
                  }
                />
              </Tooltip>
            </Pointer>
          </Stack>
        </InputWrapper>
      ),
    });

    if (canCsAndWebhook) {
      if (!customerSupportEnabled) {
        leftColumn.push({
          label: polyglot.t('solution.cs_email'),
          value: solution.csEmail || '-',
        });
        leftColumn.push({
          label: polyglot.t('solution.cs_phone'),
          value: solution.csPhone || '-',
        });
      }
      rightColumn.push({
        label: polyglot.t('solution.webhook_endpoint_production'),
        value: solution.webhookProduction || '-',
      });
      rightColumn.push({
        label: polyglot.t('solution.webhook_endpoint_staging'),
        value: solution.webhookStaging || '-',
      });
    }

    if (solution && product) {
      const setQuotaConfig = solution.quotaConfig || product.quotaConfig;

      const getQuotaTranslation = () => {
        if (setQuotaConfig.quota > 1) {
          if (setQuotaConfig.quotaInterval > 1) {
            return 'solutions.details.quota_info_requests_multiple_timeunit_multiple';
          } else {
            return 'solutions.details.quota_info_requests_multiple_timeunit_single';
          }
        } else {
          if (setQuotaConfig.quotaInterval > 1) {
            return 'solutions.details.quota_info_requests_single_timeunit_multiple';
          } else {
            return 'solutions.details.quota_info_requests_single_timeunit_single';
          }
        }
      };

      const quotaTranslationKey = getQuotaTranslation();

      rightColumn.push({
        label: polyglot.t('solutions.details.quota_key'),
        value: polyglot.t(quotaTranslationKey, {
          requests: setQuotaConfig.quota,
          interval: setQuotaConfig.quotaInterval,
          timeUnit: setQuotaConfig.quotaTimeUnit,
        }),
      });
    }

    return [leftColumn, rightColumn];
  };

  const copyConsumerKey = () => {
    copyToClipboard(solution.consumerKey);

    successCopyMessageVisible.setOn();

    setTimeout(() => {
      successCopyMessageVisible.setOff();
    }, 3000);
  };

  const getCopyMessage = () => {
    return successCopyMessageVisible.isOn
      ? 'common.copied_to_clipboard'
      : 'common.double_click_to_copy';
  };

  const [leftColumn, rightColumn] = getCardData();

  return (
    <>
      {appDetailsFormOpen.isOn && (
        <SolutionDetailsForm
          onSuccess={() => {
            appDetailsFormOpen.setOff();
            successUpdateAlert.setOn();
          }}
          onClose={appDetailsFormOpen.setOff}
          product={product}
          canEditQuota={canEditQuota}
        />
      )}
      <Card>
        <CardActionHeader
          title={polyglot.t('common.details')}
          icon={
            <Space right="m">
              <InformationCircleIcon />
            </Space>
          }
          action={
            ((canEdit && canCsAndWebhook) || canEditQuota) && (
              <Button
                type="secondary"
                size="small"
                onClick={appDetailsFormOpen.setOn}
                iconLeft={<EditIcon />}
              >
                {polyglot.t('common.edit')}
              </Button>
            )
          }
        />
        <CardData
          showAlert={successUpdateAlert.isOn}
          alert={
            <Alert type="success" icon closable onClose={successUpdateAlert.setOff}>
              {polyglot.t('solutions.details_edit_success')}
            </Alert>
          }
          leftColumn={leftColumn}
          rightColumn={rightColumn}
        />
      </Card>
    </>
  );
};

export default SolutionDetails;
