import React, { useState, useReducer } from 'react';

import api, { proxy } from 'utils/api';
import { useToggle } from 'utils/hooks';
import * as Intercom from 'utils/intercom';
import { formikToApi } from 'mappers/SmartPass';

export const SmartPassContext = React.createContext();

const initialState = {
  smartPassesList: [],
  smartPassesListLoading: false,
  smartPassesListError: null,
  smartPass: null,
  smartPassLoading: false,
  smartPassError: null,
  createNewSmartPassLoading: false,
  createNewSmartPassError: null,
  approveSmartPassLoading: false,
  approveSmartPassError: null,
  editSmartPassLoading: false,
  editSmartPassError: null,
  requestChangeData: null,
  requestChangeLoading: false,
  requestChangeError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_SMART_PASSES_LIST': {
      return {
        ...state,
        smartPassesListLoading: true,
        smartPassesListError: null,
        smartPassesList: [],
      };
    }
    case 'FETCH_SMART_PASSES_LIST_SUCCESS': {
      return {
        ...state,
        smartPassesListLoading: false,
        smartPassesList: action.smartPassesList,
      };
    }
    case 'FETCH_SMART_PASSES_LIST_FAILED': {
      return {
        ...state,
        smartPassesListLoading: false,
        smartPassesListError: action.error,
      };
    }
    case 'FETCH_SMART_PASS': {
      return {
        ...state,
        smartPassLoading: true,
        smartPassError: null,
        smartPass: null,
      };
    }
    case 'FETCH_SMART_PASS_SUCCESS': {
      return {
        ...state,
        smartPassLoading: false,
        approveSmartPassError: null,
        editSmartPassError: null,
        smartPass: action.smartPass,
      };
    }
    case 'FETCH_SMART_PASS_FAILED': {
      return {
        ...state,
        smartPassLoading: false,
        smartPassError: action.error,
      };
    }
    case 'CREATE_NEW_SMART_PASS': {
      return {
        ...state,
        createNewSmartPassLoading: true,
        createNewSmartPassError: null,
      };
    }
    case 'CREATE_NEW_SMART_PASS_SUCCESS': {
      return {
        ...state,
        createNewSmartPassLoading: false,
      };
    }
    case 'CREATE_NEW_SMART_PASS_FAILED': {
      return {
        ...state,
        createNewSmartPassLoading: false,
        createNewSmartPassError: action.error,
      };
    }
    case 'APPROVE_SMART_PASS': {
      return {
        ...state,
        approveSmartPassLoading: true,
        approveSmartPassError: null,
      };
    }
    case 'APPROVE_SMART_PASS_SUCCESS': {
      return {
        ...state,
        approveSmartPassLoading: false,
      };
    }
    case 'APPROVE_SMART_PASS_FAILED': {
      return {
        ...state,
        approveSmartPassLoading: false,
        approveSmartPassError: action.error,
      };
    }
    case 'EDIT_SMART_PASS': {
      return {
        ...state,
        editSmartPassLoading: true,
        editSmartPassError: null,
      };
    }
    case 'EDIT_SMART_PASS_SUCCESS': {
      return {
        ...state,
        editSmartPassLoading: false,
      };
    }
    case 'EDIT_SMART_PASS_FAILED': {
      return {
        ...state,
        editSmartPassLoading: false,
        editSmartPassError: action.error,
      };
    }
    case 'REQUEST_CHANGE': {
      return {
        ...state,
        requestChangeLoading: true,
        requestChangeError: null,
      };
    }
    case 'REQUEST_CHANGE_SUCCESS': {
      return {
        ...state,
        requestChangeLoading: false,
        requestChangeData: action.data,
      };
    }
    case 'REQUEST_CHANGE_FAILED': {
      return {
        ...state,
        requestChangeLoading: false,
        requestChangeError: action.error,
      };
    }
    case 'CLEAR_SUCCESS_MESSAGE': {
      return {
        ...state,
        requestChangeData: null,
      };
    }
    default:
      return state;
  }
};

const SmartPassProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const saveForReviewModal = useToggle();
  const unsavedVoucherModal = useToggle();
  const requestChangeModal = useToggle();
  const fastTrackVoucherModal = useToggle();
  const voucherExampleModal = useToggle();
  const exitModal = useToggle();
  const [deactivateModal, changeDeactivateModal] = useState(null);
  const [voucherPreviewModalInfo, setVoucherPreviewModalInfo] = useState(null);
  const [deleteVoucherModalInfo, setDeleteVoucherModalInfo] = useState(null);

  const [editingVoucher, setEditingVoucher] = useState(null);
  const addNewVoucherScreen = useToggle();

  const showVoucherPreviewModal = info => setVoucherPreviewModalInfo(info);
  const hideVoucherPreviewModal = () => setVoucherPreviewModalInfo(null);
  const showDeleteVoucherModal = info => setDeleteVoucherModalInfo(info);
  const hideDeleteVoucherModal = () => setDeleteVoucherModalInfo(null);

  const fetchSmartPassesList = async companyName => {
    dispatch({ type: 'FETCH_SMART_PASSES_LIST' });
    try {
      const res = await api.v1.get(`special-apps/smartpass/${companyName}`);
      dispatch({ type: 'FETCH_SMART_PASSES_LIST_SUCCESS', smartPassesList: res.data });
    } catch (error) {
      dispatch({ type: 'FETCH_SMART_PASSES_LIST_FAILED', error: error.message });
    }
  };

  const createNewSmartPass = async (values, saveType, companyName) => {
    dispatch({ type: 'CREATE_NEW_SMART_PASS' });
    try {
      const res = await api.v1.post(
        `special-apps/smartpass/${companyName}`,
        formikToApi(values, saveType, companyName),
      );
      dispatch({ type: 'CREATE_NEW_SMART_PASS_SUCCESS' });

      try {
        Intercom.trackEvent('created-smartpass', {
          company: companyName,
          save_type: saveType,
        });
      } catch (_) {}

      return res;
    } catch (error) {
      dispatch({ type: 'CREATE_NEW_SMART_PASS_FAILED', error });
    }
  };

  const editSmartPass = async (smartPassId, values, saveType, companyName) => {
    dispatch({ type: 'EDIT_SMART_PASS' });
    try {
      const res = await api.v1.put(
        `special-apps/smartpass/${companyName}/${smartPassId}`,
        formikToApi(values, saveType, companyName, smartPassId),
      );
      dispatch({ type: 'EDIT_SMART_PASS_SUCCESS' });

      try {
        Intercom.trackEvent('edited-smartpass', {
          smartPassId,
          company: companyName,
          save_type: saveType,
        });
      } catch (_) {}

      return res;
    } catch (error) {
      dispatch({ type: 'EDIT_SMART_PASS_FAILED', error });
    }
  };

  const fetchSmartPass = async (smartPassId, companyName) => {
    dispatch({ type: 'FETCH_SMART_PASS' });
    try {
      const res = await api.v1.get(`special-apps/smartpass/${companyName}/${smartPassId}`);
      dispatch({ type: 'FETCH_SMART_PASS_SUCCESS', smartPass: res.data });
    } catch (error) {
      dispatch({ type: 'FETCH_SMART_PASS_FAILED', error });
    }
  };

  const uploadFile = async (imageFile, base64, companyName) => {
    try {
      const res = await api.v1.post(`special-apps/smartpass/${companyName}/images`, {
        name: imageFile.name.replace('.jpeg', '.jpg'),
        mimetype: imageFile.type,
        base64,
      });
      return res.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const approveSmartPass = async (smartPassId, companyName) => {
    dispatch({ type: 'APPROVE_SMART_PASS' });
    try {
      const res = await api.v1.put(`special-apps/smartpass/${companyName}/${smartPassId}/approve`);
      dispatch({ type: 'APPROVE_SMART_PASS_SUCCESS' });
      return res;
    } catch (error) {
      dispatch({ type: 'APPROVE_SMART_PASS_FAILED', error });
    }
  };

  const requestChange = async (summary, description, smartPassRequestTypeId) => {
    dispatch({ type: 'REQUEST_CHANGE' });
    try {
      const body = {
        requestTypeId: smartPassRequestTypeId,
        requestFieldValues: {
          summary,
          description,
          temporaryAttachments: [],
        },
      };

      const res = await proxy.v1.post(`jira/bpsd/request`, JSON.stringify(body));
      try {
        Intercom.trackEvent('created-help-request', {
          'Issue Key': res.data.request.issueKey, // depends on shouldUseNewEndpoints(),
        });
      } catch (_) {}

      dispatch({ type: 'REQUEST_CHANGE_SUCCESS', data: res.data });
      return res;
    } catch (error) {
      dispatch({ type: 'REQUEST_CHANGE_FAILED', error: error.message });
    }
  };

  const clearSuccessMessage = () => dispatch({ type: 'CLEAR_SUCCESS_MESSAGE' });

  const value = {
    state,
    fetchSmartPassesList,
    fetchSmartPass,
    saveForReviewModal,
    unsavedVoucherModal,
    editingVoucher,
    setEditingVoucher,
    addNewVoucherScreen,
    exitModal,
    fastTrackVoucherModal,
    voucherExampleModal,
    voucherPreviewModalInfo,
    showVoucherPreviewModal,
    hideVoucherPreviewModal,
    deleteVoucherModalInfo,
    deactivateModal,
    changeDeactivateModal,
    showDeleteVoucherModal,
    hideDeleteVoucherModal,
    uploadFile,
    createNewSmartPass,
    editSmartPass,
    approveSmartPass,
    requestChangeModal,
    requestChange,
    clearSuccessMessage,
  };

  return <SmartPassContext.Provider value={value}>{children}</SmartPassContext.Provider>;
};

export default SmartPassProvider;
