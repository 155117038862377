import * as R from 'ramda';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';

import { setCookie, getCookie } from 'utils/cookie';
import {
  tripTypes,
  tripTypesValues,
  MULTICITY_PRODUCT_TYPES,
  NOMAD_PRODUCT_TYPES,
  vehicleTypes,
  passengerTypes,
  MAX_PASSENGERS_SUM,
  locationTypes,
  GROUND_TRANSPORT,
} from 'consts/search';
import { initialSearchValues } from 'components/services/search/SearchProvider';

import returnAdapters from './adapters/return';
import multicityAdapters from './adapters/multicity';

export const correctReturnDates = (departureSelectedDates, returnSelectedDates) => {
  return isAfter(new Date(R.head(departureSelectedDates)), new Date(R.head(returnSelectedDates)))
    ? returnSelectedDates.filter(
        date =>
          isEqual(new Date(date), new Date(R.head(departureSelectedDates))) ||
          isAfter(new Date(date), new Date(R.head(departureSelectedDates))),
      )
    : returnSelectedDates;
};

export const parseLocations = (locations, joinLocations = true) => {
  const parsedLocations = locations.map(location =>
    location.type === 'airport' ? `${location.type}:${location.value}` : location.value,
  );
  return joinLocations ? parsedLocations.join(',') : parsedLocations;
};

export const getParsedPassengersAmounts = passengersAmounts => {
  return passengersAmounts.reduce((prev, passenger) => {
    if (passenger.amount === 0) return prev;
    return { ...prev, [passenger.type]: passenger.amount };
  }, {});
};

export const getIncludedOrExcludedCarriersList = (allCarriers, filteredCarriers) => {
  if (!allCarriers?.length || !filteredCarriers.length) {
    return [undefined, undefined];
  }

  if (filteredCarriers.length >= allCarriers.length / 2) {
    const includedCarriers = allCarriers.filter(x => !filteredCarriers.includes(x));
    return [includedCarriers.join(','), false];
  }

  return [filteredCarriers.join(','), true];
};

const { RETURN, ONEWAY, MULTICITY, NOMAD } = tripTypesValues;
export const flightCardAdapters = {
  [RETURN]: returnAdapters,
  [ONEWAY]: returnAdapters,
  [MULTICITY]: multicityAdapters,
  [NOMAD]: multicityAdapters,
};

export {
  duration,
  secondsToString,
  defaultDateFormat,
  localTimeFormat,
  dateRange,
  convertUTCDateToLocalDate,
} from './timeFormatters';

export const getClearableValues = state =>
  R.pick(
    [
      'passengersAmounts',
      'selectedCabinClassType',
      'vehicleTypes',
      'filteredCarriers',
      'pricesFilterValues',
      'numberOfStopovers',
      'currency',
      'departureHours',
      'arrivalHours',
      'returnDepartureHours',
      'returnArrivalHours',
      'response',
      'sortType',
      'selectedStopAirports',
      'isSortShown',
      'bestFlights',
    ],
    state,
  );

export const expandLocations = (currentLocations, expandedLocations) => {
  const toAdd = [];
  currentLocations.forEach(({ type, value }) => {
    if (type === locationTypes.AIRPORT && expandedLocations[value]) {
      const location = expandedLocations[value].data;
      if (location) {
        const { id, name } = location.city;
        if (currentLocations.find(elem => elem.value === id) === undefined) {
          toAdd.push({ label: name, value: id, type: locationTypes.CITY });
        }
      }
    }
  });
  return currentLocations.concat(toAdd);
};

export const areLocationsExpanded = (currentLocations, expandedLocations) => {
  const groundLocations = currentLocations.filter(({ type }) => type === locationTypes.CITY);
  const airports = currentLocations.filter(({ type }) => type === locationTypes.AIRPORT);
  return airports
    .map(
      ({ value }) =>
        expandedLocations[value] &&
        expandedLocations[value].data &&
        groundLocations.find(item => item.value === expandedLocations[value].data.city.id),
    )
    .every(elem => Boolean(elem));
};

export const getSumOfPassengers = passengersAmounts => {
  return passengersAmounts.reduce((prev, passenger) => prev + passenger.amount, 0);
};

export const getPassengersAmountsQueryString = passengersAmounts => {
  const adults = passengersAmounts.find(pa => pa.type === 'adults').amount;
  const children = passengersAmounts.find(pa => pa.type === 'children').amount;
  const infants = passengersAmounts.find(pa => pa.type === 'infants').amount;
  return `${adults}-${children}-${infants}`;
};

export const getIsIncrementDisabled = (passengersAmounts, passenger) => {
  const sumOfPassengers = getSumOfPassengers(passengersAmounts);
  if (passenger.type === passengerTypes.INFANTS) {
    const numberOfAdults = passengersAmounts.find(p => p.type === passengerTypes.ADULTS).amount;

    return passenger.amount === numberOfAdults || sumOfPassengers === MAX_PASSENGERS_SUM;
  }

  return sumOfPassengers === MAX_PASSENGERS_SUM;
};

export const getTripTypes = productType => {
  if (MULTICITY_PRODUCT_TYPES.includes(productType)) {
    return tripTypes.filter(tripType => tripType.value === MULTICITY);
  } else if (NOMAD_PRODUCT_TYPES.includes(productType)) {
    return tripTypes.filter(tripType => tripType.value === NOMAD);
  }
  return tripTypes.filter(tripType => tripType.value === RETURN || tripType.value === ONEWAY);
};

export const getPricesFilterDefaultValues = tabData => {
  if (
    !tabData.response[tabData.tripType] ||
    !tabData.response[tabData.tripType][tabData.sortType] ||
    tabData.response[tabData.tripType][tabData.sortType].data.length === 0
  ) {
    return null;
  }

  const { getMinPrice, getMaxPrice } = flightCardAdapters[tabData.tripType];

  return [
    getMinPrice(tabData.response[tabData.tripType][tabData.sortType]),
    getMaxPrice(tabData.response[tabData.tripType][tabData.sortType]),
  ];
};

export const getCurrencySymbol = tabData => {
  if (!tabData.response[tabData.tripType][tabData.sortType]) {
    return;
  }

  const { getCurrencySymbolFromTrip } = flightCardAdapters[tabData.tripType];

  return getCurrencySymbolFromTrip(tabData.response[tabData.tripType][tabData.sortType].data[0]);
};

export const getCarriersList = tabData => {
  if (!tabData?.response?.[tabData.tripType]?.[tabData.sortType]?.data) {
    return [];
  }

  const { getAllCarriers } = flightCardAdapters[tabData.tripType];
  //Keeps previously filtered carriers in carrier select
  return R.uniq([
    ...getAllCarriers(tabData.response[tabData.tripType][tabData.sortType].data),
    ...tabData.filteredCarriers,
    ...tabData.previouslyFilteredCarriers,
  ]);
};

export const showClearButton = tabData => {
  return !R.equals(getClearableValues(tabData), getClearableValues(initialSearchValues));
};

export const getFilteredListOfSearchFlights = tabData => {
  if (!tabData?.response?.[tabData.tripType]?.[tabData.sortType]?.data) {
    return [];
  }
  const { filterResponseByCarriers, filterResponseByPrices } = flightCardAdapters[tabData.tripType];
  const filteredByCarriers = filterResponseByCarriers(
    tabData.response[tabData.tripType][tabData.sortType],
    tabData.filteredCarriers,
  );
  const filteredByPrices = filterResponseByPrices(
    filteredByCarriers,
    tabData.pricesFilterValues[tabData.sortType],
  );

  return filteredByPrices;
};

export const getMulticityTrips = tabData => {
  const multicityTrips = tabData.searchbar[MULTICITY].trips;
  return multicityTrips.map((trip, i) => {
    if (i === 0) {
      return trip;
    }

    // avoiding eachDayOfInterval error (first date cannot be after the second date)
    const dateInPast = addDays(new Date(), -2);
    const dayBeforeLastDepartureDate = addDays(
      new Date(R.last(multicityTrips[i - 1].departureSelectedDates)),
      -1,
    );
    return {
      ...trip,
      disabledDates: eachDayOfInterval({ start: dateInPast, end: dayBeforeLastDepartureDate }),
      firstMonth: R.last(multicityTrips[i - 1].departureSelectedDates),
    };
  });
};

export const getIsMulticityAddNewButtonDisabled = multicityTrips => {
  return multicityTrips.some(
    trip =>
      trip.locationsFrom.length === 0 ||
      trip.locationsTo.length === 0 ||
      trip.departureSelectedDates.length === 0,
  );
};

export const getIsMulticitySearchDisabled = (multicityTrips, isButtonDisabled) => {
  return isButtonDisabled || multicityTrips.length === 1;
};

export const getIataCodes = fromToLocations => {
  return fromToLocations
    .filter(elem => elem.type === locationTypes.AIRPORT)
    .map(elem => elem.value);
};

export const getIsGroundTransportSelected = selectedVehicleTypes => {
  return selectedVehicleTypes
    .map(({ value }) => GROUND_TRANSPORT.includes(value))
    .some(e => e === true);
};

export const getSelectedVehicleTypes = tabData => {
  return vehicleTypes.filter(vehicleType => tabData.vehicleTypes.includes(vehicleType.value));
};

const BOOKING_TOOL_TABS_COOKIE = 'booking_tool_tabs';
export const getBookingToolCookie = () => {
  const cookie = getCookie(BOOKING_TOOL_TABS_COOKIE);
  if (cookie) {
    return JSON.parse(cookie);
  }
  return null;
};

export const setBookingToolCookie = (param, value, selectedTab) => {
  const cookie = getBookingToolCookie();
  let newCookieValue = {};
  if (cookie?.[selectedTab]) {
    newCookieValue[selectedTab] = {
      ...cookie[selectedTab],
      [param]: value,
    };
  } else {
    newCookieValue[selectedTab] = {
      [param]: value,
    };
  }

  setCookie(BOOKING_TOOL_TABS_COOKIE, JSON.stringify(newCookieValue), { sameSite: 'strict' });
};
