const v1_get_from = user => {
  return {
    companyName: user.company_name,
    dateOfBirth: user.date_of_birth,
    email: user.email,
    firstName: user.first_name,
    jiraOrganizationId: user.jira_organization_id,
    lastName: user.last_name,
    nationality: user.nationality,
    role: user.role,
    status: user.status,
    username: user.username,
    uuid: user.uuid,
  };
};

const v1_post_to = (user, isAdditionalInfoNeeded) => ({
  first_name: user.firstName,
  last_name: user.lastName,
  date_of_birth: isAdditionalInfoNeeded ? user.dateOfBirth : undefined,
  nationality: isAdditionalInfoNeeded ? user.nationality.toLowerCase() : undefined,
  email: user.email,
  role: user.role,
});

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      from: v1_get_from,
      to: v1_post_to,
    },
  },
};
