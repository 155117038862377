import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Text from '@kiwicom/orbit-components/lib/Text';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import { ListChoice, Popover, Alert } from '@kiwicom/orbit-components';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import RefundIcon from '@kiwicom/orbit-components/lib/icons/Refund';

import { usePolyglot } from 'components/services/i18n';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';
import useBookingDisruptions from 'components/services/mmb/useBookingDisruptions';
import { useToggle } from 'utils/hooks';
import useBookingCheckboxes from 'components/services/mmb/useBookingCheckboxes';
import ThreeStatesCheckbox from 'common/ThreeStatesCheckbox';
import { MMBState } from 'components/services/mmb';
import { useDependencyEffect } from 'hooks';

import MultiRefundsModal from './MultiRefundsModal';
import SingleRefundModal from './SingleRefundModal';
import { Trip, Bid, ContextualMenuButton, StatusBadge, ErrorMessage } from './components';
import {
  TableActionsWrapper,
  BadgeWrapper,
  CloseIconWrapper,
  WrappedTableRow,
  LoadMoreWrapper,
} from './index.styled';
import useMMB from 'components/services/mmb/useMMB';

const BookingsTable = ({
  bookings,
  filters,
  resetFilters,
  loadMore,
  loading,
  loadingMore,
  nextPage,
  selectedDates,
}) => {
  const polyglot = usePolyglot();
  const history = useHistory();
  const { addBookingIframe } = useBookingDetailsIframes();
  const { data: disruptions } = useBookingDisruptions(bookings);
  const { checkboxIds, toggleBid, toggleAllBids } = useBookingCheckboxes();

  const [currentBid, setCurrentBid] = useState();
  const {
    state: { refunds },
  } = useContext(MMBState);

  const singleRefundModal = useToggle();
  const singleRefundAlertSuccess = useToggle();
  const singleRefundAlertFail = useToggle();

  const multiRefundsModal = useToggle();
  const multiRefundsAlertSuccess = useToggle();
  const multiRefundsAlertFail = useToggle();

  const { loadMMBBookings } = useMMB();

  const handleBookingDetails = bid => {
    addBookingIframe(bid);
    history.push(`/manage-my-bookings/${bid}`);
  };

  const refundsStateKeys = Object.keys(refunds);
  const hasRefundsStateLength = refundsStateKeys && refundsStateKeys.length;
  const areAllRefundsLoaded =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => refunds[refundStateKey].requestingRefund === false);
  const areAllRefundsPassedSuccessfully =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => !refunds[refundStateKey].requestRefundError);

  useDependencyEffect(() => {
    if (areAllRefundsLoaded) {
      toggleAllBids(0);
      loadMMBBookings(selectedDates);
    }
  }, [areAllRefundsLoaded]);

  useDependencyEffect(() => {
    if (
      areAllRefundsLoaded &&
      !multiRefundsAlertFail.isOn &&
      areAllRefundsPassedSuccessfully &&
      refundsStateKeys &&
      refundsStateKeys.length > 1
    ) {
      multiRefundsAlertSuccess.setOn();
    }
  }, [multiRefundsAlertFail.isOn, areAllRefundsPassedSuccessfully, areAllRefundsLoaded]);

  useDependencyEffect(() => {
    if (
      areAllRefundsLoaded &&
      !singleRefundAlertSuccess.isOn &&
      areAllRefundsPassedSuccessfully &&
      refundsStateKeys &&
      refundsStateKeys.length === 1
    ) {
      singleRefundAlertSuccess.setOn();
    }
  }, [singleRefundAlertSuccess.isOn, areAllRefundsPassedSuccessfully, areAllRefundsLoaded]);

  return (
    <>
      <Card loading={loading}>
        {!bookings?.length ? (
          <ErrorMessage filters={filters} resetFilters={resetFilters} />
        ) : (
          <CardSection>
            <TableActionsWrapper>
              <Text type="secondary">{polyglot.t('mmb.bulk_actions')}</Text>
              {!!checkboxIds.length && (
                <BadgeWrapper>
                  <Badge>
                    {polyglot.t('mmb.selected_items', {
                      items_length: checkboxIds.length,
                    })}
                    <CloseIconWrapper onClick={() => toggleAllBids(0)}>
                      <CloseIcon />
                    </CloseIconWrapper>
                  </Badge>
                </BadgeWrapper>
              )}
              <Button
                iconLeft={<RefundIcon />}
                onClick={() => multiRefundsModal.setOn()}
                disabled={!checkboxIds.length}
                size="small"
              >
                {polyglot.t('mmb.refund')}
              </Button>
            </TableActionsWrapper>
            {singleRefundAlertSuccess.isOn && (
              <Alert
                type="success"
                closable
                onClose={singleRefundAlertSuccess.setOff}
                spaceAfter="normal"
                title={polyglot.t('mmb.single.refund.success.title')}
                icon
              >
                {polyglot.t('mmb.single.refund.success.subtitle')}
              </Alert>
            )}
            {multiRefundsAlertSuccess.isOn && (
              <Alert
                type="success"
                closable
                onClose={multiRefundsAlertSuccess.setOff}
                spaceAfter="normal"
                title={polyglot.t('mmb.multi.refunds.success.title')}
                icon
              >
                {polyglot.t('mmb.multi.refunds.success.subtitle')}
              </Alert>
            )}
            <Table striped={false}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <ThreeStatesCheckbox
                      stateValues={checkboxIds}
                      values={bookings.filter(booking => booking.status === 'confirmed')}
                      onChange={checkboxState =>
                        toggleAllBids(
                          checkboxState,
                          bookings
                            .filter(booking => booking.status === 'confirmed')
                            .map(booking => booking.bid),
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>{polyglot.t('mmb.bid')}</TableCell>
                  <TableCell>{polyglot.t('mmb.trip')}</TableCell>
                  <TableCell>{polyglot.t('mmb.departure_date')}</TableCell>
                  <TableCell>{polyglot.t('mmb.booking_date')}</TableCell>
                  <TableCell>{polyglot.t('mmb.passengers')}</TableCell>
                  <TableCell>{polyglot.t('mmb.booking_status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings?.map((booking, i) => {
                  return (
                    <WrappedTableRow
                      key={booking.bid}
                      dataTest={`table-row-${i}`}
                      disrupted={disruptions?.[booking.bid]?.disruptions}
                    >
                      <TableCell>
                        <Checkbox
                          disabled={booking.status !== 'confirmed'}
                          name={`checkbox-${booking.bid}`}
                          onChange={() => toggleBid(booking.bid)}
                          checked={checkboxIds.includes(booking.bid)}
                        />
                      </TableCell>
                      <TableCell>
                        <Bid
                          onClick={() => handleBookingDetails(booking.bid)}
                          bid={booking.bid}
                          dataTest={`bid-row-${i}`}
                        />
                      </TableCell>
                      <TableCell dataTest={`trip-row-${i}`}>
                        <Trip
                          arrival={booking.destination}
                          departure={booking.departure}
                          fullTrip={!booking.roundTrip && booking?.fullTrip}
                          roundTrip={booking.roundTrip}
                        />
                      </TableCell>
                      <TableCell dataTest={`departure-date-row-${i}`}>
                        {booking.departureDate}
                      </TableCell>
                      <TableCell dataTest={`booking-date-row-${i}`}>
                        {booking.bookingDate}
                      </TableCell>
                      <TableCell dataTest={`passengers-row-${i}`}>{booking.passengers}</TableCell>
                      <TableCell>
                        <StatusBadge status={booking.status} dataTest={`status-row-${i}`} />
                      </TableCell>
                      <TableCell>
                        {booking.status === 'confirmed' && (
                          <Popover
                            placement="left"
                            noPadding
                            content={
                              <ListChoice
                                icon={<RefundIcon />}
                                onClick={() => singleRefundModal.setOn()}
                                title={polyglot.t('mmb.refund')}
                              />
                            }
                          >
                            <ContextualMenuButton onClick={() => setCurrentBid(booking.bid)} />
                          </Popover>
                        )}
                      </TableCell>
                    </WrappedTableRow>
                  );
                })}
              </TableBody>
            </Table>
            <LoadMoreWrapper>
              <Text type="secondary">{`${bookings?.length} Booking(s)`}</Text>
              {nextPage && (
                <Button
                  loading={loadingMore}
                  onClick={loadMore}
                  type="primary"
                  dataTest="load-more-btn"
                >
                  {polyglot.t('common.load_more')}
                </Button>
              )}
            </LoadMoreWrapper>
          </CardSection>
        )}
      </Card>
      {multiRefundsModal.isOn && (
        <MultiRefundsModal
          onClose={() => multiRefundsModal.setOff()}
          multiRefundsAlertFail={multiRefundsAlertFail}
          onCloseSingleRefundAlertSuccess={singleRefundAlertSuccess.setOff}
          onCloseMultiRefundsAlertSuccess={multiRefundsAlertSuccess.setOff}
          bookings={bookings}
          disruptions={disruptions}
        />
      )}
      {singleRefundModal.isOn && (
        <SingleRefundModal
          onClose={() => singleRefundModal.setOff()}
          bid={currentBid}
          singleRefundAlertFail={singleRefundAlertFail}
          onCloseSingleRefundAlertSuccess={singleRefundAlertSuccess.setOff}
          onCloseMultiRefundsAlertSuccess={multiRefundsAlertSuccess.setOff}
        />
      )}
    </>
  );
};

export default BookingsTable;
