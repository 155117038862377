import { v1 as uuid } from 'uuid';
import * as R from 'ramda';
import { serviceProvidedValues, tierTypesValues } from 'consts/smartPass';

import {
  keys,
  displayingConditionValues,
  fastTrackConditionValues,
  terminalsValues,
  fastTrackVoucherFullfilmentValues,
  dynamicStaticValues,
  yesNoValues,
  designTypeValues,
  transferAssistanceServiceTypeValues,
  transferAssistanceAirportStaffLookValues,
  voucherDiscountTypeValues,
  voucherCodeTypeValues,
  voucherTypeValues,
  carriersTypeValues,
  customDesignSizeValues,
} from 'consts/smartPass';

const {
  LEGAL_NAME,
  LEGAL_ADDRESS,
  VAT_NUMBER,
  BANK_ACCOUNT_NUMBER,
  BANK_NAME_AND_ADDRESS,
  CONTACT_PERSON_1_NAME,
  CONTACT_PERSON_1_ROLE,
  CONTACT_PERSON_1_EMAIL,
  CONTACT_PERSON_1_PHONE,
  CONTACT_PERSON_2_NAME,
  CONTACT_PERSON_2_ROLE,
  CONTACT_PERSON_2_EMAIL,
  CONTACT_PERSON_2_PHONE,
  IATA_CODE,
  AIRPORT_LOGO,
  SMART_PASS_NAME,
  SMART_PASS_LOGO,
  SMART_PASS_PAGE,
  ILLUSTRATION,
  ILLUSTRATION_COLOR_1,
  ILLUSTRATION_COLOR_2,
  DISPLAYING_CONDITION,
  DISPLAYING_CONDITION_HOURS,
  REPORTING_EMAIL_1,
  REPORTING_EMAIL_2,
  REPORTING_EMAIL_3,
  REPORTING_EMAIL_4,
  AIRPORT_SIGNAGE_1,
  AIRPORT_SIGNAGE_2,
  AIRPORT_SIGNAGE_3,
  TERMINAL_MAPS_1,
  TERMINAL_MAPS_2,
  TERMINAL_MAPS_3,
  FAST_TRACK_ACTIVATED,
  FAST_TRACK_CONDITION,
  FAST_TRACK_LAYOVER_HOURS,
  FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS,
  FAST_TRACK_ITINERARIES_TYPES,
  FAST_TRACK_TERMINALS,
  FAST_TRACK_TERMINALS_LIST,
  FAST_TRACK_TERMINALS_LIST_NEW_VALUE,
  FAST_TRACK_VOUCHER_FULFILMENT,
  FAST_TRACK_VOUCHER_CODE_TYPE,
  FAST_TRACK_VOUCHER_CODE_GENERATION,
  FAST_TRACK_STATIC_KEYWORD,
  FAST_TRACK_STATIC_URL,
  FAST_TRACK_REPORT_EMAILS_ACTIVATED,
  FAST_TRACK_REPORT_EMAILS_LIST,
  FAST_TRACK_REPORT_EMAILS_NEW_VALUE,
  FAST_TRACK_DESIGN_TYPE,
  FAST_TRACK_CUSTOM_VOUCHER_DESIGN,
  TRANSFER_PROCESS,
  TRANSFER_ASSISTANCE_ACTIVATED,
  TRANSFER_ASSISTANCE_SERVICE_TYPE,
  TRANSFER_ASSISTANCE_DESK_TYPE,
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK,
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR,
  TRANSFER_ASSISTANCE_LOCATION,
  BAG_TRANSFER_ASSISTANCE_ACTIVATED,
  BAG_TRANSFER_ASSIST_LOCATION,
  BAG_TRANSFER_ASSIST_TERMINALS,
  BAG_TRANSFER_ASSIST_TERMINALS_LIST,
  BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE,
  BAG_TRANSFER_ASSIST_SERVICE_PROVIDED,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE,
  VOUCHERS_ACTIVATED,
  VOUCHERS,
  VOUCHERS_REPORTING_ACTIVATED,
  VOUCHERS_REPORTING_EMAILS_LIST,
  VOUCHERS_REPORTING_EMAILS_NEW_VALUE,
  VOUCHER_NAME,
  VOUCHER_TYPE,
  VOUCHER_DESIGN_TYPE,
  VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION,
  VOUCHER_BAG_DEPOSIT_TERMINALS,
  VOUCHER_BAG_DEPOSIT_TERMINALS_LIST,
  VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE,
  VOUCHER_LOUNGE_LOCATION,
  VOUCHER_LOUNGE_TERMINALS,
  VOUCHER_LOUNGE_TERMINALS_LIST,
  VOUCHER_LOUNGE_TERMINALS_NEW_VALUE,
  VOUCHER_LARGE,
  VOUCHER_SMALL,
  VOUCHER_CODE_PLACED,
  VOUCHER_DISCOUNT_TYPE,
  VOUCHER_DISCOUNT_PERCENTAGE,
  VOUCHER_DISCOUNT_AMOUNT,
  VOUCHER_DISCOUNT_CURRENCY,
  VOUCHER_PLACE_NAME_1,
  VOUCHER_PLACE_NAME_2,
  VOUCHER_PLACE_NAME_3,
  VOUCHER_PLACE_NAME_4,
  VOUCHER_URL,
  VOUCHER_CODE_TYPE,
  VOUCHER_CODE_GENERATION,
  VOUCHER_STATIC_KEYWORD,
  VOUCHER_EAN_CODE,
  VOUCHER_CUSTOM_DESIGN_SIZE,
  SFAQ_ARTICLE_ID,
} = keys;

const parseColor = color => (color.startsWith('#') ? color : `#${color}`);

const formikToApiVoucher = voucher => {
  const getSpecificTerminals = () => {
    if (voucher[VOUCHER_TYPE] === voucherTypeValues.BAG_DEPOSIT) {
      return voucher[VOUCHER_BAG_DEPOSIT_TERMINALS] === terminalsValues.ALL_TERMINALS
        ? undefined
        : voucher[VOUCHER_BAG_DEPOSIT_TERMINALS_LIST];
    } else if (voucher[VOUCHER_TYPE] === voucherTypeValues.LOUNGE_ACCESS) {
      return voucher[VOUCHER_LOUNGE_TERMINALS] === terminalsValues.ALL_TERMINALS
        ? undefined
        : voucher[VOUCHER_LOUNGE_TERMINALS_LIST];
    }

    return undefined;
  };

  const placeNames = [
    voucher[VOUCHER_PLACE_NAME_1],
    voucher[VOUCHER_PLACE_NAME_2],
    voucher[VOUCHER_PLACE_NAME_3],
    voucher[VOUCHER_PLACE_NAME_4],
  ].filter(item => item);

  return {
    name: voucher[VOUCHER_NAME],
    type: voucher[VOUCHER_TYPE],
    design_type: voucher[VOUCHER_DESIGN_TYPE],
    discount: {
      type:
        voucher[VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD
          ? voucher[VOUCHER_DISCOUNT_TYPE] || undefined
          : undefined,
      percentage:
        voucher[VOUCHER_DISCOUNT_TYPE] === voucherDiscountTypeValues.PERCENTAGE
          ? Number(voucher[VOUCHER_DISCOUNT_PERCENTAGE]) || undefined
          : undefined,
      amount:
        voucher[VOUCHER_DISCOUNT_TYPE] === voucherDiscountTypeValues.AMOUNT
          ? Number(voucher[VOUCHER_DISCOUNT_AMOUNT]) || undefined
          : undefined,
      currency:
        voucher[VOUCHER_DISCOUNT_TYPE] === voucherDiscountTypeValues.AMOUNT
          ? voucher[VOUCHER_DISCOUNT_CURRENCY]
          : undefined,
    },
    place_names:
      voucher[VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD ? placeNames : undefined,
    url:
      voucher[VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD ? voucher[VOUCHER_URL] : undefined,
    code: {
      is_code_placed: voucher[VOUCHER_CODE_PLACED] === yesNoValues.YES,
      type:
        voucher[VOUCHER_CODE_PLACED] === yesNoValues.YES ? voucher[VOUCHER_CODE_TYPE] : undefined,
      data_type: [voucherCodeTypeValues.QR_CODE, voucherCodeTypeValues.PDF417].includes(
        voucher[VOUCHER_CODE_TYPE],
      )
        ? voucher[VOUCHER_CODE_GENERATION]
        : undefined,
      static_keyword:
        voucher[VOUCHER_CODE_GENERATION] === dynamicStaticValues.STATIC
          ? voucher[VOUCHER_STATIC_KEYWORD]
          : voucher[VOUCHER_EAN_CODE],
    },
    lounge_location:
      voucher[VOUCHER_TYPE] === voucherTypeValues.LOUNGE_ACCESS
        ? voucher[VOUCHER_LOUNGE_LOCATION]
        : undefined,

    bag_deposit_service_location:
      voucher[VOUCHER_TYPE] === voucherTypeValues.BAG_DEPOSIT
        ? voucher[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]
        : undefined,

    specific_terminals: getSpecificTerminals(),
    custom_voucher:
      voucher[VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM
        ? {
            image:
              voucher[VOUCHER_CUSTOM_DESIGN_SIZE] === customDesignSizeValues.LARGE
                ? voucher[VOUCHER_LARGE]
                : voucher[VOUCHER_SMALL],
            size: voucher[VOUCHER_CUSTOM_DESIGN_SIZE],
          }
        : undefined,
  };
};

export const formikToApi = (values, saveType, companyName, id) => {
  const getLayoverHours = () => {
    if (values[FAST_TRACK_CONDITION] === fastTrackConditionValues.LAYOVER) {
      return Number(values[FAST_TRACK_LAYOVER_HOURS]);
    } else if (
      values[FAST_TRACK_CONDITION] === fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED
    ) {
      return Number(values[FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS]);
    }
    return undefined;
  };

  const getVoucherRedemption = () => {
    if (values[FAST_TRACK_VOUCHER_FULFILMENT] === fastTrackVoucherFullfilmentValues.AUTOMATED) {
      return {
        is_code_placed: true,
        type: values[FAST_TRACK_VOUCHER_CODE_TYPE],
        data_type: values[FAST_TRACK_VOUCHER_CODE_GENERATION],
        static_keyword:
          values[FAST_TRACK_VOUCHER_CODE_GENERATION] === dynamicStaticValues.STATIC
            ? values[FAST_TRACK_STATIC_KEYWORD]
            : undefined,
      };
    }
    if (values[FAST_TRACK_VOUCHER_FULFILMENT] === fastTrackVoucherFullfilmentValues.WEB) {
      return {
        is_code_placed: true,
        type: fastTrackVoucherFullfilmentValues.WEB,
        data_type: dynamicStaticValues.STATIC,
        static_keyword: values[FAST_TRACK_STATIC_KEYWORD],
        url: values[FAST_TRACK_STATIC_URL],
      };
    }
    return {
      is_code_placed: false,
    };
  };

  const fastTrack = values[FAST_TRACK_ACTIVATED]
    ? {
        is_activated: true,
        condition: values[FAST_TRACK_CONDITION] || undefined,
        layover_hours: getLayoverHours(),
        itineraries_types: values[FAST_TRACK_ITINERARIES_TYPES],
        specific_terminals:
          values[FAST_TRACK_TERMINALS] === terminalsValues.ALL_TERMINALS
            ? undefined
            : values[FAST_TRACK_TERMINALS_LIST],
        reporting_emails:
          values[FAST_TRACK_REPORT_EMAILS_ACTIVATED] === yesNoValues.YES
            ? values[FAST_TRACK_REPORT_EMAILS_LIST]
            : undefined,
        design_type: values[FAST_TRACK_DESIGN_TYPE],
        custom_voucher_image:
          values[FAST_TRACK_DESIGN_TYPE] === designTypeValues.CUSTOM
            ? values[FAST_TRACK_CUSTOM_VOUCHER_DESIGN]
            : undefined,
        transfer_process: values[TRANSFER_PROCESS] || undefined,
        code: getVoucherRedemption(),
      }
    : {
        is_activated: false,
      };

  const transferAssistance = values[TRANSFER_ASSISTANCE_ACTIVATED]
    ? {
        is_activated: true,
        service_type: values[TRANSFER_ASSISTANCE_SERVICE_TYPE],
        desk_type:
          values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
          transferAssistanceServiceTypeValues.AIRPORT_DESK
            ? values[TRANSFER_ASSISTANCE_DESK_TYPE]
            : undefined,
        airport_staff_look:
          values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
          transferAssistanceServiceTypeValues.AIRPORT_STAFF
            ? values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]
            : undefined,
        airport_staff_uniform_color:
          values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] ===
          transferAssistanceAirportStaffLookValues.DEDICATED_UNIFORM
            ? values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR]
            : undefined,
        location: values[TRANSFER_ASSISTANCE_LOCATION],
      }
    : {
        is_activated: false,
      };

  const calculateServiceProvider = (option, carriersType, list) =>
    values[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED].includes(option)
      ? {
          option,
          specific_carriers: carriersType === carriersTypeValues.ALL_CARRIERS ? undefined : list,
        }
      : undefined;

  const bagTransferAssistance = values[BAG_TRANSFER_ASSISTANCE_ACTIVATED]
    ? {
        is_activated: true,
        location: values[BAG_TRANSFER_ASSIST_LOCATION] || undefined,
        specific_terminals:
          values[BAG_TRANSFER_ASSIST_TERMINALS] === terminalsValues.ALL_TERMINALS
            ? undefined
            : values[BAG_TRANSFER_ASSIST_TERMINALS_LIST],
        service_providers: [
          calculateServiceProvider(
            serviceProvidedValues.SELF_BAG_DROP_MACHINES,
            values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE],
            values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST],
          ),
          calculateServiceProvider(
            serviceProvidedValues.COUNTER,
            values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE],
            values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST],
          ),
        ].filter(item => item),
      }
    : {
        is_activated: false,
      };

  const ReportingEmails = [
    values[REPORTING_EMAIL_1],
    values[REPORTING_EMAIL_2],
    values[REPORTING_EMAIL_3],
    values[REPORTING_EMAIL_4],
  ].filter(email => email);

  const AirportSignage = [
    values[AIRPORT_SIGNAGE_1],
    values[AIRPORT_SIGNAGE_2],
    values[AIRPORT_SIGNAGE_3],
  ].filter(item => item);

  const TerminalMaps = [
    values[TERMINAL_MAPS_1],
    values[TERMINAL_MAPS_2],
    values[TERMINAL_MAPS_3],
  ].filter(item => item);

  return {
    id,
    status: saveType,
    tier: tierTypesValues.STANDARD,
    sfaq_article_id: values[SFAQ_ARTICLE_ID],
    company: {
      name: companyName,
      legal_name: values[LEGAL_NAME] || undefined,
      legal_address: values[LEGAL_ADDRESS] || undefined,
      VAT_number: values[VAT_NUMBER] || undefined,
      bank_account_number: values[BANK_ACCOUNT_NUMBER] || undefined,
      bank_name_and_address: values[BANK_NAME_AND_ADDRESS] || undefined,
    },
    contact_persons: [
      {
        name: values[CONTACT_PERSON_1_NAME] || undefined,
        role: values[CONTACT_PERSON_1_ROLE] || undefined,
        email: values[CONTACT_PERSON_1_EMAIL] || undefined,
        phone: values[CONTACT_PERSON_1_PHONE] || undefined,
      },
      {
        name: values[CONTACT_PERSON_2_NAME] || undefined,
        role: values[CONTACT_PERSON_2_ROLE] || undefined,
        email: values[CONTACT_PERSON_2_EMAIL] || undefined,
        phone: values[CONTACT_PERSON_2_PHONE] || undefined,
      },
    ],
    IATA_code: values[IATA_CODE] || undefined,
    airport_logo: values[AIRPORT_LOGO] || undefined,
    name: values[SMART_PASS_NAME] || undefined,
    logo: values[SMART_PASS_LOGO] || undefined,
    page: values[SMART_PASS_PAGE] || undefined,
    illustration: Number(values[ILLUSTRATION]) || undefined,
    illustration_color_1: values[ILLUSTRATION_COLOR_1]
      ? parseColor(values[ILLUSTRATION_COLOR_1])
      : '#FFFFFF',
    illustration_color_2: values[ILLUSTRATION_COLOR_2]
      ? parseColor(values[ILLUSTRATION_COLOR_2])
      : '#000000',
    applicability_hours_till_departure:
      values[DISPLAYING_CONDITION] === displayingConditionValues.CONDITION
        ? Number(values[DISPLAYING_CONDITION_HOURS])
        : undefined,
    reporting_emails: ReportingEmails.length > 0 ? ReportingEmails : undefined,
    airport_signage: AirportSignage.length > 0 ? AirportSignage : undefined,
    terminal_maps: TerminalMaps.length > 0 ? TerminalMaps : undefined,
    fast_track: fastTrack,
    transfer_assistance: transferAssistance,
    baggage_transfer_assistance: bagTransferAssistance,
    vouchers: {
      is_activated: values[VOUCHERS_ACTIVATED],
      list: values[VOUCHERS_ACTIVATED] ? values[VOUCHERS].map(formikToApiVoucher) : undefined,
      reporting_emails:
        values[VOUCHERS_REPORTING_ACTIVATED] === yesNoValues.YES
          ? values[VOUCHERS_REPORTING_EMAILS_LIST]
          : undefined,
    },
  };
};

const apiToFormikVoucher = voucher => ({
  id: uuid(),
  [VOUCHER_NAME]: voucher.name,
  [VOUCHER_TYPE]: voucher.type,
  [VOUCHER_DESIGN_TYPE]: voucher.design_type,
  [VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]: voucher.bag_deposit_service_location,
  [VOUCHER_BAG_DEPOSIT_TERMINALS]:
    voucher.type === voucherTypeValues.BAG_DEPOSIT
      ? voucher.specific_terminals
        ? terminalsValues.SPECIFIC_TERMINALS
        : terminalsValues.ALL_TERMINALS
      : null,
  [VOUCHER_BAG_DEPOSIT_TERMINALS_LIST]:
    voucher.type === voucherTypeValues.BAG_DEPOSIT && voucher.specific_terminals
      ? voucher.specific_terminals
      : [],
  [VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE]: '',
  [VOUCHER_LOUNGE_LOCATION]: voucher.lounge_location || [],
  [VOUCHER_LOUNGE_TERMINALS]:
    voucher.type === voucherTypeValues.LOUNGE_ACCESS
      ? voucher.specific_terminals
        ? terminalsValues.SPECIFIC_TERMINALS
        : terminalsValues.ALL_TERMINALS
      : null,
  [VOUCHER_LOUNGE_TERMINALS_LIST]:
    voucher.type === voucherTypeValues.LOUNGE_ACCESS && voucher.specific_terminals
      ? voucher.specific_terminals
      : [],
  [VOUCHER_LOUNGE_TERMINALS_NEW_VALUE]: '',
  [VOUCHER_CUSTOM_DESIGN_SIZE]: voucher.custom_voucher?.size,
  [VOUCHER_LARGE]:
    voucher.custom_voucher?.size === customDesignSizeValues.LARGE
      ? voucher.custom_voucher?.image
      : undefined,
  [VOUCHER_SMALL]:
    voucher.custom_voucher?.size === customDesignSizeValues.SMALL
      ? voucher.custom_voucher?.image
      : undefined,
  [VOUCHER_CODE_PLACED]: voucher.code.is_code_placed ? yesNoValues.YES : yesNoValues.NO,
  [VOUCHER_DISCOUNT_TYPE]: voucher.discount?.type,
  [VOUCHER_DISCOUNT_PERCENTAGE]: voucher.discount?.percentage,
  [VOUCHER_DISCOUNT_AMOUNT]: voucher.discount?.amount,
  [VOUCHER_DISCOUNT_CURRENCY]: voucher.discount?.currency,
  [VOUCHER_PLACE_NAME_1]: voucher.place_names && voucher.place_names[0],
  [VOUCHER_PLACE_NAME_2]: voucher.place_names && voucher.place_names[1],
  [VOUCHER_PLACE_NAME_3]: voucher.place_names && voucher.place_names[2],
  [VOUCHER_PLACE_NAME_4]: voucher.place_names && voucher.place_names[3],
  [VOUCHER_URL]: voucher.url,
  [VOUCHER_CODE_TYPE]: voucher.code.type,
  [VOUCHER_CODE_GENERATION]: voucher.code.data_type,
  [VOUCHER_STATIC_KEYWORD]:
    voucher.code.type !== 'EAN-13' ? voucher.code.static_keyword : undefined,
  [VOUCHER_EAN_CODE]: voucher.code.type === 'EAN-13' ? voucher.code.static_keyword : undefined,
});

const getBagTransferAssistServiceProvider = (smartPass, option) =>
  smartPass.baggage_transfer_assistance.service_providers?.find(
    serviceProvider => serviceProvider.option === option,
  );

const getFastTrackVoucherFullfilment = smartPass => {
  if (!smartPass.fast_track.code?.is_code_placed) {
    return fastTrackVoucherFullfilmentValues.MANUAL;
  }
  if (
    smartPass.fast_track.code?.is_code_placed &&
    smartPass.fast_track.code?.type === fastTrackVoucherFullfilmentValues.WEB
  ) {
    return fastTrackVoucherFullfilmentValues.WEB;
  }
  return fastTrackVoucherFullfilmentValues.AUTOMATED;
};

export const apiToFormik = smartPass => {
  const selfBagDropMachineServiceProvider = getBagTransferAssistServiceProvider(
    smartPass,
    serviceProvidedValues.SELF_BAG_DROP_MACHINES,
  );
  const counterServiceProvider = getBagTransferAssistServiceProvider(
    smartPass,
    serviceProvidedValues.COUNTER,
  );

  return {
    [LEGAL_NAME]: smartPass.company.legal_name,
    [LEGAL_ADDRESS]: smartPass.company.legal_address,
    [VAT_NUMBER]: smartPass.company.VAT_number,
    [BANK_ACCOUNT_NUMBER]: smartPass.company.bank_account_number,
    [BANK_NAME_AND_ADDRESS]: smartPass.company.bank_name_and_address,
    [CONTACT_PERSON_1_NAME]: smartPass.contact_persons ? smartPass.contact_persons[0].name : '',
    [CONTACT_PERSON_1_NAME]: R.path(['contact_persons', 0, 'name'], smartPass) || '',
    [CONTACT_PERSON_1_ROLE]: R.path(['contact_persons', 0, 'role'], smartPass) || '',
    [CONTACT_PERSON_1_EMAIL]: R.path(['contact_persons', 0, 'email'], smartPass) || '',
    [CONTACT_PERSON_1_PHONE]: R.path(['contact_persons', 0, 'phone'], smartPass) || '',
    [CONTACT_PERSON_2_NAME]: R.path(['contact_persons', 1, 'name'], smartPass) || '',
    [CONTACT_PERSON_2_ROLE]: R.path(['contact_persons', 1, 'role'], smartPass) || '',
    [CONTACT_PERSON_2_EMAIL]: R.path(['contact_persons', 1, 'email'], smartPass) || '',
    [CONTACT_PERSON_2_PHONE]: R.path(['contact_persons', 1, 'phone'], smartPass) || '',
    [IATA_CODE]: smartPass.IATA_code || '',
    [AIRPORT_LOGO]: smartPass.airport_logo,
    [SMART_PASS_NAME]: smartPass.name || '',
    [SMART_PASS_LOGO]: smartPass.logo,
    [SMART_PASS_PAGE]: smartPass.page,
    [ILLUSTRATION]: String(smartPass.illustration),
    [ILLUSTRATION_COLOR_1]: smartPass.illustration_color_1,
    [ILLUSTRATION_COLOR_2]: smartPass.illustration_color_2,
    [DISPLAYING_CONDITION]:
      smartPass.applicability_hours_till_departure !== undefined
        ? displayingConditionValues.CONDITION
        : displayingConditionValues.NO_CONDITION,
    [DISPLAYING_CONDITION_HOURS]: smartPass.applicability_hours_till_departure,
    [REPORTING_EMAIL_1]: R.path(['reporting_emails', 0], smartPass) || '',
    [REPORTING_EMAIL_2]: R.path(['reporting_emails', 1], smartPass) || '',
    [REPORTING_EMAIL_3]: R.path(['reporting_emails', 2], smartPass) || '',
    [REPORTING_EMAIL_4]: R.path(['reporting_emails', 3], smartPass) || '',
    [AIRPORT_SIGNAGE_1]: R.path(['airport_signage', 0], smartPass) || '',
    [AIRPORT_SIGNAGE_2]: R.path(['airport_signage', 1], smartPass) || '',
    [AIRPORT_SIGNAGE_3]: R.path(['airport_signage', 2], smartPass) || '',
    [TERMINAL_MAPS_1]: R.path(['terminal_maps', 0], smartPass) || '',
    [TERMINAL_MAPS_2]: R.path(['terminal_maps', 1], smartPass) || '',
    [TERMINAL_MAPS_3]: R.path(['terminal_maps', 2], smartPass) || '',
    [FAST_TRACK_ACTIVATED]: smartPass.fast_track.is_activated,
    [FAST_TRACK_CONDITION]: smartPass.fast_track.condition,
    [FAST_TRACK_LAYOVER_HOURS]:
      smartPass.fast_track.condition === fastTrackConditionValues.LAYOVER
        ? smartPass.fast_track.layover_hours
        : '',
    [FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS]:
      smartPass.fast_track.condition === fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED
        ? smartPass.fast_track.layover_hours
        : '',
    [FAST_TRACK_ITINERARIES_TYPES]: smartPass.fast_track.itineraries_types || [],
    [FAST_TRACK_TERMINALS]: smartPass.fast_track.specific_terminals
      ? terminalsValues.SPECIFIC_TERMINALS
      : terminalsValues.ALL_TERMINALS,
    [FAST_TRACK_TERMINALS_LIST]: smartPass.fast_track.specific_terminals || [],
    [FAST_TRACK_TERMINALS_LIST_NEW_VALUE]: '',
    [FAST_TRACK_VOUCHER_FULFILMENT]: getFastTrackVoucherFullfilment(smartPass),
    [FAST_TRACK_VOUCHER_CODE_TYPE]: smartPass.fast_track.code?.type,
    [FAST_TRACK_VOUCHER_CODE_GENERATION]: smartPass.fast_track.code?.data_type,
    [FAST_TRACK_STATIC_KEYWORD]: smartPass.fast_track.code?.static_keyword,
    [FAST_TRACK_STATIC_URL]: smartPass.fast_track.code?.url,
    [SFAQ_ARTICLE_ID]: smartPass.sfaq_article_id,
    [FAST_TRACK_REPORT_EMAILS_ACTIVATED]: smartPass.fast_track.reporting?.is_activated
      ? yesNoValues.YES
      : yesNoValues.NO,
    [FAST_TRACK_REPORT_EMAILS_ACTIVATED]: smartPass.fast_track.reporting_emails
      ? yesNoValues.YES
      : yesNoValues.NO,
    [FAST_TRACK_REPORT_EMAILS_LIST]: smartPass.fast_track.reporting_emails || [],
    [FAST_TRACK_REPORT_EMAILS_NEW_VALUE]: '',
    [FAST_TRACK_DESIGN_TYPE]: smartPass.fast_track.design_type,
    [FAST_TRACK_CUSTOM_VOUCHER_DESIGN]: smartPass.fast_track.custom_voucher_image,
    [TRANSFER_PROCESS]: smartPass.fast_track.transfer_process,
    [TRANSFER_ASSISTANCE_ACTIVATED]: smartPass.transfer_assistance.is_activated,
    [TRANSFER_ASSISTANCE_SERVICE_TYPE]: smartPass.transfer_assistance.service_type,
    [TRANSFER_ASSISTANCE_DESK_TYPE]: smartPass.transfer_assistance.desk_type || [],
    [TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]: smartPass.transfer_assistance.airport_staff_look,
    [TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR]:
      smartPass.transfer_assistance.airport_staff_uniform_color,
    [TRANSFER_ASSISTANCE_LOCATION]: smartPass.transfer_assistance.location || [],
    [BAG_TRANSFER_ASSISTANCE_ACTIVATED]: smartPass.baggage_transfer_assistance.is_activated,
    [BAG_TRANSFER_ASSIST_LOCATION]: smartPass.baggage_transfer_assistance.location || [],
    [BAG_TRANSFER_ASSIST_TERMINALS]: smartPass.baggage_transfer_assistance.specific_terminals
      ? terminalsValues.SPECIFIC_TERMINALS
      : terminalsValues.ALL_TERMINALS,
    [BAG_TRANSFER_ASSIST_TERMINALS_LIST]:
      smartPass.baggage_transfer_assistance.specific_terminals || [],
    [BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE]: '',
    [BAG_TRANSFER_ASSIST_SERVICE_PROVIDED]:
      smartPass.baggage_transfer_assistance.service_providers?.map(
        serviceProvider => serviceProvider.option,
      ) || [],
    [BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE]: selfBagDropMachineServiceProvider
      ? selfBagDropMachineServiceProvider.specific_carriers
        ? carriersTypeValues.SPECIFIC_CARRIERS
        : carriersTypeValues.ALL_CARRIERS
      : null,
    [BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST]:
      selfBagDropMachineServiceProvider?.specific_carriers || [],
    [BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE]: '',
    [BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE]: counterServiceProvider
      ? counterServiceProvider.specific_carriers
        ? carriersTypeValues.SPECIFIC_CARRIERS
        : carriersTypeValues.ALL_CARRIERS
      : null,
    [BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST]:
      counterServiceProvider?.specific_carriers || [],
    [BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE]: '',
    [VOUCHERS_ACTIVATED]: smartPass.vouchers.is_activated,
    [VOUCHERS]: smartPass.vouchers.list ? smartPass.vouchers.list.map(apiToFormikVoucher) : [],
    [VOUCHERS_REPORTING_ACTIVATED]: smartPass.vouchers.reporting_emails
      ? yesNoValues.YES
      : yesNoValues.NO,
    [VOUCHERS_REPORTING_EMAILS_LIST]: smartPass.vouchers.reporting_emails || [],
    [VOUCHERS_REPORTING_EMAILS_NEW_VALUE]: '',
  };
};
