import styled from 'styled-components';

export const TruncateContainer = styled.div`
  transition: all 100ms ease-in;
  overflow-y: hidden;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: -40px;
  margin-left: -24px;
  margin-right: -24px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.75);
  transition: bottom 100ms ease-in;

  ${({ expanded }) => expanded && 'margin-top: 0;'};
`;

export const ExpandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ExpandText = styled.div`
  font-weight: 600;
`;

export const BlurredDiv = styled.div`
  height: 30px;
  filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.85);
`;
