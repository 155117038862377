import styled from 'styled-components';

export const StackDiv = styled.div`
  flex: 1 1 100%;
`;
export const WidthWrapper = styled.div`
  max-width: 370px;
  margin-bottom: 10px;

  ${({ labelWidthWrap }) =>
    labelWidthWrap
      ? `& label:first-child {
    width: 177px;
  }`
      : ''}
`;
export const FileWithPreview = styled.div`
  width: 50%;
`;

export const LabelsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 18px;
  align-items: center;

  & > label {
    width: ${props => props.labelWidth || 'auto'};
    white-space: nowrap;
    margin-right: 34px;
  }
`;

export const LabelWithTextInput = styled.div`
  margin-top: -5px;
  margin-bottom: -8px;

  & > p {
    display: inline;
  }
  & > div {
    display: inline-block;
  }
`;

export const InputFieldWidthLimiter = styled.div`
  width: 104px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const HalfWidthWrapper = styled.div`
  width: 407px;
`;
