import React, { useReducer, useRef, useEffect } from 'react';
import addHours from 'date-fns/addHours';
import subHours from 'date-fns/subHours';
import getHours from 'date-fns/getHours';
import addMinutes from 'date-fns/addMinutes';
import subMinutes from 'date-fns/subMinutes';
import getMinutes from 'date-fns/getMinutes';
import format from 'date-fns/format';

import api from 'utils/api';
import companyLogsMapper from 'mappers/nextGen/company-logs/{company_name}/_';
import companyLogsExportsMapper from 'mappers/nextGen/company-logs/{company_name}/exports/_';
import companyExportsLinksMapper from 'mappers/nextGen/cloud-storage/buckets/{bucket_type}/{company_name}/download-links/_';

export const LogsState = React.createContext({
  state: {},
  loadInitialCompanyLogs: async (companyName, params) => [companyName, params],
});

const detailsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_LOGS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_LOGS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.data,
        },
      };
    case 'LOAD_COMPANY_LOGS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'LOAD_MORE_LOGS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loadingMore: true,
          loadMoreError: null,
        },
      };
    case 'LOAD_MORE_LOGS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loadingMore: false,
          data: {
            data: state[action.companyName].data.data.concat(action.data.data),
            next_page_token: action.data.next_page_token,
          },
        },
      };
    case 'LOAD_MORE_LOGS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loadingMore: false,
          loadMoreError: action.error,
        },
      };
    case 'LOAD_COMPANY_EXPORTS':
      return {
        ...state,
        ...(action.shouldPoll && { stopPolling: false }),
        [action.companyName]: {
          ...state[action.companyName],
          loadingExports: true,
          loadExportsError: null,
        },
      };
    case 'LOAD_COMPANY_EXPORTS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loadingExports: false,
          exports: action.exports,
        },
      };
    case 'LOAD_COMPANY_EXPORTS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loadingExports: false,
          loadExportsError: action.error,
        },
      };
    case 'EXPORT_LOGS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          creatingExport: true,
          createExportError: null,
        },
      };
    case 'EXPORT_LOGS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          creatingExport: false,
        },
      };
    case 'EXPORT_LOGS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          creatingExport: false,
          createExportError: action.error,
        },
      };
    case 'STOP_EXPORT_POLLING':
      return {
        ...state,
        stopPolling: true,
      };
    default:
      return state;
  }
};

const getApiDate = (date, time) => {
  if (!date) {
    return;
  }

  const [startHours, startMinutes] = time ? time.split(':') : [];

  const withHours = addHours(new Date(subHours(date, getHours(date))), +startHours);
  const withMinutes = addMinutes(new Date(subMinutes(withHours, getMinutes(date))), +startMinutes);

  return withMinutes.toISOString();
};

const formattedDateToISO = (formattedDate, time) => {
  if (!formattedDate) {
    return null;
  }
  const date = new Date(`${formattedDate} ${time}`);

  return date.toISOString();
};

const LogsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(detailsReducer, {});
  const pollingIntervalId = useRef();

  useEffect(() => {
    if (pollingIntervalId.current && state.stopPolling) {
      clearInterval(pollingIntervalId.current);
    }
  }, [state.stopPolling]);

  const loadInitialCompanyLogs = async (
    companyName,
    { perPage, startDate, endDate, startTime, endTime, text },
  ) => {
    dispatch({ type: 'LOAD_COMPANY_LOGS', companyName });

    const apiStartDate = getApiDate(startDate, startTime);
    const apiEndDate = getApiDate(endDate, endTime);

    try {
      const res = await api.v1.get(`company-logs/${companyName}`, {
        params: companyLogsMapper.v1.get.to({
          perPage,
          startDate: apiStartDate,
          endDate: apiEndDate,
          text,
        }),
      });

      const data = companyLogsMapper.v1.get.from(res.data);

      dispatch({
        type: 'LOAD_COMPANY_LOGS_SUCCESS',
        companyName,
        data,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_COMPANY_LOGS_FAIL', companyName, error });
    }
  };

  const loadMoreCompanyLogs = async (companyName, params) => {
    dispatch({ type: 'LOAD_MORE_LOGS', companyName });

    try {
      const apiStartDate = getApiDate(params.startDate, params.startTime);
      const apiEndDate = getApiDate(params.endDate, params.endTime);

      const nextPageToken = state[companyName].data.next_page_token;

      const res = await api.v1.get(`company-logs/${companyName}`, {
        params: companyLogsMapper.v1.get.to({
          ...params,
          startDate: apiStartDate,
          endDate: apiEndDate,
          nextPageToken,
        }),
      });

      dispatch({
        type: 'LOAD_MORE_LOGS_SUCCESS',
        companyName,
        data: companyLogsMapper.v1.get.from(res.data),
      });
    } catch (error) {
      dispatch({ type: 'LOAD_MORE_LOGS_FAIL', companyName, error });
    }
  };

  const loadCompanyExports = async (companyName, startPolling) => {
    dispatch({
      type: 'LOAD_COMPANY_EXPORTS',
      companyName,
      ...(startPolling && { shouldPoll: true }),
    });

    try {
      const res = await api.v1.get(`cloud-storage/buckets/log/${companyName}`);
      if (startPolling && res.data.some(exp => !exp.available)) {
        pollingIntervalId.current = setInterval(() => {
          loadCompanyExports(companyName, false);
        }, 3000);
      }

      dispatch({
        type: 'LOAD_COMPANY_EXPORTS_SUCCESS',
        companyName,
        exports: res.data,
      });
    } catch (error) {
      return Promise.reject(
        dispatch({
          type: 'LOAD_COMPANY_EXPORTS_FAIL',
          companyName,
          error,
        }),
      );
    }
  };

  const exportCompanyLogs = async (companyName, { startDate, endDate, startTime, endTime }) => {
    dispatch({ type: 'EXPORT_LOGS', companyName });

    const start_date = formattedDateToISO(format(new Date(startDate), 'yyyy-MM-dd'), startTime);
    const end_date = formattedDateToISO(format(new Date(endDate), 'yyyy-MM-dd'), endTime);

    try {
      await api.v1.post(
        `company-logs/${companyName}/exports`,
        companyLogsExportsMapper.v1.post.to({
          start_date,
          end_date,
        }),
      );

      dispatch({ type: 'EXPORT_LOGS_SUCCESS', companyName });
      loadCompanyExports(companyName);
    } catch (error) {
      dispatch({ type: 'EXPORT_LOGS_FAIL', companyName, error });
    }
  };

  const downloadExport = async (logExport, companyName) => {
    let exportDownload;

    const res = await api.v1.post(
      `cloud-storage/buckets/log/${companyName}/download-links`,
      companyExportsLinksMapper.v1.post.to(logExport.name),
    );

    exportDownload = res.data[0];

    const link = document.createElement('a');

    link.download = logExport.name;
    link.href = exportDownload.link;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const stopExportPolling = () => {
    dispatch({ type: 'STOP_EXPORT_POLLING' });
  };

  const value = {
    state,
    loadInitialCompanyLogs,
    loadMoreCompanyLogs,
    loadCompanyExports,
    exportCompanyLogs,
    downloadExport,
    stopExportPolling,
  };

  return <LogsState.Provider value={value}>{children}</LogsState.Provider>;
};

export default LogsProvider;
