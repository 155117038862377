import React from 'react';
import { Illustration, Stack, Text, Button } from '@kiwicom/orbit-components';

import { usePolyglot } from 'components/services/i18n';

const ErrorApi = () => {
  const polyglot = usePolyglot();
  return (
    <Stack direction="column" align="center" justify="center">
      <Illustration name="Error" size="medium" />
      <Text size="large" weight="bold">
        {polyglot.t('tequila.mmb.credit_activity.table.error.title')}
      </Text>
      <Text size="normal" type="secondary">
        {polyglot.t('tequila.mmb.credit_activity.table.error.title')}
      </Text>
      <Button type="primary" onClick={() => window.location.reload()}>
        {polyglot.t('tequila.mmb.credit_activity.table.error.button.text')}
      </Button>
    </Stack>
  );
};

export default ErrorApi;
