import React from 'react';

import Card from '@kiwicom/orbit-components/lib/Card';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { usePolyglot } from 'components/services/i18n';

const LoadingCard = () => {
  const polyglot = usePolyglot();
  return (
    <Card>
      <Loading loading type="boxLoader" text={polyglot.t('searchAndBooking.searching_trips')} />
    </Card>
  );
};

export default LoadingCard;
