const v1_get_from = data => {
  const invoices = [];
  data.booking_invoices.map(i => invoices.push({ type: 'Booking', url: i.url }));
  data.extra_invoices.map(i => invoices.push({ type: 'Extra', url: i.url }));

  return invoices;
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
