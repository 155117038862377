import React from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import DownloadIcon from '@kiwicom/orbit-components/lib/icons/Download';

import { usePolyglot } from 'components/services/i18n';
import useRegisterTOS from 'components/services/resources/useRegisterTOS';

import { ActionsContainer } from './RegisterTermsOfService.styled';

const RegisterTermsOfService = ({ visible, onClose }) => {
  const polyglot = usePolyglot();
  const registerTOS = useRegisterTOS();

  const getModalContent = () => {
    if (registerTOS.loading) {
      return (
        <ModalSection>
          <Loading />;
        </ModalSection>
      );
    }

    if (registerTOS.error || !registerTOS.data) {
      return (
        <ModalSection>
          <Alert type="critical" icon title={null}>
            {polyglot.t('register.error_loading_tos')}
          </Alert>
        </ModalSection>
      );
    }

    return (
      <>
        <ModalHeader title={registerTOS.data.title.value} />
        <ModalSection>
          <div dangerouslySetInnerHTML={{ __html: registerTOS.data.compiledContent }} />
        </ModalSection>
      </>
    );
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal closable onClose={onClose} fixedFooter>
      {getModalContent()}
      <ModalFooter fixed>
        <ActionsContainer>
          <Button width="128px" type="secondary" iconRight={<DownloadIcon />}>
            {polyglot.t('common.download')}
          </Button>
          <Button width="128px" onClick={onClose}>
            {polyglot.t('common.ok')}
          </Button>
        </ActionsContainer>
      </ModalFooter>
    </Modal>
  );
};

export default RegisterTermsOfService;
