import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { RequestTypeCategory } from 'shapes/Support';

import { RequestTypeContainer } from '../index.styled';

const Category = ({ category, openModal }) => (
  <Stack spaceAfter="largest">
    <Text size="large" type="secondary" spaceAfter="small">
      {category.name}
    </Text>
    <Stack direction="row" wrap align="stretch" spacing="none">
      {category.requestTypes.map(requestType => (
        <Stack shrink={false} grow={false} basis="25%" key={requestType.id}>
          <RequestTypeContainer
            onClick={() => {
              openModal(requestType);
            }}
            data-test={requestType.name.split(' ').join('-').toLowerCase()}
          >
            <Stack direction="row" align="center">
              <Stack basis="20%" shink={false} grow={false} justify="center" align="center">
                <img src={requestType.icon} alt={requestType.name} />
              </Stack>
              <Text>{requestType.name}</Text>
            </Stack>

            <Text type="secondary">{requestType.description}</Text>
          </RequestTypeContainer>
        </Stack>
      ))}
    </Stack>
  </Stack>
);

Category.propTypes = {
  openModal: PropTypes.func.isRequired,
  category: PropTypes.shape(RequestTypeCategory).isRequired,
};

export default Category;
