import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  & > * {
    margin-left: 4px;
  }
`;
