import * as Yup from 'yup';
import { endOfYesterday, startOfYesterday } from 'date-fns';

import { countriesWithDefault as countries } from 'consts/countries';

export const REPORT_TYPES = {
  MARKETS: 'markets',
  SALES: 'sales',
};

export const SALES_METRIC_OPTIONS = [
  {
    value: 'accounting_date',
    label: 'new_reports.report_form.metrics.accounting_date',
    isTransaction: true,
  },
  { value: 'additional_emails', label: 'new_reports.report_form.metrics.additional_emails' },
  { value: 'affily_part_2', label: 'new_reports.report_form.metrics.affily_part_2' },
  { value: 'airlines', label: 'new_reports.report_form.metrics.airlines' },
  { value: 'bid', label: 'new_reports.report_form.metrics.bid' },
  { value: 'booked_at', label: 'new_reports.report_form.metrics.booked_at' },
  { value: 'booked_date', label: 'new_reports.report_form.metrics.booked_date' },
  {
    value: 'booking_contact_email',
    label: 'new_reports.report_form.metrics.booking_contact_email',
    isTransaction: true,
  },
  { value: 'booking_timestamp', label: 'new_reports.report_form.metrics.booking_timestamp' },
  { value: 'confirmation_sent', label: 'new_reports.report_form.metrics.confirmation_sent' },
  { value: 'confirmed_at', label: 'new_reports.report_form.metrics.confirmed_at' },
  { value: 'content_type', label: 'new_reports.report_form.metrics.content_type' },
  { value: 'currency', label: 'new_reports.report_form.metrics.currency', isTransaction: true },
  {
    value: 'custom_currency',
    label: 'new_reports.report_form.metrics.custom_currency',
    isTransaction: true,
  },
  { value: 'departure_time', label: 'new_reports.report_form.metrics.departure_time' },
  {
    value: 'deposit_balance',
    label: 'new_reports.report_form.metrics.deposit_balance',
    isTransaction: true,
  },
  {
    value: 'deposit_reason',
    label: 'new_reports.report_form.metrics.deposit_reason',
    isTransaction: true,
  },
  { value: 'device_type', label: 'new_reports.report_form.metrics.device_type' },
  {
    value: 'destination_country',
    label: 'new_reports.report_form.metrics.destination_country',
    isTransaction: true,
  },
  { value: 'direct_roundtrip', label: 'new_reports.report_form.metrics.direct_roundtrip' },
  { value: 'email', label: 'new_reports.report_form.metrics.email', isTransaction: true },
  { value: 'eticket_number', label: 'new_reports.report_form.metrics.eticket_number' },
  { value: 'eur_amount', label: 'new_reports.report_form.metrics.eur_amount', isTransaction: true },
  {
    value: 'expected_paid_guarantee_gross_sales',
    label: 'new_reports.report_form.metrics.expected_paid_guarantee_gross_sales',
  },
  { value: 'external_id', label: 'new_reports.report_form.metrics.external_id' },
  { value: 'facilitated', label: 'new_reports.report_form.metrics.facilitated' },
  { value: 'fare_category', label: 'new_reports.report_form.metrics.fare_category' },
  { value: 'gwm_id', label: 'new_reports.report_form.metrics.gwm_id', isTransaction: true },
  {
    value: 'has_guarantee_attached',
    label: 'new_reports.report_form.metrics.has_guarantee_attached',
  },
  { value: 'is_no_bags_booking', label: 'new_reports.report_form.metrics.is_no_bags_booking' },
  { value: 'is_registered', label: 'new_reports.report_form.metrics.is_registered' },
  { value: 'language', label: 'new_reports.report_form.metrics.language' },
  { value: 'market', label: 'new_reports.report_form.metrics.market' },
  { value: 'markup_amount_eur', label: 'new_reports.report_form.metrics.markup_amount_eur' },
  {
    value: 'markup_original_amount',
    label: 'new_reports.report_form.metrics.markup_original_amount',
  },
  {
    value: 'markup_original_currency',
    label: 'new_reports.report_form.metrics.markup_original_currency',
  },
  { value: 'name', label: 'new_reports.report_form.metrics.name' },
  { value: 'o_d', label: 'new_reports.report_form.metrics.o_d' },
  {
    value: 'origin_country',
    label: 'new_reports.report_form.metrics.origin_country',
    isTransaction: true,
  },
  {
    value: 'original_amount',
    label: 'new_reports.report_form.metrics.original_amount',
    isTransaction: true,
  },
  { value: 'partner', label: 'new_reports.report_form.metrics.partner' },
  { value: 'passengers', label: 'new_reports.report_form.metrics.passengers' },
  { value: 'payout', label: 'new_reports.report_form.metrics.payout' },
  { value: 'pnrs', label: 'new_reports.report_form.metrics.pnrs' },
  {
    value: 'post_booking',
    label: 'new_reports.report_form.metrics.post_booking',
    isTransaction: true,
  },
  { value: 'price', label: 'new_reports.report_form.metrics.price' },
  {
    value: 'price_custom_currency',
    label: 'new_reports.report_form.metrics.price_custom_currency',
  },
  { value: 'promo_code', label: 'new_reports.report_form.metrics.promo_code' },
  {
    value: 'return_departure_time',
    label: 'new_reports.report_form.metrics.return_departure_time',
  },
  { value: 'status', label: 'new_reports.report_form.metrics.status' },
  { value: 'surname', label: 'new_reports.report_form.metrics.surname' },
  { value: 'total_flight_time', label: 'new_reports.report_form.metrics.total_flight_time' },
  {
    value: 'tracking_timestamp',
    label: 'new_reports.report_form.metrics.tracking_timestamp',
    isTransaction: true,
  },
  {
    value: 'transaction_bid',
    label: 'new_reports.report_form.metrics.transaction_bid',
    isTransaction: true,
  },
  {
    value: 'transaction_id',
    label: 'new_reports.report_form.metrics.transaction_id',
    isTransaction: true,
  },
  {
    value: 'transaction_partner',
    label: 'new_reports.report_form.metrics.transaction_partner',
    isTransaction: true,
  },
  {
    value: 'transaction_reference',
    label: 'new_reports.report_form.metrics.transaction_reference',
    isTransaction: true,
  },
  {
    value: 'transaction_status',
    label: 'new_reports.report_form.metrics.transaction_status',
    isTransaction: true,
  },
  {
    value: 'transaction_timestamp',
    label: 'new_reports.report_form.metrics.transaction_timestamp',
    isTransaction: true,
  },
  {
    value: 'transaction_type',
    label: 'new_reports.report_form.metrics.transaction_type',
    isTransaction: true,
  },
  { value: 'trip_detail', label: 'new_reports.report_form.metrics.trip_detail' },
  { value: 'trip_type', label: 'new_reports.report_form.metrics.trip_type' },
  { value: 'utm_campaign', label: 'new_reports.report_form.metrics.utm_campaign' },
  { value: 'utm_content', label: 'new_reports.report_form.metrics.utm_content' },
  { value: 'utm_medium', label: 'new_reports.report_form.metrics.utm_medium' },
  { value: 'utm_source', label: 'new_reports.report_form.metrics.utm_source' },
  { value: 'vehicle_type', label: 'new_reports.report_form.metrics.vehicle_type' },
];

export const MARKET_METRIC_OPTIONS = [
  { value: 'avg_booking', label: 'new_reports.report_form.metrics.avg_booking' },
  { value: 'clicks', label: 'new_reports.report_form.metrics.clicks' },
  { value: 'content_type', label: 'new_reports.report_form.metrics.content_type' },
  { value: 'conversion_rate', label: 'new_reports.report_form.metrics.conversion_rate' },
  { value: 'conversions', label: 'new_reports.report_form.metrics.conversions' },
  { value: 'facilitated', label: 'new_reports.report_form.metrics.facilitated' },
  { value: 'l2b_ratio', label: 'new_reports.report_form.metrics.l2b_ratio' },
  { value: 'market', label: 'new_reports.report_form.metrics.market' },
  { value: 'partner', label: 'new_reports.report_form.metrics.partner' },
  { value: 'payout', label: 'new_reports.report_form.metrics.payout' },
  { value: 'sale_amount', label: 'new_reports.report_form.metrics.sale_amount' },
];

export const FILTER_TYPES_VALUES = {
  DEVICE: 'device',
  MARKET: 'market',
  ROUTE_TYPE: 'routeType',
  FINAL_STATUS: 'finalStatus',
};

export const FILTER_TYPES = {
  [REPORT_TYPES.SALES]: [
    { label: 'new_reports.filters.filter_type.device', value: FILTER_TYPES_VALUES.DEVICE },
    { label: 'new_reports.filters.filter_type.market', value: FILTER_TYPES_VALUES.MARKET },
    { label: 'new_reports.filters.filter_type.route_type', value: FILTER_TYPES_VALUES.ROUTE_TYPE },
    {
      label: 'new_reports.filters.filter_type.final_status',
      value: FILTER_TYPES_VALUES.FINAL_STATUS,
    },
  ],
  [REPORT_TYPES.MARKETS]: [
    { label: 'new_reports.filters.filter_type.market', value: FILTER_TYPES_VALUES.MARKET },
  ],
};

export const FILTER_VALUES = {
  [REPORT_TYPES.SALES]: {
    [FILTER_TYPES_VALUES.DEVICE]: [
      { label: 'Mobile', value: 'mobile' },
      { label: 'Tablet', value: 'tablet' },
      { label: 'PC', value: 'pc' },
      { label: 'Bot', value: 'bot' },
    ],
    [FILTER_TYPES_VALUES.MARKET]: countries.map(country => ({
      label: country.label,
      value: country.value,
    })),
    [FILTER_TYPES_VALUES.ROUTE_TYPE]: [
      { label: 'new_reports.filters.filter_value.route_type.oneway', value: 'oneway' },
      { label: 'new_reports.filters.filter_value.route_type.roundtrip', value: 'roundtrip' },
      { label: 'new_reports.filters.filter_value.route_type.multicity', value: 'multicity' },
      { label: 'new_reports.filters.filter_value.route_type.openjaw', value: 'openjaw' },
      { label: 'new_reports.filters.filter_value.route_type.other', value: 'other' },
      {
        label: 'new_reports.filters.filter_value.route_type.travellingsalesman',
        value: 'travellingsalesman',
      },
      { label: 'new_reports.filters.filter_value.route_type.nomad', value: 'nomad' },
    ],
    [FILTER_TYPES_VALUES.FINAL_STATUS]: [
      {
        label: 'new_reports.filters.filter_value.final_status.closed',
        value: 'closed',
      },
      {
        label: 'new_reports.filters.filter_value.final_status.refunded',
        value: 'refunded',
      },
      {
        label: 'new_reports.filters.filter_value.final_status.confirmed',
        value: 'confirmed',
      },
    ],
  },
  [REPORT_TYPES.MARKETS]: {
    [FILTER_TYPES_VALUES.MARKET]: countries.map(country => ({
      label: country.label,
      value: country.value,
    })),
  },
};

const MAIL_REGEX = /^[\w+.-]+@\w+([.-]?\w+)*(\.\w{2,24})+$/;

const scheduleSchema = Yup.object().shape({
  formatFile: Yup.string().test('isScheduleChecked', 'booking.required', (value, context) => {
    return !(context.parent.isOn && !['csv', 'xlsx'].includes(value));
  }),
  emailIsOn: Yup.boolean().test(
    'is-email',
    'new_reports.report_form.scheduled_delivery.checkbox.email.error',
    (value, context) => {
      return !(context.parent.isOn && !context.parent.sftpIsOn && !value);
    },
  ),
  emailRecipients: Yup.array().when('emailIsOn', {
    is: true,
    then: Yup.array()
      .test({
        name: 'email-test',
        message: 'validation.invalid_email',
        test: value =>
          value.reduce((acc, curr) => (acc && !!curr ? MAIL_REGEX.test(curr) : false), true),
      })
      .test('required-test', 'booking.required', value => value.length !== 0),
  }),
  sftpIsOn: Yup.boolean().test(
    'is-sftp',
    'new_reports.report_form.scheduled_delivery.checkbox.email.error',
    (value, context) => {
      return !(context.parent.isOn && !context.parent.emailIsOn && !value);
    },
  ),
  sftpHostName: Yup.string().when('sftpIsOn', {
    is: true,
    then: Yup.string().max(512, 'validation.length_exceeded').required('booking.required'),
    otherwise: Yup.string(),
  }),
  sftpPort: Yup.number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .when('sftpIsOn', {
      is: true,
      then: Yup.number()
        .typeError('validation.must_be_numbers')
        .max(65535, 'validation.length_exceeded')
        .required('booking.required')
        .transform((_, val) => (val === Number(val) ? val : null)),
    }),
  sftpUserName: Yup.string().when('sftpIsOn', {
    is: true,
    then: Yup.string().max(512, 'validation.length_exceeded').required('booking.required'),
  }),
  sftpPassword: Yup.string().when('sftpIsOn', {
    is: true,
    then: Yup.string().required('booking.required'),
  }),
  sftpFilePath: Yup.string().when('sftpIsOn', {
    is: true,
    then: Yup.string().max(512, 'validation.length_exceeded').required('booking.required'),
  }),
});

export const reportsSalesValidationSchema = Yup.object().shape({
  reportName: Yup.string()
    .required('new_reports.report_form.report_name_error')
    .max(33, 'validation.length_exceeded'),
  dateRange: Yup.object().when('timeRangeType', {
    is: 'scheduled',
    then: Yup.object().notRequired(),
    otherwise: Yup.object().shape({
      from: Yup.date().required('new_reports.report_form.time_range_error'),
      to: Yup.date().required('new_reports.report_form.time_range_error'),
    }),
  }),
  reportMetrics: Yup.array().min(1, 'new_reports.report_form.metrics_error'),
  solutions: Yup.array().when('companies', {
    is: companies => !companies?.length,
    then: Yup.array().min(1, 'new_reports.report_form.solutions_error'),
  }),
  companies: Yup.array(),
  filters: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('new_reports.report_form.filter_type_error'),
      values: Yup.array().min(1, 'new_reports.report_form.filter_values_error'),
    }),
  ),
  timeRangeType: Yup.string().required('new_reports.report_form.time_range_error'),
  dailySchedule: scheduleSchema,
  weeklySchedule: scheduleSchema,
  monthlySchedule: scheduleSchema,
});

export const reportsMarketsValidationSchema = Yup.object().shape({
  reportName: Yup.string()
    .required('new_reports.report_form.report_name_error')
    .max(33, 'validation.length_exceeded'),
  dateRange: Yup.object().when('timeRangeType', {
    is: 'scheduled',
    then: Yup.object().notRequired(),
    otherwise: Yup.object().shape({
      from: Yup.date().required('new_reports.report_form.time_range_error'),
      to: Yup.date().required('new_reports.report_form.time_range_error'),
    }),
  }),
  reportMetrics: Yup.array().min(1, 'new_reports.report_form.metrics_error'),
  solutions: Yup.array().min(1, 'new_reports.report_form.solutions_error_2'),
  filters: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('new_reports.report_form.filter_type_error'),
      values: Yup.array().min(1, 'new_reports.report_form.filter_values_error'),
    }),
  ),
  timeRangeType: Yup.string().required('new_reports.report_form.time_range_error'),
  dailySchedule: scheduleSchema,
  weeklySchedule: scheduleSchema,
  monthlySchedule: scheduleSchema,
});

export const FORMAT_OPTIONS = [
  { value: 'csv', label: 'CSV' },
  { value: 'xlsx', label: 'Excel' },
];

export const SCHEDULE_VALUES = {
  emailIsOn: false,
  emailRecipients: [],
  formatFile: '',
  isOn: false,
  sftpFilePath: '',
  sftpHostName: '',
  sftpIsOn: false,
  sftpPassword: '',
  sftpPort: undefined,
  sftpUserName: '',
};

const todaysDate = new Date();

export const DEFAULT_VALUES_SALES = {
  companies: [],
  dailySchedule: SCHEDULE_VALUES,
  dateRange: { from: startOfYesterday(todaysDate), to: endOfYesterday(todaysDate) },
  filters: [],
  monthlySchedule: SCHEDULE_VALUES,
  reportMetrics: ['bid', 'departure_time', 'passengers', 'status'],
  reportName: '',
  solutions: [],
  timeRangeType: '',
  weeklySchedule: SCHEDULE_VALUES,
};

export const DEFAULT_VALUES_MARKETS = {
  dailySchedule: SCHEDULE_VALUES,
  dateRange: { from: startOfYesterday(todaysDate), to: endOfYesterday(todaysDate) },
  filters: [],
  monthlySchedule: SCHEDULE_VALUES,
  reportMetrics: ['avg_booking', 'clicks', 'market', 'partner', 'payout'],
  reportName: '',
  solutions: [],
  timeRangeType: '',
  weeklySchedule: SCHEDULE_VALUES,
};

export const ALIAS_TYPES = {
  DEFAULT: 'default',
  DRAFT: 'draft',
  TEMP: 'temp',
};

export const REPORT_STATUS = {
  INPROGRESS: 'inprogress',
  AVAILABLE: 'available',
  DISPATCHED: 'dispatched',
};

export const filterActiveScheduledReports = scheduledReports => {
  if (!scheduledReports?.length) {
    return [];
  }

  return scheduledReports.filter(scheduledReport => scheduledReport.isOn);
};
