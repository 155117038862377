import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { HOUR, DAY, YESTERDAY, LAST_7_DAYS, LAST_28_DAYS, LAST_90_DAYS } from 'consts/dates';

export const getIntervalForDateRangeConstant = dateRange => {
  switch (dateRange) {
    case YESTERDAY:
      return HOUR;
    case LAST_7_DAYS:
      return DAY;
    case LAST_28_DAYS:
      return DAY;
    case LAST_90_DAYS:
      return DAY;
    default:
      return DAY;
  }
};

export const getFormatedIntervalForChart = (interval, intervalConstant) => {
  switch (intervalConstant) {
    case HOUR:
      return format(parseISO(interval), 'H EEE, MMM d');
    case DAY:
      return format(parseISO(interval), 'EEE, MMM d');
    default:
      return format(parseISO(interval), 'EEE, MMM d');
  }
};
