import { formatISO } from 'date-fns';

import { camelToSnakeCase, snakeCaseToCamelCase } from 'utils/string';

const v2_get_from = reportAlias => ({
  alias: reportAlias.alias,
  aliasType: reportAlias.alias_type,
  id: reportAlias.id,
  reportType: reportAlias.report_type,
  reportConfig: {
    dateRange: {
      from: reportAlias.report_config.date_range.from,
      to: reportAlias.report_config.date_range.to,
    },
    metrics: reportAlias.report_config.metrics,
    interval: reportAlias.report_config.interval,
    ...(reportAlias.report_config.filters && {
      filters: Object.keys(reportAlias.report_config.filters).reduce((acc, key) => {
        acc[snakeCaseToCamelCase(key)] = reportAlias.report_config.filters[key];
        return acc;
      }, {}),
    }),
  },
});

const v2_put_to = (reportAlias, newReportValues) => ({
  ...(reportAlias.reportName && { alias: reportAlias.reportName }),
  report_config: {
    ...(!!reportAlias.dateRange && {
      date_range: {
        from: formatISO(reportAlias.dateRange.from),
        to: formatISO(reportAlias.dateRange.to),
      },
    }),
    ...(reportAlias.reportMetrics && {
      metrics: reportAlias.reportMetrics,
    }),
    filters: {
      ...(newReportValues.filters &&
        newReportValues.filters.reduce((acc, filterItem) => {
          acc[camelToSnakeCase(filterItem.type)] = filterItem.values;
          return acc;
        }, {})),
      ...((reportAlias.solutions?.length || newReportValues.solutions?.length) && {
        affiliate: reportAlias.solutions || newReportValues.solutions,
      }),
      ...((reportAlias.companies?.length || newReportValues.companies?.length) && {
        company: reportAlias.companies || newReportValues.companies,
      }),
      ...(reportAlias.filters &&
        reportAlias.filters.reduce((acc, filterItem) => {
          acc[camelToSnakeCase(filterItem.type)] = filterItem.values;
          return acc;
        }, {})),
    },
  },
});

export default {
  v2: {
    get: {
      from: v2_get_from,
    },
    put: {
      to: v2_put_to,
    },
  },
};
