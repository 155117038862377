import { useContext } from 'react';

import { BookingState } from './BookingProvider';

const useBooking = () => {
  const bookingCtx = useContext(BookingState);

  return bookingCtx;
};

export default useBooking;
