import React from 'react';
import PropTypes from 'prop-types';

import { TabContainer, TabButton } from './Tab.styled';

const Tab = props => {
  return (
    <TabContainer lineSize={props.lineSize}>
      <TabButton {...props}>{props.label}</TabButton>
    </TabContainer>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  active: PropTypes.bool,
};

export default Tab;
