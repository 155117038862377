import * as React from 'react';

const Illustration5 = ({ color1, color2 }) => (
  <svg id="ill5_pref__Layer_10" data-name="Layer 10" viewBox="0 0 1941.08 1249.2">
    <defs>
      <linearGradient
        id="ill5_pref__linear-gradient_ill5"
        x1={1309.03}
        y1={1480.01}
        x2={526.04}
        y2={123.83}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" stopOpacity={0} />
        <stop offset={0.5} stopColor="#bac7d5" />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill5_pref__linear-gradient_ill5-2"
        x1={1349.24}
        y1={1331.22}
        x2={566.25}
        y2={-24.96}
        xlinkHref="#ill5_pref__linear-gradient_ill5"
      />
      <linearGradient
        id="ill5_pref__linear-gradient_ill5-3"
        x1={1415.04}
        y1={1191.15}
        x2={632.05}
        y2={-165.03}
        xlinkHref="#ill5_pref__linear-gradient_ill5"
      />
      <style>
        {
          '.ill5_pref__cls-1{fill:#cad5df}.ill5_pref__cls-2{fill:#e5eaee}.ill5_pref__cls-8{fill:#e8e8e8}.ill5_pref__cls-9{fill:#f0f0f0}.ill5_pref__cls-10{fill:#f7931e}.ill5_pref__cls-11{fill:none}'
        }
      </style>
    </defs>
    <path
      className="ill5_pref__cls-1"
      d="M1833.17 701.88V994c0 1.45 1.11 2.9 3.33 4a20.17 20.17 0 0016 0c2.22-1.11 3.33-2.56 3.33-4V701.88z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={1733.91} cy={492.33} rx={11.35} ry={5.68} />
    <path
      fill="url(#ill5_pref__linear-gradient_ill5)"
      opacity={0.5}
      d="M146.9 360.31L0 427.55l1692.9 821.65 142.18-72.9L146.9 360.31z"
    />
    <path
      fill="url(#ill5_pref__linear-gradient_ill5-2)"
      opacity={0.5}
      d="M187.1 211.52L40.2 278.75l1692.91 821.66 142.17-72.9L187.1 211.52z"
    />
    <path
      fill="url(#ill5_pref__linear-gradient_ill5-3)"
      opacity={0.5}
      d="M252.9 71.45L106 138.68l1692.91 821.66 142.17-72.9L252.9 71.45z"
    />
    <path
      d="M1143.5 1078.81c-69.25.79-135.62-12.64-190.56-35.44l398.9-274.11c50.83 27.94 87.38 62.5 97.88 95.12 39.59 122.98-91.79 211.97-306.22 214.43z"
      transform="translate(-110.62 -209.55)"
      fill="#bac7d5"
    />
    <path
      className="ill5_pref__cls-7"
      d="M1499.88 860c-25.84-80.25-187.61-170.59-355-176.27C881.34 674.8 760.31 782 760.31 890.46c0 70 64.63 134.9 159.88 175.41 64.42 27.39 142.83 43.62 224.73 42.68 248.55-2.84 400.85-106 354.96-248.55zm-356.38 218.81c-69.25.79-135.62-12.64-190.56-35.44-84-34.83-141.23-91.53-141.23-152.71 0-93.55 104.41-186 331.79-178.33 76.33 2.59 151.32 25.59 208.34 56.93 50.83 27.94 87.38 62.5 97.88 95.12 39.59 122.98-91.79 211.97-306.22 214.43z"
      transform="translate(-110.62 -209.55)"
      fill={color1}
    />
    <path
      d="M1143.5 712.33c-227.39-7.71-331.79 84.78-331.79 178.33 0 61.18 57.25 117.88 141.22 152.71l398.91-274.11c-57.03-31.34-132.02-54.34-208.34-56.93zm-185 212.93l-27.33-132.7 226-8.41 5.78 30-173.43 5.26 21 104.58z"
      transform="translate(-110.62 -209.55)"
      fill={color2}
    />
    <path
      className="ill5_pref__cls-7"
      d="M878.85 609.82l21.02 104.58-52.02 1.31-27.34-132.7 225.98-8.41 5.79 29.96-173.43 5.26z"
      fill={color1}
    />
    <path
      className="ill5_pref__cls-8"
      d="M1331.64 1097.08v285.11c0 1.46 1.11 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4v-285.11z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-9" cx={1232.37} cy={887.54} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-8"
      d="M1015.87 946.44v285.11c0 1.45 1.11 2.91 3.33 4a20.26 20.26 0 0016 0c2.22-1.11 3.33-2.57 3.33-4V946.44z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-9" cx={916.61} cy={736.89} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M1331.64 1097.08v285.12c0 1.45 1.11 2.9 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4v-285.12z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={1232.37} cy={887.54} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-10"
      d="M1221.02 986.89L924.53 838.08v-47.95l296.49 148.8v47.96z"
    />
    <path
      className="ill5_pref__cls-1"
      d="M679.25 782.49v292.11c0 1.46 1.11 2.91 3.33 4a20.2 20.2 0 0016.06 0c2.21-1.11 3.32-2.56 3.32-4V782.49z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={579.99} cy={572.94} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M358.41 627.52v292.11c0 1.46 1.11 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4V627.52z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={259.14} cy={417.97} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M859 215.22v292.12c0 1.45 1.1 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.1 3.32-2.56 3.32-4V215.22z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={759.75} cy={5.68} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M1183.4 369.8v292.11c0 1.46 1.1 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.32-2.56 3.32-4V369.8z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={1084.13} cy={160.25} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M1509.76 528.25v292.12c0 1.45 1.11 2.9 3.33 4a20.17 20.17 0 0016.05 0c2.22-1.11 3.33-2.56 3.33-4V528.25z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={1410.5} cy={318.7} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-1"
      d="M1015.87 946.78v285.12c0 1.45 1.11 2.9 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4V946.78z"
      transform="translate(-110.62 -209.55)"
    />
    <ellipse className="ill5_pref__cls-2" cx={916.6} cy={737.24} rx={11.35} ry={5.68} />
    <path
      className="ill5_pref__cls-10"
      d="M905.25 827.27L594.88 671.06v-47.95l310.37 156.21v47.95zM568.63 665L270.5 515.48v-47.96l298.14 149.52-.01 47.96zM1072.78 253.31L772.87 103.79V55.84l299.91 149.51v47.96zM1397.39 407.88l-301.91-149.51v-47.96l301.91 149.52v47.95zM1721.96 563.5l-300.11-149.52v-47.96l300.7 150.81-.59 46.67z"
    />
    <path className="ill5_pref__cls-11" d="M1241.92 550.49l-6.82 4.64M835.14 827.27l-7.61 5.18" />
  </svg>
);

export default React.memo(Illustration5);
