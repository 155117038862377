import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { usePolyglot } from 'components/services/i18n';

import Tab from './Tab';
import { MainContainer, TabsContainer, TabLine, ComponentContainer } from './Tabs.styled';

const Tabs = ({ items, crossDimensionBorder, translateLabels, transparent, lineSize }) => {
  const [activeTab, setActiveTab] = useState(items[0]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const refs = useRef([...Array(items.length)].map(() => React.createRef()));
  const containerRef = useRef(null);
  const [refresh, setRefresh] = useState(0);
  const polyglot = usePolyglot();

  const handleSelectTab = (item, i) => {
    setActiveTab(item);
    setActiveTabIndex(i);
  };

  useLayoutEffect(() => {
    setRefresh(refresh + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs.current]);

  const activeBoundaries =
    refs.current[activeTabIndex].current &&
    refs.current[activeTabIndex].current.getBoundingClientRect();

  const lineProps = {};
  if (activeBoundaries) {
    const { left: containerLeft } = containerRef.current.getBoundingClientRect();
    lineProps.width = activeBoundaries.width;
    lineProps.left = activeBoundaries.left - containerLeft;
  }

  return (
    <MainContainer>
      <TabsContainer ref={containerRef} crossDimensionBorder={crossDimensionBorder}>
        {items.map((item, i) => {
          return (
            <div ref={refs.current[i]} key={item.value}>
              <Tab
                onClick={() => handleSelectTab(item, i)}
                label={translateLabels ? polyglot.t(item.label) : item.label}
                active={item.value === activeTab.value}
                value={item.value}
                transparent={transparent}
                lineSize={lineSize}
              />
            </div>
          );
        })}
        <TabLine active {...lineProps} lineSize={lineSize} />
      </TabsContainer>
      <ComponentContainer>{activeTab.component}</ComponentContainer>
    </MainContainer>
  );
};

Tabs.defaultProps = {
  crossDimensionBorder: false,
  lineSize: 'medium',
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
    }),
  ),
  crossDimensionBorder: PropTypes.bool,
  translateLabels: PropTypes.bool,
  transparent: PropTypes.bool,
  lineSize: PropTypes.oneOf(['small', 'medium']),
};

export default Tabs;
