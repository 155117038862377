import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ConfirmDialog } from 'common';
import useMenuState from 'components/services/menu/useMenuState';
import { useLocalStorage } from 'components/services/localStorage';

import PageHeader from './PageHeader';
import StaticCard from './ContextualResources/StaticCard';
import ContextualResourcesProvider, {
  ContextualResourcesState,
} from './ContextualResourcesProvider';

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  ${({ isNavigationShown, theme }) =>
    `margin-left: ${isNavigationShown ? theme.sizes.navigationWidth : 0}px`}
`;

const ChildrenWrapper = styled.div`
  padding: ${({ isNavigationShown }) => (isNavigationShown ? '84px 24px 24px' : '84px 0 24px ')};
  position: relative;
`;

const ResourcesCard = () => {
  const [staticHelp] = useLocalStorage('static_help', { shown: true });
  const { forceToPopover, hasResources } = useContext(ContextualResourcesState);

  if (!staticHelp.shown || forceToPopover || !hasResources) {
    return null;
  }

  return <StaticCard />;
};

const AppContent = ({ children }) => {
  const menuCtx = useMenuState();

  return (
    <ContextualResourcesProvider>
      <ContentContainer isNavigationShown={menuCtx.isNavigationShown}>
        <PageHeader />
        <ResourcesCard />
        <ChildrenWrapper isNavigationShown={menuCtx.isNavigationShown}>{children}</ChildrenWrapper>
        <ConfirmDialog />
      </ContentContainer>
    </ContextualResourcesProvider>
  );
};

AppContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContent;
