import React from 'react';

import FlightNomad from '@kiwicom/orbit-components/lib/icons/FlightNomad';
import SeatWindow from '@kiwicom/orbit-components/lib/icons/SeatWindow';
import SeatAisle from '@kiwicom/orbit-components/lib/icons/SeatAisle';
import SeatExtraLegroom from '@kiwicom/orbit-components/lib/icons/SeatExtraLegroom';
import UserGroup from '@kiwicom/orbit-components/lib/icons/UserGroup';

import { QUICK_OPTION_TYPES } from 'consts/seating';

const SeatingIcon = ({ option }) => {
  switch (option) {
    case QUICK_OPTION_TYPES.WINDOW:
      return <SeatWindow />;
    case QUICK_OPTION_TYPES.AISLE:
      return <SeatAisle />;
    case QUICK_OPTION_TYPES.EXTRA_LEG_ROOM:
      return <SeatExtraLegroom />;
    case QUICK_OPTION_TYPES.SIT_TOGETHER:
      return <UserGroup />;

    default:
      return <FlightNomad />;
  }
};

export default SeatingIcon;
