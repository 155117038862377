import React from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { secondsToString, flightCardAdapters } from 'utils/search';
import { SortTabContainer, ActiveBar, SortTabContent } from './index.styled';

const SortTab = ({ changeSortType, sortType, name, active, icon, flight, tripType, loading }) => {
  const handleSortTypeChange = () => {
    if (loading) {
      return;
    }
    changeSortType(sortType);
  };

  const { getPrice } = flightCardAdapters[tripType];

  return (
    <SortTabContainer>
      <SortTabContent active={active} onClick={handleSortTypeChange}>
        <Stack justify="between">
          <Text weight="bold" dataTest={`booking-sort-tab-name-${sortType}`}>
            {name}
          </Text>
          {icon}
        </Stack>
        <Stack inline>
          {loading ? (
            <Loading type="inlineLoader" />
          ) : (
            <>
              <Stack inline spacing="small">
                <Text size="small" dataTest={`booking-sort-tab-price-${sortType}`}>
                  {flight ? getPrice(flight) : '...'}
                </Text>
              </Stack>
              <Text
                size="small"
                type="secondary"
                dataTest={`booking-sort-tab-duration-${sortType}`}
              >
                {flight ? secondsToString(flight.duration.total || flight.duration) : '...'}
              </Text>
            </>
          )}
        </Stack>
      </SortTabContent>
      {active && <ActiveBar />}
    </SortTabContainer>
  );
};

export default SortTab;
