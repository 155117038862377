import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'debounce';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import AccessDeniedErrorMessage from 'components/scenes/Company/scenes/Users/AccessDeniedErrorMessage';
import { OrbitLoader, Pagination, Flex } from 'common';
import UserModal from 'components/common/UserModal';
import { Authorization } from 'common/Authorization';
import { UsersState } from 'components/services/users';
import { CompanyUsersState } from 'components/services/companyUsers';
import useUsers from 'components/services/users/useUsers';
import useRoles from 'components/services/products/useRoles';
import { usePolyglot } from 'components/services/i18n';
import useImpersonate from 'hooks/useImpersonate';
import {
  HeaderContainer,
  AlertContainer,
  LoaderContainer,
  PageContainer,
  FilterContainer,
} from './UserManagement.styled';
import UserList from './components/UserList';

/**
 * Displays a table for user management.
 * Kiwi admin can view all users in the system, edit them and create new users.
 * Assigning companies to users can be done through this component.
 */

const UserManagement = () => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userBeingEdited, setUserBeingEdited] = useState(null);
  const [filterQuery, setFilterQuery] = useState('');
  const [isAdditionalInfoNeeded, setIsAdditionalInfoNeeded] = useState(false);
  const [pagination, setPagination] = useState({
    perPage: 20,
    page: 1,
    totalCount: 0,
    usersPaginated: [],
    usersFiltered: [],
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const { updateUser, loadUsers } = useContext(UsersState);
  const { users, loading: loadingUsers, updatingUser } = useUsers();
  const { createCompanyUser, creatingUser } = useContext(CompanyUsersState);
  const polyglot = usePolyglot();
  const roles = useRoles();
  const history = useHistory();

  const [, setImpersonatingUser] = useImpersonate();

  useEffect(() => {
    if (users) {
      setPagination(prevState => ({
        ...prevState,
        totalCount: users.length,
        usersFiltered: users,
        usersPaginated: users.slice(0, prevState.perPage),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUsers]);

  const handleError = err => {
    if (err?.response?.data?.message.toLowerCase() === 'kyc check failed.') {
      if (!isAdditionalInfoNeeded) {
        setError({ msg: polyglot.t('add_user.screen_additional_info'), type: 'warning' });
        setIsAdditionalInfoNeeded(true);
      } else {
        setError({
          title: polyglot.t('register.access_denied'),
          msg: <AccessDeniedErrorMessage />,
          type: 'critical',
        });
      }
    } else if (
      err?.response?.status === 409 &&
      err?.response?.data?.message.toLowerCase().includes('user')
    ) {
      setError({ msg: polyglot.t('register.error_user_exists') });
    } else {
      setError({ msg: polyglot.t('common.submit_error_message') });
    }
  };

  const cleanUpAndClose = (successMsg = null) => {
    setUserModalOpen(false);
    setUserBeingEdited(null);
    setIsAdditionalInfoNeeded(false);
    setError(null);
    setFilterQuery('');
    setSuccessMessage(successMsg);
  };

  const handleSubmit = async user => {
    try {
      if (!userBeingEdited) {
        await createCompanyUser(user, user.company, isAdditionalInfoNeeded);
        cleanUpAndClose(polyglot.t('user_management.user_successfully_created'));
        await loadUsers();
      } else {
        await updateUser(user);
        // if user has no company, add user to selected one
        if (!userBeingEdited.companyName) {
          await createCompanyUser(user, user.company, isAdditionalInfoNeeded);
        }
        cleanUpAndClose(polyglot.t('user_management.user_successfully_edited'));
        await loadUsers();
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleFilter = event => {
    setFilterQuery(event.target.value);
    debouncedFilter(event.target.value);
  };

  const debouncedFilter = debounce(filterQueryValue => {
    const filteredUsers = users.filter(user => {
      return (
        user.firstName?.indexOf(filterQueryValue) !== -1 ||
        user.lastName?.indexOf(filterQueryValue) !== -1 ||
        user.email?.indexOf(filterQueryValue) !== -1 ||
        roles?.data?.find(r => r.name === user.role)?.displayName?.indexOf(filterQueryValue) !==
          -1 ||
        user?.companyName?.indexOf(filterQueryValue) !== -1
      );
    });
    setPagination(prevState => ({
      ...prevState,
      usersFiltered: filteredUsers,
      usersPaginated: filteredUsers.slice(0, prevState.perPage),
      page: 1,
      totalCount: filteredUsers.length,
    }));
  }, 400);

  const handlePagination = (rowsPerPage, nextPage) => {
    setPagination(prevState => ({
      ...prevState,
      usersPaginated: prevState.usersFiltered.slice(
        (nextPage - 1) * rowsPerPage,
        nextPage * rowsPerPage,
      ),
      perPage: Number(rowsPerPage),
      page: nextPage,
    }));
  };

  const handleImpersonateUser = user => {
    setImpersonatingUser({ uuid: user.uuid });
    history.go(0);
  };

  const submitting = updatingUser || creatingUser;
  return (
    <PageContainer>
      {userModalOpen && (
        <UserModal
          onClose={() => {
            cleanUpAndClose();
          }}
          onSubmit={handleSubmit}
          loading={submitting}
          userBeingEdited={userBeingEdited}
          emptyCompany
          isAdditionalInfoNeeded={isAdditionalInfoNeeded}
          error={error}
        />
      )}

      <HeaderContainer>
        {!loadingUsers && (
          <Flex>
            <FilterContainer>
              <InputField
                size="small"
                type="text"
                value={filterQuery}
                placeholder={polyglot.t('user_management.filter')}
                onChange={handleFilter}
              />
            </FilterContainer>
            <Authorization resource="model.user" action={['create']}>
              <Button size="small" type="primary" onClick={() => setUserModalOpen(true)}>
                {polyglot.t('company.add_user')}
              </Button>
            </Authorization>
          </Flex>
        )}
      </HeaderContainer>
      {successMessage && (
        <AlertContainer>
          <Alert type="success" icon onClose={() => setSuccessMessage(null)} closable>
            {successMessage}
          </Alert>
        </AlertContainer>
      )}
      <div>
        {loadingUsers ? (
          <LoaderContainer>
            <OrbitLoader id="user-loader" visible />
          </LoaderContainer>
        ) : (
          <>
            <UserList
              polyglot={polyglot}
              users={pagination.usersPaginated}
              openUserModal={user => {
                setUserModalOpen(true);
                setUserBeingEdited(user);
              }}
              onImpersonate={handleImpersonateUser}
            />
            <Pagination
              rows={pagination.totalCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              rowsPerPageItems={[10, 20, 50, 100]}
              onPagination={handlePagination}
            />
          </>
        )}
      </div>
    </PageContainer>
  );
};

export default UserManagement;
