import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import * as sanitizeHtml from 'sanitize-html';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import Text from '@kiwicom/orbit-components/lib/Text';

import Polyglot from 'shapes/Polyglot';
import { RequestType } from 'shapes/Support';
import { STATUS } from 'redux/modules/apigee/requests';
import { proxy } from 'utils/api';
import { fireDocsEvent } from 'utils/ga';
import * as Intercom from 'utils/intercom';
import { SERVICE_DESK } from 'consts/support';
import { SANITIZE_CONFIG } from 'consts/sanitize';

import CustomField from './CustomField';
import AttachmentInput from './AttachmentInput';
import QuickGuides from './QuickGuides';

const DISABLED_FIELDS = ['customfield_14206'];

const YUP_MAP = {
  number: Yup.number(),
  option: Yup.object(),
  string: Yup.string().max(500, 'validation.length_exceeded'),
};

const customFieldValidationObject = requestTypeFields => {
  const o = {};
  requestTypeFields.forEach(field => {
    const {
      name,
      required,
      fieldId,
      jiraSchema: { type },
    } = field;
    o[fieldId] = required ? YUP_MAP[type].required('validation.cant_be_empty') : YUP_MAP[type];
    if (name && (name.toLowerCase().includes('uri') || name.toLowerCase().includes('link'))) {
      o[fieldId] = o[fieldId].url('validation.must_be_uri');
    }
  });
  return o;
};

const generateInitialValues = (customFields, bid) => {
  const initialValues = {
    summary: '',
    description: '',
    temporaryAttachments: [],
  };

  customFields.forEach(f => {
    initialValues[f.fieldId] = '';
    if (f.name === 'BID' && bid) {
      initialValues[f.fieldId] = bid;
    }
  });

  return initialValues;
};

const CreateSupportRequestModal = ({
  serviceDesk,
  clearRequests,
  requestType,
  handleCreateRequestSuccess,
  bid,
  polyglot,
  isFromMMB,
  closeModal,
}) => {
  const [postRequestStatus, setPostRequestStatus] = useState(STATUS.CLEAR);
  const [errorMessage, setErrorMessage] = useState('');

  const customFields = requestType.requestTypeFields.filter(
    f => f.fieldId && f.fieldId.startsWith('customfield'),
  );

  const initialValues = useMemo(
    () => generateInitialValues(customFields, bid),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const CREATE_REQUEST_SCHEMA = Yup.object({
    summary: Yup.string()
      .max(500, 'validation.length_exceeded')
      .required('validation.cant_be_empty'),
    description: Yup.string()
      .max(2500, 'validation.length_exceeded')
      .required('validation.cant_be_empty'),
  }).shape(customFieldValidationObject(customFields));

  const submitForm = values => {
    values.description = sanitizeHtml(values.description, SANITIZE_CONFIG);
    if (serviceDesk === SERVICE_DESK.BPSD) {
      fireDocsEvent({
        event: 'tequila.help.createRequestModal.formSubmitted',
        requestType: requestType.name,
        page: window.location.pathname,
      });
    }

    const body = {
      requestTypeId: requestType.id,
      requestFieldValues: values,
    };

    setPostRequestStatus(STATUS.LOADING);

    proxy.v1
      .post(`jira/${serviceDesk}/request`, JSON.stringify(body))
      .then(res => {
        handleCreateRequestSuccess();
        clearRequests();
        if (serviceDesk === SERVICE_DESK.BPSD) {
          try {
            Intercom.trackEvent('created-help-request', {
              'Issue Key': res.data.request.issueKey,
            });
          } catch (_) {}
        } else {
          try {
            Intercom.trackEvent('created-customer-support-request', {
              'Issue Key': res.data.request.issueKey,
              isMMB: !!isFromMMB,
            });
          } catch (_) {}
        }
      })
      .catch(error => {
        if (error.response?.data?.message?.includes('BID')) {
          setErrorMessage(error.response.data.message);
        }
        setPostRequestStatus(STATUS.ERROR);
      });
  };

  return (
    <Modal closable onClose={closeModal}>
      <ModalSection>
        <Heading type="title2" element="h2" spaceAfter="medium">
          {requestType.name}
        </Heading>
        <Text spaceAfter="medium">{requestType.description}</Text>

        {serviceDesk === SERVICE_DESK.BPSD && <QuickGuides requestType={requestType} />}

        {postRequestStatus === STATUS.ERROR && (
          <Alert type="critical" spaceAfter="large">
            {errorMessage || polyglot.t('support.createRequestModal.error')}
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={CREATE_REQUEST_SCHEMA}
          onSubmit={submitForm}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors, touched }) => (
            <Form>
              <InputField
                name="summary"
                label={polyglot.t('common.summary')}
                onChange={handleChange}
                value={values.summary}
                error={touched.summary && errors.summary && polyglot.t(errors.summary)}
                spaceAfter="large"
                dataTest="summary"
              />
              <Textarea
                name="description"
                label={polyglot.t('common.description')}
                rows={10}
                resize="none"
                onChange={handleChange}
                value={values.description}
                error={touched.description && errors.description && polyglot.t(errors.description)}
                spaceAfter="large"
                dataTest="description"
              />
              {requestType.requestTypeFields
                .filter(
                  field =>
                    field.fieldId.startsWith('customfield') &&
                    !DISABLED_FIELDS.includes(field.fieldId),
                )
                .map(field => {
                  const { fieldId, name } = field;
                  const isReadOnly = name === 'BID' && bid;

                  return (
                    <CustomField
                      key={fieldId}
                      label={polyglot.t(`support.createRequestModal.${fieldId}`, {
                        _: name,
                      })}
                      value={values[fieldId]}
                      fieldDetails={field}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      error={touched[fieldId] && errors[fieldId] && polyglot.t(errors[fieldId])}
                      dataTest={name.split(' ').join('-').toLowerCase()}
                      readOnly={isReadOnly}
                      disabled={isReadOnly}
                    />
                  );
                })}
              <AttachmentInput
                title={polyglot.t('support.createRequestModal.attachments')}
                uploadButtonText={polyglot.t('support.createRequestModal.uploadButton')}
                setFieldValue={setFieldValue}
                temporaryAttachments={values.temporaryAttachments}
                serviceDesk={serviceDesk}
                spaceAfter="large"
                dataTest="attachments"
              />
              <Stack direction="row" justify="end">
                <Stack grow={false} shrink basis="20%">
                  <Button
                    fullWidth
                    type="secondary"
                    onClick={closeModal}
                    disabled={postRequestStatus === STATUS.LOADING}
                    dataTest="cancel-button"
                  >
                    {polyglot.t('common.cancel')}
                  </Button>
                </Stack>
                <Stack grow={false} shrink basis="25%">
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    loading={postRequestStatus === STATUS.LOADING}
                    dataTest="send-button"
                  >
                    {polyglot.t('common.send')}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </ModalSection>
    </Modal>
  );
};

CreateSupportRequestModal.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  closeModal: PropTypes.func.isRequired,
  requestType: PropTypes.shape(RequestType).isRequired,
  serviceDesk: PropTypes.string.isRequired,
  clearRequests: PropTypes.func.isRequired,
};

export default CreateSupportRequestModal;
