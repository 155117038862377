import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';

import { Table, TableHead, TableCell, TableRow, Flex } from 'common';
import { CreateFilterRow, FilterRow } from 'pages/Reports/components/Filters';
import ReportFilterRow from 'shapes/ReportFilterRow';
import Polyglot from 'shapes/Polyglot';

import { IconCell, FilterCell, ButtonCell, TablePlaceholder } from './common.styled';

class ReportFilters extends Component {
  state = {
    isCreateFilterRowVisible: false,
  };

  addCreateFilterLine = () => {
    const { isCreateFilterRowVisible } = this.state;
    if (!isCreateFilterRowVisible) {
      this.setState({ isCreateFilterRowVisible: true });
    }
  };

  removeCreateFilterLine = () => this.setState({ isCreateFilterRowVisible: false });

  handleSaveRow = (newFilter, selectedValues) => {
    const { onChange } = this.props;
    this.setState({ isCreateFilterRowVisible: false });
    onChange(newFilter, selectedValues);
  };

  handleUpdateRow = (updatedFilter, selectedValues) =>
    this.props.onChange(updatedFilter, selectedValues);

  handleDeleteRow = deletedFilter => this.props.onChange(deletedFilter);

  render() {
    const { filterRows, polyglot } = this.props;
    const { isCreateFilterRowVisible } = this.state;

    const takenFilters = filterRows.map(filterRow => filterRow.filter.value);

    return (
      <div>
        <Flex main="spaceBetween">
          <Text type="secondary">{polyglot.t('common.filters')}</Text>
          <Button
            size="small"
            type="secondary"
            onClick={this.addCreateFilterLine}
            iconLeft={<PlusIcon />}
          >
            {polyglot.t('reports.add_filter')}
          </Button>
        </Flex>
        <Table>
          <TableHead>
            <TableRow>
              <IconCell />
              <FilterCell>{polyglot.t('common.type')}</FilterCell>
              <TableCell>{polyglot.t('common.value')}</TableCell>
              <ButtonCell />
              <ButtonCell />
            </TableRow>
          </TableHead>
          {[
            ...filterRows.map((filterRow, i) => (
              <FilterRow
                filter={filterRow.filter}
                selectedValues={filterRow.selectedValues}
                onUpdateRow={this.handleUpdateRow}
                onDeleteRow={this.handleDeleteRow}
                companyName={this.props.companyName}
                key={i}
              />
            )),
            isCreateFilterRowVisible && (
              <CreateFilterRow
                takenFilters={takenFilters}
                onSaveRow={this.handleSaveRow}
                onDeleteRow={this.removeCreateFilterLine}
                companyName={this.props.companyName}
                key="report-filters-create-row"
              />
            ),
          ]}
        </Table>
        {R.isEmpty(filterRows) && !isCreateFilterRowVisible && (
          <TablePlaceholder>{polyglot.t('reports.no_filters')}</TablePlaceholder>
        )}
      </div>
    );
  }
}

ReportFilters.propTypes = {
  filterRows: PropTypes.arrayOf(PropTypes.shape(ReportFilterRow)).isRequired,
  onChange: PropTypes.func.isRequired,
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

export default ReportFilters;
