import React, { useEffect } from 'react';

import { useToggle } from 'utils/hooks';
import { WIDGET } from 'consts/solutions';

import SolutionsModal from 'components/scenes/MySolutions/components/SolutionsModal';
import CreateSolutionProvider from 'components/scenes/MySolutions/services/CreateSolution';

import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import { usePolyglot } from 'components/services/i18n';

const CreateWidgetSolutionModal = ({ closeMe, setSuccessCreatingWidgetSolutionAlert }) => {
  const show = useToggle(false);

  useEffect(() => {
    show.setOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuccess = widgetSolutionName => {
    setSuccessCreatingWidgetSolutionAlert(
      <SuccessCreatingWidgetSolutionAlert
        widgetSolutionName={widgetSolutionName}
        onClose={() => setSuccessCreatingWidgetSolutionAlert(null)}
      />,
    );
    setTimeout(() => setSuccessCreatingWidgetSolutionAlert(null), 7000);
    closeMe();
  };

  return (
    <CreateSolutionProvider preselectedStep={WIDGET}>
      {show.isOn && (
        <SolutionsModal hideBackButton={true} onClose={closeMe} onSuccess={handleSuccess} />
      )}
    </CreateSolutionProvider>
  );
};

export default CreateWidgetSolutionModal;

const SuccessCreatingWidgetSolutionAlert = ({ widgetSolutionName, onClose }) => {
  const polyglot = usePolyglot();

  return (
    <Alert
      type="success"
      spaceAfter="normal"
      icon
      closable
      title={
        <Text type="attention">
          {polyglot.t('widgets.success_create_solution')}{' '}
          <Text as="span" weight="bold">
            {widgetSolutionName}
          </Text>
          .
        </Text>
      }
      onClose={onClose}
    >
      {polyglot.t('widgets.success_create_solution_select')}
    </Alert>
  );
};
