import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import OrbitCheckbox from '@kiwicom/orbit-components/lib/Checkbox';

const Checkbox = ({ name, ...props }) => {
  const { errors, control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <OrbitCheckbox
          {...props}
          name={name}
          checked={value}
          error={errors?.[name]?.message ?? null}
          onChange={e => {
            onChange(e.target.checked);
          }}
        />
      )}
    />
  );
};

export default Checkbox;
