import React from 'react';

import Modal, { ModalFooter, ModalHeader } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';

const CloseModal = ({ closeModal, closeDrawer }) => {
  const polyglot = usePolyglot();
  const { resetSeatings } = useSeating();

  const handleOnClose = () => {
    resetSeatings();
    closeModal();
    closeDrawer();
  };

  return (
    <Modal size="small" onClose={closeModal}>
      <ModalHeader
        description={polyglot.t('booking_tool.seating.drawer.close_modal.header.description')}
        title={polyglot.t('booking_tool.seating.drawer.close_modal.header.title')}
      ></ModalHeader>
      <ModalFooter>
        <Button onClick={handleOnClose} type="critical">
          {polyglot.t('common.discard_changes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CloseModal;
