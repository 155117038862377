import { getBrandFromProduct } from 'utils/products';

const direct_get_to = (bookingToken, apiKey, product) => ({
  headers: { apikey: apiKey },
  params: {
    language: 'en-GB',
    booking_token: bookingToken,
    platform: 'web',
    brand: getBrandFromProduct(product),
  },
});

const direct_get_from = prebookingConfigData => ({
  banners: prebookingConfigData.banners,
  lightweightItinerary: {
    bookableUntilUTC: prebookingConfigData.lightweight_itinerary.bookable_until_utc,
    layover: prebookingConfigData.lightweight_itinerary.layover,
    sectors: prebookingConfigData.lightweight_itinerary.sectors.map(sector => ({
      id: sector.id,
      departure: {
        ...sector.departure,
        place: {
          city: sector.departure.place.city,
          country: sector.departure.place.country,
          id: sector.departure.place.id,
        },
      },
      arrival: {
        ...sector.arrival,
        place: {
          city: sector.arrival.place.city,
          country: sector.arrival.place.country,
          id: sector.arrival.place.id,
        },
      },
      segments: sector.segments.map(segment => ({
        id: segment.id,
        departure: {
          ...segment.departure,
          place: {
            city: segment.departure.place.city,
            country: segment.departure.place.country,
            id: segment.departure.place.id,
          },
        },
        arrival: {
          ...segment.arrival,
          place: {
            city: segment.arrival.place.city,
            country: segment.arrival.place.country,
            id: segment.arrival.place.id,
          },
        },
        fareCategory: segment.fare.category,
        carrier: {
          code: segment.carrier.code,
          name: segment.carrier.name,
          segmentCode: segment.carrier.segment_code,
        },
        vehicleType: segment.vehicle_type,
      })),
    })),
  },
});

export default {
  direct: {
    get: {
      to: direct_get_to,
      from: direct_get_from,
    },
  },
};
