import React from 'react';

import Select from '@kiwicom/orbit-components/lib/Select';

import { usePolyglot } from 'components/services/i18n';
import useSearch from 'components/services/search/useSearch';
import { selectableAvailableSearchCurrencies } from 'consts/search';

import { Wrapper } from './../index.styled';

const Currency = () => {
  const polyglot = usePolyglot();
  const { tabData, changeCurrency } = useSearch();
  return (
    <Wrapper>
      <Select
        label={polyglot.t('common.currency')}
        options={selectableAvailableSearchCurrencies}
        onChange={e => changeCurrency(e.target.value)}
        value={tabData.currency}
        spaceAfter="medium"
      />
    </Wrapper>
  );
};

export default Currency;
