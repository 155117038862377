import * as R from 'ramda';
import * as dateConstants from 'consts/dates';

export const SHOW_VALUES = {
  affiliate: {
    k: 'reports.affiliate',
    value: 'affiliate',
  },
  clicks: {
    k: 'reports.clicks',
    value: 'clicks',
  },
  bookings: {
    k: 'reports.bookings',
    value: 'bookings',
  },
  sales: {
    k: 'reports.sales',
    value: 'sales',
  },
  payout: {
    k: 'reports.payout',
    value: 'payout',
  },
  profit: {
    k: 'reports.profit',
    value: 'profit',
  },
  revenue: {
    k: 'reports.revenue',
    value: 'revenue',
  },
  market: {
    k: 'market.market',
    value: 'market',
  },
  device: {
    k: 'reports.device',
    value: 'device',
  },
  platform: {
    k: 'reports.platform',
    value: 'platform',
  },
  conversionRate: {
    k: 'reports.conversion_rate',
    value: 'conversionRate',
  },
  payoutType: {
    k: 'reports.payout_type_label',
    value: 'payoutType',
  },
  cpsFlat: {
    k: 'reports.cps_flat',
    value: 'cpsFlat',
  },
  cpsPercent: {
    k: 'reports.cps_percent',
    value: 'cpsPercent',
  },
  cpcFlat: {
    k: 'reports.cpc_flat',
    value: 'cpcFlat',
  },
  legCount: {
    k: 'reports.leg_count',
    value: 'legCount',
  },
  routeType: {
    k: 'reports.route_type',
    value: 'routeType',
  },
  bookedAt: {
    k: 'reports.booked_at',
    value: 'bookedAt',
  },
  bookingId: {
    k: 'reports.booking_id',
    value: 'bookingId',
  },
  bookingIdTimestamp: {
    k: 'reports.booking_id_timestamp',
    value: 'bookingIdTimestamp',
  },
  offerId: {
    k: 'reports.offer_id',
    value: 'offerId',
  },
  finalStatus: {
    k: 'reports.final_status',
    value: 'finalStatus',
  },
};

export const SHOW_VALUES_I18N = R.values(SHOW_VALUES);

// HACK ALERT - for time being, MILLENNIUM is set as value for None
// That will make the BE show all values ungrouped
export const INTERVAL_VALUES_I18N = [
  {
    k: 'common.none',
    value: dateConstants.MILLENNIUM,
  },
  {
    k: 'common.hour',
    value: dateConstants.HOUR,
  },
  {
    k: 'common.day',
    value: dateConstants.DAY,
  },
  {
    k: 'common.week',
    value: dateConstants.WEEK,
  },
  {
    k: 'common.month',
    value: dateConstants.MONTH,
  },
  {
    k: 'common.year',
    value: dateConstants.YEAR,
  },
];

/**
 * Predefined values for filters should either be strings or objects with these two properties
 */
export const FILTER_LABEL_FIELD = 'label';
export const FILTER_VALUE_FIELD = 'value';

export const FILTER_NAMES_I18N = {
  PLATFORM: { k: 'reports.platform', value: 'platform' },
  DEVICE: { k: 'reports.device', value: 'device' },
  AFFILIATE: { k: 'reports.affiliate', value: 'affiliate' },
  MARKET: { k: 'market.market', value: 'market' },
  ROUTE_TYPE: { k: 'reports.route_type', value: 'routeType' },
};

export const FILTER_NAMES_VALUES = R.values(FILTER_NAMES_I18N);

/**
 * Filters with predefined values
 */
export const FILTER_VALUES = {
  [FILTER_NAMES_I18N.PLATFORM.value]: [
    'Android',
    'BlackBerry OS',
    'Chrome OS',
    'Fedora',
    'Firefox OS',
    'FreeBSD',
    'iOS',
    'Linux',
    'Mac OS X',
    'Maemo',
    'MeeGo',
    'NetBSD',
    'OpenBSD',
    'Other',
    'Solaris',
    'SUSE',
    'Symbian OS',
    'Tizen',
    'Ubuntu',
    'Windows',
    'Windows 10',
    'Windows 2000',
    'Windows 7',
    'Windows 8',
    'Windows 8.1',
    'Windows 95',
    'Windows NT 4.0',
    'Windows Phone',
    'Windows RT',
    'Windows RT 8.1',
    'Windows Vista',
    'Windows XP',
  ],
  [FILTER_NAMES_I18N.DEVICE.value]: ['mobile', 'tablet', 'pc', 'bot'],
  [FILTER_NAMES_I18N.ROUTE_TYPE.value]: [
    { k: 'common.route_one_way', value: 'oneway' },
    { k: 'common.route_round_trip', value: 'roundtrip' },
    { k: 'common.route_multi_city', value: 'multicity' },
    { k: 'common.route_openjaw', value: 'openjaw' },
    { k: 'common.route_other', value: 'other' },
    { k: 'common.route_travellingsalesman', value: 'travellingsalesman' },
    { k: 'common.route_nomad', value: 'nomad' },
  ],
};

export const FILTER_SEARCH_LIST_LENGTH = 40;

// report metrics which trigger a calculation of a full report
export const FULL_REPORT_METRICS = [
  'device',
  'platform',
  'routeType',
  'bookedAt',
  'bookingId',
  'bookingIdTimestamp',
  'finalStatus',
  'payoutType',
  'cpsFlat',
  'cpsPercent',
  'cpcFlat',
  'legCount',
];

export const DEFAULT_SHOW_VALUES_ON_CREATE = ['payout', 'bookings', 'sales'];

export const SORTABLE_PARAMETERS = [
  'affiliate',
  'clicks',
  'bookings',
  'sales',
  'payout',
  'profit',
  'revenue',
  'market',
  'device',
  'platform',
  'conversionRate',
  'payoutType',
  'cpsFlat',
  'cpsPercent',
  'cpcFlat',
  'legCount',
  'routeType',
  'bookedAt',
  'bookingId',
  'bookingIdTimestamp',
  'offerId',
  'interval',
];

export const FULL_REPORT_POLL_INTERVAL = 5000;

// LocalStorage key for map reportId:reportAlias
export const FULL_REPORTS_MAP_KEY = 'fullReportsMap';

export const METRICS_SNAKE_CAMEL_CASE_MAPPING = [
  {
    snakeCase: 'conversion_rate',
    camelCase: 'conversionRate',
  },
  {
    snakeCase: 'payout_type',
    camelCase: 'payoutType',
  },
  {
    snakeCase: 'cps_flat',
    camelCase: 'cpsFlat',
  },
  {
    snakeCase: 'cps_percent',
    camelCase: 'cpsPercent',
  },
  {
    snakeCase: 'cpc_flat',
    camelCase: 'cpcFlat',
  },
  {
    snakeCase: 'leg_count',
    camelCase: 'legCount',
  },
  {
    snakeCase: 'route_type',
    camelCase: 'routeType',
  },
  {
    snakeCase: 'booked_at',
    camelCase: 'bookedAt',
  },
  {
    snakeCase: 'booking_id',
    camelCase: 'bookingId',
  },
  {
    snakeCase: 'booking_id_timestamp',
    camelCase: 'bookingIdTimestamp',
  },
  {
    snakeCase: 'offer_id',
    camelCase: 'offerId',
  },
  {
    snakeCase: 'final_status',
    camelCase: 'finalStatus',
  },
];

export const REPORT_STATUS = {
  INPROGRESS: 'inprogress',
  AVAILABLE: 'available',
};

export const REPORT_ALIAS_TYPES = {
  default: 'default',
  draft: 'draft',
  temp: 'temp',
};
