import React, { useMemo } from 'react';

import useCompanyNames from 'components/services/companies/useCompanyNames';
import { usePolyglot } from 'components/services/i18n';
import { Autocomplete } from 'common';

const SelectCompanyName = ({ onSelect, value }) => {
  const polyglot = usePolyglot();
  const companyNames = useCompanyNames();
  const companyOptions = useMemo(() => {
    return (companyNames.data || []).map(name => ({
      value: name,
      label: name,
    }));
  }, [companyNames]);
  return (
    <Autocomplete
      options={companyOptions}
      onSelect={companyName => {
        onSelect(companyName.value);
      }}
      value={value}
      label={polyglot.t('widgets.list.company_select')}
      noResultsText={polyglot.t('common.select_app_company.no_results_text')}
      size="normal"
    />
  );
};

export default SelectCompanyName;
