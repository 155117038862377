import React, { useEffect, useLayoutEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@kiwicom/orbit-components/lib/Button';
import Desktop from '@kiwicom/orbit-components/lib/Desktop';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Text from '@kiwicom/orbit-components/lib/Text';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { PageTitle } from 'common';
import useMenuState from 'components/services/menu/useMenuState';
import { AuthState, TOO_MANY_LOGIN_ATTEMPTS_ERROR } from 'components/services/auth/AuthProvider';
import { getInitialOnboardingValue } from 'components/services/localStorage/useOnboardingState';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { NEXT_GEN_AUTH_COOKIE_NAME } from 'consts/index';
import { useToggle } from 'utils/hooks';
import * as nextGenApi from 'utils/api';
import { getCookie } from 'utils/cookie';
import { fireRegistrationEvent } from 'utils/ga';

import ForgotPasswordModal from './ForgotPasswordModal';
import ForbiddenAccessModal from './ForbiddenAccessModal';
import {
  EmailContainer,
  PasswordContainer,
  ErrorMessage,
  ActionsContainer,
  RememberMeContainer,
  LoginButtonWrapper,
  ForgotPassword,
  RegisterContainer,
  LoginButtonContainer,
} from './index.styled';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('validation.not_valid_email')
    .max(2048, 'validation.too_long')
    .required('validation.cant_be_empty'),
  password: Yup.string().required('validation.cant_be_empty').max(512, 'validation.too_long'),
});

const Login = props => {
  const authCtx = useContext(AuthState);
  const polyglot = usePolyglot();
  const { isDesktop } = useMediaQuery();

  useLayoutEffect(() => {
    const nextGenToken = getCookie(NEXT_GEN_AUTH_COOKIE_NAME);

    if (nextGenToken) {
      nextGenApi.setAuthToken(nextGenToken);
      authCtx.getSession();
    }
    return () => {
      authCtx.hideRegisterInfoBox();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forgotPasswordModal = useToggle();

  const menuCtx = useMenuState();

  useEffect(() => {
    if (!authCtx.user) {
      return;
    }
    const { location, history } = props;

    const { from } = location.state || {
      from: { pathname: ROUTE_CONFIG.GETTING_STARTED.path },
    };

    const onboarding =
      JSON.parse(localStorage.getItem(`${authCtx.user?.email}_onboarding`)) ||
      getInitialOnboardingValue(authCtx.user);

    if (onboarding && !onboarding.done) {
      history.push(ROUTE_CONFIG.GETTING_STARTED.path);
      return;
    }

    if (from.pathname === '/') {
      history.push(`${ROUTE_CONFIG.COMPANY.toPath}/${authCtx.user.companyName}`);
      return;
    }

    if (from.pathname === ROUTE_CONFIG.GETTING_STARTED.path) {
      history.push(`${ROUTE_CONFIG.COMPANY.toPath}/${authCtx.user.companyName}`);
      return;
    }

    if (!authCtx.loggingIn && !authCtx.loginError && authCtx.user) {
      history.push(from.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCtx.loggingIn, authCtx.user]);

  const handleLoginSubmit = async values => {
    fireRegistrationEvent('Login', values.email);

    try {
      authCtx.login(values.email, values.password, values.rememberMe);
    } catch (error) {}
  };

  return (
    <>
      {forgotPasswordModal.isOn && <ForgotPasswordModal onClose={forgotPasswordModal.setOff} />}
      <div>
        <PageTitle label="common.login" />

        {authCtx.showRegisterInfoBox && (
          <Alert type="info" spaceAfter="large" closable onClose={authCtx.hideRegisterInfoBox}>
            {polyglot.t('login.verify_account')}
          </Alert>
        )}
        {authCtx.showActivationSuccessBox && (
          <Alert
            type="success"
            spaceAfter="large"
            closable
            onClose={authCtx.hideActivateSuccessBox}
          >
            {polyglot.t('login.activation_success')}
          </Alert>
        )}
        {authCtx.showRequestPasswordResetSuccessBox && (
          <Alert
            type="success"
            spaceAfter="large"
            closable
            onClose={authCtx.hideRequestResetPasswordBox}
          >
            {polyglot.t('reset_password.password_reset_request_info')}
          </Alert>
        )}
        {authCtx.showPasswordResetSuccessBox && (
          <Alert type="success" spaceAfter="large" closable onClose={authCtx.hideResetPasswordBox}>
            {polyglot.t('reset_password.password_reset_success')}
          </Alert>
        )}
        {authCtx.showTooManyLoginAttemptsError && (
          <Alert
            icon={<InformationCircleIcon />}
            title={polyglot.t('login.too_many_failed_attempts_title')}
            type="critical"
            spaceAfter="large"
            closable
            onClose={authCtx.hideTooManyLoginAttemptsError}
          >
            {polyglot.t('login.too_many_failed_attempts_description')}
          </Alert>
        )}
        {authCtx.showForbiddenAccessModal && (
          <ForbiddenAccessModal onClose={authCtx.toggleForbiddenAccessModal} />
        )}
        <Formik
          initialValues={{
            email: '',
            password: '',
            rememberMe: false,
          }}
          validationSchema={loginSchema}
          onSubmit={handleLoginSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <EmailContainer>
                <InputField
                  type="email"
                  id="email"
                  name="email"
                  label={polyglot.t('common.email')}
                  onChange={handleChange}
                  value={values.email}
                  error={touched.email && errors.email && polyglot.t(errors.email)}
                  dataTest="login-email"
                />
              </EmailContainer>
              <PasswordContainer>
                <InputField
                  id="password"
                  name="password"
                  label={polyglot.t('common.password')}
                  type="password"
                  onChange={handleChange}
                  value={values.password}
                  error={touched.password && errors.password && polyglot.t(errors.password)}
                  dataTest="login-password"
                />
              </PasswordContainer>

              {authCtx.loginError &&
                authCtx.loginError.error_code !== TOO_MANY_LOGIN_ATTEMPTS_ERROR && (
                  <ErrorMessage>{polyglot.t(authCtx.loginError.message)}</ErrorMessage>
                )}

              <ActionsContainer>
                {isDesktop && (
                  <div>
                    <RememberMeContainer>
                      {menuCtx.isNavigationShown && (
                        <Checkbox
                          id="login-remember-me"
                          name="rememberMe"
                          onChange={handleChange}
                          checked={values.rememberMe}
                          label={polyglot.t('login.remember_me')}
                        />
                      )}
                    </RememberMeContainer>
                  </div>
                )}

                <LoginButtonContainer>
                  <LoginButtonWrapper>
                    <Button
                      width="128px"
                      fullWidth={!isDesktop}
                      onClick={handleSubmit}
                      disabled={authCtx.loggingIn}
                      loading={authCtx.loggingIn}
                    >
                      {polyglot.t('common.login')}
                    </Button>
                  </LoginButtonWrapper>

                  <ForgotPassword>
                    <TextLink onClick={forgotPasswordModal.setOn} type="secondary">
                      {polyglot.t('common.forgot_password')}
                    </TextLink>
                  </ForgotPassword>

                  <Desktop>
                    <RegisterContainer>
                      {polyglot.t('common.get_in_touch.text')}{' '}
                      <TextLink external href="mailto:affiliates@kiwi.com">
                        affiliates@kiwi.com
                      </TextLink>
                    </RegisterContainer>
                  </Desktop>
                </LoginButtonContainer>
              </ActionsContainer>
            </div>
          )}
        </Formik>
      </div>
      <Text>
        Google{' '}
        <TextLink type="primary" external href="https://policies.google.com/privacy">
          Privacy Policy
        </TextLink>{' '}
        and{' '}
        <TextLink type="primary" external href="https://policies.google.com/terms">
          Terms of Service
        </TextLink>{' '}
        apply.
      </Text>
    </>
  );
};

export default Login;
