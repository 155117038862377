import React from 'react';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';

import { usePolyglot } from 'components/services/i18n';

const Container = styled.div`
  margin-top: 48px;
`;

const TableContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.orbit.paletteInkLighter};
  padding: 8px 8px 8px 12px;
  min-height: 203px;
  max-height: 203px;
  overflow: auto;
  box-sizing: border-box;
`;

const IPRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

const IPRow = ({ value, onRemove }) => {
  return (
    <IPRowContainer>
      <Text>{value}</Text>

      <Button type="criticalSubtle" size="small" iconLeft={<RemoveIcon />} onClick={onRemove} />
    </IPRowContainer>
  );
};

const AddedAddressesTable = ({ values, onRemove }) => {
  const polyglot = usePolyglot();

  return (
    <Container>
      <Text spaceAfter="smallest">{polyglot.t('allowlist.added_addresses')}</Text>

      <TableContainer>
        {values.map(ip => (
          <IPRow key={ip} value={ip} onRemove={() => onRemove(ip)} />
        ))}
      </TableContainer>
    </Container>
  );
};

export default AddedAddressesTable;
