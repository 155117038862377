import React, { useReducer } from 'react';

import * as Intercom from 'utils/intercom';
import { sortComparator } from 'utils/string';
import api, { getAndPaginateAll, getUrlWithQuery } from 'utils/api';
import companySolutionMapper from 'mappers/nextGen/companies/{company_name}/applications/_';
import companiesSolutionsMapper from 'mappers/nextGen/applications/_';
import solutionDisableMapper from 'mappers/nextGen/applications/{application_uuid}/disable/_';
import solutionEnableMapper from 'mappers/nextGen/applications/{application_uuid}/enable/_';
import solutionEditMapper from 'mappers/nextGen/applications/{application_uuid}/_';
import useApiProducts from 'components/services/products/useApiProducts';

const defaultState = {
  solutions: {
    data: null,
    loading: false,
    error: null,
  },
};

export const SolutionsState = React.createContext({
  state: {},
  singleAppsState: {},
  loadSolutions: async searchQuery => {},
  loadCompanySolutions: async companyName => companyName,
  disableSolution: async (company, solution, reason) => [company, solution, reason],
  enableSolution: async (companyName, solution) => [companyName, solution],
  createSolution: async (companyName, solution) => [companyName, solution],
  editSolution: async (solutionName, solutionDetails, canEditQuota) => [
    solutionName,
    solutionDetails,
    canEditQuota,
  ],
  loadSolutionDetails: async affilId => [affilId],
  bulkDisableAffilIds: async affilIds => [affilIds],
});

const solutionsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SOLUTIONS':
      return {
        ...state,
        solutions: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case 'LOAD_SOLUTIONS_SUCCESS':
      return {
        ...state,
        solutions: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case 'LOAD_SOLUTIONS_FAIL':
      return {
        ...state,
        solutions: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    case 'LOAD_COMPANY_SOLUTIONS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_SOLUTIONS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.apps,
        },
      };
    case 'LOAD_COMPANY_SOLUTIONS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'BULK_DISABLE_AFFILID':
      return {
        ...state,
        disablingBulk: true,
      };
    case 'BULK_DISABLE_AFFILID_DONE':
      return {
        ...state,
        disablingBulk: false,
        failedBulkDisable: action.rejected,
        passedBulkDisable: action.disabledAffilidIds,
      };
    case 'DISABLE_SOLUTION':
      return {
        ...state,
        disabling: true,
        disableError: null,
      };
    case 'DISABLE_SOLUTION_SUCCESS':
      return {
        ...state,
        disabling: false,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.map(app =>
            app.name === action.app.name ? action.app : app,
          ),
        },
      };
    case 'DISABLE_SOLUTION_FAIL':
      return {
        ...state,
        disabling: false,
        disableError: action.error,
      };
    case 'ENABLE_SOLUTION':
      return {
        ...state,
        enablingSolutionName: action.solution,
        enableError: null,
      };
    case 'ENABLE_SOLUTION_SUCCESS':
      return {
        ...state,
        enablingSolutionName: null,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.map(sol =>
            sol.name === action.sol.name ? action.sol : sol,
          ),
        },
      };
    case 'ENABLE_SOLUTION_FAIL':
      return {
        ...state,
        enablingSolutionName: null,
        enableError: action.error,
      };
    case 'CREATE_SOLUTION':
      return {
        ...state,
        creating: true,
        createError: null,
      };
    case 'CREATE_SOLUTION_SUCCESS':
      if (state[action.companyName] && state[action.companyName].data) {
        return {
          ...state,
          creating: false,
          [action.companyName]: {
            ...state[action.companyName],
            data: state[action.companyName].data
              .concat(action.application)
              .sort(sortComparator(x => x.name)),
          },
        };
      }

      return {
        ...state,
        creating: false,
      };
    case 'CREATE_SOLUTION_FAIL':
      return {
        ...state,
        creating: false,
        createError: action.error,
      };
    default:
      return state;
  }
};

const singleSolutionReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SOLUTION':
      return {
        ...state,
        [action.affilId]: {
          ...(state[action.affilId] ? state[action.affilId] : { data: null }),
          loading: true,
          error: null,
        },
      };
    case 'LOAD_SOLUTION_SUCCESS':
      return {
        ...state,
        [action.affilId]: {
          ...state[action.affilId],
          loading: false,
          data: action.application,
        },
      };
    case 'LOAD_SOLUTION_FAIL':
      return {
        ...state,
        [action.affilId]: {
          ...state[action.affilId],
          loading: false,
          error: action.error,
        },
      };
    case 'EDIT_SOLUTION':
      return {
        ...state,
        editing: true,
        editError: null,
      };
    case 'EDIT_SOLUTION_SUCCESS':
      if (state[action.affilId]) {
        return {
          ...state,
          [action.affilId]: {
            ...state[action.affilId],
            data: action.application,
          },
          editing: false,
        };
      }
      return {
        ...state,
        editing: false,
      };
    case 'EDIT_SOLUTION_FAIL':
      return {
        ...state,
        editing: false,
        editError: action.error,
      };
    default:
      return state;
  }
};

const SolutionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(solutionsReducer, defaultState);
  const [singleAppsState, singleAppsDispatch] = useReducer(singleSolutionReducer, {});
  const { data: apiProducts } = useApiProducts();

  const loadSolutions = async searchQuery => {
    dispatch({ type: 'LOAD_SOLUTIONS' });
    try {
      const query = getUrlWithQuery('', {
        q: searchQuery,
      });

      const {
        data: { data },
      } = await api.v1.get(`/applications${query}`);

      const solutions = data.map(app => companiesSolutionsMapper.v1.get.from(app, apiProducts));

      dispatch({ type: 'LOAD_SOLUTIONS_SUCCESS', payload: solutions });
    } catch (error) {
      dispatch({ type: 'LOAD_SOLUTIONS_FAIL', error });
    }
  };

  const loadCompanySolutions = async (companyName, searchQuery) => {
    dispatch({ type: 'LOAD_COMPANY_SOLUTIONS', companyName });

    try {
      const query = getUrlWithQuery('', {
        q: searchQuery,
      });

      const data = await getAndPaginateAll(
        'v1',
        'get',
        `companies/${companyName}/applications${query}`,
        200,
        {
          _order: 'name',
        },
      );
      const solutions = data.map(app => companySolutionMapper.v1.get.from(app, apiProducts));

      dispatch({ type: 'LOAD_COMPANY_SOLUTIONS_SUCCESS', companyName, apps: solutions });
    } catch (error) {
      dispatch({ type: 'LOAD_COMPANY_SOLUTIONS_FAIL', companyName, error });
      return Promise.reject(error);
    }
  };

  const disableSolution = async (companyName, solution, reason) => {
    dispatch({ type: 'DISABLE_SOLUTION' });

    try {
      const app = await api.v1.post(`applications/${solution.uuid}/disable`, {
        disabled_message: reason.replace(/\n/g, '<br />'),
      });

      const disabledApp = solutionDisableMapper.v1.post.from(app.data, apiProducts);

      dispatch({
        type: 'DISABLE_SOLUTION_SUCCESS',
        companyName,
        app: disabledApp,
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      dispatch({ type: 'DISABLE_SOLUTION_FAIL', errorMessage });

      return Promise.reject(errorMessage);
    }
  };

  const enableSolution = async (companyName, solution) => {
    dispatch({ type: 'ENABLE_SOLUTION', solution: solution.name });

    try {
      const app = await api.v1.post(`applications/${solution.uuid}/enable`, {});
      const enabledSol = solutionEnableMapper.v1.post.from(app.data, apiProducts);

      dispatch({
        type: 'ENABLE_SOLUTION_SUCCESS',
        companyName,
        sol: enabledSol,
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      dispatch({ type: 'ENABLE_SOLUTION_FAIL', error: errorMessage });

      return Promise.reject(errorMessage);
    }
  };

  const createSolution = async (companyName, solution) => {
    dispatch({ type: 'CREATE_SOLUTION', companyName });

    try {
      const app = await api.v1.post(
        `companies/${companyName}/applications`,
        companySolutionMapper.v1.post.to(solution),
      );
      const createdApp = companySolutionMapper.v1.post.from(app.data, apiProducts);

      try {
        Intercom.trackEvent('create-application', {
          Name: createdApp.name,
          'Product Type': createdApp.productType,
          'Affiliate ID': createdApp.affilId,
        });
      } catch (_) {}

      dispatch({
        type: 'CREATE_SOLUTION_SUCCESS',
        companyName,
        application: createdApp,
      });
    } catch (error) {
      dispatch({ type: 'CREATE_SOLUTION_FAIL', companyName, error });

      return Promise.reject(error);
    }
  };

  const editSolution = async (solution, solutionDetails, canEditQuota) => {
    singleAppsDispatch({ type: 'EDIT_SOLUTION', affilId: solution.affilId });

    try {
      const app = await api.v1.put(
        `applications/${solution.uuid}`,
        solutionEditMapper.v1.put.to(solutionDetails, canEditQuota),
      );
      const editedApp = solutionEditMapper.v1.put.from(app.data, apiProducts);

      singleAppsDispatch({
        type: 'EDIT_SOLUTION_SUCCESS',
        affilId: solution.affilId,
        application: editedApp,
      });
    } catch (error) {
      singleAppsDispatch({
        type: 'EDIT_SOLUTION_FAIL',
        affilId: solution.affilId,
        error,
      });

      return Promise.reject(error);
    }
  };

  const loadSolutionDetails = async affilId => {
    singleAppsDispatch({ type: 'LOAD_SOLUTION', affilId });

    try {
      const app = await api.v1.get(`applications/${affilId}`);
      const loadedApp = companySolutionMapper.v1.get.from(app.data, apiProducts);

      singleAppsDispatch({
        type: 'LOAD_SOLUTION_SUCCESS',
        affilId,
        application: loadedApp,
      });
    } catch (error) {
      singleAppsDispatch({ type: 'LOAD_SOLUTION_FAIL', affilId, error });

      return Promise.reject(error);
    }
  };

  const bulkDisableAffilIds = async (affilIds, reason) => {
    dispatch({ type: 'BULK_DISABLE_AFFILID' });
    const loadAffiIdsResponses = await Promise.allSettled(
      affilIds.map(affilId => api.v1.get(`applications/${affilId}`)),
    );

    let fulfilled = [];
    let rejected = [];
    for (let i = 0; i < affilIds.length; i++) {
      if (loadAffiIdsResponses[i].status === 'fulfilled') {
        fulfilled.push({
          affilId: affilIds[i],
          uuid: loadAffiIdsResponses[i].value.data.uuid,
          companyName: loadAffiIdsResponses[i].value.data.company_name,
        });
      } else {
        rejected.push({ affilId: affilIds[i], reason: loadAffiIdsResponses[i].reason });
      }
    }

    const disableAffilidIdsResponses = await Promise.allSettled(
      fulfilled.map(affilId =>
        api.v1.post(`applications/${affilId.uuid}/disable`, {
          disabled_message: reason.replace(/\n/g, '<br />'),
        }),
      ),
    );

    let disabledAffilidIds = [];

    for (let i = 0; i < fulfilled.length; i++) {
      if (disableAffilidIdsResponses[i].status === 'fulfilled') {
        disabledAffilidIds.push({
          affilId: fulfilled[i].affilId,
          companyName: fulfilled[i].companyName,
        });
      } else {
        rejected.push({
          affilId: fulfilled[i].affilId,
          reason: disableAffilidIdsResponses[i].reason,
        });
      }
    }

    disabledAffilidIds.forEach(affilId => loadCompanySolutions(affilId.companyName));

    dispatch({ type: 'BULK_DISABLE_AFFILID_DONE', disabledAffilidIds, rejected });
  };

  const value = {
    state,
    singleAppsState,
    loadSolutions,
    loadCompanySolutions,
    disableSolution,
    enableSolution,
    createSolution,
    editSolution,
    loadSolutionDetails,
    bulkDisableAffilIds,
  };

  return <SolutionsState.Provider value={value}>{children}</SolutionsState.Provider>;
};

export default SolutionsProvider;
