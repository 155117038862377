import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';

import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { ClickOutside } from 'common';
import { splitButKeepDeliminator } from 'utils/string';
import { filterValuesByLabelCaseInsensitive, getLastIndexInArray } from 'utils/array';
import { getPolyglot } from 'redux/selectors/i18n';
import { AutocompleteItem, AutocompleteValue } from 'shapes/Input';

import Item from './Item';
import AutocompleteMulti from './AutocompleteMulti';
import {
  AutocompleteList,
  ListContainer,
  EmptyResultsIconContainer,
  ItemIconContainer,
  ItemLabel,
} from './InputAutocomplete.styled';
import { AutocompleteGlobals } from './AutocompleteMulti.styled';

const formatText = (value, item) => {
  if (R.isEmpty(value)) {
    return item;
  }

  const chunks = splitButKeepDeliminator(item, value);

  return chunks.map((string, i) => {
    if (string.toLowerCase().includes(value.toLowerCase())) {
      // have to replace space with some special space, because brwoser won't render it
      return <strong key={i}>{string.replace(' ', '\xa0')}</strong>;
    }
    // ⬆️
    return string.replace(' ', '\xa0');
  });
};

/**
 * InputAutocomplete component's behaviour is same as autocompleter
 * e.g. you write text to the input and it will show to you list with available options
 * that can be selected
 */
class InputAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      itemsShown: R.isNil(props.displayedItems)
        ? this.getItemsWithoutSelectedItems(props.items, props.selectedItems)
        : props.displayedItems,
      itemFocused: 0,
      focusedChipIndex: null,
      isFocused: false,
    };
  }

  getItemsWithoutSelectedItems = (items, selectedItems) =>
    R.differenceWith(
      (item, selectedItem) => item.value === selectedItem.value,
      items,
      selectedItems,
    );

  UNSAFE_componentWillReceiveProps = nextProps => {
    const areSelectedItemsChanged = nextProps.selectedItems !== this.props.selectedItems;
    const areDisplayedItemsControlledAndChanged =
      nextProps.displayedItems && nextProps.displayedItems !== this.props.displayedItems;

    if (areSelectedItemsChanged || areDisplayedItemsControlledAndChanged) {
      this.setState({
        itemsShown:
          nextProps.displayedItems ||
          this.getFilteredItems(nextProps.text, nextProps.selectedItems),
      });
    }
  };

  handleChange = text => {
    let value = text.target ? text.target.value : text;

    const { selectedItems, filter, displayedItems } = this.props;
    if (filter && displayedItems) {
      filter(value);
    }

    this.setState({
      itemsShown: displayedItems || this.getFilteredItems(value, selectedItems),
      isFocused: true,
      itemFocused: 0,
      text: value,
      focusedChipIndex: null,
    });
  };

  handleFocus = () => {
    const { onFocus } = this.props;
    this.setState({ isFocused: true, focusedChipIndex: null });
    if (onFocus) {
      onFocus();
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    this.setState({ isFocused: false, focusedChipIndex: null });
    if (onBlur) {
      onBlur();
    }
  };

  handleInputClick = () => {
    this.setState({ focusedChipIndex: null });
  };

  handleArrowUp = () => {
    this.setState(state => ({
      itemFocused: state.itemFocused - 1,
    }));
  };

  handleArrowDown = () => {
    this.setState(state => ({
      itemFocused: state.itemFocused + 1,
    }));
  };

  handleArrowLeft = ev => {
    const inputCaretPosition = ev.target.selectionStart;
    const { text, focusedChipIndex } = this.state;
    if (inputCaretPosition === text.length && !this.areChipsFocused() && !R.isEmpty(text)) {
      return;
    }
    if (focusedChipIndex !== 0) {
      ev.preventDefault();
    }
    this.handleChipNavigatingLeft();
  };

  handleArrowRight = ev => {
    const inputCaretPosition = ev.target.selectionStart;
    const { text, focusedChipIndex } = this.state;
    const { selectedItems } = this.props;
    if (inputCaretPosition === 0 && !this.areChipsFocused() && !R.isEmpty(text)) {
      return;
    }

    if (focusedChipIndex !== getLastIndexInArray(selectedItems)) {
      ev.preventDefault();
    }
    this.handleChipNavigatingRight();
  };

  handleBackspace = ev => {
    const inputCaretPosition = ev.target.selectionStart;
    const { focusedChipIndex } = this.state;
    const { selectedItems, handleRemove } = this.props;
    if (inputCaretPosition !== 0 && !this.areChipsFocused()) {
      return;
    }
    ev.preventDefault();
    handleRemove(selectedItems[focusedChipIndex] || R.last(selectedItems));
  };

  handleChipNavigatingLeft = () => {
    const { focusedChipIndex } = this.state;
    const { selectedItems } = this.props;
    const lastIndexOfSelectedItems = getLastIndexInArray(selectedItems);
    if (
      this.areChipsFocused() &&
      focusedChipIndex > 0 &&
      focusedChipIndex <= lastIndexOfSelectedItems
    ) {
      this.setState({ focusedChipIndex: focusedChipIndex - 1 });
    } else if (focusedChipIndex === 0) {
      this.setState({ focusedChipIndex: null });
    } else {
      this.setState({ focusedChipIndex: lastIndexOfSelectedItems });
    }
  };

  handleChipNavigatingRight = () => {
    const { focusedChipIndex } = this.state;
    const { selectedItems } = this.props;
    const lastIndexOfSelectedItems = getLastIndexInArray(selectedItems);
    if (
      this.areChipsFocused() &&
      focusedChipIndex >= 0 &&
      focusedChipIndex < lastIndexOfSelectedItems
    ) {
      this.setState({ focusedChipIndex: focusedChipIndex + 1 });
    } else if (focusedChipIndex === lastIndexOfSelectedItems) {
      this.setState({ focusedChipIndex: null });
    } else {
      this.setState({ focusedChipIndex: 0 });
    }
  };

  handleHover = itemFocused => {
    this.setState({ itemFocused });
  };

  getFilteredItems = (text = '', selectedItems = []) => {
    const { items, type } = this.props;
    const itemsFiltered = filterValuesByLabelCaseInsensitive(items, text);

    return type === 'multi'
      ? this.getItemsWithoutSelectedItems(itemsFiltered, selectedItems)
      : itemsFiltered;
  };

  handleSelect = item => {
    const { type, onSelect } = this.props;
    if (type === 'single' && item && !item.disabled) {
      onSelect(item);
      this.setState({ text: item.label });
    }

    if (type === 'multi' && item && !item.disabled) {
      onSelect(item);
      this.setState({ text: '' });
    }
  };

  handleEnter = () => {
    const { itemFocused, itemsShown } = this.state;
    const item = itemsShown[itemFocused];
    this.handleSelect(item);
  };

  handleClick = value => {
    const item = R.find(R.propEq('label', value), this.state.itemsShown);
    this.handleSelect(item);
  };

  handleKeyDown = ev => {
    const { type } = this.props;
    const { isFocused, itemFocused, itemsShown } = this.state;

    if (!isFocused) {
      return;
    }

    // prevent jumping cursor from start to end
    if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
      ev.preventDefault();
    }

    if (ev.key === 'ArrowDown' && itemsShown.length - 1 > itemFocused) {
      this.handleArrowDown();
    }

    if (ev.key === 'ArrowUp' && itemFocused > 0) {
      this.handleArrowUp();
    }

    if (ev.key === 'Enter') {
      this.handleEnter();
    }

    if (
      (ev.key === 'ArrowLeft' || ev.key === 'ArrowRight' || ev.key === 'Backspace') &&
      type === 'multi'
    ) {
      this.handleChipsNavigation(ev);
    }
  };

  handleChipsNavigation = ev => {
    const inputCaretPosition = ev.target.selectionStart;
    const { selectedItems } = this.props;
    const { text } = this.state;

    if (R.isEmpty(selectedItems)) {
      return;
    }
    if (inputCaretPosition > 0 && inputCaretPosition < text.length) {
      return;
    }
    if (ev.key === 'ArrowLeft') {
      this.handleArrowLeft(ev);
    }
    if (ev.key === 'ArrowRight') {
      this.handleArrowRight(ev);
    }
    if (ev.key === 'Backspace') {
      this.handleBackspace(ev);
    }
  };

  handleRemove = value => {
    const { handleRemove } = this.props;
    const item = R.find(R.propEq('label', value), this.props.selectedItems);
    this.setState({ focusedChipIndex: null });
    handleRemove(item);
  };

  areChipsFocused = () => this.state.focusedChipIndex !== null;

  renderInput() {
    const {
      type,
      id,
      selectedItems,
      placeholder,
      label,
      Icon,
      size,
      disabled,
      inputWrapperClassName,
      chipsClassName,
      containerClassName,
      textInputWrapperClassName,
      className,
      moveOverflow,
    } = this.props;
    const { isFocused, text, focusedChipIndex } = this.state;

    switch (type) {
      case 'single':
        return (
          <InputField
            id={id}
            value={text}
            placeholder={placeholder}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            label={label}
            size={size}
            prefix={<Icon />}
            disabled={disabled}
          />
        );

      case 'multi':
        return (
          <AutocompleteMulti
            id={id}
            value={text}
            values={selectedItems}
            placeholder={placeholder}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onRemove={this.handleRemove}
            onInputClick={this.handleInputClick}
            label={label}
            size={size}
            Icon={Icon}
            disabled={disabled}
            wrapperClassName={inputWrapperClassName}
            focusedChipIndex={focusedChipIndex}
            chipsClassName={chipsClassName}
            inputWrapperClassName={textInputWrapperClassName}
            containerClassName={`${containerClassName} ${
              moveOverflow ? 'input-autocomplete-move-overflow' : ''
            }`}
            className={className}
            isFocused={isFocused}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { displayedItemsListClassName, polyglot } = this.props;
    const { itemsShown, isFocused, itemFocused, text } = this.state;

    return (
      <ClickOutside onClick={this.handleBlur}>
        <AutocompleteGlobals />
        <ListContainer onKeyDown={this.handleDown} onClick={this.handleFocus}>
          {this.renderInput()}
          {isFocused && !this.areChipsFocused() && (
            <AutocompleteList className={displayedItemsListClassName}>
              {R.isEmpty(itemsShown) ? (
                <Item readOnly>
                  <EmptyResultsIconContainer>
                    <InformationCircleIcon />
                  </EmptyResultsIconContainer>
                  {polyglot.t('common.not_found')}
                </Item>
              ) : (
                itemsShown.map((item, i) => (
                  <Item
                    key={i}
                    isHovered={itemFocused === i}
                    value={item.label}
                    index={i}
                    onClick={this.handleClick}
                    onHover={this.handleHover}
                    readOnly={item.disabled}
                  >
                    {item.icon && (
                      <ItemIconContainer className={item.iconClassName}>
                        <item.icon />
                      </ItemIconContainer>
                    )}
                    <ItemLabel withItem={!!item.icon}>
                      {item.disabled ? item.label : formatText(text, item.label)}
                    </ItemLabel>
                  </Item>
                ))
              )}
            </AutocompleteList>
          )}
        </ListContainer>
      </ClickOutside>
    );
  }
}

InputAutocomplete.defaultProps = {
  type: 'single',
  size: 'medium',
};

InputAutocomplete.propTypes = {
  id: PropTypes.any.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape(AutocompleteValue)).isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  label: PropTypes.string,
  type: PropTypes.oneOf(['single', 'multi']),
  Icon: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape(AutocompleteItem)),
  onSelect: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  filter: PropTypes.func,
  displayedItems: PropTypes.arrayOf(PropTypes.shape(AutocompleteValue)),
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  inputWrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  displayedItemsListClassName: PropTypes.string,
  chipsClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  textInputWrapperClassName: PropTypes.string,
  onBlur: PropTypes.func,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

export default connect(mapStateToProps)(InputAutocomplete);
