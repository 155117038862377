import React from 'react';
import PropTypes from 'prop-types';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import CalendarRangeIcon from '@kiwicom/orbit-components/lib/icons/CalendarRange';
import CalendarTripLengthIcon from '@kiwicom/orbit-components/lib/icons/CalendarTripLength';

import { Space, LocationsAutocomplete } from 'common';
import { usePolyglot } from 'components/services/i18n';
import { SEARCH_LOCATION_TYPES } from 'consts/search';

import CalendarDropdown from '../../CalendarDropdown';
import DropdownInputButton from '../../DropdownInputButton';

import NightsPopover from './NightsPopover';
import { NomadSearchbarRow } from './Nomad.styled';
import { WidthWrapper } from './NomadTrip.styled';

const NomadTrip = ({
  changeNomadTripLocation,
  trip,
  changeNomadDates,
  removeNomadTrip,
  changeNomadIsNightsInputShown,
  changeNomadNumberOfNights,
  changeNomadTripIsDatesInputShown,
  isDeleteEnabled,
}) => {
  const { locations, nights, isNightsInputShown, dates, isDatesInputShown } = trip;
  const polyglot = usePolyglot();

  const handleSelectLocation = selectedValue => {
    changeNomadTripLocation(trip.id, [...trip.locations, selectedValue]);
  };

  const handleUnselectLocation = unselectedValue => {
    changeNomadTripLocation(
      trip.id,
      trip.locations.filter(location => location.value !== unselectedValue.value),
    );
  };

  const selectDates = selectedDates => {
    changeNomadDates(trip.id, selectedDates);
  };

  const removeTrip = () => {
    removeNomadTrip(trip.id);
  };

  const showNightsInput = () => {
    changeNomadIsNightsInputShown(trip.id, true);
  };

  const showDatesInput = () => {
    changeNomadTripIsDatesInputShown(trip.id, true);
  };

  const closeDatesInput = () => {
    changeNomadTripIsDatesInputShown(trip.id, false);
  };

  const closeNightsInput = () => {
    changeNomadIsNightsInputShown(trip.id, false);
  };

  const changeNightsStart = newTripValue => {
    const nightsEnd = newTripValue > trip.nights[1] ? newTripValue : trip.nights[1];

    changeNomadNumberOfNights(trip.id, [newTripValue, nightsEnd]);
  };

  const changeNightsEnd = newTripValue => {
    const nightsStart = newTripValue < trip.nights[0] ? newTripValue : trip.nights[0];
    changeNomadNumberOfNights(trip.id, [nightsStart, newTripValue]);
  };

  return (
    <NomadSearchbarRow>
      <Space>
        <LocationsAutocomplete
          onSelect={handleSelectLocation}
          onUnselect={handleUnselectLocation}
          selectedValues={locations}
          label={<Text type="secondary">{polyglot.t('searchAndBooking.nomad.via')}</Text>}
          inlineLabel
          locationTypes={SEARCH_LOCATION_TYPES}
        />
      </Space>
      <Space>
        <Stack direction="row" spacing="XSmall" spaceAfter="large" justify="between" grow>
          {isNightsInputShown && (
            <WidthWrapper applyWidth={!isDatesInputShown}>
              <DropdownInputButton
                closable={isDatesInputShown}
                onClose={closeNightsInput}
                prefix={polyglot.t('searchAndBooking.nomad.length')}
                value={polyglot.t('searchAndBooking.nomad.nights', {
                  nights:
                    nights[0] === nights[1] ? String(nights[0]) : `${nights[0]} - ${nights[1]}`,
                })}
                content={
                  <NightsPopover
                    nights={nights}
                    changeNightsStart={changeNightsStart}
                    changeNightsEnd={changeNightsEnd}
                  />
                }
              />
            </WidthWrapper>
          )}

          {isDatesInputShown && (
            <WidthWrapper applyWidth={!isNightsInputShown}>
              <CalendarDropdown
                closable={isNightsInputShown}
                onClose={closeDatesInput}
                label={<Text type="secondary">{polyglot.t('searchAndBooking.nomad.between')}</Text>}
                inlineLabel
                selectedDates={dates}
                changeSelectedDates={selectDates}
              />
            </WidthWrapper>
          )}
          {!isNightsInputShown && (
            <Button
              iconLeft={<CalendarRangeIcon />}
              title={polyglot.t('searchAndBooking.nomad.number_of_nights')}
              onClick={showNightsInput}
            />
          )}
          {!isDatesInputShown && (
            <Button
              iconLeft={<CalendarTripLengthIcon />}
              title={polyglot.t('searchAndBooking.nomad.dates')}
              onClick={showDatesInput}
            />
          )}
          <Button
            iconLeft={<CloseIcon />}
            title={polyglot.t('searchAndBooking.nomad.delete_trip')}
            disabled={!isDeleteEnabled}
            onClick={removeTrip}
          />
        </Stack>
      </Space>
    </NomadSearchbarRow>
  );
};

NomadTrip.propTypes = {
  trip: PropTypes.object,
  isDeleteEnabled: PropTypes.bool,
  changeNomadTripLocation: PropTypes.func,
  changeNomadIsNightsInputShown: PropTypes.func,
  changeNomadNumberOfNights: PropTypes.func,
  changeNomadTripIsDatesInputShown: PropTypes.func,
  changeNomadDates: PropTypes.func,
};

export default NomadTrip;
