const v1_post_from = (solution, apiProducts) => {
  return {
    name: solution.name,
    productType: apiProducts?.find(product => solution.api_product_id === product.id)?.value,
    affilId: solution.affiliate_id,
    consumerKey: solution.api_key,
    status: solution.status,
    approvalDate: new Date(solution?.created_at),
    intendedUse: solution.intended_use,
    uuid: solution.uuid,
    webhookProduction: solution.webhook_production,
    webhookStaging: solution.webhook_staging,
    csEmail: solution.cs_email,
    quotaConfig: {
      quota: solution.quota_config.quota,
      quotaInterval: solution.quota_config.quota_interval,
      quotaTimeUnit: solution.quota_config.quota_time_unit,
    },
  };
};

export default {
  v1: {
    post: {
      from: v1_post_from,
    },
  },
};
