import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';

import { RowGroupItem } from '../index.styled';

const SeatGroupTitle = ({ rowGroup, isLastItem }) => {
  return (
    <>
      <Box align="center" direction="row" display="flex">
        {rowGroup.map((item, i) => (
          <RowGroupItem key={`SeatGroupItem-${i}`} $title>
            <Text weight="bold" size="large" key={item}>
              {item}
            </Text>
          </RowGroupItem>
        ))}
      </Box>
      {!isLastItem && <RowGroupItem $title aisle />}
    </>
  );
};

export default SeatGroupTitle;
