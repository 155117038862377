import styled from 'styled-components';

export const AccountTypeRadioWrapper = styled.div`
  height: 40px;
  padding: 24px 16px;
`;

export const AccountTypeWrapper = styled.div`
  margin-bottom: 24px;
  box-shadow: 0 4px 8px 0 rgba(37, 42, 49, 0.12), 0 1px 4px 0 rgba(37, 42, 49, 0.16);
  border-radius: 3px;

  & > ${AccountTypeRadioWrapper}:first-of-type {
    border-bottom: 1px solid #e8edf1;
  }
`;
