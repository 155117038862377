import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import OrbitSelect from '@kiwicom/orbit-components/lib/Select';
import { usePolyglot } from 'components/services/i18n';

const Select = ({ name, ...props }) => {
  const { errors, control } = useFormContext();
  const polyglot = usePolyglot();

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <OrbitSelect
          {...props}
          name={name}
          value={value}
          error={polyglot.t(errors?.[name]?.message) ?? null}
          onChange={e => onChange(e.target.value)}
        />
      )}
    />
  );
};

export default Select;
