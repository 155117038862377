import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { DepositState } from 'components/services/deposit';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import { useToggle } from 'utils/hooks';
import { MINIMUM_CASH_OUT_VALUE, CASHOUT_CURRENCY } from 'consts/company';

const CashOutModal = ({ onClose, onSuccess }) => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const deposit = useCompanyDeposit(companyName);
  const { cashOut } = useContext(DepositState);
  const errorCashOutAlert = useToggle();
  const balance = deposit.data && deposit.data.balance;

  const DepositSchema = Yup.object().shape({
    cashOutValue: Yup.number()
      .required('validation.must_contain_numbers')
      .min(MINIMUM_CASH_OUT_VALUE, 'deposit.cash_out_value_error')
      .max(balance, 'deposit.cash_out_value_error'),
  });

  const handleCashOut = async values => {
    try {
      await cashOut(values.cashOutValue, companyName);
      onSuccess();
    } catch (error) {
      errorCashOutAlert.setOn();
    }
  };

  return (
    <Modal closable onClose={onClose}>
      <ModalHeader title={polyglot.t('deposit.cash_out')} />
      <Formik
        initialValues={{
          cashOutValue: '',
        }}
        validationSchema={DepositSchema}
        onSubmit={values => handleCashOut(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <ModalSection>
              {errorCashOutAlert.isOn && (
                <Alert
                  type="critical"
                  closable
                  onClose={errorCashOutAlert.setOff}
                  spaceAfter="large"
                >
                  {polyglot.t('deposit.cash_out_error_message')}
                </Alert>
              )}
              <Stack>
                <Text size="large">{`${polyglot.t(
                  'deposit.cash_out.modal.available_credits',
                )}: €${balance}`}</Text>
                <InputField
                  type="number"
                  name="cashOutValue"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={polyglot.t('common.amount')}
                  placeholder={polyglot.t('deposit.cash_out.modal.placeholder')}
                  prefix={<span>{CASHOUT_CURRENCY}</span>}
                  value={values.cashOutValue}
                  error={
                    touched.cashOutValue &&
                    errors.cashOutValue &&
                    polyglot.t(errors.cashOutValue, {
                      minValue: MINIMUM_CASH_OUT_VALUE,
                      maxValue: balance,
                    })
                  }
                />
              </Stack>
            </ModalSection>
            <ModalFooter>
              <Stack direction="row" justify="end">
                <Button
                  dataTest="cash-out-modal-cancel-button"
                  type="secondary"
                  width="128px"
                  onClick={onClose}
                >
                  {polyglot.t('common.cancel')}
                </Button>
                <Button
                  dataTest="cash-out-modal-confirm-button"
                  type="primary"
                  width="128px"
                  onClick={handleSubmit}
                  loading={deposit.cashOuting}
                  disabled={touched.cashOutValue && errors.cashOutValue}
                >
                  {polyglot.t('common.confirm')}
                </Button>
              </Stack>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default CashOutModal;
