import styled from 'styled-components';

export const SnackbarContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: -150px;
  margin: 0 auto;
  width: 325px;
  padding: 15px 10px 15px 10px;
  background: black;
  color: white;
  border-radius: 6px;
  transition: all 1000ms ease-in-out;
  margin-bottom: 32px;
  z-index: 5;

  ${({ visible }) => visible && 'bottom: -5px'};
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
`;
