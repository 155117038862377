import { SKYPICKER_VISITOR_UNIQID } from 'consts';
import { getCookie } from 'utils/cookie';
import { camelize } from 'utils/functions';
import { extractType } from 'utils/prebooking';
import { getBrandFromProduct } from 'utils/products';

const direct_post_to = ({ bookingToken, product, currency, passengers }) => ({
  init_params: {
    affily: 'skypicker',
    booking_token: bookingToken,
    brand: getBrandFromProduct(product),
    platform: 'web',
    visitor_id: getCookie(SKYPICKER_VISITOR_UNIQID),
  },
  user_input: {
    booking_phase: 'summary',
    currency,
    passengers: passengers.map(passenger => ({
      category: passenger.category,
    })),
  },
});

const direct_post_config_to = apiKey => ({
  headers: { apikey: apiKey },
});

const direct_post_from = prebookingSessionData => ({
  sessionId: prebookingSessionData.id,
  refreshTtl: prebookingSessionData.refresh_ttl,
  events: prebookingSessionData.events ? extractType(camelize(prebookingSessionData.events)) : {},
  price: {
    amount: prebookingSessionData.pricing.itinerary.price.amount,
    currency: prebookingSessionData.pricing.itinerary.price.currency,
  },
});

export default {
  direct: {
    post: {
      to: direct_post_to,
      from: direct_post_from,
      config_to: direct_post_config_to,
    },
  },
};
