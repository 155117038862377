import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Check from '@kiwicom/orbit-components/lib/icons/Check';

import { useCurrentUser } from 'components/services/auth';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useCompanyBankAccounts from 'components/services/bankAccounts/useCompanyBankAccounts';
import useCompanyUsers from 'components/services/companyUsers/useCompanyUsers';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';

import BillingInformation from './components/BillingInformation';
import Users from './components/Users';
import ReadTheDocs from './components/ReadTheDocs';
import AddSolution from './components/AddSolution';
import CustomerSupport from './components/CustomerSupport';
import {
  PageContainer,
  AlertTitle,
  SetupChecklist,
  CheckContainer,
  RowContainer,
  RowItem,
} from './AddDetails.styled';

const AddDetails = () => {
  const polyglot = usePolyglot();
  const history = useHistory();
  const user = useCurrentUser();
  const companyName = user.companyName;
  const {
    data: { customerSupportEnabled },
  } = useCompanyDetails(companyName);
  const solutions = useCompanySolutions(companyName);
  const users = useCompanyUsers(companyName);
  const bankAccounts = useCompanyBankAccounts(companyName);
  const [showAlertMessage, setShowAlertMessage] = useState(true);

  const bankAccountsSetUp = bankAccounts?.data?.length > 0;
  const solutionsSetUp = solutions?.data?.length > 0;
  const usersSetUp = users?.data?.length > 0;

  const navigateToBilling = () => {
    history.push({
      pathname: `${ROUTE_CONFIG.COMPANIES.path}/${companyName}/billing`,
      state: {
        fromTutorial: true,
      },
    });
  };

  return (
    <PageContainer>
      {showAlertMessage && (
        <Alert
          type="info"
          icon={<InformationCircle />}
          closable
          onClose={() => {
            setShowAlertMessage(false);
          }}
        >
          <AlertTitle>{polyglot.t('add_details.alert_title')}</AlertTitle>
          <div>
            <p>{polyglot.t('add_details.list_title')}</p>
            <SetupChecklist>
              <li>
                {bankAccountsSetUp ? (
                  <CheckContainer>
                    <Check size="small" />
                  </CheckContainer>
                ) : (
                  '1. '
                )}
                {polyglot.t('add_details.add_billing')}
              </li>
              <li>
                {solutionsSetUp ? (
                  <CheckContainer>
                    <Check size="small" />
                  </CheckContainer>
                ) : (
                  '2. '
                )}
                {polyglot.t('add_details.add_solution')}
              </li>
            </SetupChecklist>
            <p>{polyglot.t('add_details.alert_footer')}</p>
          </div>
        </Alert>
      )}
      <RowContainer>
        {!bankAccountsSetUp && (
          <RowItem basis="100%">
            <BillingInformation clickAction={navigateToBilling} polyglot={polyglot} />
          </RowItem>
        )}
        {!usersSetUp && (
          <RowItem>
            <Users user={user} polyglot={polyglot} />
          </RowItem>
        )}
        <RowItem>
          <ReadTheDocs polyglot={polyglot} />
        </RowItem>
        {!solutionsSetUp && (
          <RowItem>
            <AddSolution polyglot={polyglot} companyName={companyName} />
          </RowItem>
        )}
        {customerSupportEnabled && (
          <RowItem>
            <CustomerSupport polyglot={polyglot} />
          </RowItem>
        )}
      </RowContainer>
    </PageContainer>
  );
};

export default AddDetails;
