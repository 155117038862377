import { createContext, useReducer, useRef } from 'react';
import { proxy } from 'utils/api';

import PrebookingConfigMapper from 'mappers/nextGen/booking/prebookings/config/_';
import PrebookingSessionsMapper from 'mappers/nextGen/booking/prebookings/sessions/_';
import PrebookingOneSessionMapper from 'mappers/nextGen/booking/prebookings/sessions/{session_id}/_';
import PrebookingOneSessionOneEventMapper from 'mappers/nextGen/booking/prebookings/sessions/{session_id}/events/{event_id}/_';
import PrebookingOneSessionUserInputMapper from 'mappers/nextGen/booking/prebookings/sessions/{session_id}/user_input/_';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from 'utils/sessionStorage';
import { BOOKING_FLOW_SESSION_ID } from 'consts';
import { camelize } from 'utils/functions';
import { extractType, getLast20CharsBookingToken } from 'utils/prebooking';

const FETCH_PREBOOKING_CONFIG = 'FETCH_PREBOOKING_CONFIG';
const FETCH_PREBOOKING_CONFIG_SUCCESS = 'FETCH_PREBOOKING_CONFIG_SUCCESS';
const FETCH_PREBOOKING_CONFIG_FAIL = 'FETCH_PREBOOKING_CONFIG_FAIL';
const RESET_PREBOOKING_SESSIONS = 'RESET_PREBOOKING_SESSIONS';
const PREBOOKING_SESSION = 'PREBOOKING_SESSION';
const PREBOOKING_SESSION_SUCCESS = 'PREBOOKING_SESSION_SUCCESS';
const PREBOOKING_SESSION_FAIL = 'PREBOOKING_SESSION_FAIL';

const initialState = {
  prebookingConfig: {
    data: null,
    loading: false,
    error: null,
  },
  prebookingSession: {
    data: null,
    loading: false,
    error: null,
  },
};

export const BookingFlowState = createContext({
  ...initialState,
  startPollingPrebookingSessions: async (
    bookingToken,
    apiKey,
    product,
    passengersData,
    currency,
  ) => {},
  retryPollingPrebookingSessions: (bookingToken, apiKey, product, passengersData, currency) => {},
  resumePollingPrebookingSessions: async (bookingToken, apiKey) => {},
  updatePrebookingSession: async (apiKey, passengers, currency, sessionId) => {},
  resolveActivePrebookingSessionEvent: async (apiKey, sessionId, eventId) => {},
  stopPollingPrebookingSessions: () => {},
  resetPrebookingSessions: () => {},
});

const bookingFlowReducer = (state, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case FETCH_PREBOOKING_CONFIG:
      return {
        ...state,
        prebookingConfig: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case FETCH_PREBOOKING_CONFIG_SUCCESS:
      return {
        ...state,
        prebookingConfig: {
          data: payload.prebookingConfigData,
          loading: false,
          error: null,
        },
      };
    case FETCH_PREBOOKING_CONFIG_FAIL:
      return {
        ...state,
        prebookingConfig: {
          data: null,
          loading: false,
          error,
        },
      };
    case PREBOOKING_SESSION:
      return {
        ...state,
        prebookingSession: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case PREBOOKING_SESSION_SUCCESS:
      return {
        ...state,
        prebookingSession: {
          data: payload.prebookingSessionData,
          loading: false,
          error: null,
        },
      };
    case PREBOOKING_SESSION_FAIL:
      return {
        ...state,
        prebookingSession: {
          data: payload?.prebookingSessionData ?? null,
          loading: false,
          error,
        },
      };
    case RESET_PREBOOKING_SESSIONS:
      return initialState;
    default:
      return initialState;
  }
};

const BookingFlowProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bookingFlowReducer, initialState);
  const timeoutId = useRef(null);

  const fetchPrebookingConfig = async (bookingToken, apiKey, product) => {
    dispatch({ type: FETCH_PREBOOKING_CONFIG });
    try {
      const { data: prebookingConfig } = await proxy.direct.get(
        'booking/prebookings/config',
        PrebookingConfigMapper.direct.get.to(bookingToken, apiKey, product),
      );

      const mappedPrebookingConfig = PrebookingConfigMapper.direct.get.from(prebookingConfig);

      dispatch({
        type: FETCH_PREBOOKING_CONFIG_SUCCESS,
        payload: {
          prebookingConfigData: mappedPrebookingConfig,
        },
      });
    } catch (error) {
      dispatch({ type: FETCH_PREBOOKING_CONFIG_FAIL, error });
    }
  };

  const createNewPrebookingSession = async (
    bookingToken,
    apiKey,
    product,
    passengers,
    currency,
  ) => {
    dispatch({ type: PREBOOKING_SESSION });
    try {
      const { data: prebookingSession } = await proxy.direct.post(
        'booking/prebookings/sessions',
        PrebookingSessionsMapper.direct.post.to({
          bookingToken,
          product,
          passengers,
          currency,
        }),
        PrebookingSessionsMapper.direct.post.config_to(apiKey),
      );

      const mappedPrebookingSession = PrebookingSessionsMapper.direct.post.from(prebookingSession);

      dispatch({
        type: PREBOOKING_SESSION_SUCCESS,
        payload: {
          prebookingSessionData: mappedPrebookingSession,
        },
      });

      const last20CharsBookingToken = getLast20CharsBookingToken(bookingToken);

      setSessionStorageItem(
        `${BOOKING_FLOW_SESSION_ID}_${last20CharsBookingToken}`,
        mappedPrebookingSession.sessionId,
      );

      if (!Object.keys(mappedPrebookingSession.events).length) {
        setBookingFlowInterval(
          apiKey,
          mappedPrebookingSession.refreshTtl,
          getSessionStorageItem(`${BOOKING_FLOW_SESSION_ID}_${last20CharsBookingToken}`),
        );
      }
    } catch (error) {
      dispatch({ type: PREBOOKING_SESSION_FAIL, error: camelize(error.response.data) });
    }
  };

  const fetchFreshPrebookingSession = async (apiKey, bookingSessionId) => {
    dispatch({ type: PREBOOKING_SESSION });
    try {
      const { data: prebookingSession } = await proxy.direct.get(
        `booking/prebookings/sessions/${bookingSessionId}`,
        PrebookingOneSessionMapper.direct.get.to(apiKey),
      );

      const mappedPrebookingSession = PrebookingOneSessionMapper.direct.get.from(prebookingSession);

      dispatch({
        type: PREBOOKING_SESSION_SUCCESS,
        payload: {
          prebookingSessionData: mappedPrebookingSession,
        },
      });

      return mappedPrebookingSession;
    } catch (error) {
      const camelizedError = camelize(error.response.data);

      dispatch({ type: PREBOOKING_SESSION_FAIL, error: camelizedError });

      return camelizedError;
    }
  };

  const resolveActivePrebookingSessionEvent = async (apiKey, sessionId, eventId) => {
    dispatch({ type: PREBOOKING_SESSION });
    try {
      const { data: prebookingSession } = await proxy.direct.patch(
        `booking/prebookings/sessions/${sessionId}/events/${eventId}`,
        PrebookingOneSessionOneEventMapper.direct.patch.to(),
        PrebookingOneSessionOneEventMapper.direct.patch.config_to(apiKey),
      );

      const mappedPrebookingSession =
        PrebookingOneSessionOneEventMapper.direct.patch.from(prebookingSession);

      dispatch({
        type: PREBOOKING_SESSION_SUCCESS,
        payload: {
          prebookingSessionData: mappedPrebookingSession,
        },
      });

      setBookingFlowInterval(apiKey, 0, sessionId);
    } catch (error) {
      dispatch({ type: PREBOOKING_SESSION_FAIL, error: camelize(error.response.data) });
    }
  };

  const updatePrebookingSession = async (apiKey, passengers, currency, sessionId) => {
    dispatch({ type: PREBOOKING_SESSION });
    try {
      const { data: prebookingSession } = await proxy.direct.put(
        `booking/prebookings/sessions/${sessionId}/user_input`,
        PrebookingOneSessionUserInputMapper.direct.put.to({ currency, passengers }),
        PrebookingOneSessionUserInputMapper.direct.put.config_to(apiKey),
      );

      const mappedPrebookingSession =
        PrebookingOneSessionUserInputMapper.direct.put.from(prebookingSession);

      dispatch({
        type: PREBOOKING_SESSION_SUCCESS,
        payload: {
          prebookingSessionData: mappedPrebookingSession,
        },
      });

      if (!Object.keys(mappedPrebookingSession.events).length) {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
        setBookingFlowInterval(apiKey, mappedPrebookingSession.refreshTtl, sessionId);
      }
    } catch (error) {
      const camelizedError = camelize(error.response.data);

      dispatch({
        type: PREBOOKING_SESSION_FAIL,
        payload: camelizedError?.sessionData?.events
          ? {
              prebookingSessionData: {
                events: extractType(camelizedError.sessionData.events),
              },
            }
          : null,
        error: camelizedError,
      });
    }
  };

  const setBookingFlowInterval = (apiKey, timeToRefresh, bookingFlowSessionId) => {
    let refreshInterval = timeToRefresh * 1000;

    const fetchAndCheckData = async () => {
      const freshPrebookingData = await fetchFreshPrebookingSession(apiKey, bookingFlowSessionId);
      const status = freshPrebookingData.status;
      const events = freshPrebookingData.events;
      const hasStatusError = status >= 400 && status <= 500;
      const hasEvents = events && Object.keys(events).length;

      if (hasEvents || hasStatusError) {
        // resolve events
        clearTimeout(timeoutId.current);
      } else {
        refreshInterval = freshPrebookingData.refreshTtl * 1000;
        timeoutId.current = setTimeout(fetchAndCheckData, refreshInterval);
      }
    };

    timeoutId.current = setTimeout(fetchAndCheckData, refreshInterval);
  };

  const startPollingPrebookingSessions = async (
    bookingToken,
    apiKey,
    product,
    passengers,
    currency,
  ) => {
    const last20CharsBookingToken = getLast20CharsBookingToken(bookingToken);
    const bookingFlowSessionId = getSessionStorageItem(
      `${BOOKING_FLOW_SESSION_ID}_${last20CharsBookingToken}`,
    );

    if (bookingFlowSessionId) {
      await Promise.all([
        fetchPrebookingConfig(bookingToken, apiKey, product),
        updatePrebookingSession(apiKey, passengers, currency, bookingFlowSessionId),
      ]);
    } else {
      await Promise.all([
        fetchPrebookingConfig(bookingToken, apiKey, product),
        createNewPrebookingSession(bookingToken, apiKey, product, passengers, currency),
      ]);
    }
  };

  const resumePollingPrebookingSessions = (bookingToken, apiKey) => {
    const last20CharsBookingToken = getLast20CharsBookingToken(bookingToken);
    const bookingFlowSessionId = getSessionStorageItem(
      `${BOOKING_FLOW_SESSION_ID}_${last20CharsBookingToken}`,
    );

    setBookingFlowInterval(apiKey, 0, bookingFlowSessionId);
  };

  const retryPollingPrebookingSessions = (bookingToken, apiKey, product, passengers, currency) => {
    const last20CharsBookingToken = getLast20CharsBookingToken(bookingToken);
    const bookingFlowSessionId = getSessionStorageItem(
      `${BOOKING_FLOW_SESSION_ID}_${last20CharsBookingToken}`,
    );

    removeSessionStorageItem(bookingFlowSessionId);

    createNewPrebookingSession(bookingToken, apiKey, product, passengers, currency);
  };

  const stopPollingPrebookingSessions = () => {
    clearTimeout(timeoutId.current);
  };

  const resetPrebookingSessions = () => {
    dispatch({ type: RESET_PREBOOKING_SESSIONS });
    stopPollingPrebookingSessions();
  };

  const value = {
    ...state,
    startPollingPrebookingSessions,
    resumePollingPrebookingSessions,
    retryPollingPrebookingSessions,
    resolveActivePrebookingSessionEvent,
    updatePrebookingSession,
    stopPollingPrebookingSessions,
    resetPrebookingSessions,
  };

  return <BookingFlowState.Provider value={value}>{children}</BookingFlowState.Provider>;
};

export default BookingFlowProvider;
