import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import isWithinInterval from 'date-fns/isWithinInterval';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { SelectNationality, DateInput } from 'common';

import Polyglot from 'shapes/Polyglot';
import { cantContainCyrillic } from 'utils/validation';

const UserAccount = ({
  polyglot,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  setFieldValue,
}) => {
  return (
    <Stack spaceAfter="largest" spacing="large">
      <Heading type="title2" element="h2">
        {polyglot.t('register.user_account')}
      </Heading>
      <Stack direction="row" spacing="XSmall">
        <InputField
          id="register-first-name"
          label={polyglot.t('common.first_name')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="firstName"
          value={values.firstName}
          error={touched.firstName && errors.firstName && polyglot.t(errors.firstName)}
        />
        <InputField
          id="register-last-name"
          label={polyglot.t('common.last_name')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="lastName"
          value={values.lastName}
          error={touched.lastName && errors.lastName && polyglot.t(errors.lastName)}
        />
      </Stack>
      <DateInput
        label={polyglot.t('register.date_input.label')}
        setFieldValue={setFieldValue}
        onBlur={handleBlur}
        value={values.dateOfBirth}
        name="dateOfBirth"
        error={touched.dateOfBirth && errors.dateOfBirth && polyglot.t(errors.dateOfBirth)}
      />
      <SelectNationality
        id="register-nationality"
        label={polyglot.t('register.nationality')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="nationality"
        value={values.nationality}
        error={touched.nationality && errors.nationality && polyglot.t(errors.nationality)}
      />
      <InputField
        id="register-email"
        label={polyglot.t('common.email')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="email"
        value={values.email}
        error={touched.email && errors.email && polyglot.t(errors.email)}
      />
    </Stack>
  );
};

UserAccount.schema = Yup.object().shape({
  firstName: Yup.string()
    .required('validation.cant_be_empty')
    .min(3, 'validation.too_short')
    .max(255, 'validation.length_exceeded'),
  lastName: Yup.string()
    .required('validation.cant_be_empty')
    .min(3, 'validation.too_short')
    .max(255, 'validation.length_exceeded'),
  nationality: Yup.string().required('validation.cant_be_empty').nullable(),
  email: Yup.string()
    .email('validation.invalid_email')
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(255, 'validation.length_exceeded'),
  dateOfBirth: Yup.string()
    .required('validation.cant_be_empty')
    .test('is-in-range', 'validation.invalid_date', date =>
      isWithinInterval(new Date(date), { start: new Date(1900, 0), end: new Date() }),
    )
    .test('is-valid', 'validation.invalid_date', date => {
      let [, month, day] = date.split('-');
      if (month === '02') {
        return day > '29' ? false : true;
      }
      return true;
    })
    .nullable(),
});

UserAccount.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default UserAccount;
