import { useEffect, useContext } from 'react';

import { MMBState } from 'components/services/mmb';

const useBookings = filters => {
  const { state, loadMMBBookings } = useContext(MMBState);

  useEffect(() => {
    if (!state.bookings) {
      loadMMBBookings(filters);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    { data: state?.bookings, loading: state.loadingBookings, error: null } || {
      data: null,
      loading: false,
      error: null,
    }
  );
};

export default useBookings;
