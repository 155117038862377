import format from 'date-fns/format';

import {
  mapperDefinitions,
  mapperCombinations,
} from 'mappers/nextGen/partners/bookings/{bid}/baggage/_';
import { tripTypesValues } from 'consts/search';

const isPhaseOneDone = ({ flights_checked, price_change, flights_invalid }) => {
  if (flights_checked && !price_change && !flights_invalid) return true;
  return false;
};

const v2_get_to = (params, passengers) => {
  return {
    booking_token: params.token,
    bnum: 0,
    pnum: passengers ? passengers.length : 1,
    currency: params.currency,
    adults: passengers ? passengers?.filter(pax => pax.category === 'adult').length : 1,
    children: passengers ? passengers?.filter(pax => pax.category === 'child').length : 0,
    infants: passengers ? passengers?.filter(pax => pax.category === 'infant').length : 0,
  };
};

const flightsMapper = (flights, index) => {
  return {
    id: flights[index].id,
    checkin: flights[index].checkin,
    eurPrice: flights[index].eur,
    flightNumber: `${flights[index].airline.code} ${flights[index].flight_no}`,
    vehicleType: flights[index].vehicle.type,
    duration: new Date(flights[index].utc_arrival) - new Date(flights[index].utc_departure),
    arrival: {
      place: {
        city: flights[index].dst_name,
      },
      station: {
        name: flights[index].dst_station,
        code: flights[index].dst,
      },
      time: {
        local: flights[index].local_arrival,
        utc: format(new Date(flights[index].utc_arrival), "yyyy-MM-dd'T'HH:mm:ss"),
      },
      name: `${flights[index].dst_station} ${flights[index].dst}`,
    },
    operatingCarrier: {
      code: flights[index].airline.code,
      name: flights[index].airline.name,
      public_code: flights[index].airline.code_public,
      segment_code: flights[index].operating_flight_no,
    },
    departure: {
      place: {
        city: flights[index].src_name,
      },
      station: {
        name: flights[index].src_station,
        code: flights[index].src,
      },
      time: {
        local: flights[index].local_departure,
        utc: format(new Date(flights[index].utc_departure), "yyyy-MM-dd'T'HH:mm:ss"),
      },
      name: `${flights[index].src_station} ${flights[index].src}`,
    },
    fare: {
      category: flights[index].fare_category,
      class: flights[index].fare_class,
    },
    isBagRecheckRequired: flights[index].bags_recheck_required,
    isGuaranteed: flights[index].guarantee,
    isSelfTransfer: flights[index].is_self_transfer,
  };
};

/*
Map flights to same structure as searchItinerary
flights -> [{}...]
searchItinerary -> [{}...] (one-way) || [[{}...][{}...]] (return)
*/
const mapFlights = (flights, tripType, searchItinerary) => {
  if (tripType.value === tripTypesValues.MULTICITY || tripType.value === tripTypesValues.NOMAD) {
    //return an array to keep the same format as one-way/return
    return [flights.map((_, i) => flightsMapper(flights, i))];
  }

  return searchItinerary.map((itinerary, i) => {
    if (!Array.isArray(itinerary)) {
      return flightsMapper(flights, i);
    }
    /*
    to preserve flight order we need a way to keep the current index
    eg. if there are 6 flights in a return trip -> searchItinerary could be [[3],[3]]
    in the second loop the index of the first flight object will be i(1) * searchItinerary[0].length(3) + j(0) -> flights[3]
    */
    return itinerary.map((_, j) => flightsMapper(flights, i * searchItinerary[0].length + j));
  });
};

const mapBaggage = data => {
  return {
    definitions: mapperDefinitions(data.definitions),
    combinations: mapperCombinations(data.combinations),
  };
};

const v2_get_from = (response, tripType, searchItinerary) => {
  return {
    flightsChecked: response.flights_checked,
    priceChange: response.price_change,
    totalPrice: response.total,
    flightsInvalid: response.flights_invalid,
    baggage: mapBaggage(response.baggage),
    pnum: response.pnum,
    currency: response.currency,
    originalPrice: response.orig_price,
    ageCategoryThresholds: response.age_category_thresholds,
    phaseOnePassed: isPhaseOneDone({ ...response }),
    sessionId: response.session_id,
    documentNeed: response.document_options.document_need,
    ...(searchItinerary && { flights: mapFlights(response.flights, tripType, searchItinerary) }),
  };
};

export default {
  v2: {
    get: {
      to: v2_get_to,
      from: v2_get_from,
    },
  },
};
