import { countriesWithIBAN } from 'consts/countries';

const v1_put_to = (companyName, bankAccount) => {
  return {
    beneficiary_name: bankAccount.beneficiary_name,
    company_name: companyName,
    country: bankAccount.country,
    swift: bankAccount.swift,
    ...(countriesWithIBAN.includes(bankAccount.country)
      ? {
          iban: bankAccount.iban && bankAccount.iban.replace(/\W+/g, ''),
          account_number: null,
        }
      : {
          account_number:
            bankAccount.account_number && bankAccount.account_number.replace(/\W+/g, ''),
          iban: null,
        }),
  };
};

const v1_put_from = bankAccount => {
  return {
    accountNumber: bankAccount.account_number,
    beneficiaryName: bankAccount.beneficiary_name,
    companyName: bankAccount.company_name,
    country: bankAccount.country,
    iban: bankAccount.iban,
    swift: bankAccount.swift,
    uuid: bankAccount.uuid,
    isPrimary: bankAccount.is_primary,
  };
};

export default {
  v1: {
    put: {
      to: v1_put_to,
      from: v1_put_from,
    },
  },
};
