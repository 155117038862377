import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import getSymbolFromCurrency from 'currency-symbol-map';

import Text from '@kiwicom/orbit-components/lib/Text';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Button from '@kiwicom/orbit-components/lib/Button';
import ArrowUpIcon from '@kiwicom/orbit-components/lib/icons/ArrowUp';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import usePriceChanges from 'components/services/mmb/usePriceChanges';
import { usePolyglot } from 'components/services/i18n';

import Card from '../../components/Card';
import { precisionRound } from 'utils/number';

const Container = styled.div`
  display: flex;

  & > div {
    flex: 1;

    &:last-of-type {
      border-left: none;
    }
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 8px;
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;

  ${({ oldPrice, theme }) =>
    oldPrice &&
    css`
      text-decoration: line-through;
      color: ${theme.orbit.paletteInkLight};

      & > p {
        text-decoration: none;
        color: ${theme.orbit.paletteInkLight};
      }
    `}

  ${({ newPrice, theme }) =>
    newPrice &&
    css`
      color: ${theme.orbit.paletteProductNormal};

      & > p {
        color: ${theme.orbit.paletteProductNormal};
      }
    `}

  & svg {
    margin-right: 4px;
  }
`;

const DifferenceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: ${({ hideAction }) => (hideAction ? 'flex-end' : 'space-between')};

  & > ${Price} > div {
    display: flex;
    align-items: center;
  }
`;

const PriceDisplay = ({ oldPrice, newPrice, difference, id, bid, onClick, loading }) => {
  const polyglot = usePolyglot();
  return (
    <Container>
      <Card nested showTitle={false}>
        <PriceWrapper>
          <Price oldPrice>
            <Text type="primary" size="small" spaceAfter="smallest">
              {polyglot.t('mmb.booking_details.price_change.old_price')}
            </Text>
            {oldPrice}
          </Price>
          <FlightDirectIcon size="large" color="secondary" />
          <Price newPrice>
            <Text size="small" spaceAfter="smallest">
              {polyglot.t('mmb.booking_details.price_change.new_price')}
            </Text>
            {newPrice}
          </Price>
        </PriceWrapper>
      </Card>
      <Card nested showTitle={false}>
        <DifferenceWrapper>
          <Price>
            <Text type="primary" size="small" spaceAfter="smallest" align="left">
              {polyglot.t('mmb.booking_details.price_change.difference')}
            </Text>
            <div>
              <ArrowUpIcon size="large" color="warning" />
              {difference}
            </div>
          </Price>
          <Tooltip content={polyglot.t('mmb.booking_details.price_change.carriers_change_prices')}>
            <Button
              type="primary"
              size="small"
              loading={loading}
              iconRight={<InformationCircleIcon />}
              onClick={() => onClick(bid, id)}
            >
              {polyglot.t('mmb.booking_details.price_change.accept_and_pay')}
            </Button>
          </Tooltip>
        </DifferenceWrapper>
      </Card>
    </Container>
  );
};

const PriceChange = ({ bookingData, onClick }) => {
  const { bid } = useParams();
  const { data: priceChange, payingPriceChange } = usePriceChanges(bid);
  const shouldShowPriceChange = priceChange?.status === 'open' && !priceChange?.paid;
  const polyglot = usePolyglot();

  if (!shouldShowPriceChange) {
    return null;
  }

  const currency = getSymbolFromCurrency(bookingData.booking.price.currency);
  const oldPrice = `${bookingData.booking.price.amount} ${currency}`;
  const difference = `${priceChange.price} ${currency}`;
  const newPrice = `${precisionRound(
    parseFloat(bookingData.booking.price.amount) + parseFloat(priceChange.price),
    3,
  )} ${currency}`;

  return (
    <Card title={polyglot.t('mmb.booking_details.price_change')}>
      <PriceDisplay
        oldPrice={oldPrice}
        newPrice={newPrice}
        difference={difference}
        id={priceChange.id}
        bid={bid}
        loading={payingPriceChange}
        onClick={onClick}
      />
    </Card>
  );
};

export default React.memo(PriceChange);
