import styled from 'styled-components';

import AttachmentIcon from '@kiwicom/orbit-components/lib/icons/Attachment';
import { TextLink } from '@kiwicom/orbit-components';

const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.orbit.spaceXXSmall};
`;

const Attachment = ({ fileName, onClick }) => {
  return (
    <AttachmentWrapper>
      <AttachmentIcon />
      <TextLink onClick={onClick}>{fileName}</TextLink>
    </AttachmentWrapper>
  );
};

export default Attachment;
