import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const smallWrapperCSS = css`
  height: 32px;
  font-size: 14px;
  & > ${Container} {
    padding: 0 12px;
  }
`;

const borderCSS = css`
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-radius: 3px;
`;

const disabledCSS = css`
  opacity: ${({ theme }) => theme.orbit.opacityButtonDisabled};
  cursor: not-allowed;
  background-color: ${({ theme }) => theme.orbit.backgroundInputDisabled};
`;

export const StyledWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.orbit.backgroundInput};

  & > ${Container} {
    padding: 0 16px;
  }

  ${({ size }) => size === 'small' && smallWrapperCSS};
  ${({ border }) => border && borderCSS};
  ${({ disabled }) => disabled && disabledCSS};
`;
