import styled from 'styled-components';

export const Container = styled.div`
  max-width: 880px;
  margin-top: 24px;
  margin-left: 24px;
`;

export const FooterContainer = styled.div`
  padding: 20px;
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
