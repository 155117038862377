import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import Radio from '@kiwicom/orbit-components/lib/Radio';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import BaggagePersonalNone from '@kiwicom/orbit-components/lib/icons/BaggagePersonalNone';

import { usePolyglot } from 'components/services/i18n';

import OptionItem from './OptionItem';

const LineHeightFix = styled.div`
  > p {
    line-height: 24px;
  }
`;

const EmptyLabel = ({ pickerType, isCurrentCombination, isInfant }) => {
  const polyglot = usePolyglot();
  if (!isInfant) {
    return (
      <Stack spacing="XSmall" align="start" justify="between" dataTest="BaggagePicker-EmptyLabel">
        <Stack flex align="start" spacing="XSmall" inline>
          <CloseIcon size="medium" />
          <LineHeightFix>
            <Text>
              {pickerType === 'handBag'
                ? polyglot.t('mmb.booking_details.add_baggage_modal.option.no_cabin')
                : polyglot.t('mmb.booking_details.add_baggage_modal.option.no_checked')}
            </Text>
          </LineHeightFix>
        </Stack>
        {isCurrentCombination && (
          <Stack flex align="center" justify="end" inline>
            <LineHeightFix>
              <Text as="p" weight="bold" type="secondary">
                {polyglot.t('mmb.booking_details.add_baggage_modal.option.current')}
              </Text>
            </LineHeightFix>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack flex>
      <CloseIcon size="medium" color="critical" />
      <LineHeightFix>
        <Text>
          {pickerType === 'handBag'
            ? polyglot.t('mmb.booking_details.add_baggage_modal.option.personal_items')
            : polyglot.t('mmb.booking_details.add_baggage_modal.option.checked_baggage')}
        </Text>
      </LineHeightFix>
    </Stack>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.orbit.paletteWhite};
  padding: ${({ theme }) => theme.orbit.spaceSmall};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.orbit.paletteWhiteHover};
  border: solid 2px
    ${({ theme, checked }) =>
      checked ? `${theme.orbit.colorTextButtonPrimaryBordered}` : `${theme.orbit.borderColorCard}`};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  outline: solid 1px white;
  outline-offset: ${({ checked }) => (checked ? '-3px' : '-2px')};
  overflow: auto;
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.orbit.borderColorCheckboxRadioHover};
    outline-offset: -3px;
  }

  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const RadioWrapper = styled.div`
  div {
    margin-top: 2px;
  }
`;

const Option = ({
  items,
  price,
  shouldConvertPrice,
  dataTest,
  isChecked,
  onClick,
  isCurrentCombination,
  pickerType,
  isPersonalItemPresent,
  isInfant,
  isError,
}) => {
  const itemsArr = R.values(items);
  const firstItem = R.head(itemsArr);
  const polyglot = usePolyglot();

  return (
    <Wrapper onClick={onClick} checked={isChecked} data-test={dataTest}>
      <Stack flex spacing="XSmall">
        <RadioWrapper>
          <Radio checked={isChecked} onChange={onClick} hasError={isError} />
        </RadioWrapper>
        <Stack shrink flex spacing="XXXSmall" direction="column">
          {itemsArr.length > 0 ? (
            itemsArr.map((item, index) => (
              <OptionItem
                key={index}
                amount={item.amount}
                restrictions={item.restrictions}
                category={item.category}
                isFirstItem={index === 0}
                price={price}
                shouldConvertPrice={shouldConvertPrice}
                isCurrentCombination={isCurrentCombination}
              />
            ))
          ) : (
            <EmptyLabel
              pickerType={pickerType}
              isCurrentCombination={isCurrentCombination}
              isInfant={isInfant}
            />
          )}
          {firstItem?.category === 'cabinBag' && isPersonalItemPresent && (
            <Stack flex align="center" spacing="XXSmall" dataTest="bag-picker-no-personal-item">
              <BaggagePersonalNone color={isChecked ? 'warning' : 'secondary'} />
              <Text type={isChecked ? 'warning' : 'secondary'}>
                {polyglot.t('mmb.booking_details.add_baggage_modal.option.no_personal_item')}
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Wrapper>
  );
};

export default Option;
