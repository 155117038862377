/**
 * WindowMessage is a postMessage API two-side communication wrapper with
 * message acknowledgement.
 *
 * Example usage:
 *  Sender
 *      sendWindowMessage(windowObject, windowOrigin, { key: "example"})
 *
 *  - returned promise will fail if message was not acknowledged by the
 *    receiver (considered not delivered) and there are no more retries
 *
 *  Receiver
 *      const msg = await receiveWindowMessage =
 *                    receiveWindowMessage(senderOriginArray, ["key"])
 *      // here, msg has a value of { key: "example" }
 *
 *  - returned promise will fail if there was a timeout (no timeout by default)
 *
 */

/**
 * Checks if the received message payload has all the keys specified in
 * messageFormat array.
 * @param {Object} message
 * @param {Array<sting>} messageFormat
 */
const messageValid = (message, messageFormat) => {
  if (Array.isArray(messageFormat)) {
    const formatKeys = Object.keys(message);
    return messageFormat.every(key => formatKeys.includes(key));
  }
  return false;
};

const handleMessage = (origins, messageFormat, resolve) => {
  // named function necessary for removing eventHandler
  function eventHandler(event) {
    const { data, source, origin } = event;
    if (origins.includes(origin)) {
      try {
        const { id, message } = JSON.parse(data);
        if (!messageValid(message, messageFormat)) return;

        // Respond with the ack message format only if the received message is
        // not an acknowledgement message itself
        if (!message.ack) {
          source.postMessage(JSON.stringify({ id, message: { ack: id } }), origin);
        }
        resolve(message);
      } catch (e) {
        return;
      }
      window.removeEventListener('message', eventHandler);
    }
  }
  return eventHandler;
};

/**
 * Receive a message from a specific window.
 * @param {Array.<String>} origins - event origins whose message will be accepted
 * @param {Array.<String>} messageFormat - expected message format, array of string
 * keys which expected message (stringified object) must have
 * @param {Number} [timeout=0] - listening timeout in milliseconds, no timeout by
 * default (timeout=0)
 */
export const receiveWindowMessage = (origins, messageFormat, timeout = 0): Promise<any> =>
  new Promise((resolve, reject) => {
    const handler = handleMessage(origins, messageFormat, resolve);
    window.addEventListener('message', handler);

    if (timeout > 0) {
      setTimeout(() => {
        window.removeEventListener('message', handler);
        reject(new Error('Message receive timeout'));
      }, timeout);
    }
  });

class MessageSender {
  constructor(target, targetOrigin, message, tries, retryInterval) {
    this.ack = false;
    // Generate a random string for an id with alphanumeric characters, to
    // avoid false positive acknowledgements
    this.id = Math.random().toString(36).substring(2, 15);
    this.target = target;
    this.targetOrigin = targetOrigin;
    this.message = message;
    this.tries = tries;
    this.retryInterval = retryInterval;
  }

  send = () => {
    this.sendMessage();
    this.ackReceive();

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  };

  ackReceive = () => {
    receiveWindowMessage([this.targetOrigin], ['ack']).then(msg => {
      if (msg.ack === this.id) {
        this.ack = true;
      } else {
        this.ackReceive();
      }
    });
  };

  sendMessage = () => {
    if (this.ack) {
      this.resolve();
      return;
    }
    this.tries -= 1;
    if (this.tries < 0) {
      this.reject(new Error(`Message not acknowledged`));
      return;
    }

    let targetWindow;
    // target.contentWindow used for parent -> iframe
    // target used for iframe -> parent (contentWindow not accessible)
    try {
      targetWindow = this.target.contentWindow;
    } catch (e) {
      targetWindow = this.target;
    }

    if (targetWindow) {
      const payload = { id: this.id, message: this.message };
      targetWindow.postMessage(JSON.stringify(payload), this.targetOrigin);
      const instance = this;
      setTimeout(() => {
        instance.sendMessage();
      }, this.retryInterval);
    }
  };
}

/**
 * Send a message to a window.
 * @param {Object} target - React ref to the target window, set in target component
 * ref prop
 * @param {String} targetOrigin - targetWindow origin, URI, ensures safety by only
 * sending messages to a known target
 * @param {Object} message - message payload
 * @param {Number} [tries=5] - number of times to try and resend the message in
 * case window has failed to respond with an acknowledgement message
 * @param {Number} [retryInterval=500] - time between retries in milliseconds
 */
export const sendWindowMessage = (
  target,
  targetOrigin,
  message,
  tries = 5,
  retryInterval = 500,
) => {
  return new MessageSender(target, targetOrigin, message, tries, retryInterval).send();
};
