import { AUTOMATCHED } from 'consts/partnerPayments';

const v2_get_from = (pp, polyglot) => {
  const automatch = getTargetAuto(pp);
  return {
    id: pp.id,
    date: pp.booking_date || pp.value_date || pp.created_at,
    amount: pp.amount,
    company:
      pp?.target_final?.payload?.company?.name ||
      pp?.target_manual?.payload?.company?.name ||
      automatch?.[1] ||
      polyglot.t('partner_payments.company_not_identified'),
    status: pp.status === 'imported' ? automatch[1] || pp.status : pp.status,
    targets: automatch[0],
    entryRef: pp.entry_ref,
    bookingDate: pp.booking_date,
    valueDate: pp.value_date,
    domainCode: pp?.bank_code?.domain?.code,
    domainFamilyCode: pp?.bank_code?.domain?.family?.code,
    domanSubFamilyCode: pp?.bank_code?.domain?.family?.subfamily,
    proprietaryCode: pp?.bank_code?.proprietary?.code,
    proprietaryIssuer: pp?.bank_code?.proprietary?.issuer,
    additionalEntryInformation: pp.entry_info,
    endToEndIdentification: pp?.detail_ref?.end_to_end_id,
    debtorName: pp?.detail_debtor?.name,
    debtorAddressType: pp?.detail_debtor?.address?.type,
    debtorAddress: getAddress(pp?.detail_debtor?.address?.address_lines),
    debtorAccount: pp.detail_debtor_account && getAccountId(pp.detail_debtor_account),
    creditorAccount: pp.detail_creditor_account && getAccountId(pp.detail_creditor_account),
    debtorAgentName: pp?.detail_debtor_agent?.name,
    remittance: pp.detail_remittance,
    additionalInfo: pp.detail_additional_info,
    acceptanceDate: pp.detail_related_dates?.acceptance,
    currency: pp.currency,
    depositAccount: pp?.target_final?.payload?.deposit_account?.email,
  };
};

const getAccountId = account => {
  if (account.id.other) {
    return account?.id?.other?.id;
  } else {
    return account?.id?.iban;
  }
};

const getAddress = address => {
  if (Array.isArray(address)) {
    return address.join(', ');
  } else {
    return address;
  }
};

const getTargetAuto = pp => {
  const targets = [];
  if (pp?.target_auto === null) {
    return [];
  }

  for (const key in pp.target_auto) {
    if (pp.target_auto[key].target !== undefined) {
      targets.push(pp.target_auto[key].target);
    }
  }

  if (targets.length) {
    return [targets, AUTOMATCHED];
  }

  return targets;
};

const v2_post_to = (user, companyName, depositAccount) => ({
  approver: {
    email: user.email,
    uuid: user.uuid,
  },
  target: {
    payload: {
      company: {
        name: companyName,
      },
      deposit_account: {
        affiliate_id: depositAccount.affiliateId,
        email: depositAccount.depositAcc,
      },
    },
    type: 'manual',
    version: 2,
  },
});

export default {
  v2: {
    get: {
      from: v2_get_from,
    },
    post: {
      to: v2_post_to,
    },
  },
};
