import * as R from 'ramda';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';

import { monthsAbbreviationValues } from 'consts/dates';
import {
  tripTypesValues,
  passengerTypes,
  cabinClassTypesValues,
  stopoversRadioValues,
  classTypeSmartpoint,
} from 'consts/search';

const getNormalizedDate = (day, month) => {
  let date = new Date();
  const m = R.isNil(monthsAbbreviationValues[month]) ? month : monthsAbbreviationValues[month] - 1;

  date.setTime(Date.UTC(date.getUTCFullYear(), m, day, 0, 0, 0));
  if (isPast(date)) {
    date = addYears(date, 1);
  }
  return date.toISOString();
};

const getTime = timeParsed => {
  if (!timeParsed) {
    return [0, 24];
  } else if (timeParsed.match(/M/)) {
    return [4, 12];
  } else if (timeParsed.match(/N/)) {
    return [12, 20];
  } else if (timeParsed.match(/E/)) {
    return [20, 4];
  } else if (timeParsed.match(/[0-9]{4}/)) {
    const h = parseInt(timeParsed.substring(0, 2));
    return [h - 1, h + 1];
  } else {
    return null;
  }
};

export default ({
  prefix,
  from,
  to,
  day,
  month,
  returnDay,
  returnMonth,
  tripType,
  passengers,
  options,
  returnOptions,
  unparsed,
}) => {
  let messages = [];
  if (!unparsed) {
    unparsed = [];
  }
  if (!returnOptions) {
    returnOptions = {};
  }

  const departureDate = getNormalizedDate(day, month);

  /**
   * Passengers
   *  - unsupported passenger codes are added as unparsed commands and not counted in search
   *  - infants need an adult to search
   *  - if no passengers value was present, use default (1 adult)
   */
  if (passengers && passengers.unsupported) {
    unparsed = [...unparsed, ...passengers.unsupported];
  }

  if (passengers) {
    if (
      passengers[passengerTypes.ADULTS] === 0 &&
      passengers[passengerTypes.CHILDREN] === 0 &&
      passengers[passengerTypes.INFANTS] !== 0
    ) {
      passengers[passengerTypes.ADULTS] = 1;
      messages.push({ id: 'adult_required', type: 'warning' });
    }
  } else {
    passengers = {
      [passengerTypes.ADULTS]: 1,
      [passengerTypes.CHILDREN]: 0,
      [passengerTypes.INFANTS]: 0,
    };
  }

  const passengersAmounts = Object.values(passengerTypes).map(type => ({
    type,
    amount: passengers[type],
    minAmount: passengers[type],
  }));

  /**
   * Class Type
   *  - if unknown class type was provided, add as unparsed and use default
   *  - different options for class type per leg are not supported
   */
  let selectedCabinClassType;
  const { classType } = options;
  const { classType: returnClassType } = returnOptions;

  if (tripType === tripTypesValues.ONEWAY) {
    selectedCabinClassType = classTypeSmartpoint[classType];
    if (!selectedCabinClassType) {
      if (classType) {
        unparsed.push(classType);
      }
      selectedCabinClassType = cabinClassTypesValues.ECONOMY;
    }
  } else {
    if (classType && returnClassType) {
      if (classType !== returnClassType) {
        messages.push({ id: 'legs_different_options', type: 'warning' });
      }
      selectedCabinClassType = classTypeSmartpoint[classType];
    } else if (classType || returnClassType) {
      selectedCabinClassType = classTypeSmartpoint[classType || returnClassType];
      if (!selectedCabinClassType) {
        unparsed.push(classType || returnClassType);
        selectedCabinClassType = cabinClassTypesValues.ECONOMY;
      }
    } else {
      selectedCabinClassType = cabinClassTypesValues.ECONOMY;
    }
  }

  /**
   * Connections
   *  - connections are not supported at all, return null to fail search
   */
  //
  if (options.connections || returnOptions.connections) {
    return null;
  }

  /**
   * Number of stopovers
   *  - different options per leg are not supported, use DIRECT if it was specified on any leg
   */
  let numberOfStopovers = options.onlyDirect
    ? stopoversRadioValues.DIRECT
    : stopoversRadioValues.ANY;

  if (tripType === tripTypesValues.RETURN && options.onlyDirect !== returnOptions.onlyDirect) {
    numberOfStopovers = stopoversRadioValues.DIRECT;
    messages.push({ id: 'legs_different_options', type: 'warning' });
  }

  /**
   * Carriers
   *  - different options per leg for carriers are not supported, use originating flight option
   */
  // TODO: add carriers to filters
  // eslint-disable-next-line no-unused-vars
  const carriers = options.carriers;
  if (tripType === tripTypesValues.RETURN && !R.equals(options.carriers, returnOptions.carriers)) {
    messages.push({ id: 'legs_different_options', type: 'warning' });
  }

  messages = R.uniqBy(m => m.id, messages);
  if (tripType === tripTypesValues.ONEWAY) {
    return {
      prefix,
      messages,
      unparsed,
      searchState: {
        tripType: tripTypesValues.ONEWAY,
        searchbar: {
          [tripTypesValues.ONEWAY]: {
            locationsFrom: [{ label: from, value: from }],
            locationsTo: [{ label: to, value: to }],
            departureSelectedDates: [departureDate],
            returnSelectedDates: [null],
          },
        },
        passengersAmounts,
        selectedCabinClassType,
        departureHours: getTime(options.time),
        numberOfStopovers,
      },
    };
  } else if (tripType === tripTypesValues.RETURN) {
    // If no month is provided, use relative to departure
    let returnSelectedDates;
    if (!returnMonth && !returnDay) {
      returnSelectedDates = eachDayOfInterval({
        start: new Date(departureDate),
        end: addDays(new Date(departureDate), 30),
      }).map(d => getNormalizedDate(d.getDate(), d.getMonth()));
    } else if (!returnMonth && returnDay) {
      returnSelectedDates = [addDays(new Date(departureDate), returnDay).toISOString()];
    } else {
      returnSelectedDates = [getNormalizedDate(returnDay, returnMonth)];
    }

    return {
      prefix,
      messages,
      unparsed,
      searchState: {
        tripType: tripTypesValues.RETURN,
        searchbar: {
          [tripTypesValues.RETURN]: {
            locationsFrom: [{ label: from, value: from }],
            locationsTo: [{ label: to, value: to }],
            departureSelectedDates: [departureDate],
            returnSelectedDates,
          },
        },
        passengersAmounts,
        selectedCabinClassType,
        departureHours: getTime(options.time),
        returnDepartureHours: getTime(returnOptions.time),
        numberOfStopovers,
      },
    };
  } else {
    return null;
  }
};
