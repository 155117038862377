import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import Button from '@kiwicom/orbit-components/lib/Button';
import SendIcon from '@kiwicom/orbit-components/lib/icons/Send';
import TicketOutlineIcon from '@kiwicom/orbit-components/lib/icons/TicketOutline';

import { ReactComponent as SolutionsIcon } from 'images/Icons_Solutions.svg';
import { Card, CardActionHeader } from 'components/common/Card';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { Authorization } from 'common/Authorization';
import {
  BIDVIEWER_APP_ID,
  SMART_PASS_APP_ID,
  DEMAND_DASHBOARD_ID,
  DESTINATION_DEMAND_DASHBOARD_ID,
  DASHBOARDS,
} from 'consts/specialApps';

const getIcon = definitionId => {
  switch (definitionId) {
    case BIDVIEWER_APP_ID:
      return <TicketOutlineIcon />;
    case SMART_PASS_APP_ID:
      return <SendIcon />;
    case DEMAND_DASHBOARD_ID:
      return <SolutionsIcon />;
    case DESTINATION_DEMAND_DASHBOARD_ID:
      return <SolutionsIcon />;
    default:
      return null;
  }
};

const SpecialAppsLinks = () => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const specialApps = useSpecialApps(companyName);

  if (!specialApps.apps) {
    return null;
  }

  return specialApps.apps.map(app => {
    if (app.deleted || !app.name) {
      return null;
    }

    const icon = getIcon(app.definitionId);
    let resource = '';
    let route = '';
    //If the special app is the demand dashboard, resource is null so authorization fails and the link is not rendered
    if (DASHBOARDS.includes(app.name)) {
      resource = null;
    } else {
      resource = `special_app.${app.name.replace(/-/g, '_')}`;
      route = `${ROUTE_CONFIG.COMPANY.toPath}/${companyName}/${app.name}`;
    }

    return (
      <Authorization
        key={app.id}
        resource={resource}
        action={['read', 'read_assigned', 'read_own']}
      >
        <Card>
          <CardActionHeader
            title={app.displayName}
            icon={icon}
            action={
              <Link to={route} key={app.name}>
                <Button type="primary" size="small" dataTest={`${app.name}-button-link`}>
                  {polyglot.t('common.view')}
                </Button>
              </Link>
            }
          />
        </Card>
      </Authorization>
    );
  });
};

export default SpecialAppsLinks;
