import { useContext } from 'react';

import { SaveBookingState } from './SaveBookingProvider';

const useSaveBooking = () => {
  const saveBookingCtx = useContext(SaveBookingState);

  return saveBookingCtx;
};

export default useSaveBooking;
