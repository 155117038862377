import React, { useEffect } from 'react';

import { useToggle } from 'utils/hooks';

import { usePolyglot } from 'components/services/i18n';

import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';

const ClosableAlert = ({ children, ...props }) => {
  const polyglot = usePolyglot();
  const show = useToggle(true);
  const closeMe = show.setOff;

  useEffect(() => {
    if (props.timeout) {
      const timeoutTime = (props.timeout === true && 8000) || props.timeout;
      const timeout = setTimeout(closeMe, timeoutTime);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    show.isOn && (
      <Alert {...props} onClose={closeMe}>
        {children}
        {props.okButton && (
          <AlertButton space type="info" onClick={closeMe}>
            {polyglot.t('common.ok_got_it')}
          </AlertButton>
        )}
      </Alert>
    )
  );
};

export default ClosableAlert;
