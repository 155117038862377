import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Text from '@kiwicom/orbit-components/lib/Text';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import { SeatingSegmentWrapper } from './index.styled';
import ActionButton from '../ActionButton';

const SeatingSegment = ({
  cityFrom,
  cityTo,
  airline,
  id,
  disabled,
  onOpenDrawer,
  areActionButtonsDisabled,
}) => {
  return (
    <SeatingSegmentWrapper>
      <Box
        display="flex"
        direction="row"
        justify="between"
        align="center"
        padding="medium"
        height="64px"
      >
        <Inline align="center" spacing="XSmall">
          <CarrierLogo carriers={[{ code: airline }]} size="medium" />
          <Inline align="center" spacing="XXSmall">
            <Text weight="bold">{cityFrom}</Text>
            <FlightDirectIcon />
            <Text weight="bold">{cityTo}</Text>
          </Inline>
        </Inline>
        <Inline align="center" spacing="large">
          <ActionButton
            disabled={disabled}
            routeId={id}
            onOpenDrawer={onOpenDrawer}
            areActionButtonsDisabled={areActionButtonsDisabled}
          />
        </Inline>
      </Box>
    </SeatingSegmentWrapper>
  );
};

export default SeatingSegment;
