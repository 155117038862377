import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { useToggle } from 'utils/hooks';

import Button from '@kiwicom/orbit-components/lib/Button';
import Drawer from '@kiwicom/orbit-components/lib/Drawer';

import { generateScriptFromFormValues, createPreviewCodeAndAttachToRefs } from 'utils/widgets';
import { initialValues } from 'consts/widgets';
import copyToClipboard from 'utils/copyToClipboard';
import { usePolyglot } from 'components/services/i18n';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > button:first-of-type {
    margin-right: 12px;
  }
`;

const WidgetPreviewDrawer = ({ drawerShown, widgetConfigValues, closeDrawer }) => {
  const polyglot = usePolyglot();
  const copiedToClipboard = useToggle(false);

  const values = drawerShown ? widgetConfigValues : initialValues;

  const scriptRef = useRef(null);
  const holderRef = useRef(null);

  useEffect(() => {
    createPreviewCodeAndAttachToRefs(values, drawerShown, scriptRef, holderRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerShown]);

  const handleCopyClick = () => {
    copyToClipboard(generateScriptFromFormValues(values));

    copiedToClipboard.setOn();

    setTimeout(() => {
      copiedToClipboard.setOff();
    }, 2000);
  };

  // Disabled due to XSS
  // const handleFullPreviewClick = () => {
  //   const link = document.createElement('a');
  //   link.target = 'blank';
  //   link.href = `${window.location.origin}/widget-preview?script=${encodeURIComponent(
  //     values.script,
  //   )}`;
  //   link.click();
  // };

  return (
    <Drawer
      shown={drawerShown}
      position="right"
      width="644px"
      onClose={closeDrawer}
      actions={
        <ActionsWrapper>
          <Button type="primary" size="small" onClick={handleCopyClick}>
            {copiedToClipboard.isOn
              ? polyglot.t('common.copied_to_clipboard')
              : polyglot.t('common.copy_to_clipboard')}
          </Button>
        </ActionsWrapper>
      }
    >
      <div id={values?.advanced?.containerId || 'widget-holder'} ref={holderRef}></div>
      <div ref={scriptRef}></div>
    </Drawer>
  );
};

export default WidgetPreviewDrawer;
