import React, { useContext } from 'react';
import * as dfs from 'date-fns';
import { InputField } from '@kiwicom/orbit-components';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import { useToggle } from 'utils/hooks';
import useTransactions from 'components/services/transactions/useTransactions';
import { usePolyglot } from 'components/services/i18n';
import { ClickOutside } from 'common';

import { SuffixIconWrapper, InputDropdownWrapper, InputFieldWrapper } from './index.styled';
import TimeframeFilterDropdown from './TimeframeFilterDropdown';
import { TimeFilterState } from '../services/TimeFilters';

const TimeframeFilter = () => {
  const isTimeframeFilterDropdown = useToggle(false);
  const { filterCreatedFrom, filterCreatedTo } = useTransactions();
  const polyglot = usePolyglot();

  const { clearSelectedFilters } = useContext(TimeFilterState);

  const handleOnClose = () => {
    clearSelectedFilters();
    isTimeframeFilterDropdown.setOff();
  };

  const formattedDate =
    filterCreatedFrom || filterCreatedTo
      ? `${filterCreatedFrom ? dfs.format(new Date(filterCreatedFrom), 'dd/MM/yyyy') : ''} - ${
          filterCreatedTo ? dfs.format(new Date(filterCreatedTo), 'dd/MM/yyyy') : ''
        }`
      : '';

  return (
    <ClickOutside onClick={handleOnClose}>
      <InputFieldWrapper>
        <InputField
          onFocus={isTimeframeFilterDropdown.setOn}
          placeholder={polyglot.t('tequila.mmb.credit_activity.filters.timeframe.placeholder')}
          label={polyglot.t('tequila.mmb.credit_activity.filters.timeframe.label')}
          value={formattedDate}
          suffix={
            <SuffixIconWrapper>
              <ChevronDownIcon size="small" />
            </SuffixIconWrapper>
          }
          readOnly
          size="small"
        />
        {isTimeframeFilterDropdown.isOn && (
          <InputDropdownWrapper isExpanded={isTimeframeFilterDropdown.isOn} width="570px">
            <TimeframeFilterDropdown
              onClose={isTimeframeFilterDropdown.setOff}
              onCancel={handleOnClose}
              isOpened={isTimeframeFilterDropdown.isOn}
            />
          </InputDropdownWrapper>
        )}
      </InputFieldWrapper>
    </ClickOutside>
  );
};

export default TimeframeFilter;
