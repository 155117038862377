import React from 'react';
import * as R from 'ramda';
import BaggageChecked30 from '@kiwicom/orbit-components/lib/icons/BaggageChecked30';
import BaggagePersonal from '@kiwicom/orbit-components/lib/icons/BaggagePersonal';
import BaggageCabin from '@kiwicom/orbit-components/lib/icons/BaggageCabin';

const getTextFromCategory = category => {
  switch (category) {
    case 'personalItem':
      return 'mmb.booking_details.personal_item';
    case 'cabinBag':
      return 'mmb.booking_details.cabin_bag';
    case 'holdBag':
      return 'mmb.booking_details.checked_bag';
    default:
      return null;
  }
};

const getIconFromCategory = (category, size, color) => {
  switch (category) {
    case 'personalItem':
      return <BaggagePersonal size={size} color={color} />;
    case 'cabinBag':
      return <BaggageCabin size={size} color={color} />;
    case 'holdBag':
      return <BaggageChecked30 size={size} color={color} />;
    default:
      return null;
  }
};

const getBaggageSize = ({ height, length, weight, width }) => {
  return `${length || 0} × ${width || 0} × ${height || 0} cm, ${weight || 0} kg`;
};

const getPersonalItemPresence = ({ pickerType, options }) => {
  if (pickerType === 'holdBag') return false;
  return options
    .reduce((acc, option) => {
      const items = R.values(option.items).map(item => item.category);
      return [...acc, ...items];
    }, [])
    .some(i => i === 'personalItem');
};

const groupPerFlight = bags => {
  const groupBy = R.groupBy(bag => {
    return bag.flightId;
  });
  return R.values(groupBy(bags));
};
const groupPerType = (bags, ancillaryBags) => {
  const groupBy = R.groupBy(item => {
    const additionalBookingIdBag = ancillaryBags.find(
      ancillary => ancillary.extraId === item.additionalBookingId,
    );

    return `${item.bag.category}--${item.bag.height}--${item.bag.length}--${item.bag.weight}--${
      item.bag.width
    }--${String(!!additionalBookingIdBag && additionalBookingIdBag.status !== 'confirmed')}`;
  });
  return groupBy(bags);
};
const getCurrentPaxBags = (baggage, passengerId, ancillaries) => {
  // Filter by passenger ID
  const bags = baggage.filter(bag => bag.passengerId === passengerId);

  // Group items per flight (needed because all baggage categories are flattened)
  const groupedPerFlight = groupPerFlight(bags);

  // Group items per dimensions, only first element from grouped per flight is relevant
  const groupedPerType = groupPerType(groupedPerFlight[0] || [], ancillaries?.bags || []);

  const countedBaggage = Object.keys(groupedPerType).reduce((acc, groupKey) => {
    const additionalBookingIdBag = ancillaries?.bags?.find(
      ancillary => ancillary.extraId === groupedPerType[groupKey][0].additionalBookingId,
    );

    return [
      ...acc,
      {
        count: groupedPerType[groupKey].length,
        data: groupedPerType[groupKey][0],
        orderStatus: additionalBookingIdBag?.status,
      },
    ];
  }, []);

  return countedBaggage;
};

export {
  getBaggageSize,
  getIconFromCategory,
  getTextFromCategory,
  getPersonalItemPresence,
  getCurrentPaxBags,
};
