import styled from 'styled-components';

// Row
const rowPaddingYDesktopNewSeatMap = '6px';
// Seat
const seatSizeDesktop = '36px';
const seatSizeHeightNewSeatMap = '72px';
const seatPaddingXDesktopNewSeatMap = '12px';
// Aisle
const aisleNewSeatMapPadding = '10px';
const aisleWidthDesktop = '16px';
// Exit
const exitRowHeightDesktop = '40px';
const titleRowHeightDesktop = '30px';

export const Row = styled.div`
  display: flex;
  height: ${({ isSeatingClassRow }) => (isSeatingClassRow ? 'auto' : seatSizeHeightNewSeatMap)};
  flex-direction: row;
  margin: ${({ isSeatsRow }) => (isSeatsRow ? rowPaddingYDesktopNewSeatMap : 0)} 0;
`;

export const ExitRow = styled.div`
  width: 100%;
  height: ${exitRowHeightDesktop};
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  padding: 0 ${({ theme }) => theme.orbit.spaceSmall};
  margin: 8px 0;
  border-left: 2px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-right: 2px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  text-transform: uppercase;
`;

export const RowGroupItem = styled.div`
  display: inline-flex;
  width: ${({ aisle }) => (aisle ? aisleWidthDesktop : seatSizeDesktop)};
  // to prevent props being passed to dom element the prop needs to be prefixed with "$" sign https://styled-components.com/docs/api#transient-props
  height: ${({ $title }) => ($title ? titleRowHeightDesktop : seatSizeHeightNewSeatMap)};
  padding: 0 ${({ aisle }) => (aisle ? aisleNewSeatMapPadding : seatPaddingXDesktopNewSeatMap)};
  text-align: center;
  justify-content: center;
  align-items: ${({ aisle }) => (aisle ? 'center' : 'flex-end')};
`;

export const AisleLetter = styled.div`
  transform: translateY(-5px);
`;
