import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'components/services/auth';
import { I18nProvider } from 'components/services/i18n';
import CompanyProvider from 'components/services/company';
import CompaniesProvider from 'components/services/companies';
import CompanyUsersProvider from 'components/services/companyUsers';
import UsersProvider from 'components/services/users';
import AccountManagersProvider from 'components/services/accountManagers';
import SpecialAppsProvider from 'components/services/specialApps';
import SolutionsProvider from 'components/services/solutions';
import BillingCyclesProvider from 'components/services/billingCycles';
import LogsProvider from 'components/services/logs';
import ResourcesProvider from 'components/services/resources';
import SmartPassProvider from 'components/services/smartPass';
import CommercialsProvider from 'components/services/commercials';
import DepositProvider from 'components/services/deposit';
import ProductsProvider from 'components/services/products';
import InitialQueryParamsProvider from 'components/services/initialQueryParams';
import SmartpointProvider from 'components/services/smartpoint';
import { BookingToolProvider, InfoMessagesProvider } from 'components/services/bookingTool';
import BidViewerProvider from 'components/services/bidViewer';
import MMBProvider from 'components/services/mmb';
import BankAccountsProvider from 'components/services/bankAccounts';
import { BookingFlowProvider, BookingProvider } from 'components/services/booking';
import { SearchProvider } from 'components/services/search';
import PartnerPaymentsProvider from 'components/services/partnerPayments';
import { CompanySettingsProvider } from 'components/services/companySettings';
import { CurrenciesProvider } from 'components/services/currencies';
import Auth from 'components/scenes/Auth';
import Debug from 'components/scenes/Debug';
import Portal from 'components/scenes/Portal';
import LocalStorageProvider from 'components/services/localStorage';
import BillingProvider from 'components/services/billing';
import { TranslationProvider } from 'components/services/translations';
import SeatingProvider from 'components/services/bookingToolSeating';
import HelpProvider from 'components/services/help';

import { UTMProcessor, ErrorBoundary, Messages, SubmitError, SaveChangesComponent } from 'common';
import theme from 'styles/theme';
import { ROUTE_CONFIG } from 'consts/routes';

import GlobalStyles from './styles';

const App = () => {
  const [confirm, setConfirm] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [saveChangesRoute, setSaveChangesRoute] = useState(null);

  const getConfirmation = (message, callback) => {
    setConfirmCallback(() => callback);
    setConfirm(true);
    setSaveChangesRoute(message);
  };

  return (
    <TranslationProvider>
      <LocalStorageProvider>
        <ThemeProvider theme={theme}>
          <I18nProvider>
            <ErrorBoundary>
              <AuthProvider>
                <ProductsProvider>
                  <CompaniesProvider>
                    <UsersProvider>
                      <CompanyProvider>
                        <AccountManagersProvider>
                          <SpecialAppsProvider>
                            <CompanyUsersProvider>
                              <SolutionsProvider>
                                <BillingCyclesProvider>
                                  <CommercialsProvider>
                                    <LogsProvider>
                                      <ResourcesProvider>
                                        <DepositProvider>
                                          <SmartpointProvider>
                                            <SmartPassProvider>
                                              <BookingToolProvider>
                                                <InfoMessagesProvider>
                                                  <BidViewerProvider>
                                                    <HelpProvider>
                                                      <MMBProvider>
                                                        <BankAccountsProvider>
                                                          <SearchProvider>
                                                            <BookingProvider>
                                                              <BookingFlowProvider>
                                                                <SeatingProvider>
                                                                  <CurrenciesProvider>
                                                                    <PartnerPaymentsProvider>
                                                                      <BillingProvider>
                                                                        <CompanySettingsProvider>
                                                                          <GlobalStyles />
                                                                          <Router
                                                                            basename="portal"
                                                                            getUserConfirmation={
                                                                              getConfirmation
                                                                            }
                                                                          >
                                                                            <InitialQueryParamsProvider>
                                                                              <UTMProcessor />
                                                                              <Switch>
                                                                                <Route
                                                                                  path="/debug"
                                                                                  render={props => (
                                                                                    <Debug
                                                                                      {...props}
                                                                                    />
                                                                                  )}
                                                                                />
                                                                                <Route
                                                                                  path={[
                                                                                    ROUTE_CONFIG
                                                                                      .LOGIN.path,
                                                                                    ROUTE_CONFIG
                                                                                      .LANDING.path,
                                                                                    ROUTE_CONFIG
                                                                                      .RESET_PASSWORD
                                                                                      .path,
                                                                                  ]}
                                                                                  render={props => (
                                                                                    <Auth
                                                                                      {...props}
                                                                                    />
                                                                                  )}
                                                                                />
                                                                                <Route
                                                                                  path={
                                                                                    ROUTE_CONFIG
                                                                                      .PORTAL.path
                                                                                  }
                                                                                  render={props => (
                                                                                    <Portal
                                                                                      {...props}
                                                                                    />
                                                                                  )}
                                                                                />
                                                                                <Route
                                                                                  render={() => (
                                                                                    <Redirect
                                                                                      to={
                                                                                        ROUTE_CONFIG
                                                                                          .PORTAL
                                                                                          .path
                                                                                      }
                                                                                    />
                                                                                  )}
                                                                                />
                                                                              </Switch>
                                                                              {confirm && (
                                                                                <SaveChangesComponent
                                                                                  setConfirm={
                                                                                    setConfirm
                                                                                  }
                                                                                  confirmCallback={
                                                                                    confirmCallback
                                                                                  }
                                                                                  saveChangesRoute={
                                                                                    saveChangesRoute
                                                                                  }
                                                                                />
                                                                              )}
                                                                              <Messages />
                                                                              <SubmitError />
                                                                            </InitialQueryParamsProvider>
                                                                          </Router>
                                                                        </CompanySettingsProvider>
                                                                      </BillingProvider>
                                                                    </PartnerPaymentsProvider>
                                                                  </CurrenciesProvider>
                                                                </SeatingProvider>
                                                              </BookingFlowProvider>
                                                            </BookingProvider>
                                                          </SearchProvider>
                                                        </BankAccountsProvider>
                                                      </MMBProvider>
                                                    </HelpProvider>
                                                  </BidViewerProvider>
                                                </InfoMessagesProvider>
                                              </BookingToolProvider>
                                            </SmartPassProvider>
                                          </SmartpointProvider>
                                        </DepositProvider>
                                      </ResourcesProvider>
                                    </LogsProvider>
                                  </CommercialsProvider>
                                </BillingCyclesProvider>
                              </SolutionsProvider>
                            </CompanyUsersProvider>
                          </SpecialAppsProvider>
                        </AccountManagersProvider>
                      </CompanyProvider>
                    </UsersProvider>
                  </CompaniesProvider>
                </ProductsProvider>
              </AuthProvider>
            </ErrorBoundary>
          </I18nProvider>
        </ThemeProvider>
      </LocalStorageProvider>
    </TranslationProvider>
  );
};

export default App;
