import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { DepositState } from 'components/services/deposit';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import { cantContainCyrillic } from 'utils/validation';
import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';

const DepositSchema = Yup.object().shape({
  email: Yup.string()
    .email('validation.invalid_email')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .required('validation.cant_be_empty')
    .max(255, 'validation.length_exceeded'),
  affiliateId: Yup.string()
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(255, 'validation.length_exceeded'),
  bookingPartnerId: Yup.string()
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(255, 'validation.length_exceeded'),
  secretToken: Yup.string()
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic),
});

const DepositModal = ({ onClose, onSuccess }) => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const deposit = useCompanyDeposit(companyName);
  const { setNewDeposit } = useContext(DepositState);
  const errorSettingAlert = useToggle();

  const handleSetDeposit = async values => {
    try {
      await setNewDeposit(values, companyName);
      onSuccess();
    } catch (error) {
      errorSettingAlert.setOn();
    }
  };

  return (
    <Modal closable onClose={onClose}>
      <ModalHeader
        title={
          Boolean(deposit.data)
            ? polyglot.t('company.edit_deposit')
            : polyglot.t('company.add_deposit')
        }
      />
      <Formik
        initialValues={{
          email: deposit.data?.depositAcc || '',
          bookingPartnerId: deposit.data?.bookingPartnerId || '',
          affiliateId: deposit.data?.affiliateId || '',
          secretToken: deposit.data?.secretToken || '',
        }}
        validationSchema={DepositSchema}
        onSubmit={values => handleSetDeposit(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <ModalSection>
              {errorSettingAlert.isOn && (
                <Stack spaceAfter="large">
                  <Alert type="critical" closable icon onClose={errorSettingAlert.setOff}>
                    There was an error setting deposit.
                  </Alert>
                </Stack>
              )}
              <Stack spacing="large">
                <InputField
                  name="email"
                  label={polyglot.t('common.email')}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email && polyglot.t(errors.email)}
                />
                <InputField
                  name="affiliateId"
                  label={polyglot.t('common.affiliate_id')}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.affiliateId}
                  error={
                    touched.affiliateId && errors.affiliateId && polyglot.t(errors.affiliateId)
                  }
                />
                <InputField
                  name="bookingPartnerId"
                  label={polyglot.t('common.booking_partner_id')}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bookingPartnerId}
                  error={
                    touched.bookingPartnerId &&
                    errors.bookingPartnerId &&
                    polyglot.t(errors.bookingPartnerId)
                  }
                />
                <InputField
                  name="secretToken"
                  label={polyglot.t('common.token')}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.secretToken}
                  error={
                    touched.secretToken && errors.secretToken && polyglot.t(errors.secretToken)
                  }
                />
              </Stack>
            </ModalSection>
            <ModalFooter>
              <Stack direction="row" justify="end">
                <Button
                  dataTest="deposit-modal-cancel-button"
                  type="secondary"
                  width="128px"
                  onClick={onClose}
                >
                  {polyglot.t('common.cancel')}
                </Button>
                <Button
                  dataTest="deposit-modal-confirm-button"
                  type="primary"
                  width="128px"
                  onClick={handleSubmit}
                  loading={deposit.setting}
                >
                  {polyglot.t('common.confirm')}
                </Button>
              </Stack>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default DepositModal;
