import React, { Component } from 'react';
import * as R from 'ramda';

/**
 * Higher order component that takes an object of rules with the name
 * to validate as a key, and the rules of validation as its value
 * An example of the rule is the following:
 * {
 *   'input.name': [
 *      {
 *        rule: v => v,
 *        message: 'must.be.truthy',
 *      },
 *   ],
 * }
 * The rule recites: The input of name 'input.name' must obide the rules supplied in the array.
 * The 'rule' key in the object has the function that must return a truthy value.
 * If it returns a falsy value, the message is appended on the 'errors' object an passed to props
 * @param {object} rules - Object containing the validation rules based on names
 */
const withValidation =
  (rules = {}) =>
  WrappedComponent =>
    class Validation extends Component {
      state = {
        errors: {},
        isInvalid: false,
      };

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (!R.isEmpty(this.state.errors) || this.state.isInvalid) {
          this.setState({
            errors: {},
            isInvalid: false,
          });
        }
      }

      validateInput = (name, val) => {
        if (!rules[name]) {
          return;
        }

        const errors = rules[name].reduce(
          (acc, { rule, message }) => {
            // If it doesn't satisfy the rule it sets the key of the error object to the alias name
            // and its value to the error message
            if (!rule(val)) {
              acc[name] = message;
            } else if (acc[name] === message) {
              // If it passes the rule and the alias is reported on the error object, clear it
              delete acc[name];
            }
            return acc;
          },
          { ...this.state.errors },
        );

        this.setState({
          errors,
          isInvalid: !R.isEmpty(errors),
        });
      };

      /**
       * A validation method similar to the validateInput method,
       * but instead of checking one field it checks the whole input.
       * If the input satisfies the rules, it executes the passed callback function. i.e. form submission
       * @param {object} data - The object containing the data
       * @param {function} callback - The function that will get executed if the input passes all the rules
       */
      validateSubmission = (data, callback) => {
        const errors = R.keys(data).reduce(
          (acc, next) => {
            if (!rules[next]) {
              return acc;
            }
            rules[next].forEach(({ rule, message }) => {
              if (!rule(data[next])) {
                acc[next] = message;
              }
            });
            return acc;
          },
          { ...this.state.errors },
        );

        this.setState({
          errors,
          isInvalid: !R.isEmpty(errors),
        });

        if (R.isEmpty(errors)) {
          callback();
        }
      };

      /**
       * Clears all errors
       */
      clearErrors = () => {
        this.setState({ errors: [], isInvalid: false });
      };

      static WrappedComponent = WrappedComponent.WrappedComponent || WrappedComponent;

      render() {
        return (
          <WrappedComponent
            {...this.state}
            {...this.props}
            validateSubmission={this.validateSubmission}
            validateInput={this.validateInput}
            clearErrors={this.clearErrors}
          />
        );
      }
    };

export default withValidation;
