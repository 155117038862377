import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@kiwicom/orbit-components/lib/Button';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { usePolyglot } from 'components/services/i18n';

import IllustratedCard from './IllustratedCard';

const TextWrapper = styled.div`
  max-width: 400px;
  margin-bottom: 30px;
`;

const HelpWrapper = styled.div`
  padding: 15px 10px;
  margin: 10px 0;
  border: ${({ theme }) => `1px solid ${theme.orbit.backgroundButtonSecondary}`};
  border-radius: 3px;
  ${({ spaceAfter }) => spaceAfter && 'margin-bottom:20px;'}
`;

const SolutionsWrapper = styled.div`
  display: flex;
  min-width: 300px;
  justify-content: space-between;
`;

const SolutionNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const solutionLinks = [
  { name: 'meta_search', href: '/portal/docs/tequila_api/search_api', external: true },
  { name: 'widget', href: '/widgets' },
  { name: 'booking_tool', href: '/search' },
  { name: 'deeplink', href: '/deeplink-generator' },
  {
    name: 'smartpoint',
    href: '/portal/docs/user_guides/extending_your_smartpoint_content_with_kiwi_com_pl',
    external: true,
  },
];

const StartUsingSolution = ({ done, onAcknowledge, solutions }) => {
  const polyglot = usePolyglot();

  if (solutions.loading) {
    return (
      <Card loading>
        <CardSection />
      </Card>
    );
  }
  if (solutions.data.length === 0) {
    return (
      <IllustratedCard
        title={polyglot.t('getting_started.start_using_solution.title')}
        illustrationName="OnlineCheckIn"
      >
        <TextWrapper>
          {polyglot.t('getting_started.start_using_solution.empty_pre')}
          <TextLink href="/portal/my-solutions" noUnderline>
            {polyglot.t('getting_started.create_solution.button')}
          </TextLink>{' '}
          {polyglot.t('getting_started.start_using_solution.empty_post')}
        </TextWrapper>
      </IllustratedCard>
    );
  }

  const filteredSolutions = solutions.data.reduce((acc, cur) => {
    if (cur.gettingStartedType && !acc.find(s => s.gettingStartedType === cur.gettingStartedType)) {
      return acc.concat(cur);
    }
    return acc;
  }, []);

  const getSolutionFeatureLink = (solution, links) =>
    links.find(link => solution.gettingStartedType?.indexOf(link.name) > 0)?.href;

  return (
    <IllustratedCard
      type={done ? 'secondary' : 'primary'}
      title={polyglot.t('getting_started.start_using_solution.title')}
      illustrationName="OnlineCheckIn"
      help={
        <HelpWrapper spaceAfter>
          <Text>{polyglot.t('getting_started.start_using_solution.help_pre')}</Text>
          <TextLink external href="/docs/user_guides/choosing_a_right_solution">
            {polyglot.t('getting_started.start_using_solution.help_post')}
          </TextLink>
        </HelpWrapper>
      }
      action={
        <Button size="small" disabled={done} onClick={onAcknowledge}>
          {polyglot.t('getting_started.acknowledge')}
        </Button>
      }
      dataTest="card-start_using"
    >
      {filteredSolutions.map((s, i) => (
        <SolutionsWrapper key={i}>
          <SolutionNameWrapper>
            <Text size="small" type="secondary" uppercase weight="bold">
              {polyglot.t(s.gettingStartedType)}
            </Text>
            <Text>{s.name}</Text>
          </SolutionNameWrapper>

          {solutionLinks.find(l => l.href === getSolutionFeatureLink(s, solutionLinks)).external ? (
            <a
              href={getSolutionFeatureLink(s, solutionLinks)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primarySubtle" size="small">
                {polyglot.t('getting_started.start_using_solution.start')}
              </Button>
            </a>
          ) : (
            <Link
              to={{
                pathname: getSolutionFeatureLink(s, solutionLinks),
                state: { affilId: s.affilId },
              }}
            >
              <Button type="primarySubtle" size="small">
                {polyglot.t('getting_started.start_using_solution.start')}
              </Button>
            </Link>
          )}
        </SolutionsWrapper>
      ))}
    </IllustratedCard>
  );
};

export default StartUsingSolution;
