import React from 'react';
import Select from '@kiwicom/orbit-components/lib/Select';
import Passenger from '@kiwicom/orbit-components/lib/icons/Passenger';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { usePolyglot } from 'components/services/i18n';
import useAccountManagers from 'components/services/accountManagers/useAccountManagers';

const SelectAccountManagers = ({ onChange, value, name, onBlur, error }) => {
  const { data, loading } = useAccountManagers();
  const polyglot = usePolyglot();
  const accountManagerOptions = data || [];

  return loading ? (
    <Loading type="boxLoader" />
  ) : (
    <Select
      type="text"
      size="small"
      name={name}
      loading={loading}
      onChange={onChange}
      onBlur={onBlur}
      label={polyglot.t('company.account_manager')}
      value={value}
      placeholder={polyglot.t('company.select_account_manager')}
      prefix={<Passenger size="small" customColor="#5f738c" />}
      options={accountManagerOptions}
      error={error}
    />
  );
};

export default SelectAccountManagers;
