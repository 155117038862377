import { useContext } from 'react';

import { ShoppingBasketState } from './';

const useShoppingBasket = () => {
  const {
    activeOrder,
    payment,
    seatings,
    status,
    fetchCurrentlyActiveOrder,
    fetchBasketPaymentStatus,
    payShoppingBasket,
    addSeatingMMB,
    resetSeatingsMMB,
    resetData,
  } = useContext(ShoppingBasketState);

  return {
    activeOrder,
    payment,
    seatings,
    status,
    fetchCurrentlyActiveOrder,
    fetchBasketPaymentStatus,
    payShoppingBasket,
    addSeatingMMB,
    resetSeatingsMMB,
    resetData,
  };
};

export default useShoppingBasket;
