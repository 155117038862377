import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: ${({ fullWidth, maxWidth }) => !fullWidth && (maxWidth ? `${maxWidth}px` : '880px')};
  max-width: ${({ maxContent }) => maxContent && 'max-content'};
  padding-bottom: 24px;
  ${({ theme, noNavigation }) =>
    noNavigation && `margin-left: ${theme.sizes.navigationWidth + 24}px`}
  ${({ moveDown }) => moveDown && `margin-top: 54px`}

  @media all and (min-width: 1280px) {
    ${({ theme, noNavigation }) =>
      noNavigation && `margin-left: ${theme.sizes.navigationExpandedWidth + 24}px`}
  }
`;

export default PageContainer;
