import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';

import useCompanySettings from './useCompanySettings';

const useCurrentCompanySettings = () => {
  const companyName = useCurrentCompanyName();

  return useCompanySettings(companyName);
};

export default useCurrentCompanySettings;
