import React, { useReducer } from 'react';
import { v1 as uuid } from 'uuid';
import * as R from 'ramda';
import qs from 'query-string';

import { proxy } from 'utils/api';
import {
  tripTypesValues,
  passengerTypes,
  sortTypes,
  PRODUCT_TYPE_TO_TRIP_TYPE,
  cabinClassTypesValues,
  vehicleTypesValues,
  stopoversRadioValues,
} from 'consts/search';
import { SMARTPOINT_PRODUCTS } from 'consts/products';
import { useBookingToolTabs } from 'components/services/bookingTool';
import {
  correctReturnDates,
  flightCardAdapters,
  getClearableValues,
  expandLocations,
  setBookingToolCookie,
} from 'utils/search';
import searchOneWayReturnMapper from 'mappers/nextGen/search/_';
import searchMulticityMapper from 'mappers/nextGen/flights/multicity/_';
import searchNomadMapper from 'mappers/nextGen/nomad/_';

export const SearchState = React.createContext({});
const { ONEWAY, RETURN, MULTICITY, NOMAD } = tripTypesValues;
const createEmptyMulticityTrip = (toFromPreviousTrip = []) => ({
  id: uuid(),
  locationsFrom: toFromPreviousTrip,
  locationsTo: [],
  departureSelectedDates: [],
});

const createEmptyNomadTrip = () => ({
  id: uuid(),
  locations: [],
  nights: [3, 5],
  dates: [],
  isNightsInputShown: true,
  isDatesInputShown: false,
});

export const initialSearchValues = {
  tripType: RETURN,
  passengersAmounts: [
    { type: passengerTypes.ADULTS, amount: 1, minAmount: 1 },
    { type: passengerTypes.CHILDREN, amount: 0, minAmount: 0 },
    { type: passengerTypes.INFANTS, amount: 0, minAmount: 0 },
  ],
  searchStatus: {
    [sortTypes.BEST]: null,
    [sortTypes.PRICE]: null,
    [sortTypes.DURATION]: null,
  },
  selectedCabinClassType: cabinClassTypesValues.ECONOMY,
  vehicleTypes: [vehicleTypesValues.FLIGHT],
  filteredCarriers: [],
  previouslyFilteredCarriers: [],
  allCarriersList: [],
  pricesFilterValues: {
    [sortTypes.BEST]: [],
    [sortTypes.PRICE]: [],
    [sortTypes.DURATION]: [],
  },
  numberOfStopovers: stopoversRadioValues.ANY,
  currency: 'EUR',
  departureHours: [0, 24],
  arrivalHours: [0, 24],
  returnDepartureHours: [0, 24],
  returnArrivalHours: [0, 24],
  response: {
    [ONEWAY]: null,
    [RETURN]: null,
    [MULTICITY]: null,
    [NOMAD]: null,
  },
  searchbar: {
    [ONEWAY]: {
      departureSelectedDates: [],
      locationsFrom: [],
      locationsTo: [],
      limit: 30,
    },
    [RETURN]: {
      departureSelectedDates: [],
      returnSelectedDates: [],
      locationsFrom: [],
      locationsTo: [],
      limit: 30,
    },
    [MULTICITY]: {
      limit: 30,
      trips: [createEmptyMulticityTrip()],
    },
    [NOMAD]: {
      limit: 30,
      locationsFrom: [],
      locationsTo: [],
      departureSelectedDates: [],
      returnSelectedDates: [],
      isReturnToOriginChecked: true,
      isSetReturnDateChecked: false,
      trips: [createEmptyNomadTrip()],
    },
  },
  carrierInfoResponse: null,
  affilId: '',
  consumerKey: '',
  productType: '',
  sortType: sortTypes.BEST,
  selectedStopAirports: null,
  isSortShown: false,
  bestFlights: {
    [sortTypes.BEST]: null,
    [sortTypes.PRICE]: null,
    [sortTypes.DURATION]: null,
  },
  isSmartpointSearch: false,
  isFlightChangeMode: false,
};

const searchReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SELECT_SOLUTION':
      const isSmartpointSearch = R.includes(payload.productType, SMARTPOINT_PRODUCTS);
      let tripType = RETURN;
      if (payload.productType) {
        tripType = PRODUCT_TYPE_TO_TRIP_TYPE[payload.productType];
      }
      if (isSmartpointSearch) {
        tripType = state[payload.selectedTab].tripType;
      }

      setBookingToolCookie('affilId', payload.affilId, payload.selectedTab);

      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          affilId: payload.affilId,
          consumerKey: payload.consumerKey,
          productType: payload.productType,
          tripType: payload.preDefinedTripType || tripType,
          isSortShown: false,
          response: R.clone(initialSearchValues.response),
          bestFlights: R.clone(initialSearchValues.bestFlights),
          isSmartpointSearch,
          isFlightChangeMode: false,
        },
      };
    case 'CHANGE_LOCATIONS_FROM':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [ONEWAY]: {
              ...state[payload.selectedTab].searchbar[ONEWAY],
              locationsFrom: payload.locationsFrom,
            },
            [RETURN]: {
              ...state[payload.selectedTab].searchbar[RETURN],
              locationsFrom: payload.locationsFrom,
            },
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              locationsFrom: payload.locationsFrom,
              locationsTo: state[payload.selectedTab].searchbar[NOMAD].isReturnToOriginChecked
                ? payload.locationsFrom
                : state[payload.selectedTab].searchbar[NOMAD].locationsTo,
            },
          },
        },
      };
    case 'CHANGE_LOCATIONS_TO':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [ONEWAY]: {
              ...state[payload.selectedTab].searchbar[ONEWAY],
              locationsTo: payload.locationsTo,
            },
            [RETURN]: {
              ...state[payload.selectedTab].searchbar[RETURN],
              locationsTo: payload.locationsTo,
            },
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              locationsTo: payload.locationsTo,
            },
          },
        },
      };
    case 'CHANGE_DEPARTURE_SELECTED_DATES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [ONEWAY]: {
              ...state[payload.selectedTab].searchbar[ONEWAY],
              departureSelectedDates: payload.departureSelectedDates,
            },
            [RETURN]: {
              ...state[payload.selectedTab].searchbar[RETURN],
              departureSelectedDates: payload.departureSelectedDates,
              // correct returnSelectedDates if first departure date is after first return date
              returnSelectedDates: correctReturnDates(
                payload.departureSelectedDates,
                state[payload.selectedTab].searchbar[RETURN].returnSelectedDates,
              ),
            },
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              departureSelectedDates: payload.departureSelectedDates,
            },
          },
        },
      };
    case 'CHANGE_RETURN_SELECTED_DATES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [RETURN]: {
              ...state[payload.selectedTab].searchbar[RETURN],
              returnSelectedDates: payload.returnSelectedDates,
            },
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              returnSelectedDates: payload.returnSelectedDates,
            },
          },
        },
      };
    case 'SEARCH_FLIGHTS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchStatus: {
            ...state[payload.selectedTab].searchStatus,
            [payload.sortType]: 'loading',
          },
          response: {
            ...R.clone(initialSearchValues.response),
            [state[payload.selectedTab].tripType]:
              state[payload.selectedTab].response[state[payload.selectedTab].tripType],
          },
          bestFlights: R.clone(initialSearchValues.bestFlights),
          isSortShown: false,
          tripType:
            state[payload.selectedTab].tripType === RETURN && R.isEmpty(payload.returnDates)
              ? ONEWAY
              : state[payload.selectedTab].tripType,
          filteredCarriers:
            state[payload.selectedTab].filteredCarriers ||
            R.clone(initialSearchValues.filteredCarriers),
          previouslyFilteredCarriers: R.clone(state[payload.selectedTab].filteredCarriers),
          pricesFilterValues: R.clone(initialSearchValues.pricesFilterValues),
        },
      };
    case 'SEARCH_FLIGHTS_SUCCESS':
      const { searchFlightsResponse, sortType, selectedTab } = payload;
      const { getMinPrice, getMaxPrice } = flightCardAdapters[state[selectedTab].tripType];
      const { getAllCarriers } = flightCardAdapters[state[selectedTab].tripType];
      return {
        ...state,
        [selectedTab]: {
          ...state[selectedTab],
          searchStatus: {
            ...state[payload.selectedTab].searchStatus,
            [payload.sortType]: 'success',
          },
          response: {
            ...state[selectedTab].response,
            [state[selectedTab].tripType]: {
              ...state[selectedTab].response[state[selectedTab].tripType],
              [sortType]: searchFlightsResponse,
            },
          },
          pricesFilterValues: {
            ...state[selectedTab].pricesFilterValues,
            [sortType]:
              searchFlightsResponse.data.length > 0
                ? [getMinPrice(searchFlightsResponse), getMaxPrice(searchFlightsResponse)]
                : R.clone(initialSearchValues.pricesFilterValues),
          },
          isSortShown: true,
          bestFlights: {
            ...state[selectedTab].bestFlights,
            [sortType]: searchFlightsResponse.data[0],
          },
          allCarriersList: R.uniq([
            ...getAllCarriers(searchFlightsResponse.data),
            ...state[selectedTab].filteredCarriers,
            ...state[selectedTab].previouslyFilteredCarriers,
          ]),
        },
      };
    case 'SEARCH_FLIGHTS_FAIL':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchStatus: {
            ...state[payload.selectedTab].searchStatus,
            [payload.sortType]: 'error',
          },
        },
      };
    case 'CHANGE_SORT_TYPE':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          sortType: payload.sortType,
        },
      };
    case 'SELECT_STOP_AIRPORT':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          selectedStopAirports: payload.selectedStopAirports,
        },
      };
    case 'UNSELECT_STOP_AIRPORT':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          selectedStopAirports: null,
        },
      };
    case 'CHANGE_TRIP_TYPE':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          tripType: payload.tripType,
          isSortShown: false,
        },
      };
    case 'CHANGE_NUMBER_OF_PASSENGERS':
      const { passengerType, newNumberOfPassengers } = payload;
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          passengersAmounts: state[payload.selectedTab].passengersAmounts.map(passenger => {
            // There cannot be more infants than adults.
            // This is a case where number of adults gets decreased,
            // number of infants should be decreased too.
            if (
              passenger.type === passengerTypes.INFANTS &&
              passengerType === passengerTypes.ADULTS &&
              newNumberOfPassengers < passenger.amount
            ) {
              return {
                ...passenger,
                amount: newNumberOfPassengers,
              };
            }

            return {
              ...passenger,
              amount: passengerType === passenger.type ? newNumberOfPassengers : passenger.amount,
            };
          }),
        },
      };
    case 'CHANGE_CABIN_CLASS_TYPE':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          selectedCabinClassType: payload.cabinClassType,
        },
      };
    case 'CHANGE_VEHICLE_TYPE':
      const vehicleTypes = state[payload.selectedTab].vehicleTypes.includes(payload.vehicleType)
        ? state[payload.selectedTab].vehicleTypes.filter(
            vehicleType => vehicleType !== payload.vehicleType,
          )
        : [...state[payload.selectedTab].vehicleTypes, payload.vehicleType];

      setBookingToolCookie('vehicleTypes', vehicleTypes, payload.selectedTab);

      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          vehicleTypes,
        },
      };
    case 'CHANGE_NUMBER_OF_STOPOVERS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          numberOfStopovers: payload.value,
        },
      };
    case 'CHANGE_DEPARTURE_HOURS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          departureHours: payload.departureHours,
        },
      };
    case 'CHANGE_ARRIVAL_HOURS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          arrivalHours: payload.arrivalHours,
        },
      };
    case 'CHANGE_RETURN_DEPARTURE_HOURS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          returnDepartureHours: payload.returnDepartureHours,
        },
      };
    case 'CHANGE_RETURN_ARRIVAL_HOURS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          returnArrivalHours: payload.returnArrivalHours,
        },
      };
    case 'CHANGE_CURRENCY':
      setBookingToolCookie('currency', payload.currency, payload.selectedTab);
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          currency: payload.value,
        },
      };
    case 'CHANGE_PRICES_FILTER_VALUES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          pricesFilterValues: {
            ...state[payload.selectedTab].pricesFilterValues,
            [state[payload.selectedTab].sortType]: payload.value,
          },
        },
      };
    case 'SELECT_CARRIERS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          filteredCarriers: payload.filteredCarriers,
        },
      };
    case 'CHANGE_FILTERED_CARRIERS': {
      const newFilteredCarriers = state[payload.selectedTab].filteredCarriers.includes(
        payload.carrier,
      )
        ? state[payload.selectedTab].filteredCarriers.filter(carrier => carrier !== payload.carrier)
        : [...state[payload.selectedTab].filteredCarriers, payload.carrier];

      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          filteredCarriers: newFilteredCarriers,
        },
      };
    }
    case 'ADD_MULTICITY_TRIP':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [MULTICITY]: {
              ...state[payload.selectedTab].searchbar[MULTICITY],
              trips: [
                ...state[payload.selectedTab].searchbar[MULTICITY].trips,
                createEmptyMulticityTrip(
                  R.last(state[payload.selectedTab].searchbar[MULTICITY].trips).locationsTo,
                ),
              ],
            },
          },
        },
      };
    case 'REMOVE_MULTICITY_TRIP':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [MULTICITY]: {
              ...state[payload.selectedTab].searchbar[MULTICITY],
              trips: state[payload.selectedTab].searchbar[MULTICITY].trips.filter(
                trip => trip.id !== action.payload.id,
              ),
            },
          },
        },
      };
    case 'ADD_MULTICITY_LOCATION': {
      const { locationType, location, id } = payload;
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [MULTICITY]: {
              ...state[payload.selectedTab].searchbar[MULTICITY],
              trips: state[payload.selectedTab].searchbar[MULTICITY].trips.map(trip => {
                if (trip.id === id) {
                  return {
                    ...trip,
                    [locationType]: trip[locationType].concat(location),
                  };
                }
                return trip;
              }),
            },
          },
        },
      };
    }
    case 'REMOVE_MULTICITY_LOCATION': {
      const { locationType, location, id } = payload;
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [MULTICITY]: {
              ...state[payload.selectedTab].searchbar[MULTICITY],
              trips: state[payload.selectedTab].searchbar[MULTICITY].trips.map(trip => {
                if (trip.id === id) {
                  return {
                    ...trip,
                    [locationType]: trip[locationType].filter(l => l.value !== location.value),
                  };
                }
                return trip;
              }),
            },
          },
        },
      };
    }
    case 'CHANGE_MULTICITY_DEPARTURE_SELECTED_DATES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [MULTICITY]: {
              ...state[payload.selectedTab].searchbar[MULTICITY],
              trips: state[payload.selectedTab].searchbar[MULTICITY].trips.map(trip => ({
                ...trip,
                departureSelectedDates:
                  trip.id === payload.id
                    ? payload.departureSelectedDates
                    : trip.departureSelectedDates,
              })),
            },
          },
        },
      };
    case 'TOGGLE_IS_RETURN_TO_ORIGIN_CHECKED':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              isReturnToOriginChecked:
                !state[payload.selectedTab].searchbar[NOMAD].isReturnToOriginChecked,
            },
          },
        },
      };
    case 'TOGGLE_IS_SET_RETURN_DATE_CHECKED':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              isSetReturnDateChecked:
                !state[payload.selectedTab].searchbar[NOMAD].isSetReturnDateChecked,
            },
          },
        },
      };
    case 'CHANGE_NOMAD_TRIP_LOCATION':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.map(trip => ({
                ...trip,
                locations: payload.id === trip.id ? payload.locations : trip.locations,
              })),
            },
          },
        },
      };
    case 'CHANGE_NOMAD_NUMBER_OF_NIGHTS':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.map(trip => ({
                ...trip,
                nights: payload.id === trip.id ? payload.nights : trip.nights,
              })),
            },
          },
        },
      };
    case 'CHANGE_NOMAD_IS_NIGHTS_SHOWN':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.map(trip => ({
                ...trip,
                isNightsInputShown:
                  payload.id === trip.id ? payload.value : trip.isNightsInputShown,
              })),
            },
          },
        },
      };
    case 'CHANGE_NOMAD_TRIP_IS_DATES_INPUT_SHOWN':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.map(trip => ({
                ...trip,
                isDatesInputShown: payload.id === trip.id ? payload.value : trip.isDatesInputShown,
              })),
            },
          },
        },
      };
    case 'CHANGE_NOMAD_DATES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.map(trip => ({
                ...trip,
                dates: payload.id === trip.id ? payload.dates : trip.dates,
              })),
            },
          },
        },
      };
    case 'ADD_NOMAD_TRIP':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.concat(
                createEmptyNomadTrip(),
              ),
            },
          },
        },
      };
    case 'REMOVE_NOMAD_TRIP':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              trips: state[payload.selectedTab].searchbar[NOMAD].trips.filter(
                trip => trip.id !== payload.id,
              ),
            },
          },
        },
      };
    case 'EXPAND_GROUND_LOCATIONS': {
      const { expandedLocations } = payload;
      if (
        state[payload.selectedTab].tripType === RETURN ||
        state[payload.selectedTab].tripType === ONEWAY
      ) {
        const { locationsFrom, locationsTo } =
          state[payload.selectedTab].searchbar[state[payload.selectedTab].tripType];
        const locationsFromExpanded = expandLocations(locationsFrom, expandedLocations);
        const locationsToExpanded = expandLocations(locationsTo, expandedLocations);
        return {
          ...state,
          [payload.selectedTab]: {
            ...state[payload.selectedTab],
            searchbar: {
              ...state[payload.selectedTab].searchbar,
              [ONEWAY]: {
                ...state[payload.selectedTab].searchbar[ONEWAY],
                locationsFrom: locationsFromExpanded,
                locationsTo: locationsToExpanded,
              },
              [RETURN]: {
                ...state[payload.selectedTab].searchbar[RETURN],
                locationsFrom: locationsFromExpanded,
                locationsTo: locationsToExpanded,
              },
            },
          },
        };
      }
      if (state[payload.selectedTab].tripType === MULTICITY) {
        const { trips } = state[payload.selectedTab].searchbar[MULTICITY];
        const expandedTrips = trips.map(({ locationsFrom, locationsTo, ...rest }) => {
          const locationsFromExpanded = expandLocations(locationsFrom, expandedLocations);
          const locationsToExpanded = expandLocations(locationsTo, expandedLocations);
          return {
            ...rest,
            locationsFrom: locationsFromExpanded,
            locationsTo: locationsToExpanded,
          };
        });
        return {
          ...state,
          [payload.selectedTab]: {
            ...state[payload.selectedTab],
            searchbar: {
              ...state[payload.selectedTab].searchbar,
              [MULTICITY]: {
                ...state[payload.selectedTab].searchbar[MULTICITY],
                trips: expandedTrips,
              },
            },
          },
        };
      }
      if (state[payload.selectedTab].tripType === NOMAD) {
        const { locationsTo, locationsFrom, trips } = state[payload.selectedTab].searchbar[NOMAD];
        const locationsFromExpanded = expandLocations(locationsFrom, expandedLocations);
        const locationsToExpanded = expandLocations(locationsTo, expandedLocations);
        const expandedTrips = trips.map(({ locations, ...rest }) => {
          const locationsExpanded = expandLocations(locations, expandedLocations);
          return { ...rest, locations: locationsExpanded };
        });
        return {
          ...state,
          [payload.selectedTab]: {
            ...state[payload.selectedTab],
            searchbar: {
              ...state[payload.selectedTab].searchbar,
              [NOMAD]: {
                ...state[payload.selectedTab].searchbar[NOMAD],
                locationsFrom: locationsFromExpanded,
                locationsTo: locationsToExpanded,
                trips: expandedTrips,
              },
            },
          },
        };
      }
      return state;
    }
    case 'CLEAR_SEARCH': {
      const clearedValues = getClearableValues(initialSearchValues);

      if (payload.isCompleteClear) {
        setBookingToolCookie('currency', initialSearchValues.currency, payload.selectedTab);
        setBookingToolCookie('vehicleTypes', initialSearchValues.vehicleTypes, payload.selectedTab);
      }

      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          ...clearedValues,
        },
      };
    }
    case 'CHANGE_SEARCH_STATE': {
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          ...payload.searchState,
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            ...payload.searchState.searchbar,
          },
        },
      };
    }
    case 'SET_DEFAULT_SEARCH_VALUES':
      return {
        ...state,
        [payload.selectedTab]: {
          ...state[payload.selectedTab],
          ...initialSearchValues,
          isFlightChangeMode: true,
          affilId: payload.searchValues.affilId,
          isSmartpointSearch: false,
          tripType: payload.searchValues.tripType,
          passengersAmounts: payload.searchValues.passengersAmounts,
          searchbar: {
            ...state[payload.selectedTab].searchbar,
            [ONEWAY]: {
              ...state[payload.selectedTab].searchbar[ONEWAY],
              locationsFrom: payload.searchValues.locationsFrom,
              locationsTo: payload.searchValues.locationsTo,
              departureSelectedDates: payload.searchValues.departureSelectedDates,
            },
            [RETURN]: {
              ...state[payload.selectedTab].searchbar[RETURN],
              locationsFrom: payload.searchValues.locationsFrom,
              locationsTo: payload.searchValues.locationsTo,
              departureSelectedDates: payload.searchValues.departureSelectedDates,
              returnSelectedDates: payload.searchValues.returnSelectedDates || [],
            },
            [NOMAD]: {
              ...state[payload.selectedTab].searchbar[NOMAD],
              locationsFrom: payload.searchValues.locationsFrom,
              locationsTo: state[payload.selectedTab].searchbar[NOMAD].isReturnToOriginChecked
                ? payload.searchValues.locationsFrom
                : state[payload.selectedTab].searchbar[NOMAD].locationsTo,
              departureSelectedDates: payload.searchValues.departureSelectedDates,
              returnSelectedDates: payload.searchValues.returnSelectedDates || [],
            },
          },
        },
      };
    default:
      return state;
  }
};

const getInitialStateWithPreselectedValues = tabs => {
  // if there is no data in tabs, it means that there is no cookie with prefilled values
  if (R.isEmpty(tabs.data)) {
    return {
      [tabs.selectedTab]: initialSearchValues,
    };
  }

  let prefilledData = {};
  for (const tabKey in tabs.data) {
    prefilledData[tabKey] = {
      ...initialSearchValues,
      affilId: tabs.data[tabKey].affilId || initialSearchValues.affilId,
      currency: tabs.data[tabKey].currency || initialSearchValues.currency,
      vehicleTypes: tabs.data[tabKey].vehicleTypes || initialSearchValues.vehicleTypes,
    };
  }
  return prefilledData;
};

const SearchProvider = ({ children }) => {
  const bookingToolTabs = useBookingToolTabs();
  const selectedTab = bookingToolTabs.selectedTab;
  const [state, dispatch] = useReducer(
    searchReducer,
    getInitialStateWithPreselectedValues(bookingToolTabs),
  );
  const selectSolution = (data, preDefinedTripType) => {
    dispatch({
      type: 'SELECT_SOLUTION',
      payload: {
        affilId: data.affilId,
        consumerKey: data.consumerKey,
        productType: data.productType,
        preDefinedTripType,
        selectedTab,
      },
    });
  };

  const searchFlightsOnewayReturn = (tabData, sortType) => {
    dispatch({
      type: 'SEARCH_FLIGHTS',
      payload: {
        selectedTab,
        returnDates: tabData.searchbar.Return?.returnSelectedDates,
        sortType,
      },
    });

    proxy.v2
      .get('search', {
        params: searchOneWayReturnMapper.v2.get.to(tabData, sortType),
        headers: { apikey: tabData.consumerKey },
      })
      .then(res => {
        dispatch({
          type: 'SEARCH_FLIGHTS_SUCCESS',
          payload: { searchFlightsResponse: res.data, sortType, selectedTab },
        });
      })
      .catch(() => dispatch({ type: 'SEARCH_FLIGHTS_FAIL', payload: { selectedTab, sortType } }));
  };

  const searchFlightsMulticity = (tabData, sortType) => {
    dispatch({ type: 'SEARCH_FLIGHTS', payload: { selectedTab, sortType } });

    const params = searchMulticityMapper.direct.get.to(tabData, sortType);
    proxy.direct
      .post('flights/multicity', params.body, {
        headers: {
          apikey: tabData.consumerKey,
        },
        params: params.query,
      })
      .then(res => {
        dispatch({
          type: 'SEARCH_FLIGHTS_SUCCESS',
          payload: { searchFlightsResponse: { data: res.data.data }, sortType, selectedTab },
        });
      })
      .catch(() => dispatch({ type: 'SEARCH_FLIGHTS_FAIL', payload: { selectedTab, sortType } }));
  };

  const searchFlightsNomad = (tabData, sortType) => {
    dispatch({ type: 'SEARCH_FLIGHTS', payload: { selectedTab, sortType } });

    const params = searchNomadMapper.v2.post.to(tabData, sortType);
    const query = qs.stringify(params.query);

    proxy.v2
      .post(`nomad?${query}`, params.body, { headers: { apikey: tabData.consumerKey } })
      .then(res => {
        dispatch({
          type: 'SEARCH_FLIGHTS_SUCCESS',
          payload: { searchFlightsResponse: { data: res.data.data }, sortType, selectedTab },
        });
      })
      .catch(() => dispatch({ type: 'SEARCH_FLIGHTS_FAIL', payload: { selectedTab, sortType } }));
  };

  const SEARCH_FLIGHT_THUNKS = Object.freeze({
    [ONEWAY]: searchFlightsOnewayReturn,
    [RETURN]: searchFlightsOnewayReturn,
    [MULTICITY]: searchFlightsMulticity,
    [NOMAD]: searchFlightsNomad,
  });

  const searchFlights = tabData => {
    const searchFlightsThunk = SEARCH_FLIGHT_THUNKS[tabData.tripType];
    for (let key in sortTypes) {
      searchFlightsThunk(tabData, sortTypes[key]);
    }
  };

  const changeSortType = sortType => {
    dispatch({ type: 'CHANGE_SORT_TYPE', payload: { sortType, selectedTab } });
  };

  const changeLocationsFrom = locationsFrom => {
    dispatch({
      type: 'CHANGE_LOCATIONS_FROM',
      payload: { locationsFrom, selectedTab },
    });
  };

  const changeLocationsTo = locationsTo => {
    dispatch({
      type: 'CHANGE_LOCATIONS_TO',
      payload: { locationsTo, selectedTab },
    });
  };

  const changeDepartureSelectedDates = departureSelectedDates => {
    dispatch({
      type: 'CHANGE_DEPARTURE_SELECTED_DATES',
      payload: { departureSelectedDates, selectedTab },
    });
  };

  const changeReturnSelectedDates = returnSelectedDates => {
    dispatch({
      type: 'CHANGE_RETURN_SELECTED_DATES',
      payload: { returnSelectedDates, selectedTab },
    });
  };

  const selectStopAirport = selectedStopAirports => {
    dispatch({
      type: 'SELECT_STOP_AIRPORT',
      payload: { selectedStopAirports, selectedTab },
    });
  };

  const unselectStopAirport = () => {
    dispatch({
      type: 'UNSELECT_STOP_AIRPORT',
      payload: { selectedTab },
    });
  };

  const changeNumberOfPassengers = (passengerType, newNumberOfPassengers) => {
    dispatch({
      type: 'CHANGE_NUMBER_OF_PASSENGERS',
      payload: { passengerType, newNumberOfPassengers, selectedTab },
    });
  };

  const changeTripType = tripType => {
    dispatch({
      type: 'CHANGE_TRIP_TYPE',
      payload: { tripType, selectedTab },
    });
  };

  const changeCabinClassType = cabinClassType => {
    dispatch({
      type: 'CHANGE_CABIN_CLASS_TYPE',
      payload: { cabinClassType, selectedTab },
    });
  };

  const changeVehicleType = vehicleType => {
    dispatch({
      type: 'CHANGE_VEHICLE_TYPE',
      payload: { vehicleType, selectedTab },
    });
  };

  const changeNumberOfStopovers = value => {
    dispatch({
      type: 'CHANGE_NUMBER_OF_STOPOVERS',
      payload: { value, selectedTab },
    });
  };

  const changeDepartureHours = departureHours => {
    dispatch({
      type: 'CHANGE_DEPARTURE_HOURS',
      payload: { departureHours, selectedTab },
    });
  };

  const changeArrivalHours = arrivalHours => {
    dispatch({
      type: 'CHANGE_ARRIVAL_HOURS',
      payload: { arrivalHours, selectedTab },
    });
  };

  const changeReturnDepartureHours = returnDepartureHours => {
    dispatch({
      type: 'CHANGE_RETURN_DEPARTURE_HOURS',
      payload: { returnDepartureHours, selectedTab },
    });
  };

  const changeReturnArrivalHours = returnArrivalHours => {
    dispatch({
      type: 'CHANGE_RETURN_ARRIVAL_HOURS',
      payload: { returnArrivalHours, selectedTab },
    });
  };

  const changeCurrency = value => {
    dispatch({
      type: 'CHANGE_CURRENCY',
      payload: { value, selectedTab },
    });
  };

  const changePricesFilterValues = value => {
    dispatch({
      type: 'CHANGE_PRICES_FILTER_VALUES',
      payload: { value, selectedTab },
    });
  };

  const selectCarriers = filteredCarriers => {
    dispatch({
      type: 'SELECT_CARRIERS',
      payload: { filteredCarriers, selectedTab },
    });
  };

  const changeFilteredCarriers = carrier => {
    dispatch({
      type: 'CHANGE_FILTERED_CARRIERS',
      payload: { carrier, selectedTab },
    });
  };

  const addMulticityTrip = () => {
    dispatch({ type: 'ADD_MULTICITY_TRIP', payload: { selectedTab } });
  };

  const removeMulticityTrip = id => {
    dispatch({ type: 'REMOVE_MULTICITY_TRIP', payload: { id, selectedTab } });
  };

  const addMulticityLocation = (id, location, locationType) => {
    dispatch({
      type: 'ADD_MULTICITY_LOCATION',
      payload: { id, location, locationType, selectedTab },
    });
  };

  const removeMulticityLocation = (id, location, locationType) => {
    dispatch({
      type: 'REMOVE_MULTICITY_LOCATION',
      payload: { id, location, locationType, selectedTab },
    });
  };

  const changeMulticityDepartureSelectedDates = (id, departureSelectedDates) => {
    dispatch({
      type: 'CHANGE_MULTICITY_DEPARTURE_SELECTED_DATES',
      payload: { id, departureSelectedDates, selectedTab },
    });
  };

  const toggleIsReturnToOriginChecked = () => {
    dispatch({
      type: 'TOGGLE_IS_RETURN_TO_ORIGIN_CHECKED',
      payload: { selectedTab },
    });
  };

  const toggleIsSetReturnDateChecked = () => {
    dispatch({
      type: 'TOGGLE_IS_SET_RETURN_DATE_CHECKED',
      payload: { selectedTab },
    });
  };

  const changeNomadTripLocation = (id, locations) => {
    dispatch({
      type: 'CHANGE_NOMAD_TRIP_LOCATION',
      payload: { id, locations, selectedTab },
    });
  };

  const changeNomadIsNightsInputShown = (id, value) => {
    dispatch({
      type: 'CHANGE_NOMAD_IS_NIGHTS_SHOWN',
      payload: { id, value, selectedTab },
    });
  };

  const changeNomadTripIsDatesInputShown = (id, value) => {
    dispatch({
      type: 'CHANGE_NOMAD_TRIP_IS_DATES_INPUT_SHOWN',
      payload: { id, value, selectedTab },
    });
  };

  const addNomadTrip = () => {
    dispatch({
      type: 'ADD_NOMAD_TRIP',
      payload: { selectedTab },
    });
  };

  const removeNomadTrip = id => {
    dispatch({
      type: 'REMOVE_NOMAD_TRIP',
      payload: { id, selectedTab },
    });
  };

  const changeNomadDates = (id, dates) => {
    dispatch({
      type: 'CHANGE_NOMAD_DATES',
      payload: { id, dates, selectedTab },
    });
  };

  const changeNomadNumberOfNights = (id, nights) => {
    dispatch({
      type: 'CHANGE_NOMAD_NUMBER_OF_NIGHTS',
      payload: { id, nights, selectedTab },
    });
  };

  const expandGroundLocations = expandedLocations => {
    dispatch({
      type: 'EXPAND_GROUND_LOCATIONS',
      payload: { expandedLocations, selectedTab },
    });
  };

  const clearSearch = isCompleteClear => {
    dispatch({
      type: 'CLEAR_SEARCH',
      payload: { selectedTab, isCompleteClear },
    });
  };

  const changeSearchState = searchState => {
    dispatch({
      type: 'CHANGE_SEARCH_STATE',
      payload: { searchState, selectedTab },
    });
  };

  const setDefaultSearchValues = searchValues => {
    dispatch({
      type: 'SET_DEFAULT_SEARCH_VALUES',
      payload: { searchValues, selectedTab },
    });
  };

  const value = {
    tabs: state,
    selectSolution,
    changeLocationsFrom,
    changeLocationsTo,
    changeDepartureSelectedDates,
    changeReturnSelectedDates,
    searchFlights,
    changeSortType,
    selectStopAirport,
    unselectStopAirport,
    changeNumberOfPassengers,
    changeTripType,
    changeCabinClassType,
    changeVehicleType,
    changeNumberOfStopovers,
    changeDepartureHours,
    changeArrivalHours,
    changeReturnDepartureHours,
    changeReturnArrivalHours,
    changeCurrency,
    changePricesFilterValues,
    selectCarriers,
    changeFilteredCarriers,
    addMulticityTrip,
    removeMulticityTrip,
    addMulticityLocation,
    removeMulticityLocation,
    changeMulticityDepartureSelectedDates,
    toggleIsReturnToOriginChecked,
    toggleIsSetReturnDateChecked,
    changeNomadTripLocation,
    changeNomadIsNightsInputShown,
    changeNomadTripIsDatesInputShown,
    addNomadTrip,
    removeNomadTrip,
    changeNomadDates,
    changeNomadNumberOfNights,
    expandGroundLocations,
    clearSearch,
    changeSearchState,
    setDefaultSearchValues,
  };

  return <SearchState.Provider value={value}>{children}</SearchState.Provider>;
};

export default SearchProvider;
