import React, { useContext } from 'react';
import { FieldArray } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import FileInput from './FileInput';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import { SmartPassContext } from 'components/services/smartPass';
import { propsComparison } from 'utils/propsComparison';
import {
  keys,
  fastTrackConditionValues,
  fastTrackItinerariesTypesValues,
  terminalsValues,
  dynamicStaticValues,
  fastTrackVoucherFullfilmentValues,
  fastTrackVoucherCodeTypeValues,
  yesNoValues,
  designTypeValues,
  fastTrackFormKeys,
  previewTypeKeys,
} from 'consts/smartPass';

import {
  LabelsRowWrapper,
  HalfWidthWrapper,
  FileWithPreview,
  InputFieldWidthLimiter,
  LabelWithTextInput,
} from './index.styled';
import ActivationButton from './ActivationButton';

const {
  FAST_TRACK_ACTIVATED,
  FAST_TRACK_CONDITION,
  FAST_TRACK_LAYOVER_HOURS,
  FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS,
  FAST_TRACK_ITINERARIES_TYPES,
  FAST_TRACK_TERMINALS,
  FAST_TRACK_TERMINALS_LIST,
  FAST_TRACK_TERMINALS_LIST_NEW_VALUE,
  FAST_TRACK_VOUCHER_FULFILMENT,
  FAST_TRACK_VOUCHER_CODE_TYPE,
  FAST_TRACK_VOUCHER_CODE_GENERATION,
  FAST_TRACK_STATIC_KEYWORD,
  FAST_TRACK_STATIC_URL,
  FAST_TRACK_REPORT_EMAILS_ACTIVATED,
  FAST_TRACK_REPORT_EMAILS_LIST,
  FAST_TRACK_REPORT_EMAILS_NEW_VALUE,
  FAST_TRACK_CUSTOM_VOUCHER_DESIGN,
  FAST_TRACK_DESIGN_TYPE,
  TRANSFER_PROCESS,
  SFAQ_ARTICLE_ID,
} = keys;

const FastTrack = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  disabled,
  errors,
  touched,
  companyName,
}) => {
  const { fastTrackVoucherModal, showVoucherPreviewModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();

  const handleAddToList = (push, newValueName) => async event => {
    if (!values[newValueName].replace(/\s/g, '')) {
      return;
    }
    if (event.keyCode === 13) {
      await push(values[newValueName]);
      setFieldValue(newValueName, '');
    }
  };

  return (
    <>
      <Card
        spaceAfter="largest"
        header={
          <Stack direction="row" justify="between" align="center">
            <Heading as="h2" type="title3">
              {polyglot.t('smart_pass.form.fast_track.header_title')}
            </Heading>
            <ActivationButton
              formKeys={fastTrackFormKeys}
              isActivatedKey={FAST_TRACK_ACTIVATED}
              name={polyglot.t('smart_pass.form.fast_track.header_title')}
              values={values}
              disabled={disabled}
              setFieldValue={setFieldValue}
            />
          </Stack>
        }
      >
        {values[FAST_TRACK_ACTIVATED] && (
          <CardSection>
            <Text spaceAfter="small">{polyglot.t('smart_pass.form.fast_track.condition')}</Text>
            <Stack spaceAfter="large">
              <Radio
                label={polyglot.t('smart_pass.form.fast_track.condition_radio_1')}
                name={FAST_TRACK_CONDITION}
                checked={values[FAST_TRACK_CONDITION] === fastTrackConditionValues.NO_CONDITION}
                value={fastTrackConditionValues.NO_CONDITION}
                onChange={handleChange}
                disabled={disabled}
                hasError={touched[FAST_TRACK_CONDITION] && errors[FAST_TRACK_CONDITION]}
              />
              <Radio
                label={polyglot.t('smart_pass.form.fast_track.condition_radio_2')}
                name={FAST_TRACK_CONDITION}
                checked={
                  values[FAST_TRACK_CONDITION] === fastTrackConditionValues.CHECKED_BAG_INCLUDED
                }
                value={fastTrackConditionValues.CHECKED_BAG_INCLUDED}
                onChange={handleChange}
                disabled={disabled}
                hasError={touched[FAST_TRACK_CONDITION] && errors[FAST_TRACK_CONDITION]}
              />
              <Radio
                name={FAST_TRACK_CONDITION}
                checked={values[FAST_TRACK_CONDITION] === fastTrackConditionValues.LAYOVER}
                hasError={touched[FAST_TRACK_CONDITION] && errors[FAST_TRACK_CONDITION]}
                value={fastTrackConditionValues.LAYOVER}
                onChange={handleChange}
                disabled={disabled}
                label={
                  <LabelWithTextInput>
                    <Text>{polyglot.t('smart_pass.form.fast_track.condition_radio_layover')}</Text>
                    <InputFieldWidthLimiter>
                      <InputField
                        disabled={
                          disabled ||
                          values[FAST_TRACK_CONDITION] !== fastTrackConditionValues.LAYOVER
                        }
                        error={
                          touched[FAST_TRACK_LAYOVER_HOURS] &&
                          errors[FAST_TRACK_LAYOVER_HOURS] &&
                          polyglot.t(errors[FAST_TRACK_LAYOVER_HOURS])
                        }
                        name={FAST_TRACK_LAYOVER_HOURS}
                        value={values[FAST_TRACK_LAYOVER_HOURS]}
                        onChange={handleChange}
                        size="small"
                        placeholder={polyglot.t('smart_pass.form.add_hours')}
                      />
                    </InputFieldWidthLimiter>
                    <Text>
                      {polyglot.t('smart_pass.form.fast_track.condition_radio_layover_1')}
                    </Text>
                  </LabelWithTextInput>
                }
              />
              <Radio
                name={FAST_TRACK_CONDITION}
                checked={
                  values[FAST_TRACK_CONDITION] ===
                  fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED
                }
                value={fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED}
                onChange={handleChange}
                hasError={touched[FAST_TRACK_CONDITION] && errors[FAST_TRACK_CONDITION]}
                disabled={disabled}
                label={
                  <LabelWithTextInput>
                    <Text>{polyglot.t('smart_pass.form.fast_track.condition_radio_layover')}</Text>
                    <InputFieldWidthLimiter>
                      <InputField
                        disabled={
                          disabled ||
                          values[FAST_TRACK_CONDITION] !==
                            fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED
                        }
                        name={FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS}
                        value={values[FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS]}
                        onChange={handleChange}
                        size="small"
                        placeholder={polyglot.t('smart_pass.form.add_hours')}
                        error={
                          touched[FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS] &&
                          errors[FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS] &&
                          polyglot.t(errors[FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS])
                        }
                      />
                    </InputFieldWidthLimiter>
                    <Text>
                      {polyglot.t('smart_pass.form.fast_track.condition_radio_layover_2')}
                    </Text>
                  </LabelWithTextInput>
                }
              />
            </Stack>
            <Separator spaceAfter="medium" />
            <Text spaceAfter="small">
              {polyglot.t('smart_pass.form.fast_track.itineraries_type')}
            </Text>
            <LabelsRowWrapper>
              <Checkbox
                disabled={disabled}
                label={polyglot.t('smart_pass.form.fast_track.air_air_itineraries')}
                name={FAST_TRACK_ITINERARIES_TYPES}
                checked={values[FAST_TRACK_ITINERARIES_TYPES].includes(
                  fastTrackItinerariesTypesValues.AIR_AIR,
                )}
                value={fastTrackItinerariesTypesValues.AIR_AIR}
                onChange={handleChange}
                hasError={
                  touched[FAST_TRACK_ITINERARIES_TYPES] && errors[FAST_TRACK_ITINERARIES_TYPES]
                }
              />
              <Checkbox
                disabled={disabled}
                label={polyglot.t('smart_pass.form.fast_track.ground_air_itineraries')}
                name={FAST_TRACK_ITINERARIES_TYPES}
                checked={values[FAST_TRACK_ITINERARIES_TYPES].includes(
                  fastTrackItinerariesTypesValues.GROUND_AIR,
                )}
                value={fastTrackItinerariesTypesValues.GROUND_AIR}
                onChange={handleChange}
                hasError={
                  touched[FAST_TRACK_ITINERARIES_TYPES] && errors[FAST_TRACK_ITINERARIES_TYPES]
                }
              />
            </LabelsRowWrapper>
            <Separator spaceAfter="medium" />
            <Text spaceAfter="small">
              {polyglot.t('smart_pass.form.fast_track.available_terminals')}
            </Text>
            <Stack spaceAfter="large">
              <Radio
                disabled={disabled}
                label={polyglot.t('smart_pass.form.all_terminals')}
                name={FAST_TRACK_TERMINALS}
                checked={values[FAST_TRACK_TERMINALS] === terminalsValues.ALL_TERMINALS}
                value={terminalsValues.ALL_TERMINALS}
                onChange={handleChange}
                hasError={touched[FAST_TRACK_TERMINALS] && errors[FAST_TRACK_TERMINALS]}
              />
              <LabelsRowWrapper labelWidth="200px">
                <Radio
                  disabled={disabled}
                  name={FAST_TRACK_TERMINALS}
                  checked={values[FAST_TRACK_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS}
                  value={terminalsValues.SPECIFIC_TERMINALS}
                  onChange={handleChange}
                  label={polyglot.t('smart_pass.form.specific_terminals')}
                  hasError={touched[FAST_TRACK_TERMINALS] && errors[FAST_TRACK_TERMINALS]}
                />
                <FieldArray name={FAST_TRACK_TERMINALS_LIST}>
                  {({ remove, push }) => (
                    <InputField
                      disabled={
                        disabled ||
                        values[FAST_TRACK_TERMINALS] !== terminalsValues.SPECIFIC_TERMINALS
                      }
                      name={FAST_TRACK_TERMINALS_LIST_NEW_VALUE}
                      value={values[FAST_TRACK_TERMINALS_LIST_NEW_VALUE]}
                      onChange={handleChange}
                      inlineLabel
                      error={
                        errors[FAST_TRACK_TERMINALS_LIST] &&
                        polyglot.t(errors[FAST_TRACK_TERMINALS_LIST])
                      }
                      onKeyUp={handleAddToList(push, FAST_TRACK_TERMINALS_LIST_NEW_VALUE)}
                      label={polyglot.t('smart_pass.form.specific_terminals_label')}
                      placeholder={polyglot.t('smart_pass.form.press_enter_to_add_terminal')}
                      tags={
                        values[FAST_TRACK_TERMINALS_LIST].length > 0
                          ? values[FAST_TRACK_TERMINALS_LIST].map((tag, index) => (
                              <Tag
                                key={index}
                                selected
                                onRemove={disabled ? undefined : () => remove(index)}
                              >
                                {tag}
                              </Tag>
                            ))
                          : undefined
                      }
                    />
                  )}
                </FieldArray>
              </LabelsRowWrapper>
            </Stack>
            <Separator spaceAfter="medium" />
            <Text spaceAfter="small">
              {polyglot.t('smart_pass.form.fast_track.voucher_fulfilment')}
            </Text>
            <Stack spaceAfter="large">
              <LabelsRowWrapper>
                <Radio
                  label={
                    <Stack direction="row" align="center" spacing="XXSmall">
                      <Text>{polyglot.t('smart_pass.form.fast_track.automated_through_scan')}</Text>
                      <Tooltip
                        content={polyglot.t(
                          'smart_pass.form.fast_track.automated_through_scan.tooltip',
                        )}
                      >
                        <InformationCircleIcon size="small" />
                      </Tooltip>
                    </Stack>
                  }
                  disabled={disabled}
                  name={FAST_TRACK_VOUCHER_FULFILMENT}
                  checked={
                    values[FAST_TRACK_VOUCHER_FULFILMENT] ===
                    fastTrackVoucherFullfilmentValues.AUTOMATED
                  }
                  hasError={
                    touched[FAST_TRACK_VOUCHER_FULFILMENT] && errors[FAST_TRACK_VOUCHER_FULFILMENT]
                  }
                  value={fastTrackVoucherFullfilmentValues.AUTOMATED}
                  onChange={handleChange}
                />
                <Radio
                  label={
                    <Stack direction="row" align="center" spacing="XXSmall">
                      <Text>{polyglot.t('smart_pass.form.fast_track.manual')}</Text>
                      <Tooltip content={polyglot.t('smart_pass.form.fast_track.manual.tooltip')}>
                        <InformationCircleIcon size="small" />
                      </Tooltip>
                    </Stack>
                  }
                  disabled={disabled}
                  name={FAST_TRACK_VOUCHER_FULFILMENT}
                  checked={
                    values[FAST_TRACK_VOUCHER_FULFILMENT] ===
                    fastTrackVoucherFullfilmentValues.MANUAL
                  }
                  hasError={
                    touched[FAST_TRACK_VOUCHER_FULFILMENT] && errors[FAST_TRACK_VOUCHER_FULFILMENT]
                  }
                  value={fastTrackVoucherFullfilmentValues.MANUAL}
                  onChange={handleChange}
                />
              </LabelsRowWrapper>
              <Radio
                label={
                  <Stack direction="row" align="center" spacing="XXSmall">
                    <Text>
                      {polyglot.t('smart_pass.form.fast_track.reedemed_through_airport_website')}
                    </Text>
                    <Tooltip
                      content={polyglot.t(
                        'smart_pass.form.fast_track.reedemed_through_airport_website.tooltip',
                      )}
                    >
                      <InformationCircleIcon size="small" />
                    </Tooltip>
                  </Stack>
                }
                disabled={disabled}
                name={FAST_TRACK_VOUCHER_FULFILMENT}
                checked={
                  values[FAST_TRACK_VOUCHER_FULFILMENT] === fastTrackVoucherFullfilmentValues.WEB
                }
                hasError={
                  touched[FAST_TRACK_VOUCHER_FULFILMENT] && errors[FAST_TRACK_VOUCHER_FULFILMENT]
                }
                value={fastTrackVoucherFullfilmentValues.WEB}
                onChange={handleChange}
              />
            </Stack>
            {values[FAST_TRACK_VOUCHER_FULFILMENT] === fastTrackVoucherFullfilmentValues.WEB && (
              <Stack>
                <Stack spaceAfter="large" direction="row">
                  <InputField
                    label={<Text>{polyglot.t('smart_pass.form.fast_track.voucher_url')}</Text>}
                    placeholder={polyglot.t('smart_pass.form.fast_track.voucher_url_placeholder')}
                    disabled={disabled}
                    name={FAST_TRACK_STATIC_URL}
                    onBlur={handleBlur}
                    error={
                      touched[FAST_TRACK_STATIC_URL] &&
                      errors[FAST_TRACK_STATIC_URL] &&
                      polyglot.t(errors[FAST_TRACK_STATIC_URL])
                    }
                    value={values[FAST_TRACK_STATIC_URL]}
                    onChange={handleChange}
                  />
                  <InputField
                    label={
                      <Stack direction="row" align="center" spacing="XXSmall">
                        <Tooltip
                          preferredPosition="top"
                          content={polyglot.t('smart_pass.form.fast_track.webcode.tooltip')}
                        >
                          <InformationCircleIcon size="small" />
                        </Tooltip>
                        <Text>{polyglot.t('smart_pass.form.fast_track.webcode')}</Text>
                      </Stack>
                    }
                    placeholder={polyglot.t('smart_pass.form.fast_track.webcode')}
                    disabled={disabled}
                    onBlur={handleBlur}
                    name={FAST_TRACK_STATIC_KEYWORD}
                    error={
                      touched[FAST_TRACK_STATIC_KEYWORD] &&
                      errors[FAST_TRACK_STATIC_KEYWORD] &&
                      polyglot.t(errors[FAST_TRACK_STATIC_KEYWORD])
                    }
                    value={values[FAST_TRACK_STATIC_KEYWORD]}
                    onChange={handleChange}
                  />
                </Stack>

                <InputField
                  label={<Text>{polyglot.t('smart_pass.form.fast_track.sfaq_article_id')}</Text>}
                  placeholder={polyglot.t('smart_pass.form.fast_track.sfaq_article_id_placeholder')}
                  disabled={disabled}
                  name={SFAQ_ARTICLE_ID}
                  onBlur={handleBlur}
                  error={
                    touched[SFAQ_ARTICLE_ID] &&
                    errors[SFAQ_ARTICLE_ID] &&
                    polyglot.t(errors[SFAQ_ARTICLE_ID])
                  }
                  value={values[SFAQ_ARTICLE_ID]}
                  onChange={handleChange}
                />
              </Stack>
            )}
            {values[FAST_TRACK_VOUCHER_FULFILMENT] ===
              fastTrackVoucherFullfilmentValues.AUTOMATED && (
              <>
                <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.code_type')}</Text>
                <Stack spaceAfter="large">
                  <Radio
                    label={polyglot.t('smart_pass.form.vouchers.code_type.qr_code')}
                    name={FAST_TRACK_VOUCHER_CODE_TYPE}
                    disabled={disabled}
                    checked={
                      values[FAST_TRACK_VOUCHER_CODE_TYPE] ===
                      fastTrackVoucherCodeTypeValues.QR_CODE
                    }
                    value={fastTrackVoucherCodeTypeValues.QR_CODE}
                    onChange={handleChange}
                    hasError={
                      touched[FAST_TRACK_VOUCHER_CODE_TYPE] && errors[FAST_TRACK_VOUCHER_CODE_TYPE]
                    }
                  />
                  <Radio
                    label={polyglot.t('smart_pass.form.vouchers.code_type.pdf417')}
                    name={FAST_TRACK_VOUCHER_CODE_TYPE}
                    disabled={disabled}
                    checked={
                      values[FAST_TRACK_VOUCHER_CODE_TYPE] === fastTrackVoucherCodeTypeValues.PDF417
                    }
                    value={fastTrackVoucherCodeTypeValues.PDF417}
                    onChange={handleChange}
                    hasError={
                      touched[FAST_TRACK_VOUCHER_CODE_TYPE] && errors[FAST_TRACK_VOUCHER_CODE_TYPE]
                    }
                  />
                </Stack>
                <Text spaceAfter="small">{polyglot.t('smart_pass.form.code_generated')}</Text>
                <Stack spaceAfter="large">
                  <Radio
                    disabled={disabled}
                    label={
                      <Stack direction="row" align="center" spacing="XXSmall">
                        <Text>{polyglot.t('smart_pass.form.dynamic')}</Text>
                        <Tooltip
                          size="medium"
                          content={
                            <>
                              <Text spaceAfter="normal">
                                {polyglot.t('smart_pass.form.fast_track.dynamic.tooltip_1')}
                              </Text>
                              <Text spaceAfter="normal">
                                {polyglot.t('smart_pass.form.fast_track.dynamic.tooltip_2')}
                              </Text>
                              <Text>
                                &#8226; {polyglot.t('smart_pass.form.fast_track.dynamic.tooltip_3')}
                              </Text>
                              <Text>
                                &#8226; {polyglot.t('smart_pass.form.fast_track.dynamic.tooltip_4')}
                              </Text>
                              <Text>
                                &#8226; {polyglot.t('smart_pass.form.fast_track.dynamic.tooltip_5')}
                              </Text>
                            </>
                          }
                        >
                          <InformationCircleIcon size="small" />
                        </Tooltip>
                      </Stack>
                    }
                    name={FAST_TRACK_VOUCHER_CODE_GENERATION}
                    checked={
                      values[FAST_TRACK_VOUCHER_CODE_GENERATION] === dynamicStaticValues.DYNAMIC
                    }
                    value={dynamicStaticValues.DYNAMIC}
                    onChange={handleChange}
                    hasError={
                      touched[FAST_TRACK_VOUCHER_CODE_GENERATION] &&
                      errors[FAST_TRACK_VOUCHER_CODE_GENERATION]
                    }
                  />

                  <LabelsRowWrapper>
                    <Radio
                      disabled={disabled}
                      label={polyglot.t('smart_pass.form.static')}
                      name={FAST_TRACK_VOUCHER_CODE_GENERATION}
                      checked={
                        values[FAST_TRACK_VOUCHER_CODE_GENERATION] === dynamicStaticValues.STATIC
                      }
                      value={dynamicStaticValues.STATIC}
                      onChange={handleChange}
                      hasError={
                        touched[FAST_TRACK_VOUCHER_CODE_GENERATION] &&
                        errors[FAST_TRACK_VOUCHER_CODE_GENERATION]
                      }
                    />
                    <HalfWidthWrapper>
                      <InputField
                        placeholder={polyglot.t('smart_pass.form.fast_track.static_placeholder')}
                        disabled={
                          disabled ||
                          values[FAST_TRACK_VOUCHER_CODE_GENERATION] !== dynamicStaticValues.STATIC
                        }
                        name={FAST_TRACK_STATIC_KEYWORD}
                        onBlur={handleBlur}
                        error={
                          touched[FAST_TRACK_STATIC_KEYWORD] &&
                          errors[FAST_TRACK_STATIC_KEYWORD] &&
                          polyglot.t(errors[FAST_TRACK_STATIC_KEYWORD])
                        }
                        value={values[FAST_TRACK_STATIC_KEYWORD]}
                        onChange={handleChange}
                      />
                    </HalfWidthWrapper>
                  </LabelsRowWrapper>
                </Stack>
              </>
            )}
            <Separator spaceAfter="medium" />
            <Stack direction="row" justify="between" align="start" spaceAfter="medium">
              <HalfWidthWrapper>
                <Stack direction="column">
                  <Text spaceAfter="small">{polyglot.t('smart_pass.form.design_type')}</Text>
                  <LabelsRowWrapper>
                    <Radio
                      disabled={disabled}
                      label={polyglot.t('smart_pass.form.standard')}
                      onChange={handleChange}
                      name={FAST_TRACK_DESIGN_TYPE}
                      value={designTypeValues.STANDARD}
                      checked={values[FAST_TRACK_DESIGN_TYPE] === designTypeValues.STANDARD}
                    />
                    <Radio
                      disabled={disabled}
                      label={polyglot.t('smart_pass.form.own')}
                      onChange={handleChange}
                      name={FAST_TRACK_DESIGN_TYPE}
                      value={designTypeValues.CUSTOM}
                      checked={values[FAST_TRACK_DESIGN_TYPE] === designTypeValues.CUSTOM}
                    />
                  </LabelsRowWrapper>
                </Stack>
              </HalfWidthWrapper>
              <Stack direction="row" inline justify="end" align="center" spacing="XXSmall">
                <InformationCircleIcon size="small" color="info" />
                <TextLink onClick={fastTrackVoucherModal.setOn}>
                  <Text size="small" type="info" weight="bold">
                    {polyglot.t('smart_pass.form.voucher_example')}
                  </Text>
                </TextLink>
              </Stack>
            </Stack>

            {values[FAST_TRACK_DESIGN_TYPE] === designTypeValues.CUSTOM && (
              <Stack direction="row" align="end" spaceAfter="largest">
                <FileWithPreview>
                  <FileInput
                    disabled={disabled}
                    name={FAST_TRACK_CUSTOM_VOUCHER_DESIGN}
                    setFieldValue={setFieldValue}
                    value={values[FAST_TRACK_CUSTOM_VOUCHER_DESIGN]}
                    exactImageWidth={812}
                    exactImageHeight={168}
                    companyName={companyName}
                    label={polyglot.t('smart_pass.form.fast_track.voucher_design')}
                    help={polyglot.t('smart_pass.form.fast_track.voucher_design_help')}
                    error={
                      touched[FAST_TRACK_CUSTOM_VOUCHER_DESIGN] &&
                      errors[FAST_TRACK_CUSTOM_VOUCHER_DESIGN] &&
                      polyglot.t(errors[FAST_TRACK_CUSTOM_VOUCHER_DESIGN])
                    }
                  />
                </FileWithPreview>
              </Stack>
            )}
            <Separator spaceAfter="medium" />
            <Text spaceAfter="small">{polyglot.t('smart_pass.form.reporting_fast_track')}</Text>
            <Text spaceAfter="large" type="secondary">
              {polyglot.t('smart_pass.form.reporting_descr')}
            </Text>
            <Text spaceAfter="small">{polyglot.t('smart_pass.form.receive_reporting')}</Text>
            <Stack spaceAfter="large">
              <LabelsRowWrapper>
                <Radio
                  disabled={disabled}
                  label={polyglot.t('common.yes')}
                  name={FAST_TRACK_REPORT_EMAILS_ACTIVATED}
                  value={yesNoValues.YES}
                  checked={values[FAST_TRACK_REPORT_EMAILS_ACTIVATED] === yesNoValues.YES}
                  onChange={handleChange}
                  hasError={
                    touched[FAST_TRACK_REPORT_EMAILS_ACTIVATED] &&
                    errors[FAST_TRACK_REPORT_EMAILS_ACTIVATED]
                  }
                />
                <FieldArray name={FAST_TRACK_REPORT_EMAILS_LIST}>
                  {({ remove, push }) => (
                    <InputField
                      disabled={
                        disabled || values[FAST_TRACK_REPORT_EMAILS_ACTIVATED] !== yesNoValues.YES
                      }
                      name={FAST_TRACK_REPORT_EMAILS_NEW_VALUE}
                      value={values[FAST_TRACK_REPORT_EMAILS_NEW_VALUE]}
                      inlineLabel
                      label={polyglot.t('smart_pass.form.receive_reporting_label')}
                      placeholder={polyglot.t('smart_pass.form.press_enter_to_add_email')}
                      onChange={handleChange}
                      error={
                        errors[FAST_TRACK_REPORT_EMAILS_LIST] &&
                        polyglot.t(errors[FAST_TRACK_REPORT_EMAILS_LIST])
                      }
                      onKeyUp={handleAddToList(push, FAST_TRACK_REPORT_EMAILS_NEW_VALUE)}
                      tags={
                        values[FAST_TRACK_REPORT_EMAILS_LIST].length > 0
                          ? values[FAST_TRACK_REPORT_EMAILS_LIST].map((tag, index) => (
                              <Tag
                                key={index}
                                selected
                                onRemove={disabled ? undefined : () => remove(index)}
                              >
                                {tag}
                              </Tag>
                            ))
                          : undefined
                      }
                    />
                  )}
                </FieldArray>
              </LabelsRowWrapper>
              <Radio
                disabled={disabled}
                label={polyglot.t('common.no')}
                name={FAST_TRACK_REPORT_EMAILS_ACTIVATED}
                value={yesNoValues.NO}
                checked={values[FAST_TRACK_REPORT_EMAILS_ACTIVATED] === yesNoValues.NO}
                onChange={handleChange}
                hasError={
                  touched[FAST_TRACK_REPORT_EMAILS_ACTIVATED] &&
                  errors[FAST_TRACK_REPORT_EMAILS_ACTIVATED]
                }
              />
            </Stack>
            {values[FAST_TRACK_DESIGN_TYPE] === designTypeValues.CUSTOM && (
              <>
                <Separator spaceAfter="medium" />
                <Stack justify="end">
                  <Button
                    disabled={
                      !values[FAST_TRACK_CUSTOM_VOUCHER_DESIGN] ||
                      !values[FAST_TRACK_VOUCHER_FULFILMENT] ||
                      (values[FAST_TRACK_VOUCHER_FULFILMENT] ===
                        fastTrackVoucherFullfilmentValues.AUTOMATED &&
                        !values[FAST_TRACK_VOUCHER_CODE_GENERATION])
                    }
                    onClick={() => {
                      showVoucherPreviewModal({
                        voucherInfo: { ...values, isFastTrack: true },
                        smartPassInfo: {},
                        previewType: previewTypeKeys.CUSTOM_FAST_TRACK,
                      });
                    }}
                  >
                    {polyglot.t('smart_pass.form.vouchers.voucher_type.voucher_preview')}
                  </Button>
                </Stack>
              </>
            )}
          </CardSection>
        )}
      </Card>
      {values[FAST_TRACK_ACTIVATED] && (
        <Card
          spaceAfter="largest"
          header={
            <Stack direction="row" justify="between" align="center">
              <Heading as="h2" type="title3">
                {polyglot.t('smart_pass.form.transfer_process.header_title')}
              </Heading>
            </Stack>
          }
        >
          <CardSection>
            <Text spaceAfter="small">
              {polyglot.t('smart_pass.form.transfer_process.subtitle')}
            </Text>
            <Text spaceAfter="medium" type="secondary">
              {polyglot.t('smart_pass.form.transfer_process.description')}
            </Text>
            <Textarea
              disabled={disabled}
              placeholder={polyglot.t('smart_pass.form.transfer_process.textarea')}
              rows={6}
              resize="none"
              spaceAfter="medium"
              value={values[TRANSFER_PROCESS]}
              onChange={handleChange}
              name={TRANSFER_PROCESS}
            />
          </CardSection>
        </Card>
      )}
    </>
  );
};

export default React.memo(FastTrack, propsComparison(fastTrackFormKeys));
