import * as R from 'ramda';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import { FULL_DATE_FORMAT } from 'consts/dates';
import { SHOW_VALUES_I18N, METRICS_SNAKE_CAMEL_CASE_MAPPING } from 'consts/reports';
import { defaultPagination } from 'components/services/reports';

const v1_post_to = reportAlias => {
  return R.reject(R.isNil, {
    alias: reportAlias.alias,
    alias_type: reportAlias.aliasType,
    report_config: R.reject(R.isNil, {
      metrics: reportAlias.show
        ? reportAlias.show.map(metric => {
            const formattedValue = METRICS_SNAKE_CAMEL_CASE_MAPPING.find(
              formattedMetric => formattedMetric.camelCase === metric.value,
            );
            return formattedValue ? formattedValue.snakeCase : metric.value;
          })
        : [],
      // comparing reports need to have millennium | backend issue :P
      interval:
        reportAlias.dateRangeToCompare && reportAlias.dateRangeToCompare.length
          ? 'millennium'
          : reportAlias.interval,
      date_range:
        R.isEmpty(reportAlias.dateRange) || !reportAlias.dateRange
          ? {}
          : {
              from: format(startOfDay(new Date(R.head(reportAlias.dateRange))), FULL_DATE_FORMAT),
              to: format(endOfDay(new Date(R.last(reportAlias.dateRange))), FULL_DATE_FORMAT),
            },
      compare_with:
        R.isEmpty(reportAlias.dateRangeToCompare) || !reportAlias.dateRangeToCompare
          ? null
          : {
              from: format(
                startOfDay(new Date(R.head(reportAlias.dateRangeToCompare))),
                FULL_DATE_FORMAT,
              ),
              to: format(
                endOfDay(new Date(R.last(reportAlias.dateRangeToCompare))),
                FULL_DATE_FORMAT,
              ),
            },
      filters: reportAlias.filters
        ? {
            affiliate: reportAlias.filters.affiliate,
            market: reportAlias.filters.market,
            device: reportAlias.filters.device,
            platform: reportAlias.filters.platform,
            route_type: reportAlias.filters.routeType,
          }
        : {},
      sort: reportAlias.sort,
    }),
    parent_id: reportAlias.parentId,
    version: 2,
  });
};

const v1_put_to = reportAlias => {
  return R.reject(R.isNil, {
    alias: reportAlias.alias,
    report_config: R.reject(R.isNil, {
      metrics: reportAlias.show
        ? reportAlias.show.map(metric => {
            const formattedValue = METRICS_SNAKE_CAMEL_CASE_MAPPING.find(
              formattedMetric => formattedMetric.camelCase === metric.value,
            );
            return formattedValue ? formattedValue.snakeCase : metric.value;
          })
        : [],
      // comparing reports need to have millennium | backend issue :P
      interval:
        reportAlias.dateRangeToCompare && reportAlias.dateRangeToCompare.length
          ? 'millennium'
          : reportAlias.interval,
      date_range:
        R.isEmpty(reportAlias.dateRange) || !reportAlias.dateRange
          ? {}
          : {
              from: format(startOfDay(new Date(R.head(reportAlias.dateRange))), FULL_DATE_FORMAT),
              to: format(endOfDay(new Date(R.last(reportAlias.dateRange))), FULL_DATE_FORMAT),
            },
      compare_with:
        R.isEmpty(reportAlias.dateRangeToCompare) || !reportAlias.dateRangeToCompare
          ? null
          : {
              from: format(
                startOfDay(new Date(R.head(reportAlias.dateRangeToCompare))),
                FULL_DATE_FORMAT,
              ),
              to: format(
                endOfDay(new Date(R.last(reportAlias.dateRangeToCompare))),
                FULL_DATE_FORMAT,
              ),
            },
      filters: reportAlias.filters
        ? {
            affiliate: reportAlias.filters.affiliate,
            market: reportAlias.filters.market,
            device: reportAlias.filters.device,
            platform: reportAlias.filters.platform,
            route_type: reportAlias.filters.routeType,
          }
        : {},
      sort: reportAlias.sort,
    }),
    parent_id: reportAlias.parentId,
    version: 2,
  });
};

const v1_get_from = (reportAlias, polyglot) => {
  return {
    alias: reportAlias.alias,
    id: reportAlias.id,
    user: reportAlias.user,
    aliasType: reportAlias.alias_type,
    dateRange: reportAlias.report_config.date_range
      ? eachDayOfInterval({
          start: new Date(reportAlias.report_config.date_range.from),
          end: new Date(reportAlias.report_config.date_range.to),
        }).map(d => format(d, FULL_DATE_FORMAT))
      : [],
    dateRangeToCompare: reportAlias.report_config.compare_with
      ? eachDayOfInterval({
          start: new Date(reportAlias.report_config.compare_with.from),
          end: new Date(reportAlias.report_config.compare_with.to),
        }).map(d => format(d, FULL_DATE_FORMAT))
      : [],
    isCompare:
      R.isNil(reportAlias.report_config.compare_with) ||
      R.isEmpty(reportAlias.report_config.compare_with)
        ? false
        : true,
    interval: reportAlias.report_config.interval,
    show: reportAlias.report_config.metrics
      ? reportAlias.report_config.metrics.map(value => {
          const formattedValue = METRICS_SNAKE_CAMEL_CASE_MAPPING.find(
            formattedMetric => formattedMetric.snakeCase === value,
          );
          if (formattedValue) {
            return {
              value: formattedValue.camelCase,
              label: polyglot.t(
                SHOW_VALUES_I18N.find(metric => metric.value === formattedValue.camelCase).k,
              ),
            };
          } else {
            return {
              value,
              label: polyglot.t(SHOW_VALUES_I18N.find(metric => metric.value === value).k),
            };
          }
        })
      : [],
    filters: reportAlias.report_config.filters
      ? R.pickBy(element => !R.isNil(element) && !R.isEmpty(element), {
          affiliate: reportAlias.report_config.filters.affiliate,
          market: reportAlias.report_config.filters.market,
          device: reportAlias.report_config.filters.device,
          platform: reportAlias.report_config.filters.platform,
          routeType: reportAlias.report_config.filters.route_type,
        })
      : {},
    sort: reportAlias.report_config.sort,
    parentId: reportAlias.parent_id,
    lastModified: new Date(reportAlias.updated_at),
  };
};

const v2_get_to = pagination => {
  return {
    params: {
      _page: pagination?.page || defaultPagination.page,
      _per_page: pagination?.perPage || defaultPagination.perPage,
    },
  };
};

const v2_get_from = (data, pagination) => {
  return {
    data: data.map(alias => ({
      name: alias.alias,
      aliasType: alias.alias_type,
      id: alias.id,
      reportType: alias.report_type,
      createdAt: alias.created_at,
      updatedAt: alias.updated_at,
      dateRange: alias.report_config.date_range,
    })),
    pagination: {
      page: pagination.previous_page + 1,
      perPage: pagination.page_size,
      totalCount: pagination.total,
      pageCount: pagination.pages,
    },
  };
};

const v2_post_to = reportAlias => {
  return {
    alias: reportAlias.alias,
    alias_type: reportAlias.aliasType,
    report_config: {
      date_range: {
        from: reportAlias.reportConfig?.dateRange.from,
        to: reportAlias.reportConfig?.dateRange.to,
      },
      filters: {
        ...(reportAlias.reportConfig.filters.solutions?.length && {
          affiliate: reportAlias.reportConfig.filters.solutions,
        }),
        ...(reportAlias.reportConfig.filters.companies?.length && {
          company: reportAlias.reportConfig.filters.companies,
        }),
        ...(reportAlias.reportConfig.filters.market && {
          market: reportAlias.reportConfig.filters.market,
        }),
        ...(reportAlias.reportConfig.filters.device && {
          device: reportAlias.reportConfig.filters.device,
        }),
        ...(reportAlias.reportConfig.filters.routeType && {
          route_type: reportAlias.reportConfig.filters.routeType,
        }),
        ...(reportAlias.reportConfig.filters.finalStatus && {
          final_status: reportAlias.reportConfig.filters.finalStatus,
        }),
      },
      interval: reportAlias.reportConfig.interval,
      metrics: reportAlias.reportConfig.metrics,
    },
    report_type: reportAlias.reportType,
    version: 3,
  };
};

const v2_post_from = data => {
  return {
    id: data.id,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      to: v1_post_to,
      from: v1_get_from,
    },
    put: {
      from: v1_get_from,
      to: v1_put_to,
    },
  },
  v2: {
    get: {
      to: v2_get_to,
      from: v2_get_from,
    },
    post: {
      to: v2_post_to,
      from: v2_post_from,
    },
  },
};
