import React from 'react';
import styled from 'styled-components';
import * as dfs from 'date-fns';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { useToggle } from 'utils/hooks';
import { useCan, useCurrentUser } from 'components/services/auth';
import { useCurrentCompanySettings } from 'components/services/companySettings';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { usePolyglot } from 'components/services/i18n';
import { Flex, Space } from 'common';

import NoData from './components/NoData';
import Table from './components/Table';
import WhitelistModal from './components/WhitelistModal';

const Wrapper = styled.div`
  max-width: 960px;
`;

const ButtonWrapper = styled.div`
  height: 32px;
`;

const Container = ({ actions, children }) => {
  return (
    <Wrapper>
      <Card>
        <CardSection title="IP Allowlist" actions={actions}>
          {children}
        </CardSection>
      </Card>
    </Wrapper>
  );
};

const IPWhitelist = () => {
  const user = useCurrentUser();
  const companyName = useCurrentCompanyName();
  const { loading, data, error } = useCurrentCompanySettings();
  const addIPModal = useToggle();
  const polyglot = usePolyglot();

  const canUpdateMain = useCan(user, ['update'], 'model.company_settings', companyName);
  const canUpdateOwn = useCan(user, ['update_own'], 'model.company_settings', companyName);
  const canUpdateAssigned = useCan(
    user,
    ['update_assigned'],
    'model.company_settings',
    companyName,
  );

  const canUpdate = canUpdateMain || canUpdateOwn || canUpdateAssigned;
  const canEnableWhitelisting = canUpdate?.includes?.('ip_whitelist_enabled') || false;

  const enabled = data?.whitelistEnabled;
  const hasData = data?.ipWhitelist?.length > 0 || enabled;

  if (loading) {
    return (
      <Container>
        <Loading type="pageLoader" text={polyglot.t('common.loading_content')} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Stack direction="column" justify="center" align="center" spacing="none">
          <Illustration name="NoResults" spaceAfter="largest" />

          <Text weight="bold" spaceAfter="small">
            {polyglot.t('booking_tool.payment.error_modal.title')}
          </Text>

          <Text type="secondary" spaceAfter="large">
            {polyglot.t('allowlist.error_refresh_description')}
          </Text>

          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            {polyglot.t('common.refresh_page')}
          </Button>
        </Stack>
      </Container>
    );
  }

  const updatedAt = new Date(data.updatedAt);
  updatedAt.setTime(updatedAt.getTime() - updatedAt.getTimezoneOffset() * 60000);

  return (
    <Container
      actions={
        canEnableWhitelisting ? (
          <ButtonWrapper>
            {enabled && (
              <Button size="small" onClick={addIPModal.setOn}>
                {polyglot.t('allowlist.add_ip')}
              </Button>
            )}
          </ButtonWrapper>
        ) : (
          <Flex cross="center">
            <Space right="n">
              <Text size="small" type="secondary">
                {polyglot.t('widgets.header.last_updated')}:{' '}
                {dfs.format(updatedAt, "dd/MM/yyyy' - 'HH:mm")}
              </Text>
            </Space>
            <Badge type={enabled ? 'success' : 'criticalInverted'}>
              {enabled ? polyglot.t('solutions.enabled') : polyglot.t('solutions.disabled')}
            </Badge>
          </Flex>
        )
      }
    >
      {addIPModal.isOn && (
        <WhitelistModal onClose={addIPModal.setOff} data={data?.ipWhitelist || []} />
      )}
      {hasData ? <Table enabled={enabled} data={data} canUpdate={canUpdate} /> : <NoData />}
    </Container>
  );
};

export default IPWhitelist;
