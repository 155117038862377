import React from 'react';
import styled from 'styled-components';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';

const IllustrationWrapper = styled.div`
  margin-top: -24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 121px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IllustratedCard = ({
  type = 'primary',
  title,
  illustrationName,
  help,
  action,
  children,
  dataTest,
}) => {
  return (
    <Card
      title={
        <Text type={type} size="large">
          {title}
        </Text>
      }
      dataTest={dataTest}
    >
      <CardSection>
        <Container>
          <Wrapper>{children}</Wrapper>

          <IllustrationWrapper>
            <Illustration name={illustrationName} size="small" />
          </IllustrationWrapper>
        </Container>
        {help}
        {action}
      </CardSection>
    </Card>
  );
};

export default IllustratedCard;
