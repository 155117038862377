import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

import * as messagesActions from 'redux/modules/messages';
import { getSuccessMessages } from 'redux/selectors/messages';
import Message from 'shapes/Message';

import { Snackbar } from 'common';

/**
 * Displays notifications as Toasts.
 * Multiple notifications are supported, but they will be shown one by one.
 * onDismiss function should clear the first message in queue, so that the second one will be displayed.
 */
class Messages extends Component {
  handleDismiss = () => {
    const { onDismiss, messages } = this.props;
    if (R.isEmpty(messages)) {
      return;
    }

    onDismiss(messages[0].id);
  };

  render() {
    const { messages } = this.props;
    if (messages.length > 2) {
      this.handleDismiss();
    }
    return (
      <Snackbar id="succes-messages-snackbar" toasts={messages} onDismiss={this.handleDismiss} />
    );
  }
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(Message)).isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  messages: getSuccessMessages(state),
});

const mapDispatchToProps = {
  onDismiss: messagesActions.clearMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
