import { useContext } from 'react';
import { MMBState } from './';

const useBookingDetailsIframes = () => {
  const {
    state: { iframes },
    addBookingIframe,
    removeBookingIframe,
  } = useContext(MMBState);

  return { iframes, addBookingIframe, removeBookingIframe };
};

export default useBookingDetailsIframes;
