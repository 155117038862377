import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import { useForm, FormProvider } from 'react-hook-form/dist/index.ie11';

import Modal from '@kiwicom/orbit-components/lib/Modal';

import CreateIssueModalContent from './CreateIssueModalContent';

const CreateIssueSchema = Yup.object().shape({
  subject: Yup.string().required('booking.required'),
  description: Yup.string().required('booking.required'),
  attachments: Yup.mixed(),
  bid: Yup.number().typeError('validation.must_be_numbers').required('booking.required'),
  subcategory: Yup.object().shape({
    field: Yup.string().required('booking.required'),
    value: Yup.string().required('booking.required'),
  }),
});

const CreateIssueModal = ({ onClose, requestType, bid = undefined }) => {
  const form = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(CreateIssueSchema),
    defaultValues: {
      subject: undefined,
      description: undefined,
      attachments: { name: undefined, data: new FormData() },
      bid: bid,
      subcategory: {
        field: undefined,
        value: undefined,
      },
    },
  });

  return (
    <Modal onClose={onClose}>
      <FormProvider {...form}>
        <CreateIssueModalContent onClose={onClose} requestType={requestType} />
      </FormProvider>
    </Modal>
  );
};

export default CreateIssueModal;
