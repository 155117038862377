import React from 'react';

const Illustration3 = ({ color1, color2 }) => (
  <svg viewBox="0 0 2200 1520.19">
    <defs>
      <linearGradient
        id="ill3_pref__linear-gradient_ill3"
        x1={1759.24}
        y1={929.9}
        x2={960.36}
        y2={1429.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" />
        <stop offset={0.04} stopColor="#bac7d5" stopOpacity={0.96} />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill3_pref__linear-gradient_ill3-2"
        x1={1175.8}
        y1={819.06}
        x2={1016.02}
        y2={908.34}
        xlinkHref="#ill3_pref__linear-gradient_ill3"
      />
      <linearGradient
        id="ill3_pref__linear-gradient_ill3-3"
        x1={-10726.69}
        y1={530.91}
        x2={-9445.47}
        y2={1296.29}
        gradientTransform="matrix(-1 0 0 1 -8705.34 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009882" />
        <stop offset={1} stopColor="#01a891" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill3_pref__linear-gradient_ill3-4"
        x1={961.7}
        y1={852.76}
        x2={475.31}
        y2={1124.57}
        xlinkHref="#ill3_pref__linear-gradient_ill3"
      />
      <linearGradient
        id="ill3_pref__linear-gradient_ill3-5"
        x1={1012.1}
        y1={755.33}
        x2={848.27}
        y2={846.88}
        xlinkHref="#ill3_pref__linear-gradient_ill3"
      />
      <style>
        {
          '.ill3_pref__cls-11,.ill3_pref__cls-6,.ill3_pref__cls-7,.ill3_pref__cls-8{fill-rule:evenodd}.ill3_pref__cls-6{fill:#cad5df}.ill3_pref__cls-7{fill:#e8edf1}.ill3_pref__cls-8{fill:#b9c7d5}.ill3_pref__cls-12{fill:#a6b5c8}.ill3_pref__cls-11{fill:#6d809c}'
        }
      </style>
    </defs>
    <g
      style={{
        isolation: 'isolate',
      }}
    >
      <g id="ill3_pref__Layer_10" data-name="Layer 10">
        <path
          d="M1344.72 1116.89l126.4 86.63-405.88 232.91-126.74-86.68z"
          fill="url(#ill3_pref__linear-gradient_ill3)"
          fillRule="evenodd"
        />
        <path
          d="M1083.75 979.83l-36.35-21.41a20.86 20.86 0 01-10.47-18.07V799.53a20.81 20.81 0 0131.16-18.06L1191.38 853a20.86 20.86 0 0110.47 18.06l-7.54 10.4c0-2.05-3.94-11.33-10.42-15.38l-123.3-71.55a5.73 5.73 0 00-8.58 5v140.82a5.74 5.74 0 002.88 5l43.88 26z"
          fill="url(#ill3_pref__linear-gradient_ill3-2)"
        />
        <path
          fill="url(#ill3_pref__linear-gradient_ill3-3)"
          d="M481.47 1321.94l1103.96-627.01-65.79-39.03 327-6.9-33.48 181-74.17-43.99-1103.03 627.06-154.49-91.13z"
        />
        <path
          d="M780.43 902l76.46 65-356 198.31-95-57.52z"
          fill="url(#ill3_pref__linear-gradient_ill3-4)"
          fillRule="evenodd"
        />
        <path
          className="ill3_pref__cls-6"
          d="M828.24 962.6L771 999l-3.51-50.62-1.85-594 5.13-20.94 30.17 17v49.86l27.73 16.31 3.15 320.78zM922 470.42v445.11l-75 36.06-5.65-223.76L847 427.28z"
        />
        <path
          className="ill3_pref__cls-7"
          d="M913.06 308.13l113 65.25 75.44-43.64v-4.49l-113-64.84-75.45 43.23z"
        />
        <path
          className="ill3_pref__cls-7"
          d="M1488.33 476.83L1450.11 513l-27.84 5.36-21.34 6.66v12.08l-9.77-.8-27.19 24.11-65.8 29.7-347.79-192.74-240.59-155.94 93.39-54.23 13.46 8.16 17.53-10.61 13.47-7.75-13.47-7.74 62.41-35.89 591.75 343.46z"
        />
        <path
          className="ill3_pref__cls-6"
          d="M1301.95 584.94l92.98-53.81 2.14 2.33-1 697.43v7.74l.3 10.88-84.67 44.95-3.42-2.18-20.47-631.53 14.14-75.81zM1488.33 476.82v718.16l-62 36.46-10.57-660.94 10.28-57.32 62.29-36.36z"
        />
        <path
          className="ill3_pref__cls-8"
          d="M1412.88 1223.12l-5.01-697.63 5.01-20.05 13.45 7.75v718.25l-13.45-8.32z"
        />
        <path
          fill="#a6b5c8"
          fillRule="evenodd"
          d="M1394.93 1233.32V531.13l-13.05-8.16 31-17.53v717.68l-8.25 2.9-9.7 7.3z"
        />
        <path className="ill3_pref__cls-8" d="M834.38 169.36l13.46 7.75-13.46 7.75v-15.5z" />
        <path
          fill="#d8e1e8"
          d="M1308.87 589.54l-.61 704.08-119.3-62.81-1.63-100.56-.01-427.03-274.63-169.06-26.31-15.05-23.54-13.46-26.32-15.05-20.39-11.66-26.32-15.05-16.64-9.52-2.43 541.28-63.2-37.69 1.82-715.79 85.55 45.93 26.29 15.43 20.4 11.96 26.3 15.43 23.53 13.79 26.3 15.43 391.14 229.4z"
        />
        <path
          className="ill3_pref__cls-11"
          d="M1488.33 1195.55l-62 35.89v-21.62l62-36.29v22.02zM1394.93 1228l-.37-15.4 18.32-9.3v21.88l-16.51 9.52v15.5l-87.85 50.86v-24.04l86.41-49.02z"
        />
        <path
          className="ill3_pref__cls-12"
          d="M846.1 495.9l.2 456.11-18.76 11.01.2-477.72 18.36 10.6z"
        />
        <path
          fill="#6d809c"
          d="M770.74 1017.25v-21.6l57.5-33.03L847 951.6l75.04-43.22v21.61l-151.3 87.68-.18-.1.18-.32z"
        />
        <path
          className="ill3_pref__cls-12"
          d="M770.56 1017.57l-63.02-38v-21.61l63.2 37.69v21.6l-.18.32zM1426.33 1231.81l-13.45-7.74v-22.03l13.45 7.75v22.02z"
        />
        <path fill="#305f9b" fillRule="evenodd" d="M1283.65 840.63h-.97v-.47l.97.47z" />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          fill="#6fa1a5"
          fillRule="evenodd"
          d="M1308.29 1128.25l179.81-94.61.23 139.89-93.4 54.47-86.41 49.02-.23-148.77z"
        />
        <path
          d="M1308.78 1193l-.52-.24z"
          stroke="#6fa1a5"
          strokeMiterlimit={10}
          strokeWidth={3.53}
          fill="#6fa1a5"
        />
        <path
          fill="#6fa1a5"
          fillRule="evenodd"
          d="M1308.29 1128.25l.23 172.81-119.56-70.25-2.02-165.62 121.35 63.06z"
        />
        <path
          fill="#6fa1a5"
          d="M771.47 831.59l-.73 164.06-63.2-37.69.41-160.37 62.79 34.36.73-.36z"
        />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          fill="#6fa1a5"
          d="M922.03 759.49v148.89l-151.29 87.27.73-164.06 150.56-72.1z"
        />
        <path
          d="M995.63 930.12a20.78 20.78 0 01-10.41-2.82l-130.88-75.57a20.86 20.86 0 01-10.41-18V693a20.82 20.82 0 0131.22-18L1006 750.48a20.89 20.89 0 0110.41 18v140.79a20.89 20.89 0 01-20.81 20.85zM864.77 687.18a5.9 5.9 0 00-2.89.79 5.65 5.65 0 00-2.87 5v140.74a5.77 5.77 0 002.87 5l130.88 75.56a5.74 5.74 0 008.61-5V768.51a5.75 5.75 0 00-2.87-5L867.61 688a5.58 5.58 0 00-2.84-.82z"
          fill="url(#ill3_pref__linear-gradient_ill3-5)"
        />
        <path fill="none" d="M771.55 813.9l-.08 17.69" />
        <path d="M873.27 380.65v106l-96.51-58.05V322.75l96.51 57.9z" fill={color2} />
        <path fill={color1} d="M1194.31 573.28l-.82 105.99-320.22-192.62v-106l321.04 192.63z" />
        <path d="M906.02 439.95v23.98l264.69 158.77-.43-24.43-264.26-158.32z" fill={color2} />
        <path
          d="M824.64 435.08l8.45 7.42v-4.87l-7.21-9.08a45.14 45.14 0 00.25-5.64V412.8l21.77 17.09v-5.69l-9.68-10.74-4.22-4.67-8-8.79-.08-16.92a24.44 24.44 0 00-.94-6.89c-.73-2.4-1.82-4.05-3-4.65s-2.2-.1-2.91 1.53a15.28 15.28 0 00-.89 5.91l.08 17.08-21.41 1v5.69l21.45 6v10a52.76 52.76 0 00.31 5.93l.16 1-7 .64v4.86l8.11 1.5v-.17l1 5.86c.35 2.12 2.44 3.24 2.77 1.47z"
          fill={color1}
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default React.memo(Illustration3);
