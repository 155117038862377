/**
 * extractType will extract "type" key from the object and set new object data in that object
 * @param {Array.<Object>} data
 * @returns {object}
 */

export const extractType = data => {
  return data.reduce((res, item) => {
    const { type, ...rest } = item;
    res[type] = rest;
    return res;
  }, {});
};

export const getLast20CharsBookingToken = bookingToken => bookingToken.slice(-20);
