import { useContext, useEffect } from 'react';
import { CommissionsState } from './index';

const useAffilIdCommissions = (affilId, uuid, initialParams) => {
  const ctx = useContext(CommissionsState);

  useEffect(() => {
    ctx.loadAffilIdCommissions(affilId, uuid, initialParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affilId]);

  if (!affilId) {
    return null;
  }

  return {
    commercials: {
      ...(ctx.state[affilId] || {
        loading: true,
        data: null,
        error: null,
      }),
    },
    loadCommercials: ctx.loadAffilIdCommissions,
  };
};

export default useAffilIdCommissions;
