import styled from 'styled-components';

import { TableRow } from '@kiwicom/orbit-components/lib/Table';

export const ChangeTableRow = styled(TableRow)`
  font-weight: 500;
`;

const getColor = ({ positive, theme }) => {
  return positive ? theme.orbit.colorTextSuccess : theme.orbit.colorTextError;
};

export const ChangeValue = styled.span`
  color: ${getColor};
`;
