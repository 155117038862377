import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import * as R from 'ramda';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import CircleEmptyIcon from '@kiwicom/orbit-components/lib/icons/CircleEmpty';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import MinusIcon from '@kiwicom/orbit-components/lib/icons/Minus';

const StepDisplay = ({ steps, currentStep, theme }) => (
  <Stack direction="row" spacing="none" shrink basis="120px" grow={false}>
    {R.range(0, steps).map(i => {
      const { paletteProductNormal, paletteInkLighter } = theme.orbit;
      const color = i <= currentStep ? paletteProductNormal : paletteInkLighter;
      const Circle = i < currentStep ? CheckCircleIcon : CircleEmptyIcon;
      const isLast = i === steps - 1;
      if (isLast) {
        return <Circle key={i} customColor={color} />;
      }
      return (
        <React.Fragment key={i}>
          <Circle customColor={color} /> <MinusIcon customColor={color} />
        </React.Fragment>
      );
    })}
  </Stack>
);

StepDisplay.propTypes = {
  steps: PropTypes.number.isRequired,
  currentStep: PropTypes.number,
};

export default withTheme(StepDisplay);
