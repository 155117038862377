import React, { useState, useEffect, useContext } from 'react';
import { Prompt } from 'react-router-dom';
import * as R from 'ramda';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import AlertCircleIcon from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';
import { saveChangesRoutes } from 'consts/saveChangesRoutes';
import { SmartpointState } from 'components/services/smartpoint';

import { PageContainer, LoaderContainer } from './index.styled';
import PCCConfiguration from './components/PCCConfiguration';
import useCompanyPCCs from './services/useCompanyPCCs';
import { SAVE_BUTTON_STATUS } from './consts';

const SmartpointConfiguration = () => {
  const [companyPCCs, setCompanyPCCs] = useState([]);
  const [savingPCCApp, setSavingPCCApp] = useState([]);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const polyglot = usePolyglot();
  const isBlocked = useToggle();
  const companyName = useCurrentCompanyName();
  const loadedCompanyPCCs = useCompanyPCCs();

  const { updatePCCAppState, loadCompanyPCCApps, updatePCCApp } = useContext(SmartpointState);

  useEffect(() => {
    setCompanyPCCs(loadedCompanyPCCs.data);
  }, [loadedCompanyPCCs]);

  useEffect(() => {
    // Reload pcc apps on leaving this page for this company if changes were made
    return () => {
      if (changesMade) {
        loadCompanyPCCApps(companyName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesMade]);

  useEffect(() => {
    // If no longer saving, check for request statuses
    if (!R.isEmpty(savingPCCApp)) {
      if (savingPCCApp.some(pccAppId => updatePCCAppState[pccAppId].loading)) {
        return;
      }
      if (savingPCCApp.some(pccAppId => updatePCCAppState[pccAppId].error)) {
        // Refresh the page with by fetching correct values if any failed
        loadCompanyPCCApps(companyName);
        setErrorOccurred(true);
      } else {
        setChangesMade(true);
        // Update local state to hide save button and remove prompt lock
        setCompanyPCCs(
          companyPCCs.map(pcc => {
            if (
              pcc.saveButtonStatus === SAVE_BUTTON_STATUS.LOADING &&
              pcc.applications.every(pccApp => !updatePCCAppState[pccApp.id].loading)
            ) {
              return {
                ...pcc,
                saveButtonStatus: SAVE_BUTTON_STATUS.HIDDEN,
              };
            } else {
              return pcc;
            }
          }),
        );
      }
      setSavingPCCApp(
        Object.entries(updatePCCAppState)
          .filter(([pccAppId, state]) => state.loading)
          .map(([pccAppId, state]) => pccAppId),
      );
      isBlocked.setOff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePCCAppState]);

  if (loadedCompanyPCCs.error) {
    return (
      <PageContainer>
        <Alert
          type="critical"
          icon={<AlertCircleIcon />}
          title={polyglot.t('common.error_occurred')}
        >
          <Text>{polyglot.t('smartpoint_configuration.error_content')}</Text>
        </Alert>
      </PageContainer>
    );
  }

  if (!loadedCompanyPCCs.data || loadedCompanyPCCs.loading) {
    return (
      <PageContainer>
        <LoaderContainer>
          <Stack align="center" justify="center">
            <Loading type="pageLoader" />
          </Stack>
        </LoaderContainer>
      </PageContainer>
    );
  }

  // If fetching was successful, but the PCC array is empty
  if (!loadedCompanyPCCs.loading && loadedCompanyPCCs.data && R.isEmpty(loadedCompanyPCCs.data)) {
    return (
      <PageContainer>
        <Alert
          type="info"
          icon={<InformationCircleIcon />}
          title={polyglot.t('smartpoint_configuration.no_content.title')}
        >
          <Text>{polyglot.t('smartpoint_configuration.no_content.body')}</Text>
        </Alert>
      </PageContainer>
    );
  }

  const getListOfChangedPCCs = () =>
    companyPCCs
      .filter(pcc => pcc.saveButtonStatus !== SAVE_BUTTON_STATUS.HIDDEN)
      .map(pcc => pcc.code)
      .join(', ');

  const handleSolutionChange = (pccCode, productType) => event => {
    const { affilId } = event.target.value;
    if (companyPCCs.find(app => app.applications?.[0]?.affilId === affilId)) {
      return;
    }
    const newCompanyPCCs = [...companyPCCs];
    const changedPccConfig = newCompanyPCCs.find(pcc => pcc.code === pccCode);
    changedPccConfig.saveButtonStatus = SAVE_BUTTON_STATUS.DISPLAYED;

    const pccApp = changedPccConfig.applications.find(pa => pa.productType === productType);
    if (pccApp) {
      pccApp.affilId = affilId;
    }

    isBlocked.setOn();
    setCompanyPCCs(newCompanyPCCs);
  };

  const handleSaveClick = pccCode => () => {
    const newCompanyPCCs = [...companyPCCs];
    const newSavingPCCApps = [];
    const pccConfig = newCompanyPCCs.find(pcc => pcc.code === pccCode);

    pccConfig.saveButtonStatus = SAVE_BUTTON_STATUS.LOADING;
    pccConfig.applications.forEach(pccApp => {
      const { id, affilId } = pccApp;
      newSavingPCCApps.push(id);
      updatePCCApp(id, { application_code: affilId });
    });

    setCompanyPCCs(newCompanyPCCs);
    setSavingPCCApp([...savingPCCApp, ...newSavingPCCApps]);
  };

  return (
    <PageContainer>
      {errorOccurred && (
        <Alert
          type="critical"
          icon={<AlertCircleIcon />}
          title={polyglot.t('common.error_occurred')}
          closable
          onClose={() => setErrorOccurred(false)}
          spaceAfter="large"
        >
          <Text>{polyglot.t('smartpoint_configuration.save_error')}</Text>
        </Alert>
      )}
      <Prompt
        when={isBlocked.isOn}
        message={JSON.stringify({
          type: saveChangesRoutes.SMARTPOINT_CONFIGURATION,
          payload: { listOfChangedPCCs: getListOfChangedPCCs() },
        })}
      />
      {companyPCCs.map(pcc => {
        const { code, saveButtonStatus, applications: solutions } = pcc;
        return (
          <PCCConfiguration
            key={code}
            code={code}
            saveButtonStatus={saveButtonStatus}
            solutions={solutions}
            handleSaveClick={handleSaveClick}
            handleSolutionChange={handleSolutionChange}
          />
        );
      })}
    </PageContainer>
  );
};

export default SmartpointConfiguration;
