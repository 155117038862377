import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

import { ExitRow } from '../index.styled';

const SeatExitRow = () => {
  const polyglot = usePolyglot();

  return (
    <ExitRow>
      <Text type="secondary" size="small">
        {polyglot.t('booking.additional_services.seating.seat_map.exit')}
      </Text>
      <Text type="secondary" size="small">
        {polyglot.t('booking.additional_services.seating.seat_map.exit')}
      </Text>
    </ExitRow>
  );
};

export default SeatExitRow;
