import React, { useState } from 'react';

import Slider from '@kiwicom/orbit-components/lib/Slider';

import { usePolyglot } from 'components/services/i18n';
import { tripTypesValues } from 'consts/search';
import useSearch from 'components/services/search/useSearch';
import { Wrapper, TypeOfFlightSelectWrapper, TypeOfFlightSelect } from './../index.styled';

const typesOfFlight = {
  DEPARTURE: 'departure',
  RETURN: 'return',
};

const { DEPARTURE, RETURN } = typesOfFlight;

const Times = () => {
  const [typeOfFlight, changeTypeOfFlight] = useState(DEPARTURE);
  const polyglot = usePolyglot();
  const {
    tabData,
    changeDepartureHours,
    changeArrivalHours,
    changeReturnDepartureHours,
    changeReturnArrivalHours,
  } = useSearch();

  const setTypeOfFlightToDeparture = () => {
    changeTypeOfFlight(DEPARTURE);
  };
  const setTypeOfFlightToReturn = () => {
    changeTypeOfFlight(RETURN);
  };

  return (
    <Wrapper width={300}>
      <TypeOfFlightSelectWrapper>
        <TypeOfFlightSelect
          selected={typeOfFlight === DEPARTURE}
          onClick={setTypeOfFlightToDeparture}
          data-test="times-departure"
        >
          {polyglot.t('searchAndBooking.times.departure')}
        </TypeOfFlightSelect>
        {tabData.tripType === tripTypesValues.RETURN && (
          <TypeOfFlightSelect
            selected={typeOfFlight === RETURN}
            data-test="times-return"
            onClick={setTypeOfFlightToReturn}
          >
            {polyglot.t('searchAndBooking.times.return')}
          </TypeOfFlightSelect>
        )}
      </TypeOfFlightSelectWrapper>
      {typeOfFlight === DEPARTURE && (
        <>
          <Slider
            onChange={changeDepartureHours}
            label={polyglot.t('searchAndBooking.times.DEPARTURE')}
            valueDescription={polyglot.t('searchAndBooking.times.description', {
              startTime: tabData.departureHours[0],
              endTime: tabData.departureHours[1],
            })}
            defaultValue={tabData.departureHours}
            minValue={0}
            maxValue={24}
            step={1}
            spaceAfter="medium"
            dataTest="departure-slider"
          />
          <Slider
            onChange={changeArrivalHours}
            label={polyglot.t('searchAndBooking.times.ARRIVAL')}
            valueDescription={polyglot.t('searchAndBooking.times.description', {
              startTime: tabData.arrivalHours[0],
              endTime: tabData.arrivalHours[1],
            })}
            defaultValue={tabData.arrivalHours}
            minValue={0}
            maxValue={24}
            step={1}
            spaceAfter="medium"
            dataTest="arrival-slider"
          />
        </>
      )}

      {typeOfFlight === RETURN && (
        <>
          <Slider
            onChange={changeReturnDepartureHours}
            label={polyglot.t('searchAndBooking.times.DEPARTURE')}
            valueDescription={polyglot.t('searchAndBooking.times.description', {
              startTime: tabData.returnDepartureHours[0],
              endTime: tabData.returnDepartureHours[1],
            })}
            defaultValue={tabData.returnDepartureHours}
            minValue={0}
            maxValue={24}
            step={1}
            dataTest="departure-slider"
          />
          <Slider
            onChange={changeReturnArrivalHours}
            label={polyglot.t('searchAndBooking.times.ARRIVAL')}
            valueDescription={polyglot.t('searchAndBooking.times.description', {
              startTime: tabData.returnArrivalHours[0],
              endTime: tabData.returnArrivalHours[1],
            })}
            defaultValue={tabData.returnArrivalHours}
            minValue={0}
            maxValue={24}
            step={1}
            dataTest="arrival-slider"
          />
        </>
      )}
    </Wrapper>
  );
};

export default Times;
