import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import { IconSuffix } from 'common';
import { propsComparison } from 'utils/propsComparison';
import {
  keys,
  transferAssistanceServiceTypeValues,
  transferAssistanceDeskTypeValues,
  transferAssistanceAirportStaffLookValues,
  transferAssistanceLocationValues,
  transferAssistanceFormKeys,
} from 'consts/smartPass';

import { LabelsRowWrapper, WidthWrapper } from './index.styled';
import ActivationButton from './ActivationButton';

const {
  TRANSFER_ASSISTANCE_ACTIVATED,
  TRANSFER_ASSISTANCE_SERVICE_TYPE,
  TRANSFER_ASSISTANCE_DESK_TYPE,
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK,
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR,
  TRANSFER_ASSISTANCE_LOCATION,
} = keys;

const TransferAssistance = ({ handleChange, values, setFieldValue, disabled, errors, touched }) => {
  const polyglot = usePolyglot();

  return (
    <Card
      spaceAfter="largest"
      header={
        <Stack direction="row" justify="between" align="center">
          <Heading as="h2" type="title3">
            {polyglot.t('smart_pass.form.transfer_assist.header_title')}
          </Heading>
          <ActivationButton
            formKeys={transferAssistanceFormKeys}
            isActivatedKey={TRANSFER_ASSISTANCE_ACTIVATED}
            name={polyglot.t('smart_pass.form.transfer_assist.header_title')}
            values={values}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />
        </Stack>
      }
    >
      {values[TRANSFER_ASSISTANCE_ACTIVATED] && (
        <CardSection>
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.transfer_assist.service_type')}
          </Text>
          <LabelsRowWrapper>
            <Radio
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.airport_desk')}
              name={TRANSFER_ASSISTANCE_SERVICE_TYPE}
              value={transferAssistanceServiceTypeValues.AIRPORT_DESK}
              hasError={
                touched[TRANSFER_ASSISTANCE_SERVICE_TYPE] &&
                errors[TRANSFER_ASSISTANCE_SERVICE_TYPE]
              }
              checked={
                values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
                transferAssistanceServiceTypeValues.AIRPORT_DESK
              }
              onChange={handleChange}
            />
            <Radio
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.airport_staff')}
              name={TRANSFER_ASSISTANCE_SERVICE_TYPE}
              value={transferAssistanceServiceTypeValues.AIRPORT_STAFF}
              checked={
                values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
                transferAssistanceServiceTypeValues.AIRPORT_STAFF
              }
              hasError={
                touched[TRANSFER_ASSISTANCE_SERVICE_TYPE] &&
                errors[TRANSFER_ASSISTANCE_SERVICE_TYPE]
              }
              onChange={handleChange}
            />
          </LabelsRowWrapper>
          {values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
            transferAssistanceServiceTypeValues.AIRPORT_DESK && (
            <>
              <Text spaceAfter="small">
                {polyglot.t('smart_pass.form.transfer_assist.transfer_assistance_desk')}
              </Text>
              <LabelsRowWrapper>
                <Checkbox
                  disabled={disabled}
                  label={polyglot.t(
                    'smart_pass.form.transfer_assist.transfer_assistance_desk_dedicated',
                  )}
                  name={TRANSFER_ASSISTANCE_DESK_TYPE}
                  hasError={
                    touched[TRANSFER_ASSISTANCE_DESK_TYPE] && errors[TRANSFER_ASSISTANCE_DESK_TYPE]
                  }
                  value={transferAssistanceDeskTypeValues.DEDICATED}
                  checked={values[TRANSFER_ASSISTANCE_DESK_TYPE].includes(
                    transferAssistanceDeskTypeValues.DEDICATED,
                  )}
                  onChange={handleChange}
                />
                <Checkbox
                  disabled={disabled}
                  label={polyglot.t(
                    'smart_pass.form.transfer_assist.transfer_assistance_desk_general',
                  )}
                  name={TRANSFER_ASSISTANCE_DESK_TYPE}
                  hasError={
                    touched[TRANSFER_ASSISTANCE_DESK_TYPE] && errors[TRANSFER_ASSISTANCE_DESK_TYPE]
                  }
                  value={transferAssistanceDeskTypeValues.GENERAL}
                  checked={values[TRANSFER_ASSISTANCE_DESK_TYPE].includes(
                    transferAssistanceDeskTypeValues.GENERAL,
                  )}
                  onChange={handleChange}
                />
              </LabelsRowWrapper>
            </>
          )}
          {values[TRANSFER_ASSISTANCE_SERVICE_TYPE] ===
            transferAssistanceServiceTypeValues.AIRPORT_STAFF && (
            <>
              <Text spaceAfter="small">
                {polyglot.t('smart_pass.form.transfer_assist.airport_staff_look')}
              </Text>
              <Stack spaceAfter="large">
                <LabelsRowWrapper>
                  <Radio
                    disabled={disabled}
                    label={polyglot.t(
                      'smart_pass.form.transfer_assist.airport_staff_look_dedicated',
                    )}
                    name={TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK}
                    hasError={
                      touched[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] &&
                      errors[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]
                    }
                    value={transferAssistanceAirportStaffLookValues.DEDICATED_UNIFORM}
                    checked={
                      values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] ===
                      transferAssistanceAirportStaffLookValues.DEDICATED_UNIFORM
                    }
                    onChange={handleChange}
                  />
                  <WidthWrapper>
                    <InputField
                      inlineLabel
                      label={polyglot.t(
                        'smart_pass.form.transfer_assist.airport_staff_look_dedicated_label',
                      )}
                      placeholder={polyglot.t(
                        'smart_pass.form.transfer_assist.airport_staff_look_dedicated_placeholder',
                      )}
                      suffix={
                        <Tooltip
                          content={polyglot.t(
                            'smart_pass.form.transfer_assist.airport_staff_look_dedicated_tooltip',
                          )}
                        >
                          <IconSuffix>
                            <InformationCircleIcon />
                          </IconSuffix>
                        </Tooltip>
                      }
                      name={TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR}
                      value={values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR]}
                      error={
                        touched[TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR] &&
                        errors[TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR] &&
                        polyglot.t(errors[TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR])
                      }
                      onChange={handleChange}
                      disabled={
                        disabled ||
                        values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] !==
                          transferAssistanceAirportStaffLookValues.DEDICATED_UNIFORM
                      }
                    />
                  </WidthWrapper>
                </LabelsRowWrapper>

                <Radio
                  label={polyglot.t(
                    'smart_pass.form.transfer_assist.airport_staff_look_badge_or_pin',
                  )}
                  disabled={disabled}
                  hasError={
                    touched[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] &&
                    errors[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]
                  }
                  name={TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK}
                  value={transferAssistanceAirportStaffLookValues.SERVICE_BADGE_OR_PIN}
                  checked={
                    values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] ===
                    transferAssistanceAirportStaffLookValues.SERVICE_BADGE_OR_PIN
                  }
                  onChange={handleChange}
                />
                <Radio
                  disabled={disabled}
                  label={polyglot.t(
                    'smart_pass.form.transfer_assist.airport_staff_look_no_specific',
                  )}
                  name={TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK}
                  hasError={
                    touched[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] &&
                    errors[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]
                  }
                  value={transferAssistanceAirportStaffLookValues.NO_SPECIFIC_LOOK}
                  checked={
                    values[TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK] ===
                    transferAssistanceAirportStaffLookValues.NO_SPECIFIC_LOOK
                  }
                  onChange={handleChange}
                />
              </Stack>
            </>
          )}
          <Separator spaceAfter="medium" />
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.transfer_assist.ta_location')}
          </Text>
          <LabelsRowWrapper>
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.baggage_reclaim_area')}
              name={TRANSFER_ASSISTANCE_LOCATION}
              hasError={
                touched[TRANSFER_ASSISTANCE_LOCATION] && errors[TRANSFER_ASSISTANCE_LOCATION]
              }
              value={transferAssistanceLocationValues.BAGGAGE_RECLAIM_AREA}
              checked={values[TRANSFER_ASSISTANCE_LOCATION].includes(
                transferAssistanceLocationValues.BAGGAGE_RECLAIM_AREA,
              )}
              onChange={handleChange}
            />
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.the_arrivals')}
              name={TRANSFER_ASSISTANCE_LOCATION}
              value={transferAssistanceLocationValues.ARRIVALS}
              hasError={
                touched[TRANSFER_ASSISTANCE_LOCATION] && errors[TRANSFER_ASSISTANCE_LOCATION]
              }
              checked={values[TRANSFER_ASSISTANCE_LOCATION].includes(
                transferAssistanceLocationValues.ARRIVALS,
              )}
              onChange={handleChange}
            />
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.the_departures')}
              name={TRANSFER_ASSISTANCE_LOCATION}
              hasError={
                touched[TRANSFER_ASSISTANCE_LOCATION] && errors[TRANSFER_ASSISTANCE_LOCATION]
              }
              value={transferAssistanceLocationValues.DEPARTURES}
              checked={values[TRANSFER_ASSISTANCE_LOCATION].includes(
                transferAssistanceLocationValues.DEPARTURES,
              )}
              onChange={handleChange}
            />
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.transfer_assist.the_transit_area')}
              name={TRANSFER_ASSISTANCE_LOCATION}
              hasError={
                touched[TRANSFER_ASSISTANCE_LOCATION] && errors[TRANSFER_ASSISTANCE_LOCATION]
              }
              value={transferAssistanceLocationValues.TRANSIT_AREA}
              checked={values[TRANSFER_ASSISTANCE_LOCATION].includes(
                transferAssistanceLocationValues.TRANSIT_AREA,
              )}
              onChange={handleChange}
            />
          </LabelsRowWrapper>
        </CardSection>
      )}
    </Card>
  );
};

export default React.memo(TransferAssistance, propsComparison(transferAssistanceFormKeys));
