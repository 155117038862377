import {
  BACKEND_ENDPOINT,
  BACKEND_PROXY_ENDPOINT,
  USE_NEXT_GEN_ENDPOINTS,
  BACKEND_TOPPER_ENDPOINT,
} from 'consts/features';

export const getFeatureValue = key => {
  try {
    return JSON.parse(localStorage.getItem(key)).value;
  } catch (error) {
    return null;
  }
};

export const setFeatureValue = (feature, value) => {
  localStorage.setItem(feature, JSON.stringify({ value }));
};

export const shouldUseNewEndpoints = () => getFeatureValue(USE_NEXT_GEN_ENDPOINTS);

export const getBackendUrl = () =>
  getFeatureValue(BACKEND_ENDPOINT) || process.env.REACT_APP_BACKEND_URL;

export const getBackendProxyUrl = () =>
  getFeatureValue(BACKEND_PROXY_ENDPOINT) || process.env.REACT_APP_PROXY_URL;

export const getBackendTopperUrl = () =>
  getFeatureValue(BACKEND_TOPPER_ENDPOINT) || process.env.REACT_APP_TOPPER_URL;
