import React, { useState } from 'react';

import StepperStateless from '@kiwicom/orbit-components/lib/Stepper/StepperStateless';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import useSearch from 'components/services/search/useSearch';
import { usePolyglot } from 'components/services/i18n';
import {
  MIN_CUSTOM_STOPOVERS,
  MAX_CUSTOM_STOPOVERS,
  stopoversRadioName,
  stopoversRadioValues,
} from 'consts/search';

import { Wrapper } from './../index.styled';

const Stopovers = () => {
  const { tabData, changeNumberOfStopovers } = useSearch();
  const polyglot = usePolyglot();

  const numberOfStopovers = tabData.numberOfStopovers;
  const { ANY, DIRECT } = stopoversRadioValues;

  const [customNumberOfStopovers, changeCustomNumberOfStopovers] = useState(
    numberOfStopovers || MIN_CUSTOM_STOPOVERS,
  );

  const changeRadioValue = value => e => {
    changeNumberOfStopovers(value);
  };

  const incrementNumberOfStopovers = () => {
    if (numberOfStopovers !== MAX_CUSTOM_STOPOVERS && numberOfStopovers) {
      changeNumberOfStopovers(numberOfStopovers + 1);
      changeCustomNumberOfStopovers(numberOfStopovers + 1);
    }
  };

  const decrementNumberOfStopovers = () => {
    if (numberOfStopovers !== MIN_CUSTOM_STOPOVERS && numberOfStopovers) {
      changeNumberOfStopovers(numberOfStopovers - 1);
      changeCustomNumberOfStopovers(numberOfStopovers - 1);
    }
  };

  return (
    <Wrapper>
      <Radio
        label={polyglot.t('searchAndBooking.stopovers.any')}
        value={ANY}
        name={stopoversRadioName}
        checked={numberOfStopovers === ANY}
        onChange={changeRadioValue(ANY)}
        dataTest="stopover-any"
      />
      <Separator spaceAfter="medium" />
      <Radio
        label={polyglot.t('searchAndBooking.stopovers.direct')}
        value={DIRECT}
        name={stopoversRadioName}
        checked={numberOfStopovers === DIRECT}
        onChange={changeRadioValue(DIRECT)}
        dataTest="stopover-non-stop"
      />
      <Separator spaceAfter="medium" />
      <Radio
        label={polyglot.t('searchAndBooking.stopovers.custom')}
        value={customNumberOfStopovers}
        name={stopoversRadioName}
        checked={numberOfStopovers === customNumberOfStopovers}
        onChange={changeRadioValue(customNumberOfStopovers)}
        dataTest="stopover-up-to"
      />
      <Separator spaceAfter="medium" />
      <StepperStateless
        onIncrement={incrementNumberOfStopovers}
        onDecrement={decrementNumberOfStopovers}
        disabledIncrement={customNumberOfStopovers === MAX_CUSTOM_STOPOVERS}
        disabledDecrement={customNumberOfStopovers === MIN_CUSTOM_STOPOVERS}
        value={customNumberOfStopovers}
        disabled={!numberOfStopovers}
        dataTest="stopover-increase-decrease"
        titleIncrement="increase stopover"
        titleDecrement="decrease stopover"
      />
    </Wrapper>
  );
};

export default Stopovers;
