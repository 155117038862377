import React, { useReducer } from 'react';

import CommissionMapper from 'mappers/nextGen/applications/{application_uuid}/commissions/_';
import api from 'utils/api';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import { countriesWithDefault as countries } from 'consts/countries';
import { OFFER_FIELDS } from 'consts/offers';

export const CommissionsState = React.createContext({
  state: {},
  loadAffilIdCommissions: async (affilId, uuid) => [affilId, uuid],
  createCommission: async (commercial, affilId, uuid) => [commercial, affilId, uuid],
  editCommission: async (commercial, affilId, uuid) => [commercial, affilId, uuid],
  enableCommission: async (commercial, affilId, uuid) => [commercial, affilId, uuid],
  disableCommission: async (commercial, affilId, uuid) => [commercial, affilId, uuid],
});

const commissionsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_AFFILID_COMMISSIONS':
      return {
        ...state,
        [action.affilId]: {
          ...state[action.affilId],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_AFFILID_COMMISSIONS_SUCCESS':
      return {
        ...state,
        [action.affilId]: {
          ...state[action.affilId],
          loading: false,
          data: action.commercials,
          params: action.params,
        },
      };
    case 'LOAD_AFFILID_COMMISSIONS_FAIL':
      return {
        ...state,
        [action.affilId]: {
          ...state[action.affilId],
          loading: false,
          error: action.error,
        },
      };
    case 'CREATE_COMMISSION':
      return {
        ...state,
        creating: true,
        createError: null,
      };
    case 'CREATE_COMMISSION_SUCCESS':
      return {
        ...state,
        creating: false,
      };
    case 'CREATE_COMMISSION_FAIL':
      return {
        ...state,
        creating: false,
        createError: action.error,
      };
    case 'EDIT_COMMISSION':
      return {
        ...state,
        editing: true,
        editError: null,
      };
    case 'EDIT_COMMISSION_SUCCESS':
      return {
        ...state,
        editing: false,
      };
    case 'EDIT_COMMISSION_FAIL':
      return {
        ...state,
        editing: false,
        editError: action.error,
      };
    case 'ENABLE_COMMISSION':
      return {
        ...state,
        enabling: true,
        enableError: null,
      };
    case 'ENABLE_COMMISSION_SUCCESS':
      return {
        ...state,
        enabling: false,
      };
    case 'ENABLE_COMMISSION_FAIL':
      return {
        ...state,
        enabling: false,
        enableError: action.error,
      };
    case 'DISABLE_COMMISSION':
      return {
        ...state,
        disabling: true,
        disableError: null,
      };
    case 'DISABLE_COMMISSION_SUCCESS':
      return {
        ...state,
        disabling: false,
      };
    case 'DISABLE_COMMISSION_FAIL':
      return {
        ...state,
        disabling: false,
        disableError: action.error,
      };
    default:
      return state;
  }
};

const sortKeys = {
  validFrom: 'valid_from',
  validTo: 'valid_to',
  name: 'market',
};

const defaultPageSizes = [5, 10, 20, 100];

const mapToApiPagination = pagination => {
  return {
    _per_page: pagination.perPage,
    page: pagination.page,
  };
};

const mapToApiSort = sort => {
  return {
    _order: `${sort.dir === 'desc' ? '' : '-'}${sortKeys[sort.key]}`,
  };
};

const defaultPagination = {
  perPage: defaultPageSizes[0],
  page: 1,
};

const defaultSort = {
  dir: 'asc',
  key: 'validFrom',
};

const mapToApiParams = ({ pagination, sort }) => {
  return {
    ...mapToApiPagination(pagination),
    ...mapToApiSort(sort),
  };
};

const CommercialsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(commissionsReducer, {});
  const user = useCurrentUser();

  const loadAffilIdCommissions = async (affilId, uuid, params) => {
    dispatch({ type: 'LOAD_AFFILID_COMMISSIONS', affilId });

    const newParams = {
      pagination: params?.pagination || defaultPagination,
      sort: params?.sort || defaultSort,
    };

    const apiParams = mapToApiParams(newParams);

    try {
      const isAdminOrACM = user?.role === 'ACL-admin' || user?.role === 'ACL-account-manager';
      const res = await api.v1.get(`applications/${uuid}/commissions`, {
        params: {
          ...apiParams,
          ...(isAdminOrACM ? { status: 'all' } : {}),
        },
      });
      const commissions = res.data.data.map(CommissionMapper.v1.get.from);

      const transformedCommercials = commissions.map(commission => {
        const country = countries.find(c => c.value === commission.market);

        const usedField = OFFER_FIELDS.find(
          field =>
            commission[field] !== null &&
            commission[field] !== undefined &&
            commission[field] !== 0,
        );
        return {
          ...commission,
          name: country ? country.label : '',
          offers: [
            {
              type: 'cpcFlat',
              value: commission['cpcFlat'],
            },
            {
              type: 'cpsFlat',
              value: commission['cpsFlat'],
            },
            {
              type: 'cpsPercent',
              value: commission['cpsPercent'],
            },
          ],
          offer: {
            type: usedField,
            value: commission[usedField],
          },
        };
      });

      const mappedParams = {
        ...newParams,
        pagination: {
          ...newParams.pagination,
          totalCount: res.data.pagination.total,
          pageSizes: defaultPageSizes,
        },
      };

      dispatch({
        type: 'LOAD_AFFILID_COMMISSIONS_SUCCESS',
        commercials: transformedCommercials,
        params: mappedParams,
        affilId,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_AFFILID_COMMISSIONS_FAIL', affilId, error });

      return Promise.reject(error);
    }
  };

  const createCommission = async (commercial, affilId, uuid) => {
    dispatch({ type: 'CREATE_COMMISSION' });

    try {
      await api.v1.post(
        `applications/${uuid}/commissions`,
        CommissionMapper.v1.post.to({
          ...commercial,
          [commercial.offerType]: commercial.offerValue,
        }),
      );

      await loadAffilIdCommissions(affilId, uuid);

      dispatch({ type: 'CREATE_COMMISSION_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'CREATE_COMMISSION_FAIL' });

      return Promise.reject(error);
    }
  };

  const editCommission = async (commercial, affilId, uuid) => {
    dispatch({ type: 'EDIT_COMMISSION' });

    try {
      await api.v1.put(
        `commissions/${commercial.id}`,
        CommissionMapper.v1.put.to.update(commercial),
      );

      await loadAffilIdCommissions(affilId, uuid);

      dispatch({ type: 'EDIT_COMMISSION_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'EDIT_COMMISSION_FAIL' });

      return Promise.reject(error);
    }
  };

  const enableCommission = async (commercial, affilId, uuid) => {
    dispatch({ type: 'ENABLE_COMMISSION' });

    try {
      await api.v1.put(
        `commissions/${commercial.id}`,
        CommissionMapper.v1.put.to.enable(commercial),
      );

      await loadAffilIdCommissions(affilId, uuid);

      dispatch({ type: 'ENABLE_COMMISSION_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'ENABLE_COMMISSION_FAIL' });

      return Promise.reject(error);
    }
  };

  const disableCommission = async (commercial, affilId, uuid) => {
    dispatch({ type: 'DISABLE_COMMISSION' });

    try {
      await api.v1.put(
        `commissions/${commercial.id}`,
        CommissionMapper.v1.put.to.disable(commercial),
      );

      await loadAffilIdCommissions(affilId, uuid);

      dispatch({ type: 'DISABLE_COMMISSION_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'DISABLE_COMMISSION_FAIL' });

      return Promise.reject(error);
    }
  };

  const value = {
    state,
    loadAffilIdCommissions,
    createCommission,
    editCommission,
    enableCommission,
    disableCommission,
  };

  return <CommissionsState.Provider value={value}>{children}</CommissionsState.Provider>;
};

export default CommercialsProvider;
