import React from 'react';
import PropTypes from 'prop-types';
import Polyglot from 'shapes/Polyglot';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import AlertButton from '@kiwicom/orbit-components/lib/Alert/AlertButton';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import { ROUTE_CONFIG } from 'consts/routes';
import { resourcesCodenames } from 'consts/kentico';

const CSSDIntroAlert = ({ polyglot, handleCSSDIntroAlertClose }) => (
  <Alert title={polyglot.t('common.customer_support')} type="info" spaceAfter="large" icon>
    <div>
      <Text spaceAfter="medium">{polyglot.t('support.cssd.intro_message_1')}</Text>
      <Text spaceAfter="medium">
        {polyglot.t('support.cssd.intro_message_2')}
        <TextLink
          external
          href={`${ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE.toPath}/${resourcesCodenames.CUSTOMER_SUPPORT_GUIDE}`}
        >
          {polyglot.t('support.cssd.intro_message_text_link_1')}
        </TextLink>
        {polyglot.t('support.cssd.intro_message_3')}
      </Text>
      <Text spaceAfter="large">
        {polyglot.t('support.cssd.intro_message_4')}
        <TextLink
          external
          href={`${ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE.toPath}/${resourcesCodenames.CUSTOMER_SUPPORT}`}
        >
          {polyglot.t('support.cssd.intro_message_text_link_2')}
        </TextLink>
        {polyglot.t('support.cssd.intro_message_5')}
      </Text>
      <AlertButton onClick={handleCSSDIntroAlertClose}>
        {polyglot.t('common.ok_got_it')}
      </AlertButton>
    </div>
  </Alert>
);

CSSDIntroAlert.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleCSSDIntroAlertClose: PropTypes.func,
};

export default CSSDIntroAlert;
