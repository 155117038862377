import { useDispatch } from 'react-redux';
import { addMessage } from 'redux/modules/messages';
import { MESSAGE_TYPES } from 'consts/messages';

const useToasts = () => {
  const dispatch = useDispatch();

  const addSuccessToast = message => {
    dispatch(addMessage(MESSAGE_TYPES.SUCCESS)(message));
  };

  const addErrorToast = message => {
    dispatch(addMessage(MESSAGE_TYPES.ERROR)(message));
  };

  return {
    addSuccessToast,
    addErrorToast,
  };
};

export default useToasts;
