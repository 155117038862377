import React from 'react';

import Popover from '@kiwicom/orbit-components/lib/Popover';
import MenuKebabIcon from '@kiwicom/orbit-components/lib/icons/MenuKebab';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';

import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';

import { KebabMenuWrapper, IconWrapper } from './index.styled';

const KebabMenu = ({ onOpenDrawer, routeId, areActionButtonsDisabled }) => {
  const polyglot = usePolyglot();
  const { removeSeating } = useSeating();

  return (
    <Popover
      placement="bottom-end"
      noPadding
      content={
        <>
          <ListChoice
            title={polyglot.t('booking_tool.seating_card.edit.seating')}
            icon={
              <IconWrapper>
                <EditIcon size="small" />
              </IconWrapper>
            }
            disabled={areActionButtonsDisabled}
            onClick={() => onOpenDrawer(routeId)}
          />
          <ListChoice
            title={polyglot.t('booking_tool.seating_card.remove.seating')}
            icon={
              <IconWrapper>
                <RemoveIcon size="small" />
              </IconWrapper>
            }
            onClick={() => removeSeating(routeId)}
          />
        </>
      }
    >
      <KebabMenuWrapper>
        <MenuKebabIcon size="small" />
      </KebabMenuWrapper>
    </Popover>
  );
};

export default KebabMenu;
