import React, { useReducer } from 'react';

import api from 'utils/api';
import singleCompanyMapper from 'mappers/nextGen/companies/{company_name}/_';

export const CompanyState = React.createContext({
  detailsState: {},
  loadCompany: companyName => companyName,
  updateCompanyDetails: (companyName, data) => [companyName, data],
  deleteCompany: companyName => companyName,
});

const detailsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY':
      return {
        ...state,
        [action.companyName]: {
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          loading: false,
          error: null,
          data: action.companyDetails,
        },
      };
    case 'LOAD_COMPANY_FAIL':
      return {
        ...state,
        [action.companyName]: {
          loading: false,
          error: action.error,
        },
      };
    case 'UPDATE_COMPANY':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          updating: true,
          updateError: null,
        },
      };
    case 'UPDATE_COMPANY_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          updating: false,
          data: action.companyDetails,
        },
      };
    case 'UPDATE_COMPANY_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          updating: false,
          updateError: action.error,
        },
      };
    case 'DELETE_COMPANY':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          deleteLoading: true,
          deleteError: null,
        },
      };
    case 'DELETE_COMPANY_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          deleteLoading: false,
          deleteError: null,
        },
      };
    case 'DELETE_COMPANY_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          deleteLoading: false,
          deleteError: action.error,
        },
      };
    default:
      return state;
  }
};

const CompanyProvider = ({ children }) => {
  const [detailsState, detailsDispatch] = useReducer(detailsReducer, {});

  const loadCompany = async companyName => {
    if (detailsState[companyName]) {
      return detailsState[companyName];
    }

    detailsDispatch({ type: 'LOAD_COMPANY', companyName });

    try {
      const res = await api.v1.get(`companies/${companyName}`);
      const company = singleCompanyMapper.v1.get.from(res.data);

      return detailsDispatch({
        type: 'LOAD_COMPANY_SUCCESS',
        companyName,
        companyDetails: company,
      });
    } catch (error) {
      detailsDispatch({ type: 'LOAD_COMPANY_FAIL', companyName, error });
    }
  };

  const updateCompanyDetails = async (companyName, companyDetails) => {
    detailsDispatch({ type: 'UPDATE_COMPANY', companyName });
    try {
      const res = await api.v1.put(
        `companies/${companyName}`,
        singleCompanyMapper.v1.put.to(companyDetails),
      );

      const company = singleCompanyMapper.v1.put.from(res.data);

      return detailsDispatch({
        type: 'UPDATE_COMPANY_SUCCESS',
        companyName,
        companyDetails: company,
      });
    } catch (error) {
      detailsDispatch({ type: 'UPDATE_COMPANY_FAIL', companyName, error });
    }
  };

  const deleteCompany = async companyName => {
    detailsDispatch({ type: 'DELETE_COMPANY', companyName });
    try {
      await api.v1.delete(`companies/${companyName}`);

      return detailsDispatch({
        type: 'DELETE_COMPANY_SUCCESS',
        companyName,
      });
    } catch (error) {
      detailsDispatch({ type: 'DELETE_COMPANY_FAIL', companyName, error });
    }
  };

  const value = {
    detailsState,
    loadCompany,
    updateCompanyDetails,
    deleteCompany,
  };

  return <CompanyState.Provider value={value}>{children}</CompanyState.Provider>;
};

export default CompanyProvider;
