import React from 'react';
import * as R from 'ramda';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { ROUTE_CONFIG } from 'consts/routes';
import { resourcesCodenames } from 'consts/kentico';
import Card from 'components/scenes/Booking/common/Card';
import { useCurrentUser } from 'components/services/auth';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import useSaveBooking from 'components/services/booking/useSaveBooking';
import usePolyglot from 'components/services/i18n/usePolyglot';
import { useToggle } from 'utils/hooks';
import useBooking from 'components/services/booking/useBooking';
import * as Intercom from 'utils/intercom';
import useSeating from 'components/services/bookingToolSeating/useSeating';
import { getSeatingTotalSum } from 'utils/seating';

const BorderedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 50%;
  margin-bottom: 20px;
  border: ${({ theme }) => `1px solid ${theme.orbit.borderColorCard}`};

  span {
    margin-left: 10px;
  }
  ${({ red, theme }) =>
    red &&
    css`
      border-left: none;
      & > h2 {
        color: ${theme.orbit.colorTextCritical};
      }
    `}

  ${({ primary, theme }) =>
    primary &&
    css`
      width: auto;
      & > h2 {
        color: ${theme.orbit.paletteProductNormal};
      }
    `}
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const CreditBalance = ({ balance, price, isInsufficient }) => {
  const polyglot = usePolyglot();

  if (isInsufficient) {
    return (
      <FlexWrapper direction="row" spacing="none">
        <BorderedWrapper>
          <FlexWrapper>
            <Text size="small" spaceAfter="small">
              {polyglot.t('booking_tool.payment.credit_balance')}
            </Text>
            <Tooltip content={polyglot.t('booking_tool.payment.credit_balance.tooltip')}>
              <InformationCircleIcon size="small" />
            </Tooltip>
          </FlexWrapper>
          <Heading as="h2">{balance.toFixed(2)}</Heading>
        </BorderedWrapper>
        <BorderedWrapper red>
          <Text size="small" spaceAfter="small" type="critical">
            {polyglot.t('booking_tool.payment.insufficient_funds')}
          </Text>
          <Heading as="h2">{(balance - price)?.toFixed(2)}</Heading>
        </BorderedWrapper>
      </FlexWrapper>
    );
  }
  return (
    <BorderedWrapper primary>
      <FlexWrapper>
        <Text size="small" spaceAfter="small">
          {polyglot.t('booking_tool.payment.credit_balance')}
        </Text>
        <Tooltip content={polyglot.t('booking_tool.payment.credit_balance.tooltip')}>
          <InformationCircleIcon size="small" />
        </Tooltip>
      </FlexWrapper>
      <Heading as="h2">{balance.toFixed(2)}</Heading>
    </BorderedWrapper>
  );
};

const { BOOK_WITH_KIWI_CODENAME, PRIVACY_POLICY_CODENAME } = resourcesCodenames;

const { toPath } = ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE;

const SaveBookingError = ({ error, priceChange }) => {
  const polyglot = usePolyglot();
  if (priceChange) {
    return (
      <Alert
        type="critical"
        spaceAfter="large"
        title={polyglot.t('booking_tool.generic_error_title')}
      >
        {polyglot.t('booking_tool.price_change.error')}
      </Alert>
    );
  }
  if (error) {
    return (
      <Alert
        type="critical"
        spaceAfter="large"
        title={polyglot.t('booking_tool.generic_error_title')}
      >
        {polyglot.t('booking_tool.generic_error')}
      </Alert>
    );
  }
  return null;
};

const DepositPayment = ({
  loading,
  price,
  apiKey,
  bookingToken,
  passengers,
  baggage,
  affilId,
  paidGuarantee,
}) => {
  const history = useHistory();
  const user = useCurrentUser();
  const { data: deposit, loading: loadingDeposit } = useCompanyDeposit(user.companyName);
  const {
    saveBooking,
    saveBookingLoading,
    confirmPaymentLoading,
    saveBookingError,
    confirmPaymentError,
    priceChangeInterrupt,
  } = useSaveBooking();
  const polyglot = usePolyglot();
  const agreedToTerms = useToggle();
  const balance = deposit?.balance;
  const { getValues } = useFormContext();
  const { data, getBaggagePrice } = useBooking();
  const { trigger, errors } = useFormContext();
  const { seatings } = useSeating();

  const handBagPrice = getBaggagePrice(passengers, baggage, 'handBag');
  const holdBagPrice = getBaggagePrice(passengers, baggage, 'holdBag');
  const paidGuaranteePrice = paidGuarantee ? Number(paidGuarantee.price.amount) : 0;
  const totalPrice =
    price +
    holdBagPrice.price +
    handBagPrice.price +
    paidGuaranteePrice +
    getSeatingTotalSum(seatings);
  const isInsufficient = balance < totalPrice;
  const allSeatings = !!Object.keys(seatings).length && seatings;

  const handleSaveBooking = async (paidPrice, affiliateId) => {
    try {
      await trigger();

      if (!R.isEmpty(errors)) {
        return;
      }

      const res = await saveBooking(
        {
          ...getValues(),
          apiKey,
          price: totalPrice,
          bookingToken,
          sessionId: data.sessionId,
          depositEmail: deposit.depositAcc,
        },
        allSeatings,
        data?.baggage?.combinations,
        paidGuarantee,
      );

      Intercom.trackEvent('finished-booking-process', {
        paidPrice,
        bookingToken,
        affiliateId,
        bookedAt: 'tequila_book',
      });

      history.push({
        pathname: ROUTE_CONFIG.KIWI_BOOKING_SUCCESS.path,
        state: {
          fromBooking: true,
          bookingId: res.booking_id,
        },
      });
    } catch (e) {}
  };

  return (
    <Card
      title={polyglot.t('booking_tool.payment.title')}
      loading={loading || loadingDeposit || typeof balance !== 'number'}
    >
      <SaveBookingError
        error={saveBookingError || confirmPaymentError}
        priceChange={priceChangeInterrupt}
      />
      <CreditBalance balance={balance} price={totalPrice} isInsufficient={isInsufficient} />
      <Separator spaceAfter="medium" />
      {isInsufficient ? (
        <Alert
          type="warning"
          title={polyglot.t('booking_tool.payment.insufficient_funds.alert.title')}
          spaceAfter="largest"
        >
          <Stack direction="column" spacing="medium">
            <Text>{polyglot.t('booking_tool.payment.insufficient_funds.alert.description')}</Text>
            <AlertButton
              external={true}
              href="https://tequila.kiwi.com/portal/docs/faq/topping_up_deposit"
              type="warning"
              size="small"
            >
              {polyglot.t('booking_tool.payment.topping_up')}
            </AlertButton>
          </Stack>
        </Alert>
      ) : (
        <BorderedWrapper primary>
          <Checkbox
            dataTest="terms-of-service"
            checked={agreedToTerms.isOn}
            onChange={agreedToTerms.toggle}
            label={
              <Text spaceAfter="small">
                {polyglot.t('booking_tool.payment.tos_pre')}
                <TextLink
                  type="secondary"
                  external
                  stopPropagation
                  href={`${toPath}/${BOOK_WITH_KIWI_CODENAME}`}
                >
                  {polyglot.t('booking_tool.payment.tos')}
                </TextLink>{' '}
                {polyglot.t('booking_tool.payment.tos_post')}
                <TextLink
                  type="secondary"
                  external
                  stopPropagation
                  href={`${toPath}/${PRIVACY_POLICY_CODENAME}`}
                >
                  {polyglot.t('booking_tool.payment.pp')}
                </TextLink>{' '}
                {polyglot.t('booking_tool.payment.pp_post')}
              </Text>
            }
            info={
              <InfoWrapper>
                <Text type="secondary" size="small" spaceAfter="large">
                  {polyglot.t('booking_tool.payment.info_pre')}
                </Text>
              </InfoWrapper>
            }
          />
        </BorderedWrapper>
      )}
      <Separator spaceAfter="medium" />
      <Footer>
        <Button
          type="primary"
          size="normal"
          dataTest="total-price-button"
          disabled={isInsufficient || !agreedToTerms.isOn}
          loading={saveBookingLoading || confirmPaymentLoading || loading}
          onClick={() => handleSaveBooking(totalPrice, affilId)}
        >
          {polyglot.t('booking_tool.payment.action', { price: totalPrice.toFixed(2) })}
        </Button>
      </Footer>
    </Card>
  );
};

export default React.memo(DepositPayment);
