import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Heading from '@kiwicom/orbit-components/lib/Heading';

import { TruncateComponent, Space } from 'common';
import { ReportData } from 'pages/Reports/components';
import * as reportsActions from 'redux/modules/reports';
import { FIRST_PAGE } from 'consts/pagination';
import { getPolyglot } from 'redux/selectors/i18n';
import Polyglot from 'shapes/Polyglot';

import ReportAlias from 'shapes/ReportAlias';

/**
 * Component responsible for rendering the correct version of report data.
 * If the given report params result with an instant report, we draw the table
 * in preview mode.
 * If the report is a full report, then an info message is displayed while this component polls
 * for the report data. Once the data is in, the table is displayed in preview mode.
 */
class PreviewWrapper extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadReport } = this.props;
    const nextReportAlias = nextProps.reportAlias;

    if (nextReportAlias.id) {
      loadReport(nextReportAlias.id, FIRST_PAGE);
    }
  }

  render() {
    const { reportAlias, polyglot } = this.props;

    if (!reportAlias) {
      return null;
    }

    const title = reportAlias.alias ? (
      <span>{polyglot.t('reports.preview_title', { alias: reportAlias.alias })}</span>
    ) : (
      <span>{polyglot.t('common.preview')}</span>
    );

    return (
      <div>
        <Space before="l">
          <Heading type="title2" element="h2">
            {title}
          </Heading>
        </Space>
        <TruncateComponent minHeight="33vh" transitionDuration="0.25s">
          <ReportData />
          <Space before="xl">
            <br />
          </Space>
        </TruncateComponent>
      </div>
    );
  }
}

PreviewWrapper.propTypes = {
  reportAlias: PropTypes.shape(ReportAlias),
  loadReport: PropTypes.func,
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

const mapDispatchToProps = {
  loadReport: reportsActions.loadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewWrapper);
