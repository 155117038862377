import React from 'react';
import { Badge } from '@kiwicom/orbit-components/lib';

import { usePolyglot } from 'components/services/i18n';

const StatusBadge = ({ status }) => {
  const polyglot = usePolyglot();
  switch (status) {
    case 'confirmed':
      return <Badge type="success">{polyglot.t('mmb.status.confirmed')}</Badge>;
    case 'refunded':
      return <Badge type="success">{polyglot.t('mmb.status.refunded')}</Badge>;
    case 'traveled':
      return <Badge type="neutral">{polyglot.t('mmb.status.traveled')}</Badge>;
    case 'refunding':
      return <Badge type="info">{polyglot.t('mmb.status.refunding')}</Badge>;
    case 'processing':
      return <Badge type="info">{polyglot.t('mmb.status.processing')}</Badge>;
    case 'cancelled':
      return <Badge type="warning">{polyglot.t('mmb.status.cancelled')}</Badge>;
    case 'failed':
      return <Badge type="critical">{polyglot.t('mmb.status.failed')}</Badge>;
    case 'locked':
      return <Badge type="critical">{polyglot.t('mmb.status.locked')}</Badge>;
    default:
      return <Badge>{status}</Badge>;
  }
};

export default StatusBadge;
