import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TableCell } from 'common';

import { StyledRow, ExpandArrow } from './TableRow.styled';

class TableRow extends Component {
  state = {
    expanded: false,
  };

  toggleRow = () => {
    this.setState(state => ({
      expanded: !state.expanded,
    }));
  };

  render() {
    const { children, expandable, separator, head, ...props } = this.props;

    return (
      <StyledRow
        expandable={expandable}
        expanded={this.state.expanded}
        separator={separator}
        head={head}
        {...props}
      >
        {expandable && (
          <TableCell>
            <ExpandArrow onClick={this.toggleRow} expanded={this.state.expanded} />
          </TableCell>
        )}
        {children}
      </StyledRow>
    );
  }
}

TableRow.propTypes = {
  expandable: PropTypes.bool,
  separator: PropTypes.bool,
  head: PropTypes.bool,
};

export default TableRow;
