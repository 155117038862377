import PropTypes from 'prop-types';

const ReportFilterRow = {
  filter: PropTypes.shape({
    k: PropTypes.string,
    value: PropTypes.string,
  }),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
};
export default ReportFilterRow;
