import React, { useState } from 'react';
import styled from 'styled-components';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { validateIp, validateIPCIDR } from 'utils/ip';
import { usePolyglot } from 'components/services/i18n';

const TooltipContentWrapper = styled.div`
  font-size: 12px;
`;

const InformationCircleWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IPForm = ({ value, onChange, onAdd, getIsDuplicated }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const polyglot = usePolyglot();

  const handleAdd = () => {
    const validCidr = validateIPCIDR(value);
    const validIp = validateIp(value?.split('/')[0]);

    if (getIsDuplicated(value)) {
      setErrorMessage('Duplicate entry');
    } else if (!validCidr || !validIp) {
      setErrorMessage('Invalid address format');
    } else {
      onAdd();
      setErrorMessage(null);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleAdd();
    }
  };

  const handleChange = e => {
    onChange(e.target.value);
    setErrorMessage(null);
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <Stack direction="row" align="end">
        <InputField
          label="IP address"
          help="Press Enter to check address format"
          value={value}
          onChange={handleChange}
          error={errorMessage}
          suffix={
            <InformationCircleWrapper>
              <Tooltip
                preferredPosition="top"
                content={
                  <TooltipContentWrapper>
                    {polyglot.t('allowlist.add_address_tooltip_1')}
                    <br />
                    {polyglot.t('allowlist.add_address_tooltip_2')}
                    <br />
                    {polyglot.t('allowlist.add_address_tooltip_3')}
                    <br />
                    <br />
                    {polyglot.t('allowlist.add_address_tooltip_4')}
                    <br />
                    {polyglot.t('allowlist.add_address_tooltip_5')}
                  </TooltipContentWrapper>
                }
              >
                <InformationCircleIcon color="secondary" />
              </Tooltip>
            </InformationCircleWrapper>
          }
        />

        <Button type="primarySubtle" iconLeft={<PlusIcon />} onClick={handleAdd}>
          {polyglot.t('allowlist.add_address')}
        </Button>
      </Stack>
    </div>
  );
};

export default IPForm;
