import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';

import { usePolyglot } from 'components/services/i18n';
import useUiConfig from 'components/services/initialQueryParams/useUiConfig';
import * as Intercom from 'utils/intercom';
import { fireEvent } from 'utils/ga';
import { TequilaLogotype } from 'common';

import {
  DisableGlobalStyles,
  DisableContainer,
  Content,
  NavigationWrapper,
} from './DesktopContainer.styled';

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const DesktopContainer = ({ children }) => {
  const { showNonDesktop } = useUiConfig();
  const polyglot = usePolyglot();

  useEffect(() => {
    if (showNonDesktop) {
      window.scrollTo(0, 0);
    }
  }, [showNonDesktop]);

  useEffect(() => {
    try {
      fireEvent('portalVisible');
    } catch (error) {}

    if (showNonDesktop) {
      Intercom.trackEvent('mobile-usage-attempt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showNonDesktop && (
        <Portal>
          <DisableContainer>
            <DisableGlobalStyles />
            <NavigationWrapper>
              <TequilaLogotype />
            </NavigationWrapper>
            <Content>
              <Illustration name="MobileApp" size="medium" spaceAfter="medium" />

              <Text type="primary" size="large" align="center">
                {polyglot.t('disable_nondesktop.not_ready')}
              </Text>
            </Content>
          </DisableContainer>
        </Portal>
      )}
      {children}
    </>
  );
};

export default DesktopContainer;
