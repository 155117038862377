import { useContext, useEffect } from 'react';

import { BookingToolState } from './';

const useCarriers = () => {
  const { carriers, loadCarriers } = useContext(BookingToolState);

  useEffect(() => {
    if (!carriers.data) {
      loadCarriers();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: carriers.loading,
    error: carriers.error,
    data: carriers.data || {},
  };
};

export default useCarriers;
