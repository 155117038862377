import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import Visibility from '@kiwicom/orbit-components/lib/icons/Visibility';
import { Link as LinkIcon } from '@kiwicom/orbit-components/lib/icons';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import useWidgetsContext from 'components/services/widgets';

import CopyCodeToClipboardButton from './CopyCodeToClipboardButton';
import { FlexContainer } from './Icons.styled.js';

const Icons = ({
  listedCompanyWidget,
  showWidgetPreview,
  setDeleteModal,
  selectedCompany,
  canUpdate,
  canDelete,
}) => {
  const polyglot = usePolyglot();

  const { goToEdit } = useWidgetsContext();

  return (
    <FlexContainer main="space-between">
      <Tooltip preferredPosition="bottom" content={polyglot.t('widgets.list.icons.copy_code')}>
        <CopyCodeToClipboardButton
          config={listedCompanyWidget.config}
          icon={<LinkIcon size="small" />}
        />
      </Tooltip>
      <Tooltip preferredPosition="bottom" content={polyglot.t('widgets.list.icons.preview_widget')}>
        <Button
          size="small"
          type="secondary"
          onClick={() => showWidgetPreview(listedCompanyWidget.config)}
          dataTest="preview-button"
        >
          <Visibility size="small" />
        </Button>
      </Tooltip>
      <Tooltip preferredPosition="bottom" content={polyglot.t('widgets.list.icons.edit_widget')}>
        <Button
          disabled={!canUpdate}
          size="small"
          type="secondary"
          dataTest="edit-button"
          onClick={() => {
            if (canUpdate) {
              goToEdit(selectedCompany, listedCompanyWidget.id);
            }
          }}
        >
          <EditIcon size="small" />
        </Button>
      </Tooltip>
      <Tooltip preferredPosition="bottom" content={polyglot.t('widgets.list.icons.delete_widget')}>
        <Button
          disabled={!canDelete}
          size="small"
          type="criticalSubtle"
          onClick={() =>
            setDeleteModal({ id: listedCompanyWidget.id, name: listedCompanyWidget.name })
          }
          dataTest="delete-button"
        >
          <RemoveIcon size="small" />
        </Button>
      </Tooltip>
    </FlexContainer>
  );
};

export default Icons;
