import { useContext } from 'react';

import { InfoMessagesState } from './';

const useInfoMessages = () => {
  const ctx = useContext(InfoMessagesState);

  return ctx;
};

export default useInfoMessages;
