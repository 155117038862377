import * as R from 'ramda';
import { filterValuesByLabelCaseInsensitive } from 'utils/array';
import { FILTER_NAMES_I18N, FILTER_VALUES, FULL_REPORT_METRICS, SHOW_VALUES } from 'consts/reports';
import { formatInterval } from 'utils/dates';
import { precisionRound, formatPercentage } from 'utils/number';
import { countriesWithDefault as countries } from 'consts/countries';

/**
 * Based on a given filter and filter values, maps values for select (objects with value and label).
 * @param {*} filter
 * @param {*} values
 * @param {*} polyglot
 */
export const mapFilterValuesForDisplay = (filter, values, polyglot, solutions) => {
  switch (filter) {
    case FILTER_NAMES_I18N.PLATFORM.value:
    case FILTER_NAMES_I18N.DEVICE.value:
      return values.map(value => ({ label: value, value }));
    case FILTER_NAMES_I18N.ROUTE_TYPE.value: {
      return values.map(value => ({
        value,
        label: polyglot.t(FILTER_VALUES[filter].find(filterValue => filterValue.value === value).k),
      }));
    }
    case FILTER_NAMES_I18N.AFFILIATE.value: {
      return values.map(value => ({
        value,
        label: (solutions.find(app => app.affilId === value) || { name: value }).name,
      }));
    }
    case FILTER_NAMES_I18N.MARKET.value: {
      return values.map(value => ({
        value,
        // This part prevents the whole application from breaking if another case like gb occurs.
        // It will simply render the key instead of trying to find a key that doesn't exist and
        // throwing the 'cannot read property label of undefined'
        label: (countries.find(country => country.value === value) || { label: value }).label,
      }));
    }
    default:
      return [];
  }
};

/**
 * Based on a given filter and query, filters filter values and maps them for select (objects with value and label).
 * @param {*} filter
 * @param {*} query
 * @param {*} polyglot
 */
export const getFilteredValues = (filter, query, polyglot, solutions) => {
  switch (filter) {
    case FILTER_NAMES_I18N.PLATFORM.value:
    case FILTER_NAMES_I18N.DEVICE.value: {
      const values = FILTER_VALUES[filter].map(value => ({ label: value, value }));
      const filteredValues = filterValuesByLabelCaseInsensitive(values, query);
      return Promise.resolve(filteredValues);
    }
    case FILTER_NAMES_I18N.ROUTE_TYPE.value: {
      const values = FILTER_VALUES[filter].map(filterValue => ({
        value: filterValue.value,
        label: polyglot.t(filterValue.k),
      }));
      const filteredValues = filterValuesByLabelCaseInsensitive(values, query);
      return Promise.resolve(filteredValues);
    }
    case FILTER_NAMES_I18N.AFFILIATE.value: {
      if (!solutions) {
        return Promise.resolve([]);
      }
      const values = solutions.map(app => ({ label: app.name, value: app.affilId }));
      const filteredValues = filterValuesByLabelCaseInsensitive(values, query);
      return Promise.resolve(filteredValues);
    }
    case FILTER_NAMES_I18N.MARKET.value: {
      const values = countries;
      const filteredValues = filterValuesByLabelCaseInsensitive(values, query);
      return Promise.resolve(filteredValues);
    }
    default:
      return Promise.reject();
  }
};

/**
 * Returns a list of full report metrics contained in the given list of metrics.
 * @param {*} metrics List of report metrics
 */
export const findFullMetrics = metrics => R.intersection(metrics, FULL_REPORT_METRICS);

/**
 * Returns true if given report alias will trigger a full report.
 * @param {*} reportAlias
 */
export const isFullReport = reportAlias => {
  const metrics = reportAlias.show.map(metric => metric.value);
  const fullMetrics = findFullMetrics(metrics);
  return Boolean(fullMetrics.length);
};

/**
 * Returns true if given metric is a type of metric that triggers a full report.
 * @param {*} metric Metric or filter
 */
export const isMetricSlow = metric => R.contains(metric, FULL_REPORT_METRICS);

/**
 * Returns formated value for reports.
 * @param {*} key Key representing metric
 * @param {*} value Metric value
 * @param {*} interval Optional param defining type of interval
 */
export const formatReportValues = (key, value, interval) => {
  if (value === null || value === 'n/a') {
    return 'N/A';
  }
  switch (key) {
    case 'interval':
      return formatInterval(value, interval);
    case 'conversionRate':
      return formatPercentage(precisionRound(value * 100, 2));
    case 'sales':
    case 'profit':
    case 'payout':
    case 'revenue':
      return `${precisionRound(value, 2)} €`;
    default:
      return value;
  }
};

export const getFormattedConversionRate = conversionRate => precisionRound(conversionRate * 100, 2);

export const getColumnLabelForKey = key => {
  if (key === 'interval') {
    return 'reports.interval';
  }
  return SHOW_VALUES[key].k;
};
