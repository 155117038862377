import { useContext } from 'react';

import { AuthState } from 'components/services/auth/AuthProvider';

const useCurrentUser = () => {
  const { user } = useContext(AuthState);

  return user;
};

export default useCurrentUser;
