import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { formatInterval } from 'utils/dates';
import * as stringUtils from 'utils/string';
import { DATE } from 'consts/dates';
import { formatReportValues } from 'pages/Reports/utils';

import { ChangeTableRow, ChangeValue } from './ReportCompareData.styled';

/**
 * getDataForDisplay will decide what should be rendered
 * @param {ReportCompare} data check shapes/Report.js
 * @param {number} index
 * @param {string} key some metrics from show array
 * @returns {string | number}
 */
const getDataForDisplay = (data, index, key) => {
  if (R.is(Array, data)) {
    return formatReportValues(key, data[index]);
  }
  return formatReportValues(key, data);
};

const getRangeForDisplay = (from, to) => {
  return `${formatInterval(from, DATE)} - ${formatInterval(to, DATE)}`;
};

const ReportCompareData = ({ report }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align="left">Interval</TableCell>
        {report.metrics.map(key => (
          <TableCell align="left" key={key}>
            {stringUtils.capitalize(key)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    {report.rows.map((item, i) => (
      <Fragment key={i}>
        {/* row 1 */}
        <TableBody>
          <TableRow>
            <TableCell align="left">
              {getRangeForDisplay(report.dateRange.from, report.dateRange.to)}
            </TableCell>
            {report.metrics.map(key => (
              <TableCell align="left" key={key}>
                {getDataForDisplay(item[key], 0, key)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>

        {/* row 2 */}
        <TableBody>
          <TableRow>
            <TableCell align="left">
              {getRangeForDisplay(report.compareRange.from, report.compareRange.to)}
            </TableCell>
            {report.metrics.map(key => (
              <TableCell align="left" key={key}>
                {getDataForDisplay(item[key], 1, key)}
              </TableCell>
            ))}
          </TableRow>

          {/* row change */}
          <ChangeTableRow>
            <TableCell align="left">Change:</TableCell>
            {report.metrics.map(key => (
              <TableCell align="left" key={key}>
                {(() => {
                  if (!Array.isArray(item[key])) {
                    return null;
                  }
                  const value = getDataForDisplay(item[key], 2, key);
                  const isNumber = R.is(Number, item[key][2]);
                  const positive = isNumber && item[key][2] >= 0;

                  return <ChangeValue positive={positive}>{value}</ChangeValue>;
                })()}
              </TableCell>
            ))}
          </ChangeTableRow>
        </TableBody>
      </Fragment>
    ))}
  </Table>
);

ReportCompareData.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportCompareData;
