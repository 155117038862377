import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';
import { getPolyglot } from 'redux/selectors/i18n';
import { isMetricSlow } from 'pages/Reports/utils';
import { FILTER_NAMES_VALUES } from 'consts/reports';
import { FilterSelect, FilterGlobals, FilterPickerWrapper } from './common.styled';

const FILTER_PLACEHOLDER = {
  k: 'reports.select_filter',
  value: 'report-filter-placeholder',
};

class FilterPicker extends Component {
  state = {
    selectedValue: FILTER_PLACEHOLDER.value,
  };

  handleSelect = filter => {
    const { onSelect } = this.props;
    this.setState({ selectedValue: filter });
    onSelect(filter);
  };

  isFilterTaken = filter => this.props.takenFilters.some(takenFilter => takenFilter === filter);

  render() {
    const { polyglot } = this.props;
    const { selectedValue } = this.state;

    return (
      <FilterPickerWrapper>
        <FilterGlobals />
        <FilterSelect
          id="report-filter-form-select"
          size="small"
          value={selectedValue}
          onChange={event => this.handleSelect(event.target.value)}
          options={[
            {
              label: polyglot.t(FILTER_PLACEHOLDER.k),
              value: FILTER_PLACEHOLDER.value,
              key: FILTER_PLACEHOLDER.value,
              className: 'filter-padding-left',
              disabled: true,
            },
            ...FILTER_NAMES_VALUES.map(({ k, value }) => ({
              label: polyglot.t(k),
              value,
              key: value,
              icon: isMetricSlow(value) && <ClockIcon customColor="#f9971e" />,
              className: !isMetricSlow(value) ? 'filter-padding-left' : null,
              disabled: this.isFilterTaken(value),
            })),
          ]}
        />
      </FilterPickerWrapper>
    );
  }
}

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

FilterPicker.propTypes = {
  takenFilters: PropTypes.array,
  onSelect: PropTypes.func,
};

export default connect(mapStateToProps)(FilterPicker);
