import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  & button:first-of-type {
    margin-right: 8px;
  }

  ${media.desktop(css`
    justify-content: flex-end;
  `)}
`;
