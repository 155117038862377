import React, { useRef, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { useHistory } from 'react-router-dom';

import Loading from '@kiwicom/orbit-components/lib/Loading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import AlertInfoIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import BackButton from 'components/common/BackButton';
import useBooking from 'components/services/booking/useBooking';
import useBookingFlow from 'components/services/booking/useBookingFlow';
import useApiProducts from 'components/services/products/useApiProducts';
import { useCurrency } from 'components/services/currencies';
import { useDependencyEffect } from 'hooks';
import * as Intercom from 'utils/intercom';
import useIsTabActive from 'hooks/useIsTabActive';

import TripSummary from './scenes/TripDetails';
import DepositPayment from './scenes/DepositPayment';
import BookingSummary from './scenes/BookingSummary';
import PriceChangeModal from './scenes/PriceChangeModal';
import InvalidFlightsModal from './scenes/InvalidFlightsModal';
import ErrorModal from './scenes/ErrorModal';
import PassengersAndBags from './scenes/PassengersAndBags';
import PaidGuarantee from './scenes/PaidGuarantee';
import Seating from './scenes/Seating';
import { BOOKING_FLOW } from './consts';
import ScheduleChangeModal from './scenes/ScheduleChangeModal';
import ContactDetails from './scenes/ContactDetails';

const SwitchToOldVersion = ({ location, bookingToken, affilId }) => {
  const history = useHistory();
  const polyglot = usePolyglot();
  return (
    <Alert
      title={polyglot.t('booking.deposit_booking.old_tool')}
      type="info"
      spaceAfter="large"
      icon={<AlertInfoIcon />}
      inlineActions={
        <AlertButton
          size="small"
          type="info"
          dataTest="switch-version-btn"
          onClick={() => {
            Intercom.trackEvent('switched-to-old-booking', { bookingToken, affilId });
            history.push({
              pathname: '/book',
              state: {
                ...location.state,
                forceOld: true,
              },
            });
          }}
        >
          {polyglot.t('booking.deposit_booking.old_version')}
        </AlertButton>
      }
    />
  );
};

const getPaxCategories = passengers => {
  let passengerCategoryString = [];
  for (const passenger of passengers) {
    if (!passenger) {
      continue;
    }

    passengerCategoryString.push(passenger.category);
  }
  return passengerCategoryString.join(',');
};

const DepositBooking = ({ location }) => {
  const { price, tripType, routeGroups, apiKey, token, productType } = location.state;
  const { watch } = useFormContext();
  const passengersData = watch('passengers');
  const paidGuaranteeData = watch('paidGuarantee');
  const {
    checkFlights,
    checkFlightsError,
    data,
    loadingFlights,
    loadingChanges,
    clearData,
    guaranteeOffer,
  } = useBooking();
  const polyglot = usePolyglot();
  const products = useApiProducts();
  const { setCurrency, loading: loadingCurrencies } = useCurrency();
  const paxCategories = getPaxCategories(passengersData);
  //used to determine if any ancillary using session_id from check_flights is ready to be called
  const ancillariesReady = useRef(true);
  const {
    startPollingPrebookingSessions,
    resetPrebookingSessions,
    stopPollingPrebookingSessions,
    resumePollingPrebookingSessions,
    retryPollingPrebookingSessions,
    prebookingSession,
  } = useBookingFlow();
  const isTabActive = useIsTabActive();
  const events = prebookingSession.data && prebookingSession.data.events;

  const product = useMemo(() => {
    if (products.data) {
      return products.data.find(p => p.value === productType);
    }

    return null;
  }, [products.data, productType]);

  const priceChangeEvent = useMemo(() => {
    if (events && events[BOOKING_FLOW.EVENT_TYPE.PRICE_CHANGE]) {
      return events[BOOKING_FLOW.EVENT_TYPE.PRICE_CHANGE];
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prebookingSession.data]);

  const scheduleChangeEvent = useMemo(() => {
    if (events && events[BOOKING_FLOW.EVENT_TYPE.SCHEDULE_CHANGE]) {
      return events[BOOKING_FLOW.EVENT_TYPE.SCHEDULE_CHANGE];
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prebookingSession.data]);

  useEffect(() => {
    setCurrency(location.state?.currency?.toLowerCase());
    Intercom.trackEvent('started-booking-process', {
      bookingToken: token,
      affilId: location.state.affilId,
    });
    checkFlights(location.state, passengersData);
    return () => {
      clearData();
      resetPrebookingSessions();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product) {
      startPollingPrebookingSessions(
        token,
        apiKey,
        product,
        passengersData,
        location.state.currency,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, token, apiKey]);

  useDependencyEffect(() => {
    if (!isTabActive) {
      stopPollingPrebookingSessions();
    } else if (
      prebookingSession.error?.status !== 432 &&
      !!prebookingSession.data?.events &&
      !Object.keys(prebookingSession.data.events)?.length
    ) {
      resumePollingPrebookingSessions(token, apiKey);
    }
  }, [isTabActive]);

  useEffect(() => {
    if (product && prebookingSession.error?.status === 404) {
      retryPollingPrebookingSessions(
        token,
        apiKey,
        product,
        passengersData,
        location.state.currency,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prebookingSession.error, product]);

  //edgecase: when passengersData is changed while check_flights is pending, sessionId will be wrong until next call
  //solution: prevent adding anything dependant on sessionId until data is valid
  useDependencyEffect(() => {
    if (data?.pnum === passengersData.length) {
      ancillariesReady.current = true;
    } else {
      ancillariesReady.current = false;
    }
  }, [data?.pnum, passengersData.length]);

  if (!data || loadingFlights || loadingFlights === undefined || loadingCurrencies) {
    return (
      <Loading type="pageLoader" text={polyglot.t('booking.deposit_booking.loading_booking')} />
    );
  }

  if (!!checkFlightsError) {
    return (
      <div>
        <ErrorModal error={checkFlightsError} />
        <Loading type="pageLoader" text={polyglot.t('booking.deposit_booking.loading_booking')} />
      </div>
    );
  }

  const tripData = { ...data, tripType, price, itinerary: routeGroups };

  return (
    <Stack spacing="large">
      {!!Object.keys(priceChangeEvent).length && (
        <PriceChangeModal
          apiKey={apiKey}
          bookingToken={token}
          eventId={priceChangeEvent.eventId}
          amount={priceChangeEvent.newItineraryPrice.amount}
          currency={priceChangeEvent.newItineraryPrice.currency}
        />
      )}
      {!!Object.keys(scheduleChangeEvent).length && (
        <ScheduleChangeModal affectedSegments={scheduleChangeEvent.affectedSegments} />
      )}
      {prebookingSession.error?.status === 410 && <InvalidFlightsModal />}
      <BackButton route="/search" />
      <SwitchToOldVersion
        location={location}
        bookingToken={token}
        affilId={location.state.affilId}
      />
      <BookingSummary
        loading={loadingFlights}
        tripData={tripData}
        price={Number(prebookingSession.data?.price?.amount)}
        passengers={passengersData}
        paidGuarantee={paidGuaranteeData && guaranteeOffer[0]}
      />
      <TripSummary loading={loadingFlights} tripData={tripData} />
      <ContactDetails />
      <PassengersAndBags
        apiKey={apiKey}
        currency={location.state.currency}
        bookingToken={token}
        loading={loadingFlights}
        tripData={tripData}
        paxCategories={paxCategories}
        ancillariesReady={ancillariesReady}
      />
      <Seating />
      <PaidGuarantee ancillariesReady={ancillariesReady} location={location} />
      <DepositPayment
        loading={loadingFlights || loadingChanges}
        price={Number(prebookingSession.data?.price?.amount)}
        apiKey={apiKey}
        bookingToken={token}
        baggage={tripData.baggage}
        passengers={passengersData}
        paidGuarantee={paidGuaranteeData && guaranteeOffer[0]}
        affilId={location.state.affilId}
      />
    </Stack>
  );
};

export default DepositBooking;
