import { capitalize } from 'utils/string';
import * as dfs from 'date-fns';

const v1_get_to = data => {
  return {
    limit: data.limit,
    status: data.status,
    ...(data.offset && { offset: data.offset }),
    ...(data.bid && { bid: data.bid }),
    ...(data.createdFrom && { created_from: new Date(data.createdFrom).toISOString() }),
    ...(data.createdTo && {
      created_to:
        data.createdTo === data.createdFrom
          ? dfs.endOfDay(new Date(data.createdTo)).toISOString()
          : new Date(data.createdTo).toISOString(),
    }),
    ...(data.transactionTypes && { transaction_types: data.transactionTypes.join(',') }),
  };
};

const v1_get_from = transaction => {
  return {
    amount: `${Math.round(transaction.amount * 100) / 100} €`,
    bid: transaction.booking_id || 'N/A',
    type: capitalize(transaction.transaction_type),
    date: new Date(transaction.created_at).toLocaleString(),
  };
};

export default {
  v1: {
    get: {
      to: v1_get_to,
      from: v1_get_from,
    },
  },
};
