import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import { usePolyglot } from 'components/services/i18n';
import { getFormatedIntervalForChart } from 'components/scenes/Dashboard/scenes/DashboardData/utils';
import { getColumnLabelForKey } from 'pages/Reports/utils';
import { euroSign } from 'consts/symbols';

const AffiliateAppChart = ({ data, groupByInterval }) => {
  const polyglot = usePolyglot();
  const formattedData = data.map(({ interval, sales, clicks, bookings }) => ({
    interval: getFormatedIntervalForChart(interval, groupByInterval),
    clicks,
    sales,
    bookings,
  }));

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={formattedData} margin={{ top: 50, right: 50, bottom: 50, left: 50 }}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="interval" />
          <YAxis yAxisId="0" type="number" dataKey="clicks" stroke="#8884d8" />
          <YAxis
            yAxisId="1"
            type="number"
            dataKey="sales"
            unit={euroSign}
            orientation="right"
            stroke="#82ca9d"
          />
          <YAxis
            yAxisId="2"
            type="number"
            dataKey="bookings"
            orientation="right"
            stroke="#ff0000"
          />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="clicks"
            barSize={40}
            fill="#8884d8"
            yAxisId={0}
            name={polyglot.t(getColumnLabelForKey('clicks'))}
          />
          <Bar
            dataKey="sales"
            barSize={40}
            fill="#82ca9d"
            yAxisId={1}
            name={polyglot.t(getColumnLabelForKey('sales'))}
          />
          <Bar
            dataKey="bookings"
            barSize={40}
            fill="#ff0000"
            yAxisId={2}
            name={polyglot.t(getColumnLabelForKey('bookings'))}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AffiliateAppChart;
