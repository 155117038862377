import React from 'react';

import Card from '@kiwicom/orbit-components/lib/Card';
import CardSection from '@kiwicom/orbit-components/lib/Card/CardSection';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Box from '@kiwicom/orbit-components/lib/Box';

import { usePolyglot } from 'components/services/i18n';

import QuickOptions from './components/QuickOptions';

const NoSeatMap = ({ routeId }) => {
  const polyglot = usePolyglot();

  return (
    <Box width="100%" maxWidth="700px">
      <Card>
        <CardSection>
          <Stack direction="column" align="center" justify="center">
            <Text weight="bold" align="center">
              {polyglot.t('booking_tool.seating.drawer.no_seatmap.title')}
            </Text>
            <Text weight="bold" spaceAfter="medium" align="center">
              {polyglot.t('booking_tool.seating.drawer.no_seatmap.subtitle')}
            </Text>
            <Text align="center">
              {polyglot.t('booking_tool.seating.drawer.no_seatmap.description')}
            </Text>
            <QuickOptions routeId={routeId} />
          </Stack>
        </CardSection>
      </Card>
    </Box>
  );
};

export default NoSeatMap;
