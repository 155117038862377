import { useState } from 'react';
import Fuse from 'fuse.js';
import * as R from 'ramda';

import { QuickGuide } from 'components/services/resources/resolvers';
import { TAXONOMY_CODENAMES } from 'consts/kentico';
import { fireDocsEvent } from 'utils/ga';

import useTaxonomyWithResources from './useTaxonomyWithResources';
import useResources from './useResources';

const useFAQSearch = searchQuery => {
  const [query, setQuery] = useState(searchQuery || '');
  const [filteredFAQs, setFilteredFAQs] = useState([]);
  const taxonomyWithResources = useTaxonomyWithResources();

  const FAQs = taxonomyWithResources
    ? taxonomyWithResources.find(
        taxonomyItem => taxonomyItem.taxonomyCodename === TAXONOMY_CODENAMES.GUIDES_CONTENT_TREE,
      ).terms
    : [];

  const quickGuides = useResources(QuickGuide);

  const handleQueryChange = e => {
    setQuery(e.target.value);
  };

  const handleTracking = results => {
    if (quickGuides.data && results.length === 0) {
      fireDocsEvent({
        event: 'tequila.docs.faq.search.noResults',
        query,
        page: window.location.pathname,
      });
    } else if (quickGuides.data && results.length > 0) {
      fireDocsEvent({
        event: 'tequila.docs.faq.search.gotResults',
        query,
        page: window.location.pathname,
        results: results.map(({ name }) => name),
      });
    }
  };

  const handleSearch = () => {
    if (!quickGuides.data) {
      return;
    }

    const lowercasedQuery = query.toLowerCase();

    const exactMatches = quickGuides.data.filter(({ goal, action, faq_tags }) => {
      const isSameResourceGoal = goal.value.toLowerCase().match(lowercasedQuery);
      const isSameResourceAction = action.value.toLowerCase().match(lowercasedQuery);
      const isSameResourceTags = faq_tags.value.find(({ name }) =>
        name.toLowerCase().match(lowercasedQuery),
      );

      return isSameResourceGoal || isSameResourceAction || isSameResourceTags;
    });

    const searchOptions = [
      { scoreLimit: 0.3, keys: ['goal.value'] },
      { scoreLimit: 0.3, keys: ['action.value'] },
      { scoreLimit: 0.15, keys: ['faq_tags.value.name'] },
    ];

    const searchResults = searchOptions
      .flatMap(({ keys, scoreLimit }) => {
        const fuse = new Fuse(quickGuides.data, { keys, includeScore: true });

        const results = fuse.search(query);

        const qualityResults = results.filter(({ score }) => score <= scoreLimit);

        if (R.isEmpty(results)) {
          return [];
        }

        if (R.isEmpty(qualityResults)) {
          if (results[0].score <= scoreLimit + 0.05) {
            return results.slice(0, 1);
          }
          return [];
        }
        return qualityResults;
      })
      .map(({ item }) => item);

    const uniqueResults = R.uniqBy(
      quickGuide => quickGuide.system.codename,
      searchResults.concat(exactMatches),
    );

    const mappedFAQs = FAQs.map(term => ({
      ...term,
      terms: term.terms.filter(
        t =>
          t.resource &&
          uniqueResults.find(
            quickGuide => quickGuide.faq_content_tree.value[0].codename === t.codename,
          ),
      ),
    }));

    const FAQsWithoutEmptyResources = mappedFAQs.filter(term => term.terms.length > 0);

    handleTracking(FAQsWithoutEmptyResources);

    setFilteredFAQs(FAQsWithoutEmptyResources);
  };

  return {
    handleQueryChange,
    query,
    handleSearch,
    filteredFAQs,
    setQuery,
  };
};

export default useFAQSearch;
