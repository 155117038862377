import { useContext, useEffect } from 'react';
import { PartnerPaymentsState } from './';

const usePartnerPayments = params => {
  const { state, loadPartnerPayments } = useContext(PartnerPaymentsState);
  useEffect(() => {
    if (!state.data) {
      loadPartnerPayments(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: state.data || [],
    loading: state.loading || false,
    error: state.error || false,
    pagination: state.pagination || {
      page: 1,
      perPage: 20,
      totalCount: 0,
      pagesCount: 0,
    },
  };
};

export default usePartnerPayments;
