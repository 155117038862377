import { ContentItem } from 'kentico-cloud-delivery/_bundles/kentico-cloud-delivery-sdk.browser.umd.min';

import { ROUTE_CONFIG } from 'consts/routes';

export default class NewsArticle extends ContentItem {
  isSearchable = true;

  searchableCategory = 'News';

  getTitle() {
    return this.title.value;
  }

  get searchableTitle() {
    return this.title.value;
  }

  get searchableContent() {
    return this.content.value;
  }

  get taxonomyCodename() {
    return this.isSearchable && this.system.codename;
  }

  get toPath() {
    return `${ROUTE_CONFIG.DOCS_NEWS_SINGLE.toPath}/${this.system.codename}`;
  }
}
