import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import { usePolyglot } from 'components/services/i18n';

const Wrapper = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 10px;
  }
`;
const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 5px);
`;

const DocsDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  & > p {
    width: 70%;
  }
  & > :last-child {
    align-self: center;
    margin-right: 10px;
  }
`;

const TextWrapper = styled.div`
  padding: 15px 10px;
  margin-bottom: 10px;
  border: ${({ theme }) => `1px solid ${theme.orbit.backgroundButtonSecondary}`};
  border-radius: 3px;
  transition: box-shadow 0.15s ease-in-out;

  ${({ spaceAfter }) => spaceAfter && 'margin-bottom:20px;'}

  ${({ product }) =>
    product &&
    css`
      background: ${({ theme }) => theme.orbit.paletteProductLight};
      border: ${({ theme }) => `1px solid ${theme.orbit.paletteProductLightActive}`};
    `}

  &:hover {
    ${({ hover }) => hover && 'box-shadow: 0px 3px 8px -5px #000000'};
  }
`;

const leftSection = {
  desc: 'getting_started.documentation.expand.desc',
  items: [
    {
      name: 'getting_started.documentation.expand.first_link',
      docs: '/docs/user_guides/building_online_travel_agency_with_tequila',
    },
    {
      name: 'getting_started.documentation.expand.second_link',
      docs: '/docs/user_guides/managing_your_offline_travel_agency',
    },
  ],
};

const rightSection = {
  desc: 'getting_started.documentation.growth.desc',
  items: [
    {
      name: 'getting_started.documentation.growth.first_link',
      docs: '/docs/user_guides/monetizing_your_content_in_five_minutes',
    },
    {
      name: 'getting_started.documentation.growth.second_link',
      docs: '/docs/user_guides/integrating_kiwi_com_metasearch',
    },
  ],
};

const Documentation = ({ done, onAcknowledge }) => {
  const polyglot = usePolyglot();

  return (
    <Card
      title={
        <Text type={done ? 'secondary' : 'primary'} size="large">
          {polyglot.t('getting_started.documentation.title')}
        </Text>
      }
      dataTest="card-documentation"
    >
      <CardSection>
        <Wrapper>
          <SideWrapper>
            <DocsDescription>
              <Text type="secondary">{polyglot.t(leftSection.desc)}</Text>
              <Badge>{polyglot.t('getting_started.documentation.expand')}</Badge>
            </DocsDescription>
            {leftSection.items.map(i => (
              <TextWrapper product hover key={i.name}>
                <TextLink external noUnderline href={i.docs} dataTest={i.name}>
                  {polyglot.t(i.name)}
                </TextLink>
              </TextWrapper>
            ))}
          </SideWrapper>

          <SideWrapper>
            <DocsDescription>
              <Text type="secondary">{polyglot.t(rightSection.desc)}</Text>
              <Badge>{polyglot.t('getting_started.documentation.growth')}</Badge>
            </DocsDescription>
            {rightSection.items.map(i => (
              <TextWrapper product hover key={i.name}>
                <TextLink external noUnderline href={i.docs} dataTest={i.name}>
                  {polyglot.t(i.name)}
                </TextLink>
              </TextWrapper>
            ))}
          </SideWrapper>
        </Wrapper>
        <TextWrapper spaceAfter>
          {polyglot.t('getting_started.documentation.help_pre')}
          <TextLink external href="/docs/user_guides/choosing_a_right_solution">
            {polyglot.t('getting_started.documentation.help_post')}
          </TextLink>
        </TextWrapper>
        <Button size="small" disabled={done} onClick={onAcknowledge}>
          {polyglot.t('getting_started.acknowledge')}
        </Button>
      </CardSection>
    </Card>
  );
};

export default Documentation;
