import useResources from './useResources';

const useResource = codename => {
  const { loading, data, error } = useResources();

  if (!codename || !data) {
    return {
      loading,
      data: null,
      error,
    };
  }

  const resource = data.find(
    item =>
      item.system.codename === codename ||
      (item.user_guides && item.user_guides.value.find(v => codename === v.codename)),
  );

  return {
    loading,
    data: resource,
    error,
  };
};

export default useResource;
