import React, { useReducer } from 'react';

export const TimeFilterState = React.createContext({
  state: {},
  setSelectedFilterPeriod: async () => [],
  setSelectedFilterCreatedFrom: async () => [],
  setSelectedFilterCreatedTo: async () => [],
  clearSelectedFilters: async () => [],
});

const TimeFilterReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTER_PERIOD':
      return {
        ...state,
        selectedFilterPeriod: action.period,
      };
    case 'SET_FILTER_CREATED_FROM':
      return {
        ...state,
        selectedFilterCreatedFrom: action.createdFrom,
      };
    case 'SET_FILTER_CREATED_TO':
      return {
        ...state,
        selectedFilterCreatedTo: action.createdTo,
      };
    default:
      return state;
  }
};

const INITIAL_STATE = {
  selectedFilterCreatedFrom: '',
  selectedFilterCreatedTo: '',
  selectedFilterPeriod: 'custom',
};

const TimeFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TimeFilterReducer, INITIAL_STATE);

  const setSelectedFilterPeriod = period => {
    try {
      const parsedPeriod = JSON.parse(period);

      dispatch({ type: 'SET_FILTER_PERIOD', period: parsedPeriod?.name });

      if (parsedPeriod.value?.from && parsedPeriod.value?.to) {
        setSelectedFilterCreatedFrom(parsedPeriod.value?.from);
        setSelectedFilterCreatedTo(parsedPeriod.value?.to);
      }
    } catch (err) {
      dispatch({ type: 'SET_FILTER_PERIOD', period: 'custom' });
    }
  };

  const setSelectedFilterCreatedFrom = createdFrom => {
    dispatch({ type: 'SET_FILTER_CREATED_FROM', createdFrom });
  };

  const setSelectedFilterCreatedTo = createdTo => {
    dispatch({ type: 'SET_FILTER_CREATED_TO', createdTo });
  };

  const clearSelectedFilters = () => {
    setSelectedFilterPeriod('custom');
    setSelectedFilterCreatedTo('');
    setSelectedFilterCreatedFrom('');
  };

  const value = {
    ...state,
    setSelectedFilterPeriod,
    setSelectedFilterCreatedFrom,
    setSelectedFilterCreatedTo,
    clearSelectedFilters,
  };

  return <TimeFilterState.Provider value={value}>{children}</TimeFilterState.Provider>;
};

export default TimeFilterProvider;
