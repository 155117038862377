import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import { BillingState } from 'components/services/billing';
import { useToggle } from 'utils/hooks';
import { PageContainer, Pagination } from 'common';
import { usePolyglot } from 'components/services/i18n';
import { AuthState } from 'components/services/auth/AuthProvider';
import { useIsMounted } from 'utils/hooks';

import NoResults from '../../components/NoResults';
import DeleteModal from '../../common/DeleteModal';
import SelectCompanyModal from './components/SelectCompanyModal';

const Wrapper = styled.div`
  width: 350px;
`;

const ExclusionList = () => {
  const {
    loadExclusions,
    createExclusion,
    deleteExclusion,
    clearError,
    state: { exclusions, loading, deleting, creating, error },
  } = useContext(BillingState);
  const { checkAccess, user, checkedGrants } = useContext(AuthState);
  const isMounted = useIsMounted();
  const polyglot = usePolyglot();
  const createExclusionModal = useToggle();
  const [selectedExclusionId, setSelectedExclusionId] = useState(null);
  const [paginatedExclusions, setPaginatedExclusions] = useState([]);
  const [canRemoveExclusion, setCanRemoveExclusion] = useState({ value: null, loading: true });
  const [canCreateExclusion, setCanCreateExclusion] = useState({ value: null, loading: true });
  const [pagination, setPagination] = useState({
    perPage: 20,
    totalCount: 0,
    page: 1,
  });

  useEffect(() => {
    checkAccess('model.billing_job_exclusion', ['delete'], user.companyName).then(res => {
      if (isMounted()) {
        setCanRemoveExclusion({ value: res, loading: false });
      }
    });

    checkAccess('model.billing_job_exclusion', ['create'], user.companyName).then(res => {
      if (isMounted()) {
        setCanCreateExclusion({ value: res, loading: false });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedGrants?.[user.companyName]?.['model.billing_job_exclusion']]);

  //load exclusions, if they're already loaded, paginate them.
  useEffect(() => {
    if (!exclusions) {
      loadExclusions();
    } else {
      setPaginatedExclusions(exclusions.slice(0, pagination.perPage));
      setPagination({ ...pagination, totalCount: exclusions.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusions]);

  const handlePagination = (rowsPerPage, nextPage) => {
    setPaginatedExclusions(exclusions.slice((nextPage - 1) * rowsPerPage, nextPage * rowsPerPage));
    setPagination({ ...pagination, perPage: Number(rowsPerPage), page: nextPage });
  };

  const handleFilterByCompany = useCallback(
    val => {
      const filteredExclusions = exclusions.filter(company =>
        company.companyName.toLowerCase().includes(val.toLowerCase()),
      );
      setPaginatedExclusions(filteredExclusions.slice(0, pagination.perPage));
      setPagination({ ...pagination, totalCount: filteredExclusions.length });
    },
    [exclusions, pagination],
  );

  const handleClose = () => {
    createExclusionModal.setOff();
    setSelectedExclusionId(null);
    clearError();
  };

  const handleDelete = async () => {
    await deleteExclusion(selectedExclusionId);
    handleClose();
  };

  const handleSubmit = async company => {
    try {
      await createExclusion(company);
      await loadExclusions();
      handleClose();
    } catch (err) {}
  };

  return (
    <PageContainer>
      {selectedExclusionId && (
        <DeleteModal
          loading={deleting}
          onClose={handleClose}
          onDelete={handleDelete}
          size="small"
          title={polyglot.t('billing.delete_modal.exclusion.title')}
          description={polyglot.t('billing.delete_modal.exclusion.description')}
        />
      )}
      {createExclusionModal.isOn && (
        <SelectCompanyModal
          onClose={handleClose}
          onSuccess={handleSubmit}
          loading={creating}
          error={error}
        />
      )}
      <Stack direction="row" spaceAfter="large" justify="between">
        <Wrapper>
          <InputField
            size="small"
            prefix={<SearchIcon />}
            onChange={e => handleFilterByCompany(e.target.value)}
            placeholder={polyglot.t('billing.exclusions.filter')}
          />
        </Wrapper>
        <Button
          disabled={!canCreateExclusion.value}
          loading={canCreateExclusion.loading}
          type="primary"
          onClick={createExclusionModal.setOn}
          size="small"
        >
          {polyglot.t('billing.exclusions.new_exclusion')}
        </Button>
      </Stack>
      <Card loading={loading}>
        <CardSection>
          {!paginatedExclusions.length && !exclusions?.length ? (
            <NoResults message={polyglot.t('billing.no_exclusions')} />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{polyglot.t('billing.company')}</TableCell>
                  <TableCell>{polyglot.t('billing.exclusions.date_excluded')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedExclusions.map(exclusion => {
                  return (
                    <TableRow key={exclusion.id}>
                      <TableCell>{exclusion.companyName}</TableCell>
                      <TableCell>{exclusion.createdAt}</TableCell>
                      <TableCell align="right">
                        <Stack justify="end">
                          <Button
                            disabled={!canRemoveExclusion.value}
                            loading={canRemoveExclusion.loading || deleting === exclusion.id}
                            type="critical"
                            size="small"
                            onClick={() => setSelectedExclusionId(exclusion.id)}
                          >
                            {polyglot.t('billing.exclusions.remove')}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </CardSection>
        {!loading && (
          <Pagination
            rows={pagination.totalCount}
            rowsPerPage={pagination.perPage}
            page={pagination.page}
            rowsPerPageItems={[10, 20, 50, 100]}
            onPagination={handlePagination}
          />
        )}
      </Card>
    </PageContainer>
  );
};

export default ExclusionList;
