import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useWindowSize } from 'hooks';
import { Container } from './ScrollableVertical.styled';

const ScrollableVertical = props => {
  const containerRef = useRef(null);
  const [topShadow, setTopShadow] = useState(false);
  const [bottomShadow, setBottomShadow] = useState(false);
  const size = useWindowSize();

  const handleShadowSettings = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

    const shouldShowTopShadow = scrollTop > 16;
    const shouldShowBottomShadow = scrollTop < scrollHeight - clientHeight - 16;

    if (shouldShowTopShadow && !topShadow) {
      setTopShadow(true);
    } else if (!shouldShowTopShadow && topShadow) {
      setTopShadow(false);
    }

    if (shouldShowBottomShadow && !bottomShadow) {
      setBottomShadow(true);
    } else if (!shouldShowBottomShadow && bottomShadow) {
      setBottomShadow(false);
    }
  }, [containerRef, topShadow, bottomShadow]);

  useEffect(() => {
    handleShadowSettings();
  }, [handleShadowSettings, size.height]);

  return (
    <Container
      ref={containerRef}
      topShadow={topShadow}
      bottomShadow={bottomShadow}
      onScroll={handleShadowSettings}
      {...props}
    />
  );
};

export default ScrollableVertical;
