import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: auto;

  &::after,
  &::before {
    content: '';
    display: block;
    position: sticky;
    width: 100%;
    height: 16px;
    left: 0;
    transition: opacity ${({ theme }) => theme.orbit.durationNormal} ease-in-out;
  }

  &::before {
    top: 0;
    opacity: ${({ topShadow }) => (topShadow ? '1' : '0')};
    background-image: linear-gradient(to top, transparent, rgba(186, 199, 213, 0.23));
  }

  &::after {
    bottom: 0;
    opacity: ${({ bottomShadow }) => (bottomShadow ? '1' : '0')};
    background-image: linear-gradient(to bottom, transparent, rgba(186, 199, 213, 0.23));
  }
`;
