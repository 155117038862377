import React from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import AlertCircleIcon from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import useMenuState from 'components/services/menu/useMenuState';
import { useToggle } from 'utils/hooks';
import useOnboardingState from 'components/services/localStorage/useOnboardingState';
import { useCurrentUser } from 'components/services/auth';
import { usePolyglot } from 'components/services/i18n';
import { useHistory } from 'react-router';
import { ROUTE_CONFIG } from 'consts/routes';

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: ${({ largeScreen }) => (largeScreen ? 256 : 61)}px;
  transition: left 150ms ease-in-out;
  right: 0;
  padding: 14px 48px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.orbit.backgroundCard : theme.orbit.paletteProductLight};
  border-top: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  transition: all 150ms ease-in-out;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 435px;
  margin-right: 100px;

  p,
  svg {
    transition: all 150ms ease-in-out;
    color: ${({ theme, disabled }) =>
      disabled ? theme.orbit.paletteInkLighter : theme.orbit.colorTextLinkPrimary};
  }

  svg {
    margin-right: 12px;
  }
`;

const CongratsModal = ({ onClose }) => {
  const user = useCurrentUser();
  const history = useHistory();
  const polyglot = usePolyglot();
  const [onboardingState, setOnboardingState] = useOnboardingState();

  const handleFinish = () => {
    setOnboardingState({
      ...onboardingState,
      done: true,
    });

    history.push(`${ROUTE_CONFIG.COMPANY.toPath}/${user.companyName}`);
  };

  return (
    <Modal size="small" onClose={onClose}>
      <ModalHeader
        title={polyglot.t('getting_started.finish.modal_title')}
        illustration={<Illustration name="Success" size="extraSmall" />}
        description={<Text size="normal">{polyglot.t('getting_started.finish.modal_text')}</Text>}
      >
        <Alert title={polyglot.t('getting_started.finish.alert_title')} type="info">
          <Text spaceAfter="normal">{polyglot.t('getting_started.finish.alert_text')}</Text>
          <AlertButton href="/docs/user_guides/booking_flow_with_deposit_model" external>
            {polyglot.t('getting_started.finish.alert_button')}
          </AlertButton>
        </Alert>
      </ModalHeader>
      <ModalFooter>
        <Button type="primary" size="large" onClick={handleFinish}>
          {polyglot.t('getting_started.finish.button_ok')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FinishBottomBar = ({ disabled = true }) => {
  const menuCtx = useMenuState();
  const modal = useToggle();
  const polyglot = usePolyglot();
  const largeScreen = menuCtx.navigationExpanded && menuCtx.isLargeScreen;

  return (
    <>
      {modal.isOn && <CongratsModal onClose={modal.setOff} />}

      <Container largeScreen={largeScreen} disabled={disabled}>
        <Wrapper direction="row" align="center" disabled={disabled}>
          <AlertCircleIcon color="secondary" />
          <Text size="small" type="secondary">
            {polyglot.t('getting_started.finish.bar_text')}
          </Text>
        </Wrapper>
        {disabled ? (
          <Tooltip
            preferredPosition="top"
            content={polyglot.t('getting_started.finish.bar_tooltip')}
          >
            <Button size="large" type="primary" disabled onClick={modal.setOn}>
              {polyglot.t('getting_started.finish.bar_button')}
            </Button>
          </Tooltip>
        ) : (
          <Button size="large" type="primary" onClick={modal.setOn}>
            {polyglot.t('getting_started.finish.bar_button')}
          </Button>
        )}
      </Container>
    </>
  );
};

export default FinishBottomBar;
