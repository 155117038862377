export const processImageFile = async file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.onload = e => {
      resolve(e.target.result);
    };

    reader.readAsDataURL(file);
  });
};

export const processFile = async file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject();
    };

    reader.onload = e => {
      resolve(e.target.result);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const handleCSVDownload = async (content, fileName) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', `${fileName}.csv`);
  pom.click();
};

export const downloadURL = (fileUrl, fileName) => {
  const pom = document.createElement('a');
  pom.href = fileUrl;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const downloadURLs = files => {
  const interval = setInterval(
    urls => {
      const url = urls.pop();

      const a = document.createElement('a');
      a.setAttribute('href', url.url);
      a.setAttribute('download', url.name);
      a.click();

      if (urls.length === 0) {
        clearInterval(interval);
      }
    },
    300,
    files,
  );
};
