import React from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import SeatLegend from '@kiwicom/orbit-components/lib/Seat/components/SeatLegend';

import { usePolyglot } from 'components/services/i18n';
import useBooking from 'components/services/booking/useBooking';
import { getSegmentSeatMapPriceRange } from 'utils/seating';

import { LegendItemsWrapper } from './index.styled';
import { useCurrency } from 'components/services/currencies';

const Legend = ({ routeId }) => {
  const {
    seatingOfferData: { offers },
  } = useBooking();
  const polyglot = usePolyglot();
  const [priceRangeExtraLegroom, priceRangeStandard] = getSegmentSeatMapPriceRange(offers, routeId);
  const { getFormattedPriceString } = useCurrency();

  return (
    <Stack
      direction="column"
      spacing="XSmall"
      align="start"
      spaceAfter="large"
      largeMobile={{
        align: 'center',
      }}
    >
      <LegendItemsWrapper>
        {!!priceRangeExtraLegroom.length && (
          <SeatLegend
            type="legroom"
            label={
              priceRangeExtraLegroom[0] !== priceRangeExtraLegroom[1]
                ? polyglot.t('booking.additional_services.seating.seat_map.legend.extra_leg_room', {
                    from: getFormattedPriceString(priceRangeExtraLegroom[0]),
                    to: getFormattedPriceString(priceRangeExtraLegroom[1]),
                  })
                : polyglot.t(
                    'booking.additional_services.seating.seat_map.legend.extra_leg_room.single',
                    {
                      price: getFormattedPriceString(priceRangeExtraLegroom[0]),
                    },
                  )
            }
          />
        )}
        {!!priceRangeStandard.length && (
          <SeatLegend
            type="default"
            label={
              priceRangeStandard[0] !== priceRangeStandard[1]
                ? polyglot.t('booking.additional_services.seating.seat_map.legend.standard', {
                    from: getFormattedPriceString(priceRangeStandard[0]),
                    to: getFormattedPriceString(priceRangeStandard[1]),
                  })
                : polyglot.t(
                    'booking.additional_services.seating.seat_map.legend.standard.single',
                    {
                      price: getFormattedPriceString(priceRangeStandard[0]),
                    },
                  )
            }
          />
        )}
        <SeatLegend
          type="unavailable"
          label={polyglot.t('booking.additional_services.seating.seat_map.legend.unavailable_seat')}
        />

        <Stack direction="row" spacing="small" inline>
          <Text type="secondary">*</Text>
          <Text type="secondary">
            {polyglot.t(
              'booking.additional_services.seating.seat_map.legend.seat_with_some_conditions',
            )}
          </Text>
        </Stack>
      </LegendItemsWrapper>
    </Stack>
  );
};

export default Legend;
