import React from 'react';

import Modal, { ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import useCreateSolution from 'components/scenes/MySolutions/services/useCreateSolution';

import SelectSolution from './SelectSolution';
import SelectSolutionType from './SelectSolutionType';
import Summary from './Summary';

const Step = React.memo(() => {
  const { step } = useCreateSolution();
  switch (step) {
    case 0:
      return <SelectSolution />;
    case 1:
      return <SelectSolutionType />;
    case 2:
      return <Summary />;
    default:
      return null;
  }
});

const StepController = ({ onClose, onSuccess, hideBackButton }) => {
  const polyglot = usePolyglot();
  const {
    step,
    handlePrevious,
    handleStepSelect,
    solutionName,
    handleSubmit,
    loading,
    validationError,
  } = useCreateSolution();

  const isFirstStep = step === 0;
  const isLastStep = step === 2;

  return (
    <ModalFooter>
      {!isFirstStep && !hideBackButton && (
        <Button title="back-button" type="secondary" onClick={handlePrevious}>
          <Text>{polyglot.t('common.back')}</Text>
        </Button>
      )}
      {isLastStep ? (
        <Button
          width="144px"
          loading={loading}
          dataTest="create-button"
          disabled={!solutionName || validationError}
          onClick={() => handleSubmit(onSuccess)}
        >
          {polyglot.t('common.create')}
        </Button>
      ) : (
        !isFirstStep && (
          <Button width="144px" onClick={handleStepSelect}>
            {polyglot.t('common.next')}
          </Button>
        )
      )}
      {isFirstStep && (
        <Button width="144px" type="secondary" onClick={onClose} dataTest="cancel-button">
          {polyglot.t('common.cancel')}
        </Button>
      )}
    </ModalFooter>
  );
};

const SolutionsModal = React.memo(({ onClose, onSuccess, hideBackButton }) => {
  return (
    <Modal onClose={onClose} dataTest="solutions-modal" size="large">
      <Step />
      <Separator spaceAfter="normal" />
      <StepController onClose={onClose} onSuccess={onSuccess} hideBackButton={hideBackButton} />
    </Modal>
  );
});

export default SolutionsModal;
