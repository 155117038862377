import { useContext, useEffect } from 'react';
import { ResourcesState } from './index';

const useResources = ContentType => {
  const {
    state: { allResources },
    fetchAllResources,
  } = useContext(ResourcesState);

  useEffect(() => {
    if (!allResources.data) {
      fetchAllResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ContentType) {
    return {
      ...allResources,
      data: allResources.data && allResources.data.filter(item => item instanceof ContentType),
    };
  }

  return allResources;
};

export default useResources;
