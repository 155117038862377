import { tripTypesValues } from 'consts/search';
import { default as Return } from './Return';
import { default as OneWay } from './OneWay';
import { default as MultiCity } from './MultiCity';
import { default as Nomad } from './Nomad';

export default {
  [tripTypesValues.RETURN]: Return,
  [tripTypesValues.ONEWAY]: OneWay,
  [tripTypesValues.MULTICITY]: MultiCity,
  [tripTypesValues.NOMAD]: Nomad,
};
