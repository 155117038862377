import { useContext, useEffect } from 'react';
import { BillingCyclesState } from './';

const useCompanyBillingCycles = companyName => {
  const { state, loadCompanyBillingCycles } = useContext(BillingCyclesState);

  useEffect(() => {
    if (!state[companyName]) {
      loadCompanyBillingCycles(companyName);
    }
    // eslint-disable-next-line
  }, [companyName]);

  return state[companyName] || { loading: false, data: null, error: null };
};

export default useCompanyBillingCycles;
