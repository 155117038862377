import useCurrentUser from 'components/services/auth/useCurrentUser';

const useUsersCompanyName = () => {
  const user = useCurrentUser();

  if (user) {
    return user.companyName;
  }
  return null;
};

export default useUsersCompanyName;
