import React from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import AlertIcon from '@kiwicom/orbit-components/lib/icons/Alert';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import useMMB from 'components/services/mmb/useMMB';
import { usePolyglot } from 'components/services/i18n';

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }
`;

const AlertWrapper = styled.div`
  margin-top: 30px;
`;

const CancelBookingModal = ({ onClose, bid, handleAlert }) => {
  const { cancelBooking, cancelBookingLoading } = useMMB();
  const polyglot = usePolyglot();

  const handleCancelBooking = async () => {
    try {
      await cancelBooking(bid);
      handleAlert();
      onClose();
    } catch (err) {
      return err;
    }
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader title={polyglot.t('mmb.stop_processing.title')} />
      <ModalSection>
        <Text size="large">{polyglot.t('mmb.stop_processing.description')}</Text>
        <AlertWrapper>
          <Alert type="warning">{polyglot.t('mmb.stop_processing.warning')}</Alert>
        </AlertWrapper>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onClose} type="secondary">
          {polyglot.t('common.close')}
        </Button>
        <Button type="critical" loading={cancelBookingLoading} onClick={handleCancelBooking}>
          <IconWrapper>
            <AlertIcon size="normal" />
            {polyglot.t('mmb.stop_processing')}
          </IconWrapper>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelBookingModal;
