import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  callExternalWidgetToGetFormValues,
  createPreviewCodeAndAttachToRefs,
  generateScriptFromFormValues,
  mapFromExternalWidgetBuilderFormValues,
  mapToExternalWidgetBuilderFormValues,
  mapWidgetConfig,
} from 'utils/widgets';
import { useToggle } from 'utils/hooks';

import Loading from '@kiwicom/orbit-components/lib/Loading';
import WidgetLoadingErrorAlert from './components/WidgetLoadingErrorAlert';

import CreateWidgetSolutionModal from '../common/CreateWidgetSolutionModal';
import useWidgetsContext from 'components/services/widgets';
import useHideNav from './services/useHideNav';

import useCompanyWidgetSolutions from 'components/services/widgets/useCompanyWidgetSolutions';
import Drawer from '@kiwicom/orbit-components/lib/Drawer';
import { ActionsWrapper } from './components/Preview/index.styled';
import Button from '@kiwicom/orbit-components/lib/Button';
import { usePolyglot } from 'components/services/i18n';
import copyToClipboard from 'utils/copyToClipboard';
import MenuHeader from './components/MenuHeader';

const widgetBuilderHolderStyle = {
  marginLeft: 'calc((100vw - 855px - 270px) / 2)',
  maxWidth: '855px',
};

const appendWidgetBuilderScript = (affilIds, widgetFormValues) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'widget-builder';
  script.setAttribute('data-container-id', 'widget-builder-holder');
  script.setAttribute('data-iframe-id', 'widget-builder');
  script.setAttribute('data-create-solution-button', 'true');
  script.setAttribute('data-preview-button', 'true');
  script.setAttribute('data-affilid', affilIds);
  script.setAttribute('data-ui', 'tequila');
  script.setAttribute('data-widget-values', widgetFormValues);
  script.src = 'https://widgets.kiwi.com/scripts/widget-builder-iframe.js';
  document.body.appendChild(script);
};

const WidgetBuilder = () => {
  const hideNavbarAndHeader = useHideNav();
  const showCreateWidgetSolutionModal = useToggle(false);
  const showPreview = useToggle(false);
  const [successCreatingWidgetSolutionAlert, setSuccessCreatingWidgetSolutionAlert] =
    useState(null);
  const [externalWidgetFormValues, setExternalWidgetFormValues] = useState({});
  const [widgetParam, setWidgetParam] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const scriptRef = useRef(null);
  const holderRef = useRef(null);
  const polyglot = usePolyglot();
  const copiedToClipboard = useToggle(false);
  const script = document.getElementById('widget-builder');

  const {
    fetchWidget,
    goBack,
    state: { widget, loadingWidget, creatingWidget, updatingWidget },
  } = useWidgetsContext();

  const { companyName, widgetId: widgetIdString } = useParams();
  const widgetId = Number(widgetIdString);
  const editingWidget = Boolean(widgetId);

  const {
    companyWidgetSolutions,
    companySolutionsLoading: loading,
    companySolutionsLoadingError: loadingError,
  } = useCompanyWidgetSolutions(companyName);

  const removeScript = () => {
    if (script) {
      document.body.removeChild(script);
      window.removeEventListener('message', handleMessage);
    }
  };

  const handleMessage = async e => {
    const { createSolution, formValues, isPreview, param } = e.data;
    if (createSolution) {
      showCreateWidgetSolutionModal.setOn();
    } else if (formValues) {
      setExternalWidgetFormValues(mapFromExternalWidgetBuilderFormValues(formValues));
      if (param) {
        setWidgetParam(param);
      }
      if (isPreview) {
        showPreview.setOn();
      }
    }
  };

  useEffect(() => {
    if (widgetParam === 'copyToClipboard') {
      copyToClipboard(generateScriptFromFormValues(externalWidgetFormValues));

      copiedToClipboard.setOn();

      setTimeout(() => {
        copiedToClipboard.setOff();
      }, 2000);

      setWidgetParam(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetParam]);

  useEffect(() => {
    createPreviewCodeAndAttachToRefs(
      externalWidgetFormValues,
      showPreview.isOn,
      scriptRef,
      holderRef,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreview.isOn]);

  const handleCopyClick = () => {
    callExternalWidgetToGetFormValues('copyToClipboard');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(hideNavbarAndHeader, []);
  useEffect(() => {
    fetchWidget(widgetId);

    return () => {
      removeScript();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const affilIds = useMemo(() => {
    if (companyWidgetSolutions.length) {
      return companyWidgetSolutions.map(companyWidgetSolution => ({
        label: companyWidgetSolution.name,
        value: companyWidgetSolution.affilId,
      }));
    }
    return [];
  }, [companyWidgetSolutions]);

  useEffect(() => {
    if (
      !widgetId && // if it is not edit mode
      !loading && // if company solutions are loaded
      !loadingError && // if company solutions have not error
      affilIds.length && // if affildIds (mapped company solutions) are not empty
      !script && // if script does not exist yet
      !successCreatingWidgetSolutionAlert // if state of widget solution alert does not exist
    ) {
      setTimeout(() => {
        appendWidgetBuilderScript(JSON.stringify(affilIds));
        window.addEventListener('message', handleMessage);
        setIsLoading(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, successCreatingWidgetSolutionAlert]);

  useEffect(() => {
    if (
      widgetId && // if it is edit mode
      !loading && // if company solutions are loaded
      !loadingError && // if company solutions have not error
      affilIds.length && // if affildIds (mapped company solutions) are not empty
      widget?.config && // if state widget has some config received from BE
      !script && // if script does not exist yet
      !successCreatingWidgetSolutionAlert && // if state of widget solution alert does not exist
      !createLoading && // in edit mode we can create new or edit current widget so createLoading must be false
      !updateLoading // updateLoading must be false
    ) {
      const mappedWidgetConfig = mapWidgetConfig(widget);
      appendWidgetBuilderScript(
        JSON.stringify(affilIds),
        JSON.stringify(mapToExternalWidgetBuilderFormValues(mappedWidgetConfig)),
      );
      window.addEventListener('message', handleMessage);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetId, loading, widget, successCreatingWidgetSolutionAlert, createLoading, updateLoading]);

  useEffect(() => {
    if (
      !creatingWidget.inProgress &&
      creatingWidget.success &&
      !creatingWidget.error &&
      !successCreatingWidgetSolutionAlert
    ) {
      setCreateLoading(true);

      setTimeout(() => {
        setCreateLoading(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingWidget]);

  useEffect(() => {
    if (
      !updatingWidget.inProgress &&
      updatingWidget.success &&
      !updatingWidget.error &&
      !successCreatingWidgetSolutionAlert
    ) {
      setUpdateLoading(true);

      setTimeout(() => {
        setUpdateLoading(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingWidget]);

  if (
    loadingWidget.inProgress ||
    successCreatingWidgetSolutionAlert ||
    createLoading ||
    updateLoading ||
    isLoading
  ) {
    return <Loading />;
  }

  if (loadingWidget.error) {
    return <WidgetLoadingErrorAlert reload={() => fetchWidget(widgetId)} goBack={goBack} />;
  }

  return (
    <>
      {showCreateWidgetSolutionModal.isOn && (
        <CreateWidgetSolutionModal
          setSuccessCreatingWidgetSolutionAlert={setSuccessCreatingWidgetSolutionAlert}
          closeMe={() => showCreateWidgetSolutionModal.setOff()}
          removeScript={removeScript}
        />
      )}
      <MenuHeader
        isLoaded={!loading && !loadingError && affilIds.length}
        successCreatingWidgetSolutionAlert={successCreatingWidgetSolutionAlert}
        widgetId={widgetId}
        editingWidget={editingWidget}
        companyName={companyName}
        values={externalWidgetFormValues}
        widgetParam={widgetParam}
        resetWidgetParam={() => setWidgetParam(null)}
      />
      <Drawer
        shown={showPreview.isOn}
        position="right"
        width="644px"
        onClose={() => showPreview.setOff()}
        actions={
          <ActionsWrapper>
            <Button type="primary" size="small" onClick={handleCopyClick}>
              {copiedToClipboard.isOn
                ? polyglot.t('common.copied_to_clipboard')
                : polyglot.t('common.copy_to_clipboard')}
            </Button>
          </ActionsWrapper>
        }
      >
        <div
          id={externalWidgetFormValues?.advanced?.containerId || 'widget-holder'}
          ref={holderRef}
        ></div>
        <div ref={scriptRef}></div>
      </Drawer>
      <div id="widget-builder-holder" style={widgetBuilderHolderStyle}></div>
    </>
  );
};

export default WidgetBuilder;
