import { useContext } from 'react';

import { useBookingToolTabs } from 'components/services/bookingTool';

import { SearchState } from './SearchProvider';

const useSearch = () => {
  const ctx = useContext(SearchState);
  const { selectedTab } = useBookingToolTabs();
  const tabData = ctx.tabs[selectedTab];

  return {
    ...ctx,
    tabData,
  };
};

export default useSearch;
