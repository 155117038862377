import { useDependencyEffect } from 'hooks';
import { useContext, useRef } from 'react';
import { MMBState } from '.';
import useBookingCheckboxes from './useBookingCheckboxes';

const useBulkRefunds = () => {
  const { checkboxIds } = useBookingCheckboxes();
  const {
    state: { refunds },
    refundByBid,
  } = useContext(MMBState);
  const remainingBids = useRef([]);
  const requestingRefundedItems = useRef([]);
  const isLoading = useRef(false);
  const newRequest = useRef(false);
  const refundsStateKeys = Object.keys(refunds);
  const hasRefundsStateLength = refundsStateKeys && refundsStateKeys.length;

  const submitRefunds = () => {
    isLoading.current = true;
    const cbIds = [...checkboxIds];
    const bids = cbIds.slice(0, 5);
    remainingBids.current = cbIds.filter(checkboxId => !bids.includes(checkboxId));
    bids.forEach(bid => {
      refundByBid(bid);
    });
  };

  useDependencyEffect(() => {
    if (hasRefundsStateLength) {
      refundsStateKeys.forEach(refundStateKey => {
        if (
          !requestingRefundedItems.current.includes(refundStateKey) &&
          refunds[refundStateKey].requestingRefund === false
        ) {
          requestingRefundedItems.current.push(refundStateKey);
          newRequest.current = true;
        }
      });
    }

    if (newRequest.current && remainingBids.current.length) {
      newRequest.current = false;
      const bid = remainingBids.current[0];
      remainingBids.current = remainingBids.current.filter(remainingBid => remainingBid !== bid);
      refundByBid(bid);
    }
  }, [refunds]);

  return { submitRefunds, isLoading };
};

export default useBulkRefunds;
