import React from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';

import {
  CardDataContainer,
  CardDataAlertWrapper,
  CardDataColumnsWrapper,
  CardDataWrapper,
  CardDataColumn,
  CardDataTitle,
  CardDataAction,
  CardDataRow,
} from './common.styled';

const CardData = ({ title, showAlert, alert, fullWidthRows, leftColumn, rightColumn, action }) => {
  return (
    <CardDataContainer>
      {showAlert && <CardDataAlertWrapper>{alert}</CardDataAlertWrapper>}
      {fullWidthRows &&
        fullWidthRows.map((row, i) => (
          <CardDataColumnsWrapper key={i}>
            <CardDataRow>
              <Text type="secondary" dataTest={row.labelDataTest}>
                {row.label}:
              </Text>
              <Text type="primary" dataTest={row.valueDataTest}>
                {row.value}
              </Text>
            </CardDataRow>
          </CardDataColumnsWrapper>
        ))}
      {(leftColumn || rightColumn) && (
        <CardDataWrapper>
          <CardDataColumnsWrapper>
            <CardDataColumn>
              {title && <CardDataTitle>{title}</CardDataTitle>}
              {(leftColumn || []).map((row, i) => (
                <CardDataRow key={i}>
                  <Text type="secondary" dataTest={row.labelDataTest}>
                    {row.label}:
                  </Text>
                  <Text type="primary" dataTest={row.valueDataTest}>
                    {row.value}
                  </Text>
                </CardDataRow>
              ))}
            </CardDataColumn>
            {rightColumn && (
              <CardDataColumn>
                {action && <CardDataAction>{action}</CardDataAction>}
                {rightColumn.map((row, i) => (
                  <CardDataRow key={i}>
                    <Text type="secondary" dataTest={row.labelDataTest}>
                      {row.label}:
                    </Text>
                    <Text type="primary" dataTest={row.valueDataTest}>
                      {row.value}
                    </Text>
                  </CardDataRow>
                ))}
              </CardDataColumn>
            )}
          </CardDataColumnsWrapper>
        </CardDataWrapper>
      )}
    </CardDataContainer>
  );
};

export default CardData;
