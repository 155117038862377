import React, { useMemo } from 'react';
import styled from 'styled-components';

import Stack from '@kiwicom/orbit-components/lib/Stack';

import SolutionsCard from 'components/scenes/MySolutions/components/SolutionsCard';
import { ACCOUNT_MANAGER, KIWI_ADMIN, FINANCIAL_MANAGER } from 'consts/roles';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useCompanyNames from 'components/services/companies/useCompanyNames';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';
import { usePolyglot } from 'components/services/i18n';
import { Autocomplete } from 'common';

const Container = styled.div`
  max-width: 880px;
`;

const SelectCompanyName = ({ onSelect, value }) => {
  const polyglot = usePolyglot();
  const companyNames = useCompanyNames();
  const companyOptions = useMemo(() => {
    return (companyNames.data || []).map(name => ({
      value: name,
      label: name,
    }));
  }, [companyNames]);
  return (
    <Autocomplete
      options={companyOptions}
      onSelect={companyName => {
        onSelect(companyName.value);
      }}
      value={value}
      label={polyglot.t('common.load_solutions_for')}
      noResultsText={polyglot.t('common.select_app_company.no_results_text')}
      size="normal"
    />
  );
};

const MySolutions = () => {
  const user = useCurrentUser();
  const { selectedCompany, selectCompany } = useSelectedCompany();

  return (
    <Container>
      {(user.role === KIWI_ADMIN ||
        user.role === ACCOUNT_MANAGER ||
        user.role === FINANCIAL_MANAGER) && (
        <Stack spaceAfter="large">
          <SelectCompanyName onSelect={selectCompany} value={selectedCompany} />
        </Stack>
      )}
      {selectedCompany && <SolutionsCard companyName={selectedCompany} />}
    </Container>
  );
};

export default MySolutions;
