import React, { useContext } from 'react';
import Button from '@kiwicom/orbit-components/lib/Button';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { CommissionsState } from 'components/services/commercials';
import useToasts from 'components/services/toasts/useToasts';
import { usePolyglot } from 'components/services/i18n';

const CommercialEnableDialog = ({ commercial, affilId, uuid, onClose, onSuccess }) => {
  const polyglot = usePolyglot();
  const {
    state: { enabling },
    enableCommission,
  } = useContext(CommissionsState);
  const { addErrorToast } = useToasts();

  const handleEnableCommercial = async () => {
    try {
      await enableCommission(commercial, affilId, uuid);
      onSuccess();
    } catch (error) {
      addErrorToast(polyglot.t('common.submit_error_message'));
    }
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader title={polyglot.t('commercials.confirm_enable_header')}>
        {polyglot.t('commercials.confirm_enable_message')}
      </ModalHeader>
      <ModalFooter>
        <Button type="secondary" width="128px" onClick={onClose}>
          {polyglot.t('common.cancel')}
        </Button>
        <Button type="primary" width="128px" onClick={handleEnableCommercial} loading={enabling}>
          {polyglot.t('common.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(CommercialEnableDialog);
