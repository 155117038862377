import React, { useContext, useEffect, useState, useMemo } from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import AccountCircleIcon from '@kiwicom/orbit-components/lib/icons/AccountCircle';

import { countriesWithICO } from 'consts/countries';
import { nationalities } from 'consts/nationalities';
import { useToggle } from 'utils/hooks';
import { getVatIdLabel } from 'utils/company';
import { Card, CardActionHeader, CardData } from 'components/common/Card';
import { usePolyglot } from 'components/services/i18n';
import { AuthState } from 'components/services/auth/AuthProvider';
import { CompanyState } from 'components/services/company';
import { Authorization } from 'common/Authorization';

import EditDetailsModal from './EditDetailsModal/index';
const COMPANY_TYPES = {
  natural_person: 'Natural Person',
  legal_entity: 'Company',
};

const Details = ({ companyDetails }) => {
  const { checkGrantProperties } = useContext(AuthState);
  const { updateCompanyDetails } = useContext(CompanyState);
  const editModal = useToggle();
  const updateAlert = useToggle();
  const polyglot = usePolyglot();
  const [canAccountManager, setCanAccountManager] = useState(false);

  const { updateError } = companyDetails;

  useEffect(() => {
    if (!companyDetails.loading) {
      setCanAccountManager(
        checkGrantProperties(
          'model.company',
          'read',
          'account_manager',
          companyDetails?.data?.name,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails.loading]);

  const companyCountry = useMemo(() => {
    if (!companyDetails.data) {
      return;
    }

    const country = nationalities.find(n => n.code === companyDetails.data.country.toUpperCase());

    return country && country.name;
  }, [companyDetails.data]);

  const handleSubmit = async data => {
    await updateCompanyDetails(companyDetails.data.name, data);
    updateAlert.setOn();
    editModal.setOff();
  };

  return (
    <>
      {editModal.isOn && (
        <EditDetailsModal
          onSubmit={handleSubmit}
          onClose={editModal.setOff}
          companyDetails={companyDetails}
        />
      )}
      <Card loading={companyDetails.loading} loadingText={polyglot.t('company.loading_details')}>
        {companyDetails.data && (
          <>
            <CardActionHeader
              data-test="company-details-company-name"
              title={
                <Text type="primary" weight="bold" size="large">
                  {companyDetails.data.displayName}
                </Text>
              }
              icon={<AccountCircleIcon />}
              action={
                <Authorization
                  resource="model.company"
                  action={['update', 'update_own', 'update_assigned']}
                  companyName={companyDetails.data.name}
                >
                  <Button
                    type="secondary"
                    size="small"
                    title="Edit details"
                    dataTest="button-edit-details"
                    iconLeft={<EditIcon />}
                    onClick={editModal.setOn}
                  />
                </Authorization>
              }
            />
            <CardData
              showAlert={updateAlert.isOn}
              alert={
                <Alert
                  type={updateError?.toJSON() ? 'critical' : 'success'}
                  closable
                  icon
                  onClose={updateAlert.setOff}
                >
                  {updateError?.toJSON() &&
                    polyglot.t(
                      updateError.response?.data?.errors?.account_type
                        ? 'company.update_details_error.account_type'
                        : 'company.update_details_error',
                    )}
                  {!updateError?.toJSON() && polyglot.t('company.update_details_success')}
                </Alert>
              }
              title="Company details"
              leftColumn={[
                {
                  label: polyglot.t('company.type'),
                  value: COMPANY_TYPES[companyDetails.data.type],
                },
                {
                  label: polyglot.t('company.legal_name'),
                  value: companyDetails.data.legalName || '-',
                },
                { label: polyglot.t('common.url'), value: companyDetails.data.url || '-' },
              ]}
              rightColumn={[
                {
                  label: polyglot.t('company.account_manager'),
                  value: canAccountManager
                    ? `${companyDetails.data.accountManager} (${companyDetails.data.adminEmail})`
                    : '-',
                },
                {
                  label: polyglot.t(getVatIdLabel(companyDetails.data.country)),
                  value: companyDetails.data.vatId || '-',
                },
                {
                  label: countriesWithICO.includes(companyDetails.data.country)
                    ? polyglot.t('company.tax_id_cz')
                    : polyglot.t('company.tax_id'),
                  value: companyDetails.data.taxId || '-',
                },
              ]}
            />
            <CardData
              alert={
                <Alert type="success" icon onClose={updateAlert.setOff} closable>
                  {polyglot.t('company.update_details_success')}
                </Alert>
              }
              title={polyglot.t('company.legal_details')}
              leftColumn={[
                {
                  label: polyglot.t('company.details_address_1'),
                  value: companyDetails.data.address1 || '-',
                },
                {
                  label: polyglot.t('company.details_zip_postal'),
                  value: companyDetails.data.zip || '-',
                },
              ]}
              rightColumn={[
                {
                  label: polyglot.t('common.country'),
                  value: companyCountry || '-',
                },
                { label: polyglot.t('company.city'), value: companyDetails.data.city || '-' },
              ]}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default Details;
