import * as R from 'ramda';
import format from 'date-fns/format';
import queryString from 'query-string';
import addDays from 'date-fns/addDays';

import { handleError } from 'utils/http';
import { isNotEmptyOrNull } from 'utils/string';

const getLocationsUrl = (term, locationTypes = ['country', 'city', 'airport']) => {
  const types = R.reduce((a, b) => `${a}&location_types=${b}`, '', locationTypes);
  return `https://api.skypicker.com/locations?locale=en-US&limit=100&term=${term}${types}`;
};

const dateFormat = day => format(new Date(day), 'd. M. yyyy');

export const dateInputValue = (days, placeholder) => {
  if (R.isEmpty(days)) {
    return placeholder || '';
  }
  if (R.length(days) === 1) {
    return dateFormat(R.head(days));
  }
  return `${dateFormat(R.head(days))} - ${dateFormat(R.last(days))}`;
};

const URL = 'www.kiwi.com/deep?';
const linkDateFormat = day => format(new Date(day), 'yyyy-MM-dd');

// default parameter for func is identity, it will return just the input itself,
// idea is: pass some function that will do something with date
// in this case I wanna add some days
const dateLinkValue = (dates, func = R.identity) => {
  const head = linkDateFormat(func(R.head(dates)));
  const last = linkDateFormat(func(R.last(dates)));
  if (head === last) {
    return head;
  }
  return `${head}_${last}`;
};

const reducedLinkParams = obj => {
  // filtering object
  const filtered = R.filter(isNotEmptyOrNull, obj);
  return R.keys(filtered).reduce((acc, key) => {
    // now we know that if there is array, that the array's type is: Date[]
    if (obj[key] instanceof Array) {
      // daysInDestination - we have create also return dates
      if (R.has('daysInDestination', filtered)) {
        acc['departure'] = dateLinkValue(obj.departure);
        // passing addDays function to calc return dates
        acc['return'] = dateLinkValue(
          obj.departure,
          R.partialRight(addDays, [obj.daysInDestination]),
        );
        return acc;
      }
      // otherwise construct date values with propper key
      acc[key] = dateLinkValue(obj[key]);
      return acc;
    }

    acc[key] = obj[key];
    return acc;
  }, {});
};

export const generateLink = obj => URL + queryString.stringify(reducedLinkParams(obj));

export const getLocationName = location => {
  if (location.type === 'airport') {
    return `${location.name} - ${location.code}`;
  }
  if (location.type === 'city') {
    return `${location.name}, ${location.country.name}`;
  }
  return `${location.name} (${location.type})`;
};

export const getLocation = (term, locationTypes) => {
  return fetch(getLocationsUrl(term, locationTypes))
    .then(res => res.json())
    .then(res => res.locations)
    .catch(handleError);
};

export const areLocationsWide = locations => {
  if (!locations.length) {
    return false;
  }
  return !!locations.filter(loc => loc.type !== 'city' && loc.type !== 'airport').length;
};
