import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPolyglot } from 'redux/selectors/i18n';

import ChevronUpIcon from '@kiwicom/orbit-components/lib/icons/ChevronUp';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import {
  TruncateContainer,
  ButtonContainer,
  BlurredDiv,
  ExpandWrapper,
} from './TruncateComponent.styled';
import { Flex } from 'common';

/**
 * Generic truncate component
 * Wraps the supplied children in an element of the supplied height and expands it with a click on the 'Show more/less' button
 */
class TruncateComponent extends PureComponent {
  state = {
    expanded: false,
  };

  truncateContainer = React.createRef();

  toggleExpand = () => this.setState(state => ({ expanded: !state.expanded }));

  componentDidMount() {
    if (!this.truncateContainer.current) {
      return;
    }

    if (this.truncateContainer.current) {
      this.contentHeight = this.truncateContainer.current.getBoundingClientRect().height;
    }
  }

  componentDidUpdate() {
    // If the children update, it sets the height to the new height of the content
    if (this.truncateContainer.current) {
      this.contentHeight = this.truncateContainer.current.getBoundingClientRect().height;
    }
  }

  render() {
    const { expanded } = this.state;
    const { children, minHeight, transitionDuration, polyglot } = this.props;

    const calculatedHeight = expanded ? this.truncateContainer.current.clientHeight : minHeight;

    if (!children) {
      return null;
    }

    return (
      <>
        <TruncateContainer
          style={{
            maxHeight: calculatedHeight,
            transitionDuration,
          }}
        >
          <div ref={this.truncateContainer}>{children}</div>
        </TruncateContainer>
        <ButtonContainer>
          <ExpandWrapper id="toggle-expand-button" onClick={this.toggleExpand}>
            <Flex column cross="center">
              {expanded ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />}

              <span>
                {expanded ? polyglot.t('common.show_less') : polyglot.t('common.show_more')}
              </span>
            </Flex>
          </ExpandWrapper>
          <BlurredDiv />
        </ButtonContainer>
      </>
    );
  }
}

TruncateComponent.propTypes = {
  children: PropTypes.node,
  minHeight: PropTypes.string.isRequired,
  transitionDuration: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

export default connect(mapStateToProps)(TruncateComponent);
