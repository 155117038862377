import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:not(:first-of-type) {
    margin-left: 8px;
  }
`;
