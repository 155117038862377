import { useContext, useState, useEffect } from 'react';
import * as R from 'ramda';

import useSearch from 'components/services/search/useSearch';
import statusTypes from 'consts/statusTypes';
import { SMARTPOINT_APP_INIT_PERIOD, sortTypes } from 'consts/search';
import { fireSmartpointEvent } from 'utils/ga';
import { SolutionsState } from 'components/services/solutions';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';
import useInitialQueryParams from 'components/services/initialQueryParams/useInitialQueryParams';
import { SmartpointState } from 'components/services/smartpoint';
import parseSmartpointCommand from 'utils/smartpoint';

import useInfoMessages from './useInfoMessages';

const getLastParsableCommand = lastCommands =>
  R.last(lastCommands.split(',').filter(c => /^(A|FS|FD)/i.test(c)));

const useSmartpoint = () => {
  const userCompany = useUsersCompanyName();
  const companyApps = useCompanySolutions(userCompany);
  const { loadCompanySolutions } = useContext(SolutionsState);
  const { tabData, searchFlights, selectSolution, changeSearchState } = useSearch();
  const { addInfoMessage } = useInfoMessages();
  const initialQueryParams = useInitialQueryParams();
  const { ensurePCCState } = useContext(SmartpointState);
  const [smartpointAppStatus, setSmartpointAppStatus] = useState(statusTypes.NONE);
  const [isInitialSmartPointModalShown, setIsInitialSmartPointModalShown] = useState(false);
  const [existingApplication, setExistingApplication] = useState(null);

  const pcc = initialQueryParams.pcc;
  const command = initialQueryParams.lastCommands
    ? getLastParsableCommand(initialQueryParams.lastCommands)
    : initialQueryParams.command;

  const handleParsedCommand = (parsedCommand, ensuredApplication) => {
    const { unparsed, messages, prefix, searchState } = parsedCommand;
    if (!R.isEmpty(unparsed)) {
      addInfoMessage({
        id: 'unparsed_remainder',
        type: 'warning',
        content: unparsed.map(x => `"${x}"`).join(', '),
      });
      fireSmartpointEvent('Smartpoint - partial', prefix, command);
    }
    if (!R.isEmpty(messages)) {
      for (const m of messages) {
        const { id, type, content } = m;
        addInfoMessage({
          id,
          type,
          content,
        });
      }
    }
    const searchRequestData = {
      ...tabData,
      ...searchState,
      ...ensuredApplication,
      isSmartpointSearch: true,
    };
    changeSearchState(searchRequestData);
    searchFlights(searchRequestData);
  };

  useEffect(() => {
    if (!pcc) return;

    if (
      ensurePCCState.data &&
      !ensurePCCState.loading &&
      !ensurePCCState.error &&
      companyApps.data &&
      !companyApps.loading &&
      !companyApps.error
    ) {
      if (
        R.all(app => app.affilId !== ensurePCCState.data.affilId)(companyApps.data) &&
        smartpointAppStatus === statusTypes.NONE
      ) {
        loadCompanySolutions(userCompany);
        setSmartpointAppStatus(statusTypes.LOADING);
        setTimeout(() => {
          setSmartpointAppStatus(statusTypes.SUCCESS);
          setIsInitialSmartPointModalShown(false);
        }, SMARTPOINT_APP_INIT_PERIOD);
      } else if (smartpointAppStatus !== statusTypes.LOADING) {
        setSmartpointAppStatus(statusTypes.SUCCESS);
      }
    } else if (ensurePCCState.error) {
      addInfoMessage({
        id: 'pcc_application_error',
        type: 'critical',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ensurePCCState, companyApps]);

  useEffect(() => {
    if (!pcc) return;

    if (!ensurePCCState.data && !existingApplication) {
      return;
    }

    const ensuredApplication = ensurePCCState.data
      ? {
          affilId: ensurePCCState.data.affilId,
          consumerKey: ensurePCCState.data.consumerKey,
          productType: ensurePCCState.data.productType,
        }
      : existingApplication;

    if (!command) {
      selectSolution(ensuredApplication);
      return;
    }

    const appExists = smartpointAppStatus === statusTypes.SUCCESS || existingApplication;
    const searchStatusNone =
      tabData.searchStatus[sortTypes.BEST] === statusTypes.NONE &&
      tabData.searchStatus[sortTypes.PRICE] === statusTypes.NONE &&
      tabData.searchStatus[sortTypes.DURATION] === statusTypes.NONE;

    if (searchStatusNone && appExists) {
      const parsedCommand = parseSmartpointCommand(command);
      if (parsedCommand) {
        handleParsedCommand(parsedCommand, ensuredApplication);
      } else {
        fireSmartpointEvent('Smartpoint - unparsed', 'Unsupported command', command);
        addInfoMessage({
          id: 'unsupported_command',
          type: 'critical',
          content: command,
        });

        // use last parsable command
        if (initialQueryParams.lastCommands) {
          const lastParsableCommand = getLastParsableCommand(initialQueryParams.lastCommands);

          const parsed = parseSmartpointCommand(lastParsableCommand);
          if (parsed) {
            handleParsedCommand(parsed);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartpointAppStatus, existingApplication]);

  return {
    isInitialSmartPointModalShown,
    setIsInitialSmartPointModalShown,
    setExistingApplication,
  };
};

export default useSmartpoint;
