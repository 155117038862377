import { camelize } from 'utils/functions';
import { extractType } from 'utils/prebooking';

const direct_patch_to = () => ({
  resolution: 'accept',
});

const direct_patch_config_to = apiKey => ({
  headers: { apikey: apiKey },
});

const direct_patch_from = prebookingSessionData => ({
  sessionId: prebookingSessionData.id,
  refreshTtl: prebookingSessionData.refresh_ttl,
  events: prebookingSessionData.events ? extractType(camelize(prebookingSessionData.events)) : {},
  price: {
    amount: prebookingSessionData.pricing.itinerary.price.amount,
    currency: prebookingSessionData.pricing.itinerary.price.currency,
  },
});

export default {
  direct: {
    patch: {
      to: direct_patch_to,
      config_to: direct_patch_config_to,
      from: direct_patch_from,
    },
  },
};
