import { COMPANY_TYPES } from 'consts/company';

export const screenTypes = {
  COMPANY: 'company',
  PERSON: 'person',
};

export const screeningStatus = {
  COMPLIANT: 'COMPLIANT',
  NON_COMPLIANT: 'NON_COMPLIANT',
};

export const initialValues = {
  address1: '',
  city: '',
  country: null,
  isPrimary: true,
  zip: '',
  czTaxPayer: false,
  companyName: '',
  companyEmail: '',
  legalName: '',
  stakeholderFirstName: '',
  stakeholderLastName: '',
  stakeholderDateOfBirth: '',
  stakeholderNationality: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  nationality: '',
  businessPresenceDisclaimer: false,
  type: COMPANY_TYPES.COMPANY,
  website: '',
  vatId: '',
  email: '',
  ico: '',
  dic: '',
  agreedOnTosAndPolicy: false,
  accountName: '',
  marketingConsent: false,
};

export const businessTypeOptions = [
  {
    label: 'register.business_type.metasearch',
    value: 'Metasearch (flight comparator)',
  },
  {
    label: 'register.business_type.travel_website',
    value: 'Travel website (Affiliate)',
  },
  {
    label: 'register.business_type.data_aggregator',
    value: 'Data Aggregator',
  },
  {
    label: 'register.business_type.affiliate_network',
    value: 'Affiliate Network',
  },
  {
    label: 'register.business_type.airport',
    value: 'Airport',
  },
  {
    label: 'register.business_type.other',
    value: 'Other',
  },
];

export const companySizeOptions = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2-10',
    value: '2-10',
  },
  {
    label: '10-50',
    value: '10-50',
  },
  {
    label: '50-100',
    value: '50-100',
  },
  {
    label: '100-500',
    value: '100-500',
  },
  {
    label: '500-1000',
    value: '500-1000',
  },
  {
    label: '1000+',
    value: '1000+',
  },
];
