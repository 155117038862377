import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import Stack from '@kiwicom/orbit-components/lib/Stack';

import BusinessAccountForm from './BusinessAccountForm';
import PersonalAccountForm from './PersonalAccountForm';

import { COMPANY_TYPES } from 'consts/company';

const Container = styled.div`
  flex: 1;
`;

const Form = ({ canAccountManager, canTaxId }) => {
  const { watch } = useFormContext();

  const accountType = watch('accountType');

  return (
    <Container>
      <Stack spacing="large">
        {accountType === COMPANY_TYPES.COMPANY ? (
          <BusinessAccountForm canAccountManager={canAccountManager} canTaxId={canTaxId} />
        ) : (
          <PersonalAccountForm canAccountManager={canAccountManager} />
        )}
      </Stack>
    </Container>
  );
};

export default Form;
