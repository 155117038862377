import styled from 'styled-components';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

const InlineTextWrapper = styled.span`
  font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
`;

const ScheduleChangeModal = ({ affectedSegments = [] }) => {
  const polyglot = usePolyglot();

  return (
    <Modal hasCloseButton={false} preventOverlayClose dataTest="schedule-change-modal">
      <ModalHeader
        illustration={<Illustration name="Time" size="small" />}
        title={
          <Heading>{polyglot.t('booking.deposit_booking.schedule_change.modal.title')}</Heading>
        }
        description={
          <>
            <Text size="large" spaceAfter="large">
              {polyglot.t('booking.deposit_booking.schedule_change.modal.primary_description_1')}{' '}
              <InlineTextWrapper>{affectedSegments.join(', ')}</InlineTextWrapper>
              {polyglot.t('booking.deposit_booking.schedule_change.modal.primary_description_2')}
            </Text>
            <Text size="large">
              {polyglot.t('booking.deposit_booking.schedule_change.modal.secondary_description')}
            </Text>
          </>
        }
      />
      <ModalFooter>
        <Button
          type="primary"
          onClick={() => {
            document.location.reload();
          }}
          dataTest="schedule-change-accept"
        >
          {polyglot.t('booking.deposit_booking.schedule_change.modal.button.accept')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleChangeModal;
