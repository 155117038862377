import styled from 'styled-components';
import { Input } from 'common';

export const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const StyledInput = styled(Input)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ disabled, theme }) =>
    disabled ? theme.orbit.backgroundInputDisabled : theme.orbit.backgroundInput};
  text-align: ${({ align }) => align};
`;
