import React from 'react';
import styled from 'styled-components';
import GenderWomanIcon from '@kiwicom/orbit-components/lib/icons/GenderWoman';
import GenderManIcon from '@kiwicom/orbit-components/lib/icons/GenderMan';
import InfantIcon from '@kiwicom/orbit-components/lib/icons/Infant';

import { usePolyglot } from 'components/services/i18n';
import { capitalize } from 'utils/string';

const Row = styled.div`
  display: flex;
  align-items: center;

  & > div,
  svg {
    margin-right: 5px;
  }
`;

export const getPaxGender = title => {
  if (title === 'Mr' || title === 'mr') {
    return 'male';
  }
  if (title === 'Ms' || title === 'ms') {
    return 'female';
  }
};

const PaxGenderInfo = React.memo(({ category, title, iconOnly, type, dataTest }) => {
  const polyglot = usePolyglot();

  if (category !== 'adult') {
    return (
      <Row data-test={dataTest}>
        <InfantIcon size="small" color={type || 'secondary'} />
        {!iconOnly && capitalize(category)}
      </Row>
    );
  }

  const gender = getPaxGender(title);

  if (gender === 'male') {
    return (
      <Row data-test={dataTest}>
        <GenderManIcon size="small" color={type || 'secondary'} />{' '}
        {!iconOnly && polyglot.t('mmb.passenger_details.male')}
      </Row>
    );
  }
  if (gender === 'female') {
    return (
      <Row data-test={dataTest}>
        <GenderWomanIcon size="small" color={type || 'secondary'} />{' '}
        {!iconOnly && polyglot.t('mmb.passenger_details.female')}
      </Row>
    );
  }

  return null;
});

export default PaxGenderInfo;
