import { createGlobalStyle } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

import swaggerOverridesStyles from './swaggerOverrides';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Circular Pro", -apple-system, ".SFNSText-Regular", "San Francisco", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 14px;
    background-color: ${({ theme }) => theme.orbit.backgroundBody};
  }
  .grecaptcha-badge{
    visibility:hidden;
    opacity:0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0;
    color: inherit;
  }

  #root {
    overflow: auto;
    height: 100%;

    ${media.tablet`
      overflow: initial;
      height: initial;
    `}
  }

  ${swaggerOverridesStyles};
`;

export default GlobalStyles;
