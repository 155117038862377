import React, { useReducer } from 'react';

import api, { proxy } from 'utils/api';
import companyUserMapper from 'mappers/nextGen/companies/{company_name}/users/_';
import userMapper from 'mappers/nextGen/users/{user_identifier}/_';
import { SERVICE_DESK } from 'consts/support';

export const CompanyUsersState = React.createContext({
  companyUsersState: {
    loading: false,
    error: null,
    creatingUser: false,
    createError: null,
    updatingUser: false,
    updateError: null,
    removingUser: false,
    removeError: null,
  },
  loadCompanyUsers: async companyName => companyName,
  updateCompanyUser: async user => user,
  createCompanyUser: async (user, companyName, isAdditionalInfoNeeded) => [
    user,
    companyName,
    isAdditionalInfoNeeded,
  ],
  removeCompanyUser: async (user, companyName) => [user, companyName],
});

const companyUsersReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_USERS':
      return {
        ...state,
        loading: true,
        error: null,
        [action.companyName]: {
          loading: true,
        },
      };
    case 'LOAD_COMPANY_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.users,
          pagination: action.pagination,
          sort: action.sort,
        },
      };
    case 'LOAD_COMPANY_USERS_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
        [action.companyName]: {
          loading: false,
        },
      };
    case 'UPDATE_COMPANY_USER':
      return {
        ...state,
        updatingUser: true,
        updateError: null,
      };
    case 'UPDATE_COMPANY_USER_SUCCESS':
      return {
        ...state,
        updatingUser: false,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.map(u =>
            u.email === action.user.email ? action.user : u,
          ),
        },
      };
    case 'UPDATE_COMPANY_USER_FAIL':
      return {
        ...state,
        updatingUser: false,
        updateError: action.error,
      };
    case 'CREATE_COMPANY_USER':
      return {
        ...state,
        creatingUser: true,
        createError: null,
      };
    case 'CREATE_COMPANY_USER_SUCCESS':
      return {
        ...state,
        creatingUser: false,
        [action.companyName]: {
          ...state[action.companyName],
          data: state?.[action.companyName]?.data?.concat(action.user),
        },
      };
    case 'CREATE_COMPANY_USER_FAIL':
      return {
        ...state,
        creatingUser: false,
        createError: action.error,
      };
    case 'REMOVE_USER_FROM_COMPANY':
      return {
        ...state,
        removingUser: true,
        removeError: null,
      };
    case 'REMOVE_USER_FROM_COMPANY_SUCCESS':
      return {
        ...state,
        removingUser: false,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.filter(u => u.email !== action.user.email),
        },
      };
    case 'REMOVE_USER_FROM_COMPANY_FAIL':
      return {
        ...state,
        removingUser: false,
        removeError: action.error,
      };
    default:
      return state;
  }
};

const defaultSort = { key: 'last_name', dir: '' };
const UsersProvider = ({ children }) => {
  const [companyUsersState, companyUsersDispatch] = useReducer(companyUsersReducer, {});

  const loadCompanyUsers = async (companyName, page = 1, perPage = 20, sort = defaultSort) => {
    companyUsersDispatch({ type: 'LOAD_COMPANY_USERS', companyName });

    try {
      let { data } = await api.v1.get(`companies/${companyName}/users`, {
        params: {
          _per_page: Number(perPage),
          _page: Number(page),
          ...(sort && { _order: `${sort.dir}${sort.key}` }),
        },
      });
      const users = data.data.map(companyUserMapper.v1.get.from);

      companyUsersDispatch({
        type: 'LOAD_COMPANY_USERS_SUCCESS',
        companyName,
        users,
        pagination: {
          page: Number(page),
          perPage: Number(perPage),
          pagesCount: data.pagination.pages,
          totalCount: data.pagination.total,
        },
        sort: sort,
      });
    } catch (error) {
      companyUsersDispatch({ type: 'LOAD_COMPANY_USERS_FAIL', companyName, error });
    }
  };

  const updateCompanyUser = async ({ userBeingEdited, ...user }) => {
    companyUsersDispatch({ type: 'UPDATE_COMPANY_USER', companyName: userBeingEdited.companyName });

    try {
      const res = await api.v1.put(`users/${user.email}`, userMapper.v1.put.to(user));
      const editedUser = userMapper.v1.put.from(res.data);

      if (editedUser.role !== user.role) {
        await api.v1.post(`acl/users/${editedUser.email}/roles`, {
          role: user.role,
          replace: editedUser.role,
        });
        editedUser.role = user.role;
      }

      companyUsersDispatch({
        type: 'UPDATE_COMPANY_USER_SUCCESS',
        companyName: userBeingEdited.companyName,
        user: editedUser,
      });
    } catch (error) {
      companyUsersDispatch({
        type: 'UPDATE_COMPANY_USER_FAIL',
        companyName: userBeingEdited.companyName,
        error,
      });

      return Promise.reject(error);
    }
  };

  const createCompanyUser = async (user, companyName, isAdditionalInfoNeeded) => {
    companyUsersDispatch({ type: 'CREATE_COMPANY_USER', companyName });

    try {
      await api.v1.post(
        `companies/${companyName}/users`,
        companyUserMapper.v1.post.to(user, isAdditionalInfoNeeded),
      );
      const { data } = await api.v1.get(`users/${user.email}`);

      const newUser = companyUserMapper.v1.post.from(data);

      companyUsersDispatch({
        type: 'CREATE_COMPANY_USER_SUCCESS',
        user: newUser,
        companyName,
      });
    } catch (error) {
      companyUsersDispatch({ type: 'CREATE_COMPANY_USER_FAIL', error, companyName });

      return Promise.reject(error);
    }
  };

  const removeCompanyUser = async (user, companyName) => {
    companyUsersDispatch({ type: 'REMOVE_USER_FROM_COMPANY', companyName });

    try {
      // It does not matter if jira calls fail, but it needs to be done before user removal
      try {
        await proxy.v1.delete(`jira/${SERVICE_DESK.BPSD}/customer?email=${user.email}`);
      } catch (_) {}
      try {
        await proxy.v1.delete(`jira/${SERVICE_DESK.CSSD}/customer?email=${user.email}`);
      } catch (_) {}

      await api.v1.delete(`users/${user.email}`);

      companyUsersDispatch({ type: 'REMOVE_USER_FROM_COMPANY_SUCCESS', user, companyName });
    } catch (error) {
      companyUsersDispatch({ type: 'REMOVE_USER_FROM_COMPANY_FAIL', error });

      return Promise.reject(error);
    }
  };

  const value = {
    ...companyUsersState,
    loadCompanyUsers,
    updateCompanyUser,
    createCompanyUser,
    removeCompanyUser,
  };

  return <CompanyUsersState.Provider value={value}>{children}</CompanyUsersState.Provider>;
};

export default UsersProvider;
