import { v1 as uuid } from 'uuid';
import * as R from 'ramda';
import * as Yup from 'yup';

export const MAX_NUMBER_OF_VOUCHERS = 6;

export const keys = {
  LEGAL_NAME: 'legal_name',
  LEGAL_ADDRESS: 'legal_address',
  VAT_NUMBER: 'VAT_number',
  BANK_ACCOUNT_NUMBER: 'bank_account_number',
  BANK_NAME_AND_ADDRESS: 'bank_name_and_address',
  CONTACT_PERSON_1_NAME: 'contact_person_1_name',
  CONTACT_PERSON_1_ROLE: 'contact_person_1_role',
  CONTACT_PERSON_1_EMAIL: 'contact_person_1_email',
  CONTACT_PERSON_1_PHONE: 'contact_person_1_phone',
  CONTACT_PERSON_2_NAME: 'contact_person_2_name',
  CONTACT_PERSON_2_ROLE: 'contact_person_2_role',
  CONTACT_PERSON_2_EMAIL: 'contact_person_2_email',
  CONTACT_PERSON_2_PHONE: 'contact_person_2_phone',
  IATA_CODE: 'IATA_code',
  AIRPORT_LOGO: 'airport_logo',
  SMART_PASS_NAME: 'smart_pass_name',
  SMART_PASS_LOGO: 'smart_pass_logo',
  SMART_PASS_PAGE: 'smart_pass_page',
  ILLUSTRATION: 'illustration',
  ILLUSTRATION_COLOR_1: 'illustration_color_1',
  ILLUSTRATION_COLOR_2: 'illustration_color_2',
  DISPLAYING_CONDITION: 'displaying_condition',
  DISPLAYING_CONDITION_HOURS: 'displaying_condition_hours',
  REPORTING_EMAIL_1: 'reporting_email_1',
  REPORTING_EMAIL_2: 'reporting_email_2',
  REPORTING_EMAIL_3: 'reporting_email_3',
  REPORTING_EMAIL_4: 'reporting_email_4',
  AIRPORT_SIGNAGE_1: 'airport_signage_1',
  AIRPORT_SIGNAGE_2: 'airport_signage_2',
  AIRPORT_SIGNAGE_3: 'airport_signage_3',
  TERMINAL_MAPS_1: 'terminal_maps_1',
  TERMINAL_MAPS_2: 'terminal_maps_2',
  TERMINAL_MAPS_3: 'terminal_maps_3',
  FAST_TRACK_ACTIVATED: 'fast_track_activated',
  FAST_TRACK_CONDITION: 'fast_track_condition',
  FAST_TRACK_LAYOVER_HOURS: 'fast_track_condition_layover_hours',
  FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS: 'fast_track_condition_layover_and_checked_bag_hours',
  FAST_TRACK_ITINERARIES_TYPES: 'fast_track_itineraries_types',
  FAST_TRACK_TERMINALS: 'fast_track_terminals',
  FAST_TRACK_TERMINALS_LIST: 'fast_track_terminals_list',
  FAST_TRACK_TERMINALS_LIST_NEW_VALUE: 'fast_track_terminals_list_new_value',
  FAST_TRACK_VOUCHER_FULFILMENT: 'fast_track_voucher_fulfilment',
  FAST_TRACK_VOUCHER_CODE_TYPE: 'fast_track_voucher_code_type',
  FAST_TRACK_VOUCHER_CODE_GENERATION: 'fast_track_voucher_code_generation',
  FAST_TRACK_STATIC_KEYWORD: 'fast_track_static_keyword',
  FAST_TRACK_STATIC_URL: 'fast_track_static_url',
  FAST_TRACK_REPORT_EMAILS_ACTIVATED: 'fast_track_report_emails_activated',
  FAST_TRACK_REPORT_EMAILS_LIST: 'fast_track_report_emails_list',
  FAST_TRACK_REPORT_EMAILS_NEW_VALUE: 'fast_track_report_emails_new_value',
  FAST_TRACK_DESIGN_TYPE: 'fast_track_design_type',
  FAST_TRACK_CUSTOM_VOUCHER_DESIGN: 'fast_track_custom_voucher_design',
  TRANSFER_PROCESS: 'transfer_process',
  TRANSFER_ASSISTANCE_ACTIVATED: 'transfer_assistance_activated',
  TRANSFER_ASSISTANCE_SERVICE_TYPE: 'transfer_assistance_service_type',
  TRANSFER_ASSISTANCE_DESK_TYPE: 'transfer_assistance_desk_type',
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK: 'transfer_assistance_airport_staff_look',
  TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR:
    'transfer_assistance_airport_staff_uniform_color',
  TRANSFER_ASSISTANCE_LOCATION: 'transfer_assistance_location',
  BAG_TRANSFER_ASSISTANCE_ACTIVATED: 'bag_transfer_assistance_activated',
  BAG_TRANSFER_ASSIST_LOCATION: 'bag_transfer_assist_location',
  BAG_TRANSFER_ASSIST_TERMINALS: 'bag_transfer_assist_terminals',
  BAG_TRANSFER_ASSIST_TERMINALS_LIST: 'bag_transfer_assist_terminals_list',
  BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE: 'bag_transfer_assist_terminals_list_new_value',
  BAG_TRANSFER_ASSIST_SERVICE_PROVIDED: 'bag_transfer_assist_service_provided',
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE: 'bag_transfer_assist_sbdm_carriers_type',
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST: 'bag_transfer_assist_sbdm_carriers_type_list',
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE:
    'bag_transfer_assist_sbdm_carriers_type_list_new_value',
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE: 'bag_transfer_assist_counter_carriers_type',
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST: 'bag_transfer_assist_counter_carriers_type_list',
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE:
    'bag_transfer_assist_counter_carriers_type_list_new_value',
  VOUCHERS: 'vouchers',
  VOUCHERS_ACTIVATED: 'vouchers_activated',
  VOUCHERS_REPORTING_ACTIVATED: 'voucher_reporting_activated',
  VOUCHERS_REPORTING_EMAILS_LIST: 'voucher_reporting_emails_list',
  VOUCHERS_REPORTING_EMAILS_NEW_VALUE: 'voucher_reporting_emails_new_value',
  VOUCHER_NAME: 'voucher_name',
  VOUCHER_TYPE: 'voucher_type',
  VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION: 'voucher_bag_deposit_service_location',
  VOUCHER_BAG_DEPOSIT_TERMINALS: 'voucher_bag_deposit_terminals',
  VOUCHER_BAG_DEPOSIT_TERMINALS_LIST: 'voucher_bag_deposit_terminals_list',
  VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE: 'voucher_bag_deposit_terminals_new_value',
  VOUCHER_LOUNGE_LOCATION: 'voucher_lounge_location',
  VOUCHER_LOUNGE_TERMINALS: 'voucher_lounge_terminals',
  VOUCHER_LOUNGE_TERMINALS_LIST: 'voucher_lounge_terminals_list',
  VOUCHER_LOUNGE_TERMINALS_NEW_VALUE: 'voucher_lounge_terminals_new_value',
  VOUCHER_DESIGN_TYPE: 'voucher_design_type',
  VOUCHER_CUSTOM_DESIGN_SIZE: 'voucher_custom_design_size',
  VOUCHER_LARGE: 'voucher_large',
  VOUCHER_SMALL: 'voucher_small',
  VOUCHER_CODE_PLACED: 'voucher_code_placed',
  VOUCHER_DISCOUNT_TYPE: 'voucher_discount_type',
  VOUCHER_DISCOUNT_PERCENTAGE: 'voucher_discount_percentage',
  VOUCHER_DISCOUNT_AMOUNT: 'voucher_discount_amount',
  VOUCHER_DISCOUNT_CURRENCY: 'voucher_discount_currency',
  VOUCHER_PLACE_NAME_1: 'voucher_place_name_1',
  VOUCHER_PLACE_NAME_2: 'voucher_place_name_2',
  VOUCHER_PLACE_NAME_3: 'voucher_place_name_3',
  VOUCHER_PLACE_NAME_4: 'voucher_place_name_4',
  VOUCHER_URL: 'voucher_url',
  VOUCHER_CODE_TYPE: 'voucher_code_type',
  VOUCHER_CODE_GENERATION: 'voucher_code_generation',
  VOUCHER_STATIC_KEYWORD: 'voucher_static_keyword',
  VOUCHER_EAN_CODE: 'voucher_ean_code',
  SFAQ_ARTICLE_ID: 'sfaq_article_id',
};

export const designTypeValues = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
};

export const customDesignSizeValues = {
  SMALL: 'small',
  LARGE: 'large',
};

export const newSmartPassInitialValues = {
  [keys.LEGAL_NAME]: '',
  [keys.LEGAL_ADDRESS]: '',
  [keys.VAT_NUMBER]: '',
  [keys.BANK_ACCOUNT_NUMBER]: '',
  [keys.BANK_NAME_AND_ADDRESS]: '',
  [keys.CONTACT_PERSON_1_NAME]: '',
  [keys.CONTACT_PERSON_1_ROLE]: '',
  [keys.CONTACT_PERSON_1_EMAIL]: '',
  [keys.CONTACT_PERSON_1_PHONE]: '',
  [keys.CONTACT_PERSON_2_NAME]: '',
  [keys.CONTACT_PERSON_2_ROLE]: '',
  [keys.CONTACT_PERSON_2_EMAIL]: '',
  [keys.CONTACT_PERSON_2_PHONE]: '',
  [keys.IATA_CODE]: '',
  [keys.AIRPORT_LOGO]: null,
  [keys.SMART_PASS_NAME]: '',
  [keys.SMART_PASS_LOGO]: '',
  [keys.SMART_PASS_PAGE]: '',
  [keys.ILLUSTRATION]: null,
  [keys.ILLUSTRATION_COLOR_1]: '',
  [keys.ILLUSTRATION_COLOR_2]: '',
  [keys.DISPLAYING_CONDITION]: null,
  [keys.DISPLAYING_CONDITION_HOURS]: '',
  [keys.REPORTING_EMAIL_1]: '',
  [keys.REPORTING_EMAIL_2]: '',
  [keys.REPORTING_EMAIL_3]: '',
  [keys.REPORTING_EMAIL_4]: '',
  [keys.AIRPORT_SIGNAGE_1]: '',
  [keys.AIRPORT_SIGNAGE_2]: '',
  [keys.AIRPORT_SIGNAGE_3]: '',
  [keys.TERMINAL_MAPS_1]: '',
  [keys.TERMINAL_MAPS_2]: '',
  [keys.TERMINAL_MAPS_3]: '',
  [keys.FAST_TRACK_ACTIVATED]: false,
  [keys.FAST_TRACK_CONDITION]: null,
  [keys.FAST_TRACK_LAYOVER_HOURS]: '',
  [keys.FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS]: '',
  [keys.FAST_TRACK_ITINERARIES_TYPES]: [],
  [keys.FAST_TRACK_TERMINALS]: null,
  [keys.FAST_TRACK_TERMINALS_LIST]: [],
  [keys.FAST_TRACK_TERMINALS_LIST_NEW_VALUE]: '',
  [keys.FAST_TRACK_VOUCHER_FULFILMENT]: null,
  [keys.FAST_TRACK_VOUCHER_CODE_TYPE]: null,
  [keys.FAST_TRACK_VOUCHER_CODE_GENERATION]: null,
  [keys.FAST_TRACK_STATIC_KEYWORD]: '',
  [keys.FAST_TRACK_STATIC_URL]: '',
  [keys.SFAQ_ARTICLE_ID]: '',
  [keys.FAST_TRACK_REPORT_EMAILS_ACTIVATED]: null,
  [keys.FAST_TRACK_REPORT_EMAILS_LIST]: [],
  [keys.FAST_TRACK_REPORT_EMAILS_NEW_VALUE]: '',
  [keys.FAST_TRACK_DESIGN_TYPE]: designTypeValues.STANDARD,
  [keys.FAST_TRACK_CUSTOM_VOUCHER_DESIGN]: '',
  [keys.TRANSFER_PROCESS]: '',
  [keys.TRANSFER_ASSISTANCE_ACTIVATED]: false,
  [keys.TRANSFER_ASSISTANCE_SERVICE_TYPE]: null,
  [keys.TRANSFER_ASSISTANCE_DESK_TYPE]: [],
  [keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]: null,
  [keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR]: '',
  [keys.TRANSFER_ASSISTANCE_LOCATION]: [],
  [keys.BAG_TRANSFER_ASSISTANCE_ACTIVATED]: false,
  [keys.BAG_TRANSFER_ASSIST_LOCATION]: [],
  [keys.BAG_TRANSFER_ASSIST_TERMINALS]: null,
  [keys.BAG_TRANSFER_ASSIST_TERMINALS_LIST]: [],
  [keys.BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE]: '',
  [keys.BAG_TRANSFER_ASSIST_SERVICE_PROVIDED]: [],
  [keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE]: null,
  [keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST]: [],
  [keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE]: '',
  [keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE]: null,
  [keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST]: [],
  [keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE]: '',
  [keys.VOUCHERS_ACTIVATED]: false,
  [keys.VOUCHERS]: [],
  [keys.VOUCHERS_REPORTING_ACTIVATED]: null,
  [keys.VOUCHERS_REPORTING_EMAILS_LIST]: [],
  [keys.VOUCHERS_REPORTING_EMAILS_NEW_VALUE]: '',
};

export const voucherTypeValues = {
  SHOP: 'shop',
  RESTAURANT: 'restaurant',
  HOTEL: 'hotel',
  BAG_DEPOSIT: 'bag_deposit',
  LOUNGE_ACCESS: 'lounge_access',
  OTHER: 'other',
};

export const voucherFormInitialValues = {
  [keys.VOUCHER_NAME]: '',
  [keys.VOUCHER_TYPE]: voucherTypeValues.SHOP,
  [keys.VOUCHER_DESIGN_TYPE]: null,
  [keys.VOUCHER_CUSTOM_DESIGN_SIZE]: null,
  [keys.VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]: null,
  [keys.VOUCHER_BAG_DEPOSIT_TERMINALS]: null,
  [keys.VOUCHER_BAG_DEPOSIT_TERMINALS_LIST]: [],
  [keys.VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE]: '',
  [keys.VOUCHER_LOUNGE_LOCATION]: [],
  [keys.VOUCHER_LOUNGE_TERMINALS]: null,
  [keys.VOUCHER_LOUNGE_TERMINALS_LIST]: [],
  [keys.VOUCHER_LOUNGE_TERMINALS_NEW_VALUE]: '',
  [keys.VOUCHER_LARGE]: null,
  [keys.VOUCHER_SMALL]: null,
  [keys.VOUCHER_CODE_PLACED]: null,
  [keys.VOUCHER_DISCOUNT_TYPE]: null,
  [keys.VOUCHER_DISCOUNT_PERCENTAGE]: '',
  [keys.VOUCHER_DISCOUNT_AMOUNT]: '',
  [keys.VOUCHER_DISCOUNT_CURRENCY]: null,
  [keys.VOUCHER_PLACE_NAME_1]: '',
  [keys.VOUCHER_PLACE_NAME_2]: '',
  [keys.VOUCHER_PLACE_NAME_3]: '',
  [keys.VOUCHER_PLACE_NAME_4]: '',
  [keys.VOUCHER_URL]: '',
  [keys.VOUCHER_CODE_TYPE]: null,
  [keys.VOUCHER_CODE_GENERATION]: null,
  [keys.VOUCHER_STATIC_KEYWORD]: '',
  [keys.VOUCHER_EAN_CODE]: '',
};

export const generateNewVoucher = values => ({
  id: uuid(),
  ...R.mapObjIndexed(value => value, values),
});

export const illustrationValues = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
  FIFTH: '5',
};

export const displayingConditionValues = {
  NO_CONDITION: 'no_condition',
  CONDITION: 'condition',
};

export const fastTrackConditionValues = {
  NO_CONDITION: 'no_condition',
  CHECKED_BAG_INCLUDED: 'checked_bag_included',
  LAYOVER: 'layover',
  LAYOVER_AND_CHECKED_BAG_INCLUDED: 'layover_and_checked_bag_included',
};

export const fastTrackItinerariesTypesValues = {
  AIR_AIR: 'air-air',
  GROUND_AIR: 'ground-air',
};

export const terminalsValues = {
  ALL_TERMINALS: 'all_terminals',
  SPECIFIC_TERMINALS: 'specific_terminals',
};

export const fastTrackVoucherFullfilmentValues = {
  AUTOMATED: 'automated',
  MANUAL: 'manual',
  WEB: 'WEB',
};

export const dynamicStaticValues = {
  DYNAMIC: 'dynamic',
  STATIC: 'static',
};

export const yesNoValues = {
  YES: 'yes',
  NO: 'no',
};

export const transferAssistanceServiceTypeValues = {
  AIRPORT_DESK: 'airport_desk',
  AIRPORT_STAFF: 'airport_staff',
};

export const transferAssistanceDeskTypeValues = {
  DEDICATED: 'dedicated',
  GENERAL: 'general',
};

export const transferAssistanceAirportStaffLookValues = {
  DEDICATED_UNIFORM: 'dedicated_uniform',
  SERVICE_BADGE_OR_PIN: 'service_badge_or_pin',
  NO_SPECIFIC_LOOK: 'no_specific_look',
};

export const transferAssistanceLocationValues = {
  BAGGAGE_RECLAIM_AREA: 'baggage_reclaim_area',
  ARRIVALS: 'arrivals',
  DEPARTURES: 'departures',
  TRANSIT_AREA: 'transit_area',
};

export const bagTransferAssistLocationValues = {
  BAGGAGE_RECLAIM_AREA: 'baggage_reclaim_area',
  DEPARTURES: 'departures',
};

export const serviceProvidedValues = {
  SELF_BAG_DROP_MACHINES: 'self_bag_drop_machines',
  COUNTER: 'counter',
};

export const carriersTypeValues = {
  ALL_CARRIERS: 'all_carriers',
  SPECIFIC_CARRIERS: 'specific_carriers',
};

export const voucherBagDepositServiceLocationValues = {
  ARRIVAL_AREA: 'arrival_area',
  DEPARTURE_AREA: 'departure_area',
};

export const loungeLocationValues = {
  AIRSIDE: 'airside',
  LANDSIDE: 'landside',
};

export const voucherDiscountTypeValues = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
};

export const voucherCodeTypeValues = {
  QR_CODE: 'QR',
  EAN_13_CODE: 'EAN-13',
  PDF417: 'PDF417',
};

export const fastTrackVoucherCodeTypeValues = {
  QR_CODE: 'QR',
  PDF417: 'PDF417',
  WEB: 'WEB',
};

export const saveTypeValues = {
  DRAFT: 'draft',
  FOR_REVIEW: 'for_review',
};

export const statuses = {
  DRAFT: 'draft',
  APPROVED: 'approved',
  PENDING_REVIEW: 'pending_review',
};

export const statusesBadgeTypes = {
  [statuses.DRAFT]: 'neutral',
  [statuses.APPROVED]: 'success',
  [statuses.PENDING_REVIEW]: 'warning',
};

export const tierTypesValues = {
  STANDARD: 'standard',
};

export const tierTypes = {
  [tierTypesValues.STANDARD]: 'Standard',
};

const digitsOnly = value => /^\d+$/.test(value);

export const VouchersSchema = Yup.object({
  [keys.VOUCHER_TYPE]: Yup.string().required('validation.cant_be_empty').nullable(),
  [keys.VOUCHER_DESIGN_TYPE]: Yup.string()
    .required('validation.cant_be_empty')
    .when([keys.VOUCHER_SMALL, keys.VOUCHER_LARGE], {
      is: (voucher_small, voucher_large) => !voucher_small && !voucher_large,
      then: Yup.string().test(
        'At least one custom voucher',
        'smart_pass.at_least_one_custom_design',
        voucher_design_type => voucher_design_type === designTypeValues.STANDARD,
      ),
    })
    .nullable(),
  [keys.VOUCHER_DISCOUNT_TYPE]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_DESIGN_TYPE, {
      is: voucher_design_type => voucher_design_type === designTypeValues.STANDARD,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_DISCOUNT_PERCENTAGE]: Yup.string().when(keys.VOUCHER_DISCOUNT_TYPE, {
    is: voucher_discount_type => voucher_discount_type === voucherDiscountTypeValues.PERCENTAGE,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('number', 'validation.must_be_numbers_short', digitsOnly)
      .test(
        'number_size',
        'smart_pass.standard_percentage_error',
        value => Number(value) >= 0 && Number(value) <= 100,
      ),
  }),
  [keys.VOUCHER_DISCOUNT_AMOUNT]: Yup.string().when(keys.VOUCHER_DISCOUNT_TYPE, {
    is: voucher_discount_type => voucher_discount_type === voucherDiscountTypeValues.AMOUNT,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('number', 'validation.must_be_numbers_short', digitsOnly),
  }),
  [keys.VOUCHER_DISCOUNT_CURRENCY]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_DISCOUNT_TYPE, {
      is: voucher_discount_type => voucher_discount_type === voucherDiscountTypeValues.AMOUNT,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_PLACE_NAME_1]: Yup.string().when(
    [
      keys.VOUCHER_TYPE,
      keys.VOUCHER_DESIGN_TYPE,
      keys.VOUCHER_PLACE_NAME_2,
      keys.VOUCHER_PLACE_NAME_3,
      keys.VOUCHER_PLACE_NAME_4,
    ],
    {
      is: (
        voucher_type,
        voucher_design_type,
        voucher_place_name_2,
        voucher_place_name_3,
        voucher_place_name_4,
      ) =>
        voucher_design_type === designTypeValues.STANDARD &&
        [
          voucherTypeValues.RESTAURANT,
          voucherTypeValues.SHOP,
          voucherTypeValues.HOTEL,
          voucherTypeValues.LOUNGE_ACCESS,
        ].includes(voucher_type) &&
        !voucher_place_name_2 &&
        !voucher_place_name_3 &&
        !voucher_place_name_4,
      then: Yup.string().required('validation.cant_be_empty'),
    },
  ),
  [keys.VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_TYPE, {
      is: voucher_type => voucher_type === voucherTypeValues.BAG_DEPOSIT,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_BAG_DEPOSIT_TERMINALS]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_TYPE, {
      is: voucher_type => voucher_type === voucherTypeValues.BAG_DEPOSIT,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_BAG_DEPOSIT_TERMINALS_LIST]: Yup.array()
    .nullable()
    .when(keys.VOUCHER_BAG_DEPOSIT_TERMINALS, {
      is: voucher_bag_deposit_terminals =>
        voucher_bag_deposit_terminals === terminalsValues.SPECIFIC_TERMINALS,
      then: Yup.array().of(Yup.string()).required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_LOUNGE_LOCATION]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_TYPE, {
      is: voucher_type => voucher_type === voucherTypeValues.LOUNGE_ACCESS,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_LOUNGE_TERMINALS]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_TYPE, {
      is: voucher_type => voucher_type === voucherTypeValues.LOUNGE_ACCESS,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_LOUNGE_TERMINALS_LIST]: Yup.array()
    .nullable()
    .when(keys.VOUCHER_LOUNGE_TERMINALS, {
      is: voucher_lounge_terminals =>
        voucher_lounge_terminals === terminalsValues.SPECIFIC_TERMINALS,
      then: Yup.array().of(Yup.string()).required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_CODE_PLACED]: Yup.string().required('validation.cant_be_empty').nullable(),
  [keys.VOUCHER_CODE_TYPE]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_CODE_PLACED, {
      is: voucher_code_placed => voucher_code_placed === yesNoValues.YES,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_EAN_CODE]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_CODE_TYPE, {
      is: voucher_code_type => voucher_code_type === voucherCodeTypeValues.EAN_13_CODE,
      then: Yup.string()
        .required('validation.cant_be_empty')
        .test('number', 'validation.must_be_numbers', digitsOnly)
        .length(13, 'smart_pass.ean_code_error'),
    }),
  [keys.VOUCHER_CODE_GENERATION]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_CODE_TYPE, {
      is: voucher_code_type =>
        [voucherCodeTypeValues.QR_CODE, voucherCodeTypeValues.PDF417].includes(voucher_code_type),
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHER_STATIC_KEYWORD]: Yup.string()
    .nullable()
    .when(keys.VOUCHER_CODE_GENERATION, {
      is: voucher_code_generation => voucher_code_generation === dynamicStaticValues.STATIC,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
});

export const SmartPassValidationSchema = Yup.object({
  [keys.CONTACT_PERSON_1_NAME]: Yup.string().required('validation.cant_be_empty'),
  [keys.CONTACT_PERSON_1_PHONE]: Yup.string().required('validation.cant_be_empty'),
  [keys.CONTACT_PERSON_1_EMAIL]: Yup.string()
    .email('validation.invalid_email')
    .required('validation.cant_be_empty'),
  [keys.CONTACT_PERSON_2_EMAIL]: Yup.string().email('validation.invalid_email'),
  [keys.IATA_CODE]: Yup.string().required('validation.cant_be_empty'),
  [keys.AIRPORT_LOGO]: Yup.object().required('validation.cant_be_empty').nullable(),
  [keys.SMART_PASS_NAME]: Yup.string().required('validation.cant_be_empty'),
  [keys.ILLUSTRATION]: Yup.string().required('validation.cant_be_empty').nullable(),
  [keys.DISPLAYING_CONDITION]: Yup.string().required('validation.cant_be_empty').nullable(),
  [keys.DISPLAYING_CONDITION_HOURS]: Yup.string().when(keys.DISPLAYING_CONDITION, {
    is: displaying_condition => displaying_condition === displayingConditionValues.CONDITION,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('number', 'validation.must_be_numbers', digitsOnly),
  }),
  [keys.REPORTING_EMAIL_1]: Yup.string().email('validation.invalid_email'),
  [keys.REPORTING_EMAIL_2]: Yup.string().email('validation.invalid_email'),
  [keys.REPORTING_EMAIL_3]: Yup.string().email('validation.invalid_email'),
  [keys.REPORTING_EMAIL_4]: Yup.string().email('validation.invalid_email'),
  [keys.FAST_TRACK_CONDITION]: Yup.string()
    .nullable()
    .when(keys.FAST_TRACK_ACTIVATED, {
      is: fast_track_activated => fast_track_activated,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_LAYOVER_HOURS]: Yup.string().when(keys.FAST_TRACK_CONDITION, {
    is: fast_track_condition => fast_track_condition === fastTrackConditionValues.LAYOVER,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('number', 'validation.must_be_numbers', digitsOnly),
  }),
  [keys.FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS]: Yup.string().when(keys.FAST_TRACK_CONDITION, {
    is: fast_track_condition =>
      fast_track_condition === fastTrackConditionValues.LAYOVER_AND_CHECKED_BAG_INCLUDED,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('number', 'validation.must_be_numbers', digitsOnly),
  }),
  [keys.FAST_TRACK_ITINERARIES_TYPES]: Yup.array().when(keys.FAST_TRACK_ACTIVATED, {
    is: fast_track_activated => fast_track_activated,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.FAST_TRACK_TERMINALS]: Yup.string()
    .nullable()
    .when(keys.FAST_TRACK_ACTIVATED, {
      is: fast_track_activated => fast_track_activated,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_TERMINALS_LIST]: Yup.array().when(keys.FAST_TRACK_TERMINALS, {
    is: fast_track_terminals => fast_track_terminals === terminalsValues.SPECIFIC_TERMINALS,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.FAST_TRACK_VOUCHER_FULFILMENT]: Yup.string()
    .nullable()
    .when(keys.FAST_TRACK_ACTIVATED, {
      is: fast_track_activated => fast_track_activated,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_VOUCHER_CODE_TYPE]: Yup.string()
    .nullable()
    .when(keys.FAST_TRACK_VOUCHER_FULFILMENT, {
      is: fast_track_voucher_fulfilment =>
        fast_track_voucher_fulfilment === fastTrackVoucherFullfilmentValues.AUTOMATED,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_STATIC_KEYWORD]: Yup.string().when(keys.FAST_TRACK_VOUCHER_FULFILMENT, {
    is: fast_track_voucher_fulfilment =>
      fast_track_voucher_fulfilment === fastTrackVoucherFullfilmentValues.WEB,
    then: Yup.string().min(3).max(25).required('validation.cant_be_empty'),
  }),
  //https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
  [keys.FAST_TRACK_STATIC_URL]: Yup.string()
    .when(keys.FAST_TRACK_VOUCHER_FULFILMENT, {
      is: fast_track_voucher_fulfilment =>
        fast_track_voucher_fulfilment === fastTrackVoucherFullfilmentValues.WEB,
      then: Yup.string()
        .matches(
          /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'validation.must_be_uri',
        )
        .required('validation.cant_be_empty'),
    })
    .when(keys.FAST_TRACK_VOUCHER_CODE_GENERATION, {
      is: code_generation => code_generation === dynamicStaticValues.STATIC,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_VOUCHER_CODE_GENERATION]: Yup.string()
    .nullable()
    .when(keys.FAST_TRACK_VOUCHER_FULFILMENT, {
      is: fast_track_voucher_fulfilment =>
        fast_track_voucher_fulfilment === fastTrackVoucherFullfilmentValues.AUTOMATED,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.FAST_TRACK_REPORT_EMAILS_LIST]: Yup.array().when(keys.FAST_TRACK_REPORT_EMAILS_ACTIVATED, {
    is: fast_track_report_emails_activated =>
      fast_track_report_emails_activated === yesNoValues.YES,
    then: Yup.array()
      .of(Yup.string().email('validation.invalid_email'))
      .required('validation.cant_be_empty'),
  }),
  [keys.TRANSFER_ASSISTANCE_SERVICE_TYPE]: Yup.string()
    .nullable()
    .when(keys.TRANSFER_ASSISTANCE_ACTIVATED, {
      is: transfer_assistance_activated => transfer_assistance_activated,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.TRANSFER_ASSISTANCE_DESK_TYPE]: Yup.array().when(keys.TRANSFER_ASSISTANCE_SERVICE_TYPE, {
    is: transfer_assistance_service_type =>
      transfer_assistance_service_type === transferAssistanceServiceTypeValues.AIRPORT_DESK,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK]: Yup.string()
    .nullable()
    .when(keys.TRANSFER_ASSISTANCE_SERVICE_TYPE, {
      is: transfer_assistance_service_type =>
        transfer_assistance_service_type === transferAssistanceServiceTypeValues.AIRPORT_STAFF,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR]: Yup.string().when(
    keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK,
    {
      is: transfer_assistance_airport_staff_look =>
        transfer_assistance_airport_staff_look ===
        transferAssistanceAirportStaffLookValues.DEDICATED_UNIFORM,
      then: Yup.string().required('validation.cant_be_empty'),
    },
  ),
  [keys.TRANSFER_ASSISTANCE_LOCATION]: Yup.array().when(keys.TRANSFER_ASSISTANCE_ACTIVATED, {
    is: transfer_assistance_activated => transfer_assistance_activated,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.BAG_TRANSFER_ASSIST_LOCATION]: Yup.array().when(keys.BAG_TRANSFER_ASSISTANCE_ACTIVATED, {
    is: bag_transfer_assistance_activated => bag_transfer_assistance_activated,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.BAG_TRANSFER_ASSIST_TERMINALS]: Yup.string()
    .nullable()
    .when(keys.BAG_TRANSFER_ASSISTANCE_ACTIVATED, {
      is: bag_transfer_assistance_activated => bag_transfer_assistance_activated,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.BAG_TRANSFER_ASSIST_TERMINALS_LIST]: Yup.array().when(keys.BAG_TRANSFER_ASSIST_TERMINALS, {
    is: bag_transfer_assist_terminals =>
      bag_transfer_assist_terminals === terminalsValues.SPECIFIC_TERMINALS,
    then: Yup.array().required('validation.cant_be_empty'),
  }),
  [keys.BAG_TRANSFER_ASSIST_SERVICE_PROVIDED]: Yup.array().when(
    keys.BAG_TRANSFER_ASSISTANCE_ACTIVATED,
    {
      is: bag_transfer_assistance_activated => bag_transfer_assistance_activated,
      then: Yup.array().required('validation.cant_be_empty'),
    },
  ),
  [keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE]: Yup.string()
    .nullable()
    .when(keys.BAG_TRANSFER_ASSIST_SERVICE_PROVIDED, {
      is: bag_transfer_assist_service_provided =>
        bag_transfer_assist_service_provided.includes(serviceProvidedValues.SELF_BAG_DROP_MACHINES),
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST]: Yup.string()
    .nullable()
    .when(keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE, {
      is: bag_transfer_assist_sbdm_carriers_type =>
        bag_transfer_assist_sbdm_carriers_type === carriersTypeValues.SPECIFIC_CARRIERS,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE]: Yup.string()
    .nullable()
    .when(keys.BAG_TRANSFER_ASSIST_SERVICE_PROVIDED, {
      is: bag_transfer_assist_service_provided =>
        bag_transfer_assist_service_provided.includes(serviceProvidedValues.COUNTER),
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST]: Yup.string()
    .nullable()
    .when(keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE, {
      is: bag_transfer_assist_counter_carriers_type =>
        bag_transfer_assist_counter_carriers_type === carriersTypeValues.SPECIFIC_CARRIERS,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHERS_REPORTING_ACTIVATED]: Yup.string()
    .nullable()
    .when(keys.VOUCHERS, {
      is: vouchers => vouchers.length > 0,
      then: Yup.string().required('validation.cant_be_empty'),
    }),
  [keys.VOUCHERS_REPORTING_EMAILS_LIST]: Yup.array()
    .nullable()
    .when(keys.VOUCHERS_REPORTING_ACTIVATED, {
      is: vouchers_reporting_activated => vouchers_reporting_activated === yesNoValues.YES,
      then: Yup.array()
        .of(Yup.string().email('validation.invalid_email'))
        .required('validation.cant_be_empty'),
    }),
});

export const previewTypeKeys = {
  STANDARD: 'STANDARD',
  CUSTOM_LARGE: 'CUSTOM_LARGE',
  CUSTOM_SMALL: 'CUSTOM_SMALL',
  CUSTOM_FAST_TRACK: 'CUSTOM_FAST_TRACK',
};

export const containerSizes = {
  [previewTypeKeys.STANDARD]: {
    width: '414px',
    height: '312px',
  },
  [previewTypeKeys.CUSTOM_LARGE]: {
    width: '812px',
    height: '296px',
  },
  [previewTypeKeys.CUSTOM_SMALL]: {
    width: '398px',
    height: '296px;',
  },
  [previewTypeKeys.CUSTOM_FAST_TRACK]: {
    width: '812px',
    height: '168px;',
  },
};

export const legalFormKeys = [
  keys.LEGAL_NAME,
  keys.LEGAL_ADDRESS,
  keys.VAT_NUMBER,
  keys.BANK_ACCOUNT_NUMBER,
  keys.BANK_NAME_AND_ADDRESS,
  keys.CONTACT_PERSON_1_EMAIL,
  keys.CONTACT_PERSON_1_ROLE,
  keys.CONTACT_PERSON_1_NAME,
  keys.CONTACT_PERSON_1_PHONE,
  keys.CONTACT_PERSON_2_EMAIL,
  keys.CONTACT_PERSON_2_NAME,
  keys.CONTACT_PERSON_2_PHONE,
  keys.CONTACT_PERSON_2_ROLE,
];
export const generalFormKeys = [
  keys.IATA_CODE,
  keys.AIRPORT_LOGO,
  keys.SMART_PASS_NAME,
  keys.SMART_PASS_LOGO,
  keys.SMART_PASS_PAGE,
  keys.ILLUSTRATION,
  keys.ILLUSTRATION_COLOR_1,
  keys.ILLUSTRATION_COLOR_2,
  keys.DISPLAYING_CONDITION,
  keys.DISPLAYING_CONDITION_HOURS,
  keys.REPORTING_EMAIL_1,
  keys.REPORTING_EMAIL_2,
  keys.REPORTING_EMAIL_3,
  keys.REPORTING_EMAIL_4,
  keys.AIRPORT_SIGNAGE_1,
  keys.AIRPORT_SIGNAGE_2,
  keys.AIRPORT_SIGNAGE_3,
  keys.TERMINAL_MAPS_1,
  keys.TERMINAL_MAPS_2,
  keys.TERMINAL_MAPS_3,
];

export const fastTrackFormKeys = [
  keys.FAST_TRACK_ACTIVATED,
  keys.FAST_TRACK_CONDITION,
  keys.FAST_TRACK_LAYOVER_HOURS,
  keys.FAST_TRACK_LAYOVER_AND_CHECKED_BAG_HOURS,
  keys.FAST_TRACK_ITINERARIES_TYPES,
  keys.FAST_TRACK_TERMINALS,
  keys.FAST_TRACK_TERMINALS_LIST,
  keys.FAST_TRACK_TERMINALS_LIST_NEW_VALUE,
  keys.FAST_TRACK_VOUCHER_FULFILMENT,
  keys.FAST_TRACK_VOUCHER_CODE_TYPE,
  keys.FAST_TRACK_VOUCHER_CODE_GENERATION,
  keys.FAST_TRACK_STATIC_KEYWORD,
  keys.FAST_TRACK_STATIC_URL,
  keys.FAST_TRACK_REPORT_EMAILS_ACTIVATED,
  keys.FAST_TRACK_REPORT_EMAILS_LIST,
  keys.FAST_TRACK_REPORT_EMAILS_NEW_VALUE,
  keys.FAST_TRACK_CUSTOM_VOUCHER_DESIGN,
  keys.FAST_TRACK_DESIGN_TYPE,
  keys.TRANSFER_PROCESS,
  keys.SFAQ_ARTICLE_ID,
];

export const transferAssistanceFormKeys = [
  keys.TRANSFER_ASSISTANCE_ACTIVATED,
  keys.TRANSFER_ASSISTANCE_SERVICE_TYPE,
  keys.TRANSFER_ASSISTANCE_DESK_TYPE,
  keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_LOOK,
  keys.TRANSFER_ASSISTANCE_AIRPORT_STAFF_UNIFORM_COLOR,
  keys.TRANSFER_ASSISTANCE_LOCATION,
];

export const bagTransferAssistFormKeys = [
  keys.BAG_TRANSFER_ASSISTANCE_ACTIVATED,
  keys.BAG_TRANSFER_ASSIST_LOCATION,
  keys.BAG_TRANSFER_ASSIST_TERMINALS,
  keys.BAG_TRANSFER_ASSIST_TERMINALS_LIST,
  keys.BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE,
  keys.BAG_TRANSFER_ASSIST_SERVICE_PROVIDED,
  keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE,
  keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST,
  keys.BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE,
  keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE,
  keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST,
  keys.BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE,
];

export const vouchersFormKeys = [
  keys.VOUCHERS,
  keys.VOUCHERS_ACTIVATED,
  keys.VOUCHERS_REPORTING_ACTIVATED,
  keys.VOUCHERS_REPORTING_EMAILS_LIST,
  keys.VOUCHERS_REPORTING_EMAILS_NEW_VALUE,
];
