import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { MMBState } from 'components/services/mmb';
import useBookingDisruptions from 'components/services/mmb/useBookingDisruptions';
import { usePolyglot } from 'components/services/i18n';

import CreditCardBooking from './CreditCardBookingDetails';
import DepositBooking from './scenes/DepositBookingDetails';

const BookingDetails = ({ location }) => {
  const { bid } = useParams();
  const { data: disruptions } = useBookingDisruptions();
  const { state, getPartnerToken } = useContext(MMBState);
  const polyglot = usePolyglot();

  useEffect(() => {
    getPartnerToken(bid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  if (!!state.loadingBid) {
    return (
      <Loading type="pageLoader" text={polyglot.t('booking.deposit_booking.loading_booking')} />
    );
  }

  const isPaidWithDeposit = state.bookingsState[bid]?.bookingData.isDeposit;
  const affilid = state.bookingsState[bid]?.affilid;

  if (
    isPaidWithDeposit &&
    !disruptions?.[bid]?.disruptions &&
    !location.state?.forceOld &&
    !state.tokens[bid]?.error
  ) {
    return <DepositBooking />;
  }

  return <CreditCardBooking affilid={affilid} />;
};

export default BookingDetails;
