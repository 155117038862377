import React from 'react';
import PropTypes from 'prop-types';
import loader from 'images/loader.png';

import { StyledImg } from './OrbitLoader.styled';

const OrbitLoader = ({ id, visible, ...props }) => {
  if (!visible) {
    return null;
  }

  return <StyledImg id={id} src={loader} alt="Loader" {...props} />;
};

OrbitLoader.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

export default OrbitLoader;
