import React, { useContext, useCallback } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import { CreateSolutionState } from 'components/scenes/MySolutions/services/CreateSolution';
import { useCurrentUser } from 'components/services/auth';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';
import useResource from 'components/services/resources/useResource';

import { useToggle } from 'utils/hooks';
import { SelectCompany, TermsAndConditionsModal } from 'common';

import {
  SummaryWrapper,
  SummaryTableWrapper,
  CardSectionWrapper,
  MoreInfoWrapper,
} from './index.styled';

const SummaryDescription = React.memo(({ type }) => {
  const polyglot = usePolyglot();

  if (type === 'b2b') {
    return (
      <MoreInfoWrapper>
        <Text weight="bold" spaceAfter="normal">
          {polyglot.t('solutions.b2b_title')}
        </Text>
        <Text>{polyglot.t('solutions.b2b_text1')}</Text>
        <Text>{polyglot.t('solutions.b2b_text2')}</Text>
        <Text spaceAfter="normal">{polyglot.t('solutions.b2b_text3')}</Text>
        <Text type="secondary" size="small">
          {polyglot.t('solutions.b2b_small_text')}
        </Text>
      </MoreInfoWrapper>
    );
  }
  if (type === 'b2b2c') {
    return (
      <MoreInfoWrapper>
        <Text weight="bold" spaceAfter="normal">
          {polyglot.t('solutions.b2b2c_title')}
        </Text>
        <Text>{polyglot.t('solutions.b2b2c_text1')}</Text>
        <Text spaceAfter="normal">{polyglot.t('solutions.b2b2c_text2')}</Text>
        <Text type="secondary" size="small">
          {polyglot.t('solutions.b2b2c_small_text')}
        </Text>
      </MoreInfoWrapper>
    );
  }
  return null;
});

const CardSubsection = React.memo(({ title, description }) => {
  const polyglot = usePolyglot();
  const getDescription = useCallback(() => {
    if (description === 'product' || !description) {
      return polyglot.t('solutions.not_applicable');
    } else if (description === 'credit') {
      return polyglot.t('solutions.credit_card');
    } else if (description === 'deposit') {
      return polyglot.t('solutions.deposit');
    }
    return description;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return (
    <CardSectionWrapper>
      <Text size="large" type="secondary">
        {title}
      </Text>
      <Text>
        {getDescription()}
        {(!description || description === 'product') && (
          <Tooltip
            size="small"
            placement="top"
            content={polyglot.t('solutions.not_applicable_tooltip')}
          >
            <InformationCircleIcon size="small" />
          </Tooltip>
        )}
      </Text>
    </CardSectionWrapper>
  );
});

const Summary = React.memo(() => {
  const {
    selectedSolution,
    selectedSolutionType: selectedType,
    selectedSettlement,
    solutionName,
    currentResource,
    validateSolutionName,
    validationError,
    apiError,
  } = useContext(CreateSolutionState);
  const tncModal = useToggle();

  const polyglot = usePolyglot();
  const resource = useResource(currentResource);
  const { selectedCompany, selectCompany } = useSelectedCompany();
  const user = useCurrentUser();

  const isAdminOrACM = user.role === 'ACL-admin' || user.role === 'ACL-account-manager';

  return (
    <>
      {tncModal.isOn && (
        <TermsAndConditionsModal
          polyglot={polyglot}
          onClose={tncModal.setOff}
          loading={resource.loading}
          content={resource?.data?.compiledContent}
        />
      )}
      <ModalHeader title={<Heading type="title2">{polyglot.t('solutions.summary')} </Heading>} />
      <ModalSection>
        {apiError && (
          <Alert type="critical" spaceAfter="medium">
            {polyglot.t(apiError)}
          </Alert>
        )}
        <SummaryWrapper>
          <InputField
            label={polyglot.t('common.name')}
            placeholder={polyglot.t('solutions.enter_name')}
            value={solutionName}
            spaceAfter="large"
            onChange={e => validateSolutionName(e.target.value)}
            help={polyglot.t('solutions.enter_name_help')}
            error={validationError && polyglot.t(validationError)}
          />

          {isAdminOrACM && (
            <SelectCompany
              onSelect={selectCompany}
              label={polyglot.t('common.select_company')}
              value={selectedCompany}
            />
          )}
        </SummaryWrapper>
        <Text weight="bold" spaceAfter="normal">
          {polyglot.t('solutions.summary_details')}
        </Text>
        <SummaryTableWrapper>
          <CardSubsection
            title={polyglot.t('solutions.solution_type')}
            description={polyglot.t(selectedSolution.display_name)}
          />
          {selectedSolution.name === 'meta_search' && (
            <CardSubsection
              title={polyglot.t('solutions.search_type')}
              description={polyglot.t(selectedType.name)}
            />
          )}
          <CardSubsection
            title={polyglot.t('solutions.settlement_option')}
            description={selectedSettlement}
          />
        </SummaryTableWrapper>
        <SummaryDescription type={selectedSolution.type} />
        <Text>
          {polyglot.t('solutions.terms_and_conditions_pre')}
          <TextLink type="secondary" external={false} onClick={tncModal.setOn}>
            {polyglot.t('solutions.terms_and_conditions')}
          </TextLink>
        </Text>
      </ModalSection>
    </>
  );
});

export default Summary;
