import React from 'react';

import Badge from '@kiwicom/orbit-components/lib/Badge';
import TipsIcon from '@kiwicom/orbit-components/lib/icons/Tips';
import DocumentIcon from '@kiwicom/orbit-components/lib/icons/Document';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import AlertIcon from '@kiwicom/orbit-components/lib/icons/Alert';
import ReloadIcon from '@kiwicom/orbit-components/lib/icons/Reload';

import usePolyglot from 'components/services/i18n/usePolyglot';

const BillingStatus = React.memo(({ status }) => {
  const polyglot = usePolyglot();
  switch (status) {
    case 'new':
      return (
        <Badge type="info" icon={<TipsIcon />}>
          {polyglot.t('billing.status.new')}
        </Badge>
      );
    case 'processing':
      return (
        <Badge type="info" icon={<ReloadIcon />}>
          {polyglot.t('billing.status.processing')}
        </Badge>
      );
    case 'review':
      return (
        <Badge type="warning" icon={<DocumentIcon />}>
          {polyglot.t('billing.status.review')}
        </Badge>
      );
    case 'approved':
      return (
        <Badge type="success" icon={<CheckCircleIcon />}>
          {polyglot.t('billing.status.approved')}
        </Badge>
      );
    case 'failed':
      return (
        <Badge type="critical" icon={<AlertIcon />}>
          {polyglot.t('billing.status.failed')}
        </Badge>
      );
    case 'deleted':
      return <Badge type="neutral">{polyglot.t('billing.status.deleted')}</Badge>;

    default:
      return <Badge type="neutral">{polyglot.t('billing.status.unknown')}</Badge>;
  }
});

export default BillingStatus;
