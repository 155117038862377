import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Box from '@kiwicom/orbit-components/lib/Box';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import { usePolyglot } from 'components/services/i18n';
import { useCurrency } from 'components/services/currencies';
import useSeating from 'components/services/bookingToolSeating/useSeating';
import { getSeatingTotalSum } from 'utils/seating';

const SummarySection = ({ closeDrawer, openCloseModal }) => {
  const polyglot = usePolyglot();
  const { getFormattedPriceString } = useCurrency();
  const { currentSeatings, seatings, saveSeatings } = useSeating();
  const {
    state: { currency },
  } = useLocation();

  const handleOnAddSeating = useCallback(() => {
    saveSeatings();
    closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box padding={{ right: 'large', left: 'large' }} display="flex" direction="column">
      <Stack direction="row" align="center" justify="between" spaceAfter="large">
        <Inline spacing="XSmall">
          <Text weight="bold" size="large">
            {polyglot.t('booking_tool.seating.drawer.total', {
              currency,
            })}
          </Text>
        </Inline>
        <Heading type="title2">
          {getFormattedPriceString(getSeatingTotalSum(currentSeatings))}
        </Heading>
      </Stack>
      <Separator spaceAfter="large" />
      <Stack direction="row" align="center" justify="between">
        <Button
          type="secondary"
          onClick={
            JSON.stringify(seatings) === JSON.stringify(currentSeatings)
              ? () => closeDrawer()
              : () => openCloseModal()
          }
        >
          {polyglot.t('booking_tool.seating.drawer.cancel.button')}
        </Button>
        <Button
          type="primary"
          disabled={JSON.stringify(seatings) === JSON.stringify(currentSeatings)}
          onClick={handleOnAddSeating}
        >
          {polyglot.t('booking_tool.seating.drawer.add_seating.button')}
        </Button>
      </Stack>
    </Box>
  );
};

export default SummarySection;
