import getTokens from '@kiwicom/orbit-components/lib/getTokens';

const orbit = getTokens({
  base: {
    fontFamily:
      '"Circular Pro", -apple-system, ".SFNSText-Regular", "San Francisco", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
  },
});

const theme = {
  orbit: { ...orbit, widthModalLarge: '860px' },
  colors: {
    chip: '#dbe0e4',
    navigationSecondary: '#e5f6f4',
    navigationBorder: 'rgb(232, 237, 241)',
    tabNavigation: '#017f6d',
  },
  sizes: {
    navigationWidth: 60,
    navigationExpandedWidth: 260,
    secondaryNavigationExpandedWidth: 220,
  },
};

export default theme;
