import React from 'react';
import styled from 'styled-components';
import { useToggle } from 'utils/hooks';
import { getDateRange } from 'utils/dates';

import InputField from '@kiwicom/orbit-components/lib/InputField';

import { usePolyglot } from 'components/services/i18n';

import DateFilterModal from './DateFilterModal';

const SelectWrapper = styled.div`
  width: 215px;
  & > label > div > input {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
`;

const DateFilter = ({ selectedDate, loading, onClick }) => {
  const polyglot = usePolyglot();
  const modalOpen = useToggle(false);

  return (
    <SelectWrapper>
      <InputField
        placeholder={polyglot.t('billing.filter_by_date.placeholder')}
        size="small"
        label={polyglot.t('billing.filter_by_date.label')}
        onFocus={modalOpen.setOn}
        type="text"
        readOnly
        value={
          !!selectedDate.beginDate && !!selectedDate.endDate
            ? getDateRange([selectedDate.beginDate, selectedDate.endDate])
            : ''
        }
        disabled={loading}
      />
      {modalOpen.isOn && (
        <DateFilterModal selectedDate={selectedDate} onClick={onClick} onClose={modalOpen.setOff} />
      )}
    </SelectWrapper>
  );
};

export default DateFilter;
