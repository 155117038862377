import styled from 'styled-components';

export const RouteGroupWrapper = styled.div`
  & > div {
    margin-bottom: 16px;
    border-radius: 3px;
    border: solid 1px #e8edf1;
    padding: 20px 16px;
    background: ${({ selected, theme }) => (selected ? theme.orbit.backgroundBody : 'white')};
    border-right: ${({ selected, theme }) =>
      selected ? '0px' : `1px solid ${theme.orbit.backgroundButtonSecondary}`};
    width: ${({ selected }) => (selected ? '438px' : 'auto')};
    transition: width 2s linear 1s;
    &: hover {
      background: ${({ theme }) => theme.orbit.backgroundBody};
      cursor: pointer;
      transition: width 2s linear 1s;
    }
  }
`;

export const CarrierLogoWrapper = styled.div`
  margin-right: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SelectedSeatsWrapper = styled.div`
  margin-left: 38px;
`;
