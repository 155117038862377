import React from 'react';
import { FieldArray } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Tag from '@kiwicom/orbit-components/lib/Tag';

import { usePolyglot } from 'components/services/i18n';
import { propsComparison } from 'utils/propsComparison';
import {
  keys,
  bagTransferAssistLocationValues,
  terminalsValues,
  serviceProvidedValues,
  carriersTypeValues,
  bagTransferAssistFormKeys,
} from 'consts/smartPass';

import { LabelsRowWrapper } from './index.styled';
import ActivationButton from './ActivationButton';

const {
  BAG_TRANSFER_ASSISTANCE_ACTIVATED,
  BAG_TRANSFER_ASSIST_LOCATION,
  BAG_TRANSFER_ASSIST_TERMINALS,
  BAG_TRANSFER_ASSIST_TERMINALS_LIST,
  BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE,
  BAG_TRANSFER_ASSIST_SERVICE_PROVIDED,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST,
  BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST,
  BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE,
} = keys;

const BagTransferAssist = ({ values, handleChange, setFieldValue, disabled, errors, touched }) => {
  const polyglot = usePolyglot();

  const handleAddToList = (push, newValueName) => async event => {
    if (event.keyCode === 13) {
      await push(values[newValueName]);
      setFieldValue(newValueName, '');
    }
  };

  return (
    <Card
      spaceAfter="largest"
      header={
        <Stack direction="row" justify="between" align="center">
          <Heading as="h2" type="title3">
            {polyglot.t('smart_pass.form.bag_transfer_assist.header_title')}
          </Heading>
          <ActivationButton
            formKeys={bagTransferAssistFormKeys}
            isActivatedKey={BAG_TRANSFER_ASSISTANCE_ACTIVATED}
            name={polyglot.t('smart_pass.form.bag_transfer_assist.header_title')}
            values={values}
            disabled={disabled}
            setFieldValue={setFieldValue}
          />
        </Stack>
      }
    >
      {values[BAG_TRANSFER_ASSISTANCE_ACTIVATED] && (
        <CardSection>
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.bag_transfer_assist.service_location')}
          </Text>
          <LabelsRowWrapper>
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.bag_transfer_assist.baggage_reclaim_area')}
              name={BAG_TRANSFER_ASSIST_LOCATION}
              value={bagTransferAssistLocationValues.BAGGAGE_RECLAIM_AREA}
              checked={values[BAG_TRANSFER_ASSIST_LOCATION].includes(
                bagTransferAssistLocationValues.BAGGAGE_RECLAIM_AREA,
              )}
              hasError={
                touched[BAG_TRANSFER_ASSIST_LOCATION] && errors[BAG_TRANSFER_ASSIST_LOCATION]
              }
              onChange={handleChange}
            />
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.bag_transfer_assist.departures')}
              name={BAG_TRANSFER_ASSIST_LOCATION}
              hasError={
                touched[BAG_TRANSFER_ASSIST_LOCATION] && errors[BAG_TRANSFER_ASSIST_LOCATION]
              }
              value={bagTransferAssistLocationValues.DEPARTURES}
              checked={values[BAG_TRANSFER_ASSIST_LOCATION].includes(
                bagTransferAssistLocationValues.DEPARTURES,
              )}
              onChange={handleChange}
            />
          </LabelsRowWrapper>
          <Separator spaceAfter="medium" />
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.bag_transfer_assist.terminals')}
          </Text>
          <Stack spaceAfter="large">
            <Radio
              disabled={disabled}
              label={polyglot.t('smart_pass.form.all_terminals')}
              name={BAG_TRANSFER_ASSIST_TERMINALS}
              hasError={
                touched[BAG_TRANSFER_ASSIST_TERMINALS] && errors[BAG_TRANSFER_ASSIST_TERMINALS]
              }
              checked={values[BAG_TRANSFER_ASSIST_TERMINALS] === terminalsValues.ALL_TERMINALS}
              value={terminalsValues.ALL_TERMINALS}
              onChange={handleChange}
            />
            <LabelsRowWrapper labelWidth="200px">
              <Radio
                disabled={disabled}
                name={BAG_TRANSFER_ASSIST_TERMINALS}
                checked={
                  values[BAG_TRANSFER_ASSIST_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS
                }
                hasError={
                  touched[BAG_TRANSFER_ASSIST_TERMINALS] && errors[BAG_TRANSFER_ASSIST_TERMINALS]
                }
                value={terminalsValues.SPECIFIC_TERMINALS}
                onChange={handleChange}
                label={polyglot.t('smart_pass.form.specific_terminals')}
              />
              <FieldArray name={BAG_TRANSFER_ASSIST_TERMINALS_LIST}>
                {({ remove, push }) => (
                  <InputField
                    disabled={
                      disabled ||
                      values[BAG_TRANSFER_ASSIST_TERMINALS] !== terminalsValues.SPECIFIC_TERMINALS
                    }
                    name={BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE}
                    value={values[BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE]}
                    onChange={handleChange}
                    inlineLabel
                    error={
                      errors[BAG_TRANSFER_ASSIST_TERMINALS_LIST] &&
                      polyglot.t(errors[BAG_TRANSFER_ASSIST_TERMINALS_LIST])
                    }
                    onKeyUp={handleAddToList(push, BAG_TRANSFER_ASSIST_TERMINALS_LIST_NEW_VALUE)}
                    label={polyglot.t('smart_pass.form.specific_terminals_label')}
                    placeholder={polyglot.t('smart_pass.form.press_enter_to_add_terminal')}
                    tags={
                      values[BAG_TRANSFER_ASSIST_TERMINALS_LIST].length > 0
                        ? values[BAG_TRANSFER_ASSIST_TERMINALS_LIST].map((tag, index) => (
                            <Tag
                              key={index}
                              selected
                              onRemove={disabled ? undefined : () => remove(index)}
                            >
                              {tag}
                            </Tag>
                          ))
                        : undefined
                    }
                  />
                )}
              </FieldArray>
            </LabelsRowWrapper>
          </Stack>
          <Separator spaceAfter="medium" />
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.bag_transfer_assist.service_provided')}
          </Text>
          <LabelsRowWrapper>
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.bag_transfer_assist.self_bag_drop_machines')}
              name={BAG_TRANSFER_ASSIST_SERVICE_PROVIDED}
              value={serviceProvidedValues.SELF_BAG_DROP_MACHINES}
              checked={values[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED].includes(
                serviceProvidedValues.SELF_BAG_DROP_MACHINES,
              )}
              hasError={
                touched[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED] &&
                errors[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED]
              }
              onChange={handleChange}
            />
            <Checkbox
              disabled={disabled}
              label={polyglot.t('smart_pass.form.bag_transfer_assist.counter')}
              name={BAG_TRANSFER_ASSIST_SERVICE_PROVIDED}
              hasError={
                touched[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED] &&
                errors[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED]
              }
              value={serviceProvidedValues.COUNTER}
              checked={values[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED].includes(
                serviceProvidedValues.COUNTER,
              )}
              onChange={handleChange}
            />
          </LabelsRowWrapper>
          {values[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED].includes(
            serviceProvidedValues.SELF_BAG_DROP_MACHINES,
          ) && (
            <>
              <Text spaceAfter="small">
                {polyglot.t('smart_pass.form.sbdm_carriers_service_available')}
              </Text>
              <Stack spaceAfter="large">
                <Radio
                  disabled={disabled}
                  label={polyglot.t('smart_pass.form.carriers_service_available_all')}
                  name={BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE}
                  hasError={
                    touched[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE] &&
                    errors[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE]
                  }
                  checked={
                    values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE] ===
                    carriersTypeValues.ALL_CARRIERS
                  }
                  value={carriersTypeValues.ALL_CARRIERS}
                  onChange={handleChange}
                />
                <LabelsRowWrapper labelWidth="180px">
                  <Radio
                    disabled={disabled}
                    name={BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE}
                    hasError={
                      touched[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE] &&
                      errors[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE]
                    }
                    checked={
                      values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE] ===
                      carriersTypeValues.SPECIFIC_CARRIERS
                    }
                    value={carriersTypeValues.SPECIFIC_CARRIERS}
                    onChange={handleChange}
                    label={polyglot.t('smart_pass.form.carriers_service_available_specific')}
                  />

                  <FieldArray name={BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST}>
                    {({ remove, push }) => (
                      <InputField
                        disabled={
                          disabled ||
                          values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE] !==
                            carriersTypeValues.SPECIFIC_CARRIERS
                        }
                        name={BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE}
                        placeholder={polyglot.t('smart_pass.form.press_enter_to_add_carrier')}
                        value={values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE]}
                        onChange={handleChange}
                        inlineLabel
                        error={
                          errors[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST] &&
                          polyglot.t(errors[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST])
                        }
                        onKeyUp={handleAddToList(
                          push,
                          BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST_NEW_VALUE,
                        )}
                        label={polyglot.t(
                          'smart_pass.form.carriers_service_available_specific_label',
                        )}
                        tags={
                          values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST].length > 0
                            ? values[BAG_TRANSFER_ASSIST_SBDM_CARRIERS_TYPE_LIST].map(
                                (tag, index) => (
                                  <Tag
                                    key={index}
                                    selected
                                    onRemove={disabled ? undefined : () => remove(index)}
                                  >
                                    {tag}
                                  </Tag>
                                ),
                              )
                            : undefined
                        }
                      />
                    )}
                  </FieldArray>
                </LabelsRowWrapper>
              </Stack>
            </>
          )}
          {values[BAG_TRANSFER_ASSIST_SERVICE_PROVIDED].includes(serviceProvidedValues.COUNTER) && (
            <>
              <Text spaceAfter="small">
                {polyglot.t('smart_pass.form.counter_carriers_service_available')}
              </Text>
              <Stack spaceAfter="large">
                <Radio
                  disabled={disabled}
                  label={polyglot.t('smart_pass.form.carriers_service_available_all')}
                  name={BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE}
                  hasError={
                    touched[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE] &&
                    errors[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE]
                  }
                  checked={
                    values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE] ===
                    carriersTypeValues.ALL_CARRIERS
                  }
                  value={carriersTypeValues.ALL_CARRIERS}
                  onChange={handleChange}
                />
                <LabelsRowWrapper labelWidth="180px">
                  <Radio
                    disabled={disabled}
                    name={BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE}
                    hasError={
                      touched[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE] &&
                      errors[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE]
                    }
                    checked={
                      values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE] ===
                      carriersTypeValues.SPECIFIC_CARRIERS
                    }
                    value={carriersTypeValues.SPECIFIC_CARRIERS}
                    onChange={handleChange}
                    label={polyglot.t('smart_pass.form.carriers_service_available_specific')}
                  />

                  <FieldArray name={BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST}>
                    {({ remove, push }) => (
                      <InputField
                        disabled={
                          disabled ||
                          values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE] !==
                            carriersTypeValues.SPECIFIC_CARRIERS
                        }
                        name={BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE}
                        placeholder={polyglot.t('smart_pass.form.press_enter_to_add_carrier')}
                        value={values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE]}
                        onChange={handleChange}
                        inlineLabel
                        error={
                          errors[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST] &&
                          polyglot.t(errors[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST])
                        }
                        onKeyUp={handleAddToList(
                          push,
                          BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST_NEW_VALUE,
                        )}
                        label={polyglot.t(
                          'smart_pass.form.carriers_service_available_specific_label',
                        )}
                        tags={
                          values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST].length > 0
                            ? values[BAG_TRANSFER_ASSIST_COUNTER_CARRIERS_TYPE_LIST].map(
                                (tag, index) => (
                                  <Tag
                                    key={index}
                                    selected
                                    onRemove={disabled ? undefined : () => remove(index)}
                                  >
                                    {tag}
                                  </Tag>
                                ),
                              )
                            : undefined
                        }
                      />
                    )}
                  </FieldArray>
                </LabelsRowWrapper>
              </Stack>
            </>
          )}
        </CardSection>
      )}
    </Card>
  );
};

export default React.memo(BagTransferAssist, propsComparison(bagTransferAssistFormKeys));
