import format from 'date-fns/format';

const v1_get_from = res => {
  return {
    id: res.id,
    companyName: res.company_name,
    createdAt: format(new Date(res.created_at), 'yyyy-MM-dd'),
    updatedAt: format(new Date(res.updated_at), 'yyyy-MM-dd'),
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      from: v1_get_from,
    },
  },
};
