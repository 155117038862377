import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { SORTABLE_PARAMETERS } from 'consts/reports';
import { FIRST_PAGE } from 'consts/pagination';
import { OrbitLoader, Pagination, SortArrow } from 'common';
import * as reportsActions from 'redux/modules/reports';
import { getPolyglot } from 'redux/selectors/i18n';

import ReportCompareData from './ReportCompareData';
import ReportDataRows from './ReportDataRows';
import * as stringUtils from 'utils/string';

import { formatReportValues } from 'pages/Reports/utils';

import { LoaderContainer, NoDataContainer, SortableTableCell } from './ReportData.styled';
import { StyledTableFoot, TotalValues } from './ReportDataRows.styled';

const sortedValue = (ascending, value) => (ascending ? value : `-${value}`);

class ReportData extends React.Component {
  state = {
    sorted: null,
    ascending: false,
  };

  handleChangePage = (rowsPerPage, nextPage) => {
    const { reportAliases, loadReport } = this.props;
    const pagination = { page: nextPage, perPage: rowsPerPage };

    loadReport(reportAliases.reportAlias.id, pagination);
  };

  handleSort = value => {
    const { reportAliases, loadReport } = this.props;
    this.setState(
      state => ({
        sorted: value,
        ascending: !state.ascending,
      }),
      () => {
        const { ascending, sorted } = this.state;
        loadReport(reportAliases.reportAlias.id, {
          sort: sortedValue(ascending, sorted),
          ...FIRST_PAGE,
        });
      },
    );
  };

  render() {
    const { report, reportAliases, polyglot } = this.props;
    const { sorted, ascending } = this.state;
    if (report.loading || reportAliases.loading || reportAliases.submitting) {
      return (
        <LoaderContainer>
          <OrbitLoader id="dashboard-loader" visible={true} />
        </LoaderContainer>
      );
    }

    if (!report.data || (report.data && report.data.rows.length === 0)) {
      return (
        <NoDataContainer>
          <Illustration name="Offline" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('common.no_data')}
          </Text>
        </NoDataContainer>
      );
    }

    if (report.data.isCompare) {
      return <ReportCompareData report={report.data} />;
    }

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {report.data.metrics.map(key =>
                R.find(R.equals(key), SORTABLE_PARAMETERS) ? (
                  <SortableTableCell key={key} onClick={() => this.handleSort(key)}>
                    {stringUtils.capitalize(key)}
                    {sorted === key && <SortArrow ascending={ascending} />}
                  </SortableTableCell>
                ) : (
                  <TableCell key={key} align="left">
                    {stringUtils.capitalize(key)}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <ReportDataRows report={report.data} polyglot={polyglot} />
          </TableBody>
          <StyledTableFoot key="results-row">
            <TableRow>
              {report.data.metrics.map((key, index) => (
                <TableCell key={index}>
                  {index === 0 && <TotalValues>{polyglot.t('common.total_values')}</TotalValues>}
                  {report.data.totals[key] && formatReportValues(key, report.data.totals[key])}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableFoot>
        </Table>
        <Pagination
          rows={report.pagination.totalCount}
          rowsPerPage={report.pagination.perPage}
          page={report.pagination.page}
          rowsPerPageItems={[5, 10, 15, 20]}
          onPagination={this.handleChangePage}
        />
      </div>
    );
  }
}

ReportData.propTypes = {
  report: PropTypes.object.isRequired,
  reportAliases: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  report: state.reports,
  reportAliases: state.reportAliases,
  reportAlias: state.reportAliases.reportAlias,
  polyglot: getPolyglot(state),
});

const mapDispatchToProps = {
  loadReport: reportsActions.loadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportData);
