import React, { useState } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Box from '@kiwicom/orbit-components/lib/Box';
import AlertCircle from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import { usePolyglot } from 'components/services/i18n';
import { ExpandedDescriptionWrapper } from './index.styled';

const SeatPopoverAlert = () => {
  const polyglot = usePolyglot();
  const [expanded, setExpanded] = useState(false);

  return (
    <Box padding={{ right: 'medium' }}>
      <Alert icon={<AlertCircle size="small" />}>
        <Stack spacing="small">
          <Text>
            <Text size="small">
              {polyglot.t('booking.additional_services.seating.popover.emergency_paragraph')}
            </Text>
            {!expanded && (
              <TextLink onClick={() => setExpanded(isExpanded => !isExpanded)}>
                {polyglot.t('booking.additional_services.seating.popover.show_requirements')}
              </TextLink>
            )}
          </Text>
          {expanded && (
            <ExpandedDescriptionWrapper>
              <Stack spacing="none">
                <Text weight="bold" spaceAfter="none">
                  {polyglot.t('booking.additional_services.seating.popover.select_seat_if')}
                </Text>
                <List>
                  <ListItem>
                    {polyglot.t(
                      'booking.additional_services.seating.popover.requirements.physically_and_mentally_fit',
                    )}
                  </ListItem>
                  <ListItem>
                    {polyglot.t(
                      'booking.additional_services.seating.popover.requirements.at_least_fifteen_years_old',
                    )}
                  </ListItem>
                  <ListItem>
                    {polyglot.t(
                      'booking.additional_services.seating.popover.requirements.capable_of_understanding',
                    )}
                  </ListItem>
                  <ListItem>
                    {polyglot.t(
                      'booking.additional_services.seating.popover.requirements.not_pregnant',
                    )}
                  </ListItem>
                  <ListItem>
                    {polyglot.t(
                      'booking.additional_services.seating.popover.requirements.not_traveling_with_infant',
                    )}
                  </ListItem>
                </List>
              </Stack>
              <TextLink onClick={() => setExpanded(isExpanded => !isExpanded)}>
                {polyglot.t('booking.additional_services.seating.popover.hide_requirements')}
              </TextLink>
            </ExpandedDescriptionWrapper>
          )}
        </Stack>
      </Alert>
    </Box>
  );
};

export default SeatPopoverAlert;
