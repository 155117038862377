import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import CalendarIcon from '@kiwicom/orbit-components/lib/icons/Calendar';

import { CompareCalendar, Space } from 'common';

import Polyglot from 'shapes/Polyglot';
import { getPolyglot } from 'redux/selectors/i18n';
import * as dateUtils from 'utils/dates';
import * as dateConstants from 'consts/dates';
import { StyledInputButton } from './ReportDates.styled';

class ReportDates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canCompare: !R.isEmpty(props.datesCompare),
      isVisible: false,
      dates: props.dates,
      datesCompare: props.datesCompare,
      compareCalendar: false,
      compareTo: dateConstants.CUSTOM,
    };
  }

  handleShowCalendar = () => {
    this.setState({ isVisible: true, compareCalendar: false });
  };

  handleShowCalendarCompare = () => {
    this.setState({ isVisible: true, compareCalendar: true });
  };

  handleHide = () => {
    this.setState({ isVisible: false });
  };

  handleApply = (dates, datesCompare, rangeInterval, isCompare) => {
    const { onChangeAlias } = this.props;
    const { canCompare, compareTo } = this.state;
    if (isCompare) {
      this.setState({ datesCompare });
      this.props.onChangeAlias({
        dateRangeToCompare: datesCompare,
      });
    } else {
      this.setState({ dates });
      let comparePeriod = null;
      if (canCompare && compareTo === dateConstants.PREV_PERIOD) {
        comparePeriod = dateUtils.getPreviousPeriod(dates);
        this.setState({
          datesCompare: comparePeriod,
        });
      }
      if (canCompare && compareTo === dateConstants.PREV_YEAR) {
        comparePeriod = dateUtils.getPreviousYear(dates);
        this.setState({
          datesCompare: comparePeriod,
        });
      }
      onChangeAlias({
        dateRange: dates,
        ...(datesCompare ? { dateRangeToCompare: datesCompare } : {}),
      });
    }
    this.handleHide();
  };

  handleChangeCheckbbox = event => {
    const { checked: canCompare } = event.target;

    if (canCompare) {
      this.setState({ canCompare });
      this.handleShowCalendarCompare();
    } else {
      this.setState({ datesCompare: [], canCompare });
      this.props.onChangeAlias({
        dateRange: this.state.dates,
        dateRangeToCompare: [],
      });
    }
  };

  changeCompareTo = compareTo => {
    this.setState({ compareTo });
  };

  render() {
    const { isVisible, canCompare, compareCalendar, dates, datesCompare, compareTo } = this.state;
    const { polyglot } = this.props;

    return (
      <>
        <Space right="m">
          <StyledInputButton
            label={polyglot.t('common.date_range')}
            id="reports-period-compare"
            onClick={this.handleShowCalendar}
            value={dateUtils.getDateRange(dates)}
            icon={<CalendarIcon customColor="#bac7d5" />}
          />
        </Space>
        <div>
          <Checkbox
            id="reports-period-checkbox"
            htmlFor="reports-period"
            label={polyglot.t('common.compare_to')}
            onChange={this.handleChangeCheckbbox}
            checked={canCompare}
          />
          <Space before="s">
            <StyledInputButton
              id="reports-period"
              onClick={this.handleShowCalendarCompare}
              value={dateUtils.getDateRange(datesCompare, polyglot.t('common.custom'))}
              icon={<CalendarIcon customColor="#bac7d5" />}
              disabled={!canCompare}
            />
          </Space>
        </div>
        {isVisible && (
          <CompareCalendar
            onHide={this.handleHide}
            onApply={this.handleApply}
            onCancel={this.handleHide}
            dates={dates}
            datesToCompare={datesCompare}
            canCompare={compareCalendar}
            compareTo={compareTo}
            changeCompareTo={this.changeCompareTo}
          />
        )}
      </>
    );
  }
}

ReportDates.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  datesCompares: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChangeAlias: PropTypes.func.isRequired,
  // redux props
  polyglot: PropTypes.shape(Polyglot),
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

export default connect(mapStateToProps)(ReportDates);
