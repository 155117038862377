import { createSelector } from 'reselect';

import { addTraits } from 'redux/modules/apigee/requests';

import { categoriesResponseToFilterOptions } from './jira';

export const getCustomer = state => addTraits(state.apigeeSupport.customer);

export const getRequests = state => addTraits(state.apigeeSupport.requests);

export const getRequestsCategories = state => addTraits(state.apigeeSupport.requestsCategories);

const SUPPORT_MODAL_CATEGORY = 'tequila';

export const getSupportModalRequestCategory = createSelector(
  getRequestsCategories,
  categoriesResponse => {
    if (categoriesResponse.isValid()) {
      return categoriesResponse.requestCategories.find(type =>
        type.name.toLowerCase().includes(SUPPORT_MODAL_CATEGORY),
      );
    }
    return null;
  },
);

export const getFirstRequestTypeId = createSelector(
  getSupportModalRequestCategory,
  category => category && category.requestTypes[0].id,
);

export const getRequestTypeFilterOptions = createSelector(
  getRequestsCategories,
  categoriesResponseToFilterOptions,
);

export const isModalVisible = state => state.apigeeSupport.supportModal.isModalVisible;
