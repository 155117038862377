import React from 'react';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

const ErrorSeatingOffers = ({ title, description, buttonText }) => {
  return (
    <Stack justify="center" align="center" direction="column">
      <Illustration name="Error" />
      <Text weight="bold">{title}</Text>
      <Text>{description}</Text>
      <Button onClick={() => document.location.reload()}>{buttonText}</Button>
    </Stack>
  );
};

export default ErrorSeatingOffers;
