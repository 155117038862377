import React from 'react';
import styled from 'styled-components';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';

import { BILLING_STATUSES } from 'consts/billing';
import { ClickOutside } from 'common';

import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';

const SelectWrapper = styled.div`
  position: relative;
  & > div > label > div > input {
    cursor: pointer;
  }
`;

const PopoverContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  position: absolute;
  width: calc(100% - 18px);
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-top: 0;
  background-color: white;
  margin-top: -2px;
  border-bottom-left-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  border-bottom-right-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  z-index: 5;
  padding: 8px;
`;
const CheckboxWrapper = styled.div`
  margin-bottom: 12px;
`;

const StatusFilter = ({ selectedStatuses, loading, onChange }) => {
  const menuOpen = useToggle(false);
  const polyglot = usePolyglot();
  return (
    <SelectWrapper>
      <ClickOutside onClick={menuOpen.setOff}>
        <InputField
          placeholder={polyglot.t('billing.status')}
          size="small"
          label={polyglot.t('billing.filter.status.label')}
          onFocus={menuOpen.setOn}
          suffix={
            <ButtonLink
              compact
              iconLeft={<ChevronDownIcon />}
              onClick={menuOpen.toggle}
              size="small"
              type="primary"
            />
          }
          type="text"
          readOnly
        />
        {menuOpen.isOn && (
          <PopoverContainer>
            {BILLING_STATUSES.map(status => {
              const checked = selectedStatuses.includes(status.value);
              return (
                <CheckboxWrapper key={status.value}>
                  <Checkbox
                    disabled={loading}
                    readOnly={loading}
                    name={status.value}
                    value={status.value}
                    checked={checked}
                    label={polyglot.t(status.label)}
                    onChange={e => onChange(e.target.value, !checked)}
                  />
                </CheckboxWrapper>
              );
            })}
          </PopoverContainer>
        )}
      </ClickOutside>
    </SelectWrapper>
  );
};

export default StatusFilter;
