import React from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';

import { extractDuplicates, canRemovePassenger } from './../utils';

const ActionButtons = ({
  onAddPassenger,
  onClearPassenger,
  onDeletePassenger,
  passengers,
  index,
  paxCategories,
}) => {
  const { adult, infant } = extractDuplicates(paxCategories);
  const polyglot = usePolyglot();
  return (
    <Stack grow direction="row" justify="between" align="center">
      <Stack align="start" shrink>
        <Button type="secondary" size="small" onClick={onClearPassenger} dataTest="clear-input">
          {polyglot.t('booking.deposit_booking.passengers_and_bags.action_buttons.clear_input')}
        </Button>
        {canRemovePassenger(passengers?.[index]?.category, index, adult, infant) && (
          <Button
            type="criticalSubtle"
            size="small"
            onClick={onDeletePassenger}
            dataTest="remove-passenger"
          >
            {polyglot.t(
              'booking.deposit_booking.passengers_and_bags.action_buttons.remove_passenger',
            )}
          </Button>
        )}
      </Stack>

      {onAddPassenger && (
        <Button type="primarySubtle" size="small" onClick={onAddPassenger} dataTest="add-passenger">
          {polyglot.t('booking.deposit_booking.passengers_and_bags.action_buttons.add_passenger')}
        </Button>
      )}
    </Stack>
  );
};

export default ActionButtons;
