const v1_get_from = (data, pagination) => {
  const mappedData = data.map(billing => ({
    id: billing.id,
    companyName: billing.company_name,
    csvReady: billing.csv_ready,
    pdfReady: billing.pdf_ready,
    csv: billing.csv,
    pdf: billing.pdf,
    amount: billing.final_balance,
    periodStart: new Date(billing.period_start).toLocaleDateString(),
    periodEnd: new Date(billing.period_end).toLocaleDateString(),
    status: billing.status,
  }));
  const mappedPagination = {
    perPage: pagination.page_size,
    totalCount: pagination.total,
    pageCount: pagination.pages,
    hasNext: pagination.has_next,
  };

  return { data: mappedData, pagination: mappedPagination };
};

const v1_get_to = (filters, pagination, sort) => {
  let order = [];
  for (const el in sort) {
    if (sort[el].main === true) {
      order.unshift(`${sort[el].ascending ? '' : '-'}${sort[el].key}`);
    } else {
      order.push(`${sort[el].ascending ? '' : '-'}${sort[el].key}`);
    }
  }
  order = order.join(',');

  return {
    ...(filters?.statusFilters?.length && { status: filters.statusFilters.join(',') }),
    ...(!!filters?.beginDate &&
      !!filters?.endDate && {
        period_start: filters.beginDate,
        period_end: filters.endDate,
      }),
    _page: pagination.page,
    _per_page: pagination.perPage,
    _fields: 'id,company_name,period_start,period_end,status,final_balance,pdf_ready,csv_ready',
    _order: order.length ? order : 'company_name,period_start,period_end,final_balance,status',
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
      to: v1_get_to,
    },
  },
};
