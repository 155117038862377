import { camelize } from 'utils/functions';
import { extractType } from 'utils/prebooking';

const direct_get_to = apiKey => ({
  headers: { apikey: apiKey },
});

const direct_get_from = prebookingSessionData => ({
  price: {
    amount: prebookingSessionData.pricing.itinerary.price.amount,
    currency: prebookingSessionData.pricing.itinerary.price.currency,
  },
  refreshTtl: prebookingSessionData.refresh_ttl,
  events: prebookingSessionData.events ? extractType(camelize(prebookingSessionData.events)) : {},
  sessionId: prebookingSessionData.id,
});

export default {
  direct: {
    get: {
      to: direct_get_to,
      from: direct_get_from,
    },
  },
};
