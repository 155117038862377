import styled from 'styled-components';

export const SupportContainer = styled.div`
  width: 880px;
  padding-bottom: 24px;
`;

export const AttachmentThumbnail = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.orbit.paletteCloudNormal};

  &:hover {
    cursor: pointer;
  }

  > img {
    max-height: 95%;
    max-width: 95%;
  }
`;

export const MultilineParagraph = styled.p`
  white-space: pre-wrap;
  word-break: break-word;
`;

export const StyledFileInput = styled.div`
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  display: inline-block;

  & :hover {
    cursor: pointer;
  }

  &:hover button {
    background: ${({ theme }) => theme.orbit.backgroundButtonSecondaryHover};
  }

  & > input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  & :disabled:hover {
    cursor: not-allowed;
  }
`;

export const MultipleFileInput = styled.div`
  input[type='text']:hover {
    cursor: default;
  }

  div {
    cursor: default;
  }

  & input[type='text']:not(:placeholder-shown) {
    max-width: 0;
  }
`;

export const RequestTypeContainer = styled.div`
  width: 100%;
  transition: 300ms;
  border-radius: 5px;
  padding: 10px;
  min-height: 3em;

  display: flex;
  flex-direction: column;

  img {
    max-height: 80%;
    max-width: 80%;
  }

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: ${({ theme }) => theme.orbit.paletteCloudNormalHover};
  }
`;

export const AttachmentsContainer = styled.div`
  overflow: auto;
  width: 100%;
  padding-bottom: 15px;
`;

export const SearchFAQWrapper = styled.div`
  background: ${({ theme }) => theme.orbit.paletteProductNormal};
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

export const QuickGuidesWrapper = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.orbit.paletteGreenLight};
  margin-bottom: 24px;
`;

export const LinksWrapper = styled.div`
  flex: 1;
  line-height: 1.5;
`;

export const MarkupWrapper = styled.div`
  table {
    border-collapse: collapse;
  }
  table,
  th,
  td,
  code {
    border: 1px solid #c1c7d0;
    padding: 3px;
  }
  code {
    background: #f4f5f7;
  }
`;
