import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuthState } from 'components/services/auth/AuthProvider';
import { useIsMounted } from 'utils/hooks';

/**
 * Renders children only if the given user should be able to access them
 * based on the defined policies.
 */

export const INITIAL_BATCH_RESOURCES = [
  'fe.page.applications',
  'fe.page.companies',
  'fe.page.customer_support',
  'fe.page.dashboard',
  'fe.page.deeplink_generator',
  'fe.page.help',
  'fe.page.kiwicom.search',
  'fe.page.mmb',
  'fe.page.partner_payments',
  'fe.page.reports',
  'fe.page.resources',
  'fe.page.user_management',
  'fe.page.widget_builder',
  'feature.company_log',
  'feature.demand_dashboard',
  'feature.destination_dashboard',
  'model.application',
  'model.bank_account',
  'model.billing_cycle',
  'model.commission',
  'model.company',
  'model.company_pcc',
  'model.company_special_app',
  'model.company_settings',
  'model.cooperation',
  'model.deposit_account',
  'model.special_app',
  'model.user',
  'model.billing_job',
  'model.data_marketplace_dataset',
  'model.widget',
  'acl.roles',
  'meta.roles',
];

const CREATE = ['create', 'create_assigned', 'create_own', 'create_self'];
const READ = ['index', 'read', 'read_assigned', 'read_own', 'read_self'];
const UPDATE = ['update', 'update_assigned', 'update_own', 'update_self'];
const DELETE = ['delete', 'delete_assigned', 'delete_own', 'delete_self'];
export const CRUD_ACTIONS = [...CREATE, ...READ, ...UPDATE, ...DELETE];

const Authorization = ({ resource, action, children, fallback, loader, companyName }) => {
  const { user, checkAccess, checkedGrants } = useContext(AuthState);
  const [allowed, setAllowed] = useState({ value: false, loading: true });
  const params = useParams();
  const company = params.companyName || companyName || user.companyName;
  const isMounted = useIsMounted();

  useEffect(() => {
    checkAccess(resource, action, company).then(res => {
      if (isMounted()) {
        setAllowed({ value: res, loading: false });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedGrants?.[company]?.[resource]]);

  if (allowed.loading) {
    return <>{loader || null}</>;
  }
  if (allowed.value) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default Authorization;
