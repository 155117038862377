import * as R from 'ramda';
import { validateYupSchema, yupToFormErrors } from 'formik';
import format from 'date-fns/format';
import isBeforeDfs from 'date-fns/isBefore';
import isSameDayDfs from 'date-fns/isSameDay';

import { cabinOptions, WidgetBuilderSchema, WIDGET_SIZE_TYPE, WIDGET_URLS } from 'consts/widgets';
import { toDataParams } from 'mappers/Widgets';
import { handleError } from 'utils/http';
import { getLocationName } from './deeplinkGenerator';
import { ALL_CARRIERS } from 'consts/carriers';

export const newDateWithoutTime = date => {
  if (date) {
    return new Date(new Date(date).toDateString());
  } else {
    return new Date(new Date().toDateString());
  }
};
export const isBefore = (firstDate, secondDate) => {
  // eslint-disable-next-line eqeqeq
  return isBeforeDfs(newDateWithoutTime(firstDate), newDateWithoutTime(secondDate)) == true;
};
export const isSameDay = (firstDate, secondDate) => {
  return isSameDayDfs(newDateWithoutTime(firstDate), newDateWithoutTime(secondDate));
};

export const isTodayOrLater = date => {
  return isBeforeOrSameDay(null, date);
};

export const isBeforeToday = date => {
  return isBefore(date, null);
};

export const isBeforeOrSameDay = (firstDate, secondDate) => {
  return isBefore(firstDate, secondDate) || isSameDay(firstDate, secondDate);
};

export const sortComparator = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

const MAX_CABIN_BAGS_PER_PERSON = 1;
const MAX_CHECKED_BAGS_PER_PERSON = 2;

const distributeBags = (bagsArray, bags, paxCount, checkedBags) => {
  let bagCount = bags;
  let bagOverflow = 0;
  while (bagCount > 0) {
    if (bagOverflow > 0) {
      break;
    }
    for (let paxNum = 0; paxNum < paxCount; paxNum++) {
      if (bagCount === 0) {
        break;
      }
      if (!bagsArray[paxNum]) {
        bagsArray[paxNum] = [0, 0];
      }

      if (checkedBags) {
        bagOverflow = bags - paxCount * MAX_CHECKED_BAGS_PER_PERSON;
        if (bagsArray[paxNum][1] === MAX_CHECKED_BAGS_PER_PERSON) {
          continue;
        }
        bagsArray[paxNum][1]++;
      } else {
        bagOverflow = bags - paxCount * MAX_CABIN_BAGS_PER_PERSON;
        if (bagsArray[paxNum][0] === MAX_CABIN_BAGS_PER_PERSON) {
          continue;
        }
        bagsArray[paxNum][0]++;
      }
      bagCount--;
    }
  }
};

export const getQueryBags = (checkedBags, cabinBags, passengers, adultsCount) => {
  if ((checkedBags === 0 && cabinBags === 0) || !passengers) {
    return undefined;
  }
  const paxCount = passengers.adults + (passengers?.children || 0) + (passengers?.infants || 0);
  const bagsArray = [];
  distributeBags(bagsArray, checkedBags, paxCount, true);
  distributeBags(bagsArray, cabinBags, paxCount, false);
  const formattedBagsArray = bagsArray.map(bags => bags.join('.'));
  const adults = formattedBagsArray.slice(0, adultsCount);
  const children = formattedBagsArray.slice(adultsCount, bagsArray.length);

  return children.length ? `${adults.join('_')}-${children.join('_')}` : adults.join('_');
};

export const transformPlacepickerValues = async arrayOfLabels => {
  const transformedLocations = [];

  await Promise.all(
    arrayOfLabels.map(async place => {
      const res = await getLocation(place);

      const location = R.innerJoin((item, selectedItem) => item.id === selectedItem, res, [place]);

      if (location.length) {
        transformedLocations.push({
          label: getLocationName(location[0]),
          value: location[0].id,
          type: location[0].type,
        });
      }
    }),
  );

  return transformedLocations;
};

const getLocation = (term, locationTypes) => {
  return fetch(getLocationsUrl(term, locationTypes))
    .then(res => res.json())
    .then(res => res.locations)
    .catch(handleError);
};

const getLocationsUrl = term => {
  return `https://api.skypicker.com/locations/id?id=${term}&limit=20&locale=en-US&active_only=true`;
};

export const validateWidget = value => {
  try {
    validateYupSchema(value, WidgetBuilderSchema, true, value);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

const getScriptSrc = widgetTypeValue =>
  widgetTypeValue === 'singleWidget' ? WIDGET_URLS.singleWidget : WIDGET_URLS.fullWidget;

export const createPreviewCodeAndAttachToRefs = (values, drawerShown, scriptRef, holderRef) => {
  if (!scriptRef.current || !Object.keys(values).length) {
    return;
  }

  const scriptSrc = getScriptSrc(values.widgetType.value);
  const params = toDataParams(values);

  if (!drawerShown) {
    while (holderRef.current.firstChild) {
      holderRef.current.removeChild(holderRef.current.firstChild);
    }

    while (scriptRef.current.firstChild) {
      scriptRef.current.removeChild(scriptRef.current.firstChild);
    }
  } else {
    const widgetScript = document.createElement('script');

    for (const key in params) {
      widgetScript.setAttribute(key, params[key]);
    }
    //necessary?
    widgetScript.setAttribute('data-width', '565px');

    widgetScript.src = scriptSrc;

    scriptRef.current.appendChild(widgetScript);
  }
};

export const generateScriptFromFormValues = values => {
  if (!values.general.solutionName) {
    return;
  }
  const params = toDataParams(values);
  const containerId = values.advanced?.containerId || 'widget-holder';
  const textArray = [`<div id="${containerId}"></div>`, `<script `];

  Object.keys(params).forEach(param => {
    textArray.push(`${param}="${params[param]}" `);
  });
  textArray.push(`src="${getScriptSrc(values.widgetType.value)}">`, `</script>`);

  return textArray.join('\n');
};

export const getWidgetTypeDateString = (datesObj, tripType) => {
  if (datesObj.value) {
    return datesObj.value;
  }

  if (tripType === 'return' && R.isEmpty(datesObj)) {
    return 'anytime';
  }
  if (R.isEmpty(datesObj)) {
    return '';
  }

  let dateString = format(new Date(datesObj.from), 'yyyy-MM-dd');

  if (datesObj.to) {
    dateString += `_${format(new Date(datesObj.to), 'yyyy-MM-dd')}`;
  }
  return dateString;
};

export const getDateFromWidgetString = dateString => {
  if (!dateString) return {};

  const dates = dateString.split('_');

  if (dates.length === 1) {
    return { from: new Date(dates.length[0]) };
  } else if (dates.length === 2) {
    const firstDate = new Date(dates[0]);
    const lastDate = new Date(dates[1]);

    return firstDate.valueOf() < lastDate.valueOf()
      ? { from: firstDate, to: lastDate }
      : { from: lastDate, to: firstDate };
  }
};

export const getInboundOutboundDaysString = inboundOutboundArray => {
  if (inboundOutboundArray.length !== 7) {
    return inboundOutboundArray.join(',');
  } else return '';
};

export const callExternalWidgetToGetFormValues = param => {
  const script = document.getElementById('widget-builder');

  if (script) {
    script.contentWindow.postMessage({ isExternalWidgetCalled: true, param }, '*');
  }
};

export const mapFromExternalWidgetBuilderFormValues = formValues => {
  let newFormValues = {};
  let newCarriers = [];
  const excludedKeys = [
    'allCarriers',
    'carriers',
    'stops',
    'outboundDate',
    'inboundDate',
    'brandID',
    'containerID',
    'iframeID',
    'colors',
  ];
  for (const primaryKey in formValues) {
    for (const secondaryKey in formValues[primaryKey]) {
      if (!excludedKeys.includes(secondaryKey)) {
        newFormValues = {
          ...newFormValues,
          [primaryKey]: {
            ...newFormValues[primaryKey],
            [secondaryKey]: formValues[primaryKey][secondaryKey],
          },
        };
      }
    }
  }

  newCarriers = formValues.additionalSearchFilters.allCarriers.filter(carrier => {
    if (formValues.additionalSearchFilters.exclusion.value) {
      return !formValues.additionalSearchFilters.carriers.find(fv => {
        return fv.code === carrier.code;
      });
    }
    return formValues.additionalSearchFilters.carriers.find(fv => {
      return fv.code === carrier.code;
    });
  });

  return {
    ...newFormValues,
    additionalSearchFilters: {
      ...newFormValues.additionalSearchFilters,
      carriers: newCarriers,
      daysOfTravel: {
        departure: newFormValues.additionalSearchFilters.daysOfTravel.departure.map(departureDay =>
          departureDay.slice(0, 3),
        ),
        return: newFormValues.additionalSearchFilters.daysOfTravel.return.map(returnDay =>
          returnDay.slice(0, 3),
        ),
      },
      stopNumber: formValues.additionalSearchFilters.stops,
    },
    advanced: {
      ...newFormValues.advanced,
      brandId: formValues.advanced.brandID,
      containerId: formValues.advanced.containerID,
      iframeId: formValues.advanced.iframeID,
    },
    ...(formValues.widgetType !== 'singleWidget'
      ? {
          appearance: {
            ...newFormValues.appearance,
            colors: {
              ...(formValues?.appearance?.colors?.primary &&
              formValues.appearance.colors.primary !== ''
                ? { primaryColor: formValues.appearance.colors.primary }
                : {}),
              ...(formValues?.appearance?.colors?.resultBackground &&
              formValues.appearance.colors.resultBackground !== ''
                ? { resultBackground: formValues.appearance.colors.resultBackground }
                : {}),
              ...(formValues?.appearance?.colors?.searchFormBackground &&
              formValues.appearance.colors.searchFormBackground !== ''
                ? { searchFormBackground: formValues.appearance.colors.searchFormBackground }
                : {}),
              ...(formValues?.appearance?.colors?.searchFormSecondaryText &&
              formValues.appearance.colors.searchFormSecondaryText !== ''
                ? { searchFormSecondaryText: formValues.appearance.colors.searchFormSecondaryText }
                : {}),
              ...(formValues?.appearance?.colors?.searchFormSelectedText &&
              formValues.appearance.colors.searchFormSelectedText !== ''
                ? { searchFormSelectedText: formValues.appearance.colors.searchFormSelectedText }
                : {}),
            },
          },
        }
      : {}),
    search: {
      ...newFormValues.search,
      from: newFormValues.search.from.length
        ? newFormValues.search.from.map(searchFrom => searchFrom.code)
        : [],
      to: newFormValues.search.to.length
        ? newFormValues.search.to.map(searchTo => searchTo.code)
        : [],
      cabin:
        newFormValues.search.cabin === 'all'
          ? cabinOptions.map(cabinOption => cabinOption.value)
          : newFormValues.search.cabin.length
          ? [newFormValues.search.cabin]
          : [],
      departureDateRange:
        formValues.search.outboundDate === 'anytime'
          ? {}
          : {
              from: new Date(formValues.search.outboundDate.start),
              to: new Date(formValues.search.outboundDate.end),
            },
      returnDateRange:
        formValues.search.inboundDate === 'anytime'
          ? {}
          : formValues.search.inboundDate?.start && formValues.search.inboundDate?.end
          ? {
              from: new Date(formValues.search.inboundDate.start),
              to: new Date(formValues.search.inboundDate.end),
            }
          : { value: formValues.search.inboundDate },
    },
    widgetType: {
      value: formValues.widgetType || '',
    },
  };
};

export const mapWidgetConfig = widget => {
  return {
    ...widget.config,
    ...(widget.config.appearance
      ? {
          appearance: {
            ...widget.config.appearance,
            colors: {
              ...(widget.config.appearance.colors?.primaryColor
                ? {
                    primaryColor: widget.config.appearance.colors.primaryColor.replace('#', ''),
                  }
                : {}),
              ...(widget.config.appearance.colors?.resultBackground
                ? {
                    resultBackground: widget.config.appearance.colors.resultBackground.replace(
                      '#',
                      '',
                    ),
                  }
                : {}),
              ...(widget.config.appearance.colors?.searchFormBackground
                ? {
                    searchFormBackground:
                      widget.config.appearance.colors.searchFormBackground.replace('#', ''),
                  }
                : {}),
              ...(widget.config.appearance.colors?.searchFormSecondaryText
                ? {
                    searchFormSecondaryText:
                      widget.config.appearance.colors.searchFormSecondaryText.replace('#', ''),
                  }
                : {}),
              ...(widget.config.appearance.colors?.searchFormSelectedText
                ? {
                    searchFormSelectedText:
                      widget.config.appearance.colors.searchFormSelectedText.replace('#', ''),
                  }
                : {}),
            },
          },
        }
      : {}),
  };
};

const DAYS_IN_WEEK = [
  { label: 'Sunday', value: 'SUNDAY' },
  { label: 'Monday', value: 'MONDAY' },
  { label: 'Tuesday', value: 'TUESDAY' },
  { label: 'Wednesday', value: 'WEDNESDAY' },
  { label: 'Thursday', value: 'THURSDAY' },
  { label: 'Friday', value: 'FRIDAY' },
  { label: 'Saturday', value: 'SATURDAY' },
];

const initialValues = {
  general: {
    affilId: '',
    currency: 'EUR',
    language: 'en',
    autoDetectLang: false,
    autoDetectCurrency: false,
    solutionName: '',
  },
  widgetType: null,
  search: {
    from: [],
    to: [],
    outboundDate: 'anytime',
    inboundDate: 'anytime',
    cabin: '',
    passengersAndBags: { adults: 1, children: 0, infants: 0, cabinBags: 0, checkedBags: 0 },
    transport: [],
    tripType: 'one-way',
  },
  additionalSearchFilters: {
    stops: -1,
    sortBy: '',
    daysOfTravel: {
      departure: DAYS_IN_WEEK.map(d => d.value),
      return: DAYS_IN_WEEK.map(d => d.value),
    },
    carriers: [],
    exclusion: {
      value: true,
    },
  },
  appearance: {
    colors: {
      primary: '',
      resultBackground: '',
      searchFormBackground: '',
      searchFormSelectedText: '',
      searchFormSecondaryText: '',
    },
    sizeType: WIDGET_SIZE_TYPE.FIXED,
    size: '',
  },
  advanced: {
    brandID: '',
    containerID: '',
    iframeID: '',
  },
  snippet: {},
};

export const mapToExternalWidgetBuilderFormValues = formValues => {
  let newFormValues = {};
  let daysOfTravelDeparture = DAYS_IN_WEEK.map(dayInWeek => dayInWeek.value);
  let daysOfTravelReturn = DAYS_IN_WEEK.map(dayInWeek => dayInWeek.value);
  let carriers = ALL_CARRIERS;
  const excludedKeys = [
    'brandId',
    'containerId',
    'iframeId',
    'stopNumber',
    'carriers',
    'returnDateRange',
    'departureDateRange',
    'passengersAndBags',
  ];

  for (const primaryKey in formValues) {
    for (const secondaryKey in formValues[primaryKey]) {
      if (!excludedKeys.includes(secondaryKey)) {
        newFormValues = {
          ...newFormValues,
          [primaryKey]: {
            ...newFormValues[primaryKey],
            [secondaryKey]: formValues[primaryKey][secondaryKey],
          },
        };
      }
    }
  }

  if (
    ((formValues.additionalSearchFilters?.exclusion?.value === undefined ||
      formValues.additionalSearchFilters.exclusion.value) &&
      !formValues.additionalSearchFilters?.carriers) ||
    (!formValues.additionalSearchFilters?.exclusion?.value &&
      !formValues.additionalSearchFilters?.carriers)
  ) {
    carriers = [];
  } else {
    carriers = ALL_CARRIERS.filter(carrier => {
      return formValues.additionalSearchFilters.carriers.find(ffv => {
        return ffv.code === carrier.code;
      });
    });
  }

  if (newFormValues?.additionalSearchFilters?.daysOfTravel?.departure) {
    daysOfTravelDeparture = daysOfTravelDeparture.filter(dayOfTravelDeparture => {
      return newFormValues.additionalSearchFilters.daysOfTravel.departure.find(nffv => {
        return nffv === dayOfTravelDeparture.slice(0, 3);
      });
    });
  }

  if (newFormValues?.additionalSearchFilters?.daysOfTravel?.return) {
    daysOfTravelReturn = daysOfTravelReturn.filter(dayOfTravelReturn => {
      return newFormValues.additionalSearchFilters.daysOfTravel.return.find(nffv => {
        return nffv === dayOfTravelReturn.slice(0, 3);
      });
    });
  }

  return {
    ...newFormValues,
    general: {
      ...initialValues.general,
      ...newFormValues.general,
    },
    widgetType: newFormValues.widgetType.value,
    search: {
      ...initialValues.search,
      ...(newFormValues?.search || {}),
      cabin:
        newFormValues?.search?.cabin?.length === 4
          ? 'all'
          : newFormValues?.search?.cabin?.length
          ? newFormValues.search.cabin[0]
          : '',
      outboundDate: formValues?.search?.departureDateRange
        ? {
            start: formValues.search.departureDateRange.from,
            end: formValues.search.departureDateRange.to,
          }
        : 'anytime',
      inboundDate:
        formValues?.search?.returnDateRange?.from && formValues?.search?.returnDateRange?.to
          ? {
              start: formValues.search.returnDateRange.from,
              end: formValues.search.returnDateRange.to,
            }
          : formValues?.search?.returnDateRange?.value || 'anytime',
      passengersAndBags:
        formValues?.search?.passengersAndBags || initialValues.search.passengersAndBags,
    },
    additionalSearchFilters: {
      ...initialValues.additionalSearchFilters,
      ...(newFormValues?.additionalSearchFilters || {}),
      stops:
        formValues?.additionalSearchFilters?.stopNumber === 0
          ? formValues.additionalSearchFilters.stopNumber
          : formValues?.additionalSearchFilters?.stopNumber || -1,
      carriers,
      daysOfTravel: {
        departure: daysOfTravelDeparture,
        return: daysOfTravelReturn,
      },
    },
    ...(newFormValues.widgetType !== 'singleWidget'
      ? {
          appearance: {
            ...initialValues.appearance,
            ...(newFormValues?.appearance || {}),
            colors: {
              primary: newFormValues?.appearance?.colors?.primaryColor
                ? newFormValues.appearance.colors.primaryColor
                : '',
              resultBackground: newFormValues?.appearance?.colors?.resultBackground
                ? newFormValues.appearance.colors.resultBackground
                : '',
              searchFormBackground: newFormValues?.appearance?.colors?.searchFormBackground
                ? newFormValues.appearance.colors.searchFormBackground
                : '',
              searchFormSelectedText: newFormValues?.appearance?.colors?.searchFormSelectedText
                ? newFormValues.appearance.colors.searchFormSelectedText
                : '',
              searchFormSecondaryText: newFormValues?.appearance?.colors?.searchFormSecondaryText
                ? newFormValues?.appearance?.colors?.searchFormSecondaryText
                : '',
            },
          },
        }
      : {}),
    advanced: {
      ...initialValues.advanced,
      ...(newFormValues?.advanced || {}),
      brandID: formValues?.advanced?.brandId || '',
      containerID: formValues?.advanced?.containerId || '',
      iframeID: formValues?.advanced?.iframeId || '',
    },
  };
};
