const getFullName = pax => {
  if (pax.middle_name) {
    return `${pax.first_name} ${pax.middle_name} ${pax.last_name}`;
  }

  return `${pax.first_name} ${pax.last_name}`;
};

const direct_get_from = data => {
  return data.map(pax => ({
    birthDate: pax.birth_date,
    category: pax.category,
    documentExpiry: pax.document_expiry,
    documentNumber: pax.document_number,
    firstName: pax.first_name,
    lastName: pax.last_name,
    id: pax.id,
    isContactPassenger: pax.is_contaxt_passenger,
    middleName: pax.middle_name,
    nationality: pax.nationality,
    title: pax.title,
    fullName: getFullName(pax),
  }));
};

const direct_patch_to = data => {
  return {
    [data.id]: {
      document_number: data.documentNumber,
      ...(data.noExpiry ? {} : { document_expiry: data.documentExpiry }),
    },
  };
};

export default {
  direct: {
    get: {
      from: direct_get_from,
    },
    patch: {
      to: direct_patch_to,
    },
  },
};
