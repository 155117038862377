const v1_get_from = deposit => ({
  balance: deposit.credits,
  depositAcc: deposit.email,
  affiliateId: deposit.affiliate_id,
  currency: deposit.currency,
  companyName: deposit.company_name,
});

const v1_post_to = (deposit, companyName) => ({
  email: deposit.email,
  booking_partner_id: deposit.bookingPartnerId,
  affiliate_id: deposit.affiliateId,
  secret_token: deposit.secretToken,
  company_name: companyName,
});

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: { to: v1_post_to },
  },
};
