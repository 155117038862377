import styled, { css } from 'styled-components';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

const expandableStyles = css`
  transition: all 200ms ease-in-out;

  & ~ tr:not(:last-of-type) {
    height: 0 !important;
    opacity: 0;
    visibility: hidden;
    font-size: 0px;
    line-height: 0;
    transition: all 200ms ease-in-out;
    border: none;

    & td:first-of-type {
      padding-left: 30px;
    }

    td {
      padding-bottom: 0;
    }
  }
`;

const expandedStyles = css`
  & ~ tr:not(:last-of-type) {
    opacity: 1;
    visibility: visible;
    font-size: 14px;
    line-height: 1.4;
    height: 48px !important;
    border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};

    &:nth-of-type(even) {
      background-color: #ffffff;
    }

    &:nth-of-type(odd) {
      background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
    }

    td {
      padding-bottom: 5px;

      &:first-of-type::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 48px;
        background-color: #ffffff;
        left: 0;
        top: 0;
        border-right: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
      }

      &:last-of-type::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 48px;
        background-color: #ffffff;
        right: 0;
        top: 0;
        border-left: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
      }
    }
  }

  & ~ tr:last-of-type {
    display: table-row;
  }
`;

const separatorStyles = css`
  display: none;
  height: 16px !important;
  border-bottom: none;
`;

const headStyles = css`
  font-weight: 700;
  vertical-align: bottom;
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteInkLighter} !important;

  td {
    padding-bottom: 5px;
  }
`;

export const StyledRow = styled.tr`
  height: 48px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-top: none;

  td {
    position: relative;

    padding-left: 24px;
  }

  ${({ expandable }) => expandable && expandableStyles};
  ${({ expanded }) => expanded && expandedStyles};
  ${({ separator }) => separator && separatorStyles};
  ${({ head }) => head && headStyles};
`;

export const ExpandArrow = styled(ChevronDownIcon)`
  transition: all 200ms ease-in-out;
  cursor: pointer;
  color: $gray-100;
  margin-left: 8px;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(-180deg);
    `}
`;
