import React, { useContext, useEffect } from 'react';
import * as dfs from 'date-fns';
import { Stack, CountryFlag, Text, Heading, Inline } from '@kiwicom/orbit-components/lib';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import CakeIcon from '@kiwicom/orbit-components/lib/icons/Cake';
import PassportIcon from '@kiwicom/orbit-components/lib/icons/Passport';
import GenderWomanIcon from '@kiwicom/orbit-components/lib/icons/GenderWoman';
import GenderManIcon from '@kiwicom/orbit-components/lib/icons/GenderMan';
import InfantIcon from '@kiwicom/orbit-components/lib/icons/Infant';
import BaggagePersonalIcon from '@kiwicom/orbit-components/lib/icons/BaggagePersonal';
import BaggageCabinIcon from '@kiwicom/orbit-components/lib/icons/BaggageCabin';

import { useToggle } from 'utils/hooks';

import { MMBState } from 'components/services/mmb';
import { getCurrentPaxBags } from 'components/scenes/ManageMyBookings/scenes/BookingDetails/services/utils';
import { usePolyglot } from 'components/services/i18n';

import EditPassengerModal from '../EditPassengerModal';
import AddBaggageModal from '../AddBaggageModal';
import { PassengerInfo, BaggageInfo } from './components';

import CollapsibleCard from '../../../components/CollapsibleCard';

const PassengerRow = React.memo(
  ({ bid, passenger, bags, ancillaries, itinerary, status, refund, dataTest }) => {
    const editPax = useToggle();
    const addBags = useToggle();
    const isExpanded = useToggle(false);
    const currentPaxBags = getCurrentPaxBags(bags, passenger.id, ancillaries);

    const holdBagsNum = currentPaxBags?.reduce(
      (acc, curr) =>
        curr.data.bag.category === 'hold_bag' || curr.data.bag.category === 'personal_item'
          ? acc + 1
          : acc,
      0,
    );

    const cabinBagsNum = currentPaxBags?.reduce(
      (acc, curr) => (curr.data.bag.category === 'cabin_bag' ? acc + 1 : acc),
      0,
    );

    return (
      <CollapsibleCard
        dataTest={dataTest}
        nested
        isExpanded={isExpanded.isOn}
        onExpand={isExpanded.toggle}
        header={
          <>
            {passenger.category !== 'adult' ? (
              <InfantIcon size="small" />
            ) : passenger?.title === 'mr' ? (
              <GenderManIcon size="small" />
            ) : (
              <GenderWomanIcon size="small" />
            )}
            <Heading type="title4">{passenger.fullName}</Heading>
            <CountryFlag
              code={passenger.nationality}
              name={passenger.nationality}
              size="small"
              dataTest="pax-country"
            />
          </>
        }
        sideInfo={
          <Stack direction="row" shrink align="center" justify="end">
            <Inline align="center" spacing="XXSmall">
              <CakeIcon size="small" />{' '}
              <Text>{dfs.format(new Date(passenger.birthDate), 'dd MMM yyyy')}</Text>
            </Inline>
            {passenger.documentNumber && (
              <Inline align="center" spacing="XXSmall">
                <PassportIcon size="small" /> <Text>{passenger.documentNumber}</Text>
              </Inline>
            )}
            <Inline align="center" spacing="XXSmall">
              <BaggagePersonalIcon size="small" /> <Text>{holdBagsNum}</Text>
            </Inline>
            <Inline align="center" spacing="XXSmall">
              <BaggageCabinIcon size="small" /> <Text>{cabinBagsNum}</Text>
            </Inline>
          </Stack>
        }
      >
        <>
          {editPax.isOn && (
            <EditPassengerModal
              bid={bid}
              onClose={editPax.setOff}
              passenger={passenger}
              itinerary={itinerary}
            />
          )}
          {addBags.isOn && (
            <AddBaggageModal onClose={addBags.setOff} passenger={passenger} bid={bid} />
          )}

          <Stack direction="column" spacing="none">
            <PassengerInfo
              handlePassengerEdit={editPax.setOn}
              passenger={passenger}
              itinerary={itinerary}
              bid={bid}
            />
            <BaggageInfo
              bags={bags}
              passengerId={passenger.id}
              status={status}
              ancillaries={ancillaries}
              refund={refund}
              departureTime={itinerary.sectors[0].departure.time.local}
              handleBagsAdd={addBags.setOn}
            />
          </Stack>
        </>
      </CollapsibleCard>
    );
  },
);

const Passengers = ({ bid }) => {
  const { state, getPassengersInfo } = useContext(MMBState);
  const polyglot = usePolyglot();

  useEffect(() => {
    getPassengersInfo(bid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    passengers = [],
    bookingData: { baggage, ancillaries, itinerary, displayStatus },
    refund,
  } = state.bookingsState[bid] || {};

  return (
    <Card title={polyglot.t('common.passengers')}>
      <CardSection>
        <Stack spacing="large">
          {passengers?.map((pax, i) => (
            <PassengerRow
              key={i}
              bid={bid}
              passenger={pax}
              bags={baggage}
              ancillaries={ancillaries}
              itinerary={itinerary}
              status={displayStatus}
              refund={refund}
              dataTest={`passenger-${i}`}
            />
          ))}
        </Stack>
      </CardSection>
    </Card>
  );
};

export default React.memo(Passengers);
