export const PAYOUT_TYPES = {
  CPA: 'CPA',
  CPC: 'CPC',
};

export const PAYOUT_VALUE_TYPES = {
  PERCENT: 'PERCENT',
  FLAT: 'FLAT',
};

export const OFFER_FIELDS = ['cpcFlat', 'cpsFlat', 'cpsPercent'];

export const OFFERS_I18N = {
  cpcFlat: 'offers.cost_per_click',
  cpsFlat: 'offers.cost_per_sale',
  cpsPercent: 'offers.cost_per_sale_percent',
};
