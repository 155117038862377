import { ANCILLARIES } from 'consts/booking';

const v2_post_to = (token, sessionId, currency, passengers, ancillary = ANCILLARIES.GUARANTEE) => {
  return {
    ancillaries: [ancillary],
    booking_token: token,
    currency: currency,
    passengers: passengers.map(pax => ({
      ...(pax.dateOfBirth?.length ? { birthday: pax.dateOfBirth } : {}),
      category: pax.category,
      ...(pax.nationality?.length ? { nationality: pax.nationality.toLowerCase() } : {}),
    })),
    session_id: sessionId,
  };
};

const v2_post_from_guarantee = (eurGuarantee, selectedCurrencyGuarantee) => {
  return eurGuarantee.guarantee.offers.map(offer => ({
    price: {
      amount: offer.price.amount,
      amountInSelectedCurrency: selectedCurrencyGuarantee
        ? selectedCurrencyGuarantee.guarantee.offers[0].price.amount
        : offer.price.amount,
      base: offer.price.base,
      currency: offer.price.currency,
      merchant: offer.price.merchant,
      service: offer.price.service,
      service_flat: offer.price.service_flat,
    },
    type: offer.type,
  }));
};

const v2_post_from_seating = data => {
  return {
    status: data.seating.status,
    offers: data.seating.offers.map(offer => ({
      seatmap: offer.seatmap
        ? offer.seatmap.sections.map(section => ({
            deck: section.deck,
            sectionClass: section.section_class,
            rows: section.rows.map(row => ({
              rowNumber: row.row_number,
              seatGroups: row.seat_groups.map(seatGroup =>
                seatGroup.map(group => ({
                  name: group.name,
                  column: group.column,
                  features: group.features,
                  seatClass: group.seat_class,
                  state: group.state,
                  type: group.type,
                  price: {
                    currency: group.price.currency,
                    amount: Number(group.price.amount),
                    base: Number(group.price.base),
                    service: Number(group.price.service),
                    serviceFlat: Number(group.price.service_flat),
                    merchant: Number(group.price.merchant),
                  },
                })),
              ),
            })),
          }))
        : null,
      quickOptions: offer.quick_options.map(quickOption => ({
        option: quickOption.option,
        price: {
          amount: Number(quickOption.price.amount),
          base: Number(quickOption.price.base),
          currency: quickOption.price.currency,
          merchant: Number(quickOption.price.merchant),
          service: Number(quickOption.price.service),
          serviceFlat: Number(quickOption.price.service_flat),
        },
      })),
      segmentCode: offer.segment_code,
    })),
  };
};

export default {
  v2: {
    post: {
      to: v2_post_to,
      from: {
        guarantee: v2_post_from_guarantee,
        seating: v2_post_from_seating,
      },
    },
  },
};
