import useCompanyDetails from './useCompanyDetails';
import useCurrentCompanyName from './useCurrentCompanyName';

const useCurrentCompanyDetails = () => {
  const companyName = useCurrentCompanyName();

  return useCompanyDetails(companyName);
};

export default useCurrentCompanyDetails;
