import * as dfs from 'date-fns';

export const BAGS = {
  hold_bag: 'Checked bag',
  hand_bag: 'Cabin bag',
  personal_item: 'Personal item',
  cabin_bag: 'Cabin bag',
};

export const TIMEFRAME_PREDEFINED_OPTIONS = [
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.currentMonth',
    name: 'currentMonth',
    value: {
      from: dfs.format(dfs.startOfMonth(new Date()), 'yyyy-MM-dd'),
      to: dfs.format(new Date(), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.previousMonth',
    name: 'previousMonth',
    value: {
      from: dfs.format(dfs.startOfMonth(dfs.subMonths(new Date(), 1)), 'yyyy-MM-dd'),
      to: dfs.format(dfs.endOfMonth(dfs.subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.currentQuarter',
    name: 'currentQuarter',
    value: {
      from: dfs.format(dfs.startOfQuarter(new Date()), 'yyyy-MM-dd'),
      to: dfs.format(new Date(), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.previousQuarter',
    name: 'previousQuarter',
    value: {
      from: dfs.format(dfs.startOfQuarter(dfs.subQuarters(new Date(), 1)), 'yyyy-MM-dd'),
      to: dfs.format(dfs.endOfQuarter(dfs.subQuarters(new Date(), 1)), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.currentYear',
    name: 'currentYear',
    value: {
      from: dfs.format(dfs.startOfYear(new Date()), 'yyyy-MM-dd'),
      to: dfs.format(new Date(), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.previousYear',
    name: 'previousYear',
    value: {
      from: dfs.format(dfs.startOfYear(dfs.subYears(new Date(), 1)), 'yyyy-MM-dd'),
      to: dfs.format(dfs.endOfYear(dfs.subYears(new Date(), 1)), 'yyyy-MM-dd'),
    },
  },
  {
    label: 'tequila.mmb.credit_activity.filters.timeframe.option.custom',
    name: 'custom',
    value: undefined,
  },
];

export const CREDIT_ACTIVITY_TYPES = [
  {
    label: 'tequila.mmb.credit_activity.types.deposit.label',
    value: 'deposit',
  },
  {
    label: 'tequila.mmb.credit_activity.types.refund.label',
    value: 'refund',
  },
  {
    label: 'tequila.mmb.credit_activity.types.payment.label',
    value: 'payment',
  },
  {
    label: 'tequila.mmb.credit_activity.types.expiration.label',
    value: 'expiration',
  },
];
export const ANCILLARIES = {
  SEATING: 'seating',
};

export const ANCILLARY_SEATING_SOURCE = {
  PRE_BOOKING: 'pre_booking',
  POST_BOOKING: 'post_booking',
};

export const ANCILLARY_SEATING_STATUS = {
  PROCESSING: 'processing',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  REFUNDED: 'refunded',
  UNPAID: 'unpaid',
};
