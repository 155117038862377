import React from 'react';
import PropTypes from 'prop-types';

import { StyledWrapper, Container } from './Wrapper.styled';

// Wrapper component should wrap Input - it will provide some css stuff to the input component
const Wrapper = ({ children, size, className, border, containerClassName, disabled }) => (
  <StyledWrapper size={size} disabled={disabled} border={border} className={className}>
    <Container className={containerClassName}>{children}</Container>
  </StyledWrapper>
);

Wrapper.defaultProps = {
  size: 'medium',
  border: true,
  disabled: false,
};

Wrapper.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  className: PropTypes.string,
  children: PropTypes.any,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
};

export default Wrapper;
