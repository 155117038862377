import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isValidIBAN, isValidBIC } from 'ibantools';
import * as R from 'ramda';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';
import { SelectCountry } from 'common';
import { countriesWithIBAN } from 'consts/countries';
import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';
import { BankAccountsState } from 'components/services/bankAccounts';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import useCompanyBankAccounts from 'components/services/bankAccounts/useCompanyBankAccounts';

const displayError = (prop, errors, touched, submitCount, polyglot) =>
  (touched && errors && touched[prop] && errors[prop] && polyglot.t(errors[prop])) ||
  (submitCount > 0 && errors && errors[prop] && polyglot.t(errors[prop]));

const accountSchema = Yup.object().shape({
  country: Yup.string().required('billing.must_select_country'),
  swift: Yup.string()
    .required('billing.empty_swift')
    .test('validBic', 'billing.invalid_swift', value => {
      return value && isValidBIC(value.replace(/\W+/g, ''));
    }),
  iban: Yup.string().when('country', {
    is: c => countriesWithIBAN.includes(c),
    then: Yup.string()
      .required('billing.empty_iban')
      .test('validIban', 'billing.invalid_iban', value => {
        return value && isValidIBAN(value.replace(/\W+/g, ''));
      }),
    otherwise: Yup.string().nullable(),
  }),
  account_number: Yup.string().when('country', {
    is: c => countriesWithIBAN.includes(c),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('billing.bank_account_number_empty'),
  }),
  beneficiary_name: Yup.string().trim().required('validation.cant_be_empty'),
});

const BankAccountModal = ({ onClose, companyName, editingAccount }) => {
  const polyglot = usePolyglot();
  const { data } = useCurrentCompanyDetails();
  const { createCompanyBankAccount, updateCompanyBankAccount } = useContext(BankAccountsState);
  const currentCompanyName = useCurrentCompanyName();
  const nextGenBankAccounts = useCompanyBankAccounts(currentCompanyName || companyName);
  const [errorUpdateAlertMessage, setErrorUpdateAlertMessage] = useState(false);

  const hasBankAccounts = nextGenBankAccounts.data?.length === 0;
  const buttonLoading = nextGenBankAccounts.creating || nextGenBankAccounts.updating;

  const addBankAccount = async values => {
    try {
      await createCompanyBankAccount(data?.name || companyName, values);
      onClose(true);
    } catch (error) {
      setErrorUpdateAlertMessage(error.response.data.message);
    }
  };

  const editBankAccount = async values => {
    try {
      await updateCompanyBankAccount(data?.name || companyName, values);
      onClose(true);
    } catch (error) {
      setErrorUpdateAlertMessage(error.response.data.message);
    }
  };

  const closeModal = () => {
    onClose(false);
  };

  const initialValues = editingAccount
    ? {
        country: editingAccount.country || '',
        swift: editingAccount.swift || '',
        iban: editingAccount.iban || '',
        account_number: editingAccount.accountNumber || '',
        beneficiary_name: editingAccount.beneficiaryName || '',
        uuid: editingAccount.uuid,
      }
    : {
        country: '',
        swift: '',
        iban: '',
        account_number: '',
        beneficiary_name: '',
      };

  return (
    <Modal closable onClose={closeModal}>
      <ModalHeader
        title={polyglot.t(
          editingAccount
            ? 'company.billing.edit_bank_account.modal.header'
            : 'company.billing.add_bank_account.modal.header',
        )}
        description={
          !editingAccount && !hasBankAccounts
            ? polyglot.t('company.billing.add_bank_account.modal.header_description')
            : undefined
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={accountSchema}
        onSubmit={editingAccount ? editBankAccount : addBankAccount}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, submitCount }) => (
          <>
            <ModalSection>
              {errorUpdateAlertMessage && (
                <Alert
                  spaceAfter="large"
                  type="critical"
                  closable
                  icon
                  onClose={() => setErrorUpdateAlertMessage(false)}
                >
                  {errorUpdateAlertMessage}
                </Alert>
              )}
              <Stack direction="row" spaceAfter="large">
                <SelectCountry
                  name="country"
                  onChange={handleChange}
                  label={polyglot.t('company.country')}
                  value={values.country}
                  error={displayError('country', errors, touched, submitCount, polyglot)}
                />
                <InputField
                  type="text"
                  onBlur={handleBlur}
                  name="swift"
                  onChange={handleChange}
                  label={polyglot.t('company.swift')}
                  value={values.swift}
                  disabled={!values.country}
                  error={displayError('swift', errors, touched, submitCount, polyglot)}
                />
              </Stack>
              {countriesWithIBAN.includes(values.country) ? (
                <InputField
                  type="text"
                  onBlur={handleBlur}
                  name="iban"
                  onChange={handleChange}
                  label={polyglot.t('company.iban')}
                  value={values.iban}
                  disabled={!values.country}
                  error={displayError('iban', errors, touched, submitCount, polyglot)}
                  spaceAfter="large"
                />
              ) : (
                <InputField
                  type="text"
                  onBlur={handleBlur}
                  name="account_number"
                  onChange={handleChange}
                  label={polyglot.t('company.bank_account_number')}
                  value={values.account_number}
                  disabled={!values.country}
                  error={displayError('account_number', errors, touched, submitCount, polyglot)}
                  spaceAfter="large"
                />
              )}
              <InputField
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                name="beneficiary_name"
                label="Account holder's name"
                value={values.beneficiary_name}
                disabled={!values.country}
                error={displayError('beneficiary_name', errors, touched, submitCount, polyglot)}
              />
            </ModalSection>
            <ModalFooter>
              <Stack direction="row" justify="between">
                <Button
                  dataTest={
                    editingAccount
                      ? 'edit-bank-account-modal-cancel-button'
                      : 'add-bank-account-modal-cancel-button'
                  }
                  type="secondary"
                  onClick={closeModal}
                  width="108px"
                >
                  {polyglot.t('common.cancel')}
                </Button>
                <Button
                  dataTest={
                    editingAccount
                      ? 'edit-bank-account-modal-confirm-button'
                      : 'add-bank-account-modal-confirm-button'
                  }
                  type="primary"
                  width="190px"
                  onClick={handleSubmit}
                  loading={buttonLoading}
                  disabled={!values.country || !R.isEmpty(errors)}
                >
                  {polyglot.t(
                    editingAccount
                      ? 'company.billing.edit_bank_account.modal.submit_text'
                      : 'company.billing.add_bank_account.modal.submit_text',
                  )}
                </Button>
              </Stack>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default BankAccountModal;
