import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 880px;
`;

export const AlertTitle = styled.div`
  color: ${({ theme }) => theme.orbit.colorTextButtonFacebookBorderedActive};
  font-weight: 500;
`;

export const SetupChecklist = styled.ul`
  margin: 2px 0 0;
  padding-left: 8px;
  list-style-type: none;
`;

export const CheckContainer = styled.span`
  margin-left: -2px;
  color: ${({ theme }) => theme.orbit.colorTextInfo};
`;

export const RowContainer = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const RowItem = styled.div`
  flex-basis: ${({ basis }) => (basis ? basis : '49%')};
  margin-top: 10px;
`;
