import React, { useState, useContext } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import { Flex, Space, SelectCompany } from 'common';
import { AUTOMATCHED } from 'consts/partnerPayments';

import { PartnerPaymentsState } from 'components/services/partnerPayments';
import { DepositState } from 'components/services/deposit';
import { usePolyglot } from 'components/services/i18n';

const PartnerIdentificationForm = ({ onClose }) => {
  const [companyName, setCompanyName] = useState('');
  const [cantFindCompany, setCantFindCompany] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    state: { partnerPayment },
    loadPartnerPayment,
    identifyPartnerPayment,
  } = useContext(PartnerPaymentsState);
  const { loadDeposit } = useContext(DepositState);
  const polyglot = usePolyglot();

  const handleSelectCompanyName = async val => {
    setLoading(true);
    setCompanyName(val);
    try {
      await loadDeposit(val);
      setError('');
    } catch (err) {
      setError(polyglot.t('deposit.not_found', { company: val }));
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    identifyPartnerPayment(partnerPayment.data.id, companyName)
      .then(() => {
        loadPartnerPayment(partnerPayment.data.id);
        onClose();
      })
      .catch(err => {
        setError(polyglot.t('partner_payments.error_identify'));
        onClose();
      });
  };

  const ifAutomatched = payment => {
    if (payment.data.status === AUTOMATCHED) {
      const uniqueCompanies = [...new Set(payment.data.targets || [])];

      return (
        <>
          <SelectCompany
            label={polyglot.t('partner_payments.suggested_companies')}
            companiesFromProps={uniqueCompanies}
            onSelect={handleSelectCompanyName}
            value={companyName}
          />
          <Space before="l" after="l">
            <Checkbox
              name="cantFindCompany"
              checked={cantFindCompany}
              label={polyglot.t('partner_payments.cantfindcompany')}
              onChange={() => setCantFindCompany(!cantFindCompany)}
            />
          </Space>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Heading type="title1">{polyglot.t('partner_payments.identify')}</Heading>
      <Space before="l" after="l">
        {error && (
          <Alert type="critical" spaceAfter="medium">
            {error}
          </Alert>
        )}
        {cantFindCompany === false ? (
          ifAutomatched(partnerPayment) || (
            <SelectCompany onSelect={handleSelectCompanyName} value={companyName} />
          )
        ) : (
          <>
            <Space before="l" after="l">
              <Checkbox
                name="cantFindCompany"
                checked={cantFindCompany}
                label={polyglot.t('partner_payments.cantfindcompany')}
                onChange={() => setCantFindCompany(!cantFindCompany)}
              />
            </Space>
            <SelectCompany
              label={polyglot.t('partner_payments.all_companies')}
              onSelect={handleSelectCompanyName}
              value={companyName}
            />
          </>
        )}
      </Space>
      <Flex main="end">
        <Space right="l">
          <Button
            type="secondary"
            onClick={onClose}
            width="120px"
            size="large"
            loading={partnerPayment.identifying}
          >
            {polyglot.t('common.cancel')}
          </Button>
        </Space>
        <Button
          onClick={handleSubmit}
          width="120px"
          size="large"
          disabled={error}
          loading={loading || partnerPayment.identifying}
        >
          {polyglot.t('common.save')}
        </Button>
      </Flex>
    </>
  );
};
export default PartnerIdentificationForm;
