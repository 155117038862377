import React from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import OrbitCheckbox from '@kiwicom/orbit-components/lib/Checkbox';

import { CheckboxContainer } from './Navigation.styled';

const Checkbox = ({ title, checked, toggle, name, item }) => {
  return (
    <CheckboxContainer checked={checked}>
      <Stack flex={true} justify="between" align="center">
        <span onClick={() => toggle(item, checked)}>
          <Text>{title}</Text>
        </span>
        <OrbitCheckbox id={name} checked={checked} onChange={() => toggle(item, checked)} />
      </Stack>
    </CheckboxContainer>
  );
};
export default Checkbox;
