import React, { useReducer } from 'react';

import api from 'utils/api';
import accountManagerMapper from 'mappers/nextGen/users/account-managers/_';

export const AccountManagersState = React.createContext({});

const initialState = {
  state: {},
  loadAccountManagers: () => [],
};

const accountManagerReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_ACCOUNT_MANAGERS':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'LOAD_ACCOUNT_MANAGERS_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case 'LOAD_ACCOUNT_MANAGERS_FAIL':
      return {
        ...state,
        data: null,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const AccountManagerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountManagerReducer, initialState);

  const loadAccountManagers = async () => {
    dispatch({ type: 'LOAD_ACCOUNT_MANAGERS' });

    try {
      const { data } = await api.v1.get('users/account-managers');
      const accManagers = data.map(accountManagerMapper.v1.get.from);

      dispatch({
        type: 'LOAD_ACCOUNT_MANAGERS_SUCCESS',
        data: accManagers,
      });
    } catch (error) {
      Promise.reject(dispatch({ type: 'LOAD_ACCOUNT_MANAGERS_FAIL', error }));
    }
  };

  const value = { state, loadAccountManagers };

  return <AccountManagersState.Provider value={value}>{children}</AccountManagersState.Provider>;
};
export default AccountManagerProvider;
