import React, { useEffect } from 'react';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';

import SeatingContent from './components/SeatingContent';

const Seating = () => {
  const polyglot = usePolyglot();
  const { resetAllSeatings } = useSeating();

  useEffect(() => {
    return () => resetAllSeatings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title={polyglot.t('booking_tool.seating.card.title')}>
      <CardSection>
        <SeatingContent />
      </CardSection>
    </Card>
  );
};

export default Seating;
