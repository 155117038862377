const v1_post_to = filters => {
  return {
    limit: filters?.limit || 10,
    offset: filters.offset,
    ...(filters.project && { project: filters.project }),
    ...(filters.createdBy && { reporter: filters.createdBy }),
    ...(filters.requestType && { request_type: filters.requestType }),
    ...(filters.status && { status: filters.status }),
    ...(filters.summary && { summary: filters.summary }),
    ...(filters.bid && { bid: filters.bid }),
  };
};

const v1_post_from = data => {
  return {
    data: data.data.map(issue => ({
      issueKey: issue.key,
      summary: issue.fields.summary,
      companyName: issue.fields.company_name,
      reporter: issue.fields.reporter,
      requestType: issue.fields.request_type,
      attachment: issue.fields.attachment,
      bid: issue.fields.bid,
      comment: issue.fields.bid,
      createdAt: issue.fields.created,
      description: issue.fields.description,
      issueType: issue.fields.issuetype,
      mmbCaseLink: issue.fields.mmb_case_link,
      slaClass: issue.fields.sla_class,
      status: issue.fields.status,
      subcategory: issue.fields.subcategory,
      tequilaCompanyUrl: issue.fields.tequilaCompanyUrl,
    })),
    pagination: {
      limit: data.limit,
      offset: data.offset,
      total: data.total,
    },
  };
};

export default {
  v1: {
    post: {
      to: v1_post_to,
      from: v1_post_from,
    },
  },
};
