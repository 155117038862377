import React from 'react';
import * as Yup from 'yup';

import { ReactComponent as IconNumber1 } from 'images/Icons_Number1.svg';
import { ReactComponent as IconNumber2 } from 'images/Icons_Number2.svg';
import { ReactComponent as IconNumber3 } from 'images/Icons_Number3.svg';
import { ReactComponent as IconNumber4 } from 'images/Icons_Number4.svg';

import { DAYS_IN_WEEK } from 'consts/days';
import { isBeforeOrSameDay, isTodayOrLater } from 'utils/widgets';

export const WIDGET_URLS = {
  singleWidget: 'https://widgets.kiwi.com/scripts/widget-single-iframe.js',
  fullWidget: 'https://widgets.kiwi.com/scripts/widget-search-iframe.js',
};

export const WIDGET_SIZE_TYPE = {
  RELATIVE: 'RELATIVE',
  FIXED: 'FIXED',
};

const GeneralSchema = Yup.object().shape({
  general: Yup.object().shape({
    solutionName: Yup.string().required('common.select_solution'),
    companyName: Yup.string(),
  }),
});

const SearchSchema = Yup.object().shape({
  search: Yup.object().shape({
    from: Yup.array(),
    to: Yup.array()
      .test('resultsOnlyWidget', 'builder.search.to.error', function () {
        if (!this.parent.to.length && this.options.context.widgetType.value === 'resultWidget') {
          return false;
        }
        return true;
      })
      .test('singleWidget', 'builder.search.to.error', function () {
        if (!this.parent.to.length && this.options.context.widgetType.value === 'singleWidget') {
          return false;
        }
        return true;
      }),
    returnDateRange: Yup.object()
      .test(
        'Return dates cannot be placed earlier than departure dates',
        'builder.search.return_error',
        function () {
          const { departureDateRange, returnDateRange } = this.parent;
          let latestDepartureDate, latestReturnDate;
          const bothNonEmpty =
            Object.keys(departureDateRange).length && Object.keys(returnDateRange).length;
          if (bothNonEmpty) {
            latestDepartureDate = departureDateRange.to || departureDateRange.from;
            latestReturnDate = returnDateRange.to || returnDateRange.from;
            return isBeforeOrSameDay(latestDepartureDate, latestReturnDate);
          }
          return true;
        },
      )
      .test('Some dates are in the past', 'builder.search.dates_in_past', function () {
        const { returnDateRange } = this.parent;
        const earliestReturnDate = returnDateRange.from;
        if (earliestReturnDate) {
          return isTodayOrLater(earliestReturnDate);
        }
        return true;
      }),
    departureDateRange: Yup.object()
      .test(
        'Departure dates cannot be placed later than return dates',
        'builder.search.departure_error',
        function () {
          const { departureDateRange, returnDateRange } = this.parent;
          let earliestDepartureDate, earliestReturnDate;
          const bothNonEmpty =
            Object.keys(departureDateRange).length && Object.keys(returnDateRange).length;
          if (bothNonEmpty) {
            earliestDepartureDate = departureDateRange.from;
            earliestReturnDate = returnDateRange.from;
            return isBeforeOrSameDay(earliestDepartureDate, earliestReturnDate);
          }
          return true;
        },
      )
      .test('Some dates are in the past', 'builder.search.dates_in_past', function () {
        const { departureDateRange } = this.parent;
        const earliestDepartureDate = departureDateRange.from;
        if (earliestDepartureDate) {
          return isTodayOrLater(earliestDepartureDate);
        }
        return true;
      }),
    cabin: Yup.array(),
    transport: Yup.array(),
  }),
});

const AdditionalSearchFiltersSchema = Yup.object().shape({
  additionalSearchFilters: Yup.object().shape({
    carriers: Yup.array().test(
      'Some carriers have to be selected!',
      'builder.asf.no_carriers_selected_error',
      function () {
        const { carriers, exclusion } = this.parent;
        return !!carriers.length || exclusion;
      },
    ),
    tileLimit: Yup.string().test('Digits only', 'Must be a number between 1 and 100', value => {
      if (value) {
        const minMax = Number(value) >= 1 && Number(value) <= 100;
        return /^\d+$/.test(value) && minMax;
      }

      return true;
    }),
    limit: Yup.string().test('Digits only', 'Must be a number between 1 and 100', value => {
      if (value) {
        const minMax = Number(value) >= 1 && Number(value) <= 100;
        return /^\d+$/.test(value) && minMax;
      }

      return true;
    }),
  }),
});

const AppearanceSchema = Yup.object().shape({
  appearance: Yup.object().shape({
    size: Yup.number().when('sizeType', {
      is: WIDGET_SIZE_TYPE.RELATIVE,
      then: Yup.number()
        .min(1, 'builder.appearance.size_type_relative_error')
        .max(100, 'builder.appearance.size_type_relative_error'),
      otherwise: Yup.number().min(320, 'builder.appearance.size_type_fixed_error'),
    }),
    sizeType: Yup.string().oneOf([WIDGET_SIZE_TYPE.RELATIVE, WIDGET_SIZE_TYPE.FIXED]),
    colors: Yup.object().shape({
      resultBackground: Yup.lazy(() =>
        Yup.string().when(['searchFormBackground', 'resultBackground'], {
          is: (a, b) => !a || b,
          then: Yup.string(),
          otherwise: Yup.string().required('builder.appearance.result_background.error'),
        }),
      ),
      searchFormBackground: Yup.lazy(() =>
        Yup.string().when(['resultBackground', 'searchFormBackground'], {
          is: (a, b) => !a || b,
          then: Yup.string(),
          otherwise: Yup.string().required('builder.appearance.search_form_background.error'),
        }),
      ),
      searchFormSelectedText: Yup.lazy(() =>
        Yup.string().when(['searchFormSecondaryText', 'searchFormSelectedText'], {
          is: (a, b) => !a || b,
          then: Yup.string(),
          otherwise: Yup.string().required('builder.appearance.search_form_selected_text.error'),
        }),
      ),
      searchFormSecondaryText: Yup.lazy(() =>
        Yup.string().when(['searchFormSelectedText', 'searchFormSecondaryText'], {
          is: (a, b) => !a || b,
          then: Yup.string(),
          otherwise: Yup.string().required('builder.appearance.search_form_secondary_text.error'),
        }),
      ),
    }),
  }),
});

const WidgetTypeSchema = Yup.object().shape({
  widgetType: Yup.object().shape({
    value: Yup.string().required('common.select_application'),
  }),
});

const AdvancedSchema = Yup.object().shape({
  advanced: Yup.object().shape({
    containerId: Yup.string(),
    iframeId: Yup.string().test(
      'no-id-overlap',
      'IFrame ID can not be the same as the Container ID',
      function (iframeId) {
        if (!iframeId) {
          return true;
        }

        return iframeId !== this.parent.containerId;
      },
    ),
  }),
});

export const WidgetBuilderSchema = GeneralSchema.concat(SearchSchema)
  .concat(AdditionalSearchFiltersSchema)
  .concat(AppearanceSchema)
  .concat(WidgetTypeSchema)
  .concat(AdvancedSchema);

export const initialValues = {
  general: {
    solutionName: '',
    affilId: '',
    currency: 'EUR',
    language: 'en',
    autoDetectLang: false,
    autoDetectCurrency: false,
  },
  widgetType: {
    value: '',
  },
  search: {
    from: [],
    to: [],
    tripType: 'one-way',
    departureDateRange: {},
    returnDateRange: {},
    cabin: [],
    passengersAndBags: { adults: 1, children: 0, infants: 0, cabinBags: 0, checkedBags: 0 },
    transport: [],
  },
  additionalSearchFilters: {
    stopNumber: -1,
    limit: '',
    tileLimit: '',
    daysOfTravel: {
      departure: DAYS_IN_WEEK.map(d => d.value),
      return: DAYS_IN_WEEK.map(d => d.value),
    },
    carriers: [],
    exclusion: true,
    sortBy: '',
  },
  appearance: {
    size: '',
    sizeType: WIDGET_SIZE_TYPE.RELATIVE,
    colors: {},
  },
  advanced: {
    brandId: '',
    containerId: '',
    iframeId: '',
  },
};

export const sectionValuesByName = {
  general: {
    nextSectionName: 'widgetType',
    blockerSectionName: undefined,
    icon: <IconNumber1 />,
    label: 'builder.general',
    doneButtonTooltipText: 'builder.button_tooltip_general',
    isPrimarySection: true,
  },
  widgetType: {
    nextSectionName: 'search',
    blockerSectionName: 'general',
    icon: <IconNumber2 />,
    label: 'builder.widget_type.title',
    doneButtonTooltipText: 'builder.button_tooltip_widget_type',
    isPrimarySection: true,
  },
  search: {
    nextSectionName: 'additionalSearchFilters',
    blockerSectionName: 'widgetType',
    icon: <IconNumber3 />,
    label: 'builder.search.title',
    doneButtonTooltipText: 'builder.button_tooltip_search',
    isPrimarySection: true,
  },
  additionalSearchFilters: {
    nextSectionName: undefined,
    blockerSectionName: 'widgetType',
    icon: <IconNumber4 />,
    label: 'builder.asf.title',
    isPrimarySection: true,
  },
  appearance: {
    nextSectionName: undefined,
    blockerSectionName: 'widgetType',
    label: 'builder.appearance',
  },
  advanced: {
    nextSectionName: undefined,
    blockerSectionName: 'widgetType',
    label: 'builder.advanced.title',
  },
};

export const cabinOptions = [
  {
    label: 'searchAndBooking.cabinClassTypes.economy',
    value: 'ECONOMY',
  },
  {
    label: 'searchAndBooking.cabinClassTypes.premium_economy',
    value: 'PREMIUM_ECONOMY',
  },
  {
    label: 'searchAndBooking.cabinClassTypes.business',
    value: 'BUSINESS',
  },
  {
    label: 'searchAndBooking.cabinClassTypes.first_class',
    value: 'FIRST_CLASS',
  },
];

export const transportOptions = [
  {
    label: 'searchAndBooking.vehicleTypes.flight',
    value: 'FLIGHT',
  },
  {
    label: 'searchAndBooking.vehicleTypes.bus',
    value: 'BUS',
  },
  {
    label: 'searchAndBooking.vehicleTypes.train',
    value: 'TRAIN',
  },
];

export const appearanceFirstRow = [
  {
    fieldName: 'primaryColor',
    placeholder: 'e.g. #000000',
    label: 'Primary',
  },
  {
    fieldName: 'resultBackground',
    placeholder: 'e.g. #000000',
    label: 'Result background',
  },
  {
    fieldName: 'searchFormBackground',
    placeholder: 'e.g. #000000',
    label: 'Search form background',
  },
];

export const appearanceSecondRow = [
  {
    fieldName: 'searchFormSelectedText',
    placeholder: 'e.g. #000000',
    label: 'Search form selected text',
  },
  {
    fieldName: 'searchFormSecondaryText',
    placeholder: 'e.g. #000000',
    label: 'Search form secondary text',
  },
];
