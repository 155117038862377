import PropTypes from 'prop-types';
import CloseCircle from '@kiwicom/orbit-components/lib/icons/CloseCircle';

export const AutocompleteItem = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.func,
};

export const autocompleteItems = [
  {
    label: 'booking',
    value: 'bookings',
    icon: CloseCircle,
  },
  {
    label: 'booking id',
    value: 'bid',
    icon: CloseCircle,
  },
  {
    label: 'booking clicks',
    value: 'bid clicks',
  },
];

export const AutocompleteValue = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export const autocompleteValue = {
  label: 'booking',
  value: 'bookings',
};
