import styled from 'styled-components';

export const IconContainer = styled.div`
  position: absolute;
  right: 34px;
  top: 23px;
`;

export const VoucherContainer = styled.div`
  position: relative;
  display: inline-block;
  border: 1px dashed #bac7d5;

  ${({ isStandard }) => (isStandard ? `background-color: #ecf8f7;` : null)};

  ${({ size }) => `
    width: ${size.width};
    height: ${size.height};`}

  background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};

  * {
    font-family: Circular Pro;
    box-sizing: border-box;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 16px 0 0 24px;
  width: 318px;
  height: 68px;
  background-color: #ffffff;

  > :nth-child(2) {
    margin-left: 3px;
  }
`;

export const PrimaryTextContainer = styled.div`
  margin-top: 17px;
  margin-left: 24px;
  width: 221px;
`;

export const SecondaryTextContainer = styled.div`
  width: 221px;
  margin-top: 17px;
  margin-left: 24px;

  ${PrimaryTextContainer} + & {
    margin-top: 11px;
  }
`;

export const ScanCodeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 96px;
  height: 244px;
  background-color: #ffffff;
`;

export const FitContentWidthContainer = styled.div`
  > :first-child {
    > :first-child {
      > :first-child {
        min-width: fit-content;
      }
    }
  }
`;

export const LogoImg = styled.div`
  display: inline-block;
  width: 131px;
  height: 52px;
  margin-right: 13px;

  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const LogoImgContainer = styled.div`
  position: absolute;
  left: 24px;
  bottom: 24px;
`;
