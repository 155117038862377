import React, { useContext } from 'react';
import * as Yup from 'yup';
import format from 'date-fns/format';
import styled from 'styled-components';
import { Formik, ErrorMessage } from 'formik';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Button from '@kiwicom/orbit-components/lib/Button';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import Select from '@kiwicom/orbit-components/lib/Select';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import useToasts from 'components/services/toasts/useToasts';
import { CommissionsState } from 'components/services/commercials';
import { usePolyglot } from 'components/services/i18n';
import { countriesWithDefault as countries } from 'consts/countries';
import { OFFERS_I18N } from 'consts/offers';
import { CONTENT_TYPES } from 'consts/markets';
import { DayPickerButton, ErrorMessageComponent, Space, SelectTime } from 'common';

const DayPickerContainer = styled.div`
  width: 100%;

  & > div {
    width: 100%;
  }
`;

const SelectTimeContainer = styled.div`
  width: 100%;

  & > div:first-of-type {
    max-width: 136px;
  }
`;

const SolutionSchema = Yup.object().shape({
  market: Yup.string().required('validation.cant_be_empty'),
  contentType: Yup.string().required('validation.cant_be_empty'),
  offerType: Yup.string().required('validation.cant_be_empty'),
  offerValue: Yup.number().required('validation.cant_be_empty'),
  validFrom: Yup.string().required('validation.cant_be_empty'),
  validTo: Yup.string(),
  validFromTime: Yup.string().required('validation.cant_be_empty'),
  validToTime: Yup.string().test('greater', 'commercials.greater', function (value) {
    const validTo = this.parent.validTo && format(new Date(this.parent.validTo), 'yyyy-MM-dd');
    const validFrom =
      this.parent.validFrom && format(new Date(this.parent.validFrom), 'yyyy-MM-dd');
    if (validTo && validTo === validFrom) {
      return value > this.parent.validFromTime;
    }
    return true;
  }),
});

const CommercialsForm = ({ affilId, onClose, commercial, onSuccess, uuid }) => {
  const polyglot = usePolyglot();
  const {
    state: { creating, editing },
    createCommission,
    editCommission,
  } = useContext(CommissionsState);
  const { addErrorToast } = useToasts();
  const editMode = Object.keys(commercial).length > 0;

  const [validFrom, validFromTime] =
    (commercial.validFrom && commercial.validFrom.split(' ')) || [];
  const [validTo, validToTime] = (commercial.validTo && commercial.validTo.split(' ')) || [];

  const market = (
    countries.find(c => c.label === commercial.name) || { value: 'default', label: 'Default' }
  ).value;

  const handleSubmitCommercial = async values => {
    try {
      if (editMode) {
        await editCommission(values, affilId, uuid);
      } else {
        await createCommission(values, affilId, uuid);
      }

      onSuccess();
    } catch (error) {
      if (error.status === 409) {
        return addErrorToast(polyglot.t('commercials.conflict'));
      }

      addErrorToast(polyglot.t('common.submit_error_message'));
    }
  };

  return (
    <Portal>
      <Modal size="normal" onClose={onClose}>
        <ModalHeader
          title={polyglot.t(editMode ? 'commercials.edit_title' : 'commercials.add_commercial')}
        />
        <Formik
          initialValues={{
            market: market,
            offerId: commercial?.offer?.id || null,
            offerType: editMode ? commercial?.offer?.type : '',
            offerValue: editMode ? commercial?.offer?.value : '',
            validFrom: validFrom || '',
            validTo: validTo || '',
            contentType: commercial.contentType || 'all',
            validFromTime: validFromTime || '00:00',
            validToTime: validToTime || '00:00',
            id: commercial.id,
            cpsFlat: commercial.cpsFlat,
            cpsPercent: commercial.cpsPercent,
            cpcFlat: commercial.cpcFlat,
            cpsPercentMin: commercial?.cpsPercentMin || null,
            cpsPercentMax: commercial?.cpsPercentMax || null,
          }}
          validationSchema={SolutionSchema}
          onSubmit={handleSubmitCommercial}
        >
          {({ handleChange, setFieldValue, handleSubmit, values, errors, touched }) => {
            return (
              <>
                <ModalSection>
                  <Stack spacing="medium">
                    <Stack direction="row" spacing="XSmall" justify="between">
                      <Select
                        label={polyglot.t('market.market')}
                        options={countries.map(({ label, value }) => ({
                          label,
                          value,
                        }))}
                        name="market"
                        value={values.market}
                        disabled={editMode}
                        onChange={handleChange}
                        error={touched.market && errors.market && polyglot.t(errors.market)}
                      />

                      <Select
                        label={polyglot.t('commercials.content_type')}
                        options={CONTENT_TYPES.map(({ label, value }) => ({
                          label,
                          value,
                        }))}
                        name="contentType"
                        value={values.contentType}
                        onChange={handleChange}
                        disabled={editMode}
                        error={
                          touched.contentType &&
                          errors.contentType &&
                          polyglot.t(errors.contentType)
                        }
                      />
                    </Stack>

                    <Stack direction="row" spacing="XSmall" justify="between">
                      <Select
                        name="offerType"
                        label={polyglot.t('offers.offer_type')}
                        options={Object.keys(OFFERS_I18N).map(key => ({
                          label: polyglot.t(OFFERS_I18N[key]),
                          value: key,
                        }))}
                        value={values.offerType}
                        disabled={editMode}
                        onChange={handleChange}
                        placeholder="Select value from list"
                        error={
                          touched.offerType && errors.offerType && polyglot.t(errors.offerType)
                        }
                      />
                      <InputField
                        type="text"
                        name="offerValue"
                        label={polyglot.t('offers.offer_value')}
                        value={values.offerValue}
                        onChange={e => {
                          setFieldValue(`${values.offerType}`, e.target.value);
                          setFieldValue('offerValue', e.target.value);
                        }}
                        disabled={!values.offerType || editMode}
                        error={
                          touched.offerValue && errors.offerValue && polyglot.t(errors.offerValue)
                        }
                      />
                    </Stack>

                    {values.offerType === 'cpsPercent' && (
                      <Stack direction="row" spacing="XSmall" justify="between">
                        <InputField
                          type="text"
                          name="cpsPercentMin"
                          label={polyglot.t('offers.cost_per_sale_percent.min')}
                          value={values.cpsPercentMin}
                          onChange={e => {
                            setFieldValue('cpsPercentMin', e.target.value);
                          }}
                          disabled={!values.offerType || editMode}
                        />
                        <InputField
                          type="text"
                          name="cpsPercentMax"
                          label={polyglot.t('offers.cost_per_sale_percent.max')}
                          value={values.cpsPercentMax}
                          onChange={e => {
                            setFieldValue('cpsPercentMax', e.target.value);
                          }}
                          disabled={!values.offerType || editMode}
                        />
                      </Stack>
                    )}

                    <Stack direction="row" spacing="XSmall" justify="between">
                      <DayPickerContainer data-test="commercials-valid-from-datepicker-container">
                        <DayPickerButton
                          name="validFrom"
                          label={polyglot.t('commercials.valid_from')}
                          onSelect={e => setFieldValue('validFrom', e)}
                          onReset={e => setFieldValue('validFrom', '')}
                          value={
                            values.validFrom && format(new Date(values.validFrom), 'dd. MM. yyyy.')
                          }
                          hideOnDayClick={true}
                          disabledDays={[
                            {
                              after: new Date(values.validTo),
                            },
                          ]}
                        />
                        <ErrorMessage
                          name="validFrom"
                          render={msg => (
                            <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>
                          )}
                        />
                      </DayPickerContainer>

                      <DayPickerContainer data-test="commercials-valid-to-datepicker-container">
                        <DayPickerButton
                          name="validTo"
                          label={polyglot.t('commercials.valid_to')}
                          onSelect={e => setFieldValue('validTo', e)}
                          onReset={e => setFieldValue('validTo', '')}
                          value={
                            values.validTo && format(new Date(values.validTo), 'dd. MM. yyyy.')
                          }
                          hideOnDayClick={true}
                          disabledDays={[
                            {
                              before: new Date(values.validFrom),
                            },
                          ]}
                        />
                        <ErrorMessage
                          name="validTo"
                          render={msg => (
                            <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>
                          )}
                        />
                      </DayPickerContainer>
                    </Stack>

                    <Stack direction="row" spacing="XSmall">
                      <SelectTimeContainer>
                        <div>
                          <Space after="s">{polyglot.t('common.time')}</Space>
                          <SelectTime
                            name="validFromTime"
                            time={values.validFromTime}
                            onChange={e => setFieldValue('validFromTime', e)}
                          />
                          <ErrorMessage
                            name="validFromTime"
                            render={msg => (
                              <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>
                            )}
                          />
                        </div>
                      </SelectTimeContainer>
                      <SelectTimeContainer>
                        <div>
                          <Space after="s">{polyglot.t('common.time')}</Space>
                          <SelectTime
                            name="validToTime"
                            time={values.validToTime}
                            onChange={e => setFieldValue('validToTime', e)}
                          />
                        </div>
                        <ErrorMessage
                          name="validToTime"
                          render={msg => (
                            <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>
                          )}
                        />
                      </SelectTimeContainer>
                    </Stack>

                    <Badge type="info" size="small" icon={<InformationCircleIcon />}>
                      {polyglot.t('commercials.choose_relative_to_timezone', {
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                      })}
                    </Badge>
                  </Stack>
                </ModalSection>
                <ModalFooter>
                  <Button type="secondary" width="128px" onClick={onClose}>
                    {polyglot.t('common.cancel')}
                  </Button>
                  <Button loading={creating || editing} onClick={handleSubmit} width="128px">
                    {polyglot.t('common.save')}
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </Modal>
    </Portal>
  );
};

export default CommercialsForm;
