import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';

import { Space, LocationsAutocomplete } from 'common';
import { usePolyglot } from 'components/services/i18n';
import { SEARCH_LOCATION_TYPES } from 'consts/search';

import CalendarDropdown from '../../CalendarDropdown';
import { TripContainer } from './Trip.styled';

const Trip = ({
  removeMulticityTrip,
  trip,
  addMulticityLocation,
  removeMulticityLocation,
  changeMulticityDepartureSelectedDates,
  removeDisabled,
  index,
}) => {
  const polyglot = usePolyglot();

  const removeTrip = () => {
    removeMulticityTrip(trip.id);
  };

  const selectFrom = location => {
    addMulticityLocation(trip.id, location, 'locationsFrom');
  };

  const unselectFrom = location => {
    removeMulticityLocation(trip.id, location, 'locationsFrom');
  };

  const selectTo = location => {
    addMulticityLocation(trip.id, location, 'locationsTo');
  };

  const unselectTo = location => {
    removeMulticityLocation(trip.id, location, 'locationsTo');
  };

  const changeDepartureDates = dates => {
    changeMulticityDepartureSelectedDates(trip.id, dates);
  };

  return (
    <TripContainer>
      <Space right="m">
        <LocationsAutocomplete
          onSelect={selectFrom}
          onUnselect={unselectFrom}
          selectedValues={trip.locationsFrom}
          label={<Text type="secondary">{polyglot.t('common.from')}</Text>}
          inlineLabel
          locationTypes={SEARCH_LOCATION_TYPES}
        />
      </Space>
      <Space right="m">
        <LocationsAutocomplete
          onSelect={selectTo}
          onUnselect={unselectTo}
          selectedValues={trip.locationsTo}
          label={<Text type="secondary">{polyglot.t('common.to')}</Text>}
          inlineLabel
          locationTypes={SEARCH_LOCATION_TYPES}
        />
      </Space>
      <Space right="m">
        <CalendarDropdown
          prefix={polyglot.t('searchAndBooking.departure')}
          selectedDates={trip.departureSelectedDates}
          changeSelectedDates={changeDepartureDates}
          disabledDates={trip.disabledDates}
          firstMonth={trip.firstMonth}
          dataTest={`departure-${index}`}
        />
      </Space>
      <Button title="close" disabled={removeDisabled} onClick={removeTrip}>
        <CloseIcon />
      </Button>
    </TripContainer>
  );
};

Trip.propTypes = {
  trip: PropTypes.object,
  addMulticityLocation: PropTypes.func.isRequired,
  removeMulticityLocation: PropTypes.func.isRequired,
};

export default Trip;
