import React from 'react';

const Illustration1 = ({ color1, color2 }) => (
  <svg id="ill1_pref__Layer_10" data-name="Layer 10" viewBox="0 0 2200 1300">
    <defs>
      <linearGradient
        id="ill1_pref__linear-gradient_ill1"
        x1={-1468.54}
        y1={1384.66}
        x2={-1468.54}
        y2={645.03}
        gradientTransform="matrix(-1 .06 .06 1 -658.17 21.02)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={0.56} stopColor="#fff" />
        <stop offset={0.99} />
      </linearGradient>
      <linearGradient
        id="ill1_pref__linear-gradient_ill1-2"
        x1={1091.97}
        y1={640.45}
        x2={1826.18}
        y2={1012.42}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" />
        <stop offset={0.04} stopColor="#bac7d5" stopOpacity={0.96} />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill1_pref__linear-gradient_ill1-3"
        x1={-5847.19}
        y1={369.19}
        x2={-4501.55}
        y2={1173.06}
        gradientTransform="translate(6171.35)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009882" />
        <stop offset={1} stopColor="#01a891" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill1_pref__linear-gradient_ill1-4"
        x1={1336.18}
        y1={1160.61}
        x2={301.9}
        y2={619.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" stopOpacity={0} />
        <stop offset={0.5} stopColor="#bac7d5" />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <filter
        id="ill1_pref__luminosity-noclip"
        x={68.64}
        y={-11245.45}
        width={1605.37}
        height={32766}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="SourceGraphic" in2="bg" />
      </filter>
      <filter
        id="ill1_pref__luminosity-noclip-2"
        x={68.64}
        y={548.42}
        width={1605.37}
        height={752.31}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="SourceGraphic" in2="bg" />
      </filter>
      <style>
        {
          '.ill1_pref__cls-7{fill:#cad5df}.ill1_pref__cls-8{fill:#8598af}.ill1_pref__cls-9{fill:#a6b5c8}.ill1_pref__cls-10{fill:#3c3c3b}.ill1_pref__cls-13{opacity:.5}.ill1_pref__cls-14{fill:#37404b}.ill1_pref__cls-19{fill:#e8e8e8}.ill1_pref__cls-20{fill:#f0f0f0}.ill1_pref__cls-21{fill:#e5eaee}.ill1_pref__cls-22{fill:#f7931e}'
        }
      </style>
      <mask
        id="ill1_pref__mask-2"
        x={68.64}
        y={548.42}
        width={1605.37}
        height={752.31}
        maskUnits="userSpaceOnUse"
      >
        <g mask="url(#ill1_pref__mask)" filter="url(#ill1_pref__luminosity-noclip-2)">
          <path
            style={{
              mixBlendMode: 'multiply',
            }}
            fill="url(#ill1_pref__linear-gradient_ill1)"
            d="M193.16 548.42L1674 1285.04l-252.74 15.7L68.64 627.99l124.52-79.57z"
          />
        </g>
      </mask>
    </defs>
    <path
      d="M1940.1 1040.42l-30 23.93-898.18-434.74 42-17.92z"
      opacity={0.3}
      fill="url(#ill1_pref__linear-gradient_ill1-2)"
      fillRule="evenodd"
    />
    <path fill="#ededed" d="M1105.99 639.03l-37.92 18.47-57.52-28.18 57.52-38.23 37.92 47.94z" />
    <path
      fill="#e8edf1"
      d="M1101.49 55.65l-33.42 12.22-10.91-2.4-46.61-25.78 37.6-18.25 57.84 27.96-4.5 6.25z"
    />
    <path
      fill="#d8e1e8"
      d="M1069.37 653.77l1.46-579.5-2.76-6.4-24.82-12.16-32.7-16.02v589.63l57.52 28.18 1.3-3.73z"
    />
    <path className="ill1_pref__cls-7" d="M1068.07 657.5V67.87l37.92-18.47v589.63l-37.92 18.47z" />
    <path
      className="ill1_pref__cls-8"
      d="M1055.09 115.72l-30.47-14.89c-2.57-1.26-4.66.13-4.66 3.11V192a9.28 9.28 0 004.66 7.67l30.47 14.89c2.58 1.26 4.67-.14 4.67-3.11v-88.06a9.26 9.26 0 00-4.67-7.67z"
    />
    <path
      className="ill1_pref__cls-8"
      d="M1027.66 102.31l-6.28-1.55-4.2 2.8 8.43 2.29 2.05-3.54zM1058.55 214.49l-7.32-2.14-4.21 2.8 7.19 2.2 4.34-2.86z"
    />
    <path
      className="ill1_pref__cls-9"
      d="M1051.15 217.18l-30.47-14.89a9.25 9.25 0 01-4.67-7.66v-88.08c0-3 2.09-4.37 4.67-3.11l30.47 14.89a9.27 9.27 0 014.66 7.67v88.08c0 2.97-2.09 4.36-4.66 3.1z"
    />
    <path
      className="ill1_pref__cls-10"
      d="M965.4 180c0 3.15-2.21 4.24-4.95 2.42a12.2 12.2 0 01-5-9c0-3.16 2.22-4.24 5-2.42a12.15 12.15 0 014.95 9z"
    />
    <path
      className="ill1_pref__cls-8"
      d="M1038.14 143.27a12.18 12.18 0 00-5-9 3.64 3.64 0 00-3.41-.69l-.19.1-72.64 36.66 7.17 12.66 72.3-36.52a2.15 2.15 0 00.67-.35 3.41 3.41 0 001.1-2.86z"
    />
    <path
      className="ill1_pref__cls-10"
      d="M965.4 217.78c0 3.15-2.21 4.24-4.95 2.41a12.16 12.16 0 01-5-9c0-3.16 2.22-4.24 5-2.42a12.15 12.15 0 014.95 9.01z"
    />
    <path
      className="ill1_pref__cls-8"
      d="M1038.14 181.06a12.18 12.18 0 00-5-9 3.64 3.64 0 00-3.41-.69l-.19.1-72.64 36.66 7.17 12.65 72.3-36.53a1.93 1.93 0 00.67-.34 3.41 3.41 0 001.1-2.85z"
    />
    <path fill="#6d809c" d="M724.74 396.87L966.26 279.9V93.82L724.74 210.79v186.08z" />
    <path fill={color1} d="M746.52 364.37l197.96-95.71V128.49L746.52 224.2v140.17z" />
    <g className="ill1_pref__cls-13">
      <path
        className="ill1_pref__cls-14"
        d="M955.5 137.29l10.76-5.25v-4.1l-10.76 5.25v4.1zM955.5 145.09l10.76-5.26v-4.1l-10.76 5.25v4.11zM955.5 152.88l10.76-5.25v-4.1l-10.76 5.25v4.1zM955.5 160.68l10.76-5.26v-4.1l-10.76 5.25v4.11zM955.5 168.47l10.76-5.25v-4.1l-10.76 5.25v4.1zM955.5 176.27l10.76-5.25v-4.11l-10.76 5.25v4.11zM955.5 184.06l10.76-5.25v-4.1l-10.76 5.25v4.1zM955.5 191.86l10.76-5.25v-4.11l-10.76 5.26v4.1zM955.5 199.66l10.76-5.26v-4.1l-10.76 5.25v4.11zM955.5 207.45l10.76-5.25v-4.11l-10.76 5.26v4.1z"
      />
    </g>
    <g className="ill1_pref__cls-13">
      <path
        className="ill1_pref__cls-14"
        d="M724.74 249.01l10.75-5.25v-4.1l-10.75 5.25v4.1zM724.74 256.81l10.75-5.25v-4.11l-10.75 5.26v4.1zM724.74 264.6l10.75-5.25v-4.1l-10.75 5.25v4.1zM724.74 272.4l10.75-5.25v-4.11l-10.75 5.26v4.1zM724.74 280.19l10.75-5.25v-4.1l-10.75 5.25v4.1zM724.74 287.99l10.75-5.25v-4.1l-10.75 5.25v4.1zM724.74 295.79l10.75-5.26v-4.1l-10.75 5.25v4.11zM724.74 303.58l10.75-5.25v-4.1l-10.75 5.25v4.1zM724.74 311.38l10.75-5.25v-4.11l-10.75 5.25v4.11zM724.74 319.17l10.75-5.25v-4.1l-10.75 5.25v4.1z"
      />
    </g>
    <path
      className="ill1_pref__cls-9"
      d="M966.25 93.81L724.74 210.79l-10.19-5.17 239.69-117.7 12.01 5.89z"
    />
    <path fill="#5f728c" d="M714.55 391.92v-186.3l10.19 5.17v186.08l-10.19-4.95z" />
    <g className="ill1_pref__cls-13">
      <path
        className="ill1_pref__cls-14"
        d="M719.35 246.43l5.39 2.65v-4.1l-5.39-2.66v4.11zM719.35 254.22l5.39 2.66v-4.11l-5.39-2.65v4.1zM719.35 262.02l5.39 2.65v-4.1l-5.39-2.66v4.11zM719.35 269.81l5.39 2.66v-4.1l-5.39-2.66v4.1zM719.35 277.61l5.39 2.65v-4.1l-5.39-2.66v4.11zM719.35 285.4l5.39 2.66v-4.1l-5.39-2.66v4.1zM719.35 293.2l5.39 2.65v-4.1l-5.39-2.65v4.1zM719.35 300.99l5.39 2.66v-4.1l-5.39-2.66v4.1zM719.35 308.79l5.39 2.65v-4.1l-5.39-2.65v4.1zM719.35 316.58l5.39 2.66v-4.1l-5.39-2.66v4.1z"
      />
    </g>
    <path
      fill="url(#ill1_pref__linear-gradient_ill1-3)"
      d="M1969.54 1153L763.08 555.6l66.71-39.57-331.58-6.99 33.95 183.52 75.21-44.61 1205.52 597.46 156.65-92.41z"
    />
    <g mask="url(#ill1_pref__mask-2)">
      <path
        fill="url(#ill1_pref__linear-gradient_ill1-4)"
        d="M206.65 554.88l1398.47 690.53-61.29 38.34L124.88 582.86l81.77-27.98z"
      />
    </g>
    <path
      className="ill1_pref__cls-19"
      d="M1296.36 954.62v285.11c0 1.46 1.11 2.91 3.33 4a20.2 20.2 0 0016.06 0c2.21-1.11 3.32-2.56 3.32-4V954.62z"
    />
    <ellipse className="ill1_pref__cls-20" cx={1307.72} cy={954.62} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-19"
      d="M980.6 804v285.11c0 1.45 1.1 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.1 3.32-2.56 3.32-4V804z"
    />
    <ellipse className="ill1_pref__cls-20" cx={991.95} cy={803.98} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-7"
      d="M1296.36 954.62v285.12c0 1.45 1.11 2.9 3.33 4a20.17 20.17 0 0016 0c2.22-1.11 3.33-2.56 3.33-4V954.62z"
    />
    <ellipse className="ill1_pref__cls-21" cx={1307.72} cy={954.62} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-22"
      d="M1296.36 1053.98L999.88 905.17v-47.96l296.48 148.81v47.96z"
    />
    <path
      className="ill1_pref__cls-7"
      d="M693.64 649.51v292.12c0 1.45 1.11 2.9 3.33 4a20.2 20.2 0 0016.06 0c2.21-1.11 3.32-2.56 3.32-4V649.51z"
    />
    <ellipse className="ill1_pref__cls-21" cx={705} cy={649.51} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-7"
      d="M980.6 804.32v285.11c0 1.46 1.1 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.32-2.56 3.32-4V804.32z"
    />
    <ellipse className="ill1_pref__cls-21" cx={991.95} cy={804.32} rx={11.35} ry={5.68} />
    <path className="ill1_pref__cls-22" d="M980.6 897.08L712.3 762.3v-47.95l268.3 134.77v47.96z" />
    <path
      className="ill1_pref__cls-19"
      d="M1659.79 647.43v285.11c0 1.45 1.11 2.91 3.33 4a20.17 20.17 0 0016.05 0c2.22-1.1 3.33-2.56 3.33-4V647.43z"
    />
    <ellipse className="ill1_pref__cls-20" cx={1671.15} cy={647.43} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-19"
      d="M1344 497.13v285.11c0 1.45 1.11 2.91 3.33 4a20.2 20.2 0 0016.06 0c2.21-1.1 3.32-2.56 3.32-4V497.13z"
    />
    <ellipse className="ill1_pref__cls-20" cx={1355.38} cy={497.13} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-7"
      d="M1659.79 647.77v285.11c0 1.46 1.11 2.91 3.32 4a20.28 20.28 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4V647.77z"
    />
    <ellipse className="ill1_pref__cls-21" cx={1671.14} cy={647.77} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-22"
      d="M1659.79 744.08l-293.06-146.86v-45.76l293.06 146.86v45.76z"
    />
    <path
      className="ill1_pref__cls-7"
      d="M1344 497.21v285.11c0 1.46 1.11 2.91 3.33 4a20.26 20.26 0 0016.05 0c2.22-1.11 3.33-2.56 3.33-4V497.21z"
    />
    <ellipse className="ill1_pref__cls-21" cx={1355.38} cy={497.21} rx={11.35} ry={5.68} />
    <path
      className="ill1_pref__cls-22"
      d="M1344.02 587.77L1080.4 455.9v-45.76l263.62 131.87v45.76z"
    />
    <ellipse
      cx={851.92}
      cy={246.41}
      rx={58.4}
      ry={33.04}
      transform="rotate(-66.95 851.906 246.419)"
      fill={color2}
    />
    <path
      d="M854.22 273l7.88-1.54.3-4.53-6.53-1.28v-.05a49.77 49.77 0 00.62-5.53l.63-9.42 20.45-5.84.35-5.3-8.88-.34-3.83-.15-7.29-.28L859 223a13.94 13.94 0 00-.48-5.5c-.56-1.52-1.53-2-2.61-1.4s-2.18 2.11-3 4.36a23.72 23.72 0 00-1.28 6.42l-1.07 15.92-21.3 22.45-.36 5.3 20.87-15.92-.63 9.36a40.38 40.38 0 00-.09 5.25l.09.78-7 7.65-.31 4.53 7.94-6.73v-.18l.56 4.52c.21 1.65 2.21.59 2.66-1.4z"
      fillRule="evenodd"
      fill={color1}
    />
  </svg>
);
export default React.memo(Illustration1);
