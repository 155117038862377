import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 880px;
  height: 64px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(23, 27, 30, 0.1);
  border: solid 1px ${({ theme }) => theme.orbit.paletteCloudNormal};
  background-color: ${({ theme }) => theme.orbit.paletteWhite};
`;

export const SortTabContainer = styled.div`
  position: relative;
  width: 33.33%;
  height: 64px;
  cursor: pointer;
  transition: background-color 0.2s ease-in 0.1s;
  &:hover {
    background-color: ${({ theme }) => theme.orbit.backgroundButtonWhiteHover};
  }
`;

export const SortTabContent = styled.div`
  height: 51px;
  overflow: hidden;
  padding: 13px 24px 0px;
  & > div:first-of-type > p:first-of-type {
    ${({ active, theme }) => active && `color: ${theme.orbit.paletteProductNormal};`}
  }
`;

export const ActiveBar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.orbit.paletteProductNormal};
`;
