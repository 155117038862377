import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import GenderWomanIcon from '@kiwicom/orbit-components/lib/icons/GenderWoman';
import GenderManIcon from '@kiwicom/orbit-components/lib/icons/GenderMan';
import InfantIcon from '@kiwicom/orbit-components/lib/icons/Infant';
import ChildIcon from '@kiwicom/orbit-components/lib/icons/Child';

import useBaggageOffer from 'components/services/mmb/useBaggageOffer';
import { usePolyglot } from 'components/services/i18n';

import BaggagePicker from './BaggagePicker';
import ErrorAlert from './ErrorAlert';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Text from '@kiwicom/orbit-components/lib/Text';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import { useDependencyEffect } from 'hooks';

const PaxNameWrapper = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.orbit.colorIconPrimary};
    width: 24px;
    height: 24px;
  }
`;

const PassengerIcon = React.memo(({ size, color, category, title }) => {
  switch (category) {
    case 'adult':
    case 'teen':
      switch (title) {
        case 'mr':
          return <GenderManIcon size={size} customColor={color} dataTest="man" />;
        case 'ms':
          return <GenderWomanIcon size={size} customColor={color} dataTest="woman" />;
        default:
          return null; // should not happen
      }
    case 'child':
      return <ChildIcon size={size} customColor={color} dataTest="child" />;
    case 'infant':
      return <InfantIcon size={size} customColor={color} dataTest="infant" />;
    default:
      return null; // should not happen
  }
});

const AddBaggageModal = ({ onClose, passenger, bid }) => {
  const {
    data: baggage,
    loading,
    payingBaggageError,
    changeHandBag,
    changeHoldBag,
    payBaggage,
    payingBaggage,
  } = useBaggageOffer(bid);

  const { id: passengerId, category: passengerCategory, title: passengerTitle } = passenger;
  const polyglot = usePolyglot();
  const [checked, setChecked] = useState(false);

  const changeHandBagCombination = useCallback(
    nextCombination => {
      if (baggage.passengerSelectedCombinations.handBag[passengerId] === nextCombination) {
        return;
      }

      changeHandBag(nextCombination, passengerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baggage, passengerId],
  );

  const changeHoldBagCombination = useCallback(
    nextCombination => {
      if (baggage.passengerSelectedCombinations.holdBag[passengerId] === nextCombination) {
        return;
      }

      changeHoldBag(nextCombination, passengerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baggage, passengerId],
  );

  const changeBagCombination = (type, index) => {
    if (type === 'handBag') {
      changeHandBagCombination(index);
    } else if (type === 'holdBag') {
      changeHoldBagCombination(index);
    }
  };

  const isDisabled =
    (baggage?.passengerSelectedCombinations?.handBag[passengerId] ===
      baggage?.passengerCombinations?.handBag[passengerId] &&
      baggage?.passengerSelectedCombinations?.holdBag[passengerId] ===
        baggage?.passengerCombinations?.holdBag[passengerId]) ??
    true;

  useDependencyEffect(() => {
    if (isDisabled) {
      setChecked(false);
    }
  }, [isDisabled]);

  return (
    <Modal dataTest="add-bags-modal">
      <ModalHeader title={polyglot.t('mmb.booking_details.passengers.add_baggage')}>
        <Heading type="title2">
          <PaxNameWrapper style={!isDisabled ? { marginBottom: 40 } : null}>
            <PassengerIcon
              size="medium"
              color="black"
              category={passengerCategory}
              title={passengerTitle}
            />
            {passenger.fullName}
          </PaxNameWrapper>
          {!isDisabled && (
            <Alert icon type="warning" title="You have changed your baggage">
              <Text>{polyglot.t('mmb.booking_details.add_baggage_modal.alert_subtitle')}</Text>
            </Alert>
          )}
        </Heading>
      </ModalHeader>
      <ModalSection>
        {payingBaggageError && <ErrorAlert error={payingBaggageError} />}
        {loading ? (
          <Loading type="pageLoader" />
        ) : (
          <>
            <BaggagePicker
              passengerCategory={passengerCategory}
              passengerId={passengerId}
              pickerType="handBag"
              changeBagCombination={changeBagCombination}
              baggage={{ definitions: baggage.definitions, combinations: baggage.combinations }}
              passengerBaggage={{
                handBag: baggage.passengerSelectedCombinations.handBag[passengerId],
                holdBag: baggage.passengerSelectedCombinations.holdBag[passengerId],
              }}
              currentCombination={baggage.passengerCombinations.handBag[passengerId]}
            />
            <BaggagePicker
              passengerCategory={passengerCategory}
              passengerId={passengerId}
              pickerType="holdBag"
              changeBagCombination={changeBagCombination}
              baggage={{ definitions: baggage.definitions, combinations: baggage.combinations }}
              passengerBaggage={{
                handBag: baggage.passengerSelectedCombinations.handBag[passengerId],
                holdBag: baggage.passengerSelectedCombinations.holdBag[passengerId],
              }}
              currentCombination={baggage.passengerCombinations.holdBag[passengerId]}
            />
            {!isDisabled && (
              <Checkbox
                label={polyglot.t('mmb.booking_details.add_baggage_modal.checkbox_label')}
                checked={checked}
                onChange={() => setChecked(isChecked => !isChecked)}
              />
            )}
          </>
        )}
      </ModalSection>

      <ModalFooter>
        <Button type="secondary" size="large" onClick={onClose} dataTest="cancel-btn">
          {polyglot.t('common.cancel')}
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={!checked}
          loading={payingBaggage}
          onClick={() => payBaggage(bid, passengerId, baggage, onClose)}
          dataTest="send-btn"
        >
          {polyglot.t('mmb.booking_details.add_baggage_modal.update_baggage')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(AddBaggageModal);
