import { combineReducers } from 'redux';

import { requestsAdapter } from 'common/Support/utils/jira';
import { SERVICE_DESK } from 'consts/support';

import { createRequest, createReducer, clear } from './requests';

const SHOW_SUPPORT_MODAL = `support/SHOW_SUPPORT_MODAL`;
const HIDE_SUPPORT_MODAL = `support/HIDE_SUPPORT_MODAL`;

export const initialState = {
  isModalVisible: false,
};

export const fieldIds = {
  SUMMARY: 'summary',
  DESCRIPTION: 'description',
  ATTACHMENT: 'attachment',
};

const supportModal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SUPPORT_MODAL:
      return {
        ...state,
        isModalVisible: true,
      };
    case HIDE_SUPPORT_MODAL:
      return {
        ...state,
        isModalVisible: false,
      };
    default:
      return state;
  }
};

export const showSupportModal = () => ({ type: SHOW_SUPPORT_MODAL });

export const hideSupportModal = () => ({ type: HIDE_SUPPORT_MODAL });

const REQUESTS_SCHEMA = `${SERVICE_DESK.BPSD}/requests`;

export const fetchRequests = createRequest(REQUESTS_SCHEMA, 'GET');

export const clearRequests = () => dispatch => dispatch(clear(REQUESTS_SCHEMA));

export const fetchRequestCategories = createRequest(
  `${SERVICE_DESK.BPSD}/requestsCategories`,
  'GET',
);

export const addUserToJiraOrganization = createRequest(`${SERVICE_DESK.BPSD}/customer`, 'POST');

export default combineReducers({
  customer: createReducer(`${SERVICE_DESK.BPSD}/customer`),
  requests: createReducer(REQUESTS_SCHEMA, requestsAdapter),
  requestsCategories: createReducer(`${SERVICE_DESK.BPSD}/requestsCategories`),
  supportModal,
});
