import styled from 'styled-components';

export const StyledLabel = styled.label`
  margin-bottom: 4px;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
`;

export const RequiredStar = styled.span`
  color: ${({ theme }) => theme.orbit.colorTextAlertWarning};
`;
