import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import Polyglot from 'shapes/Polyglot';
import { SupportRequest, RequestComment } from 'shapes/Support';
import { STATUS } from 'redux/modules/apigee/requests';

import Comment from './Comment';

const ListOfMessages = ({
  comments,
  commentsStatus,
  request,
  polyglot,
  attachments,
  serviceDesk,
}) => {
  if (commentsStatus === STATUS.ERROR) {
    return (
      <Stack spaceAfter="large">
        <Alert type="critical">{polyglot.t('support.requestDetailsModal.messagesError')}</Alert>
      </Stack>
    );
  }

  const commentsWithAttachments = comments
    .map(comm => {
      let newComm = { ...comm };

      if (attachments) {
        newComm.attachments = [];
        let newBody = newComm.body
          .split('!')
          .filter(val => !!val)
          .map(val => val.replace('|thumbnail', ''));
        for (const attachment of attachments.value) {
          if (newBody.includes(attachment.filename)) {
            // This removes attachment file name from the comment
            newComm.attachments.push(attachment);
            newBody.splice(newBody.indexOf(attachment.filename), 1);
          }
        }
        newComm.body = newBody.join('');
      } else {
        newComm.body = newComm.body
          .split('\n')
          .filter(el => el[0] !== '!' && el[el.length - 1] !== '!')
          .join('');
      }

      return newComm;
    })
    .filter(comm => !!comm.body || !!comm.attachments);

  return (
    <Stack spaceAfter="large">
      {commentsWithAttachments.map(comment => {
        const {
          id,
          body: text,
          created: { epochMillis: timestamp },
          author: { displayName: author },
        } = comment;
        const datetime = new Date(timestamp);
        return (
          <Comment
            key={id}
            text={text}
            datetime={datetime.toLocaleString()}
            author={author}
            attachments={comment.attachments}
            request={request}
            serviceDesk={serviceDesk}
          />
        );
      })}
      {commentsStatus === STATUS.LOADING && (
        <Loading text={polyglot.t('support.requestDetailsModal.loadingMessages')} />
      )}
    </Stack>
  );
};

ListOfMessages.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  request: PropTypes.shape(SupportRequest),
  comments: PropTypes.arrayOf(PropTypes.shape(RequestComment)),
  commentsStatus: PropTypes.string.isRequired,
};

export default ListOfMessages;
