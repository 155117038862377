import styled, { createGlobalStyle } from 'styled-components';

export const AutocompleteGlobals = createGlobalStyle`
  .autocomplete-input-wrapper {
    padding: 0 !important;
    align-items: baseline;
  }

  .autocomplete-container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    width: 100%;
    margin-top: -1px;

    & > div > div {
      white-space: nowrap;
    }
  }

  .autocomplete-input {
    min-width: 100px;
  }
`;

export const WrapperContainer = styled.div`
  overflow: hidden;
`;
