import React, { useContext, useState, useCallback } from 'react';
import debounce from 'debounce';

import InputFile from '@kiwicom/orbit-components/lib/InputFile';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Tag from '@kiwicom/orbit-components/lib/Tag';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';

import { StackDiv } from './index.styled';

const FileInput = ({
  name,
  value,
  setFieldValue,
  label,
  help,
  spaceAfter,
  disabled,
  maxImageWidth,
  maxImageHeight,
  exactImageWidth,
  exactImageHeight,
  companyName,
  error,
}) => {
  const { uploadFile } = useContext(SmartPassContext);

  const [uploadError, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const polyglot = usePolyglot();

  const handleRemoveFile = () => {
    setFieldValue(name, null);
  };

  const handleUpload = async (imageFile, base64) => {
    setLoading(true);
    try {
      const newFile = await uploadFile(imageFile, base64, companyName);
      setLoading(false);
      setFieldValue(name, newFile);
      setError(null);
    } catch {
      setLoading(false);
      setError(polyglot.t('smart_pass.file_upload_error'));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUploadDebounced = useCallback(debounce(handleUpload, 500), []);

  const handleChange = event => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      let img = new Image();
      img.src = window.URL.createObjectURL(imageFile);
      img.onload = () => {
        const isExactImageWidthError =
          exactImageWidth &&
          exactImageWidth !== img.width &&
          polyglot.t('smart_pass.exact_image_width_error', { width: exactImageWidth });
        const isExactImageHeightError =
          exactImageHeight &&
          exactImageHeight !== img.height &&
          polyglot.t('smart_pass.exact_image_width_error', { width: exactImageWidth });
        const isMaxImageWidthError =
          maxImageWidth &&
          img.width > maxImageWidth &&
          polyglot.t('smart_pass.max_image_width_error', { width: maxImageWidth });
        const isMaxImageHeightError =
          maxImageHeight &&
          img.height > maxImageHeight &&
          polyglot.t('smart_pass.max_image_height_error', { height: maxImageHeight });

        const maxImageWidthOrHeightError = isMaxImageWidthError || isMaxImageHeightError;
        const isMaxImageWidthAndHeightError =
          maxImageWidth &&
          maxImageHeight &&
          maxImageWidthOrHeightError &&
          polyglot.t('smart_pass.max_image_height_and_width_error', {
            height: maxImageHeight,
            width: maxImageWidth,
          });

        const isExactImageWidthAndHeightError =
          isExactImageWidthError &&
          isExactImageHeightError &&
          polyglot.t('smart_pass.image_height_and_width_error', {
            height: exactImageHeight,
            width: exactImageWidth,
          });

        const imageError =
          isExactImageWidthAndHeightError ||
          isExactImageWidthError ||
          isExactImageHeightError ||
          isMaxImageWidthAndHeightError ||
          isMaxImageWidthError ||
          isMaxImageHeightError;

        if (imageError) {
          setError(imageError);
        } else {
          const reader = new FileReader();
          reader.readAsBinaryString(imageFile);
          reader.onload = async readerEvent => {
            const base64 = btoa(readerEvent.target.result);
            handleUploadDebounced(imageFile, base64);
          };
        }
      };
    }
  };

  if (loading) {
    return (
      <StackDiv>
        <Loading type="boxLoader" />
      </StackDiv>
    );
  }

  if (value || disabled) {
    return (
      <InputField
        help={help}
        label={label}
        spaceAfter={spaceAfter}
        name={name}
        placeholder={!value ? polyglot.t('common.no_file_selected') : undefined}
        disabled
        tags={
          value && (
            <Tag selected onRemove={disabled ? undefined : handleRemoveFile}>
              <a href={value.url} target="_blank" rel="noopener noreferrer">
                {value.name}
              </a>
            </Tag>
          )
        }
      />
    );
  }

  let displayedError = '';
  if (error) {
    displayedError = error;
  } else if (uploadError) {
    displayedError = uploadError;
  }

  return (
    <InputFile
      spaceAfter={spaceAfter}
      label={label}
      help={help}
      name={name}
      onChange={handleChange}
      error={displayedError}
      allowedFileTypes={['.png', '.jpg', '.jpeg']}
    />
  );
};

export default FileInput;
