import React from 'react';

import { SEATMAP_ROW_TYPES } from 'consts/seating';

import SeatExitRow from './SeatExitRow';
import SeatSeatingClassRow from './SeatSeatingClassRow';
import SeatGroupTitle from './SeatGroupTitle';
import SeatGroup from './SeatGroup';
import { Row } from '../index.styled';

const SeatRow = ({ activeSegmentCode, row }) => {
  return (
    <Row
      isSeatsRow={row.type === SEATMAP_ROW_TYPES.SEATS}
      isSeatingClassRow={row.type === SEATMAP_ROW_TYPES.SEATING_CLASS}
    >
      {row.type === SEATMAP_ROW_TYPES.EXIT && <SeatExitRow />}
      {row.type === SEATMAP_ROW_TYPES.SEATING_CLASS && (
        <SeatSeatingClassRow range={row.data.range} seatingClass={row.data.seatingClass} />
      )}
      {row.type === SEATMAP_ROW_TYPES.TITLE &&
        row.data?.map((rowGroup, i) => (
          <SeatGroupTitle
            key={`GroupTitle-${i}`}
            rowGroup={rowGroup}
            isLastItem={row.data.length === i + 1}
          />
        ))}
      {row.type === SEATMAP_ROW_TYPES.SEATS &&
        row.data?.map((rowGroup, i) => (
          <SeatGroup
            key={`GroupSeats-${i}`}
            activeSegmentCode={activeSegmentCode}
            rowNumber={Number(row.number)}
            rowGroup={rowGroup}
            isLastGroupInRow={row.data.length === i + 1}
          />
        ))}
    </Row>
  );
};

export default SeatRow;
