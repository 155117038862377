const v1_get_from = company => {
  return {
    name: company.name,
    accountManager: company.account_manager,
    createdAt: company.created_at,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
