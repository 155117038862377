import React, { useReducer, useRef } from 'react';

import api from 'utils/api';
import nextGenSpecialAppsMapper from 'mappers/nextGen/companies/{company_name}/special-apps/_';
import nextGenSpecialAppDefinitionsMapper from 'mappers/nextGen/special-apps/_';
import {
  DEMAND_DASHBOARD_ID,
  DESTINATION_DEMAND_DASHBOARD_ID,
  DATA_MARKETPLACE_ID,
  REPORT_SCHEDULES_ID,
} from 'consts/specialApps';

export const SpecialAppsContext = React.createContext({
  specialAppsState: {},
  loadSpecialApps: async companyName => companyName,
  loadSpecialAppsDefinitions: async () => [],
  updateSpecialApp: async (companyName, app, appParams) => [companyName, app, appParams],
  deleteSpecialApp: async (companyName, app) => [companyName, app],
  createSpecialApp: async (companyName, appDefinition, appParams) => [
    companyName,
    appDefinition,
    appParams,
  ],
});

const specialAppsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_SPECIAL_APPS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_SPECIAL_APPS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          apps: action.apps,
          demandDashboard: action.demandDashboard,
          destinationDemandDashboard: action.destinationDemandDashboard,
          dataMarketplace: action.dataMarketplace,
          scheduledReports: action.scheduledReports,
          loading: false,
        },
      };
    case 'LOAD_SPECIAL_APPS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'LOAD_DEFINITIONS':
      return {
        ...state,
        loadingDefinitions: true,
        errorDefinitions: null,
      };
    case 'LOAD_DEFINITIONS_SUCCESS':
      return {
        ...state,
        definitions: action.definitions,
        loadingDefinitions: false,
      };
    case 'LOAD_DEFINITIONS_FAIL':
      return {
        ...state,
        loadingDefinitions: false,
        errorDefinitions: action.error,
      };
    case 'UPDATE_SPECIAL_APP':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'UPDATE_SPECIAL_APP_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          apps: state[action.companyName].apps.map(app =>
            app.definitionId === action.app.definitionId ? action.app : app,
          ),
          loading: false,
        },
      };
    case 'UPDATE_SPECIAL_APP_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'DELETE_SPECIAL_APP':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'DELETE_SPECIAL_APP_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          apps: state[action.companyName].apps.map(app =>
            app.definitionId === action.app.definitionId ? action.app : app,
          ),
          loading: false,
        },
      };
    case 'DELETE_SPECIAL_APP_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'CREATE_SPECIAL_APP':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'CREATE_SPECIAL_APP_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          apps: [...state[action.companyName].apps, action.app],
          loading: false,
        },
      };
    case 'CREATE_SPECIAL_APP_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

const SpecialAppsProvider = ({ children }) => {
  const [specialAppsState, specialAppsDispatch] = useReducer(specialAppsReducer, {});
  const loadingRef = useRef(null);

  const loadSpecialApps = async companyName => {
    if (loadingRef.current) {
      return;
    }
    specialAppsDispatch({ type: 'LOAD_SPECIAL_APPS', companyName });
    loadingRef.current = true;
    try {
      const {
        data: { data: appsResponse },
      } = await api.v1.get(`companies/${companyName}/special-apps`);
      const apps = appsResponse.map(nextGenSpecialAppsMapper.v1.get.from);

      const demandDashboardApp = apps.find(app => app.definitionId === DEMAND_DASHBOARD_ID);
      const destinationDemandDashboardApp = apps.find(
        app => app.definitionId === DESTINATION_DEMAND_DASHBOARD_ID,
      );

      const dataMarketplaceApp = apps.find(app => app.definitionId === DATA_MARKETPLACE_ID);
      const reportSchedulesApp = apps.find(app => app.definitionId === REPORT_SCHEDULES_ID);

      specialAppsDispatch({
        type: 'LOAD_SPECIAL_APPS_SUCCESS',
        apps,
        companyName,
        demandDashboard: demandDashboardApp,
        destinationDemandDashboard: destinationDemandDashboardApp,
        dataMarketplace: dataMarketplaceApp,
        scheduledReports: reportSchedulesApp,
      });
      loadingRef.current = false;
    } catch (error) {
      specialAppsDispatch({ type: 'LOAD_SPECIAL_APPS_FAIL', companyName, error });
      loadingRef.current = false;
    }
  };

  const loadSpecialAppsDefinitions = async () => {
    specialAppsDispatch({ type: 'LOAD_DEFINITIONS' });

    try {
      const {
        data: { data: responseDefinitions },
      } = await api.v1.get('special-apps');
      const definitions = responseDefinitions.map(nextGenSpecialAppDefinitionsMapper.v1.get.from);

      specialAppsDispatch({
        type: 'LOAD_DEFINITIONS_SUCCESS',
        definitions,
      });
    } catch (error) {
      specialAppsDispatch({ type: 'LOAD_DEFINITIONS_FAIL', error });
    }
  };

  const updateSpecialApp = async (companyName, app, appParams) => {
    specialAppsDispatch({ type: 'UPDATE_SPECIAL_APP', companyName });

    try {
      const { data } = await api.v1.put(
        `companies/${companyName}/special-apps/${app.id}`,
        nextGenSpecialAppsMapper.v1.put.to.update(appParams, app),
      );
      const updatedApp = nextGenSpecialAppsMapper.v1.put.from.update(data);

      specialAppsDispatch({
        type: 'UPDATE_SPECIAL_APP_SUCCESS',
        companyName,
        app: updatedApp,
      });
    } catch (error) {
      specialAppsDispatch({ type: 'UPDATE_SPECIAL_APP_FAIL', companyName, error });
    }
  };

  const deleteSpecialApp = async (companyName, app) => {
    specialAppsDispatch({ type: 'DELETE_SPECIAL_APP', companyName });

    try {
      await api.v1.put(
        `companies/${companyName}/special-apps/${app.id}`,
        nextGenSpecialAppsMapper.v1.put.to.delete(),
      );

      specialAppsDispatch({
        type: 'DELETE_SPECIAL_APP_SUCCESS',
        companyName,
        app: { ...app, deleted: true },
      });
    } catch (error) {
      specialAppsDispatch({ type: 'DELETE_SPECIAL_APP_FAIL', companyName, error });
    }
  };

  const createSpecialApp = async (companyName, appDefinition, appParams) => {
    specialAppsDispatch({ type: 'CREATE_SPECIAL_APP', companyName });

    try {
      const { data } = await api.v1.post(
        `companies/${companyName}/special-apps`,
        nextGenSpecialAppsMapper.v1.post.to(companyName, appParams, appDefinition),
      );
      const createdApp = nextGenSpecialAppsMapper.v1.post.from(data);

      specialAppsDispatch({
        type: 'CREATE_SPECIAL_APP_SUCCESS',
        companyName,
        app: createdApp,
      });
    } catch (error) {
      specialAppsDispatch({ type: 'CREATE_SPECIAL_APP_FAIL', companyName, error });
    }
  };

  const value = {
    specialAppsState,
    loadSpecialApps,
    loadSpecialAppsDefinitions,
    updateSpecialApp,
    deleteSpecialApp,
    createSpecialApp,
  };

  return <SpecialAppsContext.Provider value={value}>{children}</SpecialAppsContext.Provider>;
};

export default SpecialAppsProvider;
