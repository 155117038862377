import { useEffect, useContext } from 'react';

import { MMBState } from 'components/services/mmb';

const useBoardingPasses = bid => {
  const { state, loadBoardingPasses } = useContext(MMBState);

  useEffect(() => {
    if (!state.bookingsState[bid]?.boardingPasses && state.bookingsState[bid]?.passengers) {
      loadBoardingPasses(
        bid,
        state.bookingsState[bid]?.bookingData.segments,
        state.bookingsState[bid]?.passengers,
        state.bookingsState[bid]?.bookingData.tripSummary.type,
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid, state.bookingsState[bid]?.passengers]);

  return state.bookingsState[bid]?.boardingPasses
    ? {
        data: state.bookingsState[bid].boardingPasses,
        loading: state.bookingsState[bid].loadingboardingPasses,
        error: state.bookingsState[bid].loadingboardingPassesError,
      }
    : {
        data: null,
        loading: true,
        error: null,
      };
};

export default useBoardingPasses;
