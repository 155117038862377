import { useContext } from 'react';

import { SeatingState } from '.';

const useSeating = () => {
  const seating = useContext(SeatingState);

  return seating;
};

export default useSeating;
