import { useContext, useEffect } from 'react';
import { MenuState } from './MenuProvider';
import useCurrentUser from '../auth/useCurrentUser';

const useMenuState = () => {
  const ctx = useContext(MenuState);
  const user = useCurrentUser();
  useEffect(() => {
    if (user) {
      ctx.loadUserPreferences(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    { ...ctx } || {
      loading: false,
      error: null,
      menu: null,
    }
  );
};

export default useMenuState;
