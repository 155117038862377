import { camelize } from 'utils/functions';
import { extractType } from 'utils/prebooking';

const direct_put_to = ({ currency, passengers }) => ({
  booking_phase: 'summary',
  currency,
  passengers: passengers.map(passenger => ({
    category: passenger.category,
  })),
});

const direct_put_config_to = apiKey => ({
  headers: { apikey: apiKey },
});

const direct_put_from = prebookingSessionData => ({
  sessionId: prebookingSessionData.id,
  refreshTtl: prebookingSessionData.refresh_ttl,
  events: prebookingSessionData.events ? extractType(camelize(prebookingSessionData.events)) : {},
  price: {
    amount: prebookingSessionData.pricing.itinerary.price.amount,
    currency: prebookingSessionData.pricing.itinerary.price.currency,
  },
});

export default {
  direct: {
    put: {
      to: direct_put_to,
      from: direct_put_from,
      config_to: direct_put_config_to,
    },
  },
};
