import React, { useState, useEffect, useMemo } from 'react';
import * as R from 'ramda';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Select from '@kiwicom/orbit-components/lib/Select';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import PlusCircleIcon from '@kiwicom/orbit-components/lib/icons/PlusCircle';
import { Link } from 'react-router-dom';

import { Authorization } from 'common/Authorization';
import { usePolyglot } from 'components/services/i18n';
import useSolutionOptions from 'components/services/solutions/useSolutionOptions';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';

import {
  SelectSolutionContainer,
  LoaderContainer,
  SelectWrapper,
  Divider,
  SelectSolutionFigureContainer,
} from './SelectSolution.styled';

const SelectSolution = ({
  children,
  value: pValue,
  onChange,
  enables,
  enablesTypes,
  disablesTypes,
  allEnabled,
  preselectedAffilidValue,
  label,
  renderSelectOnly,
  companyName,
  noDivider,
  justify,
  fromSearch = false,
  preselectedStep,
}) => {
  const [value, setValue] = useState(pValue);
  const currentCompanyName = useCurrentCompanyName();
  const usersCompanyName = useUsersCompanyName();
  const company = companyName || currentCompanyName || usersCompanyName;

  const { solutions, solutionOptions, products } = useSolutionOptions(
    company,
    enables,
    enablesTypes,
    disablesTypes,
    allEnabled,
  );

  const activeApps = useMemo(() => solutionOptions.filter(a => !a.disabled), [solutionOptions]);

  useEffect(() => {
    if (activeApps.length === 1) {
      setValue(activeApps[0].value);
      onChange({ target: { value: JSON.parse(activeApps[0].value) } });
    } else if (preselectedAffilidValue && solutionOptions.length > 0) {
      const preselectedSolution = solutionOptions.find(a => {
        const parsedValue = JSON.parse(a.value);
        return parsedValue.affilId === preselectedAffilidValue;
      });

      if (preselectedSolution && preselectedSolution.value !== value) {
        setValue(preselectedSolution.value);
        onChange({ target: { value: JSON.parse(preselectedSolution.value) } });
      }
    } else {
      setValue(pValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApps, preselectedAffilidValue]);

  const handleSelect = event => {
    setValue(event.target.value);

    try {
      onChange(R.assocPath(['target', 'value'], JSON.parse(event.target.value), event));
    } catch (error) {
      onChange(R.assocPath(['target', 'value'], { affilId: '', consumerKey: '' }, event));
    }
  };

  const polyglot = usePolyglot();

  const copy =
    (solutions.data &&
      solutionOptions.length === 0 && {
        text: polyglot.t('select_solutions.no_solutions_text'),
        subText: polyglot.t('select_solutions.no_solutions_subtext'),
      }) ||
    (solutions.data &&
      !value && {
        text: polyglot.t('select_solutions.select_text'),
        subText: polyglot.t('select_solutions.select_subtext'),
      }) ||
    {};

  const select =
    !solutions.data || !products.data ? (
      <LoaderContainer>
        <Loading loading type="inlineLoader" text={polyglot.t('common.loading_solutions')} />
      </LoaderContainer>
    ) : (
      <Select
        label={label || polyglot.t('common.select_solution')}
        options={activeApps}
        size="small"
        onChange={handleSelect}
        placeholder={polyglot.t('common.select_solution')}
        value={value}
        spaceAfter={renderSelectOnly && 'medium'}
      />
    );

  if (renderSelectOnly) {
    return select;
  }

  return (
    <>
      <SelectSolutionContainer>
        <Stack justify={justify || 'between'} align="end">
          <SelectWrapper>{select}</SelectWrapper>
          {!fromSearch && (
            <Authorization
              resource="model.application"
              action={['create', 'create_assigned', 'create_own']}
            >
              <Link
                to={{
                  pathname: `/companies/${company}/solutions`,
                  state: {
                    createSolution: true,
                    fromSearch: false,
                    preselectedStep,
                  },
                }}
              >
                <Button type="primary" size="small" iconLeft={<PlusCircleIcon />}>
                  {polyglot.t('common.create_solution')}
                </Button>
              </Link>
            </Authorization>
          )}
        </Stack>

        {children}

        {!noDivider && <Divider />}
      </SelectSolutionContainer>

      {copy.text && (
        <SelectSolutionFigureContainer>
          <Illustration name="PlaceholderAirport" spaceAfter="large" />
          <Text type="primary" spaceAfter="small" weight="bold" size="large">
            {copy.text}
          </Text>
          <Text>{copy.subText}</Text>
        </SelectSolutionFigureContainer>
      )}
    </>
  );
};

export default SelectSolution;
