import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import useCurrency from 'components/services/currencies/useCurrency';
import useBooking from 'components/services/booking/useBooking';
import { ErrorMessageComponent } from 'common';
import { useToggle } from 'utils/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  padding: 10px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.orbit.paletteWhiteHover};
  border: solid 2px
    ${({ theme, checked }) =>
      checked ? `${theme.orbit.borderColorCheckboxRadioFocus}` : `${theme.orbit.borderColorCard}`};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  outline: solid 1px white;
  outline-offset: ${({ checked }) => (checked ? '-3px' : '-2px')};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.orbit.borderColorCheckboxRadioFocus};
    outline-offset: -3px;
  }

  label {
    width: auto;
    margin-right: 18px;
  }

  span {
    white-space: nowrap;
  }

  > *:not(:last-child) {
    margin-bottom: 12px;
  }

  ${({ hasError, theme }) =>
    hasError && `border:2px solid ${theme.orbit.borderColorButtonCriticalBordered}`}
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 80px;
`;

const EmptyOffers = ({ disabled, loading, onClick }) => {
  const polyglot = usePolyglot();
  return (
    <Stack align="center" direction="column">
      <Illustration name="Insurance" size="small" />
      <Text weight="bold">
        {' '}
        {polyglot.t('booking.deposit_booking.paid_guarantee.offer_calculation')}
      </Text>
      <Text type="secondary">
        {polyglot.t('booking.deposit_booking.paid_guarantee.complete_calculation')}
      </Text>
      <Button disabled={disabled} loading={loading} onClick={onClick}>
        {polyglot.t('booking.deposit_booking.paid_guarantee.check_offers')}
      </Button>
    </Stack>
  );
};

const PaidGuarantee = ({ location, ancillariesReady }) => {
  const polyglot = usePolyglot();
  const { apiKey, token } = location.state;
  const { targetCurrencyCode, getFormattedPriceString } = useCurrency();
  const { data, fetchPaidGuaranteeOffers, loadingGuaranteeOffer, guaranteeOffer, guaranteeError } =
    useBooking();
  const { control, errors, watch, trigger, setValue } = useFormContext();
  const passengersData = watch('passengers');
  const canGetOffers = useToggle(false);
  const passengersDataChanged = useToggle(false);
  const noOffers = useToggle(false);

  //format price to match selected currency format
  const priceString =
    targetCurrencyCode !== 'eur' &&
    guaranteeOffer?.length > 0 &&
    getFormattedPriceString(guaranteeOffer[0].price.amountInSelectedCurrency);

  //paid guarantee requires dateOfBirth and nationality
  const filteredPassengers = useMemo(
    () => passengersData.filter(pax => pax.dateOfBirth?.length === 10 && pax.nationality),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(passengersData)],
  );

  useEffect(() => {
    if (!!guaranteeError || (guaranteeOffer !== undefined && !guaranteeOffer.length)) {
      noOffers.setOn();
      setValue('paidGuarantee', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guaranteeError]);

  useEffect(() => {
    passengersDataChanged.setOn();
    noOffers.setOff(); //allow another guarantee check if passengers changed regardless of error
    //if there is at least one passenger and all passengers have needed data
    if (
      filteredPassengers.length > 0 &&
      passengersData.length === filteredPassengers.length &&
      !errors?.passengers?.length &&
      ancillariesReady.current
    ) {
      canGetOffers.setOn();
    } else {
      canGetOffers.setOff();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredPassengers.length,
    passengersData.length,
    ancillariesReady.current,
    errors.passengers,
  ]);

  const handleFetchingOffers = async () => {
    await fetchPaidGuaranteeOffers(
      apiKey,
      data.sessionId,
      token,
      location.state.currency,
      filteredPassengers,
    );
    passengersDataChanged.setOff();
  };

  if (noOffers.isOn) {
    return (
      <Card title={polyglot.t('booking.deposit_booking.paid_guarantee.guarantee')}>
        <CardSection>
          <Alert
            type="critical"
            title={polyglot.t('booking.deposit_booking.paid_guarantee.no_guarantee')}
            icon={<InformationCircleIcon />}
            spaceAfter="large"
          >
            {polyglot.t('booking.deposit_booking.paid_guarantee.no_guarantee_available')}
          </Alert>
        </CardSection>
      </Card>
    );
  }

  return (
    <Card title={polyglot.t('booking.deposit_booking.paid_guarantee.guarantee')}>
      <CardSection>
        {filteredPassengers.length < 1 || loadingGuaranteeOffer || passengersDataChanged.isOn ? (
          <EmptyOffers
            disabled={!canGetOffers.isOn}
            loading={loadingGuaranteeOffer}
            onClick={handleFetchingOffers}
          />
        ) : (
          <Controller
            name="paidGuarantee"
            control={control}
            render={({ onChange, value, name }) => {
              return (
                <>
                  <Wrapper
                    onClick={() => {
                      onChange(false);
                      trigger(name);
                    }}
                    checked={value === false}
                    hasError={errors.paidGuarantee}
                  >
                    <Radio
                      onChange={() => {
                        onChange(false);
                        trigger(name);
                      }}
                      checked={value === false}
                      hasError={errors.paidGuarantee}
                    />
                    <DescriptionWrapper>
                      <Text weight="bold" size="large" spaceAfter="medium">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.no_protection')}
                      </Text>
                      <Text spaceAfter="normal">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.canceled_trip')}
                      </Text>
                      <Text type="critical" weight="bold">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.carrier_refund')}
                      </Text>
                    </DescriptionWrapper>
                    <Text weight="bold" as="span">
                      {`0 €`} {priceString && `(${getFormattedPriceString(0)})`}
                    </Text>
                  </Wrapper>
                  <Wrapper
                    onClick={() => {
                      onChange(true);
                      trigger(name);
                    }}
                    checked={value}
                    hasError={errors.paidGuarantee}
                  >
                    <Radio
                      onChange={() => {
                        onChange(true);
                        trigger(name);
                      }}
                      checked={value}
                      hasError={errors.paidGuarantee}
                    />
                    <DescriptionWrapper>
                      <Text weight="bold" size="large" spaceAfter="medium">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.guarantee')}
                      </Text>
                      <Text spaceAfter="normal">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.canceled_trip')}
                      </Text>
                      <Text type="success" weight="bold">
                        {polyglot.t('booking.deposit_booking.paid_guarantee.free_alternative')}
                      </Text>
                    </DescriptionWrapper>
                    <Text weight="bold" as="span">
                      {`${guaranteeOffer?.[0]?.price?.amount || 0} €`}{' '}
                      {priceString && `(${priceString})`}
                    </Text>
                  </Wrapper>
                </>
              );
            }}
          />
        )}
        {errors?.paidGuarantee && (
          <ErrorMessageComponent>{errors.paidGuarantee.message}</ErrorMessageComponent>
        )}
      </CardSection>
    </Card>
  );
};

export default PaidGuarantee;
