import { useContext, useEffect, useState } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@kiwicom/orbit-components/lib/Table';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import DownloadIcon from '@kiwicom/orbit-components/lib/icons/Download';
import ReloadIcon from '@kiwicom/orbit-components/lib/icons/Reload';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import { Alert } from '@kiwicom/orbit-components';
import styled from 'styled-components';

import { PageContainer, Pagination } from 'common';
import { AuthState } from 'components/services/auth/AuthProvider';
import { BillingState } from 'components/services/billing';
import usePolyglot from 'components/services/i18n/usePolyglot';
import { ROUTE_CONFIG } from 'consts/routes';
import { useIsMounted } from 'utils/hooks';
import { TableCellSort } from 'common';
import { useToggle } from 'utils/hooks';

import DeleteModal from '../../common/DeleteModal';
import NoResults from '../../components/NoResults';
import ApproveModal from './components/ApproveModal';
import BillingStatus from './components/BillingStatus';
import DateFilter from './components/DateFilter';
import RetryModal from './components/RetryModal';
import StatusFilter from './components/StatusFilter';
import ExportModal from './components/ExportModal';
import { CardWrapper } from './components/index.styled';

const ExportWrapper = styled.div`
  padding-top: 20px;
`;

const Billing = () => {
  const polyglot = usePolyglot();
  const [selectedBilling, setSelectedBilling] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const [sort, setSort] = useState({
    company: { key: 'company_name', main: true, ascending: true },
    periodStart: { key: 'period_start', main: false, ascending: true },
    periodEnd: { key: 'period_end', main: false, ascending: true },
    amount: { key: 'final_balance', main: false, ascending: true },
    billingStatus: { key: 'status', main: false, ascending: true },
  });
  const {
    loadBillings,
    approveBilling,
    retryBilling,
    deleteBilling,
    downloadBilling,
    clearError,
    setDateFilter,
    setStatusFilter,
    state: {
      data,
      pagination,
      loading,
      retrying,
      deleting,
      approving,
      downloading,
      error,
      dateFilter,
      statusFilters,
      exportError,
    },
  } = useContext(BillingState);
  const { checkAccess, user, checkedGrants } = useContext(AuthState);
  const isMounted = useIsMounted();
  const [canApprove, setCanApprove] = useState({ value: null, loading: true });
  const [canRetry, setCanRetry] = useState({ value: null, loading: true });
  const [canDelete, setCanDelete] = useState({ value: null, loading: true });
  const isExportModal = useToggle(false);

  useEffect(() => {
    loadBillings(
      pagination,
      {
        statusFilters,
        ...dateFilter,
      },
      sort,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilters, dateFilter, sort]);

  useEffect(() => {
    checkAccess('model.billing_job', ['approve'], user.companyName).then(res => {
      if (isMounted()) {
        setCanApprove({ value: res, loading: false });
      }
    });
    checkAccess('model.billing_job', ['recalculate'], user.companyName).then(res => {
      if (isMounted()) {
        setCanRetry({ value: res, loading: false });
      }
    });
    checkAccess('model.billing_job', ['delete'], user.companyName).then(res => {
      if (isMounted()) {
        setCanDelete({ value: res, loading: false });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedGrants?.[user.companyName]?.['model.billing_job']]);

  const handlePagination = (rowsPerPage, nextPage) => {
    loadBillings(
      { perPage: rowsPerPage, page: nextPage },
      {
        statusFilters,
        ...dateFilter,
      },
      sort,
    );
  };
  const handleClose = () => {
    setSelectedBilling(null);
    setModalOpen(null);
    clearError();
  };

  const handleApprove = async id => {
    try {
      await approveBilling(id);
      handleClose();
    } catch (err) {}
  };

  const handleRetry = async id => {
    await retryBilling(id);
    handleClose();
  };

  const handleDelete = async id => {
    await deleteBilling(id);
    handleClose();
  };

  const handleSort = valueSort => {
    const tempSort = { ...sort };
    for (const el in tempSort) {
      tempSort[el].main = false;
    }
    setSort({
      ...tempSort,
      [valueSort]: { ...sort[valueSort], main: true, ascending: !sort[valueSort].ascending },
    });
  };

  return (
    <PageContainer maxContent>
      {modalOpen === 'approve' && (
        <ApproveModal
          {...selectedBilling}
          loading={approving}
          error={error}
          onClose={handleClose}
          onApprove={() => handleApprove(selectedBilling.id)}
        />
      )}
      {modalOpen === 'retry' && (
        <RetryModal
          loading={retrying}
          onClose={handleClose}
          onRetry={() => handleRetry(selectedBilling.id)}
        />
      )}
      {modalOpen === 'delete' && (
        <DeleteModal
          loading={deleting}
          onClose={handleClose}
          onDelete={() => handleDelete(selectedBilling.id)}
          title={polyglot.t('billing.delete_modal.billing.title')}
          description={polyglot.t('billing.delete_modal.billing.description')}
        />
      )}

      <Stack direction="row" spaceAfter="medium" align="center" justify="between">
        <Stack direction="row" align="center" shrink>
          <StatusFilter
            selectedStatuses={statusFilters}
            loading={loading}
            onChange={setStatusFilter}
          />
          <DateFilter selectedDate={dateFilter} loading={loading} onClick={setDateFilter} />
        </Stack>

        <ExportWrapper>
          {isExportModal.isOn && <ExportModal onClose={isExportModal.setOff} />}
          <Button
            dataTest="export-button"
            size="small"
            type="primary"
            onClick={isExportModal.setOn}
            iconLeft={<DownloadIcon />}
          >
            {polyglot.t('customer_demand_dashboard.filter.button.export')}
          </Button>
        </ExportWrapper>
      </Stack>

      {exportError && (
        <Alert type="warning" spaceAfter="medium">
          {polyglot.t('billing.export.error.message')}
        </Alert>
      )}

      <CardWrapper>
        <Card loading={loading}>
          <CardSection>
            {!data?.length && !data ? (
              <NoResults message={polyglot.t('billing.no_results')} />
            ) : (
              <Table striped={false}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableCellSort
                        onClick={() => handleSort('company')}
                        isAscending={sort.company.ascending}
                      >
                        {polyglot.t('billing.company')}
                      </TableCellSort>
                    </TableCell>
                    <TableCell>
                      <TableCellSort
                        onClick={() => handleSort('periodStart')}
                        isAscending={sort.periodStart.ascending}
                      >
                        {polyglot.t('billing.period_start')}
                      </TableCellSort>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <TableCellSort
                        onClick={() => handleSort('periodEnd')}
                        isAscending={sort.periodEnd.ascending}
                      >
                        {polyglot.t('billing.period_end')}
                      </TableCellSort>
                    </TableCell>
                    <TableCell>
                      {' '}
                      <TableCellSort
                        onClick={() => handleSort('amount')}
                        isAscending={sort.amount.ascending}
                      >
                        {polyglot.t('billing.amount')}
                      </TableCellSort>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {' '}
                      <TableCellSort
                        onClick={() => handleSort('billingStatus')}
                        isAscending={sort.billingStatus.ascending}
                      >
                        {polyglot.t('billing.status')}
                      </TableCellSort>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((billing, i) => (
                    <TableRow key={`${billing.companyName}-${i}`} dataTest={`table-row-${i}`}>
                      <TableCell dataTest={`company-name-row-${i}`}>
                        <TextLink
                          href={`/portal${ROUTE_CONFIG.COMPANIES.path}/${billing.companyName}`}
                          noUnderline
                        >
                          {billing.companyName}
                        </TextLink>
                      </TableCell>
                      <TableCell dataTest={`period-start-row-${i}`}>
                        {billing.periodStart}
                      </TableCell>
                      <TableCell dataTest={`period-end-row-${i}`}>{billing.periodEnd}</TableCell>
                      <TableCell dataTest={`amount-row-${i}`}>€ {billing.amount}</TableCell>
                      <TableCell align="center">
                        <Stack direction="row" justify="end">
                          {billing.pdfReady && (
                            <Button
                              dataTest="download-pdf-button"
                              size="small"
                              type="secondary"
                              iconLeft={<DownloadIcon />}
                              onClick={() => downloadBilling(billing, 'pdf')}
                              loading={
                                downloading?.type === 'pdf' && downloading?.id === billing.id
                              }
                            >
                              {polyglot.t('common.pdf')}
                            </Button>
                          )}
                          {billing.csvReady && (
                            <Button
                              dataTest="download-csv-button"
                              size="small"
                              type="secondary"
                              iconLeft={<DownloadIcon />}
                              onClick={() => downloadBilling(billing, 'csv')}
                              loading={
                                downloading?.type === 'csv' && downloading?.id === billing.id
                              }
                            >
                              {polyglot.t('common.csv')}
                            </Button>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <BillingStatus status={billing.status} />
                      </TableCell>
                      <TableCell align="end">
                        {billing.status === 'review' && (
                          <Tooltip
                            content={polyglot.t('billing.tooltip.approve_billing')}
                            preferredPosition="top"
                          >
                            <Button
                              type="primary"
                              size="small"
                              disabled={!canApprove.value}
                              onClick={() => {
                                setSelectedBilling({
                                  id: billing.id,
                                  company: billing.companyName,
                                  periodStart: billing.periodStart,
                                  periodEnd: billing.periodEnd,
                                  amount: billing.amount,
                                });
                                setModalOpen('approve');
                              }}
                              iconLeft={<CheckIcon />}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="end">
                        {['review', 'failed', 'deleted'].includes(billing.status) && (
                          <Tooltip
                            content={polyglot.t('billing.tooltip.retry_billing')}
                            preferredPosition="top"
                          >
                            <Button
                              type="secondary"
                              size="small"
                              disabled={!canRetry.value}
                              onClick={() => {
                                setSelectedBilling({
                                  company: billing.companyName,
                                  id: billing.id,
                                });
                                setModalOpen('retry');
                              }}
                              iconLeft={<ReloadIcon />}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="end">
                        {(billing.status === 'review' || billing.status === 'failed') && (
                          <Tooltip
                            content={polyglot.t('billing.tooltip.delete_billing')}
                            preferredPosition="top"
                          >
                            <Button
                              type="secondary"
                              size="small"
                              disabled={!canDelete.value}
                              onClick={() => {
                                setSelectedBilling({ id: billing.id });
                                setModalOpen('delete');
                              }}
                              iconLeft={<RemoveIcon />}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardSection>

          {!loading && data && (
            <Pagination
              rows={pagination.totalCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              rowsPerPageItems={[1000, 500, 250, 100]}
              onPagination={handlePagination}
              withoutAll
            />
          )}
        </Card>
      </CardWrapper>
    </PageContainer>
  );
};

export default Billing;
