import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';

const InvalidFlightsModal = () => {
  const history = useHistory();
  const polyglot = usePolyglot();

  return (
    <Modal hasCloseButton={false} preventOverlayClose size="small" dataTest="invalid-flight-modal">
      <ModalHeader
        illustration={<Illustration name="NoResults" size="small" />}
        title={polyglot.t('booking.deposit_booking.invalid_flights_modal.flight_not_available')}
        description={polyglot.t('booking.deposit_booking.invalid_flights_modal.return_to_search')}
      />
      <ModalFooter>
        <Button
          onClick={() => {
            history.replace(ROUTE_CONFIG.KIWI_SEARCH.path);
          }}
          dataTest="invalid-flight-accept"
        >
          {polyglot.t('booking.deposit_booking.invalid_flights_modal.back_to_search')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InvalidFlightsModal;
