import React, { useMemo } from 'react';

import { QUICK_OPTION_TYPES } from 'consts/seating';
import useBooking from 'components/services/booking/useBooking';
import useSeating from 'components/services/bookingToolSeating/useSeating';
import { usePolyglot } from 'components/services/i18n';
import { useCurrency } from 'components/services/currencies';
import { precisionRound } from 'utils/number';

import QuickOption from '../QuickOption';
import { StackWrapper } from './index.styled';

const QuickOptions = ({ routeId }) => {
  const {
    seatingOfferData: { offers },
  } = useBooking();
  const { currentSeatings, addSeating } = useSeating();
  const polyglot = usePolyglot();
  const { getFormattedPriceString } = useCurrency();

  const quickOptions = useMemo(() => {
    const offer = offers.filter(it => it.segmentCode === routeId)[0];

    return [
      {
        option: QUICK_OPTION_TYPES.RANDOM,
        price: null,
      },
      ...offer.quickOptions,
    ];
  }, [offers, routeId]);

  return (
    <StackWrapper>
      {quickOptions.map(quickOption => (
        <QuickOption
          key={quickOption.option}
          option={quickOption.option}
          isChecked={currentSeatings[routeId]?.option === quickOption.option}
          price={
            quickOption.price
              ? getFormattedPriceString(precisionRound(quickOption.price.amount, 2))
              : polyglot.t('booking_tool.seating.drawer.no_seatmap.quick_option.included')
          }
          onClick={() => addSeating(routeId, quickOption)}
        />
      ))}
    </StackWrapper>
  );
};

export default QuickOptions;
