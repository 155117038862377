import React from 'react';
import Button from '@kiwicom/orbit-components/lib/Button';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

const ForbiddenAccessModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <ModalHeader
        title="IP address error"
        description="Looks like access via your IP address is not allowed by your company. Please contact your
        company administrator for more information."
      />
      <ModalFooter>
        <Button onClick={onClose}>Okay</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ForbiddenAccessModal;
