import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Text from '@kiwicom/orbit-components/lib/Text';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import CalendarIcon from '@kiwicom/orbit-components/lib/icons/Calendar';

import { GroupBy } from 'pages/Reports/components';
import { getDateRange } from 'utils/dates';
import { getPolyglot } from 'redux/selectors/i18n';
import { CompareCalendar, Flex, Space } from 'common';
import * as reportAliasesActions from 'redux/modules/reportAliases';
import * as reportsActions from 'redux/modules/reports';
import { FIRST_PAGE } from 'consts/pagination';

import { ROUTE_CONFIG } from 'consts/routes';
import { MILLENNIUM } from 'consts/dates';
import { REPORT_ALIAS_TYPES } from 'consts/reports';
import * as dateConstants from 'consts/dates';
import { PeriodInputButton, CompareInputButton } from './ReportActions.styled';

class ReportActions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: props.reportAlias.dateRange,
      dateRangeToCompare: props.reportAlias.dateRangeToCompare,
      appliedDateRange: props.reportAlias.dateRange,
      appliedDateRangeToCompare: props.reportAlias.dateRangeToCompare,
      compareChecked: props.reportAlias.isCompare,
      interval: props.reportAlias.interval,
      datepickerVisible: false,
      isCompareCalendar: false,
      compareTo: dateConstants.CUSTOM,
    };
  }

  showCalendar = compare => () => {
    this.setState({
      datepickerVisible: true,
      isCompareCalendar: compare,
    });
  };

  hideCalendar = () => {
    this.setState(state => ({
      dateRange: state.appliedDateRange,
      dateRangeToCompare: state.appliedDateRangeToCompare,
      datepickerVisible: false,
    }));
  };

  applyCalendar = (dates, datesCompare, isCompare) => {
    if (isCompare) {
      this.setState(
        state => ({
          appliedDateRangeToCompare: datesCompare,
          interval: MILLENNIUM,
          datepickerVisible: false,
        }),
        () => this.updateAlias(),
      );
    } else {
      this.setState(
        state => ({
          appliedDateRange: dates,
          datepickerVisible: false,
        }),
        () => this.updateAlias(),
      );
    }
  };

  toggleCompareCheck = () => {
    const { compareChecked } = this.state;
    if (!compareChecked) {
      this.setState({
        compareChecked: true,
      });
      this.showCalendar(true)();
    } else {
      this.setState(
        {
          compareChecked: false,
          dateRangeToCompare: [],
          appliedDateRangeToCompare: [],
        },
        () => {
          this.updateAlias();
        },
      );
    }
  };

  handleGroupBy = value => {
    this.setState(
      {
        interval: value,
      },
      () => this.updateAlias(),
    );
  };

  updateAlias = () => {
    const {
      reportAlias,
      createReportAlias,
      editReportAlias,
      loadReportAlias,
      loadReport,
      history,
    } = this.props;
    const { interval, appliedDateRange, appliedDateRangeToCompare } = this.state;
    const randomName = '#' + +new Date();
    const updatedReportAlias = {
      ...reportAlias,
      alias: reportAlias.alias.split('#')[0] + randomName,
      interval: interval,
      dateRange: appliedDateRange,
      dateRangeToCompare: appliedDateRangeToCompare,
      aliasType: REPORT_ALIAS_TYPES.temp,
    };

    if (reportAlias.aliasType === REPORT_ALIAS_TYPES.temp) {
      editReportAlias(updatedReportAlias).then(({ reportAlias: { id } }) => {
        loadReportAlias(id);
        loadReport(id, FIRST_PAGE);
      });
    } else {
      createReportAlias(updatedReportAlias).then(({ reportAlias: { id } }) => {
        loadReportAlias(id).then(action => {
          history.push(`${ROUTE_CONFIG.REPORTS.path}/details/${id}`);
        });
      });
    }
  };

  handleEdit = () => {
    const { reportAlias, history } = this.props;
    history.push(`${ROUTE_CONFIG.REPORTS.path}/edit/${reportAlias.id}`);
  };

  changeCompareTo = compare => {
    this.setState({ compareTo: compare });
  };

  render() {
    const { polyglot } = this.props;
    const {
      dateRange,
      dateRangeToCompare,
      appliedDateRange,
      appliedDateRangeToCompare,
      compareChecked,
      interval,
      datepickerVisible,
      isCompareCalendar,
      compareTo,
    } = this.state;

    return (
      <div>
        <Flex main="spaceBetween" cross="end">
          <PeriodInputButton
            label={polyglot.t('common.period')}
            id="reports-period"
            size="small"
            value={getDateRange(appliedDateRange)}
            icon={<CalendarIcon size="small" customColor="#bac7d5" />}
            onClick={this.showCalendar(false)}
          />
          <div>
            <Checkbox
              id="reports-period-compare-checkbox"
              htmlFor="reports-period-compare"
              label={polyglot.t('common.compare_to')}
              checked={compareChecked}
              onChange={this.toggleCompareCheck}
            />
            <CompareInputButton
              size="small"
              id="reports-period-compare"
              icon={<CalendarIcon size="small" customColor="#bac7d5" />}
              value={getDateRange(appliedDateRangeToCompare)}
              disabled={!compareChecked}
              onClick={this.showCalendar(true)}
            />
          </div>
          <Button size="small" onClick={this.handleEdit} iconLeft={<EditIcon size="small" />}>
            {polyglot.t('common.edit')}
          </Button>
        </Flex>
        <Space before="l">
          <Flex main="end" cross="center">
            <Space right="m">
              <Text type="secondary">{polyglot.t('reports.group_by')}</Text>
            </Space>
            <GroupBy
              size="small"
              id="report-interval"
              disabled={compareChecked}
              value={compareChecked ? MILLENNIUM : interval}
              onChange={this.handleGroupBy}
            />
          </Flex>
        </Space>
        {datepickerVisible && (
          <CompareCalendar
            dates={dateRange}
            datesToCompare={dateRangeToCompare}
            onHide={this.hideCalendar}
            onCancel={this.hideCalendar}
            onApply={this.applyCalendar}
            isVisible={datepickerVisible}
            canCompare={isCompareCalendar}
            compareTo={compareTo}
            changeCompareTo={this.changeCompareTo}
          />
        )}
      </div>
    );
  }
}

ReportActions.propTypes = {
  reportAlias: PropTypes.object.isRequired,
  createReportAlias: PropTypes.func.isRequired,
  editReportAlias: PropTypes.func.isRequired,
  loadReportAlias: PropTypes.func.isRequired,
  loadReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

const mapDispatchToProps = {
  createReportAlias: reportAliasesActions.create,
  editReportAlias: reportAliasesActions.edit,
  loadReportAlias: reportAliasesActions.loadById,
  loadReport: reportsActions.loadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportActions);
