import React, { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { withRouter } from 'react-router-dom';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { CompaniesState } from 'components/services/companies';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { OrbitLoader, Flex, Link, Pagination, SelectAccountManagers } from 'common';
import { TableCellSort } from 'common';

import { DATE_INTERVAL_FORMAT } from 'consts/dates';

import { ContentContainer, FilterContainer } from './CompaniesList.styled';

const CompanyListTable = ({ loading, paginatedCompanies, sort, onSort }) => {
  const polyglot = usePolyglot();
  if (loading) {
    return (
      <Flex main="center">
        <OrbitLoader id="orbit-loader" visible />
      </Flex>
    );
  }
  if (!paginatedCompanies || R.isEmpty(paginatedCompanies)) {
    return (
      <div>
        <Illustration name="Offline" spaceAfter="large" />
        <Text type="primary" weight="bold" spaceAfter="small">
          {polyglot.t('common.not_found')}
        </Text>
      </div>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <TableCellSort
              onClick={() => onSort('name')}
              isAscending={sort.sortBy === 'name' && sort.sortType === 'asc'}
            >
              {polyglot.t('common.name')}
            </TableCellSort>
          </TableCell>
          <TableCell align="left">
            <TableCellSort
              onClick={() => onSort('createdAt')}
              isAscending={sort.sortBy === 'createdAt' && sort.sortType === 'asc'}
            >
              {polyglot.t('common.created_at')}
            </TableCellSort>
          </TableCell>
          <TableCell align="left">
            <TableCellSort
              onClick={() => onSort('accountManager')}
              isAscending={sort.sortBy === 'accountManager' && sort.sortType === 'asc'}
              data-test={'account-manager-sort'}
            >
              {polyglot.t('company.account_manager')}
            </TableCellSort>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paginatedCompanies.map(company => (
          <TableRow key={company.name}>
            <TableCell align="left">
              <Link
                to={{
                  pathname: `${ROUTE_CONFIG.COMPANIES.path}/${company.name}`,
                  state: {
                    fromCompanies: true,
                  },
                }}
              >
                {company.name}
              </Link>
            </TableCell>
            <TableCell align="left">
              {format(parseISO(company.createdAt), DATE_INTERVAL_FORMAT)}
            </TableCell>
            <TableCell align="left">{company.accountManager}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CompanyList = () => {
  const [companyFilter, setCompanyFilter] = useState('');
  const [accountManagerFilter, setAccountManagerFilter] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState(null);
  const [paginatedCompanies, setPaginatedCompanies] = useState([]);
  const [sort, setSort] = useState({ sortType: 'asc', sortBy: 'name' });
  const [pagination, setPagination] = useState({
    perPage: 20,
    totalCount: 0,
    page: 1,
  });
  const polyglot = usePolyglot();
  const {
    loadCompanies,
    state: { companies, loading },
  } = useContext(CompaniesState);

  useEffect(() => {
    if (companies) {
      const sortedCompanies = companies?.sort((a, b) => (a.name < b.name ? -1 : 1));

      setFilteredCompanies(sortedCompanies);
      setPaginatedCompanies(sortedCompanies.slice(0, pagination.perPage));
      setPagination({ ...pagination, totalCount: sortedCompanies.length });
    } else {
      setFilteredCompanies([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    let newFilteredCompanies;
    if (!companyFilter.length) {
      newFilteredCompanies = companies;
    } else {
      newFilteredCompanies = companies?.filter(company => {
        return company.name.toLowerCase().includes(companyFilter.toLowerCase());
      });
    }
    setFilteredCompanies(newFilteredCompanies);
    setPaginatedCompanies(newFilteredCompanies?.slice(0, pagination.perPage) || []);
    setPagination({ ...pagination, page: 1, totalCount: newFilteredCompanies?.length || 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFilter, accountManagerFilter, companies]);

  useEffect(() => {
    loadCompanies({ account_manager: accountManagerFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagerFilter]);

  const handleCompanyFilterChange = value => {
    setCompanyFilter(value);
  };

  const handleSortBy = sortBy => {
    const newSortType = sort.sortType === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy, sortType: newSortType });

    const propName = R.prop(sortBy);
    const comparator = newSortType === 'asc' ? R.ascend(propName) : R.descend(propName);
    const newFilteredCompanies = R.sortWith([comparator])(filteredCompanies);

    setFilteredCompanies(newFilteredCompanies);
    setPaginatedCompanies(newFilteredCompanies.slice(0, pagination.perPage));
    setPagination({ ...pagination, page: 1, totalCount: newFilteredCompanies.length });
  };

  const handlePagination = (rowsPerPage, nextPage) => {
    setPaginatedCompanies(
      filteredCompanies.slice((nextPage - 1) * rowsPerPage, nextPage * rowsPerPage),
    );
    setPagination({ ...pagination, perPage: Number(rowsPerPage), page: nextPage });
  };

  return (
    <div>
      <ContentContainer>
        <Flex>
          <FilterContainer>
            <InputField
              label={polyglot.t('company.filter_by_name')}
              type="text"
              size="small"
              onChange={e => handleCompanyFilterChange(e.target.value)}
              value={companyFilter}
            />
          </FilterContainer>
          <FilterContainer>
            <SelectAccountManagers
              onChange={e => setAccountManagerFilter(e.target.value)}
              value={accountManagerFilter}
            />
          </FilterContainer>
        </Flex>
      </ContentContainer>
      <CompanyListTable
        sort={sort}
        loading={loading}
        paginatedCompanies={paginatedCompanies}
        onSort={handleSortBy}
      />
      {!loading && (
        <Pagination
          rows={pagination.totalCount}
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          rowsPerPageItems={[10, 20, 50, 100]}
          onPagination={handlePagination}
        />
      )}
    </div>
  );
};

export default withRouter(CompanyList);
