import React, { useReducer } from 'react';
import saveAs from 'file-saver';

import api from 'utils/api';
import nextGenBillingCycleMapper from 'mappers/nextGen/companies/{company_name}/billing-cycles/_';

const FIRST_PAGE = {
  page: 1,
  perPage: 5,
};

export const BillingCyclesState = React.createContext({
  state: {},
  loadCompanyBillingCycles: async (companyName, page, perPage) => [companyName, page, perPage],
  downloadBillingCycle: async (companyName, cycle, type) => [companyName, cycle, type],
  uploadBillingCycle: async (data, pdfToUpload, csvToUpload) => [data, pdfToUpload, csvToUpload],
  deleteBillingCycle: async (companyName, cycle) => [companyName, cycle],
});

const billingCyclesReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_BILLING_CYCLES':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_BILLING_CYCLES_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.data,
          pagination: action.pagination,
        },
      };
    case 'LOAD_COMPANY_BILLING_CYCLES_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'DOWNLOAD_BILLING_CYCLE':
      return {
        ...state,
        downloading: action.id,
      };
    case 'DOWNLOAD_BILLING_CYCLE_DONE':
      return {
        ...state,
        downloading: null,
      };
    case 'UPLOAD_BILLING_CYCLE':
      return {
        ...state,
        uploading: true,
        uploadingError: null,
      };
    case 'UPLOAD_BILLING_CYCLE_SUCCESS':
      return {
        ...state,
        uploading: false,
      };
    case 'UPLOAD_BILLING_CYCLE_FAIL':
      return {
        ...state,
        uploading: false,
        uploadingError: action.error,
      };
    case 'DELETE_BILLING_CYCLE':
      return {
        ...state,
        deleting: true,
        deletingError: null,
      };
    case 'DELETE_BILLING_CYCLE_SUCCESS':
      return {
        ...state,
        deleting: false,
      };
    case 'DELETE_BILLING_CYCLE_FAIL':
      return {
        ...state,
        deleting: false,
        deletingError: action.error,
      };
    default:
      return state;
  }
};

const BillingCyclesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(billingCyclesReducer, {
    downloading: false,
    uploading: false,
    uploadingError: null,
    deleting: false,
    deletingError: null,
  });

  const loadCompanyBillingCycles = async (
    companyName,
    page = FIRST_PAGE.page,
    perPage = FIRST_PAGE.perPage,
  ) => {
    dispatch({ type: 'LOAD_COMPANY_BILLING_CYCLES', companyName });
    try {
      const {
        data: { data: billingCycles, pagination },
      } = await api.v1.get(`companies/${companyName}/billing-cycles`, {
        params: { page, per_page: perPage, _order: '-period_start' },
      });

      const [mappedBillingCycles, mappedPagination] = nextGenBillingCycleMapper.v1.get.from(
        billingCycles,
        pagination,
      );

      dispatch({
        type: 'LOAD_COMPANY_BILLING_CYCLES_SUCCESS',
        companyName,
        data: mappedBillingCycles,
        pagination: mappedPagination,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_COMPANY_BILLING_CYCLES_FAIL', companyName, error });
      return Promise.reject(error);
    }
  };

  const downloadBillingCycle = async (companyName, cycle, type) => {
    const cycleIdentifier = `${cycle.id}_${type}`;
    dispatch({ type: 'DOWNLOAD_BILLING_CYCLE', companyName, id: cycleIdentifier });
    try {
      const response = await api.v1.get(`billing-cycle/${cycle.id}/export`, {
        params: { export_type: type },
        responseType: 'blob',
      });

      saveAs(response.data, `billing_cycle_${cycle.periodStart}.${type}`);

      dispatch({ type: 'DOWNLOAD_BILLING_CYCLE_DONE', companyName });
    } catch (error) {
      dispatch({ type: 'DOWNLOAD_BILLING_CYCLE_DONE', companyName });
      return Promise.reject(error);
    }
  };

  const readFileAsync = file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split(',').pop());
      };
      reader.onerror = reject;
    });
  };

  const uploadBillingCycle = async (data, pdfToUpload, csvToUpload) => {
    dispatch({ type: 'UPLOAD_BILLING_CYCLE', companyName: data.companyName });
    try {
      let pdf = null;
      let csv = null;
      if (pdfToUpload) {
        pdf = await readFileAsync(pdfToUpload[0]);
      }
      if (csvToUpload) {
        csv = await readFileAsync(csvToUpload[0]);
      }

      await api.v1.post(
        `companies/${data.companyName}/billing-cycles`,
        nextGenBillingCycleMapper.v1.post.to(data, pdf, csv),
      );

      dispatch({ type: 'UPLOAD_BILLING_CYCLE_SUCCESS', companyName: data.companyName });
    } catch (error) {
      dispatch({ type: 'UPLOAD_BILLING_CYCLE_FAIL', companyName: data.companyName, error });
      return Promise.reject(error);
    }
  };

  const deleteBillingCycle = async (companyName, cycle) => {
    dispatch({ type: 'DELETE_BILLING_CYCLE', companyName });
    try {
      await api.v1.delete(`billing-cycle/${cycle.id}`);

      dispatch({ type: 'DELETE_BILLING_CYCLE_SUCCESS', companyName });
    } catch (error) {
      dispatch({ type: 'DELETE_BILLING_CYCLE_FAIL', companyName });
      return Promise.reject(error);
    }
  };

  const value = {
    state,
    loadCompanyBillingCycles,
    downloadBillingCycle,
    uploadBillingCycle,
    deleteBillingCycle,
  };

  return <BillingCyclesState.Provider value={value}>{children}</BillingCyclesState.Provider>;
};

export default BillingCyclesProvider;
