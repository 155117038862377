import dformat from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import * as R from 'ramda';

/**
 * Format duration
 * @param {number} start time in milliseconds
 * @param {number} end time in milliseconds
 * @returns {string} formats into e.g. `2h 30m`
 */
export function duration(start, end) {
  const totalMinutes = differenceInMinutes(new Date(end), new Date(start));
  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;
  if (minutes === 0) {
    return `${hours}h`;
  }
  if (hours === 0) {
    return `${minutes}m`;
  } else if (hours > 24) {
    return Math.round(hours / 24) === 1 ? '1 night' : `${Math.round(hours / 24)} nights`;
  }

  return `${hours}h ${minutes}m`;
}

export function secondsToString(seconds) {
  const hours = Math.trunc(seconds / 60 / 60);
  const minutes = Math.trunc(seconds / 60 - hours * 60);
  if (minutes === 0) {
    return `${hours}h`;
  }
  if (hours === 0) {
    return `${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
}

function zeroPadding(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
}

/**
 * Prevents autocomputing local date in the browser.
 * @param {string} date
 */
export function localTimeFormat(timestamp) {
  const date = new Date(timestamp);
  return `${zeroPadding(date.getUTCHours())}:${zeroPadding(date.getUTCMinutes())}`;
}

/**
 * Pretty print Date object. Example: Mon 1 Jun
 * @param {Date} date
 */
export function defaultDateFormat(date) {
  return dformat(new Date(date), 'EEE d MMM');
}

/**
 * Pretty print a pair of dates
 * @param {[Date]} dates
 */
export function dateRange(dates, format) {
  if (dates.length === 0) {
    return '';
  }
  if (format) {
    if (dates.length === 1) {
      return `${dformat(dates[0], format)} - `;
    }
    return `${dformat(dates[0], format)} - ${dformat(R.takeLast(1, dates)[0], format)}`;
  } else if (dates.length === 1) {
    return defaultDateFormat(dates[0]);
  }
  return `${defaultDateFormat(dates[0])} - ${defaultDateFormat(R.takeLast(1, dates)[0])}`;
}

export function convertUTCDateToLocalDate(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}
