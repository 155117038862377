import { useContext, useEffect } from 'react';
import { LogsState } from './';

const useCompanyLogs = (companyName, { perPage, startDate, endDate, startTime, endTime, text }) => {
  const ctx = useContext(LogsState);

  const params = {
    perPage,
    startDate,
    endDate,
    startTime,
    endTime,
    text,
  };

  useEffect(() => {
    ctx.loadCompanyExports(companyName, true);

    return () => ctx.stopExportPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  useEffect(() => {
    ctx.loadInitialCompanyLogs(companyName, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, startDate, startTime, endDate, endTime, text]);

  return (
    {
      ...ctx.state[companyName],
      loadMore: () => ctx.loadMoreCompanyLogs(companyName, params),
    } || {
      loading: false,
      error: null,
      data: null,
      loadMore: () => {},
      loadMoreError: null,
    }
  );
};

export default useCompanyLogs;
