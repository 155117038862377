import React from 'react';

import Badge from '@kiwicom/orbit-components/lib/Badge';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import ReloadIcon from '@kiwicom/orbit-components/lib/icons/Reload';
import AlertIcon from '@kiwicom/orbit-components/lib/icons/Alert';

import { usePolyglot } from 'components/services/i18n';
import { ANCILLARY_SEATING_STATUS } from 'consts/mmb';

const StatusBadge = ({ status }) => {
  const polyglot = usePolyglot();

  switch (status) {
    case ANCILLARY_SEATING_STATUS.CONFIRMED:
      return (
        <Badge icon={<CheckCircleIcon />} type="success">
          {polyglot.t('tequila.ancillary_seating.mmb.status.confirmed')}
        </Badge>
      );
    case ANCILLARY_SEATING_STATUS.PROCESSING:
      return (
        <Badge icon={<ReloadIcon />} type="info">
          {polyglot.t('tequila.ancillary_seating.mmb.status.processing')}
        </Badge>
      );
    default:
      return (
        <Badge icon={<AlertIcon />} type="critical">
          {status}
        </Badge>
      );
  }
};

export default StatusBadge;
