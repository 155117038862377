const copyToClipboard = text => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);

  dummy.innerHTML = text;
  dummy.select();

  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export default copyToClipboard;
