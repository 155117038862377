import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;

  float: left;
  border-color: ${({ theme }) => theme.orbit.paletteCloudLightActive};
  box-shadow: 0.5px 0 0 0 ${({ theme }) => theme.orbit.paletteCloudLightActive},
    0 0.5px 0 0 ${({ theme }) => theme.orbit.paletteCloudLightActive},
    0.5px 0px 0 0 ${({ theme }) => theme.orbit.paletteCloudLightActive},
    1px 0 0 0 ${({ theme }) => theme.orbit.paletteCloudLightActive} inset,
    0 1px 0 0 ${({ theme }) => theme.orbit.paletteCloudLightActive} inset;
  background-color: ${({ theme, isSelected, isInRange, isDisabled }) => {
    if (isDisabled) {
      return '';
    } else if (isSelected) {
      return theme.orbit.paletteProductNormalActive;
    } else if (isInRange) {
      return theme.orbit.paletteProductLightHover;
    }
  }};
  color: ${({ isSelected, isDisabled, theme }) => {
    if (isDisabled) {
      return theme.orbit.paletteInkLight;
    } else if (isSelected) {
      return 'white';
    }
  }};
  &:hover {
    background-color: ${({ theme, isSelected, isDisabled }) =>
      !isDisabled && !isSelected && theme.orbit.paletteProductNormalHover};
    cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
    color: ${({ isDisabled }) => !isDisabled && 'white'};
  }
`;

export const ValueContainer = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-decoration: ${({ isDisabled }) => isDisabled && 'line-through'};
`;
