/**
 * Filters an array by label. Label property must be a string.
 */
export const filterValuesByLabelCaseInsensitive = (values, query) =>
  values.filter(value => value.label.toLowerCase().includes(query.toLowerCase()));

/**
 * Return index of last item in the given array.
 */
export const getLastIndexInArray = array => array.length - 1;

/**
 * Merge 2 arrays without duplicates
 * @array1 First array to be merged
 * @array2 Second array to be merged
 */
export const mergeArrays = (array1, array2) => {
  if (!array1?.length) return array2;
  if (!array2?.length) return array1;
  if (!array2?.length && !array1?.length) return [];

  return [...new Set([...array1, ...array2])];
};
