import React from 'react';
import { Link } from 'react-router-dom';
import { Illustration, Button, Text, CardSection } from '@kiwicom/orbit-components/lib';

import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';

import { NoBookingsWrapper } from '../index.styled';

const ErrorMessage = React.memo(({ filters, resetFilters }) => {
  const polyglot = usePolyglot();

  if (filters) {
    return (
      <CardSection>
        <NoBookingsWrapper data-test="no-bookings-error">
          <Illustration name="CompassPoints" size="large" spaceAfter="medium" />
          <Text weight="bold" spaceAfter="medium" align="center">
            {polyglot.t('mmb.no_bookings_title')}
          </Text>
          <Text align="center">{polyglot.t('mmb.no_bookings_description_filters')}</Text>
          <Button onClick={resetFilters}>{polyglot.t('mmb.reset_filters')}</Button>
        </NoBookingsWrapper>
      </CardSection>
    );
  }
  return (
    <CardSection>
      <NoBookingsWrapper data-test="no-bookings-error">
        <Illustration name="CompassPoints" size="large" spaceAfter="medium" />
        <Text weight="bold" spaceAfter="medium" align="center">
          {polyglot.t('mmb.no_bookings_title')}
        </Text>
        <Text align="center">{polyglot.t('mmb.no_bookings_description')}</Text>
        <Link to={ROUTE_CONFIG.KIWI_SEARCH.path}>
          <Button>{polyglot.t('mmb.start_booking')}</Button>
        </Link>
      </NoBookingsWrapper>
    </CardSection>
  );
});

export default ErrorMessage;
