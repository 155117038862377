import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import Polyglot from 'shapes/Polyglot';
import HttpResponse from 'shapes/HttpResponse';
import { History } from 'shapes/ReactRouter';
import { useToggle } from 'utils/hooks';
import { fireDocsEvent } from 'utils/ga';
import { OrbitLoader } from 'common';
import { SERVICE_DESK } from 'consts/support';

import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';

import KiwiUserAlert from './KiwiUserAlert';
import RequestCategory from './RequestCategory';
import CreateSupportRequestModal from './CreateSupportRequestModal';
import SearchFAQ from './SearchFAQ';

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.orbit.colorTextLinkPrimary};
  font-weight: bold;
`;

const BreadcrumbArrowContainer = styled.span`
  padding: 0 4px;
`;

function CreateRequest({
  history,
  requestCategoriesResponse,
  fetchRequestCategories,
  serviceDesk,
  clearRequests,
}) {
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const createRequestSuccessAlert = useToggle();
  const { isKiwiUser } = useContext(AuthState);
  const polyglot = usePolyglot();

  useEffect(() => {
    if (!isKiwiUser && !requestCategoriesResponse.isValid()) {
      fetchRequestCategories(`jira/${serviceDesk}/requesttype`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    if (serviceDesk === SERVICE_DESK.BPSD) {
      fireDocsEvent({
        event: 'tequila.help.createRequestModal.closed',
      });
    }

    setSelectedRequestType(null);
  };

  const closeCreateRequestSuccessAlert = () => {
    createRequestSuccessAlert.setOff();
  };

  const openModal = requestType => {
    if (serviceDesk === SERVICE_DESK.BPSD) {
      fireDocsEvent({
        event: 'tequila.help.createRequestModal.opened',
        requestType: requestType.name,
        page: window.location.pathname,
      });
    }
    setSelectedRequestType(requestType);
    closeCreateRequestSuccessAlert();
  };

  const handleCreateRequestSuccess = () => {
    createRequestSuccessAlert.setOn();
    setSelectedRequestType(null);
  };

  if (isKiwiUser) {
    return <KiwiUserAlert polyglot={polyglot} />;
  }

  return (
    <Stack direction="column">
      {selectedRequestType && (
        <CreateSupportRequestModal
          polyglot={polyglot}
          closeModal={closeModal}
          requestType={selectedRequestType}
          serviceDesk={serviceDesk}
          clearRequests={clearRequests}
          handleCreateRequestSuccess={handleCreateRequestSuccess}
        />
      )}

      <span>
        <StyledNavLink
          to=""
          onClick={e => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {polyglot.t('common.back')}
        </StyledNavLink>
        <BreadcrumbArrowContainer>&raquo;</BreadcrumbArrowContainer>
      </span>

      {requestCategoriesResponse.isError() && (
        <Alert type="critical" spaceAfter="large" icon>
          {polyglot.t('support.createRequest.error')}
        </Alert>
      )}
      {createRequestSuccessAlert.isOn && (
        <Alert type="success" spaceAfter="large" closable onClose={closeCreateRequestSuccessAlert}>
          {polyglot.t(`support.cssd.success_message`)}
        </Alert>
      )}
      {requestCategoriesResponse.isLoading() && (
        <Stack justify="center" align="center">
          <OrbitLoader id="solutions-loader" visible />
        </Stack>
      )}

      {requestCategoriesResponse.isValid() && (
        <>
          {serviceDesk === SERVICE_DESK.BPSD && <SearchFAQ />}
          {requestCategoriesResponse.requestCategories.map(category => (
            <RequestCategory openModal={openModal} category={category} key={category.id} />
          ))}
        </>
      )}
    </Stack>
  );
}

CreateRequest.propTypes = {
  history: PropTypes.shape(History).isRequired,
  polyglot: PropTypes.shape(Polyglot).isRequired,
  requestCategoriesResponse: HttpResponse.isRequired,
  serviceDesk: PropTypes.string.isRequired,
  fetchRequestCategories: PropTypes.func.isRequired,
  clearRequests: PropTypes.func.isRequired,
};

export default withRouter(CreateRequest);
