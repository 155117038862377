import styled, { css } from 'styled-components';

export const StyledError = styled.div`
  z-index: 30;
  bottom: 10%;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 600px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.orbit.backgroundButtonCritical};
  color: ${({ theme }) => theme.orbit.colorTextButtonFilled};
  font-size: 16px;
  padding: 16px 24px;
  text-align: center;
  transition: all 200ms ease-in-out;

  ${({ visible }) =>
    !visible &&
    css`
      height: 0;
      padding: 0 !important;
      opacity: 0;
      visibility: hidden;
    `}
`;
