import { useContext, useEffect } from 'react';
import { DepositState } from './';

const useCompanyDeposit = companyName => {
  const { state, loadDeposit } = useContext(DepositState);
  useEffect(() => {
    if (!state[companyName]) {
      loadDeposit(companyName);
    }
    // eslint-disable-next-line
  }, [companyName]);

  return state[companyName] || { loading: false, data: null, error: null };
};

export default useCompanyDeposit;
