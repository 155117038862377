import React, { useState, useRef } from 'react';
import { FixedSizeList } from 'react-window';

import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { ClickOutside } from 'common';
import { usePolyglot } from 'components/services/i18n';
import languagesJSON from 'data/languages.v2.json';

import {
  DropdownIconContainer,
  ListChoiceContainer,
  InputContainer,
} from './SelectLanguage.styled';
import { useLocalStorage } from 'components/services/localStorage';

const ITEM_SIZE = 43;
const MAX_HEIGHT = 400;

const SelectLanguage = ({ onChange }) => {
  const polyglot = usePolyglot();
  const [language, setLanguage] = useLocalStorage('mmb_language', {
    label: 'English',
    value: 'en',
    flag: 'gb',
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const inputRef = useRef(null);
  const handleSelectLanguage = lang => {
    setLanguage(lang);
    setMenuOpen(false);
    onChange(lang.value);
  };

  const languages = Object.keys(languagesJSON).map(key => ({
    label: languagesJSON[key]?.displayName,
    value: key,
    flag: languagesJSON[key]?.flag,
  }));

  const Row = ({ data, index, style }) => {
    return (
      <div style={style}>
        <ListChoice
          title={data[index].label}
          icon={<CountryFlag code={data[index].flag} />}
          onClick={() => handleSelectLanguage(data[index])}
        />
      </div>
    );
  };

  return (
    <>
      <ClickOutside onClick={() => setMenuOpen(false)}>
        <InputContainer>
          <InputField
            readOnly
            size="normal"
            label={polyglot.t('common.choose_language')}
            suffix={
              <DropdownIconContainer open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
                <ChevronDownIcon />
              </DropdownIconContainer>
            }
            prefix={<CountryFlag code={language.flag} />}
            value={language.label}
            onFocus={() => setMenuOpen(true)}
            ref={inputRef}
          />
        </InputContainer>
        {menuOpen && (
          <ListChoiceContainer menuHeight={MAX_HEIGHT} size="normal">
            <FixedSizeList
              itemData={languages}
              itemCount={languages.length}
              height={
                languages.length * ITEM_SIZE > MAX_HEIGHT
                  ? MAX_HEIGHT
                  : languages.length * ITEM_SIZE
              }
              itemSize={ITEM_SIZE}
            >
              {Row}
            </FixedSizeList>
          </ListChoiceContainer>
        )}
      </ClickOutside>
    </>
  );
};

export default SelectLanguage;
