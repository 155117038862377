import React from 'react';
import styled from 'styled-components';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { ErrorMessageComponent } from 'common';
import { usePolyglot } from 'components/services/i18n';

import LogRow from './LogRow';

const NoDataContainer = styled.div`
  width: 339px;
`;

const Results = ({ logs }) => {
  const polyglot = usePolyglot();

  if (logs.loading) {
    return <Loading type="pageLoader" text={'Loading logs...'} />;
  }

  if (!logs.data) {
    return (
      <NoDataContainer>
        <Illustration name="Offline" spaceAfter="large" />
        <Text type="primary" weight="bold" spaceAfter="small">
          {polyglot.t('common.no_data')}
        </Text>
      </NoDataContainer>
    );
  }

  return (
    <>
      <Table compact>
        <TableHead>
          <TableRow>
            <TableCell>{polyglot.t('logs.view_log')}</TableCell>
            <TableCell align="center">{polyglot.t('logs.time')}</TableCell>
            <TableCell align="left">{polyglot.t('logs.log')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.data.data.map((row, i) => (
            <LogRow key={i} data={row} polyglot={polyglot} />
          ))}
        </TableBody>
      </Table>
      <Stack direction="row" justify="center">
        {!logs.loadMoreError ? (
          logs.data.next_page_token &&
          logs && (
            <Button dataTest="load-more-button" loading={logs.loadingMore} onClick={logs.loadMore}>
              {polyglot.t('common.load_more')}
            </Button>
          )
        ) : (
          <ErrorMessageComponent>{polyglot.t('logs.load_more_error')}</ErrorMessageComponent>
        )}
      </Stack>
    </>
  );
};

export default Results;
