import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { ROUTE_CONFIG } from 'consts/routes';
import useBooking from 'components/services/booking/useBooking';
import usePolyglot from 'components/services/i18n/usePolyglot';

const getErrorTexts = error => {
  switch (error) {
    case 'time_limit_exceeded':
      return [
        'booking_tool.time_limit.error_modal.title',
        'booking_tool.time_limit.error_modal.description',
      ];
    default:
      return [
        'booking_tool.payment.error_modal.title',
        'booking_tool.payment.error_modal.description',
      ];
  }
};
const ErrorModal = ({ error }) => {
  const { clearCheckFlightsInterval } = useBooking();
  const history = useHistory();
  const polyglot = usePolyglot();

  const [title, description] = getErrorTexts(error.type);
  return (
    <Modal hasCloseButton={false} preventOverlayClose size="small">
      <ModalHeader
        illustration={<Illustration name="NoResults" size="small" />}
        title={polyglot.t(title)}
        description={polyglot.t(description)}
      />
      <ModalFooter>
        <Button
          type="secondary"
          onClick={() => {
            clearCheckFlightsInterval();
            history.replace(ROUTE_CONFIG.KIWI_SEARCH.path);
          }}
        >
          {polyglot.t('booking_tool.payment.error_modal.action')}
        </Button>
        {error.type === 'time_limit_exceeded' && (
          <Button
            onClick={() => {
              clearCheckFlightsInterval();
              history.go(0);
            }}
          >
            {polyglot.t('booking_tool.time_limit.error_modal.action')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
