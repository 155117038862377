import React, { useContext } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';
import { MMBState } from 'components/services/mmb';

const RefundModal = ({ bid, onClose, onSubmit }) => {
  const { state } = useContext(MMBState);
  const polyglot = usePolyglot();

  return (
    <Modal size="small" onClose={onClose} dataTest="refund-modal">
      <ModalHeader
        title={polyglot.t('mmb.booking_details.refund')}
        description={
          state.bookingsState?.[bid]?.requestRefundError ? (
            <Text type="critical">
              {polyglot.t('mmb.booking_details.refund_modal.error_requesting_refund')}
            </Text>
          ) : (
            polyglot.t('mmb.booking_details.refund_modal.are_you_sure', {
              bid: bid,
            })
          )
        }
      />

      <ModalFooter>
        <Button type="secondary" size="large" onClick={onClose} dataTest="cancel-btn">
          {polyglot.t('common.cancel')}
        </Button>
        <Button
          type="primary"
          size="large"
          loading={state.bookingsState?.[bid]?.requestingRefund}
          onClick={onSubmit}
          dataTest="request-refund-btn"
        >
          {polyglot.t('mmb.booking_details.refund_modal.request_refund')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RefundModal;
