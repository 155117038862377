import React from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';

import { usePolyglot } from 'components/services/i18n';

const ErrorAlert = ({ error }) => {
  const polyglot = usePolyglot();
  if (error.detail === 'too late to change hours') {
    return (
      <Alert
        type="critical"
        spaceAfter="large"
        title={polyglot.t('mmb.booking_details.add_baggage_modal.error_alert.too_late')}
        dataTest="too-late-alert"
      >
        {polyglot.t('mmb.booking_details.add_baggage_modal.error_alert.until_departure')}
      </Alert>
    );
  }
  return (
    <Alert
      type="critical"
      spaceAfter="large"
      title={polyglot.t('mmb.booking_details.add_baggage_modal.error_alert.insufficient_credits')}
      dataTest="insufficient-credits-alert"
    >
      {polyglot.t('mmb.booking_details.add_baggage_modal.error_alert.add_more_credits')}
    </Alert>
  );
};

export default ErrorAlert;
