import React, { useEffect, useContext, useRef } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { PageContainer } from 'common';
import { keys } from 'consts/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { SmartPassContext } from 'components/services/smartPass';
import { ROUTE_CONFIG } from 'consts/routes';

import Legal from './Legal';
import General from './General';
import FastTrack from './FastTrack';
import TransferAssistance from './TransferAssistance';
import BagTransferAssist from './BagTransferAssist';
import Vouchers from './Vouchers';
import Header from './Header';
import ErrorsList from './ErrorsList';
import SaveForReviewModal from './Modals/SaveForReviewModal';
import UnsavedVoucherModal from './Modals/UnsavedVoucherModal';
import RequestChangeModal from './Modals/RequestChangeModal';
import FastTrackVoucherModal from './Modals/FastTrackVoucherModal';
import VoucherExampleModal from './Modals/VoucherExampleModal';
import VoucherPreviewModal from './Modals/VoucherPreviewModal';
import DeleteVoucherModal from './Modals/DeleteVoucherModal';
import DeactivateModal from './Modals/DeactivateModal';
import ExitModal from './Modals/ExitModal';

const Form = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  companyName,
  disabled,
  changeDisabled,
  status,
  responseError,
  smartPassId,
  errors,
  touched,
  getSubmitHandler,
  setFieldTouched,
}) => {
  const history = useHistory();
  const polyglot = usePolyglot();
  const {
    state,
    saveForReviewModal,
    unsavedVoucherModal,
    requestChangeModal,
    fastTrackVoucherModal,
    voucherExampleModal,
    voucherPreviewModalInfo,
    deleteVoucherModalInfo,
    deactivateModal,
    exitModal,
    editingVoucher,
    addNewVoucherScreen,
  } = useContext(SmartPassContext);

  const initialFormValues = useRef(values);
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, [saveForReviewModal.isOn]);

  const onSubmit = saveType => {
    getSubmitHandler({ saveType })(values);
  };

  const isVoucherScreenShown = editingVoucher || addNewVoucherScreen.isOn;

  const handleBackClick = () => {
    if (isVoucherScreenShown) {
      unsavedVoucherModal.setOn();
    } else if (!R.equals(initialFormValues.current, values)) {
      exitModal.setOn();
    } else {
      history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass`);
    }
  };

  return (
    <>
      {saveForReviewModal.isOn && <SaveForReviewModal errors={errors} touched={touched} />}
      {unsavedVoucherModal.isOn && <UnsavedVoucherModal />}
      {requestChangeModal.isOn && <RequestChangeModal />}
      {fastTrackVoucherModal.isOn && <FastTrackVoucherModal />}
      {voucherExampleModal.isOn && <VoucherExampleModal />}
      {exitModal.isOn && (
        <ExitModal
          companyName={companyName}
          status={status}
          onSubmit={onSubmit}
          errors={errors}
          touched={touched}
        />
      )}
      {deactivateModal && (
        <DeactivateModal setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
      )}
      {voucherPreviewModalInfo && <VoucherPreviewModal />}
      {deleteVoucherModalInfo && <DeleteVoucherModal />}
      <Header
        smartPassName={values[keys.SMART_PASS_NAME]}
        companyName={companyName}
        onSubmit={onSubmit}
        status={status}
        changeDisabled={changeDisabled}
        disabled={disabled}
        handleBackClick={handleBackClick}
        smartPassId={smartPassId}
        isVoucherScreenShown={isVoucherScreenShown}
        unsavedVoucherModal={unsavedVoucherModal}
      />
      <Stack justify="center">
        <PageContainer>
          {responseError && (
            <Alert type="critical" spaceAfter="large">
              {responseError}
            </Alert>
          )}
          {state.approveSmartPassError && (
            <Alert type="critical" spaceAfter="large">
              {polyglot.t('smart_pass.approve_error')}
            </Alert>
          )}

          {!R.isEmpty(errors) && !R.isEmpty(touched) && <ErrorsList errors={errors} />}
          <Legal
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            disabled={disabled}
            errors={errors}
            touched={touched}
          />
          <General
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            disabled={disabled}
            companyName={companyName}
            errors={errors}
            touched={touched}
          />
          <FastTrack
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            disabled={disabled}
            companyName={companyName}
            errors={errors}
            touched={touched}
          />
          <TransferAssistance
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            disabled={disabled}
            errors={errors}
            touched={touched}
          />
          <BagTransferAssist
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
            errors={errors}
            touched={touched}
          />
          <Vouchers
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            disabled={disabled}
            companyName={companyName}
            errors={errors}
            touched={touched}
          />
        </PageContainer>
      </Stack>
    </>
  );
};

export default Form;
