import React from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import OrbitInputField from '@kiwicom/orbit-components/lib/InputField';

import { usePolyglot } from 'components/services/i18n';

const InputField = ({ name, ...props }) => {
  const { register, errors } = useFormContext();
  const polyglot = usePolyglot();

  return (
    <OrbitInputField
      {...props}
      ref={register}
      name={name}
      error={polyglot.t(errors?.[name]?.message) ?? null}
    />
  );
};

export default InputField;
