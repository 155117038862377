import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import Polyglot from 'shapes/Polyglot';
import { COMPANY_TYPES } from 'consts/company';

import { AccountTypeWrapper, AccountTypeRadioWrapper } from './AccountType.styled';

const AccountType = ({ polyglot, values, handleChange, resetForm, setFieldValue, setType }) => {
  const onChange = companyType => e => {
    resetForm();
    setType(companyType);
    handleChange(e);
  };

  return (
    <>
      <Stack spaceAfter="large">
        <Heading type="title2">{polyglot.t('register.type_of_account')}</Heading>
      </Stack>
      <AccountTypeWrapper>
        <AccountTypeRadioWrapper>
          <Radio
            label={polyglot.t('register.type_company')}
            info={polyglot.t('register.company_desc')}
            value={COMPANY_TYPES.COMPANY}
            checked={values.type === COMPANY_TYPES.COMPANY}
            onChange={onChange(COMPANY_TYPES.COMPANY)}
            name="type"
            dataTest="register-radio-type-company"
          />
        </AccountTypeRadioWrapper>
        <AccountTypeRadioWrapper>
          <Radio
            label={polyglot.t('register.type_person')}
            info={polyglot.t('register.person_desc')}
            value={COMPANY_TYPES.PERSON}
            checked={values.type === COMPANY_TYPES.PERSON}
            onChange={onChange(COMPANY_TYPES.PERSON)}
            name="type"
            dataTest="register-radio-type-person"
          />
        </AccountTypeRadioWrapper>
      </AccountTypeWrapper>
    </>
  );
};

AccountType.schema = Yup.object().shape({
  type: Yup.string().required('validation.cant_be_empty'),
});

AccountType.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AccountType;
