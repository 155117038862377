import React from 'react';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { useCurrency } from 'components/services/currencies';
import { precisionRound } from 'utils/number';
import { usePolyglot } from 'components/services/i18n';

import { getBaggageSize, getIconFromCategory, getTextFromCategory } from '../../../services/utils';

const BaggageSizeText = styled.p`
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
  line-height: 24px;
  margin: 0;
`;

const Title = styled.p`
  line-height: 24px;
  margin: 0;
`;

const CurrentItem = styled.span`
  line-height: 24px;
`;

const OptionItem = ({
  isFirstItem,
  amount,
  restrictions,
  category,
  price,
  shouldConvertPrice,
  isCurrentCombination,
}) => {
  const { getConvertedPriceString } = useCurrency();
  //only show price for first item in case there are multiple bags in combination
  const polyglot = usePolyglot();
  const getFirstItemInfo = (isCurrent, priceValue) => {
    const convertedPriceString = shouldConvertPrice ? `${getConvertedPriceString(priceValue)}` : '';
    return isCurrent ? (
      <Text as="span" weight="bold" type="secondary" dataTest="bag-picker-option-current">
        {polyglot.t('mmb.booking_details.add_baggage_modal.option.current')}
      </Text>
    ) : (
      <Text as="span" weight="bold" dataTest="bag-picker-option-price">
        {precisionRound(priceValue, 2)} € {convertedPriceString && `(${convertedPriceString})`}
      </Text>
    );
  };

  return (
    <Stack flex dataTest={`bag-picker-option-${category}`} spacing="none">
      <Stack shrink spacing="XSmall">
        {getIconFromCategory(category, 'medium', 'primary')}
        <Stack inline direction="column" spacing="none">
          <Title>
            {amount > 1 && (
              <Text as="span" weight="bold">
                {`${amount}× `}
              </Text>
            )}
            {category === 'holdBag' &&
              typeof restrictions.weight === 'number' &&
              `${restrictions.weight} kg `}
            {polyglot.t(getTextFromCategory(category))}
          </Title>
        </Stack>
      </Stack>
      <Stack justify="between" shrink basis="100%">
        <BaggageSizeText>{getBaggageSize(restrictions)}</BaggageSizeText>
        <CurrentItem>
          {isFirstItem && getFirstItemInfo(isCurrentCombination, price.amount)}
        </CurrentItem>
      </Stack>
    </Stack>
  );
};

export default OptionItem;
