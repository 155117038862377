import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TableRow from './TableRow';

const StyledTableBody = styled.tbody`
  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  }
`;

/**
 * The expandable table rows are achieved by adding the 'expandable' prop to the TableBody.
 * The first row is going to be the displayed row when the table is not expanded
 * The second row is usually the head of the subtable, noted with the 'head' prop
 * All other rows below are normal rows in the subtable
 */
const TableBody = ({ children, expandable, ...props }) => {
  const childrenArray = React.Children.toArray(children);

  const displayedChildren = expandable
    ? [React.cloneElement(R.head(children), { expandable }), ...R.tail(childrenArray)].map(
        (c, i) => ({
          ...c,
          key: i,
        }),
      )
    : children;

  return (
    <StyledTableBody {...props}>
      {displayedChildren}
      {expandable && <TableRow separator />}
    </StyledTableBody>
  );
};

TableBody.propTypes = {
  expandable: PropTypes.bool,
};

export default TableBody;
