import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';

const WarningModal = ({ loading, onClose, onAccept }) => {
  const polyglot = usePolyglot();

  return (
    <Modal onClose={onClose} size="small">
      <ModalHeader
        title={polyglot.t('allowlist.remove_ip_title')}
        description={polyglot.t('allowlist.remove_last_ip_description')}
      />
      <ModalFooter>
        <Button type="criticalSubtle" size="large" onClick={onClose}>
          {polyglot.t('common.cancel')}
        </Button>
        <Button type="critical" size="large" onClick={onAccept} loading={loading}>
          {polyglot.t('common.remove')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarningModal;
