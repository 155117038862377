import styled, { createGlobalStyle } from 'styled-components';

export const RemoveContainer = styled.div`
  cursor: pointer;
`;

export const OverlayContainer = styled.div`
  margin-top: 8px;
  z-index: 5;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  background: white;
`;

export const DatePickerGlobals = createGlobalStyle`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
  }
  .DayPicker-NavButton--prev {
    margin-right: 0 !important;
    right: 45px !important;
  }
`;
