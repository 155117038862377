import React, { useMemo, useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Text from '@kiwicom/orbit-components/lib/Text';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { ROLES } from 'consts/roles';
import { mainNavigationItems, ROUTE_CONFIG } from 'consts/routes';
import { isCustomerSupportEnabled } from 'consts/support';
import { useIsMounted } from 'utils/hooks';
import { TequilaLogotype, ScrollableVertical } from 'common';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';
import useMenuState from 'components/services/menu/useMenuState';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useOnboardingState from 'components/services/localStorage/useOnboardingState';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';

import NavigationItem from './NavigationItem';
import Drawer from './Drawer';
import {
  HeaderBorder,
  LogoContainer,
  NavigationControlContainer,
  NavigationContainer,
  NavigationItemsBorder,
  Backdrop,
} from './Navigation.styled';

const noop = () => {};

const Navigation = () => {
  const polyglot = usePolyglot();
  const menuCtx = useMenuState();
  const { pathname } = useLocation();
  const { checkAccess } = useContext(AuthState);
  const [mainNav, setMainNav] = useState(mainNavigationItems);
  const user = useCurrentUser();
  const isMounted = useIsMounted();
  const specialApps = useSpecialApps(user.companyName);

  const destinationDemandDashboardApp = specialApps.apps?.find(
    app => app.name === 'destination-demand-dashboard',
  );

  const [onboardingState] = useOnboardingState();

  useEffect(() => {
    Promise.all(mainNavigationItems.map(filterNavigationItems))
      .then(main => {
        if (isMounted()) {
          return setMainNav(mainNavigationItems.filter((_, index) => main[index]));
        }
      })
      .catch(() => {
        if (isMounted()) {
          setMainNav(mainNavigationItems);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingState]);

  const onMouseEnter = useMemo(() => {
    return menuCtx.isLargeScreen ? noop : menuCtx.expandNavigation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCtx.isLargeScreen]);

  const onMouseLeave = useMemo(() => {
    return menuCtx.isLargeScreen ? noop : menuCtx.collapseNavigation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCtx.isLargeScreen]);

  const onControlClick = useMemo(() => {
    return menuCtx.navigationExpanded ? menuCtx.collapseNavigation : menuCtx.expandNavigation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCtx.navigationExpanded]);

  const filterNavigationItems = async item => {
    if (item.resource === ROUTE_CONFIG.COMPANY.resource) {
      const res = await checkAccess('fe.page.companies', ['read'], user.companyName);
      return !res;
    }

    if (item.resource === ROUTE_CONFIG.CUSTOMER_SUPPORT.resource) {
      return isCustomerSupportEnabled();
    }

    if (item.resource === ROUTE_CONFIG.GETTING_STARTED.resource) {
      return (
        [ROLES.KIWI_ADMIN, ROLES.KIWI_ACCOUNT_MANAGER, ROLES.PARTNER_ADMIN].includes(user.role) &&
        !onboardingState.done
      );
    }

    return true;
  };

  if (menuCtx.isHeaderShown && !menuCtx.isNavigationShown) {
    return (
      <LogoContainer fixed>
        <TequilaLogotype polyglot={polyglot} collapsed={!menuCtx.navigationExpanded} />
      </LogoContainer>
    );
  }

  if (!menuCtx.isHeaderShown && !menuCtx.isNavigationShown) {
    return null;
  }

  return (
    <>
      <HeaderBorder navigationOpen={menuCtx.navigationExpanded} />
      {menuCtx.secondaryNavigation && <Backdrop onClick={menuCtx.toggleSecondaryNavigation} />}
      <NavigationContainer
        id="navigation"
        open={menuCtx.navigationExpanded}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <LogoContainer>
          <TequilaLogotype polyglot={polyglot} collapsed={!menuCtx.navigationExpanded} />
        </LogoContainer>

        <ScrollableVertical>
          {mainNav.map((item, id) => (
            <NavigationItem
              key={id}
              item={item}
              user={user}
              polyglot={polyglot}
              pathname={pathname}
              deselect={menuCtx.secondaryNavigation}
              navigationOpen={menuCtx.navigationExpanded}
            />
          ))}
          <NavigationItemsBorder navigationOpen={menuCtx.navigationExpanded}>
            {menuCtx.navigationExpanded && (
              <Text type="secondary" weight="bold" size="normal" uppercase={true}>
                {polyglot.t('navigation.your_tools')}
              </Text>
            )}
          </NavigationItemsBorder>
          {menuCtx.loading ? (
            <Loading
              loading={true}
              type="pageLoader"
              text={polyglot.t('navigation.loading_navigation')}
            />
          ) : (
            menuCtx.menu.map((item, id) => {
              //Checks if special apps are loaded and if so, is Demand Dashboard active
              if (
                item.resource === 'feature.demand_dashboard' &&
                (!specialApps.demandDashboard || specialApps.demandDashboard?.deleted)
              ) {
                return null;
              }

              if (
                item.resource === 'feature.destination_dashboard' &&
                (!destinationDemandDashboardApp || destinationDemandDashboardApp?.deleted)
              ) {
                return null;
              }

              if (
                item.resource === 'model.data_marketplace_dataset' &&
                (!specialApps.dataMarketplace || specialApps.dataMarketplace?.deleted)
              ) {
                return null;
              }

              return (
                <NavigationItem
                  key={id}
                  item={item}
                  user={user}
                  polyglot={polyglot}
                  pathname={pathname}
                  deselect={menuCtx.secondaryNavigation}
                  navigationOpen={menuCtx.navigationExpanded}
                />
              );
            })
          )}
        </ScrollableVertical>
        <Drawer user={user} polyglot={polyglot} menuCtx={menuCtx} />
        <NavigationControlContainer>
          <ButtonLink
            type="secondary"
            iconLeft={
              !menuCtx.navigationExpanded ? <ChevronForwardIcon /> : <ChevronBackwardIcon />
            }
            size="large"
            width="60px"
            title="Toggle navigation"
            onClick={onControlClick}
            ariaControls="navigation"
            ariaExpanded={menuCtx.navigationExpanded}
          />
        </NavigationControlContainer>
      </NavigationContainer>
    </>
  );
};

export default React.memo(Navigation);
