import styled from 'styled-components';

export const ErrorsContainer = styled.div`
  z-index: 801;
  bottom: 10%;
  position: fixed;
  left: 0;
  right: 0;
`;

export const AlertContainer = styled.div`
  width: 672px;
  margin: 2px auto 4px;
`;
