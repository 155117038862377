import React from 'react';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { calculateNightsLayover } from 'utils/dates';

const Container = styled.div`
  border-left: 1px dashed ${({ theme }) => theme.orbit.borderColorButtonInfoBordered};
  margin-left: 26px;
  padding: 10px 0 10px 24px;

  p {
    line-height: 18px;
  }
`;

function parseMillis(duration) {
  return {
    hours: Math.floor((duration / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((duration / (1000 * 60)) % 60),
  };
}

const ItineraryTransferData = ({ segment, nextSegment }) => {
  const polyglot = usePolyglot();

  const numOfNights = calculateNightsLayover(
    segment.arrival.time.local,
    nextSegment.departure.time.local,
  );

  const layoverTime = parseMillis(
    new Date(nextSegment.departure.time.utc) - new Date(segment.arrival.time.utc),
  );

  return (
    <div>
      <Container>
        {!numOfNights &&
          (segment.operatingCarrier.name === nextSegment.operatingCarrier.name &&
          segment.flightNumber === nextSegment.flightNumber ? (
            <Stack spacing="none" direction="column">
              <Text type="info" size="small">
                {!!layoverTime.hours &&
                  polyglot.t('common.itinerary.layover_hours', { hours: layoverTime.hours })}
                {!!layoverTime.minutes &&
                  polyglot.t('common.itinerary.layover_minutes', { minutes: layoverTime.minutes })}
                {polyglot.t('common.itinerary.technical_stop')}
              </Text>
              <Text size="small" weight="bold" type="info">
                {polyglot.t('searchAndBooking.technical_stop.description')}
              </Text>
            </Stack>
          ) : (
            <Text type="info" size="small">
              {!!layoverTime.hours &&
                polyglot.t('common.itinerary.layover_hours', { hours: layoverTime.hours })}
              {!!layoverTime.minutes &&
                polyglot.t('common.itinerary.layover_minutes', { minutes: layoverTime.minutes })}
              {polyglot.t('common.itinerary.layover')}
            </Text>
          ))}
        {nextSegment.isBagRecheckRequired && (
          <Text weight="bold" type="info" size="small">
            {polyglot.t('common.itinerary.bag_recheck')}
          </Text>
        )}
        {nextSegment.isSelfTransfer && (
          <Text weight="bold" type="info" size="small">
            {polyglot.t('common.itinerary.self_transfer', {
              departure_station_name: nextSegment.departure.station.name,
              departure_station_code: nextSegment.departure.station.code,
            })}
          </Text>
        )}
        {!!numOfNights && (
          <Text type="info" size="small">
            {polyglot.t('common.itinerary.nights', {
              night: numOfNights,
              nights: numOfNights === 1 ? 'night' : 'nights',
              arrival_city: segment.arrival.place.city,
            })}
          </Text>
        )}
      </Container>
    </div>
  );
};

export default ItineraryTransferData;
