export const CUSTOM = 'Custom';
export const MILLENNIUM = 'millennium';
export const HOUR = 'hour';
export const DAY = 'day';
export const YESTERDAY = 'YESTERDAY';
export const WEEK = 'week';
export const LAST_WEEK = 'LAST_WEEK';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_14_DAYS = 'LAST_14_DAYS';
export const LAST_21_DAYS = 'LAST_21_DAYS';
export const LAST_28_DAYS = 'LAST_28_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_60_DAYS = 'LAST_60_DAYS';
export const LAST_90_DAYS = 'LAST_90_DAYS';
export const LAST_180_DAYS = 'LAST_180_DAYS';
export const LAST_364_DAYS = 'LAST_364_DAYS';
export const LAST_365_DAYS = 'LAST_365_DAYS';
export const MONTH = 'month';
export const LAST_MONTH = 'LAST_MONTH';
export const YEAR = 'year';
export const LAST_YEAR = 'LAST_YEAR';
export const FULL_DATE = 'FULL_DATE';
export const DATE = 'DATE';

export const PREV_PERIOD = 'PREV_PERIOD';
export const PREV_YEAR = 'PREV_YEAR';

export const DAY_INTERVAL_FORMAT = 'yyyy-MM-dd';
export const HOUR_INTERVAL_FORMAT = `${DAY_INTERVAL_FORMAT} - HH`;
export const YEAR_INTERVAL_FORMAT = 'yyyy';
export const MONTH_INTERVAL_FORMAT = 'MM/yyyy';
export const FULL_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_INTERVAL_FORMAT = 'dd MMM, yyyy';
export const DATEFNS_INVALID_DATE = 'Invalid Date';
export const DATE_AND_TIME = `${DAY_INTERVAL_FORMAT} HH:mm`;
export const DAY_MONTH_YEAR_FORMAT = 'dd/MM/yyyy';

export const monthsValues = {
  JANUARY: '01',
  FEBRUARY: '02',
  MARCH: '03',
  APRIL: '04',
  MAY: '05',
  JUNE: '06',
  JULY: '07',
  AUGUST: '08',
  SEPTEMBER: '09',
  OCTOBER: '10',
  NOVEMBER: '11',
  DECEMBER: '12',
};

export const monthsAbbreviationValues = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12',
};

export const monthsOptions = [
  {
    label: 'common.months.january',
    value: monthsValues.JANUARY,
  },
  {
    label: 'common.months.february',
    value: monthsValues.FEBRUARY,
  },
  {
    label: 'common.months.march',
    value: monthsValues.MARCH,
  },
  {
    label: 'common.months.april',
    value: monthsValues.APRIL,
  },
  {
    label: 'common.months.may',
    value: monthsValues.MAY,
  },
  {
    label: 'common.months.june',
    value: monthsValues.JUNE,
  },
  {
    label: 'common.months.july',
    value: monthsValues.JULY,
  },
  {
    label: 'common.months.august',
    value: monthsValues.AUGUST,
  },
  {
    label: 'common.months.september',
    value: monthsValues.SEPTEMBER,
  },
  {
    label: 'common.months.october',
    value: monthsValues.OCTOBER,
  },
  {
    label: 'common.months.november',
    value: monthsValues.NOVEMBER,
  },
  {
    label: 'common.months.december',
    value: monthsValues.DECEMBER,
  },
];
