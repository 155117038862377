import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { PageTitle, ErrorMessageComponent } from 'common';
import { ROUTE_CONFIG } from 'consts/routes';
import Polyglot from 'shapes/Polyglot';
import { History, Match } from 'shapes/ReactRouter';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';
import { executeRecaptcha } from 'utils/window';

import {
  ContentContainer,
  ActionsContainer,
  SpanWithLineHeight,
  IconContainer,
} from './index.styled';
import { Space } from 'common';
import { RegisterPrivacyPolicy, RegisterTermsOfService } from '../Register/components';

export const PasswordValidationSchema = Yup.object().shape({
  password1: Yup.string()
    .required('validation.cant_be_empty')
    .test(
      'missing_all_requirements',
      'validation.missing_all_requirements',
      (passwordString = '') => {
        const containsNumberOrUppercase = !/\d|[A-Z]/.test(passwordString);
        const hasAtleast8Chars = passwordString.length > 7;
        return containsNumberOrUppercase || hasAtleast8Chars;
      },
    )
    .min(7, 'validation.8_or_more_char')
    .matches(/\d|[A-Z]/, 'validation.must_contain_number_and_uppercase')
    .matches(/\d/, 'validation.must_contain_number')
    .matches(/[A-Z]/, 'validation.must_contain_uppercase'),
  password2: Yup.string()
    .required('validation.cant_be_empty')
    .oneOf([Yup.ref('password1')], 'common.error_passwords_match'),
  agreedOnTosAndPolicy: Yup.bool().oneOf([true], 'validation.checked'),
});

const Landing = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerTOSModalOpen, setRegisterTOSModalOpen] = useState(false);
  const [registerPolicyModalOpen, setRegisterPolicyModalOpen] = useState(false);
  const authCtx = useContext(AuthState);
  const polyglot = usePolyglot();

  const getErrorMsg = err => {
    if (err.response.data.error_code === 'INVALID_ARGUMENT_PASSWORD_TOKEN') {
      setErrMsg(polyglot.t('landing.invalid_token'));
    } else if (err.response.status === 401) {
      setErrMsg(polyglot.t('reset_password.common_password_error'));
    } else if (err.response.status === 403) {
      setErrMsg(polyglot.t('common.recaptcha_failed'));
    } else {
      setErrMsg(polyglot.t('common.exception_message'));
    }
  };

  //added manual loading flag because recaptcha needs to execute before calling activation
  const submitAccountActivation = async (password1, password2) => {
    if (password1 !== password2) {
      setLoading(false);
      return;
    }
    setErrMsg(null);
    await executeRecaptcha(
      gtoken => authCtx.activateUser(password1, token, gtoken),
      () => {
        setLoading(false);
        history.push(ROUTE_CONFIG.LOGIN.path);
      },
      err => {
        setLoading(false);
        getErrorMsg(err);
      },
    );
  };

  return (
    <div>
      <RegisterTermsOfService
        visible={registerTOSModalOpen}
        onClose={() => setRegisterTOSModalOpen(false)}
      />
      <RegisterPrivacyPolicy
        visible={registerPolicyModalOpen}
        onClose={() => setRegisterPolicyModalOpen(false)}
      />
      <PageTitle label="landing.activate_user" />
      {errMsg && (
        <Alert type="critical" spaceAfter="medium">
          {errMsg}
        </Alert>
      )}
      <Formik
        initialValues={{
          password1: '',
          password2: '',
          agreedOnTosAndPolicy: false,
        }}
        validateOnMount={true}
        validationSchema={PasswordValidationSchema}
        onSubmit={values => {
          setLoading(true);
          submitAccountActivation(values.password1, values.password2);
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <ContentContainer
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <Stack direction="column" spacing="XXLarge" justify="between">
              <InputField
                type="password"
                id="password1"
                name="password1"
                label={polyglot.t('common.enter_password')}
                placeholder={polyglot.t('reset_password.placeholder')}
                help={polyglot.t('validation.instruction')}
                error={touched.password1 && polyglot.t(errors.password1)}
                onChange={handleChange}
                suffix={
                  <IconContainer>
                    <Tooltip
                      content={polyglot.t('landing.tooltip_password_instruction')}
                      size="medium"
                      placement="top-end"
                    >
                      <InformationCircle color="secondary" size="medium" />
                    </Tooltip>
                  </IconContainer>
                }
              />
              <InputField
                type="password"
                id="password2"
                name="password2"
                label={polyglot.t('common.confirm_password')}
                placeholder={polyglot.t('reset_password.placeholder')}
                error={touched.password2 && polyglot.t(errors.password2)}
                onChange={handleChange}
              />
            </Stack>
            <div id="tooltips" />
            <Space before="xl">
              <Checkbox
                id="register-agree-tos-and-privacy"
                onChange={handleChange}
                checked={values.agreedOnTosAndPolicy}
                name="agreedOnTosAndPolicy"
                info={
                  <SpanWithLineHeight>
                    {polyglot.t('register.tos_pre')}
                    <TextLink onClick={setRegisterTOSModalOpen}>
                      {polyglot.t('register.tos')}
                    </TextLink>
                    {polyglot.t('register.tos_post')}
                    <TextLink onClick={setRegisterPolicyModalOpen}>
                      {polyglot.t('register.policy')}
                    </TextLink>
                    {polyglot.t('register.policy_post')}
                  </SpanWithLineHeight>
                }
              />
              <ErrorMessage
                name="agreedOnTosAndPolicy"
                render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
              />
            </Space>
            <ActionsContainer>
              <Button
                width="128px"
                disabled={!values.agreedOnTosAndPolicy}
                onClick={handleSubmit}
                loading={authCtx.activationInProgress || loading}
              >
                {polyglot.t('common.activate')}
              </Button>
            </ActionsContainer>
          </ContentContainer>
        )}
      </Formik>
    </div>
  );
};

Landing.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  polyglot: PropTypes.shape(Polyglot).isRequired,
  activateUser: PropTypes.func.isRequired,
  history: PropTypes.shape(History).isRequired,
  match: PropTypes.shape(Match).isRequired,
};

export default Landing;
