import React from 'react';

import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';

import useSearch from 'components/services/search/useSearch';
import { getTripTypes } from 'utils/search';
import { usePolyglot } from 'components/services/i18n';

import EmptyListChoiceIcon from './EmptyListChoiceIcon';

const Direction = () => {
  const polyglot = usePolyglot();
  const { tabData, changeTripType } = useSearch();
  const tripTypes = getTripTypes(tabData.productType);

  return tripTypes.map(option => (
    <ListChoice
      key={option.value}
      icon={option.value === tabData.tripType ? <CheckIcon /> : <EmptyListChoiceIcon />}
      title={polyglot.t(option.label)}
      onClick={() => changeTripType(option.value)}
      dataTest={`booking-select-mode-filter-${option.value}`}
    />
  ));
};

export default Direction;
