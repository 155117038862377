import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { useCurrency } from 'components/services/currencies';
import useSeating from 'components/services/bookingToolSeating/useSeating';
import { usePolyglot } from 'components/services/i18n';
import { getSingleSegmentPriceSum } from 'utils/seating';
import { precisionRound } from 'utils/number';

const SeatingPrice = ({ routeId }) => {
  const { getFormattedPriceString } = useCurrency();
  const { currentSeatings } = useSeating();
  const polyglot = usePolyglot();
  const segment = currentSeatings[routeId];

  if (!segment) {
    return (
      <Text type="secondary">
        {polyglot.t('booking_tool.seating.drawer.route_groups.group.button.select.active')}
      </Text>
    );
  } else if (segment && segment.option && !segment.price) {
    return (
      <Text>{polyglot.t('booking_tool.seating.drawer.no_seatmap.quick_option.included')}</Text>
    );
  } else if (segment && segment.option && segment.price) {
    return <Text>{getFormattedPriceString(precisionRound(segment.price.amount, 2))}</Text>;
  }

  return <Text>{getFormattedPriceString(getSingleSegmentPriceSum(segment))}</Text>;
};

export default SeatingPrice;
