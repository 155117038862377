import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import { MMB_SEATING_TABLE_BUTTON_TYPE } from 'components/scenes/Booking/scenes/DepositBooking/consts';

const ActionButton = ({ disabled, isDisabledToClick, buttonType, onOpenDrawer, routeId }) => {
  const polyglot = usePolyglot();

  if (disabled) {
    return (
      <Text type="secondary">
        {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.group.assigned_by_carrier')}
      </Text>
    );
  } else if (buttonType) {
    return (
      <>
        {buttonType === MMB_SEATING_TABLE_BUTTON_TYPE.ADD && (
          <Text type="secondary">
            {polyglot.t('tequila.ancillary_seating.mmb.card.no_seats_selected')}
          </Text>
        )}
        <Tooltip
          enabled={isDisabledToClick}
          placement="top"
          content={polyglot.t('tequila.ancillary_seating.mmb.button.tooltip')}
        >
          <Button
            disabled={isDisabledToClick}
            type="primarySubtle"
            size="small"
            onClick={() => onOpenDrawer(routeId)}
          >
            {polyglot.t(`tequila.ancillary_seating.mmb.card.${buttonType}`)}
          </Button>
        </Tooltip>
      </>
    );
  }

  return null;
};

export default ActionButton;
