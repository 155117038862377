import React, { useEffect, useCallback, useReducer } from 'react';
import getCurrencies from '@kiwicom/nitro/lib/services/currency/getAll';

export const CurrenciesState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_CURRENCIES':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_CURRENCIES_SUCCESS':
      return {
        ...state,
        loading: false,
        currencies: action.currencies,
      };
    case 'FETCH_CURRENCIES_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'SET_CURRENCY_CODE':
      return {
        ...state,
        targetCurrencyCode: action.currencyCode,
      };
    default:
      return state;
  }
};

const initialState = {
  targetCurrencyCode: 'eur',
  loading: true,
  currencies: null,
  error: null,
};

const CurrenciesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'FETCH_CURRENCIES' });

    getCurrencies()
      .then(response => {
        const data = Object.values(response).map(curr => ({
          code: curr.id,
          format: curr.format,
          name: curr.name,
          symbol: curr.format.replace('__price__', '').trim(),
          rate: curr.rate,
          precision: curr.precision,
        }));

        dispatch({
          type: 'FETCH_CURRENCIES_SUCCESS',
          currencies: data,
        });
      })
      .catch(error => {
        dispatch({ type: 'FETCH_CURRENCIES_FAIL', error });
      });
  }, []);

  const setCurrency = useCallback(code => {
    dispatch({ type: 'SET_CURRENCY_CODE', currencyCode: code.toLowerCase() });
  }, []);

  const value = {
    ...state,
    setCurrency,
  };

  return <CurrenciesState.Provider value={value}>{children}</CurrenciesState.Provider>;
};

export default React.memo(CurrenciesProvider);
