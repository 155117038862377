import format from 'date-fns/format';
import * as R from 'ramda';

import { DAY_MONTH_YEAR_FORMAT } from 'consts/dates';
import { tripTypesValues } from 'consts/search';
import {
  parseLocations,
  getParsedPassengersAmounts,
  getIncludedOrExcludedCarriersList,
} from 'utils/search';

const v2_get_to = (tabData, sortType) => {
  const tripType = tabData.tripType;
  const searchbar = tabData.searchbar[tripType];
  const parsedPassengersAmounts = getParsedPassengersAmounts(tabData.passengersAmounts);
  const [selectAirlines, selectAirlinesExclude] = getIncludedOrExcludedCarriersList(
    tabData.allCarriersList,
    tabData.filteredCarriers,
  );

  return {
    fly_from: parseLocations(searchbar.locationsFrom),
    fly_to: parseLocations(searchbar.locationsTo),
    date_from:
      R.head(searchbar.departureSelectedDates) &&
      format(new Date(R.head(searchbar.departureSelectedDates)), DAY_MONTH_YEAR_FORMAT),
    date_to:
      R.last(searchbar.departureSelectedDates) &&
      format(new Date(R.last(searchbar.departureSelectedDates)), DAY_MONTH_YEAR_FORMAT),
    ...(tripType === tripTypesValues.RETURN && !R.isEmpty(searchbar.returnSelectedDates)
      ? {
          return_from: format(
            new Date(R.head(searchbar.returnSelectedDates)),
            DAY_MONTH_YEAR_FORMAT,
          ),
          return_to: format(new Date(R.last(searchbar.returnSelectedDates)), DAY_MONTH_YEAR_FORMAT),
          ret_dtime_from: `${tabData.returnDepartureHours[0]}:00`,
          ret_dtime_to: `${tabData.returnDepartureHours[1]}:00`,
          ret_atime_from: `${tabData.returnArrivalHours[0]}:00`,
          ret_atime_to: `${tabData.returnArrivalHours[1]}:00`,
        }
      : {}),
    adults: parsedPassengersAmounts.adults,
    children: parsedPassengersAmounts.children,
    infants: parsedPassengersAmounts.infants,
    selected_cabins: tabData.selectedCabinClassType,
    vehicle_type: tabData.vehicleTypes.join(','),
    max_stopovers: tabData.numberOfStopovers,
    curr: tabData.currency,
    dtime_from: `${tabData.departureHours[0]}:00`,
    dtime_to: `${tabData.departureHours[1]}:00`,
    atime_from: `${tabData.arrivalHours[0]}:00`,
    atime_to: `${tabData.arrivalHours[1]}:00`,
    select_airlines: selectAirlines,
    select_airlines_exclude: selectAirlinesExclude,
    sort: sortType,
  };
};

export default {
  v2: {
    get: {
      to: v2_get_to,
    },
  },
};
