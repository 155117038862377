import styled from 'styled-components';

export const PopoverContainer = styled.div`
  position: relative;
  left: -105px;
  top: -40px;
`;

export const PopoverContent = styled.div`
  position: absolute;
  width: 100px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
