import React from 'react';

import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import { ROUTE_CONFIG } from 'consts/routes';

import { TabLinksWrapper, TabLink } from './TabNavigation.styled';

const TabNavigation = ({ companyName }) => {
  return (
    <TabLinksWrapper>
      <TabLink to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}`} icon backButton>
        <ChevronBackwardIcon />
      </TabLink>
    </TabLinksWrapper>
  );
};

export default TabNavigation;
