import * as R from 'ramda';

const v1_put_to = user =>
  R.reject(val => !val, {
    first_name: user.firstName,
    last_name: user.lastName,
    date_of_birth: user.dateOfBirth,
    nationality: user?.nationality?.toLowerCase(),
  });

const v1_put_from = user => {
  return {
    companyName: user.company_name,
    dateOfBirth: user.date_of_birth,
    email: user.email,
    firstName: user.first_name,
    jiraOrganizationId: user.jira_organization_id,
    lastName: user.last_name,
    nationality: user.nationality,
    role: user.role,
    status: user.status,
    username: user.username,
    uuid: user.uuid,
  };
};
export default {
  v1: {
    put: {
      to: v1_put_to,
      from: v1_put_from,
    },
  },
};
