import { useContext } from 'react';
import { I18nState } from './I18nProvider';

const usePolyglot = () => {
  const i18n = useContext(I18nState);

  return i18n.polyglot;
};

export default usePolyglot;
