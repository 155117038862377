import React from 'react';

import { CardMainContainer } from './common.styled';
import CardLoader from './CardLoader';

const Card = ({ loading, loadingText, children }) => {
  if (loading) {
    return (
      <CardMainContainer>
        <CardLoader loadingText={loadingText} />
      </CardMainContainer>
    );
  }

  return <CardMainContainer>{children}</CardMainContainer>;
};

export default Card;
