import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Text from '@kiwicom/orbit-components/lib/Text';

import { useSearch } from 'components/services/search';
import { usePolyglot } from 'components/services/i18n';
import { tripTypesValues } from 'consts/search';

import TripCard from './components/TripCard';
import WholeTripCard from './components/WholeTripCard';
import { mapDefaultSearchValues } from './utils';

const FlightChangeModal = ({ onClose, outbound, inbound, passengers, numberOfNights, affilId }) => {
  const polyglot = usePolyglot();
  const history = useHistory();
  const { setDefaultSearchValues } = useSearch();

  const handleOnFindNewFlight = useCallback((tripType, itinerary) => {
    setDefaultSearchValues(mapDefaultSearchValues(tripType, itinerary, passengers, affilId));
    history.push('/search');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal onClose={onClose} size="small">
      <ModalHeader title={polyglot.t('mmb.booking_details.modal.title')}>
        <Text>{polyglot.t('mmb.booking_details.modal.description')}</Text>
      </ModalHeader>
      <ModalSection>
        <Stack direction="column" spacing="XLarge">
          <TripCard
            arrivalTime={outbound.arrival.time.local}
            arrivalPlace={`${outbound.arrival.place.city} ${outbound.arrival.station.code}`}
            carrier={outbound.carrier}
            departurePlace={`${outbound.departure.place.city} ${outbound.departure.station.code}`}
            departureTime={outbound.departure.time.local}
            departureTimeUTC={outbound.departure.time.utc}
            duration={outbound.duration}
            title={polyglot.t('mmb.booking_details.modal.trip_card.outbound.title')}
            onClick={() => {
              handleOnFindNewFlight(tripTypesValues.ONEWAY, [outbound]);
            }}
          />
          {!!inbound && (
            <>
              <TripCard
                arrivalTime={inbound.arrival.time.local}
                arrivalPlace={`${inbound.arrival.place.city} ${inbound.arrival.station.code}`}
                carrier={inbound.carrier}
                departurePlace={`${inbound.departure.place.city} ${inbound.departure.station.code}`}
                departureTime={inbound.departure.time.local}
                departureTimeUTC={inbound.departure.time.utc}
                duration={inbound.duration}
                title={polyglot.t('mmb.booking_details.modal.trip_card.inbound.title')}
                onClick={() => {
                  handleOnFindNewFlight(tripTypesValues.ONEWAY, [inbound]);
                }}
              />
              <WholeTripCard
                departureTimeUTC={outbound.departure.time.utc}
                itinerary={[outbound, inbound]}
                numberOfNights={numberOfNights}
                onClick={() => {
                  handleOnFindNewFlight(tripTypesValues.RETURN, [outbound, inbound]);
                }}
              />
            </>
          )}
        </Stack>
      </ModalSection>
    </Modal>
  );
};

export default FlightChangeModal;
