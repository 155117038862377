import React, { useContext, useEffect } from 'react';
import * as R from 'ramda';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { usePrevious } from 'utils/hooks';

const SaveForReviewModal = ({ touched, errors }) => {
  const { state, saveForReviewModal } = useContext(SmartPassContext);
  const prevTouched = usePrevious(touched);

  useEffect(() => {
    return () => {
      saveForReviewModal.setOff();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (R.isEmpty(prevTouched) && !R.isEmpty(touched)) {
      saveForReviewModal.setOff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  const polyglot = usePolyglot();

  return (
    <Modal size="small" closable onClose={saveForReviewModal.setOff}>
      <ModalHeader
        title={polyglot.t('smart_pass.save_for_review_modal.title')}
        description={polyglot.t('smart_pass.save_for_review_modal.description')}
      />
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button onClick={saveForReviewModal.setOff} type="primarySubtle">
            {polyglot.t('common.cancel')}
          </Button>
          <Button submit loading={state.createNewSmartPassLoading}>
            {polyglot.t('smart_pass.save_for_review_modal.submit')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default SaveForReviewModal;
