import React, { useContext, useEffect } from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Timeline, { TimelineStep } from '@kiwicom/orbit-components/lib/Timeline';

import { usePolyglot } from 'components/services/i18n';
import { MMBState } from 'components/services/mmb';

import Card from '../../components/Card';

const COMMON_STATES = ['requested', 'in_progress', 'waiting_for_carrier', 'carrier_is_refunding'];

const NONREFUNDABLE_STATES = [...COMMON_STATES, 'non_refundable'];

const REFUNDABLE_STATES = [...COMMON_STATES, 'refunded'];

const getTimelineState = status => {
  let index = NONREFUNDABLE_STATES.findIndex(s => s === status);

  if (index === -1) {
    index = REFUNDABLE_STATES.findIndex(s => s === status);
  }

  if (index === -1) {
    return [];
  }

  const res = [...new Array(index + 1)].map((_, i) => i + 1);

  res.unshift(0);

  return res;
};

const getLastStepData = status => {
  if (status === 'non_refundable') {
    return {
      label: 'mmb.booking_details.refund.non_refundable',
      description: 'mmb.booking_details.refund.we_are_sorry',
      type: 'critical',
    };
  }

  return {
    label: 'mmb.status.refunded',
    description: 'mmb.booking_details.refund.forward_refund',
    type: status === 'refunded' ? 'success' : undefined,
  };
};

const Refund = ({ bid }) => {
  const { state, getRefundState } = useContext(MMBState);

  const { refund, refundError } = state.bookingsState[bid] || {};
  const polyglot = usePolyglot();

  useEffect(() => {
    getRefundState(bid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  if (refundError) {
    return (
      <Card title={polyglot.t('mmb.booking_details.refund')}>
        <Text type="critical">
          {polyglot.t('mmb.booking_details.refund.failed_to_load_refund')}
        </Text>
      </Card>
    );
  }

  if (!refund) {
    return null;
  }

  const refundState = refund.refund_state;

  const timelineState = getTimelineState(refundState);
  const lastStepData = getLastStepData(refundState);

  return (
    <Card
      title={polyglot.t('mmb.booking_details.refund')}
      subtitle="Estimated refund time: 3 months"
    >
      <Timeline>
        <TimelineStep
          label={polyglot.t('mmb.booking_details.refund.requested')}
          type={timelineState[1] ? 'success' : undefined}
          dataTest="refund-requested"
        >
          {polyglot.t('mmb.booking_details.refund.recieved_request')}
        </TimelineStep>
        <TimelineStep
          label={polyglot.t('mmb.booking_details.refund.in_progress')}
          type={timelineState[2] ? 'success' : undefined}
          dataTest="refund-in-progress"
        >
          {polyglot.t('mmb.booking_details.refund.review_request')}
        </TimelineStep>
        <TimelineStep
          label={polyglot.t('mmb.booking_details.refund.waiting_for_carrier')}
          type={timelineState[3] ? 'success' : undefined}
          dataTest="refund-waiting"
        >
          {polyglot.t('mmb.booking_details.refund.evaluate_refund')}
        </TimelineStep>
        <TimelineStep
          label={polyglot.t('mmb.booking_details.refund.carrier_refunding')}
          type={timelineState[4] ? 'success' : undefined}
          dataTest="refund-carrier-refunding"
        >
          {polyglot.t('mmb.booking_details.refund.once_approved')}
        </TimelineStep>
        <TimelineStep
          label={polyglot.t(lastStepData.label)}
          type={lastStepData.type}
          dataTest="refund-last"
        >
          {polyglot.t(lastStepData.description)}
        </TimelineStep>
      </Timeline>
    </Card>
  );
};

export default React.memo(Refund);
