import React from 'react';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Text from '@kiwicom/orbit-components/lib/Text';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import { usePolyglot } from 'components/services/i18n';
import TripSummaryDetails from 'components/common/TripSummaryDetails';
import TripSummaryFlightDetails from 'components/common/TripSummaryFlightDetails';
import ItineraryTransferData from 'components/common/ItineraryTransferData';
import Card from 'components/scenes/Booking/common/Card';

const HalfWidth = styled.div`
  width: 50%;
`;

const ReturnTripSummary = ({ tripData }) => {
  const polyglot = usePolyglot();
  return (
    <Stack direction="row" spacing="large" justify="between">
      <HalfWidth>
        <TripSummaryDetails
          title={polyglot.t('booking.deposit_booking.trip_details.departure')}
          type={tripData.tripType}
          from={tripData.flights?.[0][0].departure}
          to={tripData.flights?.[0][tripData.flights[0].length - 1].arrival}
          fullTrip={tripData.flights}
          carriers={tripData.flights?.[0].map(s => s.operatingCarrier.code)}
          dataTest="trip-summary-departure"
          duration={differenceInMilliseconds(
            new Date(tripData.flights?.[0][tripData.flights?.[0].length - 1].arrival.time.utc),
            new Date(tripData.flights?.[0][0].departure.time.utc),
          )}
        />
        <Separator spaceAfter="large" />
        <Text size="small" type="primary" weight="bold" spaceAfter="normal">
          {polyglot.t('booking.deposit_booking.trip_details.departure')}
        </Text>
        {tripData.flights[0].map((segment, i) => (
          <div key={i} data-test={`trip-summary-wrapper-left-${i}`}>
            <TripSummaryFlightDetails
              vehicleType={segment.vehicleType}
              departurePlace={segment.departure.name}
              departureTime={segment.departure.time.local}
              arrivalPlace={segment.arrival.name}
              arrivalTime={segment.arrival.time.local}
              flightNumber={segment.flightNumber}
              duration={segment.duration}
              carrier={segment.operatingCarrier.code}
              carrierName={segment.operatingCarrier.name}
              fareCategory={segment.fare.category}
              previousSegment={tripData.flights[0]?.[i - 1]}
              dataTest={`trip-summary-details-departure-${i}`}
              scene="booking"
            />
            {i !== tripData.flights[0].length - 1 && (
              <ItineraryTransferData segment={segment} nextSegment={tripData.flights[0][i + 1]} />
            )}
          </div>
        ))}
      </HalfWidth>

      <HalfWidth>
        <TripSummaryDetails
          title={polyglot.t('booking.deposit_booking.trip_details.return')}
          type={tripData.tripType}
          from={tripData.flights[1][0].departure}
          to={tripData.flights[1][tripData.flights[1].length - 1].arrival}
          carriers={tripData.flights[1].map(s => s.operatingCarrier.code)}
          fullTrip={tripData.flights}
          dataTest="trip-summary-return"
          duration={differenceInMilliseconds(
            new Date(tripData.flights?.[1][tripData.flights?.[1].length - 1].arrival.time.utc),
            new Date(tripData.flights?.[1][0].departure.time.utc),
          )}
        />
        <>
          <Separator spaceAfter="large" />
          <Text size="small" type="primary" weight="bold" spaceAfter="normal">
            {polyglot.t('booking.deposit_booking.trip_details.return')}
          </Text>
          {tripData.flights[1].map((segment, i) => (
            <div key={i} data-test={`trip-summary-wrapper-right-${i}`}>
              <TripSummaryFlightDetails
                vehicleType={segment.vehicleType}
                departurePlace={segment.departure.name}
                departureTime={segment.departure.time.local}
                arrivalPlace={segment.arrival.name}
                arrivalTime={segment.arrival.time.local}
                flightNumber={segment.flightNumber}
                duration={segment.duration}
                carrier={segment.operatingCarrier.code}
                carrierName={segment.operatingCarrier.name}
                fareCategory={segment.fare.category}
                previousSegment={tripData.flights[1]?.[i - 1]}
                scene="booking"
                dataTest={`trip-summary-details-return-${i}`}
              />

              {i !== tripData.flights[1].length - 1 && (
                <ItineraryTransferData segment={segment} nextSegment={tripData.flights[1][i + 1]} />
              )}
            </div>
          ))}
        </>
      </HalfWidth>
    </Stack>
  );
};

const TripSummary = ({ tripData, loading }) => {
  const polyglot = usePolyglot();
  if (tripData.tripType === 'Return') {
    return (
      <Card
        title={polyglot.t('booking.deposit_booking.trip_details.trip_summary')}
        loading={loading}
      >
        <ReturnTripSummary tripData={tripData} />
      </Card>
    );
  }

  const flattenedTrip = tripData.flights.reduce((acc, t) => {
    t.map(tr => acc.push(tr));
    return acc;
  }, []);

  return (
    <Card title={polyglot.t('booking.deposit_booking.trip_details.trip_summary')} loading={loading}>
      <div>
        <TripSummaryDetails
          title={polyglot.t('booking.deposit_booking.trip_details.departure')}
          type={tripData.tripType}
          to={tripData.flights?.[0][tripData.flights[0].length - 1].arrival}
          from={tripData.flights?.[0][0].departure}
          carriers={tripData.flights?.[0].map(s => s.operatingCarrier.code)}
          duration={differenceInMilliseconds(
            new Date(tripData.flights?.[0][tripData.flights?.[0].length - 1].arrival.time.utc),
            new Date(tripData.flights?.[0][0].departure.time.utc),
          )}
          fullTrip={tripData.flights}
          dataTest="trip-summary-departure"
        />
        <HalfWidth>
          <Separator spaceAfter="large" />

          <Text size="small" type="primary" weight="bold" spaceAfter="normal">
            {polyglot.t('booking.deposit_booking.trip_details.departure')}
          </Text>

          {flattenedTrip.map((segment, i) => (
            <div key={i} data-test={`trip-summary-wrapper-${i}`}>
              <TripSummaryFlightDetails
                vehicleType={segment.vehicleType}
                departurePlace={segment.departure.name}
                departureTime={segment.departure.time.local}
                arrivalPlace={segment.arrival.name}
                arrivalTime={segment.arrival.time.local}
                flightNumber={segment.flightNumber}
                duration={segment.duration}
                carrier={segment.operatingCarrier.code}
                carrierName={segment.operatingCarrier.name}
                fareCategory={segment.fare.category}
                previousSegment={flattenedTrip?.[i - 1]}
                scene="booking"
                dataTest={`trip-summary-details-departure-${i}`}
              />

              {i !== flattenedTrip.length - 1 && (
                <ItineraryTransferData segment={segment} nextSegment={flattenedTrip[i + 1]} />
              )}
            </div>
          ))}
        </HalfWidth>
      </div>
    </Card>
  );
};

export default React.memo(TripSummary);
