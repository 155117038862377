import React, { useReducer } from 'react';

import api, { getAndPaginateAll, getUrlWithQuery } from 'utils/api';
import companiesMapper from 'mappers/nextGen/companies/_';

const defaultState = {
  searchedCompanies: {
    data: null,
    loading: false,
    error: null,
  },
};

export const CompaniesState = React.createContext({
  state: {},
  loadCompanyNames: () => [],
  loadCompanies: () => [],
  selectCompany: companyName => [companyName],
  searchCompanies: async searchQuery => {},
});

const companiesReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANIES':
      return {
        ...state,
        loading: true,
      };
    case 'LOAD_COMPANIES_SUCCESS':
      return {
        ...state,
        companies: action.data,
        error: null,
        loading: false,
      };
    case 'LOAD_COMPANIES_FAIL':
      return {
        ...state,
        companies: null,
        error: action.error,
        loading: false,
      };
    case 'LOAD_COMPANY_NAMES':
      return {
        ...state,
        loadingNames: true,
      };
    case 'LOAD_COMPANY_NAMES_SUCCESS':
      return {
        ...state,
        companyNames: action.data,
        errorNames: null,
        loadingNames: false,
      };
    case 'LOAD_COMPANY_NAMES_FAIL':
      return {
        ...state,
        companyNames: null,
        errorNames: action.error,
        loadingNames: false,
      };
    case 'SELECT_DEFAULT_COMPANY_NAME':
      return {
        ...state,
        selectedCompany: action.companyName,
      };
    case 'SEARCH_COMPANIES':
      return {
        ...state,
        searchedCompanies: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case 'SEARCH_COMPANIES_SUCCESS':
      return {
        ...state,
        searchedCompanies: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case 'SEARCH_COMPANIES_FAIL':
      return {
        ...state,
        searchedCompanies: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

const CompaniesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(companiesReducer, defaultState);

  const loadCompanies = async filter => {
    dispatch({ type: 'LOAD_COMPANIES' });

    try {
      const response = await getAndPaginateAll('v1', 'get', 'companies', 6000, {
        _fields: 'name,account_manager,created_at',
        ...(filter && filter),
      });

      const companies = response.map(companiesMapper.v1.get.from);

      return dispatch({
        type: 'LOAD_COMPANIES_SUCCESS',
        data: companies,
      });
    } catch (error) {
      return dispatch({ type: 'LOAD_COMPANIES_FAIL', error });
    }
  };

  const searchCompanies = async searchQuery => {
    dispatch({ type: 'SEARCH_COMPANIES' });
    try {
      const query = getUrlWithQuery('', {
        name: searchQuery,
      });

      const {
        data: { data },
      } = await api.v1.get(`/companies${query}`);

      dispatch({
        type: 'SEARCH_COMPANIES_SUCCESS',
        payload: data.map(companiesMapper.v1.get.from),
      });
    } catch (error) {
      dispatch({ type: 'SEARCH_COMPANIES_FAIL', error });
    }
  };

  const loadCompanyNames = async () => {
    dispatch({ type: 'LOAD_COMPANY_NAMES' });

    try {
      const response = await getAndPaginateAll('v1', 'get', 'companies', 6000, {
        _fields: 'name',
      });
      const companyNames = response.map(company => company.name);

      return dispatch({
        type: 'LOAD_COMPANY_NAMES_SUCCESS',
        data: companyNames,
      });
    } catch (error) {
      return dispatch({ type: 'LOAD_COMPANY_NAMES_FAIL', error });
    }
  };

  const selectCompany = companyName => {
    dispatch({ type: 'SELECT_DEFAULT_COMPANY_NAME', companyName });
  };

  const value = {
    state,
    loadCompanies,
    loadCompanyNames,
    selectCompany,
    searchCompanies,
  };

  return <CompaniesState.Provider value={value}>{children}</CompaniesState.Provider>;
};

export default CompaniesProvider;
