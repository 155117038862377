import { useReducer, createContext } from 'react';

import jiraCloudIssuesSearchMapper from 'mappers/nextGen/jira-cloud/issues/search/_';
import jiraCloudIssueMapper from 'mappers/nextGen/jira-cloud/issues/_';
import jiraCloudSingleIssueMapper from 'mappers/nextGen/jira-cloud/issues/{issueKey}/_';
import saveAs from 'file-saver';

import api from 'utils/api';
import { SERVICE_DESK } from 'consts/serviceDesks';

const GET_REQUEST_TYPES = 'GET_REQUEST_TYPES';
const GET_REQUEST_TYPES_SUCCESS = 'GET_REQUEST_TYPES_SUCCESS';
const GET_REQUEST_TYPES_FAIL = 'GET_REQUEST_TYPES_FAIL';
const GET_ISSUES = 'GET_ISSUES';
const GET_ISSUES_SUCCESS = 'GET_ISSUES_SUCCESS';
const GET_ISSUES_FAIL = 'GET_ISSUES_FAIL';
const GET_CUSTOM_FIELD_OPTIONS = 'GET_CUSTOM_FIELD_OPTIONS';
const GET_CUSTOM_FIELD_OPTIONS_SUCCESS = 'GET_CUSTOM_FIELD_OPTIONS_SUCCESS';
const GET_CUSTOM_FIELD_OPTIONS_FAIL = 'GET_CUSTOM_FIELD_OPTIONS_FAIL';
const CREATE_ISSUE = 'CREATE_ISSUE';
const CREATE_ISSUE_SUCCESS = 'CREATE_ISSUE_SUCCESS';
const CREATE_ISSUE_FAIL = 'CREATE_ISSUE_FAIL';
const GET_SINGLE_ATTACHMENT = 'GET_SINGLE_ATTACHMENT';
const GET_SINGLE_ATTACHMENT_SUCCESS = 'GET_SINGLE_ATTACHMENT_SUCCESS';
const GET_SINGLE_ATTACHMENT_FAIL = 'GET_SINGLE_ATTACHMENT_FAIL';
const GET_SINGLE_ISSUE = 'GET_SINGLE_ISSUE';
const GET_SINGLE_ISSUE_SUCCESS = 'GET_SINGLE_ISSUE_SUCCESS';
const GET_SINGLE_ISSUE_FAIL = 'GET_SINGLE_ISSUE_FAIL';
const CREATE_ISSUE_COMMENT = 'CREATE_ISSUE_COMMENT';
const CREATE_ISSUE_COMMENT_SUCCESS = 'CREATE_ISSUE_COMMENT_SUCCESS';
const CREATE_ISSUE_COMMENT_FAIL = 'CREATE_ISSUE_COMMENT_FAIL';
const CLEAR_ISSUE = 'CLEAR_ISSUE';
const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
const CREATE_ATTACHMENT_FAIL = 'CREATE_ISSUE_FAIL';
const CLEAR_DATA = 'CLEAR_DATA';
const CLEAR_ISSUE_COMMENT = 'CLEAR_ISSUE_COMMENT';
const CLEAR_ATTACHMENT = 'CLEAR_ATTACHMENT';

export const defaultPagination = {
  perPage: 50,
  totalCount: 0,
  page: 1,
};

const defaultState = {
  requestTypes: {
    [SERVICE_DESK.BPSD]: {
      data: null,
      loading: false,
      error: null,
    },
    [SERVICE_DESK.CSSD]: {
      data: null,
      loading: false,
      error: null,
    },
  },
  createIssue: {
    data: null,
    loading: false,
    error: null,
  },
  issue: {
    data: null,
    loading: false,
    error: null,
  },
  comment: {
    data: null,
    loading: false,
    error: null,
  },
  attachments: {
    data: null,
    loading: false,
    error: null,
  },
  attachment: {
    data: null,
    loading: false,
    error: null,
  },
  singleIssue: {
    data: null,
    loading: false,
    error: null,
  },
};

export const HelpState = createContext({
  state: {
    pagination: defaultPagination,
    createIssue: {
      loading: false,
      error: null,
    },
  },
  getRequestTypes: async serviceDesk => {},
  getIssues: async filters => {},
  getCustomFieldsOptions: async () => {},
  createIssue: async (issue, serviceDesk) => {},
  addAttachments: async (attachments, issueId) => {},
  getSingleAttachment: async (issueId, attachmentId, fileName) => {},
  getSingleIssue: async issueId => {},
  createIssueComment: async body => {},
  clearIssue: () => {},
  createAttachment: async (issueKey, attachment) => {},
  clearIssueComment: () => {},
  clearAttachment: () => {},
  clearData: () => {},
});

const helpReducer = (state, action) => {
  switch (action.type) {
    case GET_REQUEST_TYPES:
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          [action.payload.serviceDesk]: {
            data: null,
            loading: true,
            error: null,
          },
        },
      };
    case GET_REQUEST_TYPES_SUCCESS:
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          [action.payload.serviceDesk]: {
            data: action.payload.data,
            loading: false,
            error: null,
          },
        },
      };
    case GET_REQUEST_TYPES_FAIL:
      return {
        ...state,
        requestTypes: {
          ...state.requestTypes,
          [action.payload.serviceDesk]: {
            data: null,
            loading: false,
            error: action.error,
          },
        },
      };
    case GET_ISSUES:
      return {
        ...state,
        issues: action.getMore ? state.issues : null,
        issuesLoading: true,
        issuesError: null,
      };
    case GET_ISSUES_SUCCESS:
      return {
        ...state,
        issues: {
          data: action.getMore
            ? [...state.issues?.data, ...action.payload.data]
            : action.payload.data,
          pagination: action.payload.pagination,
        },
        issuesLoading: false,
        issuesError: null,
      };
    case GET_ISSUES_FAIL:
      return {
        ...state,
        issues: null,
        issuesLoading: false,
        issuesError: action.error,
      };
    case GET_CUSTOM_FIELD_OPTIONS:
      return {
        ...state,
        customFieldsOptions: null,
        requestTypeCustomField: null,
        customFieldsOptionsLoading: true,
        customFieldsOptionsError: null,
      };
    case GET_CUSTOM_FIELD_OPTIONS_SUCCESS:
      return {
        ...state,
        customFieldsOptions: action.payload.options,
        requestTypeCustomField: action.payload.requestTypeCustomField,
        customFieldsOptionsLoading: false,
        customFieldsOptionsError: null,
      };
    case GET_CUSTOM_FIELD_OPTIONS_FAIL:
      return {
        ...state,
        customFieldsOptions: null,
        requestTypeCustomField: null,
        customFieldsOptionsLoading: false,
        customFieldsOptionsError: action.error,
      };
    case CREATE_ISSUE:
      return {
        ...state,
        issue: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case CREATE_ISSUE_SUCCESS:
      return {
        ...state,
        issue: {
          data: action.payload.issue,
          loading: false,
          error: null,
        },
      };
    case CREATE_ISSUE_FAIL:
      return {
        ...state,
        issue: {
          data: null,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_ATTACHMENT:
      return {
        ...state,
        attachment: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case CREATE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachment: {
          data: action.payload.attachment,
          loading: false,
          error: null,
        },
      };
    case CREATE_ATTACHMENT_FAIL:
      return {
        ...state,
        attachment: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    case GET_SINGLE_ISSUE:
      return {
        ...state,
        singleIssue: { data: null, loading: true, error: null },
      };
    case GET_SINGLE_ISSUE_SUCCESS:
      return {
        ...state,
        singleIssue: {
          data: action.payload.issue,
          loading: false,
          error: null,
        },
      };

    case GET_SINGLE_ISSUE_FAIL:
      return {
        ...state,
        singleIssue: { data: null, loading: false, error: action.error },
      };

    case CREATE_ISSUE_COMMENT:
      return {
        ...state,
        comment: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case CREATE_ISSUE_COMMENT_SUCCESS:
      return {
        ...state,
        comment: {
          data: action.payload.comment,
          loading: false,
          error: null,
        },
      };
    case CREATE_ISSUE_COMMENT_FAIL:
      return {
        ...state,
        comment: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    case GET_SINGLE_ATTACHMENT:
      return {
        ...state,
        attachment: {
          data: null,
          loading: true,
          error: null,
        },
      };
    case GET_SINGLE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachment: {
          data: action.payload.attachment,
          loading: false,
          error: null,
        },
      };
    case GET_SINGLE_ATTACHMENT_FAIL:
      return {
        ...state,
        attachment: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    case CLEAR_ISSUE:
      return {
        ...state,
        singleIssue: defaultState.issue,
      };
    case CLEAR_ISSUE_COMMENT:
      return {
        ...state,
        comment: defaultState.comment,
      };
    case CLEAR_ATTACHMENT:
      return {
        ...state,
        attachment: defaultState.attachment,
      };
    case CLEAR_DATA:
      return defaultState;
    default:
      return defaultState;
  }
};

const HelpProvider = ({ children }) => {
  const [state, dispatch] = useReducer(helpReducer, defaultState);

  const getRequestTypes = async serviceDesk => {
    dispatch({ type: GET_REQUEST_TYPES, payload: { serviceDesk } });
    try {
      const { data } = await api.v1.get(`/jira-cloud/request-type/${serviceDesk}`);
      dispatch({
        type: GET_REQUEST_TYPES_SUCCESS,
        payload: {
          serviceDesk,
          data,
        },
      });
    } catch (error) {
      dispatch({ type: GET_REQUEST_TYPES_FAIL, payload: { serviceDesk }, error });
    }
  };

  const getIssues = async (filters, getMore = false) => {
    dispatch({ type: GET_ISSUES, getMore });
    try {
      const reqBody = jiraCloudIssuesSearchMapper.v1.post.to(filters);

      const { data } = await api.v1.post('/jira-cloud/issues/search', reqBody);

      dispatch({
        type: GET_ISSUES_SUCCESS,
        payload: jiraCloudIssuesSearchMapper.v1.post.from(data),
        getMore,
      });
    } catch (error) {
      dispatch({ type: GET_ISSUES_FAIL, error });
    }
  };

  const getCustomFieldsOptions = async () => {
    dispatch({ type: GET_CUSTOM_FIELD_OPTIONS });
    try {
      const { data: options } = await api.v1.get('/jira-cloud/customfield/options');
      const { data: requestTypeCustomField } = await api.v1.get(
        '/jira-cloud/request-type/CSSD/subcategories',
      );

      dispatch({
        type: GET_CUSTOM_FIELD_OPTIONS_SUCCESS,
        payload: { options, requestTypeCustomField },
      });
    } catch (error) {
      dispatch({ type: GET_CUSTOM_FIELD_OPTIONS_FAIL, error });
    }
  };

  const createIssue = async (issue, serviceDesk) => {
    dispatch({ type: CREATE_ISSUE });
    try {
      const { data } = await api.v1.post(
        '/jira-cloud/issues',
        jiraCloudIssueMapper.v1.post.to(issue, serviceDesk),
      );
      dispatch({ type: CREATE_ISSUE_SUCCESS, payload: { issue: data } });
      return data;
    } catch (error) {
      dispatch({ type: CREATE_ISSUE_FAIL, error });
    }
  };

  const getSingleAttachment = async (issueId, attachmentId, fileName) => {
    dispatch({ type: GET_SINGLE_ATTACHMENT });
    try {
      const { data } = await api.v1.get(
        `/jira-cloud/issues/${issueId}/attachments/${attachmentId}`,
        {
          responseType: 'blob',
        },
      );
      saveAs(data, fileName);
      dispatch({
        type: GET_SINGLE_ATTACHMENT_SUCCESS,
        payload: { attachment: data },
      });
    } catch (error) {
      dispatch({ type: GET_SINGLE_ATTACHMENT_FAIL, error });
    }
  };

  const getSingleIssue = async issueId => {
    dispatch({ type: GET_SINGLE_ISSUE });
    try {
      const { data } = await api.v1.get(`/jira-cloud/issues/${issueId}`);
      dispatch({
        type: GET_SINGLE_ISSUE_SUCCESS,
        payload: { issue: jiraCloudSingleIssueMapper.v1.get.from(data.issue_key, data.fields) },
      });
    } catch (error) {
      dispatch({ type: GET_SINGLE_ISSUE_FAIL, error });
    }
  };

  const createIssueComment = async (issueId, body) => {
    dispatch({ type: CREATE_ISSUE_COMMENT });
    try {
      const { data } = await api.v1.post(`/jira-cloud/issues/${issueId}/comments`, { body });
      dispatch({ type: CREATE_ISSUE_COMMENT_SUCCESS, payload: { comment: data } });
    } catch (error) {
      dispatch({ type: CREATE_ISSUE_COMMENT_FAIL, error });
    }
  };

  const clearIssue = () => {
    dispatch({ type: CLEAR_ISSUE });
  };

  const clearIssueComment = () => {
    dispatch({ type: CLEAR_ISSUE_COMMENT });
  };

  const clearAttachment = () => {
    dispatch({ type: CLEAR_ATTACHMENT });
  };

  const createAttachment = async (issueKey, attachment) => {
    dispatch({ type: CREATE_ATTACHMENT });

    const form = new FormData();
    form.append('attachment', attachment, attachment.name);
    form.append('filename', attachment.name);

    try {
      const { data } = await api.v1.post(`/jira-cloud/issues/${issueKey}/attachments`, form, {
        headers: { 'Content-Type': 'mulipart/form-data' },
      });
      dispatch({ type: CREATE_ATTACHMENT_SUCCESS, payload: { attachments: data } });
    } catch (error) {
      dispatch({ type: CREATE_ATTACHMENT_FAIL, error });
    }
  };

  const clearData = () => {
    dispatch({ type: CLEAR_DATA });
  };

  const value = {
    ...state,
    getRequestTypes,
    getIssues,
    getCustomFieldsOptions,
    createIssue,
    getSingleAttachment,
    getSingleIssue,
    createIssueComment,
    clearIssue,
    clearIssueComment,
    createAttachment,
    clearData,
    clearAttachment,
  };

  return <HelpState.Provider value={value}>{children}</HelpState.Provider>;
};

export default HelpProvider;
