import styled from 'styled-components';

export const ActionsFlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: 14px;
  }
`;

export const Container = styled.div`
  width: 880px;
  height: 400px;
`;
