import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import { FixedSizeList } from 'react-window';

import {
  CheckboxWrapper,
  DropdownIconContainer,
  SelectAllContainer,
  Wrapper,
  SelectOnlyWrapper,
  ChoiceWrapper,
} from './CheckboxFilter.styled';

const MenuList = ({ options, children, height }) => {
  return (
    <FixedSizeList height={height} itemCount={options.length} itemData={options} itemSize={45}>
      {children}
    </FixedSizeList>
  );
};

const CheckboxFilter = ({
  options,
  value,
  onSelect,
  inputSize,
  prefix,
  width,
  topLabel,
  height,
}) => {
  const [selectedValues, setSelectedValues] = useState(options);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (value) {
      setSelectedValues(value);
    }
  }, [value]);

  const isSelected = (array, option) => {
    return array.reduce((acc, item) => acc || item.label === option.label, false);
  };

  const handleSelect = (item, e) => {
    e.preventDefault();
    const isItemSelected = isSelected(selectedValues, item);

    if (isItemSelected && selectedValues.length === 1) {
      return;
    }

    if (!isItemSelected) {
      const filteredValues = [...selectedValues, item].sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });
      setSelectedValues(filteredValues);
      onSelect(filteredValues);
    } else {
      const filteredValues = selectedValues.filter(val => val.label !== item.label);

      setSelectedValues(filteredValues);
      onSelect(filteredValues);
    }
  };

  const handleSelectAll = e => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedValues(options);
    onSelect(options);
  };

  const handleSelectOnly = option => {
    setSelectedValues([option]);
    onSelect([option]);
  };

  const CheckboxListChoice = ({ option }) => (
    <ChoiceWrapper>
      <ListChoice
        key={option.value}
        title={option.label}
        selectable
        selected={isSelected(selectedValues, option)}
        onClick={e => handleSelect(option, e)}
      />
      <SelectOnlyWrapper>
        <TextLink
          size="small"
          type="secondary"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSelectOnly(option);
          }}
        >
          Only
        </TextLink>
      </SelectOnlyWrapper>
    </ChoiceWrapper>
  );

  const renderValue = () => {
    const allSelected = selectedValues.length === options.length;

    if (allSelected) {
      return 'All';
    }

    return selectedValues.length > 0 ? selectedValues.map(option => option.label) : '';
  };

  const handleQueryInput = e => {
    setSearchQuery(e.target.value);
  };

  const Row = ({ data, index, style }) => {
    return (
      <div style={style}>
        <CheckboxListChoice option={data[index]} />
      </div>
    );
  };

  const label = topLabel ? { label: prefix } : { prefix };

  const menuOptions = useMemo(() => {
    if (searchQuery === '') {
      return options;
    }

    return options.filter(o => o.label.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [searchQuery, options]);

  return (
    <Popover
      noPadding
      width={width}
      content={
        <div>
          <Wrapper>
            <SelectAllContainer onClick={handleSelectAll}>
              <TextLink>Select all</TextLink>
            </SelectAllContainer>
            <InputField
              size="small"
              type="text"
              prefix={<SearchIcon />}
              onChange={handleQueryInput}
            />
          </Wrapper>
          <MenuList options={menuOptions} height={height}>
            {Row}
          </MenuList>
        </div>
      }
    >
      <CheckboxWrapper>
        <InputField
          {...label}
          size={inputSize}
          readOnly
          value={renderValue() || ''}
          suffix={
            <DropdownIconContainer>
              <ChevronDownIcon />
            </DropdownIconContainer>
          }
        />
      </CheckboxWrapper>
    </Popover>
  );
};

CheckboxFilter.defaultProps = {
  inputSize: 'small',
  width: '240px',
  height: 300,
  onSelect: () => {},
};

CheckboxFilter.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  inputSize: PropTypes.oneOf(['small', 'normal']),
  prefix: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.number,
};

export default CheckboxFilter;
