import useCompanySolutions from 'components/services/solutions/useCompanySolutions';

const useCompanyWidgetSolutions = selectedCompany => {
  const companySolutionsData = useCompanySolutions(selectedCompany);

  const companySolutionsLoading = companySolutionsData.loading;
  const companySolutionsLoadingError = !!companySolutionsData.error;
  const companySolutions = companySolutionsData.data || [];
  const companyWidgetSolutions =
    companySolutions &&
    companySolutions.filter(
      solution => solution.productType === 'Widget' && solution.status === 'approved',
    );
  const companyHasWidgetSolutions = companyWidgetSolutions && companyWidgetSolutions.length !== 0;

  return {
    companySolutionsLoading,
    companySolutionsLoadingError,
    companyWidgetSolutions,
    companyHasWidgetSolutions,
  };
};

export default useCompanyWidgetSolutions;
