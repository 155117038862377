import React, { useState, useContext } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import ThumbUp from '@kiwicom/orbit-components/lib/icons/ThumbUp';

import { Flex, Space, CardData } from 'common';
import { formatInterval } from 'utils/dates';
import { FULL_DATE } from 'consts/dates';
import { usePolyglot } from 'components/services/i18n';
import { PartnerPaymentsState } from 'components/services/partnerPayments';

const PartnerApprovalForm = ({ onClose }) => {
  const [error, setError] = useState('');
  const {
    state: { partnerPayment },
    loadPartnerPayment,
    approvePartnerPayment,
  } = useContext(PartnerPaymentsState);
  const polyglot = usePolyglot();

  const handleSubmit = () => {
    approvePartnerPayment(partnerPayment.data.id)
      .then(() => {
        loadPartnerPayment(partnerPayment.data.id);
        onClose();
      })
      .catch(err => {
        setError(polyglot.t('partner_payments.error_approve'));
        onClose();
      });
  };

  const getApprovalInformation = () => {
    return [
      {
        label: polyglot.t('partner_payments.company'),
        value: partnerPayment.data.company,
      },
      {
        label: polyglot.t('partner_payments.sender'),
        value: partnerPayment.data.debtorName,
      },
      {
        label: polyglot.t('partner_payments.date'),
        value: formatInterval(partnerPayment.data.date, FULL_DATE),
      },
      {
        label: polyglot.t('partner_payments.amount'),
        value: `${partnerPayment.data.currency} ${partnerPayment.data.amount}`,
      },
      {
        label: polyglot.t('partner_payments.iban'),
        value: partnerPayment.data.debtorAccount,
      },
      {
        label: polyglot.t('partner_payments.deposit_account'),
        value: partnerPayment.data.depositAccount,
      },
    ];
  };

  return (
    <>
      <Heading type="title1">{polyglot.t('partner_payments.approve_identity')}</Heading>
      <Space before="m" after="xxl">
        <Text size="large">{polyglot.t('partner_payments.cannot_undo')}</Text>
      </Space>
      {error && (
        <Alert type="critical" spaceAfter="medium">
          {error}
        </Alert>
      )}
      <CardData fullWidthRow={getApprovalInformation()} />
      <Flex main="end">
        <Space right="l">
          <Button type="secondary" onClick={onClose} width="120px" size="large">
            {polyglot.t('common.cancel')}
          </Button>
        </Space>
        <Button
          onClick={handleSubmit}
          width="140px"
          size="large"
          iconRight={<ThumbUp />}
          loading={partnerPayment.approving}
        >
          {polyglot.t('common.approve')}
        </Button>
      </Flex>
    </>
  );
};

export default PartnerApprovalForm;
