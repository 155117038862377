import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { OrbitLoader, Flex, Space } from 'common';

import { BidViewerState } from 'components/services/bidViewer';
import { usePolyglot } from 'components/services/i18n';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';

import TabNavigation from './TabNavigation';

const BidViewerSchema = Yup.object().shape({
  bid: Yup.string().required('validation.cant_be_empty'),
});

const Container = styled.div`
  margin: -24px -24px 0 -24px;
  padding-bottom: 32px;
`;

const BidViewerContainer = styled.div`
  max-width: 714px;
  margin-top: 24px;
  margin-left: 24px;
`;

const BidViewer = ({
  match: {
    params: { companyName },
  },
}) => {
  const { state: bidViewer, loadBidViewer } = useContext(BidViewerState);
  const specialApps = useSpecialApps(companyName);
  const polyglot = usePolyglot();

  const showPassword = specialApps.apps?.find(app => app.name === 'bidviewer')?.config
    ?.includePassword;
  const renderTable = () => {
    if (bidViewer.loading) {
      return (
        <Flex main="center">
          <OrbitLoader id="solutions-loader" visible />
        </Flex>
      );
    }

    if (bidViewer?.data?.length) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{polyglot.t('bidviewer.bid')}</TableCell>
              <TableCell align="left">{polyglot.t('bidviewer.email')}</TableCell>
              <TableCell align="left">{polyglot.t('bidviewer.name')}</TableCell>
              <TableCell align="left">{polyglot.t('bidviewer.surname')}</TableCell>
              <TableCell align="left">{polyglot.t('bidviewer.pnr')}</TableCell>
              {showPassword && (
                <>
                  <TableCell align="left">Login</TableCell>
                  <TableCell align="left">Password</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {bidViewer.data.map((bid, key) => {
              return (
                <TableRow key={key}>
                  <TableCell align="left">{bid.bid}</TableCell>
                  <TableCell align="left">{bid.email}</TableCell>
                  <TableCell align="left">{bid.name}</TableCell>
                  <TableCell align="left">{bid.surname}</TableCell>
                  <TableCell align="left">{bid.pnr}</TableCell>
                  {showPassword && (
                    <>
                      <TableCell align="left">{bid.login}</TableCell>
                      <TableCell align="left">{bid.password}</TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }

    if (bidViewer?.data?.length === 0) {
      return (
        <>
          <Illustration name="Offline" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('bidviewer.no_data')}
          </Text>
        </>
      );
    }

    if (bidViewer.error) {
      return (
        <>
          <Illustration name="Error" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('bidviewer.error')}
          </Text>
        </>
      );
    }
  };

  return (
    <Container>
      <TabNavigation companyName={companyName} />
      <BidViewerContainer>
        <Formik
          initialValues={{
            bid: '',
          }}
          validationSchema={BidViewerSchema}
          onSubmit={values => loadBidViewer(values.bid, companyName)}
        >
          {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <Flex cross="end">
              <InputField
                id="bidviewer-bid"
                label={polyglot.t('bidviewer.bid')}
                placeholder={polyglot.t('bidviewer.bid_placeholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={bidViewer.loading}
                error={touched.bid && errors.bid && polyglot.t(errors.bid)}
                name="bid"
              />
              <Space left="l">
                <Button width="128px" onClick={handleSubmit} loading={bidViewer.loading}>
                  {polyglot.t('bidviewer.search')}
                </Button>
              </Space>
            </Flex>
          )}
        </Formik>
        <Space before="xxl">{renderTable()}</Space>
      </BidViewerContainer>
    </Container>
  );
};

export default BidViewer;
