import React, { useReducer } from 'react';

import api, { getAndPaginateAll } from 'utils/api';
import usersMapper from 'mappers/nextGen/users/_';
import userMapper from 'mappers/nextGen/users/{user_identifier}/_';

export const UsersState = React.createContext({
  state: {
    users: [],
    loading: false,
    error: null,
  },
  loadUsers: async () => {},
  updateUser: async () => {},
});

const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_USERS':
      return {
        ...state,
        loading: true,
        loadingError: null,
      };
    case 'LOAD_USERS_SUCCESS':
      return {
        ...state,
        users: action.users,
        loading: false,
      };
    case 'LOAD_USERS_FAIL':
      return {
        ...state,
        loading: false,
        loadingError: action.error,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        updatingUser: true,
        updateUserError: null,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        updatingUser: false,
        users: state.users.map(u => (u.email === action.user.email ? action.user : u)),
      };
    case 'UPDATE_USER_FAIL':
      return {
        ...state,
        updatingUser: false,
        updateUserError: action.error,
      };
    default:
      return state;
  }
};

const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {});

  const loadUsers = async () => {
    dispatch({ type: 'LOAD_USERS' });

    try {
      const data = await getAndPaginateAll('v1', 'get', 'users', 5000);
      const users = data.map(usersMapper.v1.get.from);

      dispatch({ type: 'LOAD_USERS_SUCCESS', users });
    } catch (error) {
      dispatch({ type: 'LOAD_USERS_FAIL', error });
      throw error;
    }
  };

  const updateUser = async user => {
    dispatch({ type: 'UPDATE_USER' });

    try {
      const response = await api.v1.put(`users/${user.email}`, userMapper.v1.put.to(user));
      const editedUser = userMapper.v1.put.from(response.data);

      if (editedUser.role !== user.role) {
        await api.v1.post(`acl/users/${editedUser.email}/roles`, {
          role: user.role,
          replace: editedUser.role,
        });
        editedUser.role = user.role;
      }

      dispatch({ type: 'UPDATE_USER_SUCCESS', user: editedUser });
    } catch (error) {
      dispatch({ type: 'UPDATE_USER_FAIL', error });
      throw error;
    }
  };

  const value = {
    ...state,
    loadUsers,
    updateUser,
  };

  return <UsersState.Provider value={value}>{children}</UsersState.Provider>;
};
export default UsersProvider;
