import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import AirplaneIcon from '@kiwicom/orbit-components/lib/icons/Airplane';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';

import Itinerary, {
  ItinerarySegment,
  ItineraryBadgeList,
  ItinerarySegmentStop,
  ItinerarySegmentDetail,
} from '@kiwicom/orbit-components/lib/Itinerary';
import Text from '@kiwicom/orbit-components/lib/Text';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import BadgeListItem from '@kiwicom/orbit-components/lib/BadgeList/BadgeListItem';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import useCarriers from 'components/services/bookingTool/useCarriers';

import {
  defaultDateFormat,
  localTimeFormat,
  duration,
  convertUTCDateToLocalDate,
} from 'utils/search';

const TripSectorWrapper = styled.div`
  width: 380px;
`;

const TruncatedString = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WarningDepartureCity = ({ previousFlight, cityFrom, flyFrom }) => {
  const polyglot = usePolyglot();

  return (
    <Stack inline spacing="XXSmall">
      <Text>{cityFrom} </Text>
      <Text type="critical">
        <Tooltip
          content={polyglot.t('searchAndBooking.different_place_departure_warning', {
            flyTo: previousFlight.flyTo,
            flyFrom,
          })}
        >
          {flyFrom}
        </Tooltip>
      </Text>
    </Stack>
  );
};

const RouteGroup = ({ routeGroup }) => {
  const polyglot = usePolyglot();
  const { data: carriers } = useCarriers();
  return (
    <TripSectorWrapper>
      <Itinerary>
        {routeGroup.map((flight, i) => {
          const {
            local_departure,
            cityFrom,
            flyFrom,
            flyTo,
            cityTo,
            utc_arrival,
            utc_departure,
            local_arrival,
            airline,
            flight_no,
            vehicle_type,
          } = flight;
          const nextFlight = routeGroup[i + 1];
          const previousFlight = routeGroup[i - 1];

          const isPreviousArrivalAirportDifferent =
            previousFlight && previousFlight.flyTo !== flyFrom;
          const departureCity = isPreviousArrivalAirportDifferent ? (
            <WarningDepartureCity
              previousFlight={previousFlight}
              cityFrom={cityFrom}
              flyFrom={flyFrom}
            />
          ) : (
            cityFrom
          );
          const tripNumberString =
            vehicle_type === 'aircraft'
              ? 'searchAndBooking.flight_number'
              : 'searchAndBooking.ride_number';

          return (
            <div key={flight.id}>
              <ItinerarySegment spaceAfter="medium">
                <ItinerarySegmentStop
                  city={departureCity}
                  status={isPreviousArrivalAirportDifferent && 'warning'}
                  date={defaultDateFormat(convertUTCDateToLocalDate(new Date(local_departure)))}
                  station={flyFrom}
                  minWidth={70}
                  time={localTimeFormat(local_departure)}
                />
                <ItinerarySegmentDetail
                  duration={duration(utc_departure, utc_arrival)}
                  summary={<CarrierLogo carriers={[{ code: airline, name: carriers[airline] }]} />}
                  content={[
                    {
                      items: [
                        {
                          icon: <AirplaneIcon size="small" />,
                          name: polyglot.t('searchAndBooking.carrier'),
                          value: (
                            <Tooltip content={carriers[airline]} placement="right" block>
                              <TruncatedString>{carriers[airline]}</TruncatedString>
                            </Tooltip>
                          ),
                        },
                        {
                          icon: <InformationCircleIcon size="small" />,
                          name: polyglot.t(tripNumberString),
                          value: flight_no,
                        },
                      ],
                    },
                  ]}
                />
                <ItinerarySegmentStop
                  city={cityTo}
                  date={defaultDateFormat(convertUTCDateToLocalDate(new Date(local_arrival)))}
                  station={flyTo}
                  time={localTimeFormat(local_arrival)}
                  minWidth={70}
                />
              </ItinerarySegment>
              {nextFlight && (
                <ItineraryBadgeList>
                  <BadgeListItem icon={<ClockIcon />} dataTest={`route-group-layover-${i}`}>
                    {duration(utc_arrival, nextFlight.utc_departure)} layover
                  </BadgeListItem>
                  {isPreviousArrivalAirportDifferent && (
                    <BadgeListItem icon={<FlightReturnIcon />}>
                      {polyglot.t('searchAndBooking.changing_airports')}
                    </BadgeListItem>
                  )}
                </ItineraryBadgeList>
              )}
            </div>
          );
        })}
      </Itinerary>
    </TripSectorWrapper>
  );
};

RouteGroup.propTypes = {
  routeGroup: PropTypes.array,
};

export default RouteGroup;
