import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import Text from '@kiwicom/orbit-components/lib/Text';

import BaggagePicker from 'components/scenes/ManageMyBookings/scenes/BookingDetails/scenes/DepositBookingDetails/AddBaggageModal/BaggagePicker';
import { usePolyglot } from 'components/services/i18n';

const BaggageForm = ({ index, baggage }) => {
  const { watch, control, errors, trigger } = useFormContext();
  const passengers = watch('passengers');

  const polyglot = usePolyglot();

  return (
    <>
      <Text size="large" type="secondary" spaceAfter="large">
        Baggage
      </Text>
      <Controller
        name={`passengers.${index}.baggage.handBag`}
        control={control}
        render={({ onChange, value }) => {
          return (
            <>
              <BaggagePicker
                titleSize="small"
                passengerCategory={passengers[index]?.category}
                pickerType="handBag"
                changeBagCombination={(e, bagIndex) => {
                  onChange(bagIndex);
                  trigger(`passengers.${index}.baggage.handBag`);
                }}
                baggage={{
                  definitions: baggage.definitions,
                  combinations: baggage.combinations,
                }}
                passengerBaggage={{
                  handBag: value,
                  holdBag: value,
                }}
                error={polyglot.t(errors?.passengers?.[index]?.baggage?.handBag?.message)}
                convertPrice
              />
            </>
          );
        }}
      />
      <Controller
        name={`passengers.${index}.baggage.holdBag`}
        control={control}
        render={({ onChange, value }) => {
          return (
            <>
              <BaggagePicker
                titleSize="small"
                passengerCategory={passengers[index]?.category}
                pickerType="holdBag"
                changeBagCombination={(e, bagIndex) => {
                  onChange(bagIndex);
                  trigger(`passengers.${index}.baggage.holdBag`);
                }}
                baggage={{
                  definitions: baggage.definitions,
                  combinations: baggage.combinations,
                }}
                passengerBaggage={{
                  handBag: value,
                  holdBag: value,
                }}
                error={polyglot.t(errors?.passengers?.[index]?.baggage?.holdBag?.message)}
                convertPrice
              />
            </>
          );
        }}
      />
    </>
  );
};

export default BaggageForm;
