import { createSelector } from 'reselect';
import Polyglot from 'node-polyglot';

// use this to prevent warnings when polyglot is not loaded
export const defaultPolyglot = { t: s => s };

const getLocale = state => state.i18n.locale;
const getPhrases = state => state.i18n.phrases;

/**
 * Returns a new Polyglot instance when locale changes.
 * Will return a fake instance if no phrases or locale is provided.
 */
export const getPolyglot = createSelector(getLocale, getPhrases, (locale, phrases) => {
  if (!locale || !phrases) {
    return defaultPolyglot;
  }

  const polyglot = new Polyglot({ locale, phrases });
  return polyglot;
});
