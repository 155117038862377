import React, { useReducer, createContext } from 'react';
import saveAs from 'file-saver';

import billingJobsMapper from 'mappers/nextGen/billing/jobs/_';
import billingExclusionsMapper from 'mappers/nextGen/billing/exclusions/_';
import api, { getAndPaginateAll } from 'utils/api';
import { BILLING_STATUSES } from 'consts/billing';

const defaultPagination = {
  perPage: 100,
  totalCount: 0,
  page: 1,
};

const defaultState = {
  pagination: defaultPagination,
  statusFilters: BILLING_STATUSES.map(item => item.value),
  statusFiltersModal: BILLING_STATUSES.map(item => item.value),
  dateFilter: { beginDate: null, endDate: null },
};

export const BillingState = createContext({
  state: {
    pagination: defaultPagination,
  },
  loadBillings: async (pagination, filter) => [pagination, filter],
  loadExclusions: async () => [],
  approveBilling: id => [id],
  downloadBilling: (billing, type) => [billing, type],
  retryBilling: id => [id],
  deleteBilling: id => [id],
  createExclusion: companyName => [companyName],
  deleteExclusion: id => [id],
  clearError: () => [],
  setDateFilter: value => [],
  setStatusFilter: value => [],
  loadExportBillings: async filter => [],
});

const billingReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_BILLINGS':
      return { ...state, loading: true, data: [] };
    case 'LOAD_BILLINGS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data,
        pagination: { ...state.pagination, ...action.pagination },
      };
    case 'LOAD_BILLINGS_FAIL':
      return { loading: false, error: action.error };
    case 'APPROVE_BILLING':
      return { ...state, approving: true };
    case 'APPROVE_BILLING_SUCCESS':
      const updatedBilling = state.data.find(b => b.id === action.id);
      updatedBilling.status = 'approved';
      return {
        ...state,
        approving: false,
        data: [...state.data],
      };
    case 'APPROVE_BILLING_FAIL':
      return { ...state, approving: false, error: action.error };
    case 'RETRY_BILLING':
      return { ...state, retrying: true };
    case 'RETRY_BILLING_SUCCESS':
      const retriedBilling = state.data.find(b => b.id === action.id);
      retriedBilling.status = 'processing';
      return {
        ...state,
        retrying: false,
        data: [...state.data],
      };
    case 'RETRY_BILLING_FAIL':
      return { ...state, retrying: false, error: action.error };
    case 'DELETE_BILLING':
      return { ...state, deleting: true };
    case 'DELETE_BILLING_SUCCESS':
      const deletedBilling = state.data.find(b => b.id === action.id);
      deletedBilling.status = 'deleted';
      return {
        ...state,
        deleting: false,
        data: [...state.data],
      };
    case 'DELETE_BILLING_FAIL':
      return { ...state, deleting: false, error: action.error };
    case 'DOWNLOADING_BILLING':
      return { ...state, downloading: { id: action.id, type: action.fileType } };
    case 'DOWNLOADING_BILLING_SUCCESS':
      return {
        ...state,
        downloading: false,
      };
    case 'DOWNLOADING_BILLING_FAIL':
      return { ...state, downloading: false, error: action.error };
    case 'LOAD_EXCLUSIONS':
      return { loading: true, exclusions: [] };
    case 'LOAD_EXCLUSIONS_SUCCESS':
      return {
        loading: false,
        exclusions: [...state.exclusions, ...action.data],
      };
    case 'LOAD_EXCLUSIONS_FAIL':
      return { loading: false, error: action.error };
    case 'CREATE_EXCLUSION':
      return { ...state, creating: true };
    case 'CREATE_EXCLUSION_SUCCESS':
      return {
        ...state,
        creating: false,
      };
    case 'CREATE_EXCLUSION_FAIL':
      return { ...state, creating: false, error: action.error };
    case 'DELETE_EXCLUSION':
      return { ...state, deleting: action.id };
    case 'DELETE_EXCLUSION_SUCCESS':
      return {
        ...state,
        deleting: false,
        exclusions: state.exclusions.filter(ex => ex.id !== action.id),
      };
    case 'DELETE_EXCLUSION_FAIL':
      return { ...state, deleting: false, error: action.error };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_DATE_FILTER':
      return { ...state, dateFilter: action.payload };
    case 'SET_FILTER_STATUS':
      return {
        ...state,
        statusFilters: state.statusFilters.includes(action.payload)
          ? state.statusFilters.filter(el => el !== action.payload)
          : [...state.statusFilters, action.payload],
      };
    case 'LOAD_EXPORT_BILLINGS':
      return { ...state, exportLoading: true, exportData: [] };
    case 'LOAD_EXPORT_BILLINGS_SUCCESS':
      return {
        ...state,
        exportLoading: false,
        exportData: action.exportData,
      };
    case 'LOAD_EXPORT_BILLINGS_FAIL':
      return { ...state, exportLoading: false, exportError: action.error };
    default:
      return state;
  }
};

const BillingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(billingReducer, defaultState);

  const loadBillings = async (pagination = defaultPagination, filters, sort) => {
    dispatch({ type: 'LOAD_BILLINGS' });
    try {
      const res = await api.v1.get('billing/jobs', {
        params: billingJobsMapper.v1.get.to(filters, pagination, sort),
      });

      const billings = billingJobsMapper.v1.get.from(res.data.data, res.data.pagination);
      return dispatch({
        type: 'LOAD_BILLINGS_SUCCESS',
        data: billings.data,
        pagination: { ...billings.pagination, page: pagination.page },
      });
    } catch (err) {
      dispatch({ type: 'LOAD_BILLINGS_FAIL', error: err });
    }
  };

  const loadExclusions = async () => {
    dispatch({ type: 'LOAD_EXCLUSIONS' });
    try {
      const response = await getAndPaginateAll('v1', 'get', 'billing/exclusions', 500, {
        _order: '-created_at',
      });
      const exclusions = response.map(billingExclusionsMapper.v1.get.from);
      dispatch({ type: 'LOAD_EXCLUSIONS_SUCCESS', data: exclusions });
    } catch (error) {
      dispatch({ type: 'LOAD_EXCLUSIONS_FAIL', error });
    }
  };

  const approveBilling = async id => {
    dispatch({ type: 'APPROVE_BILLING' });
    try {
      await api.v1.post(`/billing/jobs/${id}/approve`);
      dispatch({ type: 'APPROVE_BILLING_SUCCESS', id });
    } catch (err) {
      dispatch({ type: 'APPROVE_BILLING_FAIL', error: err });
      return Promise.reject(err);
    }
  };

  const retryBilling = async id => {
    dispatch({ type: 'RETRY_BILLING' });
    try {
      await api.v1.post(`/billing/jobs/${id}/recalculate`);
      dispatch({ type: 'RETRY_BILLING_SUCCESS', id });
    } catch (err) {
      dispatch({ type: 'RETRY_BILLING_FAIL', error: err });
    }
  };

  const deleteBilling = async id => {
    dispatch({ type: 'DELETE_BILLING' });
    try {
      await api.v1.delete(`/billing/jobs/${id}`);
      dispatch({ type: 'DELETE_BILLING_SUCCESS', id });
    } catch (err) {
      dispatch({ type: 'DELETE_BILLING_FAIL', error: err });
    }
  };

  const downloadBilling = async (billing, type) => {
    if (type !== 'pdf' && type !== 'csv') {
      return null;
    }

    dispatch({ type: 'DOWNLOADING_BILLING', fileType: type, id: billing.id });
    try {
      const prefix =
        type === 'pdf' ? 'data:application/pdf;base64,' : 'data:application/octet-stream;base64,';

      const { data } = await api.v1.get(`/billing/jobs/${billing.id}`);
      const res = await fetch(`${prefix}${data[type]}`);
      const blob = await res.blob();

      saveAs(
        blob,
        `${billing.companyName}_${billing.periodStart}_${billing.periodEnd}_billing.${type}`,
      );
      dispatch({ type: 'DOWNLOADING_BILLING_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'DOWNLOADING_BILLING_FAIL' });
    }
  };

  const createExclusion = async companyName => {
    dispatch({ type: 'CREATE_EXCLUSION' });
    try {
      const response = await api.v1.post(`/billing/exclusions`, { company_name: companyName });
      dispatch({
        type: 'CREATE_EXCLUSION_SUCCESS',
        data: billingExclusionsMapper.v1.post.from(response.data),
      });
    } catch (err) {
      dispatch({ type: 'CREATE_EXCLUSION_FAIL', error: err });
      return Promise.reject(err);
    }
  };

  const deleteExclusion = async id => {
    dispatch({ type: 'DELETE_EXCLUSION', id });
    try {
      await api.v1.delete(`/billing/exclusions/${id}`);
      dispatch({ type: 'DELETE_EXCLUSION_SUCCESS', id });
    } catch (err) {
      dispatch({ type: 'DELETE_EXCLUSION_FAIL', error: err });
    }
  };

  const setStatusFilter = value => {
    dispatch({ type: 'SET_FILTER_STATUS', payload: value });
  };

  const setDateFilter = newDates => {
    const dateFilter = {
      beginDate: !!newDates.beginDate && new Date(newDates.beginDate),
      endDate: !!newDates.endDate && new Date(newDates.endDate),
    };
    dispatch({ type: 'SET_DATE_FILTER', payload: dateFilter });
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  const loadExportBillings = async filters => {
    dispatch({ type: 'LOAD_EXPORT_BILLINGS' });
    let hasNext = true;

    const pagination = {
      perPage: 1000,
      totalCount: 0,
      page: 1,
    };

    let exportData = [];

    try {
      while (hasNext) {
        const res = await api.v1.get('billing/jobs', {
          params: billingJobsMapper.v1.get.to(filters, pagination),
        });

        const billings = billingJobsMapper.v1.get.from(res.data.data, res.data.pagination);

        pagination.page += 1;
        exportData = [...exportData, ...billings.data];

        if (!billings.pagination.hasNext) {
          hasNext = false;
        }
      }

      dispatch({
        type: 'LOAD_EXPORT_BILLINGS_SUCCESS',
        exportData,
      });

      return exportData;
    } catch (err) {
      dispatch({ type: 'LOAD_EXPORT_BILLINGS_FAIL', error: err });
    }
  };

  const value = {
    state,
    loadBillings,
    approveBilling,
    retryBilling,
    deleteBilling,
    downloadBilling,
    loadExclusions,
    createExclusion,
    deleteExclusion,
    clearError,
    setDateFilter,
    setStatusFilter,
    loadExportBillings,
  };

  return <BillingState.Provider value={value}>{children}</BillingState.Provider>;
};

export default BillingProvider;
