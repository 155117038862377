import React from 'react';
import { retry } from 'utils/functions';

const DestinationDemandDashboard = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "DestinationDemandDashboard" */ './DestinationDemandDashboard'),
  ),
);

export default DestinationDemandDashboard;
