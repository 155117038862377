import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import { Button, Stack, Text } from '@kiwicom/orbit-components';

import { arrayOfObjectsToCsv } from 'utils/object';
import { handleCSVDownload } from 'utils/files';
import { BILLING_STATUSES } from 'consts/billing';

import usePolyglot from 'components/services/i18n/usePolyglot';
import { BillingState } from 'components/services/billing';
import StatusFilter from './StatusFilter';
import DateFilter from './DateFilter';

const SelectWrapper = styled.div`
  width: 215px;
  & > label > div > input {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
`;

const ExportModal = ({ onClose }) => {
  const polyglot = usePolyglot();
  const {
    state: { exportLoading },
    loadExportBillings,
  } = useContext(BillingState);

  const [dateFilter, setDateFilter] = useState({ startDate: null, endDate: null });
  const [statusFilters, setStatusFilters] = useState(BILLING_STATUSES.map(item => item.value));

  const handleExport = async () => {
    const exportData = await loadExportBillings({ statusFilters, ...dateFilter });

    await handleCSVDownload(
      arrayOfObjectsToCsv(
        exportData.map(el => ({
          company_name: el.companyName,
          period_start: el.periodStart,
          period_end: el.periodEnd,
          amount: el.amount ? `€ ${el.amount}` : null,
          pdf_ready: el.pdfReady,
          csv_ready: el.csvReady,
        })),
      ),
      `billing_${Date.now()}`,
    );
    onClose();
  };

  const handleStatusFilterChange = value => {
    if (statusFilters.includes(value)) {
      setStatusFilters(statusFilters.filter(el => el !== value));
    } else {
      setStatusFilters([...statusFilters, value]);
    }
  };

  const handleDateFilterChange = value => {
    setDateFilter(value);
  };

  return (
    <Modal onClose={onClose} size="small">
      <ModalHeader title={polyglot.t('customer_demand_dashboard.modal.export.title')}>
        <Text size="big">{polyglot.t('customer_demand_dashboard.modal.export.subtitle')}</Text>
      </ModalHeader>
      <ModalSection>
        <Stack direction="column" spacing="small">
          <StatusFilter
            selectedStatuses={statusFilters}
            loading={exportLoading}
            onChange={handleStatusFilterChange}
          />
          <SelectWrapper>
            <DateFilter
              selectedDate={dateFilter}
              loading={exportLoading}
              onClick={handleDateFilterChange}
            />
          </SelectWrapper>
        </Stack>
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" align="center" justify="between">
          <Button type="secondary" onClick={onClose} loading={exportLoading}>
            {polyglot.t('customer_demand_dashboard.modal.export.button.cancel')}
          </Button>
          <Button onClick={handleExport} loading={exportLoading}>
            {polyglot.t('customer_demand_dashboard.modal.export.button.export')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default ExportModal;
