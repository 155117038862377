import React from 'react';
import * as R from 'ramda';
import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Replace from '@kiwicom/orbit-components/lib/icons/Replace';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import { LocationsAutocomplete } from 'common';

import { usePolyglot } from 'components/services/i18n';
import { useSearch } from 'components/services/search';
import { Space } from 'common';
import { SEARCH_LOCATION_TYPES, sortTypes } from 'consts/search';

import { SearchbarContainer, DirectionSwitchContainer, ReturnWrapper } from './Searchbar.styled';
import CalendarDropdown from './../CalendarDropdown';

const Return = props => {
  const polyglot = usePolyglot();
  const {
    changeLocationsFrom,
    changeLocationsTo,
    changeDepartureSelectedDates,
    changeReturnSelectedDates,
    searchFlights,
    tabData,
  } = useSearch();

  const tripType = tabData.tripType;
  const locationsFrom = tabData.searchbar[tripType].locationsFrom;
  const locationsTo = tabData.searchbar[tripType].locationsTo;
  const departureSelectedDates = tabData.searchbar[tripType].departureSelectedDates;
  const returnSelectedDates = tabData.searchbar[tripType].returnSelectedDates;
  const showReturnCalendar = !props.oneway && departureSelectedDates.length > 0;
  const loadingResults =
    tabData.searchStatus[sortTypes.BEST] === 'loading' ||
    tabData.searchStatus[sortTypes.PRICE] === 'loading' ||
    tabData.searchStatus[sortTypes.DURATION] === 'loading';

  const handleSelectLocation = key => selectedValue => {
    if (key === 'from') {
      changeLocationsFrom(R.append(selectedValue, locationsFrom));
    } else if (key === 'to') {
      changeLocationsTo(R.append(selectedValue, locationsTo));
    }
  };

  const handleUnselectLocation = key => unselectedValue => {
    if (key === 'from') {
      changeLocationsFrom(
        locationsFrom.filter(location => location.value !== unselectedValue.value),
      );
    } else if (key === 'to') {
      changeLocationsTo(locationsTo.filter(location => location.value !== unselectedValue.value));
    }
  };

  const handleDirectionSwitch = () => {
    changeLocationsFrom(locationsTo);
    changeLocationsTo(locationsFrom);
  };

  const handleSearch = () => {
    searchFlights(tabData);
  };

  return (
    <Stack spaceAfter="large">
      <SearchbarContainer row>
        <Space right="m">
          <LocationsAutocomplete
            onSelect={handleSelectLocation('from')}
            onUnselect={handleUnselectLocation('from')}
            selectedValues={locationsFrom}
            label={<Text type="secondary">{polyglot.t('common.from')}</Text>}
            inlineLabel
            locationTypes={SEARCH_LOCATION_TYPES}
            dataTest="search-from"
          />
        </Space>
        <DirectionSwitchContainer>
          <Button
            title="switch direction"
            size="small"
            type="google"
            iconLeft={<Replace />}
            circled
            onClick={handleDirectionSwitch}
            dataTest="search-switch-direction"
          />
        </DirectionSwitchContainer>
        <Space>
          <LocationsAutocomplete
            onSelect={handleSelectLocation('to')}
            onUnselect={handleUnselectLocation('to')}
            selectedValues={locationsTo}
            label={<Text type="secondary">{polyglot.t('common.to')}</Text>}
            inlineLabel
            locationTypes={SEARCH_LOCATION_TYPES}
            dataTest="search-to"
          />
        </Space>
      </SearchbarContainer>
      <SearchbarContainer row>
        <Space right="m">
          <CalendarDropdown
            prefix={polyglot.t('searchAndBooking.departure')}
            selectedDates={departureSelectedDates}
            changeSelectedDates={changeDepartureSelectedDates}
            dataTest="search-departure"
          />
        </Space>
        <ReturnWrapper oneway={props.oneway}>
          {showReturnCalendar && (
            <Space right="m">
              <CalendarDropdown
                prefix={polyglot.t('common.return')}
                selectedDates={returnSelectedDates}
                dataTest="search-return"
                disabledDates={eachDayOfInterval({
                  start: addDays(new Date(), -2),
                  end: addDays(new Date(R.head(departureSelectedDates)), -1),
                })}
                firstMonth={new Date(R.head(departureSelectedDates))}
                changeSelectedDates={changeReturnSelectedDates}
              />
            </Space>
          )}
          <Button
            title={polyglot.t('searchAndBooking.search')}
            loading={loadingResults}
            disabled={Boolean(
              !locationsFrom.length || !locationsTo.length || !departureSelectedDates.length,
            )}
            onClick={handleSearch}
            dataTest="start-search-button"
            block
          >
            <SearchIcon />
          </Button>
        </ReturnWrapper>
      </SearchbarContainer>
    </Stack>
  );
};

export default Return;
