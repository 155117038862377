import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { StyledInput } from './Input.styled';

// Input component is basic building block for others components,
// that are related to inputs. Except checkbox input - it has own component
class Input extends PureComponent {
  input = React.createRef();

  componentDidUpdate = () => {
    if (this.props.autoFocus) {
      this.input.focus();
    }
  };

  render() {
    const {
      id,
      onChange,
      onClick,
      onFocus,
      onBlur,
      onKeyDown,
      placeholder,
      value,
      readOnly,
      className,
      disabled,
      type,
    } = this.props;

    return (
      <StyledInput
        id={id}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onClick={onClick}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        ref={element => (this.input = element)}
      />
    );
  }
}

Input.defaultProps = {
  disabled: false,
};

Input.propTypes = {
  id: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
};

export default Input;
