import React, { useContext, useEffect, useMemo } from 'react';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@kiwicom/orbit-components/lib/Table';
import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import styled from 'styled-components';

import ThreeStatesCheckbox from 'common/ThreeStatesCheckbox';
import useBookingCheckboxes from 'components/services/mmb/useBookingCheckboxes';
import { useDependencyEffect } from 'hooks';
import { MMBState } from 'components/services/mmb';
import { usePolyglot } from 'components/services/i18n';
import useBulkRefunds from 'components/services/mmb/useBulkRefunds';
import * as Intercom from 'utils/intercom';

import { Bid, Trip } from './components';
import { WrappedTableRow } from './index.styled';

const TableWrapper = styled.div`
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'initial')};

  table {
    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 2;
    }
  }
`;

const MultiRefundsModal = ({
  bookings,
  disruptions,
  onClose,
  multiRefundsAlertFail,
  onCloseSingleRefundAlertSuccess,
  onCloseMultiRefundsAlertSuccess,
}) => {
  const polyglot = usePolyglot();
  const { checkboxIds, toggleBid, toggleAllBids } = useBookingCheckboxes();
  const {
    state: { refunds },
  } = useContext(MMBState);
  const { submitRefunds, isLoading } = useBulkRefunds();
  const refundsStateKeys = Object.keys(refunds);
  const hasRefundsStateLength = refundsStateKeys && refundsStateKeys.length;
  const areAllRefundsLoaded =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => refunds[refundStateKey].requestingRefund === false);
  const areAllRefundsPassedSuccessfully =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => !refunds[refundStateKey].requestRefundError);

  const receivedBookings = useMemo(
    () => {
      if (!multiRefundsAlertFail.isOn) {
        return bookings.filter(booking => checkboxIds.includes(booking.bid));
      }
      return bookings.filter(booking => refunds?.[`${booking.bid}`]?.requestRefundError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiRefundsAlertFail.isOn],
  );

  const stateValuesLength = checkboxIds.length;
  const valuesLength = receivedBookings.length;

  const threeStatesCheckboxDefaultValue = useMemo(() => {
    if (!stateValuesLength) {
      return 0;
    } else if (stateValuesLength && stateValuesLength !== valuesLength) {
      return 1;
    } else if (stateValuesLength && stateValuesLength === valuesLength) {
      return 2;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDependencyEffect(() => {
    if (areAllRefundsLoaded && areAllRefundsPassedSuccessfully) {
      onClose();
    } else if (areAllRefundsLoaded && !areAllRefundsPassedSuccessfully) {
      multiRefundsAlertFail.setOn();
      isLoading.current = false;
    }
  }, [areAllRefundsLoaded]);

  useEffect(() => {
    onCloseSingleRefundAlertSuccess();
    onCloseMultiRefundsAlertSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitRefunds = async () => {
    await submitRefunds();
    Intercom.trackEvent('bulk-refund', { bids: receivedBookings.map(booking => booking.bid) });
  };

  return (
    <Modal
      onClose={
        isLoading.current
          ? null
          : () => {
              onClose();
              multiRefundsAlertFail.setOff();
            }
      }
    >
      <ModalHeader>
        <Heading spaceAfter="small">{polyglot.t('mmb.refund')}</Heading>
        {!multiRefundsAlertFail.isOn && (
          <Text>{polyglot.t('mmb.multi_refunds_modal.description')}</Text>
        )}
        {multiRefundsAlertFail.isOn && (
          <Alert type="critical" title={polyglot.t('mmb.multi.refunds.fail.title')} icon>
            {polyglot.t('mmb.multi.refunds.fail.subtitle')}
          </Alert>
        )}
      </ModalHeader>
      <ModalSection>
        <TableWrapper isLoading={isLoading.current}>
          <Table striped={false}>
            <TableHead>
              <TableRow>
                {!multiRefundsAlertFail.isOn && (
                  <TableCell>
                    <ThreeStatesCheckbox
                      defaultValue={threeStatesCheckboxDefaultValue}
                      stateValues={checkboxIds}
                      values={receivedBookings}
                      onChange={checkboxState =>
                        toggleAllBids(
                          checkboxState,
                          receivedBookings.map(booking => booking.bid),
                        )
                      }
                    />
                  </TableCell>
                )}
                <TableCell>
                  {!multiRefundsAlertFail.isOn
                    ? polyglot.t('mmb.trip')
                    : polyglot.t('mmb.trip.error')}
                </TableCell>
                <TableCell>{polyglot.t('mmb.bid')}</TableCell>
                <TableCell>{polyglot.t('mmb.passengers')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receivedBookings.map((booking, i) => (
                <WrappedTableRow
                  key={booking.bid}
                  dataTest={`table-row-${i}`}
                  disrupted={disruptions?.[booking.bid]?.disruptions}
                >
                  {!multiRefundsAlertFail.isOn && (
                    <TableCell dataTest={`trip-row-${i}`}>
                      <Checkbox
                        name={`checkbox-${booking.bid}`}
                        onChange={() => toggleBid(booking.bid)}
                        checked={checkboxIds.includes(booking.bid)}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <Trip
                      arrival={booking.destination}
                      departure={booking.departure}
                      fullTrip={!booking.roundTrip && booking?.fullTrip}
                      roundTrip={booking.roundTrip}
                    />
                  </TableCell>
                  <TableCell>
                    {}
                    <Bid
                      bid={booking.bid}
                      dataTest={`bid-row-${i}`}
                      href={`/manage-my-bookings/${booking.bid}`}
                      external
                    />
                  </TableCell>
                  <TableCell dataTest={`passengers-row-${i}`}>{booking.passengers}</TableCell>
                </WrappedTableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </ModalSection>
      {!multiRefundsAlertFail.isOn && (
        <ModalFooter>
          <Stack direction="row" justify="between">
            <Button type="secondary" onClick={onClose} disabled={isLoading.current}>
              {polyglot.t('mmb.multi_refunds_modal.cancel')}
            </Button>
            <Button
              type="primary"
              disabled={isLoading.current || !checkboxIds.length}
              loading={isLoading.current}
              onClick={handleSubmitRefunds}
            >
              {polyglot.t('mmb.multi_refunds_modal.request')}
            </Button>
          </Stack>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default React.memo(MultiRefundsModal);
