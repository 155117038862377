import styled from 'styled-components';

import Button from '@kiwicom/orbit-components/lib/Button';

export const DropdownInputButtonWrapper = styled.div`
  position: relative;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -40%);
  z-index: 10;
`;
