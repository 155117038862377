import { useContext, useEffect } from 'react';
import { CompanyState } from './';

const useCompanyDetails = companyName => {
  const companiesCtx = useContext(CompanyState);

  useEffect(() => {
    companiesCtx.loadCompany(companyName);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  return (
    companiesCtx.detailsState[companyName] || {
      loading: true,
      error: null,
      updateError: null,
      data: null,
    }
  );
};

export default useCompanyDetails;
