import PropTypes, { shape } from 'prop-types';
import { admin } from 'shapes/User';

const ReportAliasMetric = {
  value: PropTypes.string,
  label: PropTypes.string,
};

const ReportAliasMetric1 = {
  value: 'affiliate',
  label: 'Affiliate',
};
const ReportAliasMetric2 = {
  value: 'clicks',
  label: 'Clicks',
};

const ReportAliasFilters = {
  affiliate: PropTypes.array,
  market: PropTypes.array,
  device: PropTypes.array,
  platform: PropTypes.array,
  routeType: PropTypes.array,
};

const ReportAliasFilters1 = {
  market: ['ax', 'dz'],
  device: ['mobile', 'tablet'],
  platform: ['Android', 'Fedora', 'iOS'],
  routeType: ['OW', 'RT'],
};

const ReportAlias = {
  alias: PropTypes.string,
  id: PropTypes.number,
  aliasType: PropTypes.string,
  dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  dateRangeToCompare: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  interval: PropTypes.string,
  show: PropTypes.arrayOf(PropTypes.shape(ReportAliasMetric)),
  sort: PropTypes.string,
  filters: shape(ReportAliasFilters),
  parentId: PropTypes.number,
  lastModified: PropTypes.instanceOf(Date),
};

export const reportAlias = {
  alias: 'alias',
  aliasType: 'default',
  dateRange: [new Date('Sat Oct 07 2017 00:00:00 GMT+0200 (CEST)')],
  id: 1,
  interval: 'hour',
  show: [ReportAliasMetric1, ReportAliasMetric2],
  user: admin,
  sort: 'clicks',
  filters: ReportAliasFilters1,
  parentId: 2,
};

export const reportAliasCompare = {
  ...reportAlias,
  dateRangeToCompare: [new Date(2017, 9, 1)],
};

export default ReportAlias;
