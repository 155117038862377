import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';

import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { GroupBy } from 'pages/Reports/components';
import ReportAlias from 'shapes/ReportAlias';
import { withValidation, OrbitLoader, Flex, Space, SelectSolutionsCompany } from 'common';

import * as reportsSelectors from 'redux/selectors/reports';
import { getPolyglot } from 'redux/selectors/i18n';
import Polyglot from 'shapes/Polyglot';

import { MILLENNIUM } from 'consts/dates';
import {
  cantBeEmpty,
  cantBeLongerThan,
  cantBeEmptyArray,
  cantContainCyrillicObsolete,
} from 'utils/validation';
import { FILTER_NAMES_VALUES } from 'consts/reports';
import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import ReportDates from './ReportDates/ReportDates';
import { ReportFilters } from 'pages/Reports/components/Filters';
import ReportMetrics from './ReportMetrics/ReportMetrics';

import { InputContainer, MetricsContainer, ActionsContainer } from './ReportAliasForm.styled';
import { useCurrentUser } from 'components/services/auth';
import { SolutionsState } from 'components/services/solutions';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';

const ALIAS_NAME_MAX_CHARACTER_LENGTH = 255;

const validationRules = {
  alias: [
    cantBeEmpty(),
    cantBeLongerThan(ALIAS_NAME_MAX_CHARACTER_LENGTH),
    cantContainCyrillicObsolete(),
  ],
  show: [cantBeEmptyArray(), cantContainCyrillicObsolete()],
};

const withNewEndpoints = WrappedComponent => {
  return function ({ ...props }) {
    const user = useCurrentUser();
    const { loadCompanySolutions, state } = useContext(SolutionsState);
    const { selectedCompany, selectCompany } = useSelectedCompany();
    return (
      <WrappedComponent
        {...{
          ...props,
          user,
          loadApplications: loadCompanySolutions,
          applicationsLoading: state?.[user.companyName]?.loading,
          selectedCompany,
          selectCompany,
        }}
      />
    );
  };
};

class ReportAliasForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportAlias: props.reportAlias,
      companyName: props.selectedCompany || props.user.companyName,
    };
  }

  componentDidMount() {
    const { user, loadApplications, selectedCompany } = this.props;
    loadApplications(selectedCompany || user.companyName);
  }

  handleChangeAlias = obj => {
    this.setState(state => ({
      reportAlias: R.merge(state.reportAlias, obj),
    }));
  };

  handleChange = field => value => {
    this.handleChangeAlias({ [field]: value });
    if (validationRules[field]) {
      this.props.validateInput(field, value);
    }
  };

  handleChangeName = event => {
    const { value } = event.target;

    this.handleChange('alias')(value);
    this.props.validateInput('alias', value);
  };

  handleChangeFilter = (filter, values) => {
    const currentFilters = this.state.reportAlias.filters;
    if (!values) {
      this.setState(state => ({
        reportAlias: {
          ...state.reportAlias,
          filters: R.omit([filter], currentFilters),
        },
      }));
    } else {
      this.setState(state => ({
        reportAlias: {
          ...state.reportAlias,
          filters: {
            ...currentFilters,
            [filter]: values,
          },
        },
      }));
    }
  };

  handleBlur = field => () => {
    this.props.validateInput(field, this.state.reportAlias[field]);
  };

  save = () => {
    const { validateSubmission, save } = this.props;
    const { reportAlias } = this.state;

    validateSubmission(reportAlias, () => save(reportAlias));
  };

  preview = () => this.props.preview(this.state.reportAlias);

  handleCompanySelect = companyName => {
    this.setState(state => ({
      ...state,
      companyName,
    }));
    const { loadApplications, selectCompany } = this.props;
    selectCompany(companyName);
    loadApplications(companyName);
  };
  render() {
    const { reportAlias } = this.state;
    const { polyglot, errors, isInvalid, areLoading, onCancel, applicationsLoading, user } =
      this.props;

    const filterRows = R.keys(reportAlias.filters).map(filter => ({
      filter: FILTER_NAMES_VALUES.find(filterName => filterName.value === filter),
      selectedValues: reportAlias.filters[filter],
    }));

    return (
      <div>
        {(user.role === ACCOUNT_MANAGER || user.role === KIWI_ADMIN) && (
          <SelectSolutionsCompany onSelect={this.handleCompanySelect} />
        )}
        {applicationsLoading ? (
          <Flex main="center">
            <OrbitLoader id="loading-dashboard" visible />
          </Flex>
        ) : (
          <>
            <InputContainer>
              <InputField
                label={polyglot.t('common.name')}
                value={reportAlias.alias}
                onChange={this.handleChangeName}
                onBlur={this.handleBlur('alias')}
                error={errors['alias'] && polyglot.t(errors['alias'])}
              />
            </InputContainer>
            <MetricsContainer>
              <ReportMetrics
                onChange={this.handleChange}
                alias={reportAlias.alias}
                items={reportAlias.show}
                errors={errors}
              />
            </MetricsContainer>

            <Space after="xl">
              <ReportFilters
                polyglot={polyglot}
                filterRows={filterRows}
                onChange={this.handleChangeFilter}
                companyName={this.state.companyName}
              />
            </Space>
            <Space after="xl">
              <Flex>
                <ReportDates
                  dates={reportAlias.dateRange}
                  datesCompare={reportAlias.dateRangeToCompare}
                  onChangeAlias={this.handleChangeAlias}
                />
              </Flex>
            </Space>

            <InputContainer>
              <GroupBy
                id="report-interval"
                value={
                  !R.isEmpty(reportAlias.dateRangeToCompare) ? MILLENNIUM : reportAlias.interval
                }
                onChange={this.handleChange('interval')}
                disabled={!R.isEmpty(reportAlias.dateRangeToCompare)}
              />
            </InputContainer>
            <ActionsContainer>
              <Button width="128px" type="secondary" onClick={onCancel}>
                {polyglot.t('common.cancel')}
              </Button>
              <Button width="128px" type="secondary" onClick={this.preview} loading={areLoading}>
                {polyglot.t('common.preview')}
              </Button>
              <Button width="128px" onClick={this.save} disabled={isInvalid}>
                {polyglot.t('common.save')}
              </Button>
            </ActionsContainer>
          </>
        )}
      </div>
    );
  }
}

ReportAliasForm.propTypes = {
  reportAlias: PropTypes.shape(ReportAlias).isRequired,
  save: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  // redux props
  polyglot: PropTypes.shape(Polyglot),
  isInvalid: PropTypes.bool,
  errors: PropTypes.object,
  areLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
  areLoading: reportsSelectors.areLoading(state),
});

export default R.compose(
  withValidation(validationRules),
  connect(mapStateToProps),
  withNewEndpoints,
)(ReportAliasForm);
