import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonGroup from '@kiwicom/orbit-components/lib/ButtonGroup';
import PassengersIcon from '@kiwicom/orbit-components/lib/icons/Passengers';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import useSearch from 'components/services/search/useSearch';
import { tripTypesValues, tripTypesLabels, cabinClassTypesLabels } from 'consts/search';
import {
  getSumOfPassengers,
  getPricesFilterDefaultValues,
  getCarriersList,
  showClearButton,
} from 'utils/search';

import DropdownButton from './components/DropdownButton';
import Passengers from './components/Passengers';
import Direction from './components/Direction';
import FlightClass from './components/FlightClass';
import TransportType from './components/TransportType';
import Stopovers from './components/Stopovers';
import Times from './components/Times';
import Currency from './components/Currency';
import Prices from './components/Prices';
import Carriers from './components/Carriers';

const Filters = () => {
  const polyglot = usePolyglot();
  const { tabData, clearSearch } = useSearch();

  return (
    <Stack direction="row" spaceAfter="normal">
      <ButtonGroup>
        <DropdownButton
          buttonText={polyglot.t(tripTypesLabels[tabData.tripType])}
          dataTest="filter-direction"
          content={<Direction />}
        />
        <DropdownButton
          buttonText={String(getSumOfPassengers(tabData.passengersAmounts))}
          iconLeft={<PassengersIcon />}
          dataTest="filter-passenger"
          content={<Passengers />}
        />
        <DropdownButton
          buttonText={polyglot.t(cabinClassTypesLabels[tabData.selectedCabinClassType])}
          dataTest="filter-flight-class"
          content={<FlightClass />}
        />
        <DropdownButton
          buttonText={polyglot.t('searchAndBooking.vehicleTypes.title')}
          dataTest="filter-transport"
          content={<TransportType />}
        />
        <DropdownButton
          buttonText={polyglot.t('searchAndBooking.stopovers')}
          dataTest="filter-stopovers"
          content={<Stopovers />}
        />
        {(tabData.tripType === tripTypesValues.RETURN ||
          tabData.tripType === tripTypesValues.ONEWAY) && (
          <DropdownButton
            buttonText={polyglot.t('searchAndBooking.times')}
            dataTest="filter-times"
            content={<Times />}
          />
        )}
        <DropdownButton
          buttonText={polyglot.t('searchAndBooking.currency')}
          dataTest="filter-currency"
          content={<Currency />}
        />
        {getPricesFilterDefaultValues(tabData) && (
          <DropdownButton
            buttonText={polyglot.t('searchAndBooking.prices')}
            dataTest="filter-prices"
            content={<Prices />}
          />
        )}
        {getCarriersList(tabData).length > 0 && (
          <DropdownButton
            buttonText={polyglot.t('searchAndBooking.carriers')}
            dataTest="filter-carriers"
            content={<Carriers />}
          />
        )}
        {showClearButton(tabData) && (
          <Button
            size="small"
            type="white"
            dataTest="filter-clear-all"
            onClick={() => {
              clearSearch(true);
            }}
          >
            {polyglot.t('searchAndBooking.clear')}
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default Filters;
