const v1_get_from = specialAppDefinition => {
  return {
    displayName: specialAppDefinition.display_name,
    id: specialAppDefinition.id,
    name: specialAppDefinition.name,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
