import React, { useContext } from 'react';
import { FieldArray } from 'formik';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import { SmartPassContext } from 'components/services/smartPass';
import { propsComparison } from 'utils/propsComparison';
import { keys, generateNewVoucher, vouchersFormKeys } from 'consts/smartPass';

import VoucherList from './VoucherList';
import AddOrEditVoucher from './AddOrEditVoucher';
import ActivationButton from '../ActivationButton';

const { VOUCHERS, VOUCHERS_ACTIVATED } = keys;

const Vouchers = ({
  setFieldValue,
  values,
  handleChange,
  disabled,
  companyName,
  errors,
  touched,
}) => {
  const { voucherExampleModal, editingVoucher, addNewVoucherScreen, setEditingVoucher } =
    useContext(SmartPassContext);

  const isVoucherScreenShown = editingVoucher || addNewVoucherScreen.isOn;

  const polyglot = usePolyglot();

  const showVoucherScreen = selectedVoucher => {
    if (selectedVoucher) {
      addNewVoucherScreen.setOff();
      setEditingVoucher(selectedVoucher);
    } else {
      setEditingVoucher(null);
      addNewVoucherScreen.setOn();
    }
  };

  const handleCancel = () => {
    setEditingVoucher(null);
    addNewVoucherScreen.setOff();
  };

  const handleSave = (push, voucherFormValues) => {
    if (editingVoucher) {
      setFieldValue(
        VOUCHERS,
        values[VOUCHERS].map(v => (v.id === editingVoucher.id ? voucherFormValues : v)),
      );
    } else {
      const newVoucher = generateNewVoucher(voucherFormValues);
      push(newVoucher);
    }

    addNewVoucherScreen.setOff();
    setEditingVoucher(null);
  };

  return (
    <FieldArray name={VOUCHERS}>
      {({ remove, push }) => (
        <Card
          spaceAfter="largest"
          header={
            <Stack direction="row" justify="between" align="center">
              <Heading as="h2" type="title3">
                {polyglot.t('smart_pass.form.vouchers.header_title')}
              </Heading>
              {isVoucherScreenShown && (
                <Stack direction="row" inline justify="end" align="center" spacing="XXSmall">
                  <InformationCircleIcon size="small" color="info" />
                  <TextLink onClick={voucherExampleModal.setOn}>
                    <Text size="small" type="info" weight="bold">
                      {polyglot.t('smart_pass.form.voucher_example')}
                    </Text>
                  </TextLink>
                </Stack>
              )}
              {!isVoucherScreenShown && (
                <ActivationButton
                  formKeys={vouchersFormKeys}
                  isActivatedKey={VOUCHERS_ACTIVATED}
                  name={polyglot.t('smart_pass.form.vouchers.header_title')}
                  values={values}
                  disabled={disabled}
                  setFieldValue={setFieldValue}
                />
              )}
            </Stack>
          }
        >
          {values[VOUCHERS_ACTIVATED] && (
            <CardSection>
              {isVoucherScreenShown ? (
                <AddOrEditVoucher
                  handleCancel={handleCancel}
                  handleSave={handleSave}
                  pushVoucher={push}
                  handleChange={handleChange}
                  smartPassFormValues={values}
                  setFieldValue={setFieldValue}
                  companyName={companyName}
                  editingVoucher={editingVoucher}
                />
              ) : (
                <VoucherList
                  remove={remove}
                  showVoucherScreen={showVoucherScreen}
                  values={values}
                  disabled={disabled}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              )}
            </CardSection>
          )}
        </Card>
      )}
    </FieldArray>
  );
};

export default React.memo(Vouchers, propsComparison(vouchersFormKeys));
