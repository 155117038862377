import { isProduction } from 'utils/env';

export const TEQUILA_FE_ZOOZ = isProduction()
  ? 'https://tequila-fe.kiwi.com'
  : 'https://staging-tequila-fe.kiwi.com';

export const TEQUILA_FE_DEPOSIT = isProduction()
  ? 'https://tequila-fe-deposit.kiwi.com'
  : 'https://staging-tequila-fe-deposit.kiwi.com';

export const TEQUILA_FE_ZOOZ_SMARTPOINT = isProduction()
  ? 'https://tequila-fe-smartpoint.kiwi.com'
  : 'https://staging-tequila-fe-smartpoint.kiwi.com';

export const TEQUILA_FE_DEPOSIT_SMARTPOINT = isProduction()
  ? 'https://tequila-fe-deposit-smartpoint.kiwi.com'
  : 'https://staging-tequila-fe-deposit-smartpoint.kiwi.com';

export const ANCILLARIES = {
  GUARANTEE: 'guarantee',
  SEATING: 'seating',
};
