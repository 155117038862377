export const executeRecaptcha = (action, onSuccess, onError) => {
  if (window.grecaptcha) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'submit',
        })
        .then(gtoken => action(gtoken))
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(err => onError(err));
    });
  }
};
