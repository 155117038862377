import React, { useEffect, useState, useMemo, useContext } from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { Formik } from 'formik';
import isWithinInterval from 'date-fns/isWithinInterval';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import AlertCircleIcon from '@kiwicom/orbit-components/lib/icons/AlertCircle';

import { cantContainCyrillic } from 'utils/validation';
import { OrbitLoader, Flex, Space } from 'common';
import useRoles from 'components/services/products/useRoles';
import useCompanyNames from 'components/services/companies/useCompanyNames';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';

import AdditionalInfo from './components/AdditionalInfo';

const CompanySelect = ({ polyglot, userBeingEdited, company, handleChange }) => {
  const { data, loading, error } = useCompanyNames();
  const mappedCompanyNames = useMemo(() => data?.map(r => ({ label: r, value: r })) || [], [data]);
  const hasCompany = userBeingEdited && !R.isEmpty(userBeingEdited?.companyName);

  return useMemo(
    () => (
      <Space before="l">
        {loading && !hasCompany ? (
          <Loading type="boxLoader" />
        ) : (
          <Select
            name="company"
            id="company-name-select"
            error={error && polyglot.t('add_user.company_loading_error')}
            label={polyglot.t('company.name')}
            options={mappedCompanyNames}
            value={company}
            onChange={handleChange}
            disabled={hasCompany || error}
          />
        )}
      </Space>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company, data, loading, error],
  );
};

const UserSchema = Yup.object().shape({
  email: Yup.string()
    .email('validation.invalid_email')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(255, 'validation.length_exceeded')
    .required('validation.cant_be_empty'),
  firstName: Yup.string()
    .trim()
    .min(1, 'validation.cant_be_empty')
    .max(255, 'validation.length_exceeded')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .required('validation.cant_be_empty'),
  lastName: Yup.string()
    .trim()
    .min(1, 'validation.cant_be_empty')
    .max(255, 'validation.length_exceeded')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .required('validation.cant_be_empty'),
  dateOfBirth: Yup.string().when(['isAdditionalInfoNeeded'], {
    is: isAdditionalInfoNeeded => isAdditionalInfoNeeded,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('is-valid', 'validation.invalid_date', date =>
        isWithinInterval(new Date(date), { start: new Date(1900, 0), end: new Date() }),
      ),
  }),
  nationality: Yup.string().when(['isAdditionalInfoNeeded'], {
    is: isAdditionalInfoNeeded => isAdditionalInfoNeeded,
    then: Yup.string().required('validation.cant_be_empty'),
  }),
  role: Yup.string().required('validation.cant_be_empty'),
});

const UserModal = ({
  userBeingEdited,
  emptyCompany,
  onClose,
  onSubmit,
  loading,
  isAdditionalInfoNeeded,
  error,
}) => {
  const polyglot = usePolyglot();
  const roles = useRoles();
  const [loadingGrants, setLoadingGrants] = useState(false);
  const { checkGrantProperties, checkedGrants, can } = useContext(AuthState);
  const user = useCurrentUser();
  const currentCompany = useCurrentCompanyName();
  const companyName = userBeingEdited?.companyName || currentCompany || user.companyName;
  useEffect(() => {
    if (
      !checkedGrants[companyName]?.['model.user'] ||
      !checkedGrants[companyName]?.['meta.roles']
    ) {
      setLoadingGrants(true);
      Promise.all([
        can(user, ['update'], 'model.user', companyName),
        can(user, ['index'], 'meta.roles', companyName),
        can(user, ['update'], 'acl.roles', companyName),
      ]).then(setLoadingGrants(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  if (roles.loading || loadingGrants) {
    return (
      <Modal onClose={onClose}>
        <ModalSection>
          <Flex main="center">
            <OrbitLoader id="roles-loader" visible />
          </Flex>
        </ModalSection>
      </Modal>
    );
  }

  const canEmail =
    !userBeingEdited && checkGrantProperties('model.user', 'create', 'email', companyName);
  const canFirstName = userBeingEdited
    ? checkGrantProperties('model.user', 'update', 'first_name', userBeingEdited.companyName)
    : checkGrantProperties('model.user', 'create', 'first_name', companyName);
  const canLastName = userBeingEdited
    ? checkGrantProperties('model.user', 'update', 'last_name', userBeingEdited.companyName)
    : checkGrantProperties('model.user', 'create', 'last_name', companyName);
  const canDateOfBirth = userBeingEdited
    ? checkGrantProperties('model.user', 'update', 'date_of_birth', userBeingEdited.companyName)
    : checkGrantProperties('model.user', 'create', 'date_of_birth', companyName);
  const canNationality = userBeingEdited
    ? checkGrantProperties('model.user', 'update', 'nationality', userBeingEdited.companyName)
    : checkGrantProperties('model.user', 'create', 'nationality', companyName);
  const canRole = checkGrantProperties('acl.roles', 'update', null, companyName);

  let mappedRoles;

  if (canRole) {
    mappedRoles = roles.data
      ?.filter(r => checkedGrants?.[companyName]?.['meta.roles']?.index?.includes(r.name))
      ?.map(r => ({ label: r.displayName, value: r.name }));
  } else {
    mappedRoles = roles.data.map(r => ({ label: r.displayName, value: r.name }));
  }

  const handleSubmitData = values => {
    let data = { ...values };
    if (!canNationality) {
      delete data.nationality;
    }
    if (!canDateOfBirth) {
      delete data.dateOfBirth;
    }
    onSubmit(data);
  };

  return (
    <Portal>
      <Modal onClose={onClose}>
        <ModalHeader
          title={userBeingEdited ? polyglot.t('modal_edit.user') : polyglot.t('modal_new.user')}
        />
        <Formik
          initialValues={{
            userBeingEdited,
            isAdditionalInfoNeeded: false,
            email: userBeingEdited?.email || '',
            firstName: userBeingEdited?.firstName || '',
            lastName: userBeingEdited?.lastName || '',
            dateOfBirth: userBeingEdited?.dateOfBirth || '',
            nationality: userBeingEdited?.nationality || '',
            role: userBeingEdited?.role || '',
            company: companyName || '',
          }}
          validationSchema={UserSchema}
          onSubmit={handleSubmitData}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setTouched,
            values,
            errors,
            touched,
            validateForm,
          }) => (
            <>
              <ModalSection>
                <Stack direction="column" align="center" spacing="comfy" spaceAfter="large">
                  {error && (
                    <Alert
                      icon={error.title ? <AlertCircleIcon /> : null}
                      title={error.title || ''}
                      type={error.type || 'critical'}
                    >
                      {error.msg}
                    </Alert>
                  )}
                </Stack>
                <Stack>
                  <InputField
                    name="email"
                    value={values.email}
                    label={polyglot.t('common.email')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readonly={userBeingEdited}
                    disabled={!canEmail}
                    error={touched.email && errors.email && polyglot.t(errors.email)}
                  />

                  <Stack direction="row" spacing="XSmall">
                    <InputField
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      label={polyglot.t('common.first_name')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canFirstName}
                      error={touched.firstName && errors.firstName && polyglot.t(errors.firstName)}
                    />
                    <InputField
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      label={polyglot.t('common.last_name')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canLastName}
                      error={touched.lastName && errors.lastName && polyglot.t(errors.lastName)}
                    />
                  </Stack>

                  {isAdditionalInfoNeeded && (
                    <AdditionalInfo
                      isAdditionalInfoNeeded={isAdditionalInfoNeeded}
                      setFieldValue={setFieldValue}
                      setTouched={setTouched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  )}

                  <Select
                    name="role"
                    label={polyglot.t('common.role')}
                    options={mappedRoles}
                    placeholder={polyglot.t('modal.select_role')}
                    error={touched.role && errors.role && polyglot.t(errors.role)}
                    value={values.role}
                    onChange={handleChange}
                    disabled={!canRole}
                  />

                  {emptyCompany && (
                    <CompanySelect
                      polyglot={polyglot}
                      userBeingEdited={userBeingEdited}
                      company={values.company}
                      handleChange={handleChange}
                    />
                  )}
                </Stack>
              </ModalSection>
              <ModalFooter>
                <Button type="secondary" width="128px" onClick={onClose}>
                  {polyglot.t('common.cancel')}
                </Button>
                <Button loading={loading} onClick={handleSubmit} width="128px">
                  {polyglot.t('common.save')}
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </Portal>
  );
};

export default UserModal;
