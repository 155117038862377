import { useEffect, useContext } from 'react';
import * as R from 'ramda';

import { ResourcesState } from './';

const useTaxonomy = codename => {
  const { state, fetchTaxonomy } = useContext(ResourcesState);

  useEffect(() => {
    if (!R.path(['taxonomies', codename, 'data'], state)) {
      fetchTaxonomy(codename);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.taxonomies[codename] || { loading: false, data: null, error: null };
};

export default useTaxonomy;
