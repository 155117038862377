import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import Money from '@kiwicom/orbit-components/lib/icons/Money';
import CheckCircle from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import CloseCircle from '@kiwicom/orbit-components/lib/icons/CloseCircle';
import Alert from '@kiwicom/orbit-components/lib/icons/Alert';
import City from '@kiwicom/orbit-components/lib/icons/City';
import MoneyTransferIn from '@kiwicom/orbit-components/lib/icons/MoneyTransferIn';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { Flex, Space, CardData, OrbitLoader } from 'common';
import { formatInterval } from 'utils/dates';
import { FULL_DATE } from 'consts/dates';
import { ROUTE_CONFIG } from 'consts/routes';
import PartnerApprovalForm from './components/PartnerApprovalForm';
import PartnerIdentificationForm from './components/PartnerIdentificationForm';
import { AUTOMATCHED } from 'consts/partnerPayments';
import AlertCircle from '@kiwicom/orbit-components/lib/icons/AlertCircle';

import { PartnerPaymentsState } from 'components/services/partnerPayments';
import usePartnerPayment from 'components/services/partnerPayments/usePartnerPayment';
import { usePolyglot } from 'components/services/i18n';

const PartnerPaymentDetails = ({
  match: {
    params: { partnerPaymentId },
  },
  history,
}) => {
  const [identificationModalOpen, setIdentificationModalOpen] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [error, setError] = useState('');
  const { ignorePartnerPayment, loadPartnerPayment } = useContext(PartnerPaymentsState);
  const polyglot = usePolyglot();
  const partnerPayment = usePartnerPayment(partnerPaymentId);

  const handleIgnore = () => {
    ignorePartnerPayment(partnerPayment.data.id)
      .then(() => {
        loadPartnerPayment(partnerPayment.data.id);
      })
      .catch(err => {
        setError(polyglot.t('partner_payments.error_ignore'));
      });
  };

  const getEntry = () => {
    const leftColumn = [
      {
        label: polyglot.t('partner_payments.entry_reference'),
        value: partnerPayment.data.entryRef,
      },
      {
        label: polyglot.t('partner_payments.amount'),
        value: `${partnerPayment.data.currency} ${partnerPayment.data.amount}`,
      },
    ];
    const rightColumn = [
      {
        label: polyglot.t('partner_payments.booking_date'),
        value: formatInterval(partnerPayment.data.bookingDate, FULL_DATE),
      },
      {
        label: polyglot.t('partner_payments.value_date'),
        value: formatInterval(partnerPayment.data.valueDate, FULL_DATE),
      },
      {
        label: polyglot.t('partner_payments.account_servicer_reference'),
        value: partnerPayment.data.accountServicerRef,
      },
    ];
    return [leftColumn, rightColumn];
  };

  const getTransaction = () => {
    const leftColumn = [
      {
        label: polyglot.t('partner_payments.domain_code'),
        value: partnerPayment.data.domainCode,
      },
      {
        label: polyglot.t('partner_payments.domain_family_code'),
        value: partnerPayment.data.domainFamilyCode,
      },
      {
        label: polyglot.t('partner_payments.domain_sub_family_code'),
        value: partnerPayment.data.domainSubFamilyCode,
      },
    ];
    const rightColumn = [
      {
        label: polyglot.t('partner_payments.proprietary_code'),
        value: partnerPayment.data.proprietaryCode,
      },
      {
        label: polyglot.t('partner_payments.proprietary_issuer'),
        value: partnerPayment.data.proprietaryIssuer,
      },
    ];
    return [leftColumn, rightColumn];
  };

  const getAdditionalEntryInformation = () => {
    return [
      {
        label: polyglot.t('partner_payments.additional_entry_information'),
        value: partnerPayment.data.additionalEntryInformation,
      },
    ];
  };

  const getTransactionalDetails = () => {
    const leftColumn = [
      {
        label: polyglot.t('partner_payments.account_servicer_reference'),
        value: partnerPayment.data.accountServicerRef,
      },
    ];
    const rightColumn = [
      {
        label: polyglot.t('partner_payments.e2e_identification'),
        value: partnerPayment.data.endToEndIdentification,
      },
    ];
    return [leftColumn, rightColumn];
  };

  const getAmountDetails = () => {
    return [
      {
        label: polyglot.t('partner_payments.amount'),
        value: `${partnerPayment.data.currency} ${partnerPayment.data.amount}`,
      },
    ];
  };

  const getRelatedParties = () => {
    const leftColumn = [
      {
        label: polyglot.t('partner_payments.debtor_name'),
        value: partnerPayment.data.debtorName,
      },
      {
        label: polyglot.t('partner_payments.debtor_address'),
        value: partnerPayment.data.debtorAddressType,
      },
      {
        label: polyglot.t('partner_payments.debtor_address'),
        value: partnerPayment.data.debtorAddress,
      },
      {
        label: polyglot.t('partner_payments.debtor_account'),
        value: partnerPayment.data.debtorAccount,
      },
    ];
    const rightColumn = [
      {
        label: polyglot.t('partner_payments.creditor_account'),
        value: partnerPayment.data.creditorAccount,
      },
    ];
    return [leftColumn, rightColumn];
  };

  const getRelatedAgents = () => {
    return [
      {
        label: polyglot.t('partner_payments.debtor_agent_name'),
        value: partnerPayment.data.debtorAgentName,
      },
    ];
  };

  const getRemittanceInformation = () => {
    return [
      {
        label: polyglot.t('partner_payments.unstructured'),
        value: partnerPayment.data.remittance,
      },
    ];
  };

  const getRelatedDates = () => {
    const leftColumn = [
      {
        label: polyglot.t('partner_payments.acceptance_date'),
        value: formatInterval(partnerPayment.data.acceptanceDate, FULL_DATE),
      },
    ];
    const rightColumn = [
      {
        label: polyglot.t('partner_payments.additional_information'),
        value: partnerPayment.data.additionalInfo,
      },
    ];
    return [leftColumn, rightColumn];
  };

  const renderStatusIcon = () => {
    const status = partnerPayment.data.status;
    if (status === 'imported') {
      return (
        <Badge type="warning" icon={<Alert />}>
          {polyglot.t('partner_payments.imported')}
        </Badge>
      );
    } else if (status === 'processed') {
      return (
        <Badge type="success" icon={<CheckCircle />}>
          {polyglot.t('partner_payments.processed')}
        </Badge>
      );
    } else if (status === 'approved') {
      return (
        <Badge type="info" icon={<City />}>
          {polyglot.t('partner_payments.approved')}
        </Badge>
      );
    } else if (status === 'ignored') {
      return (
        <Badge type="critical" icon={<CloseCircle />}>
          {polyglot.t('partner_payments.ignored')}
        </Badge>
      );
    } else if (status === AUTOMATCHED) {
      return (
        <Badge type="info" icon={<AlertCircle />}>
          {polyglot.t('partner_payments.automatch')}
        </Badge>
      );
    }
  };

  const renderCards = () => {
    return (
      <>
        <Card spaceAfter="large">
          <CardSection
            header={
              <Flex>
                <Money />
                <Space left="m">
                  <Heading type="title2">{polyglot.t('partner_payments.entry')}</Heading>
                </Space>
              </Flex>
            }
          >
            <CardData leftColumn={getEntry()[0]} rightColumn={getEntry()[1]} />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.bank_transaction')}
              </Heading>
            </Space>
            <CardData leftColumn={getTransaction()[0]} rightColumn={getTransaction()[1]} />
          </CardSection>
          <CardSection>
            <CardData fullWidthRow={getAdditionalEntryInformation()} />
          </CardSection>
        </Card>
        <Card spaceAfter="large">
          <CardSection
            header={
              <Flex>
                <MoneyTransferIn />
                <Space left="m">
                  <Heading type="title2">
                    {polyglot.t('partner_payments.transactional_details')}
                  </Heading>
                </Space>
              </Flex>
            }
          >
            <CardData
              leftColumn={getTransactionalDetails()[0]}
              rightColumn={getTransactionalDetails()[1]}
            />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.amount_details')}
              </Heading>
            </Space>
            <CardData fullWidthRow={getAmountDetails()} />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.related_parties')}
              </Heading>
            </Space>
            <CardData leftColumn={getRelatedParties()[0]} rightColumn={getRelatedParties()[1]} />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.related_agents')}
              </Heading>
            </Space>
            <CardData fullWidthRow={getRelatedAgents()} />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.remittance_information')}
              </Heading>
            </Space>
            <CardData fullWidthRow={getRemittanceInformation()} />
          </CardSection>
          <CardSection>
            <Space after="l">
              <Heading type="title3" element="h3">
                {polyglot.t('partner_payments.related_dates')}
              </Heading>
            </Space>
            <CardData leftColumn={getRelatedDates()[0]} rightColumn={getRelatedDates()[1]} />
          </CardSection>
        </Card>
      </>
    );
  };

  const renderActions = () => {
    const status = partnerPayment.data.status;
    return (
      <Flex main="end">
        <Button
          type="secondary"
          size="large"
          iconLeft={<ChevronBackwardIcon />}
          onClick={() => history.push(`${ROUTE_CONFIG.PARTNER_PAYMENTS.path}`)}
        >
          {polyglot.t('common.back')}
        </Button>

        {(status === 'imported' || status === 'approved') && (
          <Space left="m">
            <Button
              type="secondary"
              size="large"
              onClick={handleIgnore}
              loading={partnerPayment.ignoring}
            >
              {polyglot.t('common.ignore')}
            </Button>
          </Space>
        )}

        {status !== 'processed' && (
          <Space left="m">
            <Button
              type={status === 'approved' ? 'secondary' : 'primary'}
              size="large"
              onClick={() => setIdentificationModalOpen(true)}
            >
              {polyglot.t('partner_payments.identify')}
            </Button>
          </Space>
        )}

        {status === 'approved' && (
          <Space left="m">
            <Button type="primary" size="large" onClick={() => setApprovalModalOpen(true)}>
              {polyglot.t('common.approve')}
            </Button>
          </Space>
        )}
      </Flex>
    );
  };

  if (partnerPayment.loading) {
    return (
      <Flex main="center">
        <OrbitLoader id="solution-loader" visible />
      </Flex>
    );
  }

  return (
    <>
      {identificationModalOpen && (
        <Modal onClose={() => setIdentificationModalOpen(false)} size="small">
          <ModalSection>
            <PartnerIdentificationForm onClose={() => setIdentificationModalOpen(false)} />
          </ModalSection>
        </Modal>
      )}
      {approvalModalOpen && (
        <Modal onClose={() => setApprovalModalOpen(false)} size="small">
          <ModalSection>
            <PartnerApprovalForm onClose={() => setApprovalModalOpen(false)} />
          </ModalSection>
        </Modal>
      )}
      {error && (
        <Alert type="critical" spaceAfter="medium">
          {error}
        </Alert>
      )}
      <Stack direction="row" justify="end" spaceAfter="normal">
        {partnerPayment?.data && renderStatusIcon()}
      </Stack>
      {partnerPayment.loading ? (
        <Flex main="center">
          <OrbitLoader id="solution-loader" visible />
        </Flex>
      ) : partnerPayment.data ? (
        <>
          {renderCards()}
          {renderActions()}
        </>
      ) : (
        <div>
          <Illustration name="Error" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('common.load_error_message')}
          </Text>
        </div>
      )}
    </>
  );
};

export default withRouter(PartnerPaymentDetails);
