import { useContext, useEffect } from 'react';

import { BookingToolState } from './';

/**
 *
 * @param {Array} ids array of IATA codes to be resolved as cities
 */
const useLocations = ids => {
  const { loadLocations, locations } = useContext(BookingToolState);

  useEffect(() => {
    if (Array.isArray(ids)) {
      ids.forEach(id => {
        if (!locations[id]) {
          loadLocations(id);
        }
      });
    }
  });

  if (!Array.isArray(ids)) {
    return null;
  }

  const result = {};

  ids.forEach(id => (result[id] = locations[id]));

  return result;
};

export default useLocations;
