import styled from 'styled-components';

export const ContentContainer = styled.form`
  margin-top: 32px;
`;

export const PasswordContainer = styled.div`
  margin-top: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
`;
export const SpanWithLineHeight = styled.span`
  line-height: 1.7;
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
