import styled, { css } from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

export const SelectContainer = styled.div`
  margin-left: 8px;
  width: 84px;
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 32px;
`;

export const PageItem = styled.div`
  height: 14px;
  min-width: 14px;
  ${({ current }) =>
    current &&
    css`
      color: ${({ theme }) => theme.orbit.paletteProductNormal};
    `}
`;
