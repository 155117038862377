import { DATE_AND_TIME } from 'consts/dates';
import { UTCToLocalDate } from 'utils/dates';
import format from 'date-fns/format';

const formattedDateToISO = (formattedDate, time) => {
  if (!formattedDate) {
    return null;
  }
  const date = new Date(`${format(new Date(formattedDate), 'MM/dd/yyyy')} ${time}`);

  return date.toISOString();
};

const v1_get_from = commission => ({
  bookedAt: commission.booked_at,
  cooperation: commission.cooperation,
  id: commission.id,
  cpsFlat: commission.cps_flat || 0,
  cpsPercent: commission.cps_percent || 0,
  cpcFlat: commission.cpc_flat || 0,
  cpsPercentMin: commission.cps_percent_min || null,
  cpsPercentMax: commission.cps_percent_max || null,
  partner: commission.partner,
  market: commission.market,
  validFrom: commission.valid_from
    ? format(UTCToLocalDate(commission.valid_from), DATE_AND_TIME)
    : '',
  validTo: commission.valid_to ? format(UTCToLocalDate(commission.valid_to), DATE_AND_TIME) : '',
  contentType: commission.content_type,
  isValid: commission.is_valid,
});

const v1_post_to = commission => {
  return {
    cps_flat: commission.offerType === 'cpsFlat' ? parseFloat(commission.cpsFlat) : 0,
    cps_percent: commission.offerType === 'cpsPercent' ? parseFloat(commission.cpsPercent) : 0,
    cpc_flat: commission.offerType === 'cpcFlat' ? parseFloat(commission.cpcFlat) : 0,
    cps_percent_max: commission?.cpsPercentMax || 0,
    cps_percent_min: commission?.cpsPercentMin || 0,
    booked_at: 'all',
    content_type: commission.contentType,
    cooperation: 'none',
    market: commission.market,
    is_valid: commission.isValid || true,
    valid_from: formattedDateToISO(commission.validFrom, commission.validFromTime),
    valid_to: formattedDateToISO(commission.validTo, commission.validToTime),
  };
};

const v1_put_to = commission => {
  return {
    valid_from: formattedDateToISO(commission.validFrom, commission.validFromTime),
    valid_to: formattedDateToISO(commission.validTo, commission.validToTime),
  };
};

const v1_enable_to = commission => {
  const [validFrom, validFromTime] = commission.validFrom
    ? commission.validFrom.split(' ')
    : [null, null];
  const [validTo, validToTime] = commission.validTo ? commission.validTo.split(' ') : [null, null];

  return {
    valid_from: validFrom ? formattedDateToISO(validFrom, validFromTime) : null,
    valid_to: validTo ? formattedDateToISO(validTo, validToTime) : null,
    is_valid: true,
  };
};
const v1_disable_to = commission => {
  const [validFrom, validFromTime] = commission.validFrom
    ? commission.validFrom.split(' ')
    : [null, null];
  const [validTo, validToTime] = commission.validTo ? commission.validTo.split(' ') : [null, null];

  return {
    valid_from: validFrom ? formattedDateToISO(validFrom, validFromTime) : null,
    valid_to: validTo ? formattedDateToISO(validTo, validToTime) : null,
    is_valid: false,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      to: v1_post_to,
    },
    put: {
      to: { update: v1_put_to, enable: v1_enable_to, disable: v1_disable_to },
    },
  },
};
