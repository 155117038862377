import * as P from 'parsimmon';
import * as R from 'ramda';

import { monthsAbbreviationValues } from 'consts/dates';
import { tripTypesValues, passengerTypes } from 'consts/search';
import { inclusionOrderByDecorator } from './common';

const prefixParser = P.regexp(/(^FS)/, 1).desc('Fare Shop command prefix');

const totalPassengersParser = P.regexp(/[0-9]{1,2}/)
  .times(0, 1)
  .map(res => (res[0] ? Number(res) : 1))
  .desc('Number of total passengers');

const dayParser = P.regexp(/[0-9]{1,2}/)
  .map(Number)
  .desc('Day of the month');

const monthParser = P.alt(...Object.keys(monthsAbbreviationValues).map(P.string)).desc(
  'Three letter month',
);

const locationParser = P.regexp(/[A-Z]{3}/).desc('Three letter location');

const connectionParser = P.regexp(/\.?([A-Z]{3}(-|#)?)/, 1);
const connectionsParser = P.string('X-')
  .then(connectionParser.atMost(2))
  .times(0, 1)
  .map(r => r[0] || null)
  .map(inclusionOrderByDecorator);

const carriersParser = P.regexp(/\/([A-Z]{2}(-|#)?)/, 1)
  .many()
  .map(inclusionOrderByDecorator);

const multipleAirportsParser = P.string('/')
  .then(P.alt(P.regexp(/M\.([A-Z]{3})/, 1), P.string('M')))
  .times(0, 1)
  .map(r => r[0] || null);

const timeParser = P.regexp(/\.(M|N|E|[0-9]{4})/, 1)
  .times(0, 1)
  .map(r => r[0] || null);

const classTypeParser = P.regexp(/-(ECON|BUSNS|PREME|FIRST|PREMF|UPPER)/, 1)
  .times(0, 1)
  .map(r => r[0] || null);

const onlyDirectParser = P.regexp(/\/\.\.DN/)
  .times(0, 1)
  .map(r => !R.isEmpty(r));

const passengersParser = P.seqObj(
  P.regexp(/\+P/),
  ['passengerList', P.regexp(/\.?(([0-9]{1,2})(\*(?:[A-Z]|[0-9]){3})?)/, 1).many()],
  ['pcc', P.regexp(/(\*(([A-Z]|[0-9]){3,}))?/, 2)],
)
  .times(0, 1)
  .map(r => {
    if (!r[0]) {
      return null;
    }

    const { passengerList } = r[0];
    const initial = {
      [passengerTypes.ADULTS]: 0,
      [passengerTypes.CHILDREN]: 0,
      [passengerTypes.INFANTS]: 0,
      unsupported: [],
    };

    const passengersByType = passengerList.reduce((acc, p) => {
      if (p.match(/(CNN|C[0-9]{2})/)) {
        return {
          ...acc,
          [passengerTypes.CHILDREN]: acc[passengerTypes.CHILDREN] + 1,
        };
      } else if (p.match(/INF/)) {
        return {
          ...acc,
          [passengerTypes.INFANTS]: acc[passengerTypes.INFANTS] + 1,
        };
      } else if (p.match(/([0-9]{1,2}$|[0-9]{1,2}\*ADT)/)) {
        return {
          ...acc,
          [passengerTypes.ADULTS]: acc[passengerTypes.ADULTS] + 1,
        };
      } else {
        return {
          ...acc,
          unsupported: [...acc.unsupported, p],
        };
      }
    }, initial);

    return passengersByType;
  });

const routeOptionsParser = P.seqObj(
  ['carriers', carriersParser],
  ['multipleAirports', multipleAirportsParser],
  ['time', timeParser],
  ['classType', classTypeParser],
  ['onlyDirect', onlyDirectParser],
);

const fareShop = P.seqObj(
  ['prefix', prefixParser],
  ['totalPassengers', totalPassengersParser],
  ['from', locationParser],
  ['day', dayParser],
  ['month', monthParser],
  ['to', locationParser],
  ['connections', connectionsParser],
  ['passengers', passengersParser],
  P.string('++').times(0, 1),
  ['options', routeOptionsParser],
).map(r => {
  const { connections, passengers, totalPassengers, ...rest } = r;
  rest.options['connections'] = connections;

  // set adults if no custom passengers options are provided
  const newPassengers = passengers
    ? passengers
    : {
        [passengerTypes.ADULTS]: totalPassengers,
        [passengerTypes.CHILDREN]: 0,
        [passengerTypes.INFANTS]: 0,
      };

  return {
    tripType: tripTypesValues.ONEWAY,
    passengers: newPassengers,
    ...rest,
  };
});

const fareShopReturn = P.seqObj(
  ['prefix', prefixParser],
  ['totalPassengers', totalPassengersParser],
  ['from', locationParser],
  ['day', dayParser],
  ['month', monthParser],
  ['to', locationParser],
  ['connections', connectionsParser],
  ['options', routeOptionsParser],
  ['returnDay', dayParser],
  ['returnMonth', monthParser],
  locationParser,
  ['returnConnections', connectionsParser],
  ['returnOptions', routeOptionsParser],
  ['passengers', passengersParser],
  P.string('++').times(0, 1),
  ['mutualOptions', routeOptionsParser],
).map(r => {
  const { mutualOptions, connections, returnConnections, passengers, totalPassengers, ...rest } = r;
  // group connections per flight
  rest.options['connections'] = connections;
  rest.returnOptions['connections'] = returnConnections;

  // set adults if no custom passengers options are provided
  const newPassengers = passengers
    ? passengers
    : {
        [passengerTypes.ADULTS]: totalPassengers,
        [passengerTypes.CHILDREN]: 0,
        [passengerTypes.INFANTS]: 0,
      };

  // mutual options always override specific flight options
  Object.entries(mutualOptions).forEach(([option, value]) => {
    if (value) {
      rest.options[option] = value;
      rest.returnOptions[option] = value;
    }
  });
  return {
    tripType: tripTypesValues.RETURN,
    passengers: newPassengers,
    ...rest,
  };
});

export default P.alt(fareShopReturn, fareShop);
