import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Text from '@kiwicom/orbit-components/lib/Text';

import Seat from './Seat';
import { AisleLetter, RowGroupItem } from '../index.styled';

const SeatGroup = ({ rowGroup, rowNumber, activeSegmentCode, isLastGroupInRow }) => (
  <>
    <Box align="center" direction="row" display="flex">
      {rowGroup.map((seat, i) => (
        <RowGroupItem key={`SeatGroupItem-${i}`}>
          {seat && <Seat seat={seat} activeSegmentCode={activeSegmentCode} />}
        </RowGroupItem>
      ))}
    </Box>
    {rowNumber && !isLastGroupInRow && (
      <RowGroupItem aisle>
        <AisleLetter>
          <Text size="large" weight="bold" type="secondary">
            {rowNumber}
          </Text>
        </AisleLetter>
      </RowGroupItem>
    )}
  </>
);

export default SeatGroup;
