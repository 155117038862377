import React, { useContext } from 'react';
import * as Yup from 'yup';

import { useForm, FormProvider } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';

import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';
import { COMPANY_TYPES } from 'consts/company';
import { cantContainCyrillic } from 'utils/validation';
import { ZIP_REGEX, countriesWithICO } from 'consts/countries';
import { AuthState } from 'components/services/auth/AuthProvider';
import { businessTypeOptions } from 'consts/register';

import AccountType from './AccountType';
import Form from './Form';
import { VerticalSeparator } from './index.styled';

const EditDetailsModal = ({ onClose, onSubmit, companyDetails }) => {
  const polyglot = usePolyglot();
  const { checkGrantProperties } = useContext(AuthState);

  const canAccountManager = checkGrantProperties(
    'model.company',
    'update',
    'account_manager',
    companyDetails.data.name,
  );

  const canTaxId = checkGrantProperties(
    'model.company',
    'update',
    'tax_id',
    companyDetails.data.name,
  );

  const isBusinessTypeOther = !businessTypeOptions
    .map(o => o.value)
    .includes(companyDetails.data.businessType);

  const form = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(CompanyDetailsSchema),
    defaultValues: {
      accountType: companyDetails.data.type,
      ...companyDetails.data,
      address: {
        ...companyDetails.data.address,
        country: companyDetails.data.address.country.toUpperCase(),
      },
      businessTypeOther: isBusinessTypeOther ? companyDetails.data.businessType : undefined,
      businessType: isBusinessTypeOther ? 'Other' : companyDetails.data.businessType,
    },
    context: { canTaxId },
  });

  const handleSubmit = async () => {
    await form.trigger();

    if (Object.keys(form.errors).length) {
      return;
    }

    onSubmit(form.getValues());
  };

  return (
    <Portal>
      <FormProvider {...form}>
        <Modal size="extraLarge" onClose={onClose}>
          <ModalHeader title={polyglot.t('company.edit_company')} />
          <ModalSection>
            <Stack direction="row" spacing="XXLarge" align="stretch">
              <Form canAccountManager={canAccountManager} canTaxId={canTaxId} />
              <VerticalSeparator />
              <AccountType />
            </Stack>
          </ModalSection>
          <ModalFooter>
            <Stack direction="row" spacing="XSmall" justify="end">
              <Button size="large" type="secondary" onClick={onClose}>
                {polyglot.t('common.back')}
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={handleSubmit}
                disabled={!!Object.keys(form.errors).length}
                loading={companyDetails?.updating}
              >
                {polyglot.t('common.confirm')}
              </Button>
            </Stack>
          </ModalFooter>
        </Modal>
      </FormProvider>
    </Portal>
  );
};

const CompanyDetailsSchema = Yup.object().shape({
  displayName: Yup.string().when('accountType', {
    is: COMPANY_TYPES.PERSON,
    then: Yup.string()
      .required('company.need_to_enter_name')
      .max(64, 'validation.length_exceeded')
      .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic),
    otherwise: Yup.string().nullable(),
  }),
  legalName: Yup.string().when('accountType', {
    is: COMPANY_TYPES.COMPANY,
    then: Yup.string()
      .typeError('company.need_to_enter_legal_name')
      .required('company.need_to_enter_legal_name')
      .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
      .min(3, 'validation.too_short')
      .max(255, 'validation.length_exceeded'),
    otherwise: Yup.string().nullable(),
  }),
  accountType: Yup.string()
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .required('validation.cant_be_empty'),
  address: Yup.object().shape({
    address1: Yup.string().required('company.address_empty').max(255, 'validation.length_exceeded'),
    country: Yup.string().required('company.country_empty'),
    city: Yup.string().required('company.city_empty'),
    zip: Yup.string()
      .required('company.zip_empty')
      .min(2, 'validation.too_short')
      .matches(ZIP_REGEX, 'validation.latin_chars')
      .max(10, 'validation.length_exceeded'),
  }),
  vatId: Yup.string().when(['accountType', 'country'], {
    is: (accountType, country) =>
      accountType === COMPANY_TYPES.COMPANY && !countriesWithICO.includes(country),
    then: Yup.string().typeError('validation.cant_be_empty').required('validation.cant_be_empty'),
  }),
  taxId: Yup.string().when(['accountType', 'czTaxPayer', '$canTaxId'], {
    is: (accountType, czTaxPayer, canTaxId) =>
      accountType === COMPANY_TYPES.COMPANY && czTaxPayer && canTaxId,
    then: Yup.string().typeError('validation.cant_be_empty').required('validation.cant_be_empty'),
    otherwise: Yup.string().nullable(),
  }),
  businessType: Yup.string().required('validation.cant_be_empty'),
  companySize: Yup.string().when('accountType', {
    is: COMPANY_TYPES.COMPANY,
    then: Yup.string().required('validation.cant_be_empty'),
    otherwise: Yup.string().nullable(),
  }),
  url: Yup.string()
    .test('cyrillic', 'validation.no_cyrillic', value => {
      if (!value) {
        return true;
      }
      return cantContainCyrillic(value);
    })
    .url('company.enter_valid_url'),
  majorityStakeholder: Yup.object().when('accountType', {
    is: COMPANY_TYPES.COMPANY,
    then: Yup.object().shape({
      firstName: Yup.string()
        .test('cyrillic', 'validation.no_cyrillic', value => {
          if (!value) {
            return true;
          }
          return cantContainCyrillic(value);
        })
        .max(64, 'validation.length_exceeded')
        .required('validation.cant_be_empty'),
      lastName: Yup.string()
        .test('cyrillic', 'validation.no_cyrillic', value => {
          if (!value) {
            return true;
          }
          return cantContainCyrillic(value);
        })
        .max(64, 'validation.length_exceeded')
        .required('validation.cant_be_empty'),
    }),
    otherwise: Yup.object().nullable(),
  }),
  accountManager: Yup.string().required('validation.cant_be_empty'),
});

export default EditDetailsModal;
