import { useContext, useEffect } from 'react';

import { AccountManagersState } from './index';

const useAccountManagers = () => {
  const accManagerCtx = useContext(AccountManagersState);
  useEffect(() => {
    if (!accManagerCtx.state.data) {
      accManagerCtx.loadAccountManagers();
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    accManagerCtx.state || {
      data: null,
      loading: true,
      error: null,
    }
  );
};

export default useAccountManagers;
