import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Text from '@kiwicom/orbit-components/lib/Text';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import { MMB_SEATING_TABLE_BUTTON_TYPE } from 'components/scenes/Booking/scenes/DepositBooking/consts';

import { SeatingSegmentWrapper } from './index.styled';
import ActionButton from '../ActionButton';
import SeatsStatus from '../SeatsStatus';

const SeatingSegment = ({
  cityFrom,
  cityTo,
  airline,
  id,
  segment,
  isDisabledToClick,
  disabled,
  onOpenDrawer,
  groupedSeats,
  buttonType,
}) => {
  return (
    <SeatingSegmentWrapper>
      <Box
        display="flex"
        direction="row"
        justify="between"
        align="center"
        padding="medium"
        height="64px"
      >
        <Inline align="center" spacing="XSmall">
          <CarrierLogo carriers={[{ code: airline }]} size="medium" />
          <Inline align="center" spacing="XXSmall">
            <Text weight="bold">{cityFrom}</Text>
            <FlightDirectIcon />
            <Text weight="bold">{cityTo}</Text>
          </Inline>
        </Inline>
        <Inline align="center" spacing="large">
          {buttonType !== MMB_SEATING_TABLE_BUTTON_TYPE.ADD && (
            <SeatsStatus groupedSeats={groupedSeats} />
          )}
          <ActionButton
            isDisabledToClick={isDisabledToClick}
            disabled={disabled}
            segment={segment}
            routeId={id}
            onOpenDrawer={onOpenDrawer}
            buttonType={buttonType}
          />
        </Inline>
      </Box>
    </SeatingSegmentWrapper>
  );
};

export default SeatingSegment;
