import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import ClickOutside from '@kiwicom/orbit-components/lib/ClickOutside';

import { RadioWrapper, DropdownIconContainer } from './RadioFilter.styled';

const RadioFilter = ({ options, inputSize, prefix, onSelect, width }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelect = item => {
    if (selectedValue.length === 0) {
      onSelect(item);
      setSelectedValue(item.label);
    } else {
      const isItemSelected = selectedValue === item.label;

      if (!isItemSelected) {
        onSelect(item);
        setSelectedValue(item.label);
      }
    }
    setIsMenuOpen(false);
  };

  const RadioListChoice = option => (
    <ListChoice key={option.value} title={option.label} onClick={() => handleSelect(option)} />
  );

  const renderInput = (
    <RadioWrapper onClick={() => setIsMenuOpen(true)}>
      <InputField
        size={inputSize}
        readOnly
        prefix={prefix}
        value={selectedValue}
        suffix={
          <DropdownIconContainer>
            <ChevronDownIcon />
          </DropdownIconContainer>
        }
      />
    </RadioWrapper>
  );

  return (
    <Popover
      noPadding
      opened={isMenuOpen}
      width={width}
      content={
        <ClickOutside onClickOutside={() => setIsMenuOpen(false)}>
          {options.map(option => RadioListChoice(option))}
        </ClickOutside>
      }
    >
      {renderInput}
    </Popover>
  );
};

RadioFilter.defaultProps = {
  inputSize: 'small',
  width: '240px',
  onSelect: () => {},
};

RadioFilter.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  inputSize: PropTypes.oneOf(['small', 'normal']),
  prefix: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default RadioFilter;
