import React from 'react';
import { retry } from 'utils/functions';

export const Reports = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "reports" */ 'pages/Reports')),
);

export const CustomerSupport = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "customerSupport" */ 'pages/CustomerSupport')),
);

export const Docs = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "docs" */ 'components/scenes/Docs')),
);

export { default as Support } from 'pages/Support';
