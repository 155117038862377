import React from 'react';

const Illustration4 = ({ color1, color2 }) => (
  <svg id="ill4_pref__Layer_10" data-name="Layer 10" viewBox="0 0 2200 1631.3">
    <defs>
      <linearGradient
        id="ill4_pref__linear-gradient_ill4"
        x1={1130.79}
        y1={979.63}
        x2={1130.79}
        y2={1546.81}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" />
        <stop offset={0.04} stopColor="#bac7d5" stopOpacity={0.96} />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill4_pref__linear-gradient_ill4-2"
        x1={-10085.74}
        y1={581.27}
        x2={-9136.36}
        y2={1148.41}
        gradientTransform="matrix(-1 0 0 1 -8705.34 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009882" />
        <stop offset={1} stopColor="#01a891" stopOpacity={0} />
      </linearGradient>
      <style>
        {
          '.ill4_pref__cls-4{fill:#d8e1e8}.ill4_pref__cls-6{fill:#3d3d39}.ill4_pref__cls-7{fill:#262624}.ill4_pref__cls-8{fill:#e5eaee}'
        }
      </style>
    </defs>
    <path
      opacity={0.3}
      fill="url(#ill4_pref__linear-gradient_ill4)"
      d="M1134.94 1134.38l251.92-154.75 225.97 130.46-754.1 436.72-209.98-126.98 486.19-285.45z"
    />
    <path
      fill="url(#ill4_pref__linear-gradient_ill4-2)"
      d="M224.94 1143.28l773.3-427.49-64-37.96 318.1-6.71-32.57 176.07-72.15-42.8-789.66 437.4-133.02-98.51z"
    />
    <path
      fill="#e8edf1"
      d="M1386.86 523.01l169.48-103.26 225.97 135.87-174.6 100.8-220.85-133.41z"
    />
    <path
      className="ill4_pref__cls-4"
      d="M1386.86 523.01v456.62l225.97 130.46-5.12-453.67-220.85-133.41z"
    />
    <path fill="#cad5df" d="M1612.83 1110.09l164.79-100.55 4.69-453.92-174.6 100.8 5.12 453.67z" />
    <path
      className="ill4_pref__cls-6"
      d="M1480.6 240.09l-.01 195.69 169.48 99.27 1-195.26-170.47-99.7z"
    />
    <path
      className="ill4_pref__cls-6"
      d="M1541.39 533.63l56.49 32.62-1.14-63.81-55.35-34.04v65.23z"
    />
    <path
      className="ill4_pref__cls-7"
      d="M1668 329.79v.02l-17.47 10.3v-.13l-169.93-99.89 16.03-9.16L1668 329.79zM1668 329.81l-1.57 196.13-16.36 9.11.46-194.94 17.47-10.3z"
    />
    <path
      className="ill4_pref__cls-6"
      d="M1507.53 522.79l44.99-28.27 101.86 58.67-44.91 28.88-101.94-59.28z"
    />
    <path className="ill4_pref__cls-7" d="M1597.88 566.25l39.54-22.83-39.54-22.77v45.6z" />
    <ellipse
      className="ill4_pref__cls-7"
      cx={1661.03}
      cy={352.16}
      rx={5.84}
      ry={3.3}
      transform="rotate(-66.95 1661.005 352.17)"
    />
    <ellipse
      className="ill4_pref__cls-7"
      cx={1661.03}
      cy={376.36}
      rx={5.84}
      ry={3.3}
      transform="rotate(-66.95 1661.005 376.362)"
    />
    <ellipse
      className="ill4_pref__cls-7"
      cx={1661.03}
      cy={400.55}
      rx={5.84}
      ry={3.3}
      transform="rotate(-66.95 1661 400.561)"
    />
    <ellipse
      className="ill4_pref__cls-7"
      cx={1661.03}
      cy={424.75}
      rx={5.84}
      ry={3.3}
      transform="rotate(-66.95 1661 424.753)"
    />
    <path
      className="ill4_pref__cls-4"
      d="M1614 725.44v427.3c0 2.12 1.62 4.25 4.87 5.87 6.48 3.25 17 3.25 23.48 0 3.25-1.62 4.87-3.75 4.87-5.87v-427.3z"
    />
    <ellipse className="ill4_pref__cls-8" cx={1630.63} cy={725.44} rx={16.61} ry={8.3} />
    <path fill="#f7931e" d="M1034.93 1144.61l579.46-335.95v58.55l-578.5 335.39-.96-57.99z" />
    <path
      className="ill4_pref__cls-4"
      d="M1018.05 1083.42v417.06c0 2.12 1.62 4.24 4.86 5.87 6.49 3.24 17 3.24 23.49 0 3.25-1.63 4.87-3.75 4.87-5.87v-417.06z"
    />
    <ellipse className="ill4_pref__cls-8" cx={1034.66} cy={1083.42} rx={16.61} ry={8.3} />
    <path
      className="ill4_pref__cls-4"
      d="M1329 909.15v417.05c0 2.13 1.62 4.25 4.86 5.87 6.49 3.24 17 3.24 23.49 0 3.25-1.62 4.87-3.74 4.87-5.87V909.15z"
    />
    <ellipse className="ill4_pref__cls-8" cx={1345.58} cy={909.15} rx={16.61} ry={8.3} />
    <path
      d="M1573 759.23c-2.66 56.19-41.86 86.77-87.56 68.19-17.84-7.27-34.11-20.95-47.19-38.31-20.46-27.11-33.14-63.17-31.9-97.55 2-56.38 41.32-86.77 87.66-68 38.16 15.44 68.86 59.33 77 105.75a136.26 136.26 0 011.99 29.92z"
      fill={color1}
    />
    <path
      d="M1565.7 756.25c-2.43 51.25-38.18 79.14-79.85 62.2-16.28-6.64-31.11-19.11-43-35-18.66-24.72-30.22-57.6-29.09-89 1.87-51.41 37.68-79.13 79.94-62 34.8 14.08 62.8 54.1 70.23 96.44a123.93 123.93 0 011.77 27.36z"
      fill={color2}
    />
    <path
      d="M1493.8 776l15.14 13.29v-8.72l-12.94-16.3v-.08a79.24 79.24 0 00.45-10.1l-.08-18.13 39 30.63-.05-10.19-17.32-19.24-7.49-8.3-14.24-15.8-.14-30.33a43.57 43.57 0 00-1.7-12.34c-1.31-4.3-3.26-7.26-5.28-8.33s-4-.19-5.22 2.74c-1 2.25-1.62 5.78-1.6 10.59l.15 30.62-38.38 1.84.05 10.19 38.43 10.71.09 18a92.43 92.43 0 00.54 10.63l.29 1.81-12.6 1.13v8.72l14.53 2.68v-.3l1.72 10.5c.62 3.81 4.38 5.81 5 2.65z"
      fillRule="evenodd"
      fill={color1}
    />
  </svg>
);

export default React.memo(Illustration4);
