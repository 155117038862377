const v1_get_from = (billingCycles, pagination) => {
  return [
    billingCycles.map(billingCycle => ({
      companyName: billingCycle.company_name,
      periodStart: billingCycle.period_start,
      periodEnd: billingCycle.period_end,
      amount: billingCycle.final_balance,
      currency: billingCycle.currency,
      id: billingCycle.id,
      csvReady: billingCycle.csv_ready,
      pdfReady: billingCycle.pdf_ready,
    })),
    {
      pagesCount: pagination.pages,
      perPage: pagination.page_size,
      totalCount: pagination.total,
    },
  ];
};

const v1_post_to = (billingCycle, pdf, csv) => {
  return {
    company_name: billingCycle.companyName,
    period_start: new Date(billingCycle.periodStart).toISOString(),
    period_end: new Date(billingCycle.periodEnd).toISOString(),
    starting_balance: 0,
    final_balance: Number(billingCycle.amount),
    currency: billingCycle.currency,
    ...(pdf ? { pdf } : {}),
    ...(csv ? { csv } : {}),
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      to: v1_post_to,
    },
  },
};
