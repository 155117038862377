import { useMemo } from 'react';
import * as R from 'ramda';

import { tripTypesValues } from 'consts/search';
import { getMulticityTrips } from 'utils/search';
import { useSearch } from 'components/services/search';

const { ONEWAY, RETURN, MULTICITY, NOMAD } = tripTypesValues;

const noValues = [];

const useFromToLocations = () => {
  const { tabData } = useSearch();
  const locationsFrom = tabData.searchbar[tabData.tripType].locationsFrom || noValues;
  const locationsTo = tabData.searchbar[tabData.tripType].locationsTo || noValues;
  const nomadTrips = tabData.searchbar[NOMAD].trips || noValues;
  const multicityTrips = getMulticityTrips(tabData);

  const returnLocations = useMemo(
    () => locationsFrom.concat(locationsTo),
    [locationsFrom, locationsTo],
  );

  const multicityLocations = useMemo(
    () =>
      multicityTrips.reduce(
        (acc, item) => acc.concat(item.locationsFrom).concat(item.locationsTo),
        [],
      ),
    [multicityTrips],
  );

  const nomadLocations = useMemo(() => {
    const tripsLocations = nomadTrips.reduce((acc, item) => acc.concat(item.locations), []);
    return R.uniqBy(
      location => location.value,
      tripsLocations.concat(locationsFrom).concat(locationsTo),
    );
  }, [nomadTrips, locationsFrom, locationsTo]);

  if ([ONEWAY, RETURN].includes(tabData.tripType)) {
    return returnLocations;
  }

  if (tabData.tripType === MULTICITY) {
    return multicityLocations;
  }

  if (tabData.tripType === NOMAD) {
    return nomadLocations;
  }

  return [];
};

export default useFromToLocations;
