import React from 'react';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Card from '@kiwicom/orbit-components/lib/Card';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';

import { usePolyglot } from 'components/services/i18n';
import useInvoices from 'components/services/mmb/useInvoices';

const InvoiceModal = ({ onClose, bid }) => {
  const { data: invoices } = useInvoices(bid);
  const polyglot = usePolyglot();

  return (
    <Modal onClose={onClose} dataTest="invoice-modal">
      <ModalHeader
        title={polyglot.t('mmb.booking_details.invoice_download')}
        illustration={<Illustration name="Money" spaceAfter="medium" size="extraSmall" />}
        description={polyglot.t('mmb.booking_details.invoice_modal.all_invoices')}
      />
      <ModalSection>
        {invoices.map((i, j) => (
          <Card
            key={i.url}
            title={i.type}
            spaceAfter="medium"
            actions={
              <Button size="small" href={i.url} external dataTest={`download-invoice-${j}`}>
                {polyglot.t('common.download')}
              </Button>
            }
          />
        ))}
      </ModalSection>
    </Modal>
  );
};

export default React.memo(InvoiceModal);
