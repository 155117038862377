import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { PageContainer } from 'common';
import { AuthState } from 'components/services/auth/AuthProvider';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import { useIsMounted } from 'utils/hooks';

import CompanyDetails from './scenes/CompanyDetails';
import Solutions from './scenes/Solutions';
import SolutionDetails from './scenes/SolutionDetails';
import BillingCycles from './scenes/BillingCycles';
import Users from './scenes/Users';
import Logs from './scenes/Logs';
import AuditLogs from './scenes/AuditLogs';
import Configuration from './scenes/Configuration';
import TabNavigation from './components/TabNavigation';
import IPWhitelist from './scenes/IPWhitelist';
import SmartpointConfiguration from './scenes/SmartpointConfiguration';

const NEEDS = [
  'model.application',
  'model.bank_account',
  'model.billing_cycle',
  'model.commission',
  'model.company',
  'model.company_pcc',
  'model.company_special_app',
  'model.deposit_account',
  'model.special_app',
  'model.user',
  'feature.company_log',
  'acl.roles',
  'special_app.bidviewer',
  'special_app.smartpass',
  'feature.demand_dashboard',
  'feature.destination_dashboard',
];

const getPageMaxWidth = pathname => {
  if (pathname.includes('/audit-logs')) {
    return 1048;
  }

  return undefined;
};

const Company = () => {
  const { companyName } = useRouteMatch().params;
  const { loadingBatch, batchCan, checkAccess, checkedGrants } = useContext(AuthState);
  const user = useCurrentUser();
  const [nextGenCanAccessConfiguration, setNextGenCanAccessConfiguration] = useState({
    value: false,
    loading: true,
  });
  const location = useLocation();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!checkedGrants?.[companyName]) {
      batchCan(user, NEEDS, companyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, checkedGrants]);

  useEffect(() => {
    checkAccess('model.company_special_app', ['read', 'read_assigned'], companyName).then(res => {
      if (isMounted()) {
        setNextGenCanAccessConfiguration({ value: res, loading: false });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedGrants?.[companyName]?.['model.company']]);

  if (loadingBatch || nextGenCanAccessConfiguration.loading) {
    return <Loading type="pageLoader" />;
  }
  return (
    <>
      <TabNavigation />
      <PageContainer moveDown maxWidth={getPageMaxWidth(location.pathname)}>
        <Switch>
          <Route exact path="/companies/:companyName" component={CompanyDetails} />
          <Route exact path="/companies/:companyName/solutions" component={Solutions} />
          <Route
            exact
            path="/companies/:companyName/solutions/:affilId"
            component={SolutionDetails}
          />
          <Route path="/companies/:companyName/users" component={Users} />
          <Route path="/companies/:companyName/logs" component={Logs} />
          <Route path="/companies/:companyName/audit-logs" component={AuditLogs} />
          {nextGenCanAccessConfiguration.value && (
            <Route path="/companies/:companyName/configuration" component={Configuration} />
          )}
          <Route path="/companies/:companyName/ip-allowlist" component={IPWhitelist} />
          <Route
            path="/companies/:companyName/smartpoint-configuration"
            component={SmartpointConfiguration}
          />
          <Route path="/companies/:companyName/billing-cycle" component={BillingCycles} />
        </Switch>
      </PageContainer>
    </>
  );
};

export default Company;
