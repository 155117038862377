import React, { useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import CalendarIcon from '@kiwicom/orbit-components/lib/icons/Calendar';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import { ClickOutside, InputButton } from '..';
import { RemoveContainer, DatePickerGlobals, OverlayContainer } from './DayPickerButton.styled';
import 'react-day-picker/lib/style.css';

const OverlayComponent = ({ children, dayPickerRef, ...props }) => {
  return (
    <ClickOutside
      onClick={() => {
        dayPickerRef.current.hideDayPicker();
      }}
    >
      <OverlayContainer {...props}>{children}</OverlayContainer>
    </ClickOutside>
  );
};

const DayPickerButton = ({
  id,
  label,
  value,
  disabledDays,
  onSelect,
  onReset,
  size,
  iconColor,
  align,
  prefix,
  disabled,
  hideOnDayClick,
  onDayChange,
  ...datePickerProps
}) => {
  const dayPickerRef = useRef(null);

  const icon =
    onReset && value ? (
      <RemoveContainer onClick={onReset} data-test="day-picker-remove-button">
        <RemoveIcon customColor="#bac7D5" />
      </RemoveContainer>
    ) : (
      <CalendarIcon customColor={iconColor || '#bac7D5'} size={size} />
    );

  return (
    <>
      <DatePickerGlobals />
      <DayPickerInput
        overlayComponent={props => <OverlayComponent {...props} dayPickerRef={dayPickerRef} />}
        dayPickerProps={{
          className: 'Selectable',
          onDayClick: onSelect,
          disabledDays: disabledDays,
          modifiersStyles: {
            disabled: {
              pointerEvents: 'none',
            },
          },
          ...datePickerProps,
        }}
        ref={dayPickerRef}
        hideOnDayClick={hideOnDayClick}
        onDayChange={onDayChange}
        component={props => (
          <InputButton
            {...props}
            placeholder={null}
            id={label}
            prefix={prefix}
            label={label}
            value={value}
            icon={icon}
            size={size}
            align={align}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

DayPickerButton.defaultProps = {
  hideOnDayClick: false,
};

export default DayPickerButton;
