import React, { useReducer, useEffect } from 'react';

import api from 'utils/api';
import ensurePCCMapper from 'mappers/nextGen/companies/{company_name}/pccs/ensure/{pcc_code}/_';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';
import useApiProducts from 'components/services/products/useApiProducts';

const companyPCCSolutionsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_PCC_SOLUTIONS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_PCC_SOLUTIONS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case 'LOAD_COMPANY_PCC_SOLUTIONS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

const updatePCCSolutionReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PCC_SOLUTION':
      return {
        ...state,
        [action.pccAppId]: {
          ...state[action.pccAppId],
          loading: true,
          error: null,
        },
      };
    case 'UPDATE_PCC_SOLUTION_SUCCESS':
      return {
        ...state,
        [action.pccAppId]: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    case 'UPDATE_PCC_SOLUTION_FAIL':
      return {
        ...state,
        [action.pccAppId]: {
          ...state[action.pccAppId],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

const ensurePCCReducer = (state, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case 'LOAD_PCC_SOLUTION':
      return {
        data: null,
        loading: true,
        error: null,
      };
    case 'LOAD_PCC_SOLUTION_SUCCESS':
      return {
        data: {
          affilId: payload.affilId,
          consumerKey: payload.consumerKey,
          productType: payload.productType,
        },
        loading: false,
        error: null,
      };
    case 'LOAD_PCC_SOLUTION_FAIL':
      return {
        data: null,
        loading: false,
        error,
      };
    default:
      return state;
  }
};

export const SmartpointState = React.createContext({
  loadCompanyPCCApps: companyName => companyName,
  updatePCCApp: (pccId, body) => [pccId, body],
  ensurePCCReducer: (pccCode, productType) => [pccCode, productType],
});

const SmartpointProvider = ({ children }) => {
  const usersCompanyName = useUsersCompanyName();
  const { data: apiProducts } = useApiProducts();

  const [companyPCCApps, dispatchCompanyPCCApps] = useReducer(companyPCCSolutionsReducer, {});
  const [updatePCCAppState, dispatchUpdatePCCApp] = useReducer(updatePCCSolutionReducer, {});
  const [ensurePCCState, dispatchEnsurePCCState] = useReducer(ensurePCCReducer, {});

  const loadCompanyPCCApps = async companyName => {
    dispatchCompanyPCCApps({ type: 'LOAD_COMPANY_PCC_SOLUTIONS', companyName });
    try {
      const res = await api.v1.get(`companies/${companyName}/pccs`);

      dispatchCompanyPCCApps({
        type: 'LOAD_COMPANY_PCC_SOLUTIONS_SUCCESS',
        companyName,
        payload: res.data.data,
      });
    } catch (error) {
      dispatchCompanyPCCApps({
        type: 'LOAD_COMPANY_PCC_SOLUTIONS_FAIL',
        companyName,
        payload: error,
      });
      return Promise.reject(error);
    }
  };

  const updatePCCApp = async (pccAppId, body) => {
    dispatchUpdatePCCApp({ type: 'UPDATE_PCC_SOLUTION', pccAppId });

    try {
      const res = await api.v1.put(`pccs/${pccAppId}`, body);

      dispatchUpdatePCCApp({
        type: 'UPDATE_PCC_SOLUTION_SUCCESS',
        pccAppId,
        payload: res.data.data,
      });
    } catch (error) {
      dispatchUpdatePCCApp({ type: 'UPDATE_PCC_SOLUTION_FAIL', pccAppId, payload: error });
      return Promise.reject(error);
    }
  };

  const ensurePCC = async (pccCode, productType) => {
    dispatchEnsurePCCState({ type: 'LOAD_PCC_SOLUTION' });

    try {
      const res = await api.v1.get(
        `companies/${usersCompanyName}/pccs/ensure/${pccCode}?api_product=${productType}`,
      );

      const data = ensurePCCMapper.v1.get.from(res.data.application, apiProducts);

      dispatchEnsurePCCState({
        type: 'LOAD_PCC_SOLUTION_SUCCESS',
        payload: data,
      });
    } catch (error) {
      dispatchEnsurePCCState({ type: 'LOAD_PCC_SOLUTION_FAIL', error });
    }
  };

  useEffect(() => {
    if (usersCompanyName) {
      loadCompanyPCCApps(usersCompanyName);
    }
  }, [usersCompanyName]);

  const value = {
    companyPCCApps,
    updatePCCAppState,
    ensurePCCState,
    loadCompanyPCCApps,
    updatePCCApp,
    ensurePCC,
  };

  return <SmartpointState.Provider value={value}>{children}</SmartpointState.Provider>;
};

export default SmartpointProvider;
