import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { Flex, SelectLanguage } from 'common';
import useMMB from 'components/services/mmb/useMMB';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';
import { usePolyglot } from 'components/services/i18n';

import { MMBIframe } from './index.styled';
import { useLocalStorage } from 'components/services/localStorage';
import * as cookies from '@kiwicom/cookies';

const BookingDetails = ({ affilid }) => {
  const polyglot = usePolyglot();
  const [customMMBUrl, setCustomMMBUrl] = useState('');
  const [language] = useLocalStorage('mmb_language', {
    label: 'English',
    value: 'en',
    flag: 'gb',
  });
  const [previousLanguage, setPreviousLanguage] = useState('/en/');
  const { loadingToken, error, mmbUrl, mmbToken } = useMMB();
  const { bid } = useRouteMatch().params;
  const { iframes } = useBookingDetailsIframes();
  const history = useHistory();

  const changeLanguage = useCallback(
    lang => {
      setCustomMMBUrl(customMMBUrl.replace(previousLanguage, `/${lang}/`));
      setPreviousLanguage(`/${lang}/`);
    },
    [customMMBUrl, previousLanguage],
  );

  useEffect(() => {
    if (mmbUrl) {
      const mmbUrlParts = mmbUrl?.split('/account');
      const customUrl = `${mmbUrlParts[0]}/manage/${bid}${mmbUrlParts[1]}`;

      setCustomMMBUrl(customUrl.replace('/en/', `/${language.value}/`));
      setPreviousLanguage(`/${language.value}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mmbUrl, bid]);

  useEffect(() => {
    if (!iframes?.length || !iframes?.includes(parseInt(bid, 10))) {
      history.replace('/manage-my-bookings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframes]);

  useEffect(() => {
    if (mmbToken) {
      cookies.save('ua_session_token', mmbToken, { domain: 'kiwi.com' });
    }
  }, [mmbToken]);

  useEffect(() => {
    return () => {
      cookies.remove('ua_session_token');
    };
  }, []);

  if (loadingToken && !mmbUrl && !mmbToken && !affilid) {
    return <Loading type="pageLoader" />;
  }

  if (error || (!mmbUrl && !mmbToken && !affilid)) {
    return (
      <Alert type="critical" icon>
        {polyglot.t('mmb.connection_error')}
      </Alert>
    );
  }

  return (
    <>
      <Heading element="h1" type="title1">
        <Flex main="spaceBetween" cross="center">
          {polyglot.t('common.manage_my_bookings')}
          <SelectLanguage onChange={lang => changeLanguage(lang)} />
        </Flex>
      </Heading>
      <MMBIframe
        title="Manage my Booking"
        src={`${customMMBUrl}&ui=tequila&platform=tequila&iframe=${window.location.origin}&partner=${affilid}`}
      />
    </>
  );
};

export default BookingDetails;
