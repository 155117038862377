import styled from 'styled-components';
import { InputButton } from 'common';

export const PeriodInputButton = styled(InputButton)`
  width: 280px;
  margin-right: 8px;
`;

export const CompareInputButton = styled(PeriodInputButton)`
  margin-top: 4px;
`;
