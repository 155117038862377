import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';

import logo_bgy from '../../../images/logo_bgy.jpg';
import logo_bud from '../../../images/logo_bud.jpg';
import logo_nce from '../../../images/logo_nce.jpg';
import logo_vce from '../../../images/logo_vce.jpg';

const Wrapper = styled.div`
  max-width: 796px;
`;
const TextWrapper = styled.div`
  max-width: 348px;
`;
const ColoredTextWrapper = styled.div`
  & p {
    text-decoration: none;
    cursor: auto;
    color: ${({ theme }) => theme.orbit.paletteProductDark};
  }
`;

const SalesPitch = ({ companyName }) => {
  const polyglot = usePolyglot();

  return (
    <Wrapper>
      <Card>
        <CardSection>
          <Stack direction="row" justify="between" spaceAfter="large">
            <TextWrapper>
              <Heading spaceAfter="medium" type="title3">
                {polyglot.t('smart_pass.sales_pitch_title')}
              </Heading>
              <Text size="large" spaceAfter="large">
                {polyglot.t('smart_pass.sales_pitch.description')}
              </Text>
            </TextWrapper>
            <Illustration name="PriorityBoarding" />
          </Stack>
          <Separator spaceAfter="large" />
          <Stack direction="row" spaceAfter="large">
            <ColoredTextWrapper>
              <Stack spacing="small" direction="column">
                <Heading type="title3" spaceAfter="large">
                  {polyglot.t('smart_pass.sales_pitch.benefits.title_1')}
                </Heading>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_1')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_2')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_3')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_4')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_5')}</Text>
              </Stack>
            </ColoredTextWrapper>
            <ColoredTextWrapper>
              <Stack spacing="small" direction="column">
                <Heading type="title3" spaceAfter="large">
                  {polyglot.t('smart_pass.sales_pitch.benefits.title_2')}
                </Heading>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_6')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_7')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_8')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_9')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_10')}</Text>
                <Text>{polyglot.t('smart_pass.sales_pitch.benefits.benefit_11')}</Text>
              </Stack>
            </ColoredTextWrapper>
          </Stack>
          <Separator spaceAfter="large" />
          <Text size="large" spaceAfter="medium" type="secondary" weight="bold">
            {polyglot.t('smart_pass.sales_pitch.partners.title')}
          </Text>
          <Stack direction="row" spaceAfter="medium">
            <img src={logo_bgy} alt="Milan Bergamo Airport" />
            <img src={logo_bud} alt="Budapest Airport" />
            <img src={logo_nce} alt="Nice Cote D'Azur" />
            <img src={logo_vce} alt="Venezia Airport" />
          </Stack>
          <Separator spaceAfter="medium" />
          <Stack direction="row" justify="end" spaceAfter="none">
            <Link to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass/new`}>
              <Button>{polyglot.t('smart_pass.sales_pitch.create_smartpass')}</Button>
            </Link>
          </Stack>
        </CardSection>
      </Card>
    </Wrapper>
  );
};

export default SalesPitch;
