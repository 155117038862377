const v1_post_to = reportAliasId => ({
  report_alias_id: Number(reportAliasId),
});

const v1_post_from = reportInstance => ({
  reportAliasId: reportInstance.report_alias_id,
  reportStatus: reportInstance.report_status,
  reportId: reportInstance.id,
});

const v2_post_to = (reportAliasId, reportScheduleId) => ({
  report_alias_id: Number(reportAliasId),
  ...(typeof reportScheduleId === 'number' && {
    report_schedule_id: Number(reportScheduleId),
    disable_dispatch: true,
  }),
});

const v2_post_from = reportInstance => ({
  reportAliasId: reportInstance.report_alias_id,
  reportStatus: reportInstance.report_status,
  reportId: reportInstance.id,
});

export default {
  v1: {
    post: {
      from: v1_post_from,
      to: v1_post_to,
    },
  },
  v2: {
    post: {
      to: v2_post_to,
      from: v2_post_from,
    },
  },
};
