import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import NewWindowIcon from '@kiwicom/orbit-components/lib/icons/NewWindow';

import { useLocalStorage } from 'components/services/localStorage';
import {
  Article,
  QuickGuide,
  NewsAPIArticle,
  NewsArticle,
  SwaggerDocumentation,
} from 'components/services/resources/resolvers';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import * as Intercom from 'utils/intercom';
import * as GA from 'utils/ga';

import { ContextualResourcesState } from '../ContextualResourcesProvider';

const TitleWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ${({ inPopover }) =>
    inPopover &&
    css`
      &:last-of-type {
        margin-bottom: 0;
      }
    `}
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.orbit.paletteProductDarker};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  svg {
    margin-left: 8px;
  }
`;

const Item = styled.a`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.orbit.colorTextPrimary};
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: color 150ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.orbit.colorTextLinkSecondaryHover};
    text-decoration: underline;
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const getBasePath = r => {
  if (r instanceof Article) {
    return ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE.toPath;
  } else if (r instanceof QuickGuide) {
    return ROUTE_CONFIG.DOCS_FAQ_SINGLE.toPath;
  } else if (r instanceof NewsAPIArticle) {
    return ROUTE_CONFIG.DOCS_NEWS_API_SINGLE.toPath;
  } else if (r instanceof NewsArticle) {
    return ROUTE_CONFIG.DOCS_NEWS_SINGLE.toPath;
  } else if (r instanceof SwaggerDocumentation) {
    return ROUTE_CONFIG.DOCS_TEQUILA_API_SINGLE.toPath;
  }

  return '';
};

const getResourcePath = r => {
  return r.toPath || null;
};

const Group = ({ title, resources, inPopover }) => {
  if (!resources || resources.length === 0) {
    return null;
  }

  const baseUrl = `${window.location.origin}/portal`;
  const basePath = `${baseUrl}${getBasePath(resources[0])}`;

  const handleTrack = resource => {
    Intercom.trackEvent('Clicked Help', {
      section: title,
      resource: resource || title,
    });

    GA.fireHelpCardEvent(title, resource || title);
  };

  return (
    <Container inPopover={inPopover}>
      <Title href={basePath} target="_blank" onClick={() => handleTrack()}>
        {title}
        <NewWindowIcon size="small" />
      </Title>
      {resources.map(r => {
        const href = `${baseUrl}${getResourcePath(r)}`;
        return (
          <Item
            key={r.system.id}
            href={href}
            target="_blank"
            onClick={() => handleTrack(r.system.name)}
          >
            {r.system.name}
          </Item>
        );
      })}
    </Container>
  );
};

const ResourceGroup = ({ inPopover }) => {
  const polyglot = usePolyglot();
  const { title, data, hasResources } = useContext(ContextualResourcesState);
  const [, setStaticHelp] = useLocalStorage('static_help', { shown: true });

  const handleClose = () => {
    setStaticHelp({ shown: false });
  };

  if (!hasResources) {
    return null;
  }

  return (
    <>
      <TitleWrapper>
        {inPopover ? (
          <Badge type="dark">{title}</Badge>
        ) : (
          <>
            <Heading type="title4">{title}</Heading>
            <Tooltip
              content={polyglot.t('resources.contextual_close_tooltip')}
              preferredPosition="left"
            >
              <CloseWrapper onClick={handleClose}>
                <CloseIcon onClick={handleClose} />
              </CloseWrapper>
            </Tooltip>
          </>
        )}
      </TitleWrapper>

      <Group title="Documentation" resources={data['User guide']} inPopover={inPopover} />
      <Group title="FAQ" resources={data.FAQ} inPopover={inPopover} />

      {Object.keys(data)
        .filter(k => k !== 'User guide' && k !== 'FAQ')
        .map(key => (
          <Group key={key} resources={data[key]} title={key} inPopover={inPopover} />
        ))}

      {/* 
        Will be added later on
        {!onboardingState.done && (
          <Container inPopover={inPopover}>
            <Title href="/portal/getting-started">Onboarding</Title>
            <Item href="/portal/getting-started">Take a tour</Item>
          </Container>
      )} */}
      {!inPopover && (
        <IllustrationWrapper>
          <Illustration name="AirHelp" size="small" />
        </IllustrationWrapper>
      )}
    </>
  );
};

export default ResourceGroup;
