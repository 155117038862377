import * as React from 'react';

const Illustration2 = ({ color1, color2 }) => (
  <svg id="ill2_pref__Layer_10" data-name="Layer 10" viewBox="0 0 2200 1300">
    <defs>
      <linearGradient
        id="ill2_pref__linear-gradient_ill2"
        x1={-1602.12}
        y1={1400.1}
        x2={-1602.12}
        y2={660.47}
        gradientTransform="matrix(-1 .06 .06 1 -741.61 23.61)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={0.56} stopColor="#fff" />
        <stop offset={0.99} />
      </linearGradient>
      <linearGradient
        id="ill2_pref__linear-gradient_ill2-2"
        x1={-5791.73}
        y1={376.22}
        x2={-4446.09}
        y2={1180.09}
        gradientTransform="translate(6171.35)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009882" />
        <stop offset={1} stopColor="#01a891" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill2_pref__linear-gradient_ill2-3"
        x1={1194.65}
        y1={676.19}
        x2={1883.42}
        y2={1025.15}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" />
        <stop offset={0.04} stopColor="#bac7d5" stopOpacity={0.96} />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="ill2_pref__linear-gradient_ill2-4"
        x1={1367.86}
        y1={1160.68}
        x2={347.63}
        y2={626.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#bac7d5" stopOpacity={0} />
        <stop offset={0.5} stopColor="#bac7d5" />
        <stop offset={1} stopColor="#bac7d5" stopOpacity={0} />
      </linearGradient>
      <filter
        id="ill2_pref__luminosity-noclip"
        x={119.47}
        y={-12591.28}
        width={1605.37}
        height={32766}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="SourceGraphic" in2="bg" />
      </filter>
      <filter
        id="ill2_pref__luminosity-noclip-2"
        x={119.47}
        y={558.13}
        width={1605.37}
        height={752.31}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodColor="#fff" result="bg" />
        <feBlend in="SourceGraphic" in2="bg" />
      </filter>
      <style>
        {
          '.ill2_pref__cls-6{fill:#cad5df}.ill2_pref__cls-12{fill:#e8e8e8}.ill2_pref__cls-13{fill:#f0f0f0}.ill2_pref__cls-14{fill:#e5eaee}.ill2_pref__cls-15{fill:#f7931e}'
        }
      </style>
      <mask
        id="ill2_pref__mask-2"
        x={119.47}
        y={558.13}
        width={1605.37}
        height={752.31}
        maskUnits="userSpaceOnUse"
      >
        <g mask="url(#ill2_pref__mask)" filter="url(#ill2_pref__luminosity-noclip-2)">
          <path
            style={{
              mixBlendMode: 'multiply',
            }}
            fill="url(#ill2_pref__linear-gradient_ill2)"
            d="M243.99 558.13l1480.85 736.62-252.75 15.7L119.47 637.7l124.52-79.57z"
          />
        </g>
      </mask>
    </defs>
    <path fill="#ededed" d="M1156.7 648.74l-37.93 18.47-57.51-28.18 57.51-38.23 37.93 47.94z" />
    <path fill="#e8edf1" d="M1118.77 252.59l-181.05-89.73 38.75-19.48 180.23 89.9-37.93 19.31z" />
    <path
      fill="#d8e1e8"
      d="M1126.93 663.24l2.71-416.19-10.87 5.54-33.06-16.41-147.99-73.32-3.81 410.9 184.86 93.54 8.16-4.06z"
    />
    <path className="ill2_pref__cls-6" d="M1118.77 667.3V252.59l37.93-19.31v415.46l-37.93 18.56z" />
    <path
      fill="url(#ill2_pref__linear-gradient_ill2-2)"
      d="M2025 1160.04L818.54 562.63l66.71-39.57-331.58-6.99 33.95 183.52 75.21-44.61 1205.52 597.46 156.65-92.4z"
    />
    <path
      d="M1990.93 1050.13l-42.31 18L1118.9 667.3l37.93-18.56z"
      opacity={0.3}
      fill="url(#ill2_pref__linear-gradient_ill2-3)"
      fillRule="evenodd"
    />
    <g mask="url(#ill2_pref__mask-2)" opacity={0.5}>
      <path
        fill="url(#ill2_pref__linear-gradient_ill2-4)"
        d="M257.49 564.6l1375.85 679.35-69.24 34.41L172.83 591.14l84.66-26.54z"
      />
    </g>
    <path
      className="ill2_pref__cls-12"
      d="M1347.2 964.33v285.11c0 1.46 1.11 2.91 3.33 4a20.17 20.17 0 0016 0c2.22-1.11 3.33-2.56 3.33-4V964.33z"
    />
    <ellipse className="ill2_pref__cls-13" cx={1358.55} cy={964.33} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-12"
      d="M1031.43 813.69v285.11c0 1.45 1.11 2.91 3.33 4a20.28 20.28 0 0016.06 0c2.21-1.11 3.32-2.57 3.32-4V813.69z"
    />
    <ellipse className="ill2_pref__cls-13" cx={1042.79} cy={813.69} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-6"
      d="M1347.2 964.33v285.12c0 1.45 1.11 2.9 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4V964.33z"
    />
    <ellipse className="ill2_pref__cls-14" cx={1358.55} cy={964.33} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-15"
      d="M1347.2 1063.69l-296.48-148.81v-47.96l296.48 148.81v47.96z"
    />
    <path
      className="ill2_pref__cls-6"
      d="M744.48 659.22v292.12c0 1.45 1.11 2.91 3.33 4a20.17 20.17 0 0016.05 0c2.22-1.1 3.33-2.56 3.33-4V659.22z"
    />
    <ellipse className="ill2_pref__cls-14" cx={755.83} cy={659.22} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-6"
      d="M1031.43 814v285.12c0 1.45 1.11 2.9 3.33 4a20.17 20.17 0 0016 0c2.22-1.11 3.33-2.56 3.33-4V814z"
    />
    <ellipse className="ill2_pref__cls-14" cx={1042.78} cy={814.03} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-15"
      d="M1031.43 906.79L763.14 772.01v-47.95l268.29 134.77v47.96z"
    />
    <path
      className="ill2_pref__cls-12"
      d="M1710.63 657.14v285.11c0 1.45 1.1 2.91 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.1 3.32-2.56 3.32-4V657.14z"
    />
    <ellipse className="ill2_pref__cls-13" cx={1721.98} cy={657.14} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-12"
      d="M1394.86 506.84V792c0 1.45 1.11 2.91 3.33 4a20.17 20.17 0 0016 0c2.22-1.1 3.33-2.56 3.33-4V506.84z"
    />
    <ellipse className="ill2_pref__cls-13" cx={1406.21} cy={506.84} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-6"
      d="M1710.63 657.48V942.6c0 1.45 1.1 2.9 3.32 4a20.2 20.2 0 0016.06 0c2.22-1.11 3.32-2.56 3.32-4V657.48z"
    />
    <ellipse className="ill2_pref__cls-14" cx={1721.98} cy={657.48} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-15"
      d="M1710.63 753.79l-293.06-146.86v-45.76l293.06 146.87v45.75z"
    />
    <path
      className="ill2_pref__cls-6"
      d="M1394.86 506.92V792c0 1.46 1.11 2.91 3.32 4a20.28 20.28 0 0016.06 0c2.22-1.11 3.33-2.56 3.33-4V506.92z"
    />
    <ellipse className="ill2_pref__cls-14" cx={1406.21} cy={506.92} rx={11.35} ry={5.68} />
    <path
      className="ill2_pref__cls-15"
      d="M1394.86 597.48l-263.62-131.87v-45.76l263.62 131.87v45.76z"
    />
    <path d="M1097.66 481.82l-136.05-67.27 1.43-192.09 136.29 66.9-1.67 192.46z" fill={color2} />
    <path
      className="ill2_pref__cls-16"
      fill={color1}
      d="M1058.25 430.8a12.93 12.93 0 00-4.07-8.17l-.37-.39a16.6 16.6 0 002.2-.78c3.13-1.64 2.88-8.35 1.3-11.69a15.27 15.27 0 00-5.31-6.11c-4.43-3.06-8.94-4.51-13.43-5.83-.78-.23-1.14.19-1.21 1.09v.94l-.33 23.07c0 3.18-.09 6.35-.12 9.54a2.14 2.14 0 001.27 2.29c3 1.52 6 3.07 8.95 4.31a18.77 18.77 0 005.23 1.22 4.73 4.73 0 005.33-3.16 13.5 13.5 0 00.56-6.33zm-17.08-16.49c0-3.68.11-7.36.15-11 0-.43 0-.79.53-.56 2.44 1.05 4.89 2 7.32 3.15a12.57 12.57 0 012.54 1.73 7.17 7.17 0 012.7 5.3 15.19 15.19 0 01-.19 3.27c-.36 2-2 2.9-4.19 2.31a33.47 33.47 0 01-3.38-1.21c-1.75-.71-3.5-1.48-5.25-2.22-.05 0-.09-.06-.21-.15s-.02-.44-.02-.62zm12.89 19.3a2.85 2.85 0 01-3.65 2.41 27.23 27.23 0 01-4.85-1.45c-1.51-.57-3-1.39-4.62-2.14l.2-13.66c.08 0 .12-.06.15 0 3 1.33 6 2.46 8.89 4.06a8.3 8.3 0 014.15 7.44 15.34 15.34 0 01-.27 3.34zM1012.43 419.93q-2.08-9.74-4.19-19.45c-1.15-5.32-2.28-10.62-3.49-15.92a6.93 6.93 0 00-3.82-4.86c-1.94-.95-3.56-.91-4.53.72a6.74 6.74 0 00-.6 1.56q-3.13 10.32-6.24 20.6c-.67 2.19-1.34 4.37-2 6.56-.27.88 0 1.4.91 1.82l1 .42.55.25c1.31.57 1.39.53 1.76-.74.84-2.85 1.71-5.71 2.53-8.59.14-.51.36-.61.9-.38 3.22 1.42 6.45 2.83 9.68 4.15a1.86 1.86 0 011.23 1.62c.74 3.64 1.56 7.3 2.33 10.95a1.88 1.88 0 001.25 1.63c.57.2 1.15.5 1.73.75 1.04.46 1.28.21 1-1.09zm-17-21.8c.33-1.14.64-2.23 1-3.32q1.53-5.21 3.07-10.43c0-.07 0-.14.05-.2.16-.51.29-1.11 1-.81s.78 1 .92 1.63c.31 1.42.6 2.83.9 4.25q1.28 6 2.54 11.95c.06.32.11.64.17 1zM1029.62 412.18l-10.06-4.68a3.38 3.38 0 01-1.78-3v-.51a1.33 1.33 0 012-1.28l10.06 4.65a3.39 3.39 0 011.78 3v.52a1.33 1.33 0 01-2 1.3zM1064 325.72c-2 20.22-19.83 35.08-42.77 27.84a42.54 42.54 0 01-29.55-42.36c.63-17.52 13.16-37.36 40.28-31 24.41 5.8 33.56 29.8 32.04 45.52z"
    />
    <path fill="#3a3a3a" d="M963.04 222.41l3.53-1.3 135.65 66.44-2.89 1.77-136.29-66.91z" />
    <path fill="#262626" d="M1097.66 481.82l2.19-1.46 2.37-192.81-2.89 1.77-1.67 192.5z" />
    <path
      d="M1028.26 338.29l6.63 4.32-.1-3.34-5.67-5.65a27.12 27.12 0 00.11-3.95l-.17-7.05 16.94 10.06-.16-3.78-7.6-6.57-3.25-2.92-6.13-5.72-.29-11.8a16 16 0 00-.8-4.79 5.56 5.56 0 00-2.23-3.25c-.84-.42-1.63-.07-2.14 1.08a10.15 10.15 0 00-.6 4.19l.23 12.1-16.07 1.52v4.19l16.22 3.27.14 7.11a33.23 33.23 0 00.29 4.17l.14.71-5.37 1v3.49l6.23.35v-.11l.8 4c.29 1.46 1.92 2 2.16.76z"
      fillRule="evenodd"
      fill={color2}
    />
  </svg>
);

export default React.memo(Illustration2);
