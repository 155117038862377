import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NewSmartPass from './scenes/NewSmartPass';
import EditSmartPass from './scenes/EditSmartPass';
import SmartPassDetails from './scenes/SmartPassDetails';

const SmartPass = () => {
  const { companyName } = useRouteMatch().params;
  return (
    <Switch>
      <Route
        exact
        path={`/companies/${companyName}/smartpass/new`}
        render={() => <NewSmartPass companyName={companyName} />}
      />
      <Route
        exact
        path={`/companies/${companyName}/smartpass/view`}
        render={() => <EditSmartPass companyName={companyName} />}
      />
      <Route
        exact
        path={`/companies/${companyName}/smartpass`}
        component={() => <SmartPassDetails companyName={companyName} />}
      />
    </Switch>
  );
};

export default SmartPass;
