import React from 'react';
import { FieldArray } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import { usePolyglot } from 'components/services/i18n';
import { keys, terminalsValues, loungeLocationValues } from 'consts/smartPass';

import { LabelsRowWrapper } from '../index.styled';

const {
  VOUCHER_LOUNGE_LOCATION,
  VOUCHER_LOUNGE_TERMINALS,
  VOUCHER_LOUNGE_TERMINALS_LIST,
  VOUCHER_LOUNGE_TERMINALS_NEW_VALUE,
} = keys;

const LoungeAccess = ({ values, handleChange, handleBlur, handleAddToList, errors, touched }) => {
  const polyglot = usePolyglot();

  return (
    <>
      <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.lounge_located')}</Text>
      <LabelsRowWrapper>
        <Radio
          label={polyglot.t('smart_pass.form.vouchers.lounge_located_airside')}
          name={VOUCHER_LOUNGE_LOCATION}
          value={loungeLocationValues.AIRSIDE}
          checked={values[VOUCHER_LOUNGE_LOCATION] === loungeLocationValues.AIRSIDE}
          onChange={handleChange}
          hasError={touched[VOUCHER_LOUNGE_LOCATION] && errors[VOUCHER_LOUNGE_LOCATION]}
        />
        <Radio
          label={polyglot.t('smart_pass.form.vouchers.lounge_located_landside')}
          name={VOUCHER_LOUNGE_LOCATION}
          value={loungeLocationValues.LANDSIDE}
          checked={values[VOUCHER_LOUNGE_LOCATION] === loungeLocationValues.LANDSIDE}
          onChange={handleChange}
          hasError={touched[VOUCHER_LOUNGE_LOCATION] && errors[VOUCHER_LOUNGE_LOCATION]}
        />
      </LabelsRowWrapper>
      <Text spaceAfter="small">
        {polyglot.t('smart_pass.form.vouchers.terminal_lounge_located')}
      </Text>

      <Stack spaceAfter="large">
        <Radio
          label={polyglot.t('smart_pass.form.all_terminals')}
          name={VOUCHER_LOUNGE_TERMINALS}
          checked={values[VOUCHER_LOUNGE_TERMINALS] === terminalsValues.ALL_TERMINALS}
          value={terminalsValues.ALL_TERMINALS}
          onChange={handleChange}
          hasError={touched[VOUCHER_LOUNGE_TERMINALS] && errors[VOUCHER_LOUNGE_TERMINALS]}
        />
        <LabelsRowWrapper labelWidth="200px">
          <Radio
            label={polyglot.t('smart_pass.form.specific_terminals')}
            name={VOUCHER_LOUNGE_TERMINALS}
            checked={values[VOUCHER_LOUNGE_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS}
            value={terminalsValues.SPECIFIC_TERMINALS}
            onChange={handleChange}
            hasError={touched[VOUCHER_LOUNGE_TERMINALS] && errors[VOUCHER_LOUNGE_TERMINALS]}
          />
          <FieldArray name={VOUCHER_LOUNGE_TERMINALS_LIST}>
            {({ remove, push }) => (
              <InputField
                disabled={values[VOUCHER_LOUNGE_TERMINALS] !== terminalsValues.SPECIFIC_TERMINALS}
                name={VOUCHER_LOUNGE_TERMINALS_NEW_VALUE}
                value={values[VOUCHER_LOUNGE_TERMINALS_NEW_VALUE]}
                onChange={handleChange}
                onBlur={handleBlur}
                inlineLabel
                onKeyUp={handleAddToList(push, VOUCHER_LOUNGE_TERMINALS_NEW_VALUE)}
                label={polyglot.t('smart_pass.form.specific_terminals_label')}
                placeholder={polyglot.t('smart_pass.form.press_enter_to_add_terminal')}
                error={
                  errors[VOUCHER_LOUNGE_TERMINALS_NEW_VALUE] &&
                  polyglot.t(errors[VOUCHER_LOUNGE_TERMINALS_NEW_VALUE])
                }
                tags={
                  values[VOUCHER_LOUNGE_TERMINALS_LIST].length > 0
                    ? values[VOUCHER_LOUNGE_TERMINALS_LIST].map((tag, index) => (
                        <Tag key={index} selected onRemove={() => remove(index)}>
                          {tag}
                        </Tag>
                      ))
                    : undefined
                }
              />
            )}
          </FieldArray>
        </LabelsRowWrapper>
      </Stack>
      <Separator spaceAfter="medium" />
    </>
  );
};

export default LoungeAccess;
