import PassengersIcon from '@kiwicom/orbit-components/lib/icons/Passengers';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';
import SettingsIcon from '@kiwicom/orbit-components/lib/icons/Settings';
import TripIcon from '@kiwicom/orbit-components/lib/icons/Trip';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import MoneyTransferOutIcon from '@kiwicom/orbit-components/lib/icons/MoneyTransferOut';
import QuestionCircleIcon from '@kiwicom/orbit-components/lib/icons/QuestionCircle';
import MessagesIcon from '@kiwicom/orbit-components/lib/icons/Messages';
import TipsIcon from '@kiwicom/orbit-components/lib/icons/Tips';
import DashboardIcon from '@kiwicom/orbit-components/lib/icons/Dashboard';
import AdminIcon from '@kiwicom/orbit-components/lib/icons/Admin';

import { ReactComponent as SolutionsIcon } from 'images/Icons_Solutions.svg';
import { ReactComponent as CompanyIcon } from 'images/Icons_Company.svg';
import { ReactComponent as ReportsIcon } from 'images/Icons_Reports.svg';
import { ReactComponent as ResourcesIcon } from 'images/Icons_Resources.svg';
import { ReactComponent as DeeplinksIcon } from 'images/Icons_Deeplinks.svg';

const LOGIN = {
  name: 'common.login',
  path: '/login',
};

const REGISTER = {
  name: 'common.register',
  path: `${LOGIN.path}/register`,
};

const LANDING = {
  name: 'landing.activate_user',
  path: '/landing/:token',
};

const RESET_PASSWORD = {
  name: 'common.reset_password',
  path: '/reset-password/:token',
};

const PORTAL = {
  name: 'portal.title',
  path: '',
};

const DASHBOARD = {
  name: 'dashboard.title',
  path: `${PORTAL.path}/dashboard`,
  resource: 'fe.page.dashboard',
  action: ['read'],
};

const RESOURCES = {
  name: 'resources.title',
  path: '/resources',
  Icon: ResourcesIcon,
  resource: 'fe.page.resources',
  action: ['read'],
};

const RESOURCES_SINGLE = {
  name: 'resources.single',
  path: `${RESOURCES.path}/:codename`,
  toPath: `${RESOURCES.path}`,
};

const RESOURCES_FAQ = {
  name: 'resources.faq',
  path: `${RESOURCES.path}/faq`,
  toPath: `${RESOURCES.path}`,
};

const RESOURCES_FAQ_SINGLE = {
  name: 'resources.faq_single',
  path: `${RESOURCES_FAQ.path}/:codename`,
  toPath: `${RESOURCES_FAQ.path}`,
};

const DOCS = {
  name: 'docs.title',
  path: `${PORTAL.path}/docs`,
};

const DOCS_SEARCH = {
  name: 'docs.search.title',
  path: `${DOCS.path}/search`,
  toPath: `${DOCS.path}`,
};

const DOCS_NEWS = {
  name: 'docs.news.title',
  path: `${DOCS.path}/news`,
  toPath: `${DOCS.path}/news`,
};

const DOCS_NEWS_SINGLE = {
  name: 'docs.news.title',
  path: `${DOCS_NEWS.path}/general/:codename`,
  toPath: `${DOCS_NEWS.path}/general`,
};

const DOCS_NEWS_API_SINGLE = {
  name: 'docs.news.title',
  path: `${DOCS_NEWS.path}/api/:codename`,
  toPath: `${DOCS_NEWS.path}/api`,
};

const DOCS_TEQUILA_API = {
  name: 'docs.tequila_api.title',
  path: `${DOCS.path}/tequila_api`,
  toPath: `${DOCS.path}/tequila_api`,
};

const DOCS_TEQUILA_API_SINGLE = {
  name: 'docs.tequila_api.title',
  path: `${DOCS_TEQUILA_API.path}/:codename`,
  toPath: `${DOCS_TEQUILA_API.path}`,
};

const DOCS_USER_GUIDES = {
  name: 'docs.user_guides.title',
  path: `${DOCS.path}/user_guides`,
  toPath: `${DOCS.path}/user_guides`,
};

const DOCS_USER_GUIDES_SINGLE = {
  name: 'docs.user_guides.title',
  path: `${DOCS_USER_GUIDES.path}/:codename`,
  toPath: `${DOCS_USER_GUIDES.path}`,
};

const DOCS_FAQ = {
  name: 'docs.faq.title',
  path: `${DOCS.path}/faq`,
  toPath: `${DOCS.path}/faq`,
};

const DOCS_FAQ_SINGLE = {
  name: 'docs.faq.title',
  path: `${DOCS_FAQ.path}/:codename`,
  toPath: `${DOCS_FAQ.path}`,
};

const DEEPLINK_GENERATOR = {
  name: 'deeplink_generator.title',
  path: `${PORTAL.path}/deeplink-generator`,
  Icon: DeeplinksIcon,
  resource: 'fe.page.deeplink_generator',
  action: ['read'],
};

const REPORTS = {
  name: 'reports.title',
  path: `${PORTAL.path}/reports`,
  Icon: ReportsIcon,
  resource: 'fe.page.reports',
  action: ['read'],
};

const REPORTS_DETAILS = {
  name: 'reports.details',
  path: `${REPORTS.path}/details/:aliasId`,
};

const REPORTS_EDIT = {
  name: 'reports.edit_report',
  path: `${REPORTS.path}/edit/:aliasId`,
};

const REPORTS_CREATE = {
  name: 'reports.create_report',
  path: `${REPORTS.path}/create`,
};

const REPORTS_CREATE_SALES = {
  name: 'reports.create_report',
  path: `${REPORTS.path}/create/sales`,
};

const REPORTS_CREATE_MARKET = {
  name: 'reports.create_report',
  path: `${REPORTS.path}/create/markets`,
};

const REPORTS_CREATE_SALES_DATA_PREVIEW = {
  name: 'reports.data_preview',
  path: `${REPORTS.path}/create/sales/data-preview`,
};

const REPORTS_CREATE_MARKETS_DATA_PREVIEW = {
  name: 'reports.data_preview',
  path: `${REPORTS.path}/create/markets/data-preview`,
};

const REPORT_DETAILS = {
  name: null,
  path: `${REPORTS.path}/:aliasId`,
};

const REPORT_EDIT = {
  name: 'reports.edit_report',
  path: `${REPORTS.path}/edit/:aliasId`,
};

const REPORTS_DUPLICATE = {
  name: 'reports.duplicate_report',
  path: `${REPORTS.path}/copy/:aliasId`,
};

const USER_MANAGEMENT = {
  name: 'user_management.title',
  path: `${PORTAL.path}/user-management`,
  Icon: PassengersIcon,
  resource: 'fe.page.user_management',
  action: ['read'],
};

const COMPANIES = {
  name: 'company.title',
  path: `${PORTAL.path}/companies`,
  Icon: CompanyIcon,
  resource: 'fe.page.companies',
  action: ['read'],
};

const MANAGE_MY_BOOKINGS = {
  name: 'common.manage_my_bookings',
  path: `${PORTAL.path}/manage-my-bookings`,
  Icon: TripIcon,
  resource: 'fe.page.mmb',
  action: ['read'],
};

const COMPANY = {
  name: 'company.title_singular',
  path: `${COMPANIES.path}/:companyName`,
  toPath: `${COMPANIES.path}`,
  resource: 'model.company',
  action: ['read', 'read_own'],
  Icon: CompanyIcon,
};

const COMPANY_LOGS = {
  name: 'logs.title',
  path: `${COMPANY.path}/logs`,
};

const COMPANY_BIDVIEWER = {
  name: 'bidviewer.title',
  path: `${COMPANY.path}/bidviewer`,
};

const MY_SOLUTIONS_PATH = `${PORTAL.path}/my-solutions`;
const MY_APPLICATIONS_PATH = `${PORTAL.path}/my-applications`;

const MY_SOLUTIONS = {
  name: 'common.my_solutions',
  path: MY_SOLUTIONS_PATH,
  oldPath: MY_APPLICATIONS_PATH,
  Icon: SolutionsIcon,
  resource: 'fe.page.applications',
  action: ['read'],
};

const SOLUTION = {
  name: 'company.solutions',
  path: `${COMPANY.path}/solutions/:affilId`,
};

const COMPANY_BILLING = {
  name: 'company.billing',
  path: `${COMPANIES.path}/:companyName/billing`,
};

const KIWI_SEARCH = {
  name: 'common.booking_tool',
  path: `${PORTAL.path}/search`,
  Icon: SearchIcon,
  resource: 'fe.page.kiwicom.search',
  action: ['read'],
};

const KIWI_BOOKING = {
  name: 'common.booking_tool',
  path: `${PORTAL.path}/book`,
};

const KIWI_BOOKING_SUCCESS = {
  name: 'common.booking_tool',
  path: `${PORTAL.path}/book/success`,
};

const WIDGETS = {
  name: 'builder.title',
  path: `${PORTAL.path}/widgets`,
  Icon: SettingsIcon,
  resource: 'fe.page.widget_builder',
  action: ['read'],
};

const HELP_PATH = `${PORTAL.path}/support`;

const HELP_CREATE_REQUEST = {
  name: 'support.create_new_request',
  path: `${HELP_PATH}/request`,
  Icon: PlusIcon,
  resource: 'fe.page.help',
  action: ['read'],
};

const HELP = {
  name: 'common.help',
  path: HELP_PATH,
  Icon: QuestionCircleIcon,
  resource: 'fe.page.help',
  action: ['read'],
};

const CUSTOMER_SUPPORT_PATH = `${PORTAL.path}/customer-support`;

const CUSTOMER_SUPPORT_CREATE_REQUEST = {
  name: 'customer_support.create_new_request',
  path: `${CUSTOMER_SUPPORT_PATH}/request`,
  Icon: PlusIcon,
  resource: 'fe.page.customer_support',
  action: ['read'],
};

const CUSTOMER_SUPPORT = {
  name: 'common.customer_support',
  path: CUSTOMER_SUPPORT_PATH,
  Icon: MessagesIcon,
  resource: 'fe.page.customer_support',
  action: ['read'],
};

const PARTNER_PAYMENTS = {
  name: 'partner_payments.title',
  path: `${PORTAL.path}/partner-payments`,
  Icon: MoneyIcon,
  resource: 'fe.page.partner_payments',
  action: ['read'],
};

const PARTNER_PAYMENT = {
  name: 'partner_payments.title',
  path: `${PARTNER_PAYMENTS.path}/:partnerPaymentId`,
};

const CUSTOMER_DEMAND_DASHBOARD = {
  name: 'customer_demand_dashboard.title',
  resource: 'feature.demand_dashboard',
  action: ['read', 'read_own'],
  path: `${PORTAL.path}/customer-demand`,
  Icon: DashboardIcon,
};

const DESTINATION_DEMAND_DASHBOARD = {
  name: 'tequila.destination_demand_dashboard.title',
  resource: 'feature.destination_dashboard',
  action: ['read', 'read_own'],
  path: `${PORTAL.path}/destination-demand`,
  Icon: SolutionsIcon,
};

const COMPANY_SMART_PASS = {
  name: 'smart_pass.title',
  path: `${COMPANY.path}/smartpass`,
};
const GETTING_STARTED = {
  name: 'getting_started.title',
  path: `${PORTAL.path}/getting-started`,
  Icon: TipsIcon,
  resource: 'model.bank_account',
  action: ['update', 'update_assigned', 'update_own'],
};
const BILLING = {
  name: 'billing.title',
  path: `${PORTAL.path}/billing`,
  Icon: MoneyTransferOutIcon,
  resource: 'model.billing_job',
  action: ['index'],
};

const ADMIN_DASHBOARD = {
  name: 'admin_dashboard.title',
  path: `${PORTAL.path}/admin-dashboard`,
  Icon: AdminIcon,
  resource: 'model.cooperation',
  action: ['read'],
};

const DATA_MARKETPLACE = {
  name: 'data_marketplace.title',
  path: `${PORTAL.path}/data-marketplace`,
  Icon: DashboardIcon,
  resource: 'model.data_marketplace_dataset',
  action: ['read_unpublished', 'read_own_unpublished'],
};

export const ROUTE_CONFIG = {
  LOGIN,
  REGISTER,
  LANDING,
  RESET_PASSWORD,
  PORTAL,
  DASHBOARD,
  RESOURCES,
  RESOURCES_SINGLE,
  RESOURCES_FAQ,
  RESOURCES_FAQ_SINGLE,
  REPORTS,
  REPORTS_DETAILS,
  REPORTS_CREATE,
  REPORTS_CREATE_SALES,
  REPORTS_CREATE_MARKET,
  REPORTS_CREATE_MARKETS_DATA_PREVIEW,
  REPORTS_CREATE_SALES_DATA_PREVIEW,
  REPORT_DETAILS,
  REPORT_EDIT,
  REPORTS_EDIT,
  REPORTS_DUPLICATE,
  USER_MANAGEMENT,
  COMPANY,
  COMPANY_LOGS,
  COMPANY_BIDVIEWER,
  COMPANIES,
  SOLUTION,
  COMPANY_BILLING,
  MY_SOLUTIONS,
  DEEPLINK_GENERATOR,
  WIDGETS,
  MANAGE_MY_BOOKINGS,
  HELP,
  HELP_CREATE_REQUEST,
  PARTNER_PAYMENTS,
  PARTNER_PAYMENT,
  KIWI_SEARCH,
  KIWI_BOOKING,
  KIWI_BOOKING_SUCCESS,
  CUSTOMER_SUPPORT,
  CUSTOMER_SUPPORT_CREATE_REQUEST,
  CUSTOMER_DEMAND_DASHBOARD,
  DESTINATION_DEMAND_DASHBOARD,
  DOCS,
  DOCS_SEARCH,
  DOCS_NEWS,
  DOCS_NEWS_SINGLE,
  DOCS_NEWS_API_SINGLE,
  DOCS_TEQUILA_API,
  DOCS_TEQUILA_API_SINGLE,
  DOCS_USER_GUIDES,
  DOCS_USER_GUIDES_SINGLE,
  DOCS_FAQ,
  DOCS_FAQ_SINGLE,
  COMPANY_SMART_PASS,
  GETTING_STARTED,
  BILLING,
  ADMIN_DASHBOARD,
  DATA_MARKETPLACE,
};

export const mainNavigationItems = [
  GETTING_STARTED,
  COMPANY,
  COMPANIES,
  MY_SOLUTIONS,
  REPORTS,
  USER_MANAGEMENT,
  CUSTOMER_SUPPORT,
  HELP,
];

export const secondaryNavigationItems = [
  KIWI_SEARCH,
  MANAGE_MY_BOOKINGS,
  DEEPLINK_GENERATOR,
  WIDGETS,
  PARTNER_PAYMENTS,
  BILLING,
  ADMIN_DASHBOARD,
  CUSTOMER_DEMAND_DASHBOARD,
  DESTINATION_DEMAND_DASHBOARD,
  DATA_MARKETPLACE,
];

export const selectableNavigationItems = [
  [KIWI_SEARCH, MANAGE_MY_BOOKINGS],
  [DEEPLINK_GENERATOR, WIDGETS],
  [PARTNER_PAYMENTS, BILLING, ADMIN_DASHBOARD],
  [CUSTOMER_DEMAND_DASHBOARD, DESTINATION_DEMAND_DASHBOARD, DATA_MARKETPLACE],
];

export const BACK_ACTION = 'POP';
