import React, { useReducer } from 'react';

import api from 'utils/api';
import pnrBookingsMapper from 'mappers/nextGen/special-apps/bidviewer/pnr-bookings/_';

export const BidViewerState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_BID_VIEWER':
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case 'LOAD_BID_VIEWER_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case 'LOAD_BID_VIEWER_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const BidviewerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const loadBidViewer = async bid => {
    dispatch({ type: 'LOAD_BID_VIEWER' });

    try {
      const { data } = await api.v1.get(`special-apps/bidviewer/pnr-bookings?bid=${bid}`);
      const bids = data.map(pnrBookingsMapper.v1.get.from);

      return dispatch({
        type: 'LOAD_BID_VIEWER_SUCCESS',
        data: bids,
      });
    } catch (error) {
      return Promise.reject(dispatch({ type: 'LOAD_BID_VIEWER_FAIL', error }));
    }
  };

  const value = { state, loadBidViewer };

  return <BidViewerState.Provider value={value}>{children}</BidViewerState.Provider>;
};

export default BidviewerProvider;
