import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Error } from 'common';

import Polyglot from 'shapes/Polyglot';

import { getPolyglot } from 'redux/selectors/i18n';
import { logError } from 'utils/sentry';

/**
 * Takes care of unhandled exceptions.
 * Displays an error message if something goes wrong.
 * In production, sends a sentry event.
 * In development, logs the error to console.
 */
class ErrorBoundary extends PureComponent {
  state = {
    error: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    logError(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children, polyglot } = this.props;

    if (error) {
      return <Error visible={error} message={polyglot.t('common.exception_message')} />;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

export default connect(mapStateToProps)(ErrorBoundary);
