import React from 'react';
import * as R from 'ramda';

import CreditCardIcon from '@kiwicom/orbit-components/lib/icons/CreditCard';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import useCreateSolution from 'components/scenes/MySolutions/services/useCreateSolution';

import { FlexWrapper, Card, Row, LabelWrapper, ModalTitleWrapper } from './index.styled';

const SettlementOption = ({ product, selectedSettlement, onSelect }) => {
  const polyglot = usePolyglot();

  return (
    <Row>
      {product?.credit && (
        <Card onClick={() => onSelect('credit')}>
          <Radio
            checked={'credit' === selectedSettlement}
            onChange={() => onSelect('credit')}
            label={
              <LabelWrapper>
                <Text>{polyglot.t('solutions.credit_card')}</Text>
                <CreditCardIcon />
              </LabelWrapper>
            }
          />
          <Text type="secondary">{polyglot.t('solutions.credit_card.description')}</Text>
        </Card>
      )}
      {product?.deposit && (
        <Card onClick={() => onSelect('deposit')}>
          <Radio
            checked={'deposit' === selectedSettlement}
            onChange={() => onSelect('deposit')}
            label={
              <LabelWrapper>
                <Text>{polyglot.t('solutions.deposit')}</Text>
                <MoneyIcon />
              </LabelWrapper>
            }
          />
          <Text type="secondary">{polyglot.t('solutions.deposit.description')}</Text>
        </Card>
      )}
    </Row>
  );
};

const SelectSolutionType = React.memo(() => {
  const {
    selectedSolution,
    selectedSolutionType,
    selectedSettlement,
    setSelectedSolutionType,
    setSelectedSettlement,
  } = useCreateSolution();
  const polyglot = usePolyglot();
  const productRows = R.splitEvery(3, selectedSolution.products);

  return (
    <>
      <ModalHeader
        title={
          <ModalTitleWrapper>
            <Heading type="title2">{polyglot.t('solutions.modal_header.title.pre')} </Heading>
            <Heading type="title2">{polyglot.t(selectedSolution.display_name)}</Heading>
          </ModalTitleWrapper>
        }
      />
      <ModalSection>
        {selectedSolution.name !== 'smartpoint' && (
          <>
            <Text spaceAfter="normal" weight="bold">
              {polyglot.t('solutions.select_type')}
            </Text>
            <FlexWrapper>
              {productRows.map((row, i) => (
                <Row key={i}>
                  {row.map(product => (
                    <Card
                      onClick={() => {
                        setSelectedSolutionType(product);
                        setSelectedSettlement(Object.keys(product)[1]);
                      }}
                      key={product.name}
                    >
                      <Radio
                        checked={selectedSolutionType === product}
                        spaceAfter="medium"
                        value={product}
                        onChange={() => setSelectedSolutionType(product)}
                        label={
                          <LabelWrapper>
                            <Text>{polyglot.t(product.name)}</Text>
                          </LabelWrapper>
                        }
                      />
                      <Text type="secondary">{polyglot.t(product.description)}</Text>
                    </Card>
                  ))}
                </Row>
              ))}
            </FlexWrapper>
          </>
        )}
        {selectedSolution.name !== 'meta_search' && (
          <>
            <Text spaceAfter="normal" weight="bold">
              {polyglot.t('solutions.select_settlement')}
            </Text>
            {selectedSolutionType && (
              <SettlementOption
                product={selectedSolutionType}
                selectedSettlement={selectedSettlement}
                onSelect={setSelectedSettlement}
              />
            )}
          </>
        )}
      </ModalSection>
    </>
  );
});

export default SelectSolutionType;
