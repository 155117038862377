import { useContext } from 'react';

import { AuthState } from 'components/services/auth/AuthProvider';

const useCurrentUserSettings = () => {
  const { userSettings } = useContext(AuthState);

  return userSettings;
};

export default useCurrentUserSettings;
