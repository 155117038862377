import React, { useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Calendar from 'react-dates-lite';
import PropTypes from 'prop-types';
import addMonths from 'date-fns/addMonths';

import { usePolyglot } from 'components/services/i18n';

import DropdownInputButton from './DropdownInputButton';
import { dateRange } from 'utils/search';

export const CalendarDropdownGlobals = createGlobalStyle`
  .customCalendarStyle {
    z-index: 10
  }
`;

const Container = styled.div`
  ${({ disabled }) => disabled && 'pointer-events: none;'};
`;

const CalendarDropdown = ({
  closable,
  onClose,
  disabledDates,
  prefix,
  suffix,
  error,
  size,
  label,
  allowPast = false,
  placeholder,
  displayFormat,
  selectedDates,
  changeSelectedDates,
  buttonBack,
  buttonForward,
  firstMonth,
  dataTest,
  newConcept,
  tripType,
  disabled,
  inlineLabel,
}) => {
  const polyglot = usePolyglot();

  const getPrefix = useCallback(() => {
    if (newConcept && (tripType === undefined || tripType === 'return')) {
      return { prefix };
    }
    if (!newConcept) {
      return { prefix };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripType]);

  const getDisplayedValue = useCallback(() => {
    if (displayFormat) {
      return dateRange(selectedDates, displayFormat);
    }
    if (newConcept) {
      if (tripType !== undefined && tripType !== 'return') {
        return polyglot.t('search_and_booking.calendar_dropdown.no_return');
      }
      if (selectedDates.length) {
        return dateRange(selectedDates);
      }
      if (placeholder) {
        return '';
      }
      return polyglot.t('search_and_booking.calendar_dropdown.anytime');
    }
    return dateRange(selectedDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripType, selectedDates]);

  return (
    <Container disabled={disabled}>
      <CalendarDropdownGlobals />
      <DropdownInputButton
        closable={closable}
        onClose={onClose}
        size={size}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        {...getPrefix()}
        suffix={suffix}
        error={error}
        value={getDisplayedValue()}
        dataTest={dataTest}
        inlineLabel={inlineLabel}
        content={
          <Calendar
            firstMonth={firstMonth}
            lastMonth={addMonths(new Date(), 12)}
            rangeSelect
            past={allowPast}
            disabledDates={disabledDates}
            visibleMonths={2}
            selectedDates={selectedDates}
            selectDates={changeSelectedDates}
            buttonBack={buttonBack}
            buttonForward={buttonForward}
            classes={{
              button: 'customCalendarStyle',
            }}
            colors={{
              selected: 'rgb(0, 169, 145)',
              selectedHover: 'rgb(0, 133, 113)',
              border: 'white',
              background: 'white',
              hover: '#e4e7e7',
              disabled: 'gray',
            }}
          />
        }
      />
    </Container>
  );
};

CalendarDropdown.propTypes = {
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataTest: PropTypes.string,
  selectedDates: PropTypes.array,
  changeSelectedDates: PropTypes.func,
  disabledDates: PropTypes.array,
};

CalendarDropdown.defaultProps = {
  disabledDates: [],
  firstMonth: new Date(),
};

export default CalendarDropdown;
