import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Text, Loading, Stack } from '@kiwicom/orbit-components';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`;

const ActivityTable = ({ transactions, loading, loadMore, loadingMore, nextPage }) => {
  const polyglot = usePolyglot();
  const { addBookingIframe } = useBookingDetailsIframes();

  if (loading) {
    return (
      <Stack direction="column" align="center" justify="center">
        <Loading loading={loading} text={polyglot.t('tequila.mmb.credit_activity.table.loading')} />
      </Stack>
    );
  }

  return (
    <>
      <Table striped={false}>
        <TableHead>
          <TableRow>
            <TableCell>{polyglot.t('mmb.bid')}</TableCell>
            <TableCell>{polyglot.t('mmb.type')}</TableCell>
            <TableCell>{polyglot.t('mmb.date')}</TableCell>
            <TableCell>{polyglot.t('mmb.amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((transaction, id) => {
            return (
              <TableRow key={id} dataTest={`table-row-${id}`}>
                <TableCell>
                  {transaction.bid && (
                    <Text dataTest={`bid-row-${id}`}>
                      {transaction.bid === 'N/A' ? (
                        transaction.bid
                      ) : (
                        <Link
                          to={`${ROUTE_CONFIG.MANAGE_MY_BOOKINGS.path}/${transaction.bid}`}
                          onClick={() => addBookingIframe(transaction.bid)}
                        >
                          #{transaction.bid}
                        </Link>
                      )}
                    </Text>
                  )}
                </TableCell>
                <TableCell>{transaction.type}</TableCell>
                <TableCell dataTest={`date-row-${id}`}>{transaction.date}</TableCell>
                <TableCell>
                  <Text
                    dataTest={`amount-row-${id}`}
                    type={transaction.amount.split(' ')[0] > 0 ? 'success' : 'critical'}
                  >
                    {transaction.amount}
                  </Text>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {nextPage && (
        <LoadMoreWrapper>
          <Text type="secondary">{`${transactions?.length} Transactions`}</Text>
          <Button loading={loadingMore} onClick={loadMore} type="primary" dataTest="load-more-btn">
            {polyglot.t('common.load_more')}
          </Button>
        </LoadMoreWrapper>
      )}
    </>
  );
};

export default ActivityTable;
