import { useEffect, useContext } from 'react';

import { CompanySettingsState } from './CompanySettingsProvider';

const useCompanySettings = companyName => {
  const { state, loadCompanySettings } = useContext(CompanySettingsState);

  useEffect(() => {
    if (state[companyName]) {
      return;
    }

    loadCompanySettings(companyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  return (
    state[companyName] || {
      loading: true,
      data: null,
      error: null,
    }
  );
};

export default useCompanySettings;
