import React, { useContext } from 'react';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { previewTypeKeys } from 'consts/smartPass';

import { map } from './utils/adapters';
import { SmartPassContext } from 'components/services/smartPass';
import StandardVoucher from './components/StandardVoucher';
import CustomVoucher from './components/CustomVoucher';
import { FitContentWidthContainer } from './components/StandardVoucher.styled';

const VoucherPreviewModal = () => {
  const polyglot = usePolyglot();

  const {
    voucherPreviewModalInfo: { voucherInfo, smartPassInfo, previewType },
    hideVoucherPreviewModal: closeHandler,
  } = useContext(SmartPassContext);

  return (
    <FitContentWidthContainer>
      <Modal closable onClose={closeHandler}>
        <ModalHeader title={polyglot.t('smart_pass.voucher_preview_modal.title')} />
        <ModalSection>
          <Text size="large" weight="bold" spaceAfter="large">
            {polyglot.t('smart_pass.voucher_preview_modal.text')}
          </Text>
          {previewType === previewTypeKeys.STANDARD && (
            <StandardVoucher
              previewType={previewTypeKeys.STANDARD}
              previewInfo={map.toStandardPreviewInfo(voucherInfo, smartPassInfo)}
            />
          )}
          {previewType === previewTypeKeys.CUSTOM_LARGE && (
            <CustomVoucher
              previewInfo={map.toCustomPreviewInfo(voucherInfo)}
              previewType={previewTypeKeys.CUSTOM_LARGE}
            />
          )}
          {previewType === previewTypeKeys.CUSTOM_SMALL && (
            <CustomVoucher
              previewInfo={map.toCustomPreviewInfo(voucherInfo)}
              previewType={previewTypeKeys.CUSTOM_SMALL}
            />
          )}
          {previewType === previewTypeKeys.CUSTOM_FAST_TRACK && (
            <CustomVoucher
              previewInfo={map.toFastTrackPreviewInfo(voucherInfo)}
              previewType={previewTypeKeys.CUSTOM_FAST_TRACK}
            />
          )}
        </ModalSection>
      </Modal>
    </FitContentWidthContainer>
  );
};

export default VoucherPreviewModal;
