import React from 'react';
import MenuKebabIcon from '@kiwicom/orbit-components/lib/icons/MenuKebab';

import { ClickWrapper } from '../index.styled';

const ContextualMenuButton = ({ onClick }) => (
  <ClickWrapper onClick={onClick}>
    <MenuKebabIcon size="small" />
  </ClickWrapper>
);

export default ContextualMenuButton;
