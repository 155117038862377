import React from 'react';

import ThumbUpIcon from '@kiwicom/orbit-components/lib/icons/ThumbUp';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';

import { sortTypes } from 'consts/search';
import useSearch from 'components/services/search/useSearch';
import { usePolyglot } from 'components/services/i18n';

import SortTab from './SortTab';
import { TabContainer } from './index.styled';

const Sort = () => {
  const polyglot = usePolyglot();
  const { changeSortType, tabData } = useSearch();

  if (!tabData.isSortShown) {
    return null;
  }

  return (
    <TabContainer>
      <SortTab
        name={polyglot.t('search_and_booking.sort.best')}
        icon={<ThumbUpIcon size="small" />}
        active={tabData.sortType === sortTypes.BEST}
        changeSortType={changeSortType}
        sortType={sortTypes.BEST}
        flight={tabData.bestFlights[sortTypes.BEST]}
        tripType={tabData.tripType}
        loading={tabData.searchStatus[sortTypes.BEST] === 'loading'}
      />
      <SortTab
        name={polyglot.t('search_and_booking.sort.cheapest')}
        icon={<MoneyIcon size="small" />}
        active={tabData.sortType === sortTypes.PRICE}
        changeSortType={changeSortType}
        sortType={sortTypes.PRICE}
        flight={tabData.bestFlights[sortTypes.PRICE]}
        tripType={tabData.tripType}
        loading={tabData.searchStatus[sortTypes.PRICE] === 'loading'}
      />
      <SortTab
        name={polyglot.t('search_and_booking.sort.fastest')}
        icon={<ClockIcon size="small" />}
        active={tabData.sortType === sortTypes.DURATION}
        changeSortType={changeSortType}
        sortType={sortTypes.DURATION}
        flight={tabData.bestFlights[sortTypes.DURATION]}
        tripType={tabData.tripType}
        loading={tabData.searchStatus[sortTypes.DURATION] === 'loading'}
      />
    </TabContainer>
  );
};

export default Sort;
