import React from 'react';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import styled from 'styled-components';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';

const NoResults = ({ illustrationName, title, subtitle, buttonText, handleOnClick }) => {
  const CardResultData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38px 0px 28px;
  `;

  return (
    <Card>
      <CardSection>
        <CardResultData>
          <Illustration name={illustrationName} spaceAfter="largest" />
          <Text weight="bold" size="large" spaceAfter="small">
            {title}
          </Text>
          <Text
            weight="normal"
            size="medium"
            type="secondary"
            spaceAfter={buttonText ? 'medium' : 'none'}
          >
            {subtitle}
          </Text>
          {buttonText ? (
            <Button width="152px" size="small" onClick={handleOnClick}>
              {buttonText}
            </Button>
          ) : null}
        </CardResultData>
      </CardSection>
    </Card>
  );
};

export default NoResults;
