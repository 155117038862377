import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  width: 190px;
  margin: 0 10px 10px 0;
  position: relative;
  input {
    cursor: pointer;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
`;

export const DropdownIconContainer = styled.span`
  margin: 0 8px 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.orbit.colorIconSecondary};
`;

export const Wrapper = styled.div`
  padding: 4px;
`;

export const ChoiceWrapper = styled.div`
  position: relative;
`;

export const SelectAllContainer = styled.div`
  margin-bottom: 4px;
`;

export const SelectOnlyWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 60px;
`;
