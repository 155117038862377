import React, { useReducer } from 'react';

import { filterObject } from 'utils/object';

export const SeatingState = React.createContext({
  state: { seatings: {}, currentSeatings: {} },
  addSeating: _ => null,
  addSeatingSeatMap: _ => null,
  updatePassengerNameSeatMap: _ => null,
  removeSeating: _ => null,
  removeSeatingSeatMap: _ => null,
  resetSeatings: _ => null,
  resetAllSeatings: _ => null,
  saveSeatings: _ => null,
});

const initialState = {
  currentSeatings: {},
  seatings: {},
};

const seatingReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_SEATING':
      return {
        ...state,
        currentSeatings: {
          ...state.currentSeatings,
          [action.routeId]: {
            ...state.currentSeatings[action.routeId],
            ...action.seating,
          },
        },
      };
    case 'ADD_SEATING_SEATMAP':
      const segment = state.currentSeatings[action.routeId] || {};
      const segmentValues = Object.values(segment);
      const hasAlreadyPaxInSeat = segmentValues.some(
        seat => seat.passenger.id === action.seating.passenger.id,
      );

      if (hasAlreadyPaxInSeat) {
        const seats = {};
        const filteredAddSeatingSeatMap = segmentValues.filter(
          seat => seat.passenger.id !== action.seating.passenger.id,
        );

        if (filteredAddSeatingSeatMap.length) {
          filteredAddSeatingSeatMap.forEach(seat => {
            seats[seat.name] = seat;
          });
        }

        return {
          ...state,
          currentSeatings: {
            ...state.currentSeatings,
            [action.routeId]: {
              ...seats,
              [action.seatName]: action.seating,
            },
          },
        };
      }

      return {
        ...state,
        currentSeatings: {
          ...state.currentSeatings,
          [action.routeId]: {
            ...state.currentSeatings[action.routeId],
            [action.seatName]: action.seating,
          },
        },
      };
    case 'UPDATE_PASSENGER_NAME_SEATMAP':
      const oldSeatings = state.seatings;
      let updatedSeatings = {};

      Object.keys(oldSeatings).forEach(oldSeating => {
        if (!oldSeatings[oldSeating].option && Object.keys(oldSeatings[oldSeating]).length) {
          Object.keys(oldSeatings[oldSeating]).forEach(oldSeat => {
            if (oldSeatings[oldSeating][oldSeat].passenger.id === action.passenger.id) {
              updatedSeatings = {
                ...updatedSeatings,
                [oldSeating]: {
                  ...oldSeatings[oldSeating],
                  [oldSeatings[oldSeating][oldSeat].name]: {
                    ...oldSeatings[oldSeating][oldSeat],
                    passenger: action.passenger,
                  },
                },
              };
            }
          });
        } else {
          updatedSeatings = {
            ...updatedSeatings,
            [oldSeating]: {
              ...oldSeatings[oldSeating],
            },
          };
        }
      });

      return {
        ...state,
        currentSeatings: updatedSeatings,
        seatings: updatedSeatings,
      };
    case 'REMOVE_SEATING':
      const filteredSegmentsRemoveSeating = filterObject(state.seatings, action.routeId);

      return {
        ...state,
        currentSeatings: filteredSegmentsRemoveSeating,
        seatings: filteredSegmentsRemoveSeating,
      };
    case 'REMOVE_SEATING_SEATMAP':
      const filteredSeats = filterObject(state.currentSeatings[action.routeId], action.seatName);

      if (!Object.keys(filteredSeats).length) {
        const filteredSegmentsRemoveSeatingSeatMap = filterObject(
          state.currentSeatings,
          action.routeId,
        );

        return {
          ...state,
          currentSeatings: filteredSegmentsRemoveSeatingSeatMap,
        };
      }

      return {
        ...state,
        currentSeatings: {
          ...state.currentSeatings,
          [action.routeId]: filteredSeats,
        },
      };
    case 'RESET_SEATINGS':
      return {
        ...state,
        currentSeatings: state.seatings,
      };
    case 'SAVE_SEATINGS':
      return {
        ...state,
        seatings: state.currentSeatings,
      };
    case 'RESET_ALL_SEATINGS':
      return initialState;
    default:
      return state;
  }
};

const SeatingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(seatingReducer, initialState);

  const addSeating = (routeId, seating) => {
    dispatch({ type: 'ADD_SEATING', routeId, seating });
  };

  const addSeatingSeatMap = (routeId, seating, seatName) => {
    dispatch({ type: 'ADD_SEATING_SEATMAP', routeId, seating, seatName });
  };

  const updatePassengerNameSeatMap = passenger => {
    dispatch({ type: 'UPDATE_PASSENGER_NAME_SEATMAP', passenger });
  };

  const removeSeating = routeId => {
    dispatch({ type: 'REMOVE_SEATING', routeId });
  };

  const removeSeatingSeatMap = (routeId, seatName) => {
    dispatch({ type: 'REMOVE_SEATING_SEATMAP', routeId, seatName });
  };

  const resetSeatings = () => {
    dispatch({ type: 'RESET_SEATINGS' });
  };

  const resetAllSeatings = () => {
    dispatch({ type: 'RESET_ALL_SEATINGS' });
  };

  const saveSeatings = () => {
    dispatch({ type: 'SAVE_SEATINGS' });
  };
  const value = {
    ...state,
    addSeating,
    addSeatingSeatMap,
    updatePassengerNameSeatMap,
    removeSeating,
    removeSeatingSeatMap,
    resetSeatings,
    resetAllSeatings,
    saveSeatings,
  };

  return <SeatingState.Provider value={value}>{children}</SeatingState.Provider>;
};

export default SeatingProvider;
