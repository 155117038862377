const v1_get_from = user => ({
  uuid: user.uuid,
  email: user.email,
  firstName: user.first_name,
  lastName: user.last_name,
  dateOfBirth: user.date_of_birth,
  companyName: user.company_name,
  jiraOrganizationId: user.jira_organization_id,
  nationality: user.nationality,
  role: user.role,
  status: user.status,
  username: user.username,
  createdAt: user.created_at,
  createdBy: user.created_by,
  updatedAt: user.updated_at,
  updatedBy: user.updated_by,
});

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
