import React from 'react';
import styled from 'styled-components';
import { ChevronDown } from '@kiwicom/orbit-components/icons';
import { ChevronUp } from '@kiwicom/orbit-components/icons';

const ClickWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  > svg {
    margin: 0 5px;
  }
`;

const TableCellSort = ({ onClick, isAscending = true, children }) => {
  return (
    <ClickWrapper onClick={onClick}>
      {children}
      {isAscending ? <ChevronUp /> : <ChevronDown />}
    </ClickWrapper>
  );
};

export default TableCellSort;
