import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import SolutionsCard from 'components/scenes/MySolutions/components/SolutionsCard';

const Container = styled.div`
  max-width: 880px;
  margin-top: 24px;
  margin-left: 24px;
`;

const Solutions = () => {
  const { companyName } = useRouteMatch().params;
  return (
    <Container>
      <SolutionsCard companyName={companyName} />
    </Container>
  );
};

export default Solutions;
