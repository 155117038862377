import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useMMB from 'components/services/mmb/useMMB';
import { useCurrency } from 'components/services/currencies';
import { precisionRound } from 'utils/number';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';
import { QUICK_OPTION_TYPES } from 'consts/seating';
import { usePolyglot } from 'components/services/i18n';

import QuickOption from '../QuickOption';
import { StackWrapper } from './index.styled';

const QuickOptions = ({ routeId }) => {
  const polyglot = usePolyglot();
  const { bid } = useParams();
  const {
    ancillariesOffers: { data },
    bookingsState,
  } = useMMB();
  const { passengers } = bookingsState[bid];
  const { seatings, addSeatingMMB } = useShoppingBasket();
  const { getFormattedPriceString } = useCurrency();

  const quickOptions = useMemo(() => {
    const offer = data.filter(it => it.segmentCode === routeId)[0];

    if (passengers.length === 1) {
      return [
        {
          option: QUICK_OPTION_TYPES.RANDOM,
          price: null,
        },
        ...offer.quickOptions.filter(
          quickOption => quickOption.option !== QUICK_OPTION_TYPES.SIT_TOGETHER,
        ),
      ];
    }
    return [
      {
        option: QUICK_OPTION_TYPES.RANDOM,
        price: null,
      },
      ...offer.quickOptions.filter(
        quickOption => quickOption.option === QUICK_OPTION_TYPES.SIT_TOGETHER,
      ),
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, routeId]);

  return (
    <StackWrapper>
      {quickOptions.map(quickOption => (
        <QuickOption
          key={quickOption.option}
          isChecked={seatings[routeId]?.option === quickOption.option}
          option={quickOption.option}
          price={
            quickOption.price
              ? getFormattedPriceString(precisionRound(quickOption.price.amount, 2))
              : polyglot.t('tequila.ancillary_seating.mmb.drawer.no_seatmap.quick_option.included')
          }
          onClick={() => addSeatingMMB(routeId, quickOption)}
        />
      ))}
    </StackWrapper>
  );
};

export default QuickOptions;
