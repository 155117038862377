import { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import { useHistory } from 'react-router-dom';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Select from '@kiwicom/orbit-components/lib/Select';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { usePolyglot } from 'components/services/i18n';
import useHelp from 'components/services/help/useHelp';
import { SERVICE_DESK } from 'consts/serviceDesks';
import MultipleInputFiles from 'common/MultipleInputFiles';
import { ROUTE_CONFIG } from 'consts/routes';

const CreateIssueModalContent = ({ onClose, requestType }) => {
  const polyglot = usePolyglot();
  const { errors, control, handleSubmit, getValues } = useFormContext();
  const {
    getCustomFieldsOptions,
    createIssue,
    createAttachment,
    customFieldsOptions,
    requestTypeCustomField,
    customFieldOptionsLoading,
    issue,
    attachment,
  } = useHelp();
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCustomFieldsOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customFieldsOptions && requestTypeCustomField) {
      setSubcategoryOptions(
        customFieldsOptions?.[requestTypeCustomField[requestType.id]]?.map(sub => ({
          value: sub,
          label: sub,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldsOptions, requestTypeCustomField]);

  const handleOnCreateIssue = async () => {
    const values = getValues();
    const { key } = await createIssue(
      { requestType: requestType.id, ...values },
      SERVICE_DESK.CSSD,
    );

    if (values?.attachments.length === 1) {
      createAttachment(key, values?.attachments[0]);
    } else if (values.attachments.length > 1) {
      await Promise.allSettled(values?.attachments?.map(file => createAttachment(key, file)));
    }

    onClose();
    history.push(ROUTE_CONFIG.CUSTOMER_SUPPORT.path);
  };

  return (
    <Loading loading={customFieldOptionsLoading}>
      <ModalHeader title={requestType.name} />
      <ModalSection>{polyglot.t('support.create_issue_modal.cssd.description')}</ModalSection>
      <ModalSection>
        <Controller
          control={control}
          name="subject"
          render={({ value, onChange }) => (
            <InputField
              label={polyglot.t('support.create_issue_modal.input.summary.label')}
              required
              spaceAfter="large"
              value={value}
              onChange={onChange}
              error={polyglot.t(errors?.['subject']?.message?.toString())}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ value, onChange }) => (
            <Textarea
              label={polyglot.t('support.create_issue_modal.textarea.label')}
              required
              placeholder={polyglot.t('support.create_issue_modal.textarea.placeholder')}
              spaceAfter="large"
              value={value}
              onChange={onChange}
              error={polyglot.t(errors?.['description']?.message?.toString())}
            />
          )}
        />
        <Controller
          control={control}
          name="bid"
          render={({ value, onChange }) => (
            <InputField
              label={polyglot.t('support.create_issue_modal.input.bid.label')}
              required
              spaceAfter="large"
              value={value}
              onChange={onChange}
              error={polyglot.t(errors?.['bid']?.message?.toString())}
              type="number"
            />
          )}
        />
        <Controller
          control={control}
          name="subcategory"
          render={({ value, onChange }) => (
            <Select
              label={polyglot.t('support.create_issue_modal.input.subcategory.label')}
              placeholder={polyglot.t('support.create_issue_modal.input.subcategory.label')}
              options={subcategoryOptions}
              value={value.value}
              onChange={e =>
                onChange({ value: e.target.value, field: requestTypeCustomField[requestType.id] })
              }
              error={polyglot.t(errors?.['subcategory']?.field?.message?.toString())}
            />
          )}
        />
        <Controller
          control={control}
          name="attachments"
          render={({ value, onChange }) => (
            <MultipleInputFiles
              label={polyglot.t('support.create_issue_modal.file.label')}
              placeholder={polyglot.t('support.create_issue_modal.file.placeholder')}
              error={polyglot.t(errors?.['attachment']?.message?.toString())}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" justify="end" loading={issue?.loading || attachment?.loading}>
          <Button type="secondary" onClick={onClose}>
            {polyglot.t('support.create_issue_modal.button.cancel')}
          </Button>
          <Button
            onClick={handleSubmit(handleOnCreateIssue)}
            loading={issue?.loading || attachment?.loading}
          >
            {polyglot.t('support.create_issue_modal.button.submit')}
          </Button>
        </Stack>
      </ModalFooter>
    </Loading>
  );
};

export default CreateIssueModalContent;
