import styled, { css } from 'styled-components';

export const AutocompleteWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  position: relative;
`;

export const DropdownIconContainer = styled.span`
  margin: 0 8px;
  color: ${({ theme }) => theme.orbit.colorIconSecondary};
  transition: transform 100ms ease-in-out;
  cursor: pointer;

  ${({ open }) =>
    open &&
    css`
      transform: rotateZ(-180deg);
    `};
`;

export const ListChoiceContainer = styled.div`
  max-height: ${({ menuHeight }) => menuHeight}px;
  overflow: auto;
  position: absolute;
  top: 73px;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '398px')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '398px')};
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  z-index: 5;
  background: ${({ theme }) => theme.orbit.backgroundInput};
`;
