import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as dfs from 'date-fns';
import {
  Alert,
  Button,
  Badge,
  Loading,
  TextLink,
  Tooltip,
  Stack,
  Heading,
  Text,
} from '@kiwicom/orbit-components/lib';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import CloseCircleIcon from '@kiwicom/orbit-components/lib/icons/CloseCircle';
import TimelapseIcon from '@kiwicom/orbit-components/lib/icons/Timelapse';

import { useToggle } from 'utils/hooks';
import { truncate } from 'utils/string';
import { usePolyglot } from 'components/services/i18n';
import useHelp from 'components/services/help/useHelp';
import { SERVICE_DESK } from 'consts/serviceDesks';
import ReplyIssueModal from 'components/scenes/CustomerSupport/scenes/components/ReplyIssueModal';

import CreateRequestModal from './CreateRequestModal';
import CollapsibleCard from '../../../components/CollapsibleCard';

const TableWrapper = styled.div`
  padding: 16px;
`;

const StatusBadge = React.memo(({ status }) => {
  switch (status) {
    case 'Resolved':
      return (
        <Badge type="success" icon={<CheckCircleIcon />}>
          {status}
        </Badge>
      );
    case 'Reopened':
      return <Badge type="success">{status}</Badge>;
    case 'Closed':
      return (
        <Badge type="neutral" icon={<CloseCircleIcon />}>
          {status}
        </Badge>
      );
    case 'Canceled':
      return (
        <Badge type="critical" icon={<CloseCircleIcon />}>
          {status}
        </Badge>
      );
    case 'In progress':
      return <Badge type="info">{status}</Badge>;
    case 'Waiting for Support':
      return (
        <Badge type="warning" icon={<TimelapseIcon />}>
          {status}
        </Badge>
      );
    case 'Waiting for Customer':
      return (
        <Badge type="warning" icon={<TimelapseIcon />}>
          {status}
        </Badge>
      );
    case 'Pending':
      return (
        <Badge type="info" icon={<TimelapseIcon />}>
          {status}
        </Badge>
      );
    default:
      return <Badge type="warning">{status}</Badge>;
  }
});

const CustomerSupport = ({ bid }) => {
  const polyglot = usePolyglot();
  const { getIssues, issues, issuesLoading } = useHelp();

  const isExpanded = useToggle(false);
  const toggle = useToggle();
  const confirmAlert = useToggle();
  const isDetails = useToggle();

  const [showedIssues, setShowedIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [closedIssues, setClosedTickets] = useState(0);
  const [openedIssues, setOpenedTickets] = useState(0);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    getIssues({ project: SERVICE_DESK.CSSD, limit: 20, bid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (issues?.data?.length && showMore) {
      setShowedIssues(issues?.data?.slice(0, 5));
    }

    const closedIssuesTemp = issues?.data?.reduce((acc, curr) => {
      if (curr.status.name === 'Closed') {
        return acc + 1;
      }

      return acc + 0;
    }, 0);

    setClosedTickets(closedIssuesTemp);
    setOpenedTickets(issues?.data?.length - closedIssuesTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(issues?.data)]);

  const handleShowMore = () => {
    setShowMore(false);
    setShowedIssues(issues?.data);
  };

  const handleIssueOnClick = issueKey => {
    setSelectedIssue(issueKey);
    isDetails.setOn();
  };

  return (
    <>
      {isDetails.isOn && selectedIssue && (
        <ReplyIssueModal onClose={isDetails.setOff} issueId={selectedIssue} />
      )}
      <CreateRequestModal
        open={toggle.isOn}
        bid={bid}
        onClose={toggle.setOff}
        onSuccess={() => {
          toggle.setOff();
          confirmAlert.setOn();
        }}
      />
      {confirmAlert.isOn && (
        <Alert
          type="success"
          onClose={confirmAlert.setOff}
          closable
          spaceAfter="medium"
          dataTest="refund-created-alert"
        >
          {polyglot.t('mmb.booking_details.customer_support.alert.success')}
        </Alert>
      )}
      <CollapsibleCard
        nested
        disabled={!issues?.data?.length}
        loading={issuesLoading}
        isExpanded={isExpanded.isOn}
        onExpand={isExpanded.toggle}
        header={
          <Stack direction="row" align="center" spacing="XSmall">
            <Heading type="title4">
              {polyglot.t('mmb.booking_details.customer_support.card.title')}
            </Heading>
            {issuesLoading ? (
              <Loading type="inlineLoader" />
            ) : (
              !isNaN(openedIssues) &&
              !isNaN(closedIssues) && (
                <>
                  <Text>
                    {polyglot.t('mmb.booking_details.customer_support.opened_tickets', {
                      opened_tickets: openedIssues,
                    })}
                  </Text>
                  <Text type="secondary">
                    {polyglot.t('mmb.booking_details.customer_support.closed_tickets', {
                      closed_tickets: closedIssues,
                    })}
                  </Text>
                </>
              )
            )}
          </Stack>
        }
        sideInfo={
          <Stack direction="row" shrink align="center" justify="end">
            <Button
              type="secondary"
              size="small"
              width="96px"
              onClick={toggle.setOn}
              dataTest="create-ticket-btn"
            >
              {polyglot.t('mmb.booking_details.customer_support.button.create_ticket')}
            </Button>
          </Stack>
        }
      >
        {issuesLoading ? (
          <Loading type="boxLoader" />
        ) : (
          !!showedIssues?.length && (
            <>
              <TableWrapper>
                <Table striped={false}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {polyglot.t('mmb.booking_details.customer_support.table.ticket_name')}
                      </TableCell>
                      <TableCell align="left">
                        {polyglot.t('mmb.booking_details.customer_support.table.type')}
                      </TableCell>
                      <TableCell align="left">
                        {polyglot.t('mmb.booking_details.customer_support.table.status')}
                      </TableCell>
                      <TableCell align="left">
                        {polyglot.t('mmb.booking_details.customer_support.table.last_edit')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showedIssues.map(issue => {
                      return (
                        <TableRow key={issue.issueKey}>
                          <TableCell align="left">
                            <Tooltip placement="top" content={issue.summary}>
                              <TextLink onClick={() => handleIssueOnClick(issue.issueKey)}>
                                {truncate(issue.summary, 40)}
                              </TextLink>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">{issue?.subcategory?.value || 'N/A'}</TableCell>
                          <TableCell align="left">
                            <StatusBadge status={issue.status.name} />
                          </TableCell>
                          <TableCell align="left">
                            {issue?.updated
                              ? dfs.format(new Date(issue?.updated), 'dd MMM yyyy, HH:mm')
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableWrapper>
              {issues?.data.length > 5 && showMore && (
                <Stack direction="row" justify="center" spaceAfter="medium">
                  <TextLink onClick={handleShowMore}>Show more</TextLink>
                </Stack>
              )}
            </>
          )
        )}
      </CollapsibleCard>
    </>
  );
};

export default CustomerSupport;
