import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import ReloadIcon from '@kiwicom/orbit-components/lib/icons/Reload';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import CloseCircleIcon from '@kiwicom/orbit-components/lib/icons/CloseCircle';
import MinusCircleIcon from '@kiwicom/orbit-components/lib/icons/MinusCircle';
import PassportIcon from '@kiwicom/orbit-components/lib/icons/Passport';
import AlertIcon from '@kiwicom/orbit-components/lib/icons/Alert';
import FeedbackIcon from '@kiwicom/orbit-components/lib/icons/Feedback';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import {
  Heading,
  Stack,
  Badge,
  Loading,
  Tooltip,
  Button,
  Inline,
  Text,
} from '@kiwicom/orbit-components';

import useBoardingPasses from 'components/services/mmb/useBoardingPasses';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import { downloadURLs } from 'utils/files';

import CollapsibleCard from '../../components/CollapsibleCard';

const SectionWrapper = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.orbit.paletteCloudNormal}`};
  padding: 16px;
  padding-left: 26px;
`;

const NameWrapper = styled.div`
  margin-right: 16px;
`;

const BoardingPassBadge = React.memo(({ status }) => {
  let type, content, tooltip, icon;
  const polyglot = usePolyglot();
  switch (status) {
    case 'done':
    case 'ground_done':
      type = 'success';
      content = polyglot.t('mmb.booking_details.boarding_passes.available');
      icon = <CheckCircleIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.download_boarding_passes');
      break;
    case 'waiting_for_details':
    case 'waiting_for_apaxi':
    case 'provide_details':
    case 'provide_apaxi':
      type = 'warning';
      icon = <InformationCircleIcon />;
      content = polyglot.t('mmb.booking_details.boarding_passes.finish_check_in');
      break;
    case 'processing':
    case 'ground_processing':
      type = 'info';
      icon = <ReloadIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.working_on_online_check_in');
      content = polyglot.t('mmb.booking_details.boarding_passes.processing');
      break;
    case 'waiting_for_checkin':
      type = 'info';
      icon = <ReloadIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.working_on_online_check_in');
      content = polyglot.t('mmb.booking_details.boarding_passes.waiting_for_check_in');
      break;
    case 'missed':
      type = 'warning';
      icon = <PassportIcon />;
      content = polyglot.t('mmb.booking_details.boarding_passes.missed_check_in');
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.unable_to_finish_check_in_no_id');
      break;
    case 'restricted':
      content = polyglot.t('mmb.booking_details.boarding_passes.restricted_check_in');
      type = 'critical';
      icon = <AlertIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.airline_not_supporting_check_in');
      break;
    case 'unavailable':
    case 'ground_unavailable':
      content = polyglot.t('mmb.booking_details.boarding_passes.check_in_unavailable');
      type = 'critical';
      icon = <MinusCircleIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.kiwi_not_supporting_check_in');
      break;
    case 'failed':
      content = polyglot.t('mmb.booking_details.boarding_passes.failed');
      type = 'critical';
      icon = <CloseCircleIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.unable_to_finish_check_in');
      break;
    case 'canceled':
      content = polyglot.t('mmb.booking_details.boarding_passes.canceled');
      type = 'neutral';
      icon = <FeedbackIcon />;
      tooltip = polyglot.t('mmb.booking_details.boarding_passes.passenger_canceled_his_order');
      break;
    default:
      type = 'warning';
      icon = <InformationCircleIcon />;
      content = polyglot.t('mmb.booking_details.boarding_passes.please_finish_check_in');
  }
  return (
    <Badge
      type={type}
      icon={
        tooltip ? (
          <Tooltip content={tooltip} preferredPosition="top">
            {icon}
          </Tooltip>
        ) : (
          icon
        )
      }
      dataTest="boarding-pass-badge"
    >
      {content}
    </Badge>
  );
});

const PassengerRow = ({ passenger }) => {
  return (
    <Stack direction="row" align="center" justify="between">
      <Inline>
        <NameWrapper>{passenger.passenger.fullName}</NameWrapper>
        <BoardingPassBadge status={passenger.status} />
      </Inline>
    </Stack>
  );
};

const PaxBoardingTicket = ({ data }) => {
  return (
    <SectionWrapper>
      <Stack direction="column" spaceAfter="large" spacing="medium" shrink>
        <Stack direction="row" align="center" spacing="XXSmall">
          <Heading type="title3">{data.from}</Heading>
          <FlightDirectIcon />
          <Heading type="title3">{data.to}</Heading>
        </Stack>
        {data.passengers.map(pax => (
          <PassengerRow passenger={pax} />
        ))}
      </Stack>
    </SectionWrapper>
  );
};

const BoardingPasses = () => {
  const { bid } = useParams();
  const { data, loading } = useBoardingPasses(bid);
  const isExpanded = useToggle();

  const polyglot = usePolyglot();

  if (loading) {
    return <Loading type="boxLoader" />;
  }

  if (!data) {
    return null;
  }

  const locations = data[0].map(el => ({
    from: el.from.city,
    to: el.to.city,
    passengers: data.map(
      pax => pax.filter(loc => loc.from.city === el.from.city && loc.to.city === el.to.city)[0],
    ),
  }));

  const boardingPasses = locations
    .reduce((acc, curr) => acc.concat(curr.passengers.map(pax => pax.boardingPasses[0])), [])
    .filter(el => !!el);

  const handleDownloadAll = files => {
    downloadURLs(files.map(file => ({ name: file.file_name, url: file.file_url })));
  };

  const paxNum = locations.reduce((acc, curr) => acc + curr.passengers.length, 0);

  return (
    <CollapsibleCard
      header={
        <Stack direction="row">
          <Heading type="title3">{polyglot.t('mmb.booking_details.boarding_passes.title')}</Heading>
          <Text>{`${boardingPasses.length} of ${paxNum} available`}</Text>
        </Stack>
      }
      nested
      isExpanded={isExpanded.isOn}
      onExpand={isExpanded.toggle}
      sideInfo={
        <Stack direction="row" shrink align="center" justify="end">
          <Button
            size="small"
            type="primarySubtle"
            onClick={() => handleDownloadAll(boardingPasses)}
            disabled={!boardingPasses.length}
          >
            {polyglot.t('mmb.booking_details.boarding_passes.button.download')}
          </Button>
        </Stack>
      }
    >
      {locations.map((location, i) => (
        <PaxBoardingTicket key={i} data={location} dataTest={`location-${i}`} />
      ))}
    </CollapsibleCard>
  );
};

export default React.memo(BoardingPasses);
