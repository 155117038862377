const mapEmails = reportSchedule => {
  if (reportSchedule.emailIsOn === false) {
    return {
      emails: [],
    };
  }

  return {
    ...(reportSchedule.emailRecipients?.length && { emails: reportSchedule.emailRecipients }),
  };
};

const mapSftp = reportSchedule => {
  if (reportSchedule.sftpIsOn === false) {
    return {
      sftp_hostname: '',
      sftp_password: '',
      sftp_path: '/',
      sftp_port: null,
      sftp_username: '',
    };
  }

  return {
    ...(reportSchedule.sftpHostName && { sftp_hostname: reportSchedule.sftpHostName }),
    ...(reportSchedule.sftpPassword && { sftp_password: reportSchedule.sftpPassword }),
    ...(reportSchedule.sftpFilePath && { sftp_path: reportSchedule.sftpFilePath }),
    ...(typeof reportSchedule.sftpPort === 'number' && { sftp_port: reportSchedule.sftpPort }),
    ...(reportSchedule.sftpUserName && { sftp_username: reportSchedule.sftpUserName }),
  };
};

const v2_put_to = reportSchedule => {
  return {
    ...mapEmails(reportSchedule),
    ...(reportSchedule.formatFile && { file_format: reportSchedule.formatFile }),
    ...(reportSchedule.frequency && { frequency: reportSchedule.frequency }),
    ...(typeof reportSchedule.isOn === 'boolean' && { is_active: reportSchedule.isOn }),
    ...mapSftp(reportSchedule),
  };
};

export default {
  v2: {
    put: {
      to: v2_put_to,
    },
  },
};
