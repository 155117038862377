import React, { useContext } from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import * as roles from 'consts/roles';
import useApiProducts from 'components/services/products/useApiProducts';
import { usePolyglot } from 'components/services/i18n';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import { AuthState } from 'components/services/auth/AuthProvider';
import useCurrentSolutionDetails from 'components/services/solutions/useCurrentSolutionDetails';
import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';

import Commercials from './components/Commercials';
import SolutionInternalInformation from './components/SolutionInternalInformation';
import SolutionDetails from './components/SolutionDetails';
import { ProductInfo, Container } from './index.styled';
import { Authorization } from 'common/Authorization';

const Solution = () => {
  const products = useApiProducts();
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const solution = useCurrentSolutionDetails();
  const companyDetails = useCurrentCompanyDetails();
  const { checkGrantProperties } = useContext(AuthState);

  if (solution.data === null || !companyDetails.data) {
    return (
      <Container>
        <Loading type="pageLoader" text="Please wait until we get solution data for you." />
      </Container>
    );
  }

  const canEditQuota = user.role === roles.KIWI_ADMIN || user.role === roles.ACCOUNT_MANAGER;

  const canInternalInformation =
    checkGrantProperties('model.application', 'read', 'affiliate_id', companyDetails.data.name) &&
    checkGrantProperties('model.application', 'read', 'status', companyDetails.data.name) &&
    checkGrantProperties('model.application', 'read', 'intended_use', companyDetails.data.name);

  const product = products.data
    ? products.data.find(e => e.value === solution.data.productType)
    : null;

  return (
    <Container>
      <Stack spacing="medium">
        <Stack direction="row" justify="between" align="center">
          <Heading>{solution.data.name}</Heading>
          <ProductInfo>
            <span>{`${polyglot.t('solution.product')}: `}</span>
            <span>{product.name}</span>
          </ProductInfo>
        </Stack>

        {canInternalInformation && (
          <SolutionInternalInformation
            solution={solution.data}
            companyName={companyDetails.data.name}
          />
        )}

        <SolutionDetails
          solution={solution.data}
          companyDetails={companyDetails.data}
          canEditQuota={canEditQuota}
          product={product}
        />
        <Authorization resource="model.commission" action={['read', 'read_own']}>
          <Commercials solution={solution.data} />
        </Authorization>
      </Stack>
    </Container>
  );
};

export default Solution;
