import React, { useState } from 'react';
import * as dfs from 'date-fns';

import Modal, { ModalSection, ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';
import Datepicker from 'components/common/Datepicker';

const currentDate = new Date();
const previousMonth = dfs.subMonths(currentDate, 1);

const PAST_DATES = [
  {
    id: 'lastmonth',
    dates: {
      startDate: dfs.startOfMonth(previousMonth),
      endDate: dfs.endOfMonth(previousMonth),
    },
    label: 'billing.filter_by_date.modal.radio_last_month.label',
  },
  {
    id: 'last6months',
    dates: {
      startDate: dfs.startOfMonth(dfs.subMonths(currentDate, 6)),
      endDate: dfs.endOfMonth(previousMonth),
    },
    label: 'billing.filter_by_date.modal.radio_last_six_months.label',
  },
  {
    id: 'lastyear',
    dates: {
      startDate: dfs.startOfMonth(dfs.subMonths(currentDate, 12)),
      endDate: dfs.endOfMonth(previousMonth),
    },
    label: 'billing.filter_by_date.modal.radio_last_year.label',
  },
];

const DateFilterModal = ({ onClose, selectedDate, onClick }) => {
  const polyglot = usePolyglot();
  const [selectedCurrentDate, setSelectedCurrentDate] = useState(
    !!selectedDate.beginDate && !!selectedDate.endDate
      ? selectedDate
      : {
          beginDate: null,
          endDate: null,
        },
  );

  return (
    <Modal closable onClose={onClose} size="small">
      <ModalHeader description="Filter by date" />
      <ModalSection>
        <Stack direction="row">
          <Stack shrink direction="column" spacing="XLarge">
            {PAST_DATES.map(option => (
              <Radio
                key={option.id}
                checked={
                  new Date(selectedCurrentDate.beginDate).getTime() ===
                    new Date(option.dates.startDate).getTime() &&
                  new Date(selectedCurrentDate.endDate).getTime() ===
                    new Date(option.dates.endDate).getTime()
                }
                onChange={() =>
                  setSelectedCurrentDate({
                    beginDate: option.dates.startDate,
                    endDate: option.dates.endDate,
                  })
                }
                label={polyglot.t(option.label)}
              />
            ))}
          </Stack>
          <Datepicker
            pages={1}
            handleChange={newDates => {
              setSelectedCurrentDate({
                beginDate: newDates.startDate,
                endDate: newDates.endDate,
              });
            }}
            startDate={selectedCurrentDate.beginDate}
            endDate={selectedCurrentDate.endDate}
            startMonth={dfs.startOfMonth(previousMonth)}
            disabledDates={{
              min: dfs.subYears(new Date(), 1000),
              max: dfs.endOfMonth(previousMonth),
            }}
          />
        </Stack>
      </ModalSection>
      <ModalFooter>
        <Button
          onClick={() => setSelectedCurrentDate({ beginDate: null, endDate: null })}
          type="secondary"
        >
          {polyglot.t('billing.filter_by_date.modal.clear_date')}
        </Button>
        <Stack direction="row">
          <Button width="128px" type="secondary" onClick={onClose}>
            {polyglot.t('common.cancel')}
          </Button>
          <Button
            width="128px"
            onClick={() => {
              onClick(selectedCurrentDate);
              onClose();
            }}
            disabled={
              (selectedCurrentDate.beginDate && !selectedCurrentDate.endDate) ||
              JSON.stringify(selectedCurrentDate) === JSON.stringify(selectedDate)
            }
          >
            {polyglot.t('common.apply')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DateFilterModal;
