import styled from 'styled-components';

import { Space, Flex } from 'common';

export const TripContainer = styled(Flex)`
  width: 880px;
  margin-bottom: 16px;

  & ${Space} > div {
    width: 266.6px;
  }
`;
