import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IntervalContainer = styled.div`
  margin-top: 12px;
  width: 300px;
`;

export const NoDataFigureContainer = styled.div`
  width: 339px;
`;
