import { REPORT_TYPES } from 'consts/newReports';

export const mapSolutions = solutions =>
  solutions.map(solution => ({
    label: solution.affilId,
    type: solution.cooperationType,
    value: solution.affilId,
  }));

export const getReportType = type => {
  if (type === REPORT_TYPES.SALES) {
    return 'new_reports.type.sales';
  } else if (type === REPORT_TYPES.MARKETS) {
    return 'new_reports.type.markets';
  }

  return 'new_reports.type.none';
};

export const formatReportValues = value => {
  if (value === null || value === 'n/a') {
    return 'N/A';
  }

  return value.toString();
};

export const mapFrequencyToTranslation = frequency => {
  switch (frequency) {
    case 'daily':
      return 'new_reports.report_form.scheduled_delivery.daily.label';
    case 'weekly':
      return 'new_reports.report_form.scheduled_delivery.weekly.label';
    case 'monthly':
      return 'new_reports.report_form.scheduled_delivery.monthly.label';
    default:
      return '';
  }
};
