import React, { Suspense, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AuthState } from 'components/services/auth/AuthProvider';
import { getInitialOnboardingValue } from 'components/services/localStorage/useOnboardingState';
import MenuProvider from 'components/services/menu/MenuProvider';

import * as messagesActions from 'redux/modules/messages';
import * as reportsActions from 'redux/modules/reports';

import { Docs } from 'pages';
import CompanyList from 'components/scenes/CompanyList';
import Company from 'components/scenes/Company';
import CustomerDemandDashboard from 'components/scenes/CustomerDemandDashboard';
import DestinationDemandDashboard from 'components/scenes/DestinationDemandDashboard';
import Dashboard from 'components/scenes/Dashboard';
import UserManagement from 'components/scenes/UserManagement';
import SmartPass from 'components/scenes/SmartPass';
import Widgets from 'components/scenes/Widgets';
import EditBilling from 'components/scenes/EditBilling';
import MySolutions from 'components/scenes/MySolutions';
import ManageMyBookings from 'components/scenes/ManageMyBookings';
import DeeplinkGenerator from 'components/scenes/DeeplinkGenerator';
import BidViewer from 'components/scenes/BidViewer';
import Search from 'components/scenes/Search';
import Booking from 'components/scenes/Booking';
import PartnerPayments from 'components/scenes/PartnerPayments';
import GettingStarted from 'components/scenes/GettingStarted';
import Billing from 'components/scenes/Billing';
import AdminDashboard from 'components/scenes/AdminDashboard';
import DataMarketplace from 'components/scenes/DataMarketplace';
import Reports from '../Reports';
import Help from 'components/scenes/Help';
import CustomerSupport from 'components/scenes/CustomerSupport';

import User from 'shapes/User';
import { ROUTE_CONFIG } from 'consts/routes';
import { GETTING_STARTED_ROLES } from 'consts/roles';
import { isCustomerSupportEnabled } from 'consts/support';
import { NEXT_GEN_AUTH_COOKIE_NAME } from 'consts/index';
import { getCookie } from 'utils/cookie';
import * as nextGenApi from 'utils/api';
import { OrbitLoader, DesktopContainer } from 'common';
import { Authorization } from 'common/Authorization';
import { Content, Navigation } from './components';
import useImpersonate from 'hooks/useImpersonate';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProtectRoute = ({ resource, action, user, redirect, ...props }) => {
  if (redirect) {
    return <Redirect to={{ pathname: redirect }} />;
  }

  return (
    <Authorization
      action={action}
      resource={resource}
      loader={
        <LoaderContainer>
          <OrbitLoader id="page-loader" visible />
        </LoaderContainer>
      }
      companyName={props?.computedMatch?.params?.companyName}
      fallback={
        <Route
          path={props.path}
          render={() => <Redirect to={`${ROUTE_CONFIG.COMPANY.toPath}/${user.companyName}`} />}
        />
      }
    >
      <Route {...props} />
    </Authorization>
  );
};

const Portal = React.memo(({ history, location, stopPollingReport, ...props }) => {
  const { getSession, batchCan, loadingBatch, user } = useContext(AuthState);
  const [loggingIn, setLoggingIn] = useState(true);

  const [impersonatingUser] = useImpersonate();

  const companyName = useUsersCompanyName();
  const specialApps = useSpecialApps(companyName);

  const customerDemandActive = !specialApps?.demandDashboard?.deleted;
  const dataMarketplaceActive = !specialApps?.dataMarketplace?.deleted;

  useEffect(() => {
    const nextGenToken = getCookie(NEXT_GEN_AUTH_COOKIE_NAME);
    if (nextGenToken) {
      nextGenApi.setAuthToken(nextGenToken, impersonatingUser.uuid);
      getSession()
        .then(u => {
          batchCan(u);
          setLoggingIn(false);
        })
        .catch(error => {
          setLoggingIn(false);
        });
    } else {
      setLoggingIn(false);
    }

    history.listen((newLocation, action) => {
      const { pathname: oldRoute } = location;
      const { pathname: newRoute } = newLocation;
      stopPollingReport();
      if (oldRoute !== newRoute) {
        props.clearErrorMessages();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatingUser.uuid]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const onboarding =
      JSON.parse(localStorage.getItem(`${user?.email}_onboarding`)) ||
      getInitialOnboardingValue(user);

    if (
      history.location.pathname === '/' &&
      !onboarding.done &&
      GETTING_STARTED_ROLES.includes(user?.role)
    ) {
      history.push(ROUTE_CONFIG.GETTING_STARTED.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loggingIn || loadingBatch) {
    return (
      <LoaderContainer>
        <OrbitLoader id="page-loader" visible />
      </LoaderContainer>
    );
  }

  if (!user) {
    return <Redirect to={{ pathname: ROUTE_CONFIG.LOGIN.path, state: { from: location } }} />;
  }
  const isDocs = location.pathname.startsWith('/docs');

  return (
    <MenuProvider>
      <DesktopContainer>
        <Navigation isDocs={isDocs} />
        <Content isDocs={isDocs}>
          <Suspense fallback={<div />}>
            <Switch>
              <ProtectRoute
                path={ROUTE_CONFIG.GETTING_STARTED.path}
                component={GettingStarted}
                //temp to redirect booking agents and developers until we get proper permissions for onboarding page
                resource="model.bank_account"
                action={['update', 'update_assigned', 'update_own']}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.dashboard"
                action={['read']}
                path={ROUTE_CONFIG.DASHBOARD.path}
                component={Dashboard}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.deeplink_generator"
                action={['read']}
                path={ROUTE_CONFIG.DEEPLINK_GENERATOR.path}
                component={DeeplinkGenerator}
                user={user}
              />
              <ProtectRoute
                resource="model.bank_account"
                action={['update', 'update_assigned', 'update_own']}
                path={ROUTE_CONFIG.COMPANY_BILLING.path}
                component={EditBilling}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.reports"
                action={['read']}
                path={ROUTE_CONFIG.REPORTS.path}
                component={Reports}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.user_management"
                action={['read']}
                path={ROUTE_CONFIG.USER_MANAGEMENT.path}
                component={UserManagement}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.resources"
                action={['read']}
                path={ROUTE_CONFIG.RESOURCES.path}
                user={user}
                render={() => <Redirect to={ROUTE_CONFIG.DOCS.path} />}
              />
              <ProtectRoute
                resource="fe.page.resources"
                action={['read']}
                path={ROUTE_CONFIG.DOCS.path}
                component={Docs}
                user={user}
              />
              <ProtectRoute
                resource="special_app.smartpass"
                action={['read', 'read_assigned', 'read_own']}
                path={ROUTE_CONFIG.COMPANY_SMART_PASS.path}
                component={SmartPass}
                user={user}
              />
              <ProtectRoute
                resource="special_app.bidviewer"
                action={['read']}
                path={ROUTE_CONFIG.COMPANY_BIDVIEWER.path}
                component={BidViewer}
                user={user}
              />
              <ProtectRoute
                resource="model.company"
                action={['read', 'read_own']}
                path={ROUTE_CONFIG.COMPANY.path}
                component={Company}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.companies"
                action={['read']}
                path={ROUTE_CONFIG.COMPANIES.path}
                component={CompanyList}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.widget_builder"
                action={['read']}
                path={ROUTE_CONFIG.WIDGETS.path}
                component={Widgets}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.applications"
                action={['read']}
                path={ROUTE_CONFIG.MY_SOLUTIONS.path}
                component={MySolutions}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.mmb"
                action={['read']}
                path={ROUTE_CONFIG.MANAGE_MY_BOOKINGS.path}
                component={ManageMyBookings}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.partner_payments"
                action={['read']}
                path={ROUTE_CONFIG.PARTNER_PAYMENTS.path}
                component={PartnerPayments}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.help"
                action={['read']}
                path={ROUTE_CONFIG.HELP.path}
                component={Help}
                user={user}
              />
              {isCustomerSupportEnabled() && (
                <ProtectRoute
                  resource="fe.page.customer_support"
                  action={['read']}
                  path={ROUTE_CONFIG.CUSTOMER_SUPPORT.path}
                  component={CustomerSupport}
                  user={user}
                />
              )}
              <ProtectRoute
                resource="model.cooperation"
                action={['read']}
                path={ROUTE_CONFIG.ADMIN_DASHBOARD.path}
                component={AdminDashboard}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.kiwicom.search"
                action={['read']}
                path={ROUTE_CONFIG.KIWI_SEARCH.path}
                component={Search}
                user={user}
              />
              <ProtectRoute
                resource="fe.page.kiwicom.search"
                action={['read']}
                path={[ROUTE_CONFIG.KIWI_BOOKING.path, ROUTE_CONFIG.KIWI_BOOKING_SUCCESS.path]}
                component={Booking}
                user={user}
              />
              <ProtectRoute
                //temporary until we get fe.page.billing
                resource="model.billing_job"
                action={['index']}
                path={ROUTE_CONFIG.BILLING.path}
                component={Billing}
                user={user}
              />
              <ProtectRoute
                resource={ROUTE_CONFIG.CUSTOMER_DEMAND_DASHBOARD.resource}
                action={ROUTE_CONFIG.CUSTOMER_DEMAND_DASHBOARD.action}
                path={ROUTE_CONFIG.CUSTOMER_DEMAND_DASHBOARD.path}
                component={CustomerDemandDashboard}
                user={user}
                redirect={!customerDemandActive}
              />
              <ProtectRoute
                resource={ROUTE_CONFIG.DESTINATION_DEMAND_DASHBOARD.resource}
                action={ROUTE_CONFIG.DESTINATION_DEMAND_DASHBOARD.action}
                path={ROUTE_CONFIG.DESTINATION_DEMAND_DASHBOARD.path}
                component={DestinationDemandDashboard}
                user={user}
              />
              <ProtectRoute
                path={ROUTE_CONFIG.DATA_MARKETPLACE.path}
                resource={ROUTE_CONFIG.DATA_MARKETPLACE.resource}
                action={ROUTE_CONFIG.DATA_MARKETPLACE.action}
                user={user}
                redirect={!dataMarketplaceActive}
                component={DataMarketplace}
              />
              <ProtectRoute
                resource="fe.page.applications"
                action={['read']}
                path={ROUTE_CONFIG.MY_SOLUTIONS.oldPath}
                redirect={ROUTE_CONFIG.MY_SOLUTIONS.path}
              />

              <ProtectRoute
                resource="fe.page.company"
                action={['read', 'read_own']}
                user={user}
                render={() => (
                  <Redirect to={`${ROUTE_CONFIG.COMPANY.toPath}/${user.companyName}`} />
                )}
              />
            </Switch>
          </Suspense>
        </Content>
      </DesktopContainer>
    </MenuProvider>
  );
});

Portal.propTypes = {
  user: PropTypes.shape(User),
};

const mapDispatchToProps = {
  clearErrorMessages: messagesActions.clearErrorMessages,
  stopPollingReport: reportsActions.stopPollingReport,
};

export default withRouter(connect(null, mapDispatchToProps)(Portal));
