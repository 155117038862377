const v1_get_from = (solution, apiProducts) => ({
  productType: apiProducts?.find(product => solution.api_product_id === product.id)?.name,
  affilId: solution.affiliate_id,
  consumerKey: solution.api_key,
  name: solution.name,
  status: solution.status,
  approvalDate: new Date(solution?.created_at),
});

export default {
  v1: { get: { from: v1_get_from } },
};
