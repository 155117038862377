import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ErrorMessage } from 'formik';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';

import { SelectCountry, ErrorMessageComponent } from 'common';
import Polyglot from 'shapes/Polyglot';
import { COMPANY_TYPES } from 'consts/company';
import { countriesWithICO, nonSanctionedCountries } from 'consts/countries';
import { getVatIdLabel } from 'utils/company';
import { cantContainCyrillic } from 'utils/validation';
import { ZIP_REGEX } from 'consts/countries';

const LegalAddress = ({
  polyglot,
  values,
  touched,
  detectedCountry,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
}) => {
  const { type } = values;
  useEffect(() => {
    if (detectedCountry && !values.country) {
      const country = detectedCountry.toLowerCase();
      if (nonSanctionedCountries.find(c => c.value === country)) {
        setFieldValue('country', country);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spaceAfter="largest" spacing="large">
      <Heading type="title2" element="h2">
        {values.type === COMPANY_TYPES.COMPANY
          ? polyglot.t('company.legal_address')
          : polyglot.t('company.address')}
      </Heading>

      {type === COMPANY_TYPES.COMPANY && (
        <InputField
          id="register-legal-name"
          label={polyglot.t('company.legal_name')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="legalName"
          value={values.legalName}
          error={touched.legalName && errors.legalName && polyglot.t(errors.legalName)}
        />
      )}

      <InputField
        id="register-address1"
        label={polyglot.t('company.address1')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="address1"
        value={values.address1}
        error={touched.address1 && errors.address1 && polyglot.t(errors.address1)}
      />

      <SelectCountry
        onChange={handleChange}
        onBlur={handleBlur}
        label={polyglot.t('company.country')}
        name="country"
        value={values.country}
        error={touched.country && errors.country && polyglot.t(errors.country)}
      />

      {type === COMPANY_TYPES.COMPANY && !countriesWithICO.includes(values.country) && (
        <InputField
          id="register-VAT"
          label={polyglot.t(getVatIdLabel(values.country))}
          onChange={handleChange}
          onBlur={handleBlur}
          name="vatId"
          value={values.vatId}
          error={touched.vatId && errors.vatId && polyglot.t(errors.vatId)}
        />
      )}

      {type === COMPANY_TYPES.COMPANY && countriesWithICO.includes(values.country) && (
        <Stack spaceAfter="large" justify="between" direction="row" spacing="XSmall">
          <InputField
            id="register-ico"
            label="IČO"
            onChange={handleChange}
            onBlur={handleBlur}
            name="ico"
            value={values.ico}
            error={touched.ico && errors.ico && polyglot.t(errors.ico)}
          />
          <InputField
            id="register-dic"
            label="DIČ"
            onChange={handleChange}
            onBlur={handleBlur}
            name="dic"
            value={values.dic}
            error={touched.dic && errors.dic && polyglot.t(errors.dic)}
          />
        </Stack>
      )}

      {type === COMPANY_TYPES.COMPANY && values.country === 'cz' && (
        <Stack spacing="XXXSmall">
          <Checkbox
            name="czTaxPayer"
            checked={values.czTaxPayer}
            label={polyglot.t('company.cz_tax_payer')}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <ErrorMessage
            name="czTaxPayer"
            render={msg => <ErrorMessageComponent>{msg}</ErrorMessageComponent>}
          />
        </Stack>
      )}

      <Stack direction="row" spacing="XSmall">
        <InputField
          id="register-city"
          label={polyglot.t('company.city')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="city"
          value={values.city}
          error={touched.city && errors.city && polyglot.t(errors.city)}
        />
        <InputField
          id="register-zip"
          label={polyglot.t('company.zip')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="zip"
          value={values.zip}
          error={touched.zip && errors.zip && polyglot.t(errors.zip)}
        />
      </Stack>
    </Stack>
  );
};

LegalAddress.schema = Yup.object().shape({
  legalName: Yup.string().when('type', {
    is: COMPANY_TYPES.COMPANY,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
      .min(3, 'validation.too_short')
      .max(255, 'validation.length_exceeded'),
  }),
  address1: Yup.string()
    .required('validation.cant_be_empty')
    .max(255, 'validation.length_exceeded'),
  city: Yup.string().required('validation.cant_be_empty').max(255, 'validation.length_exceeded'),
  zip: Yup.string()
    .required('validation.cant_be_empty')
    .min(2, 'validation.too_short')
    .matches(ZIP_REGEX, 'validation.latin_chars')
    .max(10, 'validation.length_exceeded'),
  country: Yup.string().required('validation.cant_be_empty').nullable(),
  vatId: Yup.string().when(['type', 'country'], {
    is: (type, country) => type === COMPANY_TYPES.COMPANY && !countriesWithICO.includes(country),
    then: Yup.string().required('validation.cant_be_empty'),
  }),
  ico: Yup.string().when(['type', 'country'], {
    is: (type, country) => type === COMPANY_TYPES.COMPANY && countriesWithICO.includes(country),
    then: Yup.string().required('validation.cant_be_empty'),
  }),
  dic: Yup.string().max(255, 'validation.length_exceeded'),
});

LegalAddress.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LegalAddress;
