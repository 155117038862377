import styled from 'styled-components';
import PropTypes from 'prop-types';

const SIZES = {
  xs: '2px',
  s: '4px',
  m: '8px',
  n: '12px',
  l: '16px',
  xl: '24px',
  xxl: '32px',
  xxxl: '64px',
};

const Space = styled.div`
  ${({ inline }) => inline && 'display: inline'};
  ${({ before }) => before && `margin-top: ${SIZES[before]}`};
  ${({ after }) => after && `margin-bottom: ${SIZES[after]}`};
  ${({ left }) => left && `margin-left: ${SIZES[left]}`};
  ${({ right }) => right && `margin-right: ${SIZES[right]}`};
`;

Space.propTypes = {
  left: PropTypes.oneOf([...Object.keys(SIZES)]),
  right: PropTypes.oneOf([...Object.keys(SIZES)]),
  before: PropTypes.oneOf([...Object.keys(SIZES)]),
  after: PropTypes.oneOf([...Object.keys(SIZES)]),
};

export default Space;
