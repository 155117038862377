import { useEffect, useState } from 'react';
import debounce from 'debounce';

import throttle from 'utils/throttle';

const events = new Set();
const onResize = () => events.forEach(f => f());

/**
 * @param {'throttle' | 'debounce'} restrictFunction
 *
 */
const useWindowSize = (restrictFunction = 'throttle') => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const restrictor = restrictFunction === 'throttle' ? throttle : debounce;

  const handle = restrictor(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, 300);

  useEffect(() => {
    if (events.size === 0) {
      window.addEventListener('resize', onResize, true);
    }

    events.add(handle);

    return () => {
      events.delete(handle);

      if (events.size === 0) {
        window.removeEventListener('resize', onResize, true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return size;
};

export default useWindowSize;
