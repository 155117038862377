import React from 'react';
import styled, { css } from 'styled-components';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';

const Container = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
`;

const Content = styled.div`
  padding: ${({ nested }) => (nested ? '16px' : '0 24px 24px')};
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ nested }) => (nested ? 16 : 24)}px;

  ${({ nested }) =>
    nested &&
    css`
      & + ${Content} {
        border-top: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
      }
    `}
`;

const Title = styled.div`
  text-transform: initial;
`;

const Subtitle = styled.div`
  margin-top: 4px;
`;

const AlertWrapper = styled.div`
  padding: 0 24px 24px;
`;

const Wrapper = styled.div`
  align-self: center;
`;

const Card = ({ title, subtitle, children, action, nested, alert, showTitle = true, dataTest }) => {
  return (
    <Container data-test={dataTest}>
      {showTitle && (
        <CardTitle nested={nested}>
          <Wrapper>
            <Heading type={nested ? 'title4' : 'title3'} as={Title}>
              {title}
            </Heading>

            {subtitle && (
              <Subtitle>
                <Text type="primary" weight="normal">
                  {subtitle}
                </Text>
              </Subtitle>
            )}
          </Wrapper>

          {action}
        </CardTitle>
      )}

      {alert && <AlertWrapper>{alert}</AlertWrapper>}

      {children && <Content nested={nested}>{children}</Content>}
    </Container>
  );
};

export default Card;
