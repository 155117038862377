import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import tequilaLogo from 'images/tequila_logo.png';
import tequilaLogoType from 'images/tequila_logotype.png';
import tequilaDocs from 'images/tequila_docs.png';

import { ROUTE_CONFIG } from 'consts/routes';

const Wrapper = styled.div`
  height: 31px;
`;

const TequilaLogotype = ({ collapsed }) => {
  const location = useLocation();
  const isDocs = location.pathname.startsWith('/docs');

  if (isDocs) {
    return (
      <Wrapper>
        <Link to={ROUTE_CONFIG.DOCS.path}>
          <img src={tequilaDocs} alt="Tequila docs" />
        </Link>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {collapsed && <img src={tequilaLogo} alt="Tequila logo" />}
      {!collapsed && <img src={tequilaLogoType} alt="Tequila" />}
    </Wrapper>
  );
};

TequilaLogotype.propTypes = {
  collapsed: PropTypes.bool,
  isDocsPage: PropTypes.bool,
};

export default TequilaLogotype;
