import { useEffect, useRef } from 'react';
import * as R from 'ramda';

export default function useDeepEffect(fn, deps) {
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => R.equals(obj, deps[index]));

    if (!isSame) {
      fn();
    }

    prevDeps.current = deps;
  }, [deps, fn]);
}
