import React, { useContext } from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { FitContentWidthContainer } from './VoucherPreviewModal/components/StandardVoucher.styled';

import fastTrackVoucherExample from '../../../images/fastTrackVoucherExample.png';
import fastTrackVoucherQR from '../../../images/fastTrackQR.png';

const Image = styled.img`
  margin-bottom: 24px;
  width: 812px;
  height: 168px;
`;

const FastTrackVoucherModal = () => {
  const { fastTrackVoucherModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();
  return (
    <FitContentWidthContainer>
      <Modal closable onClose={fastTrackVoucherModal.setOff}>
        <ModalHeader title={polyglot.t('smart_pass.fast_track_voucher_modal.title')} />
        <ModalSection>
          <Text spaceAfter="small" size="large" weight="bold">
            {polyglot.t('smart_pass.form.own')}
          </Text>
          <Alert
            spaceAfter="large"
            icon={<InformationCircleIcon />}
            title={polyglot.t('smart_pass.alert_title')}
          >
            <Text type="info">{polyglot.t('smart_pass.no_localisation')}</Text>
            <Text type="info">{polyglot.t('smart_pass.size_812_168')}</Text>
          </Alert>
          <Image src={fastTrackVoucherQR} alt="Fast Track Voucher QR code" />

          <Text spaceAfter="small" size="large" weight="bold">
            {polyglot.t('smart_pass.form.standard')}
          </Text>
          <Image src={fastTrackVoucherExample} alt="Fast Track Voucher Example" />
        </ModalSection>
      </Modal>
    </FitContentWidthContainer>
  );
};

export default FastTrackVoucherModal;
