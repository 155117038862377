import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

const getLeftMargin = ({ size }) => {
  switch (size) {
    case 'small':
      return -4;
    case 'large':
      return -8;
    case 'medium':
    default:
      return -6;
  }
};

const Wrapper = styled.div`
  display: flex;

  div {
    border-radius: 50%;
    background: ${({ theme }) => theme.orbit.backgroundBody};

    img {
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.orbit.backgroundBody};
    }

    & + div {
      margin-left: ${getLeftMargin}px;
    }
  }
`;

const CarriersStack = ({ carriers, tooltips, size = 'medium' }) => {
  return (
    <Wrapper>
      {carriers.map((carrier, i) =>
        tooltips ? (
          <Tooltip
            content={`${tooltips[i].code} ${tooltips[i].number}`}
            preferredPosition="top"
            key={carrier + i}
          >
            <CarrierLogo carriers={[{ code: carrier }]} size={size} />
          </Tooltip>
        ) : (
          <CarrierLogo key={carrier + i} carriers={[{ code: carrier }]} size={size} />
        ),
      )}
    </Wrapper>
  );
};

CarriersStack.propTypes = {
  carriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default CarriersStack;
