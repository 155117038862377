import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  100% {
    transform: rotate(359deg);
  }
`;

export const StyledImg = styled.img`
  align-self: center;
  margin-top: 64px;
  animation: ${spin} 1.75s linear 0s infinite forwards;
`;
