import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import { useForm, FormProvider } from 'react-hook-form/dist/index.ie11';

import Loading from '@kiwicom/orbit-components/lib/Loading';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import { ModalSection } from '@kiwicom/orbit-components/lib/Modal';

import ModalContent from './ModalContent';

const ReplyIssueSchema = Yup.object().shape({
  reply: Yup.string(),
  attachments: Yup.mixed(),
});

const Data = ({ onClose, issue, error }) => {
  const form = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(ReplyIssueSchema),
    defaultValues: {
      reply: undefined,
      attachments: [],
    },
  });

  if (error) {
    return (
      <ModalSection>
        <Stack justify="center" align="center" direction="column">
          <Illustration name="Error" />
          <Text weight="bold">{error.message || 'Error'}</Text>
        </Stack>
      </ModalSection>
    );
  }

  if (issue) {
    return (
      <FormProvider {...form}>
        <ModalContent onClose={onClose} issue={issue} />
      </FormProvider>
    );
  }

  return <Loading />;
};

export default Data;
