import React from 'react';

import Select from '@kiwicom/orbit-components/lib/Select';
import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';

import { nonSanctionedCountries } from 'consts/countries';
import { usePolyglot } from 'components/services/i18n';

const SelectCountry = ({ value, ...props }) => {
  const polyglot = usePolyglot();
  return (
    <Select
      label={polyglot.t('common.country')}
      options={nonSanctionedCountries}
      prefix={value && <CountryFlag code={value} />}
      value={value}
      placeholder={polyglot.t('register.select_country')}
      {...props}
    />
  );
};

export default SelectCountry;
