import React from 'react';
import * as R from 'ramda';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { useInfoMessages } from 'components/services/bookingTool';

const InfoMessages = () => {
  const polyglot = usePolyglot();
  const { state: infoMessages, closeInfoMessage } = useInfoMessages();

  if (R.isEmpty(infoMessages)) {
    return null;
  }

  return Object.values(infoMessages).map(({ type, id, content, isClosed }) => {
    if (isClosed) {
      return null;
    }

    const onClose = () => closeInfoMessage(id);

    return (
      <Stack key={id} spaceAfter="large">
        <Alert
          type={type}
          title={polyglot.t(`searchAndBooking.searchResults.info_message.${id}.title`)}
          onClose={onClose}
          icon
          closable
        >
          {polyglot.t(`searchAndBooking.searchResults.info_message.${id}.content`, { content })}
        </Alert>
      </Stack>
    );
  });
};

export default InfoMessages;
