import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import * as sanitizeHtml from 'sanitize-html';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import { Badge, AlertButton } from '@kiwicom/orbit-components';
import TicketIcon from '@kiwicom/orbit-components/lib/icons/Ticket';

import { RequestType } from 'shapes/Support';
import Polyglot from 'shapes/Polyglot';
import User from 'shapes/User';
import { SERVICE_DESK } from 'consts/support';
import { ROUTE_CONFIG } from 'consts/routes';
import { STATUS } from 'redux/modules/apigee/requests';
import { proxy } from 'utils/api';
import { fieldIds } from 'redux/modules/apigee/support';
import { useToggle } from 'utils/hooks';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';
import { SANITIZE_CONFIG } from 'consts/sanitize';

import { MultilineParagraph, AttachmentsContainer } from '../index.styled';

import EditSupportRequest from './EditSupportRequest';
import ListOfMessages from './ListOfMessages';
import AttachmentInput from './AttachmentInput';
import Attachment from './Attachment';
import TransitionButtons from './TransitionButtons';

const POST_COMMENT_SCHEMA = Yup.object().shape({
  message: Yup.string()
    .max(2500, 'validation.length_exceeded')
    .required('validation.cant_be_empty'),
});

const LINK_CUSTOM_FIELDS = [
  'customfield_14352', // Company URL
];

function RequestDetails({
  polyglot,
  closeModal,
  request,
  requestType,
  user,
  clearAndFetchRequests,
  serviceDesk,
}) {
  const editing = useToggle();
  const loadMoreButton = useToggle();
  const [comments, setComments] = useState([]);
  const [postCommentStatus, setPostCommentStatus] = useState(STATUS.CLEAR);
  const [fetchCommentsStatus, setFetchCommentsStatus] = useState(STATUS.CLEAR);
  const history = useHistory();
  const location = useLocation();
  const { addBookingIframe } = useBookingDetailsIframes();

  function fetchComments(id, start = 0) {
    setFetchCommentsStatus(STATUS.LOADING);
    proxy.v1
      .get(`jira/${serviceDesk}/request/${id}/comment?start=${start}`)
      .then(res => {
        const commentsList = res.data.comments;
        const isLastPage = res.data.isLastPage;
        setComments(oldComments => [...oldComments, ...commentsList]);
        setFetchCommentsStatus(STATUS.VALID);
        if (isLastPage) {
          loadMoreButton.setOff();
        } else {
          loadMoreButton.setOn();
        }
      })
      .catch(() => setFetchCommentsStatus(STATUS.ERROR));
  }

  useEffect(() => {
    fetchComments(request.issueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function stopEditing() {
    editing.setOff();
    clearAndFetchRequests();
  }

  function handleLoadMoreClick() {
    fetchComments(request.issueId, comments.length);
  }

  function postComment(values) {
    const { message, temporaryAttachments } = values;
    const body = {
      body: sanitizeHtml(message, SANITIZE_CONFIG),
      temporaryAttachments,
    };

    setPostCommentStatus(STATUS.LOADING);

    proxy.v1
      .post(`jira/${serviceDesk}/request/${request.issueId}/comment`, JSON.stringify(body))
      .then(() => {
        setPostCommentStatus(STATUS.SUCCESS);
        clearAndFetchRequests();
      })
      .catch(() => setPostCommentStatus(STATUS.ERROR));
  }

  const { requestFieldValues, issueId, issueKey, currentStatus, reporter } = request;
  const summary = requestFieldValues.find(({ fieldId }) => fieldId === fieldIds.SUMMARY);
  const description = requestFieldValues.find(({ fieldId }) => fieldId === fieldIds.DESCRIPTION);
  const attachments = requestFieldValues.find(({ fieldId }) => fieldId === fieldIds.ATTACHMENT);
  const customFields = requestFieldValues.filter(({ fieldId }) =>
    fieldId.startsWith('customfield'),
  );
  const isOwner = reporter.emailAddress === user.email;
  const canDoTransition = serviceDesk === SERVICE_DESK.CSSD;

  const isMMB = location?.pathname.includes('manage-my-bookings');

  if (editing.isOn) {
    return (
      <Modal size="normal" closable onClose={closeModal}>
        <ModalSection>
          <EditSupportRequest
            polyglot={polyglot}
            issueId={issueId}
            summary={summary.value}
            description={description.value}
            stopEditing={stopEditing}
            cancelEditing={editing.setOff}
            serviceDesk={serviceDesk}
          />
        </ModalSection>
      </Modal>
    );
  }

  const MMBCustomField = customFields?.find(field => field.label === 'MMB Case Link');
  const bid = MMBCustomField?.value?.split('/').slice(-1)[0]; //last element in array is BID
  const nativeMMBlink = `${ROUTE_CONFIG.MANAGE_MY_BOOKINGS.path}/${bid}`;

  return (
    <Modal size="normal" closable onClose={closeModal}>
      <ModalSection>
        <Stack>
          <Stack direction="row">
            <Text type="secondary">{polyglot.t('support.filtering.issue_key')}</Text>
            <Text>{issueKey}</Text>
          </Stack>
          {requestType && (
            <Stack direction="row">
              <Text type="secondary">{polyglot.t('support.filtering.request_type')}</Text>
              <Text>{requestType.name}</Text>
            </Stack>
          )}
          <Stack direction="row">
            <Text type="secondary" size="normal">
              {polyglot.t('common.status')}
            </Text>
            <Badge type="warning">{currentStatus.status}</Badge>
          </Stack>
          {canDoTransition && (
            <TransitionButtons
              polyglot={polyglot}
              issueId={issueId}
              serviceDesk={serviceDesk}
              clearAndFetchRequests={clearAndFetchRequests}
            />
          )}
        </Stack>
      </ModalSection>
      {customFields.length > 0 && (
        <ModalSection>
          <Stack>
            {!isMMB && MMBCustomField && (
              <Alert
                icon={<TicketIcon />}
                inlineActions={
                  <AlertButton
                    onClick={() => {
                      addBookingIframe(Number(bid));
                      history.push(nativeMMBlink);
                    }}
                    type="info"
                  >
                    {polyglot.t('customer_support.custom_fields.mmb_case_link_button')}
                  </AlertButton>
                }
                title={polyglot.t('customer_support.custom_fields.mmb_case_link')}
                type="info"
              />
            )}
            {customFields.map(field => {
              const { fieldId, label, value } = field;
              const isLink = LINK_CUSTOM_FIELDS.includes(fieldId);

              if (label === 'MMB Case Link') {
                return null;
              }
              return (
                value && (
                  <Stack key={fieldId} direction="row">
                    <Text type="secondary">{label}</Text>
                    {isLink ? (
                      <TextLink external href={value} type="info">
                        {value}
                      </TextLink>
                    ) : (
                      <Text>{value.value || value}</Text>
                    )}
                  </Stack>
                )
              );
            })}
          </Stack>
        </ModalSection>
      )}
      <ModalSection>
        <Stack direction="row" spaceAfter="normal">
          <Heading type="title3" element="h3">
            {summary.value}
          </Heading>
          {isOwner && (
            <Stack grow justify="end" basis="0">
              <Button
                title="Start Editing"
                onClick={editing.setOn}
                type="secondary"
                size="small"
                iconLeft={<EditIcon size="small" />}
              />
            </Stack>
          )}
        </Stack>
        <MultilineParagraph>
          <Text type="secondary" spaceAfter="medium">
            {description?.value && sanitizeHtml(description.value, SANITIZE_CONFIG)}
          </Text>
        </MultilineParagraph>
        {!!attachments?.renderedValue.length && (
          <AttachmentsContainer>
            <Stack direction="row" spaceAfter="normal" align="center">
              <Text type="secondary">{polyglot.t('support.requestDetailsModal.attachments')}</Text>
              {attachments.renderedValue.map(a => (
                <Attachment
                  key={a.id}
                  content={a.content}
                  thumbnail={a.thumbnail}
                  filename={a.filename}
                  serviceDesk={serviceDesk}
                  polyglot={polyglot}
                  requestId={request.issueId}
                  withThumbnail={false}
                />
              ))}
            </Stack>
          </AttachmentsContainer>
        )}
      </ModalSection>
      <ModalSection>
        <Text type="secondary" size="large" spaceAfter="large">
          {polyglot.t('common.activity')}
        </Text>
        {postCommentStatus === STATUS.ERROR && (
          <Alert type="critical" spaceAfter="large">
            {polyglot.t('support.requestDetailsModal.sending_message_error')}
          </Alert>
        )}
        <ListOfMessages
          polyglot={polyglot}
          request={request}
          comments={comments}
          commentsStatus={fetchCommentsStatus}
          attachments={attachments}
          serviceDesk={serviceDesk}
        />
        {loadMoreButton.isOn && (
          <Button
            fullWidth
            onClick={handleLoadMoreClick}
            disabled={fetchCommentsStatus === STATUS.LOADING}
            spaceAfter="medium"
          >
            {polyglot.t('support.requestDetailsModal.load_more_comments')}
          </Button>
        )}
        <Formik
          initialValues={{ message: '', temporaryAttachments: [] }}
          validationSchema={POST_COMMENT_SCHEMA}
          onSubmit={postComment}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors, touched }) => (
            <Form>
              <Textarea
                name="message"
                label={polyglot.t('support.requestDetailsModal.sendMessage')}
                rows={5}
                resize="none"
                onChange={handleChange}
                value={values.message}
                error={touched.message && errors.message && polyglot.t(errors.message)}
                spaceAfter="large"
              />
              <AttachmentInput
                title={polyglot.t('support.createRequestModal.attachments')}
                uploadButtonText={polyglot.t('support.createRequestModal.uploadButton')}
                setFieldValue={setFieldValue}
                temporaryAttachments={values.temporaryAttachments}
                serviceDesk={serviceDesk}
                spaceAfter="large"
              />
              <Stack direction="row" justify="end">
                <Stack grow={false} shrink basis="25%">
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    loading={postCommentStatus === STATUS.LOADING}
                  >
                    {polyglot.t('common.send')}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </ModalSection>
    </Modal>
  );
}

RequestDetails.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  closeModal: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  requestType: PropTypes.shape(RequestType),
  user: PropTypes.shape(User).isRequired,
  clearAndFetchRequests: PropTypes.func.isRequired,
  serviceDesk: PropTypes.string.isRequired,
};

export default RequestDetails;
