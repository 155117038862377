import React, { useState, useEffect } from 'react';
import { startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';

import Text from '@kiwicom/orbit-components/lib/Text';
import Grid from '@kiwicom/orbit-components/lib/utils/Grid';

import { EmptyCell, WeekContainer } from './index.styled';
import Day from '../Day';

const weeks = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Calendar = ({
  date,
  handleChange,
  startDate,
  endDate,
  isSelecting,
  setIsSelecting,
  disabledDates,
}) => {
  const firstDayOfMonth = startOfMonth(date);
  const lastDateOfMonth = endOfMonth(date);
  const [daysOfMonth, setDaysOfMonth] = useState(
    eachDayOfInterval({ start: firstDayOfMonth, end: lastDateOfMonth }),
  );

  const returnEmptyFields = noFields => {
    const fields = [];
    for (let i = 0; i < noFields; i++) {
      fields.push(<EmptyCell key={i}></EmptyCell>);
    }

    return fields;
  };

  useEffect(() => {
    setDaysOfMonth(eachDayOfInterval({ start: firstDayOfMonth, end: lastDateOfMonth }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Grid rows="1fr 1fr 1fr 1fr 1fr 1fr" columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr">
      {weeks.map((week, index) => (
        <EmptyCell key={index} type="secondary">
          <WeekContainer type="secondary">
            <Text type="secondary">{week}</Text>
          </WeekContainer>
        </EmptyCell>
      ))}
      {returnEmptyFields(daysOfMonth[0].getUTCDay())}
      {daysOfMonth.map((day, index) => (
        <Day
          isSelecting={isSelecting}
          setIsSelecting={setIsSelecting}
          key={index}
          day={day}
          handleChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          disabledDates={disabledDates}
        />
      ))}
      {returnEmptyFields(5)}
    </Grid>
  );
};

export default Calendar;
