import React from 'react';

import { usePolyglot } from 'components/services/i18n';

import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import Text from '@kiwicom/orbit-components/lib/Text';

const CompanyWidgetsLoadingErrorAlert = ({ companyName, reload }) => {
  const polyglot = usePolyglot();

  return (
    <Alert title="Something went wrong." icon type="critical" spaceAfter="large">
      <Text spaceAfter="medium">
        {polyglot.t('widgets.company_widgets_fetch_fail')}{' '}
        <Text as="span" weight="bold">
          {companyName}
        </Text>
        .
      </Text>
      <AlertButton type="criticalSubtle" onClick={reload}>
        {polyglot.t('widgets.try_again')}
      </AlertButton>
    </Alert>
  );
};

export default CompanyWidgetsLoadingErrorAlert;
