import { Fragment } from 'react';
import { format, parseISO, sub } from 'date-fns';
import styled from 'styled-components';

import Inline from '@kiwicom/orbit-components/lib/Inline';
import Box from '@kiwicom/orbit-components/lib/Box';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card from '@kiwicom/orbit-components/lib/Card';
import CardSection from '@kiwicom/orbit-components/lib/Card/CardSection';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Text from '@kiwicom/orbit-components/lib/Text';
import TimelapseIcon from '@kiwicom/orbit-components/lib/icons/Timelapse';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import { usePolyglot } from 'components/services/i18n';
import { formatTripDuration, UTCToLocalDate } from 'utils/dates';

const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0px;
  column-gap: 12px;

  & > div {
    width: 110px;
    height: 0px;
  }
`;

const WholeTripCard = ({ itinerary, numberOfNights, departureTimeUTC, onClick }) => {
  const polyglot = usePolyglot();

  return (
    <Box display="flex" direction="column" width="100%">
      <Heading type="title2" spaceAfter="normal">
        {polyglot.t('mmb.booking_details.modal.trip_card.whole_trip.title')}
      </Heading>
      <Card spaceAfter="small">
        <CardSection>
          {itinerary.map((itineraryItem, index) => (
            <Fragment key={itineraryItem.id}>
              <Inline align="start" spacing="XLarge">
                <Inline align="center" spacing="XSmall">
                  <CarrierLogo carriers={[{ code: itineraryItem.carrier.code }]} />
                  <Text size="small" type="secondary">
                    {itineraryItem.carrier.name}
                  </Text>
                </Inline>
                <Box direction="column">
                  <Text size="small">
                    {`${itineraryItem.departure.time.local.substr(
                      itineraryItem.departure.time.local.indexOf('T') + 1,
                      5,
                    )} -
                ${itineraryItem.arrival.time.local.substr(
                  itineraryItem.arrival.time.local.indexOf('T') + 1,
                  5,
                )}`}
                  </Text>
                  <Text type="secondary" size="small">
                    {format(
                      new Date(itineraryItem.departure.time.local.slice(0, -6)),
                      'MMM dd yyyy',
                    )}
                  </Text>
                </Box>
                <Box direction="column">
                  <Text size="small">
                    {`${itineraryItem.departure.place.city} ${itineraryItem.departure.station.code} - ${itineraryItem.arrival.place.city} ${itineraryItem.arrival.station.code}`}
                  </Text>
                  <Text type="secondary" size="small">
                    {formatTripDuration(itineraryItem.duration / 1000)}
                  </Text>
                </Box>
              </Inline>
              {index % 2 === 0 && (
                <SeparatorWrapper>
                  <Separator />
                  {typeof numberOfNights === 'number' && (
                    <Text size="small" type="secondary">
                      {polyglot.t('common.itinerary.nights', {
                        night: numberOfNights,
                        nights: numberOfNights === 1 ? 'night' : 'nights',
                        arrival_city: itineraryItem.arrival.place.city,
                      })}
                    </Text>
                  )}
                </SeparatorWrapper>
              )}
            </Fragment>
          ))}
        </CardSection>
      </Card>
      <Box display="flex" direction="row" justify="between">
        <Inline align="center" spacing="XXSmall">
          <TimelapseIcon color="secondary" size="small" />
          <Text type="secondary" size="small">
            {polyglot.t('mmb.booking_details.modal.trip_card.bottom_text', {
              time: `${format(
                UTCToLocalDate(sub(parseISO(departureTimeUTC), { days: 1 })),
                'EEE dd MMM HH:mm',
              )} UTC`,
            })}
          </Text>
        </Inline>
        <Button
          dataTest="whole-find-new-flight"
          iconRight={<ChevronForwardIcon />}
          size="small"
          onClick={onClick}
        >
          {polyglot.t('mmb.booking_details.modal.trip_card.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default WholeTripCard;
