import styled, { css } from 'styled-components';

import { StyledText } from '@kiwicom/orbit-components/lib/Text';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const LegendItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledText} {
    font-size: 14px;
  }

  > div {
    margin: ${({ theme }) => `${theme.orbit.spaceXXSmall} 0`};
    flex-shrink: 1;
    flex-grow: 0;

    > svg {
      flex-shrink: 0;
    }
  }

  ${media.largeMobile(css`
    max-width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      margin: ${({ theme }) => `${theme.orbit.spaceXXSmall} ${theme.orbit.spaceXSmall}`};
    }
  `)}
`;
