export function ipToBinary(ip) {
  return ip
    .split('.')
    .map(octet => {
      return parseInt(octet, 10).toString(2).padStart(8, '0');
    })
    .join('.');
}

export function ipToDecimal(ip) {
  const binaryIp = ipToBinary(ip);

  return parseInt(binaryIp.split('.').join(''), 2);
}

function expandMask(mask) {
  let result = '';

  result += '1'.repeat(mask);
  result += '0'.repeat(Math.max(32 - mask, 0));

  return result;
}

/**
 * Validate if provided ip cidr is valid
 * Talk to Duško if this doesn't work correctly
 * @param {string} ipcidr
 * @returns {boolean}
 */
export function validateIPCIDR(ipcidr) {
  const [ip, strMask] = ipcidr.split('/');

  const leadingZerosRegex = /(^|\.)0+(\d)/;

  if (ipcidr.includes('/') && !strMask) {
    return false;
  }

  if (leadingZerosRegex.test(ip)) {
    return false;
  }

  if (strMask && strMask !== String(Number(strMask))) {
    return false;
  }

  const mask = strMask ? +strMask : 32;

  if (mask > 32 || mask < 0) {
    return false;
  }

  const decimalIp = ipToDecimal(ip);
  const decimalMask = parseInt(expandMask(mask), 2);

  return (~decimalMask & decimalIp) === 0;
}

// validate if entry is a valid ip address
export function validateIp(ip) {
  if (!ip) {
    return false;
  }

  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;

  return ipRegex.test(ip);
}
