import React from 'react';
import { Link } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import NewWindow from '@kiwicom/orbit-components/lib/icons/NewWindow';
import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';

import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';
import { useCan, useCurrentUser } from 'components/services/auth';

const NoWidgetSolutionsInfo = ({ selectedCompany, openCreateWidgetSolutionModal }) => {
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const canCreate = useCan(user, ['create'], 'model.application', selectedCompany);
  const canCreateOwn = useCan(user, ['create_own'], 'model.application', selectedCompany);
  const canCreateAssigned = useCan(user, ['create_assigned'], 'model.application', selectedCompany);

  return (
    <Stack direction="column" align="center" spacing="small" spaceAfter="largest">
      <Text align="center" weight="bold" size="large">
        {polyglot.t('widgets.no_solutions_almost_there')}
        <Text weight="normal" size="large">
          {polyglot.t('widgets.no_solutions_setup')}
        </Text>
      </Text>
      <Button
        disabled={!canCreate && !canCreateAssigned && !canCreateOwn}
        size="small"
        onClick={openCreateWidgetSolutionModal}
      >
        {polyglot.t('common.create_new_solution')}
      </Button>
      <Illustration name="Train" />
      <Link to={`${ROUTE_CONFIG.DOCS_USER_GUIDES.path}/creating_solution`} target="_blank">
        <TextLink iconRight={<NewWindow />} type="secondary">
          {polyglot.t('widgets.no_solutions_learn_more')}
        </TextLink>
      </Link>
    </Stack>
  );
};

export default NoWidgetSolutionsInfo;
