import { isProduction } from 'utils/env';

export const BIDVIEWER_APP_ID = 1;
export const SMART_PASS_APP_ID = isProduction() ? 4 : 14;
export const IP_WHITELIST = isProduction() ? 5 : 15;
export const DEMAND_DASHBOARD_ID = isProduction() ? 6 : 16;
export const DESTINATION_DEMAND_DASHBOARD_ID = isProduction() ? 7 : 17;
export const DASHBOARDS = ['demand-dashboard', 'destination-demand-dashboard'];
export const DATA_MARKETPLACE_ID = isProduction() ? 8 : 18;
export const REPORT_SCHEDULES_ID = isProduction() ? 9 : 19;
