import qs from 'query-string';
import { HOSTNAME } from 'consts/api';
import { pickByNotNil } from 'utils/object';

let TOKEN = null;

export class ApiError extends Error {
  constructor(message, status) {
    super(message);

    // Maintains proper stack trace for where our error was thrown
    Error.captureStackTrace(this, ApiError);

    // Custom debugging information
    this.date = new Date();
    this.msg = message;
    this.status = status;
  }
}

export function setToken(token) {
  TOKEN = token;
}

export function removeToken() {
  TOKEN = null;
}

export const getUrl = (endpoint, query) => {
  if (!query) {
    return `${HOSTNAME}${endpoint}`;
  }

  const safeQuery = pickByNotNil(query);
  return `${HOSTNAME}${endpoint}?${qs.stringify(safeQuery)}`;
};

export function get(endpoint, query = null) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + TOKEN,
    },
  };
  const url = getUrl(endpoint, query);
  return fetch(url, options).then(handleResponse, handleError);
}

export function post(endpoint, body) {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (TOKEN) {
    options.headers = { ...options.headers, Authorization: 'JWT ' + TOKEN };
  }
  const url = getUrl(endpoint);
  return fetch(url, options).then(handleResponse, handleError);
}

/**
 * Should be used only for updating a single property on a resource.
 * @param {*} endpoint URL
 * @param {*} body Plain JS Object
 */
export function patch(endpoint, body) {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (TOKEN) {
    options.headers = { ...options.headers, Authorization: 'JWT ' + TOKEN };
  }
  const url = getUrl(endpoint);
  return fetch(url, options).then(handleResponse, handleError);
}

/**
 * Should be used for updating all fields of a resource.
 * @param {*} endpoint URL
 * @param {*} body Plain JS Object
 */
export function put(endpoint, body) {
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (TOKEN) {
    options.headers = { ...options.headers, Authorization: 'JWT ' + TOKEN };
  }
  const url = getUrl(endpoint);
  return fetch(url, options).then(handleResponse, handleError);
}

/**
 * Sends a DELETE request. Would name it delete if I could, but it is a reserved word.
 * @param {*} endpoint
 * @param {*} query
 */
export function remove(endpoint, query) {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (TOKEN) {
    options.headers = { ...options.headers, Authorization: 'JWT ' + TOKEN };
  }
  const url = getUrl(endpoint);
  return fetch(url, options).then(handleResponse, handleError);
}

export function handleResponse(response) {
  const { headers, status, ok } = response;
  if (!ok) {
    return response.json().then(function (error) {
      throw new ApiError(error.message, response.status);
    });
  }

  if (headers.get('content-type') === 'application/json') {
    return response
      .json()
      .then(json => Promise.resolve({ json, headers: response.headers, status: response.status }));
  }

  return response.text().then(text => Promise.resolve({ json: {}, text, headers, status }));
}

export function handleError(error) {
  throw new ApiError('common.connection_problem');
}

export const getPaginationFromHeaders = response => ({
  pagesCount: Number(response.headers.get('pagesCount')),
  perPage: Number(response.headers.get('perPage')),
  totalCount: Number(response.headers.get('totalCount')),
});
