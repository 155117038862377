import React, { useReducer } from 'react';

import api, { getUrlWithQuery } from 'utils/api';
import transactionsMapper from 'mappers/nextGen/mmb/credit/transactions/_';

export const TransactionsState = React.createContext({
  state: {},
  loadTransactions: async () => [],
  loadMoreTransactions: async () => [],
  setFilterBid: async () => [],
  setFilterCreatedFrom: async () => [],
  setFilterCreatedTo: async () => [],
  setFilterTypes: async () => [],
  resetFilters: async () => [],
});

const TransactionsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_TRANSACTIONS':
      return {
        ...state,
        loadingTransactions: true,
        error: null,
      };
    case 'LOAD_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        loadingTransactions: false,
        transactions: action.transactions,
        hasMoreTransactions: action.nextPage,
      };
    case 'LOAD_TRANSACTIONS_FAIL':
      return {
        ...state,
        loadingTransactions: false,
        error: action.error,
      };
    case 'LOAD_MORE_TRANSACTIONS':
      return {
        ...state,
        loadingMoreTransactions: true,
        error: null,
      };
    case 'LOAD_MORE_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        loadingMoreTransactions: false,
        transactions: state.transactions.concat(action.transactions),
        hasMoreTransactions: action.nextPage,
      };
    case 'LOAD_MORE_TRANSACTIONS_FAIL':
      return {
        ...state,
        loadingTransactions: false,
        error: action.error,
      };
    case 'SET_FILTER_BID':
      return {
        ...state,
        filterBid: action.bid,
      };
    case 'SET_FILTER_CREATED_FROM':
      return {
        ...state,
        filterCreatedFrom: action.createdFrom,
      };
    case 'SET_FILTER_CREATED_TO':
      return {
        ...state,
        filterCreatedTo: action.createdTo,
      };
    case 'SET_FILTER_TYPE':
      if (state.filterTypes.includes(action.creditActivityType)) {
        return {
          ...state,
          filterTypes: state.filterTypes.filter(type => type !== action.creditActivityType),
        };
      }

      return {
        ...state,
        filterTypes: [...state.filterTypes, action.creditActivityType],
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

const INITIAL_STATE = {
  filterBid: undefined,
  filterCreatedFrom: '',
  filterCreatedTo: '',
  filterTypes: [],
};

const TransactionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TransactionsReducer, INITIAL_STATE);

  const loadTransactions = async (filters = {}) => {
    dispatch({ type: 'LOAD_TRANSACTIONS' });
    try {
      const query = getUrlWithQuery(
        '',
        transactionsMapper.v1.get.to({
          limit: 40,
          status: 'authenticated,success',
          ...filters,
        }),
      );
      const res = await api.v1.get(`mmb/credit/transactions${query}`);
      const transactions = res.data.transactions.map(transactionsMapper.v1.get.from);

      dispatch({
        type: 'LOAD_TRANSACTIONS_SUCCESS',
        transactions,
        nextPage: transactions.length === 40,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_TRANSACTIONS_FAIL', error });
    }
  };

  const loadMoreTransactions = async (filters = {}) => {
    dispatch({ type: 'LOAD_MORE_TRANSACTIONS' });
    try {
      const offset = state.transactions.length;

      const query = getUrlWithQuery(
        '',
        transactionsMapper.v1.get.to({
          limit: 40,
          offset,
          status: 'authenticated,success',
          ...filters,
        }),
      );
      const res = await api.v1.get(`mmb/credit/transactions${query}`);
      const transactions = res.data.transactions.map(transactionsMapper.v1.get.from);

      dispatch({
        type: 'LOAD_MORE_TRANSACTIONS_SUCCESS',
        transactions,
        nextPage: transactions.length === 40,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_MORE_TRANSACTIONS_FAIL', error });
    }
  };

  const setFilterBid = bid => {
    dispatch({ type: 'SET_FILTER_BID', bid });
  };

  const setFilterCreatedFrom = createdFrom => {
    dispatch({ type: 'SET_FILTER_CREATED_FROM', createdFrom });
  };

  const setFilterCreatedTo = createdTo => {
    dispatch({ type: 'SET_FILTER_CREATED_TO', createdTo });
  };

  const setFilterTypes = creditActivityType => {
    dispatch({ type: 'SET_FILTER_TYPE', creditActivityType });
  };

  const resetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
  };

  const value = {
    ...state,
    loadTransactions,
    loadMoreTransactions,
    setFilterBid,
    setFilterCreatedFrom,
    setFilterCreatedTo,
    setFilterTypes,
    resetFilters,
  };

  return <TransactionsState.Provider value={value}>{children}</TransactionsState.Provider>;
};

export default TransactionsProvider;
