import React, { useState, useContext, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import PassengerIcon from '@kiwicom/orbit-components/lib/icons/Passenger';
import Text from '@kiwicom/orbit-components/lib/Text';

import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';
import useCompanyUsers from 'components/services/companyUsers/useCompanyUsers';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useRoles from 'components/services/products/useRoles';
import { CompanyUsersState } from 'components/services/companyUsers';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import PromptModal from 'components/common/PromptModal';
import { Card, CardActionHeader, CardSection } from 'components/common/Card';
import { Authorization } from 'common/Authorization';
import UserModal from 'components/common/UserModal';
import { AuthState } from 'components/services/auth/AuthProvider';
import AccessDeniedErrorMessage from './AccessDeniedErrorMessage';
import useImpersonate from 'hooks/useImpersonate';
import { Pagination, SortArrow } from 'common';

const Container = styled.div`
  max-width: 960px;
  margin-top: 24px;
  margin-left: 24px;
`;

const TouchableIconContainer = styled.div`
  cursor: pointer;
`;

const Users = () => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const companyDetails = useCurrentCompanyDetails();
  const users = useCompanyUsers(companyName);
  const roles = useRoles();
  const currentUser = useCurrentUser();
  const createUserModal = useToggle();
  const {
    loadCompanyUsers,
    updateCompanyUser,
    createCompanyUser,
    removeCompanyUser,
    creatingUser,
    updatingUser,
    removingUser,
  } = useContext(CompanyUsersState);
  const { checkedGrants } = useContext(AuthState);
  const [error, setError] = useState(null);
  const additionalInfoNeeded = useToggle();
  const [successMessage, setSuccessMessage] = useState();

  const [editUserDraft, setEditUserDraft] = useState(null);
  const [removeUserDraft, setRemoveUserDraft] = useState(null);
  const [impersonateUserDraft, setImpersonateUserDraft] = useState(null);
  const history = useHistory();

  const [, setImpersonatingUser] = useImpersonate();

  const sort = users.sort;
  const pagination = users.pagination || {};

  const canOnlyUpdateSelf = useMemo(() => {
    const userGrants =
      checkedGrants?.[companyName]?.['model.user'] &&
      Object.entries(checkedGrants?.[companyName]?.['model.user']);

    if (userGrants) {
      const updateUserGrants = userGrants.filter(key => key[0].includes('update') && key[1]);
      if (updateUserGrants.length === 1 && updateUserGrants[0][0] === 'update_self') {
        return true;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = key => {
    let newSortKey = key;
    let newSortDirection = sort.dir;

    if (sort.key === newSortKey) {
      newSortDirection = sort.dir === '' ? '-' : '';
    }

    const newSort = {
      key: newSortKey,
      dir: newSortDirection,
    };

    loadCompanyUsers(companyName, pagination.page, pagination.perPage, newSort);
  };

  const handleError = err => {
    if (err?.response?.data?.message.toLowerCase() === 'kyc check failed.') {
      if (!additionalInfoNeeded.isOn) {
        additionalInfoNeeded.setOn();
        setError({ msg: polyglot.t('add_user.screen_additional_info'), type: 'warning' });
      } else {
        setError({
          title: polyglot.t('register.access_denied'),
          msg: <AccessDeniedErrorMessage />,
          type: 'critical',
        });
      }
    } else if (
      err?.response?.status === 409 &&
      err?.response?.data?.message.toLowerCase().includes('user')
    ) {
      setError({ msg: polyglot.t('register.error_user_exists') });
    } else {
      setError({ msg: polyglot.t('common.submit_error_message') });
    }
  };

  const cleanUpAndClose = ({ successMsg = null }) => {
    setError(null);
    setEditUserDraft(null);
    setRemoveUserDraft(null);
    setImpersonateUserDraft(null);
    additionalInfoNeeded.setOff();
    createUserModal.setOff();
    setSuccessMessage(successMsg);
  };

  const handleCreateUserSubmit = async user => {
    try {
      await createCompanyUser(user, companyName, additionalInfoNeeded.isOn);
      cleanUpAndClose({ successMsg: polyglot.t('company.user_add_success') });
    } catch (err) {
      handleError(err);
    }
  };

  const handleEditUserSubmit = async user => {
    try {
      await updateCompanyUser(user);
      cleanUpAndClose({ successMsg: polyglot.t('company.user_edit_success') });
    } catch (err) {
      handleError(err);
    }
  };

  const handleRemoveUserSubmit = async () => {
    try {
      await removeCompanyUser(removeUserDraft, companyName);
      cleanUpAndClose({ successMsg: polyglot.t('company.user_remove_success') });
    } catch (err) {
      handleError(err);
    }
  };

  const handleImpersonateUser = () => {
    setImpersonatingUser({ uuid: impersonateUserDraft.uuid });
    history.go(0);
  };

  const handleChangePage = (rowsPerPage, nextPage) => {
    loadCompanyUsers(companyName, nextPage, rowsPerPage, sort);
  };

  const loading = updatingUser || creatingUser || removingUser;

  return (
    <Container>
      {createUserModal.isOn && (
        <UserModal
          userBeingEdited={null}
          onSubmit={handleCreateUserSubmit}
          onClose={cleanUpAndClose}
          loading={loading}
          isAdditionalInfoNeeded={additionalInfoNeeded.isOn}
          error={error}
        />
      )}
      {editUserDraft && (
        <UserModal
          userBeingEdited={editUserDraft}
          onSubmit={handleEditUserSubmit}
          onClose={cleanUpAndClose}
          loading={loading}
          isAdditionalInfoNeeded={additionalInfoNeeded.isOn}
          error={error}
        />
      )}
      {removeUserDraft && (
        <PromptModal
          title={polyglot.t('modal_remove.user')}
          description={
            <Text size="medium" as="span">
              Are you sure you want to remove the user{' '}
              <Text size="medium" type="secondary" as="span">
                {removeUserDraft.firstName} {removeUserDraft.lastName}
              </Text>
              ?
            </Text>
          }
          loading={loading}
          onSubmit={handleRemoveUserSubmit}
          onClose={() => setRemoveUserDraft(null)}
          buttonColor="critical"
          submitText="Remove"
        />
      )}
      {impersonateUserDraft && (
        <PromptModal
          title={polyglot.t('company.user_impersonate')}
          description={
            <Text size="medium" as="span">
              Looks like you are about to impersonate{' '}
              <Text size="medium" type="secondary" as="span">
                {impersonateUserDraft.firstName} {impersonateUserDraft.lastName}
              </Text>
              , are you sure about this?
            </Text>
          }
          loading={loading}
          onSubmit={handleImpersonateUser}
          onClose={() => setImpersonateUserDraft(null)}
          submitText="Impersonate"
        />
      )}
      <Card>
        <CardActionHeader
          title={polyglot.t('company.users')}
          action={
            <Authorization
              resource="model.user"
              action={['create', 'create_own', 'create_assigned']}
            >
              <Button
                dataTest="add-user-button"
                type="primary"
                size="small"
                onClick={createUserModal.setOn}
              >
                {polyglot.t('company.add_user')}
              </Button>
            </Authorization>
          }
        />
        <CardSection
          showAlert={!!successMessage}
          alert={
            successMessage && (
              <Alert
                type="success"
                closable
                icon
                onClose={() => {
                  setSuccessMessage(null);
                }}
              >
                {successMessage}
              </Alert>
            )
          }
        >
          <Loading
            loading={
              users.data === null ||
              roles.data === null ||
              companyDetails.data === null ||
              users.loading
            }
            type="boxLoader"
            text={polyglot.t('common.loading')}
          >
            {users.data && roles.data && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TouchableIconContainer onClick={() => handleSort('last_name')}>
                          {polyglot.t('common.name')}
                          {sort.key === 'last_name' && <SortArrow ascending={sort.dir === ''} />}
                        </TouchableIconContainer>
                      </TableCell>
                      <TableCell align="left">
                        <TouchableIconContainer onClick={() => handleSort('email')}>
                          {polyglot.t('common.email')}
                          {sort.key === 'email' && <SortArrow ascending={sort.dir === ''} />}
                        </TouchableIconContainer>
                      </TableCell>
                      <TableCell align="left">
                        <TouchableIconContainer onClick={() => handleSort('role')}>
                          {polyglot.t('common.role')}
                          {sort.key === 'role' && <SortArrow ascending={sort.dir === ''} />}
                        </TouchableIconContainer>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.data.map(user => (
                      <TableRow key={user.email}>
                        <TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">
                          {roles.data.find(r => r.name === user.role)?.displayName}
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing="XXSmall">
                            <Authorization
                              resource="model.user"
                              action={['update', 'update_assigned', 'update_own', 'update_self']}
                            >
                              {canOnlyUpdateSelf
                                ? currentUser.email === user.email && (
                                    <Button
                                      dataTest={`edit-user-${user.email}`}
                                      type="secondary"
                                      size="small"
                                      iconLeft={<EditIcon />}
                                      title={`Edit ${user.firstName} ${user.lastName}`}
                                      onClick={() => setEditUserDraft(user)}
                                    />
                                  )
                                : !!roles.data.find(r => r.name === user.role) && (
                                    <Button
                                      dataTest={`edit-user-${user.email}`}
                                      type="secondary"
                                      size="small"
                                      iconLeft={<EditIcon />}
                                      title={`Edit ${user.firstName} ${user.lastName}`}
                                      onClick={() => setEditUserDraft(user)}
                                    />
                                  )}
                            </Authorization>
                            <Authorization resource="actions.internal" action={['impersonate']}>
                              <Tooltip content="Impersonate">
                                <Button
                                  dataTest={`impersonate-user-${user.email}`}
                                  type="secondary"
                                  size="small"
                                  title={`Impersonate  ${user.firstName} ${user.lastName}`}
                                  iconLeft={<PassengerIcon />}
                                  onClick={() => setImpersonateUserDraft(user)}
                                />
                              </Tooltip>
                            </Authorization>
                            <Authorization
                              resource="model.user"
                              action={['delete', 'delete_own', 'delete_assigned']}
                            >
                              {!!roles.data.find(r => r.name === user.role) &&
                                user.email !== companyDetails?.data?.adminEmail && (
                                  <Button
                                    dataTest={`remove-user-${user.email}`}
                                    type="criticalSubtle"
                                    size="small"
                                    title={`Remove ${user.firstName} ${user.lastName}`}
                                    iconLeft={<RemoveIcon />}
                                    onClick={() => setRemoveUserDraft(user)}
                                  />
                                )}
                            </Authorization>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  rows={users.pagination.totalCount}
                  rowsPerPage={users.pagination.perPage}
                  page={users.pagination.page}
                  rowsPerPageItems={[20, 50, 100]}
                  onPagination={handleChangePage}
                  witoutAll
                />
              </>
            )}
          </Loading>
        </CardSection>
      </Card>
    </Container>
  );
};

export default Users;
