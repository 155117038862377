import styled from 'styled-components';

export const QuotaInputContainer = styled.div`
  width: 200px;
  margin-right: 16px;
  height: 92px;
`;

export const QuotaText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 92px;
`;

export const QuotaIntervalContainer = styled.div`
  width: 95px;
  margin-right: 8px;
  height: 92px;

  & > div {
    margin-top: 2px;
    font-weight: 500;
    font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
    color: ${({ theme }) => theme.orbit.colorTextError};
    white-space: nowrap;
  }
`;

export const QuotaTimeUnitContainer = styled.div`
  width: 221px;
  height: 92px;
`;
