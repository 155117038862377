import React from 'react';

import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';

import useSearch from 'components/services/search/useSearch';
import { usePolyglot } from 'components/services/i18n';
import { cabinClassTypes } from 'consts/search';

import EmptyListChoiceIcon from './EmptyListChoiceIcon';

const FlightClass = () => {
  const polyglot = usePolyglot();
  const { tabData, changeCabinClassType } = useSearch();

  return cabinClassTypes.map(option => (
    <ListChoice
      key={option.value}
      icon={
        option.value === tabData.selectedCabinClassType ? <CheckIcon /> : <EmptyListChoiceIcon />
      }
      title={polyglot.t(option.label)}
      onClick={() => changeCabinClassType(option.value)}
    />
  ));
};

export default FlightClass;
