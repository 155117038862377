const v1_get_from = response => {
  const { data } = response;

  return {
    companyName: data.company_name,
    createdAt: data.created_at ? new Date(data.created_at) : null,
    createdBy: data.created_by,
    ipWhitelist: data.ip_whitelist,
    whitelistEnabled: data.ip_whitelist_enabled,
    updatedAt: data.updated_at ? new Date(data.updated_at) : null,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    put: {
      from: v1_get_from,
    },
  },
};
