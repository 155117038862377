import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

import ClosableAlert from './ClosableAlert';

const SomethingWentWrongAlert = () => {
  const polyglot = usePolyglot();
  return (
    <ClosableAlert
      title={polyglot.t('widgets.save_error_title')}
      timeout
      spaceAfter="normal"
      type="critical"
      icon
    >
      <Text>{polyglot.t('widgets.save_error_desc')}</Text>
    </ClosableAlert>
  );
};

export default SomethingWentWrongAlert;
