import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import useUiConfig from 'components/services/initialQueryParams/useUiConfig';
import Login from './scenes/Login';
import Landing from './scenes/Landing';
import ResetPassword from './scenes/ResetPassword';
import { ReactComponent as TequilaLogoTop } from 'images/tequila_logo_top.svg';
import { ReactComponent as TequilaLogoBottom } from 'images/tequila_logo_bottom.svg';

import {
  AuthContainer,
  BackgroundContainer,
  LogoContainer,
  Logo,
  RouteContainer,
  RouteWrapper,
  MobileContentWrapper,
  DescriptionWrapper,
} from './Auth.styled';

const Content = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_CONFIG.LOGIN.path} component={Login} />
      <Route path={ROUTE_CONFIG.LANDING.path} component={Landing} />
      <Route path={ROUTE_CONFIG.RESET_PASSWORD.path} component={ResetPassword} />
    </Switch>
  );
};

const Auth = () => {
  const { hideRegistrationBackground } = useUiConfig();
  const { isDesktop } = useMediaQuery();
  const polyglot = usePolyglot();

  useEffect(() => {
    const isScriptMounted = document.getElementById('recaptcha-key');

    if (!isScriptMounted) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      script.id = 'recaptcha-key';
      document.body.appendChild(script);
    }

    return () => {
      const script = document.getElementById('recaptcha-key');
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AuthContainer>
      {!hideRegistrationBackground && (
        <BackgroundContainer>
          <LogoContainer>
            <Logo upper center={!isDesktop}>
              <TequilaLogoTop />
            </Logo>

            {!isDesktop && (
              <>
                <MobileContentWrapper>
                  <Content />
                </MobileContentWrapper>

                <DescriptionWrapper>
                  <h3>{polyglot.t('auth.about')}</h3>
                  <p>{polyglot.t('auth.about_description')}</p>
                </DescriptionWrapper>
              </>
            )}

            <Logo center={!isDesktop}>
              <TequilaLogoBottom />
            </Logo>
          </LogoContainer>
        </BackgroundContainer>
      )}

      {isDesktop && (
        <RouteContainer>
          <RouteWrapper>
            <Content />
          </RouteWrapper>
        </RouteContainer>
      )}
    </AuthContainer>
  );
};

export default Auth;
