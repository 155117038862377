import React, { useReducer, useContext } from 'react';

import { topper } from 'utils/api';
import paymentsMapper from 'mappers/nextGen/transactions/_';
import { usePolyglot } from 'components/services/i18n';
import { DepositState } from 'components/services/deposit/';
import { AuthState } from 'components/services/auth/AuthProvider';

const initialState = {
  data: [],
  pagination: {
    page: 1,
    perPage: 20,
    totalCount: 0,
    pagesCount: 0,
  },
  loading: false,
  error: null,
};

export const PartnerPaymentsState = React.createContext({
  initialState,
  loadPartnerPayments: () => [],
  loadPartnerPayment: () => [],
  identifyPartnerPayment: () => [],
  approvePartnerPayment: () => [],
  ignorePartnerPayment: () => [],
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_PARTNER_PAYMENTS':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'LOAD_PARTNER_PAYMENTS_SUCCESS':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        loading: false,
      };
    case 'LOAD_PARTNER_PAYMENTS_FAIL':
      return {
        ...state,
        data: [],
        pagination: initialState.pagination,
        loading: false,
        error: action.error,
      };
    case 'LOAD_PARTNER_PAYMENT':
      return {
        ...state,
        partnerPayment: {
          loading: true,
          error: null,
        },
      };
    case 'LOAD_PARTNER_PAYMENT_SUCCESS':
      return {
        ...state,
        partnerPayment: {
          data: action.data,
          loading: false,
        },
      };
    case 'LOAD_PARTNER_PAYMENT_FAIL':
      return {
        ...state,
        partnerPayment: {
          data: null,
          loading: false,
          error: action.error,
        },
      };
    case 'IDENTIFY_PARTNER_PAYMENT':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          identifying: true,
          errorIdentifying: null,
        },
      };
    case 'IDENTIFY_PARTNER_PAYMENT_SUCCESS':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          identifying: false,
        },
      };
    case 'IDENTIFY_PARTNER_PAYMENT_FAIL':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          identifying: false,
          errorIdentifying: action.error,
        },
      };
    case 'APPROVE_PARTNER_PAYMENT':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          approving: true,
          errorApproving: null,
        },
      };
    case 'APPROVE_PARTNER_PAYMENT_SUCCESS':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          approving: false,
        },
      };
    case 'APPROVE_PARTNER_PAYMENT_FAIL':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          approving: false,
          errorApproving: action.error,
        },
      };
    case 'IGNORE_PARTNER_PAYMENT':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          ignoring: true,
          errorIgnoring: null,
        },
      };
    case 'IGNORE_PARTNER_PAYMENT_SUCCESS':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          ignoring: false,
        },
      };
    case 'IGNORE_PARTNER_PAYMENT_FAIL':
      return {
        ...state,
        partnerPayment: {
          ...state.partnerPayment,
          ignoring: false,
          errorIgnoring: action.error,
        },
      };
    default:
      return state;
  }
};

const PartnerPaymentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const polyglot = usePolyglot();
  const { state: depositAccounts } = useContext(DepositState);
  const { user } = useContext(AuthState);

  const loadPartnerPayments = async params => {
    dispatch({ type: 'LOAD_PARTNER_PAYMENTS' });

    try {
      const res = await topper.v2.get('transactions', {
        params: {
          _page: params.page,
          _per_page: params.perPage,
          ...(params.status && { status: params.status }),
        },
      });
      const data = res.data.data.map(pp => paymentsMapper.v2.get.from(pp, polyglot));
      dispatch({
        type: 'LOAD_PARTNER_PAYMENTS_SUCCESS',
        data,
        pagination: {
          page: params.page,
          perPage: params.perPage,
          pagesCount: res.data.pagination.pages,
          totalCount: res.data.pagination.total,
        },
      });
    } catch (error) {
      Promise.reject(dispatch({ type: 'LOAD_PARTNER_PAYMENTS_FAIL', error }));
    }
  };

  const loadPartnerPayment = async id => {
    dispatch({ type: 'LOAD_PARTNER_PAYMENT' });
    try {
      const res = await topper.v2.get(`transactions/${id}`);
      dispatch({
        type: 'LOAD_PARTNER_PAYMENT_SUCCESS',
        data: paymentsMapper.v2.get.from(res.data, polyglot),
      });
    } catch (error) {
      dispatch({ type: 'LOAD_PARTNER_PAYMENT_FAIL', error });
      return Promise.reject(error);
    }
  };

  const identifyPartnerPayment = async (id, companyName) => {
    dispatch({ type: 'IDENTIFY_PARTNER_PAYMENT' });

    try {
      const depositAcc = depositAccounts?.[companyName].data;
      await topper.v2.post(
        `transactions/${id}/approve`,
        paymentsMapper.v2.post.to(user, companyName, depositAcc),
      );

      dispatch({ type: 'IDENTIFY_PARTNER_PAYMENT_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'IDENTIFY_PARTNER_PAYMENT_FAIL', error });
      return Promise.reject(error);
    }
  };

  const approvePartnerPayment = async id => {
    dispatch({ type: 'APPROVE_PARTNER_PAYMENT' });

    try {
      await topper.v2.post(`transactions/${id}/topup`, {});

      dispatch({ type: 'APPROVE_PARTNER_PAYMENT_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'APPROVE_PARTNER_PAYMENT_FAIL' });
      return Promise.reject(error);
    }
  };

  const ignorePartnerPayment = async id => {
    dispatch({ type: 'IGNORE_PARTNER_PAYMENT' });

    try {
      await topper.v2.post(`transactions/${id}/ignore`, {});

      dispatch({ type: 'IGNORE_PARTNER_PAYMENT_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'IGNORE_PARTNER_PAYMENT_FAIL', error });
      return Promise.reject(error);
    }
  };
  const value = {
    state,
    loadPartnerPayments,
    loadPartnerPayment,
    identifyPartnerPayment,
    approvePartnerPayment,
    ignorePartnerPayment,
  };

  return <PartnerPaymentsState.Provider value={value}>{children}</PartnerPaymentsState.Provider>;
};

export default PartnerPaymentsProvider;
