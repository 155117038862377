import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Box from '@kiwicom/orbit-components/lib/Box';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';

import { usePolyglot } from 'components/services/i18n';
import { useCurrency } from 'components/services/currencies';
import { getSeatingTotalSum } from 'utils/seating';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';
import { QUICK_OPTION_TYPES } from 'consts/seating';
import useMMB from 'components/services/mmb/useMMB';
import { MMBState } from 'components/services/mmb';

const SummarySection = ({ closeDrawer, openCloseModal }) => {
  const polyglot = usePolyglot();
  const { targetCurrencyCode, getFormattedPriceString } = useCurrency();
  const {
    seatings,
    payShoppingBasket,
    payment: { loading },
  } = useShoppingBasket();
  const currency = useMemo(() => targetCurrencyCode.toUpperCase(), [targetCurrencyCode]);
  const seatingsValues = Object.values(seatings);
  const [isCheckboxActive, setIsCheckboxActive] = useState(false);
  const totalPrice = getFormattedPriceString(getSeatingTotalSum(seatings));
  const hasOneOrAllRandom =
    (seatingsValues.length === 1 && seatingsValues[0].option === QUICK_OPTION_TYPES.RANDOM) ||
    seatingsValues.every(seatingsValue => seatingsValue.option === QUICK_OPTION_TYPES.RANDOM);
  const { bid } = useParams();
  const { getPartnerToken } = useContext(MMBState);
  const { mmbToken } = useMMB();

  const callback = useCallback(() => {
    getPartnerToken(bid);
    closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnAddAndPaySeating = useCallback(() => {
    payShoppingBasket(bid, mmbToken, currency, seatings, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatings]);

  return (
    <Box padding={{ right: 'large', left: 'large' }} display="flex" direction="column">
      <Stack direction="row" align="center" justify="between" spaceAfter="large">
        <Inline spacing="XSmall">
          <Text weight="bold" size="large">
            {polyglot.t('tequila.ancillary_seating.mmb.drawer.total', {
              currency,
            })}
          </Text>
        </Inline>
        <Heading type="title2">{totalPrice}</Heading>
      </Stack>
      <Separator spaceAfter="large" />
      <Stack direction="row" align="center" justify="start" spaceAfter="large">
        <Checkbox
          label={polyglot.t('tequila.ancillary_seating.mmb.drawer.checkbox.title')}
          checked={isCheckboxActive}
          onChange={() => setIsCheckboxActive(active => !active)}
        />
      </Stack>
      <Stack direction="row" align="center" justify="between">
        <Button
          type="secondary"
          disabled={loading}
          onClick={!!Object.keys(seatings).length ? () => openCloseModal() : () => closeDrawer()}
        >
          {polyglot.t('tequila.ancillary_seating.mmb.drawer.cancel.button')}
        </Button>
        <Button
          type="primary"
          loading={loading}
          disabled={loading || hasOneOrAllRandom || !isCheckboxActive}
          onClick={handleOnAddAndPaySeating}
        >
          {polyglot.t('tequila.ancillary_seating.mmb.drawer.add_and_pay_seating.button', {
            price: totalPrice,
          })}
        </Button>
      </Stack>
    </Box>
  );
};

export default SummarySection;
