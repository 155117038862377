import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePolyglot } from 'components/services/i18n';
import useSearch from 'components/services/search/useSearch';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import { useInfoMessages, useInvalidDeposit, useSmartpoint } from 'components/services/bookingTool';
import useInitialQueryParams from 'components/services/initialQueryParams/useInitialQueryParams';
import useUiConfig from 'components/services/initialQueryParams/useUiConfig';
import { SmartpointState } from 'components/services/smartpoint';
import { useCarriers } from 'components/services/bookingTool';
import { OrbitLoader, SelectSolution, SelectSolutionsCompany } from 'common';
import useCompanyPCCApps from 'components/services/smartpoint/useCompanyPCCApps';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';
import useApiProducts from 'components/services/products/useApiProducts';
import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import { SEARCH_API, MULTICITY_API, NOMAD_API, SMARTPOINT_PRODUCTS } from 'consts/products';

import { ContentContainer } from './index.styled';
import InfoMessages from './components/InfoMessages';
import SearchError from './components/SearchError';
import Sort from './components/Sort';
import GroundAlert from './components/GroundAlert';
import SmartpointInitModal from './components/SmartpointInitModal';
import SearchForm from './scenes/SearchForm';
import SearchResults from './scenes/SearchResults';

const ENABLES = [SEARCH_API, MULTICITY_API, NOMAD_API];

const Search = () => {
  const polyglot = usePolyglot();
  const isNavigationHidden = useUiConfig().hideNavigation;
  const { selectedCompany, selectCompany } = useSelectedCompany();
  const companyPCCApps = useCompanyPCCApps(selectedCompany);
  const apiProducts = useApiProducts();
  const carriers = useCarriers();
  const { addInfoMessage } = useInfoMessages();
  const invalidDeposit = useInvalidDeposit();
  const user = useCurrentUser();
  const { pcc } = useInitialQueryParams();
  const { ensurePCCState } = useContext(SmartpointState);
  const {
    isInitialSmartPointModalShown,
    setIsInitialSmartPointModalShown,
    setExistingApplication,
  } = useSmartpoint();
  const location = useLocation();
  const { selectSolution, clearSearch, tabData } = useSearch();
  const affilId = location.state?.affilId || tabData?.affilId;

  useEffect(() => {
    if (pcc && companyPCCApps && companyPCCApps.data && apiProducts.data) {
      const selectedSolution = companyPCCApps.data.find(
        app => app.code.toLowerCase() === pcc.toLowerCase(),
      );
      if (selectedSolution) {
        setExistingApplication({
          affilId: selectedSolution.application.affiliate_id,
          consumerKey: selectedSolution.application.api_key,
          productType: apiProducts.data.find(
            product => selectedSolution.application.api_product_id === product.id,
          )?.value,
        });
      } else {
        setIsInitialSmartPointModalShown(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyPCCApps, apiProducts]);

  if (companyPCCApps?.loading || ensurePCCState.loading || carriers.loading) {
    return (
      <ContentContainer isNavigationHidden={isNavigationHidden}>
        <OrbitLoader id="solutions-loader" visible />
      </ContentContainer>
    );
  }

  const showCompanySelector = user && (user.role === KIWI_ADMIN || user.role === ACCOUNT_MANAGER);
  const onCompanyChange = companyName => {
    clearSearch(true);
    selectSolution('');
    selectCompany(companyName);
  };
  const onSelectSolution = ev => {
    selectSolution(ev.target.value, tabData.isFlightChangeMode && tabData.tripType);
  };

  if (carriers.error) {
    return (
      <SearchError
        isNavigationHidden={isNavigationHidden}
        content={polyglot.t('searchAndBooking.carrier_info_error_message')}
      />
    );
  }

  if (isInitialSmartPointModalShown) return <SmartpointInitModal />;

  if (invalidDeposit) {
    addInfoMessage({
      id: 'no_deposit',
      type: 'info',
    });
  }

  return (
    <ContentContainer isNavigationHidden={isNavigationHidden}>
      <InfoMessages />
      {showCompanySelector && <SelectSolutionsCompany onSelect={onCompanyChange} />}
      {!isNavigationHidden && (
        <SelectSolution
          preselectedAffilidValue={affilId}
          onChange={onSelectSolution}
          companyName={selectedCompany}
          enables={ENABLES}
          disablesTypes={!tabData.isSmartpointSearch && SMARTPOINT_PRODUCTS}
          fromSearch={true}
        />
      )}
      {affilId && (
        <>
          <SearchForm />
          <GroundAlert />
          <Sort />
          <SearchResults />
        </>
      )}
    </ContentContainer>
  );
};

export default Search;
