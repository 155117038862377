import React from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import * as dateConstants from 'consts/dates';
import * as dateUtils from 'utils/dates';
import usePolyglot from 'components/services/i18n/usePolyglot';

const OuterWrapper = styled.div`
  margin-bottom: 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${props => props.direction || 'row'};

  & > button {
    margin-right: 10px;
  }
`;

const DATE_RANGE = [
  {
    k: dateConstants.LAST_7_DAYS,
    value: 7,
  },
  {
    k: dateConstants.LAST_14_DAYS,
    value: 14,
  },
  {
    k: dateConstants.LAST_21_DAYS,
    value: 21,
  },
  {
    k: dateConstants.LAST_28_DAYS,
    value: 28,
  },
  {
    k: dateConstants.LAST_30_DAYS,
    value: 30,
  },
  {
    k: dateConstants.LAST_60_DAYS,
    value: 60,
  },
  {
    k: dateConstants.LAST_90_DAYS,
    value: 90,
  },
  {
    k: dateConstants.LAST_180_DAYS,
    value: 180,
  },
  {
    k: dateConstants.LAST_364_DAYS,
    value: 364,
  },
  {
    k: dateConstants.LAST_365_DAYS,
    value: 365,
  },
  {
    k: dateConstants.CUSTOM,
    value: dateConstants.CUSTOM,
  },
];

const COMPARE = [
  {
    k: dateConstants.CUSTOM,
    value: 'common.custom',
  },
  {
    k: dateConstants.PREV_PERIOD,
    value: 'common.previous_period',
  },
  {
    k: dateConstants.PREV_YEAR,
    value: 'common.previous_year',
  },
];

const DateRangeSelect = ({
  value,
  onChange,
  isComparing,
  days,
  future = false,
  beginDate = null,
}) => {
  const polyglot = usePolyglot();

  const handleChangeRange = key => {
    switch (key) {
      case dateConstants.LAST_7_DAYS:
        onChange(future ? dateUtils.getNextXDays(7, beginDate) : dateUtils.getLastXDays(7), key);
        break;
      case dateConstants.LAST_14_DAYS:
        onChange(future ? dateUtils.getNextXDays(14, beginDate) : dateUtils.getLastXDays(14), key);
        break;
      case dateConstants.LAST_21_DAYS:
        onChange(future ? dateUtils.getNextXDays(21, beginDate) : dateUtils.getLastXDays(21), key);
        break;
      case dateConstants.LAST_28_DAYS:
        onChange(future ? dateUtils.getNextXDays(28, beginDate) : dateUtils.getLastXDays(28), key);
        break;
      case dateConstants.LAST_30_DAYS:
        onChange(future ? dateUtils.getNextXDays(30, beginDate) : dateUtils.getLastXDays(30), key);
        break;
      case dateConstants.LAST_60_DAYS:
        onChange(future ? dateUtils.getNextXDays(60, beginDate) : dateUtils.getLastXDays(60), key);
        break;
      case dateConstants.LAST_90_DAYS:
        onChange(future ? dateUtils.getNextXDays(90, beginDate) : dateUtils.getLastXDays(90), key);
        break;
      case dateConstants.LAST_180_DAYS:
        onChange(
          future ? dateUtils.getNextXDays(180, beginDate) : dateUtils.getLastXDays(180),
          key,
        );
        break;
      case dateConstants.LAST_364_DAYS:
        onChange(
          future ? dateUtils.getNextXDays(364, beginDate) : dateUtils.getLastXDays(364),
          key,
        );
        break;
      case dateConstants.LAST_365_DAYS:
        onChange(
          future ? dateUtils.getNextXDays(365, beginDate) : dateUtils.getLastXDays(365),
          key,
        );
        break;
      default:
        return null;
    }
  };

  return (
    <OuterWrapper>
      <FlexWrapper direction="column">
        {isComparing ? (
          <Heading type="title3" spaceAfter="small">
            {polyglot.t('common.compare')}
          </Heading>
        ) : (
          <Heading type="title3" spaceAfter="small">
            {polyglot.t('common.date_range')}
          </Heading>
        )}
        <FlexWrapper>
          {isComparing
            ? COMPARE.map((v, i) => (
                <Button
                  key={i}
                  type={value === v.k ? 'primary' : 'secondary'}
                  onClick={() => onChange(v.k)}
                  contentAlign="center"
                  data-test-id={v.k}
                >
                  {polyglot.t(v.value)}
                </Button>
              ))
            : DATE_RANGE.filter(v => days.includes(v.value)).map((v, i) => (
                <Button
                  key={i}
                  type={value === v.k ? 'primary' : 'secondary'}
                  onClick={() => handleChangeRange(v.k)}
                  contentAlign="center"
                  data-test-id={v.value}
                >
                  {v.value}
                </Button>
              ))}
        </FlexWrapper>
      </FlexWrapper>
    </OuterWrapper>
  );
};

export default DateRangeSelect;
