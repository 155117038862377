import axios from 'axios';
import qs from 'query-string';
import * as R from 'ramda';

import { getBackendUrl, getBackendProxyUrl, getBackendTopperUrl } from 'utils/features';

const createBackendInstance = version => {
  return axios.create({
    baseURL: `${getBackendUrl()}/${version}/`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const createBackendProxyInstance = version => {
  return axios.create({
    baseURL: `${getBackendProxyUrl()}/${version}/`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
const createBackendTopperInstance = version => {
  return axios.create({
    baseURL: `${getBackendTopperUrl()}/${version}/`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const createGcpBucketInstance = () => {
  return axios.create({
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
};
const api = {
  v1: createBackendInstance('v1'),
  v2: createBackendInstance('v2'),
};

const topper = {
  v2: createBackendTopperInstance('v2'),
};

const proxy = {
  direct: createBackendProxyInstance(''),
  v1: createBackendProxyInstance('v1'),
  v2: createBackendProxyInstance('v2'),
};

const gcp = createGcpBucketInstance();

let _token = null;

export const setAuthToken = (token, uuid) => {
  _token = token;

  for (const version in api) {
    api[version].defaults.headers.common['KW-TEQL-User-Token'] = token;
    if (uuid) api[version].defaults.headers.common['KW-TEQL-User-Impersonate'] = uuid;
  }

  for (const version in proxy) {
    proxy[version].defaults.headers.common['KW-TEQL-User-Token'] = token;
    if (uuid) proxy[version].defaults.headers.common['KW-TEQL-User-Impersonate'] = uuid;
  }

  for (const version in topper) {
    topper[version].defaults.headers.common['KW-TEQL-User-Token'] = token;
    if (uuid) topper[version].defaults.headers.common['KW-TEQL-User-Impersonate'] = uuid;
  }
};

export const removeAuthToken = () => {
  _token = null;
  for (const version in api) {
    delete api[version].defaults.headers.common['KW-TEQL-User-Token'];
    delete api[version].defaults.headers.common['KW-TEQL-User-Impersonate'];
  }

  for (const version in proxy) {
    delete proxy[version].defaults.headers.common['KW-TEQL-User-Token'];
    delete proxy[version].defaults.headers.common['KW-TEQL-User-Impersonate'];
  }

  for (const version in topper) {
    delete topper[version].defaults.headers.common['KW-TEQL-User-Token'];
    delete topper[version].defaults.headers.common['KW-TEQL-User-Impersonate'];
  }
};

export const getToken = () => {
  return _token;
};

export const getAndPaginateAll = async (
  version,
  method,
  endpoint,
  _per_page = 50,
  queryParams = {},
  data = [],
  page = 1,
) => {
  const response = await api[version][method](endpoint, {
    params: { ...queryParams, _per_page, page },
  });
  if (response.data.pagination.has_next) {
    return getAndPaginateAll(
      version,
      method,
      endpoint,
      _per_page,
      {
        ...queryParams,
      },
      data.concat(response.data.data),
      page + 1,
    );
  }

  return data.concat(response.data.data);
};

export const getUrlWithQuery = (url, query, config) => {
  if (!url && !query) return '';
  const separator = url.includes('?') ? '&' : '?';

  if (R.isEmpty(query)) {
    return url;
  }
  return `${url}${separator}${qs.stringify(query, {
    skipNull: true,
    ...config,
  })}`;
};

export default api;
export { proxy, api, topper, gcp };
