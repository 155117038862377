import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import * as dfs from 'date-fns';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { CompanySettingsState } from 'components/services/companySettings';
import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';

import DisableWhitelistModal from './DisableWhitelistModal';
import RemoveIpAddressModal from './RemoveIpAddressModal';
import WarningModal from './WarningModal';

const IPText = styled.div`
  color: ${({ theme }) => theme.orbit.paletteProductDarker};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const TableWrapper = styled.div`
  min-height: 426px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  border-radius: 4px;
`;

const WhitelistTable = ({ canUpdate }) => {
  const companyName = useCurrentCompanyName();
  const disableWhitelistModal = useToggle();
  const { enableIPWhitelisting, disableIPWhitelisting, removeIPAddressFromWhitelisting, state } =
    useContext(CompanySettingsState);
  const polyglot = usePolyglot();
  const [removeIpDraft, setRemoveIpDraft] = useState(null);

  const { data, updating } = state[companyName];

  const handleDisableWhitelist = async () => {
    await disableIPWhitelisting(companyName);
    disableWhitelistModal.setOff();
  };

  const handleEnableWhitelist = () => {
    enableIPWhitelisting(companyName);
  };

  const handleRemoveIPAddress = ip => {
    removeIPAddressFromWhitelisting(companyName, ip);
    setRemoveIpDraft(null);
  };

  const handleRemoveAndDisable = async ip => {
    await disableIPWhitelisting(companyName);
    removeIPAddressFromWhitelisting(companyName, ip);
    setRemoveIpDraft(null);
  };

  const updatedAt = new Date(data.updatedAt);
  updatedAt.setTime(updatedAt.getTime() - updatedAt.getTimezoneOffset() * 60000);

  return (
    <>
      {disableWhitelistModal.isOn && (
        <DisableWhitelistModal
          onClose={disableWhitelistModal.setOff}
          onDisable={handleDisableWhitelist}
          loading={updating}
        />
      )}
      {removeIpDraft && data.ipWhitelist.length > 1 && (
        <RemoveIpAddressModal
          onClose={() => setRemoveIpDraft(null)}
          onRemove={() => handleRemoveIPAddress(removeIpDraft)}
        />
      )}
      {removeIpDraft && data.ipWhitelist.length === 1 && (
        <WarningModal
          onClose={() => setRemoveIpDraft(null)}
          onAccept={() => handleRemoveAndDisable(removeIpDraft)}
        />
      )}
      <Stack spacing="large">
        <TableWrapper>
          <Table type="secondary" striped={false} compact>
            <TableHead>
              <TableRow>
                <TableCell>{polyglot.t('allowlist.ip_address')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.ipWhitelist.map(ip => (
                <>
                  <TableRow key={ip}>
                    <TableCell>
                      <IPText disabled={!data.whitelistEnabled}>{ip}</IPText>
                    </TableCell>

                    <TableCell align="right">
                      {data.whitelistEnabled && canUpdate && (
                        <Stack direction="row" justify="end">
                          <Button
                            type="criticalSubtle"
                            size="small"
                            iconLeft={<RemoveIcon />}
                            onClick={() => {
                              setRemoveIpDraft(ip);
                            }}
                          />
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>

        <Stack direction="row" justify="between">
          <Stack direction="column" spacing="XXSmall" shrink>
            <Text type="primary">
              {data.ipWhitelist.length} IP {data.ipWhitelist.length === 1 ? 'address' : 'addresses'}
            </Text>
            <Text type="secondary">
              {polyglot.t('widgets.header.last_updated')}:{' '}
              {dfs.format(updatedAt, "dd/MM/yyyy' - 'HH:mm")}
            </Text>
          </Stack>

          {canUpdate ? (
            data.whitelistEnabled ? (
              <Button type="criticalSubtle" size="small" onClick={disableWhitelistModal.setOn}>
                {polyglot.t('allowlist.disable')}
              </Button>
            ) : (
              <Button
                type="primary"
                size="small"
                onClick={handleEnableWhitelist}
                loading={updating}
              >
                {polyglot.t('allowlist.enable')}
              </Button>
            )
          ) : (
            <div />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default WhitelistTable;
