import { useRouteMatch } from 'react-router-dom';

const useCurrentCompanyName = () => {
  const {
    params: { companyName },
  } = useRouteMatch();

  return companyName;
};

export default useCurrentCompanyName;
