import React from 'react';
import styled from 'styled-components';

import ResourceGroup from './ResourceGroup';

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 84px;
  right: 24px;
  max-width: 280px;
  width: 280px;
  padding: 16px;
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  z-index: 500;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-radius: 3px;

  @media all and (max-width: 1500px) {
    display: none;
  }
`;

const StaticCard = () => {
  return (
    <Container>
      <ResourceGroup />
    </Container>
  );
};

export default StaticCard;
