import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';

import SeatingPrice from '../SeatingPrice';
import { IconWrapper } from './index.styled';

const ActionButton = ({ selected, disabled, onClick, routeId }) => {
  const polyglot = usePolyglot();
  const { currentSeatings } = useSeating();
  const segment = currentSeatings[routeId];

  if (disabled) {
    return (
      <Text type="secondary">
        {polyglot.t('booking_tool.seating.drawer.route_groups.group.assigned_by_carrier')}
      </Text>
    );
  } else if (
    selected ||
    (segment && segment.option) ||
    (segment && !segment.option && Object.keys(segment).length)
  ) {
    return (
      <Box display="flex" direction="row" justify="start" align="center">
        <SeatingPrice routeId={routeId} />
        {selected && (
          <IconWrapper>
            <ChevronForwardIcon size="small" />
          </IconWrapper>
        )}
      </Box>
    );
  }

  return (
    <Button onClick={onClick} size="small" type="primarySubtle">
      {polyglot.t('booking_tool.seating.drawer.route_groups.group.button.select')}
    </Button>
  );
};

export default ActionButton;
