import React from 'react';

import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';

import useSearch from 'components/services/search/useSearch';
import { usePolyglot } from 'components/services/i18n';
import { vehicleTypes } from 'consts/search';

import EmptyListChoiceIcon from './EmptyListChoiceIcon';

const TransportType = () => {
  const polyglot = usePolyglot();
  const { tabData, changeVehicleType } = useSearch();

  return vehicleTypes.map(option => (
    <ListChoice
      key={option.value}
      icon={
        tabData.vehicleTypes.find(vehicleType => vehicleType === option.value) ? (
          <CheckIcon />
        ) : (
          <EmptyListChoiceIcon />
        )
      }
      title={polyglot.t(option.label)}
      onClick={() => {
        changeVehicleType(option.value);
      }}
    />
  ));
};

export default TransportType;
