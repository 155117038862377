import styled from 'styled-components';

export const ResetPasswordContainer = styled.div`
  margin-top: 32px;

  & label:last-of-type {
    margin: 16px 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
