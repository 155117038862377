const direct_post_to = (totalPrice, sessionId) => {
  const { amount, currency } = totalPrice;
  return {
    credits: {
      price: {
        amount,
        currency,
      },
    },
    ...(sessionId && { session_id: sessionId }),
  };
};

const direct_post_from = data => {
  return {
    errors: data.errors.map(error => ({
      category: error.category,
      description: error.description,
      paymentId: error.payment_id,
      recommendation: error.recommendation,
    })),
    fraudStatus: data.fraud_status,
    payments: data.payments,
    status: data.status,
  };
};

const direct_get_from = direct_post_from;

export default {
  direct: {
    post: {
      to: direct_post_to,
      from: direct_post_from,
    },
    get: {
      from: direct_get_from,
    },
  },
};
