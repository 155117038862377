import React from 'react';
import { InputField, Stack, Checkbox } from '@kiwicom/orbit-components';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import { ClickOutside } from 'common';
import { useToggle } from 'utils/hooks';
import useTransactions from 'components/services/transactions/useTransactions';
import { usePolyglot } from 'components/services/i18n';
import { capitalize } from 'utils/string';

import { SuffixIconWrapper, InputDropdownWrapper, InputFieldWrapper } from './index.styled';

import { CREDIT_ACTIVITY_TYPES } from 'consts/mmb';

const TypeFilter = () => {
  const isPopover = useToggle();
  const { filterTypes, setFilterTypes } = useTransactions();
  const polyglot = usePolyglot();

  return (
    <ClickOutside onClick={isPopover.setOff}>
      <InputFieldWrapper>
        <InputField
          onFocus={isPopover.setOn}
          placeholder={polyglot.t('tequila.mmb.credit_activity.filters.type.input.placeholder')}
          label={polyglot.t('tequila.mmb.credit_activity.filters.type.input.label')}
          suffix={
            <SuffixIconWrapper>
              <ChevronDownIcon size="small" />
            </SuffixIconWrapper>
          }
          value={filterTypes.map(type => capitalize(type)).join(', ')}
          readOnly
          size="small"
        />
        <InputDropdownWrapper isExpanded={isPopover.isOn} width="100%">
          <Stack direction="column" justify="between">
            {CREDIT_ACTIVITY_TYPES.map(type => {
              return (
                <Checkbox
                  key={type.value}
                  label={polyglot.t(type.label)}
                  value={type.value}
                  onChange={e => setFilterTypes(e.target.value)}
                  checked={filterTypes.includes(type.value)}
                />
              );
            })}
          </Stack>
        </InputDropdownWrapper>
      </InputFieldWrapper>
    </ClickOutside>
  );
};

export default TypeFilter;
