import React from 'react';
import styled from 'styled-components';
import subMonths from 'date-fns/subMonths';
import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import CalendarRangeIcon from '@kiwicom/orbit-components/lib/icons/CalendarRange';
import ButtonForwardIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { usePolyglot } from 'components/services/i18n';
import CalendarDropdown from 'components/scenes/Search/scenes/SearchForm/components/CalendarDropdown';

const OuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 15px;
  }
  & > div {
    width: 300px;
  }
`;

const ButtonBack = styled(ButtonForwardIcon)`
  transform: rotate(180deg);
`;

const Filters = ({
  handleApply,
  disabled,
  selectedDates,
  handleReset,
  handleDateFilter,
  loading,
}) => {
  const polyglot = usePolyglot();

  return (
    <>
      <Card spaceAfter="large">
        <CardSection>
          <OuterWrapper>
            <Wrapper>
              <CalendarDropdown
                prefix={<ButtonLink size="small" iconLeft={<CalendarRangeIcon size="small" />} />}
                label="Booking date"
                size="small"
                disabled={disabled}
                displayFormat="d MMM yyyy"
                placeholder="Select date"
                allowPast
                dataTest="booking-date-select"
                firstMonth={subMonths(new Date(), 12)}
                buttonBack={<ButtonBack />}
                buttonForward={<ButtonForwardIcon />}
                selectedDates={selectedDates || []}
                changeSelectedDates={handleDateFilter}
              />
            </Wrapper>
            <Wrapper>
              <Button
                dataTest="apply-filter"
                onClick={handleApply}
                disabled={!selectedDates.length || loading}
                size="small"
              >
                {polyglot.t('mmb.apply_filters')}
              </Button>
              <Button
                dataTest="reset-filter"
                onClick={handleReset}
                disabled={!selectedDates.length || loading}
                size="small"
              >
                {polyglot.t('mmb.reset_filters')}
              </Button>
            </Wrapper>
          </OuterWrapper>
        </CardSection>
      </Card>
    </>
  );
};

export default Filters;
