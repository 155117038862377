import * as P from 'parsimmon';
import * as R from 'ramda';

import { monthsAbbreviationValues } from 'consts/dates';
import { tripTypesValues } from 'consts/search';
import { optionsParser, inclusionOrderByDecorator } from './common';

const prefixParser = P.regexp(/^(A(F|J)?)/, 1);

const dayParser = P.regexp(/\d{1,2}/).map(Number);

const monthParser = P.alt(...Object.keys(monthsAbbreviationValues).map(P.string));

const optionalMonthParser = monthParser.times(0, 1).map(r => r[0] || null);

const locationParser = P.regexp(/[A-Z]{3}/);

const carriersParser = P.regexp(/\/([A-Z]{2}(?:#|-)?)/, 1)
  .atMost(3)
  .map(inclusionOrderByDecorator);

const classTypeParser = P.regexp(/@([A-Z])/, 1);

const onlyDirectParser = P.regex(/\.D/).map(Boolean);

const timeParser = P.regex(/\.?([0-9]{4}|[0-9](?:A|P))/, 1);

const optionsParsersMap = {
  carriers: {
    expression: /(?:\/[A-Z]{2}(?:#|-)?)+/,
    entries: carriersParser,
  },
  classType: { expression: /@[A-Z](?![A-Z])/, entries: classTypeParser },
  onlyDirect: { expression: /\.D/, entries: onlyDirectParser },
  time: { expression: /\.?([0-9]{4}|[0-9](A|P))/, entries: timeParser },
};

const availibility = P.seqObj(
  ['prefix', prefixParser],
  ['day', dayParser],
  ['month', monthParser],
  ['from', locationParser],
  ['to', locationParser],
  ['options', optionsParser(optionsParsersMap)],
)
  .map(r => {
    const {
      options: { _remainder, ...options },
      prefix,
      ...rest
    } = r;
    return {
      ...rest,
      prefix,
      options,
      tripType: tripTypesValues.ONEWAY,
      unparsed: _remainder ? [_remainder] : [],
    };
  })
  .desc('Could not parse this Availiability command');

const returnAvailibility = P.seqObj(
  ['prefix', prefixParser],
  ['day', dayParser],
  ['month', monthParser],
  ['from', locationParser],
  ['to', locationParser],
  ['options', optionsParser(optionsParsersMap, '\\+\\+')],
  P.string('++'),
  ['returnDay', dayParser],
  ['returnMonth', optionalMonthParser],
  ['returnOptions', optionsParser(optionsParsersMap)],
)
  .map(r => {
    const {
      options: { _remainder: unparsedOptions, ...options },
      returnOptions: { _remainder: unparsedReturnOptions, ...returnOptions },
      prefix,
      ...rest
    } = r;
    return {
      ...rest,
      prefix,
      options,
      returnOptions,
      tripType: tripTypesValues.RETURN,
      unparsed: [unparsedOptions, unparsedReturnOptions].filter(Boolean || !R.isEmpty),
    };
  })
  .desc('Could not parse this Availiability command');

export default P.alt(returnAvailibility, availibility);
