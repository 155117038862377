import React from 'react';

import Select from '@kiwicom/orbit-components/lib/Select';
import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';

import { usePolyglot } from 'components/services/i18n';
import { nationalitiesOptions } from 'consts/nationalities';

const SelectNationality = ({ id, value, label, onChange, onBlur, name, ...props }) => {
  const polyglot = usePolyglot();

  return (
    <Select
      id={id}
      label={label}
      placeholder={polyglot.t('register.nationality.placeholder')}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      value={value?.toUpperCase()}
      options={nationalitiesOptions}
      prefix={<CountryFlag code={value} />}
      {...props}
    />
  );
};

export default SelectNationality;
