import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '@kiwicom/orbit-components/lib/Loading';

import useMMB from 'components/services/mmb/useMMB';
import { useCurrency } from 'components/services/currencies';
import ErrorSeatingOffers from 'components/scenes/Booking/scenes/DepositBooking/scenes/Seating/components/ErrorSeatingOffers';
import { usePolyglot } from 'components/services/i18n';

import SeatingSummaryTable from '../SeatingSummaryTable';

const SeatingContent = () => {
  const polyglot = usePolyglot();
  const { bid } = useParams();
  const {
    checkAncillariesOffers,
    bookingsState,
    ancillariesOffers: { loading, error, data },
  } = useMMB();
  const { targetCurrencyCode } = useCurrency();
  const currency = useMemo(() => targetCurrencyCode.toUpperCase(), [targetCurrencyCode]);

  const { passengers } = bookingsState[bid];

  useEffect(() => {
    checkAncillariesOffers(bid, currency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading text={polyglot.t('tequila.ancillary_seating.mmb.loader')} />;
  } else if (error) {
    return (
      <ErrorSeatingOffers
        title={polyglot.t('tequila.ancillary_seating.mmb.card.error.title')}
        description={polyglot.t('tequila.ancillary_seating.mmb.card.error.description')}
        buttonText={polyglot.t('tequila.ancillary_seating.mmb.card.error.reload_page')}
      />
    );
  } else if (data && passengers) {
    return <SeatingSummaryTable />;
  }

  return null;
};

export default SeatingContent;
