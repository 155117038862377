import React from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { usePolyglot } from 'components/services/i18n';
import { propsComparison } from 'utils/propsComparison';
import { keys, legalFormKeys } from 'consts/smartPass';

import { StackDiv } from './index.styled';
import { ContactPersonWrapper } from './Legal.styled';

const {
  LEGAL_NAME,
  LEGAL_ADDRESS,
  VAT_NUMBER,
  BANK_ACCOUNT_NUMBER,
  BANK_NAME_AND_ADDRESS,
  CONTACT_PERSON_1_EMAIL,
  CONTACT_PERSON_1_ROLE,
  CONTACT_PERSON_1_NAME,
  CONTACT_PERSON_1_PHONE,
  CONTACT_PERSON_2_EMAIL,
  CONTACT_PERSON_2_NAME,
  CONTACT_PERSON_2_PHONE,
  CONTACT_PERSON_2_ROLE,
} = keys;

const Legal = ({ values, handleChange, handleBlur, disabled, errors, touched }) => {
  const polyglot = usePolyglot();

  return (
    <Card
      spaceAfter="largest"
      header={
        <Heading as="h2" type="title3">
          {polyglot.t('smart_pass.form.legal.header_title')}
        </Heading>
      }
    >
      <CardSection>
        <Stack direction="row" spaceAfter="large">
          <InputField
            label={polyglot.t('smart_pass.form.legal.legal_name')}
            name={LEGAL_NAME}
            value={values[LEGAL_NAME]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
          <InputField
            name={LEGAL_ADDRESS}
            value={values[LEGAL_ADDRESS]}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.legal.legal_address')}
            disabled={disabled}
          />
        </Stack>
        <Stack direction="row" spaceAfter="large">
          <InputField
            name={VAT_NUMBER}
            value={values[VAT_NUMBER]}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.legal.vat_number')}
            disabled={disabled}
          />
          <StackDiv />
        </Stack>
        <Separator spaceAfter="large" />
        <Stack direction="row" spaceAfter="large">
          <InputField
            name={BANK_ACCOUNT_NUMBER}
            value={values[BANK_ACCOUNT_NUMBER]}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.legal.bank_account_number')}
            disabled={disabled}
          />
          <InputField
            name={BANK_NAME_AND_ADDRESS}
            value={values[BANK_NAME_AND_ADDRESS]}
            onChange={handleChange}
            onBlur={handleBlur}
            label={polyglot.t('smart_pass.form.legal.bank_name_and_address')}
            disabled={disabled}
          />
        </Stack>
        <Separator spaceAfter="large" />
        <Stack direction="row">
          <StackDiv>
            <ContactPersonWrapper>
              <Text size="small" weight="bold" uppercase spaceAfter="small">
                {polyglot.t('smart_pass.form.legal.contact_person_1')}
              </Text>
              <Text type="secondary">
                {polyglot.t('smart_pass.form.legal.contact_person_1_descr')}
              </Text>
            </ContactPersonWrapper>
            <InputField
              name={CONTACT_PERSON_1_NAME}
              value={values[CONTACT_PERSON_1_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.name')}
              disabled={disabled}
              error={
                touched[CONTACT_PERSON_1_NAME] &&
                errors[CONTACT_PERSON_1_NAME] &&
                polyglot.t(errors[CONTACT_PERSON_1_NAME])
              }
            />
            <InputField
              name={CONTACT_PERSON_1_ROLE}
              value={values[CONTACT_PERSON_1_ROLE]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.role')}
              disabled={disabled}
            />
            <InputField
              name={CONTACT_PERSON_1_EMAIL}
              value={values[CONTACT_PERSON_1_EMAIL]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.email')}
              disabled={disabled}
              error={
                touched[CONTACT_PERSON_1_EMAIL] &&
                errors[CONTACT_PERSON_1_EMAIL] &&
                polyglot.t(errors[CONTACT_PERSON_1_EMAIL])
              }
            />
            <InputField
              name={CONTACT_PERSON_1_PHONE}
              value={values[CONTACT_PERSON_1_PHONE]}
              onChange={handleChange}
              onBlur={handleBlur}
              label={polyglot.t('smart_pass.form.legal.phone')}
              disabled={disabled}
              error={
                touched[CONTACT_PERSON_1_PHONE] &&
                errors[CONTACT_PERSON_1_PHONE] &&
                polyglot.t(errors[CONTACT_PERSON_1_PHONE])
              }
            />
          </StackDiv>
          <StackDiv>
            <ContactPersonWrapper>
              <Text size="small" weight="bold" uppercase spaceAfter="small">
                {polyglot.t('smart_pass.form.legal.contact_person_2')}
              </Text>
              <Text type="secondary" spaceAfter="large">
                {polyglot.t('smart_pass.form.legal.contact_person_2_descr')}
              </Text>
            </ContactPersonWrapper>
            <InputField
              name={CONTACT_PERSON_2_NAME}
              value={values[CONTACT_PERSON_2_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.name')}
              disabled={disabled}
            />
            <InputField
              name={CONTACT_PERSON_2_ROLE}
              value={values[CONTACT_PERSON_2_ROLE]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.role')}
              disabled={disabled}
            />
            <InputField
              name={CONTACT_PERSON_2_EMAIL}
              value={values[CONTACT_PERSON_2_EMAIL]}
              onChange={handleChange}
              onBlur={handleBlur}
              spaceAfter="large"
              label={polyglot.t('smart_pass.form.legal.email')}
              disabled={disabled}
              error={
                touched[CONTACT_PERSON_2_EMAIL] &&
                errors[CONTACT_PERSON_2_EMAIL] &&
                polyglot.t(errors[CONTACT_PERSON_2_EMAIL])
              }
            />
            <InputField
              name={CONTACT_PERSON_2_PHONE}
              value={values[CONTACT_PERSON_2_PHONE]}
              onChange={handleChange}
              onBlur={handleBlur}
              label={polyglot.t('smart_pass.form.legal.phone')}
              disabled={disabled}
            />
          </StackDiv>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default React.memo(Legal, propsComparison(legalFormKeys));
