const solutionTypes = [
  {
    name: 'getting_started.booking_tool',
    apiProducts: [
      'search-and-book-zooz',
      'flights-api-basic-credits',
      'search-and-book-multicity-zooz',
      'search-and-book-multicity',
      'search-and-book-nomad-zooz',
      'search-and-book-nomad',
    ],
  },
  { name: 'getting_started.widget', apiProducts: ['Widget'] },
  { name: 'getting_started.deeplink', apiProducts: ['Deeplinks and Banners'] },
  {
    name: 'getting_started.meta_search',
    apiProducts: ['Meta Search'],
  },
  {
    name: 'getting_started.smartpoint',
    apiProducts: ['smartpoint-plugin-deposit', 'smartpoint-plugin-zooz'],
  },
];

const v1_get_from = (solution, apiProducts) => {
  const productType = apiProducts?.find(product => solution.api_product_id === product.id)?.value;
  const gettingStartedType =
    solutionTypes.find(s => s.apiProducts.includes(productType))?.name || null;
  const cooperationType = apiProducts?.find(
    product => solution.api_product_id === product.id,
  )?.cooperationType;

  return {
    name: solution.name,
    cooperationType,
    productType,
    gettingStartedType,
    affilId: solution.affiliate_id,
    consumerKey: solution.api_key,
    status: solution.status,
    approvalDate: new Date(solution?.created_at),
    intendedUse: solution.intended_use,
    uuid: solution.uuid,
    webhookProduction: solution.webhook_production,
    webhookStaging: solution.webhook_staging,
    csEmail: solution.cs_email,
    quotaConfig: {
      quota: solution.quota_config.quota,
      quotaInterval: solution.quota_config.quota_interval,
      quotaTimeUnit: solution.quota_config.quota_time_unit,
    },
  };
};

const v1_post_to = solution => {
  return {
    name: solution.name,
    api_product_id: solution.id,
    intended_use: solution.intendedUse || undefined,
    display_name: solution.name,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    post: {
      from: v1_get_from,
      to: v1_post_to,
    },
  },
};
