import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const TabLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  height: 56px;
  box-shadow: inset 0px -1px 0px 0px rgb(232, 237, 241);
`;

export const TabLink = styled(({ active, backButton, ...props }) => <Link {...props} />)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  font-size: 12px;
  border-radius: 3px;
  transition: all 150ms ease-in-out;
  font-weight: 700 !important;
  background-color: ${({ theme }) => theme.orbit.backgroundButtonLinkPrimary};
  cursor: pointer;
  margin: 0 2px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${({ icon }) =>
    icon &&
    css`
      margin-right: 16px;
      padding: 0 4px;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.navigationSecondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.navigationSecondary};
  }

  ${({ backButton }) =>
    backButton &&
    css`
      background-color: ${({ theme }) => theme.colors.navigationSecondary};
      color: ${({ theme }) => theme.colors.tabNavigation};

      &:hover {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.navigationSecondary};
      color: ${({ theme }) => theme.colors.tabNavigation};

      &:hover {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.navigationSecondary};
        color: ${({ theme }) => theme.colors.tabNavigation};
      }
    `}
`;
