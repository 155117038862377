import React from 'react';
import { useHistory } from 'react-router-dom';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { useSearch } from 'components/services/search';
import { Flex } from 'common';
import { ROUTE_CONFIG } from 'consts/routes';
import { BOOKABLE_PRODUCT_TYPES } from 'consts/search';
import { getPassengersAmountsQueryString } from 'utils/search';

import useInvalidDeposit from 'components/services/bookingTool/useInvalidDeposit';
import FlightLeg from './FlightLeg';
import { PriceSpace, MaxWidthDiv } from './FlightCard.styled';

const FlightCardSummary = ({
  routeGroups,
  searchResult,
  getFlightLegProps,
  wrapFlightLeg,
  price,
}) => {
  const polyglot = usePolyglot();
  const { tabData } = useSearch();
  const history = useHistory();
  const { booking_token, id } = searchResult;
  const invalidDeposit = useInvalidDeposit();
  const passengers = getPassengersAmountsQueryString(tabData.passengersAmounts);
  const productType = tabData.productType;

  const goBook = () =>
    history.push(ROUTE_CONFIG.KIWI_BOOKING.path, {
      apiKey: tabData.consumerKey,
      flightsId: id,
      token: booking_token,
      passengers,
      productType,
      price,
      tripType: tabData.tripType,
      currency: tabData.currency,
      isSmartpointSearch: tabData.isSmartpointSearch,
      routeGroups,
      affilId: tabData.affilId,
    });
  return (
    <Stack direction="row" align="center" justify="between">
      <MaxWidthDiv>
        <Stack direction="column" shrink>
          {routeGroups
            .map(group => (
              <FlightLeg
                {...getFlightLegProps(group)}
                selectedStopAirports={tabData.selectedStopAirports}
              />
            ))
            .map((leg, index) => wrapFlightLeg(index, routeGroups, leg, polyglot))}
        </Stack>
      </MaxWidthDiv>
      <Flex main="end">
        <Flex column main="center">
          <Heading type="title2" as="h2">
            <PriceSpace right="l" data-test="flight-card-price">
              {price}
            </PriceSpace>
          </Heading>
        </Flex>
        <Flex self="center">
          {BOOKABLE_PRODUCT_TYPES.includes(productType) && (
            <Tooltip
              enabled={invalidDeposit}
              stopPropagation
              preferredAlign="start"
              preferredPosition="bottom"
              content={polyglot.t('searchAndBooking.searchResults.info_message.no_deposit.content')}
              tabIndex="0"
            >
              <Button disabled={invalidDeposit} onClick={goBook}>
                {polyglot.t('common.select')}
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};

export default FlightCardSummary;
