import React from 'react';
import { useLocation } from 'react-router-dom';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';

import { usePolyglot } from 'components/services/i18n';
import useBooking from 'components/services/booking/useBooking';

import RouteGroup from './components/RouteGroup';

const RouteGroups = ({ routeId, onSelect }) => {
  const {
    state: { routeGroups },
  } = useLocation();
  const polyglot = usePolyglot();
  const {
    seatingOfferData: { offers },
  } = useBooking();

  return (
    <Box display="flex" direction="column" padding="large">
      <Heading type="title2" spaceAfter="medium">
        {polyglot.t('booking_tool.seating.drawer.route_groups.title')}
      </Heading>
      {routeGroups.map(itinerary => {
        return (
          <>
            <Text size="large" weight="bold" spaceAfter="small">
              {polyglot.t('booking_tool.seating.drawer.route_groups.to_place.title', {
                location: itinerary[itinerary.length - 1].cityTo,
              })}
            </Text>
            {itinerary.map(route => (
              <RouteGroup
                route={route}
                onClick={() => {
                  onSelect(route.id);
                }}
                routeId={route.id}
                selected={routeId === route.id}
                disabled={!offers.find(offer => offer.segmentCode === route.id)}
              />
            ))}
          </>
        );
      })}
    </Box>
  );
};

export default RouteGroups;
