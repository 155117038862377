import * as R from 'ramda';

import { getWidgetTypeDateString, getInboundOutboundDaysString } from 'utils/widgets';
import { getQueryBags } from 'utils/widgets';
import { WIDGET_SIZE_TYPE } from 'consts/widgets';
import { initialValues } from 'consts/widgets';

const removeNullishValues = obj => {
  let newObj = {};
  const nullishValues = [null, undefined, ''];

  for (const key in obj) {
    const prop = obj[key];
    const propNotNullish = !nullishValues.includes(prop);
    if (propNotNullish) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const toDataParams = data => {
  const shouldExcludeAirlines = data.additionalSearchFilters.exclusion;
  const airlinesList = data.additionalSearchFilters.carriers.map(c => c.code);
  const { stopNumber } = data.additionalSearchFilters;

  const sizeTypeSymbol = data?.appearance?.sizeType === WIDGET_SIZE_TYPE.RELATIVE ? '%' : 'px';

  const passengers =
    data.search.passengersAndBags?.adults !== 1 ||
    data.search.passengersAndBags?.children >= 1 ||
    data.search.passengersAndBags?.infants >= 1
      ? `${data.search?.passengersAndBags?.adults}-${
          data.search?.passengersAndBags?.children || 0
        }-${data.search?.passengersAndBags?.infants || 0}`
      : undefined;

  const params = {
    ...(data?.appearance?.size ? { 'data-width': data.appearance.size + sizeTypeSymbol } : {}),
    'data-passengers': passengers,
    'data-currency': data.general.autoDetectCurrency ? undefined : data.general.currency,
    'data-lang': data.general.autoDetectLang ? undefined : data.general.language,
    'data-affilid': data.general.affilId,
    'data-brand': data.advanced.brandId,
    'data-from': data.search.from.length ? data.search.from.join(',') : undefined,
    'data-to': data.search.to.length ? data.search.to.join(',') : undefined,
    'data-departure': getWidgetTypeDateString(data.search?.departureDateRange),
    'data-return': getWidgetTypeDateString(data.search?.returnDateRange, data.search?.tripType),
    'data-inbound-days': getInboundOutboundDaysString(
      data.additionalSearchFilters.daysOfTravel.return,
    ),
    'data-outbound-days': getInboundOutboundDaysString(
      data.additionalSearchFilters.daysOfTravel.departure,
    ),
    'data-cabin': data.search.cabin.length === 1 ? data.search.cabin : undefined,
    'data-transport-types': data.search.transport.length
      ? data.search.transport.join(',')
      : undefined,
    'data-bags': getQueryBags(
      data.search?.passengersAndBags?.checkedBags,
      data.search?.passengersAndBags?.cabinBags,
      data.search?.passengersAndBags,
      data.search?.passengersAndBags?.adults,
    ),
    'data-stop-number': stopNumber !== -1 ? stopNumber : '',
    'data-limit': data.additionalSearchFilters.limit,
    'data-tile-limit': Number(data.additionalSearchFilters.tileLimit) || null,
    'data-sort-by': data.additionalSearchFilters.sortBy,
    'data-container-id': data.advanced.containerId,
    'data-iframe-id': data.advanced.iframeId,
    ...(data?.appearance?.colors
      ? {
          'data-primary-color': data.appearance.colors.primaryColor?.substr(1),
          'data-background-primary-color': data.appearance.colors.resultBackground?.substr(1),
          'data-background-secondary-color': data.appearance.colors.searchFormBackground?.substr(1),
          'data-foreground-primary-color': data.appearance.colors.searchFormSelectedText?.substr(1),
          'data-foreground-secondary-color':
            data.appearance.colors.searchFormSecondaryText?.substr(1),
        }
      : {}),
    'data-results-only':
      data.widgetType.value === 'resultWidget' ||
      data.widgetType.value === 'tileWidget' ||
      undefined,
    'data-pid': data.pid,
    'data-sid': data.sid,
    'data-sub1': data.sid1,
    'data-sub2': data.sid2,
    'data-sub3': data.sid3,
    'data-sub4': data.sid4,
    'data-sub5': data.sid5,
    'data-airlines-list': airlinesList.length ? airlinesList.join(',') : '',

    'data-airlines-exclude': airlinesList.length ? shouldExcludeAirlines : '',
  };

  return removeNullishValues(params);
};

export const mapFormValuesToApiValues = values => {
  let mappedValues = {};

  for (const key in initialValues) {
    let changedProps = {};

    const initialSection = initialValues[key];
    const section = values[key] || {};

    for (const prop in initialSection) {
      if (!R.equals(initialSection[prop], section[prop])) {
        changedProps = { ...changedProps, [prop]: section[prop] };
      }
    }

    let notEmpty = Object.keys(changedProps).length !== 0;

    if (notEmpty) {
      mappedValues = { ...mappedValues, [key]: { ...removeNullishValues(changedProps) } };
    }
  }

  return mappedValues;
};

export const mapApiConfigValuesToFormValues = config => {
  if (!config || R.isEmpty(config)) {
    return initialValues;
  }

  let mergedValues = {};

  for (const key in initialValues) {
    //filter only props that exist in initialValues

    let filteredSection = {};
    const section = config[key] || {};
    const initialSection = initialValues[key];

    for (const prop in section) {
      const propExistsInInitialSection = prop in initialSection;
      if (propExistsInInitialSection) {
        filteredSection[prop] = section[prop];
      }
    }

    mergedValues = {
      ...mergedValues,
      [key]: {
        ...initialValues[key],
        ...removeNullishValues(filteredSection),
      },
    };
  }

  return mergedValues;
};

export const mapWidgetDataToApiData = widgetData => {
  return {
    ...(widgetData.name && { name: widgetData.name }),
    ...(widgetData.affilId && { affiliate_id: widgetData.affilId }),
    config: mapFormValuesToApiValues(widgetData.formValues),
  };
};

export const mapApiDataToWidgetData = apiData => {
  return {
    ...apiData,
    formValues: mapApiConfigValuesToFormValues(apiData.config),
  };
};

export const mapApiDataToPaginationData = apiDataPagination => {
  return {
    pageSize: apiDataPagination.page_size,
    pages: apiDataPagination.pages,
    total: apiDataPagination.total,
  };
};
