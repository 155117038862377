import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import debounce from 'debounce';

import { getLocation, getLocationName } from 'utils/deeplinkGenerator';

import { AutocompleteMulti } from 'common';
import { usePolyglot } from 'components/services/i18n';

const LocationsAutocomplete = ({
  selectedValues,
  onSelect,
  onUnselect,
  label,
  inlineLabel,
  prefix,
  className,
  locationTypes,
  dataTest,
  placeholder,
  disabled,
  error,
  help,
}) => {
  const [query, setQuery] = useState('');
  const [filteredValues, setFilteredValues] = useState([]);
  const polyglot = usePolyglot();

  useEffect(() => {
    setFilteredValues([]);
  }, [locationTypes]);

  const getFilteredValues = value => {
    getLocation(value, locationTypes).then(response => {
      const values = R.differenceWith(
        (item, selectedItem) => item.id === selectedItem.value,
        response,
        selectedValues,
      );

      setFilteredValues(values);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFilteredValues = useCallback(debounce(getFilteredValues, 500), [selectedValues]);

  const handleQueryChange = e => {
    setQuery(e.target.value);
    updateFilteredValues(e.target.value);
  };

  const handleSelect = value => {
    setQuery('');
    onSelect(value);
  };

  const displayedItems = useMemo(() => {
    if (filteredValues.length === 0 && query) {
      return [
        {
          value: 'location-autocomplete-no-results-placeholder',
          label: polyglot.t('deeplink_generator.no_locations_for_query'),
          disabled: true,
        },
      ];
    } else if (filteredValues.length === 0) {
      return [
        {
          value: 'location-autocomplete-placeholder',
          label: polyglot.t('common.type_to_get_results'),
          disabled: true,
        },
      ];
    }
    return filteredValues.map(value => ({
      label: getLocationName(value),
      value: value.id,
      type: value.type,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredValues]);

  return (
    <AutocompleteMulti
      label={label}
      inlineLabel={inlineLabel}
      prefix={prefix}
      onSelect={handleSelect}
      disabled={disabled}
      error={error}
      options={displayedItems}
      onChange={handleQueryChange}
      selectedOptions={selectedValues}
      onRemove={onUnselect}
      className={className}
      dataTest={dataTest}
      placeholder={placeholder}
      help={help}
    />
  );
};

LocationsAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  label: PropTypes.node,
  prefix: PropTypes.string,
  dataTest: PropTypes.string,
};

export default LocationsAutocomplete;
