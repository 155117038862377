import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';
import { useLocation } from 'react-router-dom';

import Loading from '@kiwicom/orbit-components/lib/Loading';

import { useDependencyEffect } from 'hooks';
import useBooking from 'components/services/booking/useBooking';
import { usePolyglot } from 'components/services/i18n';
import useSeating from 'components/services/bookingToolSeating/useSeating';

import ErrorSeatingOffers from '../ErrorSeatingOffers';
import SeatingSummaryTable from '../SeatingSummaryTable';

const SeatingContent = () => {
  const {
    state: { apiKey, token, currency },
  } = useLocation();
  const polyglot = usePolyglot();
  const { watch } = useFormContext();
  const { passengers } = watch();
  const {
    data: { sessionId },
    bulkFetchSeatingOffers,
    seatingOfferLoading,
    seatingOfferError,
  } = useBooking();
  const { resetAllSeatings } = useSeating();
  const paxCategories = passengers.map(passenger => passenger.category);

  useEffect(() => {
    bulkFetchSeatingOffers(apiKey, sessionId, token, currency, passengers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(paxCategories)]);

  useDependencyEffect(() => {
    resetAllSeatings();
  }, [JSON.stringify(paxCategories)]);

  if (seatingOfferLoading === undefined || seatingOfferLoading) {
    return <Loading text={polyglot.t('booking_tool.seating.drawer.loader')} />;
  } else if (seatingOfferError) {
    return (
      <ErrorSeatingOffers
        title={polyglot.t('booking_tool.seating_card.error.title')}
        description={polyglot.t('booking_tool.seating_card.error.description')}
        buttonText={polyglot.t('booking_tool.seating_card.error.reload_page')}
      />
    );
  }

  return <SeatingSummaryTable />;
};

export default SeatingContent;
