import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import InputGroup from '@kiwicom/orbit-components/lib/InputGroup';

import { usePolyglot } from 'components/services/i18n';

const DateInput = ({ name, label, disabled, dataTest }) => {
  const { errors, control } = useFormContext();
  const polyglot = usePolyglot();

  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => {
        const handleChange = e => {
          onChange({
            ...value,
            [e.target.name]: e.target.value,
          });
        };

        return (
          <InputGroup
            label={label}
            onChange={handleChange}
            error={polyglot.t(errors[name]?.message) ?? null}
            dataTest={dataTest}
          >
            <InputField name="day" value={value.day} placeholder="DD" disabled={disabled} />
            <InputField name="month" value={value.month} placeholder="MM" disabled={disabled} />
            <InputField name="year" value={value.year} placeholder="YYYY" disabled={disabled} />
          </InputGroup>
        );
      }}
    />
  );
};

export default DateInput;
