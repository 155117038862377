import React, { useState } from 'react';

import MenuMeatballs from '@kiwicom/orbit-components/lib/icons/MenuMeatballs';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';

import { ClickOutside } from 'common';
import { PopoverContainer, PopoverContent } from './Popover.styled';

// temporary component until orbit popover is finished
// https://orbit.kiwi/roadmap/component-status-%F0%9F%9A%80/
const Popover = ({ dataTest, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickOutside onClick={() => setOpen(false)}>
      <ButtonLink
        circled
        dataTest={dataTest}
        type="secondary"
        title="Popover"
        onClick={() => setOpen(!open)}
        iconLeft={<MenuMeatballs />}
      />
      <PopoverContainer>{open && <PopoverContent>{children}</PopoverContent>}</PopoverContainer>
    </ClickOutside>
  );
};

export default Popover;
