import { useContext } from 'react';

import { isProduction } from 'utils/env';
import { useWindowSize } from 'hooks';

import { InitialQueryParamsState } from './';

export const HIDE_REGISTRATION_BACKGROUND = ['smartpoint'];
const UIS_WITH_DISABLED_NONDESKTOP_SCREEN = ['smartpoint'];

const useUiConfig = () => {
  const { ui } = useContext(InitialQueryParamsState);
  const { width } = useWindowSize('debounce');

  return {
    showNonDesktop:
      isProduction() && !UIS_WITH_DISABLED_NONDESKTOP_SCREEN.includes(ui) && width <= 960,
    hideRegistrationBackground: HIDE_REGISTRATION_BACKGROUND.includes(ui),
  };
};

export default useUiConfig;
