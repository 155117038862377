import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import PassengersIcon from '@kiwicom/orbit-components/lib/icons/Passengers';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';

import { Authorization } from 'common/Authorization';

const Users = ({ polyglot, user, clickAction }) => (
  <Card>
    <CardSection
      icon={<PassengersIcon />}
      title={polyglot.t('add_details.add_users')}
      description={polyglot.t('add_details.add_users_subtitle')}
    />
    <CardSection>
      <Authorization resource="model.company" action={['update', 'update_assigned', 'update_own']}>
        <Link to={`/companies/${user.companyName}/users`}>
          <Button iconLeft={<PlusIcon />} size="small" onClick={clickAction}>
            {polyglot.t('add_details.add_user')}
          </Button>
        </Link>
      </Authorization>
    </CardSection>
  </Card>
);

export default Users;
