import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import InputGroup from '@kiwicom/orbit-components/lib/InputGroup';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';

import { monthsOptions } from 'consts/dates';
import { usePolyglot } from 'components/services/i18n';

const DateInputWrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const DateInputGroup = ({
  label,
  name,
  onChange,
  onBlur = null,
  trigger = null,
  disabled,
  error,
  dataTest,
  value = null,
}) => {
  const polyglot = usePolyglot();
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  const handleChange = e => {
    if (e.target.id === `${name}-day`) {
      setDay(e.target.value);
      onChange(`${year}-${month}-${e.target.value}`);
    } else if (e.target.id === `${name}-month`) {
      setMonth(e.target.value);
      onChange(`${year}-${e.target.value}-${day}`);
    } else if (e.target.id === `${name}-year`) {
      setYear(e.target.value);
      onChange(`${e.target.value}-${month}-${day}`);
    }
    if (trigger) trigger(name);
  };

  const handleBlur = e => {
    if (day.length === 1) {
      setDay(`0${day}`);
      onChange(`${year}-${month}-0${day}`);
    }
    if (onBlur) onBlur(e);
  };

  useEffect(() => {
    if (value) {
      const date = value.split('-');
      setYear(date[0]);
      setMonth(date[1]);
      setDay(date[2]);
    } else {
      setYear('');
      setMonth('');
      setDay('');
    }
  }, [value]);

  return (
    <InputGroup
      label={label}
      flex={['1', '3', '1.5']}
      error={disabled ? null : error}
      onBlur={handleBlur}
    >
      <DateInputWrapper>
        <InputField
          dataTest={`${dataTest}-day`}
          placeholder={polyglot.t('common.months.day_placeholder')}
          onChange={handleChange}
          value={day}
          type="number"
          id={`${name}-day`}
          disabled={disabled}
          maxValue={31}
        />
      </DateInputWrapper>
      <Select
        dataTest={`${dataTest}-month`}
        label={label}
        placeholder={polyglot.t('common.months.placeholder')}
        options={monthsOptions.map(o => ({ value: o.value, label: polyglot.t(o.label) }))}
        value={month}
        id={`${name}-month`}
        disabled={disabled}
        onChange={handleChange}
      />
      <DateInputWrapper>
        <InputField
          dataTest={`${dataTest}-year`}
          placeholder={polyglot.t('common.months.year_placeholder')}
          value={year}
          type="number"
          id={`${name}-year`}
          disabled={disabled}
          onChange={handleChange}
        />
      </DateInputWrapper>
    </InputGroup>
  );
};

export default DateInputGroup;
