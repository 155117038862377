import React, { useContext } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { SmartPassContext } from 'components/services/smartPass';
import { newSmartPassInitialValues } from 'consts/smartPass';
import { usePolyglot } from 'components/services/i18n';

const ActivationButton = ({ formKeys, isActivatedKey, name, setFieldValue, values, disabled }) => {
  const { changeDeactivateModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();

  const activate = () => {
    setFieldValue(isActivatedKey, true);
  };

  const deactivate = () => {
    const didChangeHappen = formKeys
      .filter(key => key !== isActivatedKey)
      .some(key => values[key] !== newSmartPassInitialValues[key]);

    if (didChangeHappen) {
      changeDeactivateModal({
        name,
        keys: formKeys,
      });
    } else {
      setFieldValue(isActivatedKey, false);
    }
  };

  return (
    <Tooltip
      enabled={values[isActivatedKey]}
      content={polyglot.t('smart_pass.form.deactivate_title_descr')}
    >
      <Button
        disabled={disabled}
        size="small"
        onClick={values[isActivatedKey] ? deactivate : activate}
        iconLeft={<InformationCircleIcon />}
      >
        {values[isActivatedKey]
          ? polyglot.t('smart_pass.form.deactivate')
          : polyglot.t('smart_pass.form.activate')}
      </Button>
    </Tooltip>
  );
};

export default ActivationButton;
