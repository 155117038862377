import { useEffect } from 'react';
import * as R from 'ramda';

import useSeating from './useSeating';

const useUpdatePassengerNameSeatMap = passengers => {
  const { updatePassengerNameSeatMap, seatings } = useSeating();

  useEffect(() => {
    const pax = passengers.map(({ firstName, lastName }, index) => ({
      id: index,
      firstName,
      lastName,
    }));

    if (seatings && Object.keys(seatings).length) {
      const allPax = [];
      Object.values(seatings).forEach(seating => {
        if (!seating.option && Object.keys(seating).length) {
          Object.values(seating).forEach(seat => {
            allPax.push(seat.passenger);
          });
        }
      });

      const uniquePassengers = R.uniq(allPax);

      pax.forEach(passenger => {
        const hasPassengerChangedFirstNameOrLastName = uniquePassengers.find(
          uniquedPassenger =>
            passenger.id === uniquedPassenger.id &&
            (passenger.firstName !== uniquedPassenger.firstName ||
              passenger.lastName !== uniquedPassenger.lastName),
        );

        if (hasPassengerChangedFirstNameOrLastName) {
          updatePassengerNameSeatMap(passenger);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(passengers)]);
};

export default useUpdatePassengerNameSeatMap;
