import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import SeatIcon from '@kiwicom/orbit-components/lib/icons/Seat';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import useSeating from 'components/services/bookingToolSeating/useSeating';
import { usePolyglot } from 'components/services/i18n';
import { useCurrency } from 'components/services/currencies';
import { getSingleSegmentPriceSum, formatMultiSeats } from 'utils/seating';
import { precisionRound } from 'utils/number';

import KebabMenu from '../KebabMenu';

const ActionButton = ({ disabled, routeId, onOpenDrawer, areActionButtonsDisabled }) => {
  const { seatings } = useSeating();
  const polyglot = usePolyglot();
  const { getFormattedPriceString } = useCurrency();
  const segment = seatings[routeId];

  if (disabled) {
    return (
      <Text type="secondary">
        {polyglot.t('booking_tool.seating.drawer.route_groups.group.assigned_by_carrier')}
      </Text>
    );
  } else if (segment && segment.option) {
    return (
      <>
        <Inline align="center" spacing="XXSmall">
          <SeatIcon size="small" />
          <Text type="secondary">
            {polyglot.t(`booking_tool.seating.drawer.no_seatmap.quick_option.${segment.option}`)}
          </Text>
        </Inline>
        <Text>
          {segment.price
            ? getFormattedPriceString(precisionRound(segment.price.amount, 2))
            : polyglot.t('booking_tool.seating.drawer.no_seatmap.quick_option.included')}
        </Text>
        <KebabMenu
          onOpenDrawer={onOpenDrawer}
          routeId={routeId}
          areActionButtonsDisabled={areActionButtonsDisabled}
        />
      </>
    );
  } else if (segment && Object.keys(segment).length) {
    return (
      <>
        <Inline align="center" spacing="XXSmall">
          <SeatIcon size="small" />
          <Text type="secondary">{formatMultiSeats(segment)}</Text>
        </Inline>
        <Text>{getFormattedPriceString(getSingleSegmentPriceSum(segment))}</Text>
        <KebabMenu
          onOpenDrawer={onOpenDrawer}
          routeId={routeId}
          areActionButtonsDisabled={areActionButtonsDisabled}
        />
      </>
    );
  }

  return (
    <>
      <Text type="secondary">{polyglot.t('booking_tool.seating_card.no_seats_selected')}</Text>
      <Tooltip
        content={polyglot.t('booking_tool.seating_card.add.seating.tooltip')}
        placement="top"
        enabled={areActionButtonsDisabled}
      >
        <Button
          type="primarySubtle"
          size="small"
          onClick={() => onOpenDrawer(routeId)}
          disabled={areActionButtonsDisabled}
        >
          {polyglot.t('booking_tool.seating_card.add.seating')}
        </Button>
      </Tooltip>
    </>
  );
};

export default ActionButton;
