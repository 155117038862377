import * as R from 'ramda';

export const filterNames = {
  STATUS: 'requestStatus',
  CREATED_BY: 'createdBy',
  TYPE: 'requestTypeId',
  SEARCH: 'searchTerm',
};

export const filterStatusValues = {
  ALL: 'ALL_REQUESTS',
  OPEN: 'OPEN_REQUESTS',
  CLOSED: 'CLOSED_REQUESTS',
};

export const filterStatusOptions = [
  { label: 'Any status', value: filterStatusValues.ALL },
  { label: 'Open requests', value: filterStatusValues.OPEN },
  { label: 'Closed requests', value: filterStatusValues.CLOSED },
];

export const filterCreatedByValues = {
  ME: 'OWNED_REQUESTS',
  ALL: 'ALL_REQUESTS',
};

export const filterCreatedByOptions = [
  { label: 'Created by anyone', value: filterCreatedByValues.ALL },
  { label: 'Created by me', value: filterCreatedByValues.ME },
];

export const filterTypeValues = {
  ALL: '',
};

// this gets populated with request types
export const filterTypeOption = { label: 'Any type', value: filterTypeValues.ALL };

export const filtersInitialValues = {
  [filterNames.STATUS]: filterStatusValues.ALL,
  [filterNames.CREATED_BY]: filterCreatedByValues.ALL,
  [filterNames.TYPE]: filterTypeValues.ALL,
  [filterNames.SEARCH]: '',
};

export const SERVICE_DESK = {
  BPSD: 'bpsd',
  CSSD: 'cssd',
};

export const HAS_CSSD_INTRO_ALERT_BEEN_SHOWN = 'HAS_CSSD_INTRO_ALERT_BEEN_SHOWN';

export const isCustomerSupportEnabled = () =>
  R.path(['userInfo', 'company', 'customerSupportEnabled'], window);
