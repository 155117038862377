export const PAYMENT_STATUSES_I18N = [
  {
    label: 'partner_payments.all',
    value: '',
  },
  {
    label: 'partner_payments.imported',
    value: 'imported',
  },
  {
    label: 'partner_payments.processed',
    value: 'processed',
  },
  {
    label: 'partner_payments.approved',
    value: 'approved',
  },
  {
    label: 'partner_payments.ignored',
    value: 'ignored',
  },
];

export const AUTOMATCHED = 'automatched';
