import * as R from 'ramda';

import {
  SEATMAP_ROW_TYPES,
  SEAT_FORMATED_TYPES,
  SEAT_MAP_SAVE_BOOKING_OPTION_NAME,
  SEAT_TYPES,
} from 'consts/seating';
import { precisionRound } from 'utils/number';
import { ANCILLARY_SEATING_STATUS } from 'consts/mmb';
import { MMB_SEATING_TABLE_BUTTON_TYPE } from 'components/scenes/Booking/scenes/DepositBooking/consts';

export const formatSingleSeat = seatName => {
  const splittedSeatName = seatName.split('-');
  return splittedSeatName[1] + splittedSeatName[0];
};

export const formatMultiSeats = segment => {
  const seats = [];

  Object.values(segment).forEach(seat => {
    seats.push(formatSingleSeat(seat.name));
  });

  return seats.join(', ');
};

export const getSingleSegmentPriceSum = segment => {
  let sum = 0;
  Object.values(segment).forEach(seat => {
    sum += precisionRound(seat.price.amount, 2);
  });

  return precisionRound(sum, 2);
};

export const getSeatingTotalSum = seatings => {
  let sum = 0;
  if (Object.keys(seatings).length) {
    const values = Object.values(seatings);

    values.forEach(value => {
      if (value.price) {
        sum += precisionRound(value.price.amount, 2);
      } else if (!value.option && Object.keys(value).length) {
        Object.keys(value).forEach(val => {
          sum += precisionRound(value[val].price.amount, 2);
        });
      }
    });
  }

  return precisionRound(sum, 2);
};

const createTitleRow = data => ({
  number: null,
  type: SEATMAP_ROW_TYPES.TITLE,
  data,
});

const createSeatingClassRow = data => ({
  number: null,
  type: SEATMAP_ROW_TYPES.SEATING_CLASS,
  data,
});

const createSeatRow = (number, data) => ({
  number,
  data,
  type: SEATMAP_ROW_TYPES.SEATS,
});

const createExitRow = () => ({
  number: null,
  type: SEATMAP_ROW_TYPES.EXIT,
  data: null,
});

const formatSeat = seat => {
  const {
    name,
    price: { currency, amount, base, merchant, service, serviceFlat },
    state,
    features,
  } = seat;
  const isExtraLegroom = features.find(feature => feature === SEAT_TYPES.EXTRA_LEG_ROOM);
  const isEmergencyExit = Boolean(features.find(feature => feature === SEAT_TYPES.EMERGENCY_EXIT));
  const resolvedAvailableType = isExtraLegroom
    ? SEAT_FORMATED_TYPES.EXTRA_LEG_ROOM
    : SEAT_FORMATED_TYPES.AVAILABLE;
  const isAvailable = state === SEAT_FORMATED_TYPES.AVAILABLE;
  const type = !isAvailable ? SEAT_FORMATED_TYPES.UNAVAILABLE : resolvedAvailableType;
  return {
    name,
    type,
    price: {
      amount,
      base,
      currency,
      merchant,
      service,
      serviceFlat,
    },
    isEmergencyExit,
  };
};

const uniqAndSort = (firstArray, secondArray) => {
  const combinedArrays = R.concat(firstArray, secondArray);
  const uniqArray = R.uniq(combinedArrays);
  return R.sort((a, b) => a.localeCompare(b), uniqArray);
};

const splitRowIntoGroups = (seats, columnGroups) =>
  columnGroups.reduce(
    (accumulator, seatGroup) => {
      const [groupedSeats, indexAcc] = accumulator;
      const currentGroupLength = seatGroup.length;
      const chunkedSeats = R.slice(indexAcc, indexAcc + currentGroupLength, seats);

      return [R.append(chunkedSeats, groupedSeats), indexAcc + currentGroupLength];
    },
    [[], 0],
  )[0];

const getFormatedColumnsAndExitRows = rows => {
  let exitRows = [];
  const formattedColumns = rows.reduce((columns, row, index) => {
    const seatGroups = R.prop('seatGroups', row);

    seatGroups.forEach(seatGroup => {
      const columnGroupTitleRaw = seatGroup.map(R.prop('column'));
      const columnGroupTitle = columnGroupTitleRaw.map(R.toUpper);

      seatGroup.forEach(seat => {
        const formattedSeat = formatSeat(seat);
        const columnName = R.toUpper(R.prop('column', seat));
        const isEmergencyRow = R.prop('features', seat).find(
          feature => feature === SEAT_TYPES.EMERGENCY_EXIT,
        );

        if (isEmergencyRow) {
          exitRows = R.uniq(R.append(index, exitRows));
        }

        if (R.isNil(columns[columnName])) {
          const seats = [];
          seats[index] = formattedSeat;

          columns[columnName] = {
            columnGroupTitle,
            seats,
          };
        } else {
          const seats = [...columns[columnName].seats];
          seats[index] = formattedSeat;

          columns[columnName] = {
            columnGroupTitle: uniqAndSort(columns[columnName].columnGroupTitle, columnGroupTitle),
            seats,
          };
        }
      });
    });

    return columns;
  }, {});

  const sortedExitRow = R.sort((a, b) => a - b, exitRows);

  return [formattedColumns, sortedExitRow];
};

const getSeatMapSeatingClassRow = (seatMapFormated, sectionClass) => {
  // Filter out only seats type rows
  const seatRowsOnly = seatMapFormated.filter(row => row.type === SEATMAP_ROW_TYPES.SEATS);
  // Find first and last seats type rows
  const firstSeatsRow = R.head(seatRowsOnly);
  const lastSeatsRow = R.last(seatRowsOnly);

  const classRowPosition = seatMapFormated.findIndex(row => row.type === SEATMAP_ROW_TYPES.SEATS);
  const seatingClassRow = createSeatingClassRow({
    seatingClass: sectionClass,
    range: [Number(firstSeatsRow?.number), Number(lastSeatsRow?.number)],
  });

  return [seatingClassRow, classRowPosition];
};

const getColumnGroups = formattedColumns => {
  const columnNames = R.keys(formattedColumns);
  const columnNamesSorted = columnNames.sort((a, b) => a.localeCompare(b));
  const columnGroups = columnNamesSorted.reduce((groups, column) => {
    const columnTitleGroups = formattedColumns[column].columnGroupTitle;
    return R.uniq(R.append(columnTitleGroups, groups));
  }, []);

  return [columnGroups, columnNamesSorted];
};

const getSeatMapFormatted = ({ rows, sectionClass }) => {
  const [formattedColumns, exitRows] = getFormatedColumnsAndExitRows(rows);
  const [columnGroups, columnNamesSorted] = getColumnGroups(formattedColumns);
  const numberOfRows = rows.length;

  let seatMapFormated = [];

  // Insert seat rows
  for (let index = 0; index < numberOfRows; index += 1) {
    const seats = columnNamesSorted.map(column => formattedColumns[column].seats[index] ?? null);
    const rowNumber = Number(R.prop('rowNumber', rows[index]));
    const seatsGrouped = splitRowIntoGroups(seats, columnGroups);
    seatMapFormated[index] = createSeatRow(rowNumber, seatsGrouped);
  }

  // Insert exit rows
  exitRows.forEach((rowIndex, index) => {
    seatMapFormated = R.insert(rowIndex + index, createExitRow(), seatMapFormated);
  });

  // Insert seating class row
  const [seatingClassRow, classRowPosition] = getSeatMapSeatingClassRow(
    seatMapFormated,
    sectionClass,
  );
  seatMapFormated = R.insert(classRowPosition, seatingClassRow, seatMapFormated);

  //  Insert title row
  const titleRow = createTitleRow(columnGroups);
  seatMapFormated = R.insert(0, titleRow, seatMapFormated);

  return seatMapFormated;
};

export const getSegmentFormattedSeatMapData = (offers, routeId) => {
  const selectedSegmentData = offers?.find(offer => offer.segmentCode === routeId);

  if (!selectedSegmentData) {
    return null;
  }

  const defaultDeck = selectedSegmentData.seatmap.find(it => it.deck === 'default');

  if (!defaultDeck) {
    return null;
  }

  return getSeatMapFormatted(defaultDeck);
};

export const getSegmentSeatMapPriceRange = (offers, routeId) => {
  const selectedSegmentData = offers?.find(offer => offer.segmentCode === routeId);

  if (!selectedSegmentData) {
    return null;
  }

  const defaultDeck = selectedSegmentData.seatmap.find(it => it.deck === 'default');

  if (!defaultDeck) {
    return null;
  }

  const rows = defaultDeck.rows;
  const extraLegroom = [];
  const standard = [];
  const priceRangeExtraLegroom = [];
  const priceRangeStandard = [];

  rows.forEach(row => {
    row.seatGroups.forEach(seatGroup => {
      seatGroup.forEach(seat => {
        if (seat.state === 'available') {
          const isExtraLegroom = seat.features.find(
            feature => feature === SEAT_TYPES.EXTRA_LEG_ROOM,
          );
          if (!isExtraLegroom) {
            standard.push(precisionRound(seat.price.amount, 2));
          } else {
            extraLegroom.push(precisionRound(seat.price.amount, 2));
          }
        }
      });
    });
  });

  if (extraLegroom.length) {
    priceRangeExtraLegroom.push(Math.min(...extraLegroom));
    priceRangeExtraLegroom.push(Math.max(...extraLegroom));
  }

  if (standard.length) {
    priceRangeStandard.push(Math.min(...standard));
    priceRangeStandard.push(Math.max(...standard));
  }

  return [priceRangeExtraLegroom, priceRangeStandard];
};

export const groupSeatsSeatingTable = bookingItem => {
  if (bookingItem) {
    let groupedSeatsSeatingTable = {
      [ANCILLARY_SEATING_STATUS.CONFIRMED]: [],
      [ANCILLARY_SEATING_STATUS.PROCESSING]: [],
    };

    Object.keys(bookingItem).forEach(bookingItemKey => {
      Object.keys(bookingItem[bookingItemKey]).forEach(bookingItemKey2 => {
        groupedSeatsSeatingTable = {
          ...groupedSeatsSeatingTable,
          [bookingItem[bookingItemKey][bookingItemKey2].status]: [
            ...(groupedSeatsSeatingTable?.[bookingItem[bookingItemKey][bookingItemKey2]?.status] ||
              []),
            {
              option: bookingItemKey,
              source: bookingItemKey2,
              seats: bookingItem[bookingItemKey][bookingItemKey2].seats,
            },
          ],
        };
      });
    });

    return groupedSeatsSeatingTable;
  }

  return null;
};

export const generateButtonType = (bookingItem, passengersCount, segmentCode, offers) => {
  if (bookingItem) {
    let paxCount = 0;
    Object.values(bookingItem).forEach(bookingItemI => {
      Object.values(bookingItemI).forEach(bookingItemI2 => {
        paxCount += bookingItemI2.seats.length;
      });
    });

    if (paxCount >= passengersCount) {
      return null;
    }

    return MMB_SEATING_TABLE_BUTTON_TYPE.ADD_MORE;
  }

  const hasOffer = offers.find(offer => offer.segmentCode === segmentCode);

  if (hasOffer) {
    return MMB_SEATING_TABLE_BUTTON_TYPE.ADD;
  }

  return null;
};

export const groupSeatsByStatus = (items, polyglot, status) => {
  const groupedSeatsByStatus = [];

  if (status === ANCILLARY_SEATING_STATUS.PROCESSING) {
    items.forEach(item => {
      if (item.option !== SEAT_MAP_SAVE_BOOKING_OPTION_NAME) {
        groupedSeatsByStatus.push(
          polyglot.t(`tequila.ancillary_seating.mmb.no_seatmap.quick_option.${item.option}`),
        );
      } else {
        item.seats.forEach(seat => {
          groupedSeatsByStatus.push(formatSingleSeat(seat.seat));
        });
      }
    });
  } else if (status === ANCILLARY_SEATING_STATUS.CONFIRMED) {
    items.forEach(item => {
      item.seats.forEach(seat => {
        groupedSeatsByStatus.push(formatSingleSeat(seat.bookedSeat));
      });
    });
  }

  return groupedSeatsByStatus.join(', ');
};
