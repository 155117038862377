import styled from 'styled-components';

export const FixedWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
  width: 100vw;
`;

export const MenuBarWrapper = styled.div`
  background: ${({ theme }) => theme.orbit.paletteWhite};
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
`;

export const StackDiv = styled.div`
  flex: 1 1 100%;
`;

export const AlertWrapper = styled.div`
  margin-left: calc(((100vw - 855px) - 270px) / 2);
  max-width: 855px;
`;
