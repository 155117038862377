const v1_get_from = response => {
  return {
    data: response.data.map(product => ({
      articleCodename: product.setup_guide_kentico_codename,
      id: product.id,
      cooperationType: product.cooperation.type,
      category: product.product_category.toUpperCase(),
      csPhone: product.default_cs_phone_number,
      dashboardMetrics: product.dashboard_metrics,
      description: product.description,
      displayOrder: product.product_display_order,
      enables: product.enables,
      name: product.display_name,
      isDeposit: product.is_deposit,
      notPublished: !product.is_published,
      quotaConfig: {
        quota: product.quota,
        quotaInterval: product.quota_interval,
        quotaTimeUnit: product.quota_time_unit,
      },
      termsAndConditionsCodename: product.terms_and_conditions_kentico_codename,
      value: product.name,
    })),
    paging: response.paging,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
