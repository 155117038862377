import styled, { keyframes } from 'styled-components';

const fromRightToLeft = keyframes`
  0% {
    opacity: 0;
    right: -100%;
  }
  100% {
    opacity: 1;
    right: 0%;
  }
`;

const fromLeftToRight = keyframes`
  0% {
    opacity: 1;
    right: 0%;
  }
  100% {
    opacity: 0;
    right: -100%;
  }
`;

export const DrawerWrapper = styled.div`
  aside {
    animation: ${({ isFadeOutAnimation }) =>
        isFadeOutAnimation ? fromLeftToRight : fromRightToLeft}
      0.25s forwards;
    & > div {
      &:nth-of-type(2) {
        margin: 0;
      }
    }
  }
`;

export const StyledText = styled.p`
  white-space: nowrap;
  color: #697d95;
  font-weight: normal;
  font-size: 0.75em;
  padding-right: 12px;
`;

export const StyledTextRoute = styled.p`
  white-space: nowrap;
  color: #252a31;
  font-weight: normal;
  font-size: 0.75em;
  display: flex;
  align-items: center;
`;

export const RouteListingBoxWrapper = styled.div`
  min-width: 460px;
  max-width: 460px;
  height: calc(100vh - 64px);
  background-color: #fff;
  margin: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ContentBoxWrapper = styled.div`
  padding: 32px 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 128px);
  overflow-y: scroll;
`;
