const v1_get_from = (solution, apiProducts) => {
  const cooperationType = apiProducts?.find(
    product => solution.api_product_id === product.id,
  )?.cooperationType;

  return {
    name: solution.name,
    affilId: solution.affiliate_id,
    cooperationType,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
