import React, { useState, useEffect } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import AlertButton from '@kiwicom/orbit-components/lib/Alert/AlertButton';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import useSearch from 'components/services/search/useSearch';
import { usePolyglot } from 'components/services/i18n';
import { useLocations, useFromToLocations } from 'components/services/bookingTool';
import {
  getIataCodes,
  areLocationsExpanded,
  getSelectedVehicleTypes,
  getIsGroundTransportSelected,
} from 'utils/search';

const GroundAlert = () => {
  const [showGroundAlert, setShowGroundAlert] = useState(true);
  const polyglot = usePolyglot();
  const { tabData, expandGroundLocations } = useSearch();
  const fromToLocations = useFromToLocations();
  const iataCodes = getIataCodes(fromToLocations);
  const expandedLocations = useLocations(iataCodes);
  const locationsExpanded = areLocationsExpanded(fromToLocations, expandedLocations);
  const selectedVehicleTypes = getSelectedVehicleTypes(tabData);
  const isGroundTransportSelected = getIsGroundTransportSelected(selectedVehicleTypes);

  useEffect(() => {
    setShowGroundAlert(true);
  }, [tabData.vehicleTypes]);

  return (
    <>
      {!locationsExpanded && isGroundTransportSelected && showGroundAlert && (
        <Stack spaceAfter="large">
          <Alert
            title={polyglot.t('searchAndBooking.ground.more_ground_transport_title')}
            type="info"
            closable
            onClose={() => setShowGroundAlert(false)}
            spaceAfter="large"
          >
            <Text spaceAfter="large">
              {polyglot.t('searchAndBooking.ground.more_ground_transport_content')}
            </Text>
            <Stack direction="row" spacing="XSmall">
              <AlertButton size="small" onClick={() => expandGroundLocations(expandedLocations)}>
                {polyglot.t('searchAndBooking.ground.expand_search')}
              </AlertButton>
              <AlertButton size="small" type="infoSubtle" onClick={() => setShowGroundAlert(false)}>
                {polyglot.t('searchAndBooking.ground.not_now')}
              </AlertButton>
            </Stack>
          </Alert>
        </Stack>
      )}
    </>
  );
};

export default GroundAlert;
