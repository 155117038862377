export const getSessionStorageItem = keyname => sessionStorage.getItem(keyname);

export const setSessionStorageItem = (keyname, value) => {
  sessionStorage.setItem(keyname, value);
};

export const removeSessionStorageItem = keyname => {
  sessionStorage.removeItem(keyname);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};
