import React from 'react';
import ReactJson from 'react-json-view';
import omit from 'ramda/es/omit';
import styled from 'styled-components';
import format from 'date-fns/format';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import Modal, { ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Document from '@kiwicom/orbit-components/lib/icons/Document';
import { TableRow, TableCell } from '@kiwicom/orbit-components/lib/Table';

import { FakeCell } from 'common';

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const LogRow = ({ data, polyglot }) => {
  const [open, setOpen] = React.useState(false);
  const [expandAll, setExpandAll] = React.useState(false);
  const [collapseAll, setCollapseAll] = React.useState(false);
  // used for force rendering of the react-json-view component. Sadly, it has to be this way.
  const [id, setId] = React.useState(0);

  const toggleModal = () => {
    setOpen(!open);
    setExpandAll(false);
    setCollapseAll(false);
    setId(id + 1);
  };

  const expandData = () => {
    setExpandAll(true);
    setCollapseAll(false);
    setId(id + 1);
  };

  const collapseData = () => {
    setCollapseAll(true);
    setExpandAll(false);
    setId(id + 1);
  };

  const collapsedState = React.useMemo(() => {
    if (expandAll) {
      return false;
    }
    if (collapseAll) {
      return true;
    }
    return 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, collapseAll, id]);

  return (
    <>
      {open && (
        <Portal>
          <Modal size="extraLarge" onClose={toggleModal}>
            <ModalSection>
              <Stack direction="row" spacing="XSmall" spaceAfter="normal">
                <Button onClick={expandData} type="secondary" size="small">
                  {polyglot.t('logs.expand_all')}
                </Button>
                <Button onClick={collapseData} type="secondary" size="small">
                  {polyglot.t('logs.collapse_all')}
                </Button>
              </Stack>
              <ReactJson
                src={data}
                name="Company Logs"
                theme="summerfruit:inverted"
                collapsed={collapsedState}
                displayDataTypes={false}
                id={id}
              />
            </ModalSection>
          </Modal>
        </Portal>
      )}
      <TableRow onClick={toggleModal}>
        <TableCell>
          <ClickableIcon data-test="open-logs-modal" onClick={toggleModal}>
            <Document />
          </ClickableIcon>
        </TableCell>
        <TableCell align="center">
          {format(new Date(data.timestamp), 'EEE HH:mm, dd MMM yyyy XX')}
        </TableCell>
        <TableCell align="left">
          <FakeCell>{JSON.stringify(omit(['timestamp'], data))}</FakeCell>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(LogRow);
