import { ContentItem } from 'kentico-cloud-delivery/_bundles/kentico-cloud-delivery-sdk.browser.umd.min';

import { ROUTE_CONFIG } from 'consts/routes';
import { mapSwaggerDocumentationFields } from 'utils/kentico';

class SwaggerDocumentation extends ContentItem {
  searchableCategory = 'Tequila API';

  constructor() {
    super({
      propertyResolver: mapSwaggerDocumentationFields,
    });
  }

  getTitle() {
    return this.system.name;
  }

  get searchableTitle() {
    return this.system.name;
  }

  get searchableContent() {
    return this.yaml.value;
  }

  get isSearchable() {
    return this.api_reference.value.length > 0;
  }

  get taxonomyCodename() {
    return this.isSearchable && this.system.codename;
  }

  get toPath() {
    return `${ROUTE_CONFIG.DOCS_TEQUILA_API_SINGLE.toPath}/${this.system.codename}`;
  }
}

export default SwaggerDocumentation;
