import React from 'react';
import PropTypes from 'prop-types';

import Select from '@kiwicom/orbit-components/lib/Select';

import { INTERVAL_VALUES_I18N } from 'consts/reports';
import { usePolyglot } from 'components/services/i18n';
import { GroupByContainer } from './GroupBy.styled';

const GroupBy = ({ id, value, onChange, className, disabled, size = 'normal' }) => {
  const polyglot = usePolyglot();

  return (
    <GroupByContainer className={className}>
      <Select
        id={id || 'report-interval'}
        value={value}
        onChange={event => onChange(event.target.value)}
        disabled={disabled}
        size={size}
        options={INTERVAL_VALUES_I18N.map(item => ({
          label: polyglot.t(item.k),
          value: item.value,
        }))}
      />
    </GroupByContainer>
  );
};

GroupBy.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default GroupBy;
