import React, { useReducer } from 'react';
import qs from 'query-string';

import statusTypes from 'consts/statusTypes';
import api, { proxy } from 'utils/api';
import CheckFlightsMapper from 'mappers/nextGen/booking/check_flights/_';
import AncillariesMapper from 'mappers/nextGen/booking/ancillaries/offers/check/_';
import getOptions from 'components/scenes/ManageMyBookings/scenes/BookingDetails/services/getBaggageOptions';
import { ANCILLARIES } from 'consts/booking';
import { SEATING_STATUS } from 'consts/seating';
import { sleep } from 'utils/time';

export const BookingState = React.createContext({
  generateToken: () => [],
});

const initialState = {
  userTokenStatus: statusTypes.NONE,
  userToken: null,
  loadingPrebookingConfig: false,
  prebookingConfig: null,
  prebookingConfigError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_TOKEN':
      return {
        ...state,
        userTokenStatus: statusTypes.LOADING,
        userToken: null,
      };
    case 'FETCH_USER_TOKEN_SUCCESS':
      return {
        ...state,
        userTokenStatus: statusTypes.SUCCESS,
        userToken: action.userToken,
      };
    case 'FETCH_USER_TOKEN_FAIL':
      return {
        ...state,
        userTokenStatus: statusTypes.ERROR,
      };
    case 'CHECK_FLIGHTS':
      return {
        ...state,
        loadingFlights: !action.append,
        loadingChanges: action.append,
        checkFlightsError: null,
      };
    case 'CHECK_FLIGHTS_SUCCESS':
      return {
        ...state,
        loadingFlights: false,
        loadingChanges: false,
        checkFlightsError: null,
        data: { ...state.data, ...action.payload },
      };
    case 'CHECK_FLIGHTS_FAIL':
      return {
        ...state,
        loadingFlights: false,
        loadingChanges: false,
        checkFlightsError: action.error,
      };
    case 'LOAD_PAID_GUARANTEE_OFFER':
      return {
        ...state,
        loadingGuaranteeOffer: true,
        guaranteeError: null,
      };
    case 'LOAD_PAID_GUARANTEE_OFFER_SUCCESS':
      return {
        ...state,
        loadingGuaranteeOffer: false,
        guaranteeOffer: action.data,
        guaranteeError: null,
      };
    case 'LOAD_PAID_GUARANTEE_OFFER_FAIL':
      return {
        ...state,
        loadingGuaranteeOffer: false,
        guaranteeError: action.error,
      };
    case 'LOAD_SEATING_OFFER':
      return {
        ...state,
        seatingOfferLoading: true,
        seatingOfferData: null,
        seatingOfferError: null,
      };
    case 'LOAD_SEATING_OFFER_SUCCESS':
      return {
        ...state,
        seatingOfferLoading: false,
        seatingOfferData: action.data,
        seatingOfferError: null,
      };
    case 'LOAD_SEATING_OFFER_FAIL':
      return {
        ...state,
        seatingOfferLoading: false,
        seatingOfferData: null,
        seatingOfferError: action.error,
      };
    case 'CLEAR_DATA':
      return initialState;
    default:
      return state;
  }
};

const BookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //Used in credit bookings for iframe
  const generateToken = async () => {
    dispatch({ type: 'FETCH_USER_TOKEN' });

    try {
      const {
        data: { access_token: nextGenAccessToken },
      } = await api.v1.post('mmb/token/create');
      const {
        data: { mmb_token: nextGenMMBToken },
      } = await api.v1.post('mmb/token/fetch', { access_token: nextGenAccessToken });

      return dispatch({ type: 'FETCH_USER_TOKEN_SUCCESS', userToken: nextGenMMBToken });
    } catch (error) {
      return Promise.reject(dispatch({ type: 'FETCH_USER_TOKEN_FAIL' }));
    }
  };

  const callCheckFlights = async (query, apiKey, tripType, searchItinerary) => {
    try {
      const { data } = await proxy.v2.get(`booking/check_flights?${query}`, {
        headers: { apikey: apiKey },
      });
      const res = CheckFlightsMapper.v2.get.from(data, tripType, searchItinerary);

      dispatch({ type: 'CHECK_FLIGHTS_SUCCESS', payload: res });

      return res;
    } catch (error) {
      dispatch({
        type: 'CHECK_FLIGHTS_FAIL',
        error,
      });
    }
  };

  const checkFlights = async (params, passengers, append = false) => {
    dispatch({ type: 'CHECK_FLIGHTS', append });

    const query = qs.stringify(CheckFlightsMapper.v2.get.to(params, passengers));

    await callCheckFlights(
      query || qs.stringify(CheckFlightsMapper.v2.get.to(params, passengers)),
      params.apiKey,
      params.tripType,
      params.routeGroups,
    );
  };

  const getBaggagePrice = (passengers, baggage, bagType) => {
    const bags = {
      amount: 0,
      price: 0,
    };

    for (const pax of passengers) {
      if (bagType === 'handBag') {
        const selectedHandBag = getOptions({
          baggage,
          passengerCategory: pax.category,
          pickerType: 'handBag',
          currentCombination: pax.baggage?.handBag,
        }).find(bag => {
          return bag.index === pax.baggage?.handBag;
        });

        if (selectedHandBag?.price?.amount > 0) {
          bags.price += Number(selectedHandBag?.price.amount);
          bags.amount += Number(selectedHandBag?.items[1].amount);
        }
      } else if (bagType === 'holdBag') {
        const selectedHoldBag = getOptions({
          baggage,
          passengerCategory: pax.category,
          pickerType: 'holdBag',
          currentCombination: pax.baggage?.holdBag,
        }).find(bag => {
          return bag.index === pax.baggage?.holdBag;
        });

        if (selectedHoldBag && selectedHoldBag?.index !== 0) {
          let amount = 0;

          Object.values(selectedHoldBag?.items).forEach(bag => {
            amount += Number(bag.amount);
          });

          bags.price += Number(selectedHoldBag?.price.amount);
          bags.amount += amount;
        }
      }
    }

    return bags;
  };

  const fetchPaidGuaranteeOffers = async (
    apiKey,
    sessionId,
    bookingToken,
    currency,
    passengers,
  ) => {
    try {
      dispatch({ type: 'LOAD_PAID_GUARANTEE_OFFER' });
      let data;

      if (currency === 'EUR') {
        const res = await proxy.v2.post(
          'booking/ancillaries/offers/check',
          AncillariesMapper.v2.post.to(bookingToken, sessionId, currency, passengers),
          {
            headers: { apikey: apiKey },
          },
        );
        data = AncillariesMapper.v2.post.from.guarantee(res.data);
      } else {
        const [eurPrice, selectedPrice] = await Promise.all([
          proxy.v2.post(
            'booking/ancillaries/offers/check',
            AncillariesMapper.v2.post.to(bookingToken, sessionId, 'EUR', passengers),
            {
              headers: { apikey: apiKey },
            },
          ),
          proxy.v2.post(
            'booking/ancillaries/offers/check',
            AncillariesMapper.v2.post.to(bookingToken, sessionId, currency, passengers),
            {
              headers: { apikey: apiKey },
            },
          ),
        ]);
        data = AncillariesMapper.v2.post.from.guarantee(eurPrice.data, selectedPrice.data);
      }

      dispatch({
        type: 'LOAD_PAID_GUARANTEE_OFFER_SUCCESS',
        data,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_PAID_GUARANTEE_OFFER_FAIL', error });
    }
  };

  const bulkFetchSeatingOffers = async (apiKey, sessionId, bookingToken, currency, passengers) => {
    dispatch({ type: 'LOAD_SEATING_OFFER' });

    try {
      const CALLS = 10;
      let data;

      await proxy.v2.post(
        'booking/ancillaries/offers/check?mode=preloading',
        AncillariesMapper.v2.post.to(
          bookingToken,
          sessionId,
          currency,
          passengers,
          ANCILLARIES.SEATING,
        ),
        {
          headers: { apikey: apiKey },
        },
      );

      await sleep(2000);

      for (let i = 0; i < CALLS; i++) {
        const res = await proxy.v2.post(
          'booking/ancillaries/offers/check',
          AncillariesMapper.v2.post.to(
            bookingToken,
            sessionId,
            currency,
            passengers,
            ANCILLARIES.SEATING,
          ),
          {
            headers: { apikey: apiKey },
          },
        );

        data = AncillariesMapper.v2.post.from.seating(res.data);

        if (data.status === SEATING_STATUS.LOADING) {
          await sleep(2000);
        } else {
          break;
        }
      }

      dispatch({
        type: 'LOAD_SEATING_OFFER_SUCCESS',
        data,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_SEATING_OFFER_FAIL', error });
    }
  };

  const clearData = () => {
    dispatch({ type: 'CLEAR_DATA' });
  };

  const value = {
    ...state,
    generateToken,
    checkFlights,
    getBaggagePrice,
    fetchPaidGuaranteeOffers,
    bulkFetchSeatingOffers,
    clearData,
  };
  return <BookingState.Provider value={value}>{children}</BookingState.Provider>;
};

export default BookingProvider;
