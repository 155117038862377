import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Modal, { ModalSection, ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import CreditCardIcon from '@kiwicom/orbit-components/lib/icons/CreditCard';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Button from '@kiwicom/orbit-components/lib/Button';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import ChevronUp from '@kiwicom/orbit-components/lib/icons/ChevronUp';
import ChevronDown from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import useRegisterTOS from 'components/services/resources/useRegisterTOS';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { useToggle } from 'utils/hooks';
import useInitialQueryParams from 'components/services/initialQueryParams/useInitialQueryParams';
import { SmartpointState } from 'components/services/smartpoint';
import { usePolyglot } from 'components/services/i18n';

// Fixes IE8 problems in Smartpoint plugin
const ModalContentWrapper = styled.div`
  width: 100%;
`;

const TOSTextContainer = styled.div`
  height: 152px;
  overflow-y: auto;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const paymentValues = {
  CREDIT_CARD: 'smartpoint-plugin-zooz',
  DEPOSIT: 'smartpoint-plugin-deposit',
};

const SmartpointInitModal = () => {
  const { ensurePCC, ensurePCCState } = useContext(SmartpointState);
  const [paymentValue, setPaymentValue] = useState(null);

  const termsOfServiceCheck = useToggle();
  const subscriberAgreementCheck = useToggle();
  const termsOfServiceExpanded = useToggle();

  const polyglot = usePolyglot();
  const registerTOS = useRegisterTOS();
  const { pcc } = useInitialQueryParams();

  const handlePaymentValueChange = e => {
    setPaymentValue(e.target.value);
  };

  const handleSubmit = () => {
    ensurePCC(pcc, paymentValue);
  };

  if (registerTOS.loading) {
    return (
      <Modal>
        <ModalSection>
          <Loading />
        </ModalSection>
      </Modal>
    );
  }

  if (registerTOS.error || !registerTOS.data) {
    return (
      <Modal>
        <ModalSection>
          <Alert type="critical">{polyglot.t('register.error_loading_tos')}</Alert>
        </ModalSection>
      </Modal>
    );
  }

  const isSubmitDisabled = !(
    paymentValue &&
    termsOfServiceCheck.isOn &&
    subscriberAgreementCheck.isOn
  );

  if (ensurePCCState.data) {
    return (
      <Modal size="small">
        <ModalSection>
          <Stack direction="column" align="center" spacing="XLarge">
            <Illustration name="Seating" />
            <Stack direction="column" align="center">
              <Text weight="bold" size="large">
                {polyglot.t('searchAndBooking.searchResults.smartpoint_init_modal.title')}
              </Text>
              <ModalContentWrapper>
                <Text type="secondary" align="center">
                  {polyglot.t('searchAndBooking.searchResults.smartpoint_init_modal.content')}
                </Text>
              </ModalContentWrapper>
            </Stack>
          </Stack>
        </ModalSection>
      </Modal>
    );
  }

  return (
    <Modal>
      <ModalHeader
        title={polyglot.t('common.create_solution')}
        description={
          <Text spaceAfter="large">
            {polyglot.t('searchAndBooking.searchResults.smartpoint_init_modal.description')}
          </Text>
        }
      />
      <ModalSection suppressed={true}>
        <ModalContentWrapper>
          <Text type="secondary" weight="bold" spaceAfter="medium">
            {polyglot.t(
              'searchAndBooking.searchResults.smartpoint_init_modal.choose_payment_option',
            )}
          </Text>
          <Stack direction="row" spaceAfter="largest">
            <Card>
              <CardSection>
                <Stack direction="column" align="center">
                  <Radio
                    onChange={handlePaymentValueChange}
                    checked={paymentValue === paymentValues.CREDIT_CARD}
                    value={paymentValues.CREDIT_CARD}
                    label={
                      <Stack direction="row" align="center">
                        <BoldText>
                          {polyglot.t(
                            'searchAndBooking.searchResults.smartpoint_init_modal.credit_card',
                          )}
                        </BoldText>
                        <CreditCardIcon />
                      </Stack>
                    }
                  />
                  <ModalContentWrapper>
                    <Text type="secondary" spaceAfter="medium">
                      {polyglot.t(
                        'searchAndBooking.searchResults.smartpoint_init_modal.credit_card_descr',
                      )}
                    </Text>
                  </ModalContentWrapper>
                </Stack>
              </CardSection>
            </Card>
            <Card>
              <CardSection>
                <Stack direction="column" align="center">
                  <Radio
                    onChange={handlePaymentValueChange}
                    checked={paymentValue === paymentValues.DEPOSIT}
                    value={paymentValues.DEPOSIT}
                    label={
                      <Stack direction="row" align="center">
                        <BoldText>
                          {polyglot.t(
                            'searchAndBooking.searchResults.smartpoint_init_modal.deposit',
                          )}
                        </BoldText>
                        <MoneyIcon />
                      </Stack>
                    }
                  />
                  <ModalContentWrapper>
                    <Text type="secondary" spaceAfter="medium">
                      {polyglot.t(
                        'searchAndBooking.searchResults.smartpoint_init_modal.deposit_descr',
                      )}
                    </Text>
                  </ModalContentWrapper>
                </Stack>
              </CardSection>
            </Card>
          </Stack>
          <Text type="secondary" weight="bold" spaceAfter="medium">
            {polyglot.t('common.terms_conditions')}
          </Text>
          <Stack>
            <Stack direction="row" justify="between">
              <ModalContentWrapper>
                <Checkbox
                  checked={termsOfServiceCheck.isOn}
                  label={polyglot.t(
                    'searchAndBooking.searchResults.smartpoint_init_modal.tos_accept_text',
                  )}
                  onChange={termsOfServiceCheck.toggle}
                />
              </ModalContentWrapper>
              <TextLink onClick={termsOfServiceExpanded.toggle}>
                {termsOfServiceExpanded.isOn ? (
                  <>
                    {polyglot.t(
                      'searchAndBooking.searchResults.smartpoint_init_modal.terms_and_conditions_expanded',
                    )}
                    <ChevronUp size="small" />
                  </>
                ) : (
                  <>
                    {polyglot.t(
                      'searchAndBooking.searchResults.smartpoint_init_modal.terms_and_conditions_non_expanded',
                    )}
                    <ChevronDown size="small" />
                  </>
                )}
              </TextLink>
            </Stack>
            {termsOfServiceExpanded.isOn && (
              <TOSTextContainer
                dangerouslySetInnerHTML={{ __html: registerTOS.data.compiledContent }}
              />
            )}
            <Checkbox
              checked={subscriberAgreementCheck.isOn}
              label={polyglot.t(
                'searchAndBooking.searchResults.smartpoint_init_modal.subscriber_agreement_check_text',
              )}
              onChange={subscriberAgreementCheck.toggle}
            />
          </Stack>
        </ModalContentWrapper>
      </ModalSection>
      <ModalFooter>
        <Tooltip
          enabled={isSubmitDisabled}
          content={polyglot.t(
            'searchAndBooking.searchResults.smartpoint_init_modal.submit_tooltip',
          )}
        >
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            width="95px"
            loading={ensurePCCState.loading}
          >
            {polyglot.t('common.done')}
          </Button>
        </Tooltip>
      </ModalFooter>
    </Modal>
  );
};

export default SmartpointInitModal;
