/**
 * Calculates first row displayed for the given rows per page and rows count
 */
export const getCurrentFirstRow = (page, rowsPerPage, rows) => rows && (page - 1) * rowsPerPage + 1;

/**
 * Calculates last row displayed for the given rows per page and rows count
 */
export const getCurrentLastRow = (page, rowsPerPage, rows) => {
  const calculatedLastRow = getCurrentFirstRow(page, rowsPerPage, rows) + rowsPerPage - 1;
  return calculatedLastRow < rows ? calculatedLastRow : rows;
};
/**
 * Calculates maximum page number for the given rows per page and rows count
 */
export const getMaxPageNumber = (rowsPerPage, rows) => Math.ceil(rows / rowsPerPage);

/**
 * Check if the page number is valid for the given rows per page and rows count
 */
export const pageNumberIsValid = (page, rowsPerPage, rows, firstPage) =>
  page >= firstPage && page <= getMaxPageNumber(rowsPerPage, rows);
