import React, { useMemo } from 'react';
import CompassIcon from '@kiwicom/orbit-components/lib/icons/Compass';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import QuestionCircleIcon from '@kiwicom/orbit-components/lib/icons/QuestionCircle';

import { ReactComponent as TequilaApiIcon } from 'images/Icons_TequilaApi.svg';
import { ReactComponent as UserGuidesIcon } from 'images/Icons_UserGuides.svg';

import { TAXONOMY_CODENAMES } from 'consts/kentico';
import { ROUTE_CONFIG } from 'consts/routes';

import useTaxonomy from 'components/services/resources/useTaxonomy';
import useResources from 'components/services/resources/useResources';

const isReady = item => !item.loading && !item.error && item.data;

const useTaxonomyWithResources = () => {
  const userGuidesTaxonomy = useTaxonomy(TAXONOMY_CODENAMES.USER_GUIDES);
  const tequilaApiTaxonomy = useTaxonomy(TAXONOMY_CODENAMES.API_REFERENCE);
  const faqTaxonomy = useTaxonomy(TAXONOMY_CODENAMES.GUIDES_CONTENT_TREE);
  const resources = useResources();

  const taxonomyWithResources = useMemo(() => {
    if (
      !isReady(resources) ||
      !isReady(userGuidesTaxonomy) ||
      !isReady(tequilaApiTaxonomy) ||
      !isReady(faqTaxonomy)
    ) {
      return null;
    }

    const findMatchingResource = subTerm =>
      resources.data.find(resource => resource.taxonomyCodename === subTerm.codename);

    const mapTermsWithResources = terms =>
      terms.map(term => ({
        ...term,
        terms: term.terms
          .map(subTerm => ({
            ...subTerm,
            resource: findMatchingResource(subTerm),
          }))
          .filter(subTerm => subTerm.resource),
      }));

    return [
      {
        taxonomyCodename: null,
        toPath: ROUTE_CONFIG.DOCS.path,
        name: 'docs.navigation.menu.overview',
        icon: <CompassIcon size="small" />,
        terms: [],
      },
      {
        taxonomyCodename: TAXONOMY_CODENAMES.USER_GUIDES,
        toPath: ROUTE_CONFIG.DOCS_USER_GUIDES.toPath,
        name: 'docs.navigation.menu.user_guides',
        icon: <UserGuidesIcon />,
        terms: mapTermsWithResources(userGuidesTaxonomy.data.terms),
      },
      {
        taxonomyCodename: TAXONOMY_CODENAMES.API_REFERENCE,
        toPath: ROUTE_CONFIG.DOCS_TEQUILA_API.toPath,
        name: 'docs.navigation.menu.tequila_api',
        icon: <TequilaApiIcon />,
        terms: mapTermsWithResources(tequilaApiTaxonomy.data.terms),
      },
      {
        taxonomyCodename: null,
        toPath: ROUTE_CONFIG.DOCS_NEWS.toPath,
        name: 'docs.navigation.menu.news',
        icon: <InformationCircleIcon size="small" />,
        terms: [],
      },
      {
        taxonomyCodename: TAXONOMY_CODENAMES.GUIDES_CONTENT_TREE,
        toPath: ROUTE_CONFIG.DOCS_FAQ.toPath,
        name: 'docs.navigation.menu.faq',
        icon: <QuestionCircleIcon size="small" />,
        terms: mapTermsWithResources(faqTaxonomy.data.terms),
      },
    ];
  }, [resources, userGuidesTaxonomy, tequilaApiTaxonomy, faqTaxonomy]);

  return taxonomyWithResources;
};

export default useTaxonomyWithResources;
