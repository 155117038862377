import React, { useContext, useState } from 'react';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';
import { BankAccountsState } from 'components/services/bankAccounts';
import useCurrentCompanyBankAccounts from 'components/services/bankAccounts/useCurrentCompanyBankAccounts';
import { usePolyglot } from 'components/services/i18n';

const DeleteAccountModal = ({ onClose, account }) => {
  const [errorAlertMessage, setErrorAlertMessage] = useState(false);
  const { data } = useCurrentCompanyDetails();
  const { deleteCompanyBankAccount } = useContext(BankAccountsState);
  const bankAccounts = useCurrentCompanyBankAccounts();
  const polyglot = usePolyglot();

  const buttonLoading = bankAccounts.deleting;
  const handleSubmit = () => {
    deleteCompanyBankAccount(data.name, account.uuid)
      .then(() => {
        onClose(true);
      })
      .catch(error => {
        setErrorAlertMessage(error.message);
      });
  };

  const closeModal = () => {
    onClose(false);
  };

  const accountNumber = account.accountNumber;

  return (
    <Modal closable onClose={closeModal} size="small">
      <ModalHeader
        title={polyglot.t('company.billing.delete_bank_account.modal.header')}
        description={polyglot.t('company.billing.delete_bank_account.modal.header_description', {
          type: account.iban ? 'IBAN:' : 'Number:',
          value: account.iban || accountNumber,
        })}
      />
      {errorAlertMessage && (
        <ModalSection>
          <Alert
            spaceAfter="large"
            type="critical"
            closable
            icon
            onClose={() => setErrorAlertMessage(false)}
          >
            {errorAlertMessage}
          </Alert>
        </ModalSection>
      )}
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button
            dataTest="delete-bank-account-modal-cancel-button"
            type="criticalSubtle"
            onClick={onClose}
            width="108px"
          >
            {polyglot.t('common.cancel')}
          </Button>
          <Button
            dataTest="delete-bank-account-modal-confirm-button"
            type="critical"
            width="108px"
            onClick={handleSubmit}
            loading={buttonLoading}
          >
            {polyglot.t('common.delete')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteAccountModal;
