import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { usePolyglot } from 'components/services/i18n';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

import SeatingPrice from '../SeatingPrice';
import { IconWrapper } from './index.styled';

const ActionButton = ({ selected, onClick, routeId }) => {
  const polyglot = usePolyglot();
  const { seatings } = useShoppingBasket();
  const segment = seatings[routeId];

  if (
    selected ||
    (segment && segment.option) ||
    (segment && !segment.option && Object.keys(segment).length)
  ) {
    return (
      <Box display="flex" direction="row" justify="start" align="center">
        <SeatingPrice routeId={routeId} />
        {selected && (
          <IconWrapper>
            <ChevronForwardIcon size="small" />
          </IconWrapper>
        )}
      </Box>
    );
  }

  return (
    <Button onClick={onClick} size="small" type="primarySubtle">
      {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.group.button.select')}
    </Button>
  );
};

export default ActionButton;
