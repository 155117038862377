import styled, { css } from 'styled-components';
import { getLineSize } from './utils';

export const MainContainer = styled.div``;

export const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  position: relative;

  ${({ crossDimensionBorder }) =>
    crossDimensionBorder &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -40px;
        width: 100vw;
        height: ${getLineSize}px;
        background-color: ${({ theme }) => theme.orbit.backgroundButtonSecondary};
        z-index: -1;
      }
    `}
`;

export const TabLine = styled.div`
  height: ${getLineSize}px;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.orbit.backgroundButtonPrimary};
  transition: all 200ms ease-in-out;

  ${({ width, left }) => css`
    width: ${width}px;
    left: ${left}px;
  `};
`;

export const ComponentContainer = styled.div``;
