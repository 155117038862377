export const CONTENT_TYPES = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'VI',
    value: 'vi',
  },
  {
    label: 'Non VI',
    value: 'nonvi',
  },
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
