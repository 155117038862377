import { eachDayOfInterval } from 'date-fns';

import { passengerTypes, tripTypesValues } from 'consts/search';

const countPassengers = passengers => {
  let defaultPassengers = {
    [passengerTypes.ADULTS]: 0,
    [passengerTypes.CHILDREN]: 0,
    [passengerTypes.INFANTS]: 0,
  };

  passengers.forEach(passenger => {
    if (passenger.category === 'adult') {
      defaultPassengers = {
        ...defaultPassengers,
        [passengerTypes.ADULTS]: defaultPassengers[passengerTypes.ADULTS] + 1,
      };
    } else if (passenger.category === 'child') {
      defaultPassengers = {
        ...defaultPassengers,
        [passengerTypes.CHILDREN]: defaultPassengers[passengerTypes.CHILDREN] + 1,
      };
    } else if (passenger.category === 'infant') {
      defaultPassengers = {
        ...defaultPassengers,
        [passengerTypes.INFANTS]: defaultPassengers[passengerTypes.INFANTS] + 1,
      };
    }
  });

  return [
    {
      type: passengerTypes.ADULTS,
      amount: defaultPassengers[passengerTypes.ADULTS],
      minAmount: 1,
    },
    {
      type: passengerTypes.CHILDREN,
      amount: defaultPassengers[passengerTypes.CHILDREN],
      minAmount: 0,
    },
    {
      type: passengerTypes.INFANTS,
      amount: defaultPassengers[passengerTypes.INFANTS],
      minAmount: 0,
    },
  ];
};

export const mapDefaultSearchValues = (tripType, itinerary, passengers, affilId) => {
  return {
    affilId,
    tripType,
    passengersAmounts: countPassengers(passengers),
    locationsFrom: [
      {
        label: `${itinerary[0].departure.station.name} - ${itinerary[0].departure.station.code}`,
        type: 'airport',
        value: itinerary[0].departure.station.code,
      },
    ],
    locationsTo: [
      {
        label: `${itinerary[0].arrival.station.name} - ${itinerary[0].arrival.station.code}`,
        type: 'airport',
        value: itinerary[0].arrival.station.code,
      },
    ],
    departureSelectedDates: eachDayOfInterval({
      start: new Date(itinerary[0].departure.time.local),
      end: new Date(itinerary[0].departure.time.local),
    }),
    ...(tripType === tripTypesValues.RETURN && {
      returnSelectedDates: eachDayOfInterval({
        start: new Date(itinerary[1].departure.time.local),
        end: new Date(itinerary[1].departure.time.local),
      }),
    }),
  };
};
