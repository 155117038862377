import React from 'react';
import PropTypes from 'prop-types';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';

import { DropdownInputButtonWrapper, CloseButton } from './DropdownInputButton.styled';

const DropdownInputButton = ({
  content,
  value,
  prefix,
  suffix,
  error,
  closable,
  onClose,
  dataTest,
  disabled,
  label,
  placeholder,
  size,
  inlineLabel,
}) => {
  const inputField = closable ? (
    <DropdownInputButtonWrapper>
      <CloseButton
        type="secondary"
        size="small"
        circled
        iconLeft={<CloseIcon />}
        onClick={onClose}
      />
      <InputField
        prefix={prefix}
        suffix={suffix}
        error={error}
        label={label}
        placeholder={placeholder}
        size={size}
        inlineLabel={inlineLabel}
        value={value}
        readOnly
        dataTest={dataTest}
      />
    </DropdownInputButtonWrapper>
  ) : (
    <InputField
      disabled={disabled}
      label={label}
      size={size}
      placeholder={placeholder}
      inlineLabel={inlineLabel}
      prefix={prefix}
      suffix={suffix}
      error={error}
      value={value}
      dataTest={dataTest}
      readOnly
    />
  );

  return (
    <Popover content={content} onClose={onClose}>
      {inputField}
    </Popover>
  );
};

DropdownInputButton.propTypes = {
  content: PropTypes.node,
  value: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataTest: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DropdownInputButton;
