import React from 'react';
import styled from 'styled-components';

import Button from '@kiwicom/orbit-components/lib/Button';
import Select from '@kiwicom/orbit-components/lib/Select';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { useAuditLogs } from '../services/AuditLogsProvider';

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 6px;
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 8px;
  }
`;

const SELECT_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
];

const Pagination = () => {
  const polyglot = usePolyglot();
  const { pagination, perPage, setPerPage, loadingMore, loadMoreError, loadMoreLogs } =
    useAuditLogs();

  if (!pagination || !pagination.has_next) {
    return null;
  }

  return (
    <Container>
      <SelectWrapper>
        <div>{polyglot.t('audit_logs.pagination.show')}</div>
        <Select
          size="small"
          options={SELECT_OPTIONS}
          value={perPage}
          onChange={e => setPerPage(e.target.value)}
        />
      </SelectWrapper>

      <LoadMoreWrapper>
        {loadMoreError && (
          <Text type="critical">{polyglot.t('audit_logs.pagination.load_more_logs.error')}</Text>
        )}
        <Button size="small" onClick={loadMoreLogs} loading={loadingMore}>
          {polyglot.t('audit_logs.pagination.load_more_logs')}
        </Button>
      </LoadMoreWrapper>
    </Container>
  );
};

export default Pagination;
