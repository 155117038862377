import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import * as R from 'ramda';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Select from '@kiwicom/orbit-components/lib/Select';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Button from '@kiwicom/orbit-components/lib/Button';
import Plus from '@kiwicom/orbit-components/lib/icons/Plus';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import HttpResponse from 'shapes/HttpResponse';
import { OrbitLoader } from 'common';
import { useDeepEffect } from 'hooks';
import { useLocalStorage } from 'components/services/localStorage';
import { RequestsList, RequestDetails, KiwiUserAlert, CSSDIntroAlert } from 'common/Support';
import {
  filterNames,
  filterStatusOptions,
  filterCreatedByOptions,
  filtersInitialValues,
  SERVICE_DESK,
  HAS_CSSD_INTRO_ALERT_BEEN_SHOWN,
} from 'consts/support';

import useCurrentUser from 'components/services/auth/useCurrentUser';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';

const PAGE_SIZE = 20;
const REQUESTS_EXPIRY = 120; // seconds

const MyRequests = ({
  customer,
  requestsResponse,
  fetchRequestCategories,
  clearRequests,
  fetchRequests,
  requestCategoriesResponse,
  typeFilterOptions,
  createRequestPath,
  serviceDesk,
}) => {
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [filterValues, setFilterValues] = useState(filtersInitialValues);
  const [pageIndex, setPageIndex] = useState(0);
  const [cssdIntroAlert, setCssdIntroAlert] = useLocalStorage(HAS_CSSD_INTRO_ALERT_BEEN_SHOWN, {
    isShown: true,
  });
  const [isCSSDAlertIntroShown, changeIsCSSDAlertIntroShown] = useState(false);
  const { isKiwiUser } = useContext(AuthState);
  const user = useCurrentUser();
  const polyglot = usePolyglot();
  const fetchFilteredRequests = index => {
    const params = {
      requestStatus: filterValues[filterNames.STATUS],
      requestOwnership: filterValues[filterNames.CREATED_BY],
      requestTypeId: Number(filterValues[filterNames.TYPE]) || undefined,
      searchTerm: filterValues[filterNames.SEARCH] || undefined,
      start: PAGE_SIZE * index,
      limit: PAGE_SIZE,
    };
    fetchRequests(`jira/${serviceDesk}/request`, params);
  };

  const clearAndFetchRequests = () => {
    clearRequests();
    setPageIndex(0);
    fetchFilteredRequests(0);
  };

  useEffect(() => {
    if (isKiwiUser) {
      return;
    }
    if (requestsResponse.isClear() || requestsResponse.olderThan(REQUESTS_EXPIRY)) {
      clearAndFetchRequests();
    }
    if (requestCategoriesResponse.isClear()) {
      fetchRequestCategories(`jira/${serviceDesk}/requesttype`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeIsCSSDAlertIntroShown(cssdIntroAlert.isShown && serviceDesk === SERVICE_DESK.CSSD);
  }, [cssdIntroAlert, serviceDesk]);

  useDeepEffect(debounce(clearAndFetchRequests, 500), [filterValues]);

  const openModal = requestId => {
    setSelectedRequestId(requestId);
  };

  const closeModal = () => {
    setSelectedRequestId(null);
  };

  const changeFilter = event => {
    const { name, value } = event.target;
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const numberOfRequests = PAGE_SIZE * (pageIndex + 1);

  const loadMore = () => {
    setPageIndex(pageIndex + 1);
    const shouldFetch = R.path(['requests', 'length'], requestsResponse) <= numberOfRequests;
    if (shouldFetch) {
      fetchFilteredRequests(pageIndex + 1);
    }
  };

  const handleCSSDIntroAlertClose = () => {
    changeIsCSSDAlertIntroShown(false);
    setCssdIntroAlert({ isShown: false });
  };

  if (isKiwiUser) {
    return <KiwiUserAlert polyglot={polyglot} />;
  }

  if (customer.isLoading()) {
    return (
      <Stack direction="column" justify="center">
        <OrbitLoader id="support-loader" visible />
      </Stack>
    );
  }

  if (customer.isError() || requestCategoriesResponse.isError()) {
    return (
      <Stack direction="column">
        <Alert type="info" icon={<InformationCircle />}>
          <Text>{polyglot.t('support.page_not_available_error_message')}</Text>
        </Alert>
      </Stack>
    );
  }

  const selectedRequest =
    selectedRequestId &&
    requestsResponse.isValid() &&
    requestsResponse.requests.find(request => request.issueId === selectedRequestId);

  const selectedRequestType =
    selectedRequest &&
    requestCategoriesResponse.requestCategories
      .reduce((requestTypes, category) => requestTypes.concat(category.requestTypes), [])
      .find(type => type.id === selectedRequest.requestTypeId);

  const enableLoadMore =
    requestsResponse.requests &&
    (numberOfRequests < R.path(['requests', 'length'], requestsResponse) ||
      !requestsResponse.isLastPage);

  return (
    <Stack direction="column">
      {selectedRequest && (
        <RequestDetails
          polyglot={polyglot}
          closeModal={closeModal}
          request={selectedRequest}
          requestType={selectedRequestType}
          user={user}
          clearAndFetchRequests={clearAndFetchRequests}
          serviceDesk={serviceDesk}
        />
      )}
      <Stack justify="end" direction="row" align="start" spaceAfter="large">
        <Link to={createRequestPath}>
          <Button type="secondary" size="small" iconLeft={<Plus size="small" />}>
            {polyglot.t('support.newRequest')}
          </Button>
        </Link>
      </Stack>
      {isCSSDAlertIntroShown && (
        <CSSDIntroAlert polyglot={polyglot} handleCSSDIntroAlertClose={handleCSSDIntroAlertClose} />
      )}
      {requestsResponse.isError() && (
        <Alert type="critical" spaceAfter="large" icon>
          {polyglot.t('support.getting_requests_error_message')}
        </Alert>
      )}
      <Stack direction="row">
        <Stack>
          <Select
            label={polyglot.t('support.filtering.status')}
            name={filterNames.STATUS}
            onChange={changeFilter}
            value={filterValues[filterNames.STATUS]}
            options={filterStatusOptions}
          />
        </Stack>
        <Stack>
          <Select
            label={polyglot.t('support.filtering.created_by')}
            name={filterNames.CREATED_BY}
            onChange={changeFilter}
            value={filterValues[filterNames.CREATED_BY]}
            options={filterCreatedByOptions}
          />
        </Stack>
        <Stack>
          <Select
            label={polyglot.t('support.filtering.request_type')}
            name={filterNames.TYPE}
            onChange={changeFilter}
            value={filterValues[filterNames.TYPE]}
            options={typeFilterOptions}
          />
        </Stack>
        <Stack>
          <InputField
            label={polyglot.t('support.filtering.search_term')}
            name={filterNames.SEARCH}
            onChange={changeFilter}
            value={filterValues[filterNames.SEARCH]}
          />
        </Stack>
      </Stack>
      <RequestsList
        polyglot={polyglot}
        requestsResponse={requestsResponse}
        openModal={openModal}
        numberOfRequests={numberOfRequests}
      />
      <Stack justify="between" align="center">
        {enableLoadMore && (
          <Button
            fullWidth
            type="primary"
            spaceAfter="largest"
            loading={requestsResponse.isLoading()}
            onClick={loadMore}
          >
            {polyglot.t('common.load_more')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

MyRequests.propTypes = {
  customer: HttpResponse.isRequired,
  requestsResponse: HttpResponse.isRequired,
  requestCategoriesResponse: HttpResponse,
  typeFilterOptions: PropTypes.array,
  clearRequests: PropTypes.func.isRequired,
  fetchRequests: PropTypes.func.isRequired,
  fetchRequestCategories: PropTypes.func,
  serviceDesk: PropTypes.string,
  createRequestPath: PropTypes.string.isRequired,
};

export default MyRequests;
