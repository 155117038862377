import React from 'react';

import Slider from '@kiwicom/orbit-components/lib/Slider';

import { usePolyglot } from 'components/services/i18n';
import useSearch from 'components/services/search/useSearch';
import { getCurrencySymbol, getPricesFilterDefaultValues } from 'utils/search';

import { Wrapper } from './../index.styled';

const Prices = () => {
  const polyglot = usePolyglot();
  const { tabData, changePricesFilterValues } = useSearch();

  const currencySymbol = getCurrencySymbol(tabData);
  const defaultValues = getPricesFilterDefaultValues(tabData);
  const pricesFilterValues = tabData.pricesFilterValues;
  const sortType = tabData.sortType;

  return (
    <Wrapper width={300}>
      <Slider
        onChange={changePricesFilterValues}
        label={polyglot.t('searchAndBooking.prices.title')}
        valueDescription={polyglot.t('searchAndBooking.prices.description', {
          minPrice: `${currencySymbol}${pricesFilterValues[sortType][0]}`,
          maxPrice: `${currencySymbol}${pricesFilterValues[sortType][1]}`,
        })}
        defaultValue={defaultValues}
        minValue={defaultValues[0]}
        maxValue={defaultValues[1]}
        spaceAfter="medium"
        dataTest="price-slider"
      />
    </Wrapper>
  );
};

export default Prices;
