import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import Paid from '@kiwicom/orbit-components/lib/icons/Paid';
import Plus from '@kiwicom/orbit-components/lib/icons/Plus';

import { Authorization } from 'common/Authorization';

const BillingInformation = ({ polyglot, clickAction }) => (
  <Card>
    <CardSection
      icon={<Paid />}
      title={polyglot.t('add_details.add_billing_information')}
      description={polyglot.t('add_details.add_billing_subtitle')}
    />
    <CardSection>
      <Authorization resource="model.company" action={['update', 'update_assigned', 'update_own']}>
        <Button iconLeft={<Plus />} size="small" onClick={clickAction}>
          {polyglot.t('add_details.add_billing_information')}
        </Button>
      </Authorization>
    </CardSection>
  </Card>
);

export default BillingInformation;
