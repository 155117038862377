import styled from 'styled-components';
import airportRunway from 'images/heroImage.jpeg';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const AuthContainer = styled.div`
  display: flex;

  ${media.tablet`
    height: 100vh;
  `}
`;

export const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url(${airportRunway});
  background-size: cover;
  background-position: 92%;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Logo = styled.div`
  margin: ${({ center, upper }) =>
    !center
      ? `48px 0 32px ${upper ? 112 : 144}px`
      : `${upper ? 12 : 22}px auto ${upper ? 0 : 4}px`};
`;

export const RouteContainer = styled.div`
  padding: 8px;
  margin-left: 32px;
  overflow: scroll;
  flex: 1;
`;

export const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  margin: 48px 0px;
  width: 480px;
  height: calc(100% - 112px);
`;

export const MobileContentWrapper = styled.div`
  margin: 0 auto;
  padding: 16px;
  width: calc(100% - 48px);
  max-width: 480px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
`;

export const DescriptionWrapper = styled.div`
  margin: 32px 0 auto;
  padding: 16px;
  background-color: ${({ theme }) => theme.orbit.paletteProductLight};
  color: ${({ theme }) => theme.orbit.paletteProductDarker};

  ${media.tablet`
    margin: 0 auto;
    width: calc(100% - 48px);
    max-width: 480px;
  `}

  h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 12px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;
