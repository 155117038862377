import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 880px;

  ${({ isNavigationHidden }) =>
    isNavigationHidden &&
    `
    width: 900px;
    margin: 0 auto;
  `}
`;
