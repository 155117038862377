import React, { useMemo } from 'react';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';

import { ErrorMessageComponent } from 'common';
import { baggageErrorMessage } from 'consts/baggage';
import { usePolyglot } from 'components/services/i18n';

import { getPersonalItemPresence } from '../../../services/utils';
import getOptions from '../../../services/getBaggageOptions';
import Option from './Option';

const EmptyOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.orbit.spaceSmall};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  & > svg {
    margin-right: 10px;
  }
`;

const BaggagePicker = ({
  titleSize = 'normal',
  changeBagCombination,
  pickerType,
  passengerBaggage,
  passengerCategory,
  currentCombination,
  baggage,
  passengerId,
  convertPrice,
  error,
}) => {
  const options = useMemo(() => {
    return getOptions({
      baggage,
      passengerCategory,
      pickerType,
      currentCombination,
      passengerId,
    });
  }, [baggage, currentCombination, passengerCategory, passengerId, pickerType]);
  const polyglot = usePolyglot();

  return (
    <Stack spacing="XSmall" spaceAfter="largest" dataTest={`bag-picker-${pickerType}`}>
      <Text weight="bold" uppercase as="p" size={titleSize}>
        {pickerType === 'handBag'
          ? polyglot.t('mmb.booking_details.add_baggage_modal.baggage_picker.cabin')
          : polyglot.t('mmb.booking_details.add_baggage_modal.baggage_picker.checked')}
      </Text>
      {options.length > 0 ? (
        options.map(item => {
          return (
            <Option
              key={item.index}
              dataTest={`bag-picker-option-${item.index}`}
              pickerType={pickerType}
              items={item.items}
              price={item.price}
              isChecked={item.index === passengerBaggage[pickerType]}
              isCurrentCombination={item.index === currentCombination}
              onClick={() => changeBagCombination(pickerType, item.index)}
              isPersonalItemPresent={getPersonalItemPresence({ pickerType, options })}
              isInfant={passengerCategory === 'infant'}
              shouldConvertPrice={convertPrice}
              isError={error}
            />
          );
        })
      ) : (
        <EmptyOptionWrapper data-test="bag-picker-empty-option">
          <CloseIcon size="medium" color="critical" />
          <Text as="p">{baggageErrorMessage[pickerType][passengerCategory]}</Text>
        </EmptyOptionWrapper>
      )}
      {error && <ErrorMessageComponent>{error}</ErrorMessageComponent>}
    </Stack>
  );
};

export default BaggagePicker;
