import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 400px;
  margin-bottom: 24px;
`;

export const MetricsContainer = styled.div`
  width: 566px;
  margin-bottom: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  button:first-of-type {
    margin-right: 32px;
  }

  button:last-of-type {
    margin-left: 8px;
  }
`;
