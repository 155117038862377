import React from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { CardSectionContainer } from './common.styled';

const CardSection = ({ showAlert, alert, children, fit = false }) => {
  return (
    <CardSectionContainer fit={fit}>
      <Stack spacing="small">
        {showAlert && alert}
        {children}
      </Stack>
    </CardSectionContainer>
  );
};

export default CardSection;
