import React from 'react';
import { Illustration, Stack, Text, Button } from '@kiwicom/orbit-components';

import { usePolyglot } from 'components/services/i18n';

const NoResults = ({ onClick }) => {
  const polyglot = usePolyglot();
  return (
    <Stack direction="column" align="center" justify="center">
      <Illustration name="NoResults" size="medium" />
      <Text size="large" weight="bold">
        {polyglot.t('tequila.mmb.credit_activity.table.no_results.title')}
      </Text>
      <Text size="normal" type="secondary">
        {polyglot.t('tequila.mmb.credit_activity.table.no_results.title')}
      </Text>
      <Button type="secondary" onClick={onClick}>
        {polyglot.t('tequila.mmb.credit_activity.table.no_results.button.text')}
      </Button>
    </Stack>
  );
};

export default NoResults;
