import React from 'react';
import * as R from 'ramda';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import { Authorization } from 'common/Authorization';
import useCompanyPCCApps from 'components/services/smartpoint/useCompanyPCCApps';
import { ROUTE_CONFIG } from 'consts/routes';
import { IP_WHITELIST } from 'consts/specialApps';
import { usePolyglot } from 'components/services/i18n';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';

import { TabLinksWrapper, TabLink } from './TabNavigation.styled';

const TabNavigation = () => {
  const location = useLocation();
  const { companyName } = useRouteMatch().params;
  const basePath = `/companies/${companyName}`;
  const polyglot = usePolyglot();

  const companyPCCApps = useCompanyPCCApps();
  const canAccessSmartpoint =
    companyPCCApps && !R.isNil(companyPCCApps.data) && !R.isEmpty(companyPCCApps.data);

  const specialApps = useSpecialApps(companyName);
  const canAccessAllowlist = !!specialApps.apps?.find(
    app => app.definitionId === IP_WHITELIST && !app.deleted,
  );

  return (
    <TabLinksWrapper>
      <Authorization resource="model.company" action={['index']} companyName={companyName}>
        <TabLink to={ROUTE_CONFIG.COMPANIES.path} icon backButton>
          <ChevronBackwardIcon />
        </TabLink>
      </Authorization>
      <TabLink to={basePath} data-test="company-details" active={basePath === location.pathname}>
        {polyglot.t('header.company_details')}
      </TabLink>
      <Authorization
        resource={'model.application'}
        action={['read', 'read_assigned', 'read_own']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/solutions`}
          data-test="company-solutions"
          active={location.pathname.includes(`${basePath}/solutions`)}
        >
          {polyglot.t('header.my_solutions')}
        </TabLink>
      </Authorization>
      <Authorization
        resource={'model.user'}
        action={['read', 'read_assigned', 'read_own']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/users`}
          data-test="company-users"
          active={`${basePath}/users` === location.pathname}
        >
          {polyglot.t('header.company_users')}
        </TabLink>
      </Authorization>
      <Authorization
        resource="model.billing_cycle"
        action={['read', 'read_assigned', 'read_own']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/billing-cycle`}
          data-test="company-billing-cycle"
          active={`${basePath}/billing-cycle` === location.pathname}
        >
          {polyglot.t('header.company_billing_cycle')}
        </TabLink>
      </Authorization>
      <Authorization
        resource="feature.company_log"
        action={['read', 'read_own']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/logs`}
          data-test="company-logs"
          active={`${basePath}/logs` === location.pathname}
        >
          {polyglot.t('header.logs')}
        </TabLink>
      </Authorization>
      <Authorization
        resource="model.audit_log"
        action={['read', 'read_own']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/audit-logs`}
          data-test="company-audit-logs"
          active={`${basePath}/audit-logs` === location.pathname}
        >
          {polyglot.t('header.audit_logs')}
        </TabLink>
      </Authorization>
      {/* <TabLink */}
      {/*   to={`${basePath}/verification`} */}
      {/*   active={`${basePath}/verification` === location.pathname} */}
      {/* > */}
      {/*   Verification */}
      {/* </TabLink> */}
      <Authorization
        resource={'model.company_special_app'}
        action={['read', 'read_assigned']}
        companyName={companyName}
      >
        <TabLink
          to={`${basePath}/configuration`}
          data-test="company-configuration"
          active={`${basePath}/configuration` === location.pathname}
        >
          {polyglot.t('header.configuration')}
        </TabLink>
      </Authorization>
      {canAccessAllowlist && (
        <Authorization
          resource={'model.company_settings'}
          action={['read', 'read_assigned', 'read_own']}
          companyName={companyName}
        >
          <TabLink
            to={`${basePath}/ip-allowlist`}
            data-test="ip-allowlist"
            active={`${basePath}/ip-allowlist` === location.pathname}
          >
            IP Allowlist
          </TabLink>
        </Authorization>
      )}
      {canAccessSmartpoint && (
        <Authorization
          resource="model.company_pcc"
          action={['read', 'read_assigned', 'read_own']}
          companyName={companyName}
        >
          <TabLink
            to={`${basePath}/smartpoint-configuration`}
            data-test="company-smartpoint-configuration"
            active={`${basePath}/smartpoint-configuration` === location.pathname}
          >
            {polyglot.t('header.smartpoint_configuration')}
          </TabLink>
        </Authorization>
      )}
    </TabLinksWrapper>
  );
};

export default TabNavigation;
