import React from 'react';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';

import { usePolyglot } from 'components/services/i18n';
import { capitalize } from 'utils/string';

const ListItemContent = styled.span`
  overflow-wrap: break-word;
`;

const getRowValue = value => {
  if (Array.isArray(value)) {
    return Object.values(value).map((val, i) => <Payload key={i} payload={{ [i]: val }} />);
  }

  if (typeof value === 'object') {
    return <Payload payload={value} />;
  }

  return String(value);
};

const PAYLOAD_IGNORED_FIELDS = ['pdf', 'csv'];

const Payload = ({ payload }) => {
  const polyglot = usePolyglot();
  const payloadRows = Object.keys(payload || {}).map(key => {
    if (PAYLOAD_IGNORED_FIELDS.includes(key)) {
      return {
        label: key.split('_').map(capitalize).join(' '),
        value: polyglot.t('company.audit_logs.too_large'),
      };
    }
    return {
      label: key.split('_').map(capitalize).join(' '),
      value: payload[key],
    };
  });

  return (
    <List>
      {payloadRows.map((row, i) => (
        <ListItem key={row.label + i}>
          <ListItemContent>
            {row.label}: {getRowValue(row.value)}
          </ListItemContent>
        </ListItem>
      ))}
    </List>
  );
};

const DetailsModal = ({ log, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <ModalHeader title={'Details'} />
      <ModalSection>
        {log.description && <Text spaceAfter="normal">{log.description}</Text>}

        <Payload payload={log.payload || log.new_state || log.previous_state} />
      </ModalSection>
    </Modal>
  );
};

export default DetailsModal;
