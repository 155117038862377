import React, { useState } from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { useToggle } from 'utils/hooks';
import { PageContainer, BankAccountModal } from 'common';
import useCurrentCompanyBankAccounts from 'components/services/bankAccounts/useCurrentCompanyBankAccounts';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { usePolyglot } from 'components/services/i18n';

import BankAccount from './BankAccount';
import DeleteAccountModal from './DeleteAccountModal';
import ActionsBar from './ActionsBar';
import ChangeMainAccountModal from './ChangeMainAccountModal';

const MAX_NUMBER_OF_ACCOUNTS_DETAILS = 10;

const Billing = () => {
  const updatedSuccessfully = useToggle();
  const addBankAccountModal = useToggle();
  const changeBankAccountModal = useToggle();
  const [deleteAccountModalInfo, setDeleteAccountModalInfo] = useState(null);
  const [editAccountModalInfo, setEditAccountModalInfo] = useState(null);
  const [changeAccountModalInfo, setChangeAccountModalInfo] = useState(null);
  const { data: bankAccounts, loading } = useCurrentCompanyBankAccounts();
  const polyglot = usePolyglot();
  const companyName = useCurrentCompanyName();

  if (loading) {
    return <Loading type="pageLoader" text="Loading billing details..." />;
  }

  const showDeleteAccountModal = (account, index) => {
    setDeleteAccountModalInfo({ ...account, index });
  };

  const closeDeleteAccountModal = isUpdatedSuccessfully => {
    setDeleteAccountModalInfo(null);
    if (isUpdatedSuccessfully) {
      updatedSuccessfully.setOn();
    }
  };

  const showEditAccountModal = (account, index) => {
    setEditAccountModalInfo({ ...account, index });
  };

  const closeEditAccountModal = isUpdatedSuccessfully => {
    setEditAccountModalInfo(null);
    if (isUpdatedSuccessfully) {
      updatedSuccessfully.setOn();
    }
  };

  const closeAddNewAccountModal = isUpdatedSuccessfully => {
    addBankAccountModal.setOff();
    if (isUpdatedSuccessfully) {
      updatedSuccessfully.setOn();
    }
  };

  const showChangeMainAccountModal = account => {
    changeBankAccountModal.setOn();
    setChangeAccountModalInfo({ ...account, companyName });
  };

  const closeChangeAccountModal = isUpdatedSuccessfully => {
    changeBankAccountModal.setOff();
    if (isUpdatedSuccessfully) {
      updatedSuccessfully.setOn();
    }
  };

  return (
    <>
      <ActionsBar
        openAddBankAccountModal={addBankAccountModal.setOn}
        companyName={companyName}
        showAddBankAccountButton={bankAccounts?.length < MAX_NUMBER_OF_ACCOUNTS_DETAILS}
      />
      {deleteAccountModalInfo && (
        <DeleteAccountModal onClose={closeDeleteAccountModal} account={deleteAccountModalInfo} />
      )}
      {editAccountModalInfo && (
        <BankAccountModal onClose={closeEditAccountModal} editingAccount={editAccountModalInfo} />
      )}
      {changeBankAccountModal.isOn && (
        <ChangeMainAccountModal
          onClose={closeChangeAccountModal}
          account={changeAccountModalInfo}
        />
      )}
      {addBankAccountModal.isOn && <BankAccountModal onClose={closeAddNewAccountModal} />}
      <PageContainer moveDown>
        {updatedSuccessfully.isOn && (
          <Alert
            spaceAfter="large"
            type="success"
            closable
            icon
            onClose={() => updatedSuccessfully.setOff()}
          >
            {polyglot.t('company.billing_updated_success')}
          </Alert>
        )}
        <Stack spacing="XLarge">
          {bankAccounts?.map((account, index) => (
            <BankAccount
              key={index}
              index={index}
              account={account}
              showDeleteAccountModal={showDeleteAccountModal}
              showEditAccountModal={showEditAccountModal}
              showChangeMainAccountModal={showChangeMainAccountModal}
              companyName={companyName}
              deletingDisabled={bankAccounts.length === 1}
            />
          ))}
        </Stack>
      </PageContainer>
    </>
  );
};

export default Billing;
