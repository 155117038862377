import React, { useState, useEffect } from 'react';
import { connect } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import useWidgetsContext from 'components/services/widgets';
import { usePolyglot } from 'components/services/i18n';

import CreateNewSuccessAlert from './components/CreateNewSuccessAlert';
import UpdateSuccessAlert from './components/UpdateSuccessAlert';
import SomethingWentWrongAlert from './components/SomethingWentWrongAlert';
import { FixedWrapper, MenuBarWrapper, StackDiv, AlertWrapper } from './index.styled';
import SaveAsNewWidgetModal from './components/SaveAsNewWidgetModal';
import { callExternalWidgetToGetFormValues } from 'utils/widgets';
import DefaultError from './components/DefaultError';

const ModalsByType = {
  SAVE_AS_NEW_WIDGET: SaveAsNewWidgetModal,
  null: () => <></>,
};

const AlertsByType = {
  CREATE_NEW_SUCCESS: <CreateNewSuccessAlert />,
  UPDATE_SUCCESS: <UpdateSuccessAlert />,
  SOMETHING_WENT_WRONG: <SomethingWentWrongAlert />,
  DEFAULT_ERROR: <DefaultError />,
  null: () => <></>,
};

const MenuHeader = ({
  successCreatingWidgetSolutionAlert,
  widgetId,
  editingWidget,
  companyName,
  values,
  widgetParam,
  resetWidgetParam,
  isLoaded,
}) => {
  const polyglot = usePolyglot();
  const {
    goBack,
    updateWidget,
    state: { widget, updatingWidget, creatingWidget },
  } = useWidgetsContext();
  const [openedModal, openModal] = useState(null);
  const [alerts, setAlerts] = useState([]);

  const canBeSaved = values?.general?.solutionName !== '' && values?.widgetType?.value !== '';

  const closeModal = () => {
    openModal(null);
    resetWidgetParam();
  };

  const pushAlert = TYPE => {
    setAlerts(currentAlerts => [...currentAlerts, AlertsByType[TYPE]]);
  };

  const OpenedModal = ModalsByType[openedModal];
  const isModalOpened = Boolean(openedModal);

  const handleUpdateWidget = () => {
    callExternalWidgetToGetFormValues('updateWidget');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (widgetParam === 'updateWidget') {
      if (canBeSaved) {
        try {
          await updateWidget(values, widgetId);
        } catch (e) {}
      } else {
        pushAlert('DEFAULT_ERROR');
      }
      resetWidgetParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetParam]);

  const handleGoBackClick = () => {
    goBack();
  };

  useEffect(() => {
    if (updatingWidget.success) {
      pushAlert('UPDATE_SUCCESS');
    }
    if (updatingWidget.error && !isModalOpened) {
      pushAlert('SOMETHING_WENT_WRONG');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingWidget]);

  useEffect(() => {
    if (creatingWidget.success) {
      pushAlert('CREATE_NEW_SUCCESS');
    }
  }, [creatingWidget.success]);

  return (
    <>
      <FixedWrapper>
        <MenuBarWrapper>
          <StackDiv>
            <Stack direction="row" align="center" justify="start">
              <Button
                size="small"
                type="primarySubtle"
                iconLeft={<ChevronBackwardIcon />}
                onClick={handleGoBackClick}
              />
              <Text type="secondary" weight="bold">
                {widget.name}
              </Text>
            </Stack>
          </StackDiv>
          <StackDiv>
            <Stack direction="row" align="center" justify="end">
              <Button
                disabled={!isLoaded}
                size="small"
                type="secondary"
                onClick={() => openModal('SAVE_AS_NEW_WIDGET')}
              >
                {polyglot.t('widgets.save_as_new')}
              </Button>
              {editingWidget && (
                <Button
                  disabled={editingWidget && !isLoaded}
                  loading={updatingWidget.inProgress}
                  size="small"
                  type="primary"
                  onClick={handleUpdateWidget}
                >
                  {polyglot.t('common.save')}
                </Button>
              )}
            </Stack>
          </StackDiv>
        </MenuBarWrapper>
      </FixedWrapper>
      <AlertWrapper>
        {alerts}
        {successCreatingWidgetSolutionAlert}
      </AlertWrapper>
      <OpenedModal
        closeMe={closeModal}
        companyName={companyName}
        widgetId={widgetId}
        editingWidget={editingWidget}
        openModal={openModal}
        values={values}
        widgetParam={widgetParam}
        resetWidgetParam={resetWidgetParam}
      />
    </>
  );
};

export default connect(MenuHeader);
