import React from 'react';
import styled from 'styled-components';
import Edit from '@kiwicom/orbit-components/lib/icons/Edit';

import Button from '@kiwicom/orbit-components/lib/Button';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import PassengerIcon from '@kiwicom/orbit-components/lib/icons/Passenger';

import useRoles from 'components/services/products/useRoles';
import { Authorization } from 'common/Authorization';
import { Flex, FakeCell } from 'common';

const PageContainer = styled.div`
  & td {
    overflow: hidden;
    height: 48px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;

  &:first-of-type {
    margin-right: 8px;
  }
`;

const UserList = ({ polyglot, users, openUserModal, onImpersonate }) => {
  const roles = useRoles();

  return (
    <PageContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{polyglot.t('common.email')}</TableCell>
            <TableCell align="left">{polyglot.t('common.first_name')}</TableCell>
            <TableCell align="left">{polyglot.t('common.last_name')}</TableCell>
            <TableCell align="left">{polyglot.t('company.title')}</TableCell>
            <TableCell align="left">{polyglot.t('user.role')}</TableCell>
            <TableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <TableRow key={user?.uuid || user?.developerId}>
              <TableCell align="left">
                <FakeCell>{user?.email}</FakeCell>
              </TableCell>
              <TableCell align="left">
                <FakeCell>{user?.firstName}</FakeCell>
              </TableCell>
              <TableCell align="left">
                <FakeCell>{user?.lastName}</FakeCell>
              </TableCell>
              <TableCell align="left">
                <FakeCell>{user?.companyName}</FakeCell>
              </TableCell>
              <TableCell align="left">
                <FakeCell>{roles?.data?.find(r => r.name === user.role)?.displayName}</FakeCell>
              </TableCell>
              <TableCell>
                <Flex>
                  <Authorization resource="model.user" action={['update']}>
                    <IconContainer onClick={() => openUserModal(user)}>
                      <Edit size="small" />
                    </IconContainer>
                  </Authorization>
                </Flex>
              </TableCell>
              <TableCell>
                <Authorization resource="actions.internal" action={['impersonate']}>
                  <Tooltip content="Impersonate">
                    <Button
                      dataTest={`impersonate-user-${user.email}`}
                      type="criticalSubtle"
                      size="small"
                      title={`Impersonate  ${user.firstName} ${user.lastName}`}
                      iconLeft={<PassengerIcon />}
                      onClick={() => onImpersonate(user)}
                    />
                  </Tooltip>
                </Authorization>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PageContainer>
  );
};

export default UserList;
