import { useContext, useEffect } from 'react';
import { CompaniesState } from './';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';

const useSelectedCompany = () => {
  const {
    state: { selectedCompany },
    selectCompany,
  } = useContext(CompaniesState);
  const usersCompany = useUsersCompanyName();

  useEffect(() => {
    if (!selectedCompany && usersCompany) {
      selectCompany(usersCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersCompany]);

  return {
    selectedCompany: selectedCompany || usersCompany,
    selectCompany,
  };
};

export default useSelectedCompany;
