import React, { Component } from 'react';

import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { Space } from 'common';

import { ContentWrapper } from './TermsAndConditionsModal.styled';

class TermsAndConditionsModal extends Component {
  getModalContent = () => {
    const { content, loading, polyglot } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Space before="m">
          <ModalHeader title={polyglot.t('common.terms_conditions')} />
        </Space>
        <ContentWrapper>
          <ModalSection>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </ModalSection>
        </ContentWrapper>
      </div>
    );
  };

  render() {
    const { onClose, polyglot } = this.props;

    return (
      <Modal onClose={onClose}>
        {this.getModalContent()}
        <ModalFooter>
          <Space right="xxl">
            <Button onClick={onClose} width="112px">
              {polyglot.t('common.ok')}
            </Button>
          </Space>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TermsAndConditionsModal;
