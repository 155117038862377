import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import PlusCircleIcon from '@kiwicom/orbit-components/lib/icons/PlusCircle';

import { Space, LocationsAutocomplete } from 'common';
import { useSearch } from 'components/services/search';
import { SEARCH_LOCATION_TYPES } from 'consts/search';
import { usePolyglot } from 'components/services/i18n';

import CalendarDropdown from '../../CalendarDropdown';
import { NomadSearchbarRow, NomadContainer } from './Nomad.styled';
import NomadTrip from './NomadTrip';

const Nomad = () => {
  const polyglot = usePolyglot();
  const {
    tabData,
    searchFlights,
    changeDepartureSelectedDates,
    changeLocationsFrom,
    changeLocationsTo,
    toggleIsReturnToOriginChecked,
    toggleIsSetReturnDateChecked,
    changeReturnSelectedDates,
    changeNomadTripLocation,
    changeNomadIsNightsInputShown,
    changeNomadTripIsDatesInputShown,
    addNomadTrip,
    removeNomadTrip,
    changeNomadDates,
    changeNomadNumberOfNights,
  } = useSearch();

  const tripType = tabData.tripType;
  const locationsFrom = tabData.searchbar[tripType].locationsFrom;
  const locationsTo = tabData.searchbar[tripType].locationsTo;
  const departureSelectedDates = tabData.searchbar[tripType].departureSelectedDates;
  const returnSelectedDates = tabData.searchbar[tripType].returnSelectedDates;
  const isSetReturnDateChecked = tabData.searchbar[tripType].isSetReturnDateChecked;
  const isReturnToOriginChecked = tabData.searchbar[tripType].isReturnToOriginChecked;
  const nomadTrips = tabData.searchbar[tripType].trips;

  const selectFromLocation = selectedValue => {
    changeLocationsFrom([...locationsFrom, selectedValue]);
  };

  const unselectFromLocation = unselectedValue => {
    changeLocationsFrom(locationsFrom.filter(location => location.value !== unselectedValue.value));
  };

  const selectToLocation = selectedValue => {
    changeLocationsTo([...locationsTo, selectedValue]);
  };

  const unselectToLocation = unselectedValue => {
    changeLocationsTo(locationsTo.filter(location => location.value !== unselectedValue.value));
  };

  const handleSearch = () => {
    searchFlights(tabData);
  };

  const isSearchDisabled = () => {
    function isTripInvalid(trip) {
      const { locations, dates, isNightsInputShown, isDatesInputShown } = trip;
      const noLocations = locations.length === 0;
      const noAndNightsPresent = isDatesInputShown && !isNightsInputShown && dates.length === 0;
      if (noLocations || noAndNightsPresent) {
        return true;
      }
    }
    const noLocations = locationsFrom.length === 0;
    const noDepartureDates = departureSelectedDates.length === 0;
    const noReturnDate = isSetReturnDateChecked && returnSelectedDates.length === 0;
    const noMultipleNomadTrips = nomadTrips.length < 2;
    const invalidTrip = nomadTrips.find(isTripInvalid);

    return Boolean(
      noLocations || noDepartureDates || noReturnDate || noMultipleNomadTrips || invalidTrip,
    );
  };

  const isLoading = () => {
    if (
      tabData.searchStatus.duration === 'loading' ||
      tabData.searchStatus.price === 'loading' ||
      tabData.searchStatus.quality === 'loading'
    ) {
      return true;
    }
    return false;
  };

  return (
    <NomadContainer>
      <NomadSearchbarRow>
        <Space after="m">
          <LocationsAutocomplete
            onSelect={selectFromLocation}
            onUnselect={unselectFromLocation}
            selectedValues={locationsFrom}
            label={<Text type="secondary">{polyglot.t('common.from')}</Text>}
            inlineLabel
            locationTypes={SEARCH_LOCATION_TYPES}
          />
        </Space>
        <Space after="m">
          <CalendarDropdown
            label={<Text type="secondary">{polyglot.t('searchAndBooking.departure')}</Text>}
            inlineLabel
            selectedDates={departureSelectedDates}
            changeSelectedDates={changeDepartureSelectedDates}
            dataTest="departure-nomad"
          />
        </Space>
      </NomadSearchbarRow>
      <NomadSearchbarRow>
        <>
          <Space after="m">
            {!isReturnToOriginChecked && (
              <LocationsAutocomplete
                onSelect={selectToLocation}
                onUnselect={unselectToLocation}
                selectedValues={locationsTo}
                prefix={polyglot.t('common.to')}
                locationTypes={SEARCH_LOCATION_TYPES}
              />
            )}
          </Space>
          <Space after="m">
            {isSetReturnDateChecked && (
              <CalendarDropdown
                prefix={polyglot.t('common.return')}
                selectedDates={returnSelectedDates}
                changeSelectedDates={changeReturnSelectedDates}
              />
            )}
          </Space>
        </>
      </NomadSearchbarRow>
      <NomadSearchbarRow>
        <Space after="l">
          <Checkbox
            checked={isReturnToOriginChecked}
            label={polyglot.t('searchAndBooking.nomad.return_to_origin')}
            onChange={toggleIsReturnToOriginChecked}
          />
        </Space>
        <Space after="l">
          <Checkbox
            checked={isSetReturnDateChecked}
            label={polyglot.t('searchAndBooking.nomad.set_return_date')}
            onChange={toggleIsSetReturnDateChecked}
          />
        </Space>
      </NomadSearchbarRow>
      {nomadTrips.map(trip => (
        <NomadTrip
          key={trip.id}
          changeNomadTripLocation={changeNomadTripLocation}
          changeNomadTripIsDatesInputShown={changeNomadTripIsDatesInputShown}
          changeNomadIsNightsInputShown={changeNomadIsNightsInputShown}
          removeNomadTrip={removeNomadTrip}
          trip={trip}
          isDeleteEnabled={nomadTrips.length > 1}
          changeNomadDates={changeNomadDates}
          changeNomadNumberOfNights={changeNomadNumberOfNights}
        />
      ))}
      <Stack direction="row" spacing="XSmall" spaceAfter="large">
        <Button width="436px" type="secondary" iconLeft={<PlusCircleIcon />} onClick={addNomadTrip}>
          {polyglot.t('searchAndBooking.multicity.add')}
        </Button>
        <Button
          title={polyglot.t('searchAndBooking.search')}
          width="436px"
          onClick={handleSearch}
          disabled={isSearchDisabled()}
          loading={isLoading()}
          dataTest="start-search-button"
        >
          {polyglot.t('searchAndBooking.search')}
        </Button>
      </Stack>
    </NomadContainer>
  );
};

export default Nomad;
