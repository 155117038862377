import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as R from 'ramda';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import {
  keys,
  voucherTypeValues,
  designTypeValues,
  voucherFormInitialValues,
  VouchersSchema,
} from 'consts/smartPass';
import { SmartPassContext } from 'components/services/smartPass';

import { LabelsRowWrapper, HalfWidthWrapper } from '../index.styled';
import StandardDesign from './StandardDesign';
import BothDesign from './BothDesign';
import { getPreviewType } from '../Modals/VoucherPreviewModal/utils/adapters.js';

const { VOUCHER_NAME, VOUCHER_TYPE, VOUCHER_DESIGN_TYPE } = keys;

const AddOrEditVoucher = ({
  handleCancel,
  handleSave,
  pushVoucher,
  companyName,
  editingVoucher,
  smartPassFormValues,
}) => {
  const polyglot = usePolyglot();

  const onSubmit = voucherFormValues => {
    handleSave(pushVoucher, voucherFormValues);
  };

  const { showVoucherPreviewModal } = useContext(SmartPassContext);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={VouchersSchema}
      initialValues={editingVoucher || voucherFormInitialValues}
      validateOnMount
    >
      {({ handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched }) => {
        return (
          <div>
            <HalfWidthWrapper>
              <InputField
                spaceAfter="largest"
                label={polyglot.t('smart_pass.form.vouchers.voucher_name')}
                help={polyglot.t('smart_pass.form.vouchers.voucher_name_help')}
                name={VOUCHER_NAME}
                value={values[VOUCHER_NAME]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[VOUCHER_NAME] && errors[VOUCHER_NAME] && polyglot.t(errors[VOUCHER_NAME])
                }
              />
            </HalfWidthWrapper>
            <Separator spaceAfter="large" />
            <Stack spaceAfter="large">
              <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.voucher_type')}</Text>
              <LabelsRowWrapper>
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.shop')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.SHOP}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.SHOP}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.restaurant')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.RESTAURANT}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.RESTAURANT}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.hotel')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.HOTEL}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.HOTEL}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.bag_deposit')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.BAG_DEPOSIT}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.BAG_DEPOSIT}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.lounge_access')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.LOUNGE_ACCESS}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.LOUNGE_ACCESS}
                  onChange={handleChange}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
                <Radio
                  label={polyglot.t('smart_pass.form.vouchers.voucher_type.other')}
                  name={VOUCHER_TYPE}
                  value={voucherTypeValues.OTHER}
                  checked={values[VOUCHER_TYPE] === voucherTypeValues.OTHER}
                  onChange={(...args) => {
                    handleChange(...args);
                    setFieldValue(VOUCHER_DESIGN_TYPE, designTypeValues.CUSTOM);
                  }}
                  hasError={touched[VOUCHER_TYPE] && errors[VOUCHER_TYPE]}
                />
              </LabelsRowWrapper>
            </Stack>
            <Separator spaceAfter="large" />
            <Stack spaceAfter="large">
              <Text spaceAfter="small">{polyglot.t('smart_pass.form.design_type')}</Text>
              <LabelsRowWrapper>
                <Radio
                  label={polyglot.t('smart_pass.form.standard')}
                  name={VOUCHER_DESIGN_TYPE}
                  value={designTypeValues.STANDARD}
                  checked={values[VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values[VOUCHER_TYPE] === voucherTypeValues.OTHER}
                  hasError={touched[VOUCHER_DESIGN_TYPE] && errors[VOUCHER_DESIGN_TYPE]}
                />
                <Radio
                  label={
                    <Stack direction="row" align="center" spacing="XXSmall">
                      <Text>{polyglot.t('smart_pass.form.own')}</Text>
                      <Tooltip
                        content={polyglot.t('smart_pass.form.vouchers.design_type_own_tooltip')}
                      >
                        <InformationCircleIcon size="small" />
                      </Tooltip>
                    </Stack>
                  }
                  name={VOUCHER_DESIGN_TYPE}
                  value={designTypeValues.CUSTOM}
                  checked={values[VOUCHER_DESIGN_TYPE] === designTypeValues.CUSTOM}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched[VOUCHER_DESIGN_TYPE] && errors[VOUCHER_DESIGN_TYPE]}
                />
              </LabelsRowWrapper>
            </Stack>
            {values[VOUCHER_DESIGN_TYPE] === designTypeValues.STANDARD &&
              values[VOUCHER_TYPE] !== voucherTypeValues.OTHER && (
                <StandardDesign
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                />
              )}

            <BothDesign
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              setFieldValue={setFieldValue}
              companyName={companyName}
              touched={touched}
              errors={errors}
            />

            <Separator spaceAfter="medium" />
            <Stack direction="row">
              <Button type="secondary" onClick={handleCancel}>
                {polyglot.t('common.cancel')}
              </Button>
              <Stack inline direction="row" justify="end">
                <Button
                  type="primarySubtle"
                  disabled={!R.isEmpty(errors)}
                  onClick={() =>
                    showVoucherPreviewModal({
                      voucherInfo: values,
                      smartPassInfo: smartPassFormValues,
                      previewType: getPreviewType(values),
                    })
                  }
                >
                  {polyglot.t('smart_pass.form.vouchers.voucher_type.voucher_preview')}
                </Button>
                <Button onClick={handleSubmit}>{polyglot.t('common.save')}</Button>
              </Stack>
            </Stack>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddOrEditVoucher;
