import React from 'react';
import styled from 'styled-components';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';

const Wrapper = styled.div`
  margin: 30px auto 0;
  text-align: center;
`;

const NoResults = ({ message }) => {
  return (
    <Wrapper>
      <Text spaceAfter="largest" size="large" align="center">
        {message}
      </Text>
      <Illustration name="Feedback" />
    </Wrapper>
  );
};

export default NoResults;
