import * as R from 'ramda';

import React, { useReducer } from 'react';
import api, { getUrlWithQuery, proxy } from 'utils/api';

import baggageMapper from 'mappers/nextGen/partners/bookings/{bid}/baggage/_';
import bookingMapper from 'mappers/nextGen/mmb/bookings/_';
import checkinMapper from 'mappers/nextGen/mmb/bookings/{bid}/checkin/_';
import { getBackendUrl } from 'utils/features';
import invoiceMapper from 'mappers/nextGen/mmb/bookings/{bid}/invoices/_';
import mmbBookingMapper from 'mappers/nextGen/mmb/bookings/{bid}/_';
import passengerMapper from 'mappers/nextGen/partners/bookings/{bid}/passengers/_';
import priceChangeMapper from 'mappers/nextGen/partners/bookings/{bid}/price_changes/_';
import AncillariesMapper from 'mappers/nextGen/mmb/bookings/{bid}/ancillaries/offers/check/_';

const BATCH_SIZE = 5;

export const MMBState = React.createContext({
  state: {
    bookingsState: {},
    refunds: {},
  },
  getTokenAndUrl: async () => [],
  loadMMBBookings: async selectedDates => [selectedDates],
  loadMoreBookings: async selectedDates => [selectedDates],
  addBookingIframe: bid => [bid],
  removeBookingIframe: bid => [bid],
  searchByBid: bid => [bid],
  downloadExcel: (locale, what, filters) => [locale, what, filters],
  getBookingDisruptions: async bids => [bids],
  getPriceChangeInfo: async bid => [bid],
  payPriceChange: async (bid, id) => [bid, id],
  getBaggageOfferInfo: async bid => [bid],
  changeBaggageOption: (bid, type, combination, passengerId) => [
    bid,
    type,
    combination,
    passengerId,
  ],
  payBaggage: async (bid, passengerId, baggage, callBack) => [bid, passengerId, baggage, callBack],
  getInvoices: async bid => [bid],
  loadBoardingPasses: async bid => [bid],
  cancelBooking: async bid => [bid],
  checkReservations: async bid => [],
  toggleBid: bid => [bid],
  toggleAllBids: bids => [bids],
  refundByBid: bid => bid,
  checkAncillariesOffers: (bid, currency, sessionId) => [bid, currency, sessionId],
  resetAncillariesOffers: () => {},
});

const MMBReducer = (state, action) => {
  switch (action.type) {
    case 'GET_TOKEN_AND_URL':
      return {
        ...state,
        loadingToken: true,
        error: null,
      };
    case 'GET_TOKEN_AND_URL_SUCCESS':
      return {
        ...state,
        loadingToken: false,
        mmbUrl: action.mmbUrl,
        mmbToken: action.mmbToken,
      };
    case 'GET_TOKEN_AND_URL_FAIL':
      return {
        ...state,
        loadingToken: false,
        error: action.error,
      };
    case 'LOAD_BOOKINGS':
      return {
        ...state,
        loadingBookings: true,
        error: null,
      };
    case 'LOAD_BOOKINGS_SUCCESS':
      return {
        ...state,
        loadingBookings: false,
        bookings: action.bookings,
        hasMoreBookings: action.nextPage,
        refunds: {},
      };
    case 'LOAD_BOOKINGS_FAIL':
      return {
        ...state,
        loadingBookings: false,
        error: action.error,
      };
    case 'LOAD_MORE_BOOKINGS':
      return {
        ...state,
        loadingMoreBookings: true,
        error: null,
      };
    case 'LOAD_MORE_BOOKINGS_SUCCESS':
      return {
        ...state,
        loadingMoreBookings: false,
        bookings: state.bookings.concat(action.bookings),
        hasMoreBookings: action.nextPage,
      };
    case 'LOAD_MORE_BOOKINGS_FAIL':
      return {
        ...state,
        loadingMoreBookings: false,
        error: action.error,
      };
    case 'ADD_BOOKING_IFRAME':
      return {
        ...state,
        iframes: state.iframes.includes(action.bid)
          ? state.iframes
          : [...state.iframes, action.bid],
      };
    case 'REMOVE_BOOKING_IFRAME':
      return {
        ...state,
        iframes: state.iframes.filter(iframe => iframe !== action.bid),
      };
    case 'SEARCH_BY_BID':
      return {
        ...state,
        loadingBid: true,
      };
    case 'SEARCH_BY_BID_SUCCESS':
      return {
        ...state,
        loadingBid: false,
      };
    case 'SEARCH_BY_BID_FAIL':
      return {
        ...state,
        loadingBid: false,
        bidSearchError: action.error,
      };
    case 'GET_PARTNER_TOKEN':
      return {
        ...state,
        loadingBid: true,
      };
    case 'GET_PARTNER_TOKEN_SUCCESS':
      return {
        ...state,
        loadingBid: false,
        tokens: {
          ...state.tokens,
          [action.affilId]: {
            apiKey: action.apiKey,
            partnerToken: action.partnerToken,
          },
          [action.bid]: {
            apiKey: action.apiKey,
            partnerToken: action.partnerToken,
          },
        },
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            bookingData: action.booking,
            affilid: action.affilId,
          },
        },
      };
    case 'GET_PARTNER_TOKEN_FAIL':
      return {
        ...state,
        loadingBid: false,
        tokens: {
          ...state.tokens,
          [action.affilId]: {
            error: action.error,
          },
          [action.bid]: {
            error: action.error,
          },
        },
      };
    case 'FETCH_REFUND':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            refund: undefined,
            loadingRefund: true,
            refundError: null,
          },
        },
      };
    case 'FETCH_REFUND_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            refund: action.data,
            requestingRefund: false,
            loadingRefund: false,
          },
        },
      };
    case 'FETCH_REFUND_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            refund: action.data,
            refundError: action.error,
          },
        },
      };
    case 'REFUND_REQUEST_LOADING':
      return {
        ...state,
        refunds: {
          ...state.refunds,
          [action.bid]: {
            refund: null,
            requestingRefund: true,
            requestRefundError: null,
          },
        },
      };
    case 'REFUND_REQUEST_LOADING_SUCCESS':
      return {
        ...state,
        refunds: {
          ...state.refunds,
          [action.bid]: {
            refund: action.data,
            requestingRefund: false,
            requestRefundError: null,
          },
        },
      };
    case 'REFUND_REQUEST_LOADING_FAIL':
      return {
        ...state,
        refunds: {
          ...state.refunds,
          [action.bid]: {
            refund: null,
            requestingRefund: false,
            requestRefundError: action.error,
          },
        },
      };
    case 'REQUEST_REFUND':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            requestingRefund: true,
            requestRefundError: null,
          },
        },
      };
    case 'REQUEST_REFUND_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            requestingRefund: false,
            requestRefundError: action.error,
          },
        },
      };
    case 'GET_PASSENGER_INFO':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPassengers: true,
            loadingPassengersError: null,
          },
        },
      };
    case 'GET_PASSENGER_INFO_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPassengers: false,
            passengers: action.data,
          },
        },
      };
    case 'GET_PASSENGER_INFO_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPassengers: false,
            loadingPassengersError: action.error,
          },
        },
      };
    case 'UPDATE_PASSENGER':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            updatingPassenger: true,
            updatePassengerError: null,
          },
        },
      };
    case 'UPDATE_PASSENGER_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            updatingPassenger: false,
            passengers: state.bookingsState[action.bid].passengers.map(p =>
              p.id === action.data.id
                ? {
                    ...p,
                    documentNumber: action.data.documentNumber || null,
                    documentExpiry: action.data.documentExpiry || null,
                  }
                : p,
            ),
          },
        },
      };
    case 'UPDATE_PASSENGER_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            updatingPassenger: false,
            updatePassengerError: action.error,
          },
        },
      };
    case 'GET_DISRUPTIONS':
      return {
        ...state,
        loadingDisruptions: true,
      };
    case 'GET_DISRUPTIONS_SUCCESS':
      return {
        ...state,
        disruptions: {
          ...state.disruptions,
          ...action.data,
        },
        loadingDisruptions: false,
      };
    case 'GET_DISRUPTIONS_FAIL':
      return {
        ...state,
        loadingDisruptions: false,
        disruptionError: action.error,
      };
    case 'GET_PRICE_CHANGE_INFO':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPriceChange: true,
            loadingPriceChangeError: null,
          },
        },
      };
    case 'GET_PRICE_CHANGE_INFO_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPriceChange: false,
            priceChange: action.data,
          },
        },
      };
    case 'GET_PRICE_CHANGE_INFO_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingPriceChange: false,
            loadingPriceChangeError: action.error,
          },
        },
      };
    case 'PAY_PRICE_CHANGE':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingPriceChange: true,
            payingPriceChangeError: null,
          },
        },
      };
    case 'PAY_PRICE_CHANGE_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingPriceChange: false,
            priceChange: { ...state.bookingsState[action.bid].priceChange, paid: true },
          },
        },
      };
    case 'PAY_PRICE_CHANGE_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingPriceChange: false,
            payingPriceChangeError: action.error,
          },
        },
      };
    case 'GET_BAGGAGE_OFFER_INFO':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBaggageOffer: true,
            loadingBaggageOfferError: null,
          },
        },
      };
    case 'GET_BAGGAGE_OFFER_INFO_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBaggageOffer: false,
            baggageOffer: action.data,
          },
        },
      };
    case 'GET_BAGGAGE_OFFER_INFO_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBaggageOffer: false,
            loadingBaggageOfferError: action.error,
          },
        },
      };
    case 'CHANGE_SELECTED_BAGGAGE':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            baggageOffer: {
              ...state.bookingsState[action.bid].baggageOffer,
              passengerSelectedCombinations: {
                ...state.bookingsState[action.bid].baggageOffer.passengerSelectedCombinations,
                [action.baggageType]: {
                  [action.passengerId]: action.combination,
                },
              },
            },
          },
        },
      };
    case 'PAY_BAGGAGE':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingBaggage: true,
            payingBaggageError: null,
          },
        },
      };
    case 'PAY_BAGGAGE_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingBaggage: false,
            bookingData: action.booking,
          },
        },
      };
    case 'PAY_BAGGAGE_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            payingBaggage: false,
            payingBaggageError: action.error,
          },
        },
      };

    case 'GET_INVOICES':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingInvoices: true,
            loadingInvoicesError: null,
          },
        },
      };
    case 'GET_INVOICES_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingInvoices: false,
            invoices: action.data,
          },
        },
      };
    case 'GET_INVOICES_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingInvoices: false,
            loadingInvoicesError: action.error,
          },
        },
      };
    case 'LOAD_BOARDING_PASSES':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBoardingPasses: true,
            loadingBoardingPassesError: null,
          },
        },
      };
    case 'LOAD_BOARDING_PASSES_SUCCESS':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBoardingPasses: false,
            boardingPasses: action.data,
          },
        },
      };
    case 'LOAD_BOARDING_PASSES_FAIL':
      return {
        ...state,
        bookingsState: {
          ...state.bookingsState,
          [action.bid]: {
            ...(state.bookingsState[action.bid] || {}),
            loadingBoardingPasses: false,
            loadingBoardingPassesError: action.error,
          },
        },
      };
    case 'CANCEL_BOOKING':
      return {
        ...state,
        cancelBookingLoading: true,
      };
    case 'CANCEL_BOOKING_SUCCESS':
      return {
        ...state,
        cancelBookingLoading: false,
      };
    case 'CANCEL_BOOKING_FAIL':
      return {
        ...state,
        cancelBookingLoading: false,
        cancelBookingError: action.error,
      };
    case 'CHECK_RESERVATIONS':
      return {
        ...state,
        checkReservationsLoading: true,
      };
    case 'CHECK_RESERVATIONS_SUCCESS':
      return {
        ...state,
        checkReservationsLoading: false,
        reservationState: {
          ...state.reservationState,
          [action.bid]: action.reserved,
        },
      };
    case 'CHECK_RESERVATIONS_FAIL':
      return {
        ...state,
        checkReservationsLoading: false,
        error: action.error,
      };
    case 'TOGGLE_BID':
      return {
        ...state,
        checkboxIds: action.bids,
      };
    case 'TOGGLE_ALL_BIDS':
      return {
        ...state,
        checkboxIds: action.bids,
      };
    case 'SET_REMAINING_BIDS':
      return {
        ...state,
        remainingBids: action.bids,
      };
    case 'CHECK_ANCILLARIES_OFFERS':
      return {
        ...state,
        ancillariesOffers: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'CHECK_ANCILLARIES_OFFERS_SUCCESS':
      return {
        ...state,
        ancillariesOffers: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case 'CHECK_ANCILLARIES_OFFERS_FAIL':
      return {
        ...state,
        ancillariesOffers: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'RESET_CHECK_ANCILLARIES_OFFERS':
      return {
        ...state,
        ancillariesOffers: initialState.ancillariesOffers,
      };
    default:
      return state;
  }
};

// get bid from url path
const bidParam = parseInt(window.location.pathname.split('manage-my-bookings/')[1]);

const hasValidBidInPath = !isNaN(parseInt(bidParam));

const initialState = {
  iframes: hasValidBidInPath ? [bidParam] : [],
  tokens: {},
  bookingsState: {},
  checkboxIds: [],
  refunds: {},
  ancillariesOffers: {
    loading: false,
    data: null,
    error: null,
  },
};

const MMBProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MMBReducer, initialState);

  const getTokenAndUrl = async () => {
    dispatch({ type: 'GET_TOKEN_AND_URL' });

    try {
      const [nextGenAccessResponse, nextGenConfigResponse] = await Promise.all([
        api.v1.post('mmb/token/create'),
        api.v1.get('config'),
      ]);
      dispatch({
        type: 'GET_TOKEN_AND_URL_SUCCESS',
        mmbToken: nextGenAccessResponse.data.access_token,
        mmbUrl: nextGenConfigResponse.data.mmb_url,
      });
    } catch (error) {
      dispatch({ type: 'GET_TOKEN_AND_URL_FAIL', error });
    }
  };

  const loadMMBBookings = async selectedDates => {
    dispatch({ type: 'LOAD_BOOKINGS' });

    try {
      const query = getUrlWithQuery('', {
        extended_fields: 'display_status',
        limit: 40,
        ...bookingMapper.v1.get.to(selectedDates),
      });

      const res = await api.v1.get(`mmb/bookings${query}`);
      const bookings = res.data.bookings.map(bookingMapper.v1.get.from).filter(item => !!item);

      dispatch({ type: 'LOAD_BOOKINGS_SUCCESS', bookings, nextPage: bookings.length === 40 });
    } catch (error) {
      dispatch({ type: 'LOAD_BOOKINGS_FAIL', error });
    }
  };

  const loadMoreBookings = async selectedDates => {
    dispatch({ type: 'LOAD_MORE_BOOKINGS' });
    try {
      const query = getUrlWithQuery('', {
        extended_fields: 'display_status',
        limit: 40,
        offset: state.bookings.length,
        ...bookingMapper.v1.get.to(selectedDates),
      });

      const res = await api.v1.get(`mmb/bookings${query}`);
      const bookings = res.data.bookings.map(bookingMapper.v1.get.from).filter(item => !!item);
      dispatch({ type: 'LOAD_MORE_BOOKINGS_SUCCESS', bookings, nextPage: bookings.length === 40 });
    } catch (error) {
      dispatch({ type: 'LOAD_MORE_BOOKINGS_FAIL', error });
    }
  };

  const addBookingIframe = bid => {
    dispatch({ type: 'ADD_BOOKING_IFRAME', bid });
  };

  const removeBookingIframe = bid => {
    dispatch({ type: 'REMOVE_BOOKING_IFRAME', bid });
  };

  const searchByBid = async bid => {
    dispatch({ type: 'SEARCH_BY_BID' });
    try {
      const res = await api.v1.get(`mmb/bookings/${bid}`);
      if (res.data) {
        await getBookingDisruptions([bid]);
        addBookingIframe(parseInt(bid, 10));
      }
      dispatch({ type: 'SEARCH_BY_BID_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'SEARCH_BY_BID_FAIL', error });
      return Promise.reject(error);
    }
  };

  //Required implementation on BBE side for v1
  const downloadExcel = (locale, what, filters) => {
    let url;
    switch (what) {
      case 'bookings':
        url = `${getBackendUrl()}/mmb/bookings`;
        break;
      case 'transactions':
        url = `${getBackendUrl()}/mmb/transactions`;
        break;
      default:
        break;
    }

    if (!url) return;

    window.location.href = getUrlWithQuery(url, {
      ...bookingMapper.v1.get.to(filters),
      token: state.mmbToken,
      locale,
      format: 'xlsx',
    });
  };

  const getHeaders = bid => {
    return {
      apikey: state.tokens[bid].apiKey,
      'KW-Auth-Token': state.tokens[bid].partnerToken,
    };
  };

  const getPartnerToken = async bid => {
    try {
      dispatch({ type: 'GET_PARTNER_TOKEN', bid });

      const { data } = await api.v1.get(
        `mmb/bookings/${bid}?extended_fields=baggage,display_status,ancillaries`,
      );

      const appResponse = await api.v1.get(`applications/${data.booking.affiliate_id}`);

      const partnerResponse = await proxy.direct.post('partners/create_auth_token', undefined, {
        headers: {
          apiKey: appResponse.data.api_key,
        },
      });

      dispatch({
        type: 'GET_PARTNER_TOKEN_SUCCESS',
        affilId: data.booking.affiliate_id,
        apiKey: appResponse.data.api_key,
        partnerToken: partnerResponse.data.authorization_token,
        bid,
        booking: mmbBookingMapper.v1.get.from(data),
      });
    } catch (error) {
      dispatch({ type: 'GET_PARTNER_TOKEN_FAIL', bid, error });
    }
  };

  const getRefundState = async bid => {
    dispatch({ type: 'FETCH_REFUND', bid });

    try {
      const response = await proxy.direct.get('partners/refunds', {
        params: {
          booking_ids: bid,
        },
        headers: getHeaders(bid),
      });
      dispatch({ type: 'FETCH_REFUND_SUCCESS', bid, data: response.data[0] });
    } catch (error) {
      dispatch({ type: 'FETCH_REFUND_FAIL', bid, error });
    }
  };

  const requestRefund = async bid => {
    dispatch({ type: 'REQUEST_REFUND', bid });

    try {
      const response = await proxy.direct.post(
        'partners/refunds',
        [
          {
            booking_id: +bid,
          },
        ],
        {
          headers: getHeaders(bid),
        },
      );

      dispatch({ type: 'FETCH_REFUND_SUCCESS', bid, data: response.data[0] });
    } catch (error) {
      dispatch({ type: 'REQUEST_REFUND_FAIL', bid, error });

      throw error;
    }
  };

  const refundByBid = async bid => {
    dispatch({ type: 'REFUND_REQUEST_LOADING', bid });

    try {
      const { data } = await api.v1.get(
        `mmb/bookings/${bid}?extended_fields=baggage,display_status,ancillaries`,
      ); // for getting affilId from certain booking
      const appResponse = await api.v1.get(`applications/${data.booking.affiliate_id}`); // with affilid getting apikey

      const partnerResponse = await proxy.direct.post('partners/create_auth_token', undefined, {
        headers: {
          apiKey: appResponse.data.api_key,
        },
      }); // getting token

      const response = await proxy.direct.post(
        'partners/refunds',
        [
          {
            booking_id: +bid,
          },
        ],
        {
          headers: {
            apikey: appResponse.data.api_key,
            'KW-Auth-Token': partnerResponse.data.authorization_token,
          },
        },
      );

      dispatch({ type: 'REFUND_REQUEST_LOADING_SUCCESS', bid, data: response.data[0] });
    } catch (error) {
      dispatch({ type: 'REFUND_REQUEST_LOADING_FAIL', bid, error });
      throw error;
    }
  };

  const getPassengersInfo = async bid => {
    dispatch({ type: 'GET_PASSENGER_INFO', bid });

    try {
      const response = await proxy.direct.get(`partners/bookings/${bid}/passengers`, {
        headers: getHeaders(bid),
      });

      dispatch({
        type: 'GET_PASSENGER_INFO_SUCCESS',
        bid,
        data: passengerMapper.direct.get.from(response.data.passengers),
      });
    } catch (error) {
      dispatch({ type: 'GET_PASSENGER_INFO_FAIL', bid, error });
    }
  };

  const updatePassenger = async (bid, data) => {
    dispatch({ type: 'UPDATE_PASSENGER', bid });

    try {
      await proxy.direct.patch(
        `partners/bookings/${bid}/passengers`,
        passengerMapper.direct.patch.to(data),
        {
          headers: getHeaders(bid),
        },
      );

      dispatch({ type: 'UPDATE_PASSENGER_SUCCESS', bid, data });
    } catch (error) {
      dispatch({ type: 'UPDATE_PASSENGER_FAIL', bid, error });

      return Promise.reject(error);
    }
  };

  const getBookingDisruptions = async (bids = []) => {
    dispatch({ type: 'GET_DISRUPTIONS' });

    const requests = R.splitEvery(BATCH_SIZE, bids).map(batch => {
      const stringifiedBids = batch.join(',');
      return api.v1.get(`/mmb/booking-disruptions?booking_id=${stringifiedBids}`);
    });

    try {
      const res = await Promise.all(requests);

      const data = res.reduce((acc, r) => (acc = { ...acc, ...r.data }), {});

      dispatch({ type: 'GET_DISRUPTIONS_SUCCESS', data });
    } catch (error) {
      dispatch({ type: 'GET_DISRUPTIONS_FAIL', error });
      return Promise.reject(error);
    }
  };

  const getPriceChangeInfo = async bid => {
    dispatch({ type: 'GET_PRICE_CHANGE_INFO', bid });

    try {
      const response = await proxy.direct.get(`partners/bookings/${bid}/price_changes`, {
        headers: getHeaders(bid),
      });

      dispatch({
        type: 'GET_PRICE_CHANGE_INFO_SUCCESS',
        bid,
        data: priceChangeMapper.direct.get.from(response.data),
      });
    } catch (error) {
      dispatch({ type: 'GET_PRICE_CHANGE_INFO_FAIL', bid, error });
    }
  };

  const payPriceChange = async (bid, id) => {
    dispatch({ type: 'PAY_PRICE_CHANGE', bid });

    try {
      await proxy.direct.post(
        `partners/bookings/${bid}/price_changes/${id}/credit_payments`,
        undefined,
        {
          headers: getHeaders(bid),
        },
      );

      dispatch({
        type: 'PAY_PRICE_CHANGE_SUCCESS',
        bid,
        id,
      });
    } catch (error) {
      dispatch({ type: 'PAY_PRICE_CHANGE_FAIL', bid, error });
    }
  };

  const getBaggageOfferInfo = async bid => {
    dispatch({ type: 'GET_BAGGAGE_OFFER_INFO', bid });

    try {
      const response = await proxy.direct.get(`partners/bookings/${bid}/baggage`, {
        headers: getHeaders(bid),
      });

      dispatch({
        type: 'GET_BAGGAGE_OFFER_INFO_SUCCESS',
        bid,
        data: baggageMapper.direct.get.from(response.data),
      });
    } catch (error) {
      dispatch({ type: 'GET_BAGGAGE_OFFER_INFO_FAIL', bid, error });
    }
  };

  const changeBaggageOption = (bid, type, combination, passengerId) => {
    if (!bid || !type || !passengerId) {
      return;
    }

    dispatch({
      type: 'CHANGE_SELECTED_BAGGAGE',
      bid,
      baggageType: type,
      combination,
      passengerId,
    });
  };

  const payBaggage = async (bid, passengerId, baggage, callBack) => {
    dispatch({ type: 'PAY_BAGGAGE', bid });
    try {
      const newBaggage = baggageMapper.direct.post.to(passengerId, baggage);

      await proxy.direct.post(`partners/bookings/${bid}/baggage`, newBaggage, {
        headers: getHeaders(bid),
      });

      callBack();

      const { data } = await api.v1.get(
        `mmb/bookings/${bid}?extended_fields=baggage,display_status,ancillaries`,
      );

      await getBaggageOfferInfo(bid);
      dispatch({
        type: 'PAY_BAGGAGE_SUCCESS',
        bid,
        booking: mmbBookingMapper.v1.get.from(data),
      });
    } catch (error) {
      dispatch({ type: 'PAY_BAGGAGE_FAIL', bid, error: error.response.data || error });
    }
  };

  const getInvoices = async bid => {
    dispatch({ type: 'GET_INVOICES', bid });

    try {
      const response = await api.v1.get(`/mmb/bookings/${bid}/invoices`);

      dispatch({
        type: 'GET_INVOICES_SUCCESS',
        bid,
        data: invoiceMapper.v1.get.from(response.data),
      });
    } catch (error) {
      dispatch({ type: 'GET_INVOICES_FAIL', bid, error });
    }
  };

  const loadBoardingPasses = async (bid, segments, passengers, type) => {
    dispatch({ type: 'LOAD_BOARDING_PASSES', bid });

    try {
      const response = await api.v1.get(`/mmb/bookings/${bid}/checkin`);
      dispatch({
        type: 'LOAD_BOARDING_PASSES_SUCCESS',
        bid,
        data: checkinMapper.v1.get.from(response.data, segments, passengers, type),
      });
    } catch (error) {
      dispatch({ type: 'LOAD_BOARDING_PASSES_FAIL', bid, error });
    }
  };

  const cancelBooking = async bid => {
    try {
      dispatch({
        type: 'CANCEL_BOOKING',
        bid,
      });

      await api.v1.delete(`/mmb/bookings/${bid}`);

      dispatch({ type: 'CANCEL_BOOKING_SUCCESS', bid });
    } catch (error) {
      dispatch({
        type: 'CANCEL_BOOKING_FAIL',
        bid,
        error,
      });
    }
  };

  const checkReservations = async bid => {
    try {
      dispatch({
        type: 'CHECK_RESERVATIONS',
        bid,
      });

      const { data } = await api.v1.get(`/mmb/bookings/${bid}/reservations`);

      const reserved = data.reservations
        ?.map(
          res => !!res.reservation_number || !!res.eticket_number || !!res.provider_reservation_id,
        )
        .reduce((acc, curr) => acc || curr);

      dispatch({
        type: 'CHECK_RESERVATIONS_SUCCESS',
        bid,
        reserved,
      });
    } catch (err) {
      dispatch({
        type: 'CHECK_RESERVATIONS_FAIL',
        bid,
      });
    }
  };
  const toggleBid = bid => {
    let checkboxIds = state.checkboxIds;

    if (checkboxIds.includes(bid)) {
      checkboxIds = checkboxIds.filter(checkboxId => checkboxId !== bid);
    } else {
      checkboxIds = [...checkboxIds, bid];
    }

    dispatch({
      type: 'TOGGLE_BID',
      bids: checkboxIds,
    });

    dispatch({
      type: 'SET_REMAINING_BIDS',
      bids: checkboxIds,
    });
  };

  const toggleAllBids = (tmpState, bids) => {
    let checkboxIds = state.checkboxIds;

    if (tmpState === 0) {
      checkboxIds = [];
    } else if (tmpState === 2) {
      checkboxIds = bids;
    }

    dispatch({
      type: 'TOGGLE_ALL_BIDS',
      bids: checkboxIds,
    });

    dispatch({
      type: 'SET_REMAINING_BIDS',
      bids: checkboxIds,
    });
  };

  const checkAncillariesOffers = async (bid, currency, sessionId) => {
    dispatch({ type: 'CHECK_ANCILLARIES_OFFERS' });
    try {
      const { data } = await proxy.direct.post(
        `/kiwi-mmb/mmb/v1/bookings/${bid}/ancillaries/offers/check`,
        AncillariesMapper.v1.post.to(currency, sessionId),
        {
          headers: {
            'kw-user-token': state.mmbToken,
          },
        },
      );

      const payload = AncillariesMapper.v1.post.from(data.seating);
      dispatch({ type: 'CHECK_ANCILLARIES_OFFERS_SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'CHECK_ANCILLARIES_OFFERS_FAIL', error });
    }
  };

  const resetAncillariesOffers = () => {
    dispatch({ type: 'RESET_CHECK_ANCILLARIES_OFFERS' });
  };

  const value = {
    state,
    getTokenAndUrl,
    loadMMBBookings,
    loadMoreBookings,
    addBookingIframe,
    removeBookingIframe,
    downloadExcel,
    searchByBid,
    getPartnerToken,
    getRefundState,
    requestRefund,
    getPassengersInfo,
    updatePassenger,
    getBookingDisruptions,
    getPriceChangeInfo,
    payPriceChange,
    getBaggageOfferInfo,
    changeBaggageOption,
    payBaggage,
    getInvoices,
    loadBoardingPasses,
    cancelBooking,
    checkReservations,
    toggleBid,
    toggleAllBids,
    refundByBid,
    checkAncillariesOffers,
    resetAncillariesOffers,
  };

  return <MMBState.Provider value={value}>{children}</MMBState.Provider>;
};

export default MMBProvider;
