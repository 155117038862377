import React, { useState } from 'react';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';

import { SelectCompany } from 'common';
import { usePolyglot } from 'components/services/i18n';

const getErrorMessage = (error, companyName, polyglot) => {
  if (error?.status === 409) {
    return polyglot.t('billing.exclusions.error.already_exists', { companyName });
  }
  return polyglot.t('billing.exclusions.error.generic');
};

const SelectCompanyModal = ({ onClose, onSuccess, loading, error }) => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const polyglot = usePolyglot();

  return (
    <Modal size="small">
      <ModalHeader
        title={polyglot.t('billing.exclusions.new_exclusion')}
        description={polyglot.t('billing.exclusions.modal.description')}
      />
      <ModalSection>
        {error && (
          <Alert type="critical" spaceAfter="large">
            {getErrorMessage(error.response, selectedCompany, polyglot)}
          </Alert>
        )}
        <SelectCompany onSelect={setSelectedCompany} fullWidth />
      </ModalSection>
      <ModalFooter>
        <Button type="secondary" onClick={onClose} disabled={loading}>
          {polyglot.t('common.cancel')}
        </Button>
        <Button type="primary" onClick={() => onSuccess(selectedCompany)} loading={loading}>
          {polyglot.t('billing.exclusions.exclude')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectCompanyModal;
