import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import ItemCompleted from '@kiwicom/orbit-components/lib/icons/ItemCompleted';
import Plus from '@kiwicom/orbit-components/lib/icons/Plus';

import { ROUTE_CONFIG } from 'consts/routes';

import { Authorization } from 'common/Authorization';

const AddSolution = ({ polyglot, companyName }) => (
  <Card>
    <CardSection
      icon={<ItemCompleted />}
      title={polyglot.t('add_details.set_up_solution')}
      description={polyglot.t('add_details.set_up_solution_subtitle')}
    />
    <Authorization
      resource="model.application"
      action={['create', 'create_assigned', 'create_own']}
    >
      <CardSection>
        <Link
          to={{
            pathname: `${ROUTE_CONFIG.COMPANIES.path}/${companyName}/solutions`,
            state: { createSolution: true },
          }}
        >
          <Button iconLeft={<Plus />} size="small">
            {polyglot.t('company.add_solution')}
          </Button>
        </Link>
      </CardSection>
    </Authorization>
  </Card>
);

export default AddSolution;
