export const PASSENGER_OPTIONS = [
  {
    value: 'adult',
    label: 'booking.deposit_booking.adult',
  },
  {
    value: 'child',
    label: 'booking.deposit_booking.child',
  },
  {
    value: 'infant',
    label: 'booking.deposit_booking.infant',
  },
];

export const GENDER_OPTIONS = [
  {
    value: 'male',
    label: 'booking.deposit_booking.male',
  },
  {
    value: 'female',
    label: 'booking.deposit_booking.female',
  },
];

export const INITIAL_PASSENGER = {
  adult: {
    category: PASSENGER_OPTIONS[0].value,
    firstName: '',
    lastName: '',
    nationality: '',
    gender: '',
    dateOfBirth: '',
    passportId: '',
    expiryDate: '',
    noExpiry: false,
    baggage: {},
  },
  child: {
    category: PASSENGER_OPTIONS[1].value,
    firstName: '',
    lastName: '',
    nationality: '',
    gender: '',
    dateOfBirth: '',
    passportId: '',
    expiryDate: '',
    noExpiry: false,
    baggage: {},
  },
  infant: {
    category: PASSENGER_OPTIONS[2].value,
    firstName: '',
    lastName: '',
    nationality: '',
    gender: '',
    dateOfBirth: '',
    passportId: '',
    expiryDate: '',
    noExpiry: false,
    baggage: {},
  },
};

export const MMB_SEATING_TABLE_BUTTON_TYPE = {
  ADD: 'add',
  ADD_MORE: 'add_more',
  ADD_AGAIN: 'add_again',
};

export const BOOKING_FLOW = {
  EVENT_TYPE: {
    PRICE_CHANGE: 'price_change',
    SCHEDULE_CHANGE: 'schedule_change',
    FARE_LOCK_UNAVAILABLE: 'fare_lock_unavailable',
    FARE_LOCK_PRICE_INCREASE: 'fare_lock_price_change',
  },
};
