import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Label, Wrapper } from 'common';
import { InputButtonContainer, StyledInput } from './InputButton.styled';
import Prefix from './Prefix';

const noop = () => {};
// InputButton component should be used when you something that looks like a input,
// however it should behave like button

class InputButton extends PureComponent {
  render() {
    const {
      id,
      label,
      size,
      placeholder,
      prefix,
      value,
      onClick,
      icon,
      className,
      align,
      disabled,
      classNameWrapper,
      classNameContainer,
      ref,
    } = this.props;

    return (
      <div className={className} ref={ref}>
        {label && <Label htmlFor={id} text={label} dontTranslate />}
        <InputButtonContainer onClick={disabled ? noop : onClick} className={classNameContainer}>
          <Wrapper size={size} className={classNameWrapper} disabled={disabled}>
            {prefix && <Prefix>{prefix}</Prefix>}
            <StyledInput
              id={id}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              readOnly
              align={align}
            />
            {icon}
          </Wrapper>
        </InputButtonContainer>
      </div>
    );
  }
}

InputButton.defaultProps = {
  size: 'medium',
  disabled: false,
};

InputButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.element,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameContainer: PropTypes.string,
};

export default InputButton;
