import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { usePolyglot } from 'components/services/i18n';

const DisableWhitelistModal = ({ loading, onClose, onDisable }) => {
  const polyglot = usePolyglot();

  return (
    <Modal onClose={onClose} size="small">
      <ModalHeader
        title={polyglot.t('allowlist.disable_title')}
        description={polyglot.t('allowlist.disable_description')}
      />
      <ModalFooter>
        <Button type="criticalSubtle" size="large" onClick={onClose}>
          {polyglot.t('common.cancel')}
        </Button>
        <Button type="critical" size="large" onClick={onDisable} loading={loading}>
          {polyglot.t('solutions.disable')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DisableWhitelistModal;
