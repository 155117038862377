import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 150px;

  & > a {
    height: 32px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 200px;
  align-self: stretch;
  justify-content: space-between;
  padding: 16px;
  border-top: ${({ theme, border }) => border && `1px solid ${theme.orbit.paletteCloudNormal}`};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  & > div,
  svg {
    margin-right: 5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 15px;

  & > span {
    margin-left: 5px;
  }
`;
