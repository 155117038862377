import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import formatISO from 'date-fns/formatISO';
import startOfYesterday from 'date-fns/startOfYesterday';
import endOfYesterday from 'date-fns/endOfYesterday';

export const mapReportAlias = (reportAlias, reportType, aliasType) => {
  return {
    alias: reportAlias.reportName,
    ...(aliasType && { aliasType }),
    reportConfig: {
      dateRange: {
        from: reportAlias?.dateRange?.from
          ? formatISO(startOfDay(reportAlias.dateRange.from))
          : formatISO(startOfYesterday(new Date())),
        to: reportAlias?.dateRange?.to
          ? formatISO(endOfDay(reportAlias.dateRange.to))
          : formatISO(endOfYesterday(new Date())),
      },
      metrics: reportAlias.reportMetrics,
      interval: 'day',
      filters: {
        ...(reportAlias.solutions && { solutions: reportAlias.solutions }),
        ...(reportAlias.companies && { companies: reportAlias.companies }),
        ...(reportAlias.filters &&
          reportAlias.filters.reduce((acc, filterItem) => {
            acc[filterItem.type] = filterItem.values;
            return acc;
          }, {})),
      },
    },
    ...(reportType && { reportType }),
  };
};

const mapSchedule = (schedule, frequency) => {
  return {
    ...schedule,
    frequency,
  };
};

export const mapSchedules = reportAlias => {
  return [
    ...(reportAlias.dailySchedule.isOn ? [mapSchedule(reportAlias.dailySchedule, 'daily')] : []),
    ...(reportAlias.weeklySchedule.isOn ? [mapSchedule(reportAlias.weeklySchedule, 'weekly')] : []),
    ...(reportAlias.monthlySchedule.isOn
      ? [mapSchedule(reportAlias.monthlySchedule, 'monthly')]
      : []),
  ];
};
