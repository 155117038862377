import React, { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import CheckCircle from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import CloseCircle from '@kiwicom/orbit-components/lib/icons/CloseCircle';
import Alert from '@kiwicom/orbit-components/lib/icons/Alert';
import AlertCircle from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import City from '@kiwicom/orbit-components/lib/icons/City';
import Edit from '@kiwicom/orbit-components/lib/icons/Edit';
import Select from '@kiwicom/orbit-components/lib/Select';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { ROUTE_CONFIG } from 'consts/routes';
import { OrbitLoader, Flex, Pagination } from 'common';
import { formatInterval } from 'utils/dates';
import { FULL_DATE } from 'consts/dates';
import { PAYMENT_STATUSES_I18N, AUTOMATCHED } from 'consts/partnerPayments';

import usePartnerPayments from 'components/services/partnerPayments/usePartnerPayments';
import { PartnerPaymentsState } from 'components/services/partnerPayments/';
import { usePolyglot } from 'components/services/i18n';

const StatusSelectContainer = styled.div`
  width: 234px;
`;

const PartnerPaymentsList = ({ history }) => {
  const [statusValue, setStatusValue] = useState('');
  const partnerPayments = usePartnerPayments({ page: 1, perPage: 20 });
  const ctx = useContext(PartnerPaymentsState);
  const polyglot = usePolyglot();

  const handleChangePage = (rowsPerPage, nextPage) => {
    const pagination = { page: nextPage, perPage: rowsPerPage };
    ctx.loadPartnerPayments(pagination);
  };

  const handleStatus = event => {
    const status = event.target.value;
    setStatusValue(status);
    ctx.loadPartnerPayments({ page: 1, perPage: 20, status });
  };
  const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

  const renderStatusIcon = status => {
    if (status === 'imported') {
      return (
        <Tooltip content={status} preferredPosition="top">
          <Alert size="small" color="warning" />
        </Tooltip>
      );
    } else if (status === 'processed') {
      return (
        <Tooltip content={status} preferredPosition="top">
          <CheckCircle size="small" color="success" />
        </Tooltip>
      );
    } else if (status === 'approved') {
      return (
        <Tooltip content={status} preferredPosition="top">
          <City size="small" color="info" />
        </Tooltip>
      );
    } else if (status === AUTOMATCHED) {
      return (
        <Tooltip content={status} preferredPosition="top">
          <AlertCircle size="small" color="info" />
        </Tooltip>
      );
    } else if (status === 'ignored') {
      return (
        <Tooltip content={status} preferredPosition="top">
          <CloseCircle size="small" color="critical" />
        </Tooltip>
      );
    }
  };

  const renderPartnerPaymentsTable = useCallback(() => {
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" />
              <TableCell align="left">{polyglot.t('partner_payments.date')}</TableCell>
              <TableCell align="left">{polyglot.t('partner_payments.amount')}</TableCell>
              <TableCell align="left">{polyglot.t('partner_payments.iban')}</TableCell>
              <TableCell align="left">{polyglot.t('partner_payments.sender')}</TableCell>
              <TableCell align="left">{polyglot.t('partner_payments.company')}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerPayments.data.map(payment => (
              <TableRow key={payment.id}>
                <TableCell align="left">{renderStatusIcon(payment.status)}</TableCell>
                <TableCell align="left">{formatInterval(payment.date, FULL_DATE)}</TableCell>
                <TableCell align="left">{`${payment.currency} ${payment.amount}`}</TableCell>
                <TableCell align="left">{payment.debtorAccount}</TableCell>
                <TableCell align="left">{payment.debtorName}</TableCell>
                <TableCell align="left">{capitalize(payment.company)}</TableCell>
                <TableCell align="left">
                  <ButtonLink
                    type="secondary"
                    size="small"
                    title="Payment"
                    onClick={() =>
                      history.push(`${ROUTE_CONFIG.PARTNER_PAYMENTS.path}/${payment.id}`)
                    }
                    iconLeft={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          rows={partnerPayments.pagination.totalCount}
          rowsPerPage={partnerPayments.pagination.perPage}
          page={partnerPayments.pagination.page}
          rowsPerPageItems={[5, 10, 15, 20]}
          onPagination={handleChangePage}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerPayments.data]);

  return (
    <>
      <StatusSelectContainer>
        <Select
          size="small"
          value={statusValue}
          disabled={partnerPayments.loading}
          onChange={handleStatus}
          options={PAYMENT_STATUSES_I18N.map(status => ({
            label: polyglot.t(status.label),
            value: status.value,
          }))}
        />
      </StatusSelectContainer>

      {partnerPayments.loading ? (
        <Flex main="center">
          <OrbitLoader id="solution-loader" visible />
        </Flex>
      ) : partnerPayments.data.length ? (
        renderPartnerPaymentsTable()
      ) : (
        <div>
          <Illustration name="Offline" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('partner_payments.no_data')}
          </Text>
        </div>
      )}
    </>
  );
};

export default withRouter(PartnerPaymentsList);
