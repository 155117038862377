import format from 'date-fns/format';
import * as R from 'ramda';

import { tripTypesValues } from 'consts/search';
import {
  parseLocations,
  getParsedPassengersAmounts,
  getIncludedOrExcludedCarriersList,
} from 'utils/search';
import { DAY_MONTH_YEAR_FORMAT } from 'consts/dates';

const v2_post_to = (tabData, sortType) => {
  const {
    departureSelectedDates,
    returnSelectedDates,
    locationsFrom,
    isSetReturnDateChecked,
    isReturnToOriginChecked,
    locationsTo,
    trips,
  } = tabData.searchbar[tripTypesValues.NOMAD];
  const date_from = format(new Date(R.head(departureSelectedDates)), DAY_MONTH_YEAR_FORMAT);
  const date_to = format(new Date(R.last(departureSelectedDates)), DAY_MONTH_YEAR_FORMAT);
  const fly_from = parseLocations(locationsFrom);
  const fly_to = isReturnToOriginChecked ? fly_from : parseLocations(locationsTo);
  const return_from =
    isSetReturnDateChecked && R.head(returnSelectedDates)
      ? format(new Date(R.head(returnSelectedDates)), DAY_MONTH_YEAR_FORMAT)
      : undefined;
  const return_to =
    isSetReturnDateChecked && R.last(returnSelectedDates)
      ? format(new Date(R.last(returnSelectedDates)), DAY_MONTH_YEAR_FORMAT)
      : undefined;

  const [select_airlines, select_airlines_exclude] = getIncludedOrExcludedCarriersList(
    tabData.allCarriersList,
    tabData.filteredCarriers,
  );
  const parsedPassengersAmounts = getParsedPassengersAmounts(tabData.passengersAmounts);

  return {
    body: {
      via: trips.map(trip => ({
        locations: trip.locations.map(location => location.value),
        nights_range: trip.isNightsInputShown ? trip.nights : undefined,
        date_range:
          trip.isDatesInputShown && R.head(trip.dates) && R.last(trip.dates)
            ? [
                format(new Date(R.head(trip.dates)), DAY_MONTH_YEAR_FORMAT),
                format(new Date(R.last(trip.dates)), DAY_MONTH_YEAR_FORMAT),
              ]
            : undefined,
      })),
    },
    query: {
      adults: parsedPassengersAmounts.adults,
      children: parsedPassengersAmounts.children,
      infants: parsedPassengersAmounts.infants,
      date_from,
      date_to,
      fly_from,
      fly_to,
      return_from,
      return_to,
      selected_cabins: tabData.selectedCabinClassType,
      vehicle_type: tabData.vehicleTypes.join(','),
      max_sector_stopovers: tabData.numberOfStopovers,
      curr: tabData.currency,
      select_airlines,
      select_airlines_exclude,
      sort: sortType,
    },
  };
};

export default {
  v2: {
    post: {
      to: v2_post_to,
    },
  },
};
