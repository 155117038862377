import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { SelectSolution } from 'common';
import { Authorization } from 'common/Authorization';
import { usePolyglot } from 'components/services/i18n';
import { SMARTPOINT_PRODUCTS } from 'consts/products';

import { SAVE_BUTTON_STATUS } from '../consts';

const PCCConfiguration = ({
  code,
  saveButtonStatus,
  solutions,
  handleSaveClick,
  handleSolutionChange,
}) => {
  const polyglot = usePolyglot();
  return (
    <Card
      spaceAfter="large"
      header={
        <Stack direction="row" justify="between">
          <Heading element="h2" type="title2" spaceAfter="medium">
            {code}
          </Heading>
          <Authorization
            resource="model.company_pcc"
            action={['update', 'update_own', 'update_assigned']}
          >
            {saveButtonStatus !== SAVE_BUTTON_STATUS.HIDDEN && (
              <Button
                size="small"
                loading={saveButtonStatus === SAVE_BUTTON_STATUS.LOADING}
                onClick={handleSaveClick(code)}
              >
                {polyglot.t('common.save')}
              </Button>
            )}
          </Authorization>
        </Stack>
      }
    >
      <CardSection>
        {solutions.map(app => {
          const { affilId, productType } = app;
          return (
            <SelectSolution
              key={productType}
              renderSelectOnly
              label={polyglot.t(`smartpoint_configuration.select_label`)}
              onChange={handleSolutionChange(code, productType)}
              enablesTypes={SMARTPOINT_PRODUCTS}
              preselectedAffilidValue={affilId}
            />
          );
        })}
      </CardSection>
    </Card>
  );
};

export default PCCConfiguration;
