const v1_post_to = ({ start_date, end_date }) => {
  return {
    start_date,
    end_date,
  };
};

export default {
  v1: {
    post: {
      to: v1_post_to,
    },
  },
};
