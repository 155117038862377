import React from 'react';

import FlightNomad from '@kiwicom/orbit-components/lib/icons/FlightNomad';
import SeatWindow from '@kiwicom/orbit-components/lib/icons/SeatWindow';
import SeatAisle from '@kiwicom/orbit-components/lib/icons/SeatAisle';
import SeatExtraLegroom from '@kiwicom/orbit-components/lib/icons/SeatExtraLegroom';
import UserGroup from '@kiwicom/orbit-components/lib/icons/UserGroup';

import { QUICK_OPTION_TYPES } from 'consts/seating';

const SeatingIcon = ({ option }) => {
  if (option === QUICK_OPTION_TYPES.WINDOW) {
    return <SeatWindow />;
  } else if (option === QUICK_OPTION_TYPES.AISLE) {
    return <SeatAisle />;
  } else if (option === QUICK_OPTION_TYPES.EXTRA_LEG_ROOM) {
    return <SeatExtraLegroom />;
  } else if (option === QUICK_OPTION_TYPES.SIT_TOGETHER) {
    return <UserGroup />;
  }

  return <FlightNomad />;
};

export default SeatingIcon;
