import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StepperStateless from '@kiwicom/orbit-components/lib/Stepper/StepperStateless';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

const TextWrapper = styled.div`
  width: 50px;
`;

const minNightsValue = 1;
const maxNightsValue = 60;

const NightsPopover = ({ nights, changeNightsStart, changeNightsEnd }) => {
  const polyglot = usePolyglot();

  const decrementNightsStart = () => {
    if (nights[0] - 1 >= minNightsValue) {
      changeNightsStart(nights[0] - 1);
    }
  };

  const incrementNightsStart = () => {
    if (nights[0] + 1 <= maxNightsValue) {
      changeNightsStart(nights[0] + 1);
    }
  };

  const decrementNightsEnd = () => {
    if (nights[1] - 1 >= minNightsValue) {
      changeNightsEnd(nights[1] - 1);
    }
  };

  const incrementNightsEnd = () => {
    if (nights[1] + 1 <= maxNightsValue) {
      changeNightsEnd(nights[1] + 1);
    }
  };

  return (
    <>
      <Stack align="center" spaceAfter="normal">
        <TextWrapper>
          <Text>{polyglot.t('searchAndBooking.min')}:</Text>
        </TextWrapper>
        <StepperStateless
          onIncrement={incrementNightsStart}
          onDecrement={decrementNightsStart}
          value={nights[0]}
          minValue={1}
          maxValue={60}
        />
      </Stack>
      <Stack align="center">
        <TextWrapper>
          <Text>{polyglot.t('searchAndBooking.max')}:</Text>
        </TextWrapper>
        <StepperStateless
          onIncrement={incrementNightsEnd}
          onDecrement={decrementNightsEnd}
          value={nights[1]}
          minValue={1}
          maxValue={60}
        />
      </Stack>
    </>
  );
};

NightsPopover.propTypes = {
  nights: PropTypes.array.isRequired,
};

export default NightsPopover;
