import React from 'react';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';
import { usePolyglot } from 'components/services/i18n';

import { COMPANY_TYPES } from 'consts/company';

const Container = styled.div`
  max-width: 343px;
`;

const AlertWrapper = styled.div`
  margin-bottom: 32px !important;
`;

const AccountType = () => {
  const { control } = useFormContext();
  const polyglot = usePolyglot();

  return (
    <Container>
      <Text type="secondary" spaceAfter="large">
        {polyglot.t('common.account_type')}
      </Text>

      <Stack spacing="medium">
        <Controller
          control={control}
          name="accountType"
          render={({ onChange, value }) => {
            return (
              <>
                <Radio
                  label={polyglot.t('company.legal_entity')}
                  info={polyglot.t('register.company_desc')}
                  value={COMPANY_TYPES.COMPANY}
                  checked={value === COMPANY_TYPES.COMPANY}
                  onChange={() => onChange(COMPANY_TYPES.COMPANY)}
                />

                {value === COMPANY_TYPES.COMPANY && (
                  <AlertWrapper>
                    <Alert>
                      <Stack direction="column">
                        <Text weight="bold">{polyglot.t('company.legal_entity_info_title')}</Text>
                        <List>
                          <ListItem>{polyglot.t('company.legal_entity_info_1')}</ListItem>
                          <ListItem>{polyglot.t('company.legal_entity_info_2')}</ListItem>
                          <ListItem>{polyglot.t('company.legal_entity_info_3')}</ListItem>
                        </List>
                      </Stack>
                    </Alert>
                  </AlertWrapper>
                )}

                <Radio
                  label={polyglot.t('register.type_person')}
                  info={polyglot.t('register.person_desc')}
                  value={COMPANY_TYPES.PERSON}
                  checked={value === COMPANY_TYPES.PERSON}
                  onChange={() => onChange(COMPANY_TYPES.PERSON)}
                />

                {value === COMPANY_TYPES.PERSON && (
                  <AlertWrapper>
                    <Alert>
                      <Stack direction="column">
                        <Text weight="bold">
                          {polyglot.t('company.personal_entity_info_title')}
                        </Text>
                        <List>
                          <ListItem>{polyglot.t('company.personal_entity_info_1')}</ListItem>
                          <ListItem>{polyglot.t('company.personal_entity_info_2')}</ListItem>
                          <ListItem>{polyglot.t('company.personal_entity_info_3')}</ListItem>
                        </List>
                      </Stack>
                    </Alert>
                  </AlertWrapper>
                )}
              </>
            );
          }}
        />
      </Stack>
    </Container>
  );
};

export default AccountType;
