import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes, { number } from 'prop-types';

import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Select from '@kiwicom/orbit-components/lib/Select';
import Text from '@kiwicom/orbit-components/lib/Text';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { getPolyglot } from 'redux/selectors/i18n';
import Polyglot from 'shapes/Polyglot';
import {
  getMaxPageNumber,
  getCurrentFirstRow,
  getCurrentLastRow,
  pageNumberIsValid,
} from 'common/Pagination/utils';
import { Space, Flex } from 'common';
import {
  PaginationContainer,
  PaginationButtonsContainer,
  PageItem,
  SelectContainer,
} from './Pagination.styled';

export const ALL_ROWS_VALUE = 'allRows';
const NUMBER_OF_SURROUNDING_PAGES_DISPLAYED = 4; //should be even
const FIRST_PAGE = 1;
const DEFAULT_ROWS_PER_PAGE_ITEMS = [10, 20];

/**
 * Displays a controlled pagination component:
 * - controls for moving between pages, back and forth
 * - controls for going to the first or to the last page
 * - controls for changing the number of rows displayed per page
 * - view all button which displays the whole rows
 * - info how many total rows are there
 *
 * Component expects parent component to handle changes.
 */
class Pagination extends React.Component {
  handlePagination = (rowsPerPage = this.props.rowsPerPage, page = this.props.page) => {
    const { onPagination, rowsPerPage: previousRowsPerPage, rows } = this.props;
    if (rowsPerPage !== previousRowsPerPage) {
      page = FIRST_PAGE;
    }
    if (rowsPerPage === ALL_ROWS_VALUE) {
      onPagination(rows, FIRST_PAGE);
    } else {
      onPagination(rowsPerPage, page);
    }
  };

  getPreviousPages = () => {
    const { rowsPerPage, page, rows } = this.props;
    return R.takeWhile(
      surroundingPage => pageNumberIsValid(surroundingPage, rowsPerPage, rows, FIRST_PAGE),
      R.range(page - NUMBER_OF_SURROUNDING_PAGES_DISPLAYED, page).reverse(),
    ).reverse();
  };

  getNextPages = () => {
    const { rowsPerPage, page, rows } = this.props;
    return R.takeWhile(
      surroundingPage => pageNumberIsValid(surroundingPage, rowsPerPage, rows, FIRST_PAGE),
      R.range(page + 1, page + NUMBER_OF_SURROUNDING_PAGES_DISPLAYED + 1),
    );
  };

  getPreviousSurroundingPages = (previousPages, nextPages) => {
    const { rowsPerPage } = this.props;
    const numberOfPreviousSurroundingPages =
      NUMBER_OF_SURROUNDING_PAGES_DISPLAYED -
      (nextPages.length > NUMBER_OF_SURROUNDING_PAGES_DISPLAYED / 2
        ? NUMBER_OF_SURROUNDING_PAGES_DISPLAYED / 2
        : nextPages.length);
    return R.takeLast(numberOfPreviousSurroundingPages, previousPages).map(surroundingPage => (
      <ButtonLink
        key={surroundingPage}
        title={`Page ${surroundingPage}`}
        type="secondary"
        size="small"
        onClick={() => this.handlePagination(rowsPerPage, surroundingPage)}
        iconLeft={<PageItem>{surroundingPage}</PageItem>}
      />
    ));
  };

  getNextSurroundingPages = (previousPages, nextPages) => {
    const { rowsPerPage } = this.props;
    const numberOfNextsSurroundingPages =
      NUMBER_OF_SURROUNDING_PAGES_DISPLAYED -
      (previousPages.length > NUMBER_OF_SURROUNDING_PAGES_DISPLAYED / 2
        ? NUMBER_OF_SURROUNDING_PAGES_DISPLAYED / 2
        : previousPages.length);
    return R.take(numberOfNextsSurroundingPages, nextPages).map(surroundingPage => (
      <ButtonLink
        key={surroundingPage}
        title={`Page ${surroundingPage}`}
        type="secondary"
        size="small"
        onClick={() => this.handlePagination(rowsPerPage, surroundingPage)}
        iconLeft={<PageItem>{surroundingPage}</PageItem>}
      />
    ));
  };

  render() {
    const { rows, rowsPerPage, rowsPerPageItems, page, polyglot, withoutAll = false } = this.props;
    const rowsPerPageListItems = rowsPerPageItems || DEFAULT_ROWS_PER_PAGE_ITEMS;
    const maxPageNumber = getMaxPageNumber(rowsPerPage, rows);
    const perPageValue = R.includes(rowsPerPageListItems) ? rowsPerPage : ALL_ROWS_VALUE;

    const previousSurroundingPages = this.getPreviousPages();
    const nextSurroundingPages = this.getNextPages();

    return (
      <PaginationContainer>
        <Flex main="end" cross="center">
          {rows > 0 && (
            <Flex main="end" cross="center">
              {polyglot.t('common.show')}
              <SelectContainer>
                <Select
                  id="pagination-rows-per-page"
                  value={perPageValue}
                  onChange={event => this.handlePagination(event.target.value, page)}
                  size="small"
                  options={[
                    ...rowsPerPageListItems.map(item => ({
                      label: item,
                      value: item,
                    })),
                    ...(withoutAll
                      ? []
                      : [
                          {
                            label: polyglot.t('common.all'),
                            value: ALL_ROWS_VALUE,
                          },
                        ]),
                  ].reverse()}
                />
              </SelectContainer>
              <Space left="m">{polyglot.t('pagination.rows')}</Space>
            </Flex>
          )}
          <Space left="xl">
            <Flex cross="center">
              <Text>
                {polyglot.t('pagination.showing_rows_from_to', {
                  current_first: getCurrentFirstRow(page, parseInt(rowsPerPage, 10), rows),
                  current_last: getCurrentLastRow(page, parseInt(rowsPerPage, 10), rows),
                })}
              </Text>
              <Space left="s">
                {polyglot.t('pagination.showing_rows_out_of_total', {
                  total: rows,
                })}
              </Space>
            </Flex>
          </Space>
        </Flex>
        <PaginationButtonsContainer>
          <ButtonLink
            size="small"
            type="secondary"
            title="First page"
            onClick={() => this.handlePagination(rowsPerPage, FIRST_PAGE)}
            disabled={page === FIRST_PAGE}
          >
            {polyglot.t('common.first')}
          </ButtonLink>
          <ButtonLink
            size="small"
            type="secondary"
            title="Previous page"
            onClick={() => this.handlePagination(rowsPerPage, page - 1)}
            disabled={!pageNumberIsValid(page - 1, rowsPerPage, rows, FIRST_PAGE)}
            iconLeft={<ChevronBackwardIcon />}
          />
          <Flex main="spaceBetween" cross="center">
            {this.getPreviousSurroundingPages(previousSurroundingPages, nextSurroundingPages)}
            <ButtonLink
              size="small"
              type="secondary"
              disabled
              title="Current page"
              iconLeft={<PageItem current>{page}</PageItem>}
            />
            {this.getNextSurroundingPages(previousSurroundingPages, nextSurroundingPages)}
          </Flex>
          <ButtonLink
            size="small"
            type="secondary"
            title="Next page"
            onClick={() => this.handlePagination(rowsPerPage, page + 1)}
            disabled={!pageNumberIsValid(page + 1, rowsPerPage, rows, FIRST_PAGE)}
            iconLeft={<ChevronForwardIcon />}
          />
          <ButtonLink
            size="small"
            type="secondary"
            title="Last page"
            onClick={() => this.handlePagination(rowsPerPage, maxPageNumber)}
            disabled={page === maxPageNumber}
          >
            {polyglot.t('common.last')}
          </ButtonLink>
        </PaginationButtonsContainer>
      </PaginationContainer>
    );
  }
}

Pagination.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageItems: PropTypes.arrayOf(number),
  rows: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
};

export default connect(state => ({
  polyglot: getPolyglot(state),
}))(Pagination);
