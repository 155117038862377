const v1_post_to = user => ({
  display_name: `${user.first_name} ${user.last_name}`,
});

export default {
  v1: {
    post: {
      to: v1_post_to,
    },
  },
};
