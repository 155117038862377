import React, { useContext } from 'react';
import { useForm, useFormContext, FormProvider } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as Yup from 'yup';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import DateInput from 'components/common/DateInput';
import InputField from 'components/common/InputField';
import Checkbox from 'components/common/Checkbox';
import { MMBState } from 'components/services/mmb';
import { useToggle } from 'utils/hooks';
import { isBefore } from 'utils/dates';
import { usePolyglot } from 'components/services/i18n';

const PassengerSchema = Yup.object().shape({
  noExpiry: Yup.bool(),
  documentNumber: Yup.string().required('mmb.booking_details.edit_passenger_modal.document_number'),
  documentExpiry: Yup.object().when('noExpiry', {
    is: Boolean,
    then: Yup.object(),
    otherwise: Yup.object()
      .test('required', 'mmb.booking_details.edit_passenger_modal.expiry_date', value => {
        return Boolean(value.day && value.month && value.year);
      })
      .test('is-valid', 'mmb.booking_details.edit_passenger_modal.invalid_format', value => {
        const { day, month, year } = value;

        if (!day || !month || !year) {
          return false;
        }

        if (!/^\d+$/.test(day) || !/^\d+$/.test(month) || !/^\d+$/.test(year)) {
          return false;
        }

        if (+day > 0 && +day < 32 && +month > 0 && +month < 13 && +year > 1800 && +year < 1e4) {
          return true;
        }

        return false;
      })
      .test(
        'is-after-trip',
        'mmb.booking_details.edit_passenger_modal.cant_expire',
        function test(value) {
          const enteredDate = new Date(value.year, value.month, value.day);
          const tripDate = new Date(this.options.context.trip);
          return !isBefore(enteredDate, tripDate);
        },
      )
      .required(),
  }),
});

const EditPassengerForm = () => {
  const { watch } = useFormContext();
  const polyglot = usePolyglot();

  const noExpiry = watch('noExpiry');

  return (
    <form>
      <Stack direction="row" spacing="large">
        <InputField
          name="documentNumber"
          label={polyglot.t('mmb.booking_details.edit_passenger_modal.passport_number')}
          placeholder={polyglot.t('mmb.booking_details.edit_passenger_modal.passport_number')}
          dataTest="document-number-input"
        />

        <Stack spacing="large">
          <DateInput
            name="documentExpiry"
            label={polyglot.t('mmb.booking_details.edit_passenger_modal.passport_expiry')}
            disabled={noExpiry}
            dataTest="document-expiry-input"
          />

          <Checkbox
            name="noExpiry"
            label={polyglot.t('mmb.booking_details.edit_passenger_modal.no_expiry')}
            dataTest="document-no-expiry"
          />
        </Stack>
      </Stack>
    </form>
  );
};

const EditPassengerModal = ({ bid, passenger, itinerary, onClose }) => {
  const errorAlert = useToggle();
  const polyglot = usePolyglot();
  const { updatePassenger, state } = useContext(MMBState);
  const [travelDocumentYear, travelDocumentMonth, travelDocumentDay] = passenger.documentExpiry
    ? passenger.documentExpiry.split('-')
    : [];

  const isUpdating = state.bookingsState[bid]?.updatingPassenger;

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(PassengerSchema),
    context: { trip: itinerary?.sectors?.[0].departure.time.utc },
    defaultValues: {
      id: passenger.id,
      documentNumber: passenger.documentNumber,
      noExpiry: false,
      documentExpiry: {
        day: travelDocumentDay,
        month: travelDocumentMonth,
        year: travelDocumentYear,
      },
    },
  });

  const handleSubmit = () => {
    errorAlert.setOff();

    const { givenNames, surnames, ...values } = form.getValues();

    const payload = {
      ...values,
      documentExpiry: values.noExpiry
        ? polyglot.t('mmb.booking_details.edit_passenger_modal.no_expiry')
        : `${values.documentExpiry.year?.padStart?.(
            2,
            '0',
          )}-${values.documentExpiry.month?.padStart?.(2, '0')}-${values.documentExpiry.day}`,
      id: passenger.id,
    };

    updatePassenger(bid, payload)
      .then(() => {
        onClose();
      })
      .catch(() => {
        errorAlert.setOn();
      });
  };

  return (
    <Modal onClose={onClose} dataTest="add-documents-modal">
      <ModalHeader
        title={polyglot.t('mmb.booking_details.edit_passenger_modal.travel_document')}
        description={polyglot.t('mmb.booking_details.edit_passenger_modal.add_passport')}
        illustration={<Illustration name="NetVerify" size="extraSmall" />}
      />

      <ModalSection>
        {errorAlert.isOn ? (
          <Alert
            type="critical"
            spaceAfter="large"
            title={polyglot.t('mmb.booking_details.edit_passenger_modal.failed_to_update')}
          >
            {polyglot.t('mmb.booking_details.edit_passenger_modal.try_again')}
          </Alert>
        ) : (
          <FormProvider {...form}>
            <EditPassengerForm />
          </FormProvider>
        )}
      </ModalSection>

      <ModalFooter>
        <Button
          type={errorAlert.isOn ? 'critical' : 'primary'}
          size="large"
          loading={isUpdating}
          onClick={errorAlert.isOn ? onClose : form.handleSubmit(handleSubmit)}
          dataTest="action-btn"
        >
          {errorAlert.isOn
            ? polyglot.t('common.close')
            : polyglot.t('searchAndBooking.multicity.add')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(EditPassengerModal);
