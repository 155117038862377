import { useEffect, useContext } from 'react';

import { MMBState } from 'components/services/mmb';

const useInvoices = bid => {
  const { state, getInvoices, downloadInvoices } = useContext(MMBState);

  useEffect(() => {
    if (!state.bookingsState[bid]?.invoices) {
      getInvoices(bid);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.bookingsState[bid]?.invoices
    ? {
        data: state.bookingsState[bid].invoices,
        loading: state.bookingsState[bid].loadingInvoices,
        error: state.bookingsState[bid].loadingInvoicesError,
        downloadInvoices,
      }
    : {
        data: null,
        loading: true,
        error: null,
      };
};

export default useInvoices;
