import { filterTypeOption } from 'consts/support';

export const categoriesResponseToFilterOptions = (categoriesResponse: HttpResponse) => {
  if (categoriesResponse.isValid()) {
    const { requestCategories } = categoriesResponse;
    return requestCategories.reduce(
      (accumulator, requestCategory) => {
        const categoryOption = {
          label: requestCategory.name,
          disabled: true,
          // value is here because of "two children with same key warning"
          value: `category-${requestCategory.id}`,
        };
        accumulator.push(categoryOption);
        requestCategory.requestTypes.forEach(requestType =>
          accumulator.push({
            label: requestType.name,
            value: requestType.id,
          }),
        );
        return accumulator;
      },
      [filterTypeOption],
    );
  }
  return [];
};
