import * as R from 'ramda';

const INITIAL_PARAMS_ACCOUNTS = {
  travel_type: '',
  status: '',
  name: '',
  q: '',
  sort: [],
  id: '',
};

export const INITIAL_PARAMS = {
  ...INITIAL_PARAMS_ACCOUNTS,
};

export const INITIAL_PARAMS_KEYS = R.keys(INITIAL_PARAMS);

export const UTM_KEYS = {
  utm_source: true,
  utm_medium: true,
  utm_campaign: true,
  utm_term: true,
  utm_content: true,
};
