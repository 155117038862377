import React from 'react';
import { useLocation } from 'react-router';
import * as R from 'ramda';

import { usePolyglot } from 'components/services/i18n';
import useResources from 'components/services/resources/useResources';

export const ContextualResourcesState = React.createContext({});

const RESOURCES_MAP = [
  {
    predicate: path => path.startsWith('/getting-started'),
    term: 'getting_started',
    title: 'portal.contextual_resources.onboarding',
  },
  {
    predicate: path => path.startsWith('/getting-started'),
    term: 'my_company',
    title: 'portal.contextual_resources.onboarding',
  },
  {
    predicate: path => path.startsWith('/my-solutions'),
    term: 'my_solutions',
    title: 'portal.contextual_resources.solutions',
  },
  {
    predicate: path =>
      path.startsWith('/reports/create') ||
      path.startsWith('/reports/edit') ||
      path.startsWith('/reports/details') ||
      path.startsWith('/reports/copy'),
    term: 'reports',
    title: 'portal.contextual_resources.reports',
    forceToPopover: true,
  },
  {
    predicate: path => path.startsWith('/reports'),
    term: 'reports',
    title: 'portal.contextual_resources.reports',
  },
  {
    predicate: path => path.startsWith('/customer_support'),
    term: 'customer_support',
    title: 'portal.contextual_resources.customer_support',
  },
  {
    predicate: path => path.startsWith('/help'),
    term: 'help',
    title: 'portal.contextual_resources.help_page',
  },
  {
    predicate: path => path.startsWith('/search'),
    term: 'booking_tool',
    title: 'portal.contextual_resources.booking_tool',
  },
  {
    predicate: path => path.startsWith('/manage-my-bookings'),
    term: 'manage_bookings',
    title: 'portal.contextual_resources.mmb',
    forceToPopover: true,
  },
  {
    predicate: path => path.startsWith('/deeplink-generator'),
    term: 'deeplinks',
    title: 'portal.contextual_resources.deeplink_generator',
  },
  {
    predicate: path => path === '/widgets',
    term: 'widgets',
    title: 'portal.contextual_resources.widgets',
  },
  {
    predicate: path => path.startsWith('/widgets') && path.endsWith('/new'),
    term: 'widgets',
    title: 'portal.contextual_resources.widgets',
    forceToPopover: true,
  },
  {
    predicate: path => path.startsWith('/companies/'),
    term: 'my_company',
    title: 'portal.contextual_resources.onboarding',
  },
];

const getResourcesToDisplay = (resources, pathname) => {
  const { term, title, forceToPopover } = RESOURCES_MAP.find(r => r.predicate(pathname)) || {};

  if (!term) {
    return {};
  }

  const resourcesOnPage = resources.filter(r =>
    r?.contextual_resources___pages?.taxonomyTerms?.some(t => t.codename === term),
  );

  const data = R.groupBy(r => r.searchableCategory, resourcesOnPage);

  return {
    title,
    data,
    forceToPopover: forceToPopover || false,
    hasResources: Object.keys(data).length > 0,
  };
};

const ContextualResourcesProvider = ({ children }) => {
  const { data } = useResources();
  const location = useLocation();
  const polyglot = usePolyglot();
  const value = data ? getResourcesToDisplay(data, location.pathname) : {};
  if (value.title) {
    value.title = polyglot.t(value.title);
  }

  return (
    <ContextualResourcesState.Provider value={value}>{children}</ContextualResourcesState.Provider>
  );
};

export default ContextualResourcesProvider;
