import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Alert from '@kiwicom/orbit-components/lib/Alert';

import * as messagesActions from 'redux/modules/messages';
import { getSubmitError } from 'redux/selectors/messages';

import Message from 'shapes/Message';
import { AlertContainer, ErrorsContainer } from './SubmitError.styled';

/**
 * Submit errors container.
 * Displays stacked errors.
 * Multiple errors are supported.
 * Error can be closed, and will not autohide.
 */
class SubmitError extends Component {
  handleClose = id => event => {
    event.stopPropagation();

    this.props.closeError(id);
  };

  render() {
    const { errors } = this.props;

    if (!errors) {
      return null;
    }

    return (
      <ErrorsContainer>
        {errors.map(error => (
          <AlertContainer key={error.id}>
            <Alert type="critical" icon closable onClose={this.handleClose(error.id)} title={null}>
              {error.text}
            </Alert>
          </AlertContainer>
        ))}
      </ErrorsContainer>
    );
  }
}

SubmitError.propTypes = {
  error: PropTypes.shape(Message),
  closeError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  errors: getSubmitError(state),
});

const mapDispatchToProps = {
  closeError: messagesActions.clearMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitError);
