import * as R from 'ramda';

import { logError } from 'utils/sentry';
import { UTM_KEYS } from 'consts/url';
import { getCookie } from 'utils/cookie';

const SEPARATOR = '||';

export const fireRegistrationEvent = (action, email) => {
  const label = Object.keys(UTM_KEYS).map(getCookie).join(SEPARATOR);

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Registration',
      action,
      label,
    });
  } catch (error) {
    logError(error);
  }
};

export const fireEvent = event => {
  const userId = R.path(['userInfo', 'user', 'developerId'], window);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, userId });
};

export const fireHelpCardEvent = (section, resource) => {
  const userId = R.path(['userInfo', 'user', 'developerId'], window);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'helpCardLinkClick',
    userId,
    section,
    resource,
  });
};

export const fireSmartpointEvent = (category, action, label) => {
  const userId = R.path(['userInfo', 'user', 'developerId'], window);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'smartpointParse',
    userId,
    category,
    action,
    label,
  });
};

export const fireDocsEvent = event => {
  const userId = R.path(['userInfo', 'user', 'developerId'], window);
  const companyName = R.path(['userInfo', 'company', 'name'], window);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...event,
    userId,
    companyName,
  });
};
