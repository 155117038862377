import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { statusesBadgeTypes, statuses } from 'consts/smartPass';
import { KIWI_ADMIN } from 'consts/roles';
import { SmartPassContext } from 'components/services/smartPass';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useCompanyDetails from 'components/services/company/useCompanyDetails';

import { StackDiv } from './index.styled';
import { TextLink } from '@kiwicom/orbit-components';

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: ${({ theme }) => theme.orbit.paletteWhite};
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
`;

const RequestChangeSuccessAlert = styled.div`
  position: fixed;
  width: ${({ width }) => width};
  z-index: 3;
  right: 24px;
  top: 75px;
`;

const Header = ({
  onSubmit,
  smartPassName,
  companyName,
  status,
  changeDisabled,
  disabled,
  handleBackClick,
  smartPassId,
  isVoucherScreenShown,
  unsavedVoucherModal,
}) => {
  const polyglot = usePolyglot();
  const { saveForReviewModal, requestChangeModal, state, approveSmartPass, clearSuccessMessage } =
    useContext(SmartPassContext);
  const user = useCurrentUser();
  const companyDetails = useCompanyDetails(user.companyName);

  const history = useHistory();

  const handleSaveAsDraft = () => {
    if (isVoucherScreenShown) {
      unsavedVoucherModal.setOn();
    } else {
      onSubmit(statuses.DRAFT);
    }
  };

  const enableEditing = () => {
    changeDisabled(false);
  };

  const approve = async () => {
    const res = await approveSmartPass(smartPassId, companyName);
    if (res && res.status === 200) {
      history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass`);
    }
  };

  const saveAdminChanges = () => {
    onSubmit(status || statuses.DRAFT);
    if (changeDisabled) {
      changeDisabled(true);
    }
  };

  const handleSaveClick = () => {
    if (isVoucherScreenShown) {
      unsavedVoucherModal.setOn();
    } else {
      saveForReviewModal.setOn();
    }
  };

  const hasAdminPrivileges =
    user.role === KIWI_ADMIN || user.email === companyDetails.data?.accountManager;

  const isPartnerUserAndDraft = !hasAdminPrivileges && status === statuses.DRAFT;

  const loading =
    state.approveSmartPassLoading || state.createNewSmartPassLoading || state.editSmartPassLoading;

  return (
    <>
      {state.requestChangeData && (
        <RequestChangeSuccessAlert width="443px">
          <Alert
            closable
            onClose={clearSuccessMessage}
            type="info"
            icon={<InformationCircleIcon />}
            title={polyglot.t('smart_pass.request_change_modal.success_1')}
          >
            {polyglot.t('smart_pass.request_change_modal.success_2')}
            <Link to={ROUTE_CONFIG.SUPPORT.path}>
              <TextLink>{polyglot.t('smart_pass.request_change_modal.success_3')}</TextLink>.
            </Link>
          </Alert>
        </RequestChangeSuccessAlert>
      )}
      <HeaderWrapper>
        <StackDiv>
          <Stack direction="row" align="center" justify="start">
            <Button size="small" iconLeft={<ChevronBackwardIcon />} onClick={handleBackClick} />
            <Text type="secondary" weight="bold">
              {smartPassName || polyglot.t('smart_pass.form.header.smart_pass_name')}
            </Text>

            {status && (
              <Badge type={statusesBadgeTypes[status]}>
                {polyglot.t(`smart_pass.table.badges.${status}`)}
              </Badge>
            )}
          </Stack>
        </StackDiv>
        <StackDiv>
          {loading ? (
            <Stack direction="row" justify="end">
              <Loading type="inlineLoader" />
            </Stack>
          ) : (
            <Stack direction="row" align="center" justify="end">
              {(isPartnerUserAndDraft || !status) && (
                <>
                  <Button size="small" type="primarySubtle" onClick={handleSaveAsDraft}>
                    {polyglot.t('smart_pass.form.header.save_as_draft')}
                  </Button>
                  <Button size="small" onClick={handleSaveClick}>
                    {polyglot.t('smart_pass.form.header.save')}
                  </Button>
                </>
              )}
              {hasAdminPrivileges && disabled && (
                <>
                  <Button size="small" type="primarySubtle" onClick={enableEditing}>
                    {polyglot.t('common.edit')}
                  </Button>
                  {status !== statuses.APPROVED && (
                    <Button size="small" onClick={approve}>
                      {polyglot.t('common.approve')}
                    </Button>
                  )}
                </>
              )}
              {hasAdminPrivileges && !disabled && (
                <>
                  <Link to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass`}>
                    <Button size="small" type="primarySubtle">
                      {polyglot.t('common.discard_changes')}
                    </Button>
                  </Link>
                  <Button size="small" onClick={saveAdminChanges}>
                    {polyglot.t('common.save_changes')}
                  </Button>
                </>
              )}
              {!hasAdminPrivileges && status && status !== statuses.DRAFT && (
                <Button size="small" onClick={requestChangeModal.setOn}>
                  {polyglot.t('smart_pass.form.header.request_change')}
                </Button>
              )}
            </Stack>
          )}
        </StackDiv>
      </HeaderWrapper>
    </>
  );
};

export default React.memo(Header);
