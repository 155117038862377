import React, { createContext, useReducer } from 'react';

import { proxy } from 'utils/api';
import ShoppingBasketOrdersMapper from 'mappers/nextGen/orders/payments/order/_';
import ShoppingBasketItemsMapper from 'mappers/nextGen/orders/payments/order/{basket_id}/_';
import ShoppingBasketPaymentMapper from 'mappers/nextGen/orders/payments/order/{basket_id}/payment/_';

export const ShoppingBasketState = createContext({
  fetchCurrentlyActiveOrder: (bid, mmbToken, currency, sessionId) => [
    bid,
    mmbToken,
    currency,
    sessionId,
  ],
  fetchBasketPaymentStatus: (bid, basketId, mmbToken) => [bid, basketId, mmbToken],
  payShoppingBasket: (bid, basketId, mmbToken, price, sessionId) => [
    bid,
    basketId,
    mmbToken,
    price,
    sessionId,
  ],
  addSeatingMMB: (routeId, seating) => [routeId, seating],
  resetSeatingsMMB: _ => null,
  resetData: _ => null,
});

const initialState = {
  activeOrder: {
    loading: false,
    data: null,
    error: null,
  },
  payment: {
    loading: false,
    data: null,
    error: null,
  },
  seatings: {},
  status: {
    loading: false,
    data: null,
    error: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_CURRENTLY_ACTIVE_ORDER':
      return {
        ...state,
        activeOrder: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'FETCH_CURRENTLY_ACTIVE_ORDER_SUCCESS':
      return {
        ...state,
        activeOrder: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case 'FETCH_CURRENTLY_ACTIVE_ORDER_FAIL':
      return {
        ...state,
        activeOrder: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'FETCH_BASKET_PAYMENT_STATUS':
      return {
        ...state,
        status: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'FETCH_BASKET_PAYMENT_STATUS_SUCCESS':
      return {
        ...state,
        status: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case 'FETCH_BASKET_PAYMENT_STATUS_FAIL':
      return {
        ...state,
        status: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'PAY_SHOPPING_BASKET':
      return {
        ...state,
        payment: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'PAY_SHOPPING_BASKET_SUCCESS':
      return {
        ...state,
        payment: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case 'PAY_SHOPPING_BASKET_FAIL':
      return {
        ...state,
        payment: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case 'ADD_SEATING_MMB':
      return {
        ...state,
        seatings: {
          ...state.seatings,
          [action.routeId]: {
            ...state.seatings[action.routeId],
            ...action.seating,
          },
        },
      };
    case 'RESET_SEATINGS_MMB':
      return {
        ...state,
        seatings: {},
        payment: {
          loading: false,
          data: null,
          error: null,
        },
      };
    case 'RESET_DATA':
      return initialState;
    default:
      return state;
  }
};

const ShoppingBasketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchCurrentlyActiveOrder = async (bid, mmbToken, currency, sessionId) => {
    dispatch({ type: 'FETCH_CURRENTLY_ACTIVE_ORDER' });
    try {
      const { data } = await proxy.direct.put(
        `kiwi-mmb/payments/v1/payments/order?booking_id=${bid}`,
        ShoppingBasketOrdersMapper.direct.put.to(currency, sessionId),
        {
          headers: {
            'kw-user-token': mmbToken,
          },
        },
      );

      const payload = ShoppingBasketOrdersMapper.direct.put.from(data);
      dispatch({ type: 'FETCH_CURRENTLY_ACTIVE_ORDER_SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'FETCH_CURRENTLY_ACTIVE_ORDER_FAIL', error });
    }
  };

  const fetchBasketPaymentStatus = async (bid, basketId, mmbToken) => {
    dispatch({ type: 'FETCH_BASKET_PAYMENT_STATUS' });
    try {
      const { data } = await proxy.direct.get(
        `kiwi-mmb/payments/v1/payments/order/${basketId}/payment?booking_id=${bid}`,
        {
          headers: {
            'kw-user-token': mmbToken,
          },
        },
      );

      const payload = ShoppingBasketPaymentMapper.direct.post.from(data);
      dispatch({ type: 'FETCH_BASKET_PAYMENT_STATUS_SUCCESS', payload });
    } catch (error) {
      dispatch({ type: 'FETCH_BASKET_PAYMENT_STATUS_FAIL', error });
    }
  };

  const payShoppingBasket = async (bid, mmbToken, currency, seatings, callback, sessionId) => {
    dispatch({ type: 'PAY_SHOPPING_BASKET' });
    try {
      // get currently active order
      const { data } = await proxy.direct.put(
        `kiwi-mmb/payments/v1/payments/order?booking_id=${bid}`,
        ShoppingBasketOrdersMapper.direct.put.to(currency, sessionId),
        {
          headers: {
            'kw-user-token': mmbToken,
          },
        },
      );

      const { basketId } = ShoppingBasketOrdersMapper.direct.put.from(data);

      // add items to order
      const addItemsToOrderResponse = await proxy.direct.post(
        `kiwi-mmb/payments/v1/payments/order/${basketId}?booking_id=${bid}`,
        ShoppingBasketItemsMapper.direct.post.to(currency, seatings),
        {
          headers: {
            'kw-user-token': mmbToken,
          },
        },
      );

      const totalPrice = addItemsToOrderResponse.data.common.basket.price_breakdown.total_price;

      // pay for current shopping basket
      const payShoppingBasketResponse = await proxy.direct.post(
        `kiwi-mmb/payments/v1/payments/order/${basketId}/payment?booking_id=${bid}`,
        ShoppingBasketPaymentMapper.direct.post.to(totalPrice, sessionId),
        {
          headers: {
            'kw-user-token': mmbToken,
          },
        },
      );

      const payload = ShoppingBasketPaymentMapper.direct.post.from(payShoppingBasketResponse.data);

      dispatch({ type: 'PAY_SHOPPING_BASKET_SUCCESS', payload });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({ type: 'PAY_SHOPPING_BASKET_FAIL', error });
    }
  };

  const addSeatingMMB = (routeId, seating) => {
    dispatch({ type: 'ADD_SEATING_MMB', routeId, seating });
  };

  const resetSeatingsMMB = () => {
    dispatch({ type: 'RESET_SEATINGS_MMB' });
  };

  const resetData = () => {
    dispatch({ type: 'RESET_DATA' });
  };

  const value = {
    ...state,
    fetchCurrentlyActiveOrder,
    fetchBasketPaymentStatus,
    payShoppingBasket,
    addSeatingMMB,
    resetSeatingsMMB,
    resetData,
  };

  return <ShoppingBasketState.Provider value={value}>{children}</ShoppingBasketState.Provider>;
};

export default ShoppingBasketProvider;
