import Stack from '@kiwicom/orbit-components/lib/Stack';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';

import { usePolyglot } from 'components/services/i18n';

const ContactDetails = ({ contactDetails: { email, phone } }) => {
  const polyglot = usePolyglot();

  return (
    <Card title="Contact details">
      <CardSection>
        <Stack direction="row" spacing="XXLarge">
          <Box>
            <Text size="small" type="secondary" spaceAfter="smallest">
              {polyglot.t('common.email')}
            </Text>
            <Text>{email}</Text>
          </Box>
          <Box>
            <Text size="small" type="secondary" spaceAfter="smallest">
              {polyglot.t('common.phone_number')}
            </Text>
            <Text>{phone}</Text>
          </Box>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default ContactDetails;
