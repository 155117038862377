import React, { Fragment } from 'react';
import * as R from 'ramda';

import { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import useCreateSolution from 'components/scenes/MySolutions/services/useCreateSolution';
import { SOLUTIONS } from 'consts/solutions';

import { FlexWrapper, Card, Row } from './index.styled';

const SelectSolution = () => {
  const polyglot = usePolyglot();
  const productRows = R.splitEvery(2, SOLUTIONS);
  const { handleStepSelect: handleSelect } = useCreateSolution();

  return (
    <>
      <ModalHeader
        title={<Heading type="title2">{polyglot.t('solutions.select_solution')}</Heading>}
      />
      <ModalSection>
        <FlexWrapper>
          {productRows.map((row, i) => (
            <Row key={i}>
              {row.map((solution, j) => (
                <Fragment key={j}>
                  <Card onClick={() => handleSelect(solution)}>
                    <Heading type="title4" spaceAfter="smallest">
                      {polyglot.t(solution.display_name)}
                    </Heading>
                    <Text type="secondary">{polyglot.t(solution.description)}</Text>
                  </Card>
                </Fragment>
              ))}
            </Row>
          ))}
          <Card direction="row" justify="space-between" noBottomPadding>
            <div>
              <Heading type="title4" spaceAfter="smallest">
                {polyglot.t('solutions.not_sure')}
              </Heading>
              <Button
                type="secondary"
                size="small"
                href="/portal/docs/user_guides/choosing_a_right_solution"
                external
              >
                {polyglot.t('solutions.help')}
              </Button>
            </div>
            <Illustration name="AirHelp" size="extraSmall" />
          </Card>
        </FlexWrapper>
      </ModalSection>
    </>
  );
};

export default SelectSolution;
