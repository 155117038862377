import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;

  ${({ width }) => width && `width: ${width}px`}
`;
export const InputStepperWrapper = styled.div`
  width: 180px;
  padding-left: 28px;
`;
export const TypeOfFlightSelectWrapper = styled.div`
  margin-bottom: 22px;
`;
export const TypeOfFlightSelect = styled.span`
  display: inline-block;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
  }

  ${({ selected, theme }) =>
    selected &&
    `
    font-weight: bold;
    padding-bottom: 3px;
    border-bottom: 3px solid ${theme.orbit.paletteProductNormal}
  `}
`;
export const CarriersFilterWrapper = styled.div`
  width: 336px;
  padding: 16px;
`;

export const CarriersFilteredValuesWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;
