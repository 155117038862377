import * as R from 'ramda';
import getSymbolFromCurrency from 'currency-symbol-map';

import { duration } from '../timeFormatters';

function groupByRoutes(route) {
  if (route.length === 0) {
    return [];
  }

  const departureRoutes = route.filter(r => r.return === 0);
  const returnRoutes = route.filter(r => r.return === 1);

  if (returnRoutes.length) {
    return [departureRoutes, returnRoutes];
  }

  return [departureRoutes];
}

/**
 * Returns props for `FlightLeg`
 * @param {array} group
 */
function getFlightLegProps(group) {
  const firstElement = group[0];
  const lastElement = group[group.length - 1];
  const { cityFrom, flyFrom } = firstElement;
  const { cityTo, flyTo } = lastElement;
  const carriers = R.uniq(group.map(element => element.airline));
  const stopAirports = stopAirportsFromGroup(group);
  return {
    key: firstElement.id,
    cityFrom,
    cityTo,
    flyFrom,
    flyTo,
    carriers,
    stopAirports,
    stops: group.length - 1,
    arrival: lastElement.local_arrival,
    departure: firstElement.local_departure,
    duration: duration(firstElement.utc_departure, lastElement.utc_arrival),
  };
}

/**
 * Get a list of airports in the route group
 * @param {object} group grouped routes from `groupByRoutes` function
 */
function stopAirportsFromGroup(group) {
  return R.uniq(R.flatten(group.map(element => [element.flyFrom, element.flyTo])));
}

function getCurrencySymbolFromTrip(trip) {
  const conversion = R.filter(value => value === trip.price, trip.conversion);
  return getSymbolFromCurrency(R.keys(conversion)[0]) || R.keys(conversion)[0];
}

function getPrice(searchResult) {
  const currencySymbol = getCurrencySymbolFromTrip(searchResult);

  return `${currencySymbol}${searchResult.price}`;
}

function getSearchResultId(searchResult) {
  return searchResult.id;
}

/**
 * Returns all carrier codes for the search result
 * @param {array} data Array of search results for Return/OneWay
 */
function getAllCarriers(data) {
  const carrierCodes = data.reduce((acc, trip) => {
    const { airlines } = trip;
    return acc.concat(airlines);
  }, []);

  return R.uniq(carrierCodes);
}

function filterResponseByCarriers(response, filteredCarriers) {
  return response.data.filter(trip => R.intersection(trip.airlines, filteredCarriers).length === 0);
}

function getMinPrice(response) {
  return response.data.reduce(
    (previousPrice, flight) => (previousPrice < flight.price ? previousPrice : flight.price),
    response.data[0].price,
  );
}

function getMaxPrice(response) {
  return response.data.reduce(
    (previousPrice, flight) => (previousPrice > flight.price ? previousPrice : flight.price),
    response.data[0].price,
  );
}

function filterResponseByPrices(list, pricesFilterValues) {
  const minPrice = pricesFilterValues[0];
  const maxPrice = pricesFilterValues[1];
  return list.filter(listItem => listItem.price >= minPrice && listItem.price <= maxPrice);
}

export default {
  groupByRoutes,
  getFlightLegProps,
  stopAirportsFromGroup,
  getPrice,
  getSearchResultId,
  getAllCarriers,
  filterResponseByCarriers,
  getMinPrice,
  getMaxPrice,
  filterResponseByPrices,
  getCurrencySymbolFromTrip,
};
