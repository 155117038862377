import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import TripIcon from '@kiwicom/orbit-components/lib/icons/Trip';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { OFFERS_I18N } from 'consts/offers';
import { CONTENT_TYPES } from 'consts/markets';
import { useToggle } from 'utils/hooks';
import { Space, SortArrow, Pagination } from 'common';
import { Authorization } from 'common/Authorization';
import { Card, CardSection, CardActionHeader } from 'components/common/Card';
import { usePolyglot } from 'components/services/i18n';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useAffilIdCommissions from 'components/services/commercials/useAffilIdCommissions';

import CommercialsForm from './CommercialsForm';
import CommercialDisableDialog from './CommercialsDisableDialog';
import CommercialsEnableDialog from './CommercialsEnableDialog';

const TouchableIconContainer = styled.div`
  cursor: pointer;
`;

const Commercials = ({ solution }) => {
  const polyglot = usePolyglot();
  const createCommercialModal = useToggle();
  const successCreateAlert = useToggle();
  const successEditAlert = useToggle();
  const successEnableAlert = useToggle();
  const successDisableAlert = useToggle();
  const [disableCommercialDraft, setDisableCommercialDraft] = useState(null);
  const [enableCommercialDraft, setEnableCommercialDraft] = useState(null);
  const [editCommercialDraft, setEditCommercialDraft] = useState(null);
  const user = useCurrentUser();
  const { commercials, loadCommercials } = useAffilIdCommissions(solution.affilId, solution.uuid);
  const isAdminOrACM = user.role === 'ACL-admin' || user.role === 'ACL-account-manager';

  const sort = commercials.params?.sort || {};
  const pagination = commercials.params?.pagination || {};

  const handleSort = key => {
    let newSortKey = key;
    let newSortDirection = sort.dir;

    if (sort.key === newSortKey) {
      newSortDirection = sort.dir === 'asc' ? 'desc' : 'asc';
    }

    const newParams = {
      pagination,
      sort: {
        key: newSortKey,
        dir: newSortDirection,
      },
    };

    loadCommercials(solution.affilId, solution.uuid, newParams);
  };

  const handlePagination = (rowsPerPage, nextPage) => {
    const newParams = {
      pagination: {
        perPage: Number(rowsPerPage),
        page: nextPage,
      },
      sort,
    };

    loadCommercials(solution.affilId, solution.uuid, newParams);
  };

  const handleCreateSuccess = () => {
    successCreateAlert.setOn();
    createCommercialModal.setOff();
  };

  const handleCommercialEditSuccess = () => {
    successEditAlert.setOn();
    setEditCommercialDraft(null);
  };

  const handleEnableCommercial = () => {
    successEnableAlert.setOn();
    setEnableCommercialDraft(null);
  };

  const handleDisableSuccess = () => {
    successDisableAlert.setOn();
    setDisableCommercialDraft(null);
  };

  return (
    <Authorization resource="model.commission" action={['read', 'read_assigned', 'read_own']}>
      {disableCommercialDraft && (
        <CommercialDisableDialog
          onClose={() => setDisableCommercialDraft(null)}
          onSuccess={handleDisableSuccess}
          commercial={disableCommercialDraft}
          affilId={solution.affilId}
          uuid={solution.uuid}
        />
      )}

      {enableCommercialDraft && (
        <CommercialsEnableDialog
          onClose={() => setEnableCommercialDraft(null)}
          onSuccess={handleEnableCommercial}
          commercial={enableCommercialDraft}
          affilId={solution.affilId}
          uuid={solution.uuid}
        />
      )}

      {createCommercialModal.isOn && (
        <CommercialsForm
          onClose={createCommercialModal.setOff}
          onSuccess={handleCreateSuccess}
          commercial={{}}
          affilId={solution.affilId}
          uuid={solution.uuid}
        />
      )}
      {editCommercialDraft && (
        <CommercialsForm
          onClose={() => setEditCommercialDraft(null)}
          onSuccess={handleCommercialEditSuccess}
          commercial={editCommercialDraft}
          affilId={solution.affilId}
          uuid={solution.uuid}
        />
      )}
      <Card>
        <CardActionHeader
          title={polyglot.t('commercials.title')}
          icon={
            <Space right="m">
              <TripIcon />
            </Space>
          }
          action={
            <Authorization
              resource="model.commission"
              action={['create', 'create_assigned', 'create_own']}
            >
              <Button
                onClick={createCommercialModal.setOn}
                type="secondary"
                size="small"
                width="140px"
                iconLeft={<PlusIcon />}
                dataTest="commercials-add-commercial-button"
              >
                {polyglot.t('commercials.add_commercial')}
              </Button>
            </Authorization>
          }
        />

        <CardSection
          showAlert={
            successCreateAlert.isOn ||
            successEditAlert.isOn ||
            successEnableAlert.isOn ||
            successDisableAlert.isOn
          }
          alert={
            (successCreateAlert.isOn && (
              <Alert icon closable type="success" onClose={successCreateAlert.setOff}>
                {polyglot.t('commercials.create_success')}
              </Alert>
            )) ||
            (successEditAlert.isOn && (
              <Alert icon closable type="success" onClose={successEditAlert.setOff}>
                {polyglot.t('commercials.edit_success')}
              </Alert>
            )) ||
            (successEnableAlert.isOn && (
              <Alert icon closable type="success" onClose={successEnableAlert.setOff}>
                {polyglot.t('commercials.enable_success')}
              </Alert>
            )) ||
            (successDisableAlert.isOn && (
              <Alert icon closable type="success" onClose={successDisableAlert.setOff}>
                {polyglot.t('commercials.disable_success')}
              </Alert>
            ))
          }
        >
          <Loading
            loading={commercials.loading || !commercials.data}
            type="boxLoader"
            text={polyglot.t('commercials.wait_commercials_loading')}
          >
            {!commercials.data && (
              <Space after="xl">
                <br />
              </Space>
            )}
            <Badge type="info" size="small" icon={<InformationCircleIcon />}>
              {polyglot.t('commercials.in_time_zone', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })}
            </Badge>
            {commercials.data && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{polyglot.t('common.name')}</TableCell>
                      <TableCell align="left">
                        <TouchableIconContainer onClick={() => handleSort('validFrom')}>
                          {polyglot.t('commercials.valid_from')}
                          {sort.key === 'validFrom' && <SortArrow ascending={sort.dir === 'asc'} />}
                        </TouchableIconContainer>
                      </TableCell>
                      <TableCell align="left">
                        <TouchableIconContainer onClick={() => handleSort('validTo')}>
                          {polyglot.t('commercials.valid_to')}
                          {sort.key === 'validTo' && <SortArrow ascending={sort.dir === 'asc'} />}
                        </TouchableIconContainer>
                      </TableCell>
                      <TableCell align="left">{polyglot.t('offers.offer')}</TableCell>
                      <TableCell align="left">{polyglot.t('commercials.content_type')}</TableCell>
                      <TableCell align="left" />
                      <TableCell align="left" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commercials.data.map((commercial, id) => {
                      if (!isAdminOrACM && commercial.isValid === false) {
                        return null;
                      }
                      return (
                        <TableRow key={id} dataTest={`commercials-table-commercial-${id}`}>
                          <TableCell align="left">{commercial.name}</TableCell>
                          <TableCell align="left">{commercial.validFrom}</TableCell>
                          <TableCell align="left">{commercial.validTo}</TableCell>
                          {commercial.offer.type ? (
                            <TableCell align="left">
                              {polyglot.t(OFFERS_I18N[commercial.offer.type])}:{' '}
                              {commercial.offer.value}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {commercial.offers.map(offer => (
                                <>
                                  {polyglot.t(OFFERS_I18N[offer.type])}: {offer.value}
                                  <br />
                                </>
                              ))}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {
                              CONTENT_TYPES.find(type => type.value === commercial.contentType)
                                .label
                            }
                          </TableCell>

                          <TableCell align="left">
                            <Authorization
                              resource="model.commission"
                              action={['update', 'update_assigned', 'update_own']}
                            >
                              <TouchableIconContainer
                                data-test="commercials-edit-commercial-button"
                                onClick={() => setEditCommercialDraft(commercial)}
                              >
                                <EditIcon size="small" customColor="#f9e4a1" />
                              </TouchableIconContainer>
                            </Authorization>
                          </TableCell>
                          {commercial.isValid ? (
                            <TableCell>
                              <Authorization
                                resource="model.commission"
                                action={['delete', 'delete_assigned']}
                              >
                                <TouchableIconContainer
                                  data-test="commercials-disable-commercial-button"
                                  onClick={() => {
                                    setDisableCommercialDraft(commercial);
                                  }}
                                >
                                  <RemoveIcon size="small" customColor="#d21c1c" />
                                </TouchableIconContainer>
                              </Authorization>
                            </TableCell>
                          ) : (
                            isAdminOrACM && (
                              <TableCell>
                                <TouchableIconContainer
                                  data-test="commercials-enable-commercial-button"
                                  onClick={() => {
                                    setEnableCommercialDraft(commercial);
                                  }}
                                >
                                  <CheckIcon size="small" customColor="#00a991" />
                                </TouchableIconContainer>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {pagination.totalCount > 0 && (
                  <Pagination
                    rows={pagination.totalCount}
                    rowsPerPage={pagination.perPage}
                    page={pagination.page}
                    rowsPerPageItems={pagination.pageSizes}
                    onPagination={handlePagination}
                  />
                )}
              </>
            )}
          </Loading>
        </CardSection>
      </Card>
    </Authorization>
  );
};

export default Commercials;
