import React, { useMemo } from 'react';
import { Stack, Badge, Tooltip, Text, Heading, TextLink } from '@kiwicom/orbit-components/lib';
import ReloadIcon from '@kiwicom/orbit-components/lib/icons/Reload';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';

import { Flex } from 'common';
import { BAGS } from 'consts/mmb';
import { getCurrentPaxBags } from 'components/scenes/ManageMyBookings/scenes/BookingDetails/services/utils';
import { usePolyglot } from 'components/services/i18n';

import { Section, Actions, Wrapper } from './index.styled';
import differenceInDays from 'date-fns/differenceInDays';

const BagRow = ({ item, dataTest }) => {
  const polyglot = usePolyglot();

  return (
    <Stack direction="row" spaceAfter="medium" justify="between" align="center" dataTest={dataTest}>
      <Flex column main="spaceBetween">
        <Text dataTest="bag-count">
          {item.count}x {BAGS[item.data.bag.category]}
        </Text>
        <Text type="secondary" size="small" dataTest="bag-dimensions">
          {`${item.data.bag.length} × ${item.data.bag.width} × ${item.data.bag.height} cm, ${item.data.bag.weight} kg`}
        </Text>
      </Flex>
      {item.orderStatus === 'processing' && (
        <Badge icon={<ReloadIcon />} size="small" type="info" dataTest="processing-bag-badge">
          {polyglot.t('mmb.booking_details.boarding_passes.processing')}
        </Badge>
      )}
    </Stack>
  );
};

const RenderBags = ({ currentPaxBags, status }) => {
  const polyglot = usePolyglot();
  if (!currentPaxBags.length) {
    return (
      <Text dataTest="no-bags">{polyglot.t('mmb.booking_details.boarding_passes.no_baggage')}</Text>
    );
  }

  return (
    <div>
      {status === 'processing' && (
        <Wrapper>
          <Badge icon={<ReloadIcon />} size="small" type="info" dataTest="processing-booking-badge">
            {polyglot.t('mmb.booking_details.passengers.processing_booking')}
          </Badge>
          <Tooltip
            preferredPosition="top"
            content={polyglot.t('mmb.booking_details.passengers.cant_add_baggage')}
          >
            <InformationCircleIcon color="info" />
          </Tooltip>
        </Wrapper>
      )}
      {currentPaxBags.map((bag, i) => (
        <BagRow key={i} item={bag} dataTest={`bag-row-${i}`} />
      ))}
    </div>
  );
};

const BaggageInfo = ({
  bags,
  passengerId,
  ancillaries,
  status,
  refund,
  handleBagsAdd,
  departureTime,
}) => {
  const currentPaxBags = getCurrentPaxBags(bags, passengerId, ancillaries);
  const polyglot = usePolyglot();

  const isTooLateToOrder = useMemo(() => {
    return differenceInDays(new Date(departureTime), new Date()) < 1;
  }, [departureTime]);

  return (
    <Section border>
      <Stack direction="column" spaceAfter="large" spacing="medium" shrink>
        <Heading type="title3">
          {polyglot.t('mmb.booking_details.passengers.baggage.title')}
        </Heading>
        <RenderBags currentPaxBags={currentPaxBags} status={status} />
      </Stack>
      <Actions>
        {status === 'confirmed' &&
          !refund &&
          !isTooLateToOrder && ( // Baggage can be added only if booking is confirmed, not in refund and it is not less than 24 hours before departure
            <TextLink
              size="small"
              onClick={handleBagsAdd}
              type="primary"
              dataTest="add-bags"
              iconLeft={<PlusIcon />}
            >
              {polyglot.t('mmb.booking_details.passengers.add_baggage')}
            </TextLink>
          )}
      </Actions>
    </Section>
  );
};

export default BaggageInfo;
