import React, { useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as R from 'ramda';

import DownloadIcon from '@kiwicom/orbit-components/lib/icons/Download';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import { formatInterval } from 'utils/dates';
import { DAY } from 'consts/dates';
import useCompanyBillingCycles from 'components/services/billingCycles/useCompanyBillingCycles';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import { Authorization } from 'common/Authorization';
import { Card, CardActionHeader, CardSection } from 'components/common/Card';
import { BillingCyclesState } from 'components/services/billingCycles';

import BillingCyclesDeleteModal from './components/BillingCyclesDeleteModal';
import BillingCyclesUploadModal from './components/BillingCyclesUploadModal';
import { Container, FooterContainer, ShowMoreContainer } from './BillingCycles.styled';

const BillingCycles = () => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const billingCycles = useCompanyBillingCycles(companyName);
  const billingCyclesUploadModal = useToggle();
  const successUploadAlert = useToggle();
  const successDeleteAlert = useToggle();
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(null);
  const {
    state: { downloading },
    loadCompanyBillingCycles,
    downloadBillingCycle,
  } = useContext(BillingCyclesState);

  const showMore = () => {
    const nextPerPage = 2 * billingCycles.pagination.perPage;
    loadCompanyBillingCycles(companyName, 1, nextPerPage);
  };

  const handleSuccessUploadModal = () => {
    billingCyclesUploadModal.setOff();
    successUploadAlert.setOn();
    loadCompanyBillingCycles(companyName);
  };

  const handleSuccessDeleteModal = () => {
    setSelectedBillingCycle(null);
    successDeleteAlert.setOn();
    loadCompanyBillingCycles(companyName);
  };

  const getAlert = () => {
    let alerts = [];
    if (successUploadAlert.isOn) {
      alerts.push(
        <Alert key="uploadSuccess" type="success" closable icon onClose={successUploadAlert.setOff}>
          Billing cycle successfully uploaded
        </Alert>,
      );
    }
    if (successDeleteAlert.isOn) {
      alerts.push(
        <Alert key="deleteSuccess" type="success" closable icon onClose={successDeleteAlert.setOff}>
          Billing cycle successfully deleted
        </Alert>,
      );
    }
    return alerts;
  };

  return (
    <Container>
      {selectedBillingCycle && (
        <BillingCyclesDeleteModal
          onClose={() => setSelectedBillingCycle(null)}
          onSuccess={handleSuccessDeleteModal}
          billingCycle={selectedBillingCycle}
        />
      )}
      {billingCyclesUploadModal.isOn && (
        <BillingCyclesUploadModal
          onClose={billingCyclesUploadModal.setOff}
          onSuccess={handleSuccessUploadModal}
        />
      )}
      <Card loading={billingCycles.loading} loadingText="Loading billing cycles...">
        {billingCycles.data && (
          <>
            <CardActionHeader
              title="Billing cycle"
              action={
                <Authorization
                  resource="model.billing_cycle"
                  action={['create', 'create_assigned', 'create_own']}
                >
                  <Button
                    dataTest="upload-billing-cycle-button"
                    size="small"
                    type="primary"
                    onClick={billingCyclesUploadModal.setOn}
                  >
                    {polyglot.t('company.upload_billing_cycle')}
                  </Button>
                </Authorization>
              }
            />
            <CardSection
              showAlert={successUploadAlert.isOn || successDeleteAlert.isOn}
              alert={getAlert()}
            >
              {!R.isEmpty(billingCycles.data) && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {polyglot.t('company.billing_period_start')}
                      </TableCell>
                      <TableCell align="left">{polyglot.t('company.billing_period_end')}</TableCell>
                      <TableCell align="left">
                        {polyglot.t('company.billing_cycle.amount')}
                      </TableCell>
                      <TableCell />
                      <Authorization
                        resource="model.billing_cycle"
                        action={['delete', 'delete_assigned', 'delete_own']}
                      >
                        <TableCell />
                      </Authorization>
                    </TableRow>
                  </TableHead>
                  {billingCycles.data.map(cycle => {
                    const downloadingPDF = downloading === `${cycle.id}_pdf`;
                    const downloadingCSV = downloading === `${cycle.id}_csv`;
                    const downloadingOther = downloading && (!downloadingPDF || !downloadingCSV);

                    return (
                      <TableBody key={cycle.id}>
                        <TableRow>
                          <TableCell align="left">
                            {formatInterval(cycle.periodStart, DAY)}
                          </TableCell>
                          <TableCell align="left">{formatInterval(cycle.periodEnd, DAY)}</TableCell>
                          <TableCell align="left">{`${cycle.amount} ${cycle.currency}`}</TableCell>
                          <TableCell>
                            <Stack direction="row" justify="end">
                              <Button
                                dataTest="download-pdf-button"
                                size="small"
                                iconLeft={<DownloadIcon />}
                                onClick={() => {
                                  downloadBillingCycle(companyName, cycle, 'pdf');
                                }}
                                loading={downloadingPDF}
                                disabled={downloadingOther || !cycle.pdfReady}
                              >
                                {polyglot.t('common.pdf')}
                              </Button>
                              <Button
                                dataTest="download-csv-button"
                                size="small"
                                iconLeft={<DownloadIcon />}
                                onClick={() => {
                                  downloadBillingCycle(companyName, cycle, 'csv');
                                }}
                                loading={downloadingCSV}
                                disabled={downloadingOther || !cycle.csvReady}
                              >
                                {polyglot.t('common.csv')}
                              </Button>
                            </Stack>
                          </TableCell>
                          <Authorization
                            resource="model.billing_cycle"
                            action={['delete', 'delete_assigned', 'delete_own']}
                          >
                            <TableCell>
                              <Button
                                dataTest="remove-billing-cycle-button"
                                type="critical"
                                size="small"
                                loading={false}
                                iconLeft={<RemoveIcon />}
                                onClick={() => setSelectedBillingCycle(cycle)}
                              />
                            </TableCell>
                          </Authorization>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              )}
              <FooterContainer>
                <Text weight="bold">{polyglot.t('company.billing_cycle_begin')}</Text>
                {R.isEmpty(billingCycles.data) && (
                  <Text weight="bold">{polyglot.t('company.billing_cycle_no_payments')}</Text>
                )}
              </FooterContainer>
              {billingCycles.pagination.pagesCount > 1 && (
                <ShowMoreContainer>
                  <Button
                    dataTest="show-more-button"
                    size="small"
                    onClick={showMore}
                    iconRight={<ChevronForwardIcon />}
                  >
                    {polyglot.t('common.show_more')}
                  </Button>
                </ShowMoreContainer>
              )}
            </CardSection>
          </>
        )}
      </Card>
    </Container>
  );
};

export default BillingCycles;
