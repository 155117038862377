export const mappedArticleKeys = {
  last_update: 'lastUpdate',
  content_tree: 'contentTree',
  related_to_product: 'relatedToProduct',
  orbit_illustration_name: 'orbitIllustrationName',
  use_multi_column_layout: 'useMultiColumnLayout',
};

export const mappedSwaggerDocumentationKeys = {
  content_tree: 'contentTree',
  requires_booking_token: 'requiresBookingToken',
};

export const projectId = '8db67b07-378d-0094-1c6c-2039a1ea38e2';
export const previewApiKey =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0OTVlOTcyMzk0OWM0NTFlYjVmODdjZTZmN2ZiMWMxMSIsImlhdCI6IjE1MzQ5MjU1MjIiLCJleHAiOiIxODgwNTI1NTIyIiwicHJvamVjdF9pZCI6IjhkYjY3YjA3Mzc4ZDAwOTQxYzZjMjAzOWExZWEzOGUyIiwidmVyIjoiMS4wLjAiLCJhdWQiOiJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSJ9.deLFOFnACxo8BHTu3lYgEWQFKaC-rgDqfcCHGzamnzU';

export const TAXONOMY_CODENAMES = {
  GUIDES_CONTENT_TREE: 'guides_content_tree',
  API_REFERENCE: 'api_reference',
  USER_GUIDES: 'user_guides',
};

export const CONTENT_TYPES = {
  QUICK_GUIDES: {
    single: 'quick_guides',
  },
  SWAGGER_DOCUMENTATION: {
    single: 'swagger_documentation',
  },
  LEGAL: {
    single: 'legal',
  },
  ARTICLE: {
    single: 'article',
    multiple: 'articles',
  },
  UPSELL_CARD: {
    single: 'upsell_card',
  },
  NEWS_ARTICLE: {
    single: 'news_article',
  },
  NEWS_API_ARTICLE: {
    single: 'news_api_article',
  },
};

export const csEmail = 'customer-support@kiwi.com';

export const resourcesCodenames = {
  TERMS_CONDITIONS_CODENAME: 'tequila_terms___conditions',
  PRIVACY_POLICY_CODENAME: 'privacy_policy',
  BOOK_WITH_KIWI_CODENAME: 'book_with_kiwi_com_terms___conditions',
  AFFILIATE_PROGRAM_CODENAME: 'affiliate_program_terms___conditions',
  CUSTOMER_SUPPORT_GUIDE: 'customer_support_guide',
  CUSTOMER_SUPPORT: 'customer_support',
};

export const csEnabledCodenames = ['customer_support_guide', 'customer_support'];

export const UPSELL_CARD_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const CODE_SNIPPET_ONELINER_CLASS = 'code-snippet-oneliner';

export const CODE_SNIPPET_MULTILINE_CLASS = 'code-snippet-multiline';
