import { combineReducers } from 'redux';

import { requestsAdapter } from 'common/Support/utils/jira';
import { SERVICE_DESK } from 'consts/support';

import { createReducer, createRequest, clear } from './requests';

const REQUESTS_SCHEMA = `${SERVICE_DESK.CSSD}/requests`;

export const fetchRequests = createRequest(REQUESTS_SCHEMA, 'GET');

export const clearRequests = () => dispatch => dispatch(clear(REQUESTS_SCHEMA));

const REQUEST_CATEGORIES_SCHEMA = `${SERVICE_DESK.CSSD}/requestsCategories`;

export const fetchRequestCategories = createRequest(REQUEST_CATEGORIES_SCHEMA, 'GET');

export const addUserToJiraOrganization = createRequest(`${SERVICE_DESK.CSSD}/customer`, 'POST');

export default combineReducers({
  customer: createReducer(`${SERVICE_DESK.CSSD}/customer`),
  requests: createReducer(REQUESTS_SCHEMA, requestsAdapter),
  requestsCategories: createReducer(REQUEST_CATEGORIES_SCHEMA),
});
