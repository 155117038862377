import React, { useEffect } from 'react';
import styled from 'styled-components';

import * as Intercom from 'utils/intercom';

import Filters from './components/Filters';
import Table from './components/Table';
import AuditLogsProvider from './services/AuditLogsProvider';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  border-radius: 3px;
`;

const AuditLogs = () => {
  useEffect(() => {
    Intercom.trackEvent('open-audit-logs');
  }, []);

  return (
    <AuditLogsProvider>
      <Container>
        <Filters />
        <Table />
      </Container>
    </AuditLogsProvider>
  );
};

export default AuditLogs;
