import React from 'react';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { CardLoadingContainer } from './common.styled';

const CardLoader = ({ loadingText }) => {
  return (
    <CardLoadingContainer>
      <Loading type="boxLoader" text={loadingText} />
    </CardLoadingContainer>
  );
};

export default CardLoader;
