import PropTypes from 'prop-types';
import { values } from 'ramda';
import { ROLES } from 'common/Authorization/policy';

export const Role = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  name: PropTypes.oneOf(values(ROLES)).isRequired,
};

const User = {
  uuid: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.string),
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  //role: PropTypes.arrayOf(PropTypes.shape(Role)),
};

export default User;

// examples
export const emptyUser = {
  developerId: '',
  firstName: '',
  lastName: '',
  email: '',
  role: [],
  accounts: [],
};

const baseUser = {
  email: 'test@test.com',
  developerId: '1',
  firstName: 'test',
  lastName: 'test',
  accounts: [],
};
const baseRole = {
  id: 1,
};

export const admin = {
  ...baseUser,
  role: [{ ...baseRole, name: ROLES.ADMIN }],
  accounts: [],
};

export const manager = {
  ...baseUser,
  role: [{ ...baseRole, name: ROLES.MANAGER }],
  accounts: [],
};

export const partner = {
  ...baseUser,
  role: [{ ...baseRole, name: ROLES.PARTNER }],
  // in apiary the accounts property should be object[], however it returns string[] ¯\_(ツ)_/¯
  accounts: ['kayak'],
};

export const user = {
  ...baseUser,
  role: [{ ...baseRole, name: ROLES.PARTNER }],
};
