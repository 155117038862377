import { useRef, useEffect } from 'react';

// https://medium.com/swlh/prevent-useeffects-callback-firing-during-initial-render-the-armchair-critic-f71bc0e03536
const useDependencyEffect = (effect, dependencies) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns = () => {};
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useDependencyEffect;
