import React from 'react';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import styled from 'styled-components';

import { usePolyglot } from 'components/services/i18n';

const PromptModal = ({
  title,
  description,
  loading,
  buttonColor,
  submitText,
  onSubmit,
  onClose,
}) => {
  const polyglot = usePolyglot();

  const FooterWrapper = styled.div`
    margin-top: 40px;
  `;

  return (
    <Portal>
      <Modal onClose={onClose}>
        <ModalHeader title={title} description={description} />
        <FooterWrapper>
          <ModalFooter>
            <Button type="secondary" onClick={onClose}>
              {polyglot.t('common.cancel')}
            </Button>
            <Button type={buttonColor || 'primary'} loading={loading} onClick={onSubmit}>
              {submitText || polyglot.t('common.submit')}
            </Button>
          </ModalFooter>
        </FooterWrapper>
      </Modal>
    </Portal>
  );
};

export default PromptModal;
