import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Tag from '@kiwicom/orbit-components/lib/Tag';

import { usePolyglot } from 'components/services/i18n';
import { SmartPassContext } from 'components/services/smartPass';
import { keys, MAX_NUMBER_OF_VOUCHERS, yesNoValues } from 'consts/smartPass';
import { LabelsRowWrapper } from '../index.styled';
import { StackDiv } from '../index.styled';
import { getPreviewType } from '../Modals/VoucherPreviewModal/utils/adapters.js';

const {
  VOUCHER_NAME,
  VOUCHER_TYPE,
  VOUCHERS,
  VOUCHERS_REPORTING_ACTIVATED,
  VOUCHERS_REPORTING_EMAILS_LIST,
  VOUCHERS_REPORTING_EMAILS_NEW_VALUE,
} = keys;

const VoucherNameWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 240px;
`;

const MoveUp = styled.div`
  margin-top: -10px;
`;

const VouchersList = ({
  values,
  showVoucherScreen,
  remove,
  disabled,
  handleChange,
  setFieldValue,
  errors,
  touched,
}) => {
  const polyglot = usePolyglot();
  const { showVoucherPreviewModal, showDeleteVoucherModal } = useContext(SmartPassContext);

  const vouchers = values[VOUCHERS];
  const isMaxNumber = vouchers.length >= MAX_NUMBER_OF_VOUCHERS;

  const handleAddToList = (push, newValueName) => async event => {
    if (!values[newValueName].replace(/\s/g, '')) {
      return;
    }
    if (event.keyCode === 13) {
      await push(values[newValueName]);
      setFieldValue(newValueName, '');
    }
  };

  return (
    <>
      {vouchers.length === 0 ? (
        <Text spaceAfter="large" type="secondary">
          {polyglot.t('smart_pass.form.vouchers.no_vouchers')}
        </Text>
      ) : (
        <MoveUp>
          {vouchers.map((voucher, index) => (
            <Fragment key={voucher.id}>
              <Stack direction="row" align="center" spaceAfter="normal">
                <StackDiv>
                  <Text type="secondary">
                    {polyglot.t('smart_pass.form.vouchers.voucher_name')}
                  </Text>
                  <VoucherNameWrapper>
                    {voucher[VOUCHER_NAME] || polyglot.t('common.unknown')}
                  </VoucherNameWrapper>
                </StackDiv>
                <StackDiv>
                  <Text type="secondary">
                    {polyglot.t('smart_pass.form.vouchers.voucher_type')}
                  </Text>
                  <Text>
                    {voucher[VOUCHER_TYPE]
                      ? polyglot.t(`smart_pass.form.vouchers.voucher_type.${voucher[VOUCHER_TYPE]}`)
                      : polyglot.t('common.unknown')}
                  </Text>
                </StackDiv>
                <StackDiv>
                  <Stack direction="row" justify="end" align="center" spacing="XSmall">
                    <Button
                      size="small"
                      disabled={disabled}
                      type="criticalSubtle"
                      iconLeft={<RemoveIcon />}
                      onClick={() =>
                        showDeleteVoucherModal({ remove, index, name: voucher[VOUCHER_NAME] })
                      }
                    />

                    <Button
                      size="small"
                      type="secondary"
                      disabled={disabled}
                      onClick={() =>
                        showVoucherPreviewModal({
                          voucherInfo: voucher,
                          smartPassInfo: values,
                          previewType: getPreviewType(voucher),
                        })
                      }
                    >
                      {polyglot.t('common.preview')}
                    </Button>
                    <Button
                      disabled={disabled}
                      size="small"
                      type="secondary"
                      onClick={() => showVoucherScreen(voucher)}
                    >
                      {polyglot.t('common.edit')}
                    </Button>
                  </Stack>
                </StackDiv>
              </Stack>
              {index !== vouchers.length - 1 && <Separator spaceAfter="normal" />}
            </Fragment>
          ))}
        </MoveUp>
      )}
      {values[keys.VOUCHERS].length > 0 && (
        <>
          <Separator spaceAfter="large" />
          <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers_reporting')}</Text>
          <Text spaceAfter="large" type="secondary">
            {polyglot.t('smart_pass.form.vouchers_reporting_descr')}
          </Text>
          <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers_receive_reporting')}</Text>
          <Stack spaceAfter="large">
            <LabelsRowWrapper>
              <Radio
                label={polyglot.t('common.yes')}
                name={VOUCHERS_REPORTING_ACTIVATED}
                value={yesNoValues.YES}
                disabled={disabled}
                checked={values[VOUCHERS_REPORTING_ACTIVATED] === yesNoValues.YES}
                onChange={handleChange}
                hasError={
                  touched[VOUCHERS_REPORTING_ACTIVATED] && errors[VOUCHERS_REPORTING_ACTIVATED]
                }
              />
              <FieldArray name={VOUCHERS_REPORTING_EMAILS_LIST}>
                {({ remove: removeEmail, push }) => (
                  <InputField
                    disabled={values[VOUCHERS_REPORTING_ACTIVATED] !== yesNoValues.YES}
                    name={VOUCHERS_REPORTING_EMAILS_NEW_VALUE}
                    value={values[VOUCHERS_REPORTING_EMAILS_NEW_VALUE]}
                    error={
                      errors[VOUCHERS_REPORTING_EMAILS_LIST] &&
                      polyglot.t(errors[VOUCHERS_REPORTING_EMAILS_LIST])
                    }
                    inlineLabel
                    label={polyglot.t('smart_pass.form.vouchers_receive_reporting_label')}
                    onChange={handleChange}
                    onKeyUp={handleAddToList(push, VOUCHERS_REPORTING_EMAILS_NEW_VALUE)}
                    tags={
                      values[VOUCHERS_REPORTING_EMAILS_LIST].length > 0
                        ? values[VOUCHERS_REPORTING_EMAILS_LIST].map((tag, index) => (
                            <Tag key={index} selected onRemove={() => removeEmail(index)}>
                              {tag}
                            </Tag>
                          ))
                        : undefined
                    }
                  />
                )}
              </FieldArray>
            </LabelsRowWrapper>
            <Radio
              label={polyglot.t('common.no')}
              name={VOUCHERS_REPORTING_ACTIVATED}
              value={yesNoValues.NO}
              disabled={disabled}
              checked={values[VOUCHERS_REPORTING_ACTIVATED] === yesNoValues.NO}
              onChange={handleChange}
              hasError={
                touched[VOUCHERS_REPORTING_ACTIVATED] && errors[VOUCHERS_REPORTING_ACTIVATED]
              }
            />
          </Stack>
        </>
      )}
      <Separator spaceAfter="medium" />
      <Stack direction="row" justify="end">
        <Tooltip
          content={polyglot.t('smart_pass.form.vouchers.max_vouchers', {
            maxNumber: MAX_NUMBER_OF_VOUCHERS,
          })}
          enabled={isMaxNumber}
        >
          <Button
            disabled={disabled || isMaxNumber}
            onClick={() => showVoucherScreen()}
            size="small"
          >
            {polyglot.t('smart_pass.form.vouchers.button_text')}
          </Button>
        </Tooltip>
      </Stack>
    </>
  );
};

export default VouchersList;
