import { ContentItem } from 'kentico-cloud-delivery/_bundles/kentico-cloud-delivery-sdk.browser.umd.min';
import * as R from 'ramda';

import { ROUTE_CONFIG } from 'consts/routes';
import { mapArticleFields } from 'utils/kentico';

class Article extends ContentItem {
  compiledHtml = null;

  searchableCategory = 'User guide';

  constructor() {
    super({
      propertyResolver: mapArticleFields,
    });
  }

  get compiledContent() {
    if (!this.compiledHtml) {
      this.compiledHtml = this.content.getHtml();
    }
    return this.compiledHtml;
  }

  getTitle() {
    return this.title.value;
  }

  get multiColumn() {
    // optional field on Article content type
    const { useMultiColumnLayout } = this;
    return R.path(['options', 0, 'codename'], useMultiColumnLayout) === 'yes';
  }

  get illustrationName() {
    // optional field on Article content type
    const { orbitIllustrationName } = this;
    if (orbitIllustrationName) {
      return orbitIllustrationName.value;
    }
    return null;
  }

  get searchableTitle() {
    return this.title.value;
  }

  get searchableContent() {
    return this.content.value;
  }

  get isSearchable() {
    return this.user_guides.value.length > 0;
  }

  get taxonomyCodename() {
    if (this.isSearchable) {
      return this.user_guides.value[0].codename;
    }
    return null;
  }

  get toPath() {
    return `${ROUTE_CONFIG.DOCS_USER_GUIDES_SINGLE.toPath}/${this.system.codename}`;
  }
}

export default Article;
