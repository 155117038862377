import PropTypes from 'prop-types';

const HttpResponse = PropTypes.shape({
  isClear: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  isError: PropTypes.func.isRequired,
});

export default HttpResponse;
