import { createSelector } from 'reselect';
import { MESSAGE_TYPES } from 'consts/messages';

const getMessages = state => state.messages.items;

export const getSuccessMessages = createSelector(getMessages, messages =>
  messages.filter(message => message.type === MESSAGE_TYPES.SUCCESS),
);

export const getSubmitError = createSelector(getMessages, messages =>
  messages.filter(message => message.type === MESSAGE_TYPES.ERROR),
);
