import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { UTM_KEYS } from 'consts/url';
import { setCookie } from 'utils/cookie';

const UTMProcessor = ({ location: { search } }) => {
  useEffect(() => {
    search
      .replace('?', '')
      .split('&')
      .map(e => e.split('='))
      .forEach(([key, value]) => {
        if (UTM_KEYS[key]) {
          setCookie(key, value);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(UTMProcessor);
