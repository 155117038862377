const v2_post_to = params => {
  return {
    booking_id: params.booking_id,
    transaction_id: params.transaction_id,
  };
};

export default {
  v2: {
    post: {
      to: v2_post_to,
    },
  },
};
