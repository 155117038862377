import styled from 'styled-components';
import PropTypes from 'prop-types';

const getAlignValue = value => {
  switch (value) {
    case 'start':
      return 'flex-start';
    case 'end':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'spaceBetween':
      return 'space-between';
    default:
      return null;
  }
};

const Flex = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ main }) => main && `justify-content: ${getAlignValue(main)};`};
  ${({ cross }) => cross && `align-items: ${getAlignValue(cross)};`};
  ${({ self }) => self && `align-self: ${getAlignValue(self)};`};
`;

Flex.propTypes = {
  column: PropTypes.bool,
  main: PropTypes.oneOf(['start', 'center', 'end', 'spaceBetween']),
  cross: PropTypes.oneOf(['start', 'center', 'end', 'spaceBetween']),
  self: PropTypes.oneOf(['start', 'center', 'end']),
};

export default Flex;
