import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FakeInput = styled.input`
  width: 100%;
  height: 150%;
  color: inherit;
  background-color: transparent;
  overflow: auto;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  border: none;
`;

const FakeCell = ({ children }) => {
  return <FakeInput readOnly disabled value={children} />;
};

FakeCell.propTypes = {
  children: PropTypes.string,
};

export default FakeCell;
