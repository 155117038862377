import * as P from 'parsimmon';
import * as R from 'ramda';

import { monthsAbbreviationValues } from 'consts/dates';
import { tripTypesValues, passengerTypes } from 'consts/search';
import { optionsParser } from './common';

const prefixParser = P.regexp(/(^FD)/, 1).desc('Fare Display command prefix');

const dayParser = P.regexp(/[0-9]{1,2}/)
  .map(Number)
  .desc('Day of the month');

const monthParser = P.alt(...Object.keys(monthsAbbreviationValues).map(P.string)).desc(
  'Three letter month',
);

const locationParser = P.regexp(/[A-Z]{3}/).desc('Three letter location');

const carrierParser = P.regexp(/\.?([A-Z]{2})/, 1);
const carriersParser = P.string('/').then(carrierParser.atMost(3));

const tripTypeParser = P.regexp(/-(OW|RT)/, 1);

const passengersParser = P.regexp(/\*(ADT|CNN|(?:C0[0-9])|INF)/, 1).map(r => {
  const ps = {
    [passengerTypes.ADULTS]: 0,
    [passengerTypes.CHILDREN]: 0,
    [passengerTypes.INFANTS]: 0,
  };

  if (r.match(/ADT/)) {
    ps[passengerTypes.ADULTS] = 1;
  } else if (r.match(/(CNN|(?:C0[0-9]))/)) {
    ps[passengerTypes.CHILDREN] = 1;
  } else if (r.match(/INF/)) {
    ps[passengerTypes.INFANTS] = 1;
  }

  return ps;
});

const classTypeParser = P.regexp(/@([A-Z])/, 1);

const optionsParsersMap = {
  carriers: { expression: /\/(?:\.?[A-Z]{2})*/, entries: carriersParser },
  classType: { expression: /@[A-Z](?![A-Z])/, entries: classTypeParser },
  tripType: { expression: /-(OW|RT(?!W))/, entries: tripTypeParser },
  passengers: {
    expression: /\*[A-Z](?:[A-Z]|[0-9]){2}/,
    entries: passengersParser,
  },
};

const fareDisplay = P.seqObj(
  ['prefix', prefixParser],
  ['day', dayParser],
  ['month', monthParser],
  ['from', locationParser],
  ['to', locationParser],
  ['options', optionsParser(optionsParsersMap)],
)
  .map(r => {
    const {
      options: { _remainder, tripType, passengers, ...otherOptions },
      ...rest
    } = r;
    return {
      ...rest,
      passengers: passengers || null,
      options: otherOptions,
      tripType: tripType === 'RT' ? tripTypesValues.RETURN : tripTypesValues.ONEWAY,
      unparsed: [_remainder].filter(Boolean || !R.isEmpty),
    };
  })
  .desc('Fare Display one way');

const fareDisplayReturn = P.seqObj(
  ['prefix', prefixParser],
  ['from', locationParser],
  ['to', locationParser],
  P.string('V'),
  ['day', dayParser],
  ['month', monthParser],
  ['returnDay', dayParser],
  ['returnMonth', monthParser],
  ['options', optionsParser(optionsParsersMap)],
)
  .map(r => {
    const {
      options: { _remainder, passengers, ...otherOptions },
      ...rest
    } = r;
    return {
      ...rest,
      passengers: passengers || null,
      options: otherOptions,
      tripType: tripTypesValues.RETURN,
      unparsed: [_remainder].filter(Boolean || !R.isEmpty),
    };
  })
  .desc('Fare Display return');

export default P.alt(fareDisplayReturn, fareDisplay);
