import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import DeviceDesktop from '@kiwicom/orbit-components/lib/icons/DeviceDesktop';
import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';

import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';
import { CompanyState } from 'components/services/company';
import { SpecialAppsContext } from 'components/services/specialApps';
import useCurrentCompanyDetails from 'components/services/company/useCurrentCompanyDetails';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';
import useSpecialAppsDefinitions from 'components/services/specialApps/useSpecialAppsDefinitions';
import { Card, CardData, CardHeader, CardActionHeader, CardSection } from 'components/common/Card';
import { Authorization } from 'common/Authorization';
import { Flex } from 'common';

import DemandDashboardModal from './components/CustomerDemandModal';
import DestinationDemandModal from './components/DestinationDemandModal';
import SpecialAppDatesModal from './components/SpecialAppDatesModal';

const Container = styled.div`
  max-width: 714px;
  margin-top: 24px;
  margin-left: 24px;
`;

export const ActionsMenu = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Configuration = () => {
  const polyglot = usePolyglot();
  const demandDashboardEditModal = useToggle();
  const destinationDemandDashboardEditModal = useToggle();
  const dataMarketplaceModal = useToggle();
  const reportSchedulesModal = useToggle();
  const companyDetails = useCurrentCompanyDetails();
  const { companyName } = useRouteMatch().params;
  const specialApps = useSpecialApps(companyName);
  const specialAppsDefinitions = useSpecialAppsDefinitions();
  const { updateCompanyDetails } = useContext(CompanyState);
  const { deleteSpecialApp, updateSpecialApp, createSpecialApp } = useContext(SpecialAppsContext);

  const destinationDemandDashboardApp = specialApps.apps?.find(
    app => app.name === 'destination-demand-dashboard',
  );

  const handleUpdateCustomerSupport = () => {
    let customerSupport = companyDetails.data.customerSupportEnabled;
    customerSupport = !customerSupport;
    updateCompanyDetails(companyName, { customerSupportEnabled: customerSupport });
  };

  const submit = (action, app, appDefinition) => async () => {
    if (action === 'delete') {
      await deleteSpecialApp(companyName, app);
    }
    if (action === 'update') {
      await updateSpecialApp(companyName, app, {});
    }
    if (action === 'create') {
      await createSpecialApp(companyName, appDefinition, {});
    }
  };

  const renderBidviewerIncludePassword = (apps, definitions) => {
    const appDefinition = definitions.find(application => application.name === 'bidviewer');
    const app = apps.find(application => application.definitionId === appDefinition.id);
    const appActive = app && !app.deleted;

    return (
      <Flex main="spaceBetween">
        <div>Include Password</div>
        <Flex>
          <Checkbox
            disabled={!appActive}
            checked={app?.config?.includePassword}
            onChange={async e => {
              await updateSpecialApp(companyName, app, { includePassword: e.target.checked });
            }}
          />
        </Flex>
      </Flex>
    );
  };

  const renderButton = (appName, apps, definitions) => {
    const appDefinition = definitions.find(application => application.name === appName);
    const app = apps.find(application => application.definitionId === appDefinition.id);
    const appActive = app && !app.deleted;

    return (
      <ActionsMenu>
        <>
          {appActive && (
            <Button
              dataTest="remove-button"
              size="small"
              onClick={submit('delete', app)}
              type="critical"
            >
              {polyglot.t('special_apps.remove_app')}
            </Button>
          )}
          {!!app && !appActive && (
            <Button dataTest="add-button" size="small" onClick={submit('update', app)}>
              {polyglot.t('special_apps.add_app')}
            </Button>
          )}
          {!app && (
            <Button
              dataTest="add-button"
              size="small"
              onClick={submit('create', {}, appDefinition)}
            >
              {polyglot.t('special_apps.add_app')}
            </Button>
          )}
        </>
      </ActionsMenu>
    );
  };

  return (
    <>
      {demandDashboardEditModal.isOn && (
        <DemandDashboardModal
          specialApps={specialApps}
          specialAppsDefinitions={specialAppsDefinitions}
          onClose={demandDashboardEditModal.setOff}
        />
      )}
      {destinationDemandDashboardEditModal.isOn && (
        <DestinationDemandModal
          specialApps={specialApps}
          specialAppsDefinitions={specialAppsDefinitions}
          onClose={destinationDemandDashboardEditModal.setOff}
        />
      )}
      {dataMarketplaceModal.isOn && (
        <SpecialAppDatesModal
          specialApps={specialApps}
          specialAppsDefinitions={specialAppsDefinitions}
          onClose={dataMarketplaceModal.setOff}
          applicationName="data-marketplace"
          title="customer_demand_dashboard.modal_title"
        />
      )}
      {reportSchedulesModal.isOn && (
        <SpecialAppDatesModal
          specialApps={specialApps}
          specialAppsDefinitions={specialAppsDefinitions}
          onClose={reportSchedulesModal.setOff}
          applicationName="scheduled-reports"
          title="special_apps.reports_schedule.title"
        />
      )}
      <Container>
        <Card>
          <CardHeader title={polyglot.t('company.configuration')} icon={<DeviceDesktop />} />
        </Card>
      </Container>
      <Container>
        <Card loading={companyDetails.loading || companyDetails.updating}>
          {companyDetails.data && (
            <>
              <CardActionHeader
                title={polyglot.t('company.customerSupport')}
                action={
                  <Button
                    dataTest="toggle-customer-support-button"
                    type="secondary"
                    size="small"
                    onClick={handleUpdateCustomerSupport}
                  >
                    {companyDetails.data.customerSupportEnabled
                      ? polyglot.t('common.toggle_off')
                      : polyglot.t('common.toggle_on')}
                  </Button>
                }
              />
              <CardData
                fullWidthRows={[
                  {
                    label: polyglot.t('common.status'),
                    value: companyDetails.data.customerSupportEnabled
                      ? polyglot.t('company.customer_support_enabled')
                      : polyglot.t('company.customer_support_disabled'),
                    valueDataTest: companyDetails.data.customerSupportEnabled
                      ? 'special-app-cs-enabled'
                      : 'special-app-cs-disabled',
                  },
                ]}
              />
            </>
          )}
        </Card>
      </Container>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading || specialAppsDefinitions.loadingDefinitions}>
            {specialApps.apps && specialAppsDefinitions.definitions && (
              <>
                <CardActionHeader
                  title={polyglot.t('bidviewer.title')}
                  action={renderButton(
                    'bidviewer',
                    specialApps.apps,
                    specialAppsDefinitions.definitions,
                  )}
                />
                <CardSection>
                  {renderBidviewerIncludePassword(
                    specialApps.apps,
                    specialAppsDefinitions.definitions,
                  )}
                </CardSection>
              </>
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && specialAppsDefinitions.definitions && (
              <CardActionHeader
                title={polyglot.t('smart_pass.title')}
                action={renderButton(
                  'smartpass',
                  specialApps.apps,
                  specialAppsDefinitions.definitions,
                )}
              />
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && specialAppsDefinitions.definitions && (
              <CardActionHeader
                title="IP Whitelisting"
                action={renderButton(
                  'ip-whitelisting',
                  specialApps.apps,
                  specialAppsDefinitions.definitions,
                )}
              />
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && (
              <>
                <CardActionHeader
                  title="Demand Dashboard"
                  action={
                    <Button type="secondary" size="small" onClick={demandDashboardEditModal.setOn}>
                      {polyglot.t('common.edit')}
                    </Button>
                  }
                />
                <CardData
                  fullWidthRows={[
                    {
                      label: polyglot.t('common.status'),
                      value:
                        specialApps.demandDashboard && !specialApps.demandDashboard.deleted
                          ? polyglot.t('company.customer_support_enabled')
                          : polyglot.t('company.customer_support_disabled'),
                      valueDataTest:
                        specialApps.demandDashboard && !specialApps.demandDashboard.deleted
                          ? 'special-app-tmr-enabled'
                          : 'special-app-tmr-disabled',
                    },
                  ]}
                />
              </>
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && (
              <>
                <CardActionHeader
                  title={polyglot.t('tequila.destination_demand_dashboard.title')}
                  action={
                    <Button
                      type="secondary"
                      size="small"
                      onClick={destinationDemandDashboardEditModal.setOn}
                    >
                      {polyglot.t('common.edit')}
                    </Button>
                  }
                />
                <CardData
                  fullWidthRows={[
                    {
                      label: polyglot.t('common.status'),
                      value:
                        destinationDemandDashboardApp && !destinationDemandDashboardApp.deleted
                          ? polyglot.t('company.customer_support_enabled')
                          : polyglot.t('company.customer_support_disabled'),
                      valueDataTest:
                        destinationDemandDashboardApp && !destinationDemandDashboardApp.deleted
                          ? polyglot.t('company.customer_support_enabled')
                          : polyglot.t('company.customer_support_disabled'),
                    },
                  ]}
                />
              </>
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && (
              <>
                <CardActionHeader
                  title="Data Marketplace"
                  action={
                    <Button type="secondary" size="small" onClick={dataMarketplaceModal.setOn}>
                      {polyglot.t('common.edit')}
                    </Button>
                  }
                />
                <CardData
                  fullWidthRows={[
                    {
                      label: polyglot.t('common.status'),
                      value:
                        specialApps.dataMarketplace && !specialApps.dataMarketplace.deleted
                          ? polyglot.t('company.customer_support_enabled')
                          : polyglot.t('company.customer_support_disabled'),
                      valueDataTest:
                        specialApps.dataMarketplace && !specialApps.dataMarketplace.deleted
                          ? 'special-app-tmr-enabled'
                          : 'special-app-tmr-disabled',
                    },
                  ]}
                />
              </>
            )}
          </Card>
        </Container>
      </Authorization>
      <Authorization resource="model.company_special_app" action={['update', 'update_assigned']}>
        <Container>
          <Card loading={specialApps.loading}>
            {specialApps.apps && (
              <>
                <CardActionHeader
                  title="Report schedules"
                  action={
                    <Button type="secondary" size="small" onClick={reportSchedulesModal.setOn}>
                      {polyglot.t('common.edit')}
                    </Button>
                  }
                />
                <CardData
                  fullWidthRows={[
                    {
                      label: polyglot.t('common.status'),
                      value:
                        specialApps.scheduledReports && !specialApps.scheduledReports.deleted
                          ? polyglot.t('company.customer_support_enabled')
                          : polyglot.t('company.customer_support_disabled'),
                      valueDataTest:
                        specialApps.scheduledReports && !specialApps.scheduledReports.deleted
                          ? 'special-app-tmr-enabled'
                          : 'special-app-tmr-disabled',
                    },
                  ]}
                />
              </>
            )}
          </Card>
        </Container>
      </Authorization>
    </>
  );
};

export default Configuration;
