import React, { useState } from 'react';
import * as R from 'ramda';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import NewWindowIcon from '@kiwicom/orbit-components/lib/icons/NewWindow';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Text from '@kiwicom/orbit-components/lib/Text';

import { fireDocsEvent } from 'utils/ga';
import { QuickGuide } from 'components/services/resources/resolvers';
import useResources from 'components/services/resources/useResources';
import { usePolyglot } from 'components/services/i18n';

import { QuickGuidesWrapper, LinksWrapper } from '../index.styled';

const QuickGuides = ({ requestType }) => {
  const [isShowAllEnabled, setIsShowAllEnabled] = useState(false);
  const polyglot = usePolyglot();
  const quickGuides = useResources(QuickGuide);

  if (!quickGuides.data || quickGuides.loading || quickGuides.error) {
    return null;
  }

  const filterByRequestType = quickGuide => {
    const { ticketing } = quickGuide;
    if (ticketing) {
      return ticketing.value.filter(({ name }) => name === requestType.name).length > 0;
    }
    return false;
  };

  const handleTracking =
    ({ faq_content_tree, toPath }) =>
    () => {
      fireDocsEvent({
        event: 'tequila.help.createRequestModal.quickGuideLinkClicked',
        name: faq_content_tree.value && faq_content_tree.value[0].name,
        path: toPath,
      });
    };

  const filtered = quickGuides.data.filter(filterByRequestType).map(quickGuide => {
    const {
      system: { codename },
      toPath,
      faq_content_tree,
    } = quickGuide;
    return (
      <TextLink
        onClick={handleTracking(quickGuide)}
        key={codename}
        external
        icon={<NewWindowIcon />}
        href={toPath}
      >
        {faq_content_tree.value && faq_content_tree.value[0].name}
      </TextLink>
    );
  });

  if (R.isEmpty(filtered)) {
    return null;
  }

  const enableShowAll = () => {
    fireDocsEvent({
      event: 'tequila.help.createRequestModal.showAllClicked',
      requestType: requestType.name,
      page: window.location.pathname,
    });

    setIsShowAllEnabled(true);
  };
  const disableShowAll = () => {
    setIsShowAllEnabled(false);
  };

  const isShowAllButtonNeeded = filtered.length > 4;
  const displayedLinks = isShowAllEnabled ? filtered : filtered.slice(0, 4);

  return (
    <QuickGuidesWrapper>
      <Stack direction="row" justify="between">
        <Text element="span" weight="bold">
          {polyglot.t('support.faq_search.alert.title')}
        </Text>
        <LinksWrapper>
          {R.intersperse(', ', displayedLinks)}
          <div>
            {isShowAllButtonNeeded && isShowAllEnabled && (
              <TextLink weight="bold" type="secondary" onClick={disableShowAll}>
                {polyglot.t('support.faq_search.alert.showLess')}
              </TextLink>
            )}
            {isShowAllButtonNeeded && !isShowAllEnabled && (
              <TextLink weight="bold" type="secondary" onClick={enableShowAll}>
                {polyglot.t('support.faq_search.alert.showAll')}
              </TextLink>
            )}
          </div>
        </LinksWrapper>
      </Stack>
    </QuickGuidesWrapper>
  );
};

export default QuickGuides;
