import React from 'react';

import Badge from '@kiwicom/orbit-components/lib/Badge';
import StarFullIcon from '@kiwicom/orbit-components/lib/icons/StarFull';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

const VirtualInterliningTag = () => {
  const polyglot = usePolyglot();
  return (
    <Badge icon={<StarFullIcon size="small" customColor="#00A991" />} size="small">
      <Text size="small">
        {polyglot.t('searchAndBooking.searchResults.virtual_interlining_tag')}
      </Text>
    </Badge>
  );
};

export default VirtualInterliningTag;
