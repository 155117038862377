import React from 'react';
import PropTypes from 'prop-types';

import { usePolyglot } from 'components/services/i18n';

import { StyledLabel, RequiredStar } from './Label.styled';

const Label = ({ htmlFor, dontTranslate, text, required }) => {
  const polyglot = usePolyglot();

  return (
    <StyledLabel htmlFor={htmlFor}>
      {dontTranslate ? text : polyglot.t(text)}
      {required && <RequiredStar>*</RequiredStar>}
    </StyledLabel>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  required: PropTypes.bool,
};

Label.defaultProps = {
  color: false,
};

export default Label;
