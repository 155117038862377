import React, { useState, useCallback, useContext, useEffect } from 'react';
import { string } from 'yup';

import useApiProducts from 'components/services/products/useApiProducts';
import { SolutionsState } from 'components/services/solutions';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';

export const CreateSolutionState = React.createContext({});

const schema = string()
  .strict()
  .required('validation.cant_be_empty')
  .min(2, 'validation.too_short')
  .lowercase('validation.only_numbers_or_letters')
  .max(64, 'validation.length_exceeded')
  .matches(/^[a-z0-9]{2,64}$/, 'validation.latin_chars')
  .test('whitespace', 'validation.whitespace', value => {
    if (value && value.indexOf(' ') < 0) return true;
    return false;
  });

const CreateSolutionProvider = ({ children, preselectedStep }) => {
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedSolutionType, setSelectedSolutionType] = useState(null);
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  const [currentResource, setCurrentResource] = useState(null);
  const [solutionName, setSolutionName] = useState('');
  const [step, setStep] = useState(0);
  const [validationError, setValidationError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const { selectedCompany } = useSelectedCompany();
  const { data: apiProducts } = useApiProducts();
  const {
    createSolution,
    state: { creating },
  } = useContext(SolutionsState);

  useEffect(() => {
    if (preselectedStep) {
      handleStepSelect(preselectedStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    setSelectedSolution(null);
    setSelectedSolutionType(null);
    setSelectedSettlement(null);
  };

  const handlePrevious = () => {
    if (selectedSolution.name === 'widget' || selectedSolution.name === 'deeplinks') {
      setStep(0);
      resetForm();
    } else if (step > 0) {
      setStep(s => s - 1);
    }
    setApiError(null);
    setSolutionName('');
    setValidationError(null);
  };

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const validateSolutionName = name => {
    try {
      setSolutionName(name);
      schema.validateSync(name);
      setValidationError(null);
    } catch (err) {
      setValidationError(err.message);
    }
  };

  const findTermsAndServiceInfo = (type, settlement) => {
    const tncCodename = apiProducts?.find(
      p => p.value === type?.[settlement],
    )?.termsAndConditionsCodename;

    setCurrentResource(tncCodename);
  };

  const handleStepSelect = useCallback(
    val => {
      if (step === 0) {
        const type = val.products[0];
        const settlement = Object.keys(val.products[0])?.[1] || null;
        setSelectedSolution(val);
        setSelectedSolutionType(type);
        setSelectedSettlement(settlement);

        if (val.name !== 'widget' && val.name !== 'deeplinks') {
          handleNext();
        } else {
          findTermsAndServiceInfo(type, settlement);
          setStep(2);
        }
      }

      if (step === 1) {
        findTermsAndServiceInfo(selectedSolutionType, selectedSettlement);
        handleNext();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step],
  );
  const findSolutionId = () => {
    return apiProducts.find(p => p.value === selectedSolutionType?.[selectedSettlement])?.id;
  };

  const handleSubmit = async onSuccess => {
    try {
      const id = findSolutionId();

      const isOfflineTravelAgency = selectedSolution.display_name.includes('offline');

      await createSolution(selectedCompany, {
        name: solutionName,
        id,
        ...(isOfflineTravelAgency && { intendedUse: 'Offline travel agency' }),
      });

      onSuccess(solutionName);
      if (preselectedStep) {
        setStep(1);
      }
    } catch (err) {
      if (err.response?.data?.errors?.affiliate_id) {
        setApiError('solutions.name_conflict');
      } else {
        setApiError(err.message);
      }
    }
  };

  const value = {
    selectedSolution,
    selectedSolutionType,
    setSelectedSolutionType,
    selectedSettlement,
    setSelectedSettlement,
    solutionName,
    step,
    handlePrevious,
    handleStepSelect,
    currentResource,
    validateSolutionName,
    validationError,
    apiError,
    handleSubmit,
    loading: creating,
    preselectedStep,
  };
  return <CreateSolutionState.Provider value={value}>{children}</CreateSolutionState.Provider>;
};

export default CreateSolutionProvider;
