export const formatPercentage = v => `${v}%`;

/**
 * Return precise rounded number
 * @param {number} number
 * @param {number} precision
 * @returns {number}
 */
export const precisionRound = (number, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};
