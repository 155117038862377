import React, { useEffect, useState } from 'react';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import Calendar from 'react-dates-lite';
import { withTheme } from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';

import { useAuditLogs } from '../services/AuditLogsProvider';

import {
  DropdownContainer,
  ContentContainer,
  ContentWrapper,
  DatePickerWrapper,
  PredefinedWrapper,
  Footer,
  Placeholder,
  CheckboxWrapper,
  FakeInputButton,
} from './DateFilter.styled';

const VERY_OLD_DATE = new Date(2016, 1, 1);
const now = new Date();

const CHECKBOX_OPTIONS = [
  {
    label: 'Current week',
    value: eachDayOfInterval({
      start: startOfWeek(now, { weekStartsOn: 1 }),
      end: now,
    }),
  },
  {
    label: 'Current month',
    value: eachDayOfInterval({
      start: startOfMonth(now),
      end: endOfMonth(now),
    }),
  },
  {
    label: 'Current year',
    value: eachDayOfInterval({
      start: startOfYear(now),
      end: endOfYear(now),
    }),
  },
  {
    label: 'Last 3 months',
    value: eachDayOfInterval({ start: subMonths(now, 3), end: now }),
  },
  {
    label: 'Current quarter',
    value: eachDayOfInterval({
      start: startOfQuarter(now),
      end: endOfQuarter(now),
    }),
  },
  {
    label: 'Previous quarter',
    value: eachDayOfInterval({
      start: startOfQuarter(subMonths(now, 3)),
      end: endOfQuarter(subMonths(now, 3)),
    }),
  },
  {
    label: 'All time',
    value: eachDayOfInterval({ start: startOfYear(VERY_OLD_DATE), end: now }),
  },
];

const DatePicker = withTheme(({ value, setValue, theme }) => {
  return (
    <Calendar
      past={true}
      future={false}
      visibleMonths={2}
      firstMonth={VERY_OLD_DATE}
      rangeSelect
      lastMonth={now}
      selectedDates={value}
      selectDates={v => setValue(v)}
      firstWeekDay={1}
      classes={{
        day: 'auditLogsDay',
        button: 'auditLogsButton',
      }}
      colors={{
        selected: theme.orbit.backgroundButtonPrimary,
      }}
      buttonBack={<Button type="secondary" size="small" iconLeft={<ChevronBackwardIcon />} />}
      buttonForward={<Button type="secondary" size="small" iconLeft={<ChevronForwardIcon />} />}
    />
  );
});

const formatDate = date => {
  return format(new Date(date), 'd MMM yyyy');
};

const getDisplayValue = value => {
  if (value.length === 0) {
    return <Placeholder>Select date range</Placeholder>;
  }
  if (value.length === 1) {
    return `${formatDate(value[0])} -`;
  }

  return `${formatDate(value[0])} - ${formatDate(value[value.length - 1])}`;
};

const DateFilter = ({ label }) => {
  const polyglot = usePolyglot();
  const datePickerPopover = useToggle();
  const { timestamps, setTimestamps } = useAuditLogs();
  const [draftValue, setDraftValue] = useState(timestamps);

  useEffect(() => {
    if (!datePickerPopover.isOn) {
      return;
    }

    if (timestamps.length === 0) {
      setDraftValue([]);
    } else if (timestamps.length === 1) {
      setDraftValue(timestamps);
    } else {
      setDraftValue(eachDayOfInterval({ start: timestamps[0], end: timestamps[1] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerPopover.isOn]);

  return (
    <Popover
      opened={datePickerPopover.isOn}
      onOpen={datePickerPopover.setOn}
      onClose={datePickerPopover.setOff}
      noPadding
      width="830px"
      content={
        <DropdownContainer onClick={e => e.stopPropagation()}>
          <ContentContainer>
            <ContentWrapper>
              <DatePickerWrapper>
                <DatePicker value={draftValue} setValue={setDraftValue} />
              </DatePickerWrapper>
              <PredefinedWrapper>
                <Text weight="bold" spaceAfter="medium">
                  {polyglot.t('audit_logs.date_filter.date_range')}
                </Text>

                {CHECKBOX_OPTIONS.map(option => (
                  <CheckboxWrapper key={option.label}>
                    <Checkbox
                      label={option.label}
                      checked={
                        isSameDay(new Date(option.value[0]), new Date(draftValue[0])) &&
                        isSameDay(
                          new Date(option.value[option.value.length - 1]),
                          new Date(draftValue[draftValue.length - 1]),
                        )
                      }
                      onChange={() => setDraftValue(option.value)}
                    />
                  </CheckboxWrapper>
                ))}
              </PredefinedWrapper>
            </ContentWrapper>

            <Footer>
              <Button size="small" type="secondary" onClick={() => setDraftValue([])}>
                {polyglot.t('audit_logs.date_filter.clear_dates')}
              </Button>

              <div>
                <Button
                  size="small"
                  type="primarySubtle"
                  onClick={() => {
                    datePickerPopover.setOff();
                    setDraftValue(timestamps);
                  }}
                >
                  {polyglot.t('audit_logs.date_filter.cancel_dates')}
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    datePickerPopover.setOff();

                    if (draftValue.length > 1) {
                      setTimestamps([draftValue[0], draftValue[draftValue.length - 1]]);
                    } else {
                      setTimestamps([...draftValue]);
                    }
                  }}
                >
                  {polyglot.t('audit_logs.date_filter.set_dates')}
                </Button>
              </div>
            </Footer>
          </ContentContainer>
        </DropdownContainer>
      }
    >
      <FakeInputButton>
        <div>
          <label>{label}</label>
          <div>{getDisplayValue(draftValue)}</div>
        </div>
      </FakeInputButton>
    </Popover>
  );
};

export default DateFilter;
