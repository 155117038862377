import React from 'react';
import styled from 'styled-components';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Collapse from '@kiwicom/orbit-components/lib/Collapse';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import useBookingFlow from 'components/services/booking/useBookingFlow';

import Title from './Title';
import Banners from './Banners';
import PriceOverview from './PriceOverview';
import PassengerDetails from './PassengerDetails';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const Container = styled.div`
  div[class^='SectionHeader__StyledCardSectionHeader'] {
    background: white;
  }

  div[class^='Collapse__StyledCollapse'] {
    padding-bottom: 0;
  }
`;

// make the separator be full width, ignoring the card padding
const SeparatorWrapper = styled.div`
  margin-left: -25px !important;
  width: calc(100% + 50px);
`;

const BookingSummary = ({ tripData, paidGuarantee, loading, price, passengers }) => {
  const {
    prebookingConfig: { data: prebookingConfigData, loading: prebookingConfigLoading },
  } = useBookingFlow();
  const polyglot = usePolyglot();

  return (
    <Container>
      <Card loading={loading}>
        <CardSection
          header={
            <Header>
              <Text weight="bold">
                {polyglot.t('booking.deposit_booking.booking_summary.summary')}
              </Text>
              <div>
                <Badge type="info" icon={<InformationCircleIcon />}>
                  {/* we have 2 hardcoded ones */}
                  {polyglot.t('booking.deposit_booking.booking_summary.notes', {
                    number: (prebookingConfigData?.banners?.length || 0) + 2,
                  })}
                </Badge>
              </div>
            </Header>
          }
          expandable
        >
          <Stack spacing="large">
            <Title tripData={tripData} />

            <Banners />

            <SeparatorWrapper>
              <Separator />
            </SeparatorWrapper>

            <PriceOverview
              price={{ amount: price, currency: tripData.currency }}
              passengers={passengers}
              baggage={tripData.baggage}
              paidGuarantee={paidGuarantee}
              loading={prebookingConfigLoading}
            />

            <Collapse
              label={polyglot.t('booking.deposit_booking.booking_summary.passenger_details')}
            >
              <PassengerDetails passengers={passengers} baggage={tripData.baggage} />
            </Collapse>
          </Stack>
        </CardSection>
      </Card>
    </Container>
  );
};

export default BookingSummary;
