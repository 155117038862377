import React from 'react';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { saveChangesRoutes } from 'consts/saveChangesRoutes';

import SmartpointConfigurationModal from './SmartpointConfigurationModal';

const { SMARTPOINT_CONFIGURATION } = saveChangesRoutes;

const SaveChangesComponent = ({ setConfirm, confirmCallback, saveChangesRoute }) => {
  const allowTransition = () => {
    setConfirm(false);
    confirmCallback(true);
  };

  const blockTransition = () => {
    setConfirm(false);
    confirmCallback(false);
  };

  const polyglot = usePolyglot();

  const parsedSaveChangesRoute = JSON.parse(saveChangesRoute);
  switch (parsedSaveChangesRoute.type) {
    case SMARTPOINT_CONFIGURATION: {
      return (
        <SmartpointConfigurationModal
          allowTransition={allowTransition}
          blockTransition={blockTransition}
          listOfChangedPCCs={parsedSaveChangesRoute.payload.listOfChangedPCCs}
        />
      );
    }

    default: {
      return (
        <Modal closable onClose={blockTransition}>
          <ModalHeader title={polyglot.t('common.save_changes_question')} />
          <ModalSection>
            <Stack direction="row" justify="end">
              <Button type="secondary" onClick={blockTransition}>
                {polyglot.t('common.cancel')}
              </Button>
              <Button onClick={allowTransition}>{polyglot.t('common.save')}</Button>
            </Stack>
          </ModalSection>
        </Modal>
      );
    }
  }
};

export default SaveChangesComponent;
