import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import PlusCircleIcon from '@kiwicom/orbit-components/lib/icons/PlusCircle';

import { useSearch } from 'components/services/search';
import { usePolyglot } from 'components/services/i18n';
import {
  getMulticityTrips,
  getIsMulticityAddNewButtonDisabled,
  getIsMulticitySearchDisabled,
} from 'utils/search';
import { Space } from 'common';

import Trip from './Trip';
import { SearchbarContainer } from '../Searchbar.styled';
import { MultiCitySearchbarContainer } from './MultiCity.styled';

const MultiCity = props => {
  const polyglot = usePolyglot();
  const {
    tabData,
    searchFlights,
    addMulticityTrip,
    removeMulticityTrip,
    addMulticityLocation,
    removeMulticityLocation,
    changeMulticityDepartureSelectedDates,
  } = useSearch();
  const multicityTrips = getMulticityTrips(tabData);
  const isMulticityAddNewButtonDisabled = getIsMulticityAddNewButtonDisabled(multicityTrips);
  const isMulticitySearchDisabled = getIsMulticitySearchDisabled(
    multicityTrips,
    isMulticityAddNewButtonDisabled,
  );

  const handleSearch = () => {
    searchFlights(tabData);
  };

  const isLoading = () => {
    if (
      tabData.searchStatus.duration === 'loading' ||
      tabData.searchStatus.price === 'loading' ||
      tabData.searchStatus.quality === 'loading'
    ) {
      return true;
    }
    return false;
  };

  return (
    <MultiCitySearchbarContainer>
      {multicityTrips.map((trip, index) => (
        <SearchbarContainer key={trip.id}>
          <Space>
            <Trip
              removeDisabled={multicityTrips.length === 1}
              trip={trip}
              removeMulticityTrip={removeMulticityTrip}
              addMulticityLocation={addMulticityLocation}
              removeMulticityLocation={removeMulticityLocation}
              changeMulticityDepartureSelectedDates={changeMulticityDepartureSelectedDates}
              index={index}
            />
          </Space>
        </SearchbarContainer>
      ))}
      <Stack direction="row" spacing="XSmall" spaceAfter="large">
        <Button
          width="436px"
          type="secondary"
          iconLeft={<PlusCircleIcon />}
          disabled={isMulticityAddNewButtonDisabled}
          onClick={addMulticityTrip}
        >
          {polyglot.t('searchAndBooking.multicity.add')}
        </Button>
        <Button
          title={polyglot.t('searchAndBooking.search')}
          width="436px"
          onClick={handleSearch}
          disabled={isMulticitySearchDisabled}
          loading={isLoading()}
          dataTest="start-search-button"
        >
          {polyglot.t('searchAndBooking.search')}
        </Button>
      </Stack>
    </MultiCitySearchbarContainer>
  );
};

export default MultiCity;
