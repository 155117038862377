import React from 'react';

import ClosableAlert from './ClosableAlert.js';

import { usePolyglot } from 'components/services/i18n/index.js';

import Text from '@kiwicom/orbit-components/lib/Text';

const SecondarySaveAlert = () => {
  const polyglot = usePolyglot();
  return (
    <>
      <ClosableAlert
        title={polyglot.t('widgets.save_success')}
        type="success"
        timeout
        spaceAfter="normal"
        icon
      />
      <ClosableAlert spaceAfter="large" type="info" closable icon>
        <Text>{polyglot.t('widgets.create_success_info_desc_secondary')}</Text>
      </ClosableAlert>
    </>
  );
};

export default SecondarySaveAlert;
