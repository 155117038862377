import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@kiwicom/orbit-components/lib/Button';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';

import * as confirmDialog from 'redux/modules/confirmDialog';
import { usePolyglot } from 'components/services/i18n';

import { ActionsContainer } from './ConfirmDialog.styled';

const ConfirmDialog = ({ confirmDialogContent, hideConfirmDialog }) => {
  const polyglot = usePolyglot();
  const { visible, title, content, confirmLabel, cancelLabel } = confirmDialogContent;

  const handleCancelClick = () => {
    hideConfirmDialog();
    if (typeof confirmDialogContent.handleCancel === 'function') {
      confirmDialogContent.handleCancel();
    }
  };

  const handleConfirmClick = () => {
    hideConfirmDialog();
    confirmDialogContent.handleConfirm();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal closable onClose={handleCancelClick}>
      <ModalHeader title={title} />
      <ModalSection>{content}</ModalSection>
      <ModalFooter>
        <ActionsContainer main="end">
          <Button width="128px" type="secondary" onClick={handleCancelClick}>
            {cancelLabel || polyglot.t('common.cancel')}
          </Button>
          <Button width="128px" onClick={handleConfirmClick}>
            {confirmLabel || polyglot.t('common.ok')}
          </Button>
        </ActionsContainer>
      </ModalFooter>
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  confirmDialog: PropTypes.object,
  cancelLabel: PropTypes.string,
};

const mapStateToProps = state => ({
  confirmDialogContent: state.confirmDialog,
});

const mapDispatchToProps = {
  hideConfirmDialog: confirmDialog.hideConfirmDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
