import React, { useState } from 'react';

import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';

import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';
import useCarriers from 'components/services/bookingTool/useCarriers';
import useSearch from 'components/services/search/useSearch';
import { getCarriersList } from 'utils/search';

import EmptyListChoiceIcon from './EmptyListChoiceIcon';
import { CarriersFilterWrapper, CarriersFilteredValuesWrapper } from './../index.styled';

const Carriers = () => {
  const polyglot = usePolyglot();
  const { tabData, selectCarriers, changeFilteredCarriers } = useSearch();
  const { data: carriers } = useCarriers();
  const [searchValue, setSearchValue] = useState('');
  const carriersList = getCarriersList(tabData);
  const filteredCarriers = tabData.filteredCarriers;

  const changeSearchValue = e => setSearchValue(e.target.value);
  const clearSearchValue = () => setSearchValue('');

  const handleSelectCarriers = () => {
    const selectedCarriers = filteredCarriers.length > 0 ? [] : carriersList;

    selectCarriers(selectedCarriers);
  };

  const searchFilteredValues = carriersList
    .filter(carrierCode => {
      return `${carriers[carrierCode]} (${carrierCode})`
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    })
    .sort();

  return (
    <CarriersFilterWrapper>
      <Stack direction="row" spaceAfter="medium" spaceBefore="medium">
        <InputField
          value={searchValue}
          placeholder={polyglot.t('searchAndBooking.search')}
          prefix={<SearchIcon />}
          suffix={
            searchValue ? (
              <ButtonLink onClick={clearSearchValue} transparent icon={<CloseIcon />} />
            ) : null
          }
          onChange={changeSearchValue}
          size="small"
        />
        {!searchValue && (
          <Button onClick={handleSelectCarriers} size="small" type="secondary">
            {filteredCarriers.length > 0
              ? polyglot.t('searchAndBooking.select_all')
              : polyglot.t('searchAndBooking.deselect')}
          </Button>
        )}
      </Stack>
      <CarriersFilteredValuesWrapper>
        {searchFilteredValues.map(carrierCode => (
          <ListChoice
            key={carrierCode}
            icon={filteredCarriers.includes(carrierCode) ? <EmptyListChoiceIcon /> : <CheckIcon />}
            title={`${carriers[carrierCode]} (${carrierCode})`}
            onClick={() => changeFilteredCarriers(carrierCode)}
          />
        ))}
      </CarriersFilteredValuesWrapper>
    </CarriersFilterWrapper>
  );
};

export default React.memo(Carriers);
