import React from 'react';

import { Space } from 'common';

import { CardActionHeaderWrapper, CardActionTitleWrapper } from './common.styled';

const CardActionHeader = ({ icon, title, action }) => {
  return (
    <CardActionHeaderWrapper>
      <CardActionTitleWrapper>
        {icon}
        <Space left="m">{title}</Space>
      </CardActionTitleWrapper>
      {action}
    </CardActionHeaderWrapper>
  );
};

export default CardActionHeader;
