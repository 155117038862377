import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  word-break: break-word;
  padding-left: ${({ theme }) => theme.orbit.spaceMedium};
  padding-top: ${({ theme }) => theme.orbit.spaceLarge};

  ${media.largeMobile(css`
    width: 360px;
  `)};
`;

export const PassengerRowWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.orbit.spaceMedium} 0;
  cursor: pointer;
`;

export const ExpandedDescriptionWrapper = styled.div`
  text-align: left;
`;
