import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import Document from '@kiwicom/orbit-components/lib/icons/Document';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { ROUTE_CONFIG } from 'consts/routes';

const ReadTheDocs = ({ polyglot }) => (
  <Card>
    <CardSection
      icon={<Document />}
      title={polyglot.t('resources.read_the_docs')}
      description={polyglot.t('add_details.docs_subtitle')}
    />
    <CardSection>
      <Button
        external
        href={`${ROUTE_CONFIG.DOCS.path}`}
        iconRight={<ChevronForwardIcon />}
        size="small"
      >
        {polyglot.t('add_details.go_to_resources')}
      </Button>
    </CardSection>
  </Card>
);

export default ReadTheDocs;
