import React, { useState, useEffect, useContext } from 'react';
import * as dfs from 'date-fns';
import { Stack, Radio, Grid, Text, Button, Inline } from '@kiwicom/orbit-components';

import { TIMEFRAME_PREDEFINED_OPTIONS } from 'consts/mmb';
import DateInputGroup from 'components/common/DateInputGroup';
import useTransactions from 'components/services/transactions/useTransactions';
import { usePolyglot } from 'components/services/i18n';

import { TimeFilterState } from '../services/TimeFilters';

const DATE_REGEX = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{1,2}$');

const getMonth = date => {
  return Number(date.split('-')[1]);
};

const TimeframeFilterDropdown = ({ onClose, onCancel, isOpened }) => {
  const polyglot = usePolyglot();
  const { filterCreatedFrom, filterCreatedTo, setFilterCreatedFrom, setFilterCreatedTo } =
    useTransactions();

  const {
    selectedFilterPeriod,
    selectedFilterCreatedFrom,
    selectedFilterCreatedTo,
    setSelectedFilterPeriod,
    setSelectedFilterCreatedFrom,
    setSelectedFilterCreatedTo,
    clearSelectedFilters,
  } = useContext(TimeFilterState);

  const [selectedDateFromError, setSelectedDateFromError] = useState(undefined);
  const [selectedDateToError, setSelectedDateToError] = useState(undefined);

  const handleSaveDate = (createdFrom, createdTo) => {
    setFilterCreatedFrom(createdFrom);
    setFilterCreatedTo(createdTo);
    onClose();
  };

  useEffect(() => {
    setSelectedFilterCreatedFrom(filterCreatedFrom);
    setSelectedFilterCreatedTo(filterCreatedTo);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const targetPeriod = TIMEFRAME_PREDEFINED_OPTIONS.find(o => {
      return o.value?.from === filterCreatedFrom && o.value?.to === filterCreatedTo;
    });

    setSelectedFilterPeriod(JSON.stringify(targetPeriod));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  //Used for validation of dates
  useEffect(() => {
    setSelectedDateFromError(undefined);
    setSelectedDateToError(undefined);

    if (dfs.isFuture(new Date(selectedFilterCreatedFrom))) {
      setSelectedDateFromError('tequila.mmb.credit_activity.filters.timeframe.error.future_date');
    }

    if (dfs.isFuture(new Date(selectedFilterCreatedTo))) {
      setSelectedDateToError('tequila.mmb.credit_activity.filters.timeframe.error.future_date');
    }

    if (dfs.isBefore(new Date(selectedFilterCreatedTo), new Date(selectedFilterCreatedFrom))) {
      setSelectedDateToError(
        'tequila.mmb.credit_activity.filters.timeframe.error.invalid_date_range',
      );
    }

    if (
      selectedFilterCreatedFrom &&
      selectedFilterPeriod === 'custom' &&
      (DATE_REGEX.exec(selectedFilterCreatedFrom) === null ||
        getMonth(selectedFilterCreatedFrom) !==
          Number(new Date(selectedFilterCreatedFrom).getMonth()) + 1)
    ) {
      setSelectedDateFromError('tequila.mmb.credit_activity.filters.timeframe.error.invalid_date');
    }

    if (
      selectedFilterCreatedTo &&
      selectedFilterPeriod === 'custom' &&
      (DATE_REGEX.exec(selectedFilterCreatedTo) === null ||
        getMonth(selectedFilterCreatedTo) !==
          Number(new Date(selectedFilterCreatedTo).getMonth()) + 1)
    ) {
      setSelectedDateToError('tequila.mmb.credit_activity.filters.timeframe.error.invalid_date');
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterCreatedTo, selectedFilterCreatedFrom]);

  return (
    <>
      <Grid columns="1fr 1fr" spaceAfter="large">
        <Stack direction="column">
          <Text weight="bold" size="large">
            {polyglot.t('tequila.mmb.credit_activity.filters.timeframe.input.from.label')}
          </Text>
          {TIMEFRAME_PREDEFINED_OPTIONS.map(o => {
            return (
              <Radio
                key={o.label}
                label={polyglot.t(o.label)}
                value={JSON.stringify(o)}
                checked={o.name === selectedFilterPeriod}
                onChange={e => setSelectedFilterPeriod(e.target.value)}
              />
            );
          })}
        </Stack>

        <Stack direction="column">
          <DateInputGroup
            name="from"
            label={polyglot.t('tequila.mmb.credit_activity.filters.timeframe.input.from.label')}
            onChange={value => {
              setSelectedFilterCreatedFrom(value);
              setSelectedFilterPeriod('custom');
            }}
            value={selectedFilterCreatedFrom}
            error={polyglot.t(selectedDateFromError)}
          />
          <DateInputGroup
            name="to"
            label={polyglot.t('tequila.mmb.credit_activity.filters.timeframe.input.to.label')}
            onChange={value => {
              setSelectedFilterCreatedTo(value);
              setSelectedFilterPeriod('custom');
            }}
            value={selectedFilterCreatedTo}
            error={polyglot.t(selectedDateToError)}
          />
        </Stack>
      </Grid>
      <Stack direction="row" justify="between">
        <Button type="secondary" onClick={clearSelectedFilters}>
          {polyglot.t('tequila.mmb.credit_activity.filters.timeframe.button.clear_date')}
        </Button>
        <Inline spacing="medium">
          <Button type="secondary" onClick={onCancel}>
            {polyglot.t('tequila.mmb.credit_activity.filters.timeframe.button.close')}
          </Button>
          <Button
            disabled={selectedDateToError || selectedDateFromError}
            onClick={() => handleSaveDate(selectedFilterCreatedFrom, selectedFilterCreatedTo)}
          >
            {polyglot.t('tequila.mmb.credit_activity.filters.timeframe.button.save')}
          </Button>
        </Inline>
      </Stack>
    </>
  );
};

export default TimeframeFilterDropdown;
