import React from 'react';
import { retry } from 'utils/functions';

const CustomerDemandDashboard = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "CustomerDemandDashboard" */ './CustomerDemandDashboard'),
  ),
);

export default CustomerDemandDashboard;
