import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

const Link = styled(RRLink)`
  color: ${({ theme }) => theme.orbit.colorTextLinkPrimary};
  font-weight: ${({ theme }) => theme.orbit.fontWeightLinks};
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.orbit.colorTextLinkPrimaryHover};
    text-decoration: underline;
  }

  &:focus {
    outline-width: 3px;
  }
`;

export default Link;
