import React, { useReducer, useContext } from 'react';

import {
  mapApiConfigValuesToFormValues,
  mapApiDataToWidgetData,
  mapWidgetDataToApiData,
  mapApiDataToPaginationData,
} from 'mappers/Widgets';
import api from 'utils/api';
import { initialValues } from 'consts/widgets';
import { ROUTE_CONFIG } from 'consts/routes';
import { useHistory } from 'react-router-dom';

const INITIAL = () => ({
  inProgress: false,
  success: false,
  error: null,
});

const IN_PROGRESS = () => ({
  inProgress: true,
  success: false,
  error: null,
});

const FAIL = error => ({
  inProgress: false,
  success: false,
  error: error || true,
});

const SUCCESS = () => ({
  inProgress: false,
  success: true,
  error: null,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_WIDGETS': {
      return {
        ...state,
        loadingCompanyWidgets: IN_PROGRESS(),
      };
    }
    case 'FETCH_COMPANY_WIDGETS_SUCCESS': {
      return {
        ...state,
        loadingCompanyWidgets: SUCCESS(),
        companyWidgets: action.data.companyWidgets,
        pagination: action.data.pagination,
      };
    }
    case 'FETCH_COMPANY_WIDGETS_FAIL': {
      return {
        ...state,
        loadingCompanyWidgets: FAIL(),
      };
    }
    case 'FETCH_WIDGET': {
      return {
        ...state,
        loadingWidget: IN_PROGRESS(),
        widget: null,
      };
    }
    case 'FETCH_WIDGET_SUCCESS': {
      return {
        ...state,
        loadingWidget: SUCCESS(),
        widget: action.widget,
      };
    }
    case 'FETCH_WIDGET_FAIL': {
      return {
        ...state,
        loadingWidget: FAIL(),
      };
    }
    case 'CREATE_WIDGET': {
      return {
        ...state,
        creatingWidget: IN_PROGRESS(),
      };
    }
    case 'CREATE_WIDGET_SUCCESS': {
      return {
        ...state,
        ...initialAlertsState,
        creatingWidget: SUCCESS(),
        widget: action.widget,
      };
    }
    case 'CREATE_WIDGET_FAIL': {
      return {
        ...state,
        creatingWidget: FAIL(action.error),
      };
    }
    case 'UPDATE_WIDGET': {
      return {
        ...state,
        updatingWidget: IN_PROGRESS(),
      };
    }
    case 'UPDATE_WIDGET_SUCCESS': {
      return {
        ...state,
        ...initialAlertsState,
        updatingWidget: SUCCESS(),
        widget: action.widget,
      };
    }
    case 'UPDATE_WIDGET_FAIL': {
      return {
        ...state,
        updatingWidget: FAIL(),
      };
    }
    case 'DELETE_WIDGET': {
      return {
        ...state,
        deletingWidget: IN_PROGRESS(),
      };
    }
    case 'DELETE_WIDGET_SUCCESS': {
      return {
        ...state,
        ...initialAlertsState,
        deletingWidget: SUCCESS(),
        companyWidgets: state.companyWidgets.filter(widget => widget.id !== action.deletedWidgetId),
      };
    }
    case 'DELETE_WIDGET_FAIL': {
      return {
        ...state,
        deletingWidget: FAIL(action.error),
      };
    }
    case 'FLUSH_ALERTS': {
      return {
        ...state,
        ...initialAlertsState,
      };
    }
    case 'CLEAR_WIDGET': {
      return {
        ...state,
        widget: INITIAL_WIDGET,
      };
    }
    case 'LOAD_MORE':
      return {
        ...state,
        loadingMore: true,
        loadMoreError: null,
      };
    case 'LOAD_MORE_SUCCESS':
      return {
        ...state,
        loadingMore: false,
        pagination: action.pagination,
        page: action.page,
      };
    case 'LOAD_MORE_FAIL':
      return {
        ...state,
        loadMoreError: action.error,
        loadingMore: false,
      };

    default:
      return state;
  }
};

const initialAlertsState = {
  creatingWidget: INITIAL(),
  loadingCompanyWidgets: INITIAL(),
  loadingWidget: INITIAL(),
  updatingWidget: INITIAL(),
  deletingWidget: INITIAL(),
};

const INITIAL_WIDGET = {
  name: 'Untitled',
  formValues: initialValues,
};

const initialState = {
  companyWidgets: [],
  widget: INITIAL_WIDGET,
  ...initialAlertsState,
  data: [],
  pagination: {},
  loading: true,
  loadingMore: false,
  page: 1,
};

const WidgetsContext = React.createContext(initialState);

export const WidgetsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const createNewWidget = async (name, formValues, companyName) => {
    const { affilId } = formValues.general;

    const apiData = mapWidgetDataToApiData({
      name,
      affilId,
      formValues,
    });

    dispatch({ type: 'CREATE_WIDGET' });

    try {
      const res = await api.v1.post(`/companies/${companyName}/widgets`, apiData);

      dispatch({ type: 'CREATE_WIDGET_SUCCESS', widget: mapApiDataToWidgetData(res.data) });

      history.push(`${ROUTE_CONFIG.WIDGETS.path}/${companyName}/edit/${res.data.id}`);

      return Promise.resolve();
    } catch (e) {
      let error = '';
      if (e.response?.status === 409) {
        error = 'widgets.create_new_unique';
      } else {
        error = 'widgets.create_fail';
      }
      dispatch({ type: 'CREATE_WIDGET_FAIL', error });

      return Promise.reject();
    }
  };

  const fetchCompanyWidgets = async (company, page, rowsPerPage) => {
    dispatch({ type: 'FETCH_COMPANY_WIDGETS' });
    try {
      const res = await api.v1.get(`/companies/${company}/widgets`, {
        params: {
          _page: page,
          _per_page: rowsPerPage,
        },
      });

      const companyApiWidgets = res.data.data;
      const pagination = mapApiDataToPaginationData(res.data.pagination);

      const companyWidgets = companyApiWidgets.map(apiWidget => ({
        ...apiWidget,
        config: mapApiConfigValuesToFormValues(apiWidget.config),
      }));

      const data = { companyWidgets, pagination };

      dispatch({
        type: 'FETCH_COMPANY_WIDGETS_SUCCESS',
        data,
      });

      return res;
    } catch (error) {
      dispatch({
        type: 'FETCH_COMPANY_WIDGETS_FAIL',
      });
    }
  };

  const fetchWidget = async widgetId => {
    dispatch({ type: 'FETCH_WIDGET' });

    try {
      if (widgetId) {
        const res = await api.v1.get(`/widgets/${widgetId}`);
        dispatch({
          type: 'FETCH_WIDGET_SUCCESS',
          widget: mapApiDataToWidgetData(res.data),
        });
      } else {
        dispatch({
          type: 'FETCH_WIDGET_SUCCESS',
          widget: mapApiDataToWidgetData({
            name: 'Untitled',
            config: null,
          }),
        });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_WIDGET_FAIL' });
    }
  };

  const updateWidget = async (formValues, widgetId) => {
    dispatch({ type: 'UPDATE_WIDGET' });
    try {
      const { affilId } = formValues.general;

      const apiData = mapWidgetDataToApiData({
        affilId,
        formValues,
      });

      const res = await api.v1.put(`/widgets/${widgetId}`, apiData);
      dispatch({
        type: 'UPDATE_WIDGET_SUCCESS',
        widget: mapApiDataToWidgetData(res.data),
      });
    } catch (error) {
      dispatch({ type: 'UPDATE_WIDGET_FAIL' });
      return Promise.reject(error);
    }
  };

  const deleteWidget = async widgetId => {
    dispatch({ type: 'DELETE_WIDGET' });
    try {
      await api.v1.delete(`/widgets/${widgetId}`);
      dispatch({ type: 'DELETE_WIDGET_SUCCESS', deletedWidgetId: widgetId });
    } catch (error) {
      dispatch({ type: 'DELETE_WIDGET_FAIL', error: 'widgets.delete_fail' });
      return Promise.reject(error);
    }
  };

  const flushAlerts = () => {
    dispatch({ type: 'FLUSH_ALERTS' });
  };

  const goBack = (flush = true) => {
    if (flush) flushAlerts();
    history.push(`${ROUTE_CONFIG.WIDGETS.path}`);
  };

  const goToEdit = (companyName, widgetId) => {
    flushAlerts();
    history.push(`${ROUTE_CONFIG.WIDGETS.path}/${companyName}/edit/${widgetId}`);
  };

  const goToCreateNew = companyName => {
    flushAlerts();
    history.push(`${ROUTE_CONFIG.WIDGETS.path}/${companyName}/new`);
  };

  const clearWidget = () => {
    dispatch({ type: 'CLEAR_WIDGET' });
  };

  const fetchMoreWidgets = async (company, page, rowsPerPage) => {
    dispatch({ type: 'LOAD_MORE' });
    try {
      const response = await fetchCompanyWidgets(company, page, rowsPerPage);

      dispatch({
        type: 'LOAD_MORE_SUCCESS',
        pagination: mapApiDataToPaginationData(response.data.pagination),
        page: page,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_MORE_FAIL', error });
    }
  };

  const value = {
    state,
    fetchCompanyWidgets,
    fetchMoreWidgets,
    fetchWidget,
    updateWidget,
    createNewWidget,
    deleteWidget,
    goBack,
    goToEdit,
    goToCreateNew,
    clearWidget,
  };

  return <WidgetsContext.Provider value={value}>{children}</WidgetsContext.Provider>;
};

const useWidgetsContext = () => {
  return useContext(WidgetsContext);
};

export default useWidgetsContext;
