const v1_get_from = accountManager => {
  return {
    label: accountManager,
    value: accountManager,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
