import React from 'react';
import Proptypes from 'prop-types';

import { StyledError } from './Error.styled';

const Error = ({ visible, message }) => <StyledError visible={visible}>{message}</StyledError>;

Error.propTypes = {
  visible: Proptypes.bool.isRequired,
  message: Proptypes.string,
};

export default Error;
