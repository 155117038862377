import React from 'react';
import { CardHeaderWrapper, CardHeaderIcon } from './common.styled';

const CardHeader = ({ icon, title }) => {
  return (
    <CardHeaderWrapper>
      {icon && <CardHeaderIcon>{icon}</CardHeaderIcon>}
      {title}
    </CardHeaderWrapper>
  );
};

export default CardHeader;
