import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';

const Wrapper = styled.div`
  padding: 12px 0 12px 24px;
  margin: -24px -24px 0 -24px;
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  background-color: ${({ theme }) => theme.orbit.backgroundBody};
  position: fixed;
  z-index: 1;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 880px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ActionsBar = ({ openAddBankAccountModal, companyName, showAddBankAccountButton }) => {
  const polyglot = usePolyglot();

  return (
    <Wrapper>
      <ContentWrapper>
        <Link to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}`}>
          <Button type="primarySubtle" size="small" iconLeft={<ChevronBackwardIcon />} />
        </Link>
        {showAddBankAccountButton && (
          <Button onClick={openAddBankAccountModal} size="small" dataTest="add-bank-account-button">
            {polyglot.t('company.billing.add_bank_account_button_text')}
          </Button>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ActionsBar;
