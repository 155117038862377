import styled, { css } from 'styled-components';

export const AutocompleteMultiWrapper = styled.div`
  width: 400px;
  position: relative;
  ${({ disabled }) => disabled && 'pointer-events:none'}
`;

export const DropdownIconContainer = styled.span`
  margin: 0 8px;
  color: ${({ theme }) => theme.orbit.colorIconSecondary};
  transition: transform 100ms ease-in-out;

  ${({ open }) =>
    open &&
    css`
      transform: rotateZ(-180deg);
    `};
`;

export const ListChoiceContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  position: absolute;
  top: ${({ label, inlineLabel }) => (label && !inlineLabel ? '73' : '53')}px;
  max-width: calc(100% - 2px);
  width: calc(100% - 2px);
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  z-index: 5;
`;
