import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Stack, InputField, Button } from '@kiwicom/orbit-components';
import DownloadIcon from '@kiwicom/orbit-components/lib/icons/Download';

import useTransactions from 'components/services/transactions/useTransactions';
import { usePolyglot } from 'components/services/i18n';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';
import { arrayOfObjectsToCsv } from 'utils/object';

import TimeframeFilter from './components/TimeframeFilter';
import TypeFilter from './components/TypeFilter';
import TimeFilterProvider from './services/TimeFilters';

const ButtonWrapper = styled.div`
  padding-top: 20px;
`;

const InputFieldWrapper = styled.div`
  width: 220px;
`;

const handleCsvDownload = (content, companyName) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', `credit_activity_${companyName}_${Date.now()}.csv`);
  pom.click();
};

const CreditActivityFilters = () => {
  const { transactions, setFilterBid } = useTransactions();
  const polyglot = usePolyglot();
  const companyName = useUsersCompanyName();

  const [currentBid, setCurrentBid] = useState('');

  useEffect(() => {
    if (currentBid?.length >= 8 || !currentBid?.length) {
      setFilterBid(currentBid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBid]);

  const csvPropertiesOrder = ['bid', 'type', 'date', 'amount'];

  return (
    <Stack direction="row" justify="between" align="top">
      <Stack direction="row" spacing="medium" shrink>
        <InputFieldWrapper>
          <InputField
            type="number"
            label={polyglot.t('tequila.mmb.credit_activity.filters.bid.label')}
            placeholder={polyglot.t('tequila.mmb.credit_activity.filters.bid.placeholder')}
            help={polyglot.t('tequila.mmb.credit_activity.filters.bid.tooltip')}
            value={currentBid}
            onChange={e => setCurrentBid(e.target.value)}
            size="small"
          />
        </InputFieldWrapper>
        <TimeFilterProvider>
          <TimeframeFilter />
        </TimeFilterProvider>
        <TypeFilter />
      </Stack>
      <ButtonWrapper>
        <Button
          type="primarySubtle"
          iconLeft={<DownloadIcon />}
          onClick={() =>
            handleCsvDownload(arrayOfObjectsToCsv(transactions, csvPropertiesOrder), companyName)
          }
          size="small"
        >
          {polyglot.t('tequila.mmb.credit_activity.button.export')}
        </Button>
      </ButtonWrapper>
    </Stack>
  );
};

export default CreditActivityFilters;
