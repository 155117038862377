import React from 'react';
import styled, { css } from 'styled-components';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Loading from '@kiwicom/orbit-components/lib/Loading';

const Container = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  border-radius: 3px;
`;

const Content = styled.div`
  padding: ${({ nested }) => (nested ? '16px' : '0 24px 24px')};
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ nested }) => (nested ? 16 : 24)}px;

  ${({ nested }) =>
    nested &&
    css`
      & + ${Content} {
        border-top: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
      }
    `}
`;

const Title = styled.div`
  text-transform: initial;
`;

const Subtitle = styled.div`
  margin-top: 4px;
`;

const AlertWrapper = styled.div`
  padding: 0 24px 24px;
`;

const Wrapper = styled.div`
  align-self: center;
`;

const Card = ({
  title,
  subtitle,
  children,
  action,
  nested,
  alert,
  showTitle = true,
  dataTest,
  loading,
}) => {
  if (loading) {
    return (
      <Container>
        <Loading type="boxLoader" loadingText="Loading..." />
      </Container>
    );
  }
  return (
    <Container data-test={dataTest}>
      {showTitle && (
        <CardTitle nested={nested}>
          <Wrapper>
            <Heading type={nested ? 'title4' : 'title3'} as={Title}>
              {title}
            </Heading>

            {subtitle && (
              <Subtitle>
                <Text type="primary" weight="normal">
                  {subtitle}
                </Text>
              </Subtitle>
            )}
          </Wrapper>

          {action}
        </CardTitle>
      )}

      {alert && <AlertWrapper>{alert}</AlertWrapper>}

      {children && <Content nested={nested}>{children}</Content>}
    </Container>
  );
};

export default Card;
