import React, { useContext, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Text from '@kiwicom/orbit-components/lib/Text';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import ButtonPrimitive from '@kiwicom/orbit-components/lib/primitives/ButtonPrimitive';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import TripSummaryDetails from 'components/common/TripSummaryDetails';
import TripSummaryFlightDetails from 'components/common/TripSummaryFlightDetails';
import ItineraryTransferData from 'components/common/ItineraryTransferData';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import { calculateNightsLayover } from 'utils/dates';

import Card from '../../components/Card';
import { MMBState } from 'components/services/mmb';
import FlightChangeModal from './FlightChangeModal';

const HalfWidth = styled.div`
  width: 50%;
`;

const ChangeButton = ({ onClick, disabled }) => {
  const polyglot = usePolyglot();
  return (
    <Tooltip
      content={polyglot.t('mmb.booking_details.trip_summary.button.tooltip')}
      enabled={!!disabled}
    >
      <ButtonPrimitive
        dataTest="change-flight"
        background="transparent"
        onClick={onClick}
        disabled={disabled}
      >
        <Inline spacing="XSmall" align="center">
          <EditIcon size="small" color="success" />
          <Text type="success">{polyglot.t('mmb.booking_details.trip_summary.button.change')}</Text>
        </Inline>
      </ButtonPrimitive>
    </Tooltip>
  );
};

const ReturnTripSummary = ({ bookingData }) => {
  const polyglot = usePolyglot();
  return (
    <Stack direction="row" spacing="large" justify="between">
      <HalfWidth>
        <TripSummaryDetails
          title={polyglot.t('searchAndBooking.times.DEPARTURE')}
          type={bookingData.tripSummary.type}
          from={bookingData.itinerary.sectors[0].departure}
          to={bookingData.itinerary.sectors[0].arrival}
          carriers={bookingData.segments[0].map(s => s.carrier.code)}
          duration={bookingData.itinerary.sectors[0].duration}
          fullTrip={Object.values(bookingData.segments)}
          dataTest="trip-summary-departure"
        />

        <Separator spaceAfter="large" />

        <Text size="small" type="primary" weight="bold" spaceAfter="normal">
          {polyglot.t('searchAndBooking.times.DEPARTURE')}
        </Text>

        {bookingData.segments[0].map((segment, i) => (
          <Fragment key={i}>
            <TripSummaryFlightDetails
              vehicleType={segment.vehicleType}
              departurePlace={segment.departure.name}
              departureTime={segment.departure.time.local}
              arrivalPlace={segment.arrival.name}
              arrivalTime={segment.arrival.time.local}
              flightNumber={segment.flightNumber}
              duration={segment.duration}
              carrier={segment.carrier.code}
              carrierName={segment.carrier.name}
              fareCategory={segment.fare.category}
              scene="mmb"
              dataTest={`trip-summary-details-departure-${i}`}
            />

            {i !== bookingData.segments[0].length - 1 && (
              <ItineraryTransferData
                segment={segment}
                nextSegment={bookingData.segments[0][i + 1]}
              />
            )}
          </Fragment>
        ))}

        <ItineraryTransferData
          segment={bookingData.segments[0][bookingData.segments[0].length - 1]}
          nextSegment={bookingData.segments[1][0]}
        />
      </HalfWidth>

      <HalfWidth>
        <TripSummaryDetails
          title={polyglot.t('mmb.booking_details.trip_summary.return')}
          type={bookingData.tripSummary.type}
          from={bookingData.itinerary.sectors[1].departure}
          to={bookingData.itinerary.sectors[1].arrival}
          carriers={bookingData.segments[1].map(s => s.carrier.code)}
          duration={bookingData.itinerary.sectors[1].duration}
          dataTest="trip-summary-return"
        />
        <>
          <Separator spaceAfter="large" />
          <Text size="small" type="primary" weight="bold" spaceAfter="normal">
            {polyglot.t('mmb.booking_details.trip_summary.return')}
          </Text>
          {bookingData.segments[1].map((segment, i) => (
            <Fragment key={i}>
              <TripSummaryFlightDetails
                vehicleType={segment.vehicleType}
                departurePlace={segment.departure.name}
                departureTime={segment.departure.time.local}
                arrivalPlace={segment.arrival.name}
                arrivalTime={segment.arrival.time.local}
                flightNumber={segment.flightNumber}
                duration={segment.duration}
                carrier={segment.carrier.code}
                carrierName={segment.carrier.name}
                fareCategory={segment.fare.category}
                scene="mmb"
                dataTest={`trip-summary-details-return-${i}`}
              />

              {i !== bookingData.segments[1].length - 1 && (
                <ItineraryTransferData
                  segment={segment}
                  nextSegment={bookingData.segments[1][i + 1]}
                />
              )}
            </Fragment>
          ))}
        </>
      </HalfWidth>
    </Stack>
  );
};

const TripSummary = ({ bid }) => {
  const { state } = useContext(MMBState);
  const polyglot = usePolyglot();
  const flightChangeModalShown = useToggle();

  const bookingData = state.bookingsState[bid]?.bookingData;
  const passengers = state.bookingsState[bid]?.passengers;
  const affilId = state.bookingsState[bid]?.affilid;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itineraryType = useMemo(() => bookingData.itinerary.itineraryType, []);
  const isChangeButtonVisible = useMemo(
    () =>
      differenceInDays(
        new Date(bookingData.itinerary.sectors[0].departure.time.local),
        new Date(),
      ) >= 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (itineraryType === 'return') {
    return (
      <>
        {flightChangeModalShown.isOn && (
          <FlightChangeModal
            onClose={flightChangeModalShown.setOff}
            outbound={{
              arrival: bookingData.itinerary.sectors[0].arrival,
              carrier: bookingData.segments[0][0].carrier,
              departure: bookingData.itinerary.sectors[0].departure,
              duration: bookingData.itinerary.sectors[0].duration,
              id: bookingData.itinerary.sectors[0].id,
            }}
            inbound={{
              arrival: bookingData.itinerary.sectors[1].arrival,
              carrier: bookingData.segments[1][0].carrier,
              departure: bookingData.itinerary.sectors[1].departure,
              duration: bookingData.itinerary.sectors[1].duration,
              id: bookingData.itinerary.sectors[1].id,
            }}
            passengers={passengers}
            numberOfNights={calculateNightsLayover(
              bookingData.segments[0][0].arrival.time.local,
              bookingData.segments[1][0].departure.time.local,
            )}
            affilId={affilId}
          />
        )}
        <Card
          title={polyglot.t('mmb.booking_details.trip_summary')}
          action={isChangeButtonVisible && <ChangeButton onClick={flightChangeModalShown.setOn} />}
        >
          <ReturnTripSummary bookingData={bookingData} />
        </Card>
      </>
    );
  }

  const flattenedTrip = Object.values(bookingData.segments).reduce((acc, t) => {
    t.map(tr => acc.push(tr));
    return acc;
  }, []);

  return (
    <>
      {flightChangeModalShown.isOn && (
        <FlightChangeModal
          onClose={flightChangeModalShown.setOff}
          outbound={{
            arrival: bookingData.itinerary.sectors[0].arrival,
            carrier: bookingData.segments[0][0].carrier,
            departure: bookingData.itinerary.sectors[0].departure,
            duration: bookingData.itinerary.sectors[0].duration,
            id: bookingData.itinerary.sectors[0].id,
          }}
          passengers={passengers}
          affilId={affilId}
        />
      )}
      <Card
        title={polyglot.t('mmb.booking_details.trip_summary')}
        action={
          isChangeButtonVisible && (
            <ChangeButton
              onClick={flightChangeModalShown.setOn}
              disabled={itineraryType !== 'one_way'}
            />
          )
        }
      >
        <div>
          <TripSummaryDetails
            title={polyglot.t('searchAndBooking.times.DEPARTURE')}
            type={bookingData.tripSummary.type}
            from={bookingData.itinerary.sectors[0].departure}
            to={bookingData.itinerary.sectors[0].arrival}
            carriers={bookingData.segments[0].map(s => s.carrier.code)}
            duration={bookingData.itinerary.sectors[0].duration}
            fullTrip={Object.values(bookingData.segments)}
            dataTest="trip-summary-departure"
          />
          <HalfWidth>
            <Separator spaceAfter="large" />

            <Text size="small" type="primary" weight="bold" spaceAfter="normal">
              {polyglot.t('searchAndBooking.times.DEPARTURE')}
            </Text>

            {flattenedTrip.map((segment, i) => (
              <Fragment key={i}>
                <TripSummaryFlightDetails
                  vehicleType={segment.vehicleType}
                  departurePlace={segment.departure.name}
                  departureTime={segment.departure.time.local}
                  arrivalPlace={segment.arrival.name}
                  arrivalTime={segment.arrival.time.local}
                  flightNumber={segment.flightNumber}
                  duration={segment.duration}
                  carrier={segment.carrier.code}
                  carrierName={segment.carrier.name}
                  fareCategory={segment.fare.category}
                  dataTest={`trip-summary-details-departure-${i}`}
                  scene="mmb"
                />

                {i !== flattenedTrip.length - 1 && (
                  <ItineraryTransferData segment={segment} nextSegment={flattenedTrip[i + 1]} />
                )}
              </Fragment>
            ))}
          </HalfWidth>
        </div>
      </Card>
    </>
  );
};

export default React.memo(TripSummary);
