import React, { useMemo, useState } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import ChevronUpIcon from '@kiwicom/orbit-components/lib/icons/ChevronUp';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import PropTypes from 'prop-types';
import { useSearch } from 'components/services/search';

const DropdownButton = ({ content, buttonText, iconLeft, dataTest }) => {
  const [isPopoverShown, changeIsPopoverShown] = useState(false);
  const {
    tabData: { searchStatus },
  } = useSearch();

  const closePopover = () => {
    changeIsPopoverShown(false);
  };

  const openPopover = () => {
    changeIsPopoverShown(true);
  };

  const disabledPopover = useMemo(() => {
    const searchStatusValues = Object.values(searchStatus);
    const areSearchStatusValuesLoading = searchStatusValues.some(
      searchStatusValue => searchStatusValue === 'loading',
    );

    if (areSearchStatusValuesLoading) {
      return true;
    }

    return false;
  }, [searchStatus]);

  return (
    <Popover
      noPadding
      onClose={closePopover}
      onOpen={openPopover}
      content={!disabledPopover ? content : null}
    >
      <Button
        size="small"
        type="white"
        iconRight={!disabledPopover && isPopoverShown ? <ChevronUpIcon /> : <ChevronDownIcon />}
        iconLeft={iconLeft || undefined}
        dataTest={dataTest}
        disabled={!disabledPopover ? false : true}
      >
        {buttonText}
      </Button>
    </Popover>
  );
};

DropdownButton.propTypes = {
  content: PropTypes.any,
  buttonText: PropTypes.string,
  iconLeft: PropTypes.node,
};

export default DropdownButton;
