import React, { useEffect, useState } from 'react';
import { connect } from 'formik';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { usePolyglot } from 'components/services/i18n';
import useWidgetsContext from 'components/services/widgets';
import { callExternalWidgetToGetFormValues } from 'utils/widgets';

const SaveAsNewWidgetModal = ({ values, closeMe, companyName, widgetParam, resetWidgetParam }) => {
  const polyglot = usePolyglot();
  const [name, setName] = useState('');
  const {
    createNewWidget,
    state: { creatingWidget },
  } = useWidgetsContext();
  const [error, setError] = useState(false);

  const handleSave = () => {
    callExternalWidgetToGetFormValues('createNewWidget');
  };

  const canBeSaved = values?.general?.solutionName !== '' && values?.widgetType?.value !== '';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (widgetParam === 'createNewWidget') {
      if (canBeSaved) {
        createNewWidget(name, values, companyName)
          .then(() => {
            closeMe();
            setError(false);
          })
          .catch(() => {});
      } else {
        setError(true);
      }
      resetWidgetParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetParam]);

  return (
    <Modal size="small" closable onClose={closeMe}>
      <ModalHeader title={polyglot.t('widgets.create_new')} />
      <ModalSection>
        {creatingWidget.error && (
          <Alert spaceAfter="normal" type="critical">
            {polyglot.t(creatingWidget.error)}
          </Alert>
        )}
        {error && (
          <Alert spaceAfter="normal" type="critical">
            {polyglot.t('widgets.default.error')}
          </Alert>
        )}
        <InputField
          type="text"
          id="widgetName"
          name="widgetName"
          value={name}
          size="normal"
          label={<Text type={name && 'secondary'}>{polyglot.t('widgets.create_name')}</Text>}
          onChange={e => {
            setName(e.target.value);
          }}
          placeholder={polyglot.t('widgets.create_name_placeholder')}
        />
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button type="primarySubtle" onClick={closeMe}>
            {polyglot.t('common.cancel')}
          </Button>
          <Button disabled={!name} onClick={handleSave} loading={creatingWidget.inProgress}>
            {polyglot.t('common.save')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default connect(SaveAsNewWidgetModal);
