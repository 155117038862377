import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

const simpleTripTypeIcons = {
  OneWay: FlightDirectIcon,
  Return: FlightReturnIcon,
};

const IconWrapper = styled.div`
  display: inline-block;
  margin: 0 8px;
`;

const SimpleTitle = ({ tripData }) => {
  const Icon = simpleTripTypeIcons[tripData.tripType.value];

  return (
    <>
      {tripData.flights[0][0].departure.place.city}
      <IconWrapper>
        <Icon size="large" />
      </IconWrapper>
      {tripData.flights[0][tripData.flights[0].length - 1].arrival.place.city}
    </>
  );
};

const MultiCityNomadTitle = ({ tripData }) => {
  const title = useMemo(() => {
    if (!tripData.flights) {
      return null;
    }

    const { flights } = tripData;

    const deduplicatedFlightCities = [];

    const allDeparturesAndArrivals = flights
      .map(f => [f[0].departure.place.city, f[f.length - 1].arrival.place.city])
      .flat(Infinity);

    for (const city of allDeparturesAndArrivals) {
      if (deduplicatedFlightCities[deduplicatedFlightCities.length - 1] === city) {
        continue;
      }

      deduplicatedFlightCities.push(city);
    }

    const result = [];

    deduplicatedFlightCities.forEach((city, index) => {
      if (index === 0) {
        result.push(<Fragment key={index}>{city}</Fragment>);
        return;
      }

      result.push(
        <Fragment key={index}>
          <IconWrapper>
            <FlightDirectIcon />
          </IconWrapper>
          {city}
        </Fragment>,
      );
    });

    return result;
  }, [tripData]);

  return title;
};

const Title = ({ tripData }) => {
  return (
    <Heading>
      {simpleTripTypeIcons[tripData.tripType.value] ? (
        <SimpleTitle tripData={tripData} />
      ) : (
        <MultiCityNomadTitle tripData={tripData} />
      )}
    </Heading>
  );
};

export default Title;
