import React from 'react';
import styled from 'styled-components';

const Prefix = styled(({ children, className }) => <div className={className}>{children}</div>)`
  height: 100%;
  color: ${({ theme }) => theme.orbit.colorTextInputPrefix};
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  z-index: 3;
  width: 140px;
`;

export default Prefix;
