import styled, { css } from 'styled-components';

const hoverStyles = css`
  background: ${({ theme }) => theme.orbit.paletteCloudNormal};

  &:active {
    background: ${({ theme }) => theme.orbit.paletteInkLighter};
  }
`;

export const ItemContainer = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  list-style-type: none;
  height: 32px;
  padding: 0 32px;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.orbit.colorTextButtonSecondary};

  ${({ hover }) => hover && hoverStyles};
  ${({ readOnly }) => readOnly && 'cursor: default;'};
`;
