import { useContext } from 'react';

import { MMBState } from './';

const useBookingCheckboxes = () => {
  const {
    state: { checkboxIds },
    toggleBid,
    toggleAllBids,
  } = useContext(MMBState);

  return { checkboxIds, toggleBid, toggleAllBids };
};

export default useBookingCheckboxes;
