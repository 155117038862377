import React from 'react';

import useSearch from 'components/services/search/useSearch';
import { getIsIncrementDisabled } from 'utils/search';

import PassengerNumber from './PassengerNumber';
import { PassengerPopoverWrapper } from './index.styled';

const Passengers = () => {
  const { tabData, changeNumberOfPassengers } = useSearch();

  return (
    <PassengerPopoverWrapper>
      {tabData.passengersAmounts.map(passenger => (
        <PassengerNumber
          key={passenger.type}
          passengerType={passenger.type}
          amount={passenger.amount}
          isDecrementDisabled={passenger.amount === passenger.minAmount}
          isIncrementDisabled={getIsIncrementDisabled(tabData.passengersAmounts, passenger)}
          changeNumberOfPassengers={changeNumberOfPassengers}
        />
      ))}
    </PassengerPopoverWrapper>
  );
};

export default Passengers;
