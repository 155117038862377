import parseJSON from 'utils/parseJSON';

export const v1_get_to = ({ perPage, startDate, endDate, text, nextPageToken }) => ({
  page_size: perPage,
  ...(nextPageToken ? { next_page_token: nextPageToken } : {}),
  ...(startDate ? { timestamp_start: startDate } : {}),
  ...(endDate ? { timestamp_end: endDate } : {}),
  ...(text ? { text } : {}),
});

export const v1_get_from = data => {
  return {
    data: data.data.map(log => {
      const keys = Object.keys(log);
      const res = {};

      for (const key of keys) {
        const parsed = parseJSON(log[key]);

        res[key] = parsed || log[key];
      }

      return res;
    }),
    next_page_token: data.next_page_token,
  };
};

export default {
  v1: {
    get: {
      to: v1_get_to,
      from: v1_get_from,
    },
  },
};
