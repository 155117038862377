import * as R from 'ramda';
import { proxy } from 'utils/api';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export const STATUS = Object.freeze({
  CLEAR: '@@status.CLEAR',
  LOADING: '@@status.LOADING',
  VALID: '@@status.VALID',
  ERROR: '@@status.ERROR',
});

export const actionTypes = Object.freeze({
  REQUEST: 'common.redux.apigee.REQUEST',
  SUCCESS: 'common.redux.apigee.SUCCESS',
  ERROR: 'common.redux.apigee.ERROR',
  CLEAR: 'common.redux.apigee.CLEAR',
});

/**
 * Action creator for an Apigee request
 * @param {String} schemeName - unique name for binding with the reducer
 * @param {String} method - HTTP methods (GET, POST, PUT or DELETE)
 * @param {String} endpoint - URL endpoint
 * @param {Object} query - URL parameters
 * @param {Object} body
 * @param {String} contentType - defaults to application/json
 * @param {Object} headers
 */
export function request(schemeName, method, endpoint, query, body, contentType, headers) {
  return {
    type: actionTypes.REQUEST,
    meta: {
      schemeName,
      method,
      endpoint,
      query,
      body,
      contentType,
      headers,
    },
  };
}

/**
 * Action creator for successful Apigee request
 * @param {String} meta - meta object from REQUEST action
 * @param {object} response - JSON response from Apigee
 */
export function success(meta, response) {
  return {
    type: actionTypes.SUCCESS,
    payload: response,
    meta,
  };
}

/**
 * Action creator for failed Apigee request
 * @param {Object} meta - meta object from REQUEST action
 * @param {*} error - error which caused the request to fail
 */
export function error(meta, errorObject) {
  return {
    type: actionTypes.ERROR,
    error: errorObject,
    meta,
  };
}

/**
 * Action creator clears Apigee request
 * @param {String} schemeName - unique name for binding with the reducer
 */
export function clear(schemeName) {
  return {
    type: actionTypes.CLEAR,
    meta: { schemeName },
  };
}

function makeApigeeRequest({ method, endpoint, query, body, contentType, headers }) {
  switch (method) {
    case 'GET':
      return proxy.v1.get(endpoint, { params: query, headers });

    case 'POST':
      return proxy.v1.post(endpoint, body, {
        params: query,
        headers: { 'Content-Type': contentType, ...headers },
      });

    case 'PUT':
      return proxy.v1.put(endpoint, body, { headers: { 'Content-Type': contentType } });

    case 'DELETE':
      return proxy.v1.delete(endpoint);

    default:
      throw new Error(
        '"method" field in meta object must be on of HTTP methods GET, POST, PUT or DELETE',
      );
  }
}

export const createRequest =
  (schemeName, method) => (endpoint, query, body, contentType, headers) => dispatch => {
    const action = request(schemeName, method, endpoint, query, body, contentType, headers);
    dispatch(action);
    const { meta } = action;
    return makeApigeeRequest(meta)
      .then(res =>
        dispatch({
          type: actionTypes.SUCCESS,
          payload: res.json || res.data,
          meta,
        }),
      )
      .catch(errorObject => dispatch({ type: actionTypes.ERROR, errorObject, meta }));
  };

export const INITIAL_STATE = {
  status: {
    state: STATUS.CLEAR,
  },
  data: {},
};

export const createReducer =
  (schemeName, dataAdapter = (data, payload) => payload, errorAdapter = e => e) =>
  (state = INITIAL_STATE, action) => {
    if (!action.meta || (action.meta && action.meta.schemeName !== schemeName)) {
      return state;
    }
    switch (action.type) {
      case actionTypes.REQUEST:
        return {
          ...state,
          status: {
            state: STATUS.LOADING,
            dateTime: Date.now(),
            endpoint: action.endpoint,
          },
        };
      case actionTypes.SUCCESS:
        return {
          status: {
            state: STATUS.VALID,
            dateTime: Date.now(),
          },
          data: dataAdapter(state.data, action.payload),
        };
      case actionTypes.ERROR:
        return {
          ...state,
          status: {
            state: STATUS.ERROR,
            error: action.error,
            errorMessage: errorAdapter(action.payload),
          },
        };
      case actionTypes.CLEAR:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export function addTraits({ data = {}, status } = {}) {
  return {
    ...data,
    isClear() {
      return status.state === STATUS.CLEAR;
    },

    isValid() {
      return status.state === STATUS.VALID;
    },

    isLoading() {
      return status.state === STATUS.LOADING;
    },

    isError() {
      return status.state === STATUS.ERROR;
    },

    errorMessage() {
      return status.errorMessage;
    },

    shouldLoad() {
      return status.state === STATUS.CLEAR || status.state === STATUS.ERROR;
    },

    olderThan(seconds) {
      return differenceInSeconds(Date.now(), new Date(status.dateTime)) > seconds;
    },

    isEmpty() {
      return R.isEmpty(data);
    },
  };
}
