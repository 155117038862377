import React from 'react';
import PropTypes from 'prop-types';

import CheckCircle from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import AlertCircle from '@kiwicom/orbit-components/lib/icons/AlertCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import Polyglot from 'shapes/Polyglot';

const SolutionStatus = ({ status, polyglot }) => {
  switch (status) {
    case 'approved':
      return (
        <Tooltip preferredPosition="bottom" content={polyglot.t('solutions.enabled')}>
          <CheckCircle size="small" color="success" />
        </Tooltip>
      );
    case 'revoked':
      return (
        <Tooltip preferredPosition="bottom" content={polyglot.t('solutions.disabled')}>
          <AlertCircle size="small" color="critical" />
        </Tooltip>
      );
    default:
      return null;
  }
};

SolutionStatus.propTypes = {
  status: PropTypes.string,
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

export default SolutionStatus;
