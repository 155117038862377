export const WIDGET = {
  display_name: 'solutions.widget',
  name: 'widget',
  type: 'b2b2c',
  description: 'solutions.widget_description',
  products: [{ name: 'solutions.widget_2', product: 'Widget' }],
};
export const DEEPLINKS = {
  display_name: 'solutions.deeplinks',
  name: 'deeplinks',
  type: 'b2b2c',
  description: 'solutions.deeplinks_description',
  products: [{ name: 'solutions.deeplinks_2', product: 'Deeplinks and Banners' }],
};

export const META_SEARCH_API = {
  display_name: 'solutions.meta_search',
  name: 'meta_search',
  type: 'b2b2c',
  description: 'solutions.meta_search_description',
  products: [
    {
      name: 'solutions.one_way_return',
      product: 'Meta Search',
      description: 'solutions.one_way_return.description',
    },
    {
      name: 'solutions.multicity',
      product: 'flights-api-multicity',
      description: 'solutions.multicity.description',
    },
    {
      name: 'solutions.nomad',
      product: 'flights-api-nomad',
      description: 'solutions.nomad.description',
    },
  ],
};
export const SOLUTIONS = [WIDGET, DEEPLINKS, META_SEARCH_API];
