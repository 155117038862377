import React, { useReducer, useRef } from 'react';
import axios from 'axios';

import reportAliasesMapper from 'mappers/nextGen/reports/aliases/_';
import reportAliasMapper from 'mappers/nextGen/reports/aliases/{report_alias_id}/_';
import reportScheduleMapper from 'mappers/nextGen/reports/schedules/{report_schedule_id}/_';
import saveReportAliasMapper from 'mappers/nextGen/reports/aliases/_';
import saveReportScheduleMapper from 'mappers/nextGen/reports/aliases/{report_alias_id}/schedules/_';
import createReportInstanceMapper from 'mappers/nextGen/reports/instances/_';
import getReportInstaceMapper from 'mappers/nextGen/reports/instances/{report_instance_id}/_';
import schedulesReportAliasMapper from 'mappers/nextGen/reports/aliases/{report_alias_id}/schedules/_';
import companySchedulesReportMapper from 'mappers/nextGen/companies/{company_name}/reports/schedules/_';
import api, { getUrlWithQuery } from 'utils/api';
import { ALIAS_TYPES, REPORT_STATUS } from 'consts/newReports';
import {
  mapReportAlias,
  mapSchedules,
} from 'components/scenes/Reports/scenes/CreateReport/components/CreateReportHeader/utils';
import { checkDirtyFields, modifyObject } from 'utils/functions';
import { handleCSVDownload } from 'utils/files';

import { useCurrentUser } from '../auth';

export const defaultPagination = {
  perPage: 50,
  totalCount: 0,
  page: 1,
};

const defaultState = {};

export const ReportsState = React.createContext({
  state: {
    pagination: defaultPagination,
  },
  getReportAliases: async (isOnReportSchedules, pagination) => {},
  getReportDetails: async (reportAliasId, companyName) => {},
  saveReportAlias: async (reportAlias, onCallbackSuccess) => {},
  saveReportSchedules: async (reportAliasId, reportSchedules, onCallbackSuccess) => {},
  previewReportsData: async (isScheduledReport, reportType, reportAlias, onCallbackSuccess) => {},
  loadReport: async (reportAliasId, reportScheduleId, params) => {},
  resetReportAliasData: () => {},
  stopPollingReport: () => {},
  deleteReportAlias: async reportAliasId => {},
  updateReport: async (
    reportAliasId,
    scheduledReports,
    oldReportValues,
    newReportValues,
    onCallbackSuccess,
  ) => {},
  downloadReport: async reportAliasId => {},
});

const reportsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_REPORT_ALIASES':
      return {
        ...state,
        reportAliasesLoading: true,
        reportAliasesError: false,
        reportAliases: null,
      };
    case 'GET_REPORT_ALIASES_SUCCESS':
      return {
        ...state,
        reportAliasesLoading: false,
        reportAliasesError: false,
        reportAliases: {
          data: action.payload.reportAliasesData.data,
          pagination: action.payload.reportAliasesData.pagination,
        },
        reportSchedules: action.payload.schedulesData,
      };
    case 'GET_REPORT_ALIASES_FAILURE':
      return {
        ...state,
        reportAliasesLoading: false,
        reportAliasesError: action.error,
        reportAliases: null,
      };
    case 'GET_REPORT_DETAILS':
      return {
        ...state,
        reportDetailsLoading: true,
        reportDetailsData: null,
        reportDetailsError: null,
      };
    case 'GET_REPORT_DETAILS_SUCCESS':
      return {
        ...state,
        reportDetailsLoading: false,
        reportDetailsData: action.payload,
        reportDetailsError: null,
      };
    case 'GET_REPORT_DETAILS_FAIL':
      return {
        ...state,
        reportDetailsLoading: false,
        reportDetailsData: null,
        reportDetailsError: action.error,
      };
    case 'SAVE_REPORT_ALIAS':
      return {
        ...state,
        saveReportAliasLoading: true,
        saveReportAliasData: null,
        saveReportAliasError: null,
      };
    case 'SAVE_REPORT_ALIAS_SUCCESS':
      return {
        ...state,
        saveReportAliasLoading: false,
        saveReportAliasData: action.payload,
        saveReportAliasError: null,
      };
    case 'SAVE_REPORT_ALIAS_FAIL':
      return {
        ...state,
        saveReportAliasLoading: false,
        saveReportAliasData: null,
        saveReportAliasError: action.error,
      };
    case 'SAVE_REPORT_SCHEDULES':
      return {
        ...state,
        saveReportSchedulesLoading: true,
      };
    case 'SAVE_REPORT_SCHEDULES_SUCCESS':
      return {
        ...state,
        saveReportSchedulesLoading: false,
        failedReportSchedules: action.rejected,
      };
    case 'PREVIEW_REPORTS_DATA':
      return {
        ...state,
        previewReportsDataLoading: true,
        previewReportsDataError: null,
      };
    case 'PREVIEW_REPORTS_DATA_SUCCESS':
      return {
        ...state,
        previewReportsDataLoading: false,
        previewReportsDataError: null,
      };
    case 'PREVIEW_REPORTS_DATA_FAIL':
      return {
        ...state,
        previewReportsDataLoading: false,
        previewReportsDataError: action.error,
      };
    case 'LOAD_REPORT':
      return {
        ...state,
        loadReportData: null,
        loadReportLoading: true,
        loadReportError: null,
      };
    case 'LOAD_REPORT_SUCCESS':
      return {
        ...state,
        loadReportData: {
          data: action.payload,
          pagination: action.pagination,
        },
        loadReportLoading: false,
        loadReportError: null,
      };
    case 'LOAD_REPORT_FAIL':
      return {
        ...state,
        loadReportData: null,
        loadReportLoading: false,
        loadReportError: action.error,
      };
    case 'START_POLLING_REPORT':
      return {
        ...state,
        shouldPollReport: true,
      };
    case 'STOP_POLLING_REPORT': {
      return {
        ...state,
        shouldPollReport: false,
      };
    }
    case 'RESET_REPORT_ALIAS_DATA':
      return defaultState;
    case 'DELETE_REPORT_ALIAS':
      return {
        ...state,
        deleteReportAliasLoading: true,
        deleteReportAliasError: null,
      };
    case 'DELETE_REPORT_ALIAS_SUCCESS':
      return {
        ...state,
        deleteReportAliasLoading: false,
        deleteReportAliasError: null,
      };
    case 'DELETE_REPORT_ALIAS_FAIL':
      return {
        ...state,
        deleteReportAliasLoading: false,
        deleteReportAliasError: action.error,
      };
    case 'UPDATE_REPORT':
      return {
        ...state,
        updateReportLoading: true,
        updateReportError: null,
      };
    case 'UPDATE_REPORT_SUCCESS':
      return {
        ...state,
        updateReportLoading: false,
        updateReportError: null,
      };
    case 'UPDATE_REPORT_FAIL':
      return {
        ...state,
        updateReportLoading: false,
        updateReportError: action.error,
      };
    case 'DOWNLOAD_REPORT':
      return {
        ...state,
        downloadReportLoading: true,
        downloadReportError: null,
      };
    case 'DOWNLOAD_REPORT_FAIL':
      return {
        ...state,
        downloadReportLoading: false,
        downloadReportError: action.error,
      };
    case 'DOWNLOAD_REPORT_SUCCESS':
      return {
        ...state,
        downloadReportLoading: false,
        downloadReportError: null,
      };
    default:
      return defaultState;
  }
};

const ReportsProvider = ({ children }) => {
  const pollTimeoutId = useRef(null);
  const [state, dispatch] = useReducer(reportsReducer, defaultState);
  const user = useCurrentUser();

  const getReportAliases = async (isOnReportSchedules, pagination) => {
    dispatch({ type: 'GET_REPORT_ALIASES' });
    try {
      const { data } = await api.v2.get(
        `reports/aliases`,
        reportAliasesMapper.v2.get.to(pagination),
      );

      const reportAliasesData = reportAliasesMapper.v2.get.from(data.data, data.pagination);
      let schedulesData = [];

      if (isOnReportSchedules && !!reportAliasesData.data.length) {
        const reportAliasesIds = reportAliasesData.data.map(report => report.id);

        const schedulesQuery = getUrlWithQuery(
          '',
          companySchedulesReportMapper.v1.get.to(reportAliasesIds),
          {
            arrayFormat: 'comma',
          },
        );

        const { data: schedules } = await api.v1.get(
          `companies/${user.companyName}/reports/schedules/${schedulesQuery}`,
        );

        schedulesData = companySchedulesReportMapper.v1.get.from(schedules);
      }

      dispatch({
        type: 'GET_REPORT_ALIASES_SUCCESS',
        payload: { reportAliasesData, schedulesData },
      });
    } catch (err) {
      dispatch({
        type: 'GET_REPORT_ALIASES_FAILURE',
        error: err,
      });
    }
  };

  const getReportDetails = async (reportAliasId, companyName, isOnReportSchedules) => {
    dispatch({ type: 'GET_REPORT_DETAILS' });
    try {
      let retScheduledReports = [];
      const { data: reportAlias } = await api.v2.get(`reports/aliases/${reportAliasId}`);

      const mappedReportAlias = reportAliasMapper.v2.get.from(reportAlias);

      if (isOnReportSchedules) {
        const { data: reportSchedules } = await api.v2.get(
          `reports/aliases/${reportAliasId}/schedules`,
          schedulesReportAliasMapper.v2.get.to(companyName),
        );

        const mappedReportSchedules = schedulesReportAliasMapper.v2.get.from(reportSchedules);

        retScheduledReports = mappedReportSchedules;
      }

      dispatch({
        type: 'GET_REPORT_DETAILS_SUCCESS',
        payload: {
          scheduledReports: retScheduledReports,
          reportAlias: mappedReportAlias,
        },
      });
    } catch (error) {
      dispatch({ type: 'GET_REPORT_DETAILS_FAIL', error });
    }
  };

  const saveReportAlias = async (reportAlias, onCallbackSuccess) => {
    dispatch({ type: 'SAVE_REPORT_ALIAS' });
    try {
      const { data } = await api.v2.post(
        'reports/aliases',
        saveReportAliasMapper.v2.post.to(reportAlias),
      );

      dispatch({
        type: 'SAVE_REPORT_ALIAS_SUCCESS',
        payload: {
          reportAliasId: data.id,
        },
      });

      const savedReportAlias = saveReportAliasMapper.v2.post.from(data);

      if (onCallbackSuccess) {
        onCallbackSuccess(savedReportAlias.id);
      }
    } catch (error) {
      dispatch({ type: 'SAVE_REPORT_ALIAS_FAIL', error });
    }
  };

  const saveReportSchedules = async (reportAlias, reportSchedules, onCallbackSuccess) => {
    dispatch({ type: 'SAVE_REPORT_ALIAS' });
    try {
      const { data } = await api.v2.post(
        'reports/aliases',
        saveReportAliasMapper.v2.post.to(reportAlias),
      );

      dispatch({
        type: 'SAVE_REPORT_ALIAS_SUCCESS',
        payload: {
          reportAliasId: data.id,
        },
      });

      const savedReportAlias = saveReportAliasMapper.v2.post.from(data);

      dispatch({ type: 'SAVE_REPORT_SCHEDULES' });

      const reportSchedulesResponse = await Promise.allSettled(
        reportSchedules.map(reportSchedule =>
          api.v2.post(
            `reports/aliases/${data.id}/schedules`,
            saveReportScheduleMapper.v2.post.to(reportSchedule),
          ),
        ),
      );

      const fulfilled = [];
      const rejected = [];
      for (let i = 0; i < reportSchedules.length; i++) {
        if (reportSchedulesResponse[i].status === 'rejected') {
          rejected.push({
            frequency: `new_reports.report_form.scheduled_delivery.${reportSchedules[i].frequency}.label`,
            reason: reportSchedulesResponse[i].reason,
          });
        } else {
          fulfilled.push({
            scheduleId: reportSchedulesResponse[i].value.data.id,
          });
        }
      }

      dispatch({ type: 'SAVE_REPORT_SCHEDULES_SUCCESS', rejected });

      if (onCallbackSuccess && !rejected.length) {
        onCallbackSuccess(
          savedReportAlias.id,
          fulfilled.map(fulfilledItem => fulfilledItem.scheduleId),
        );
      }
    } catch (error) {
      dispatch({ type: 'SAVE_REPORT_ALIAS_FAIL', error });
    }
  };

  const previewReportsData = async (
    isScheduledReport,
    reportType,
    reportAlias,
    onCallbackSuccess,
  ) => {
    dispatch({ type: 'PREVIEW_REPORTS_DATA' });
    try {
      const mappedReportAlias = mapReportAlias(reportAlias, reportType, ALIAS_TYPES.DEFAULT);

      const { data } = await api.v2.post(
        'reports/aliases',
        saveReportAliasMapper.v2.post.to(mappedReportAlias),
      );

      const savedReportAlias = saveReportAliasMapper.v2.post.from(data);

      if (!isScheduledReport) {
        if (onCallbackSuccess) {
          onCallbackSuccess(savedReportAlias.id);
        }
      } else {
        const mappedReportSchedules = mapSchedules(reportAlias);

        const reportSchedulesResponse = await Promise.allSettled(
          mappedReportSchedules.map(reportSchedule =>
            api.v2.post(
              `reports/aliases/${data.id}/schedules`,
              saveReportScheduleMapper.v2.post.to(reportSchedule),
            ),
          ),
        );

        const fulfilled = [];
        for (let i = 0; i < mappedReportSchedules.length; i++) {
          if (reportSchedulesResponse[i].status === 'fulfilled') {
            fulfilled.push({
              scheduleId: reportSchedulesResponse[i].value.data.id,
              frequency: reportSchedulesResponse[i].value.data.frequency,
            });
          }
        }

        if (fulfilled.length === mappedReportSchedules.length) {
          if (onCallbackSuccess) {
            onCallbackSuccess(savedReportAlias.id, fulfilled);
          }
        } else {
          throw new Error();
        }
      }

      dispatch({ type: 'PREVIEW_REPORTS_DATA_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'PREVIEW_REPORTS_DATA_FAIL', error });
    }
  };

  const loadReport = async (reportAliasId, reportScheduleId, params) => {
    dispatch({ type: 'LOAD_REPORT' });
    try {
      const { data: createReportInstanceResponse } = await api.v2.post(
        'reports/instances',
        createReportInstanceMapper.v2.post.to(reportAliasId, reportScheduleId),
      );

      const reportInstance = createReportInstanceMapper.v2.post.from(createReportInstanceResponse);

      dispatch({ type: 'START_POLLING_REPORT' });

      if (
        reportInstance.reportStatus === REPORT_STATUS.AVAILABLE ||
        reportInstance.reportStatus === REPORT_STATUS.DISPATCHED
      ) {
        if (state.shouldPollReport) {
          stopPollingReport();
        }

        const { data: getReportInstanceResponse, headers } = await api.v2.get(
          `reports/instances/${reportInstance.reportId}`,
          getReportInstaceMapper.v2.get.to(params),
        );

        dispatch({
          type: 'LOAD_REPORT_SUCCESS',
          payload: {
            ...getReportInstaceMapper.v2.get.from(getReportInstanceResponse.data),
            reportInstanceId: reportInstance.reportId,
          },
          pagination: {
            page: params?.page || 1,
            pagesCount: Number(headers?.['x-pagination-page-count']),
            perPage: Number(headers?.['x-pagination-per-page']),
            totalCount: Number(headers?.['x-pagination-row-count']),
          },
        });
      } else {
        pollTimeoutId.current = setTimeout(() => {
          loadReport(reportAliasId, reportScheduleId, params);
        }, 5000);
      }
    } catch (error) {
      dispatch({ type: 'LOAD_REPORT_FAIL', error });
    }
  };

  const stopPollingReport = () => {
    clearTimeout(pollTimeoutId.current);
    dispatch({ type: 'STOP_POLLING_REPORT' });
  };

  const resetReportAliasData = () => {
    dispatch({ type: 'RESET_REPORT_ALIAS_DATA' });
  };

  const deleteReportAlias = async reportAliasId => {
    dispatch({ type: 'DELETE_REPORT_ALIAS' });
    try {
      await api.v2.delete(`reports/aliases/${reportAliasId}`);

      dispatch({ type: 'DELETE_REPORT_ALIAS_SUCCESS', reportAliasId });
    } catch (error) {
      dispatch({ type: 'DELETE_REPORT_ALIAS_FAIL', error });
    }
  };

  const updateReport = async (
    reportAliasId,
    scheduledReports,
    oldReportValues,
    newReportValues,
    onCallbackSuccess,
  ) => {
    dispatch({ type: 'UPDATE_REPORT' });
    try {
      const dirtyFields = checkDirtyFields(oldReportValues, newReportValues);
      const reportAlias = modifyObject(dirtyFields, 'exclude', [
        'dailySchedule',
        'weeklySchedule',
        'monthlySchedule',
      ]);
      const timeRangeType = newReportValues.timeRangeType;
      let reportSchedulesActions = {
        toCreate: [],
        toUpdate: [],
        toDelete: [],
      };

      await api.v2.put(
        `reports/aliases/${reportAliasId}`,
        reportAliasMapper.v2.put.to(reportAlias, newReportValues),
      );

      if (timeRangeType === 'scheduled') {
        const reportSchedules = modifyObject(dirtyFields, 'include', [
          'dailySchedule',
          'weeklySchedule',
          'monthlySchedule',
        ]);
        let mappedReportSchedules = {};

        Object.keys(reportSchedules).forEach(reportScheduleKey => {
          const changedFields = checkDirtyFields(
            oldReportValues[reportScheduleKey],
            reportSchedules[reportScheduleKey],
          );
          const changedFieldsKeys = Object.keys(changedFields);
          if (changedFieldsKeys.length && reportScheduleKey === 'dailySchedule') {
            mappedReportSchedules = {
              ...mappedReportSchedules,
              daily: changedFields,
            };
          } else if (changedFieldsKeys.length && reportScheduleKey === 'weeklySchedule') {
            mappedReportSchedules = {
              ...mappedReportSchedules,
              weekly: changedFields,
            };
          } else if (changedFieldsKeys.length && reportScheduleKey === 'monthlySchedule') {
            mappedReportSchedules = {
              ...mappedReportSchedules,
              monthly: changedFields,
            };
          }
        });

        if (Object.keys(mappedReportSchedules).length) {
          Object.keys(mappedReportSchedules).forEach(mappedReportScheduleKey => {
            const reportScheduleToUpdate = scheduledReports.find(
              scheduledReport => scheduledReport.frequency === mappedReportScheduleKey,
            );

            if (reportScheduleToUpdate) {
              reportSchedulesActions = {
                ...reportSchedulesActions,
                toUpdate: [
                  ...reportSchedulesActions.toUpdate,
                  {
                    scheduleId: reportScheduleToUpdate.scheduleId,
                    ...mappedReportSchedules[mappedReportScheduleKey],
                  },
                ],
              };
            } else {
              reportSchedulesActions = {
                ...reportSchedulesActions,
                toCreate: [
                  ...reportSchedulesActions.toCreate,
                  {
                    ...mappedReportSchedules[mappedReportScheduleKey],
                    frequency: mappedReportScheduleKey,
                  },
                ],
              };
            }
          });
        }
      } else if (scheduledReports.length) {
        reportSchedulesActions = {
          ...reportSchedulesActions,
          toDelete: scheduledReports,
        };
      }

      if (reportSchedulesActions.toCreate.length) {
        await Promise.all(
          reportSchedulesActions.toCreate.map(reportScheduleToCreate =>
            api.v2.post(
              `reports/aliases/${reportAliasId}/schedules`,
              saveReportScheduleMapper.v2.post.to(reportScheduleToCreate),
            ),
          ),
        );
      }

      if (reportSchedulesActions.toUpdate.length) {
        await Promise.all(
          reportSchedulesActions.toUpdate.map(reportScheduleToUpdate =>
            api.v2.put(
              `reports/schedules/${reportScheduleToUpdate.scheduleId}`,
              reportScheduleMapper.v2.put.to(reportScheduleToUpdate),
            ),
          ),
        );
      }

      if (reportSchedulesActions.toDelete.length) {
        await Promise.all(
          reportSchedulesActions.toDelete.map(reportScheduleToDelete =>
            api.v2.delete(`reports/schedules/${reportScheduleToDelete.scheduleId}`),
          ),
        );
      }

      dispatch({ type: 'UPDATE_REPORT_SUCCESS' });

      if (onCallbackSuccess) {
        onCallbackSuccess();
      }
    } catch (error) {
      dispatch({ type: 'UPDATE_REPORT_FAIL', error });
    }
  };

  const downloadReport = async (reportInstanceId, fileName) => {
    dispatch({ type: 'DOWNLOAD_REPORT' });
    try {
      const { data } = await api.v2.post(`reports/instances/${reportInstanceId}/export`);

      if (data === REPORT_STATUS.AVAILABLE) {
        if (state.shouldPollReport) {
          stopPollingReport();
        }

        const { data: downloadUrl } = await api.v2.get(
          `reports/instances/${reportInstanceId}/export`,
          {
            withCredentials: false,
          },
        );

        const { data: downloadData } = await axios({ method: 'GET', url: downloadUrl });

        handleCSVDownload(downloadData, fileName);

        dispatch({
          type: 'DOWNLOAD_REPORT_SUCCESS',
        });
      } else {
        pollTimeoutId.current = setTimeout(() => {
          downloadReport(reportInstanceId, fileName);
        }, 5000);
      }
    } catch (error) {
      dispatch({ type: 'DOWNLOAD_REPORT_FAIL', error });
    }
  };

  const value = {
    ...state,
    getReportAliases,
    getReportDetails,
    saveReportAlias,
    saveReportSchedules,
    previewReportsData,
    loadReport,
    resetReportAliasData,
    stopPollingReport,
    deleteReportAlias,
    updateReport,
    downloadReport,
  };

  return <ReportsState.Provider value={value}>{children}</ReportsState.Provider>;
};

export default ReportsProvider;
