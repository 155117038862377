import { useContext } from 'react';

import { BookingToolState } from './';

const useBookingToolTabs = () => {
  const { tabs } = useContext(BookingToolState);

  return tabs;
};

export default useBookingToolTabs;
