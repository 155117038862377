import React, { useEffect } from 'react';

import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import {
  ActivityTable,
  CreditBalanceHeader,
  CreditActivityFilters,
  NoResults,
  NoTransactions,
  ErrorApi,
  ErrorFilters,
} from './components';
import useTransactions from 'components/services/transactions/useTransactions';

const CreditActivityShell = ({ children }) => {
  return (
    <>
      <CreditBalanceHeader />
      <Card>{children}</Card>
    </>
  );
};

const CreditActivity = () => {
  const {
    transactions,
    loadingTransactions,
    loadingMoreTransactions,
    hasMoreTransactions,
    filterBid,
    filterCreatedFrom,
    filterCreatedTo,
    filterTypes,
    error,
    loadTransactions,
    loadMoreTransactions,
    resetFilters,
  } = useTransactions();

  useEffect(() => {
    handleLoadTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBid, filterCreatedFrom, filterCreatedTo, filterTypes.join('')]);

  const handleLoadTransactions = () => {
    const filters = {
      bid: filterBid,
      createdFrom: filterCreatedFrom,
      createdTo: filterCreatedTo,
      transactionTypes: filterTypes,
    };

    loadTransactions(filters);
  };

  const handleLoadMore = async () => {
    const filters = {
      bid: filterBid,
      createdFrom: filterCreatedFrom,
      createdTo: filterCreatedTo,
      transactionTypes: filterTypes,
    };

    await loadMoreTransactions(filters);
  };

  const hasFilters =
    !!filterBid || !!filterCreatedFrom || !!filterCreatedTo || !!filterTypes?.length;

  if (error && hasFilters) {
    return (
      <CreditActivityShell>
        <CardSection>
          <CreditActivityFilters />
        </CardSection>
        <CardSection>
          <ErrorFilters onClick={handleLoadTransactions} />
        </CardSection>
      </CreditActivityShell>
    );
  }

  if (error && !hasFilters) {
    return (
      <CreditActivityShell>
        <CardSection>
          <ErrorApi />
        </CardSection>
      </CreditActivityShell>
    );
  }

  if (!transactions?.length && !hasFilters && !loadingTransactions) {
    return (
      <CreditActivityShell>
        <CardSection>
          <NoTransactions />
        </CardSection>
      </CreditActivityShell>
    );
  }

  if (!transactions?.length && hasFilters && !loadingTransactions) {
    return (
      <CreditActivityShell>
        <CardSection>
          <CreditActivityFilters />
        </CardSection>
        <CardSection>
          <NoResults onClick={resetFilters} />
        </CardSection>
      </CreditActivityShell>
    );
  }

  return (
    <CreditActivityShell>
      <CardSection>
        <CreditActivityFilters />
      </CardSection>
      <CardSection>
        <ActivityTable
          transactions={transactions}
          loading={loadingTransactions}
          loadingMore={loadingMoreTransactions}
          loadMore={handleLoadMore}
          nextPage={hasMoreTransactions}
        />
      </CardSection>
    </CreditActivityShell>
  );
};

export default CreditActivity;
