import { useEffect, useContext } from 'react';

import { MMBState } from 'components/services/mmb';

const usePriceChanges = bid => {
  const { state, getPriceChangeInfo } = useContext(MMBState);

  useEffect(() => {
    if (bid) {
      getPriceChangeInfo(bid);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  return (
    {
      data: state.bookingsState[bid]?.priceChange,
      loading: state.bookingsState[bid]?.loadingPriceChange,
      payingPriceChange: state.bookingsState[bid]?.payingPriceChange,
      error: state.bookingsState[bid]?.loadingPriceChangeError,
    } || {
      data: null,
      loading: false,
      error: null,
    }
  );
};

export default usePriceChanges;
