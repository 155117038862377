const v1_get_from = (issueKey, data) => {
  return {
    issueKey: issueKey,
    requestType: data.request_type.requestType.name,
    status: data.status.name,
    summary: data.summary,
    description: data.description,
    reporter: data.reporter ? data.reporter.displayName : 'N/A',
    createdAt: data.created,
    comments: data.comment.comments.map(comment => {
      const isSvcJira = comment.author.displayName.replace(/\s/g, '') === 'svcjira';
      const tempComment = isSvcJira
        ? {
            author: comment.body.split(':')[0],
            body: comment.body.split(':').slice(1).join(':'),
          }
        : {
            author: comment.author.displayName,
            body: comment.body,
          };

      return {
        id: comment.id,
        createdAt: comment.created,
        ...tempComment,
      };
    }),
    attachments: data.attachment.map(attachmentItem => ({
      id: attachmentItem.id,
      fileName: attachmentItem.filename,
      mimeType: attachmentItem.mimeType,
    })),
    bid: data?.bid,
    mmbCaseLink: data?.mmb_case_link,
    slaClass: data?.sla_class?.value,
    subcategory: data?.subcategory?.value,
    tequilaCompanyUrl: data?.tequila_company_url,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
