import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import { ClickOutside } from 'common';

import {
  AutocompleteWrapper,
  DropdownIconContainer,
  ListChoiceContainer,
} from './Autocomplete.styled';

const ITEM_SIZE = 43;
const MAX_HEIGHT = 400;
const NO_RES_VALUE = '@@no_results';

const AutoComplete = ({
  options,
  size,
  onSelect,
  noResultsText,
  label,
  value,
  fullWidth,
  loading,
}) => {
  const [filterText, setFilterText] = useState(value || '');
  const [menuOpen, setMenuOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!value) {
      setFilterText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const filteredOptions = useMemo(() => {
    const optionsList = options || [];
    const result = [];

    if (!filterText) {
      return optionsList;
    }

    for (let i = 0; i < optionsList.length; i++) {
      const el = optionsList[i];

      if (el.label.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
        result.push(el);
      }
    }

    return result;
  }, [options, filterText]);

  const handleSelectCompany = useCallback(
    item => {
      setFilterText(item.label);
      setMenuOpen(false);
      onSelect(item);
      inputRef.current.value = item.label;
    },
    [onSelect],
  );

  const Row = ({ data, index, style }) => {
    return (
      <div style={style}>
        <ListChoice
          title={data[index].label}
          onClick={() => {
            if (data[index].value !== NO_RES_VALUE) {
              handleSelectCompany(data[index]);
            }
          }}
        />
      </div>
    );
  };

  const itemData = filteredOptions.length
    ? filteredOptions
    : [{ label: noResultsText, value: NO_RES_VALUE }];

  return (
    <AutocompleteWrapper fullWidth={fullWidth}>
      <ClickOutside
        onClick={() => {
          setMenuOpen(false);
          setFilterText(value);
        }}
      >
        <InputField
          size={size}
          label={label}
          prefix={loading && <Loading loading={loading} type="inlineLoader" />}
          suffix={
            <DropdownIconContainer open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
              <ChevronDownIcon />
            </DropdownIconContainer>
          }
          onFocus={() => {
            setFilterText('');
            setMenuOpen(true);
          }}
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          ref={inputRef}
        />
        {menuOpen && (
          <ListChoiceContainer menuHeight={MAX_HEIGHT} size={size} fullWidth={fullWidth}>
            <FixedSizeList
              itemData={itemData}
              itemCount={itemData.length}
              height={
                itemData.length * ITEM_SIZE > MAX_HEIGHT ? MAX_HEIGHT : itemData.length * ITEM_SIZE
              }
              itemSize={ITEM_SIZE}
            >
              {Row}
            </FixedSizeList>
          </ListChoiceContainer>
        )}
      </ClickOutside>
    </AutocompleteWrapper>
  );
};

AutoComplete.defaultProps = {
  size: 'normal',
  onSelect: () => {},
};

AutoComplete.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  noResultsText: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default React.memo(AutoComplete);
