import {
  DeliveryClient,
  TypeResolver,
} from 'kentico-cloud-delivery/_bundles/kentico-cloud-delivery-sdk.browser.umd.min';

import { projectId, previewApiKey, CONTENT_TYPES } from 'consts/kentico';

import {
  SwaggerDocumentation,
  Legal,
  Article,
  QuickGuide,
  UpsellCard,
  NewsArticle,
  NewsAPIArticle,
} from './resolvers';

const client = new DeliveryClient({
  projectId,
  previewApiKey,
  typeResolvers: [
    new TypeResolver(CONTENT_TYPES.SWAGGER_DOCUMENTATION.single, () => new SwaggerDocumentation()),
    new TypeResolver(CONTENT_TYPES.LEGAL.single, () => new Legal()),
    new TypeResolver(CONTENT_TYPES.ARTICLE.single, () => new Article()),
    new TypeResolver(CONTENT_TYPES.QUICK_GUIDES.single, () => new QuickGuide()),
    new TypeResolver(CONTENT_TYPES.UPSELL_CARD.single, () => new UpsellCard()),
    new TypeResolver(CONTENT_TYPES.NEWS_ARTICLE.single, () => new NewsArticle()),
    new TypeResolver(CONTENT_TYPES.NEWS_API_ARTICLE.single, () => new NewsAPIArticle()),
  ],
});

export default client;
