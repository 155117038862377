import React, { useMemo } from 'react';
import {
  Heading,
  Stack,
  CountryFlag,
  TextLink,
  Button,
  Inline,
  Tooltip,
} from '@kiwicom/orbit-components';
import CakeIcon from '@kiwicom/orbit-components/lib/icons/Cake';
import PassportIcon from '@kiwicom/orbit-components/lib/icons/Passport';
import CopyIcon from '@kiwicom/orbit-components/lib/icons/Copy';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';

import PaxGenderInfo, {
  getPaxGender,
} from 'components/scenes/ManageMyBookings/scenes/BookingDetails/components/PaxGenderInfo';
import { formatBirthDate } from 'utils/dates';
import { capitalize } from 'utils/string';
import countries from 'consts/countries';
import copyToClipboard from 'utils/copyToClipboard';
import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';
import { Authorization } from 'common/Authorization';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';

import { Section, Row, Actions } from './index.styled';
import RequestEditPassengerModal from './RequestEditPassengerModal';

const formatPassengerToCopy = p => {
  const nationality = countries.find(
    n => n.id.toLowerCase() === p.nationality.toLowerCase(),
  )?.label;
  const gender = getPaxGender(p.title);

  const output = [
    `Full Name: ${p.fullName}`,
    nationality && `Nationality: ${nationality}`,
    gender && `Gender: ${capitalize(gender)}`,
    `Birth date: ${p.birthDate}`,
    p.documentNumber && `Document Number: ${p.documentNumber}`,
    p.documentExpiry && `Document Expiry: ${p.documentExpiry}`,
  ]
    .filter(Boolean)
    .join('\n');

  return output;
};

const PassengerInfo = ({ passenger, handlePassengerEdit, bid, itinerary }) => {
  const copied = useToggle();
  const polyglot = usePolyglot();
  const isRequestEdit = useToggle();
  const companyName = useUsersCompanyName();

  const handleCopyClick = () => {
    copyToClipboard(formatPassengerToCopy(passenger));

    copied.setOn();

    setTimeout(() => {
      copied.setOff();
    }, 2000);
  };

  const nationalityLabel = useMemo(
    () => countries.find(n => n.value.toLowerCase() === passenger.nationality.toLowerCase())?.label,
    [passenger.nationality],
  );

  const missingWholeDocument = !passenger.documentNumber && !passenger.documentExpiry;

  return (
    <>
      {isRequestEdit.isOn && (
        <RequestEditPassengerModal
          passenger={passenger}
          onClose={isRequestEdit.setOff}
          itinerary={itinerary}
          bid={bid}
        />
      )}
      <Section>
        <Stack direction="column" spaceAfter="large" spacing="medium" shrink>
          <Inline spacing="small" align="center">
            <Heading type="title3">
              {polyglot.t('mmb.booking_details.passengers.personal_details.title')}
            </Heading>
            <Tooltip
              placement="top"
              size="small"
              content={copied.isOn ? 'Copied!' : 'Copy to clipboard'}
            >
              <TextLink standAlone size="small" type="secondary" onClick={handleCopyClick}>
                <CopyIcon />
              </TextLink>
            </Tooltip>
          </Inline>
          <Row>
            <CountryFlag
              code={passenger.nationality}
              name={passenger.nationality}
              size="small"
              dataTest="pax-country"
            />
            {nationalityLabel}
          </Row>
          <PaxGenderInfo
            category={passenger.category}
            title={passenger.title}
            dataTest="pax-gender"
          />
          <Row>
            <CakeIcon size="small" color="secondary" /> {formatBirthDate(passenger.birthDate)}
          </Row>
          {!missingWholeDocument && (
            <Row data-test="document-numbers">
              <PassportIcon size="small" color="secondary" />{' '}
              {`${passenger.documentNumber} • ${passenger.documentExpiry || 'No expiry'}`}
            </Row>
          )}
        </Stack>
        <Actions>
          <Authorization
            action={['read']}
            resource="fe.page.customer_support"
            companyName={companyName}
          >
            <TextLink
              standAlone
              size="small"
              type="primary"
              iconLeft={<EditIcon />}
              onClick={isRequestEdit.setOn}
            >
              {polyglot.t('mmb.booking_details.passengers.personal_details.button.edit_details')}
            </TextLink>
          </Authorization>
          {true && (
            <Button
              size="small"
              type="primarySubtle"
              onClick={handlePassengerEdit}
              dataTest="add-document"
            >
              {polyglot.t('mmb.booking_details.passengers.add_document')}
            </Button>
          )}
        </Actions>
      </Section>
    </>
  );
};

export default PassengerInfo;
