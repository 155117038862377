import React from 'react';
import { useHistory } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

import Button from '@kiwicom/orbit-components/lib/Button';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { ROUTE_CONFIG } from 'consts/routes';
import { useCurrentUser } from 'components/services/auth';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import usePolyglot from 'components/services/i18n/usePolyglot';
import useBookingFlow from 'components/services/booking/useBookingFlow';
import { getSessionStorageItem } from 'utils/sessionStorage';
import { BOOKING_FLOW_SESSION_ID } from 'consts';
import { getLast20CharsBookingToken } from 'utils/prebooking';

//TODO: Update orbit and make modal nonscrollable
const PriceChangeModal = ({ amount, currency, eventId, apiKey, bookingToken }) => {
  const history = useHistory();
  const user = useCurrentUser();
  const { data, loading: loadingDeposit } = useCompanyDeposit(user.companyName);
  const symbol = getSymbolFromCurrency(currency);
  const polyglot = usePolyglot();
  const {
    resolveActivePrebookingSessionEvent,
    prebookingSession: { loading: prebookingSessionLoading },
  } = useBookingFlow();
  const sessionId = getSessionStorageItem(
    `${BOOKING_FLOW_SESSION_ID}_${getLast20CharsBookingToken(bookingToken)}`,
  );

  return (
    <Modal hasCloseButton={false} preventOverlayClose dataTest="price-change-modal">
      {!loadingDeposit && (
        <>
          <ModalHeader
            illustration={<Illustration name="Time" size="small" />}
            title={
              <Heading>
                {polyglot.t('booking_tool.price_change.title', { symbol, price: amount })}
              </Heading>
            }
            description={polyglot.t('booking_tool.price_change.description')}
          />
          <ModalFooter flex={['0 0 auto', '1 1 100%']}>
            <Button
              type="secondary"
              onClick={() => {
                history.replace(ROUTE_CONFIG.KIWI_SEARCH.path);
              }}
              dataTest="price-change-cancel"
            >
              {polyglot.t('booking_tool.price_change.back')}
            </Button>
            <Tooltip
              enabled={data?.balance < amount}
              content={polyglot.t('booking_tool.price_change.tooltip')}
            >
              <Button
                type="primary"
                loading={loadingDeposit || prebookingSessionLoading}
                disabled={data?.balance < amount}
                onClick={() => {
                  resolveActivePrebookingSessionEvent(apiKey, sessionId, eventId);
                }}
                dataTest="price-change-accept"
              >
                {polyglot.t('booking_tool.price_change.continue', { symbol, price: amount })}
              </Button>
            </Tooltip>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default PriceChangeModal;
