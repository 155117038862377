import { useContext, useEffect } from 'react';
import { SpecialAppsContext } from './index.js';

const useSpecialAppsDefinitions = () => {
  const { specialAppsState, loadSpecialAppsDefinitions } = useContext(SpecialAppsContext);

  useEffect(() => {
    if (!specialAppsState.definitions) {
      loadSpecialAppsDefinitions();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return specialAppsState;
};

export default useSpecialAppsDefinitions;
