import React, { useContext } from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import RemoveIcon from '@kiwicom/orbit-components/lib/icons/Remove';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { Card, CardActionHeader, CardData } from 'components/common/Card';
import { countriesWithIBAN, nonSanctionedCountries } from 'consts/countries';
import { usePolyglot } from 'components/services/i18n';
import { BankAccountsState } from 'components/services/bankAccounts';

const BankAccount = React.memo(
  ({
    index,
    account,
    showDeleteAccountModal,
    showEditAccountModal,
    showChangeMainAccountModal,
    deletingDisabled,
  }) => {
    const polyglot = usePolyglot();
    const {
      state: { settingPrimary },
    } = useContext(BankAccountsState);

    const handleChangeMainClick = () => {
      showChangeMainAccountModal(account);
    };

    const handleDeleteClick = () => {
      showDeleteAccountModal(account, index);
    };

    const handleEditClick = () => {
      showEditAccountModal(account, index);
    };

    const deleteAccountButton = (
      <Button
        onClick={handleDeleteClick}
        title="Remove account"
        dataTest={`remove-account-${index + 1}-button`}
        type="criticalSubtle"
        size="small"
        iconLeft={<RemoveIcon />}
        disabled={account.isPrimary}
      />
    );

    const beneficiaryName = account.beneficiaryName;
    const accountNumber = account.accountNumber;
    return (
      <Card>
        <CardActionHeader
          title={polyglot.t(`company.accounts_detail_${index + 1}`)}
          action={
            <div>
              <Stack direction="row">
                <Button
                  onClick={handleChangeMainClick}
                  title="Main account"
                  dataTest={`main-account-${index + 1}-button`}
                  type="secondary"
                  size="small"
                  loading={settingPrimary}
                  disabled={account.isPrimary}
                >
                  {polyglot.t('company.account_1')}
                </Button>

                <Button
                  onClick={handleEditClick}
                  title="Edit account"
                  dataTest={`edit-account-${index + 1}-button`}
                  type="secondary"
                  size="small"
                >
                  {polyglot.t('common.edit')}
                </Button>
                {deletingDisabled ? (
                  <Tooltip
                    content={polyglot.t('company.billing.delete_bank_account.disabled_button_text')}
                  >
                    {deleteAccountButton}
                  </Tooltip>
                ) : (
                  deleteAccountButton
                )}
              </Stack>
            </div>
          }
        />
        <CardData
          leftColumn={[
            {
              label: 'Country',
              value: (
                nonSanctionedCountries.find(
                  c => c.value.toLowerCase() === account.country?.toLowerCase?.(),
                ) || { label: '-' }
              ).label,
            },
            {
              label: polyglot.t('company.beneficiary_name'),
              value: beneficiaryName,
            },
          ]}
          rightColumn={[
            { label: 'SWIFT / BIC', value: account.swift || '-' },
            {
              ...(countriesWithIBAN.includes(account.country)
                ? {
                    label: 'IBAN',
                    value: account.iban || '-',
                  }
                : { label: 'Account Number', value: accountNumber || '-' }),
            },
          ]}
        />
      </Card>
    );
  },
);

export default BankAccount;
