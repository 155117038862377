import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import FlightDirect from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import { tripTypesValues } from 'consts/search';
import { usePolyglot } from 'components/services/i18n';

import { RouteGroupWrapper } from './FlightCard.styled';
import RouteGroup from './RouteGroup';

const stackDirection = {
  [tripTypesValues.ONEWAY]: 'row',
  [tripTypesValues.RETURN]: 'row',
  [tripTypesValues.MULTICITY]: 'column',
  [tripTypesValues.NOMAD]: 'column',
};

const RouteGroups = ({ routeGroups, getFlightLegProps, tripType }) => {
  const polyglot = usePolyglot();

  return (
    <Stack>
      <Stack direction={stackDirection[tripType]} spaceAfter="large" flex wrap>
        {routeGroups.map((group, i) => {
          const { cityFrom } = group[0];
          const { cityTo } = group[group.length - 1];
          return (
            <RouteGroupWrapper key={group[0].id}>
              <Stack align="center" spacing="XSmall" spaceAfter="normal" grow>
                <Text weight="bold">{cityFrom}</Text>
                <FlightDirect />
                <Text weight="bold">{cityTo}</Text>
                <Text dataTest={`route-group-flight-duration-${i}`}>
                  {polyglot.t('searchAndBooking.duration', {
                    duration: getFlightLegProps(group).duration,
                  })}
                </Text>
              </Stack>
              <RouteGroup key={i} routeGroup={group} />
            </RouteGroupWrapper>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default RouteGroups;
