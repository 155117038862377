import { func, object, shape, string, number, bool } from 'prop-types';

export const Location = {
  pathname: string,
  search: string,
  hash: string,
  state: object,
};

export const History = {
  action: string,
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  listen: func,
  length: number,
  push: func.isRequired,
  replace: func,
  location: shape(Location),
};

export const Match = {
  isExact: bool,
  path: string,
  url: string,
  params: object,
};

export default {
  Location,
  Match,
  History,
};
