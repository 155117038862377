import { useEffect } from 'react';

import Modal from '@kiwicom/orbit-components/lib/Modal';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import useHelp from 'components/services/help/useHelp';
import Data from './Data';

const ReplyIssueModal = ({ onClose, issueId }) => {
  const {
    getSingleIssue,
    singleIssue: { data, loading, error },
    clearIssue,
  } = useHelp();

  useEffect(() => {
    getSingleIssue(issueId);

    return () => {
      clearIssue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal onClose={onClose}>
      {loading ? <Loading /> : <Data onClose={onClose} issue={data} error={error} />}
    </Modal>
  );
};

export default ReplyIssueModal;
