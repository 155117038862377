import React from 'react';
import styled from 'styled-components';
import { useForm, useFormContext, FormProvider, Controller } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as Yup from 'yup';
import * as dfs from 'date-fns';
import {
  Heading,
  Button,
  Select,
  Stack,
  Inline,
  Text,
  Checkbox,
  InputField,
} from '@kiwicom/orbit-components';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import AirplaneUpIcon from '@kiwicom/orbit-components/lib/icons/AirplaneUp';
import CalendarIcon from '@kiwicom/orbit-components/lib/icons/Calendar';

import DateInputGroup from 'components/common/DateInputGroup';
import { usePolyglot } from 'components/services/i18n';
import { useCurrentUser } from 'components/services/auth';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import { SelectNationality } from 'common';
import useHelp from 'components/services/help/useHelp';
import { SERVICE_DESK } from 'consts/serviceDesks';
import MultipleInputFiles from 'common/MultipleInputFiles';
import { isProduction } from 'utils/env';

const GenderWrapper = styled.div`
  width: 155px;
`;

const CheckboxWrapper = styled.div`
  width: auto;
`;

const GENDER_OPTIONS = [
  {
    value: 'male',
    label: 'booking.deposit_booking.male',
  },
  {
    value: 'female',
    label: 'booking.deposit_booking.female',
  },
];

const dateRegex = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{1,2}$');
const getMonth = date => {
  return Number(date.split('-')[1]);
};

const RequestEditPassengerSchema = Yup.object().shape({
  documentNumber: Yup.string()
    .required('mmb.booking_details.edit_passenger_modal.document_number')
    .min(5, 'booking.too_short')
    .max(20, 'validation.too_long'),
  documentExpiry: Yup.string()
    .nullable()
    .when('noExpiry', {
      is: noExpiry => noExpiry === false || noExpiry === undefined,
      then: Yup.string()
        .required()
        .test('validDate', 'booking.invalid_date', value => {
          if (dateRegex.exec(value) === null) {
            return false;
          }
          if (getMonth(value) !== Number(new Date(value).getMonth()) + 1) {
            return false;
          }
          return dfs.isValid(new Date(value));
        })
        .test('dateInPast', 'booking.expired', value => {
          return dfs.isValid(new Date(value)) && dfs.isFuture(new Date(value));
        })
        .test('expiredOnLastFlight', 'booking.expires', function (value) {
          return !dfs.isBefore(
            dfs.addDays(new Date(value), 1),
            new Date(this.options.context.departureDate),
          );
        }),
    }),
  firstName: Yup.string().min(2, 'booking.too_short').required('booking.required'),
  lastName: Yup.string().min(2, 'booking.too_short').required('booking.required'),
  nationality: Yup.string().required('booking.required'),
  gender: Yup.string().required('booking.required'),
  noExpiry: Yup.bool(),
  birthDate: Yup.string()
    .test('validDate', 'booking.invalid_date', (value, context) => {
      if (dateRegex.exec(value) === null) {
        return false;
      }
      // This is fix for Chrome because if you create new Date from value in Chrome it has days overflow and hence returns valid date, e.g. 30.02. becomes 01.03
      if (getMonth(value) !== Number(new Date(value).getMonth()) + 1) {
        return false;
      }
      return true;
    })
    .test('dateInFuture', 'booking.invalid_date', value => {
      return dfs.isValid(new Date(value)) && dfs.isPast(new Date(value));
    })
    .test('dateTooOld', 'booking.invalid_date', value => {
      return dfs.isBefore(new Date('1899-12-31'), new Date(value));
    }),
  temporaryAttachment: Yup.mixed().required('booking.required'),
});

const RequestEditPassengerForm = () => {
  const { watch, control, trigger, errors } = useFormContext();
  const polyglot = usePolyglot();

  const noExpiry = watch('noExpiry');

  return (
    <form>
      <Stack direction="column">
        <Stack direction="column" spacing="XXXSmall">
          <Heading type="title2" spaceAfter="small">
            {polyglot.t('mmb.booking_details.edit_passenger_modal.document.input.title')}
          </Heading>
          <Text spaceAfter="smallest">
            {polyglot.t('mmb.booking_details.edit_passenger_modal.document.input.description')}
          </Text>
          <Stack>
            <Controller
              control={control}
              name="temporaryAttachment"
              render={({ value, onChange }) => (
                <MultipleInputFiles
                  label={polyglot.t('support.create_issue_modal.file.label')}
                  placeholder={polyglot.t('support.create_issue_modal.file.placeholder')}
                  error={polyglot.t(errors?.['temporaryAttachments']?.message?.toString())}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Stack>
        </Stack>
        <Heading type="title3">Passenger details</Heading>
        <Stack direction="row" spacing="large">
          <Controller
            name="firstName"
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <InputField
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={polyglot.t('mmb.booking_details.edit_passenger_modal.first_name')}
                  error={errors?.firstName && polyglot.t(errors.firstName?.message)}
                />
              );
            }}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <InputField
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label={polyglot.t('mmb.booking_details.edit_passenger_modal.last_name')}
                  error={errors?.lastName && polyglot.t(errors.lastName?.message)}
                />
              );
            }}
          />
        </Stack>
        <Stack direction="row" spacing="large" align="start">
          <Controller
            name="nationality"
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Stack>
                  <SelectNationality
                    id="nationality"
                    label={polyglot.t(
                      'booking.deposit_booking.passengers_and_bags.passenger_form.nationality',
                    )}
                    placeholder={polyglot.t(
                      'booking.deposit_booking.passengers_and_bags.passenger_form.select',
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    name="nationality"
                    value={value}
                    error={errors?.nationality && polyglot.t(errors.nationality?.message)}
                  />
                </Stack>
              );
            }}
          />
          <Stack direction="row" justify="between" spacing="small" grow={1} shrink={1}>
            <GenderWrapper>
              <Controller
                name="gender"
                control={control}
                render={({ onChange, onBlur, value }) => {
                  return (
                    <Select
                      dataTest="gender"
                      label={polyglot.t(
                        'booking.deposit_booking.passengers_and_bags.passenger_form.gender',
                      )}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      options={GENDER_OPTIONS.map(o => ({
                        value: o.value,
                        label: polyglot.t(o.label),
                      }))}
                    />
                  );
                }}
              />
            </GenderWrapper>
            <Controller
              name="birthDate"
              control={control}
              render={({ onChange, onBlur, value }) => {
                return (
                  <DateInputGroup
                    name="birthDate"
                    dataTest="birth"
                    label={polyglot.t(
                      'booking.deposit_booking.passengers_and_bags.passenger_form.date_of_birth',
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    trigger={trigger}
                    error={errors.birthDate && 'Invalid date'}
                  />
                );
              }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing="large">
          <Controller
            name="documentNumber"
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <InputField
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  name="documentNumber"
                  label={polyglot.t('mmb.booking_details.edit_passenger_modal.passport_number')}
                  placeholder={polyglot.t(
                    'mmb.booking_details.edit_passenger_modal.passport_number',
                  )}
                  dataTest="document-number-input"
                  error={errors?.documentNumber && polyglot.t(errors.documentNumber?.message)}
                />
              );
            }}
          />
          <Stack spacing="large" direction="column" align="end" shrink>
            <Controller
              name="documentExpiry"
              control={control}
              render={({ onChange, onBlur, value }) => {
                return (
                  <DateInputGroup
                    name="documentExpiry"
                    dataTest="document-expiry-input"
                    label={polyglot.t('mmb.booking_details.edit_passenger_modal.passport_expiry')}
                    onChange={onChange}
                    onBlur={onBlur}
                    trigger={trigger}
                    error={errors.documentExpiry && polyglot.t(errors.documentExpiry?.message)}
                    disabled={noExpiry}
                    value={value}
                  />
                );
              }}
            />
            <CheckboxWrapper>
              <Controller
                name="noExpiry"
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <Checkbox
                      onChange={() => onChange(!value)}
                      checked={value}
                      name="noExpiry"
                      label={polyglot.t('mmb.booking_details.edit_passenger_modal.no_expiry')}
                      dataTest="document-no-expiry"
                    />
                  );
                }}
              />
            </CheckboxWrapper>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

const RequestEditPassengerModal = ({ bid, passenger, itinerary, onClose }) => {
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const { data: companyDetails } = useCompanyDetails(user.companyName);
  const { createIssue, getIssues, createAttachment, issue, attachment } = useHelp();

  const oldPassengerDetails = {
    id: passenger.id,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    nationality: passenger.nationality,
    gender: passenger.title,
    birthDate: passenger.birthDate,
    documentNumber: passenger.documentNumber || '',
    noExpiry: !passenger.documentExpiry,
    documentExpiry: passenger.documentExpiry,
  };

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(RequestEditPassengerSchema),
    context: { trip: itinerary?.sectors?.[0].departure.time.utc },
    defaultValues: {
      ...oldPassengerDetails,
      gender: oldPassengerDetails.gender === 'mr' ? 'male' : 'female',
      temporaryAttachment: [],
    },
  });

  const handleSubmit = async () => {
    const { givenNames, surnames, ...values } = form.getValues();

    const data = {
      old: {
        ...oldPassengerDetails,
        documentExpiry: oldPassengerDetails.documentExpiry || 'No expiry',
      },
      new: {
        id: passenger.id,
        firstName: values.firstName,
        lastName: values.lastName,
        nationality: values.nationality.toLowerCase(),
        gender: values.gender === 'male' ? 'mr' : 'ms',
        birthDate: values.birthDate,
        documentNumber: values.documentNumber,
        noExpiry: !values.documentExpiry,
        documentExpiry: values.noExpiry
          ? polyglot.t('mmb.booking_details.edit_passenger_modal.no_expiry')
          : values.documentExpiry,
      },
    };

    let descriptionOld = '*OLD*';
    let descriptionNew = '*NEW*';

    Object.keys(data.old).forEach(key => {
      descriptionOld += `\n${key}\t${data.old[key]}`;
    });

    Object.keys(data.new).forEach(key => {
      descriptionNew += `\n${key}\t${data.new[key]}`;
    });

    const payload = {
      subject: bid,
      bid,
      description: `${descriptionOld} \n ${descriptionNew}`,
      requestType: isProduction ? '575' : '549',
      subcategory: {
        field: isProduction ? 'customfield_11280' : 'customfield_11314',
        value: 'Correction',
      },
    };

    const { key } = await createIssue(payload, SERVICE_DESK.CSSD);
    if (values?.temporaryAttachment.length === 1) {
      createAttachment(key, values?.temporaryAttachment[0]);
    } else if (values?.temporaryAttachment.length > 1) {
      await Promise.allSettled(
        values?.temporaryAttachment.map(file => createAttachment(key, file)),
      );
    }

    if (companyDetails.customerSupportEnabled) {
      getIssues({ project: SERVICE_DESK.CSSD, bid, limit: 20 });
    }
    onClose();
  };

  let departureDate = new Date(itinerary?.sectors[0].departure.time.utc);
  const timezoneOffset = departureDate.getTimezoneOffset() * 60000;
  departureDate = new Date(departureDate.getTime() + timezoneOffset);

  return (
    <Modal onClose={onClose} dataTest="add-documents-modal" size="large">
      <ModalHeader>
        <Heading type="title3" spaceAfter="large">
          {polyglot.t('mmb.booking_details.edit_passenger_modal.title', {
            fullName: passenger.fullName,
          })}
        </Heading>
        <Stack spacing="XXSmall">
          <Inline align="center" spacing="XXSmall">
            <AirplaneUpIcon size="small" color="secondary" />
            <Text type="secondary">
              {polyglot.t('mmb.booking_details.edit_passenger_modal.description_1')}
            </Text>
          </Inline>
          <Inline align="center" spacing="XXSmall">
            <CalendarIcon size="small" color="secondary" />
            <Text type="secondary">
              {polyglot.t('mmb.booking_details.edit_passenger_modal.description_2', {
                date: dfs.format(departureDate, 'dd. MMM yyyy, HH:mm'),
              })}
            </Text>
          </Inline>
        </Stack>
      </ModalHeader>
      <ModalSection>
        <FormProvider {...form}>
          <RequestEditPassengerForm />
        </FormProvider>
      </ModalSection>

      <ModalFooter>
        <Button type="secondary" onClick={onClose} loading={issue?.loading || attachment?.loading}>
          {polyglot.t('mmb.booking_details.edit_passenger_modal.cancel')}
        </Button>
        <Button
          type="primary"
          loading={issue?.loading || attachment?.loading}
          onClick={form.handleSubmit(handleSubmit)}
          dataTest="action-btn"
        >
          {polyglot.t('mmb.booking_details.edit_passenger_modal.send_request')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(RequestEditPassengerModal);
