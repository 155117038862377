import React from 'react';
import { isSameDay, endOfDay } from 'date-fns';

import { Container, ValueContainer } from './index.styled';

const Day = ({
  day,
  isSelecting,
  setIsSelecting,
  handleChange,
  startDate,
  endDate,
  disabledDates,
}) => {
  const isDisabled =
    disabledDates.min && disabledDates.max && (day < disabledDates.min || day > disabledDates.max);
  const isSelected =
    (startDate && isSameDay(day, startDate)) || (endDate && isSameDay(day, endDate));
  const isInRange = startDate && endDate && day > startDate && day < endDate;

  const handleDateSelect = () => {
    if (!isSelecting) {
      setIsSelecting(true);
      handleChange({ startDate: day, endDate: '' });
    } else {
      if (startDate > day) {
        handleChange({ startDate: day, endDate: endOfDay(startDate) });

        setIsSelecting(false);
      } else {
        handleChange({ startDate: startDate, endDate: endOfDay(day) });

        setIsSelecting(false);
      }
    }
  };

  return (
    <Container
      isDisabled={isDisabled}
      isSelected={isSelected}
      isInRange={isInRange}
      onClick={isDisabled ? undefined : handleDateSelect}
    >
      <ValueContainer isDisabled={isDisabled}>{day.getDate()}</ValueContainer>
    </Container>
  );
};

export default Day;
