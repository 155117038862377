import * as R from 'ramda';

/**
 * Caching for paginated Jira support tickets.
 */
export function requestsAdapter(data, payload) {
  if (!data.requests) {
    return payload;
  }
  const requests = R.uniqBy(item => item.issueId, [...data.requests, ...payload.requests]);
  return {
    ...payload,
    requests,
  };
}

/**
 * Transforms the names of the Jira transitions to `camelCase` for mapping with `polyglot` keys.
 * 
 * Example of Jira Transitions:
 * 
 * ```
  [
    {
      "id": "901",
      "name": "Cancel request"
    },
    {
      "id": "781",
      "name": "Respond to Kiwi.com support"
    }
  ]
 * ```
 * @param {string} transitionName name of the Jira ticket transition
 */
export function toCamelCase(transitionName) {
  const r = /([\s.][\w])/g;
  const pascalCase = transitionName.replace(r, g => g.toUpperCase().replace(/[\s.]/, ''));
  return pascalCase.replace(/^[\w]/g, g => g.toLowerCase());
}
