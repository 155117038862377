import * as R from 'ramda';
import { COMPANY_TYPES } from 'consts/company';

const v1_get_from = company => {
  return {
    name: company.name,
    displayName: company.display_name,
    accountManager: company.account_manager,
    bankAccounts: [], //remove after bank account endpoint is implemented
    address: company.address,
    legalAddress: `${company.address.address1}, ${company.address.city}, ${company.address.country}`,
    address1: company.address.address1,
    city: company.address.city,
    country: company.address.country,
    zip: company.address.zip,
    type: company.account_type || COMPANY_TYPES.PERSON,
    adminEmail: company.admin_email,
    email: company.email,
    legalName: company.legal_name,
    createdAt: company.created_at,
    createdBy: company.created_by,
    updatedAt: company.updated_at,
    updatedBy: company.updated_by,
    customerSupportEnabled: company.customer_support_enabled,
    czTaxPayer: company.cz_taxpayer,
    ...(company.majority_stakeholder && {
      majorityStakeholder: {
        firstName: company.majority_stakeholder.first_name,
        lastName: company.majority_stakeholder.last_name,
      },
    }),
    owner: company.owner,
    status: company.status,
    url: company.url,
    vatId: company.vat_id,
    taxId: company.tax_id,
    companySize: company.company_size,
    businessType: company.business_type,
  };
};

const v1_put_to = company => {
  const address = company.address && {
    address1: company.address.address1,
    country: company.address.country.toLowerCase(),
    city: company.address.city,
    zip: company.address.zip.trim(),
    is_primary: company.address.isPrimary,
  };

  const majorityStakeholder = company.majorityStakeholder && {
    date_of_birth: company.majorityStakeholder.dateOfBirth,
    ...(!!company.majorityStakeholder.firstName && {
      first_name: company.majorityStakeholder.firstName,
    }),
    ...(!!company.majorityStakeholder.lastName && {
      last_name: company.majorityStakeholder.lastName,
    }),
    nationality: company.majorityStakeholder.nationality,
  };

  const owner = company.owner && {
    date_of_birth: company.owner.dateOfBirth,
    first_name: company.owner.firstName,
    last_name: company.owner.lastName,
    nationality: company.owner.nationality,
  };

  const mappedCompany = R.reject(R.isNil, {
    account_manager: company.accountManager,
    account_type: company.accountType,
    admin_email: company.adminEmail,
    address,
    business_type: !!company.businessTypeOther ? company.businessTypeOther : company.businessType,
    currency: company.currency,
    customer_support_enabled: company.customerSupportEnabled,
    cz_taxpayer: company.czTaxPayer,
    display_name: company.displayName,
    email: company.adminEmail,
    ...(company.accountType === COMPANY_TYPES.COMPANY ? { legal_name: company.legalName } : {}),
    majority_stakeholder: majorityStakeholder,
    owner: owner,
    tax_id: company.taxId,
    vat_id: company.vatId,
    travelport_config: company.travelportConfig,
    url: company.url,
    company_size: company.companySize,
  });

  return mappedCompany;
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
    put: {
      to: v1_put_to,
      from: v1_get_from,
    },
  },
};
