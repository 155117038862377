import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Separator from '@kiwicom/orbit-components/lib/Separator';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import ChevronDown from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import { useToggle } from 'utils/hooks';
import theme from 'styles/theme';

const MessagesTogglerWrapper = styled.div``;

const SeparatorWrapper = styled.div`
  position: relative;
`;

const MessagesCounterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 24px;
  transform: translateY(-50%);
  transition: 0.15s ease;
  opacity: ${({ isActive }) => (isActive ? '0' : '1')};
`;

const MessagesWrapper = styled.div`
  transition: 0.3s ease;
  overflow: hidden;
  height: ${({ isActive, containerHeight }) => (isActive ? `${containerHeight}px` : '0px')};
  margin-bottom: ${({ isActive }) => isActive && '24px'};
`;

const MessagesContainer = styled.div``;

const Button = styled.button`
  position: absolute;
  top: 0px;
  right: 24px;
  transform: translateY(-50%);
  outline: none;
  border-radius: 50%;
  border: 1px solid ${theme.orbit.backgroundSeparator};
  background-color: ${theme.orbit.backgroundButtonGoogle};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.15s ease;

  &:hover {
    box-shadow: ${theme.orbit.boxShadowAction};
  }
`;

const IconWrapper = styled.div`
  transition: 0.15s ease;
  transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const MessagesToggler = ({ children, numberOfRemainingMessages }) => {
  const isToggle = useToggle();
  const messagesWrapperRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    setContainerHeight(messagesWrapperRef.current.clientHeight);
  }, []);

  return (
    <MessagesTogglerWrapper>
      <SeparatorWrapper>
        <MessagesCounterWrapper isActive={isToggle.isOn}>
          <Badge>{numberOfRemainingMessages}</Badge>
        </MessagesCounterWrapper>
        <Button onClick={isToggle.toggle}>
          <IconWrapper isActive={isToggle.isOn}>
            <ChevronDown />
          </IconWrapper>
        </Button>
        <Separator spaceAfter="large" />
      </SeparatorWrapper>
      <MessagesWrapper isActive={isToggle.isOn} containerHeight={containerHeight}>
        <MessagesContainer ref={messagesWrapperRef}>{children}</MessagesContainer>
      </MessagesWrapper>
    </MessagesTogglerWrapper>
  );
};

export default MessagesToggler;
