import React, { useReducer, useRef } from 'react';

import api from 'utils/api';
import companyBankAccountsMapper from 'mappers/nextGen/companies/{company_name}/bank-accounts/_';
import bankAccountsMapper from 'mappers/nextGen/bank-accounts/{bank_account_uuid}/_';

export const BankAccountsState = React.createContext({
  state: {},
  loadAffilIdCommercials: async affilId => affilId,
  createCommercial: async (commercial, affilId) => [commercial, affilId],
});

const bankAccountsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_BANK_ACCOUNTS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: true,
          error: null,
        },
      };
    case 'LOAD_COMPANY_BANK_ACCOUNTS_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          data: action.bankAccounts,
        },
      };
    case 'LOAD_COMPANY_BANK_ACCOUNTS_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          loading: false,
          error: action.error,
        },
      };
    case 'CREATE_COMPANY_BANK_ACCOUNT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          creating: true,
          error: null,
        },
      };
    case 'CREATE_COMPANY_BANK_ACCOUNT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.concat(action.bankAccount),
          creating: false,
        },
      };
    case 'CREATE_COMPANY_BANK_ACCOUNT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          creating: false,
          error: action.error,
        },
      };
    case 'DELETE_COMPANY_BANK_ACCOUNT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          deleting: true,
          error: null,
        },
      };
    case 'DELETE_COMPANY_BANK_ACCOUNT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.filter(
            bankAccount => bankAccount.uuid !== action.uuid,
          ),
          deleting: false,
        },
      };
    case 'DELETE_COMPANY_BANK_ACCOUNT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          deleting: false,
          error: action.error,
        },
      };
    case 'UPDATE_COMPANY_BANK_ACCOUNT':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          updating: true,
          error: null,
        },
      };
    case 'UPDATE_COMPANY_BANK_ACCOUNT_SUCCESS':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          data: state[action.companyName].data.map(bankAccount =>
            bankAccount.uuid === action.bankAccount.uuid ? action.bankAccount : bankAccount,
          ),
          updating: false,
        },
      };
    case 'UPDATE_COMPANY_BANK_ACCOUNT_FAIL':
      return {
        ...state,
        [action.companyName]: {
          ...state[action.companyName],
          updating: false,
          error: action.error,
        },
      };
    case 'SET_PRIMARY':
      return {
        ...state,
        settingPrimary: true,
      };
    case 'SET_PRIMARY_SUCCESS':
      return {
        ...state,
        settingPrimary: false,
      };
    case 'SET_PRIMARY_FAIL':
      return {
        ...state,
        error: action.error,
        settingPrimary: false,
      };
    default:
      return state;
  }
};
const BankAccountsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bankAccountsReducer, {});

  const loadingRef = useRef({});

  const loadCompanyBankAccounts = async companyName => {
    if (loadingRef.current[companyName]) {
      return;
    }

    loadingRef.current[companyName] = true;

    dispatch({ type: 'LOAD_COMPANY_BANK_ACCOUNTS', companyName });

    try {
      const { data } = await api.v1.get(`companies/${companyName}/bank-accounts`);
      dispatch({
        type: 'LOAD_COMPANY_BANK_ACCOUNTS_SUCCESS',
        companyName,
        bankAccounts: companyBankAccountsMapper.v1.get.from(data),
      });
    } catch (error) {
      dispatch({ type: 'LOAD_COMPANY_BANK_ACCOUNTS_FAIL', companyName, error });

      return Promise.reject(error);
    } finally {
      loadingRef.current[companyName] = false;
    }
  };

  const createCompanyBankAccount = async (companyName, bankAccount) => {
    dispatch({ type: 'CREATE_COMPANY_BANK_ACCOUNT', companyName });

    try {
      const { data } = await api.v1.post(
        `companies/${companyName}/bank-accounts`,
        companyBankAccountsMapper.v1.post.to(companyName, bankAccount),
      );

      dispatch({
        type: 'CREATE_COMPANY_BANK_ACCOUNT_SUCCESS',
        companyName,
        bankAccount: companyBankAccountsMapper.v1.post.from(data),
      });
    } catch (error) {
      dispatch({ type: 'CREATE_COMPANY_BANK_ACCOUNT_FAIL', companyName, error });

      return Promise.reject(error);
    }
  };

  const deleteCompanyBankAccount = async (companyName, uuid) => {
    dispatch({ type: 'DELETE_COMPANY_BANK_ACCOUNT', companyName });

    try {
      await api.v1.delete(`bank-accounts/${uuid}`);

      dispatch({
        type: 'DELETE_COMPANY_BANK_ACCOUNT_SUCCESS',
        companyName,
        uuid,
      });
    } catch (error) {
      dispatch({ type: 'DELETE_COMPANY_BANK_ACCOUNT_FAIL', companyName, error });

      return Promise.reject(error);
    }
  };

  const updateCompanyBankAccount = async (companyName, bankAccount) => {
    dispatch({ type: 'UPDATE_COMPANY_BANK_ACCOUNT', companyName });

    try {
      const { data } = await api.v1.put(
        `bank-accounts/${bankAccount.uuid}`,
        bankAccountsMapper.v1.put.to(companyName, bankAccount),
      );

      dispatch({
        type: 'UPDATE_COMPANY_BANK_ACCOUNT_SUCCESS',
        companyName,
        bankAccount: bankAccountsMapper.v1.put.from(data),
      });
    } catch (error) {
      dispatch({ type: 'UPDATE_COMPANY_BANK_ACCOUNT_FAIL', companyName, error });

      return Promise.reject(error);
    }
  };

  const setPrimary = async account => {
    dispatch({ type: 'SET_PRIMARY' });
    try {
      await api.v1.post(`bank-accounts/${account.uuid}/set-primary`, {});
      dispatch({
        type: 'SET_PRIMARY_SUCCESS',
        companyName: account.companyName,
        bankAccount: account,
      });
    } catch (error) {
      dispatch({ type: 'SET_PRIMARY_FAIL', error });

      return Promise.reject(error);
    }
  };

  const value = {
    state,
    loadCompanyBankAccounts,
    createCompanyBankAccount,
    deleteCompanyBankAccount,
    updateCompanyBankAccount,
    setPrimary,
  };

  return <BankAccountsState.Provider value={value}>{children}</BankAccountsState.Provider>;
};

export default BankAccountsProvider;
