import React, { useContext, useEffect, useReducer, useRef } from 'react';
import format from 'date-fns/format';
import endOfDay from 'date-fns/endOfDay';

import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import api from 'utils/api';

const initialState = {
  data: [],
  loadCount: 1,
  pagination: {},
  loading: true,
  loadingMore: false,
  page: 1,
  perPage: 10,
  initiator: undefined,
  logLevel: undefined,
  timestamps: [],
};

const MAX_RETRIES = 5;

export const AuditLogsState = React.createContext(initialState);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_LOGS':
      return {
        ...state,
        loadCount: action.loadCount,
        loading: true,
        error: null,
      };
    case 'LOAD_LOGS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data,
        pagination: action.pagination,
        page: 1,
      };
    case 'LOAD_LOGS_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'LOAD_MORE':
      return {
        ...state,
        loadingMore: true,
        loadMoreError: null,
      };
    case 'LOAD_MORE_SUCCESS':
      return {
        ...state,
        loadingMore: false,
        data: state.data.concat(action.data),
        pagination: action.pagination,
        page: state.page + 1,
      };
    case 'LOAD_MORE_FAIL':
      return {
        ...state,
        loadMoreError: action.error,
        loadingMore: false,
      };
    case 'SET_VALUE':
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};

const AuditLogsProvider = ({ children }) => {
  const companyName = useCurrentCompanyName();
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialLoadCount = useRef(initialState.loadCount);

  const loadLogs = async (options = {}) => {
    return api.v1.get(`companies/${companyName}/audit-logs`, {
      params: {
        _page: options.page || state.page,
        _per_page: state.perPage,
        initiator: state.initiator,
        log_level: state.logLevel,
        ...(state.timestamps[0]
          ? {
              timestamp_start: state.timestamps[0].toISOString(),
            }
          : {}),
        ...(state.timestamps[1]
          ? {
              timestamp_end: endOfDay(state.timestamps[1]).toISOString(),
            }
          : {}),
      },
    });
  };

  const loadInitialLogs = async () => {
    dispatch({ type: 'LOAD_LOGS', loadCount: initialLoadCount.current });
    initialLoadCount.current++;
    try {
      const { data } = await loadLogs({ page: 1 });

      // Reset counter reference if load logs returns a success
      initialLoadCount.current = initialState.loadCount;
      dispatch({ type: 'LOAD_LOGS_SUCCESS', data: data.data, pagination: data.pagination });
    } catch (error) {
      if (initialLoadCount.current <= MAX_RETRIES) {
        loadInitialLogs();
      } else {
        dispatch({ type: 'LOAD_LOGS_FAIL', error });
      }
    }
  };

  const timestampsHash = state.timestamps.map(t => format(t, 'yyyy-MM-dd')).join('-');

  useEffect(() => {
    loadInitialLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.perPage, state.initiator, state.logLevel, timestampsHash]);

  const loadMoreLogs = async () => {
    dispatch({ type: 'LOAD_MORE' });

    try {
      const response = await loadLogs({ page: state.page + 1 });

      dispatch({
        type: 'LOAD_MORE_SUCCESS',
        data: response.data.data,
        pagination: response.data.pagination,
      });
    } catch (error) {
      dispatch({ type: 'LOAD_MORE_FAIL', error });
    }
  };

  const setPerPage = value => {
    dispatch({ type: 'SET_VALUE', key: 'perPage', value });
  };

  const setInitiator = value => {
    dispatch({ type: 'SET_VALUE', key: 'initiator', value });
  };

  const setLogLevel = value => {
    dispatch({ type: 'SET_VALUE', key: 'logLevel', value });
  };

  const setTimestamps = value => {
    dispatch({ type: 'SET_VALUE', key: 'timestamps', value });
  };

  const value = {
    ...state,
    loadMoreLogs,
    setPerPage,
    setInitiator,
    setLogLevel,
    setTimestamps,
    loadInitialLogs,
  };

  return <AuditLogsState.Provider value={value}>{children}</AuditLogsState.Provider>;
};

export const useAuditLogs = () => {
  return useContext(AuditLogsState);
};

export default AuditLogsProvider;
