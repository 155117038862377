import { useContext, useEffect } from 'react';

import { SolutionsState } from './';
import useCurrentSolutionAffilId from './useCurrentSolutionAffilId';
import useCurrentCompanyName from '../company/useCurrentCompanyName';
import useApiProducts from 'components/services/products/useApiProducts';

const useCurrentSolutionDetails = () => {
  const affilId = useCurrentSolutionAffilId();
  const companyName = useCurrentCompanyName();
  const apiProducts = useApiProducts();

  const { state, singleAppsState, loadSolutionDetails } = useContext(SolutionsState);

  useEffect(() => {
    if (!singleAppsState[affilId] && !apiProducts.loading) {
      loadSolutionDetails(affilId);
    }
  }, [affilId, singleAppsState, loadSolutionDetails, apiProducts.loading]);

  return (
    (singleAppsState[affilId] && singleAppsState[affilId].data && singleAppsState[affilId]) ||
    (state[companyName] &&
      state[companyName].data &&
      state[companyName].data.find(a => a.affilId === affilId) && {
        loading: false,
        data: state[companyName].data.find(a => a.affilId === affilId),
        error: null,
      }) || {
      loading: true,
      data: null,
      error: null,
    }
  );
};

export default useCurrentSolutionDetails;
