import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { SEATING_CLASS_TYPES } from 'consts/seating';

const SeatSeatingClassRow = ({ range, seatingClass }) => {
  const polyglot = usePolyglot();

  return (
    <Box
      width="full"
      height="auto"
      padding={{ top: 'XSmall', bottom: 'XSmall' }}
      background="cloudLight"
      borderRadius="large"
    >
      <Text type="secondary" size="small" align="center">
        {polyglot.t('booking.additional_services.seating.seat_map.seating_class', {
          seatingClass: SEATING_CLASS_TYPES[seatingClass] || SEATING_CLASS_TYPES.default,
          from: range[0],
          to: range[1],
        })}
      </Text>
    </Box>
  );
};

export default SeatSeatingClassRow;
