import React, { useContext, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import Send from '@kiwicom/orbit-components/lib/icons/Send';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { SolutionsState } from 'components/services/solutions';
import { usePolyglot } from 'components/services/i18n';

import { Space, Flex, ErrorMessageComponent } from 'common';

const DisableSolutionSchema = Yup.object().shape({
  disableReason: Yup.string()
    .max(1024, 'validation.length_exceeded')
    .required('solutions.validation_reason'),
});

const SolutionDisableModal = ({ companyName, app, onClose }) => {
  const polyglot = usePolyglot();
  const {
    state: { disabling },
    disableSolution,
  } = useContext(SolutionsState);
  const [disableAppAlert, setDisableAppAlert] = useState(false);

  const handleDisable = values => {
    disableSolution(companyName, app, values.disableReason)
      .then(() => {
        setDisableAppAlert(false);
        onClose();
      })
      .catch(error => {
        setDisableAppAlert(error);
      });
  };

  return (
    <Modal size="normal" onClose={onClose}>
      <ModalHeader
        title={polyglot.t('solutions.disable_modal_title')}
        description={
          <Heading type="title2" element="h2">
            {app.name}
          </Heading>
        }
      />
      <Formik
        initialValues={{
          disableReason: '',
        }}
        validationSchema={DisableSolutionSchema}
        onSubmit={handleDisable}
      >
        {({ handleChange, handleSubmit, values }) => (
          <form>
            <ModalSection>
              {disableAppAlert && (
                <Alert
                  type="critical"
                  closable
                  icon
                  onClose={() => {
                    setDisableAppAlert(false);
                  }}
                >
                  {disableAppAlert}
                </Alert>
              )}
              <Textarea
                label={polyglot.t('solutions.disable_reason')}
                onChange={handleChange}
                value={values.disableReason}
                name="disableReason"
              />
              <ErrorMessage
                name="disableReason"
                render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
              />
            </ModalSection>
            <ModalFooter>
              <Flex>
                <Button width="138px" type="secondary" onClick={onClose} disabled={disabling}>
                  {polyglot.t('common.cancel')}
                </Button>
                <Space left="l">
                  <Button
                    onClick={handleSubmit}
                    width="160px"
                    loading={disabling}
                    iconRight={<Send />}
                  >
                    {polyglot.t('common.send_message')}
                  </Button>
                </Space>
              </Flex>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default SolutionDisableModal;
