const direct_get_from = data => {
  const { price_changes: priceChanges } = data;

  if (!priceChanges.length) {
    return null;
  }
  const lastPriceChange = priceChanges[priceChanges.length - 1];

  return {
    id: lastPriceChange.id,
    price: lastPriceChange.price.amount,
    currency: lastPriceChange.price.currency,
    status: lastPriceChange.status,
  };
};

export default {
  direct: {
    get: {
      from: direct_get_from,
    },
  },
};
