import styled from 'styled-components';

export const Container = styled.div`
  z-index: 5;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 12px 12px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  ${({ disabled }) => disabled && `opacity: 0.5;`}
  border-radius: 3px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > button:first-of-type {
    margin-right: 12px;
  }
`;
