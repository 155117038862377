import React from 'react';
import styled from 'styled-components';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';

const StyledWrapper = styled.div`
  width: 300px;
`;

const ApproveModal = ({
  company,
  periodStart,
  periodEnd,
  amount,
  loading,
  error,
  onClose,
  onApprove,
}) => {
  const polyglot = usePolyglot();
  return (
    <Modal hasCloseButton onClose={onClose}>
      <ModalHeader
        title={polyglot.t('billing.approve_modal.billing.title')}
        description={polyglot.t('billing.approve_modal.billing.description', { company })}
      />
      <ModalSection>
        {error && (
          <Alert
            type="critical"
            spaceAfter="large"
            title={polyglot.t('billing.delete_modal.billing.error.title')}
          >
            {error.response?.data?.message}
          </Alert>
        )}
        <StyledWrapper>
          <Stack direction="row">
            <Stack shrink="2" direction="column">
              <Text type="secondary">{polyglot.t('billing.company')}</Text>
              <Text type="secondary">{polyglot.t('billing.period')}</Text>
              <Text type="secondary">{polyglot.t('billing.amount')}</Text>
            </Stack>
            <Stack grow="2" direction="column">
              <Text>{company}</Text>
              <Text>{polyglot.t('billing.period_text', { periodStart, periodEnd })}</Text>
              <Text>€ {amount}</Text>
            </Stack>
          </Stack>
        </StyledWrapper>
      </ModalSection>
      <ModalFooter>
        <Stack justify="end">
          <Button onClick={onClose} disabled={loading} type="secondary">
            {polyglot.t('common.cancel')}
          </Button>
          <Button onClick={onApprove} loading={loading} type="primary">
            {polyglot.t('common.approve')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default ApproveModal;
