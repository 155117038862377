import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import * as R from 'ramda';

const initialState = {
  static_help: JSON.parse(localStorage.getItem('static_help')) || undefined,
  mmb_language: JSON.parse(localStorage.getItem('mmb_language')) || undefined,
};

const LocalStorageState = React.createContext(initialState);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    default:
      return state;
  }
};

const LocalStorageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const set = useCallback((key, value) => {
    if (!key || !value) {
      throw new Error('Invalid usage');
    }
    const existing = localStorage.getItem(key);

    if (existing) {
      const destringifiedData = JSON.parse(existing);
      localStorage.setItem(key, JSON.stringify({ ...destringifiedData, ...value }));
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }

    dispatch({ type: 'SET', key, value });
  }, []);

  return (
    <LocalStorageState.Provider
      value={{
        state,
        set,
      }}
    >
      {children}
    </LocalStorageState.Provider>
  );
};

export const useLocalStorage = (key, initialValue) => {
  const { state, set } = useContext(LocalStorageState);

  let item;
  try {
    let tmp = JSON.parse(localStorage.getItem(key) || undefined);
    if (!R.equals(item, tmp)) {
      item = tmp;
    }
  } catch (_) {
    item = initialValue;
  }

  useEffect(() => {
    if (R.equals(state[key], item)) {
      return;
    }

    set(key, item);
  }, [initialValue, item, key, set, state]);

  const handleSet = useCallback(
    value => {
      set(key, value);
    },
    [key, set],
  );

  return [state[key] || initialValue, handleSet];
};

export default LocalStorageProvider;
