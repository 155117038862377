import React from 'react';

import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import { ClickWrapper } from '../index.styled';

const Bid = ({ onClick, dataTest, bid, external, href }) => {
  if (external) {
    return (
      <ClickWrapper data-test={dataTest} onClick={onClick}>
        <TextLink external={external} href={href || ''}>
          #{bid}
        </TextLink>
      </ClickWrapper>
    );
  }
  return (
    <ClickWrapper data-test={dataTest} onClick={onClick}>
      <TextLink>#{bid}</TextLink>
    </ClickWrapper>
  );
};

export default Bid;
