import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & button:first-of-type {
    visibility: hidden; /* no download link yet */
    margin-right: 8px;
  }
`;
