import React, { useContext, useEffect, useRef } from 'react';
import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import { Heading, Text, Stack, Button } from '@kiwicom/orbit-components';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import AlertButton from '@kiwicom/orbit-components/lib/Alert/AlertButton';

import { usePolyglot } from 'components/services/i18n';
import { MMBState } from 'components/services/mmb';
import { useDependencyEffect } from 'hooks';
import styled from 'styled-components';

const AlertWrapper = styled.div`
  & > div {
    & > div {
      & > div {
        &:nth-of-type(2) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
`;

const SingleRefundModal = ({
  onClose,
  bid,
  singleRefundAlertFail,
  onCloseSingleRefundAlertSuccess,
  onCloseMultiRefundsAlertSuccess,
}) => {
  const polyglot = usePolyglot();
  const {
    state: { refunds },
    refundByBid,
  } = useContext(MMBState);
  const isLoading = useRef(false);
  const refundsStateKeys = Object.keys(refunds);
  const hasRefundsStateLength = refundsStateKeys && refundsStateKeys.length;
  const areAllRefundsLoaded =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => refunds[refundStateKey].requestingRefund === false);
  const areAllRefundsPassedSuccessfully =
    hasRefundsStateLength &&
    refundsStateKeys.every(refundStateKey => !refunds[refundStateKey].requestRefundError);

  const handleOnSubmitRefund = () => {
    isLoading.current = true;
    refundByBid(bid);
  };

  useDependencyEffect(() => {
    if (areAllRefundsLoaded && areAllRefundsPassedSuccessfully) {
      onClose();
    } else if (areAllRefundsLoaded && !areAllRefundsPassedSuccessfully) {
      singleRefundAlertFail.setOn();
      isLoading.current = false;
    }
  }, [areAllRefundsLoaded]);

  useEffect(() => {
    onCloseSingleRefundAlertSuccess();
    onCloseMultiRefundsAlertSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      onClose={
        isLoading.current
          ? null
          : () => {
              onClose();
              singleRefundAlertFail.setOff();
            }
      }
    >
      <ModalHeader>
        <Heading spaceAfter="small">{polyglot.t('mmb.refund')}</Heading>
        {!singleRefundAlertFail.isOn && (
          <Text>{polyglot.t('mmb.refund_modal.description', { bid })}</Text>
        )}
        {singleRefundAlertFail.isOn && (
          <AlertWrapper>
            <Alert
              type="critical"
              title={polyglot.t('mmb.single.refund.fail.title')}
              icon
              spaceAfter="largest"
            >
              <div style={{ marginBottom: 12 }}>
                {polyglot.t('mmb.single.refund.fail.subtitle')}
              </div>
              <div>
                <AlertButton type="critical" external href={`/manage-my-bookings/${bid}`}>
                  {polyglot.t('mmb.single.refund.fail.button.title')}
                </AlertButton>
              </div>
            </Alert>
          </AlertWrapper>
        )}
      </ModalHeader>
      {!singleRefundAlertFail.isOn && (
        <ModalFooter>
          <Stack direction="row" justify="between">
            <Button type="secondary" onClick={onClose} disabled={isLoading.current}>
              {polyglot.t('mmb.multi_refunds_modal.cancel')}
            </Button>
            <Button
              type="primary"
              loading={isLoading.current}
              disabled={isLoading.current}
              onClick={handleOnSubmitRefund}
            >
              {polyglot.t('mmb.multi_refunds_modal.request')}
            </Button>
          </Stack>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default React.memo(SingleRefundModal);
