import { format, sub, parseISO } from 'date-fns';

import Inline from '@kiwicom/orbit-components/lib/Inline';
import Box from '@kiwicom/orbit-components/lib/Box';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Card from '@kiwicom/orbit-components/lib/Card';
import CardSection from '@kiwicom/orbit-components/lib/Card/CardSection';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Text from '@kiwicom/orbit-components/lib/Text';
import TimelapseIcon from '@kiwicom/orbit-components/lib/icons/Timelapse';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import Button from '@kiwicom/orbit-components/lib/Button';

import { usePolyglot } from 'components/services/i18n';
import { formatTripDuration, UTCToLocalDate } from 'utils/dates';

const TripCard = ({
  title,
  carrier,
  duration,
  departureTime,
  departureTimeUTC,
  arrivalTime,
  departurePlace,
  arrivalPlace,
  onClick,
}) => {
  const polyglot = usePolyglot();

  return (
    <Box display="flex" direction="column" width="100%">
      <Heading type="title2" spaceAfter="normal">
        {title}
      </Heading>
      <Card spaceAfter="small">
        <CardSection>
          <Inline align="start" spacing="XLarge">
            <Inline align="center" spacing="XSmall">
              <CarrierLogo carriers={[{ code: carrier.code }]} />
              <Text size="small" type="secondary">
                {carrier.name}
              </Text>
            </Inline>
            <Box direction="column">
              <Text size="small">
                {`${departureTime.substr(departureTime.indexOf('T') + 1, 5)} -
                ${arrivalTime.substr(arrivalTime.indexOf('T') + 1, 5)}`}
              </Text>
              <Text type="secondary" size="small">
                {format(new Date(departureTime.slice(0, -6)), 'MMM dd yyyy')}
              </Text>
            </Box>
            <Box direction="column">
              <Text size="small">{`${departurePlace} - ${arrivalPlace}`}</Text>
              <Text type="secondary" size="small">
                {formatTripDuration(duration / 1000)}
              </Text>
            </Box>
          </Inline>
        </CardSection>
      </Card>
      <Box display="flex" direction="row" justify="between">
        <Inline align="center" spacing="XXSmall">
          <TimelapseIcon color="secondary" size="small" />
          <Text type="secondary" size="small">
            {polyglot.t('mmb.booking_details.modal.trip_card.bottom_text', {
              time: `${format(
                UTCToLocalDate(sub(parseISO(departureTimeUTC), { days: 1 })),
                'EEE dd MMM HH:mm',
              )} UTC`,
            })}
          </Text>
        </Inline>
        <Button
          dataTest="find-new-flight"
          iconRight={<ChevronForwardIcon />}
          size="small"
          onClick={onClick}
        >
          {polyglot.t('mmb.booking_details.modal.trip_card.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default TripCard;
