import styled from 'styled-components';

export const SelectSolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  padding-top: 34px;
`;

export const SelectWrapper = styled.div`
  width: 300px;
`;

export const Divider = styled.hr`
  margin-top: 14px;
  border-style: none;
  height: 1px;
  background: ${({ theme }) => theme.orbit.paletteCloudNormal};
  width: calc(100% + 46px);
  margin-left: -24px;
`;

export const SelectSolutionFigureContainer = styled.div`
  width: 340px;
  margin-top: 40px;
`;
