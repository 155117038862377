import React from 'react';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';

import { usePolyglot } from 'components/services/i18n';

const SummaryWrapper = styled.div`
  border: 1px solid #e8edf1;
  padding: 16px;
  margin-bottom: 16px;
`;

const IPSummary = ({ values }) => {
  const polyglot = usePolyglot();

  return (
    <div>
      <Heading type="title3" spaceAfter="normal">
        {polyglot.t('solutions.summary_details')}
      </Heading>

      <SummaryWrapper>
        <List>
          {values.map(ip => (
            <ListItem key={ip}>{ip}</ListItem>
          ))}
        </List>
      </SummaryWrapper>

      <Text type="secondary">{polyglot.t('allowlist.confirm_description')}</Text>
    </div>
  );
};

export default IPSummary;
