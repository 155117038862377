import React from 'react';

import SeatIcon from '@kiwicom/orbit-components/lib/icons/Seat';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Text from '@kiwicom/orbit-components/lib/Text';

import { groupSeatsByStatus } from 'utils/seating';
import { usePolyglot } from 'components/services/i18n';

import StatusBadge from './StatusBadge';
import { StatusBadgeWrapper } from './index.styled';

const SeatsStatus = ({ groupedSeats }) => {
  const polyglot = usePolyglot();

  return (
    <>
      {!!groupedSeats?.processing.length && (
        <Inline align="center" spacing="XXSmall">
          <SeatIcon size="small" />
          <Text type="secondary">
            {groupSeatsByStatus(groupedSeats.processing, polyglot, 'processing')}
          </Text>
          <StatusBadgeWrapper>
            <StatusBadge status="processing" />
          </StatusBadgeWrapper>
        </Inline>
      )}
      {!!groupedSeats?.confirmed.length && (
        <Inline align="center" spacing="XXSmall">
          <SeatIcon size="small" />
          <Text type="secondary">
            {groupSeatsByStatus(groupedSeats.confirmed, polyglot, 'confirmed')}
          </Text>
          <StatusBadgeWrapper>
            <StatusBadge status="confirmed" />
          </StatusBadgeWrapper>
        </Inline>
      )}
    </>
  );
};

export default SeatsStatus;
