import { SHOW_VALUES_I18N } from 'consts/reports';

export const ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  PARTNER: 'user',
};

export const FORBIDDEN_METRICS_VALUES = {
  [ROLES.PARTNER]: {
    profit: true,
    revenue: true,
  },
};

export const getMetricsForUser = user => {
  if (user.role.some(({ name }) => name === ROLES.PARTNER)) {
    return SHOW_VALUES_I18N.filter(
      metric => !FORBIDDEN_METRICS_VALUES[ROLES.PARTNER][metric.value],
    );
  }

  return SHOW_VALUES_I18N;
};

export const solutionHasCsWebhookInfo = solution => solution.productCategory !== 'AFFILIATE';
