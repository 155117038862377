import React, { useContext, useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { useHistory } from 'react-router-dom';

import { usePolyglot } from 'components/services/i18n';
import useMenuState from 'components/services/menu/useMenuState';
import { newSmartPassInitialValues, statuses, SmartPassValidationSchema } from 'consts/smartPass';
import { SmartPassContext } from 'components/services/smartPass';
import { ROUTE_CONFIG } from 'consts/routes';

import Form from '../../components/Form';

const NewSmartPass = ({ companyName }) => {
  const menuCtx = useMenuState();
  useEffect(() => {
    menuCtx.hideNavigation();
    menuCtx.hideHeader();

    return () => {
      menuCtx.showNavigation();
      menuCtx.showHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const polyglot = usePolyglot();
  const { createNewSmartPass, state } = useContext(SmartPassContext);
  const history = useHistory();

  const getSubmitHandler =
    ({ saveType }) =>
    async values => {
      const res = await createNewSmartPass(values, saveType, companyName);
      if (res && res.status === 200) {
        if (saveType === statuses.DRAFT) {
          history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass?draftSuccess=true`);
        } else {
          history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass?success=true`);
        }
      }
    };

  return (
    <Formik
      initialValues={newSmartPassInitialValues}
      onSubmit={getSubmitHandler({ saveType: statuses.PENDING_REVIEW, goBack: true })}
      validationSchema={SmartPassValidationSchema}
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors, touched, setFieldTouched }) => (
        <FormikForm>
          <Form
            setFieldTouched={setFieldTouched}
            values={values}
            getSubmitHandler={getSubmitHandler}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            companyName={companyName}
            disabled={false}
            status={undefined}
            responseError={
              state.createNewSmartPassError && polyglot.t('smart_pass.add_new_smart_pass_error')
            }
            errors={errors}
            touched={touched}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default NewSmartPass;
