import { useContext, useEffect } from 'react';

import { ResourcesState } from './';

const useRegisterPrivacyPolicy = () => {
  const {
    state: { registerPrivacyPolicy },
    fetchRegisterPrivacyPolicy,
  } = useContext(ResourcesState);
  useEffect(() => {
    if (true || !registerPrivacyPolicy.data) {
      fetchRegisterPrivacyPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return registerPrivacyPolicy;
};

export default useRegisterPrivacyPolicy;
