import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  display: flex;

  & > div {
    flex: 1;
  }
`;

export const PricesWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 4px;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding-top: 16px;
`;

export const TotalLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalLabel = styled.div`
  display: inline-block;
  margin-left: 10px;
  text-decoration: underline;
`;

export const TotalPrice = styled.div`
  font-weight: bold;
`;
