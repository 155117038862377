import React from 'react';
import styled from 'styled-components';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';

import { usePolyglot } from 'components/services/i18n';

const TextContainer = styled.div`
  width: 240px;
  margin-top: 4px;
`;

const NoWidgetsInfo = () => {
  const polyglot = usePolyglot();

  return (
    <Stack direction="column" align="center" spacing="small" spaceAfter="largest">
      <Text align="center" weight="bold" size="large">
        {polyglot.t('widgets.no_solutions_almost_there')}
        <TextContainer>
          <Text weight="normal" type="secondary" size="medium" align="center">
            {polyglot.t('widgets.no_widgets_setup')}
          </Text>
        </TextContainer>
      </Text>
      <Illustration name="Train" />
    </Stack>
  );
};

export default NoWidgetsInfo;
