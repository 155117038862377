import React, { useReducer } from 'react';

import { proxy } from 'utils/api';
import SaveBookingMapper from 'mappers/nextGen/booking/save_booking/_';
import ConfirmPaymentMapper from 'mappers/nextGen/booking/confirm_payment/_';

export const SaveBookingState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SAVE_BOOKING':
      return {
        ...state,
        saveBookingLoading: true,
        saveBookingError: null,
        data: null,
      };
    case 'SAVE_BOOKING_SUCCESS':
      return {
        ...state,
        saveBookingLoading: false,
        data: action.data,
      };
    case 'SAVE_BOOKING_FAIL':
      return {
        ...state,
        saveBookingLoading: false,
        saveBookingError: action.error,
      };
    case 'CONFIRM_PAYMENT':
      return {
        ...state,
        confirmPaymentLoading: true,
        confirmPaymentError: null,
        data: null,
      };
    case 'CONFIRM_PAYMENT_SUCCESS':
      return {
        ...state,
        confirmPaymentLoading: false,
        data: action.data,
      };
    case 'CONFIRM_PAYMENT_FAIL':
      return {
        ...state,
        confirmPaymentLoading: false,
        confirmPaymentError: action.error,
      };
    default:
      return state;
  }
};

const SaveBookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const saveBooking = async (params, seatings, baggage, paidGuarantee) => {
    dispatch({ type: 'SAVE_BOOKING' });

    try {
      const { data } = await proxy.v2.post(
        'booking/save_booking',
        SaveBookingMapper.v2.post.to(params, seatings, baggage, paidGuarantee),
        {
          headers: { apikey: params.apiKey },
        },
      );

      await confirmPayment(params, data);

      dispatch({ type: 'SAVE_BOOKING_SUCCESS', data });
      return data;
    } catch (error) {
      return Promise.reject(dispatch({ type: 'SAVE_BOOKING_FAIL', error: error.response.data }));
    }
  };

  const confirmPayment = async (params, data) => {
    dispatch({ type: 'CONFIRM_PAYMENT' });

    try {
      await proxy.v2.post('booking/confirm_payment', ConfirmPaymentMapper.v2.post.to(data), {
        headers: { apikey: params.apiKey },
      });

      dispatch({ type: 'CONFIRM_PAYMENT_SUCCESS', data });
      return data;
    } catch (error) {
      return Promise.reject(dispatch({ type: 'CONFIRM_PAYMENT_FAIL', error: error.response.data }));
    }
  };

  const value = {
    ...state,
    saveBooking,
    confirmPayment,
  };
  return <SaveBookingState.Provider value={value}>{children}</SaveBookingState.Provider>;
};

export default SaveBookingProvider;
