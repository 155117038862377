import { useRouteMatch } from 'react-router-dom';

const useCurrentSolutionAffilId = () => {
  const {
    params: { affilId },
  } = useRouteMatch();

  return affilId;
};

export default useCurrentSolutionAffilId;
