import * as R from 'ramda';

export const isNotEmptyOrNull = value => value && !R.isEmpty(value);

// will split string to the array of strings and will keep deliminator
export const splitButKeepDeliminator = (value, deliminator) =>
  R.split(new RegExp(`(${deliminator.replace(/\\/gi, '\\\\')})`, 'gi'), value).filter(
    string => string,
  );

export const capitalize = R.compose(R.replace(/^./, R.toUpper), String);

export const snakeCaseToCamelCase = value => value.replace(/_\w/g, m => m[1].toUpperCase());

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const escapeInvalidJsonValues = (key, val) => {
  if (typeof val !== 'string') {
    return val;
  }

  return val
    .replace(/[\\]/g, '\\\\')
    .replace(/["]/g, '\\"')
    .replace(/[/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t');
};

export const truncate = (string, limit, trailing = '...') =>
  string.length > limit ? `${string.substring(0, limit)}${trailing}` : string;

export const endsWith = (str, suffix) => str.indexOf(suffix, str.length - suffix.length) !== -1;

/**
 * @param {Function} getter - fn that extracts a property
 * @param {('asc'|'desc')} dir - direction
 */
export const sortComparator =
  (getter, dir = 'asc') =>
  (item1, item2) => {
    const [a, b] = [dir === 'asc' ? item1 : item2, dir === 'asc' ? item2 : item1];

    if (getter(a) < getter(b)) {
      return -1;
    }

    if (getter(a) > getter(b)) {
      return 1;
    }

    return 0;
  };
