export const TEQUILA_BRANDS = {
  staging: {
    SMARTPOINT_DEPOSIT: 'staging-tequila-fe-deposit-smartpoint',
    SMARTPOINT_ZOOZ: 'staging-tequila-fe-smartpoint',
    DEPOSIT: 'staging-tequila-fe-deposit',
    ZOOZ: 'staging-tequila-fe',
  },
  production: {
    SMARTPOINT_DEPOSIT: 'tequila-fe-deposit-smartpoint',
    SMARTPOINT_ZOOZ: 'tequila-fe-smartpoint',
    DEPOSIT: 'tequila-fe-deposit',
    ZOOZ: 'tequila-fe',
  },
};
