import React, { useState, useEffect } from 'react';

import languages from 'data/languages.v2.json';
import { locales } from 'consts/locales';
import { getLocale } from 'utils/locale';

export const TranslationState = React.createContext({});

const DEFAULT_LANGUAGE = 'en';

const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState(null);
  const [locale, setLocale] = useState(null);
  const [lang, setLang] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    if (translations) {
      return;
    }

    //hardcoded for now, will change later
    const langCode = getLocale(DEFAULT_LANGUAGE);

    const language = languages[langCode] || languages[DEFAULT_LANGUAGE];

    Promise.all([
      fetch(`/static/translations/${language.phraseApp}.json`).then(r => r.json()),
      locales[langCode](),
    ]).then(([fetchedTranslations, fetchedLocale]) => {
      setTranslations(fetchedTranslations);
      setLocale(fetchedLocale.default);
      setLang(langCode);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const localeData = languages[lang?.split('-')?.[0]];

  const translate = (tkey, values = {}) => {
    return (
      Object.keys(values).reduce(
        (xs, x) => xs.replace(`__${x}__`, values[x]),
        translations[tkey || ''],
      ) || tkey
    );
  };

  if (!lang) {
    return null;
  }

  const value = {
    translations,
    lang,
    locale,
    localeData,
    translate,
  };

  return <TranslationState.Provider value={value}>{children}</TranslationState.Provider>;
};

export default TranslationProvider;
