import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Select from '@kiwicom/orbit-components/lib/Select';

import { usePolyglot } from 'components/services/i18n';
import { useCurrency } from 'components/services/currencies';

import { keys, voucherDiscountTypeValues, voucherTypeValues } from 'consts/smartPass';
import { IconSuffix } from 'common';

import { LabelsRowWrapper, WidthWrapper, HalfWidthWrapper } from '../index.styled';

const {
  VOUCHER_DISCOUNT_TYPE,
  VOUCHER_DISCOUNT_PERCENTAGE,
  VOUCHER_DISCOUNT_AMOUNT,
  VOUCHER_DISCOUNT_CURRENCY,
  VOUCHER_PLACE_NAME_1,
  VOUCHER_PLACE_NAME_2,
  VOUCHER_PLACE_NAME_3,
  VOUCHER_PLACE_NAME_4,
  VOUCHER_URL,
  VOUCHER_TYPE,
} = keys;

const StandardDesign = ({ handleChange, handleBlur, values, touched, errors }) => {
  const polyglot = usePolyglot();
  const { currencies } = useCurrency();

  const placeNemesByType = {
    [voucherTypeValues.SHOP]: {
      singular: polyglot.t('smart_pass.form.vouchers.voucher_type.shop'),
      plural: polyglot.t('smart_pass.form.vouchers.voucher_type.plural.shop'),
    },
    [voucherTypeValues.RESTAURANT]: {
      singular: polyglot.t('smart_pass.form.vouchers.voucher_type.restaurant'),
      plural: polyglot.t('smart_pass.form.vouchers.voucher_type.plural.restaurant'),
    },
    [voucherTypeValues.HOTEL]: {
      singular: polyglot.t('smart_pass.form.vouchers.voucher_type.hotel'),
      plural: polyglot.t('smart_pass.form.vouchers.voucher_type.plural.hotel'),
    },
    [voucherTypeValues.BAG_DEPOSIT]: {
      singular: polyglot.t('smart_pass.form.vouchers.bag_deposit_name'),
    },
    [voucherTypeValues.LOUNGE_ACCESS]: {
      singular: polyglot.t('smart_pass.form.vouchers.lounge_name'),
    },
  };

  const voucherType = values[VOUCHER_TYPE];
  const placeName = placeNemesByType[voucherType];

  const shopRestaurantHotelArray = [
    voucherTypeValues.SHOP,
    voucherTypeValues.RESTAURANT,
    voucherTypeValues.HOTEL,
  ];

  const isShopOrRestaurantOrHotel = shopRestaurantHotelArray.includes(voucherType);
  const isLoungeAccess = voucherType === voucherTypeValues.LOUNGE_ACCESS;
  const isBagDeposit = voucherType === voucherTypeValues.BAG_DEPOSIT;

  return (
    <>
      <Separator spaceAfter="large" />
      <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.discount')}</Text>
      <Stack spaceAfter="large">
        <LabelsRowWrapper>
          <Radio
            label={polyglot.t('smart_pass.form.vouchers.discount_percentage')}
            name={VOUCHER_DISCOUNT_TYPE}
            value={voucherDiscountTypeValues.PERCENTAGE}
            checked={values[VOUCHER_DISCOUNT_TYPE] === voucherDiscountTypeValues.PERCENTAGE}
            onChange={handleChange}
            hasError={touched[VOUCHER_DISCOUNT_TYPE] && errors[VOUCHER_DISCOUNT_TYPE]}
          />
          <WidthWrapper labelWidthWrap>
            <InputField
              inlineLabel
              label="%"
              placeholder={polyglot.t('smart_pass.form.vouchers.discount_percentage_placeholder')}
              name={VOUCHER_DISCOUNT_PERCENTAGE}
              value={values[VOUCHER_DISCOUNT_PERCENTAGE]}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values[VOUCHER_DISCOUNT_TYPE] !== voucherDiscountTypeValues.PERCENTAGE}
              error={
                touched[VOUCHER_DISCOUNT_PERCENTAGE] &&
                errors[VOUCHER_DISCOUNT_PERCENTAGE] &&
                polyglot.t(errors[VOUCHER_DISCOUNT_PERCENTAGE])
              }
            />
          </WidthWrapper>
        </LabelsRowWrapper>
        <LabelsRowWrapper labelWidth="156px">
          <Radio
            label={polyglot.t('smart_pass.form.vouchers.discount_amount')}
            name={VOUCHER_DISCOUNT_TYPE}
            value={voucherDiscountTypeValues.AMOUNT}
            checked={values[VOUCHER_DISCOUNT_TYPE] === voucherDiscountTypeValues.AMOUNT}
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={touched[VOUCHER_DISCOUNT_TYPE] && errors[VOUCHER_DISCOUNT_TYPE]}
          />
          <WidthWrapper labelWidthWrap>
            <Stack direction="row">
              <InputField
                inlineLabel
                label={polyglot.t('smart_pass.form.vouchers.discount_amount_label')}
                placeholder={polyglot.t('smart_pass.form.vouchers.discount_amount_placeholder')}
                name={VOUCHER_DISCOUNT_AMOUNT}
                value={values[VOUCHER_DISCOUNT_AMOUNT]}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values[VOUCHER_DISCOUNT_TYPE] !== voucherDiscountTypeValues.AMOUNT}
                error={
                  touched[VOUCHER_DISCOUNT_AMOUNT] &&
                  errors[VOUCHER_DISCOUNT_AMOUNT] &&
                  polyglot.t(errors[VOUCHER_DISCOUNT_AMOUNT])
                }
              />
              <Select
                options={currencies.map(currency => ({
                  label: `${currency.code.toUpperCase()} (${currency.name})`,
                  value: currency.code.toUpperCase(),
                }))}
                placeholder={polyglot.t('common.currency')}
                disabled={values[VOUCHER_DISCOUNT_TYPE] !== voucherDiscountTypeValues.AMOUNT}
                name={VOUCHER_DISCOUNT_CURRENCY}
                value={values[VOUCHER_DISCOUNT_CURRENCY]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[VOUCHER_DISCOUNT_CURRENCY] &&
                  errors[VOUCHER_DISCOUNT_CURRENCY] &&
                  polyglot.t(errors[VOUCHER_DISCOUNT_CURRENCY])
                }
              />
            </Stack>
          </WidthWrapper>
        </LabelsRowWrapper>
      </Stack>
      <Separator spaceAfter="large" />

      {isShopOrRestaurantOrHotel && (
        <>
          <Text spaceAfter="small">
            {polyglot.t('smart_pass.form.vouchers.place_type_names', {
              placeName: placeName.singular,
            })}
          </Text>
          <Stack direction="row" spaceAfter="small">
            <InputField
              placeholder={
                touched[VOUCHER_PLACE_NAME_1] && errors[VOUCHER_PLACE_NAME_1]
                  ? polyglot.t(errors[VOUCHER_PLACE_NAME_1])
                  : polyglot.t('smart_pass.form.vouchers.place_type_name', {
                      number: 1,
                      placeName: placeName.singular,
                    })
              }
              name={VOUCHER_PLACE_NAME_1}
              value={values[VOUCHER_PLACE_NAME_1]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched[VOUCHER_PLACE_NAME_1] && errors[VOUCHER_PLACE_NAME_1] && ' '}
            />
            <InputField
              placeholder={polyglot.t('smart_pass.form.vouchers.place_type_name', {
                number: 2,
                placeName: placeName.singular,
              })}
              name={VOUCHER_PLACE_NAME_2}
              value={values[VOUCHER_PLACE_NAME_2]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Stack>
          <Stack direction="row" spaceAfter="small">
            <InputField
              placeholder={polyglot.t('smart_pass.form.vouchers.place_type_name', {
                number: 3,
                placeName: placeName.singular,
              })}
              name={VOUCHER_PLACE_NAME_3}
              value={values[VOUCHER_PLACE_NAME_3]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputField
              placeholder={polyglot.t('smart_pass.form.vouchers.place_type_name', {
                number: 4,
                placeName: placeName.singular,
              })}
              name={VOUCHER_PLACE_NAME_4}
              value={values[VOUCHER_PLACE_NAME_4]}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Stack>
          <Text size="small" type="secondary" spaceAfter="medium">
            {polyglot.t('smart_pass.form.vouchers.place_type_info', {
              placeName: placeName.plural,
            })}
          </Text>
        </>
      )}

      <Stack direction="row" spaceAfter="large">
        {(isLoungeAccess || isBagDeposit) && (
          <HalfWidthWrapper>
            <InputField
              label={placeName.singular}
              placeholder={placeName.singular}
              name={VOUCHER_PLACE_NAME_1}
              value={values[VOUCHER_PLACE_NAME_1]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[VOUCHER_PLACE_NAME_1] &&
                errors[VOUCHER_PLACE_NAME_1] &&
                polyglot.t(errors[VOUCHER_PLACE_NAME_1])
              }
            />
          </HalfWidthWrapper>
        )}
        <HalfWidthWrapper>
          <InputField
            suffix={
              <Tooltip content={polyglot.t('smart_pass.form.vouchers.url_tooltip')}>
                <IconSuffix>
                  <InformationCircleIcon />
                </IconSuffix>
              </Tooltip>
            }
            label={polyglot.t('smart_pass.form.vouchers.url_label')}
            name={VOUCHER_URL}
            value={values[VOUCHER_URL]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched[VOUCHER_URL] && errors[VOUCHER_URL] && ' '}
          />
        </HalfWidthWrapper>
      </Stack>

      <Separator spaceAfter="medium" />
    </>
  );
};

export default StandardDesign;
