import React, { useContext } from 'react';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';

const UnsavedVoucherModal = () => {
  const { unsavedVoucherModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();
  return (
    <Modal size="small" closable onClose={unsavedVoucherModal.setOff}>
      <ModalHeader
        title={polyglot.t('smart_pass.unsaved_voucher_modal.title')}
        description={polyglot.t('smart_pass.unsaved_voucher_modal.description')}
      />
      <ModalFooter>
        <Stack direction="row" justify="end">
          <Button type="critical" onClick={unsavedVoucherModal.setOff}>
            {polyglot.t('common.ok_got_it')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default UnsavedVoucherModal;
