import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';

import Loading from '@kiwicom/orbit-components/lib/Loading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';

import { PageContainer } from 'common';
import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { useToggle } from 'utils/hooks';

import List from './components/List';
import SalesPitch from './components/SalesPitch';

const ButtonBarWrapper = styled.div`
  position: fixed;
  z-index: 20;
  background: #f5f7f9;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  margin-left: -23px;
  padding-left: 24px;
  margin-top: -24px;
`;

const MoveDown = styled.div`
  margin-top: 60px;
`;

const FixedWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
`;
const SmartPassDetails = ({ companyName }) => {
  const { fetchSmartPassesList, state } = useContext(SmartPassContext);
  const polyglot = usePolyglot();
  const location = useLocation();
  const { success, draftSuccess } = queryString.parse(location.search);

  const successAlert = useToggle(Boolean(success));
  const draftSuccessAlert = useToggle(Boolean(draftSuccess));

  useEffect(() => {
    fetchSmartPassesList(companyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.smartPassesListLoading) {
    return (
      <FixedWrapper>
        <Loading />
      </FixedWrapper>
    );
  }

  if (state.smartPassesListError) {
    return (
      <PageContainer>
        <Alert type="critical">{state.smartPassesListError}</Alert>
      </PageContainer>
    );
  }
  return (
    <>
      <ButtonBarWrapper>
        <Link to={`${ROUTE_CONFIG.COMPANY.toPath}/${companyName}`}>
          <Button size="small" iconLeft={<ChevronBackwardIcon />} />
        </Link>
      </ButtonBarWrapper>
      <PageContainer>
        <MoveDown>
          {successAlert.isOn && (
            <Alert type="success" closable onClose={successAlert.setOff} spaceAfter="large">
              {polyglot.t('smart_pass.success')}
            </Alert>
          )}
          {draftSuccessAlert.isOn && (
            <Alert type="success" closable onClose={draftSuccessAlert.setOff} spaceAfter="large">
              {polyglot.t('smart_pass.draft_success')}
            </Alert>
          )}
          {state.smartPassesList.length > 0 ? (
            <List companyName={companyName} smartPassesList={state.smartPassesList} />
          ) : (
            <SalesPitch companyName={companyName} />
          )}
        </MoveDown>
      </PageContainer>
    </>
  );
};

export default SmartPassDetails;
