import lodash from 'lodash';
import countries from 'data/countries.json';

export function retry(fn, retriesLeft = 3, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // lets just reload the page if we cant fetch the chunk
            window.location.reload();
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const checkDirtyFields = (x, y) => {
  return lodash.pickBy(y, (v, k) => !lodash.isEqual(x?.[k], v));
};

export const modifyObject = (obj, action, fields) => {
  if (!obj || typeof obj !== 'object' || !Array.isArray(fields)) {
    return obj;
  }

  if (action === 'include') {
    const includedObj = {};
    for (const field of fields) {
      if (obj.hasOwnProperty(field)) {
        includedObj[field] = obj[field];
      }
    }
    return includedObj;
  } else if (action === 'exclude') {
    const excludedObj = { ...obj };
    for (const field of fields) {
      if (excludedObj.hasOwnProperty(field)) {
        delete excludedObj[field];
      }
    }
    return excludedObj;
  }

  return obj;
};

/**
 * camelize will convert plain objects and array of objects to camel
 * @param {object} obj
 * @returns {object | Array.<Object>}
 */

export const camelize = obj =>
  lodash.transform(obj, (acc, value, key, target) => {
    const camelKey = lodash.isArray(target) ? key : lodash.camelCase(key);

    acc[camelKey] = lodash.isObject(value) ? camelize(value) : value;
  });

export const formatPhonePrefix = countryId => {
  const callingCode = countries.find(country => country.value === countryId).callingCode;

  return `+${callingCode}`;
};
