import { countriesWithICO } from 'consts/countries';

export const getVatIdLabel = country => {
  if (countriesWithICO.includes(country)) {
    return 'company.company_id';
  }

  if (country === 'us') {
    return 'company.fein';
  }

  return 'company.vat_id';
};
