import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import debounce from 'debounce';

import { getPolyglot } from 'redux/selectors/i18n';
import * as reportUtils from 'pages/Reports/utils';

import { InputAutocomplete } from 'common';

import { SolutionsState } from 'components/services/solutions';

const FILTER_DEBOUNCE_TIME = 100;

const withNewEndpoints = WrappedComponent => {
  return function ({ ...props }) {
    const { state } = useContext(SolutionsState);
    return (
      <WrappedComponent {...{ ...props, applications: state?.[props.companyName]?.data || [] }} />
    );
  };
};

class FilterValuesAutocomplete extends Component {
  state = {
    filteredValues: [],
    query: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.filter !== nextProps.filter) {
      this.updateFilteredValues(nextProps.filter);
    }
  }

  debouncedGetFilteredValues = debounce(
    filter => this.getFilteredValues(filter),
    FILTER_DEBOUNCE_TIME,
  );

  updateFilteredValues = filter => {
    this.debouncedGetFilteredValues(filter);
  };

  getFilteredValues = filter => {
    const { polyglot, selectedValues, applications } = this.props;
    const { query } = this.state;
    reportUtils.getFilteredValues(filter, query, polyglot, applications || []).then(response => {
      const filteredValues = R.differenceWith(
        (item, selectedItem) => item.value === selectedItem,
        response,
        selectedValues,
      );
      this.setState({ filteredValues });
    });
  };

  handleFocus = () => this.updateFilteredValues(this.props.filter);

  handleFilterValueQueryChange = query =>
    this.setState({ query }, () => this.updateFilteredValues(this.props.filter));

  handleSelect = value => {
    const { onSelect, filter } = this.props;
    this.setState({ query: '' }, () => this.updateFilteredValues(filter));
    onSelect(value);
  };

  render() {
    const { polyglot, isDisabled, isInEditMode, selectedValues, filter, onUnselect, applications } =
      this.props;
    const { filteredValues } = this.state;

    return (
      <InputAutocomplete
        id="report-alias-filters-autocomplete"
        type="multi"
        size="small"
        inputWrapperClassName={`${
          !isDisabled && isInEditMode ? 'filter-autocomplete-white-background' : ''
        } ${!isInEditMode ? 'filter-autocomplete-row' : ''}`}
        disabled={isDisabled}
        placeholder={polyglot.t(
          isDisabled ? 'reports.filter_type_first' : 'reports.enter_filter_value',
        )}
        displayedItems={filteredValues}
        selectedItems={reportUtils.mapFilterValuesForDisplay(
          filter,
          selectedValues,
          polyglot,
          applications || [],
        )}
        onSelect={this.handleSelect}
        handleRemove={onUnselect}
        filter={this.handleFilterValueQueryChange}
        onFocus={this.handleFocus}
        moveOverflow
      />
    );
  }
}

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

FilterValuesAutocomplete.propTypes = {
  isDisabled: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  filter: PropTypes.string,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  selectedValues: PropTypes.array,
};

export default connect(mapStateToProps)(withNewEndpoints(FilterValuesAutocomplete));
