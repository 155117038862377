import React, { useEffect, useState } from 'react';
import { Check } from '@kiwicom/orbit-components/icons';
import styled, { keyframes } from 'styled-components';
import { useDependencyEffect } from 'hooks';

const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

const ThreeStatesCheckboxHolder = styled.div`
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  border: 1px solid
    ${({ checkboxState }) => (checkboxState === 0 || checkboxState === 1 ? '#bac7d5' : '#0172cb')};
  background-color: ${({ checkboxState }) =>
    checkboxState === 0 || checkboxState === 1 ? 'transparent' : '#0172cb'};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    border-color: ${({ checkboxState }) =>
      checkboxState === 0 || checkboxState === 1 ? '#b4dbf8' : '#005aa3'};
    background-color: ${({ checkboxState }) =>
      checkboxState === 0 || checkboxState === 1 ? 'transparent' : '#005aa3'};
  }

  &:active {
    border-color: #0172cb !important;
    transform: scale(0.95);
  }

  & > svg {
    animation: ${fadeIn} 0.15s forwards;
    width: 16px;
    height: 16px;

    & > path {
      fill: #fff;
    }
  }
`;

const SquareIcon = styled.div`
  animation: ${fadeIn} 0.15s forwards;
  max-width: 12px;
  min-width: 12px;
  max-height: 12px;
  min-height: 12px;
  background-color: #0172cb;
  border-radius: 2px;
`;

const ThreeStatesCheckbox = ({ defaultValue, stateValues, values, onChange }) => {
  const [checkboxState, setCheckboxState] = useState(defaultValue || 0); // states: 0 (not active state), 1 (middle state) and 2 (active state)

  const handleOnClick = () => {
    if (checkboxState === 0 || checkboxState === 1) {
      setCheckboxState(2);
    } else if (checkboxState === 2) {
      setCheckboxState(0);
    }
  };

  useEffect(() => {
    if (stateValues && values) {
      const stateValuesLength = stateValues.length;
      const valuesLength = values.length;
      if (!stateValuesLength) {
        setCheckboxState(0);
      } else if (stateValuesLength && stateValuesLength !== valuesLength) {
        setCheckboxState(1);
      } else if (stateValuesLength && stateValuesLength === valuesLength) {
        setCheckboxState(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValues]);

  useDependencyEffect(() => {
    if (onChange) {
      onChange(checkboxState);
    }
  }, [checkboxState]);

  return (
    <ThreeStatesCheckboxHolder onClick={handleOnClick} checkboxState={checkboxState}>
      {checkboxState === 1 ? <SquareIcon /> : checkboxState === 2 ? <Check /> : null}
    </ThreeStatesCheckboxHolder>
  );
};

export default ThreeStatesCheckbox;
