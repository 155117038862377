import React from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 176px;
  justify-content: space-between;
`;

const SmartpointConfigurationModal = ({ blockTransition, allowTransition, listOfChangedPCCs }) => {
  const polyglot = usePolyglot();

  const handleSaveClick = () => {
    blockTransition();
  };

  return (
    <Modal closable onClose={blockTransition}>
      <ModalHeader
        title={polyglot.t('common.new_changes')}
        description={polyglot.t('smartpoint_configuration.modal.description', {
          listOfChangedPCCs,
        })}
      />
      <ModalSection>
        <Stack direction="row" justify="between" align="center">
          <Text type="secondary">{polyglot.t('smartpoint_configuration.modal.note')}</Text>
          <ButtonsWrapper>
            <Button type="secondary" onClick={allowTransition}>
              {polyglot.t('common.dont_save')}
            </Button>
            <Button onClick={handleSaveClick}>{polyglot.t('common.close')}</Button>
          </ButtonsWrapper>
        </Stack>
      </ModalSection>
    </Modal>
  );
};

export default SmartpointConfigurationModal;
