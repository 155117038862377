import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import isWithinInterval from 'date-fns/isWithinInterval';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { SelectNationality, DateInput } from 'common';

import Polyglot from 'shapes/Polyglot';
import { cantContainCyrillic } from 'utils/validation';
import { redFlagCountries } from 'consts/countries';

const MajorityStakeholderInfo = ({
  polyglot,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  setFieldValue,
  isAdditionalInfoRequired,
}) => {
  if (!redFlagCountries.includes(values.country)) {
    return null;
  }
  return (
    <Stack spaceAfter="largest" spacing="large">
      <Heading type="title2" element="h2">
        {polyglot.t('register.majority_stakeholder_information')}
      </Heading>
      <Stack direction="row" spacing="XSmall">
        <InputField
          id="majority-stakeholder-info-first-name"
          label={polyglot.t('common.first_name')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="stakeholderFirstName"
          value={values.stakeholderFirstName}
          error={
            touched.stakeholderFirstName &&
            errors.stakeholderFirstName &&
            polyglot.t(errors.stakeholderFirstName)
          }
        />
        <InputField
          id="majority-stakeholder-info-last-name"
          label={polyglot.t('common.last_name')}
          onChange={handleChange}
          onBlur={handleBlur}
          name="stakeholderLastName"
          value={values.stakeholderLastName}
          error={
            touched.stakeholderLastName &&
            errors.stakeholderLastName &&
            polyglot.t(errors.stakeholderLastName)
          }
        />
      </Stack>
      {isAdditionalInfoRequired && (
        <>
          <DateInput
            label={polyglot.t('register.date_input.label')}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            name="stakeholderDateOfBirth"
            value={values.stakeholderDateOfBirth}
            error={
              touched.stakeholderDateOfBirth &&
              errors.stakeholderDateOfBirth &&
              polyglot.t(errors.stakeholderDateOfBirth)
            }
          />
          <SelectNationality
            id="majority-stakeholder-info-nationality"
            label={polyglot.t('register.nationality')}
            onChange={handleChange}
            onBlur={handleBlur}
            name="stakeholderNationality"
            value={values.stakeholderNationality}
            error={
              touched.stakeholderNationality &&
              errors.stakeholderNationality &&
              polyglot.t(errors.stakeholderNationality)
            }
          />
        </>
      )}
    </Stack>
  );
};

MajorityStakeholderInfo.schema = Yup.object().shape({
  stakeholderFirstName: Yup.string().when('country', {
    is: country => redFlagCountries.includes(country),
    then: Yup.string()
      .required('validation.cant_be_empty')
      .max(255, 'validation.length_exceeded')
      .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic),
  }),
  stakeholderLastName: Yup.string().when('country', {
    is: country => redFlagCountries.includes(country),
    then: Yup.string()
      .required('validation.cant_be_empty')
      .max(255, 'validation.length_exceeded')
      .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic),
  }),
  stakeholderNationality: Yup.string().when('$isAdditionalInfoRequired', {
    is: isAdditionalInfoRequired => isAdditionalInfoRequired,
    then: Yup.string().required('validation.cant_be_empty').nullable(),
  }),
  stakeholderDateOfBirth: Yup.string().when('$isAdditionalInfoRequired', {
    is: isAdditionalInfoRequired => isAdditionalInfoRequired,
    then: Yup.string()
      .required('validation.cant_be_empty')
      .test('is-valid', 'validation.invalid_date', date =>
        isWithinInterval(new Date(date), { start: new Date(1900, 0), end: new Date() }),
      )
      .nullable(),
  }),
});

MajorityStakeholderInfo.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isAdditionalInfoRequired: PropTypes.bool.isRequired,
};

export default MajorityStakeholderInfo;
