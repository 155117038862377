import React, { useContext } from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { FitContentWidthContainer } from './VoucherPreviewModal/components/StandardVoucher.styled';

import smallQR from '../../../images/QR_small.jpg';
import largeQR from '../../../images/QR_large.jpg';
import smallEAN from '../../../images/EAN_small.jpg';
import largeEAN from '../../../images/EAN_large.jpg';
import standardQR from '../../../images/standard_QR.jpg';
import standardEAN from '../../../images/standard_EAN.jpg';

const Image = styled.img`
  margin-bottom: 24px;
  width: 100%;
`;

const VoucherExampleModal = () => {
  const { voucherExampleModal } = useContext(SmartPassContext);
  const polyglot = usePolyglot();
  return (
    <FitContentWidthContainer>
      <Modal closable onClose={voucherExampleModal.setOff}>
        <ModalHeader title={polyglot.t('smart_pass.voucher_example_modal.title')} />
        <ModalSection>
          <Text spaceAfter="small" size="large" weight="bold">
            {polyglot.t('smart_pass.form.own')}
          </Text>
          <Alert
            spaceAfter="large"
            icon={<InformationCircleIcon />}
            title={polyglot.t('smart_pass.alert_title')}
          >
            <Text type="info">{polyglot.t('smart_pass.no_localisation')}</Text>
            <Text type="info">{polyglot.t('smart_pass.max_6_vouchers')}</Text>
            <Text type="info">{polyglot.t('smart_pass.size_812_296')}</Text>
            <Text type="info">{polyglot.t('smart_pass.size_398_296')}</Text>
          </Alert>
          <Text spaceAfter="large" weight="bold" uppercase>
            {polyglot.t('smart_pass.voucher_example.small_voucher')}
          </Text>
          <Stack direction="row" spaceAfter="large">
            <img src={smallQR} alt="example QR small voucher" />
            <img src={smallEAN} alt="example EAN small voucher" />
          </Stack>
          <Text spaceAfter="large" weight="bold" uppercase>
            {polyglot.t('smart_pass.voucher_example.large_voucher')}
          </Text>
          <Image src={largeQR} alt="example QR large voucher" />
          <Image src={largeEAN} alt="example EAN large voucher" />

          <Text spaceAfter="small" size="large" weight="bold">
            {polyglot.t('smart_pass.form.standard')}
          </Text>
          <Stack direction="row" spaceAfter="large">
            <img src={standardQR} alt="example QR standard voucher" />
            <img src={standardEAN} alt="example EAN standard voucher" />
          </Stack>
        </ModalSection>
      </Modal>
    </FitContentWidthContainer>
  );
};

export default VoucherExampleModal;
