import React from 'react';
import styled from 'styled-components';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import SearchIcon from '@kiwicom/orbit-components/lib/icons/Search';

import { fireDocsEvent } from 'utils/ga';
import { IconSuffix } from 'common';
import useFAQSearch from 'components/services/resources/useFAQSearch';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';

import { SearchFAQWrapper } from '../index.styled';

const ContentWrapper = styled.div`
  width: 100%;
`;

const SearchFAQ = () => {
  const polyglot = usePolyglot();
  const { query, handleQueryChange } = useFAQSearch();

  const handleSubmit = e => {
    e.preventDefault();

    fireDocsEvent({
      event: 'tequila.help.faqSearchTriggered',
      query,
      page: window.location.pathname,
    });

    window.open(`${ROUTE_CONFIG.DOCS_FAQ.path}?query=${query}`, '_blank');
  };

  return (
    <SearchFAQWrapper>
      <ContentWrapper>
        <Heading spaceAfter="small" inverted>
          {polyglot.t('support.faq_search.title')}
        </Heading>
        <Text size="large" spaceAfter="largest" type="white">
          {polyglot.t('support.faq_search.description')}
        </Text>
        <form onSubmit={handleSubmit}>
          <Stack direction="row">
            <InputField
              onChange={handleQueryChange}
              value={query}
              placeholder={polyglot.t('resources.faq_search_placeholder')}
              suffix={
                <IconSuffix>
                  <SearchIcon size="small" />
                </IconSuffix>
              }
            />
            <Button submit type="white">
              {polyglot.t('common.search')}
            </Button>
          </Stack>
        </form>
      </ContentWrapper>
      <Illustration name="AirHelp" />
    </SearchFAQWrapper>
  );
};

export default SearchFAQ;
