import React, { useContext, useEffect, useState } from 'react';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ChevronBackwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import Text from '@kiwicom/orbit-components/lib/Text';

import { ROUTE_CONFIG, selectableNavigationItems } from 'consts/routes';
import { GETTING_STARTED_ROLES } from 'consts/roles';
import { AuthState } from 'components/services/auth/AuthProvider';
import useOnboardingState from 'components/services/localStorage/useOnboardingState';
import * as Intercom from 'utils/intercom';
import { useIsMounted } from 'utils/hooks';
import useSpecialApps from 'components/services/specialApps/useSpecialApps';
import useUsersCompanyName from 'components/services/company/useUsersCompanyName';

import Checkbox from './Checkbox';
import {
  SecondaryNavigationControlContainer,
  SecondaryNavigationContainer,
  NavigationControlText,
  SecondaryNavigation,
  SecondaryNavigationBlock,
} from './Navigation.styled';

const Drawer = ({ user, polyglot, menuCtx }) => {
  const isMounted = useIsMounted();
  const [onboardingState] = useOnboardingState();
  const { checkAccess } = useContext(AuthState);
  const [selectableNav, setSelectableNav] = useState(selectableNavigationItems);

  const { menu, addUserPreference, removeUserPreference } = menuCtx;
  const isDone = onboardingState.done;
  const companyName = useUsersCompanyName();
  const specialApps = useSpecialApps(companyName);

  useEffect(() => {
    const flattenedNavItems = selectableNavigationItems.reduce((acc, curr) => acc.concat(curr), []);
    Promise.all(
      flattenedNavItems.map(async option => {
        return checkAccess(option.resource, option.action, user.companyName);
      }),
    ).then(res => {
      if (isMounted()) {
        const items = selectableNavigationItems.map((option, index) =>
          option.filter((_, index2) => res[index * 2 + index2]),
        );

        if (isDone && GETTING_STARTED_ROLES.includes(user.role)) {
          items.unshift([ROUTE_CONFIG.GETTING_STARTED]);
        }

        setSelectableNav(items);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDone]);

  const toggleCheckbox = (item, checked) => {
    if (checked) {
      removeUserPreference(user, item);
      try {
        Intercom.trackEvent('remove-item-from-tools', {
          Resource: item.resource,
        });
      } catch (error) {}
    } else {
      try {
        addUserPreference(user, item);
        Intercom.trackEvent('add-item-to-tools', {
          Resource: item.resource,
        });
      } catch (error) {}
    }
  };

  return (
    <>
      <SecondaryNavigationControlContainer selected={menuCtx.secondaryNavigation}>
        {menuCtx.navigationExpanded && (
          <ButtonLink
            type="secondary"
            iconRight={
              !menuCtx.secondaryNavigation ? <ChevronForwardIcon /> : <ChevronBackwardIcon />
            }
            size="small"
            width="216px"
            title={polyglot.t('navigation.manage_tools')}
            onClick={menuCtx.toggleSecondaryNavigation}
            ariaControls="navigation"
            ariaExpanded={menuCtx.secondaryNavigation}
          >
            <NavigationControlText>{polyglot.t('navigation.manage_tools')}</NavigationControlText>
          </ButtonLink>
        )}
      </SecondaryNavigationControlContainer>
      <SecondaryNavigationContainer id="secondary-navigation" open={menuCtx.secondaryNavigation}>
        <SecondaryNavigation>
          <Text type="secondary" weight="bold" size="normal" uppercase>
            {polyglot.t('navigation.selected_tools')}
          </Text>
          {selectableNav.map(
            (item, key) =>
              !!item.length && (
                <SecondaryNavigationBlock key={key}>
                  {item.map((option, id) => {
                    //Filters special apps in the drawer
                    //Once the other dashboard is added the hardcoded string should be replaced with a constant
                    if (
                      (option.resource === 'feature.demand_dashboard' &&
                        !specialApps.demandDashboard) ||
                      (option.resource === 'feature.demand_dashboard' &&
                        specialApps.demandDashboard?.deleted)
                    ) {
                      return null;
                    }
                    if (
                      (option.resource === 'feature.destination_dashboard' &&
                        !specialApps.destinationDemandDashboard) ||
                      (option.resource === 'feature.destination_dashboard' &&
                        specialApps.destinationDemandDashboard?.deleted)
                    ) {
                      return null;
                    }
                    if (
                      (option.resource === 'model.data_marketplace_dataset' &&
                        !specialApps.dataMarketplace) ||
                      (option.resource === 'model.data_marketplace_dataset' &&
                        specialApps.dataMarketplace?.deleted)
                    ) {
                      return null;
                    }
                    return (
                      <Checkbox
                        key={id}
                        title={polyglot.t(option.name)}
                        checked={!!menu.find(nav => nav.name === option.name)}
                        name={option.name}
                        toggle={toggleCheckbox}
                        item={option}
                      />
                    );
                  })}
                </SecondaryNavigationBlock>
              ),
          )}
        </SecondaryNavigation>
      </SecondaryNavigationContainer>
    </>
  );
};

export default Drawer;
