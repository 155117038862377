import React from 'react';

import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

const CancelUnavailableModal = ({ onClose }) => {
  const polyglot = usePolyglot();
  return (
    <Modal onClose={onClose} size="small">
      <ModalHeader title="Couldn't stop processing" />
      <ModalSection>
        <Text size="large">{polyglot.t('mmb.cancel_booking.part_processed')}</Text>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onClose} type="secondary">
          {polyglot.t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelUnavailableModal;
