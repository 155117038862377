import { ContentItem } from 'kentico-cloud-delivery/_bundles/kentico-cloud-delivery-sdk.browser.umd.min';

import { ROUTE_CONFIG } from 'consts/routes';

class QuickGuide extends ContentItem {
  searchableCategory = 'FAQ';

  getTitle() {
    return this.goal.value;
  }

  get searchableTitle() {
    return this.goal.value;
  }

  get searchableContent() {
    return this.action.value;
  }

  get isSearchable() {
    return this.faq_content_tree.value.length > 0;
  }

  get taxonomyCodename() {
    return this.isSearchable && this.faq_content_tree.value[0].codename;
  }

  get toPath() {
    return `${ROUTE_CONFIG.DOCS_FAQ_SINGLE.toPath}/${this.system.codename}`;
  }
}

export default QuickGuide;
