import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import * as Sentry from '@sentry/react';

/**
 * PARAMS_PERSIST [string] - list of initial query parameters to persist in local storage
 */
const PARAMS_PERSIST = ['pcc', 'command', 'utm_source', 'lastCommands'];

const LOCAL_STORAGE_PREFIX = 'initial_query_param';

export const InitialQueryParamsState = React.createContext({
  ui: null,
  pcc: null,
  command: null,
  utm_source: null,
  lastCommands: null,
});

const transformMap = {
  command: c => c?.toUpperCase(),
};

const transformParams = params => {
  const res = {};

  for (const key of Object.keys(params)) {
    if (transformMap[key]) {
      res[key] = transformMap[key](params[key]);
    } else {
      res[key] = params[key];
    }
  }

  return res;
};

const InitialQueryParamsProvider = ({ children }) => {
  const location = useLocation();
  const [params] = useState(transformParams(qs.parse(location.search)));
  const persistedParams = {};

  PARAMS_PERSIST.forEach(paramName => {
    const localStorageName = `${LOCAL_STORAGE_PREFIX}-${paramName}`;
    if (params[paramName]) {
      localStorage.setItem(localStorageName, params[paramName]);
    }
    const persistedParam = localStorage.getItem(localStorageName);
    if (persistedParam) {
      persistedParams[paramName] = persistedParam;
    }
  });

  useEffect(() => {
    try {
      Sentry.setExtra('initialQueryParams', JSON.stringify(params, null, 2));
    } catch (_) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InitialQueryParamsState.Provider value={{ ...params, ...persistedParams }}>
      {children}
    </InitialQueryParamsState.Provider>
  );
};

export default InitialQueryParamsProvider;
