import React, { useReducer, useCallback, useRef, useEffect } from 'react';
import * as R from 'ramda';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import api, { setAuthToken, removeAuthToken } from 'utils/api';
import { setCookie, removeCookie, clearAllCookies, getCookie } from 'utils/cookie';
import { NEXT_GEN_AUTH_COOKIE_NAME, SKYPICKER_VISITOR_UNIQID } from 'consts';
import registrationMapper from 'mappers/nextGen/register/_';
import loginMapper from 'mappers/nextGen/login/_';
import jiraCloudCustomerMapper from 'mappers/nextGen/jira-cloud/customer/_';
import { hasOneOfProperties } from 'utils/object';
import { endsWith } from 'utils/string';
import { INITIAL_BATCH_RESOURCES, CRUD_ACTIONS } from 'common/Authorization/Authorization';
import { setFeatureValue } from 'utils/features';
import { USE_NEXT_GEN_ENDPOINTS } from 'consts/features';
import { fireEvent } from 'utils/ga';
import useImpersonate from 'hooks/useImpersonate';
export const TOO_MANY_LOGIN_ATTEMPTS_ERROR = 'TOO_MANY_LOGIN_ATTEMPTS';

export const AuthState = React.createContext({
  state: {
    grants: null,
    loadingBatch: false,
    loadingGrants: false,
    resetLoading: false,
    resetError: false,
    resetSuccessful: false,
    resetRequestLoading: false,
    resetRequestError: null,
    resetRequestSuccessful: false,
    registerInProgress: false,
    registerError: null,
    showRegisterInfoBox: false,
    activationInProgress: false,
    activationError: null,
    showActivationSuccessBox: false,
    showTooManyLoginAttemptsError: false,
    detectedCountry: null,
    showForbiddenAccessModal: false,
  },
  login: () => {},
  logout: () => {},
  getSession: () => {},
  loadGrants: () => {},
  checkAccess: (resource, action, companyName) => [resource, action, companyName],
  can: () => {},
  batchCan: (user, resources, companyName) => [user, resources, companyName],
  requestResetPassword: () => {},
  resetPassword: () => {},
  register: () => {},
  hideRegisterInfoBox: () => {},
  activateUser: () => {},
  hideActivateSuccessBox: () => {},
  hideTooManyLoginAttemptsError: () => {},
  attemptToPreselectCountry: () => {},
  hideRequestResetPasswordBox: () => {},
  hideResetPasswordBox: () => {},
  getUserSettings: () => {},
  updateUserSettings: () => {},
});

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        loggingIn: true,
        loginError: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loggingIn: false,
        user: { ...state.user, ...action.user },
        isKiwiUser: endsWith(action?.user?.email, '@kiwi.com'),
      };
    case 'LOGIN_FAIL':
      return {
        ...state,
        loggingIn: false,
        loginError: action.loginError,
        showTooManyLoginAttemptsError:
          action.loginError.error_code === TOO_MANY_LOGIN_ATTEMPTS_ERROR,
      };
    case 'LOG_OUT':
      return {
        ...state,
        user: null,
      };
    case 'LOAD_GRANTS':
      return {
        ...state,
        loadingGrants: true,
        error: null,
      };
    case 'LOAD_GRANTS_SUCCESS':
      return {
        ...state,
        loadingGrants: false,
        grants: action.grants,
      };
    case 'LOAD_GRANTS_FAIL':
      return {
        ...state,
        loadingGrants: false,
        error: action.error,
      };
    case 'CAN':
      return {
        ...state,
        error: null,
      };
    case 'CAN_SUCCESS':
      return {
        ...state,
        checkedGrants: {
          ...state.checkedGrants,
          [action.company]: {
            ...state.checkedGrants?.[action.company],
            [action.resource]: {
              ...state.checkedGrants?.[action.company]?.[action.resource],
              [action.action]: action.result,
            },
          },
        },
      };
    case 'CAN_FAIL':
      return {
        ...state,
        error: action.error,
      };
    case 'BATCH_CAN':
      return {
        ...state,
        loadingBatch: true,
        error: null,
      };
    case 'BATCH_CAN_SUCCESS':
      return {
        ...state,
        loadingBatch: false,
        checkedGrants: {
          ...state.checkedGrants,
          [action.company]: {
            ...state.checkedGrants?.[action.company],
            ...action.checkedGrants,
          },
        },
      };
    case 'BATCH_CAN_FAIL':
      return {
        ...state,
        loadingBatch: false,
        error: action.error,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        resetError: null,
        resetLoading: true,
        resetSuccessful: false,
      };
    case 'RESET_PASSWORD_FAIL': {
      return {
        ...state,
        resetError: action.error,
        resetLoading: false,
      };
    }
    case 'RESET_PASSWORD_SUCCESS': {
      return {
        ...state,
        resetLoading: false,
        resetSuccessful: true,
        showPasswordResetSuccessBox: true,
      };
    }
    case 'RESET_PASSWORD_REQUEST':
      return {
        ...state,
        resetRequestError: null,
        resetRequestLoading: true,
        resetRequestSuccessful: false,
      };
    case 'RESET_PASSWORD_REQUEST_SUCCESS':
      return {
        ...state,
        resetRequestLoading: false,
        resetRequestSuccessful: true,
        showRequestPasswordResetSuccessBox: true,
      };
    case 'RESET_PASSWORD_REQUEST_FAIL':
      return {
        ...state,
        resetRequestLoading: false,
        resetRequestError: action.error,
      };
    case 'HIDE_REQUEST_PASSWORD_RESET_BOX':
      return {
        ...state,
        showRequestPasswordResetSuccessBox: false,
      };
    case 'HIDE_PASSWORD_RESET_BOX':
      return {
        ...state,
        showPasswordResetSuccessBox: false,
      };
    case 'REGISTER':
      return {
        ...state,
        registerInProgress: true,
        registerError: null,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        registerInProgress: false,
        showRegisterInfoBox: true,
      };
    case 'REGISTER_FAIL':
      return {
        ...state,
        registerInProgress: false,
        registerError: action.error,
      };
    case 'HIDE_REGISTER_INFO_BOX':
      return {
        ...state,
        showRegisterInfoBox: false,
      };
    case 'ACTIVATE':
      return {
        ...state,
        activationInProgress: true,
        activationError: null,
      };
    case 'ACTIVATE_SUCCESS':
      return {
        ...state,
        activationInProgress: false,
        showActivationSuccessBox: true,
      };
    case 'ACTIVATE_FAIL':
      return {
        ...state,
        activationInProgress: false,
        activationError: action.error,
      };
    case 'HIDE_ACTIVATE_SUCCESS_BOX':
      return {
        ...state,
        showActivationSuccessBox: false,
      };
    case 'HIDE_TOO_MANY_LOGIN_ATTEMPTS_ERROR':
      return {
        ...state,
        showTooManyLoginAttemptsError: false,
      };
    case 'PRESELECT_COUNTRY_SUCCESS':
      return {
        ...state,
        detectedCountry: action.country,
      };
    case 'SHOW_FORBIDDEN_MODAL':
      return {
        ...state,
        showForbiddenAccessModal: true,
      };
    case 'HIDE_FORBIDDEN_MODAL':
      return {
        ...state,
        showForbiddenAccessModal: false,
      };
    case 'GET_USER_SETTINGS':
      return {
        ...state,
      };
    case 'GET_USER_SETTINGS_SUCCESS':
      return {
        ...state,
        userSettings: { ...state.userSettings, marketingConsent: action.marketingConsent },
      };
    case 'GET_USER_SETTINGS_FAIL':
      return {
        ...state,
        error: action.error,
      };
    case 'UPDATE_USER_SETTINGS':
      return {
        ...state,
      };
    case 'UPDATE_USER_SETTINGS_SUCCESS':
      return {
        ...state,
        userSettings: { ...state.userSettings, marketingConsent: action.marketingConsent },
      };
    case 'UPDATE_USER_SETTINGS_FAIL':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {});
  const currentActionCheck = useRef({});
  const [, setImpersonatingUser] = useImpersonate();

  useEffect(() => {
    if (!getCookie(SKYPICKER_VISITOR_UNIQID)) {
      setCookie(SKYPICKER_VISITOR_UNIQID, crypto.randomUUID());
    }
  }, []);

  const login = async (email, password, rememberMe) => {
    dispatch({ type: 'LOGIN' });

    try {
      const { data } = await api.v1.post('sessions', {
        brand: 'tequila',
        email,
        password,
      });
      setFeatureValue(USE_NEXT_GEN_ENDPOINTS, true);
      setAuthToken(data.token);
      setCookie(NEXT_GEN_AUTH_COOKIE_NAME, data.token);

      const { data: user } = await api.v1.get('sessions');

      dispatch({ type: 'LOGIN_SUCCESS', user: loginMapper.v1.get.from(user) });
    } catch (error) {
      if (error.response.data.error_code === 'IP_NOT_ALLOWED') {
        dispatch({ type: 'SHOW_FORBIDDEN_MODAL' });
      }
      removeAuthToken();
      removeCookie(NEXT_GEN_AUTH_COOKIE_NAME);
      setFeatureValue(USE_NEXT_GEN_ENDPOINTS, false);
      dispatch({
        type: 'LOGIN_FAIL',
        loginError:
          error.response.status <= 500 ? error?.response?.data : { message: 'login.unavailable' },
      });
    }
  };

  const logout = () => {
    try {
      api.v1.delete('sessions');
      clearAllCookies();
      localStorage.clear();
      sessionStorage.clear();
      removeAuthToken();
      // reset feature toggle for next gen
      setFeatureValue(USE_NEXT_GEN_ENDPOINTS, false);
      fireEvent('logout');

      Sentry.setUser(null);
      Sentry.setContext('User data', null);
    } catch (error) {}

    // make full page refresh when loging out clear context data
    window.location.reload();
    dispatch({ type: 'LOG_OUT' });
  };

  const batchCan = async (skyuser, resources, companyName) => {
    dispatch({ type: 'BATCH_CAN' });
    try {
      const company = companyName || skyuser.companyName;
      const payload = (resources || INITIAL_BATCH_RESOURCES).map(res => ({
        resource: res,
        actions: CRUD_ACTIONS,
        params: { user_uuid: skyuser.uuid, company },
      }));

      const res = await api.v1.post('acl/can/batch', payload);

      let checkedGrants = {};
      //map through initially sent resources, if response returned same resource check for available actions and map true/false accordingly
      (resources || INITIAL_BATCH_RESOURCES).forEach(resource => {
        if (res.data?.[resource]) {
          CRUD_ACTIONS.forEach(action => {
            checkedGrants[resource] = res.data[resource][action]
              ? {
                  ...checkedGrants[resource],
                  [action]: res.data[resource][action]?.properties || true,
                }
              : { ...checkedGrants[resource], [action]: false };
          });
        } else {
          CRUD_ACTIONS.forEach(action => {
            checkedGrants[resource] = { ...checkedGrants[resource], [action]: false };
          });
        }
      });

      dispatch({ type: 'BATCH_CAN_SUCCESS', company, checkedGrants: checkedGrants });
    } catch (error) {
      dispatch({ type: 'BATCH_CAN_FAIL', error });
    }
  };

  const can = useCallback(async (skyuser, action, resource, companyName) => {
    if (currentActionCheck.current[resource]?.[action]) {
      return;
    }

    if (!currentActionCheck.current[resource]) {
      currentActionCheck.current[resource] = {};
    }

    currentActionCheck.current[resource][action] = true;

    dispatch({ type: 'CAN', resource, action });

    try {
      const res = await api.v1.post('acl/can', {
        actions: action,
        resource: resource,
        params: { user_uuid: skyuser.uuid, company: companyName || skyuser.companyName },
      });

      if (!R.isEmpty(res.data)) {
        dispatch({
          type: 'CAN_SUCCESS',
          company: companyName || skyuser.companyName,
          resource,
          action,
          result: res.data[resource][action]?.properties || true,
        });
        return true;
      }

      dispatch({
        type: 'CAN_SUCCESS',
        company: companyName || skyuser.companyName,
        resource,
        action,
        result: false,
      });
      return false;
    } catch (error) {
      dispatch({
        type: 'CAN_FAIL',
        error: error,
      });
      return false;
    } finally {
      currentActionCheck.current[resource][action] = false;
    }
  }, []);

  const checkAccess = async (resource, action, companyName) => {
    const possibleAction = hasOneOfProperties(state.grants?.[resource], action);
    //if user has available action in specific grant
    if (!!possibleAction) {
      const alreadyChecked = state.checkedGrants?.[companyName]?.[resource]?.hasOwnProperty([
        possibleAction,
      ]);

      //if action was already checked in the past return the state of that action (true/false)
      if (alreadyChecked) {
        return state.checkedGrants[companyName][resource][possibleAction];
      } else {
        //if action wasn't checked before, look for conditions, if there are none, user has access
        const conditions = state.grants?.[resource]?.[possibleAction]?.conditions?.length
          ? state.grants[resource][possibleAction].conditions
          : null;
        if (!conditions) {
          return true;
        } else {
          //if there are some conditions, call /can and return result
          const check = await can(state.user, [possibleAction], resource, companyName);
          return check;
        }
      }
    }
    return false;
  };

  const checkGrantProperties = (resource, action, property, companyName) => {
    if (state.checkedGrants?.[companyName]) {
      return Object.keys(state.checkedGrants?.[companyName]?.[resource] || {})
        .filter(k => k.startsWith(action) && state.checkedGrants[companyName][resource][k])
        .some(k => {
          if (state.checkedGrants[companyName][resource][k]?.length) {
            return state.checkedGrants[companyName][resource][k].includes(property);
          }
          return true;
        });
    }
    return false;
  };

  const getSession = async () => {
    dispatch({ type: 'LOGIN' });
    try {
      const { data: user } = await api.v1.get('sessions');

      if (!R.endsWith('@kiwi.com', user.email) && user.jira_cloud_account_id === null) {
        try {
          await api.v1.post('/jira-cloud/customer', jiraCloudCustomerMapper.v1.post.to(user));
        } catch (_) {}
      }

      return Promise.all([
        loadGrants(user.uuid),
        getUserSettings(user.uuid),
        api.v1.get(`companies/${user.company_name}`),
      ]).then(([grants, userSettings, company]) => {
        const { data: companyData } = company;
        try {
          window.userInfo = {
            user: {
              name: `${user.first_name} ${user.last_name}`,
              email: user.email,
              role: user.role,
              created_at: +new Date(user.created_at),
              developerId: user.uuid,
              intercom_user_id: user.uuid,
              intercom_user_hash: user.intercom_user_hash,
            },
            company: {
              name: user.company_name,
              type: companyData.account_type,
              legal_name: companyData.legal_name,
              legal_address: `${companyData.address.address1},  ${companyData.address.city}, ${companyData.address.country}`,
              account_manager: companyData.account_manager,
              created_at: +new Date(companyData.created_at),
              customerSupportEnabled: companyData.customer_support_enabled,
            },
          };

          Sentry.setUser({
            id: user.uuid,
            email: user.email,
            username: user.email,
          });
          Sentry.setContext('User data', user);
        } catch (error) {
          throw error;
        }
        const mappedUser = loginMapper.v1.get.from(user);
        setFeatureValue(USE_NEXT_GEN_ENDPOINTS, true);
        dispatch({ type: 'LOGIN_SUCCESS', user: mappedUser });
        return mappedUser;
      });
    } catch (error) {
      removeAuthToken();
      removeCookie(NEXT_GEN_AUTH_COOKIE_NAME);
      setFeatureValue(USE_NEXT_GEN_ENDPOINTS, false);
      return Promise.reject(
        dispatch({ type: 'LOGIN_FAIL', loginError: error?.response?.data || error }),
      );
    }
  };

  const loadGrants = async uuid => {
    try {
      dispatch({ type: 'LOAD_GRANTS' });
      const res = await api.v1.get(`acl/users/${uuid}/grants`);

      dispatch({ type: 'LOAD_GRANTS_SUCCESS', grants: res.data.grants });
    } catch (err) {
      dispatch({ type: 'LOAD_GRANTS_FAIL', error: err });
      throw err;
    }
  };

  const requestResetPassword = async (email, googleToken) => {
    dispatch({ type: 'RESET_PASSWORD_REQUEST' });

    try {
      await api.v1.post('users/password-reset', {
        brand: 'tequila',
        email,
        'g-recaptcha-response': googleToken,
      });
      dispatch({ type: 'RESET_PASSWORD_REQUEST_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'RESET_PASSWORD_REQUEST_FAIL', error });
      return Promise.reject(error);
    }
  };

  const resetPassword = async (password, token, googleToken) => {
    dispatch({ type: 'RESET_PASSWORD' });
    try {
      await api.v1.put('users/password-reset', {
        password,
        token,
        'g-recaptcha-response': googleToken,
      });

      dispatch({ type: 'RESET_PASSWORD_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'RESET_PASSWORD_FAIL', error });
      throw error;
    }
  };

  const hideRequestResetPasswordBox = () => dispatch({ type: 'HIDE_REQUEST_PASSWORD_RESET_BOX' });

  const register = async (registerValues, token) => {
    dispatch({ type: 'REGISTER' });
    try {
      await api.v1.post(
        'registration',
        registrationMapper.v1.post.to({ ...registerValues, token }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      dispatch({ type: 'REGISTER_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'REGISTER_FAIL', error });
      throw error;
    }
  };

  const activateUser = async (password, token, googleToken) => {
    dispatch({ type: 'ACTIVATE' });

    try {
      await api.v1.put('users/password-reset', {
        password,
        token,
        'g-recaptcha-response': googleToken,
      });

      dispatch({ type: 'ACTIVATE_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'ACTIVATE_FAIL', error });
      throw error;
    }
  };

  const hideActivateSuccessBox = () =>
    dispatch({
      type: 'HIDE_ACTIVATE_SUCCESS_BOX',
    });

  const hideTooManyLoginAttemptsError = () =>
    dispatch({
      type: 'HIDE_TOO_MANY_LOGIN_ATTEMPTS_ERROR',
    });

  const hideResetPasswordBox = () => dispatch({ type: 'HIDE_PASSWORD_RESET_BOX' });

  const hideRegisterInfoBox = () =>
    dispatch({
      type: 'HIDE_REGISTER_INFO_BOX',
    });

  const attemptToPreselectCountry = async () => {
    try {
      const { data } = await axios.get('https://geoip-api.skypicker.com');

      dispatch({ type: 'PRESELECT_COUNTRY_SUCCESS', country: data.isoCountryCode.toUpperCase() });
    } catch (error) {}
  };

  const stopImpersonating = () => {
    setImpersonatingUser({ uuid: null });
  };

  const toggleForbiddenAccessModal = () => {
    if (state.showForbiddenAccessModal) {
      dispatch({ type: 'HIDE_FORBIDDEN_MODAL' });
    } else {
      dispatch({ type: 'SHOW_FORBIDDEN_MODAL' });
    }
  };
  const getUserSettings = async uuid => {
    dispatch({ type: 'GET_USER_SETTINGS' });
    try {
      const { data } = await api.v1.get(`user-settings/${uuid}`);

      const marketingConsent = data.marketing_consent;

      dispatch({ type: 'GET_USER_SETTINGS_SUCCESS', marketingConsent });
    } catch (error) {
      dispatch({ type: 'GET_USER_SETTINGS_FAIL', error });
    }
  };

  const updateUserSettings = async toggleMarketingConsent => {
    dispatch({ type: 'UPDATE_USER_SETTINGS' });
    try {
      const { data } = await api.v1.put(`user-settings/${state.user.uuid}`, {
        marketing_consent: toggleMarketingConsent,
      });

      const marketingConsent = data.marketing_consent;

      dispatch({ type: 'UPDATE_USER_SETTINGS_SUCCESS', marketingConsent });
    } catch (error) {
      dispatch({ type: 'UPDATE_USER_SETTINGS_FAIL', error });
      return error;
    }
  };

  const value = {
    ...state,
    login,
    logout,
    checkAccess,
    can,
    batchCan,
    getSession,
    loadGrants,
    checkGrantProperties,
    requestResetPassword,
    resetPassword,
    hideResetPasswordBox,
    hideRequestResetPasswordBox,
    register,
    hideRegisterInfoBox,
    activateUser,
    hideActivateSuccessBox,
    hideTooManyLoginAttemptsError,
    attemptToPreselectCountry,
    stopImpersonating,
    toggleForbiddenAccessModal,
    getUserSettings,
    updateUserSettings,
  };

  return <AuthState.Provider value={value}>{children}</AuthState.Provider>;
};

export default AuthProvider;
