import React, { useState, useContext } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Button from '@kiwicom/orbit-components/lib/Button';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Text from '@kiwicom/orbit-components/lib/Text';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { usePolyglot } from 'components/services/i18n';
import { AuthState } from 'components/services/auth/AuthProvider';
import { ErrorMessageComponent } from 'common';
import { executeRecaptcha } from 'utils/window';

import { ActionsContainer } from './ForgotPasswordModal.styled';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('validation.invalid_email').required('validation.cant_be_empty'),
});

const ForgotPasswordModal = ({ onClose }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { resetRequestLoading, requestResetPassword } = useContext(AuthState);
  const polyglot = usePolyglot();
  const { isDesktop } = useMediaQuery();

  const getErrorMsg = err => {
    if (err?.response?.status === 404) {
      return polyglot.t('forgot_password.does_not_exist');
    } else if (err?.response?.status === 409) {
      return polyglot.t('forgot_password.already_requested');
    } else if (err?.response?.status === 403) {
      return polyglot.t('common.recaptcha_failed');
    } else {
      return polyglot.t('forgot_password.not_available');
    }
  };

  //added manual loading flag because recaptcha needs to execute before sending reset email
  const sendResetLink = async email => {
    await executeRecaptcha(
      token => requestResetPassword(email, token),
      () => {
        setLoading(false);
        onClose();
      },
      err => {
        setLoading(false);
        setError(getErrorMsg(err));
      },
    );
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader title={polyglot.t('forgot_password.modal_title')} />

      <ModalSection>
        {error && (
          <Alert type="critical" spaceAfter="large">
            {error}
          </Alert>
        )}
        <Text size="large" spaceAfter="large">
          {polyglot.t('forgot_password.modal_subtitle')}
        </Text>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={values => {
            setLoading(true);
            sendResetLink(values.email);
          }}
        >
          {({ values, handleSubmit, handleChange }) => (
            <>
              <InputField
                id="forgot-password-input"
                name="email"
                value={values.email}
                label={polyglot.t('common.email')}
                onChange={handleChange}
                required
                autoFocus
              />
              <ErrorMessage
                name="email"
                render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
              />

              <ActionsContainer>
                <Button width="140px" fullWidth={!isDesktop} onClick={onClose} type="secondary">
                  {polyglot.t('forgot_password.modal_back_to_login')}
                </Button>
                <Button
                  width="140px"
                  fullWidth={!isDesktop}
                  onClick={handleSubmit}
                  loading={resetRequestLoading || loading}
                >
                  {polyglot.t('forgot_password.modal_send_reset_link')}
                </Button>
              </ActionsContainer>
            </>
          )}
        </Formik>
      </ModalSection>
    </Modal>
  );
};

export default ForgotPasswordModal;
