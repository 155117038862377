import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import DownloadIcon from '@kiwicom/orbit-components/lib/icons/Download';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import ChevronForwardIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';

const ExportsContainer = styled.div`
  max-height: 0;
  transition: all 200ms ease-in-out;
  overflow: hidden;

  ${({ expanded, height }) =>
    expanded &&
    css`
      max-height: ${height}px;
      overflow: auto;
    `}
`;

const IconContainer = styled.div`
  width: 60px;
  margin: 0 auto;

  & > div {
    height: 24px;
  }
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

const getIconForStatus = available => {
  if (available) {
    return <DownloadIcon />;
  }
  return <Loading type="boxLoader" />;
};

const Exports = ({ disableExports, logs, expandLogsId, onExportLogs, onDownloadExport }) => {
  const polyglot = usePolyglot();
  const exportsContainerRef = useRef(null);
  const exportsTable = useToggle();

  useEffect(() => {
    if (expandLogsId === 0) {
      return;
    }

    exportsTable.setOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandLogsId]);

  if (!logs.exports && Array.isArray(logs.exports) && logs.exports.length === 0) {
    return 'loading exports';
  }

  return (
    <Stack>
      <Stack direction="row" justify="between" align="end">
        <TextLink dataTest="toggle-exports" onClick={exportsTable.toggle}>
          {exportsTable.isOn ? polyglot.t('logs.hide_exports') : polyglot.t('logs.show_exports')}
          {exportsTable.isOn ? <ChevronDownIcon /> : <ChevronForwardIcon />}
        </TextLink>
        <Button
          dataTest="exports-logs-button"
          type="primary"
          size="small"
          loading={logs.creatingExport}
          onClick={onExportLogs}
          disabled={disableExports}
        >
          {polyglot.t('logs.export_logs')}
        </Button>
      </Stack>
      <ExportsContainer
        expanded={exportsTable.isOn}
        ref={exportsContainerRef}
        height={exportsContainerRef.current && exportsContainerRef.current.scrollHeight}
      >
        <Table compact>
          <TableHead>
            <TableRow>
              <TableCell>{polyglot.t('logs.name')}</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.exports &&
              logs.exports.map((exp, i) => (
                <TableRow key={i}>
                  <TableCell>{exp.name}</TableCell>
                  <TableCell align="center">
                    <IconContainer
                      data-test={`download-export-${i}`}
                      onClick={() => onDownloadExport(exp)}
                      clickable={exp.available}
                    >
                      {getIconForStatus(exp.available)}
                    </IconContainer>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </ExportsContainer>
    </Stack>
  );
};

export default Exports;
