import React from 'react';
import { Illustration, Stack, Text } from '@kiwicom/orbit-components';

import { usePolyglot } from 'components/services/i18n';

const NoTransactions = () => {
  const polyglot = usePolyglot();
  return (
    <Stack direction="column" align="center" justify="center">
      <Illustration name="PlaneAndMoney" size="large" />
      <Text size="large" type="secondary">
        {polyglot.t('tequila.mmb.credit_activity.table.no_transactions')}
      </Text>
    </Stack>
  );
};

export default NoTransactions;
