const v1_get_from = data => {
  return data.map(role => {
    return {
      name: role.name,
      displayName: role.display_name,
    };
  });
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
