import styled, { css } from 'styled-components';

import { getLineSize } from './utils';

export const TabContainer = styled.div`
  border-bottom: ${getLineSize}px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
`;

export const TabButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: ${({ theme }) => theme.orbit.paddingButtonNormal};
  color: ${({ theme, transparent }) =>
    transparent ? theme.orbit.colorTextSecondary : theme.orbit.colorTextButtonLinkSecondary};
  font-size: ${({ theme }) => theme.orbit.fontSizeButtonSmall};
  cursor: pointer;
  background-color: transparent;
  transition: background-color 200ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
      background-color: ${({ theme, transparent }) =>
        transparent ? 'transparent' : theme.orbit.backgroundButtonSecondary};
      letter-spacing: -0.15px;
    `}
`;
