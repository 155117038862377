import React, { useMemo, useContext } from 'react';
import format from 'date-fns/format';
import KiwicomIcon from '@kiwicom/orbit-components/lib/icons/Kiwicom';

import { Card, CardHeader, CardData } from 'components/common/Card';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';

const SolutionInternalInformation = ({ solution, companyName }) => {
  const { checkGrantProperties } = useContext(AuthState);
  const polyglot = usePolyglot();

  const [leftColumn, rightColumn] = useMemo(() => {
    const canAffilId = checkGrantProperties(
      'model.application',
      'read',
      'affiliate_id',
      companyName,
    );
    const canStatus = checkGrantProperties('model.application', 'read', 'status', companyName);
    const canApprovalDate = checkGrantProperties(
      'model.application',
      'read',
      'status',
      companyName,
    );
    // const canApprovalDate = routeRules?.application.get.approvalDate;
    const canIntendedUse = checkGrantProperties(
      'model.application',
      'read',
      'intended_use',
      companyName,
    );
    const leftColumnVal = [];
    if (canAffilId) {
      leftColumnVal.push({
        label: polyglot.t('solution.affilid'),
        value: solution.affilId,
      });
    }
    if (canStatus) {
      leftColumnVal.push({
        label: polyglot.t('common.status'),
        value: solution.status,
      });
    }
    leftColumnVal.push({
      label: polyglot.t('solution.bocs_queue'),
      value: '-',
    });

    const rightColumnVal = [];
    if (canApprovalDate) {
      rightColumnVal.push({
        label: polyglot.t('solution.approval_date'),
        value: format(new Date(solution.approvalDate), 'MMM d, yyyy'),
      });
    }
    if (canIntendedUse) {
      rightColumnVal.push({
        label: polyglot.t('solution.intended_use'),
        value: solution.intendedUse,
      });
    }

    return [leftColumnVal, rightColumnVal];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solution, polyglot]);

  return (
    <Card>
      <CardHeader title={polyglot.t('solution.internal_information')} icon={<KiwicomIcon />} />

      <CardData leftColumn={leftColumn} rightColumn={rightColumn} />
    </Card>
  );
};

export default SolutionInternalInformation;
