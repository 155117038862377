import * as P from 'parsimmon';
import * as R from 'ramda';

/**
 * Creates a parser which will use all parsers from provided options
 * and create a object with matches for each option, as well as the
 * rest of the string as invalid.
 * @param {object} options object with options and their parsers
 * @param {string} consumeLimiter specifies a limit of consumed options
 */
export const optionsParser = (options, consumeLimiter) => {
  const parser = consumeLimiter
    ? P.regex(new RegExp(`.+?(?=${consumeLimiter})`))
        .times(0, 1)
        .map(r => r[0] || '')
    : P.all;
  return parser.map(optionsString => {
    const result = {};
    Object.entries(options).forEach(([name, pparser]) => {
      const fullMatch = optionsString.match(pparser.expression);
      if (fullMatch) {
        const matches = pparser.entries.parse(fullMatch[0]);
        if (matches.status) {
          result[name] = matches.value;
          optionsString = optionsString.replace(fullMatch[0], '');
        }
      }
    });
    // Will be non-empty if something was not parsed
    if (optionsString) {
      result._remainder = optionsString;
    }
    return result;
  });
};

/**
 * Orders an array of items into 3 new arrays (include, exclude, specific)
 * per item decorator (none, -, #)
 * @param {string[]} items
 */
export const inclusionOrderByDecorator = items => {
  if (!items || R.isEmpty(items)) {
    return null;
  }

  const newItems = {
    include: [],
    exclude: [],
    specific: [],
  };

  items.forEach(i => {
    if (i.endsWith('#')) {
      newItems.specific.push(i.substring(0, i.length - 1));
    } else if (i.endsWith('-')) {
      newItems.exclude.push(i.substring(0, i.length - 1));
    } else {
      newItems.include.push(i);
    }
  });
  return newItems;
};
