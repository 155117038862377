import * as R from 'ramda';

import returnAdapter from './return';
import getSymbolFromCurrency from 'currency-symbol-map';

/**
 * Multicity flight groups.
 *
 * @param {array} routes
 * @param {array} route
 */
function groupByRoutes(route) {
  return route.map(r => r.route);
}

function getCurrencySymbolFromTrip(trip) {
  return getSymbolFromCurrency(trip.currency) || trip.currency;
}

/**
 * Multicity flights price.
 * @param {*} searchResult
 */
function getPrice(searchResult) {
  return `${getCurrencySymbolFromTrip(searchResult)}${searchResult.price}`;
}

/**
 * Use booking>token for Multicity search result.
 * @param {*} searchResult
 */
function getSearchResultId(searchResult) {
  return searchResult.booking_token;
}

function filterResponseByCarriers(response, filteredCarriers) {
  return response.data.filter(trip => {
    const carriersInThisTrip = trip.route.reduce((p, route) => p.concat(route.airlines), []);
    const uniqCarriersInThisTrip = R.uniq(carriersInThisTrip);

    return R.intersection(uniqCarriersInThisTrip, filteredCarriers).length === 0;
  });
}

/**
 * Returns all carrier codes for the search result
 * @param {array} data Array of search results for Multicity/Nomad
 */
function getAllCarriers(data) {
  const carrierCodes = data.reduce((acc, trip) => {
    return acc.concat(returnAdapter.getAllCarriers(trip.route));
  }, []);

  return R.uniq(carrierCodes);
}

function getMinPrice(response) {
  return response.data.reduce(
    (previousPrice, flight) => (previousPrice < flight.price ? previousPrice : flight.price),
    response.data[0].price,
  );
}

function getMaxPrice(response) {
  return response.data.reduce(
    (previousPrice, flight) => (previousPrice > flight.price ? previousPrice : flight.price),
    response.data[0].price,
  );
}

function filterResponseByPrices(list, pricesFilterValues) {
  const minPrice = pricesFilterValues[0];
  const maxPrice = pricesFilterValues[1];
  return list.filter(listItem => listItem.price >= minPrice && listItem.price <= maxPrice);
}

export default {
  groupByRoutes,
  getFlightLegProps: returnAdapter.getFlightLegProps,
  stopAirportsFromGroup: returnAdapter.stopAirportsFromGroup,
  getPrice,
  getSearchResultId,
  filterResponseByCarriers,
  getMinPrice,
  getMaxPrice,
  filterResponseByPrices,
  getCurrencySymbolFromTrip,
  getAllCarriers,
};
