import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import Button from '@kiwicom/orbit-components/lib/Button';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';

import { getPolyglot } from 'redux/selectors/i18n';
import { isMetricSlow } from 'pages/Reports/utils';

import { TableRow, TableBody } from 'common';
import { FilterPicker, FilterValuesAutocomplete } from 'pages/Reports/components/Filters';

import { FilterCell, AutocompleteCell, ButtonCell, IconCell } from './common.styled';

class CreateFilterRow extends Component {
  state = {
    selectedFilter: null,
    selectedValues: [],
  };

  handleFilterSelect = filter => this.setState({ selectedFilter: filter, selectedValues: [] });

  handleSelectValue = filterValue =>
    this.setState(state => ({ selectedValues: [...state.selectedValues, filterValue.value] }));

  handleUnselectValue = filterValue =>
    this.setState(state => ({
      selectedValues: state.selectedValues.filter(item => item !== filterValue.value),
    }));

  handleSaveRow = () => {
    const { onSaveRow } = this.props;
    const { selectedFilter, selectedValues } = this.state;
    onSaveRow(selectedFilter, selectedValues);
  };

  render() {
    const { takenFilters, polyglot, onDeleteRow } = this.props;
    const { selectedFilter, selectedValues } = this.state;

    const areSelectedValuesEmpty = R.isEmpty(selectedValues);

    return (
      <TableBody>
        <TableRow>
          <IconCell center>
            {selectedFilter && isMetricSlow(selectedFilter) && (
              <span>
                <Tooltip
                  preferredPosition="bottom"
                  content={polyglot.t('reports.slow_filter_tooltip_text')}
                >
                  <ClockIcon customColor="#f9971e" />
                </Tooltip>
              </span>
            )}
          </IconCell>
          <FilterCell>
            <FilterPicker takenFilters={takenFilters} onSelect={this.handleFilterSelect} />
          </FilterCell>
          <AutocompleteCell>
            <FilterValuesAutocomplete
              filter={selectedFilter}
              isDisabled={!selectedFilter}
              selectedValues={selectedValues}
              onSelect={this.handleSelectValue}
              onUnselect={this.handleUnselectValue}
              companyName={this.props.companyName}
              isInEditMode={true}
            />
          </AutocompleteCell>
          <ButtonCell>
            <Button
              size="small"
              type="secondary"
              onClick={onDeleteRow}
              iconLeft={<CloseIcon customColor="#d21c1c" />}
            />
          </ButtonCell>
          <ButtonCell>
            <Button
              size="small"
              onClick={this.handleSaveRow}
              disabled={areSelectedValuesEmpty}
              iconLeft={<CheckIcon />}
            >
              {polyglot.t('reports.confirm_filter')}
            </Button>
          </ButtonCell>
        </TableRow>
      </TableBody>
    );
  }
}

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

CreateFilterRow.propTypes = {
  onSaveRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  takenFilters: PropTypes.array,
};

export default connect(mapStateToProps)(CreateFilterRow);
