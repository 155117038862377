import styled from 'styled-components';

export const PageContainer = styled.div`
  max-width: 714px;
  margin-top: 20px;
  margin-left: 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 500px;
`;
