import React from 'react';
import PropTypes from 'prop-types';

import Text from '@kiwicom/orbit-components/lib/Text';
import StepperStateless from '@kiwicom/orbit-components/lib/Stepper/StepperStateless';

import { Flex } from 'common';
import { usePolyglot } from 'components/services/i18n';

import { PassengerNumberWrapper } from './index.styled';

export const PassengerNumber = ({
  changeNumberOfPassengers,
  passengerType,
  amount,
  isIncrementDisabled,
  isDecrementDisabled,
}) => {
  const polyglot = usePolyglot();
  const incrementNumberOfPassengers = () => {
    if (!isIncrementDisabled) {
      changeNumberOfPassengers(passengerType, amount + 1);
    }
  };

  const decrementNumberOfPassengers = () => {
    if (!isDecrementDisabled) {
      changeNumberOfPassengers(passengerType, amount - 1);
    }
  };

  return (
    <PassengerNumberWrapper>
      <Flex column>
        <Text type="primary">{polyglot.t(`searchAndBooking.passengers.${passengerType}`)}</Text>
        <Text size="small" type="secondary">
          {polyglot.t(`searchAndBooking.passengers.${passengerType}_description`)}
        </Text>
      </Flex>
      <StepperStateless
        onIncrement={incrementNumberOfPassengers}
        onDecrement={decrementNumberOfPassengers}
        disabledIncrement={isIncrementDisabled}
        disabledDecrement={isDecrementDisabled}
        value={amount}
        dataTest={`${passengerType}-increase-decrease`}
      />
    </PassengerNumberWrapper>
  );
};

PassengerNumber.propTypes = {
  amount: PropTypes.number,
  changeNumberOfPassengers: PropTypes.func,
  passengerType: PropTypes.string,
  isDecrementDisabled: PropTypes.bool,
  isIncrementDisabled: PropTypes.bool,
};

export default PassengerNumber;
