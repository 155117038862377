import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Box from '@kiwicom/orbit-components/lib/Box';

import { usePolyglot } from 'components/services/i18n';

import { QuickOptionWrapper, RadioWrapper } from './index.styled';
import SeatingIcon from './components/SeatingIcon';

const QuickOption = ({ option, price, isChecked, onClick }) => {
  const polyglot = usePolyglot();

  return (
    <QuickOptionWrapper onClick={onClick} isChecked={isChecked}>
      <RadioWrapper>
        <Radio checked={isChecked} />
      </RadioWrapper>
      <Box
        width="24px"
        height="24px"
        borderRadius="circle"
        display="flex"
        justify="center"
        align="center"
        background="cloudLight"
        margin="XXSmall"
        padding="medium"
      >
        <SeatingIcon option={option} />
      </Box>
      <Text weight="bold">
        {polyglot.t(`tequila.ancillary_seating.mmb.no_seatmap.quick_option.${option}`)}
      </Text>
      <Text type="secondary">{price}</Text>
    </QuickOptionWrapper>
  );
};

export default QuickOption;
