import { createSelector } from 'reselect';
import * as R from 'ramda';
import { MILLENNIUM } from 'consts/dates';

const emptyObject = {};

const getReports = state => state.reports;
const getReportsData = createSelector(getReports, R.prop('data'));
const getReportsToCompare = state => state.reportsToCompare;
const getReportsToCompareData = createSelector(getReportsToCompare, R.prop('data'));
const getFullReportId = state => state.reports.fullReportId;
const areReportsLoading = createSelector(getReports, R.prop('reportLoading'));
const areFullReportsLoading = createSelector(getReports, R.prop('fullReportLoading'));
const areReportsToCompareLoading = createSelector(getReportsToCompare, R.prop('loading'));

export const areLoading = createSelector(
  [areReportsLoading, areReportsToCompareLoading, areFullReportsLoading],
  (loadingReports, loadingReportsCompare, loadingReportsFull) =>
    loadingReports || loadingReportsCompare || loadingReportsFull,
);

const getReportsCurrentParamsHash = state => state.reports.currentParamsHash;
const getReportsToCompareCurrentParamsHash = state => state.reportsToCompare.currentParamsHash;

/**
 * extractReportData selector takes report/reportToCompare data + hash that represent current data
 * and will do some mapping
 * @param {object} reports
 * @param {string} paramsHash
 */
const extractReportData = (reports, paramsHash) => {
  const cachedReport = reports[paramsHash];

  if (!cachedReport) {
    return emptyObject;
  }

  const { data, pagination } = reports[paramsHash];
  //TODO translate keys from consts/reports

  // take reportData, check if it has affiliate property,
  // if has => replace ',' => ', '
  const mappedData = data.reportData.map(obj =>
    obj.affiliate && R.is(String, obj.affiliate)
      ? R.assoc('affiliate', obj.affiliate.replace(/,/g, ', '), obj)
      : obj,
  );

  // Don't show interval if it is set to MILLENNIUM -> Hack to show all data without grouping
  // Else show it as the first column
  const keysWithoutInterval = R.without('interval', R.keys(mappedData[0]));
  const keys =
    data.interval === MILLENNIUM ? keysWithoutInterval : R.prepend('interval', keysWithoutInterval);

  return {
    keys,
    data: mappedData,
    interval: data.interval,
    pagination,
    totalValues: data.totalValues,
    from: data.timeframe.from,
    to: data.timeframe.to,
    fromCompare: R.pathOr(null, ['timeframe_compare', 'from'], data),
    toCompare: R.pathOr(null, ['timeframe_compare', 'to'], data),
  };
};

/**
 * getExtractedReportData selector takes current report
 * and send these data to the `extractReportData` selector
 */
const getExtractedReportData = createSelector(
  [getReportsData, getReportsCurrentParamsHash],
  extractReportData,
);

/**
 * getComparedReportData selector takes current reportToCompare
 * and send these data to the `extractReportData` selector
 */
const getComparedReportData = createSelector(
  [getReportsToCompareData, getReportsToCompareCurrentParamsHash],
  extractReportData,
);

/**
 * getReportData "filter" some props from object returned by `getReportData` selector
 */
export const getReportData = createSelector([getExtractedReportData], reportData => ({
  data: reportData.data,
  pagination: reportData.pagination,
  interval: reportData.interval,
  keys: reportData.keys,
  totalValues: reportData.totalValues,
}));

/**
 * getData will decide if will return compared reports or not
 * @param {Object} state
 * @param {Object} props
 */
export const getData = (state, props) => {
  if (props.shouldCompare) {
    return getComparedReportData(state);
  }
  return getReportData(state);
};

export const isReportLoaded = createSelector(
  getReportsData,
  getReportsCurrentParamsHash,
  (reports, currentParamsHash) => Boolean(reports[currentParamsHash]),
);

export const isReportPending = createSelector(
  isReportLoaded,
  getFullReportId,
  (reportLoaded, fullReportId) => Boolean(!reportLoaded && fullReportId),
);
