import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { businessTypeOptions } from 'consts/register';
import { SelectAccountManagers, SelectCountry } from 'common';
import Select from 'components/common/Select';
import { usePolyglot } from 'components/services/i18n';

const PersonalAccountForm = ({ canAccountManager }) => {
  const polyglot = usePolyglot();
  const { control, errors } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="displayName"
        render={({ onChange, onBlur, value }) => {
          return (
            <InputField
              size="small"
              label={polyglot.t('register.account_name')}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={polyglot.t(errors?.displayName?.message)}
            />
          );
        }}
      />

      <Select
        label={polyglot.t('register.business_type')}
        placeholder={polyglot.t('register.business_type.placeholder')}
        name="businessType"
        size="small"
        options={businessTypeOptions.map(option => ({
          value: option.value,
          label: polyglot.t(option.label),
        }))}
        error={polyglot.t(errors?.businessType?.message)}
      />

      <Stack direction="row">
        <Controller
          control={control}
          name="address.address1"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.address')}
                value={value}
                error={polyglot.t(errors.address?.address1?.message)}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
        <Controller
          name="address.country"
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <SelectCountry
                dataTest="country"
                placeholder={polyglot.t('register.select_country')}
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value.toLowerCase()}
                error={polyglot.t(errors.address?.country?.message)}
              />
            );
          }}
        />
      </Stack>

      <Stack direction="row">
        <Controller
          control={control}
          name="address.city"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.city')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.address?.city?.message)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="address.zip"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.zip')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.address?.zip?.message)}
              />
            );
          }}
        />
      </Stack>

      <Controller
        control={control}
        name="url"
        render={({ onChange, onBlur, value }) => {
          return (
            <InputField
              size="small"
              label={polyglot.t('smart_pass.form.vouchers.url_label')}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={polyglot.t(errors?.url?.message)}
            />
          );
        }}
      />

      {canAccountManager && (
        <Controller
          name="accountManager"
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <SelectAccountManagers
                size="small"
                name="accountManager"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={polyglot.t(errors?.accountManager?.message)}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default PersonalAccountForm;
