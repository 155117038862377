import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import useBooking from 'components/services/booking/useBooking';
import { usePolyglot } from 'components/services/i18n';
import Checkbox from 'components/common/Checkbox';
import DateInputGroup from 'components/common/DateInputGroup';
import { Space } from 'common';
import countries from 'consts/countries';

import {
  PASSENGER_OPTIONS,
  GENDER_OPTIONS,
  INITIAL_PASSENGER,
} from 'components/scenes/Booking/scenes/DepositBooking/consts';

import { extractDuplicates, disableOptions, canRemovePassenger } from './../utils';

const SelectWrapper = styled.div`
  width: 300px;
`;
const GenderWrapper = styled.div`
  width: 155px;
`;

// strips everything thats in the regex below
function passengerNameNormalizer(value) {
  return (
    value
      // eslint-disable-next-line no-useless-escape
      ?.match(/[^1234567890`~!\?@.–•¥£§€"#\$%\^&,:;\*_=\+\|\(\)<>«»\[\]\{}\\\/’”№]*/g)
      .join('')
  );
}

function documentNumberNormalizer(value) {
  return value?.replace?.(/[^a-z0-9]/gi, '');
}

const PassengerForm = ({ index, remove, paxCategories }) => {
  const polyglot = usePolyglot();
  const { watch, errors, control, trigger, setValue } = useFormContext();
  const passengers = watch('passengers');
  const { data } = useBooking();

  const { adult, infant } = extractDuplicates(paxCategories);
  const {
    data: { documentNeed },
  } = useBooking();

  useEffect(() => {
    if (documentNeed !== 2) {
      setValue(`passengers.${index}.noExpiry`, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spaceAfter="medium">
      <div>
        <Space before="xl" after="xl">
          <Text size="large">
            {polyglot.t('booking.deposit_booking.booking_summary.passenger_details')}
          </Text>
        </Space>
      </div>

      <Stack direction="row" align="end">
        <SelectWrapper>
          <Controller
            name={`passengers.${index}.category`}
            control={control}
            defaultValue={
              passengers[index] ? passengers[index].category : INITIAL_PASSENGER.adult.category
            }
            render={({ onChange, value }) => {
              return (
                <Select
                  label={polyglot.t(
                    'booking.deposit_booking.passengers_and_bags.passenger_form.passenger_type',
                  )}
                  dataTest="passenger-type"
                  options={PASSENGER_OPTIONS.map(o => ({
                    label: polyglot.t(o.label, {
                      adultThreshold: data?.ageCategoryThresholds?.adult,
                      childThreshold: data?.ageCategoryThresholds?.child,
                    }),
                    value: o.value,
                    disabled: disableOptions(o.value, passengers?.[index]?.category, adult, infant),
                  }))}
                  help={
                    (index === 0 &&
                      polyglot.t(
                        'booking.deposit_booking.passengers_and_bags.passenger_form.must_be_adult',
                      )) ||
                    (infant === adult &&
                      passengers[index]?.category !== 'infant' &&
                      polyglot.t(
                        'booking.deposit_booking.passengers_and_bags.passenger_form.more_infants_than_adults',
                      ))
                  }
                  spaceAfter={index === 0 && 'medium'}
                  disabled={index === 0}
                  value={value}
                  onChange={onChange}
                  defaultValue={
                    passengers[index]
                      ? passengers[index].category
                      : INITIAL_PASSENGER.adult.category
                  }
                />
              );
            }}
          />
        </SelectWrapper>
        {canRemovePassenger(passengers?.[index]?.category, index, adult, infant) && (
          <Button
            dataTest="remove-passenger-button"
            type="critical"
            onClick={() => {
              remove(index);
            }}
          >
            {polyglot.t(
              'booking.deposit_booking.passengers_and_bags.action_buttons.remove_passenger',
            )}
          </Button>
        )}
      </Stack>

      <Separator />

      <Stack direction="row" align="end">
        <Controller
          control={control}
          name={`passengers.${index}.firstName`}
          render={({ value, onChange, onBlur }) => {
            return (
              <InputField
                dataTest="first-name"
                value={value}
                label={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.given_names',
                )}
                placeholder={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.e_g',
                )}
                error={
                  errors.passengers?.[index]?.firstName &&
                  polyglot.t(errors.passengers?.[index]?.firstName?.message)
                }
                onChange={e => {
                  onChange(passengerNameNormalizer(e.target.value));
                }}
                onBlur={onBlur}
              />
            );
          }}
        />

        <Controller
          control={control}
          name={`passengers.${index}.lastName`}
          render={({ value, onChange, onBlur }) => {
            return (
              <InputField
                dataTest="last-name"
                value={value}
                label={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.surnames',
                )}
                placeholder="e.g. Brown"
                error={
                  errors.passengers?.[index]?.lastName &&
                  polyglot.t(errors.passengers?.[index]?.lastName?.message)
                }
                onChange={e => {
                  onChange(passengerNameNormalizer(e.target.value));
                }}
                onBlur={onBlur}
              />
            );
          }}
        />
      </Stack>

      <Stack direction="row" align="start">
        <Stack direction="row" justify="between" spacing="small" grow={1} shrink={1}>
          <Controller
            name={`passengers.${index}.nationality`}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <Select
                  dataTest="nationality"
                  label={polyglot.t(
                    'booking.deposit_booking.passengers_and_bags.passenger_form.nationality',
                  )}
                  placeholder={polyglot.t(
                    'booking.deposit_booking.passengers_and_bags.passenger_form.select',
                  )}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  options={countries}
                  error={
                    errors.passengers?.[index]?.nationality &&
                    polyglot.t(errors.passengers?.[index]?.nationality?.message)
                  }
                />
              );
            }}
          />

          <Controller
            name={`passengers.${index}.gender`}
            control={control}
            render={({ onChange, onBlur, value }) => {
              return (
                <GenderWrapper>
                  <Select
                    dataTest="gender"
                    label={polyglot.t(
                      'booking.deposit_booking.passengers_and_bags.passenger_form.gender',
                    )}
                    placeholder={polyglot.t(
                      'booking.deposit_booking.passengers_and_bags.passenger_form.select',
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={GENDER_OPTIONS.map(o => ({
                      value: o.value,
                      label: polyglot.t(o.label),
                    }))}
                    error={
                      errors.passengers?.[index]?.gender &&
                      polyglot.t(errors.passengers[index].gender?.message)
                    }
                  />
                </GenderWrapper>
              );
            }}
          />
        </Stack>

        <Controller
          name={`passengers.${index}.dateOfBirth`}
          control={control}
          render={({ onChange, onBlur }) => {
            return (
              <DateInputGroup
                name={`passengers.${index}.dateOfBirth`}
                dataTest="birth"
                label={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.date_of_birth',
                )}
                onChange={onChange}
                onBlur={onBlur}
                trigger={trigger}
                error={
                  errors.passengers?.[index]?.dateOfBirth &&
                  polyglot.t(errors.passengers?.[index]?.dateOfBirth?.message, {
                    adultThreshold: data?.ageCategoryThresholds?.adult,
                    childThreshold: data?.ageCategoryThresholds?.child,
                  })
                }
              />
            );
          }}
        />
      </Stack>

      <Stack direction="row" justify="between">
        <Controller
          name={`passengers.${index}.passportId`}
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                dataTest="passport-id"
                disabled={documentNeed !== 2}
                label={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.passport_number',
                )}
                value={value}
                placeholder={polyglot.t(
                  'booking.deposit_booking.passengers_and_bags.passenger_form.passport_number',
                )}
                error={
                  errors.passengers?.[index]?.passportId &&
                  polyglot.t(errors.passengers[index].passportId?.message)
                }
                onChange={e => onChange(documentNumberNormalizer(e.target.value))}
                onBlur={onBlur}
              />
            );
          }}
        />

        <Stack direction="column" spacing="medium" grow={1} shrink={1}>
          <Controller
            name={`passengers.${index}.expiryDate`}
            control={control}
            render={({ onChange, onBlur }) => {
              return (
                <DateInputGroup
                  dataTest="passport-expiry"
                  name={`passengers.${index}.expiryDate`}
                  onChange={onChange}
                  onBlur={onBlur}
                  trigger={trigger}
                  label={polyglot.t(
                    'booking.deposit_booking.passengers_and_bags.passenger_form.passport_expiry_date',
                  )}
                  error={
                    errors.passengers?.[index]?.expiryDate &&
                    polyglot.t(errors.passengers?.[index]?.expiryDate?.message)
                  }
                  disabled={passengers?.[index]?.noExpiry || documentNeed !== 2}
                />
              );
            }}
          />

          <Checkbox
            disabled={documentNeed !== 2}
            dataTest="no-expiry"
            name={`passengers.${index}.noExpiry`}
            label={polyglot.t(
              'booking.deposit_booking.passengers_and_bags.passenger_form.no_expiry',
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PassengerForm;
