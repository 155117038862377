import { mapFrequencyToTranslation } from 'utils/newReports';

const v1_get_to = reportAliasesIds => {
  return {
    alias_ids: reportAliasesIds,
  };
};

const v1_get_from = data => {
  const mappedData = {};

  for (const schedule of data) {
    if (mappedData[schedule.report_alias_id]) {
      mappedData[schedule.report_alias_id].push(mapFrequencyToTranslation(schedule.frequency));
    } else {
      mappedData[schedule.report_alias_id] = [mapFrequencyToTranslation(schedule.frequency)];
    }
  }

  return mappedData;
};

export default {
  v1: {
    get: {
      to: v1_get_to,
      from: v1_get_from,
    },
  },
};
