import { useContext } from 'react';

import { CreateSolutionState } from './CreateSolution';
const useCreateSolution = () => {
  const solutionCtx = useContext(CreateSolutionState);

  return { ...solutionCtx };
};

export default useCreateSolution;
