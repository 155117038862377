import { ReactComponent as ResultWidgetDefault } from '../images/ResultWidget.svg';
import { ReactComponent as FilterWidgetDefault } from '../images/FilterWidget.svg';
import { ReactComponent as TileWidgetDefault } from '../images/TileWidget.svg';
import { ReactComponent as FullWidgetDefault } from '../images/FullWidget.svg';
import { ReactComponent as SingleWidgetDefault } from '../images/SingleWidget.svg';

import { ReactComponent as ResultWidgetSelected } from '../images/SelectedResultWidget.svg';
import { ReactComponent as FilterWidgetSelected } from '../images/SelectedFilterWidget.svg';
import { ReactComponent as TileWidgetSelected } from '../images/SelectedTileWidget.svg';
import { ReactComponent as FullWidgetSelected } from '../images/SelectedFullWidget.svg';
import { ReactComponent as SingleWidgetSelected } from '../images/SelectedSingleWidget.svg';

import { ReactComponent as ResultWidgetPreview } from '../images/PreviewResultWidget.svg';
import { ReactComponent as FilterWidgetPreview } from '../images/PreviewFilterWidget.svg';
import { ReactComponent as TileWidgetPreview } from '../images/PreviewTileWidget.svg';
import { ReactComponent as FullWidgetPreview } from '../images/PreviewFullWidget.svg';
import { ReactComponent as SingleWidgetPreview } from '../images/PreviewSingleWidget.svg';

export const widgetTypeImages = {
  resultWidget: {
    default: ResultWidgetDefault,
    selected: ResultWidgetSelected,
    preview: ResultWidgetPreview,
  },
  filterWidget: {
    default: FilterWidgetDefault,
    selected: FilterWidgetSelected,
    preview: FilterWidgetPreview,
  },
  tileWidget: {
    default: TileWidgetDefault,
    selected: TileWidgetSelected,
    preview: TileWidgetPreview,
  },
  fullWidget: {
    default: FullWidgetDefault,
    selected: FullWidgetSelected,
    preview: FullWidgetPreview,
  },
  singleWidget: {
    default: SingleWidgetDefault,
    selected: SingleWidgetSelected,
    preview: SingleWidgetPreview,
  },
};

export const firstRow = [
  {
    label: 'builder.type.full_widget_title',
    description: 'builder.type.full_widget_description',
    value: 'fullWidget',
    defaultImage: widgetTypeImages.fullWidget.default,
    selectedImage: widgetTypeImages.fullWidget.selected,
  },
  {
    label: 'builder.type.result_widget_title',
    description: 'builder.type.result_widget_description',
    value: 'resultWidget',
    defaultImage: widgetTypeImages.resultWidget.default,
    selectedImage: widgetTypeImages.resultWidget.selected,
  },
  {
    label: 'builder.type.tile_widget_title',
    description: 'builder.type.tile_widget_description',
    value: 'tileWidget',
    defaultImage: widgetTypeImages.tileWidget.default,
    selectedImage: widgetTypeImages.tileWidget.selected,
  },
];

export const secondRow = [
  {
    label: 'builder.type.single_widget_title',
    description: 'builder.type.single_widget_description',
    value: 'singleWidget',
    defaultImage: widgetTypeImages.singleWidget.default,
    selectedImage: widgetTypeImages.singleWidget.selected,
  },
];

export const widgetNamesI18n = firstRow.concat(secondRow).reduce(
  (xs, x) => ({
    ...xs,
    [x.value]: x.label,
  }),
  {},
);
