import { useContext, useEffect } from 'react';

import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';

import { SmartpointState } from './index';

const useCompanyPCCApps = companyName => {
  const { companyPCCApps, loadCompanyPCCApps } = useContext(SmartpointState);
  const companyNameFromRoute = useCurrentCompanyName();
  const name = companyName || companyNameFromRoute;
  useEffect(() => {
    if (!companyPCCApps[name] || !companyPCCApps[name].data) {
      loadCompanyPCCApps(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return companyPCCApps[name];
};

export default useCompanyPCCApps;
