import * as R from 'ramda';

const findBoardingPass = (segment, boardingPasses) => {
  const boardingPass = boardingPasses.find(p => p.segment_code === segment.segmentCode);

  return { ...boardingPass, arrival: segment.arrival, departure: segment.departure };
};

const v1_get_from = (passes, segments, passengers, type) => {
  const flattenedSegments = R.flatten(Object.values(segments));

  let boardingPassesPerSegment = flattenedSegments.reduce((acc, seg) => {
    let pass = findBoardingPass(seg, passes.segments);

    pass.passengers.forEach(p => {
      //add passenger info to boarding pass
      let passenger = passengers.find(pax => pax.id === p.passenger_id);
      return acc.push({
        status: p.status,
        passengerId: p.passenger_id,
        boardingPasses: p.boarding_passes,
        from: { city: pass.departure.place.city, code: pass.departure.station.code },
        to: { city: pass.arrival.place.city, code: pass.arrival.station.code },
        passenger,
      });
    });

    return acc;
  }, []);

  const groupById = R.groupBy(p => p.passengerId, boardingPassesPerSegment);
  return Object.values(groupById);
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
