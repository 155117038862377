import React, { useState } from 'react';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import useWidgetsContext from 'components/services/widgets';
import { widgetNamesI18n } from 'components/scenes/Widgets/scenes/WidgetBuilder/components/WidgetType/consts';
import { useCan, useCurrentUser } from 'components/services/auth';

import DeleteWidgetModal from './components/DeleteWidgetModal';
import Icons from './components/Icons';
import NoWidgetsInfo from './components/NoWidgetsInfo';

import { SpaceAfter } from './index.styled';
import { usePolyglot } from 'components/services/i18n';

const WidgetsTable = ({ showWidgetPreview, selectedCompany }) => {
  const user = useCurrentUser();
  const canDeleteMain = useCan(user, ['delete'], 'model.widget', selectedCompany);
  const canDeleteOwn = useCan(user, ['delete_own'], 'model.widget', selectedCompany);
  const canDeleteAssigned = useCan(user, ['delete_assigned'], 'model.widget', selectedCompany);
  const canUpdateMain = useCan(user, ['update'], 'model.widget', selectedCompany);
  const canUpdateOwn = useCan(user, ['update_own'], 'model.widget', selectedCompany);
  const canUpdateAssigned = useCan(user, ['update_assigned'], 'model.widget', selectedCompany);

  const canDelete = canDeleteMain || canDeleteOwn || canDeleteAssigned;
  const canUpdate = canUpdateMain || canUpdateOwn || canUpdateAssigned;

  const polyglot = usePolyglot();
  const [deleteModal, setDeleteModal] = useState(null);
  const {
    state: { companyWidgets },
  } = useWidgetsContext();

  const companyHasWidgets = companyWidgets.length !== 0;

  return (
    <>
      <Table compact type="primary">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {polyglot.t('widgets.table_name')}
              <div style={{ width: 160 }}></div>
            </TableCell>
            <TableCell align="left">
              {polyglot.t('widgets.table_type')}
              <div style={{ width: 110 }}></div>
            </TableCell>
            <TableCell align="left">{polyglot.t('widgets.table_solution_name')}</TableCell>
            <TableCell align="left">
              <div style={{ width: 272 }}></div>
            </TableCell>
          </TableRow>
        </TableHead>
        {companyHasWidgets &&
          companyWidgets.map((listedCompanyWidget, i) => (
            <TableBody key={`${listedCompanyWidget.config.general?.solutionName} - ${i}`}>
              <TableRow key={listedCompanyWidget.id}>
                <TableCell align="left">{listedCompanyWidget.name}</TableCell>
                <TableCell align="left">
                  {polyglot.t(
                    widgetNamesI18n[listedCompanyWidget.config.widgetType?.value] ||
                      listedCompanyWidget.config.widgetType?.value,
                  )}
                </TableCell>
                <TableCell align="left">
                  {listedCompanyWidget.config.general?.solutionName}
                </TableCell>
                <TableCell>
                  <Icons
                    listedCompanyWidget={listedCompanyWidget}
                    showWidgetPreview={showWidgetPreview}
                    setDeleteModal={setDeleteModal}
                    selectedCompany={selectedCompany}
                    canUpdate={canUpdate}
                    canDelete={canDelete}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>
      <SpaceAfter />
      {!companyHasWidgets && <NoWidgetsInfo />}
      {deleteModal && (
        <DeleteWidgetModal
          widgetId={deleteModal.id}
          widgetName={deleteModal.name}
          closeMe={() => setDeleteModal(null)}
        />
      )}
    </>
  );
};

export default WidgetsTable;
