import styled from 'styled-components';

export const CardDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  border-top: none;
`;

export const CardDataWrapper = styled.div``;
export const CardDataColumnsWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

export const CardDataAlertWrapper = styled.div`
  margin: 12px 24px;
`;

export const CardDataColumn = styled.div`
  flex: 1 0 calc(50% - 48px);

  &:not(:last-of-type) {
    border-right: 1px solid #e8edf1;
  }

  &:not(:first-of-type) {
    padding-left: 24px;
  }
`;

export const CardDataRow = styled.div`
  max-width: 70%;

  p {
    word-break: break-all;
  }

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`;

export const CardDataTitle = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: rgb(37, 42, 49);
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  letter-spacing: 0.44px;
  line-height: 20px;
  text-transform: uppercase;
`;

export const CardDataAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
`;

export const CardMainContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 3px;

  & > div:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  padding: 12px 24px;
  color: rgb(37, 42, 49);
  font-size: 16px;
  font-weight: 500;
`;

export const CardHeaderIcon = styled.div`
  margin-right: 12px;
`;

export const CardActionHeaderWrapper = styled(CardHeaderWrapper)`
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;

export const CardActionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 12px;
  }
`;

export const CardLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
`;

export const CardSectionContainer = styled.div`
  padding: ${({ fit }) => (fit ? '12px 24px' : '24px')};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  border-top: none;
`;
