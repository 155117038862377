import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import {
  CarrierLogoWrapper,
  RouteGroupWrapper,
  SelectedSeatsWrapper,
  Wrapper,
} from './index.styled';
import ActionButton from '../ActionButton';
import SelectedSeats from './components/SelectedSeats';

const RouteGroup = ({ route, onClick, selected, disabled }) => {
  const { cityFrom, cityTo, airline, id } = route;

  return (
    <RouteGroupWrapper selected={selected} onClick={!disabled ? () => onClick() : null}>
      <Box display="flex" direction="column" justify="between" align="center">
        <Wrapper>
          <Box display="flex" direction="row" justify="start" align="center">
            <CarrierLogoWrapper>
              <CarrierLogo carriers={[{ code: airline }]} size="medium" />
            </CarrierLogoWrapper>
            <Box display="flex" direction="column" justify="start">
              <Box display="flex" direction="row" justify="start" align="center">
                <Text weight="bold">{cityFrom}</Text>
                <FlightDirectIcon />
                <Text weight="bold">{cityTo}</Text>
              </Box>
            </Box>
          </Box>
          <ActionButton selected={selected} disabled={disabled} onClick={onClick} routeId={id} />
        </Wrapper>
        <Wrapper>
          <SelectedSeatsWrapper>
            <SelectedSeats routeId={id} />
          </SelectedSeatsWrapper>
        </Wrapper>
      </Box>
    </RouteGroupWrapper>
  );
};

export default RouteGroup;
