import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';
import Button from '@kiwicom/orbit-components/lib/Button';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import LoadingIcon from '@kiwicom/orbit-components/lib/icons/Reload';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { SolutionStatus, Popover, SolutionDisableModal } from 'common';
import { ROUTE_CONFIG } from 'consts/routes';
import { Card, CardActionHeader, CardSection } from 'components/common/Card';
import { SolutionsState } from 'components/services/solutions';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useApiProducts from 'components/services/products/useApiProducts';
import { Authorization } from 'common/Authorization';
import { solutionHasCsWebhookInfo } from 'common/Authorization/policy';
import { AuthState } from 'components/services/auth/AuthProvider';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import CreateSolutionProvider from '../services/CreateSolution';
import useCompanyDetails from 'components/services/company/useCompanyDetails';

import SolutionsModal from './SolutionsModal/index.js';

const SolutionNameLink = styled(Link)`
  color: ${({ theme }) => theme.orbit.colorTextLinkPrimary};
  text-decoration: underline;
  font-weight: 500;
  transition: color 150ms ease-in-out;

  &:hover {
    text-decoration: none;
    color: rgb(0, 169, 145);
  }
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const spin = keyframes`
  100% {
    transform: rotate(-359deg);
  }
`;

export const Spin = styled.span`
  & svg {
    animation: ${spin} 1.75s linear 0s infinite forwards;
  }
`;

const SolutionsCard = ({ companyName }) => {
  const [disableSolDraft, setDisableSolDraft] = useState(null);
  const {
    enableSolution,
    state: { enablingSolutionName },
  } = useContext(SolutionsState);
  const { checkAccess } = useContext(AuthState);
  const { data } = useCompanyDetails(companyName);
  const isCustomerSupportEnabled = data?.customerSupportEnabled;
  const polyglot = usePolyglot();
  const products = useApiProducts();
  const solutions = useCompanySolutions(companyName);
  const location = useLocation();
  const createdSolAlert = useToggle((location.state && location.state.createdApp) || false);
  const solutionsModal = useToggle(location?.state?.createSolution || false);
  const [enableSolAlert, setEnableSolAlert] = useState(false);
  const [canEnableSol, setCanEnableSol] = useState(false);
  const [canDisableSol, setCanDisableSol] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Promise.all([
      checkAccess('model.application', ['enable', 'enable_own', 'enable_assigned'], companyName),
      checkAccess('model.application', ['disable', 'disable_own', 'disable_assigned'], companyName),
    ]).then(([enableRes, disableRes]) => {
      setCanEnableSol(enableRes);
      setCanDisableSol(disableRes);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  const getSolProductName = sol => {
    return products.data.find(p => p.value === sol.productType).name;
  };

  const handleEnableSolution = async sol => {
    if (sol.name === enablingSolutionName) {
      // already loading/enabling
      return;
    }

    try {
      await enableSolution(companyName, sol);
    } catch (error) {
      setEnableSolAlert(error);
    }
  };

  const SolutionsTable = () => {
    if (!solutions.data.length || !products.data.length) {
      return (
        <Stack direction="column" justify="start" align="center">
          <Illustration name="NoResults" size="large"></Illustration>
          <Text weight="bold">No solutions</Text>
          <Text type="secondary">Choose and create a right solution for your use case</Text>
          <Authorization
            resource="model.application"
            action={['create', 'create_assigned', 'create_own']}
          >
            <Button
              dataTest="create-solution-button"
              type="primary"
              size="large"
              onClick={solutionsModal.setOn}
            >
              {polyglot.t('company.add_solution')}
            </Button>
          </Authorization>
        </Stack>
      );
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">{polyglot.t('solutions.name')}</TableCell>
            <TableCell align="left">{polyglot.t('solutions.product')}</TableCell>
            {!isCustomerSupportEnabled && (
              <TableCell align="left">{polyglot.t('solutions.support_email')}</TableCell>
            )}
            <Authorization
              resource="model.application"
              action={[
                'enable',
                'enable_own',
                'enable_assigned',
                'disable',
                'disable_own',
                'disable_assigned',
              ]}
            >
              <TableCell />
            </Authorization>
          </TableRow>
        </TableHead>
        <TableBody>
          {solutions.data.map(sol => (
            <TableRow key={sol.name}>
              <TableCell>
                {sol.name === enablingSolutionName ? (
                  <Spin data-test="enable-app-loader">
                    <LoadingIcon size="small" color="secondary" />
                  </Spin>
                ) : (
                  <SolutionStatus status={sol.status} polyglot={polyglot} />
                )}
              </TableCell>
              <TableCell align="left">
                <SolutionNameLink
                  to={`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/solutions/${sol.affilId}`}
                >
                  {sol.name}
                </SolutionNameLink>
              </TableCell>
              <TableCell align="left">{getSolProductName(sol)}</TableCell>
              {!isCustomerSupportEnabled && (
                <TableCell align="left">
                  {solutionHasCsWebhookInfo(sol) ? sol.csEmail || '—' : '—'}
                </TableCell>
              )}

              {canEnableSol && canDisableSol && (
                <TableCell>
                  <Popover dataTest="more-options-button">
                    <PopoverContainer>
                      {sol.status !== 'approved' && canEnableSol && (
                        <TextLink
                          dataTest="enable-solution"
                          onClick={() => handleEnableSolution(sol)}
                        >
                          {polyglot.t('solutions.enable')}
                        </TextLink>
                      )}
                      {sol.status !== 'revoked' && canDisableSol && (
                        <TextLink
                          dataTest="disable-solution"
                          onClick={() => setDisableSolDraft(sol)}
                        >
                          {polyglot.t('solutions.disable')}
                        </TextLink>
                      )}
                    </PopoverContainer>
                  </Popover>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      {solutionsModal.isOn && (
        <CreateSolutionProvider preselectedStep={location?.state?.preselectedStep}>
          <SolutionsModal
            onClose={
              location.state?.fromSearch
                ? () => {
                    history.push('/search');
                  }
                : solutionsModal.setOff
            }
            onSuccess={() => {
              createdSolAlert.setOn();
              solutionsModal.setOff();
            }}
          />
        </CreateSolutionProvider>
      )}

      {disableSolDraft && (
        <SolutionDisableModal
          app={disableSolDraft}
          companyName={companyName}
          onClose={() => setDisableSolDraft(null)}
        />
      )}
      <Card>
        <>
          <CardActionHeader
            title="Solutions"
            action={
              solutions.data && (
                <Authorization
                  resource="model.application"
                  action={['create', 'create_assigned', 'create_own']}
                >
                  <Button
                    dataTest="create-solution-button"
                    type="primary"
                    size="small"
                    onClick={solutionsModal.setOn}
                  >
                    {polyglot.t('company.add_solution')}
                  </Button>
                </Authorization>
              )
            }
          />
          <CardSection
            showAlert={createdSolAlert.isOn || enableSolAlert}
            alert={
              <>
                {createdSolAlert.isOn && (
                  <Alert
                    type="success"
                    closable
                    icon
                    onClose={createdSolAlert.setOff}
                    spaceAfter="medium"
                  >
                    {polyglot.t('solutions.created_message')}
                  </Alert>
                )}
                {enableSolAlert && (
                  <Alert
                    type="critical"
                    closable
                    icon
                    onClose={() => {
                      setEnableSolAlert(false);
                    }}
                  >
                    {enableSolAlert}
                  </Alert>
                )}
              </>
            }
          >
            {solutions.loading || products.loading ? (
              <Loading loading type="boxLoader" text="Loading solutions info..." />
            ) : (
              <SolutionsTable />
            )}
          </CardSection>
        </>
      </Card>
    </>
  );
};

export default SolutionsCard;
