import React, { useMemo, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import debounce from 'debounce';
import styled from 'styled-components';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { LogsState } from 'components/services/logs';
import useCompanyLogs from 'components/services/logs/useCompanyLogs';

import Filters from './components/Filters';
import Exports from './components/Exports';
import Results from './components/Results';

const perPageOptions = [10, 25, 50];

const Container = styled.div`
  max-width: 880px;
  margin-top: 24px;
  margin-left: 24px;
`;

const Logs = () => {
  const { companyName } = useRouteMatch().params;
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [text, setText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { exportCompanyLogs, downloadExport } = useContext(LogsState);
  const [expandLogsId, setExpandLogsId] = useState(0);

  const logs = useCompanyLogs(companyName, {
    perPage,
    startDate,
    startTime,
    endDate,
    endTime,
    text,
  });

  const handleExportLogs = () => {
    exportCompanyLogs(companyName, { startDate, startTime, endDate, endTime });
    setExpandLogsId(i => i + 1);
  };

  const handleDownloadExport = logExport => {
    downloadExport(logExport, companyName);
  };

  const showInvalidDateRangeError = useMemo(() => {
    if (!startDate || !endDate) {
      return false;
    }

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    return (
      startDate &&
      +startDate === +endDate &&
      (startHours > endHours || (startHours === endHours && startMinutes > endMinutes))
    );
  }, [startDate, endDate, startTime, endTime]);

  const handleTextChange = debounce(event => {
    setText(event.target.value);
  }, 500);

  return (
    <Container>
      <Stack>
        <Filters
          loading={logs.loading}
          perPage={perPage}
          perPageOptions={perPageOptions}
          onPerPageChange={e => setPerPage(e.target.value)}
          startDate={startDate}
          onSelectStartDate={date => setStartDate(date)}
          endDate={endDate}
          onSelectEndDate={date => setEndDate(date)}
          startTime={startTime}
          onSelectStartTime={time => setStartTime(time)}
          endTime={endTime}
          onSelectEndTime={time => setEndTime(time)}
          text={text}
          onTextChange={handleTextChange}
          showInvalidDateRangeError={showInvalidDateRangeError}
        />

        <Exports
          logs={logs}
          disableExports={!startDate || !endDate}
          onExportLogs={handleExportLogs}
          expandLogsId={expandLogsId}
          onDownloadExport={handleDownloadExport}
        />

        <Results logs={logs} />
      </Stack>
    </Container>
  );
};

export default Logs;
