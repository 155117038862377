import { useContext, useEffect } from 'react';
import { MMBState } from '.';

const useBaggageOffer = bid => {
  const { state, getBaggageOfferInfo, changeBaggageOption, payBaggage } = useContext(MMBState);

  useEffect(() => {
    if (bid && !state.bookingsState?.[bid].baggageOffer) {
      getBaggageOfferInfo(bid);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  const changeHoldBag = (nextCombination, passengerId) => {
    changeBaggageOption(bid, 'holdBag', nextCombination, passengerId);
  };
  const changeHandBag = (nextCombination, passengerId) => {
    changeBaggageOption(bid, 'handBag', nextCombination, passengerId);
  };

  return state.bookingsState[bid]?.baggageOffer
    ? {
        data: state.bookingsState[bid]?.baggageOffer,
        loading: state.bookingsState[bid]?.loadingBaggageOffer,
        loadingOfferError: state.bookingsState[bid]?.loadingBaggageOfferError,
        payingBaggage: state.bookingsState[bid]?.payingBaggage,
        payingBaggageError: state.bookingsState[bid]?.payingBaggageError,
        changeHoldBag,
        changeHandBag,
        payBaggage,
      }
    : {
        data: null,
        loading: true,
        error: null,
      };
};

export default useBaggageOffer;
