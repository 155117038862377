import React from 'react';
import styled from 'styled-components';
import { Heading, Button, Stack } from '@kiwicom/orbit-components/lib';
import ChevronLeftIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

const ExpandedWrapper = styled.div`
  opacity: 0;
  transition: all 150ms ease-in-out;
  height: 0;
  border-top: ${({ theme }) => `1px solid ${theme.orbit.paletteCloudNormal}`};
  ${({ isExpanded }) =>
    isExpanded &&
    `
    background-color: white;
    opacity:1;
    height:auto;
  `}
`;

const ChevronButtonWrapper = styled.div`
  & > button:hover {
    background: ${({ theme }) => `${theme.orbit.paletteCloudNormal}`};
  }

  & > button {
    padding: 15px;
    border-left: ${({ theme }) => `1px solid ${theme.orbit.paletteCloudNormal}`};
    margin-left: 16px;
    height: 60px;
    width: 60px;
  }
`;

const Container = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
`;

const Title = styled.div`
  text-transform: initial;
`;

const AlertWrapper = styled.div`
  padding: 0 24px 24px;
`;

const CollapsibleCard = ({
  title,
  children,
  sideInfo,
  alert,
  showTitle = true,
  dataTest,
  header,
  isExpanded,
  onExpand,
  disabled,
  loading,
}) => {
  return (
    <Container data-test={dataTest}>
      {showTitle && (
        <CardTitle isExpanded={isExpanded}>
          <Stack direction="row" spacing="small" align="center" shrink>
            <Heading type="title4" as={Title}>
              {title}
            </Heading>
            {header}
          </Stack>
          {sideInfo}
          <ChevronButtonWrapper>
            <Button
              onClick={onExpand}
              iconLeft={isExpanded ? <ChevronDownIcon /> : <ChevronLeftIcon />}
              type="white"
              size="large"
              disabled={disabled}
              loading={loading}
            />
          </ChevronButtonWrapper>
        </CardTitle>
      )}

      {alert && <AlertWrapper>{alert}</AlertWrapper>}

      {isExpanded && <ExpandedWrapper isExpanded={isExpanded}>{children}</ExpandedWrapper>}
    </Container>
  );
};

export default CollapsibleCard;
