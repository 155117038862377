import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Drawer from '@kiwicom/orbit-components/lib/Drawer';
import Box from '@kiwicom/orbit-components/lib/Box';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';

import useMMB from 'components/services/mmb/useMMB';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

import { DrawerWrapper, StyledText, StyledTextRoute } from './index.styled';
import CloseModal from './components/CloseModal';
import SeatingDrawerContent from './components/SeatingDrawerContent';

const SeatingDrawer = ({ isDrawerOpen, closeDrawer }) => {
  const polyglot = usePolyglot();
  const { bid } = useParams();
  const { bookingsState } = useMMB();
  const {
    bookingData: { tripSummary },
  } = bookingsState[bid];
  const isCloseModalOpen = useToggle();
  const {
    seatings,
    resetSeatingsMMB,
    payment: { loading },
  } = useShoppingBasket();
  const [isFadeOutAnimation, setFadeOutAnimation] = useState(false);

  useEffect(() => {
    return () => {
      resetSeatingsMMB();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DrawerWrapper isFadeOutAnimation={isFadeOutAnimation}>
      <Drawer
        suppressed
        noPadding
        actions={
          !loading && (
            <ButtonLink
              type="secondary"
              onClick={
                !!Object.keys(seatings).length
                  ? () => isCloseModalOpen.setOn()
                  : () => {
                      setFadeOutAnimation(true);
                      setTimeout(() => {
                        closeDrawer();
                      }, 250);
                    }
              }
            >
              <CloseIcon />
            </ButtonLink>
          )
        }
        shown={isDrawerOpen}
        width="calc(100% - 100px)"
        title={
          <Box display="flex" direction="row">
            <StyledText>{polyglot.t('tequila.ancillary_seating.mmb.drawer.header')}</StyledText>
            <StyledText>|</StyledText>
            <StyledTextRoute>
              {tripSummary.locations.from}
              {tripSummary.value === 'return' ? <FlightReturnIcon /> : <FlightDirectIcon />}
              {tripSummary.locations.to}
            </StyledTextRoute>
          </Box>
        }
      >
        {isCloseModalOpen.isOn && (
          <CloseModal
            closeModal={isCloseModalOpen.setOff}
            closeDrawer={() => {
              setFadeOutAnimation(true);
              setTimeout(() => {
                closeDrawer();
              }, 250);
            }}
          />
        )}
        <SeatingDrawerContent
          selectedRouteId={isDrawerOpen}
          closeDrawer={() => {
            setFadeOutAnimation(true);
            setTimeout(() => {
              closeDrawer();
            }, 250);
          }}
          openCloseModal={isCloseModalOpen.setOn}
        />
      </Drawer>
    </DrawerWrapper>
  );
};

export default SeatingDrawer;
