import { connect } from 'react-redux';

import { fetchRequestCategories, clearRequests } from 'redux/modules/apigee/support';
import { getRequestsCategories } from 'redux/selectors/apigee/support';

import { CreateRequest } from 'common/Support';

const mapStateToProps = state => ({
  requestCategoriesResponse: getRequestsCategories(state),
});

const mapDispatchToProps = {
  fetchRequestCategories,
  clearRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRequest);
