import React, { useEffect, useContext, useState } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import Loading from '@kiwicom/orbit-components/lib/Loading';

import { usePolyglot } from 'components/services/i18n';
import useMenuState from 'components/services/menu/useMenuState';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import { apiToFormik } from 'mappers/SmartPass';
import { SmartPassContext } from 'components/services/smartPass';
import { KIWI_ADMIN } from 'consts/roles';
import { statuses, SmartPassValidationSchema } from 'consts/smartPass';
import { ROUTE_CONFIG } from 'consts/routes';

import Form from '../../components/Form';

const EditSmartPass = ({ companyName }) => {
  const menuCtx = useMenuState();
  useEffect(() => {
    menuCtx.hideNavigation();
    menuCtx.hideHeader();

    return () => {
      menuCtx.showNavigation();
      menuCtx.showHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const polyglot = usePolyglot();
  const location = useLocation();
  const smartPassId = queryString.parse(location.search).smartPassId;
  const [disabled, changeDisabled] = useState(false);
  const history = useHistory();
  const user = useCurrentUser();
  const companyDetails = useCompanyDetails(user.companyName);

  const hasAdminPrivileges =
    user.role === KIWI_ADMIN || user.email === companyDetails.data?.accountManager;

  const { fetchSmartPass, state, editSmartPass } = useContext(SmartPassContext);

  useEffect(() => {
    if (smartPassId) {
      fetchSmartPass(smartPassId, companyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.smartPassLoading && state.smartPass) {
      const isPartnerUserAndDraft =
        !hasAdminPrivileges && state.smartPass.status === statuses.DRAFT;
      const isAdminUserAndNotDraft =
        hasAdminPrivileges && state.smartPass.status !== statuses.DRAFT;

      changeDisabled(!isPartnerUserAndDraft || isAdminUserAndNotDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.smartPassLoading]);

  const getSubmitHandler =
    ({ saveType }) =>
    async values => {
      const res = await editSmartPass(smartPassId, values, saveType, companyName);
      if (res && res.status === 200) {
        if (saveType === statuses.DRAFT) {
          history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass?draftSuccess=true`);
        } else {
          history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass?success=true`);
        }
      }
    };

  if (state.smartPassLoading) {
    return <Loading />;
  }

  if (!state.smartPass) {
    return null;
  }

  return (
    <Formik
      onSubmit={getSubmitHandler({ saveType: statuses.PENDING_REVIEW, goBack: true })}
      validationSchema={SmartPassValidationSchema}
      initialValues={apiToFormik(state.smartPass)}
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors, touched, setFieldTouched }) => (
        <FormikForm>
          <Form
            setFieldTouched={setFieldTouched}
            values={values}
            getSubmitHandler={getSubmitHandler}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            companyName={companyName}
            status={state.smartPass.status}
            disabled={disabled}
            changeDisabled={changeDisabled}
            smartPassId={smartPassId}
            responseError={
              state.editSmartPassError && polyglot.t('smart_pass.edit_smart_pass_error')
            }
            errors={errors}
            touched={touched}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default EditSmartPass;
