import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { usePolyglot } from 'components/services/i18n';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useMenuState from 'components/services/menu/useMenuState';
import { logError } from 'utils/sentry';

import UserMenu from './UserMenu';
import ResourcesMenu from './ResourcesMenu';

const getHeadingLeftDistance = ({ navigationExpanded, largeScreen, isDocs, theme }) => {
  if (isDocs) {
    return theme.sizes.navigationExpandedWidth;
  }
  if (largeScreen) {
    return navigationExpanded ? theme.sizes.navigationExpandedWidth : theme.sizes.navigationWidth;
  }
  return theme.sizes.navigationWidth;
};

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 8px 20px;
  box-shadow: inset 0px -1px 0px 0px rgb(232, 237, 241);
  position: fixed;
  top: 0;
  left: ${getHeadingLeftDistance}px;
  width: calc(100% - ${getHeadingLeftDistance}px);
  z-index: 10;
  transition: left, 150ms ease-in-out, width 150ms ease-in-out;
`;

const PageHeading = styled.h2`
  margin: 0;
  color: rgb(0, 169, 145);
  font-size: 16px;
  font-weight: 500;
  height: 28px;
  letter-spacing: 0px;
  line-height: 28px;
  flex: 1;
`;

const SectionDivider = styled.div`
  background: rgb(232, 237, 241);
  border-radius: 0px;
  height: 44px;
  width: 1px;
`;

const pathEquals = (value, cmp) => {
  return value === cmp || `${value}/` === cmp;
};

const getPageName = (polyglot, path, user) => {
  // Dashboard
  if (pathEquals('/dashboard', path)) {
    return polyglot.t('header.dashboard');
  }
  // My applications
  else if (pathEquals('/my-solutions', path)) {
    return polyglot.t('header.my_solutions');
  }
  //support redirection if someone bookmarked my-applications
  else if (pathEquals('/my-applications', path)) {
    return polyglot.t('header.my_solutions');
  }
  // Companies
  else if (/\/companies\/.+\/bidviewer/gi.test(path)) {
    return polyglot.t('header.bidviewer');
  } else if (/\/companies\/.+\/billing$/gi.test(path)) {
    return polyglot.t('header.edit_billing');
  } else if (/\/companies\/.+/gi.test(path)) {
    // get opened company from pathname
    const company = path.split('/')[2];
    if (company === user?.companyName) {
      return polyglot.t('header.my_company');
    } else {
      return polyglot.t('header.other_company', { company });
    }
  } else if (pathEquals('/companies', path)) {
    return polyglot.t('header.companies');
  }
  // Reports
  else if (pathEquals('/reports', path)) {
    return polyglot.t('header.reports');
  } else if (pathEquals('/reports/create', path)) {
    return polyglot.t('header.create_report');
  } else if (/\/reports\/details\/.+/gi.test(path)) {
    return polyglot.t('header.report_details');
  } else if (/\/reports\/edit\/.+/gi.test(path)) {
    return polyglot.t('header.edit_report');
  } else if (/\/reports\/copy\/.+/gi.test(path)) {
    return polyglot.t('header.create_report');
  }
  // User management
  else if (pathEquals('/user-management', path)) {
    return polyglot.t('header.user_management');
  }
  // Booking tool
  else if (pathEquals('/search', path)) {
    return polyglot.t('header.booking_tool');
  } else if (pathEquals('/book', path)) {
    return polyglot.t('header.booking_tool');
  } else if (pathEquals('/book/success', path)) {
    return polyglot.t('header.booking_tool.success');
  }
  // MMB
  else if (pathEquals('/manage-my-bookings', path)) {
    return polyglot.t('header.manage_my_bookings');
  } else if (pathEquals('/manage-my-bookings/credit-activity', path)) {
    return polyglot.t('header.manage_my_bookings');
  } else if (/\/manage-my-bookings\/.+/gi.test(path)) {
    return polyglot.t('header.manage_my_bookings');
  }
  // Deeplink generator
  else if (pathEquals('/deeplink-generator', path)) {
    return polyglot.t('header.deeplink_generator');
  }
  // Widgets
  else if (pathEquals('/widgets', path)) {
    return polyglot.t('header.widget_builder');
  }
  // Widgets
  else if (/\/widgets\/./gi.test(path)) {
    return polyglot.t('header.widget_builder');
  }
  // Resources
  else if (pathEquals('/resources', path)) {
    return polyglot.t('header.resources');
  } else if (/\/resources\/.+/gi.test(path)) {
    return polyglot.t('header.resources');
  }
  // Partner payments
  else if (pathEquals('/partner-payments', path)) {
    return polyglot.t('header.partner_payments');
  } else if (/\/partner-payments\/.+/gi.test(path)) {
    return polyglot.t('header.partner_payments_details');
  }
  // Support
  else if (pathEquals('/customer-support', path)) {
    return polyglot.t('header.customer_support');
  } else if (pathEquals('/support', path)) {
    return polyglot.t('header.support');
  } else if (pathEquals('/support/request', path)) {
    return polyglot.t('header.support_request');
  } else if (pathEquals('/customer-support/request', path)) {
    return polyglot.t('header.support_request');
  }
  // Docs
  else if (/\/docs/gi.test(path)) {
    return '';
  }
  // Smart pass
  else if (/\/smartpass/gi.test(path)) {
    return polyglot.t('header.smart_pass');
  }
  // Getting started
  else if (/\/getting-started/gi.test(path)) {
    return polyglot.t('header.getting_started');
  }
  // Billing
  else if (/\/billing/gi.test(path)) {
    return polyglot.t('header.billing');
  }
  // Customer Demand Dashboard
  else if (/\/customer-demand/gi.test(path)) {
    return polyglot.t('header.customer_demand_dashboard');
  }
  // Billing
  else if (/\/admin-dashboard/gi.test(path)) {
    return polyglot.t('header.admin_dashboard');
  }
  // Customer Demand Dashboard
  else if (/\/destination-demand/gi.test(path)) {
    return polyglot.t('tequila.header.title.destination_demand_dashboard');
  }

  return null;
};

const PageHeader = () => {
  const user = useCurrentUser();
  const location = useLocation();
  const polyglot = usePolyglot();
  const name = getPageName(polyglot, location.pathname, user);
  const menuState = useMenuState();

  useEffect(() => {
    if (name === null && location.pathname !== '/') {
      logError(new Error('Unknown page name'), {
        pathname: location.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  if (!menuState.isNavigationShown && !menuState.isHeaderShown) {
    return null;
  }

  const isDocs = /\/docs(\/.+)?/gi.test(location.pathname);

  return (
    <HeadingWrapper
      navigationExpanded={menuState.navigationExpanded}
      largeScreen={menuState.isLargeScreen}
      isDocs={isDocs}
    >
      <PageHeading>{menuState.isNavigationShown && name}</PageHeading>
      <SectionDivider />
      <UserMenu />
      <SectionDivider />
      {!isDocs && <ResourcesMenu />}
    </HeadingWrapper>
  );
};

export default React.memo(PageHeader);
