import React from 'react';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { usePolyglot } from 'components/services/i18n';
import useBooking from 'components/services/booking/useBooking';
import { useCurrency } from 'components/services/currencies';

import {
  Container,
  PricesWrapper,
  PriceRow,
  TotalContainer,
  TotalLabelWrapper,
  TotalLabel,
  TotalPrice,
} from './PriceOverview.styled';

const PriceOverview = ({ price, passengers, baggage, paidGuarantee, loading }) => {
  const { getBaggagePrice } = useBooking();
  const { getConvertedPriceString } = useCurrency();
  const polyglot = usePolyglot();

  const handBagPrice = getBaggagePrice(passengers, baggage, 'handBag');
  const holdBagPrice = getBaggagePrice(passengers, baggage, 'holdBag');

  const data = [
    {
      amount: passengers?.length,
      label: polyglot.t('booking.deposit_booking.booking_summary.price_overview.passenger'),
      price: price.amount,
      convertedPriceString: getConvertedPriceString(price.amount),
    },
    {
      ...handBagPrice,
      label: polyglot.t('booking.deposit_booking.booking_summary.price_overview.cabin_baggage'),
      convertedPriceString: getConvertedPriceString(handBagPrice.price),
    },
    {
      ...holdBagPrice,
      label: polyglot.t('booking.deposit_booking.booking_summary.price_overview.checked_baggage'),
      convertedPriceString: getConvertedPriceString(holdBagPrice.price),
    },
    {
      label: polyglot.t('booking.deposit_booking.booking_summary.price_overview.paid_guarantee'),
      amount: paidGuarantee && 1,
      price: paidGuarantee ? Number(paidGuarantee.price.amount) : null,
      convertedPriceString:
        paidGuarantee && getConvertedPriceString(Number(paidGuarantee.price.amount)),
    },
  ];
  const total = data.reduce((sum, item) => (isNaN(item.price) ? sum : sum + item.price), 0);
  const convertedTotalString = getConvertedPriceString(total);

  return (
    <Container>
      <div>
        <PricesWrapper>
          {data.map((d, i) => {
            if (!d.amount) {
              return null;
            }
            return (
              <PriceRow key={i}>
                <div>{`${d.amount}x ${d.label}`}</div>
                {loading ? (
                  <Loading type="inlineLoader" />
                ) : (
                  <div>
                    {`€${0 || d.price?.toFixed(2)}`}
                    {d.convertedPriceString && ` (${d.convertedPriceString})`}
                  </div>
                )}
              </PriceRow>
            );
          })}
        </PricesWrapper>

        <TotalContainer>
          <TotalLabelWrapper>
            <Tooltip
              content={
                <p>
                  {polyglot.t(
                    'booking.deposit_booking.booking_summary.price_overview.price_warning',
                  )}
                  <br />
                  <br />
                  {polyglot.t(
                    'booking.deposit_booking.booking_summary.price_overview.total_price',
                    { price_value: total },
                  )}
                  {convertedTotalString && ` (${convertedTotalString})`}
                </p>
              }
            >
              <InformationCircleIcon color="primary" />
            </Tooltip>
            <TotalLabel>Total ({price.currency})</TotalLabel>
          </TotalLabelWrapper>
          {loading ? (
            <Loading type="inlineLoader" />
          ) : (
            <TotalPrice>
              {`€${total?.toFixed(2)}`}
              {convertedTotalString && ` (${convertedTotalString})`}
            </TotalPrice>
          )}
        </TotalContainer>
      </div>
    </Container>
  );
};

export default PriceOverview;
