import React from 'react';
import styled from 'styled-components';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import CircleEmptyIcon from '@kiwicom/orbit-components/lib/icons/CircleEmpty';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';

import useMenuState from 'components/services/menu/useMenuState';
import useOnboardingState from 'components/services/localStorage/useOnboardingState';
import { usePolyglot } from 'components/services/i18n';

const Steps = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  left: ${({ largeScreen }) => (largeScreen ? '1020px' : '815px')};
  transition: left 0.15s ease-in;
  min-height: 150px;
  padding: 24px;
  background: white;
  & div {
    justify-content: left;
  }
`;

const CompletedSteps = ({ hasSolutions, hasBankAccounts }) => {
  const polyglot = usePolyglot();
  const menuCtx = useMenuState();
  const [onboardingState] = useOnboardingState();
  const largeScreen = menuCtx.navigationExpanded && menuCtx.isLargeScreen;

  const STEPS = [
    {
      name: 'getting_started.steps.documentation',
      dataTest: 'steps.documentation',
      done: onboardingState.acknowledgedDocumentation,
    },
    {
      name: 'getting_started.steps.solution',
      dataTest: 'steps.solution',
      done: hasSolutions,
    },
    {
      name: 'getting_started.steps.bank_account',
      dataTest: 'steps.bank_account',
      done: hasBankAccounts,
    },
    {
      name: 'getting_started.steps.start_using',
      dataTest: 'steps.start_using',
      done: onboardingState.acknowledgedStartUsingSolution,
    },
  ];

  return (
    <Steps largeScreen={largeScreen}>
      {STEPS.map(s =>
        s.done ? (
          <Badge
            key={s.name}
            type="success"
            icon={<CheckCircleIcon />}
            dataTest={`${s.dataTest}_done`}
          >
            {polyglot.t(s.name)}
          </Badge>
        ) : (
          <Badge
            key={s.name}
            type="neutral"
            icon={<CircleEmptyIcon />}
            dataTest={`${s.dataTest}_not_done`}
          >
            {polyglot.t(s.name)}
          </Badge>
        ),
      )}
    </Steps>
  );
};

export default CompletedSteps;
