import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { ROUTE_CONFIG } from 'consts/routes';
import { statuses } from 'consts/smartPass';

const ExitModal = ({ companyName, status, onSubmit, errors, touched }) => {
  useEffect(() => {
    return () => exitModal.setOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { exitModal, state } = useContext(SmartPassContext);
  const polyglot = usePolyglot();
  const history = useHistory();

  const handleDiscard = () => {
    history.push(`${ROUTE_CONFIG.COMPANIES.path}/${companyName}/smartpass`);
  };

  const handleSubmit = () => {
    onSubmit(status || statuses.DRAFT);
  };

  return (
    <Modal size="small" closable onClose={exitModal.setOff}>
      <ModalHeader
        title={polyglot.t('common.save_changes')}
        description={polyglot.t('smart_pass.form.exit_modal.description')}
      />
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button onClick={handleDiscard} type="primarySubtle">
            {polyglot.t('common.discard_changes')}
          </Button>
          <Button
            onClick={handleSubmit}
            loading={state.createSmartPassLoading || state.editSmartPassLoading}
          >
            {polyglot.t('common.save')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default ExitModal;
