import React from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';

import { ContentContainer } from '../index.styled';

const SearchError = ({ title, content, isNavigationHidden }) => (
  <ContentContainer isNavigationHidden={isNavigationHidden}>
    <Alert type="critical" icon title={title}>
      {content}
    </Alert>
  </ContentContainer>
);

export default SearchError;
