import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Text from '@kiwicom/orbit-components/lib/Text';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Loading from '@kiwicom/orbit-components/lib/Loading';

import HttpResponse from 'shapes/HttpResponse';
import { truncate } from 'utils/string';

import { fieldIds } from 'redux/modules/apigee/support';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@kiwicom/orbit-components/lib/Table';

import Polyglot from 'shapes/Polyglot';

function RequestsList({ requestsResponse, polyglot, openModal, numberOfRequests }) {
  const noRequests = [undefined, 0].includes(R.path(['requests', 'length'], requestsResponse));
  if (noRequests && requestsResponse.isLoading()) {
    return (
      <Stack direction="column" justify="center" align="center">
        <Loading loading type="pageLoader" />
      </Stack>
    );
  }

  if ((noRequests && !requestsResponse.isValid()) || requestsResponse.isClear()) {
    return null;
  }

  if (noRequests) {
    return (
      <div>
        <Illustration name="Offline" spaceAfter="large" />
        <Text type="primary" weight="bold" spaceAfter="small">
          {polyglot.t('common.not_found')}
        </Text>
      </div>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">{polyglot.t('support.request')}</TableCell>
          <TableCell align="center">{polyglot.t('support.reported_by')}</TableCell>
          <TableCell align="center">{polyglot.t('support.status')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {requestsResponse.requests.slice(0, numberOfRequests).map(request => {
          const summary = request.requestFieldValues.find(
            field => field.fieldId === fieldIds.SUMMARY,
          );

          return (
            <TableRow key={request.issueId}>
              <TableCell align="left">
                <TextLink onClick={() => openModal(request.issueId)}>
                  {truncate(summary.value, 60)}
                </TextLink>
              </TableCell>
              <TableCell align="center">
                {request.reporter?.displayName || (
                  <Text italic align="center">
                    {polyglot.t('support.unknown_reporter')}
                  </Text>
                )}
              </TableCell>
              <TableCell align="center">{request.currentStatus.status}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

RequestsList.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  numberOfRequests: PropTypes.number.isRequired,
  requestsResponse: HttpResponse.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default RequestsList;
