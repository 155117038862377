import styled, { createGlobalStyle } from 'styled-components';

export const InputAutocompleteGlobals = createGlobalStyle`
  .input-autocomplete-move-overflow {
    height: 250%;
    align-items: flex-start;
    position: absolute;
    top: 0px;

    & > div:not(:last-of-type) {
      margin-top: 4px;
    }
  }
`;

export const ListContainer = styled.div`
  position: relative;
`;

export const AutocompleteList = styled.ul`
  position: absolute;
  overflow-y: auto;
  max-height: 240px;
  margin: 0;
  padding: 12px 0;
  list-style-type: none;
  background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.32);
  z-index: 6;
  width: 100%;
`;

export const EmptyResultsIconContainer = styled.span`
  margin-right: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.orbit.paletteBlueNormal};
`;

export const ItemIconContainer = styled.div`
  margin-right: 8px;
`;

export const ItemLabel = styled.div`
  ${({ withItem }) => !withItem && 'margin-left: 32px;'}
`;
