import { useContext, useEffect } from 'react';

import { UsersState } from './index';

const useUsers = () => {
  const usersCtx = useContext(UsersState);

  useEffect(() => {
    if (!usersCtx.users) {
      usersCtx.loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    { ...usersCtx } || {
      users: [],
      loadingUsers: false,
      updatingUser: false,
    }
  );
};

export default useUsers;
