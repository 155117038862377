import styled, { css } from 'styled-components';

import ChevronUpIcon from '@kiwicom/orbit-components/lib/icons/ChevronUp';

export const ArrowIcon = styled(ChevronUpIcon)`
  padding-left: 4px;
  cursor: pointer;
  transition: transform 150ms ease-in-out;

  ${({ descending }) =>
    descending &&
    css`
      transform: rotate(180deg);
      padding-right: 4px;
      padding-left: 0;
    `}
`;
