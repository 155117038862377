import React from 'react';

import { containerSizes } from 'consts/smartPass';

import { VoucherContainer } from './StandardVoucher.styled';

const CustomVoucher = ({ previewInfo: { backgroundImageUrl, scanCodeSvg }, previewType }) => {
  return (
    <VoucherContainer size={containerSizes[previewType]} backgroundImageUrl={backgroundImageUrl}>
      {scanCodeSvg}
    </VoucherContainer>
  );
};

export default CustomVoucher;
