import * as R from 'ramda';

/**
 * Returns a copy of object containing only keys with truthy values.
 * @param {*} object
 */
export const pickByNotNil = object => R.reject(R.isNil, object);

export const hasOneOfProperties = (object = {}, properties = []) => {
  for (const prop of properties) {
    if (object.hasOwnProperty(prop)) {
      return prop;
    }
  }
  return false;
};

export const isObject = variable => {
  return Object.prototype.toString.call(variable) === '[object Object]';
};

/**
 * Converts array of objects to string in CSV format
 * @param {Array of Object} data
 * @param {Array of String} order specifies order of keys in the export
 * @param {Object} mappedNames specifies how to rename properties
 * @return {String}
 */

export const arrayOfObjectsToCsv = (data, order = null, mappedNames = {}) => {
  if (!data.length) {
    return null;
  }

  let csvString = [
    order || [Object.keys(data[0])],
    ...data.map(item => {
      if (!!order?.length) {
        return order.map(i =>
          JSON.stringify(!!item[i] || item[i] === 0 || item[i] === false ? item[i] : 'N/A'),
        );
      }

      const newItems = Object.values(item).map(el =>
        !!el || el === 0 || el === false ? el : 'N/A',
      );
      return newItems;
    }),
  ]
    .map(e => e.join(','))
    .join('\n');

  if (mappedNames && !!Object.keys(mappedNames).length) {
    csvString = csvString.split('\n');
    csvString[0] = csvString[0]
      .split(',')
      .map(el => (mappedNames[el] ? mappedNames[el] : el))
      .join(',');
    csvString = csvString.join('\n');
  }

  return csvString;
};

export const filterObject = (target, value) => {
  return Object.keys(target)
    .filter(key => key !== value)
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: target[key],
      });
    }, {});
};
