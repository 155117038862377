import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Select from '@kiwicom/orbit-components/lib/Select';
import Text from '@kiwicom/orbit-components/lib/Text';

import * as reportAliasesActions from 'redux/modules/reportAliases';
import * as reportsActions from 'redux/modules/reports';

import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import { ReportData } from 'pages/Reports/components';
import { SelectSolution, OrbitLoader, SelectSolutionsCompany } from 'common';
import { getEachDayForDateRangeConstant } from 'utils/dates';
import { REPORT_ALIAS_TYPES, SHOW_VALUES } from 'consts/reports';
import { YESTERDAY, LAST_7_DAYS, LAST_28_DAYS, LAST_90_DAYS, DAY } from 'consts/dates';
import { FIRST_PAGE } from 'consts/pagination';
import { usePolyglot } from 'components/services/i18n';
import { useCurrentUser } from 'components/services/auth';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';

import AffiliateAppChart from './components/Chart/AffiliateAppChart';
import BookingAppChart from './components/Chart/BookingAppChart';
import { getIntervalForDateRangeConstant } from './utils';
import { LoaderContainer, IntervalContainer, NoDataFigureContainer } from './DashboardData.styled';

const AVAILABLE_DATE_RANGES = [YESTERDAY, LAST_7_DAYS, LAST_28_DAYS, LAST_90_DAYS];
const DATE_RANGES_I18N = {
  [YESTERDAY]: {
    k: 'common.yesterday',
  },
  [LAST_7_DAYS]: {
    k: 'common.last_n_days',
    n: 7,
  },
  [LAST_28_DAYS]: {
    k: 'common.last_n_days',
    n: 28,
  },
  [LAST_90_DAYS]: {
    k: 'common.last_n_days',
    n: 90,
  },
};
const DEFAULT_DATE_RANGE = LAST_7_DAYS;
const DEFAULT_INTERVAL = DAY;

const DashboardData = ({
  reportAlias,
  reportAliasSubmitting,
  report,
  reportLoading,
  reportAliasCreating,
  createReportAlias,
  loadReport,
  loadReportAlias,
}) => {
  const user = useCurrentUser();
  const polyglot = usePolyglot();
  const [solution, setSolution] = useState('');
  const { selectedCompany, selectCompany } = useSelectedCompany(user.companyName);
  const [selectedDateRange, setSelectedDateRange] = useState(DEFAULT_DATE_RANGE);
  const [stateReport, setStateReport] = useState({
    aliasType: REPORT_ALIAS_TYPES.temp,
    interval: DEFAULT_INTERVAL,
    dateRange: getEachDayForDateRangeConstant(DEFAULT_DATE_RANGE),
  });

  useEffect(() => {
    if (!solution || !solution.affilId) {
      return;
    }

    const reportWithMetrics = R.assoc(
      'show',
      solution.dashboardMetrics.map(metric => SHOW_VALUES[metric]),
      stateReport,
    );

    createReportAlias({
      ...reportWithMetrics,
      alias: `dashboard - ${Date.now().toString()}`,
      filters: {
        affiliate: [solution.affilId],
      },
    }).then(({ reportAlias: { id } }) => {
      loadReport(id, FIRST_PAGE);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solution, stateReport]);

  const handleChangeDateRange = event => {
    const { value: dateRange } = event.target;
    setSelectedDateRange(dateRange);
    setStateReport({
      ...stateReport,
      dateRange: getEachDayForDateRangeConstant(dateRange),
      interval: getIntervalForDateRangeConstant(dateRange),
    });
  };

  const renderReport = () => {
    if (reportLoading || reportAliasCreating) {
      return (
        <LoaderContainer>
          <OrbitLoader id="dashboard-loader" visible />
        </LoaderContainer>
      );
    }

    if (report) {
      return report.rows.length === 0 ? (
        <NoDataFigureContainer>
          <Illustration name="Offline" spaceAfter="large" />
          <Text type="primary" weight="bold" spaceAfter="small">
            {polyglot.t('dashboard.no_data')}
          </Text>
          <Text>
            {polyglot.t('dashboard.no_data_for_solution', { application: solution.name })}
          </Text>
        </NoDataFigureContainer>
      ) : (
        <>
          {report && solution.category === 'AFFILIATE' ? (
            <AffiliateAppChart data={report.rows} groupByInterval={report.interval} />
          ) : (
            <BookingAppChart data={report.rows} groupByInterval={report.interval} />
          )}
          <ReportData />
        </>
      );
    }
  };

  return (
    <>
      {(user.role === KIWI_ADMIN || user.role === ACCOUNT_MANAGER) && (
        <div>
          <SelectSolutionsCompany
            onSelect={selectedCompanyName => {
              selectCompany(selectedCompanyName);
              setSolution('');
            }}
          />
        </div>
      )}
      <SelectSolution
        allEnabled
        onChange={event => setSolution(event.target.value)}
        companyName={selectedCompany}
      >
        <IntervalContainer>
          <Select
            value={selectedDateRange}
            onChange={handleChangeDateRange}
            size="small"
            label={polyglot.t('reports.interval')}
            options={AVAILABLE_DATE_RANGES.map(dateRange => ({
              label: polyglot.t(DATE_RANGES_I18N[dateRange].k, {
                n: DATE_RANGES_I18N[dateRange].n,
              }),
              value: dateRange,
            }))}
          />
        </IntervalContainer>
      </SelectSolution>
      {solution.name && renderReport()}
    </>
  );
};

const mapStateToProps = state => ({
  reportAlias: state.reportAliases.reportAlias,
  reportAliasSubmitting: state.reportAliases.submitting,
  report: state.reports.data,
  reportLoading: state.reports.loading,
  reportAliasCreating: state.reportAliases.submitting,
});

const mapDispatchToProps = {
  createReportAlias: reportAliasesActions.create,
  loadReport: reportsActions.loadReport,
  loadReportAlias: reportAliasesActions.loadById, //temp
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardData);
