import { connect } from 'react-redux';

import { fetchRequests, fetchRequestCategories, clearRequests } from 'redux/modules/apigee/support';

import {
  getCustomer,
  getRequestsCategories,
  getRequests,
  getRequestTypeFilterOptions,
} from 'redux/selectors/apigee/support';

import { MyRequests } from 'common/Support';

const mapStateToProps = state => ({
  customer: getCustomer(state),
  requestsResponse: getRequests(state),
  requestCategoriesResponse: getRequestsCategories(state),
  typeFilterOptions: getRequestTypeFilterOptions(state),
});

const mapDispatchToProps = {
  clearRequests,
  fetchRequests,
  fetchRequestCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests);
