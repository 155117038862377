import format from 'date-fns/format';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

const newDateWithoutTimeZone = dateString => {
  let dateWithoutTimeZone;

  if (dateString.includes('+')) {
    dateWithoutTimeZone = dateString.split('+')[0];
  } else if (dateString.split('-').length === 4) {
    let stringArray = dateString.split('-');
    dateWithoutTimeZone = stringArray.slice(0, -1).join('-');
  }

  return dateWithoutTimeZone ? new Date(dateWithoutTimeZone).toLocaleString() : '-';
};

const v1_get_from = ({ booking, passengers, itinerary, display_status }) => {
  if (!passengers.length || !itinerary.sectors?.length || !itinerary.segments?.length) {
    return;
  }
  const isMultiCity = itinerary.itinerary_type === 'multicity';
  const fullTrip = [];
  let isReturnMulticity = false;
  if (isMultiCity) {
    itinerary.sectors.forEach(s => {
      const dep = `${s.departure.place.city} (${s.departure.station.code})`;
      const arr = `${s.arrival.place.city} (${s.arrival.station.code})`;

      //departure is usually the same as next arrival so include departure only if different
      if (!fullTrip?.slice(fullTrip.length - 2)?.includes(dep)) {
        fullTrip.push(dep);
      }
      fullTrip.push(arr);
    });

    //same as return
    isReturnMulticity = fullTrip.length === 4 && fullTrip[0] === fullTrip[3];
  }
  const roundTrip = itinerary.itinerary_type === 'return' || isReturnMulticity;
  return {
    affilId: booking.affiliate_id,
    brand: booking.brand,
    bid: booking.id,
    passengers: passengers.length,
    status: display_status,
    bookingDate: new Date(booking?.created_at)?.toLocaleString(),
    departureDate: newDateWithoutTimeZone(itinerary.sectors?.[0]?.departure?.time?.local),
    departure: {
      place: itinerary?.sectors?.[0]?.departure?.place?.city,
      code: itinerary?.sectors?.[0]?.departure?.station?.code,
    },
    destination: roundTrip
      ? {
          place: itinerary.sectors[0].arrival.place.city,
          code: itinerary.sectors[0].arrival.station.code,
        }
      : {
          place: itinerary.sectors[itinerary.sectors.length - 1].arrival.place.city,
          code: itinerary.sectors[itinerary.sectors.length - 1].arrival.station.code,
        },
    type: itinerary.itinerary_type,
    roundTrip,
    ...(fullTrip.length && { fullTrip }),
  };
};

const v1_get_to = selectedDates => {
  if (!selectedDates) return '';

  return {
    date_from: selectedDates?.length
      ? format(startOfDay(new Date(selectedDates[0])), "yyyy-MM-dd'T'HH:mm")
      : null,
    date_to:
      selectedDates.length > 1
        ? format(endOfDay(new Date(selectedDates[selectedDates?.length - 1])), "yyyy-MM-dd'T'HH:mm")
        : null,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
      to: v1_get_to,
    },
  },
};
