import { useContext } from 'react';

import { InitialQueryParamsState } from './';

const useInitialQueryParams = () => {
  const ctx = useContext(InitialQueryParamsState);
  return ctx;
};

export default useInitialQueryParams;
