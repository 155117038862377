import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import CheckCircleIcon from '@kiwicom/orbit-components/lib/icons/CheckCircle';
import * as cookies from '@kiwicom/cookies';

import { OrbitLoader } from 'common';
import { usePolyglot } from 'components/services/i18n';
import BackButton from 'components/common/BackButton';
import { sendWindowMessage, receiveWindowMessage } from 'utils/windowMessage';
import {
  TEQUILA_FE_ZOOZ,
  TEQUILA_FE_DEPOSIT,
  TEQUILA_FE_ZOOZ_SMARTPOINT,
  TEQUILA_FE_DEPOSIT_SMARTPOINT,
} from 'consts/booking';
import { ZOOZ_PRODUCT_TYPES } from 'consts/search';
import { resourcesCodenames } from 'consts/kentico';
import statusTypes from 'consts/statusTypes';
import { ROUTE_CONFIG } from 'consts/routes';
import { BookingState } from 'components/services/booking/BookingProvider';

import { BookingIframe } from './components/BookingIframe';

const isZoozApplication = productType => {
  return ZOOZ_PRODUCT_TYPES.includes(productType);
};

const getBookingOrigin = (productType, isSmartpointSearch) => {
  if (isSmartpointSearch) {
    return isZoozApplication(productType)
      ? TEQUILA_FE_ZOOZ_SMARTPOINT
      : TEQUILA_FE_DEPOSIT_SMARTPOINT;
  }
  return isZoozApplication(productType) ? TEQUILA_FE_ZOOZ : TEQUILA_FE_DEPOSIT;
};

const IFrameBooking = ({ location }) => {
  const [sendApiKeyFail, setSendApiKeyFail] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const bookingIframe = useRef(null);
  const { generateToken, userToken, userTokenStatus } = useContext(BookingState);
  const polyglot = usePolyglot();

  useEffect(() => {
    generateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    apiKey,
    token: bookingToken,
    flightsId,
    passengers,
    currency,
    productType,
    isSmartpointSearch,
  } = location.state;

  useEffect(() => {
    if (userToken) {
      cookies.save('ua_session_token', userToken, { domain: 'kiwi.com' });
    }
  }, [userToken]);

  useEffect(() => {
    return () => {
      cookies.remove('ua_session_token');
    };
  }, []);

  const getIframeSrc = searchParams => {
    const utmParams = { utm_source: 'tequila', utm_medium: 'booking_tool', utm_campaign: 'cc' };
    const query = new URLSearchParams({ ...searchParams, ...utmParams }).toString();
    const origin = getBookingOrigin(productType, isSmartpointSearch);
    return `${origin}/en/booking?${query}`;
  };

  const sendApiKey = () => {
    const origin = getBookingOrigin(productType, isSmartpointSearch);
    sendWindowMessage(bookingIframe.current, origin, {
      apiKey,
    })
      .catch(() => setSendApiKeyFail(true))
      .then(() => receiveWindowMessage([origin], ['bookingSuccess']))
      .then(res => {
        const { bookingSuccess: success } = res;
        if (success) {
          setBookingSuccess(true);
        }
      });
  };

  if (bookingSuccess) {
    return (
      <Alert
        type="success"
        icon={<CheckCircleIcon />}
        title={polyglot.t('searchAndBooking.successMessage.title')}
      >
        {polyglot.t('searchAndBooking.successMessage.body')}
      </Alert>
    );
  }

  if (userTokenStatus === statusTypes.LOADING) {
    return (
      <Stack direction="column" justify="center">
        <OrbitLoader id="booking-loader" visible />
      </Stack>
    );
  }

  if (bookingToken && userToken && apiKey && flightsId && passengers && !sendApiKeyFail) {
    const searchParameters = {
      passengers,
      token: bookingToken,
      flightsId,
      'oauth-login': true,
      ui: 'tequila',
      currency,
      brand: 'tequila-fe',
      iframe: window.location.origin,
    };

    const alertMessage = !isZoozApplication(productType) ? (
      <Alert type="info" icon spaceAfter="medium">
        {polyglot.t('searchAndBooking.deposit_booking_info')}
      </Alert>
    ) : (
      <Alert type="info" icon spaceAfter="medium">
        {polyglot.t('searchAndBooking.zooz_booking_info')}
        <Link to={`${ROUTE_CONFIG.RESOURCES.path}/${resourcesCodenames.TERMS_CONDITIONS_CODENAME}`}>
          {polyglot.t('common.terms_conditions')}.
        </Link>
      </Alert>
    );

    return (
      <Stack>
        <BackButton route="/search" />
        {alertMessage}
        <BookingIframe
          ref={bookingIframe}
          title="Booking"
          src={getIframeSrc(searchParameters)}
          onLoad={sendApiKey}
          reducedSize
        />
      </Stack>
    );
  }
  return <Alert type="critical" title={polyglot.t('searchAndBooking.invalid_booking')} />;
};

export default IFrameBooking;
