import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';
import Text from '@kiwicom/orbit-components/lib/Text';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import InformationCircleIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { ErrorMessageComponent } from 'common';
import Polyglot from 'shapes/Polyglot';
import { COMPANY_TYPES } from 'consts/company';
import { cantContainCyrillic } from 'utils/validation';
import { businessTypeOptions, companySizeOptions } from 'consts/register';

const Empty = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const CompanyDetails = ({ polyglot, values, touched, handleChange, handleBlur, errors }) => {
  return (
    <Stack spaceAfter="largest" spacing="large">
      <Heading type="title2">{polyglot.t('register.company_details')}</Heading>

      <InputField
        id="register-company-name"
        label={
          values.type === COMPANY_TYPES.COMPANY
            ? polyglot.t('company.name')
            : polyglot.t('register.account_name')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        name="companyName"
        value={values.companyName}
        error={touched.companyName && errors.companyName && polyglot.t(errors.companyName)}
      />

      <InputField
        id="company-email"
        label={
          <Stack direction="row" spacing="tight" align="center">
            <Text>{polyglot.t('company.email')}</Text>

            <Tooltip
              content={polyglot.t('company.email_description')}
              preferredPosition={'right'}
              size={'medium'}
            >
              <InformationCircleIcon size="small" />
            </Tooltip>
          </Stack>
        }
        onChange={handleChange}
        onBlur={handleBlur}
        name="companyEmail"
        value={values.companyEmail}
        error={touched.companyEmail && errors.companyEmail && polyglot.t(errors.companyEmail)}
      />
      <div id="tooltips"></div>

      <InputField
        id="register-company-website"
        label={polyglot.t('register.website_optional')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="website"
        value={values.website}
        error={touched.website && errors.website && polyglot.t(errors.website)}
      />

      <Stack>
        <Stack direction="row" spacing="XSmall">
          <Select
            id="register-business-type-select"
            name="businessType"
            onChange={handleChange}
            value={values.businessType}
            label={polyglot.t('register.business_type')}
            placeholder={polyglot.t('register.business_type.placeholder')}
            options={businessTypeOptions.map(o => ({
              label: polyglot.t(o.label),
              value: o.value,
            }))}
            error={touched.businessType && errors.businessType && polyglot.t(errors.businessType)}
          />

          <Select
            id="register-company-size-select"
            name="companySize"
            onChange={handleChange}
            value={values.companySize}
            label={polyglot.t('register.company_size')}
            placeholder={polyglot.t('register.company_size.placeholder')}
            options={companySizeOptions}
            error={touched.companySize && errors.companySize && polyglot.t(errors.companySize)}
          />
        </Stack>

        {values.businessType === 'Other' && (
          <Stack direction="row" spacing="XSmall">
            <InputField
              id="register-business-type-other-input"
              name="businessTypeOther"
              onChange={handleChange}
              placeholder={polyglot.t('register.business_type.other.placeholder')}
              value={values.businessTypeOther}
              error={
                touched.businessTypeOther &&
                errors.businessTypeOther &&
                polyglot.t(errors.businessTypeOther)
              }
            />
            <Empty />
          </Stack>
        )}
      </Stack>

      <Stack spacing="XXXSmall">
        <Checkbox
          name="businessPresenceDisclaimer"
          checked={values.businessPresenceDisclaimer}
          label={polyglot.t('register.business_presence_disclaimer')}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <ErrorMessage
          name="businessPresenceDisclaimer"
          render={msg => <ErrorMessageComponent>{polyglot.t(msg)}</ErrorMessageComponent>}
        />
      </Stack>
    </Stack>
  );
};

CompanyDetails.schema = Yup.object().shape({
  companyName: Yup.string()
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(64, 'validation.length_exceeded'),
  companyEmail: Yup.string()
    .email('validation.invalid_email')
    .required('validation.cant_be_empty')
    .test('cyrillic', 'validation.no_cyrillic', cantContainCyrillic)
    .max(255, 'validation.length_exceeded'),
  companyWebsite: Yup.string().url('validation.must_be_uri').max(255, 'validation.length_exceeded'),
  businessType: Yup.string().required('validation.cant_be_empty'),
  businessTypeOther: Yup.string().when(['businessType'], {
    is: businessType => businessType === 'Other',
    then: Yup.string().required('validation.cant_be_empty'),
  }),
  companySize: Yup.string().required('validation.cant_be_empty'),
  businessPresenceDisclaimer: Yup.bool().oneOf([true], 'validation.checked'),
});

CompanyDetails.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default CompanyDetails;
