import {
  mappedArticleKeys,
  mappedSwaggerDocumentationKeys,
  csEnabledCodenames,
} from 'consts/kentico';
import { isCustomerSupportEnabled } from 'consts/support';

export const mapArticleFields = field => mappedArticleKeys[field];

export const mapSwaggerDocumentationFields = field => mappedSwaggerDocumentationKeys[field];

export const filterCustomerSupport = subTerm => {
  if (!isCustomerSupportEnabled() && csEnabledCodenames.includes(subTerm.codename)) {
    return false;
  }
  return true;
};
