import styled, { createGlobalStyle } from 'styled-components';
import Select from '@kiwicom/orbit-components/lib/Select';
import { TableCell } from '@kiwicom/orbit-components/lib/Table';

export const FilterGlobals = createGlobalStyle`
  .filter-padding-left {
    padding-left: 32px;
  }

  .filter-autocomplete-white-background {
    background-color: #ffffff;
  }

  .filter-autocomplete-row {
    border-color: transparent;
    transition: all 150ms ease-in-out;

    &:hover {
      background-color: #ffffff;
      border-color: ${({ theme }) => theme.orbit.borderColorTableCell};
      opacity: 0.5;
    }
  }
`;

export const IconCell = styled(TableCell)`
  width: 40px;
  padding-left: 0 !important;

  ${({ center }) => center && 'text-align: center !important;'}
`;

export const FilterCell = styled(TableCell)`
  width: 224px;
  padding-left: 0 !important;
`;

export const ButtonCell = styled(TableCell)`
  &:first-of-type {
    width: 56px;
  }
  &:last-of-type {
    width: 170px;
    padding-right: 24px;
    text-align: right;
  }
`;

export const TablePlaceholder = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
  border-width: 0 1px 1px 1px;
  border-color: ${({ theme }) => theme.orbit.borderColorTableCell};
  opacity: 0.5;
  border-style: solid;
`;

export const AutocompleteCell = styled(TableCell)`
  max-width: 400px;
`;

export const FilterSelect = styled(Select)`
  z-index: 2;
`;

export const FilterPickerWrapper = styled.div`
  min-width: 200px;
`;
