import React, { useReducer, useEffect } from 'react';

import api from 'utils/api';
import apiProductsMapper from 'mappers/nextGen/apiproducts/_';
import rolesMapper from 'mappers/nextGen/acl/roles/_';
import { useCurrentUser } from 'components/services/auth';

const initialState = {
  products: {},
  roles: {},
};
export const ProductsState = React.createContext(initialState);

const productsReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_PRODUCTS':
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          error: null,
        },
      };
    case 'LOAD_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data: action.products,
        },
      };
    case 'LOAD_PRODUCTS_FAIL':
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: action.error,
        },
      };
    case 'LOAD_ROLES':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
          error: null,
        },
      };
    case 'LOAD_ROLES_SUCCESS':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          data: action.roles,
        },
      };
    case 'LOAD_ROLES_FAIL':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(productsReducer, initialState);
  const user = useCurrentUser();

  const loadProducts = async () => {
    dispatch({ type: 'LOAD_PRODUCTS' });

    try {
      const res = await api.v1.get('apiproducts');

      const mapped = apiProductsMapper.v1.get.from(res.data);

      const products = mapped.data;

      dispatch({ type: 'LOAD_PRODUCTS_SUCCESS', products });
    } catch (error) {
      return Promise.reject(dispatch({ type: 'LOAD_PRODUCTS_FAIL', error }));
    }
  };

  const loadRoles = async () => {
    dispatch({ type: 'LOAD_ROLES' });

    try {
      const { data } = await api.v1.get('acl/roles');
      const roles = rolesMapper.v1.get.from(data);

      dispatch({
        type: 'LOAD_ROLES_SUCCESS',
        roles,
      });
    } catch (error) {
      dispatch({
        type: 'LOAD_ROLES_FAIL',
        error,
      });
    }
  };

  useEffect(() => {
    if (user) {
      loadProducts();
      loadRoles();
    }
  }, [user]);

  const value = {
    state,
    loadProducts,
  };

  return <ProductsState.Provider value={value}>{children}</ProductsState.Provider>;
};

export default ProductsProvider;
