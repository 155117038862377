import React from 'react';

import SeatIcon from '@kiwicom/orbit-components/lib/icons/Seat';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

const SelectedSeats = ({ routeId }) => {
  const polyglot = usePolyglot();
  const { seatings } = useShoppingBasket();
  const segment = seatings[routeId];

  if (segment && segment.option) {
    return (
      <Inline spacing="XXSmall" align="center">
        <SeatIcon size="small" />
        <Text type="secondary">
          {polyglot.t(`tequila.ancillary_seating.mmb.no_seatmap.quick_option.${segment.option}`)}
        </Text>
      </Inline>
    );
  }

  return null;
};

export default SelectedSeats;
