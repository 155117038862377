import useUsersCompanyName from 'components/services/company/useUsersCompanyName';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';

const useInvalidDeposit = () => {
  const userCompany = useUsersCompanyName();
  const deposit = useCompanyDeposit(userCompany);

  return Boolean(deposit.error);
};

export default useInvalidDeposit;
