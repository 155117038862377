import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Box from '@kiwicom/orbit-components/lib/Box';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { usePolyglot } from 'components/services/i18n';
import useMMB from 'components/services/mmb/useMMB';
import useShoppingBasket from 'components/services/shoppingBasket/useShoppingBasket';

import RouteGroup from './components/RouteGroup';

const RouteGroups = ({ routeId, onSelect }) => {
  const polyglot = usePolyglot();
  const { bid } = useParams();
  const {
    bookingsState,
    ancillariesOffers: { data },
  } = useMMB();
  const {
    bookingData: {
      segments,
      ancillaries: { seating },
    },
    passengers,
  } = bookingsState[bid];
  const {
    payment: { error },
  } = useShoppingBasket();

  const routeGroups = useMemo(() => {
    const routes = {};

    seating.forEach(seatingItem => {
      seatingItem.items.forEach(item => {
        routes[item.segmentCode] = {
          availablePassengers:
            passengers.length -
            ((routes?.[item.segmentCode]?.availablePassengers || 0) + item.seats.length),
        };
      });
    });

    const routesKeys = Object.keys(routes);

    data.forEach(dataItem => {
      const hasOffer = routesKeys.find(routesKey => routesKey === dataItem.segmentCode);

      if (!hasOffer) {
        routes[dataItem.segmentCode] = {
          availablePassengers: passengers.length,
        };
      }
    });

    const ret = Object.values(segments).map(segment =>
      segment.filter(segmentItem => {
        const hasOffer = data.find(dataItem => dataItem.segmentCode === segmentItem.segmentCode);

        if (routes?.[segmentItem.segmentCode]?.availablePassengers && hasOffer) {
          return true;
        }

        return false;
      }),
    );

    return ret;
  }, [data, passengers.length, seating, segments]);

  return (
    <Box display="flex" direction="column" padding="large">
      <Heading type="title2" spaceAfter="medium">
        {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.title')}
      </Heading>
      <Text type="secondary" spaceAfter="medium">
        {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.subtitle')}
      </Text>
      {error && (
        <Alert
          type="critical"
          spaceAfter="medium"
          title={polyglot.t('tequila.ancillary_seating.mmb.drawer.alert.payment_error.title')}
        >
          {polyglot.t('tequila.ancillary_seating.mmb.drawer.alert.payment_error.subtitle')}
        </Alert>
      )}
      {routeGroups.map(routeGroup => {
        return (
          <>
            {routeGroup[routeGroup.length - 1]?.arrival && (
              <Text size="large" weight="bold" spaceAfter="small">
                {polyglot.t('tequila.ancillary_seating.mmb.drawer.route_groups.to_place.title', {
                  location: routeGroup[routeGroup.length - 1].arrival.place.city,
                })}
              </Text>
            )}
            {routeGroup.map(({ segmentCode, departure, arrival, carrier }) => (
              <RouteGroup
                cityFrom={departure.place.city}
                cityTo={arrival.place.city}
                airline={carrier.code}
                onClick={() => {
                  onSelect(segmentCode);
                }}
                id={segmentCode}
                selected={routeId === segmentCode}
              />
            ))}
          </>
        );
      })}
    </Box>
  );
};

export default RouteGroups;
