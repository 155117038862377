import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import { usePolyglot } from 'components/services/i18n';
import { containerSizes, previewTypeKeys } from 'consts/smartPass';

import {
  VoucherContainer,
  IconContainer,
  HeadingContainer,
  PrimaryTextContainer,
  ScanCodeContainer,
  SecondaryTextContainer,
  LogoImg,
  LogoImgContainer,
} from './StandardVoucher.styled';

const StandardVoucher = ({
  previewInfo: {
    discountText,
    location,
    terminalsList,
    firstName,
    pluralName,
    placeNames,
    url,
    scanCodeSvg,
    icon,
    smartpassLogoUrl,
    airportLogoUrl,
    isBaggageDeposit,
    isLoungeAccess,
  },
}) => {
  const polyglot = usePolyglot();

  const locatedAtText = (
    <PrimaryTextContainer>
      {!terminalsList.length ? (
        <Text>
          {polyglot.t('smart_pass.form.vouchers_preview_standard_location')}:{' '}
          {location && polyglot.t(location)} <br />(
          <Text as="span" weight="bold">
            {polyglot.t('smart_pass.form.vouchers_preview_all_terminals')}
          </Text>
          )
        </Text>
      ) : (
        <Text>
          {polyglot.t('smart_pass.form.vouchers_preview_standard_location')}:{' '}
          {location && polyglot.t(location)} <br />(
          {polyglot.t('smart_pass.form.vouchers_preview_standard_terminal')}{' '}
          <Text as="span" weight="bold">
            {terminalsList?.join(', ')}
          </Text>
          )
        </Text>
      )}
    </PrimaryTextContainer>
  );

  const availableAtText = (
    <PrimaryTextContainer>
      <Text>
        {polyglot.t('smart_pass.form.vouchers_preview_standard_availability')}:{' '}
        <Text as="span" weight="bold">
          {placeNames.join(', ')} {polyglot.t('smart_pass.form.vouchers_preview_standard_and_more')}
          .
        </Text>
      </Text>
    </PrimaryTextContainer>
  );

  let primaryText = null;
  if (isBaggageDeposit || isLoungeAccess) {
    primaryText = locatedAtText;
  } else if (placeNames.length > 1) {
    primaryText = availableAtText;
  }

  let placeNameText = null;
  if (placeNames.length === 1 && !isBaggageDeposit) placeNameText = placeNames[0];
  else if (isBaggageDeposit && firstName === '')
    placeNameText = polyglot.t('smart_pass.form.vouchers_preview_bag_deposit');
  else if ((isBaggageDeposit || isLoungeAccess) && firstName !== '') placeNameText = firstName;
  else if (placeNames.length > 1)
    placeNameText = `
    ${polyglot.t('smart_pass.form.vouchers_preview_standard_selected')} 
    ${polyglot.t(pluralName)}
    `;

  return (
    <VoucherContainer size={containerSizes[previewTypeKeys.STANDARD]} isStandard>
      <HeadingContainer>
        <Heading as="h1" type="title1">
          {discountText}
        </Heading>
        <Text type="primary">
          {polyglot.t('smart_pass.form.vouchers_preview_standard_discount')} {placeNameText}
        </Text>
      </HeadingContainer>
      {primaryText}
      <SecondaryTextContainer>
        <Text size="small" type="secondary">
          {polyglot.t('smart_pass.form.vouchers_preview_standard_receival_condition')}
          <br></br>
          {url && (
            <>
              {polyglot.t('smart_pass.form.vouchers_preview_standard_learn_more')}{' '}
              <Text as="span" weight="bold" size="small">
                {url}.
              </Text>
            </>
          )}
        </Text>
      </SecondaryTextContainer>
      <ScanCodeContainer>{scanCodeSvg}</ScanCodeContainer>
      <IconContainer>{icon}</IconContainer>
      <LogoImgContainer>
        <LogoImg src={smartpassLogoUrl} />
        <LogoImg src={airportLogoUrl} />
      </LogoImgContainer>
    </VoucherContainer>
  );
};

export default StandardVoucher;
