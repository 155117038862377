import React, { useContext, useState } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import Button from '@kiwicom/orbit-components/lib/Button';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';

import { CompanySettingsState } from 'components/services/companySettings';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';

import IPForm from './IPForm';
import IPSummary from './IPSummary';
import AddedAddressesTable from './AddedAddressesTable';

const WhitelistModal = ({ noWhitelistBefore, loading, onClose, data }) => {
  const companyName = useCurrentCompanyName();
  const { enableIPWhitelisting, addIPAddressesToWhitelist } = useContext(CompanySettingsState);
  const polyglot = usePolyglot();
  const [step, setStep] = useState(0);
  const [currentIp, setCurrentIp] = useState('');
  const [whitelistDraft, setWhitelistDraft] = useState([]);
  const errorAlert = useToggle();

  const handleRemoveIP = ip => {
    setWhitelistDraft(ips => ips.filter(i => i !== ip));
    errorAlert.setOff();
  };

  const handleAddIP = () => {
    const [, mask] = currentIp.split('/');
    const maskedIp = !!mask ? currentIp : currentIp + '/32';

    setWhitelistDraft(ips => [...ips, maskedIp]);
    setCurrentIp('');
    errorAlert.setOff();
  };

  const handlePrimaryButtonClick = async () => {
    errorAlert.setOff();

    try {
      if (noWhitelistBefore) {
        if (step === 0) {
          setStep(1);
        } else {
          await enableIPWhitelisting(companyName, whitelistDraft);
          onClose();
        }
      } else {
        await addIPAddressesToWhitelist(companyName, whitelistDraft);
        onClose();
      }
    } catch (error) {
      errorAlert.setOn();
    }
  };

  const handleSecondaryButtonClick = () => {
    errorAlert.setOff();
    if (step === 1) {
      setStep(0);
    } else {
      onClose();
    }
  };

  const getIsDuplicated = ip => {
    const [, mask] = ip.split('/');

    if (!mask) {
      ip += '/32';
    }

    return whitelistDraft?.includes(ip) || data?.includes(ip);
  };

  return (
    <Portal>
      <Modal onClose={onClose}>
        <ModalHeader
          title={
            noWhitelistBefore
              ? polyglot.t('allowlist.ip_allowlist')
              : polyglot.t('allowlist.add_ip')
          }
          description={
            noWhitelistBefore
              ? polyglot.t('allowlist.enable_description')
              : polyglot.t('allowlist.add_ip_description')
          }
        />

        <ModalSection>
          {errorAlert.isOn && (
            <Alert
              type="critical"
              title={polyglot.t('allowlist.submit_error')}
              spaceAfter="largest"
            />
          )}
          <Alert type="warning" title={polyglot.t('smart_pass.alert_title')} spaceAfter="largest">
            <List>
              <ListItem>{polyglot.t('allowlist.alert_description_1')}</ListItem>
              <ListItem>{polyglot.t('allowlist.alert_description_2')}</ListItem>
            </List>
          </Alert>

          {step === 0 ? (
            <>
              <IPForm
                value={currentIp}
                onChange={setCurrentIp}
                onAdd={handleAddIP}
                getIsDuplicated={getIsDuplicated}
              />

              {whitelistDraft.length > 0 && (
                <AddedAddressesTable values={whitelistDraft} onRemove={handleRemoveIP} />
              )}
            </>
          ) : (
            <IPSummary values={whitelistDraft} />
          )}
        </ModalSection>

        <ModalFooter>
          <Button type="secondary" size="large" onClick={handleSecondaryButtonClick}>
            {step === 1 ? 'Back' : 'Cancel'}
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={handlePrimaryButtonClick}
            loading={loading}
            disabled={!whitelistDraft.length}
          >
            {noWhitelistBefore && step === 0 ? 'Next' : 'Confirm'}
          </Button>
        </ModalFooter>
      </Modal>
    </Portal>
  );
};

export default WhitelistModal;
