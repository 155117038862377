import React, { useState, useEffect } from 'react';

import InputGroup from '@kiwicom/orbit-components/lib/InputGroup';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';

import { monthsOptions } from 'consts/dates';
import { usePolyglot } from 'components/services/i18n';

// Value should be date in ISO 8601 format
const DateInput = ({ value = '', label, name, setFieldValue, onBlur, ...props }) => {
  const [yearVal, monthVal, dayVal] = value.split('-');

  const [day, setDay] = useState(dayVal || '');
  const [month, setMonth] = useState(monthVal || '');
  const [year, setYear] = useState(yearVal || '');

  const handleChange = e => {
    if (e.target.id === `${name}-day`) {
      setDay(e.target.value);
    } else if (e.target.id === `${name}-month`) {
      setMonth(e.target.value);
    } else if (e.target.id === `${name}-year`) {
      setYear(e.target.value);
    }
  };

  useEffect(() => {
    if (!day && !month && !year) {
      setFieldValue(name, '');
    } else if (day && month && year && year.toString().length === 4) {
      setFieldValue(name, `${year}-${month}-${day.toString().padStart(2, 0)}`);
    } else {
      setFieldValue(name, '-');
    }
  }, [day, month, year, name, setFieldValue]);

  const polyglot = usePolyglot();

  return (
    <InputGroup
      onBlur={onBlur}
      onChange={handleChange}
      label={label}
      flex={['1', '3', '1.5']}
      {...props}
    >
      <InputField
        placeholder={polyglot.t('common.months.day_placeholder')}
        value={day}
        type="number"
        name={name}
        id={`${name}-day`}
        disabled={props.disabled}
      />
      <Select
        label={label}
        placeholder={polyglot.t('common.months.placeholder')}
        options={monthsOptions.map(o => ({ value: o.value, label: polyglot.t(o.label) }))}
        value={month}
        name={name}
        id={`${name}-month`}
        disabled={props.disabled}
      />
      <InputField
        placeholder={polyglot.t('common.months.year_placeholder')}
        value={year}
        type="number"
        name={name}
        id={`${name}-year`}
        disabled={props.disabled}
      />
    </InputGroup>
  );
};

export default DateInput;
