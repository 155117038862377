import React from 'react';

import Loading from '@kiwicom/orbit-components/lib/Loading';

import useWidgetsContext from 'components/services/widgets';
import useCompanyWidgetSolutions from 'components/services/widgets/useCompanyWidgetSolutions';

import WidgetsTable from './WidgetsTable';
import NoWidgetSolutionsInfo from './NoWidgetSolutionsInfo';
import CompanyWidgetsLoadingErrorAlert from './CompanyWidgetsLoadingErrorAlert';
import CompanySolutionsLoadingErrorAlert from './CompanySolutionsLoadingErrorAlert';

const TableCardSection = ({
  selectedCompany,
  showWidgetPreview,
  openCreateWidgetSolutionModal,
}) => {
  const {
    fetchCompanyWidgets,
    state: { loadingCompanyWidgets },
  } = useWidgetsContext();
  const { companyHasWidgetSolutions, companySolutionsLoading, companySolutionsLoadingError } =
    useCompanyWidgetSolutions(selectedCompany);

  if (companySolutionsLoadingError) {
    return <CompanySolutionsLoadingErrorAlert companyName={selectedCompany} />;
  }

  if (loadingCompanyWidgets.error) {
    return (
      <CompanyWidgetsLoadingErrorAlert
        companyName={selectedCompany}
        reload={() => fetchCompanyWidgets(selectedCompany)}
      />
    );
  }

  if (companySolutionsLoading || loadingCompanyWidgets.inProgress)
    return <Loading type="pageLoader" />;

  if (!companyHasWidgetSolutions) {
    return (
      <NoWidgetSolutionsInfo
        openCreateWidgetSolutionModal={openCreateWidgetSolutionModal}
        selectedCompany={selectedCompany}
      />
    );
  }

  return <WidgetsTable selectedCompany={selectedCompany} showWidgetPreview={showWidgetPreview} />;
};

export default TableCardSection;
