import React from 'react';
import { FieldArray } from 'formik';

import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import { usePolyglot } from 'components/services/i18n';
import { keys, voucherBagDepositServiceLocationValues, terminalsValues } from 'consts/smartPass';

import { LabelsRowWrapper } from '../index.styled';

const {
  VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION,
  VOUCHER_BAG_DEPOSIT_TERMINALS,
  VOUCHER_BAG_DEPOSIT_TERMINALS_LIST,
  VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE,
} = keys;

const BagDeposit = ({ handleChange, handleBlur, values, handleAddToList, errors, touched }) => {
  const polyglot = usePolyglot();

  return (
    <>
      <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.service_located')}</Text>
      <LabelsRowWrapper>
        <Radio
          label={polyglot.t('smart_pass.form.vouchers.service_located_arrival')}
          name={VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION}
          value={voucherBagDepositServiceLocationValues.ARRIVAL_AREA}
          checked={
            values[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION] ===
            voucherBagDepositServiceLocationValues.ARRIVAL_AREA
          }
          onChange={handleChange}
          hasError={
            touched[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION] &&
            errors[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]
          }
        />
        <Radio
          label={polyglot.t('smart_pass.form.vouchers.service_located_departure')}
          name={VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION}
          value={voucherBagDepositServiceLocationValues.DEPARTURE_AREA}
          checked={
            values[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION] ===
            voucherBagDepositServiceLocationValues.DEPARTURE_AREA
          }
          onChange={handleChange}
          hasError={
            touched[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION] &&
            errors[VOUCHER_BAG_DEPOSIT_SERVICE_LOCATION]
          }
        />
      </LabelsRowWrapper>
      <Text spaceAfter="small">{polyglot.t('smart_pass.form.vouchers.bag_deposit')}</Text>
      <Stack spaceAfter="large">
        <Radio
          label={polyglot.t('smart_pass.form.all_terminals')}
          name={VOUCHER_BAG_DEPOSIT_TERMINALS}
          checked={values[VOUCHER_BAG_DEPOSIT_TERMINALS] === terminalsValues.ALL_TERMINALS}
          value={terminalsValues.ALL_TERMINALS}
          onChange={handleChange}
          hasError={touched[VOUCHER_BAG_DEPOSIT_TERMINALS] && errors[VOUCHER_BAG_DEPOSIT_TERMINALS]}
        />
        <LabelsRowWrapper labelWidth="200px">
          <Radio
            name={VOUCHER_BAG_DEPOSIT_TERMINALS}
            checked={values[VOUCHER_BAG_DEPOSIT_TERMINALS] === terminalsValues.SPECIFIC_TERMINALS}
            value={terminalsValues.SPECIFIC_TERMINALS}
            onChange={handleChange}
            label={polyglot.t('smart_pass.form.specific_terminals')}
            hasError={
              touched[VOUCHER_BAG_DEPOSIT_TERMINALS] && errors[VOUCHER_BAG_DEPOSIT_TERMINALS]
            }
          />
          <FieldArray name={VOUCHER_BAG_DEPOSIT_TERMINALS_LIST}>
            {({ remove, push }) => (
              <InputField
                disabled={
                  values[VOUCHER_BAG_DEPOSIT_TERMINALS] !== terminalsValues.SPECIFIC_TERMINALS
                }
                name={VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE}
                value={values[VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE]}
                onChange={handleChange}
                onBlur={handleBlur}
                inlineLabel
                onKeyUp={handleAddToList(push, VOUCHER_BAG_DEPOSIT_TERMINALS_NEW_VALUE)}
                label={polyglot.t('smart_pass.form.specific_terminals_label')}
                placeholder={polyglot.t('smart_pass.form.press_enter_to_add_terminal')}
                error={
                  errors[VOUCHER_BAG_DEPOSIT_TERMINALS_LIST] &&
                  polyglot.t(errors[VOUCHER_BAG_DEPOSIT_TERMINALS_LIST])
                }
                tags={
                  values[VOUCHER_BAG_DEPOSIT_TERMINALS_LIST].length > 0
                    ? values[VOUCHER_BAG_DEPOSIT_TERMINALS_LIST].map((tag, index) => (
                        <Tag key={index} selected onRemove={() => remove(index)}>
                          {tag}
                        </Tag>
                      ))
                    : undefined
                }
              />
            )}
          </FieldArray>
        </LabelsRowWrapper>
      </Stack>
      <Separator spaceAfter="medium" />
    </>
  );
};

export default BagDeposit;
