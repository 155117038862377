import React, { useContext } from 'react';

import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Text from '@kiwicom/orbit-components/lib/Text';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { Authorization } from 'common/Authorization';
import { CompanySettingsState } from 'components/services/companySettings';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';

import WhitelistModal from './WhitelistModal';

const NoData = () => {
  const companyName = useCurrentCompanyName();
  const { state } = useContext(CompanySettingsState);
  const whitelistModal = useToggle();
  const polyglot = usePolyglot();

  return (
    <Stack direction="column" align="center" spacing="none">
      {whitelistModal.isOn && (
        <WhitelistModal
          data={[]}
          noWhitelistBefore
          loading={state[companyName]?.updating}
          onClose={whitelistModal.setOff}
        />
      )}
      <Illustration name="Insurance" size="large" spaceAfter="largest" />

      <Text weight="bold" size="large" spaceAfter="small">
        {polyglot.t('allowlist.ip_allowlist')}
      </Text>

      <Text size="large" spaceAfter="large">
        {polyglot.t('allowlist.enable_description_2')}
      </Text>

      <Authorization
        resource={'model.company_settings'}
        action={['update', 'update_own', 'update_assigned']}
        companyName={companyName}
      >
        <Button size="small" spaceAfter="largest" onClick={whitelistModal.setOn}>
          {polyglot.t('allowlist.enable_2')}
        </Button>
      </Authorization>
    </Stack>
  );
};

export default NoData;
