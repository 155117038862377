import React from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { useToggle } from 'utils/hooks';
import { useCurrentUser } from 'components/services/auth';
import { usePolyglot } from 'components/services/i18n';

import IllustratedCard from './IllustratedCard';
import { BankAccountModal } from 'common';

const TextWrapper = styled.div`
  max-width: 400px;

  margin-bottom: 30px;
`;

const Nowrap = styled.div`
  white-space: nowrap;
`;

const AddBankAccount = ({ bankAccounts, hasBankAccounts }) => {
  const polyglot = usePolyglot();
  const modal = useToggle();
  const user = useCurrentUser();

  if (bankAccounts.loading) {
    return (
      <Card loading>
        <CardSection />
      </Card>
    );
  }

  return (
    <>
      {modal.isOn && <BankAccountModal onClose={modal.setOff} companyName={user.companyName} />}

      <IllustratedCard
        title={polyglot.t('getting_started.add_bank_account.title')}
        illustrationName="PlaneAndMoney"
        type={hasBankAccounts ? 'secondary' : 'primary'}
        dataTest="card-bank_accounts"
      >
        <TextWrapper>
          <Text type={hasBankAccounts ? 'secondary' : 'primary'}>
            {polyglot.t('getting_started.add_bank_account.description')}
          </Text>
        </TextWrapper>

        <Stack direction="row" spacing="medium" align="center" grow={0}>
          <Button type="primary" size="small" onClick={modal.setOn} disabled={hasBankAccounts}>
            {polyglot.t('getting_started.add_bank_account.button')}
          </Button>

          {hasBankAccounts && (
            <Nowrap>
              <Text type="info" size="small">
                {polyglot.t('getting_started.add_bank_account.done_text')}
              </Text>
            </Nowrap>
          )}
        </Stack>
      </IllustratedCard>
    </>
  );
};

export default AddBankAccount;
