import React, { useContext } from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';

const DescriptionWrapper = styled.div`
  word-wrap: break-word;
`;

const DeleteVoucherModal = () => {
  const { deleteVoucherModalInfo, hideDeleteVoucherModal } = useContext(SmartPassContext);
  const { remove, index, name } = deleteVoucherModalInfo;
  const polyglot = usePolyglot();

  const handleDeleteClick = () => {
    remove(index);
    hideDeleteVoucherModal();
  };
  return (
    <Modal size="small" closable onClose={hideDeleteVoucherModal}>
      <ModalHeader
        title={polyglot.t('smart_pass.form.vouchers.modal_title')}
        description={
          <DescriptionWrapper>
            {polyglot.t('smart_pass.form.vouchers.modal_description', {
              name: name || polyglot.t('common.unknown'),
            })}
          </DescriptionWrapper>
        }
      />
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button onClick={hideDeleteVoucherModal} type="criticalSubtle">
            {polyglot.t('common.cancel')}
          </Button>
          <Button type="critical" onClick={handleDeleteClick}>
            {polyglot.t('common.delete')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteVoucherModal;
