import * as R from 'ramda';

const v1_get_from = report => {
  const formattedData = report.data?.map(data => mapDataFromApiFromat(data));

  return {
    rows: formattedData || [],
    totals: report.totals ? mapDataFromApiFromat(report.totals) : null,
    metrics: R.keys(formattedData?.[0]),
    interval: report.instance.report_config.interval,
    reportAliasId: report.instance.report_alias_id,
    isCompare: report.instance.report_config.compare_with ? true : false,
    dateRange: report.instance.report_config.date_range,
    compareRange: report.instance.report_config.compare_with,
  };
};

const mapDataFromApiFromat = data => {
  return R.reject(value => value === undefined, {
    interval: data.interval,
    affiliate: data.affiliate,
    clicks: data.clicks,
    bookings: data.bookings,
    sales: data.sales,
    payout: data.payout,
    profit: data.profit,
    revenue: data.revenue,
    market: data.market,
    device: data.device,
    platform: data.platform,
    conversionRate: data.conversion_rate,
    payoutType: data.payout_type,
    cpsFlat: data.cps_flat,
    cpsPercent: data.cps_percent,
    cpcFlat: data.cpc_flat,
    legCount: data.leg_count,
    routeType: data.route_type,
    bookedAt: data.booked_at,
    bookingId: data.booking_id,
    bookingIdTimestamp: data.booking_id_timestamp,
    offerId: data.offer_id,
    finalStatus: data.final_status,
  });
};

const v2_get_to = params => ({
  params: {
    ...(params?.page && { page: params.page }),
    ...(params?.perPage && { per_page: params.perPage }),
    ...(params?.sort && { sort: params.sort }),
  },
});

const v2_get_from = reports => {
  const firstItem = reports[0];
  const headCells = [];

  for (const key in firstItem) {
    if (Object.prototype.hasOwnProperty.call(firstItem, key)) {
      headCells.push({
        label: `new_reports.report_form.metrics.${key}`,
        value: key,
      });
    }
  }

  return {
    tableRows: reports,
    tableHeadCells: headCells,
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
  v2: {
    get: {
      to: v2_get_to,
      from: v2_get_from,
    },
  },
};
