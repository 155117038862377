import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Tag from '@kiwicom/orbit-components/lib/Tag';

import { AutocompleteItem, AutocompleteValue } from 'shapes/Input';
import { Input, Wrapper, Label, Space } from 'common';

import { AutocompleteGlobals, WrapperContainer } from './AutocompleteMulti.styled';

/**
 * AutocompleteMulti component is internal component (have to used just in InputAutocomplete) used by InputAutocomplete
 * It's composed by Input component and list of Chip components. These components are wrapped
 * in Wrapper component that handle common style for all inputs
 * Chips represent selected value in autocompleter
 */
class AutocompleteMulti extends PureComponent {
  constructor(props) {
    super(props);
    this.focusedChip = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const { id, values, focusedChipIndex } = this.props;
    const container = document.getElementById(`${id}-container`);

    const areValuesChanged = prevProps.values !== values;
    const isFocusedChipIndexChanged = prevProps.focusedChipIndex !== focusedChipIndex;
    const areChipsFocused = focusedChipIndex !== null;
    const isAnyValueRemoved = prevProps.values.length > values.length;

    if ((areValuesChanged && prevProps.values.length < values.length) || !areChipsFocused) {
      // jump to the right (so last added value and input is shown)
      container.scrollLeft = container.scrollWidth;
    } else if (
      // jump left or right if focused chip is not visible
      (isFocusedChipIndexChanged || isAnyValueRemoved) &&
      this.focusedChip.current !== null
    ) {
      const focusedChipNode = ReactDOM.findDOMNode(this.focusedChip);
      if (focusedChipNode) {
        const containerCoordinates = container.getBoundingClientRect();
        const focusedChipCoordinates = focusedChipNode.getBoundingClientRect();

        const isChipTooLeft = focusedChipCoordinates.left < containerCoordinates.left;
        const isChipTooRight = focusedChipCoordinates.right > containerCoordinates.right;

        if (isChipTooLeft) {
          container.scrollLeft =
            container.scrollLeft - (containerCoordinates.left - focusedChipCoordinates.left);
        } else if (isChipTooRight) {
          container.scrollLeft =
            container.scrollLeft + (focusedChipCoordinates.left - containerCoordinates.left);
        }
      }
    }
  };

  handleChange = ev => {
    this.props.onChange(ev.target.value);
  };

  render() {
    const {
      id,
      values,
      value,
      placeholder,
      className = '',
      wrapperClassName = '',
      onKeyDown,
      onFocus,
      onRemove,
      label,
      size,
      Icon,
      disabled,
      focusedChipIndex,
      onInputClick,
      inputWrapperClassName = '',
      containerClassName = '',
      isFocused,
    } = this.props;

    return (
      <>
        <AutocompleteGlobals />

        {label && <Label htmlFor={id} text={label} />}
        <WrapperContainer>
          <Wrapper
            size={size}
            containerClassName="autocomplete-input-wrapper"
            className={wrapperClassName}
          >
            <div className={`autocomplete-container ${containerClassName}`} id={`${id}-container`}>
              {values.map((v, i) => (
                <Space key={i} before="xs" after="xs" left="s" right="s">
                  <Tag
                    onRemove={() => onRemove(v.label)}
                    selected={i === focusedChipIndex}
                    icon={v.icon}
                    size={size}
                    innerRef={node => {
                      this.focusedChip = i === focusedChipIndex ? node : this.focusedChip;
                    }}
                  >
                    {v.label}
                  </Tag>
                </Space>
              ))}
              <Wrapper
                size={size}
                border={false}
                containerClassName="autocomplete-input-wrapper"
                className={inputWrapperClassName}
              >
                <Input
                  id={id}
                  key={id}
                  className={`${className} autocomplete-input`}
                  onChange={this.handleChange}
                  onFocus={onFocus}
                  onKeyDown={onKeyDown}
                  onClick={onInputClick}
                  placeholder={R.isEmpty(values) ? placeholder : ''}
                  value={value}
                  disabled={disabled}
                  autoFocus={isFocused}
                />
              </Wrapper>
            </div>
            {Icon && <Icon />}
          </Wrapper>
        </WrapperContainer>
      </>
    );
  }
}

AutocompleteMulti.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape(AutocompleteValue)).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  Icon: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  items: PropTypes.arrayOf(PropTypes.shape(AutocompleteItem)),
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  focusedChipIndex: PropTypes.number,
  onInputClick: PropTypes.func,
  chipsClassName: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  isFocused: PropTypes.bool,
};

export default AutocompleteMulti;
