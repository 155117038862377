import styled from 'styled-components';

export const QuickOptionWrapper = styled.div`
  padding: ${({ theme }) => theme.orbit.spaceMedium};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.orbit.backgroundBadgeWhite};
  box-shadow: ${({ theme }) => theme.orbit.boxShadowAction};
  border-radius: 3px;
  border: 2px solid
    ${({ theme, isChecked }) => (isChecked ? theme.orbit.backgroundButtonInfo : 'transparent')};

  @media (max-width: 1400px) {
    width: calc(100% - 32px);
  }
`;

export const RadioWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`;
