import styled from 'styled-components';

import { TableFoot } from 'common';

export const StyledTableFoot = styled(TableFoot)`
  font-weight: 700;
  color: ${({ theme }) => theme.orbit.colorTextAttention};
`;

export const TotalValues = styled.span`
  font-weight: 400;
`;
