import React from 'react';
import styled from 'styled-components';

import Text from '@kiwicom/orbit-components/lib/Text';
import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';

const AlertContainer = styled.div`
  width: 680px;
  margin: 0 auto;
`;

const WidgetLoadingErrorAlert = ({ reload, goBack }) => {
  const polyglot = usePolyglot();
  return (
    <AlertContainer>
      <Alert icon title={polyglot.t('widgets.fetching_widget_error_title')} type="critical">
        <Text spaceAfter="medium">{polyglot.t('widgets.fetching_widget_error')}</Text>
        <Stack direction="row" justify="start">
          <AlertButton size="small" type="critical" onClick={goBack}>
            {polyglot.t('widgets.go_back')}
          </AlertButton>
          <AlertButton size="small" type="criticalSubtle" onClick={reload}>
            {polyglot.t('widgets.try_again')}
          </AlertButton>
        </Stack>
      </Alert>
    </AlertContainer>
  );
};

export default WidgetLoadingErrorAlert;
