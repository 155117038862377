import React from 'react';
import { useFormContext, Controller } from 'react-hook-form/dist/index.ie11';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Heading from '@kiwicom/orbit-components/lib/Heading';

import { businessTypeOptions, companySizeOptions } from 'consts/register';
import { SelectAccountManagers, SelectCountry } from 'common';
import { usePolyglot } from 'components/services/i18n';
import Checkbox from 'components/common/Checkbox';
import Select from 'components/common/Select';
import { countriesWithICO } from 'consts/countries';

const BusinessAccountForm = ({ canAccountManager, canTaxId }) => {
  const polyglot = usePolyglot();
  const { control, errors, watch } = useFormContext();

  const businessType = watch('businessType');
  const country = watch('address.country');

  return (
    <>
      <Controller
        control={control}
        name="legalName"
        render={({ onChange, onBlur, value }) => {
          return (
            <InputField
              label={polyglot.t('company.legal_name')}
              size="small"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={polyglot.t(errors.legalName?.message)}
            />
          );
        }}
      />

      <Checkbox label={polyglot.t('company.cz_tax_payer')} name="czTaxPayer" control={control} />

      <Stack direction="row">
        <Select
          label={polyglot.t('register.business_type')}
          placeholder={polyglot.t('register.business_type.placeholder')}
          name="businessType"
          size="small"
          options={businessTypeOptions.map(option => ({
            value: option.value,
            label: polyglot.t(option.label),
          }))}
        />
        <Select
          name="companySize"
          label={polyglot.t('register.company_size')}
          size="small"
          placeholder={polyglot.t('register.company_size.placeholder')}
          options={companySizeOptions}
        />
      </Stack>
      {businessType === 'Other' && (
        <Stack>
          <Controller
            control={control}
            name="businessTypeOther"
            render={({ onChange, onBlur, value }) => {
              return (
                <InputField
                  size="small"
                  label={polyglot.t('register.business_type.other.placeholder')}
                  value={value}
                  error={polyglot.t(errors?.businessTypeOther?.message)}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </Stack>
      )}

      <Stack direction="row">
        <Controller
          control={control}
          name="address.address1"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.address')}
                value={value}
                error={polyglot.t(errors.address?.address1?.message)}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
        <Controller
          name="address.country"
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <SelectCountry
                dataTest="country"
                placeholder={polyglot.t('register.select_country')}
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value.toLowerCase()}
                error={polyglot.t(errors.address?.country?.message)}
              />
            );
          }}
        />
      </Stack>

      <Stack direction="row">
        <Controller
          control={control}
          name="vatId"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.vat_id')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.vatId?.message)}
              />
            );
          }}
        />

        {canTaxId && (
          <Controller
            control={control}
            name="taxId"
            render={({ onChange, onBlur, value }) => {
              return (
                <InputField
                  size="small"
                  label={polyglot.t(
                    countriesWithICO.includes(country) ? 'company.tax_id_cz' : 'company.tax_id',
                  )}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={polyglot.t(errors.taxId?.message)}
                />
              );
            }}
          />
        )}
      </Stack>

      <Stack direction="row">
        <Controller
          control={control}
          name="address.city"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.city')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.address?.city?.message)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="address.zip"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('company.zip')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.address?.zip?.message)}
              />
            );
          }}
        />
      </Stack>

      <Controller
        control={control}
        name="url"
        render={({ onChange, onBlur, value }) => {
          return (
            <InputField
              size="small"
              label={polyglot.t('smart_pass.form.vouchers.url_label')}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={polyglot.t(errors?.url?.message)}
            />
          );
        }}
      />

      <Heading type="title2">{polyglot.t('register.majority_stakeholder_information')}</Heading>

      <Stack direction="row">
        <Controller
          control={control}
          name="majorityStakeholder.firstName"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('common.first_name')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.majorityStakeholder?.firstName?.message)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="majorityStakeholder.lastName"
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                size="small"
                label={polyglot.t('common.last_name')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={polyglot.t(errors.majorityStakeholder?.lastName?.message)}
              />
            );
          }}
        />
      </Stack>
      {canAccountManager && (
        <Controller
          name="accountManager"
          control={control}
          render={({ onChange, onBlur, value }) => {
            return (
              <SelectAccountManagers
                size="small"
                name="accountManager"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={polyglot.t(errors?.accountManager?.message)}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default BusinessAccountForm;
