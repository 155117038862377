import * as R from 'ramda';

import format from 'date-fns/format';
import isBeforeDfs from 'date-fns/isBefore';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import subQuarters from 'date-fns/subQuarters';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import differenceInHours from 'date-fns/differenceInHours';

import * as datesConstants from 'consts/dates';

/**
 * Get first date from array in some format
 * @param {Date[]} dates
 * @param {string} [dateFormat='dd. MM. yyyy']
 * @returns {string}
 */
export const getFirstDate = (dates, dateFormat = 'dd. MM. yyyy') => {
  return !R.isEmpty(dates) && format(new Date(R.head(dates)), dateFormat);
};

/**
 * Get last date from array in some format
 * @param {Date[]} dates
 * @param {string} [dateFormat='dd. MM. yyyy']
 * @returns {string}
 */
export const getLastDate = (dates, dateFormat = 'dd. MM. yyyy') =>
  !R.isEmpty(dates) && format(new Date(R.last(dates)), dateFormat);

/**
 * Get yesterday
 * @param {Date} [date=new Date()]
 * @returns {Date}
 */
export const getYesterday = (today = new Date()) => subDays(new Date(today), 1);

/**
 * Get last n days
 * @param {number} n
 * @param {Date} [date=new Date()]
 * @returns {Date[]}
 */
export const getLastXDays = (n, today = new Date()) =>
  eachDayOfInterval({ start: subDays(new Date(today), n), end: subDays(new Date(today), 1) });

/**
 * Get last n days
 * @param {number} n
 * @param {Date} [date=new Date()]
 * @returns {Date[]}
 */
export const getNextXDays = (n, today = new Date()) =>
  eachDayOfInterval({ start: new Date(today), end: addDays(new Date(today), n) });
/**
 * Get days from last week
 * @param {Date} [date=new Date()]
 * @returns {Date[]}
 */
export const getLastWeek = (today = new Date()) =>
  eachDayOfInterval({
    start: startOfWeek(subWeeks(new Date(today), 1)),
    end: endOfWeek(subWeeks(new Date(today), 1)),
  });

/**
 * Get days from last month
 * @param {Date} [date=new Date()]
 * @returns {Date[]}
 */
export const getLastMonth = (date = new Date()) =>
  eachDayOfInterval({
    start: startOfMonth(subMonths(new Date(date), 1)),
    end: endOfMonth(subMonths(new Date(date), 1)),
  });

/**
 * Get all days from previous period
 * @param {Date[]} period
 * @returns {Date[]}
 */
export const getPreviousPeriod = period => {
  const length = R.length(period);
  return eachDayOfInterval({
    start: subDays(new Date(R.head(period)), length),
    end: subDays(new Date(R.last(period)), length),
  });
};

/**
 * Get all days from period from previous year
 * @param {Date[]} period
 * @returns {Date[]}
 */
export const getPreviousYear = period =>
  eachDayOfInterval({
    start: subYears(new Date(R.head(period)), 1),
    end: subYears(new Date(R.last(period)), 1),
  });

/**
 * Format interval e.g. format week/month, differently etc...
 * @param {Date} date
 * @param {interval} string
 * @returns {string}
 */

export const formatInterval = (date, interval) => {
  if (!date) return;

  switch (interval) {
    case datesConstants.HOUR:
      return format(new Date(date), datesConstants.HOUR_INTERVAL_FORMAT);
    case datesConstants.DAY:
      return format(new Date(date), datesConstants.DAY_INTERVAL_FORMAT);
    case datesConstants.WEEK:
      return `${format(new Date(date), datesConstants.DAY_INTERVAL_FORMAT)} - ${format(
        endOfWeek(new Date(date), { weekStartsOn: 1 }),
        `${datesConstants.DAY_INTERVAL_FORMAT} - I`,
      )}`;
    case datesConstants.MONTH:
      return format(new Date(date), datesConstants.MONTH_INTERVAL_FORMAT);
    case datesConstants.YEAR:
      return format(new Date(date), datesConstants.YEAR_INTERVAL_FORMAT);
    case datesConstants.FULL_DATE:
      return format(new Date(date), datesConstants.FULL_DATE_FORMAT);
    case datesConstants.DATE:
      return format(new Date(date), datesConstants.DATE_INTERVAL_FORMAT);
    case datesConstants.MILLENNIUM:
      return 'None';
    default:
      return format(new Date(date), datesConstants.DAY_INTERVAL_FORMAT);
  }
};

/**
 * getDateRange will take array of dates and create interval (string) from that array
 * @param {Date[]} dates
 * @param {string} value
 * @returns {string}
 */
export const getDateRange = (dates, value = '') => {
  if (R.isNil(dates) || R.isEmpty(dates)) {
    return value;
  }
  return `${getFirstDate(dates, datesConstants.DATE_INTERVAL_FORMAT)} - ${getLastDate(
    dates,
    datesConstants.DATE_INTERVAL_FORMAT,
  )}`;
};

export const getDateRangeFromConstant = dateRange => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  switch (dateRange) {
    case datesConstants.YESTERDAY:
      return {
        dateFrom: yesterday,
        dateTo: yesterday,
      };
    case datesConstants.LAST_7_DAYS:
      return {
        dateFrom: subDays(today, 7),
        dateTo: yesterday,
      };
    case datesConstants.LAST_28_DAYS:
      return {
        dateFrom: subDays(today, 28),
        dateTo: yesterday,
      };
    case datesConstants.LAST_90_DAYS:
      return {
        dateFrom: subDays(today, 90),
        dateTo: yesterday,
      };
    default:
      return {};
  }
};

export const getEachDayForDateRangeConstant = dateRange => {
  const dates = getDateRangeFromConstant(dateRange);
  return eachDayOfInterval({ start: dates.dateFrom, end: dates.dateTo });
};

export const UTCToLocalDate = utcDate => {
  const date = typeof utcDate === 'string' ? new Date(utcDate) : utcDate;

  const newDate = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);

  return newDate;
};

export const getLastQuarterRange = () => {
  const currentDate = new Date();
  const priorDate = subQuarters(currentDate, 1);

  const from = format(startOfQuarter(priorDate), 'yyyy-MM-dd');
  const to = format(endOfQuarter(priorDate), 'yyyy-MM-dd');

  return { from, to };
};

export const getWidgetTypeDateString = datesArr => {
  let dateString = format(new Date(datesArr[0]), 'yyyy-MM-dd');

  if (datesArr.length > 1) {
    dateString += `_${format(new Date(datesArr[datesArr.length - 1]), 'yyyy-MM-dd')}`;
  }
  return dateString;
};

export const getDateFromWidgetString = dateString => {
  if (!dateString) return undefined;
  const dates = dateString.split('_');
  if (dates.length > 1) {
    return eachDayOfInterval({ start: new Date(dates[0]), end: new Date(dates[1]) });
  }
  return [new Date(dates[0])];
};

export const formatTripDuration = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  return [hours && `${hours}h`, minutes && `${minutes}m`].filter(Boolean).join(' ');
};

export const formatBirthDate = date => {
  return format(new Date(date), 'dd-MM-yyyy');
};

export const calculateNightsLayover = (start, end) => {
  if (differenceInHours(new Date(end), new Date(start)) > 8) {
    return Math.round(differenceInHours(new Date(end), new Date(start)) / 24);
  }
  return null;
};

export const isBefore = (start, end) => {
  return isBeforeDfs(new Date(start), new Date(end));
};
