import React from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableCell } from '@kiwicom/orbit-components/lib/Table';

import { formatReportValues } from 'pages/Reports/utils';
import Polyglot from 'shapes/Polyglot';

const ReportDataRows = ({ report, polyglot }) => (
  <>
    {report.rows.map((row, indexData) => (
      <TableRow key={indexData}>
        {report.metrics.map((key, indexKey) => (
          <TableCell key={indexKey}>{formatReportValues(key, row[key], report.interval)}</TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

ReportDataRows.propTypes = {
  report: PropTypes.object.isRequired,
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

export default ReportDataRows;
