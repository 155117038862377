import styled from 'styled-components';

import { Space, Flex } from 'common';

export const DirectionSwitchContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

export const SearchbarContainer = styled(Flex)`
  max-width: 880px;
  position: relative;
  & > ${Space} {
    flex: 1;
    max-width: 436px;
  }
  & > ${Space} > div {
    width: 100%;
  }
`;

export const ReturnWrapper = styled(Space)`
  display: ${props => (props.oneway ? '' : 'flex')};
  flex-direction: row;

  & > div {
    width: 320px;
  }

  & > button {
    width: 56px;
  }
`;
