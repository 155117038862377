import styled from 'styled-components';
import { FakeButton } from './Filters.styled';

export const DropdownContainer = styled.div`
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  border-radius: 3px;
  cursor: auto;
  z-index: 1000;

  .auditLogsDay {
    border: none;

    & > button {
      &:disabled {
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:hover {
          background: ${({ theme }) => theme.orbit.backgroundButtonPrimary};
          color: white;
        }
      }
    }
  }

  .auditLogsButton {
    border: none;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }
`;

export const DatePickerWrapper = styled.div`
  padding: 12px;
  border-right: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
`;

export const PredefinedWrapper = styled.div`
  padding: 24px;
  max-width: 185px;
  box-sizing: border-box;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};

  & > div {
    display: flex;

    button {
      margin-left: 8px;
    }
  }
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 12px;
`;

export const FakeInputButton = styled(FakeButton)`
  & > div {
    & > div {
      max-width: 150px;
    }
  }
`;
