const baggageErrorMessage = {
  handBag: {
    adult: 'Personal items and cabin baggage are not available for this booking.',
    teen: 'Personal items and cabin baggage are not available for teenage passengers.',
    child: 'Personal items and cabin baggage are not available for child passengers.',
    infant: 'Personal items and cabin baggage are not available for infant passengers.',
  },
  holdBag: {
    adult: 'Checked baggage is not available for this booking.',
    teen: 'Checked baggage is not available for teenage passengers.',
    child: 'Checked baggage is not available for child passengers.',
    infant: 'Checked baggage is not available for infant passengers.',
  },
};

export { baggageErrorMessage };
