import expand from 'utils/expand';
import api from 'utils/api';
import reportInstanceMapper from 'mappers/nextGen/reports/instances/_';
import reportMapper from 'mappers/nextGen/reports/instances/{report_instance_id}/_';
import { REPORT_STATUS } from 'consts/reports';
import { FIRST_PAGE } from 'consts/pagination';
const getActionTypes = expand('reports');

const { LOAD_REPORT, LOAD_REPORT_SUCCESS, LOAD_REPORT_FAIL } = getActionTypes('LOAD_REPORT');

const { STOP_POLLING } = getActionTypes('STOP_POLLING');

const initialState = {
  loading: false,
  data: null,
  error: null,
  shouldPollReport: true,
  pagination: {
    page: FIRST_PAGE.page,
    perPage: FIRST_PAGE.perPage,
    totalCount: 0,
    pagesCount: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case LOAD_REPORT_SUCCESS:
      return {
        ...state,
        data: action.report,
        pagination: action.pagination,
        loading: false,
      };
    case LOAD_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case STOP_POLLING:
      return {
        ...state,
        shouldPollReport: false,
      };
    default:
      return state;
  }
}

let pollTimeoutId;

export const loadReport = (reportAliasId, params) => (dispatch, getState) => {
  const state = getState();

  dispatch({ type: LOAD_REPORT });
  return api.v1
    .post('reports/instances', reportInstanceMapper.v1.post.to(reportAliasId))
    .then(response => {
      const reportInstance = reportInstanceMapper.v1.post.from(response.data);

      if (reportInstance.reportStatus === REPORT_STATUS.AVAILABLE) {
        if (state.reports.shouldPollReport) {
          dispatch(stopPollingReport());
        }

        api.v1
          .get(`reports/instances/${reportInstance.reportId}`, {
            params: {
              page: params.page,
              per_page: params.perPage,
              sort: params.sort,
            },
          })
          .then(dataResponse => {
            const page = params.page;
            const report = reportMapper.v1.get.from(dataResponse.data);
            dispatch({
              type: LOAD_REPORT_SUCCESS,
              report: report,
              pagination: {
                page,
                pagesCount: Number(dataResponse.headers?.['x-pagination-page-count']),
                perPage: Number(dataResponse.headers?.['x-pagination-per-page']),
                totalCount: Number(dataResponse.headers?.['x-pagination-row-count']),
              },
            });
          })
          .catch(err => {
            return dispatch({ type: LOAD_REPORT_FAIL, error: err });
          });
      } else {
        pollTimeoutId = setTimeout(() => {
          return dispatch(loadReport(reportAliasId, params));
        }, 5000);
      }
    })
    .catch(err => {
      return dispatch({ type: LOAD_REPORT_FAIL, error: err });
    });
};

export const stopPollingReport = () => dispatch => {
  clearTimeout(pollTimeoutId);
  return dispatch({ type: STOP_POLLING });
};
