import styled from 'styled-components';

export const IconWrapper = styled.div`
  & > svg {
    width: 14px;
    height: 14px;
  }
`;

export const KebabMenuWrapper = styled.button`
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: transparent;
  transition: 0.15s ease;

  &:hover {
    background-color: ${({ theme }) => theme.orbit.paletteCloudNormal};
  }
`;
