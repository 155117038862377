import * as R from 'ramda';
import { COMPANY_TYPES } from 'consts/company';
import { companySizeOptions } from 'consts/register';
import { countriesWithICO } from 'consts/countries';

const v1_post_to = ({
  firstName,
  lastName,
  dateOfBirth,
  nationality,
  email,
  companyName,
  companyEmail,
  companySize,
  businessType,
  businessTypeOther,
  legalName,
  country,
  address1,
  city,
  zip,
  isPrimary,
  companyWebsite,
  type,
  czTaxPayer,
  vatId,
  taxId,
  stakeholderFirstName,
  stakeholderLastName,
  stakeholderDateOfBirth,
  stakeholderNationality,
  accountName,
  dic,
  ico,
  utm_source,
  token,
  marketingConsent,
}) => {
  const countryWithICO = countriesWithICO.includes(country);
  const displayName = (companyName || accountName)
    .toLowerCase()
    .replace(/\W+/g, '')
    .replace(/_/g, '');

  let majorityStakeholder = null;

  if (type === COMPANY_TYPES.COMPANY && stakeholderFirstName && stakeholderLastName) {
    majorityStakeholder = {
      first_name: stakeholderFirstName,
      last_name: stakeholderLastName,
    };
    if (Boolean(stakeholderDateOfBirth)) {
      majorityStakeholder.date_of_birth = stakeholderDateOfBirth;
    }
    if (Boolean(stakeholderNationality)) {
      majorityStakeholder.nationality = stakeholderNationality.toLowerCase();
    }
  }

  const owner =
    type === COMPANY_TYPES.PERSON
      ? {
          date_of_birth: dateOfBirth,
          first_name: firstName,
          last_name: lastName,
          nationality: nationality.toLowerCase(),
        }
      : null;

  return JSON.stringify({
    company: R.reject(value => R.isNil(value) || value === '', {
      account_type: type,
      address: {
        address1,
        city,
        country: country,
        is_primary: isPrimary,
        zip: zip.trim(),
      },
      business_type: businessType === 'Other' ? businessTypeOther : businessType,
      company_size: type === COMPANY_TYPES.COMPANY ? companySize : companySizeOptions[0].value,
      cz_taxpayer: czTaxPayer,
      display_name: displayName,
      email: companyEmail || email,
      legal_name: legalName,
      majority_stakeholder: majorityStakeholder,
      owner: owner,
      tax_id: countryWithICO ? dic : '',
      url: companyWebsite,
      utm_source,
      vat_id: countryWithICO ? ico : vatId,
    }),
    marketing_consent: marketingConsent,
    user: {
      date_of_birth: dateOfBirth,
      email,
      first_name: firstName,
      last_name: lastName,
      nationality: nationality.toLowerCase(),
    },
    'g-recaptcha-response': token,
  });
};

export default {
  v1: {
    post: {
      to: v1_post_to,
    },
  },
};
