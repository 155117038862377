import React, { useContext, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import styled from 'styled-components';

import Alert, { AlertButton } from '@kiwicom/orbit-components/lib/Alert';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';
import FlightReturnIcon from '@kiwicom/orbit-components/lib/icons/FlightReturn';
import AlertInfoIcon from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import { usePolyglot } from 'components/services/i18n';
import { MMBState } from 'components/services/mmb';
import { useCurrentUser } from 'components/services/auth';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import useInvoices from 'components/services/mmb/useInvoices';
import ShoppingBasketProvider from 'components/services/shoppingBasket';
import { Authorization } from 'common/Authorization';
import { OrbitLoader } from 'common';
import { useToggle } from 'utils/hooks';
import * as Intercom from 'utils/intercom';

import Refund from './Refund';
import CustomerSupport from './CustomerSupport/CustomerSupport';
import TripSummary from './TripSummary';
import Passengers from './Passengers';
import BoardingPasses from './BoardingPasses';
import PriceChange from './PriceChange';
import RefundModal from './RefundModal';
import InvoiceModal from './InvoiceModal';
import CancelBookingModal from './CancelBookingModal';
import CancelUnavailableModal from './CancelUnavailableModal';
import Seating from './Seating';
import ContactDetails from './ContactDetails';

const BookingStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-left: 12px;
    text-transform: initial;
  }
`;

const PageContainer = styled.div`
  width: 796px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 8px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SwitchToOldVersion = ({ bid }) => {
  const history = useHistory();
  const polyglot = usePolyglot();
  return (
    <Alert
      title={polyglot.t('mmb.old_tool.description')}
      type="info"
      spaceAfter="large"
      icon={<AlertInfoIcon />}
      inlineActions={
        <AlertButton
          size="small"
          type="info"
          dataTest="switch-version-btn"
          onClick={() => {
            Intercom.trackEvent('switched-to-old-mmb');
            history.push({
              pathname: `/manage-my-bookings/${bid}`,
              state: {
                forceOld: true,
              },
            });
          }}
        >
          {polyglot.t('booking.deposit_booking.old_version')}
        </AlertButton>
      }
    />
  );
};

const Title = ({ tripSummary }) => {
  const Icon = tripSummary.type === 'return' ? FlightReturnIcon : FlightDirectIcon;

  return (
    <Heading type="title1">
      {tripSummary.locations.from} <Icon /> {tripSummary.locations.to}
    </Heading>
  );
};

const InvoiceDownload = ({ bid, onClick }) => {
  const { data: invoices } = useInvoices(bid);
  const polyglot = usePolyglot();

  if (!invoices?.length) {
    return null;
  }

  return (
    <Button type="secondary" size="small" onClick={onClick} dataTest="download-invoice-btn">
      {polyglot.t('mmb.booking_details.invoice_download')}
    </Button>
  );
};

const STATUS_DATA = {
  processing: {
    type: 'info',
    label: 'mmb.status.processing',
  },
  traveled: {
    type: 'neutral',
    label: 'mmb.status.traveled',
  },
  refunding: {
    type: 'info',
    label: 'mmb.status.refunding',
  },
  refunded: {
    type: 'success',
    label: 'mmb.status.refunded',
  },
  confirmed: {
    type: 'success',
    label: 'mmb.status.confirmed',
  },
  cancelled: {
    type: 'warning',
    label: 'mmb.status.cancelled',
  },
  failed: {
    type: 'critical',
    label: 'mmb.status.failed',
  },
  locked: {
    type: 'critical',
    label: 'mmb.status.locked',
  },
};

const bookingStatusFallback = status => ({
  type: 'info',
  label: status,
});

const BookingStatus = ({ status }) => {
  const data = STATUS_DATA[status] || bookingStatusFallback(status);
  const polyglot = usePolyglot();

  return (
    <Badge type={data.type} dataTest="booking-status">
      {polyglot.t(data.label)}
    </Badge>
  );
};

const DepositBookingDetails = () => {
  const { state, requestRefund, payPriceChange, checkReservations } = useContext(MMBState);
  const { params } = useRouteMatch();
  const user = useCurrentUser();
  const { data: companyDetails, loading } = useCompanyDetails(user.companyName);
  const refundModal = useToggle();
  const invoiceModal = useToggle();
  const cancelBookingModal = useToggle();
  const cancelUnavailableModal = useToggle();
  const stopProcessingAlert = useToggle();
  const bookingData = state.bookingsState[params.bid]?.bookingData;
  const polyglot = usePolyglot();

  const { reservationState, cancelBookingError } = state;

  useEffect(() => {
    checkReservations(params.bid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.bid]);

  if (!state.tokens[params.bid] || loading) {
    return (
      <LoaderWrapper>
        <OrbitLoader visible id="mmb-loader" />
      </LoaderWrapper>
    );
  }

  const handleRequestRefund = async () => {
    try {
      await requestRefund(params.bid);
      refundModal.setOff();
    } catch (_) {}
  };

  const handleStopProcessing = async () => {
    await checkReservations(params.bid);
    if (!reservationState?.[params.bid]) {
      cancelBookingModal.setOn();
    } else {
      cancelUnavailableModal.setOn();
    }
  };

  return (
    <ShoppingBasketProvider>
      <PageContainer>
        {refundModal.isOn && (
          <RefundModal
            bid={params.bid}
            onClose={refundModal.setOff}
            onSubmit={handleRequestRefund}
          />
        )}
        {invoiceModal.isOn && <InvoiceModal bid={params.bid} onClose={invoiceModal.setOff} />}
        {cancelBookingModal.isOn && (
          <CancelBookingModal
            onClose={cancelBookingModal.setOff}
            bid={params.bid}
            handleAlert={stopProcessingAlert.setOn}
          />
        )}
        {cancelUnavailableModal.isOn && (
          <CancelUnavailableModal onClose={cancelUnavailableModal.setOff} />
        )}
        <Stack>
          <SwitchToOldVersion bid={params.bid} />
          <Title tripSummary={bookingData.tripSummary} />
          <TitleWrapper direction="row" justify="between" align="center">
            <Heading type="title5" as={BookingStatusWrapper}>
              {polyglot.t('mmb.booking_details.booking_status')}
              <BookingStatus status={bookingData.displayStatus} />
            </Heading>

            <ButtonsWrapper>
              <InvoiceDownload bid={params.bid} onClick={invoiceModal.setOn} />
              {bookingData.displayStatus === 'confirmed' &&
                state.bookingsState?.[params.bid]?.refund === undefined &&
                !state.bookingsState?.[params.bid]?.loadingRefund && (
                  <Button
                    type="critical"
                    size="small"
                    onClick={refundModal.setOn}
                    dataTest="refund-btn"
                  >
                    {polyglot.t('mmb.booking_details.refund')}
                  </Button>
                )}
              {!reservationState?.[params.bid] && bookingData.displayStatus === 'processing' && (
                <Button type="criticalSubtle" size="small" onClick={handleStopProcessing}>
                  {polyglot.t('mmb.stop_processing')}
                </Button>
              )}
            </ButtonsWrapper>
          </TitleWrapper>
          <PriceChange bookingData={bookingData} onClick={payPriceChange} />
          <Refund bid={params.bid} />
          <Authorization
            resource="fe.page.customer_support"
            action={['read']}
            companyName={user.companyName}
          >
            {companyDetails.customerSupportEnabled && <CustomerSupport bid={params.bid} />}
          </Authorization>
          {stopProcessingAlert.isOn && (
            <Alert
              type={!!cancelBookingError ? 'critical' : 'success'}
              onClose={stopProcessingAlert.setOff}
            >
              {polyglot.t(
                !!cancelBookingError
                  ? 'mmb.stop_processing.failure'
                  : 'mmb.stop_processing.success',
              )}
            </Alert>
          )}
          <TripSummary bid={params.bid} />
          <Seating />
          <Passengers bid={params.bid} />
          <ContactDetails contactDetails={bookingData.contactDetails} />
          <BoardingPasses />
        </Stack>
      </PageContainer>
    </ShoppingBasketProvider>
  );
};

export default DepositBookingDetails;
