import { useContext, useEffect } from 'react';
import { MMBState } from './';

const useMMB = () => {
  const {
    state,
    getTokenAndUrl,
    loadMMBBookings,
    loadMoreBookings,
    searchByBid,
    cancelBooking,
    checkReservations,
    checkAncillariesOffers,
    resetAncillariesOffers,
  } = useContext(MMBState);

  useEffect(() => {
    if (!state.mmbToken) {
      getTokenAndUrl();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state,
    loadMMBBookings,
    loadMoreBookings,
    searchByBid,
    cancelBooking,
    checkReservations,
    checkAncillariesOffers,
    resetAncillariesOffers,
  };
};

export default useMMB;
