import React from 'react';

import { useSearch } from 'components/services/search';

import SearchBar from './components/SearchBar';
import Filters from './components/Filters';

const SearchForm = () => {
  const { tabData } = useSearch();
  const SearchBarComponent = SearchBar[tabData.tripType];

  return (
    <>
      <Filters />
      <SearchBarComponent />
    </>
  );
};

export default SearchForm;
