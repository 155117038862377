import React from 'react';
import PropTypes from 'prop-types';

import {
  CardDataContainer,
  ColumnsContainer,
  FullWidthContainer,
  CardColumnContainer,
  DataRowContainer,
  RowLabel,
  RowValue,
} from './CardData.styled';

const CardData = ({ fullWidthRow, leftColumn, rightColumn, suppressed }) => (
  <CardDataContainer>
    {fullWidthRow && (
      <FullWidthContainer>
        {fullWidthRow.map((row, i) => (
          <DataRowContainer key={i} suppressed={i === 0 || suppressed}>
            <RowLabel fullWidth>{row.label}</RowLabel>
            <RowValue fullWidth>{row.value}</RowValue>
          </DataRowContainer>
        ))}
      </FullWidthContainer>
    )}
    {leftColumn && rightColumn && (
      <ColumnsContainer>
        <CardColumnContainer>
          {leftColumn.map((row, i) => (
            <DataRowContainer key={i} suppressed={i === 0 || suppressed}>
              <RowLabel>{row.label}</RowLabel>
              <RowValue>{row.value}</RowValue>
            </DataRowContainer>
          ))}
        </CardColumnContainer>
        <CardColumnContainer>
          {rightColumn.map((row, i) => (
            <DataRowContainer key={i} suppressed={i === 0 || suppressed}>
              <RowLabel>{row.label}</RowLabel>
              <RowValue>{row.value}</RowValue>
            </DataRowContainer>
          ))}
        </CardColumnContainer>
      </ColumnsContainer>
    )}
  </CardDataContainer>
);

const Row = PropTypes.shape({
  label: PropTypes.node,
  value: PropTypes.node,
});

CardData.propTypes = {
  fullWidthRow: PropTypes.arrayOf(Row),
  leftColumn: PropTypes.arrayOf(Row),
  rightColumn: PropTypes.arrayOf(Row),
  suppressed: PropTypes.bool,
};

export default CardData;
