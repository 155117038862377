import React, { useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import PlusIcon from '@kiwicom/orbit-components/lib/icons/Plus';
import EditIcon from '@kiwicom/orbit-components/lib/icons/Edit';
import MoneyIcon from '@kiwicom/orbit-components/lib/icons/Money';
import MoneyTransferInIcon from '@kiwicom/orbit-components/lib/icons/MoneyTransferIn';

import { Card, CardActionHeader, CardData } from 'components/common/Card';
import { Authorization } from 'common/Authorization';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';
import { DepositState } from 'components/services/deposit';
import useCompanyDeposit from 'components/services/deposit/useCompanyDeposit';
import { AuthState } from 'components/services/auth/AuthProvider';
import { BankAccountsState } from 'components/services/bankAccounts';
import { MINIMUM_CASH_OUT_VALUE } from 'consts/company';

import DepositModal from './DepositModal';
import CashOutModal from './CashOutModal';

const Deposit = () => {
  const { loadDeposit } = useContext(DepositState);
  const { loadCompanyBankAccounts, state: bankAccountsState } = useContext(BankAccountsState);
  const { checkAccess } = useContext(AuthState);
  const { companyName } = useRouteMatch().params;
  const deposit = useCompanyDeposit(companyName);
  const polyglot = usePolyglot();

  const depositModal = useToggle();
  const cashOutModal = useToggle();
  const successSetDepositAlert = useToggle();
  const successCashOutAlert = useToggle();

  useEffect(() => {
    checkAccess(
      'model.bank_account',
      ['update', 'update_assigned', 'update_own'],
      companyName,
    ).then(res => {
      if (!!res) {
        loadCompanyBankAccounts(companyName);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  const hasEnoughCredits = deposit.data && deposit.data.balance > MINIMUM_CASH_OUT_VALUE;
  const bankAccounts = bankAccountsState[companyName]?.data || [];
  const hasBeneficiaryName =
    (bankAccounts?.length > 0 && bankAccounts?.[0].beneficiaryName) || false;
  const isCashoutEnabled = hasEnoughCredits && hasBeneficiaryName;

  const handleSetDepositSuccess = () => {
    successSetDepositAlert.setOn();
    depositModal.setOff();
    loadDeposit(companyName);
  };

  const handleCashOutSuccess = () => {
    successCashOutAlert.setOn();
    cashOutModal.setOff();
    loadDeposit(companyName);
  };

  const getAlert = () => {
    let alerts = [];
    if (successCashOutAlert.isOn) {
      alerts.push(
        <Alert key="cashOutSuccess" type="success" closable onClose={successCashOutAlert.setOff}>
          {polyglot.t('deposit.cash_out_success_message')}
        </Alert>,
      );
    }
    if (successSetDepositAlert.isOn) {
      alerts.push(
        <Alert
          key="setDepositSuccess"
          type="success"
          closable
          icon
          onClose={successSetDepositAlert.setOff}
        >
          {polyglot.t('deposit.set_deposit_success')}
        </Alert>,
      );
    }
    return alerts;
  };
  return (
    <>
      {depositModal.isOn && (
        <DepositModal onClose={depositModal.setOff} onSuccess={handleSetDepositSuccess} />
      )}
      {cashOutModal.isOn && (
        <CashOutModal onClose={cashOutModal.setOff} onSuccess={handleCashOutSuccess} />
      )}

      <Card loading={deposit.loading} loadingText="Loading deposit data...">
        <>
          <CardActionHeader
            title="Deposit"
            icon={<MoneyTransferInIcon />}
            action={
              <Stack direction="row" justify="end" grow={false} shrink>
                <Authorization
                  companyName={companyName}
                  resource="model.deposit_account"
                  action={['create', 'create_assigned', 'create_own']}
                >
                  {deposit.data ? (
                    <Button
                      type="secondary"
                      size="small"
                      dataTest="button-edit-deposit"
                      iconLeft={<EditIcon />}
                      onClick={depositModal.setOn}
                    />
                  ) : (
                    <Button
                      type="primary"
                      size="small"
                      dataTest="button-add-details"
                      iconLeft={<PlusIcon />}
                      onClick={depositModal.setOn}
                    />
                  )}
                </Authorization>
                <Authorization
                  resource="model.deposit_account"
                  action={['cashout', 'cashout_assigned', 'cashout_own']}
                  companyName={companyName}
                >
                  {isCashoutEnabled ? (
                    <Button
                      type="primary"
                      size="small"
                      dataTest="button-cash-out"
                      iconLeft={<MoneyIcon />}
                      onClick={cashOutModal.setOn}
                    >
                      {polyglot.t('deposit.cash_out')}
                    </Button>
                  ) : (
                    <Tooltip
                      content={
                        <>
                          {!hasEnoughCredits && (
                            <p>
                              {polyglot.t('deposit.cash_out_disabled_text.credits', {
                                numberOfCredits: MINIMUM_CASH_OUT_VALUE,
                              })}
                            </p>
                          )}
                          {!hasBeneficiaryName &&
                            polyglot.t('deposit.cash_out_disabled_text.beneficiary')}
                        </>
                      }
                    >
                      <Button
                        type="primary"
                        size="small"
                        dataTest="button-cash-out"
                        iconLeft={<MoneyIcon />}
                        onClick={cashOutModal.setOn}
                        disabled
                      >
                        {polyglot.t('deposit.cash_out')}
                      </Button>
                    </Tooltip>
                  )}
                </Authorization>
              </Stack>
            }
          />
          {deposit.data && (
            <CardData
              showAlert={successSetDepositAlert.isOn || successCashOutAlert.isOn}
              alert={getAlert()}
              leftColumn={[{ label: polyglot.t('common.user'), value: deposit.data.depositAcc }]}
              rightColumn={[
                {
                  label: polyglot.t('common.credits'),
                  value: `€${deposit.data.balance}`,
                  labelDataTest: 'credits-label',
                  valueDataTest: 'credits-value',
                },
              ]}
            />
          )}
        </>
      </Card>
    </>
  );
};

export default Deposit;
