import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { usePolyglot } from 'components/services/i18n';
import useWidgetsContext from 'components/services/widgets';

const DeleteWidgetModal = ({ closeMe, widgetId, widgetName }) => {
  const {
    deleteWidget,
    state: { deletingWidget },
  } = useWidgetsContext();

  const polyglot = usePolyglot();

  const desc = (
    <Text size="large">
      {polyglot.t('widgets.delete_desc')}{' '}
      <Text as="span" weight="bold">
        {widgetName}
      </Text>
      ?
    </Text>
  );

  const handleDeleteClick = async () => {
    try {
      await deleteWidget(widgetId, widgetName);
      closeMe();
    } catch {}
  };

  return (
    <Modal size="small" closable onClose={closeMe}>
      <ModalHeader
        spaceAfter="small"
        title={polyglot.t('widgets.delete_title')}
        description={desc}
      />
      {deletingWidget.error && (
        <ModalSection>
          <Alert spaceAfter="normal" type="critical">
            {polyglot.t(deletingWidget.error)}
          </Alert>
        </ModalSection>
      )}
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button type="criticalSubtle" onClick={closeMe}>
            {polyglot.t('common.cancel')}
          </Button>
          <Button loading={deletingWidget.inProgress} type="critical" onClick={handleDeleteClick}>
            {polyglot.t('common.delete')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteWidgetModal;
