import { useCallback, useEffect, useRef, useState } from 'react';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useToggle = (initialState = false) => {
  const [isOn, _setIsOn] = useState(initialState);

  const toggle = () => {
    _setIsOn(s => !s);
  };

  const setOn = () => {
    _setIsOn(true);
  };

  const setOff = () => {
    _setIsOn(false);
  };

  const set = state => {
    _setIsOn(state);
  };

  return { isOn, set, toggle, setOn, setOff };
};

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
