import { useContext, useEffect } from 'react';

import { AuthState } from './AuthProvider';

const useCan = (user, action, resource, companyName) => {
  const { can, checkedGrants } = useContext(AuthState);

  useEffect(() => {
    can(user, action, resource, companyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkedGrants?.[companyName]?.[resource]?.[action] || false;
};

export default useCan;
