import React from 'react';
import PropTypes from 'prop-types';

import { ArrowIcon } from './SortArrow.styled';

const SortArrow = ({ ascending, ...props }) => <ArrowIcon descending={!ascending} {...props} />;

SortArrow.propTypes = {
  ascending: PropTypes.bool,
};

export default SortArrow;
