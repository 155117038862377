import * as R from 'ramda';

const getMainLocations = (data, type) => {
  if (type === 'return') {
    return {
      from: data.itinerary.sectors[0].departure.place.city,
      to: data.itinerary.sectors[1].departure.place.city,
    };
  }
  return {
    from: data.itinerary.sectors[0].departure.place.city,
    to: data.itinerary.sectors[data.itinerary.sectors.length - 1].arrival.place.city,
  };
};

const v1_get_from = data => {
  return {
    booking: {
      price: {
        amount: data.booking.price.amount,
        base: data.booking.price.base,
        currency: data.booking.price.currency,
        merchant: data.booking.price.merchant,
        service: data.booking.price.service,
        serviceFlat: data.booking.price.service_flat,
      },
    },
    isDeposit: data.booking?.card_payment_price && +data.booking?.card_payment_price.amount === 0, //booking wasn't paid with credit card
    segments: R.groupBy(
      s => s.sector,
      data.itinerary.segments.map(segment => ({
        arrival: {
          place: {
            city: segment.arrival.place.city,
            country: segment.arrival.place.country,
            countryCode: segment.arrival.place.country_code,
            id: segment.arrival.place.id,
          },
          station: {
            code: segment.arrival.station.code,
            gate: segment.arrival.station.gate,
            name: segment.arrival.station.name,
            terminal: segment.arrival.station.terminal,
          },
          time: {
            local: segment.arrival.time.local,
            utc: segment.arrival.time.utc,
          },
          name: `${segment.arrival.station.name} ${segment.arrival.station.code}`, // TODO: probably not correct, but works for now
        },
        carrier: {
          code: segment.carrier.code,
          name: segment.carrier.name,
          publicCode: segment.carrier.public_code,
          segmentCode: segment.carrier.segment_code,
        },
        departure: {
          place: {
            city: segment.departure.place.city,
            country: segment.departure.place.country,
            countryCode: segment.departure.place.country_code,
            id: segment.departure.place.id,
          },
          station: {
            code: segment.departure.station.code,
            gate: segment.departure.station.gate,
            name: segment.departure.station.name,
            terminal: segment.departure.station.terminal,
          },
          time: {
            local: segment.departure.time.local,
            utc: segment.departure.time.utc,
          },
          name: `${segment.departure.station.name} ${segment.departure.station.code}`, // TODO: probably not correct, but works for now
        },
        fare: {
          category: segment.fare.category,
          class: segment.fare.class,
        },
        hidingReason: segment.hiding_reason,
        isBagRecheckRequired: segment.is_bag_recheck_required,
        isGuaranteed: segment.is_guaranteed,
        isSelfTransfer: segment.is_self_transfer,
        operatingCarrier: {
          code: segment.operating_carrier.code,
          name: segment.operating_carrier.name,
          public_code: segment.operating_carrier.public_code,
          segment_code: segment.operating_carrier.segment_code,
        },
        flightNumber:
          segment.carrier?.public_code || segment.carrier?.segment_code
            ? [segment.carrier?.public_code, segment.carrier?.segment_code]
                .filter(Boolean)
                .join(' ')
            : null,
        duration: new Date(segment.arrival.time.utc) - new Date(segment.departure.time.utc),
        sector: segment.sector,
        segmentCode: segment.segment_code,
        vehicleType: segment.vehicle_type,
      })),
    ),
    itinerary: {
      itineraryType: data.itinerary.itinerary_type,
      sectors: data.itinerary.sectors.map(sector => ({
        arrival: {
          place: {
            city: sector.arrival.place.city,
            country: sector.arrival.place.country,
            country_code: sector.arrival.place.country_code,
            id: sector.arrival.place.id,
          },
          station: {
            code: sector.arrival.station.code,
            gate: sector.arrival.station.gate,
            name: sector.arrival.station.name,
            terminal: sector.arrival.station.terminal,
          },
          time: {
            local: sector.arrival.time.local,
            utc: sector.arrival.time.utc,
          },
        },
        departure: {
          place: {
            city: sector.departure.place.city,
            country: sector.departure.place.country,
            country_code: sector.departure.place.country_code,
            id: sector.departure.place.id,
          },
          station: {
            code: sector.departure.station.code,
            gate: sector.departure.station.gate,
            name: sector.departure.station.name,
            terminal: sector.departure.station.terminal,
          },
          time: {
            local: sector.departure.time.local,
            utc: sector.departure.time.utc,
          },
        },
        duration: new Date(sector.arrival.time.utc) - new Date(sector.departure.time.utc),
        id: sector.id,
        segmentCodes: sector.segment_codes,
      })),
    },
    tripSummary: {
      type: data.itinerary.itinerary_type,
      locations: getMainLocations(data, data.itinerary.itinerary_type),
    },
    displayStatus: data.display_status,
    baggage: data.baggage.length
      ? data.baggage.map(item => {
          return {
            additionalBookingId: item.additional_booking_id,
            bag: item.bag, // map sub items
            bookingId: item.booking_id,
            deletedAt: item.deleted_at,
            flightId: item.flight_id,
            id: item.id,
            index: item.index,
            isHold: item.is_hold,
            passengerId: item.passenger_id,
            price: item.price, // map sub items
            segmentCode: item.segment_code,
            universalId: item.universalId,
          };
        })
      : [],
    ancillaries: {
      bags:
        data.ancillaries.bags &&
        data.ancillaries.bags.map(item => {
          return {
            createdAt: item.created_at,
            extraId: item.extra_id, // this is additionalBookingId inside baggage
            status: item.status,
            price: item.price, // check if sub items need mapping
          };
        }),
      seating: data.ancillaries?.seating.map(seatingItem => ({
        createdAt: seatingItem.created_at,
        extraId: seatingItem.extra_id,
        id: seatingItem.id,
        items: seatingItem.items.map(item => ({
          loyaltyOption: item.loyalty_option,
          option: item.option,
          seats: item.seats.map(seat => ({
            bookedSeat: seat.booked_seat,
            passengerId: seat.passenger_id,
            seat: seat.seat,
            universalId: seat.universal_id,
          })),
          segmentCode: item.segment_code,
        })),
        source: seatingItem.source,
        status: seatingItem.status,
      })),
    },
    contactDetails: {
      email: data.booking.email,
      phone: data.booking.phone,
    },
  };
};

export default {
  v1: {
    get: {
      from: v1_get_from,
    },
  },
};
