import React from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';

import { usePolyglot } from 'components/services/i18n';
import useBookingFlow from 'components/services/booking/useBookingFlow';

const Banners = () => {
  const {
    prebookingConfig: { data: prebookingConfigData },
  } = useBookingFlow();
  const polyglot = usePolyglot();

  return (
    <Stack>
      {prebookingConfigData?.banners &&
        prebookingConfigData?.banners.map((banner, i) => (
          <Alert key={i} type={banner.type} title={banner.title}>
            <div dangerouslySetInnerHTML={{ __html: banner.description }}></div>
          </Alert>
        ))}
      <Alert type="info" title="Passenger details">
        {polyglot.t('booking.deposit_booking.booking_summary.banners.use_all')}
      </Alert>

      <Alert type="info" title="Visa requirements and travel restrictions check">
        <List>
          <ListItem>
            {polyglot.t('booking.deposit_booking.booking_summary.banners.not_liable')}
          </ListItem>
          <ListItem>
            {polyglot.t('booking.deposit_booking.booking_summary.banners.final_destination')}
          </ListItem>
        </List>
      </Alert>
    </Stack>
  );
};

export default Banners;
