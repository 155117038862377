import React from 'react';

import Button from '@kiwicom/orbit-components/lib/Button';

import { generateScriptFromFormValues } from 'utils/widgets';
import copyToClipboard from 'utils/copyToClipboard';
import { usePolyglot } from 'components/services/i18n';
import { useToggle } from 'utils/hooks';

const CopyCodeToClipboardButton = ({ config, icon }) => {
  const polyglot = usePolyglot();
  const copiedToClipboard = useToggle(false);

  const generateScriptAndCopyToClipboard = widgetConfig => {
    copyToClipboard(generateScriptFromFormValues(widgetConfig));
    copiedToClipboard.setOn();
    setTimeout(() => {
      copiedToClipboard.setOff();
    }, 1000);
  };

  return (
    <Button
      size="small"
      type={copiedToClipboard.isOn ? 'primarySubtle' : 'secondary'}
      onClick={() => {
        generateScriptAndCopyToClipboard(config);
      }}
      dataTest="copy-button"
    >
      {copiedToClipboard.isOn ? polyglot.t('common.copied') : icon}
    </Button>
  );
};

export default CopyCodeToClipboardButton;
