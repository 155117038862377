export const BILLING_STATUSES = [
  {
    label: 'billing.status.new',
    value: 'new',
  },
  {
    label: 'billing.status.processing',
    value: 'processing',
  },
  {
    label: 'billing.status.review',
    value: 'review',
  },
  {
    label: 'billing.status.approved',
    value: 'approved',
  },
  {
    label: 'billing.status.failed',
    value: 'failed',
  },
  {
    label: 'billing.status.deleted',
    value: 'deleted',
  },
];
