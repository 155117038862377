import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import useBooking from 'components/services/booking/useBooking';
import useUpdatePassengerNameSeatMap from 'components/services/bookingToolSeating/useUpdatePassengerNameSeatMap.js';

import SeatingSegment from './components/SeatingSegment';
import SeatingDrawer from '../SeatingDrawer';

const SeatingSummaryTable = () => {
  const {
    state: { routeGroups },
  } = useLocation();
  const [routeId, setRouteId] = useState(null);
  const polyglot = usePolyglot();
  const {
    seatingOfferData: { offers },
  } = useBooking();
  const { watch } = useFormContext();
  const { passengers } = watch();
  const areActionButtonsDisabled = !passengers.every(
    ({ firstName, lastName }) => firstName?.length && lastName?.length,
  );

  useUpdatePassengerNameSeatMap(passengers);

  return (
    <>
      {routeId && <SeatingDrawer closeDrawer={() => setRouteId(null)} isDrawerOpen={routeId} />}
      <Stack direction="column">
        {routeGroups.map(itinerary => (
          <>
            <Text weight="bold" spaceAfter="medium">
              {polyglot.t('booking_tool.seating.drawer.route_groups.to_place.title', {
                location: itinerary[itinerary.length - 1].cityTo,
              })}
            </Text>
            {itinerary.map(route => (
              <SeatingSegment
                key={route.id}
                id={route.id}
                cityFrom={route.cityFrom}
                cityTo={route.cityTo}
                airline={route.airline}
                disabled={!offers.find(offer => offer.segmentCode === route.id)}
                onOpenDrawer={setRouteId}
                areActionButtonsDisabled={areActionButtonsDisabled}
              />
            ))}
          </>
        ))}
      </Stack>
    </>
  );
};

export default SeatingSummaryTable;
