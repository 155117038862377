import styled from 'styled-components';

export const SelectCompanyContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;

  & > button {
    margin-left: 8px;
  }
`;
