import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const EmailContainer = styled.div`
  margin-top: 12px;
`;

export const PasswordContainer = styled.div`
  margin-top: 21px;
`;

export const ErrorMessage = styled.p`
  margin-top: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.orbit.colorTextError};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

export const LoginButtonContainer = styled.div`
  width: 100%;

  ${media.desktop(css`
    width: initial;
  `)}
`;

export const LoginButtonWrapper = styled.div`
  margin-top: 21px;

  ${media.desktop(css`
    display: flex;
    justify-content: flex-end;
  `)}
`;

export const ForgotPassword = styled.div`
  text-decoration: underline;
  margin: 16px 0;
  text-align: center;

  ${media.desktop(css`
    text-align: right;
  `)}
`;

export const RegisterContainer = styled.div`
  margin-top: 16px;
  text-align: right;
  width: 300px;
`;
