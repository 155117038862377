import PropTypes from 'prop-types';

const Message = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
      onClick: PropTypes.func,
      children: PropTypes.node,
      label: PropTypes.node,
    }),
  ]),
};

export default Message;

// example data

export const message = {
  text: 'Example message',
  type: 'success',
  id: '0',
};
