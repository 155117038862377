import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/Close';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import { getPolyglot } from 'redux/selectors/i18n';
import { isMetricSlow } from 'pages/Reports/utils';

import { TableCell, TableRow, TableBody } from 'common';
import { FilterValuesAutocomplete } from 'pages/Reports/components/Filters';

import { IconCell, FilterCell, ButtonCell } from './common.styled';

class FilterRow extends Component {
  handleSelectValue = filterValue => {
    const { selectedValues, filter, onUpdateRow } = this.props;
    const updatedSelectedValues = [...selectedValues, filterValue.value];
    onUpdateRow(filter.value, updatedSelectedValues);
  };

  handleUnselectValue = filterValue => {
    const { selectedValues, filter, onUpdateRow } = this.props;
    const updatedSelectedValues = selectedValues.filter(item => item !== filterValue.value);
    if (R.isEmpty(updatedSelectedValues)) {
      this.handleDeleteRow();
    } else {
      onUpdateRow(filter.value, updatedSelectedValues);
    }
  };

  handleDeleteRow = () => {
    const { onDeleteRow, filter } = this.props;
    onDeleteRow(filter.value);
  };

  render() {
    const { polyglot, filter, selectedValues } = this.props;

    return (
      <TableBody>
        <TableRow>
          <IconCell center>
            {isMetricSlow(filter.value) && (
              <Tooltip
                content={polyglot.t('reports.slow_filter_tooltip_text')}
                preferredPosition="bottom"
              >
                <ClockIcon customColor="#f9971e" />
              </Tooltip>
            )}
          </IconCell>
          <FilterCell>{polyglot.t(filter.k)}</FilterCell>
          <TableCell>
            <FilterValuesAutocomplete
              filter={filter.value}
              selectedValues={selectedValues}
              onSelect={this.handleSelectValue}
              onUnselect={this.handleUnselectValue}
              companyName={this.props.companyName}
              isInEditMode={false}
            />
          </TableCell>
          <ButtonCell />
          <ButtonCell>
            <ButtonLink
              size="small"
              type="secondary"
              title="Delete report"
              onClick={this.handleDeleteRow}
              iconLeft={<CloseIcon customColor="#d21c1c" />}
            />
          </ButtonCell>
        </TableRow>
      </TableBody>
    );
  }
}

const mapStateToProps = state => ({
  polyglot: getPolyglot(state),
});

FilterRow.propTypes = {
  filter: PropTypes.any,
  selectedValues: PropTypes.array,
  onUpdateRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default connect(mapStateToProps)(FilterRow);
