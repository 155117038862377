import { APP_NAME } from 'consts/index';

export const action = (reducer, prefix, outcome) =>
  `${APP_NAME}/${reducer}/${prefix}${outcome ? '_' + outcome : ''}`;
export const modalAction = (name, prefix, type) => `${name}/${prefix}_${type}`;

export const expand =
  reducer =>
  (prefix = 'LOAD') => ({
    [`${prefix}`]: action(reducer, prefix, ''),
    [`${prefix}_SUCCESS`]: action(reducer, prefix, 'SUCCESS'),
    [`${prefix}_FAIL`]: action(reducer, prefix, 'FAIL'),
  });

export const expandModalTypes = name => type => ({
  [`OPEN_${type}_MODAL`]: modalAction(name, type, 'OPEN'),
  [`SUBMIT_${type}_MODAL`]: modalAction(name, type, 'SUBMIT'),
  [`CLOSE_${type}_MODAL`]: modalAction(name, type, 'CLOSE'),
});

export default expand;
