import styled, { createGlobalStyle } from 'styled-components';

export const DisableGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const DisableContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: ${({ theme }) => theme.orbit.backgroundBody};

  @media (max-width: 414px) {
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 16px auto;
  padding: 24px;
  width: calc(100% - 32px);
  max-width: 480px;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
`;

export const NavigationWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.orbit.backgroundCard};
`;
