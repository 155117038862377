import React from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';

const SeatPopoverActions = ({ seatName, onClose, onSelectSeat }) => {
  const polyglot = usePolyglot();
  const { watch } = useFormContext();
  const { passengers } = watch();
  const isSinglePassenger = passengers.length === 1;

  const handleSinglePaxConfirm = () => {
    onSelectSeat(seatName, {
      passenger: {
        id: 0,
        firstName: passengers[0].firstName,
        lastName: passengers[0].lastName,
      },
    });
    onClose();
  };

  if (isSinglePassenger) {
    return (
      <Stack direction="row" align="stretch" justify="between">
        <Button onClick={onClose} type="secondary" fullWidth>
          {polyglot.t('booking.additional_services.seating.popover.cancel')}
        </Button>
        <Button onClick={handleSinglePaxConfirm} type="primary" fullWidth>
          {polyglot.t('booking.additional_services.seating.popover.confirm')}
        </Button>
      </Stack>
    );
  }

  return (
    <Button asComponent="div" onClick={onClose} type="secondary" fullWidth>
      {polyglot.t('booking.additional_services.seating.popover.cancel')}
    </Button>
  );
};

export default SeatPopoverActions;
