import React, { useState, useEffect, useContext } from 'react';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';

import Modal, { ModalHeader, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { DayPickerButton } from 'common';
import { SpecialAppsContext } from 'components/services/specialApps';
import useCurrentCompanyName from 'components/services/company/useCurrentCompanyName';
import { useToggle } from 'utils/hooks';
import { usePolyglot } from 'components/services/i18n';

const DestinationDemandModal = ({ onClose, specialApps, specialAppsDefinitions }) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(addDays(new Date(), 1));
  const [cities, setCities] = useState('');
  const [countries, setCountries] = useState('');
  const { createSpecialApp, updateSpecialApp, deleteSpecialApp, loadSpecialApps } =
    useContext(SpecialAppsContext);
  const currentCompany = useCurrentCompanyName();
  const isError = useToggle();
  const polyglot = usePolyglot();
  const loading = specialAppsDefinitions.loadingDefinitions || specialApps.loading;

  const demandDashboardDefinition = specialAppsDefinitions.definitions.find(
    application => application.name === 'destination-dashboard',
  );

  const app = specialApps.apps.find(
    application => application.definitionId === demandDashboardDefinition.id,
  );

  useEffect(() => {
    if (app) {
      setCities(app.config.cities.join(','));
      setCountries(app.config.countries.join(','));
      setDateFrom(new Date(app.validFrom));
      if (app.validTo) {
        setDateTo(new Date(app.validTo));
      } else {
        setDateTo(undefined);
      }
    }
  }, [app]);

  const handleInputChange = (value, type) => {
    if (/^$|^[a-zA-Z_a-zA-Z,]+$/.test(value)) {
      isError.setOff();
    } else {
      isError.setOn();
    }
    if (type === 'city') {
      setCities(value);
    } else {
      setCountries(value);
    }
  };

  const handleSubmit = async () => {
    if (app) {
      await updateSpecialApp(currentCompany, app, {
        cities: cities,
        countries: countries,
        validFrom: dateFrom,
        validTo: dateTo,
      });
    } else {
      await createSpecialApp(currentCompany, demandDashboardDefinition, {
        cities: cities,
        countries: countries,
        validFrom: dateFrom,
        validTo: dateTo,
      });
    }

    loadSpecialApps(currentCompany);
    onClose();
  };

  const handleDelete = async () => {
    await deleteSpecialApp(currentCompany, app);
    loadSpecialApps(currentCompany);
    onClose();
  };

  return (
    <Modal size="small" onClose={onClose}>
      <ModalHeader>
        <Heading type="title1" element="h1">
          {polyglot.t('customer_demand_dashboard.modal_title')}
        </Heading>
      </ModalHeader>
      <ModalSection>
        {loading ? (
          <Loading />
        ) : (
          <Stack spacing="large" direction="column">
            <InputField
              label={polyglot.t(
                'tequila.destination_demand_dashboard.special_app.destination.input.cities.title',
              )}
              value={cities}
              onChange={e => handleInputChange(e.target.value, 'city')}
            />
            <InputField
              label={polyglot.t(
                'tequila.destination_demand_dashboard.special_app.destination.input.countries.title',
              )}
              value={countries}
              onChange={e => handleInputChange(e.target.value, 'country')}
            />
            <Stack spaceAfter="large" direction="row" justify="between">
              <DayPickerButton
                name="validFrom"
                label={polyglot.t('market.valid_from')}
                value={format(dateFrom, 'yyyy-MM-dd')}
                onSelect={e => setDateFrom(e)}
                disabledDays={[{ after: subDays(dateTo, 1) }]}
              />
              <DayPickerButton
                name="validTo"
                label={polyglot.t('market.valid_to')}
                value={dateTo && format(dateTo, 'yyyy-MM-dd')}
                onSelect={e => setDateTo(e)}
                disabledDays={[{ before: dateFrom }]}
              />
            </Stack>
            <Stack justify="end" direction="row">
              {app && !app?.deleted && (
                <Button size="small" onClick={handleDelete} type="critical">
                  {polyglot.t('special_apps.remove_app')}
                </Button>
              )}
              <Button
                disabled={isError.isOn || (!cities && !countries)}
                size="small"
                onClick={handleSubmit}
              >
                {polyglot.t('special_apps.save')}
              </Button>
            </Stack>
          </Stack>
        )}
      </ModalSection>
    </Modal>
  );
};

export default DestinationDemandModal;
