import { precisionRound } from 'utils/number';

export const CATEGORY_API_CONVERTER = {
  hold_bag: 'holdBag',
  hand_bag: 'handBag',
  personal_item: 'personalItem',
  cabin_bag: 'cabinBag',
};
export const CONDITION_API_CONVERTER = {
  passenger_groups: 'passengerGroups',
  is_priority: 'isPriority',
  is_brb_baggage_bundle: 'isBrbBaggageBundle',
  eligible_passenger_ids: 'eligiblePassengerIds',
};

export const mapConditions = conditions =>
  Object.keys(conditions).reduce((acc, key) => {
    acc[CONDITION_API_CONVERTER[key]] = conditions[key];
    return acc;
  }, {});

/**
 * Definitions
 */

export const mapDefinition = (definition, index) => ({
  index,
  category: CATEGORY_API_CONVERTER[definition.category],
  restrictions: {
    dimensionsSum: definition.restrictions.dimensions_sum,
    height: definition.restrictions.height,
    length: definition.restrictions.length,
    weight: definition.restrictions.weight,
    width: definition.restrictions.width,
  },
  conditions: mapConditions(definition.conditions),
  isHold: definition.is_hold,
});

export const mapperDefinitions = definitions => {
  return {
    handBag: definitions.hand_bag.map(mapDefinition),
    holdBag: definitions.hold_bag.map(mapDefinition),
  };
};

/**
 * Combinations
 */

export const mapCombination = (combination, index) => {
  return {
    index,
    price: {
      amount: Number(combination.price.amount),
      base: Number(combination.price.base),
      service: Number(combination.price.service),
      serviceFlat: Number(combination.price.service_flat),
      currency: combination.price.currency,
      merchant: Number(combination.price.merchant),
    },
    indices: combination.indices,
    conditions: mapConditions(combination.conditions),
  };
};

export const mapperCombinations = combinations => {
  return {
    handBag: combinations.hand_bag.map(mapCombination),
    holdBag: combinations.hold_bag.map(mapCombination),
  };
};

const direct_get_from = data => {
  return {
    definitions: mapperDefinitions(data.offer.definitions),
    combinations: mapperCombinations(data.offer.combinations),
    passengerCombinations: {
      handBag: data.passenger_combinations.hand_bag,
      holdBag: data.passenger_combinations.hold_bag,
    },
    passengerSelectedCombinations: {
      handBag: data.passenger_combinations.hand_bag,
      holdBag: data.passenger_combinations.hold_bag,
    },
  };
};

const getPriceAndCombinations = (passengerId, baggage) => {
  const combinations = [];
  const price = { amount: 0, currency: 'EUR' };
  if (
    baggage.passengerCombinations.handBag[passengerId] !==
    baggage.passengerSelectedCombinations.handBag[passengerId]
  ) {
    const previousCombination = baggage.passengerCombinations.handBag[passengerId];
    const selectedCombination = baggage.passengerSelectedCombinations.handBag[passengerId];
    combinations.push({
      category: 'hand_bag',
      passenger_id: passengerId,
      combination: selectedCombination,
    });
    price.amount += baggage.combinations.handBag[selectedCombination].price.amount;

    //if customer bought bags previously, reduce price by previous bag combination
    if (baggage.combinations.handBag[previousCombination].price.amount > 0) {
      price.amount = precisionRound(
        price.amount - baggage.combinations.handBag[previousCombination].price.amount,
        2,
      );
    }
  }

  if (
    baggage.passengerCombinations.holdBag[passengerId] !==
    baggage.passengerSelectedCombinations.holdBag[passengerId]
  ) {
    const previousCombination = baggage.passengerCombinations.holdBag[passengerId];
    const selectedCombination = baggage.passengerSelectedCombinations.holdBag[passengerId];
    combinations.push({
      category: 'hold_bag',
      passenger_id: passengerId,
      combination: selectedCombination,
    });
    price.amount += baggage.combinations.holdBag[selectedCombination].price.amount;

    //if customer bought bags previously, reduce price by previous bag combination
    if (baggage.combinations.holdBag[previousCombination].price.amount > 0) {
      price.amount = precisionRound(
        price.amount - baggage.combinations.holdBag[previousCombination].price.amount,
        2,
      );
    }
  }

  price.amount = price.amount.toString();
  return [price, combinations];
};

const direct_post_to = (passengerId, baggage) => {
  const [price, combinations] = getPriceAndCombinations(passengerId, baggage);
  return {
    payment: {
      paid_by_credits: {
        price,
      },
    },
    requested_combinations: combinations,
  };
};

export default {
  direct: {
    get: {
      from: direct_get_from,
    },
    post: {
      to: direct_post_to,
    },
  },
};
