import React from 'react';

import { usePolyglot } from 'components/services/i18n';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Text from '@kiwicom/orbit-components/lib/Text';

const CompanySolutionsLoadingErrorAlert = ({ companyName }) => {
  const polyglot = usePolyglot();

  return (
    <Alert title="Something went wrong." icon type="critical" spaceAfter="large">
      <Text spaceAfter="medium">
        {polyglot.t('widgets.company_widget_solutions_fetch_error')}{' '}
        <Text as="span" weight="bold">
          {companyName}
        </Text>
        .
      </Text>
    </Alert>
  );
};

export default CompanySolutionsLoadingErrorAlert;
