import React, { useMemo, useCallback } from 'react';
import InputGroup from '@kiwicom/orbit-components/lib/InputGroup';
import Select from '@kiwicom/orbit-components/lib/Select';

const hourOptions = [...new Array(24)].map((_, i) => ({
  label: `${i}`.padStart(2, '0'),
  value: `${i}`.padStart(2, '0'),
}));
const minuteOptions = [...new Array(60)].map((_, i) => ({
  label: `${i}`.padStart(2, '0'),
  value: `${i}`.padStart(2, '0'),
}));

const SelectTime = ({ time, label, disabled, onChange, error }) => {
  const [hours, minutes] = useMemo(() => time.split(':'), [time]);

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;

      if (name === 'hours') {
        onChange(`${value}:${minutes}`);
      } else {
        onChange(`${hours}:${value}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [time],
  );

  return (
    <InputGroup size="small" flex={['1', '1']} label={label} onChange={handleChange} error={error}>
      <Select name="hours" value={hours} options={hourOptions} disabled={disabled} />
      <Select name="minutes" value={minutes} options={minuteOptions} disabled={disabled} />
    </InputGroup>
  );
};

export default SelectTime;
