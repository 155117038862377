import { useContext } from 'react';

import { convertPrice } from 'utils/price';
import { CurrenciesState } from './CurrenciesProvider';

const useCurrency = () => {
  const state = useContext(CurrenciesState);

  const getConvertedPriceString = amountInEur => {
    if (state.targetCurrencyCode === 'eur') {
      return '';
    }
    const targetCurrencyData = state.currencies.find(currency => {
      return currency.code === state.targetCurrencyCode.toLowerCase();
    });
    const convertedPrice = convertPrice(amountInEur, targetCurrencyData);
    const convertedPriceString = targetCurrencyData.format.replace('__price__', convertedPrice);

    return convertedPriceString;
  };

  const getFormattedPriceString = price => {
    const targetCurrencyData = state.currencies.find(currency => {
      return currency.code === state.targetCurrencyCode.toLowerCase();
    });
    return targetCurrencyData.format.replace('__price__', price);
  };

  return {
    ...state,
    getConvertedPriceString,
    getFormattedPriceString,
  };
};

export default useCurrency;
