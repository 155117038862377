import styled from 'styled-components';
import j2m from 'jira2md';
import * as sanitizeHtml from 'sanitize-html';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import theme from 'styles/theme';
import { SANITIZE_CONFIG } from 'consts/sanitize';

const MessageWrapper = styled.div`
  margin-bottom: ${({ spaceAfter }) => theme.orbit[spaceAfter]};
`;

const Message = ({ customer, date, message, spaceAfter }) => {
  const html = sanitizeHtml(j2m.jira_to_html(message), SANITIZE_CONFIG);

  return (
    <MessageWrapper spaceAfter={spaceAfter}>
      <Stack direction="row" justify="between" spaceAfter="medium">
        <Text>{customer}</Text>
        <Text type="secondary">{date}</Text>
      </Stack>
      <div
        style={{ color: theme.orbit.paletteInkLight, marginLeft: theme.orbit.spaceSmall }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </MessageWrapper>
  );
};

export default Message;
