import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Attachment from '@kiwicom/orbit-components/lib/icons/Attachment';
import Text from '@kiwicom/orbit-components/lib/Text';
import Tag from '@kiwicom/orbit-components/lib/Tag';

import { usePolyglot } from 'components/services/i18n';

const InputFieldWrapper = styled.div`
  input[type='text']:hover {
    cursor: default;
  }

  div {
    cursor: default;
  }
`;

const InputFileWrapper = styled.div`
  height: 32px;
  padding: 0px 12px 0px 8px;
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  background-color: ${({ theme }) => theme.orbit.paletteCloudNormal};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const InputFile = styled.input.attrs(({ type, onChange, ref }) => ({
  ref,
  type,
  onChange,
}))`
  display: none;
  input[type='text']:hover {
    cursor: default;
  }
`;

const MultipleInputFiles = ({ value, onChange, label, placeholder, error }) => {
  const polyglot = usePolyglot();
  const inputFileRef = useRef(null);

  const fileListLength = useMemo(() => value.length, [value]);

  const handleOnRemoveFile = useCallback(
    fileIndex => {
      const filesArray = Array.from(value);
      const filteredFiles = filesArray.filter((_, index) => index !== fileIndex);
      onChange(filteredFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  return (
    <InputFieldWrapper>
      <InputField
        readOnly
        label={label}
        placeholder={!fileListLength && placeholder}
        onChange={onChange}
        tags={
          fileListLength ? (
            <>
              {Array.from(value).map((file, index) => (
                <Tag
                  key={index}
                  onRemove={() => {
                    handleOnRemoveFile(index);
                  }}
                >
                  {file?.name}
                </Tag>
              ))}
            </>
          ) : null
        }
        suffix={
          <InputFileWrapper
            onClick={() => {
              inputFileRef.current.click();
            }}
          >
            <Attachment size="medium" />
            <Text>{polyglot.t('common.select_file')}</Text>
            <InputFile
              ref={inputFileRef}
              type="file"
              onChange={e => {
                const files = e.target.files;
                if (fileListLength) {
                  const updatedFileList = Array.from(value).concat([files[0]]);
                  onChange(updatedFileList);
                } else {
                  onChange(files);
                }
              }}
            />
          </InputFileWrapper>
        }
        error={error}
      />
    </InputFieldWrapper>
  );
};

export default MultipleInputFiles;
