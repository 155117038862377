import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Modal from '@kiwicom/orbit-components/lib/Modal';
import ModalHeader from '@kiwicom/orbit-components/lib/Modal/ModalHeader';
import ModalFooter from '@kiwicom/orbit-components/lib/Modal/ModalFooter';
import ModalSection from '@kiwicom/orbit-components/lib/Modal/ModalSection';
import Button from '@kiwicom/orbit-components/lib/Button';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { formatInterval } from 'utils/dates';
import { useToggle } from 'utils/hooks';
import { DAY } from 'consts/dates';
import { usePolyglot } from 'components/services/i18n';
import { BillingCyclesState } from 'components/services/billingCycles';

const BillingCyclesDeleteModal = ({ onClose, onSuccess, billingCycle }) => {
  const polyglot = usePolyglot();
  const { companyName } = useRouteMatch().params;
  const {
    state: { deleting },
    deleteBillingCycle,
  } = useContext(BillingCyclesState);
  const errorDeleteAlert = useToggle();

  const handleDelete = async () => {
    try {
      await deleteBillingCycle(companyName, billingCycle);
      onSuccess();
    } catch (error) {
      errorDeleteAlert.setOn();
    }
  };

  return (
    <Modal dataTest="delete-billing-cycle-mdoal" onClose={onClose}>
      <ModalHeader title={polyglot.t('company.billing_cycle.delete_title')} />
      <ModalSection>
        {errorDeleteAlert.isOn && (
          <Alert type="critical" closable icon onClose={errorDeleteAlert.setOff}>
            {polyglot.t('company.billing_cycle.delete_error')}
          </Alert>
        )}
        {polyglot.t('company.billing_cycle.delete_info_pre')}
        {formatInterval(billingCycle.periodStart, DAY)}
        {polyglot.t('company.billing_cycle.delete_info')}
        {formatInterval(billingCycle.periodEnd, DAY)}
        {polyglot.t('company.billing_cycle.delete_info_post')}
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" justify="end">
          <Button dataTest="cancel-button" type="secondary" onClick={onClose} disabled={deleting}>
            {polyglot.t('common.cancel')}
          </Button>
          <Button
            dataTest="delete-button"
            type="critical"
            onClick={handleDelete}
            loading={deleting}
          >
            {polyglot.t('common.delete')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default BillingCyclesDeleteModal;
