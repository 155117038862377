import React from 'react';
import styled from 'styled-components';

import { useCurrentUser } from 'components/services/auth';
import useCompanyDetails from 'components/services/company/useCompanyDetails';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useCompanyBankAccounts from 'components/services/bankAccounts/useCompanyBankAccounts';
import useCompanyUsers from 'components/services/companyUsers/useCompanyUsers';
import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import { OrbitLoader } from 'common';

import AddDetails from './scenes/AddDetails/AddDetails';
import DashboardData from './scenes/DashboardData/DashboardData';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Dashboard = () => {
  const user = useCurrentUser();
  const companyName = user.companyName;
  const companyDetails = useCompanyDetails(companyName);
  const solutions = useCompanySolutions(companyName);
  const users = useCompanyUsers(companyName);
  const bankAccounts = useCompanyBankAccounts(companyName);

  if (companyDetails.loading || solutions.loading || bankAccounts.loading || users.loading) {
    return (
      <LoaderContainer>
        <OrbitLoader id="loading-dashboard" visible />
      </LoaderContainer>
    );
  }

  const companySetUp = solutions?.data?.length > 0 && bankAccounts.data?.length > 0;

  if (companySetUp || user.role === ACCOUNT_MANAGER || user.role === KIWI_ADMIN) {
    return <DashboardData />;
  } else {
    return <AddDetails />;
  }
};

export default Dashboard;
