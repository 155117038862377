import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PageContainer, TabNavigation } from 'common';
import useBookingDetailsIframes from 'components/services/mmb/useBookingDetailsIframes';
import TransactionsProvider from 'components/services/transactions';
import * as Intercom from 'utils/intercom';

import Bookings from './scenes/Bookings';
import CreditActivity from './scenes/CreditActivity';
import BookingDetails from './scenes/BookingDetails';

const TABS = [
  {
    name: 'Bookings',
    path: '/manage-my-bookings',
    dataTest: 'navigation-bookings',
    exact: true,
  },
  {
    name: 'Credit activity',
    path: '/manage-my-bookings/credit-activity',
    dataTest: 'navigation-credit-activity',
    exact: false,
    onClick: () => Intercom.trackEvent('credit-activity-open'),
  },
];

const ManageMyBookings = () => {
  const { iframes, removeBookingIframe } = useBookingDetailsIframes();

  return (
    <>
      <TabNavigation
        basePath="/manage-my-bookings"
        tabs={TABS}
        dynamicTabs={iframes}
        removeDynamicTab={removeBookingIframe}
      />
      <TransactionsProvider>
        <PageContainer moveDown fullWidth>
          <Switch>
            <Route exact path="/manage-my-bookings" component={Bookings} />
            <Route exact path="/manage-my-bookings/credit-activity" component={CreditActivity} />
            <Route path="/manage-my-bookings/:bid" component={BookingDetails} />
          </Switch>
        </PageContainer>
      </TransactionsProvider>
    </>
  );
};

export default ManageMyBookings;
