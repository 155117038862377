import styled from 'styled-components';
import Table from '@kiwicom/orbit-components/lib/Table';

export const CompaniesTable = styled(Table)`
  overflow: visible;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterContainer = styled.div`
  width: 234px;

  &:not(:last-of-type) {
    margin-right: 12px;
  }
`;
