import React from 'react';
import PropTypes from 'prop-types';
import Polyglot from 'shapes/Polyglot';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Alert from '@kiwicom/orbit-components/lib/Alert';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Text from '@kiwicom/orbit-components/lib/Text';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';

const KiwiUserAlert = ({ polyglot }) => (
  <Stack direction="column">
    <Alert type="info" icon={<InformationCircle />}>
      <Text>{polyglot.t('support.kiwiuser.message')}</Text>
      <Text>
        {polyglot.t('support.kiwiuser.alternative')}&nbsp;
        <TextLink external href="https://skypicker.slack.com/messages/CE4DBDV51/">
          Slack(#plz-tequila)
        </TextLink>
        &nbsp;or&nbsp;
        <TextLink external href="https://jira.kiwi.com/projects/BPSD/">
          {polyglot.t('support.kiwiuser.jira')}
        </TextLink>
        .
      </Text>
    </Alert>
  </Stack>
);

KiwiUserAlert.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
};

export default KiwiUserAlert;
