import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import Polyglot from 'shapes/Polyglot';
import { STATUS } from 'redux/modules/apigee/requests';
import { proxy } from 'utils/api';

import { toCamelCase } from '../utils';

export default function TransitionButtons({
  polyglot,
  serviceDesk,
  issueId,
  clearAndFetchRequests,
}) {
  const [doTransitionStatus, setDoTransitionStatus] = useState(STATUS.CLEAR);
  const [doTransitionId, setDoTransitionId] = useState(null);
  const [transitions, setTransitions] = useState([]);

  useEffect(() => {
    proxy.v1
      .get(`jira/${serviceDesk}/request/${issueId}/transition`)
      .then(res => {
        const regex = /escalate|cancel|close|pending|to kiwi.com support|re-open/i;
        const resTransitions = res.data.transitions;
        setTransitions(resTransitions.filter(t => regex.test(t.name)));
      })
      .catch(error => {
        setTransitions([]);
      });
  }, [issueId, serviceDesk]);

  const handleTransition = transition => () => {
    const { id, name } = transition;
    if (id) {
      setDoTransitionId(id);
      setDoTransitionStatus(STATUS.LOADING);
      proxy.v1
        .post(`jira/${serviceDesk}/request/${issueId}/transition`, JSON.stringify({ id, name }))
        .then(() => {
          setDoTransitionStatus(STATUS.VALID);
          clearAndFetchRequests();
        })
        .catch(() => setDoTransitionStatus(STATUS.ERROR));
    }
  };

  return (
    <Stack direction="row" spacing="small" wrap>
      {transitions.map(transition => {
        if (!transition) {
          return null;
        }
        const { name, id } = transition;
        const isLoading = doTransitionStatus === STATUS.LOADING;
        const isThisTransitionLoading = doTransitionId === id;
        return (
          <Stack grow={false} shrink basis="15%" key={id}>
            <Button
              fullWidth
              type="secondary"
              size="small"
              onClick={handleTransition(transition)}
              loading={isThisTransitionLoading && isLoading}
              disabled={!id || (isLoading && !isThisTransitionLoading)}
            >
              {polyglot.t(`support.transition.${toCamelCase(name)}`)}
            </Button>
          </Stack>
        );
      })}
    </Stack>
  );
}

TransitionButtons.propTypes = {
  polyglot: PropTypes.shape(Polyglot).isRequired,
  serviceDesk: PropTypes.string.isRequired,
  issueId: PropTypes.string.isRequired,
  clearAndFetchRequests: PropTypes.func.isRequired,
};
