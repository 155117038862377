import React from 'react';
import PropTypes from 'prop-types';

import InputField from '@kiwicom/orbit-components/lib/InputField';
import Select from '@kiwicom/orbit-components/lib/Select';

import { RequestTypeField } from 'shapes/Support';

function CustomField({
  label,
  value,
  fieldDetails: {
    fieldId,
    jiraSchema: { type },
    validValues,
  },
  error,
  handleChange,
  setFieldValue,
  dataTest,
  readOnly,
  disabled,
}) {
  function handleNumberChange(event) {
    const { value: val } = event.target;
    if (!val || !val.trim()) {
      setFieldValue(fieldId, '');
    } else if (val.length > 10) {
      return;
    } else if (!isNaN(val)) {
      setFieldValue(fieldId, parseInt(val));
    }
  }

  if (type === 'number') {
    return (
      <InputField
        name={fieldId}
        label={label}
        onChange={handleNumberChange}
        value={value}
        error={error}
        spaceAfter="large"
        dataTest={dataTest}
      />
    );
  } else if (type === 'string') {
    return (
      <InputField
        name={fieldId}
        label={label}
        onChange={handleChange}
        value={value}
        error={error}
        spaceAfter="large"
        dataTest={dataTest}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  } else if (type === 'option') {
    return (
      <Select
        name={fieldId}
        label={label}
        onChange={e =>
          e.target.value
            ? setFieldValue(fieldId, { id: e.target.value })
            : setFieldValue(fieldId, '')
        }
        value={value ? value.id : value}
        placeholder={label}
        options={validValues}
        error={error}
        spaceAfter="large"
        dataTest={dataTest}
      />
    );
  }
}

CustomField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  fieldDetails: PropTypes.shape(RequestTypeField).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default CustomField;
