import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PageContainer, TabNavigation } from 'common';
import BillingList from './scenes/BillingList';
import ExclusionList from './scenes/ExclusionList';

const TABS = [
  {
    name: 'All companies',
    path: '/billing',
    translationString: 'billing.tabs.billing',
    dataTest: 'navigation-all-companies',
    exact: true,
  },
  {
    name: 'Excluded',
    path: '/billing/exclusions',
    translationString: 'billing.tabs.exclusions',
    dataTest: 'navigation-excluded',
    exact: false,
  },
];

const Billing = () => {
  return (
    <>
      <TabNavigation basePath="/billing" tabs={TABS} />
      <PageContainer moveDown fullWidth>
        <Switch>
          <Route exact path="/billing" component={BillingList} />
          <Route exact path="/billing/exclusions" component={ExclusionList} />
        </Switch>
      </PageContainer>
    </>
  );
};

export default Billing;
