import React, { useContext } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';

import { InputAutocomplete } from 'common';
import { isMetricSlow } from 'pages/Reports/utils';
import { usePolyglot } from 'components/services/i18n';
import { AuthState } from 'components/services/auth/AuthProvider';
import { fromApiFormat } from 'mappers/Metric';

import { ReportMetricsGlobalStyles } from './ReportMetrics.styled';

const ReportMetrics = ({ items, onChange }) => {
  const polyglot = usePolyglot();
  const { grants } = useContext(AuthState);

  const metrics = fromApiFormat(grants?.['meta.report']?.metrics?.properties);

  const handleAdd = suggestion => {
    const chosenItem = metrics.find(showItem => showItem.value === suggestion.value);
    const item = {
      value: chosenItem.value,
      label: polyglot.t(chosenItem.k),
    };
    const added = items.some(selectedItem => selectedItem.value === suggestion.value);
    if (added || !item) {
      return null;
    }

    onChange('show')(R.append(item, items));
  };

  const handleRemove = suggestion => {
    const show = R.filter(showItem => showItem.value !== suggestion.value, items);

    onChange('show')(show);
  };

  const selectedItems = items.map(({ label, value }) => ({
    label,
    value,
    icon: isMetricSlow(value) && (
      <Tooltip content={polyglot.t('reports.slow_metric_tooltip_text')} preferredPosition="bottom">
        <ClockIcon customColor="#f9971e" />
      </Tooltip>
    ),
  }));

  return (
    <>
      <ReportMetricsGlobalStyles />
      <InputAutocomplete
        label="reports.metrics"
        placeholder={polyglot.t('reports.chose_one_metric')}
        id="report-alias-metrics-autocomplete"
        type="multi"
        onSelect={handleAdd}
        handleRemove={handleRemove}
        selectedItems={selectedItems}
        items={metrics.map(({ k, value }) => ({
          label: polyglot.t(k),
          value,
          icon: isMetricSlow(value) ? ClockIcon : null,
          iconClassName: 'metrics-icon-classname',
        }))}
        size="medium"
        textInputWrapperClassName="metrics-text-input-wrapper"
        chipsClassName="metrics-chips"
        containerClassName="metrics-autocomplete-container"
        inputWrapperClassName="metrics-autocomplete-wrapper"
        className="metrics-autocomplete"
      />
    </>
  );
};

ReportMetrics.propTypes = {
  onChange: PropTypes.func.isRequired,
  alias: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportMetrics;
