import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalHeader, ModalFooter, ModalSection } from '@kiwicom/orbit-components/lib/Modal';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Textarea from '@kiwicom/orbit-components/lib/Textarea';
import Alert from '@kiwicom/orbit-components/lib/Alert';

import { SmartPassContext } from 'components/services/smartPass';
import { usePolyglot } from 'components/services/i18n';
import { getRequestsCategories } from 'redux/selectors/apigee/support';
import { fetchRequestCategories } from 'redux/modules/apigee/support';
import { useToggle } from 'utils/hooks';

const SMART_PASS_REQUEST_TYPE_NAME = 'smart pass';

const SaveForReviewModal = () => {
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [smartPassRequestTypeId, setSmartPassRequestTypeId] = useState(null);
  const findingSmartPassRequestTypeError = useToggle();

  const dispatch = useDispatch();
  const requestCategoriesResponse = useSelector(state => getRequestsCategories(state));

  useEffect(() => {
    if (!requestCategoriesResponse.requestCategories) {
      dispatch(fetchRequestCategories(`jira/bpsd/requesttype`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestCategoriesResponse.requestCategories) {
      const categoryContainingSmartPass = requestCategoriesResponse.requestCategories.find(
        category =>
          category.requestTypes.find(requestType =>
            requestType.name.toLowerCase().includes(SMART_PASS_REQUEST_TYPE_NAME),
          ),
      );

      const smartPassRequestType =
        categoryContainingSmartPass &&
        categoryContainingSmartPass.requestTypes.find(requestType =>
          requestType.name.toLowerCase().includes(SMART_PASS_REQUEST_TYPE_NAME),
        );

      if (smartPassRequestType) {
        setSmartPassRequestTypeId(smartPassRequestType.id);
      } else {
        findingSmartPassRequestTypeError.setOn();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCategoriesResponse]);

  const { requestChangeModal, state, requestChange } = useContext(SmartPassContext);

  const changeSummary = e => setSummary(e.target.value);
  const changeDescription = e => setDescription(e.target.value);

  const saveForReview = async () => {
    const res = await requestChange(summary, description, smartPassRequestTypeId);
    if (res && res.status === 201) {
      requestChangeModal.setOff();
    }
  };

  const polyglot = usePolyglot();
  return (
    <Modal closable onClose={requestChangeModal.setOff}>
      <ModalHeader
        title={polyglot.t('smart_pass.request_change_modal.title')}
        description={polyglot.t('smart_pass.request_change_modal.description')}
      />
      <ModalSection>
        {state.requestChangeError && (
          <Alert spaceAfter="largest" type="critical">
            {state.requestChangeError}
          </Alert>
        )}
        {requestCategoriesResponse.isError() && (
          <Alert type="critical" spaceAfter="largest">
            {polyglot.t('support.createRequest.error')}
          </Alert>
        )}
        {findingSmartPassRequestTypeError.isOn && (
          <Alert type="critical" spaceAfter="largest">
            {polyglot.t('smart_pass.cannot_find_smart_pass_error')}
          </Alert>
        )}
        <InputField
          spaceAfter="largest"
          label={polyglot.t('common.summary')}
          value={summary}
          onChange={changeSummary}
          disabled={requestCategoriesResponse.isError() || findingSmartPassRequestTypeError.isOn}
        />
        <Textarea
          label={polyglot.t('common.description')}
          rows={6}
          resize="none"
          value={description}
          onChange={changeDescription}
          disabled={requestCategoriesResponse.isError() || findingSmartPassRequestTypeError.isOn}
        />
      </ModalSection>
      <ModalFooter>
        <Stack direction="row" justify="between">
          <Button onClick={requestChangeModal.setOff} type="secondary" width="108px">
            {polyglot.t('common.cancel')}
          </Button>
          <Button
            onClick={saveForReview}
            loading={state.requestChangeLoading || requestCategoriesResponse.isLoading()}
            width="94px"
            disabled={
              !summary ||
              !description ||
              requestCategoriesResponse.isError() ||
              findingSmartPassRequestTypeError.isOn
            }
          >
            {polyglot.t('common.send')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default SaveForReviewModal;
