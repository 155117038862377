import React, { useState } from 'react';
import qs from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import { BACKEND_ENDPOINT, BACKEND_PROXY_ENDPOINT, USE_NEXT_GEN_ENDPOINTS } from 'consts/features';

const getValue = key => {
  try {
    return JSON.parse(localStorage.getItem(key)).value;
  } catch (error) {
    return null;
  }
};

const useFeatureToggle = feature => {
  const [_value, _setValue] = useState(getValue(feature));

  const setValue = value => {
    localStorage.setItem(feature, JSON.stringify({ value }));
    _setValue(value);
  };

  return [_value, setValue];
};

const Debug = () => {
  const loc = useLocation();
  const qp = qs.parse(loc.search);
  const [backendEndpoint, setBackendEndpoint] = useFeatureToggle(BACKEND_ENDPOINT);

  const [backendProxyEndpoint, setBackendProxyEndpoint] = useFeatureToggle(BACKEND_PROXY_ENDPOINT);

  const [nextGenEndpoints, setNextGenEndpoints] = useFeatureToggle(USE_NEXT_GEN_ENDPOINTS, false);

  if (!qp.debug) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ padding: 24, maxWidth: 600 }}>
      <Heading spaceAfter="large">Debug page</Heading>
      <Stack>
        <Heading type="title2">Feature toggle</Heading>
        <Checkbox
          size="small"
          checked={nextGenEndpoints}
          label="Use next-gen endpoints"
          onChange={e => setNextGenEndpoints(e.target.checked)}
        />
        <InputField
          size="small"
          value={backendEndpoint}
          label="Backend URL"
          onChange={e => setBackendEndpoint(e.target.value)}
        />
        <InputField
          size="small"
          value={backendProxyEndpoint}
          label="Backend Proxy URL"
          onChange={e => setBackendProxyEndpoint(e.target.value)}
        />
        <ButtonLink href="/">Back to app</ButtonLink>
      </Stack>
    </div>
  );
};

export default Debug;
