import React from 'react';
import PropTypes from 'prop-types';
import j2m from 'jira2md';
import * as sanitizeHtml from 'sanitize-html';

import Text from '@kiwicom/orbit-components/lib/Text';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';
import { SANITIZE_CONFIG } from 'consts/sanitize';

import { MarkupWrapper, AttachmentsContainer } from '../index.styled';
import Attachment from './Attachment';

const Comment = ({ text, author, datetime, attachments = null, serviceDesk, request }) => {
  const html = sanitizeHtml(j2m.jira_to_html(text), SANITIZE_CONFIG);
  const polyglot = usePolyglot();

  return (
    <Stack spaceAfter="normal">
      <Stack direction="row">
        <Text type="secondary" spaceAfter="small">
          {author}
        </Text>
        <Stack justify="end" basis="0">
          <Text type="secondary" spaceAfter="small">
            {datetime}
          </Text>
        </Stack>
      </Stack>

      <Stack direction="column">
        <Text type="primary" element="div">
          <MarkupWrapper dangerouslySetInnerHTML={{ __html: html }} />
        </Text>
        <AttachmentsContainer>
          <Stack direction="row">
            {attachments &&
              attachments?.map(attachment => (
                <Attachment
                  key={attachment?.id}
                  content={attachment?.content}
                  thumbnail={attachment?.thumbnail}
                  filename={attachment?.filename}
                  serviceDesk={serviceDesk}
                  polyglot={polyglot}
                  requestId={request.issueId}
                  align="start"
                />
              ))}
          </Stack>
        </AttachmentsContainer>
      </Stack>
      <Separator />
    </Stack>
  );
};

Comment.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
};

export default Comment;
