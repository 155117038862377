import React, { useState } from 'react';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import { Space } from 'common';
import { usePolyglot } from 'components/services/i18n';
import { useSearch } from 'components/services/search';
import { getFilteredListOfSearchFlights } from 'utils/search';

import LoadingCard from './components/LoadingCard';
import FlightCard from './components/FlightCard';
import { SearchResultsWrapper } from './components/SearchResults.styled';

const INCREMENT_RESULTS = 10;

const SearchResults = () => {
  const polyglot = usePolyglot();
  const { tabData } = useSearch();
  const [resultsShown, changeResultsShown] = useState(10);
  const searchFlightsResponse = tabData.response[tabData.tripType]?.[tabData.sortType];
  const searchStatus = tabData.searchStatus[tabData.sortType];

  const loadMoreFlights = () => {
    changeResultsShown(resultsShown + INCREMENT_RESULTS);
  };

  if (searchStatus === 'loading') {
    return (
      <SearchResultsWrapper>
        <LoadingCard />
      </SearchResultsWrapper>
    );
  }
  if (searchStatus === 'error') {
    return (
      <SearchResultsWrapper>
        <Stack spacingAfter="large">
          <Alert type="critical">
            {polyglot.t('searchAndBooking.searchResults.error_message')}
          </Alert>
        </Stack>
      </SearchResultsWrapper>
    );
  }

  const filteredListOfSearchFlights = getFilteredListOfSearchFlights(tabData);
  if (searchFlightsResponse) {
    if (filteredListOfSearchFlights.length === 0 && searchStatus === 'success') {
      return (
        <SearchResultsWrapper>
          <Stack spacingAfter="large">
            <Alert>{polyglot.t('searchAndBooking.searchResults.no_trips')}</Alert>
          </Stack>
        </SearchResultsWrapper>
      );
    }
    return (
      <SearchResultsWrapper>
        {filteredListOfSearchFlights.slice(0, resultsShown).map(result => (
          <Space key={result.id} after="l" data-test={result.booking_token}>
            <FlightCard searchResult={result} tripType={tabData.tripType} />
          </Space>
        ))}
        {resultsShown < filteredListOfSearchFlights.length && (
          <Button
            block
            loading={searchStatus === 'loading'}
            onClick={loadMoreFlights}
            type="primary"
            spaceAfter="largest"
          >
            {polyglot.t('common.load_more')}
          </Button>
        )}
      </SearchResultsWrapper>
    );
  }

  return null;
};

export default SearchResults;
