import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 880px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AlertContainer = styled.div`
  margin: 8px 0;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterContainer = styled.div`
  width: 400px;
  margin-right: 15px;
`;
