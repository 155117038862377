import { useContext, useEffect } from 'react';
import { SolutionsState } from './';
import useApiProducts from 'components/services/products/useApiProducts';

const useCompanySolutions = companyName => {
  const { state, loadCompanySolutions } = useContext(SolutionsState);
  const apiProducts = useApiProducts();

  useEffect(() => {
    if (!state[companyName] && !apiProducts.loading) {
      loadCompanySolutions(companyName);
    }
    // eslint-disable-next-line
  }, [companyName, apiProducts.loading]);

  return state[companyName] || { loading: true, data: null, error: null };
};

export default useCompanySolutions;
