import React from 'react';
import { Link } from 'react-router-dom';

import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import NewWindow from '@kiwicom/orbit-components/lib/icons/NewWindow';

import { ROUTE_CONFIG } from 'consts/routes';
import { usePolyglot } from 'components/services/i18n';

const AccessDeniedErrorMessage = () => {
  const polyglot = usePolyglot();

  return (
    <>
      {polyglot.t('add_user.access_denied_reason')}{' '}
      <Link to={ROUTE_CONFIG.SUPPORT.path}>
        <TextLink iconRight={<NewWindow size="small" />}>{polyglot.t('common.help')}</TextLink>
      </Link>
      .
    </>
  );
};

export default AccessDeniedErrorMessage;
