import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';

import ClosableAlert from './ClosableAlert';

const FirstSaveAlert = () => {
  const polyglot = usePolyglot();

  return (
    <>
      <ClosableAlert type="success" spaceAfter="normal" timeout icon>
        {polyglot.t('widgets.create_success')}
      </ClosableAlert>
      <ClosableAlert
        title={polyglot.t('widgets.create_success_info_title')}
        spaceAfter="large"
        closable
        okButton
        type="info"
        icon
      >
        <Text spaceAfter="large">{polyglot.t('widgets.create_success_info_desc')}</Text>
      </ClosableAlert>
    </>
  );
};

export default FirstSaveAlert;
