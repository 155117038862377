import React, { useState, useEffect, useReducer, useContext } from 'react';

import { useWindowSize } from 'hooks';
import { ROUTE_CONFIG, secondaryNavigationItems } from 'consts/routes';
import { InitialQueryParamsState } from 'components/services/initialQueryParams';
import useOnboardingState from '../localStorage/useOnboardingState';

const HIDE_NAVIGATION = ['smartpoint'];

export const MenuState = React.createContext({
  navigationExpanded: false,
  secondaryNavigation: false,
  menu: secondaryNavigationItems,
  loading: true,
  loadUserPreferences: email => [email],
  addUserPreference: (user, item) => [user, item],
});

const resizeWidth = 1280;

const menuReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_USER_PREFERENCES': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'LOAD_USER_PREFERENCES_SUCCESS': {
      return {
        ...state,
        loading: false,
        menu: action.menu,
      };
    }
    case 'LOAD_USER_PREFERENCES_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.error,
        menu: action.menu,
      };
    }
    case 'ADD_USER_PREFERENCE': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'ADD_USER_PREFERENCE_SUCCESS': {
      return {
        ...state,
        loading: false,
        menu: action.menu,
      };
    }
    case 'ADD_USER_PREFERENCE_FAILURE': {
      return { ...state, loading: false, error: action.error };
    }
    case 'REMOVE_USER_PREFERENCE': {
      return { ...state, loading: true, error: null };
    }
    case 'REMOVE_USER_PREFERENCE_SUCCESS': {
      return { ...state, loading: false, menu: action.menu };
    }
    case 'REMOVE_USER_PREFERENCE_FAILURE': {
      return { ...state, menu: action.menu, error: action.error };
    }
    case 'SHOW_ONBOARDING_IN_TOOLS': {
      return {
        ...state,
        menu: [ROUTE_CONFIG.GETTING_STARTED, ...state.menu],
      };
    }
    case 'HIDE_ONBOARDING_IN_TOOLS': {
      return {
        ...state,
        menu: state.menu.filter(i => i.name !== ROUTE_CONFIG.GETTING_STARTED.name),
      };
    }
    default:
      return state;
  }
};
const MenuProvider = ({ children }) => {
  const windowSize = useWindowSize();
  const { ui } = useContext(InitialQueryParamsState);
  const [onboardingState, setOnboardingState] = useOnboardingState();

  const isLargeScreen = windowSize.width >= resizeWidth;

  const [navigationExpanded, setNavigationExpanded] = useState(isLargeScreen);
  const [isNavigationShown, setIsNavigationShown] = useState(!HIDE_NAVIGATION.includes(ui));
  const [secondaryNavigation, setSecondaryNavigation] = useState(false);
  const [isHeaderShown, setIsHeaderShown] = useState(!HIDE_NAVIGATION.includes(ui));

  useEffect(() => {
    if (isLargeScreen) {
      setNavigationExpanded(true);
    } else {
      setNavigationExpanded(false);
    }
  }, [isLargeScreen]);

  const expandNavigation = () => {
    setNavigationExpanded(true);
  };

  const collapseNavigation = () => {
    setNavigationExpanded(false);
    setSecondaryNavigation(false);
  };

  const toggleNavigation = () => {
    setNavigationExpanded(b => !b);
  };

  const toggleSecondaryNavigation = () => {
    setSecondaryNavigation(b => !b);
  };
  const showNavigation = () => {
    setIsNavigationShown(true);
  };

  const hideNavigation = () => {
    setIsNavigationShown(false);
  };
  const showHeader = () => {
    setIsHeaderShown(true);
  };

  const hideHeader = () => {
    setIsHeaderShown(false);
  };

  const [menuState, menuDispatch] = useReducer(menuReducer, {
    menu: secondaryNavigationItems,
  });

  const loadUserPreferences = user => {
    menuDispatch({ type: 'LOAD_USER_PREFERENCES' });

    let onboarding;
    try {
      onboarding = JSON.parse(localStorage.getItem(`${user}_onboarding`));
    } catch (_) {}

    try {
      const serializedState = window.localStorage.getItem(user);

      if (serializedState === null) {
        let menu = menuState.menu;
        if (onboarding?.shownInTools) {
          menu = [ROUTE_CONFIG.GETTING_STARTED, ...menu];
        }

        menuDispatch({ type: 'LOAD_USER_PREFERENCES_SUCCESS', menu });
      } else {
        let navOptions = secondaryNavigationItems;

        if (onboarding?.shownInTools && !navOptions.find(o => o.name.includes('getting_started'))) {
          navOptions = [ROUTE_CONFIG.GETTING_STARTED, ...navOptions];
        }

        const selectedMenu = JSON.parse(serializedState);
        const menu = navOptions.filter(item => {
          return !!selectedMenu.find(option => option.name === item.name);
        });

        return menuDispatch({
          type: 'LOAD_USER_PREFERENCES_SUCCESS',
          menu,
        });
      }
    } catch (error) {
      menuDispatch({ type: 'LOAD_USER_PREFERENCES_FAILURE', error, menu: menuState.menu });
    }
  };

  const addUserPreference = (user, item) => {
    const menu = menuState.menu;
    if (item.name === ROUTE_CONFIG.GETTING_STARTED.name) {
      setOnboardingState({
        ...onboardingState,
        shownInTools: true,
      });
    }

    if (menu.find(option => option.name === item.name)) {
      return menuState;
    }

    menuDispatch({ type: 'ADD_USER_PREFERENCE' });

    try {
      if (item.name === ROUTE_CONFIG.GETTING_STARTED.name) {
        menu.unshift(item);
      } else {
        menu.push(item);
      }

      window.localStorage.setItem(user.email, JSON.stringify(menu));
      return menuDispatch({ type: 'ADD_USER_PREFERENCE_SUCCESS', menu });
    } catch (error) {
      menuDispatch({ type: 'ADD_USER_PREFERENCE_FAILURE', error });
    }
  };

  const removeUserPreference = (user, item) => {
    if (item.name === ROUTE_CONFIG.GETTING_STARTED.name) {
      setOnboardingState({
        ...onboardingState,
        shownInTools: false,
      });
    }

    menuDispatch({ type: 'REMOVE_USER_PREFERENCE' });
    const menu = menuState.menu.filter(option => option.name !== item.name);
    try {
      window.localStorage.setItem(user.email, JSON.stringify(menu));
      return menuDispatch({ type: 'REMOVE_USER_PREFERENCE_SUCCESS', menu });
    } catch (error) {
      menuDispatch({ type: 'REMOVE_USER_PREFERENCE_FAILURE', error });
    }
  };

  const value = {
    ...menuState,
    navigationExpanded,
    secondaryNavigation,
    isLargeScreen,
    expandNavigation,
    collapseNavigation,
    toggleNavigation,
    toggleSecondaryNavigation,
    loadUserPreferences,
    addUserPreference,
    removeUserPreference,
    isNavigationShown,
    showNavigation,
    hideNavigation,
    isHeaderShown,
    showHeader,
    hideHeader,
  };

  return <MenuState.Provider value={value}>{children}</MenuState.Provider>;
};

export default MenuProvider;
