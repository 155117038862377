import React, { useEffect, useRef } from 'react';

import { usePolyglot } from 'components/services/i18n';
import useCurrentUser from 'components/services/auth/useCurrentUser';
import useSelectedCompany from 'components/services/companies/useSelectedCompany';
import useSolutionOptions from 'components/services/solutions/useSolutionOptions';
import useCompanySolutions from 'components/services/solutions/useCompanySolutions';
import useApiProducts from 'components/services/products/useApiProducts';
import CreateSolutionProvider from 'components/scenes/MySolutions/services/CreateSolution';
import SolutionsModal from 'components/scenes/MySolutions/components/SolutionsModal';
import { PageContainer, SelectSolutionsCompany } from 'common';
import { Authorization } from 'common/Authorization';
import { ACCOUNT_MANAGER, KIWI_ADMIN } from 'consts/roles';
import { DEEPLINK_GENERATOR } from 'consts/products';
import { DEEPLINKS } from 'consts/solutions';
import * as Intercom from 'utils/intercom';

import { useToggle } from 'utils/hooks';
import NoResults from './components/NoResults';

const ENABLES = [DEEPLINK_GENERATOR];

const appendDeeplinkScript = affilids => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'deeplink-generator-script';
  script.setAttribute('data-container-id', 'deeplink-generator-holder');
  script.setAttribute('data-iframe-id', 'deeplink-generator');
  script.setAttribute('data-create-solution-button', 'true');
  script.setAttribute('data-affilid', affilids);
  script.src = `https://widgets.kiwi.com/scripts/deeplink-generator-iframe.js`;
  document.body.appendChild(script);
};

const DeeplinkGenerator = () => {
  const polyglot = usePolyglot();
  const user = useCurrentUser();
  const { selectedCompany, selectCompany } = useSelectedCompany(user.companyName);
  const { solutionOptions } = useSolutionOptions(selectedCompany, ENABLES);
  const solutions = useRef([]);
  const modal = useToggle(false);
  // needed for loading states
  const companySolutions = useCompanySolutions(selectedCompany);
  const apiProducts = useApiProducts();
  const areAllSolutionsDisabled = solutionOptions.every(solutionOption => solutionOption.disabled);

  const handleSelectCompany = companyName => {
    selectCompany(companyName);
    removeScript();
  };

  const handleMessage = ev => {
    if (ev.data.createSolution) {
      modal.setOn();
    }
    if (ev.data.code) {
      Intercom.trackEvent('copy-deeplink-code', {
        deeplink: ev.data.code,
      });
    }
  };

  const removeScript = () => {
    const script = document.getElementById('deeplink-generator-script');
    if (script) {
      document.body.removeChild(script);
      window.removeEventListener('message', handleMessage);
    }
  };

  useEffect(() => {
    const isScriptMounted = document.getElementById('deeplink-generator-script');

    // if script wasn't mounted and solutions loaded or new solution was created
    if (
      solutionOptions.length &&
      (!isScriptMounted || solutions.current.length !== solutionOptions.length)
    ) {
      solutions.current = solutionOptions;

      if (areAllSolutionsDisabled) {
        // if all solutions are disabled
        return;
      }

      const affilids = solutionOptions.reduce((acc, op) => {
        if (!op.disabled) {
          try {
            const parsedSolution = JSON.parse(op.value);
            return [...acc, { label: parsedSolution.name, value: parsedSolution.affilId }];
          } catch (e) {
            return [...acc];
          }
        }
        return [...acc];
      }, []);

      const stringifiedAffilids = JSON.stringify(affilids);

      appendDeeplinkScript(stringifiedAffilids);

      window.addEventListener('message', handleMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionOptions]);

  useEffect(() => {
    return () => {
      removeScript();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (companySolutions.loading || apiProducts.loading) {
    return null;
  }

  return (
    <PageContainer>
      <CreateSolutionProvider preselectedStep={DEEPLINKS}>
        {modal.isOn && <SolutionsModal onClose={modal.setOff} onSuccess={modal.setOff} />}
      </CreateSolutionProvider>
      {(user.role === KIWI_ADMIN || user.role === ACCOUNT_MANAGER) && (
        <SelectSolutionsCompany onSelect={handleSelectCompany} />
      )}
      {solutionOptions.length && !areAllSolutionsDisabled ? (
        <div id="deeplink-generator-holder"></div>
      ) : (
        <Authorization
          resource="model.application"
          action={['create', 'create_assigned', 'create_own']}
          fallback={
            <NoResults
              illustrationName="NoResults"
              title={polyglot.t('deeplink_generator.no_results')}
              subtitle={polyglot.t('deeplink_generator.no_deeplinks')}
            />
          }
        >
          <NoResults
            illustrationName="Train"
            title={polyglot.t('deeplink_generator.almost_there')}
            subtitle={polyglot.t('deeplink_generator.first_solution')}
            buttonText={polyglot.t('deeplink_generator.new_solution')}
            handleOnClick={modal.setOn}
          />
        </Authorization>
      )}
    </PageContainer>
  );
};

export default React.memo(DeeplinkGenerator);
