import { isStaging } from 'utils/env';

const direct_put_to = (currency, sessionId) => {
  return {
    currency,
    is_sandbox: isStaging(),
    ...(sessionId && { session_id: sessionId }),
  };
};

const direct_put_from = data => {
  return {
    basketId: data.basket_id,
    currency: data.currency,
    isSandbox: data.is_sandbox,
    isValid: data.is_valid,
    items: {
      handlingFee: data.items.handling_fee.map(fee => ({
        price: {
          amount: Number(fee.price.amount),
          base: Number(fee.price.base),
          currency: fee.price.currency,
          merchant: Number(fee.price.merchant),
          service: Number(fee.price.service),
          serviceFlat: Number(fee.price.service_flat),
        },
      })),
      ...(data.items.seating
        ? {
            seating: data.items.seating.map(seat => ({
              option: seat.option,
              passengerId: seat.passenger_id,
              price: {
                amount: Number(seat.price.amount),
                base: Number(seat.price.base),
                currency: seat.price.currency,
                merchant: Number(seat.price.merchant),
                service: Number(seat.price.service),
                serviceFlat: Number(seat.price.service_flat),
              },
              seatsInOption: seat.seats_in_option.map(seatInOption => seatInOption),
              segmentCode: seat.segment_code,
            })),
          }
        : {}),
    },
    status: data.status,
    totalPrice: {
      amount: Number(data.amount),
      base: Number(data.base),
      currency: data.currency,
      merchant: Number(data.merchant),
      service: Number(data.service),
      serviceFlat: Number(data.service_flat),
    },
    validationError: data.validation_error,
  };
};

export default {
  direct: {
    put: {
      to: direct_put_to,
      from: direct_put_from,
    },
  },
};
