import React, { useCallback } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import CalendarIcon from '@kiwicom/orbit-components/lib/icons/Calendar';
import AirplaneUpIcon from '@kiwicom/orbit-components/lib/icons/AirplaneUp';
import BusIcon from '@kiwicom/orbit-components/lib/icons/Bus';
import TrainIcon from '@kiwicom/orbit-components/lib/icons/Train';

import CarriersStack from '../CarriersStack';

import { usePolyglot } from 'components/services/i18n';
import { formatTripDuration } from 'utils/dates';
import { fareCategoryStrings } from 'consts/search';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorCard};
  border-radius: 3px;
  padding: 16px;
`;

const FlightNumberDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    margin-bottom: 2px;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.orbit.paletteCloudDark};
  border-radius: 15px;
  padding: 4px 8px;

  & + & {
    margin-left: 6px;
  }
`;

const FlightNumberWrapper = styled.div`
  display: flex;
`;

const TripDatetimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CarrierDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CarrierData = styled.div`
  display: flex;

  & > p:first-of-type {
    margin-right: 4px;
  }
`;

const HighlightedPlace = styled.span`
  color: ${({ theme }) => theme.orbit.colorTextCritical};
  text-decoration: underline;
`;

const vehicleIcons = {
  aircraft: AirplaneUpIcon,
  bus: BusIcon,
  train: TrainIcon,
};

const TripSummaryFlightDetails = ({
  vehicleType,
  flightNumber,
  departurePlace,
  departureTime,
  arrivalPlace,
  arrivalTime,
  fareCategory,
  carrier,
  carrierName,
  duration,
  previousSegment,
  dataTest,
  scene,
}) => {
  const VehicleIcon = vehicleIcons[vehicleType];
  const polyglot = usePolyglot();

  const extractDateWithoutTimezone = useCallback(
    (date, dateFormat) => {
      let time;
      //different time formats from booking and mmb api
      if (scene === 'mmb') {
        //MMB
        time = date.slice(0, -6);
      } else {
        //Booking tool
        time = date.slice(0, -5);
      }
      return format(new Date(time), dateFormat);
    },
    [scene],
  );

  const getHoursFromString = date => {
    return date.substr(date.indexOf('T') + 1, 5);
  };

  const differentLayoverAirport =
    previousSegment && previousSegment.arrival.name !== departurePlace;

  return (
    <Container data-test={dataTest}>
      <Stack>
        <FlightNumberDetails>
          <TextIcon>
            <CalendarIcon size="small" />
            <Text text="primary" size="small" dataTest="departure-date">
              {extractDateWithoutTimezone(departureTime, 'MMM dd yyyy')}
            </Text>
          </TextIcon>

          <FlightNumberWrapper>
            <Text text="primary" size="small" as={Badge} dataTest="flight-number">
              {flightNumber}
            </Text>
            <Badge>
              <VehicleIcon size="small" />
            </Badge>
          </FlightNumberWrapper>
        </FlightNumberDetails>

        <TripDatetimeContainer>
          <div>
            {differentLayoverAirport ? (
              <Tooltip
                content={`Warning: you’ll depart from a different place. You will arrive at ${previousSegment.arrival.name} and depart from ${departurePlace}`}
                preferredPosition="right"
                removeUnderlinedText
              >
                <Text size="small" dataTest="departure-time">
                  {getHoursFromString(departureTime)}{' '}
                  <HighlightedPlace>{departurePlace}</HighlightedPlace>
                </Text>
              </Tooltip>
            ) : (
              <Text text="primary" size="small" dataTest="departure-time">
                {getHoursFromString(departureTime)} {departurePlace}
              </Text>
            )}
            <Text text="primary" size="small" dataTest="arrival-time">
              {getHoursFromString(arrivalTime)} {arrivalPlace}
            </Text>
          </div>

          <Text text="primary" size="small" as={Badge} dataTest="trip-time">
            {formatTripDuration(duration / 1000)}
          </Text>
        </TripDatetimeContainer>

        <CarrierDataContainer>
          <div>
            {carrierName && (
              <CarrierData data-test="carrier">
                <Text type="secondary" size="small">
                  {polyglot.t('common.trip_summary_flight.carrier')}{' '}
                </Text>
                <Text type="primary" size="small">
                  {' '}
                  {carrierName}
                </Text>
              </CarrierData>
            )}
            {fareCategory && (
              <CarrierData data-test="class">
                <Text type="secondary" size="small">
                  {polyglot.t('common.trip_summary_flight.class')}{' '}
                </Text>
                <Text type="primary" size="small">
                  {fareCategoryStrings[fareCategory]}
                </Text>
              </CarrierData>
            )}
          </div>
          <CarriersStack carriers={[carrier]} size="medium" />
        </CarrierDataContainer>
      </Stack>
    </Container>
  );
};

export default TripSummaryFlightDetails;
