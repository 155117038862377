import { combineReducers } from 'redux';

import i18n from 'redux/modules/i18n';
import reportAliases from 'redux/modules/reportAliases';
import confirmDialog from 'redux/modules/confirmDialog';
import messages from 'redux/modules/messages';
import reports from 'redux/modules/reports';

// Apigee Stuff
import apigeeCustomerSupport from 'redux/modules/apigee/customerSupport';
import apigeeSupport from 'redux/modules/apigee/support';

export default combineReducers({
  i18n,
  reportAliases,
  confirmDialog,
  messages,
  reports,
  apigeeSupport,
  apigeeCustomerSupport,
});
