import React from 'react';

import Box from '@kiwicom/orbit-components/lib/Box';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';

import { usePolyglot } from 'components/services/i18n';
import { PassengerRowWrapper } from './index.styled';

const SeatPopoverPassenger = ({ underlined, passenger, selectedSeatName, onPassengerClick }) => {
  const polyglot = usePolyglot();

  return (
    <Stack direction="column" align="center" spacing="none">
      <PassengerRowWrapper>
        <Box padding={{ right: 'medium' }}>
          <Stack direction="row" align="center" spacing="none" spaceAfter="none" justify="between">
            <Stack direction="row" align="center" spacing="XSmall" shrink>
              <Text type="primary">{passenger.firstName + ' ' + passenger.lastName}</Text>
              {selectedSeatName && (
                <>
                  <Text type="secondary">·</Text>
                  <Text type="secondary">{selectedSeatName}</Text>
                </>
              )}
            </Stack>
            <Button type="primarySubtle" size="small" onClick={onPassengerClick}>
              {selectedSeatName
                ? polyglot.t('booking.additional_services.seating.seat_map.change')
                : polyglot.t('booking.additional_services.seating.seat_map.select')}
            </Button>
          </Stack>
        </Box>
      </PassengerRowWrapper>
      {!underlined && <Separator spaceAfter="none" />}
    </Stack>
  );
};

export default SeatPopoverPassenger;
