import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form/dist/index.ie11';

import Box from '@kiwicom/orbit-components/lib/Box';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import useBooking from 'components/services/booking/useBooking';
import { usePolyglot } from 'components/services/i18n';
import { getSegmentFormattedSeatMapData } from 'utils/seating';

import Legend from './components/Legend';
import SeatRow from './components/SeatRow';

export const SeatMap = ({ routeId }) => {
  const polyglot = usePolyglot();
  const { watch } = useFormContext();
  const { passengers } = watch();
  const isSinglePassenger = passengers.length === 1;
  const {
    seatingOfferData: { offers },
  } = useBooking();

  const formattedSeatMapRows = useMemo(
    () => getSegmentFormattedSeatMapData(offers, routeId),
    [offers, routeId],
  );

  return (
    <Card>
      <CardSection>
        <Stack justify="center" align="center" direction="column" spacing="XSmall">
          <Text weight="bold" size="large">
            {isSinglePassenger
              ? polyglot.t('booking.additional_services.seating.seat_map.select_a_seat_on_the_map')
              : polyglot.t(
                  'booking.additional_services.seating.seat_map.select_a_seats_on_the_map',
                )}
          </Text>
          <Legend routeId={routeId} />
          <Box>
            {formattedSeatMapRows.map((row, i) => (
              <SeatRow key={`Seat-Row-${i}`} row={row} activeSegmentCode={routeId} />
            ))}
          </Box>
        </Stack>
      </CardSection>
    </Card>
  );
};

export default SeatMap;
