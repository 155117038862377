import styled from 'styled-components';

const TableFoot = styled.tfoot`
  background-color: ${({ theme }) => theme.orbit.paletteCloudNormal};
  color: ${({ theme }) => theme.orbit.paletteInkDark};

  tr td:first-of-type {
    padding-left: 24px;
  }
`;

export default TableFoot;
