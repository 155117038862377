import styled from 'styled-components';

export const RadioWrapper = styled.div`
  width: 180px;
  margin: 10px 10px 0 0;
  position: relative;
  input {
    cursor: pointer;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
`;

export const DropdownIconContainer = styled.span`
  margin: 0 8px 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.orbit.colorIconSecondary};
`;
