import React, { useEffect } from 'react';

import Stack from '@kiwicom/orbit-components/lib/Stack';

import { usePolyglot } from 'components/services/i18n';

import { DateInput, SelectNationality } from 'common';

const AdditionalInfo = ({
  isAdditionalInfoNeeded,
  setFieldValue,
  setTouched,
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
}) => {
  const polyglot = usePolyglot();

  useEffect(() => {
    setFieldValue('isAdditionalInfoNeeded', true);
    setTouched({ ...touched, dateOfBirth: false, nationality: false }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdditionalInfoNeeded]);

  return (
    <Stack direction="row" spacing="XSmall">
      <DateInput
        name="dateOfBirth"
        value={values.dateOfBirth}
        label={polyglot.t('common.date_of_birth')}
        setFieldValue={setFieldValue}
        onBlur={handleBlur}
        error={touched.dateOfBirth && errors.dateOfBirth && polyglot.t(errors.dateOfBirth)}
      />
      <SelectNationality
        id="nationality"
        label={polyglot.t('common.nationality')}
        onChange={handleChange}
        onBlur={handleBlur}
        name="nationality"
        value={values.nationality}
        error={touched.nationality && errors.nationality && polyglot.t(errors.nationality)}
      />
    </Stack>
  );
};

export default AdditionalInfo;
