import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTE_CONFIG } from 'consts/routes';
import PartnerPaymentsList from './scenes/PartnerPaymentsList/';
import PartnerPaymentDetails from './scenes/PartnerPaymentsDetails/';

const PartnerPayments = () => (
  <Switch>
    <Route exact path={ROUTE_CONFIG.PARTNER_PAYMENTS.path} component={PartnerPaymentsList} />
    <Route path={ROUTE_CONFIG.PARTNER_PAYMENT.path} component={PartnerPaymentDetails} />
  </Switch>
);

export default PartnerPayments;
