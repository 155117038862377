import React, { useEffect } from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

import { useToggle } from 'utils/hooks';

import { SnackbarContainer } from './Snackbar.styled';

const SnackBar = ({ onDismiss, toasts }) => {
  const visible = useToggle(false);

  useEffect(() => {
    visible.setOn();
    const timer = setTimeout(() => handleClose(), 1800);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [onDismiss, toasts]);

  const handleClose = () => {
    onDismiss();
    visible.setOff();
  };

  if (toasts.length === 0) {
    return null;
  }

  return (
    <SnackbarContainer visible={visible}>
      <Text type="white" align="center">
        {toasts[toasts.length - 1].text}
      </Text>
    </SnackbarContainer>
  );
};

export default SnackBar;
