import format from 'date-fns/format';

import { tripTypesValues } from 'consts/search';
import { parseLocations, getParsedPassengersAmounts } from 'utils/search';
import { DAY_MONTH_YEAR_FORMAT } from 'consts/dates';

const direct_post_to = (tabData, sortType) => {
  const { trips } = tabData.searchbar[tripTypesValues.MULTICITY];
  const parsedPassengersAmounts = getParsedPassengersAmounts(tabData.passengersAmounts);
  return {
    query: {
      curr: tabData.currency,
      sort: sortType,
    },
    body: {
      adults: parsedPassengersAmounts.adults,
      children: parsedPassengersAmounts.children,
      infants: parsedPassengersAmounts.infants,
      selected_cabins: tabData.selectedCabinClassType,
      vehicle_type: tabData.vehicleTypes.join(','),
      via_blocks: trips.map(trip => {
        const dep_date_range = [
          trip.departureSelectedDates[0],
          trip.departureSelectedDates[trip.departureSelectedDates.length - 1],
        ];
        return {
          max_sector_stopovers: tabData.numberOfStopovers,
          fly_from: parseLocations(trip.locationsFrom, false),
          fly_to: parseLocations(trip.locationsTo, false),
          dep_date_range: dep_date_range.map(d => format(new Date(d), DAY_MONTH_YEAR_FORMAT)),
        };
      }),
    },
  };
};

export default {
  direct: {
    get: {
      to: direct_post_to,
    },
  },
};
