import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  display: flex;

  border-bottom: 1px solid ${({ theme }) => theme.orbit.paletteCloudNormal};

  & > div {
    flex: 1;
  }

  & > div:not(:last-of-type) {
    margin-right: 16px;
  }

  .date-filter-override {
    min-width: 290px;
  }
`;

export const FakeButton = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.orbit.borderColorInput};
  border-radius: 3px;
  padding: 6px;
  padding-left: 12px;
  align-items: center;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    flex: 1;

    & > label {
      color: ${({ theme }) => theme.orbit.colorTextSecondary};
      margin-right: 12px;
      white-space: nowrap;
    }

    & > div {
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const PopoverContent = styled.div`
  padding: 16px;
  height: 372px;
  max-height: 372px;
  overflow: auto;
  box-sizing: border-box;
`;

export const PlaceholderValue = styled.div`
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
`;

export const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 32px);
`;
