import React from 'react';

import SeatIcon from '@kiwicom/orbit-components/lib/icons/Seat';
import Inline from '@kiwicom/orbit-components/lib/Inline';
import Text from '@kiwicom/orbit-components/lib/Text';

import useSeating from 'components/services/bookingToolSeating/useSeating';
import { usePolyglot } from 'components/services/i18n';
import { formatMultiSeats } from 'utils/seating';

const SelectedSeats = ({ routeId }) => {
  const polyglot = usePolyglot();
  const { currentSeatings } = useSeating();
  const segment = currentSeatings[routeId];

  if (segment && segment.option) {
    return (
      <Inline spacing="XXSmall" align="center">
        <SeatIcon size="small" />
        <Text type="secondary">
          {polyglot.t(`booking_tool.seating.drawer.no_seatmap.quick_option.${segment.option}`)}
        </Text>
      </Inline>
    );
  } else if (segment && !segment.option && Object.keys(segment).length) {
    return (
      <Inline spacing="XXSmall" align="center">
        <SeatIcon size="small" />
        <Text type="secondary">{formatMultiSeats(segment)}</Text>
      </Inline>
    );
  }

  return null;
};

export default SelectedSeats;
