import styled from 'styled-components';

export const PassengerNumberWrapper = styled.div`
  min-width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 8px;

  &:nth-child(3) {
    margin-bottom: 0;
  }
`;

export const PassengerPopoverWrapper = styled.div`
  padding: 16px;
`;
