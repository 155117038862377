import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import CloseIcon from '@kiwicom/orbit-components/lib/icons/CloseCircle';

import { usePolyglot } from 'components/services/i18n';

import { TabLinksWrapper, TabLink, TabIconContainer } from './TabNavigation.styled';

const TabNavigation = React.memo(({ basePath, tabs, dynamicTabs, removeDynamicTab }) => {
  const location = useLocation();
  const history = useHistory();
  const polyglot = usePolyglot();

  return (
    <TabLinksWrapper>
      {tabs.map(tab => {
        return (
          <TabLink
            key={tab.path}
            to={`${tab.path}`}
            data-test={tab.dataTest}
            active={
              tab.exact ? location.pathname === tab.path : location.pathname.includes(tab.path)
            }
            onClick={tab.onClick}
          >
            {tab.translationString ? polyglot.t(tab.translationString) : tab.name}
          </TabLink>
        );
      })}

      {dynamicTabs?.map(tabName => (
        <TabLink
          key={tabName}
          to={`${basePath}/${tabName}`}
          data-test={`tab-${tabName}`}
          active={location.pathname.includes(`${basePath}/${tabName}`)}
        >
          {tabName}
          <TabIconContainer
            onClick={e => {
              e.preventDefault();
              removeDynamicTab(tabName);
              //is currently open bid the same as clicked "iframe" bid
              if (+location.pathname.slice(basePath.length + 1) === tabName) {
                history.goBack();
              }
            }}
          >
            <CloseIcon size="small" />
          </TabIconContainer>
        </TabLink>
      ))}
    </TabLinksWrapper>
  );
});

export default TabNavigation;
