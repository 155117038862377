import React from 'react';

import { useToggle } from 'utils/hooks';

import Alert from '@kiwicom/orbit-components/lib/Alert';

const ClosableAlert = ({ children, ...props }) => {
  const show = useToggle(true);

  return (
    show.isOn && (
      <Alert {...props} onClose={() => show.setOff()} closable>
        {children}
      </Alert>
    )
  );
};

export default ClosableAlert;
