import React from 'react';
import * as R from 'ramda';

import Alert from '@kiwicom/orbit-components/lib/Alert';
import Button from '@kiwicom/orbit-components/lib/Button';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';

import { usePolyglot } from 'components/services/i18n';
import {
  legalFormKeys,
  generalFormKeys,
  fastTrackFormKeys,
  transferAssistanceFormKeys,
  bagTransferAssistFormKeys,
  vouchersFormKeys,
} from 'consts/smartPass';
import { useToggle } from 'utils/hooks';

const ErrorsList = ({ errors }) => {
  const polyglot = usePolyglot();
  const saveAsDraftReminder = useToggle(true);

  const errorKeys = R.keys(errors);
  const legalListItem = R.intersection(errorKeys, legalFormKeys).length > 0;
  const generalListItem = R.intersection(errorKeys, generalFormKeys).length > 0;
  const fastTrackListItem = R.intersection(errorKeys, fastTrackFormKeys).length > 0;
  const transferAssistanceListItem =
    R.intersection(errorKeys, transferAssistanceFormKeys).length > 0;
  const bagTransferAssistListItem = R.intersection(errorKeys, bagTransferAssistFormKeys).length > 0;
  const vouchersListItem = R.intersection(errorKeys, vouchersFormKeys).length > 0;

  const isListShown =
    legalListItem ||
    generalListItem ||
    fastTrackListItem ||
    transferAssistanceListItem ||
    bagTransferAssistListItem ||
    vouchersListItem;
  return (
    <div>
      {saveAsDraftReminder.isOn && isListShown && (
        <Alert
          spaceAfter="medium"
          type="critical"
          title={polyglot.t('smart_pass.save_as_draft_reminder')}
          inlineActions={
            <Button type="critical" size="small" onClick={saveAsDraftReminder.setOff}>
              {polyglot.t('common.ok_got_it')}
            </Button>
          }
        />
      )}
      {isListShown && (
        <Alert spaceAfter="large" type="critical" title={polyglot.t('smart_pass.errors')}>
          <List>
            {legalListItem && (
              <ListItem>{polyglot.t('smart_pass.form.legal.header_title')}</ListItem>
            )}
            {generalListItem && (
              <ListItem>{polyglot.t('smart_pass.form.general.header_title')}</ListItem>
            )}
            {fastTrackListItem && (
              <ListItem>{polyglot.t('smart_pass.form.fast_track.header_title')}</ListItem>
            )}
            {transferAssistanceListItem && (
              <ListItem>{polyglot.t('smart_pass.form.transfer_assist.header_title')}</ListItem>
            )}
            {bagTransferAssistListItem && (
              <ListItem>{polyglot.t('smart_pass.form.bag_transfer_assist.header_title')}</ListItem>
            )}
            {vouchersListItem && (
              <ListItem>{polyglot.t('smart_pass.form.vouchers.header_title')}</ListItem>
            )}
          </List>
        </Alert>
      )}
    </div>
  );
};

export default ErrorsList;
