import styled, { css } from 'styled-components';

export const CardDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FullWidthContainer = styled.div`
  margin-bottom: 24px;
`;

export const CardColumnContainer = styled.div`
  flex: 1;
`;

const suppressedDataRowContainerCSS = css`
  margin-top: 0;
`;

export const DataRowContainer = styled.div`
  display: flex;
  min-height: 24px;
  margin-top: 24px;
  ${({ suppressed }) => suppressed && suppressedDataRowContainerCSS};
`;

export const RowLabel = styled.div`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? '15%' : '30%')};
  max-width: ${({ fullWidth }) => (fullWidth ? '15%' : '30%')};
  justify-content: flex-end;
  margin-right: 16px;
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
  text-align: right;
`;

export const RowValue = styled.div`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? '85%' : '70%')};
  max-width: ${({ fullWidth }) => (fullWidth ? '85%' : '70%')};
  justify-content: flex-start;
`;
